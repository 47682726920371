// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right-header-component {
  position: absolute;
  text-align: right;
  top: 10%;
  z-index: 5;
  right: 110px;
  color: white;
  height: 50px;
  width: 300px; }
  .right-header-component h5 {
    font-size: 12px; }
  .right-header-component p {
    margin: unset;
    padding: unset; }
  .right-header-component span {
    min-width: 200px;
    display: inline-block;
    text-align: center;
    font-family: Monaco; }
  .right-header-component .live-component {
    background-color: #ff6666;
    display: -webkit-inline-box;
    min-width: 60px;
    text-align: center;
    padding-left: 10px; }
  .right-header-component .right-header-tracker {
    display: inline-flex;
    flex-direction: column;
    line-height: 18px;
    vertical-align: middle; }
    .right-header-component .right-header-tracker span {
      padding-left: 5px;
      min-width: 130px;
      width: 130px; }
  .right-header-component .btn-right-menu-component {
    background-color: #4fc2d9;
    border: unset; }
    .right-header-component .btn-right-menu-component img {
      width: 15px;
      margin-top: -5px; }
`, "",{"version":3,"sources":["webpack://./src/components/right-header-styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,QAAQ;EACR,UAAU;EACV,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,YAAY,EAAA;EARd;IAWI,eAAe,EAAA;EAXnB;IAeI,aAAa;IACb,cAAc,EAAA;EAhBlB;IAoBM,gBAAgB;IAChB,qBAAqB;IACrB,kBAAkB;IAClB,mBAAmB,EAAA;EAvBzB;IA2BI,yBAAyB;IACzB,2BAA2B;IAC3B,eAAe;IACf,kBAAkB;IAClB,kBAAkB,EAAA;EA/BtB;IAmCI,oBAAoB;IACpB,sBAAsB;IACtB,iBAAiB;IACjB,sBAAsB,EAAA;IAtC1B;MAyCM,iBAAiB;MACjB,gBAAgB;MAChB,YAAY,EAAA;EA3ClB;IAgDI,yBAAyB;IACzB,aAAa,EAAA;IAjDjB;MAoDM,WAAW;MACX,gBAAgB,EAAA","sourcesContent":[".right-header-component {\n  position: absolute;\n  text-align: right;\n  top: 10%;\n  z-index: 5;\n  right: 110px;\n  color: white;\n  height: 50px;\n  width: 300px;\n\n  h5 {\n    font-size: 12px;\n  }\n\n  p {\n    margin: unset;\n    padding: unset;\n  }\n\n  span {\n      min-width: 200px;\n      display: inline-block;\n      text-align: center;\n      font-family: Monaco;\n  }\n    \n  .live-component {\n    background-color: #ff6666;\n    display: -webkit-inline-box;\n    min-width: 60px;\n    text-align: center;\n    padding-left: 10px;\n  }\n\n  .right-header-tracker {\n    display: inline-flex;\n    flex-direction: column;\n    line-height: 18px;\n    vertical-align: middle;\n\n    span {\n      padding-left: 5px;\n      min-width: 130px;\n      width: 130px;\n    }\n  }\n    \n  .btn-right-menu-component {\n    background-color: #4fc2d9;\n    border: unset;\n\n    img {\n      width: 15px;\n      margin-top: -5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
