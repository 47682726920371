import { useState, useEffect, useContext, useRef, useMemo } from 'react'
import { useLocation, Location } from 'react-router-dom';
import Map, { Marker, FullscreenControl } from 'react-map-gl';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import useSupercluster from 'use-supercluster';
import settings from '../../../settings.json';
import agent from '../../../api/agent';
import { RefContext } from '../../../RefContext'
import { Form, Col, Row, Table, Tabs, Tab } from 'react-bootstrap';
import RenderLayerMarkers from '../shared/RenderLayerMarkers';
import RenderLayers from '../shared/RenderLayers';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import MapLegend from '../shared/MapLegend';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMaximize, faMinimize } from '@fortawesome/pro-solid-svg-icons'
import MapSlider from '../shared/MapSlider';
import * as Scroll from 'react-scroll';
import 'react-circular-progressbar/dist/styles.css';
import './styles.scss';
import {
    Link,
    Button,
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller
} from 'react-scroll';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const NationalStormWaterQualityDatabase = (props: any) => {

    const { global } = props

    const [data, setData] = useState<any>()
    const [contaminates, setContaminates] = useState<any>()

    const [allData, setAllData] = useState<any>()

    const [activeReport, setActiveReport] = useState<any>()
    const [activeIndex, setActiveIndex] = useState<any>(0)

    const [dataMg, setDataMg] = useState<any>()
    const [dataUg, setDataUg] = useState<any>()
    const [dataBacteria, setDataBacteria] = useState<any>()
    const [pHHTT, setPHHTT] = useState<any>()
    const [fullscreen, setFullscreen] = useState<any>()
    const FullScreenComp = FullScreen as any;
    const fullscreenhandle = useFullScreenHandle() as any;
    const mapRef = useRef<any>()
    const { setCurrentRef } = useContext(RefContext)
    useEffect(() => { setCurrentRef?.(mapRef) }, [mapRef])

    const flyToState = (lng: number, lat: number) => {

        const centroidUS = lng === -94.659541 && lat === 38.927593

        return mapRef && mapRef?.current.flyTo({
            center: [
                lng,
                lat
            ],
            essential: true,
            zoom: centroidUS ? 1.5 : 6,
        });
    }

    useEffect(() => {
        Events.scrollEvent.register('begin', function (to, element) {
            console.log('begin', arguments);
        })

        return () => Events.scrollEvent.register('end', function (to, element) {
            console.log('end', arguments);
        });
    }, [])

    useEffect(() => {
        mapRef?.current && flyToState(global.viewport.longitude, global.viewport.latitude)
    }, [global.viewport])

    useEffect(() => {
        global.selectedStateCoordinates && flyToState(global.selectedStateCoordinates.lng, global.selectedStateCoordinates.lat)
    }, [global.selectedStateCoordinates])


    const filterReportsByState = (input: string) => {
        const filteredReports = allData && allData.filter((report: any) => report.State === input)
        // @ts-ignore
        if (filteredReports === []) {
            global.notify()
            setData(allData)
        }
        // @ts-ignore                   
        if (filteredReports !== []) {
            setData(filteredReports)
        }
    }

    useEffect(() => {
        data && filterReportsByState(global.selectedState)
    }, [global.selectedState])


    useEffect(() => {
        global.setUserLogged(true);
        global.setLoading(true)
        if (global.trackerOn) global.setTrackerOn(false)
        agent.Reports.NationalStormWaterDataBase()
            .then((data) => {
                setData(data.body)
                setAllData(data.body)
                setActiveReport(data.body[0])
            })
            .finally(() => global.setLoading(false))
    }, [])

    const sortData = (input: any) => {

        const contaminates = Object.keys(input)
        const contaminateMicroLiter = contaminates.filter((con: any) => /ug/gi.test(con))
        const contaminateMg = contaminates.filter((con: any) => /mg/gi.test(con))
        const contaminateBacteria = contaminates.filter((con: any) => /(per 100 ml)/gi.test(con))
        const conductivity = contaminates.filter((con: any) => /(cm at 25 C)/gi.test(con))

        setContaminates({
            contaminateMicroLiter: contaminateMicroLiter,
            contaminateMg: contaminateMg,
            contaminateBacteria: contaminateBacteria,
            conductivity: conductivity
        })
    }

    useEffect(() => {
        activeReport && sortData(activeReport)
    }, [activeReport])

    const modifyOptions = (input: any) => {
        const options = {
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 2,
            animation: true,
            scales: {
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: input === 'mg' && ('miligrams per liter')
                            || input === 'ug' && ('micrograms per liter')
                            || input === 'bact' && ('colonies per 100 ml'),
                        color: 'white'
                    },
                },
                x: {
                    display: true,
                    title: {
                        display: true,
                        color: 'white'
                    },
                },
            },
            plugins: {
                legend: {
                    position: "bottom" as const,
                    labels: {
                        usePointStyle: true,
                    },
                    maxHeight: 70,
                } as const,
                title: {
                    display: true,
                    text: input === 'mg' && ('Contaminant (mg/L)')
                        || input === 'ug' && ('Contaminant (\u338D/L)')
                        || input === 'bact' && ('Bacterial Contamination'),
                    color: 'white',
                    align: 'center',
                    padding: 10,
                    font: {
                        size: 20
                    }
                } as const,
                tooltip: {
                    padding: 10,
                    bodyFont: {
                        size: 24
                    },
                    titleFont: {
                        size: 24
                    },
                    boxPadding: 8,
                    usePointStyle: true,
                    backgroundColor: '#12234f',
                } as const,
                zoom: {
                    zoom: {
                        wheel: {
                            enabled: true,
                        },
                        pinch: {
                            enabled: true,
                        },
                        mode: 'xy',
                    },
                } as const,
            } as const,
        } as const;
        return options
    }



    const handleMgData = (input: any) => {
        setDataMg({
            datasets: input.map((contaminate: any, index: any) => {
                if (activeReport[contaminate] !== 0) {
                    return {
                        label: contaminate.replace(/\(([^)]+)\)/gi, ''),
                        data: { contaminates: activeReport[contaminate] },
                        key: index,
                        backgroundColor: getRandomColor()
                    }
                }
            })
        })
    }

    const handleUgData = (input: any) => {
        setDataUg({
            datasets: input.map((contaminate: any, index: any) => {
                if (activeReport[contaminate] !== 0) {
                    return {
                        label: contaminate.replace(/\(([^)]+)\)/gi, ''),
                        data: { contaminates: activeReport[contaminate] },
                        key: index,
                        backgroundColor: getRandomColor()
                    }
                }
            })
        })
    }

    const handleBactData = (input: any) => {
        setDataBacteria({
            datasets: input.map((contaminate: any, index: any) => {
                if (activeReport[contaminate] !== 0) {
                    return {
                        label: contaminate.replace(/\(([^)]+)\)/gi, ''),
                        data: { contaminates: activeReport[contaminate] },
                        key: index,
                        backgroundColor: getRandomColor()
                    }
                }
            })
        })
    }

    useEffect(() => {
        activeReport && setPHHTT({
            pH: activeReport.pH,
            hardness: activeReport['Hardness (mg/L CaCO3)'],
            turbidity: activeReport['Turbidity (ntu)'],
            temperature: activeReport['Temperature (C)']
        })
    }, [activeReport])

    useEffect(() => {
        contaminates && handleMgData(contaminates.contaminateMg)
    }, [contaminates])

    useEffect(() => {
        contaminates && handleUgData(contaminates.contaminateMicroLiter)
    }, [contaminates])

    useEffect(() => {
        contaminates && handleBactData(contaminates.contaminateBacteria)
    }, [contaminates])


    const bounds = mapRef.current
        ? mapRef.current
            .getMap()
            .getBounds()
            .toArray()
            .flat()
        : null;

    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const renderTurbidity = () => {

        const renderColor = () => {
            if (pHHTT.turbidity < 1) return '#5D8DA3'
            if (pHHTT.turbidity < 10) return '#96CDD4'
            if (pHHTT.turbidity < 25) return '#F1E8D5'
            if (pHHTT.turbidity < 50) return '#412710'
            if (pHHTT.turbidity < 150) return '#000000'
            if (pHHTT.turbidity > 150) return '#000000'
        }


        return (
            <div className="dial" style={{ marginTop: '-1pc' }}>
                <h3>Turbidity (NTU)</h3>
                <CircularProgressbar
                    value={pHHTT.turbidity}
                    minValue={0}
                    maxValue={500}
                    text={`${parseFloat(pHHTT.turbidity.toFixed(1))}`}
                    styles={buildStyles({
                        pathColor: renderColor()
                    })}
                />
                <br />
            </div>
        )
    }

    const renderTemperature = () => {

        const renderColor = () => {
            if (pHHTT.temperature < 4) return '#224398'
            if (pHHTT.temperature < 10) return '#2B95D1'
            if (pHHTT.temperature < 20) return '#24A86D'
            if (pHHTT.temperature < 37) return '#fced0c'
            if (pHHTT.temperature < 150) return '#ed212b'
        }


        return (
            <div className="dial" style={{ marginTop: '-1pc' }}>
                <h3>Temperature</h3>
                <CircularProgressbar
                    value={pHHTT.temperature}
                    minValue={0}
                    maxValue={200}
                    text={`${parseFloat(pHHTT.temperature.toFixed(1))}\u00B0C`}
                    styles={buildStyles({
                        pathColor: renderColor()
                    })}
                />
                <br />
            </div>
        )
    }

    const renderHardness = () => {

        const renderColor = () => {
            if (pHHTT.hardness < 17.1) return '#99FF66'
            if (pHHTT.hardness < 61) return '#FDFF00'
            if (pHHTT.hardness < 121) return '#FA9933'
            if (pHHTT.hardness < 181) return '#CC0800'
            if (pHHTT.hardness > 180) return '#a80803'
        }

        const renderText = () => {
            if (pHHTT.hardness < 17.1) return <strong style={{ color: renderColor() }}>Soft</strong>
            if (pHHTT.hardness < 61) return <strong style={{ color: renderColor() }}>Slightly Hard</strong>
            if (pHHTT.hardness < 121) return <strong style={{ color: renderColor() }}>Moderately Hard</strong>
            if (pHHTT.hardness < 181) return <strong style={{ color: renderColor() }}>Hard</strong>
            if (pHHTT.hardness > 180) return <strong style={{ color: renderColor() }}>Very Hard</strong>
        }

        return (
            <div className="dial">
                <h3>Hardness (mg/L)</h3>
                <CircularProgressbar
                    value={pHHTT.hardness}
                    maxValue={200}
                    text={`${parseFloat(pHHTT.hardness.toFixed(1))}`}
                    styles={buildStyles({
                        pathColor: renderColor()
                    })}
                />
                <p>Rating: {renderText()}</p>
            </div>
        )
    }

    const renderPh = () => {

        const renderColor = () => {
            if (pHHTT.pH <= 0) return '#EE1C25'
            if (pHHTT.pH <= 1) return '#F26724'
            if (pHHTT.pH <= 2) return '#F8C511'
            if (pHHTT.pH <= 3) return '#F5ED1D'
            if (pHHTT.pH <= 4) return '#B5D332'
            if (pHHTT.pH <= 5) return '#84C341'
            if (pHHTT.pH <= 6) return '#4DB749'
            if (pHHTT.pH <= 7) return '#33A94B'
            if (pHHTT.pH <= 8) return '#27B46A'
            if (pHHTT.pH <= 9) return '#2EB8B6'
            if (pHHTT.pH <= 10) return '#4690CD'
            if (pHHTT.pH <= 11) return '#3853A4'
            if (pHHTT.pH <= 12) return '#5A51A2'
            if (pHHTT.pH <= 13) return '#6543A1'
            if (pHHTT.pH <= 14) return '#462C83'
        }

        const renderText = () => {
            if (pHHTT.pH <= 0) return <strong style={{ color: renderColor() }}>Highly Acidic</strong>
            if (pHHTT.pH <= 1) return <strong style={{ color: renderColor() }}>Highly Acidic</strong>
            if (pHHTT.pH <= 2) return <strong style={{ color: renderColor() }}>Acidic</strong>
            if (pHHTT.pH <= 3) return <strong style={{ color: renderColor() }}>Moderately Acidic</strong>
            if (pHHTT.pH <= 4) return <strong style={{ color: renderColor() }}>Moderately Acidic</strong>
            if (pHHTT.pH <= 5) return <strong style={{ color: renderColor() }}>Slightly Acidic</strong>
            if (pHHTT.pH <= 6) return <strong style={{ color: renderColor() }}>Slightly Acidic</strong>
            if (pHHTT.pH <= 7) return <strong style={{ color: renderColor() }}>Neutral</strong>
            if (pHHTT.pH <= 8) return <strong style={{ color: renderColor() }}>Slighly Alkaline</strong>
            if (pHHTT.pH <= 9) return <strong style={{ color: renderColor() }}>Slighly Alkaline</strong>
            if (pHHTT.pH <= 10) return <strong style={{ color: renderColor() }}>Moderately Alkaline</strong>
            if (pHHTT.pH <= 11) return <strong style={{ color: renderColor() }}>Moderately Alkaline</strong>
            if (pHHTT.pH <= 12) return <strong style={{ color: renderColor() }}>Alkaline</strong>
            if (pHHTT.pH <= 13) return <strong style={{ color: renderColor() }}>Highly Alkaline</strong>
            if (pHHTT.pH <= 14) return <strong style={{ color: renderColor() }}>Highly Alkaline</strong>
        }



        return (
            <div className="dial">
                <h3>pH</h3>
                <CircularProgressbar
                    value={pHHTT.pH}
                    minValue={0}
                    maxValue={14}
                    text={`${parseFloat(pHHTT.pH.toFixed(1))}`}
                    styles={buildStyles({
                        pathColor: renderColor()
                    })}
                />
                <p>Rating: {renderText()}</p>
            </div>
        )
    }

    const trim = (input: any) => {
        const array = input.datasets.filter((report: any) => report !== undefined)
        return { datasets: array }
    }

    console.log(dataMg)    


    // @ts-ignore
    const mgGraph = useMemo(() => <Bar data={dataMg && trim(dataMg)} options={modifyOptions('mg')} />, [dataMg])
    // @ts-ignore
    const ugGraph = useMemo(() => <Bar data={dataUg && trim(dataUg)} options={modifyOptions('ug')} />, [dataUg])
    // @ts-ignore
    const bactGraph = useMemo(() => <Bar data={dataBacteria && trim(dataBacteria)} options={modifyOptions('ug')} />, [dataBacteria])

    return (
        <FullScreenComp handle={fullscreenhandle}>
            <div className="report-container" id='nswqd-report'>
                <ReportHeader global={global} data={data}
                    reportID={"nswqd-report"} fullScreenClickHandle={fullscreenhandle.enter} />
                <Row className="w-100 map-graphs">
                    <Col className='col-6'>
                        <div style={{ position: 'relative' }} className="stormwater-map-container">
                            <Map
                                ref={mapRef}
                                mapboxAccessToken={settings.maboxKey}
                                initialViewState={global.viewport}
                                projection={global.globeView ? 'globe' : 'mercator' as any}
                                onClick={(e) => {
                                    global.onMapClick(e)
                                }}
                                onMove={(e) =>
                                    global.setViewport({
                                        longitude: e.viewState.longitude,
                                        latitude: e.viewState.latitude,
                                        zoom: e.viewState.zoom,
                                    })
                                }

                               mapStyle={global.mapStyle}
                            >
                                <FullscreenControl />
                                <RenderLayerMarkers global={global} bounds={bounds} zipOff={true} wq={true} />
                                <RenderLayers global={global} mapRef={mapRef} />
                                {data && <RenderMarkers
                                    bounds={bounds}
                                    global={global}
                                    mapRef={mapRef}
                                    viewport={global.viewport}
                                    data={data}
                                    setActiveIndex={setActiveIndex}
                                    setActiveReport={setActiveReport}
                                />}
                                <div className='map-legend-container'>
                                  <MapLegend
                                    global={global}    
                                    legendWidth={320}                    
                                  />
                               </div>
                            </Map>
                            {global.NOAASeaLevelRise && (
                                <div
                                    style={{
                                        width: '80%',
                                        position: 'absolute',
                                        right: '41%',
                                        top: '67%'
                                    }}
                                    className="range-slider range-slider-noaa"
                                >
                                    <Form.Range
                                        style={{ marginBottom: "0" }}
                                        min={0}
                                        max={10}
                                        value={global.feetIndex}
                                        onChange={e => global.setFeetIndex(parseInt(e.target.value))}
                                    />
                                    <strong>Sea Level: {global.feetIndex}ft</strong>
                                </div>
                            )}
                            {global.terraClimateLayerSelected | global.evapotranspirationSelected ? (
                                <div style={{
                                    width: '80%',
                                    position: 'absolute',
                                    right: '41%',
                                    top: '80%'
                                }} className="range-slider">
                                    <Form.Range
                                        min={2000}
                                        max={2019}
                                        value={global.MTCLValue}
                                        onChange={e => global.setMTCLValue(parseInt(e.target.value))}
                                    />
                                    <strong>Year: {global.MTCLValue}</strong>
                                </div>
                            ) : null}
                        </div>
                    </Col>
                    <Col className='col-6'>
                        {data && (
                            <section className={fullscreen ? "report-data-container fullscreen" : "report-data-container"} >
                                {
                                    fullscreen ?

                                        <button onClick={() => setFullscreen(false)} className="table-button">
                                            <FontAwesomeIcon className="paginate-btn" icon={faMinimize} />
                                        </button>

                                        :

                                        <button onClick={() => setFullscreen(true)} className="table-button">
                                            <FontAwesomeIcon className="paginate-btn" icon={faMaximize} />
                                        </button>

                                }
                                <div className="report-data-graph-container">
                                    <Tabs
                                        defaultActiveKey="Contaminant mg/L"
                                        id="uncontrolled-tab-example"
                                        className="w-100 p-0 m-0 position-absolute top-0"
                                    >
                                        <Tab
                                            className="w-100 h-100 p-0 mt-5 mb-auto fs-6"
                                            eventKey="Contaminant mg/L"
                                            title="Contaminant mg/L">
                                            {dataMg && mgGraph}
                                        </Tab>
                                        <Tab
                                            className="w-100 h-100 p-0 mt-5 mb-auto fs-6"
                                            eventKey="Contaminant ug/L"
                                            title={`Contaminant \u338D/L`}>
                                            {dataUg && ugGraph}
                                        </Tab>
                                        <Tab
                                            className="w-100 h-100 p-0 mt-5 mb-auto fs-6"
                                            eventKey="Bacteria"
                                            title="Bacteria">
                                            {dataBacteria && bactGraph}
                                        </Tab>
                                        <Tab
                                            className="w-100 h-100 p-0 mt-5 mb-auto fs-6"
                                            eventKey="pH, Hardness, Turbidity, & Temperature"
                                            title="pH, Hardness, Turbidity, & Temperature">
                                            <div className="center-dials">
                                                <div className="dial-container">
                                                    {pHHTT && (renderPh())}
                                                    {pHHTT && (renderHardness())}
                                                    {pHHTT && (renderTurbidity())}
                                                    {pHHTT && (renderTemperature())}
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </section>
                        )}
                    </Col>
                </Row>
                <Row className="w-100 d-flex justify-content-center mt-5">
                    {data && (
                        <Table style={{ backgroundColor: '#0B1736', width: '96%' }} className="facility-table w-98 p-6">
                            <div id="scroll-container" style={{ height: '200px', overflow: 'auto' }}>
                                <thead className="facility-table-head">
                                    <tr>
                                        <th className="facility-table-header" style={{ color: 'white' }}>Facility Name</th>
                                    </tr>
                                </thead>
                                <tbody className="facility-table-body">
                                    {data.map((station: any, index: number) =>
                                        <Element name={`facility-index-${index}`}>
                                            <RenderRow
                                                data={data}
                                                setActiveReport={setActiveReport}
                                                setActiveIndex={setActiveIndex}
                                                activeIndex={activeIndex}
                                                key={index}
                                                index={index}
                                                mapRef={mapRef}
                                                station={station['Station Name']}
                                            />
                                        </Element>
                                    )}
                                </tbody>
                            </div>
                        </Table>
                    )}
                </Row>
                <ReportFooter />
            </div>
            <MapSlider global={global} />
        </FullScreenComp >
    )
}

const RenderMarkers = (props: any) => {
    const {
        bounds,
        viewport,
        data,
        mapRef,
        global,
        setActiveIndex,
        setActiveReport
    } = props

    const points = data && data.map((point: any, index: number) => ({
        type: "Feature",
        properties: {
            cluster: false,
            index,
            id: `cluster-${index}`,
        },
        geometry: {
            type: "Point",
            coordinates: [point.lng, point.lat],
        }
    }))

    const { clusters } = useSupercluster({
        points,
        bounds,
        zoom: viewport.zoom,
        options: { radius: 45, maxZoom: 10 }
    })



    const handleClick = (e: any, type: any, index: any = null) => {

        const handleActiveStation = (input: number) => {
            setActiveIndex(input)
            setActiveReport(data[input])
        }

        if (type === 'cluster') {

            return mapRef?.current.flyTo({
                center: [
                    e.target._lngLat.lng,
                    e.target._lngLat.lat
                ],
                essential: true,
                zoom: viewport.zoom += 3
            });
        }

        if (type === 'point') {
            handleActiveStation(index)
        }

    }

    const renderData = () => {
        return clusters.map((cluster: any) => {
            const [longitude, latitude] = cluster.geometry.coordinates
            const { cluster: isCluster, point_count: pointCount, index } = cluster.properties;

            if (isCluster) {
                return (
                    <Marker
                        key={index}
                        latitude={latitude}
                        longitude={longitude}
                        onClick={(e) => handleClick(e, 'cluster')}
                    >
                        <div
                            className="cluster-marker-NSWQD"
                            style={{
                                width: `12px`,
                                height: `12px`,
                                cursor: "pointer"
                            }}
                        >
                            {pointCount}
                        </div>
                    </Marker>
                )
            }


            return (
                <Link
                    to={`facility-index-${index}`}
                    containerId="scroll-container"
                    spy={true}
                    smooth={true}
                    duration={500}
                >
                    <Marker
                        key={cluster.properties.id}
                        latitude={latitude}
                        longitude={longitude}
                        onClick={(e) => handleClick(e, 'point', index)}
                    >
                        <div
                            className="cluster-marker-NSWQD"
                            style={{
                                width: `12px`,
                                height: `12px`,
                                cursor: "pointer"
                            }}
                        ></div>
                    </Marker>
                </Link>
            );
        })
    }

    return (
        <>
            {data && renderData()}
        </>
    )

}

const RenderRow = (props: any) => {

    const {
        station,
        index,
        setActiveReport,
        setActiveIndex,
        activeIndex,
        data,
        mapRef
    } = props

    const flyToLocation = (lng: number, lat: number) => {
        return mapRef?.current.flyTo({
            center: [
                lng,
                lat
            ],
            essential: true,
            zoom: 17,
        });
    }

    const handleActiveStation = (input: number) => {
        setActiveIndex(input)
        setActiveReport(data[input])
        flyToLocation(data[input].lng, data[input].lat)
    }

    return (
        <tr
            onClick={() => handleActiveStation(index)}
            className={`facility-table-row${index === activeIndex ? ' active-station' : ''}`}
        >
            <td>{station}</td>
        </tr>
    )
}

export default NationalStormWaterQualityDatabase