import agent from '../../../api/agent';
import axios from 'axios';
import localForage from "localforage";
import { parse } from 'papaparse';
import { uniqueValues, groupByKey} from '../shared/report-data';
import RiskCategories from './RiskCategories';
import settings from "../../../settings.json";

/*Notes+++

  Surface Water scores use HUC12
    @ HUC12 query has been set with radius as it is perhaps point data.

*/


const aquaduct = ['Site Water Security / Distress', 'Riverine Flood Risk', 'Coastal Flood Risk', 'Water Management', 'Ingredient Sourcing'];
const colorGreen = "#4fc22c";
const colorYellow = "#e6ee00";
const colorRed = "#c22c4f";
const colorUnavailable = "#3c343c";
const message_noscoreavailable = "No Score Available"
const updateEvaluation = RiskCategories.filter(x => x.updateonload === 'true')?.map(function(x:any) { return x.title; });

let surfaceWaterData = [] as any[];

const evaluateData = (evaluation: string, dataItem: any, returnSet: any, riskCategory:any) => { 

    if(!returnSet){
      dataItem["queryreturn"] = '!returnSet ' + evaluation;
    }


  if(evaluation === 'Site Flood Zone Ratings'){
      if(!returnSet){
        return;
      }
      const greenArray = ['X', 'C']; // Zone X (unshaded)
      const yellowArray = ['B'];  //Zone X (shaded)
      const redArray = ['A', 'AE', 'AO', 'AH', 'AR', 'A1', 'A30', 'AR/AE', 'AR/AO', 'AR/A', 'V', 'VE', 'Z'];  //A1-A30 //V1-V30

      //Flood hazard areas identified on the Flood Insurance Rate Map are identified as a Special Flood Hazard Area (SFHA). 
      //SFHA are defined as the area that will be inundated by the flood event having a 1-percent chance of being equaled or exceeded in any given year. 
      //The 1-percent annual chance flood is also referred to as the base flood or 100-year flood. 
      //SFHAs are labeled as Zone A, Zone AO, Zone AH, Zones A1-A30, Zone AE, Zone A99, Zone AR, Zone AR/AE, Zone AR/AO, Zone AR/A1-A30, Zone AR/A, Zone V, Zone VE, and Zones V1-V30. 
      //Moderate flood hazard areas, labeled Zone B or Zone X (shaded) are also shown on the FIRM, and are the areas between the limits of the base flood and the 0.2-percent-annual-chance (or 500-year) flood. 
      //The areas of minimal flood hazard, which are the areas outside the SFHA and higher than the elevation of the 0.2-percent-annual-chance flood, are labeled Zone C or Zone X (unshaded).

      if(returnSet.length === 0){
        dataItem["ra_"+ evaluation] = '-';
        dataItem[evaluation] = colorGreen;
        dataItem[evaluation + 'result'] = '-';
      } else {
        dataItem["FLD_ZONE"] = returnSet[0].properties.FLD_ZONE;
        dataItem["ra_"+ evaluation + '_supfield1'] = returnSet[0].properties["FLD_ZONE"]; 
        dataItem["ra_"+ evaluation + '_supfield2'] = returnSet[0].properties["ZONE_SUBTY"]; 
        dataItem["ra_"+ evaluation] = returnSet;       
        //console.log(Object.keys(returnSet).map(k => returnSet[k].properties["FLD_ZONE"]))
        //console.log(Object.keys(returnSet).map(k => returnSet[k].properties["ZONE_SUBTY"]))
        if(greenArray.indexOf(returnSet[0].properties.FLD_ZONE)>-1){
            dataItem[evaluation] = colorGreen;
            dataItem[evaluation + 'result'] = 'Minimal Flood Hazard';
        } else if (yellowArray.indexOf(returnSet[0].properties.FLD_ZONE)>-1){
            dataItem[evaluation] = colorYellow;
            dataItem[evaluation + 'result'] = 'Moderate Flood Hazard';
        } else if (redArray.indexOf(returnSet[0].properties.FLD_ZONE)>-1||returnSet[0].properties.FLD_ZONE.substr(0,1)==='A'||returnSet[0].properties.FLD_ZONE.substr(0,1)==='V'){
            dataItem[evaluation] = colorRed;
            dataItem[evaluation + 'result'] = 'High Flood Hazard';
        }
      }
  } else if(evaluation === 'Drought Status'){    
      if(!returnSet){
        return;
      }

      if(returnSet.length === 0){
        dataItem["ra_"+ evaluation] = returnSet;
        dataItem[evaluation] = colorGreen;
        dataItem[evaluation + 'result'] = 'Not rated as being in a drought';
      } else {
        
      dataItem["DM"] = returnSet[0].properties.DM;
      dataItem["ra_"+ evaluation + '_supfield1'] = returnSet[0].properties.DM; 
      dataItem["ra_"+ evaluation] = returnSet;

        if(returnSet[0].properties.DM === 0){
            dataItem[evaluation] = colorYellow;
            dataItem[evaluation + 'result'] = 'Abnormally Dry (D0)';
        } else if (returnSet[0].properties.DM === 1){
            dataItem[evaluation] = colorYellow;
            dataItem[evaluation + 'result'] = 'Moderate Drought (D1)';
        } else if (returnSet[0].properties.DM === 2) {
            dataItem[evaluation] = colorRed;
            dataItem[evaluation + 'result'] = 'Severe Drought (D2)';
        } else if (returnSet[0].properties.DM === 3) {
            dataItem[evaluation] = colorRed;
            dataItem[evaluation + 'result'] = 'Extreme Drought (D3)';
        } else if (returnSet[0].properties.DM === 4) {
            dataItem[evaluation] = colorRed;
            dataItem[evaluation + 'result'] = 'Exceptional Drought (D4)';
        } else if (returnSet[0].properties.DM === 5) {
            dataItem[evaluation] = colorRed;
            dataItem[evaluation + 'result'] = 'Biblical End-Times Drought (D5)';
        } else {
            dataItem[evaluation] = colorGreen;
            dataItem[evaluation + 'result'] = 'Not officially rated as being in a drought';
        }
      }
  }  else if(evaluation === 'Located within a Protected Area'){
      if(!returnSet){
        return;
      }

      if(returnSet.length === 0){
        dataItem["ra_"+ evaluation] = '-';
        dataItem[evaluation] = colorGreen;
        dataItem[evaluation + 'result'] = 'Not in Protected area';
      } else {

        dataItem["ra_"+ evaluation + '_supfield1'] = Object.keys(returnSet).map(k => returnSet[k].properties["NAME"] + ':'); 
        dataItem["ra_"+ evaluation + '_supfield2'] = Object.keys(returnSet).map(k => returnSet[k].properties["DESIG_ENG"] + ' : '); 
        dataItem["ra_"+ evaluation] = returnSet;
        dataItem[evaluation] = colorRed;
        dataItem[evaluation + 'result'] = 'Located within Protected Area';
      }
  }  else if(evaluation === 'Superfund in Close Proximity'){

      if(!returnSet){
        return;
      }

      if(returnSet.length === 0){
        dataItem["ra_"+ evaluation] = returnSet;
        dataItem[evaluation] = colorGreen;
        dataItem[evaluation + 'result'] = 'No Superfund sites are within the watershed';
      } else {
        const evalField = 'P_PNPL';
        const valueField = returnSet[0].properties[evalField];
        dataItem[evalField] = valueField
        dataItem["ra_"+ evaluation + '_supfield1'] = returnSet[0].properties['T_PNPL']//Math.round(valueField); 
        dataItem["ra_"+ evaluation + '_supfield2'] = "";
        dataItem["ra_"+ evaluation] = returnSet;  //T_PNPL  "0.56 sites/km distance (95%ile)"  T_PNPL_D2"95%ile"
        
        if(valueField < 30){
            dataItem[evaluation] = colorGreen;
            dataItem[evaluation + 'result'] = 'No Superfund sites within the watershed';
        } else if (valueField < 80 && valueField > 30){
            dataItem[evaluation] = colorYellow;
            dataItem[evaluation + 'result'] = 'Superfund site in watershed that directly feeds this one.';
        } else if (valueField > 80){
            dataItem[evaluation] = colorRed;
            dataItem[evaluation + 'result'] = 'Superfund site has been detected within this watershed.';
        }   
      }
  } else if(evaluation === 'Site Surface Water Quality Threshold'){

      const evalField = 'Overall Surface Water Score';
      
      if(!returnSet){
        dataItem["ra_"+ evaluation] = message_noscoreavailable;
        dataItem[evaluation] = colorUnavailable;
        dataItem[evaluation + 'result'] = message_noscoreavailable;
      } else if(returnSet && !returnSet[evalField]){
        dataItem["ra_"+ evaluation] = message_noscoreavailable;
        dataItem[evaluation] = colorUnavailable;
        dataItem[evaluation + 'result'] = message_noscoreavailable;
      } else if(returnSet && returnSet[evalField] && dataItem[evalField] === 'NaN'){
         console.log('NaN')
        dataItem["ra_"+ evaluation] = 'NaN';
        dataItem[evaluation] = colorUnavailable;
        dataItem[evaluation + 'result'] = message_noscoreavailable;
      } else {
      
        const valueField = returnSet[evalField];
        
        dataItem[evalField] = valueField;
        dataItem["ra_"+ evaluation] = returnSet; 
        //dataItem["ra_"+ evaluation + '_supfield1'] = dataItem["HUC12"]; 
        dataItem["ra_"+ evaluation + '_supfield2'] = dataItem["HUC12NAME"];
        //dataItem["ra_"+ evaluation] = returnSet;
         
        if(valueField > 90 ){
            dataItem[evaluation] = colorGreen;
            dataItem[evaluation + 'result'] = valueField;
        } else if (valueField < 90 && valueField > 80){
            dataItem[evaluation] = colorYellow;
            dataItem[evaluation + 'result'] = valueField;
        } else if (valueField < 80 ){
            dataItem[evaluation] = colorRed;
            dataItem[evaluation + 'result'] = valueField;
        }
      }
  } else if(evaluation === 'PFAS Detection in Proximity'){
          const colorsWaterType = {'PFAS Detected': '#d73027', 'Present Below Quantification Limit': '#6a3d9a','Below Reporting Limit': '#74add1', 'Not Detected': '#e0f3f8',  'GU': '#b15928', 'Other': '#abd9e9'} 
      const chartFields = ["Maximum Analytical Value", "Minimum Analytical Value", "Minimum Reporting Level"];
      //"Pfas Chemical Name", "Result Measure Value"


      if(!returnSet){
        dataItem["ra_"+ evaluation] = 'No PFAS detected';
        dataItem[evaluation] = colorGreen;
        dataItem[evaluation + 'result'] = 'No PFAS detected';
      } else {       
        const evalField = 'HUC12PFAS';
        const valueField = returnSet[evalField];//'PFAS detected';
        dataItem[evalField] = valueField
        dataItem["ra_"+ evaluation] = returnSet; 
        //dataItem["ra_"+ evaluation + '_supfield1'] = dataItem["HUC12"]; 
        //dataItem["ra_"+ evaluation + '_supfield2'] = dataItem["HUC12NAME"];
       
       if(returnSet["HUC12PFAS"] > 0 || returnSet["HUC12SOURCEPFAS"] > 0){
            dataItem[evaluation] = colorRed;
            dataItem[evaluation + 'result'] = 'PFAS detected';
        } else if (returnSet["PFASHUC8Detections"] > 0 ){
            dataItem[evaluation] = colorYellow;
            dataItem[evaluation + 'result'] = 'PFAS detected in HUC8';  
        } else {
            dataItem[evaluation] = colorGreen;
            dataItem[evaluation + 'result'] = 'No PFAS detected';
        }
      }
  } else if(evaluation === 'Wildfire'){
      
      
      if(!returnSet||returnSet.length===0){
        dataItem["ra_"+ evaluation] = 'No historic fires detected';
        dataItem[evaluation] = colorGreen;
        dataItem[evaluation + 'result'] = 'No historic fires detected';
      } else {  
        const evalField = 'FEATURE_CA';     
        let feature = returnSet[0];
        
        if(feature && feature.properties && feature.properties['FEATURE_CA']){
          feature = feature.properties;
        } else {
            dataItem[evaluation] = colorGreen;
            dataItem[evaluation + 'result'] = 'No Wildfires detected';    
        }
        
        const valueField = returnSet[evalField];//'PFAS detected';
        dataItem[evalField] = valueField;
        dataItem["ra_"+ evaluation] = returnSet; 

        //dataItem["ra_"+ evaluation + '_supfield1'] = dataItem["HUC12"]; 
        //dataItem["ra_"+ evaluation + '_supfield2'] = dataItem["HUC12NAME"];
       
       if(feature && feature["FEATURE_CA"] && feature["FEATURE_CA"].substr(0,8) === 'Wildfire'){
            dataItem[evaluation] = colorRed;
            dataItem[evaluation + 'result'] = 'Wildfires detected';
        } else if (feature &&  feature["FEATURE_CA"] && feature["FEATURE_CA"].substr(0,8) === 'Wildfire'){
            dataItem[evaluation] = colorYellow;
            dataItem[evaluation + 'result'] = 'Wildfires detected in adjacent';  
        } else {
            dataItem[evaluation] = colorGreen;
            dataItem[evaluation + 'result'] = 'No Wildfires detected';
        }
      }
  } else if(evaluation === 'Climate Change Precipitation Risk'){
      const evalField = 'ratechange';

      if(!returnSet){
        dataItem["ra_"+ evaluation] = message_noscoreavailable;
        dataItem[evaluation] = colorUnavailable;
        dataItem[evaluation + 'result'] = message_noscoreavailable;
      } else if(returnSet && !returnSet[evalField]){
        dataItem["ra_"+ evaluation] = message_noscoreavailable;
        dataItem[evaluation] = colorUnavailable;
        dataItem[evaluation + 'result'] = message_noscoreavailable;
      } else if(returnSet && returnSet[evalField] && dataItem[evalField] === 'NaN'){
         console.log('NaN')  
        dataItem["ra_"+ evaluation] = 'NaN';
        dataItem[evaluation] = colorUnavailable;
        dataItem[evaluation + 'result'] = message_noscoreavailable;
      } else {
        const valueField = returnSet[evalField];   
        dataItem["ra_"+ evaluation] = valueField;
        dataItem["ra_"+ evaluation + '_supfield1'] = ' ' + Math.round((valueField||0)*100)+'%'; 
         
       if(valueField < .1 ){
            dataItem[evaluation] = colorGreen;
            dataItem[evaluation + 'result'] = 'Increased by less than 10%';
        } else if (valueField < .25 && valueField > .1){
            dataItem[evaluation] = colorYellow;
            dataItem[evaluation + 'result'] = 'Risk has increased by 10% to 25%';
        } else if (valueField > .25) {
            dataItem[evaluation] = colorRed;
            dataItem[evaluation + 'result'] = 'Precipitation Risk is greater than 25%';
        }
      }
  } else if(evaluation === 'Watershed Health 303(d)'){
      //const evalField = 'ratechange';
      const evalField = 'Watershed Health 303(d)';
    
      if(!returnSet){
        dataItem["ra_"+ evaluation] = message_noscoreavailable;
        dataItem[evaluation] = colorUnavailable;
        dataItem[evaluation + 'result'] = message_noscoreavailable;
      } else if(returnSet && !returnSet[evalField]){
        dataItem["ra_"+ evaluation] = message_noscoreavailable;
        dataItem[evaluation] = colorUnavailable;
        dataItem[evaluation + 'result'] = message_noscoreavailable;
      } else if(returnSet && returnSet[evalField] && dataItem[evalField] === 'NaN'){
         console.log('NaN')
        dataItem["ra_"+ evaluation] = 'NaN';
        dataItem[evaluation] = colorUnavailable;
        dataItem[evaluation + 'result'] = message_noscoreavailable;
      } else {
      
        const valueField = returnSet[evalField];
        
        dataItem[evalField] = valueField;
        dataItem["ra_"+ evaluation] = returnSet; 
        //dataItem["ra_"+ evaluation + '_supfield1'] = dataItem["HUC12"]; 
        //dataItem["ra_"+ evaluation + '_supfield2'] = dataItem["HUC12NAME"];
        //dataItem["ra_"+ evaluation] = returnSet;
         
        if(valueField > 90 ){
            dataItem[evaluation] = colorGreen;
            dataItem[evaluation + 'result'] = valueField;
        } else if (valueField < 90 && valueField > 80){
            dataItem[evaluation] = colorYellow;
            dataItem[evaluation + 'result'] = valueField;
        } else if (valueField < 80 ){
            dataItem[evaluation] = colorRed;
            dataItem[evaluation + 'result'] = valueField;
        }
      }
  }  else if(aquaduct.indexOf(evaluation)>-1){
      //console.log(evaluation, returnSet, dataItem);
      if(!returnSet){
        return;
      }

      for(var i = 0;i<aquaduct.length;i++) { 
        let _evaluation = aquaduct[i];
        if(returnSet.length === 0){
          dataItem["ra_"+ _evaluation] = returnSet;
          dataItem[_evaluation] = colorGreen;
          dataItem[_evaluation + 'result'] = '-';
        } else {
            //

          dataItem["rfr_label"] = returnSet[0].properties.rfr_label;
          dataItem["cfr_label"] = returnSet[0].properties.cfr_label; 
          dataItem["bws_label"] = returnSet[0].properties.bws_label;  
          dataItem["ra_"+ _evaluation] = returnSet;

          if(_evaluation === 'Site Water Security / Distress'){
            dataItem[_evaluation + 'result'] = returnSet[0].properties.bws_label;
            dataItem["ra_"+ _evaluation + '_supfield1'] = returnSet[0].properties["bws_score"].toPrecision(2);
            if(returnSet[0].properties.bws_label === 'Low (<10%)' || returnSet[0].properties.bws_label === 'Low - Medium (1 in 1,000 to 2 in 1,000)'|| returnSet[0].properties.bws_label === 'Low - Medium (10-20%)'){
                dataItem[_evaluation] = colorGreen;
            } else if (returnSet[0].properties.bws_label === 'Medium - High (20-40%)'){
                dataItem[_evaluation] = colorYellow;
            } else if (returnSet[0].properties.bws_label === 'High (6 in 1,000 to 1 in 100)' || returnSet[0].properties.bws_label === 'Extremely High (>80%)' || returnSet[0].properties.bws_label === 'High (40-80%)' || returnSet[0].properties.bws_label === 'Arid and Low Water Use'){
                dataItem[_evaluation] = colorRed;
            } else {
              
            }
          } else if(_evaluation === 'Riverine Flood Risk'){ //
            dataItem[_evaluation + 'result'] = returnSet[0].properties.rfr_label;
            dataItem["ra_"+ _evaluation + '_supfield1'] = returnSet[0].properties["rfr_score"].toPrecision(2);
            if(returnSet[0].properties.rfr_label === 'Low (0 to 1 in 1,000)' || returnSet[0].properties.rfr_label === 'Low - Medium (1 in 1,000 to 2 in 1,000)'){
                dataItem[_evaluation] = colorGreen;
            } else if (returnSet[0].properties.rfr_label === 'Medium - High (2 in 1,000 to 6 in 1,000)'){
                dataItem[_evaluation] = colorYellow;
            } else if (returnSet[0].properties.rfr_label === 'Extremely High (more than 2 in 1,000)' || returnSet[0].properties.rfr_label === 'High (6 in 1,000 to 1 in 100)'|| returnSet[0].properties.rfr_label === 'Extremely High (more than 1 in 100)'){
                dataItem[_evaluation] = colorRed;
            } else {
               
            }
        } else if(_evaluation === 'Coastal Flood Risk'){

          dataItem[_evaluation + 'result'] = returnSet[0].properties.cfr_label;
            dataItem["ra_"+ _evaluation + '_supfield1'] = returnSet[0].properties["cfr_score"].toPrecision(2);
            if(returnSet[0].properties.cfr_label === 'Low (0 to 9 in 1,000,000)' || returnSet[0].properties.cfr_label === 'Low - Medium (9 in 1,000,000 to 7 in 100,000)' || returnSet[0].properties.cfr_label === 'No Risk'){
                dataItem[_evaluation] = colorGreen;
            } else if (returnSet[0].properties.cfr_label === 'Medium - High (20-40%)'||returnSet[0].properties.cfr_label === 'Medium - High (7 in 100,000 to 3 in 10,000)'){
                dataItem[_evaluation] = colorYellow;
            } else if (returnSet[0].properties.cfr_label === 'Extremely High (>80%)' || returnSet[0].properties.cfr_label === 'Extremely High (more than 2 in 1,000)' || returnSet[0].properties.cfr_label === 'High (3 in 10,000 to 2 in 1,000)'){
                dataItem[_evaluation] = colorRed;
            } else {
              
            }
        } else if (_evaluation === 'Water Management'){
            //console.log(_evaluation);
            dataItem[_evaluation + 'result'] = returnSet[0].properties.bws_label;

            let ww = Math.floor(Math.random()*898)+101;
            let wd = Math.floor(Math.random()*398)+10;
            let wc = ww - wd;

            let calcCounter = 0;

            if(dataItem["Water Withdrawal"]){
              ww = +dataItem["Water Withdrawal"];
              calcCounter = calcCounter + 1;
            }

            if(dataItem["Water Discharge"]){
              wd = +dataItem["Water Discharge"];
              calcCounter = calcCounter + 1;
            }

            if(dataItem["Water Consumption"]){
              wc = +dataItem["Water Consumption"];
              calcCounter = calcCounter + 1;
            }

            if(calcCounter === 2){
              if(dataItem["Water Withdrawal"] && dataItem["Water Discharge"]){
                wc = +dataItem["Water Withdrawal"] - +dataItem["Water Discharge"];
              }
              if(dataItem["Water Withdrawal"] && dataItem["Water Consumption"]){
                wd = +dataItem["Water Withdrawal"] - +dataItem["Water Consumption"];
              }
              if(dataItem["Water Consumption"] && dataItem["Water Discharge"]){
                ww = +dataItem["Water Consumption"] + +dataItem["Water Discharge"];
              }
            }
  
            dataItem["ra_"+ _evaluation + '_supfield1'] = wc;
            dataItem["ra_"+ _evaluation + '_supfield2'] = ww;
            dataItem["ra_"+ _evaluation + '_supfield3'] = wd;
            dataItem["ra_"+ _evaluation + '_supfieldcalculated'] = "ra_"+ _evaluation + '_supfield1';

            if(returnSet[0].properties.bws_label === 'Low (<10%)' || returnSet[0].properties.bws_label === 'Low - Medium (1 in 1,000 to 2 in 1,000)'|| returnSet[0].properties.bws_label === 'Low - Medium (10-20%)'){
                dataItem[_evaluation] = colorGreen;
            } else if (returnSet[0].properties.bws_label === 'Medium - High (20-40%)'){
                dataItem[_evaluation] = colorYellow;
            } else if (returnSet[0].properties.bws_label === 'High (6 in 1,000 to 1 in 100)' || returnSet[0].properties.bws_label === 'Extremely High (>80%)' || returnSet[0].properties.bws_label === 'High (40-80%)'){
                dataItem[_evaluation] = colorRed;
            } else {
              
            }

         
        } else if (_evaluation === 'Ingredient Sourcing'){
            //console.log(_evaluation);
            dataItem[_evaluation + 'result'] = returnSet[0].properties.bws_label;

            let ww = Math.floor(Math.random()*898)+101; ///+++
            let wd = Math.floor(Math.random()*398)+10;
            let wc = Math.floor(Math.random()*598)+10;
            let wb = Math.floor(Math.random()*398)+10;

            let calcCounter = 0;

            if(dataItem["Agriculture Products"]){
              wc = +dataItem["Agriculture Products"];
              calcCounter = calcCounter + 1;
            } else {
              dataItem["Agriculture Products"] = wc;
            }

            if(dataItem["Beverage Ingredients"]){
              wd = +dataItem["Beverage Ingredients"];
              calcCounter = calcCounter + 1;
            } else {
              dataItem["Beverage Ingredients"] = wd;
            }

            if(dataItem["Animal Feed"]){
              ww = +dataItem["Animal Feed"];
              calcCounter = calcCounter + 1;
            } else {
              dataItem["Animal Feed"] = ww;
            }

            if(dataItem["Food Ingredients"]){
              wb = +dataItem["Food Ingredients"];
              calcCounter = calcCounter + 1;
            } else {
              dataItem["Food Ingredients"] = wb;
            }

            dataItem["ra_"+ _evaluation + '_supfield1'] = wc;
            dataItem["ra_"+ _evaluation + '_supfield2'] = ww;
            dataItem["ra_"+ _evaluation + '_supfield3'] = wd;
            dataItem["ra_"+ _evaluation + '_supfieldcalculated'] = "ra_"+ _evaluation + '_supfield1';

            if(returnSet[0].properties.bws_label === 'Low (<10%)' || returnSet[0].properties.bws_label === 'Low - Medium (1 in 1,000 to 2 in 1,000)'|| returnSet[0].properties.bws_label === 'Low - Medium (10-20%)'){
                dataItem[_evaluation] = colorGreen;
            } else if (returnSet[0].properties.bws_label === 'Medium - High (20-40%)'){
                dataItem[_evaluation] = colorYellow;
            } else if (returnSet[0].properties.bws_label === 'High (6 in 1,000 to 1 in 100)' || returnSet[0].properties.bws_label === 'Extremely High (>80%)' || returnSet[0].properties.bws_label === 'High (40-80%)'){
                dataItem[_evaluation] = colorRed;
            } else {
              
            }
        } 
      }
    }
  } else {
          console.log(evaluation, dataItem, returnSet, riskCategory)
  }

  return dataItem;
}

const surfaceWater = (evaluation: string, dataItem: any) => {     
  let riskCategory = RiskCategories.find(x => x.title === evaluation);
  const _dataItem= Object.assign({}, dataItem);
  if(surfaceWaterData && surfaceWaterData.length>0){
      let body = [...surfaceWaterData];
      if(body && body.length > 0){                       
        let fData = body.filter((o: any) => o['Huc12'] === dataItem["HUC12"] );
       
        if(fData.length>0){
          setStore("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"], fData[0]); 
          return evaluateData(evaluation, dataItem, fData[0], riskCategory);
        } else {
          return evaluateData(evaluation, dataItem, _dataItem, riskCategory);
        }
      } else {
        return evaluateData(evaluation, dataItem, _dataItem, riskCategory);
      }

  } else {
        agent.Reports.SurfaceWater(dataItem["HUC8"]).then(({ body }: any) => {
          if(body && body.length > 0 && body!=="Unauthorized"){                       
            surfaceWaterData = [...body];
            let fData = body.filter((o: any) => o['Huc12'] === dataItem["HUC12"] );
            if(fData.length>0){
              setStore("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"], fData[0]); 
              return evaluateData(evaluation, dataItem, fData[0], riskCategory);
            } else {
              return evaluateData(evaluation, dataItem, _dataItem, riskCategory);
            }
          } else {
            return evaluateData(evaluation, dataItem, _dataItem, riskCategory);
          }
      });  
  }                              
}

const outflowsWatershed = (response: any, dataItem: any) => {     
    dataItem["HUC12SOURCE"] = [];
    dataItem["HUC12OUT"] = [];
    dataItem["HUC12SOURCEPFAS"] = 0;
    if(response.data.features && response.data.features.length>0 && response.data.features[0].properties.OUTHUC12){
      if(dataItem["HUC12"]){                   
        for (let dataI of response.data.features) {
           if( dataI.properties["OUTHUC12"]=== dataItem["HUC12"]){
             dataItem["HUC12SOURCE"].push(dataI.properties["SOURCEHUC1"]);                          
           }
           if( dataI.properties["SOURCEHUC1"]=== dataItem["HUC12"]){                     
             dataItem["HUC12OUT"].push(dataI.properties["OUTHUC12"]);
           }
        }
      }                
      if(dataItem["HUC12SOURCE"].length === 0){

      }  
    }                     
}


const setStore = (keyname: string, dataPayload: any) => { 
       //localStorage.setItem(keyname, JSON.stringify(dataPayload));
      setTimeout(() => {
        localForage.setItem(keyname, dataPayload);
      }, 1111);
}

const fetchData = (evaluation: string, dataItem: any) => { 

  let URL = "";
  let riskCategory = RiskCategories.find(x => x.title === evaluation);
  if(evaluation === 'Site Flood Zone Ratings'){
      URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.FEMA_Flood_Hazard_Zones.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?access_token=${settings.maboxKey}`;
      if(['02070011', '02070010', '02070008', '02080110'].indexOf(dataItem["HUC8"]) > -1){
            URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.resFEMA.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?access_token=${settings.maboxKey}`;
      }
  } else if(aquaduct.indexOf(evaluation)>-1){ //aqueductAtlas  AAV4
      URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.AAV4.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?access_token=${settings.maboxKey}`;
  } else if(evaluation === 'Drought Status'){
      URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.US_Daily_Drought_Monitoring.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?access_token=${settings.maboxKey}`;
  } else if(evaluation === 'Located within a Protected Area'){
      URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.WDPA_WDOECM.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?access_token=${settings.maboxKey}`;
  } else if(evaluation === 'Superfund in Close Proximity'){
       //URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.outflows.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?radius=11100&access_token=${settings.maboxKey}`;

       URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.EJScreen_2021.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?access_token=${settings.maboxKey}`;
  } else if(evaluation === 'Site Surface Water Quality Threshold'){     
      if(dataItem["HUC12"]){
        surfaceWater(evaluation, dataItem);
        return;
      }
      URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.HUC12.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?radius=111&access_token=${settings.maboxKey}`;
  } else if(evaluation === 'PFAS Detection in Proximity'){
      URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.outflows.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?radius=11100&access_token=${settings.maboxKey}`;
  } else if(evaluation === 'Wildfire'){
      URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.HistoricalFireBoundaryData.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?radius=11100&access_token=${settings.maboxKey}`;
  } else if(evaluation === 'Climate Change Precipitation Risk'){
      if(dataItem){ // && dataItem["atrisk"]
           evaluateData(evaluation, dataItem, dataItem, riskCategory);
      }
      return;
  } else if(evaluation === 'Watershed Health 303(d)'){

      const huc8info = (dataI: any) => {   
            let fileURL = "/csv/huc8_303d_scores_normed.csv"
            //@ts-ignore
            const data: any[] = parse(fileURL, {
                download: true,
                header: true,
                skipEmptyLines: true,
                step: function(results:any, parser:any) {
                    if(results && results.data){
                       if(results.data.huc8 === dataI.HUC8){
                           let newResult = results.data;
                           newResult["Watershed Health 303(d)"] = results.data.overall_score;               
                           dataI["Watershed Health 303(d)"] = results.data.overall_score;
                           evaluateData(evaluation, dataI, newResult, riskCategory);
                           parser.abort();                          
                        }
                    }
                },
                complete: function(results:any) {           
                }
            });
      }

      if(dataItem){ 
            if(dataItem.HUC8){
              huc8info(dataItem);
            }
      }
      return;  
  } else {
    if(riskCategory && riskCategory.tileset){
        URL = `https://api.mapbox.com/v4/${riskCategory.tileset}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?radius=111&access_token=${settings.maboxKey}`;
    } else {
       return;     
    }
  }

//
  axios.get(URL)
    .then((response) => {
      if(evaluation === 'Terra Evaporation'||evaluation === 'Wildfire'){
        //console.log(response)
      }

      if(response && response.status === 200 && response.data && response.data){
          if(aquaduct.indexOf(evaluation)>-1){
            for(var i = 0;i<aquaduct.length;i++) {    
             setStore("ra_" + aquaduct[i] + '_' + dataItem["Longitude"] + dataItem["Latitude"], response.data.features);          
             //localStorage.setItem("ra_" + aquaduct[i] + '_' + dataItem["Longitude"] + dataItem["Latitude"], JSON.stringify(response.data.features));
            }
            return evaluateData(evaluation, dataItem, response.data.features, riskCategory);
           } else if (evaluation === 'Site Surface Water Quality Threshold') {
             
               if(response.data.features && response.data.features.length>0 && response.data.features[0].properties.HUC12){
                  const huc12 = response.data.features[0].properties.HUC12;
                  dataItem["HUC12"] = huc12;
                  dataItem["HUC12NAME"] = response.data.features[0].properties.NAME;
                  //setStore("ra_" + 'HUC12' + '_' + dataItem["Longitude"] + dataItem["Latitude"], huc12);
                  return surfaceWater(evaluation, dataItem);
              } else {
                console.log('huc12')
              }
          } else if (evaluation === 'PFAS Detection in Proximity')  {   
           
              dataItem["HUC12SOURCE"] = [];
              dataItem["HUC12OUT"] = [];
              dataItem["HUC12SOURCEPFAS"] = 0;
              if(response.data.features && response.data.features.length>0 && response.data.features[0].properties.OUTHUC12){
                if(dataItem["HUC12"]){                   
                  for (let dataI of response.data.features) {
                     if( dataI.properties["OUTHUC12"]=== dataItem["HUC12"]){
                       dataItem["HUC12SOURCE"].push(dataI.properties["SOURCEHUC1"]);                          
                     }
                     if( dataI.properties["SOURCEHUC1"]=== dataItem["HUC12"]){                     
                       dataItem["HUC12OUT"].push(dataI.properties["OUTHUC12"]);
                     }
                  }
                }                
                if(dataItem["HUC12SOURCE"].length === 0){

                }  
              } 


              if(dataItem && dataItem["HUC8"]){  //PFASDW

                let objPFAS = { "HUC12SOURCEPFAS": 0, "HUC12PFAS": 0, "PFASHUC8Detections": 0 }
                    

                agent.Reports.PFA(dataItem["HUC8"]).then(({ body }: any) => {      
                  if(body && body.length > 0 && body!=="Unauthorized"){ //Huc12

                      let fData = [...body].filter((o: any) => o['Result Detection'] === 'PFAS Detected' && o['Huc12'] === dataItem["HUC12"]);
    
                      dataItem["HUC12PFAS"] = fData.length;
                      for(var i = 0;i<dataItem["HUC12SOURCE"].length;i++) {  
                          let fDataf = body.filter((o: any) => o['Result Detection'] === 'PFAS Detected' && o['Huc12'] === dataItem["HUC12SOURCE"][i]);
                          dataItem["HUC12SOURCEPFAS"] = dataItem["HUC12SOURCEPFAS"] + fDataf.length;                         
                      }    
                      
                      let PFASHUC8 = [...body].filter((o: any) => o['Result Detection'] === 'PFAS Detected');
                      let PFASHUC8Detections = PFASHUC8.length;                      
                      let PFASother = [...body].filter((o: any) => o['Result Detection'] !== 'PFAS Detected' && o['Result Detection'] !== 'Not Detected');

                      //localStorage.setItem("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"], JSON.stringify(fData));
                      objPFAS = { "HUC12SOURCEPFAS": dataItem["HUC12SOURCEPFAS"], "HUC12PFAS": dataItem["HUC12PFAS"], "PFASHUC8Detections": PFASHUC8Detections }
                  }

                  agent.Reports.PFASDW(dataItem["HUC8"]).then(({ body }: any) => {                             
                      if(body && body.length > 0 && body!=="Unauthorized"){
                        let newArr = [] as any;
                        for (let dataR of body) {                          
                            if(dataR.data && dataR.data.length>0){                                      
                                newArr = newArr.concat(dataR.data)
                            }
                        }
                        
                        let PFASdw = [...newArr].filter((o: any) => o['Detected'] === 'Yes');
    
                        let PFASdwa = [...newArr].filter((o: any) => o['Detected'] === 'Yes' && o['Huc12'] === dataItem["HUC12"]);
                        let PFASdwsource = 0;  
                        for(var i = 0;i<dataItem["HUC12SOURCE"].length;i++) {  
                            let fDatafa = [...newArr].filter((o: any) => o['Detected'] === 'Yes' && o['Huc12'] === dataItem["HUC12SOURCE"][i]);
                            PFASdwsource = PFASdwsource + fDatafa.length;                         
                        }   
                        objPFAS["HUC12SOURCEPFAS"] = objPFAS["HUC12SOURCEPFAS"] + PFASdwsource;
                        objPFAS["HUC12PFAS"] = objPFAS["HUC12PFAS"] + PFASdwa.length;
                        objPFAS["PFASHUC8Detections"] = objPFAS["PFASHUC8Detections"] + PFASdw.length;
                      }
                      setStore("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"], objPFAS); 
                      return evaluateData(evaluation, dataItem, objPFAS, riskCategory );
                      
                      return;
                  })            
                  .finally(() => {
                      setStore("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"], objPFAS);
                      return evaluateData(evaluation, dataItem, objPFAS, riskCategory );
                       
                  } )
                  .catch(error => console.log(error)); 

                }); 
              }
              return;

          }  else if (evaluation === 'Superfund in Close Proximity')  {   
              //URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.EPA_Superfund_NPL.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?radius=10000&access_token=${settings.maboxKey}`;
              
             //URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.EJScreen_2021.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?access_token=${settings.maboxKey}`;
  
              setStore("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"], response.data.features); 
              return evaluateData(evaluation, dataItem, response.data.features, riskCategory);
          } else {   
            setStore("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"], response.data.features); 
            //localStorage.setItem("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"], JSON.stringify(response.data.features));
            return evaluateData(evaluation, dataItem, response.data.features, riskCategory);
          }         
      } else {
        dataItem["queryapi"] = response;
        dataItem["queryev"] = evaluation;
        return dataItem;
      }
  });
}


async function fetchDataAsync(evaluation: string, dataItem: any){ 

  let URL = "";
  let riskCategory = RiskCategories.find(x => x.title === evaluation);
  if(evaluation === 'Site Flood Zone Ratings'){
      URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.FEMA_Flood_Hazard_Zones.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?access_token=${settings.maboxKey}`;
      if(['02070011', '02070010', '02070008', '02080110'].indexOf(dataItem["HUC8"]) > -1){
            URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.resFEMA.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?access_token=${settings.maboxKey}`;
      }
  } else if(aquaduct.indexOf(evaluation)>-1){ //aqueductAtlas  AAV4
      URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.AAV4.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?access_token=${settings.maboxKey}`;
  } else if(evaluation === 'Drought Status'){
      URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.US_Daily_Drought_Monitoring.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?access_token=${settings.maboxKey}`;
  } else if(evaluation === 'Located within a Protected Area'){
      URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.WDPA_WDOECM.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?access_token=${settings.maboxKey}`;
  } else if(evaluation === 'Superfund in Close Proximity'){
      URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.EJScreen_2021.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?access_token=${settings.maboxKey}`;
  } else if(evaluation === 'Site Surface Water Quality Threshold'){     
      if(dataItem["HUC12"]){
        surfaceWater(evaluation, dataItem);
        return;
      }
      URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.HUC12.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?radius=111&access_token=${settings.maboxKey}`;
  } else if(evaluation === 'PFAS Detection in Proximity'){
      URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.outflows.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?radius=11100&access_token=${settings.maboxKey}`;
  } else if(evaluation === 'Wildfire'){
      URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.HistoricalFireBoundaryData.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?radius=11100&access_token=${settings.maboxKey}`;
  } else if(evaluation === 'Climate Change Precipitation Risk'){
      if(dataItem){ // && dataItem["atrisk"]
           evaluateData(evaluation, dataItem, dataItem, riskCategory);
      }
      return;
  } else if(evaluation === 'Watershed Health 303(d)'){

    

      const huc8info = (dataI: any) => {   
            let fileURL = "/csv/huc8_303d_scores_normed.csv"
            //@ts-ignore
            const data: any[] = parse(fileURL, {
                download: true,
                header: true,
                skipEmptyLines: true,
                step: function(results:any, parser:any) {
                    if(results && results.data){
                       if(results.data.huc8 === dataI.HUC8){
                           let newResult = results.data;
                           newResult["Watershed Health 303(d)"] = results.data.overall_score;               
                           dataI["Watershed Health 303(d)"] = results.data.overall_score;
                           evaluateData(evaluation, dataI, newResult, riskCategory);
                           parser.abort();                          
                        }
                    }
                },
                complete: function(results:any) {           
                }
            });
      }

      if(dataItem){ 
            if(dataItem.HUC8){
              huc8info(dataItem);
            }
      }
      return;  
  } else {
    if(riskCategory && riskCategory.tileset){
        URL = `https://api.mapbox.com/v4/${riskCategory.tileset}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?radius=111&access_token=${settings.maboxKey}`;
    } else {
       return;     
    }
  }


  axios.get(URL)
    .then((response) => {
      if(evaluation === 'Terra Evaporation'||evaluation === 'Wildfire'){
        //console.log(response)
      }

      if(response.status === 200 && response.data && response.data){
          if(aquaduct.indexOf(evaluation)>-1){
            for(var i = 0;i<aquaduct.length;i++) {    
             setStore("ra_" + aquaduct[i] + '_' + dataItem["Longitude"] + dataItem["Latitude"], response.data.features);          
             //localStorage.setItem("ra_" + aquaduct[i] + '_' + dataItem["Longitude"] + dataItem["Latitude"], JSON.stringify(response.data.features));
            }
            return evaluateData(evaluation, dataItem, response.data.features, riskCategory);
           } else if (evaluation === 'Site Surface Water Quality Threshold') {
             
               if(response.data.features && response.data.features.length>0 && response.data.features[0].properties.HUC12){
                  const huc12 = response.data.features[0].properties.HUC12;
                  dataItem["HUC12"] = huc12;
                  dataItem["HUC12NAME"] = response.data.features[0].properties.NAME;
                  //setStore("ra_" + 'HUC12' + '_' + dataItem["Longitude"] + dataItem["Latitude"], huc12);
                  return surfaceWater(evaluation, dataItem);
              } else {
                console.log('huc12')
              }
          } else if (evaluation === 'PFAS Detection in Proximity')  {   
           
              dataItem["HUC12SOURCE"] = [];
              dataItem["HUC12OUT"] = [];
              dataItem["HUC12SOURCEPFAS"] = 0;
              if(response.data.features && response.data.features.length>0 && response.data.features[0].properties.OUTHUC12){
                if(dataItem["HUC12"]){                   
                  for (let dataI of response.data.features) {
                     if( dataI.properties["OUTHUC12"]=== dataItem["HUC12"]){
                       dataItem["HUC12SOURCE"].push(dataI.properties["SOURCEHUC1"]);                          
                     }
                     if( dataI.properties["SOURCEHUC1"]=== dataItem["HUC12"]){                     
                       dataItem["HUC12OUT"].push(dataI.properties["OUTHUC12"]);
                     }
                  }
                }                
                if(dataItem["HUC12SOURCE"].length === 0){

                }  
              } 
              if(dataItem && dataItem["HUC8"]){  //PFASDW

                let objPFAS = { "HUC12SOURCEPFAS": 0, "HUC12PFAS": 0, "PFASHUC8Detections": 0 }
                    

                agent.Reports.PFA(dataItem["HUC8"]).then(({ body }: any) => {      
                  if(body && body.length > 0 && body!=="Unauthorized"){ //Huc12

                      let fData = [...body].filter((o: any) => o['Result Detection'] === 'PFAS Detected' && o['Huc12'] === dataItem["HUC12"]);
    
                      dataItem["HUC12PFAS"] = fData.length;
                      for(var i = 0;i<dataItem["HUC12SOURCE"].length;i++) {  
                          let fDataf = body.filter((o: any) => o['Result Detection'] === 'PFAS Detected' && o['Huc12'] === dataItem["HUC12SOURCE"][i]);
                          dataItem["HUC12SOURCEPFAS"] = dataItem["HUC12SOURCEPFAS"] + fDataf.length;                         
                      }    
                      
                      let PFASHUC8 = [...body].filter((o: any) => o['Result Detection'] === 'PFAS Detected');
                      let PFASHUC8Detections = PFASHUC8.length;                      
                      let PFASother = [...body].filter((o: any) => o['Result Detection'] !== 'PFAS Detected' && o['Result Detection'] !== 'Not Detected');

                      //localStorage.setItem("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"], JSON.stringify(fData));
                      objPFAS = { "HUC12SOURCEPFAS": dataItem["HUC12SOURCEPFAS"], "HUC12PFAS": dataItem["HUC12PFAS"], "PFASHUC8Detections": PFASHUC8Detections }
                  }

                  agent.Reports.PFASDW(dataItem["HUC8"]).then(({ body }: any) => {                             
                      if(body && body.length > 0 && body!=="Unauthorized"){
                        let newArr = [] as any;
                        for (let dataR of body) {                          
                            if(dataR.data && dataR.data.length>0){                                      
                                newArr = newArr.concat(dataR.data)
                            }
                        }
                        
                        let PFASdw = [...newArr].filter((o: any) => o['Detected'] === 'Yes');
    
                        let PFASdwa = [...newArr].filter((o: any) => o['Detected'] === 'Yes' && o['Huc12'] === dataItem["HUC12"]);
                        let PFASdwsource = 0;  
                        for(var i = 0;i<dataItem["HUC12SOURCE"].length;i++) {  
                            let fDatafa = [...newArr].filter((o: any) => o['Detected'] === 'Yes' && o['Huc12'] === dataItem["HUC12SOURCE"][i]);
                            PFASdwsource = PFASdwsource + fDatafa.length;                         
                        }   
                        objPFAS["HUC12SOURCEPFAS"] = objPFAS["HUC12SOURCEPFAS"] + PFASdwsource;
                        objPFAS["HUC12PFAS"] = objPFAS["HUC12PFAS"] + PFASdwa.length;
                        objPFAS["PFASHUC8Detections"] = objPFAS["PFASHUC8Detections"] + PFASdw.length;
                      }
                      setStore("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"], objPFAS); 
                      return evaluateData(evaluation, dataItem, objPFAS, riskCategory );
                      
                      return;
                  })            
                  .finally(() => {
                      setStore("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"], objPFAS);
                      return evaluateData(evaluation, dataItem, objPFAS, riskCategory );
                       
                  } )
                  .catch(error => console.log(error)); 

                   
/*                  } else {
                    let objPFAS = { "HUC12SOURCEPFAS": 0, "HUC12PFAS": 0 }
                    evaluateData(evaluation, dataItem, objPFAS, riskCategory );                     
                  }*/
                }); 
              }
              return;

          }  else if (evaluation === 'Superfund in Close Proximity')  {   
              //URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.EPA_Superfund_NPL.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?radius=10000&access_token=${settings.maboxKey}`;
              
              setStore("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"], response.data.features); 
              return evaluateData(evaluation, dataItem, response.data.features, riskCategory);
          } else {   
            setStore("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"], response.data.features); 
            //localStorage.setItem("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"], JSON.stringify(response.data.features));
            return evaluateData(evaluation, dataItem, response.data.features, riskCategory);
          }         
      } else {
        dataItem["queryapi"] = response;
        dataItem["queryev"] = evaluation;
        return dataItem;
      }
  });
}


const getData = (evaluation: string, dataItem: any) => { 
  let riskCategory = RiskCategories.find(x => x.title === evaluation);
/*  if(evaluation === 'PFAS Detection in Proximity'){
    fetchData(evaluation, dataItem);
  }*/

  /*  if(evaluation === 'Site Surface Water Quality Threshold'){
    fetchData(evaluation, dataItem);
  } */

  if(evaluation === 'Water Management'){ //||evaluation === 'Wildfire'
    fetchData(evaluation, dataItem);
  }



  const clearFactor = '9'; //Change to clear cache 
  const clearRisk = localStorage.getItem("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"] + '_clearRisk');
  if(clearRisk && clearRisk===clearFactor){ 
    localForage.getItem("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"], function(err, value) {
      if(value && updateEvaluation.indexOf(evaluation)<0){
        return evaluateData(evaluation, dataItem, value, riskCategory);
      } else if(value && updateEvaluation.indexOf(evaluation)>-1) {
        evaluateData(evaluation, dataItem, value, riskCategory);
        return fetchData(evaluation, dataItem);
      } else { 
        return fetchData(evaluation, dataItem);
      }
    });
  } else {
      localForage.removeItem("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"]).then(function() {
      localStorage.setItem("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"] + "_clearRisk", clearFactor);
      return fetchData(evaluation, dataItem);
    }).catch(function(err) {
      console.log(err);
      return fetchData(evaluation, dataItem);
    });
    //console.log('unassigned')
  }
}

async function getDataAsync(evaluation: string, dataItem: any){ 
  let riskCategory = RiskCategories.find(x => x.title === evaluation);
/*  if(evaluation === 'PFAS Detection in Proximity'){
    fetchData(evaluation, dataItem);
  }*/

  /*  if(evaluation === 'Site Surface Water Quality Threshold'){
    fetchData(evaluation, dataItem);
  } */

  if(evaluation === 'Water Management'){ //||evaluation === 'Wildfire'
    fetchData(evaluation, dataItem);
  }



  const clearFactor = '9'; //Change to clear cache 
  const clearRisk = localStorage.getItem("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"] + '_clearRisk');
  if(clearRisk && clearRisk===clearFactor){ 
    return await localForage.getItem("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"], function(err, value) {
      if(value && updateEvaluation.indexOf(evaluation)<0){
        return evaluateData(evaluation, dataItem, value, riskCategory);
      } else if(value && updateEvaluation.indexOf(evaluation)>-1) {
        evaluateData(evaluation, dataItem, value, riskCategory);
        return fetchDataAsync(evaluation, dataItem);
      } else { 
        return fetchDataAsync(evaluation, dataItem);
      }
    }).then(data => {  
              return dataItem;
          });;
  } else {
      localForage.removeItem("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"]).then(function() {
      localStorage.setItem("ra_" + evaluation + '_' + dataItem["Longitude"] + dataItem["Latitude"] + "_clearRisk", clearFactor);
      return fetchDataAsync(evaluation, dataItem);
    }).catch(function(err) {
      console.log(err);
      return fetchDataAsync(evaluation, dataItem);
    });
    //console.log('unassigned')
  }
}


export const setSurfaceWaterDataEvaluation = (data: any) => { 
  surfaceWaterData = data;
}

export const getHUC12 = (dataItem: any, callback?:any) => { 
  if(dataItem){
    //if(!dataItem["HUC12"]){
      localForage.getItem("ra_" + 'HUC12OBJ' + '_' + dataItem["Longitude"] + dataItem["Latitude"], function(err, value) {
        if(err){
          //console.log(err)
        }

        if(value){
          
          //@ts-ignore
          const huc = JSON.parse(value);
          dataItem["HUC12"] = huc["HUC12"];
          dataItem["HUC12NAME"] = huc["HUC12NAME"];
          if(!dataItem["HUC8"]){
            dataItem["HUC8"] = dataItem["HUC12"].substr(0,8);
          }
          if(callback){
            callback(dataItem)
          }

        } else {

            const  URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.HUC12.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?access_token=${settings.maboxKey}`;
            axios.get(URL)
              .then((response) => {
                if(response.status === 200 && response.data && response.data){
                    if(response.data.features && response.data.features.length>0 && response.data.features[0].properties.HUC12){
                        const huc12 = response.data.features[0].properties.HUC12;
                        dataItem["HUC12"] = huc12;
                        dataItem["HUC12NAME"] = response.data.features[0].properties.NAME;
                        if(!dataItem["HUC8"]){
                          dataItem["HUC8"] = dataItem["HUC12"].substr(0,8);
                        }

                        const huc = JSON.stringify({"HUC12" : dataItem["HUC12"], "HUC12NAME": dataItem["HUC12NAME"] })                       
                        setStore("ra_" + 'HUC12OBJ' + '_' + dataItem["Longitude"] + dataItem["Latitude"], huc);

                        if(callback){
                            callback(dataItem)
                        }
                    }
                }
            })
        }

      });
    //}
  }
}

export const evaluation = async(evaluation: string, dataItem: any) => { 
  //console.log(evaluation)
  if(evaluation && dataItem){
    //@ts-ignore
    if(!dataItem["ra_"+ evaluation] || !dataItem[evaluation]  || !dataItem[evaluation + 'result']){
      //console.log(evaluation)
      getData(evaluation, dataItem);
    }        
  }
}

export const RiskEvaluation = (evaluation: string, dataItem: any) => { 
  if(evaluation && dataItem){
    // if not cached, process 
    //@ts-ignore
    if(( !dataItem[evaluation]  || !dataItem[evaluation + 'result'])){ //||(evaluation==='Wildfire') !dataItem["ra_"+ evaluation] ||
    
      if(!dataItem[evaluation + 'retries']){
        dataItem[evaluation + 'retries'] = 0;
      }
       dataItem[evaluation + 'retries'] = dataItem[evaluation + 'retries'] + 1;
       if(dataItem[evaluation + 'retries']<7){
         return getData(evaluation, dataItem);
       } else {
         return null;
       }
       
    }        
  }
}

export async function RiskEvaluationAsync(evaluation: string, dataItem: any){ 
  if(evaluation && dataItem){
    // if not cached, process 
    //@ts-ignore
    if(( !dataItem[evaluation] || !dataItem[evaluation + 'result'])){ //||(evaluation==='Wildfire') !dataItem["ra_"+ evaluation] ||
    
      if(!dataItem[evaluation + 'retries']){
        dataItem[evaluation + 'retries'] = 0;
      }
       dataItem[evaluation + 'retries'] = dataItem[evaluation + 'retries'] + 1;
       
       if(dataItem[evaluation + 'retries']<7){
         return await getDataAsync(evaluation, dataItem);
       } else {
         return null;
       }
    }        
  }
}