import { useState, useEffect } from 'react';
import { Marker, Popup } from 'react-map-gl';
import useSupercluster from 'use-supercluster';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @ts-ignore
import { faSquareXTwitter } from '@fortawesome/free-brands-svg-icons';
import './tweet-list.scss'

const TweetList = (props: any) => {

    const {
        global,
        tweets,
        bounds,
        viewport,
        officialTweetsSelected,
        unofficialTweetsSelected,
        setOfficialTweetsPresent,
        setUnOfficialTweetsPresent
    } = props;

    
    const [current, setCurrent] = useState<any | null>(null)
    const [tweetList, setTweetList] = useState(tweets)
    const [page, setPage] = useState(0)
    
    const official = tweets && tweets.filter((ele: any) => ele.official === true) ? true : false
    const unofficial = tweets && tweets.filter((ele: any) => ele.official === false) ? true : false
    console.log(tweetList)

    // @ts-ignore
    useEffect(() => {

        if (!officialTweetsSelected) {
            setTweetList(tweets.filter((ele: any) => ele.official === false))
        } else if (!unofficialTweetsSelected) {
            setTweetList(tweets.filter((ele: any) => ele.official === true))
        } else {
            setTweetList(tweets)
        }

        if (!unofficialTweetsSelected && !officialTweetsSelected) {
            setTweetList([])
        }

    }, [officialTweetsSelected, unofficialTweetsSelected])

    useEffect(() => {
        setOfficialTweetsPresent(official)
        setUnOfficialTweetsPresent(unofficial)
    }, [official, unofficial])


    const points = tweetList && tweetList.map((tweet: any, index: number) => ({
        type: "Feature",
        properties: {
            cluster: false,
            index,
            id: tweet.tweet_id,
            official: tweet.official
        },
        geometry: {
            type: "Point",
            coordinates: [tweet.Longitude, tweet.Latitude],
        }
    }))

    const { clusters } = useSupercluster({
        points,
        bounds,
        zoom: viewport.zoom,
        options: { radius: 45, maxZoom: 10 }
    })


    const renderTweets = () => {
        return clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates
            const { cluster: isCluster, point_count: pointCount, index, official } = cluster.properties;

            if (isCluster) {
                return (
                    <Marker
                        key={cluster.id}
                        latitude={latitude}
                        longitude={longitude}
                    >
                        <div
                            className="cluster-marker"
                            style={{
                                width: `${36 + (pointCount / points.length) * 50}px`,
                                height: `${36 + (pointCount / points.length) * 50}px`,
                                cursor: "pointer"
                            }}
                        >
                            {pointCount}
                        </div>
                    </Marker>
                )
            }

            return (
                <Marker
                    key={cluster.properties.id}
                    latitude={latitude}
                    longitude={longitude}
                    onClick={(e) => {
                        e.originalEvent.stopPropagation();
                        setPage(0)
                        setCurrent(tweetList[index])
                        !official && global.notify('These Tweets come from an unofficial source and may contain explicit content', 'warn')
                    }}
                >
                    <FontAwesomeIcon className={`tweet-x ${!official ? 'unofficial' : ''}`} icon={faSquareXTwitter} />
                </Marker>
            );
        })
    }

    const renderPopup = () => {

        const { tweet_info, official } = current
        const pages = tweet_info.length
        let target = tweet_info[page]


        const parseDate = (input: any) => {

            const month = [
                "Jan", "Feb", "Mar",
                "Apr", "May", "Jun",
                "July", "Aug", "Sep",
                "Oct", "Nov", "Dec"
            ]

            const splitDate = input.created_at.split('-')
            const timeArray = splitDate[2].split('T')
            const time = timeArray[1].split(':')

            const renderTime = () => {
                return time[0] > 12 ? `${time[0] - 12}:${time[1]} PM` : `${time[0]}:${time[1]} AM`
            }

            return `${renderTime()} - ${month[parseInt(splitDate[1]) - 1]}, ${timeArray[0]} ${splitDate[0]}`
        }

        return (
            <Popup longitude={Number(current.Longitude)} latitude={Number(current.Latitude)}
                className={'sc-popup-main'}
                onClose={() => setCurrent(null)}>
                <div style={{
                    backgroundColor: !official ? '#ffffffff' : '#18244c',
                    color: !official ? '#00000000' : '#ffffffff'
                }} className="tweet-card">
                    <div className="tweet-top">
                        <img
                            height="36px"
                            width="36px"
                            className="avatar"
                            src={`https://unavatar.io/twitter/${current.UserName}`}
                            alt="avatar"
                            style={{ borderRadius: "50%" }}
                        />
                        <div className="tweet-header">
                            <h4 className="tweet-name" style={{ color: !official ? 'black' : 'white' }} >{current.Name}</h4>
                            <h4 className="tweet-handle">{<a href={
                                current.City_Twitter_Account === 'nan' ? `https://twitter.com/${current.UserName}` : current.City_Twitter_Account
                            } target="_blank">@{current.UserName}</a>}</h4>
                            {<h4 className="tweet-date">{target.created_at && parseDate(target)}</h4>}
                        </div>
                    </div>
                    <div className="tweet-bottom">
                        <a className="tweet-link" href={
                            current.City_Twitter_Account !== 'nan' ?
                                `${current.City_Twitter_Account}/status/${target.tweet_id}`
                                :
                                `https://twitter.com/${current.UserName}/status/${target.tweet_id}`
                        } target="_blank">
                            <p style={{ color: !official ? 'black' : 'white' }}>{target.tweet_text}</p>
                        </a>
                    </div>
                </div>
                {pages > 1 && (
                    <div className="nav-box">
                        <button
                            className="tweet-paginate-btn"
                            disabled={page === 0 ? true : false}
                            onClick={() => setPage(prev => prev - 1)}
                            style={{ color: !official ? 'black' : 'white' }}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <p style={{ color: !official ? 'black' : 'white' }}>{page + 1} / {pages}</p>
                        <button
                            className="tweet-paginate-btn"
                            disabled={page + 1 === pages ? true : false}
                            onClick={() => setPage(prev => prev + 1)}
                            style={{ color: !official ? 'black' : 'white' }}
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                )}
            </Popup>
        )
    }

    return (
        <>
            {clusters && renderTweets()}
            {current && renderPopup()}
        </>
    )
}

export default TweetList