import { Source, Layer, Popup, FillLayer, CircleLayer, LineLayer } from 'react-map-gl';
import settings from "./../../settings.json";
import { useState, useContext, useEffect, useMemo } from 'react';
import { RefContext } from "./../../RefContext";
import { PointContext } from "./../../PointContext";
import { ActivePopupContext } from './../../ActivePopupContext';
import { v4 as uuidv4 } from 'uuid';
import { Scrollbars } from 'react-custom-scrollbars-2';

const DroughtMonitoring = ({ id, url, opacity, global }: any) => {
  let rangeSel = "nws";  
  let categorySel = `20`;// `14`;  
  let _currentUrl = `https://hazards.fema.gov/arcgis/rest/services/FIRMette/NFHLREST_FIRMette/MapServer/export?bbox={bbox-epsg-3857}&dpi=96&transparent=true&format=png32&layers=show:0,2,4,5,7,8,9,12,13,14,15,18,19,20,21&bboxSR=102100&imageSR=102100&f=image`
    _currentUrl = `https://www.ncei.noaa.gov/pub/data/nidis/geojson/us/dgt/nws-dgt-pnts.geojson`
   // _currentUrl = `https://www.ncei.noaa.gov/pub/data/nidis/geojson/us/dgt/nws-dgt.geojson`
  let _imageUrl =  `https://droughtmonitor.unl.edu/data/json/usdm_current.json`
   _imageUrl =  `https://www.ncei.noaa.gov/pub/data/nidis/geojson/na/nadm/NADM-current.geojson`
  // _imageUrl =  `https://www.ncei.noaa.gov/pub/data/nidis/geojson/us/usdo/USDO-Monthly.geojson`


  const [imageUrl, setImageUrl] = useState<any>(_imageUrl);
  const [currentUrl, setCurrentUrl] = useState<any>(_currentUrl);
  const [currentRange, setCurrentRange] = useState<any>(categorySel);

  const [popupInfo, setPopupInfo] = useState<any>([]);
  const { currentRef } = useContext(RefContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);
  // @ts-ignore
  const mapInstance = currentRef && currentRef?.current;

  const [category, setCategory] = useState<any>('observed');
  const [categoryMatch, setCategoryMatch] = useState<any>([
                  'match',
                      ['get', 'DROUGHTCAT'], 
                      'd0', `#f4fb18`,
                      'd1', `#f99010`,
                      'd2', `#ea631f`,
                      'd3', `#fa3f19`,
                      'd4', `#ab0707`,
                      "transparent",
              ] );
  //https://mesonet.agron.iastate.edu/wx/afos/p.php?pil=AFMDMX&e=202411231716


  //https://www.ncei.noaa.gov/pub/data/nidis/geojson/us/usdm/USDM-current.geojson

  //https://www.ncei.noaa.gov/pub/data/nidis/geojson/us/usdo/USDO-Monthly.geojson


  //https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/json/v1/us/usdo/USDO-Seasonal-current.json?time=1732074300000

  //https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/json/v1/us/usdm/usdm_current.json


  //https://www.ncei.noaa.gov/pub/data/nidis/geojson/us/dgt/nws-dgt-pnts.geojson
/*



https://services5.arcgis.com/0OTVzJS4K09zlixn/arcgis/rest/services/NADM_Current/FeatureServer/0/query?f=json&returnGeometry=false&spatialRel=esriSpatialRelIntersects&outFields=*&outStatistics=%5B%7B%22statisticType%22%3A%22exceedslimit%22%2C%22outStatisticFieldName%22%3A%22exceedslimit%22%2C%22maxPointCount%22%3A4000%2C%22maxRecordCount%22%3A2000%2C%22maxVertexCount%22%3A250000%7D%5D
https://www.ncei.noaa.gov/pub/data/nidis/tile/gpcc-di-1d-01m/3/2/2.png

https://www.ncei.noaa.gov/pub/data/nidis/tile/gpcc-di-1d-01m/3/1/3.png
https://edo.jrc.ec.europa.eu/edov2/php/ows.php?SERVICE=WMS&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=TRUE&STYLES=&VERSION=1.1.1&LAYERS=Combined%20Drought%20Indicator&WIDTH=576&HEIGHT=456&SRS=EPSG:900913&BBOX=-13759811.546770856,546740.760556766,-2488713.103954903,9469693.69445273

https://www.ncei.noaa.gov/pub/data/nidis/tile/ciesin-pop-1km/6/18/27.png

https://www.ncei.noaa.gov/pub/data/nidis/geojson/us/dgt/nws-dgt.geojson

*/

/*

  useEffect(() => {
    if(global.FloodInd){
      let _imageUrl = `https://www.ncei.noaa.gov/pub/data/nidis/geojson/us/dgt/nws-dgt.geojson`
       fetch(_imageUrl)
          .then((response) => response.text())
          .then((responseText) => {            
            if(responseText){
              let responseItem = JSON.parse(responseText);
            
            }
          });
  
    }    
  }, []);*/



    useMemo(() => {
        const handleClick = (event: any) => { 
            const queryObject = mapInstance.queryRenderedFeatures(event.point);      
            let layerObject = [] as any; 
            for (let i = 0; i < queryObject.length + 1; i++) {
              if (queryObject[i] && queryObject[i].layer && queryObject[i].layer.id === 'drought-layer') {
                queryObject[i].properties["centroid_longitude"] = event.lngLat.lng;
                queryObject[i].properties["centroid_latitude"] = event.lngLat.lat
                layerObject.push(queryObject[i].properties);
              }
            }
       
            if (layerObject && layerObject.length > 0) {
              if (layerObject[0]["centroid_longitude"] && !isNaN(layerObject[0]["centroid_longitude"]) && layerObject[0]["centroid_latitude"] && !isNaN(layerObject[0]["centroid_latitude"])) {
                setPopupInfo(layerObject);
                setActivePopup({
                  id,
                  content: renderPopup(layerObject)
                });
              }
            }
        };

        if (mapInstance) {
            mapInstance.on('click', handleClick);
        }

        return () => {
            if (mapInstance) {
                mapInstance.off('click', handleClick);
            }
        };
    }, [mapInstance]);


  useEffect(() => {
    if(global.layerMode && global.layerMode.layer==='drought'){
      setCategory(global.layerMode.category)
    }
  }, [global.layerMode]);

  useEffect(() => {
    if(category==='observed'){
      setImageUrl(`https://www.ncei.noaa.gov/pub/data/nidis/geojson/na/nadm/NADM-current.geojson`)
      setCategoryMatch([
                  'match',
                      ['get', 'DROUGHTCAT'], 
                      'd0', `#f4fb18`,
                      'd1', `#f99010`,
                      'd2', `#ea631f`,
                      'd3', `#fa3f19`,
                      'd4', `#ab0707`,
                      "transparent",
              ])
    } else if(category==='normal'){
            setCategoryMatch([
                  'match',
                      ['get', 'Outlook'], 
                      'No_Drought', `rgba(255,255,255,.8)`,
                      'Development', `#FFDE63`,
                      'Improvement', `#DED2BC`,
                      'Persistence', `#9B634A`,
                      'Removal', `#B2AD69`,
                      "transparent",
              ])
      setImageUrl(`https://www.ncei.noaa.gov/pub/data/nidis/geojson/us/usdo/USDO-Monthly.geojson`)
    } else if(category==='departure'){
            setCategoryMatch([
                  'match',
                      ['get', 'Outlook'], 
                      'No_Drought', `rgba(255,255,255,.8)`,
                      'Development', `#FFDE63`,
                      'Improvement', `#DED2BC`,
                      'Persistence', `#9B634A`,
                      'Removal', `#B2AD69`,
                      "transparent",
              ])
      setImageUrl(`https://www.ncei.noaa.gov/pub/data/nidis/geojson/us/usdo/USDO-Seasonal.geojson`)
    } else {
            setCategoryMatch([
                  'match',
                      ['get', 'DROUGHTCAT'], 
                      'd0', `#f4fb18`,
                      'd1', `#f99010`,
                      'd2', `#ea631f`,
                      'd3', `#fa3f19`,
                      'd4', `#ab0707`,
                      "transparent",
              ])
      setImageUrl(`https://www.ncei.noaa.gov/pub/data/nidis/geojson/na/nadm/NADM-current.geojson`)
    }
  }, [category]);

  const renderThumb = (style: any) => {
    const thumbStyle = {
      backgroundColor: 'white'
    };
    return (
      <div style={{ ...style, ...thumbStyle }} />
    );
  }

  let importOrder = [
    "Hazard_Type",
    "Hazard_Abbreviation",
    "URL",
    "Alert_Type",
    "Forecast_Office",
    "Message_Type",
    "Event",
    "Issuance",
    "Expiration",
    "Onset",
    "Ends",
    "GmlID",
    "objectid",
    "Message_ID",
    "GIS_File_Date",
    "GIS_Ingest_Date",
    "centroid_longitude",
    "centroid_latitude"
  ]

  const sortByObject: any = importOrder
    .reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});

  const renderFields = (objectMapArray: any) => {
    return objectMapArray.map((objectMap: any, index: any) => {
      let sortedFields = Object.entries(objectMap).sort((a: any, b: any) => sortByObject[a[0]] - sortByObject[b[0]]);

      return sortedFields.map((element: any, index: any) => {
        const skipFields = ['layerName', 'counts', 'results', 'radius', 'Message_ID', 'GmlID', 'objectid', 'STATEMENT'];
        if (skipFields.indexOf(element[0]) > -1) {
          return null;
        } else if (element[0] === 'URL') {
          return (
            <p className="GD-p-comid" key={uuidv4()}>
              <span style={{ opacity: .9 }}>{'URL:'}</span>
              <span style={{ marginLeft: 10, fontSize: '1.3em', opacity: 1 }}>
                <a href={element[1]} target={'_blank'}>Go to Drought Statement</a>
              </span>
            </p>
          )
        } else if (element[0] === 'Forecast_Office' && element[1] && element[1].length > 2) {
          return (
            <p className="GD-p-comid" key={uuidv4()}>
              <span style={{ opacity: .9 }}>{'Forecast_Office'}:</span>
              <span style={{ marginLeft: 10, fontSize: '1.3em', opacity: 1 }}>
                <a style={{color: '#68a6ea'}} href={`https://www.weather.gov/${element[1].substr(1, 10)}`} target={'_blank'}>Navigate to {element[1]}</a>
              </span>
            </p>
          )
        } else if (element[0] === 'centroid_latitude') {
          return (
            <div key={uuidv4()} >
              <p className="GD-p-comid" key={uuidv4()} >
                <span style={{ opacity: .9 }}>{element[0]}:</span>
                <span style={{ marginLeft: 10, fontSize: '1.3em', opacity: 1 }}>    {element[1]} </span>
              </p>
              <hr key={uuidv4()} style={{ marginBottom: 20 }} />
            </div>
          )
        } else {
          return (
            <p className="GD-p-comid" key={uuidv4()}>
              <span style={{ opacity: .9 }}>{element[0]}:</span>
              <span style={{ marginLeft: 10, fontSize: '1.3em', opacity: 1 }}>    {element[1]} </span>
            </p>
          )
        }
      })
    })
  }

  const renderPopup = (info: any) => {
    return (
      <Popup
        longitude={Number(info[0].centroid_longitude)}
        latitude={Number(info[0].centroid_latitude)}
        closeButton={true}
        closeOnClick={true}
        className="wqp"
        onClose={() => {
          setPopupInfo(null);
          setActivePopup({ id: null, content: null });
        }}>
        <div className="popup-container" style={{ paddingBottom: 5, lineHeight: '1px' }}>
          <Scrollbars renderThumbVertical={renderThumb} className="summary-scroll" style={{ width: '100%', height: '100%' }}>
            {renderFields(info)}
            <div style={{ marginBottom: 20 }} />
          </Scrollbars>
        </div>
      </Popup>
    );
  }


  const NSGLayerConfig: CircleLayer = {
    id: `${id}-layer`,
    type: "circle",
    filter:["!=", "STATEMENT", "No drought statement available for this WFO."],
    paint: {
      "circle-stroke-color": "white", 
      "circle-stroke-width": 2,
      "circle-radius": 7,
      "circle-color": [
        "match",
            ['get', 'DGT'], 
            '0', `green`,
            'Development', `#f99010`,
            'Improvement', `#ea631f`,
            'Persistence', `#fa3f19`,
            '1', `blue`,
            "transparent",
      ],
      "circle-opacity": opacity, 
      "circle-stroke-opacity": opacity 
    }
  };



  return (
    <div>
      <Source
        id={`${id}-layerw`}
        key={`${id}-layerw`}
        type="geojson"// @ts-ignore
        data={imageUrl}
      >   
        <Layer
          paint={{
            "fill-opacity": opacity,
            "fill-color": categoryMatch       
          }}
          beforeId={settings.defaultBeforeId}
          type="fill" id={`${id}-layerfll`}
          source={`${id}-layerw`}
        />
       </Source>

       <Source id={`${id}-layer`} type="geojson" data={currentUrl}>
          <Layer {...NSGLayerConfig} layout={(category==='percent') ? {"visibility": "visible"} : {"visibility": "none"}} />
       </Source>  
       {activePopup && activePopup.id === id && activePopup.content}
    </div>
  )
}

export default DroughtMonitoring