import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { Button } from 'react-bootstrap'
import hideMenuRightIcon from '../assets/hide-right-menu-icon.svg';
import showMenuRightIcon from '../assets/show-right-menu-icon.svg';
import moment from 'moment';
import './right-header-styles.scss'

function RightHeader(props: any) {
    const { global, button, otherButton } = props;
      const [date, setDate] = useState(
        moment().format('MMMM DD YYYY')
      );
      const [time, setTime] = useState(
        moment().format('hh:mm.ss')
      );

    useEffect(() => {
        const interval = setInterval(() => {
            setDate(moment().format('MMMM DD YYYY'));
            setTime(moment().format('hh:mm.ss'));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    

    const onClickMenu = () => {
        if (global.MenuRightOpen) global.SetMenuRightOpen(false);
        else global.SetMenuRightOpen(true);
    };

    const onClickMenuTwo = () => {
        global.setGoogleMenuOpen(!global.googleMenuOpen)
        if (global.MenuRightOpen) global.SetMenuRightOpen(false);
        else global.SetMenuRightOpen(true);
    };

    return (
        <div className='right-header-component'>
            {button && (
                <Button className='btn-right-menu-component' onClick={onClickMenu}>
                    <img
                        src={!global.MenuRightOpen ? showMenuRightIcon : hideMenuRightIcon}
                    />
                </Button>
            )}
            {otherButton && (
                <Button className='btn-right-menu-component' onClick={onClickMenuTwo}>
                    <img
                        src={!global.MenuRightOpen ? showMenuRightIcon : hideMenuRightIcon}
                    />
                </Button>
            )}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div className='live-component rounded-pill'>
                <FontAwesomeIcon icon={faCircle} size='xs' />
                &nbsp;live
            </div>
            &nbsp;
            <div className='right-header-tracker'>
                <span>{date}</span>
                <span>{time}</span>
            </div>
        </div>
    );
}

export default RightHeader