// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  _::-webkit-full-page-media, _:future, :root .safari_only {
      transform: translate(197px, -170px) !important;
  }

  @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) {
      .safari_only_m { 
        transform: translate(197px, -170px) !important;
      }
  }}
}`, "",{"version":3,"sources":["webpack://./src/layout/sidebar.css"],"names":[],"mappings":"AAAA;EACE;MACI,8CAA8C;EAClD;;EAEA;IACE;MACE;QACE,8CAA8C;MAChD;EACJ,CAAC;AACH","sourcesContent":[".sidebar {\n  _::-webkit-full-page-media, _:future, :root .safari_only {\n      transform: translate(197px, -170px) !important;\n  }\n\n  @media not all and (min-resolution:.001dpcm)\n  { @supports (-webkit-appearance:none) {\n      .safari_only_m { \n        transform: translate(197px, -170px) !important;\n      }\n  }}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
