import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

const MECDateRangeFilter = ({ dates, startDate, endDate, setStartDate, setEndDate }: any) => {
 
  const dateObjects = dates.map((date: number) => new Date(date * 1000));

  useEffect(() => {
    if (!startDate) {
      setStartDate(dateObjects[0]);
    }
    if (!endDate) {
      setEndDate(dateObjects[dateObjects.length - 1]);
    }
  }, [dateObjects, setStartDate, setEndDate, startDate, endDate]);

  const handleStartDateChange = (date: Date | null) => {
    if (date && date <= endDate) {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date && date >= startDate) {
      setEndDate(date);
    }
  };

  return (
    <Form>
      <Row>
        <Col>
          <Form.Group controlId="startDate">
            <Form.Label>Start Date</Form.Label>
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              minDate={dateObjects[0]}
              maxDate={endDate}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="endDate">
            <Form.Label>End Date</Form.Label>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              maxDate={dateObjects[dateObjects.length - 1]}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default MECDateRangeFilter;
