import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

type ChartProps = {
  data: any[];
  title: string;
  global: any
};

const LineChart = ({ global, data, title }: ChartProps) => {
  const [colors, setColors] = useState<string[]>(['transparent', 'transparent', 'transparent']);

  const [timestamps, setTimestamps] = useState([])
  const [landSurfaceElevation, setLandSurfaceElevation] = useState([])
  const [waterLevelsAboveSea, setWaterLevelsAboveSea] = useState([])
  const [waterLevelsBelowSurface, setWaterLevelsBelowSurface] = useState([])
  const [filteredData, setFilteredData] = useState<any>()

  const times = global.CWATimeRange || {};
  const { startTime, endTime } = times;

  const filterDataByDate = (input: any[], startTime: number, endTime: number) => {
    const filteredArray: any[] = [];
    
    input.forEach((item) => {
      const recordDate = parseInt(item.record_date);
  
      // Check if the record_date falls within the specified range
      if (recordDate >= startTime * 1000 && recordDate <= endTime * 1000) {
        filteredArray.push(item);
      }
    });
  
    return filteredArray;
  };

  useEffect(() => {
    if(startTime && endTime) {
      const filtered = filterDataByDate(data, startTime, endTime)
      setFilteredData(filtered)

    }
  }, [startTime, endTime])

  const setInitialDates = (input: any[]) => {
    let timestamps: any[] = [];
    input.forEach(({ record_date }: any) => timestamps.push(parseInt(record_date)));
    const min = Math.min(...timestamps);
    const max = Math.max(...timestamps);
  
    global.setCWAStartTime(min);
    global.setCWAMaxTime(max);
  };

  

  const getTimesStamps = (input: any) => {
    return input.map((data: any) =>
      new Date(data.record_date).toLocaleDateString('en-US', { timeZone: 'UTC' }))
  }

  const getLandSurfaceElevation = (input: any) => {
    return input.map((data: any) =>
      parseFloat(data.land_surface_elevation)
    );
  }

  const getWaterLevelsAboveSea = (input: any) => {
    return input.map((data: any) =>
      parseFloat(data.waterlevel_ft_above_mean_sea_level)
    );
  }

  const getWaterLevelsBelowSurface = (input: any) => {
    return input.map((data: any) =>
      parseFloat(data.waterlevel_ft_below_land_surface)
    );
  }


  useEffect(() => {
    if(data || filteredData) {
      const times_stamps = filteredData ? getTimesStamps(filteredData) :  getTimesStamps(data)
      setTimestamps(times_stamps)
      const land_surface = filteredData ? getLandSurfaceElevation(filteredData) : getLandSurfaceElevation(data)
      setLandSurfaceElevation(land_surface)
      const water_above = filteredData ? getWaterLevelsAboveSea(filteredData) : getWaterLevelsAboveSea(data)
      setWaterLevelsAboveSea(water_above)
      const water_below = filteredData ? getWaterLevelsBelowSurface(filteredData) : getWaterLevelsBelowSurface(data)
      setWaterLevelsBelowSurface(water_below)
      setInitialDates(data)
    }
  },[data, filteredData])


  // Function to generate a random color
  const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Generate colors for each dataset
  useEffect(() => {
    setColors([generateRandomColor(), generateRandomColor(), generateRandomColor()]);
  }, [title]);

  // Prepare data for the Line chart
  const reportData = {
    labels: timestamps,
    datasets: [
      {
        label: 'Land Surface Elevation (ft)',
        data: landSurfaceElevation,
        borderColor: colors[0],
        backgroundColor: colors[0],
        fill: false,
        pointRadius: 4,
        pointHoverRadius: 5,
        pointBackgroundColor: colors[0],
        pointBorderColor: 'white',
        tension: 0.1,
      },
      {
        label: 'Water Level Above Sea Level (ft)',
        data: waterLevelsAboveSea,
        borderColor: colors[1],
        backgroundColor: colors[1],
        fill: false,
        pointRadius: 4,
        pointHoverRadius: 5,
        pointBackgroundColor: colors[1],
        pointBorderColor: 'white',
        tension: 0.1,
      },
      {
        label: 'Water Level Below Land Surface (ft)',
        data: waterLevelsBelowSurface,
        borderColor: colors[2],
        backgroundColor: colors[2],
        fill: false,
        pointRadius: 4,
        pointHoverRadius: 5,
        pointBackgroundColor: colors[2],
        pointBorderColor: 'white',
        tension: 0.1,
      },
    ],
  };

  // Chart options
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: 'Level (ft)',
          color: 'white',
          font: {
            size: 16,
          },
        },
        ticks: {
          color: 'white',
        },
      },
      x: {
        display: true,
        title: {
          display: true,
          text: 'Date',
          color: 'white',
          font: {
            size: 16,
          },
        },
        ticks: {
          color: 'white',
        },
      },
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: title,
        color: 'white',
        font: {
          size: 18,
        },
      },
    },
  };

  return <Line data={reportData} options={options} />;
};

export default LineChart;
