//@ts-nocheck
import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, FloatingLabel } from 'react-bootstrap';
import './add-user.scss';
import InputForm from '../../components/input-form';
import companyIcon from '../../assets/company-icon.svg';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import SettingsBreadcrumb from '../account/SettingsBreadcrumb';
import agent from '../../api/agent';
import './organizations.scss';
import { Organization } from './types/Organization';

export default function AddOrganization(props: any){
	const { global } = props;
	global.setUserLogged(true);
	const location = useLocation();
	const navigate = useNavigate();

	let _mode = 'Add';
	let _first_name = '';
	let _description = '';
	let _email = '';
	let _epa_org_id = '';
	let _is_active = true;

	let _addEditOrg;

	let _org = {};

	if(location && location.state){
		if(location.state.user){
			_first_name = location.state.user.name;
			_org = location.state.user;
			_description = location.state.user.description;
			_email = location.state.user.email;
			_epa_org_id = location.state.user.epa_org_id;
			_is_active = location.state.user.is_active;
		}
		if(location.state.mode && location.state.mode === 'edit'){
			_mode = 'Edit';
		}
		if(location.state.addEditOrg){
			_addEditOrg = location.state.addEditOrg;
		}
	}  

	//addEditOrg

	const [ mode, setMode ] = useState(_mode);
	const [ firstName, setFirstName ] = useState(_first_name || "");
	const [ description, setDescription ] = useState(_description || "");
	const [ email, setEmail ] = useState(_email || "");
	const [ epa_org_id, setEpa_org_id ] = useState(_epa_org_id || "");
	const [ is_active, setIs_active ] = useState(_is_active || true);
	//const [ EPA_is_active, setEPA_Is_active ] = useState(_epa_org_id === 'WQXOFF' ? false : _epa_org_id || "");
    const [ currentOrg, setCurrentOrg] = useState<object>(_org);


	const user = {
		firstName,
		setFirstName,	
		description,
		setDescription,
		email,
		setEmail,
		epa_org_id,
		setEpa_org_id,
		is_active,
		setIs_active
	};

	useEffect(() => {
		if(currentOrg && currentOrg.org_id){
			agent.UserManagement.OrgGet(currentOrg.org_id).then((res: any) => {
				const orgdata = [] as any;
				if(res && res.body){
					const data = res.body
					if(data.length>0){
						setCurrentOrg(data[0])
						setDescription(data[0].description);
					}
				}	
			})   
		}
    }, []);

	const clickCancelHandler = (event: any) => {
        navigate('/Users');
    };

	const clickAddHandler = (event: any) => {
		if(mode === 'Add'){
			const orgSend = { ORG_NAME: firstName, EPA_ORG_ID: epa_org_id, DESCRIPTION: description, EMAIL: email, IS_ACTIVE: is_active };
				agent.UserManagement.OrgPost(orgSend).then((res: any) => {
					if(res){
						console.log(res)
						//_addEditOrg()
					}							
				}) 
		} else if(mode === 'Edit' && currentOrg && currentOrg.org_id){
			const orgSend = { ORG_ID: currentOrg.org_id, ORG_NAME: firstName, EPA_ORG_ID: epa_org_id, DESCRIPTION: description, EMAIL: email, IS_ACTIVE: is_active};
			agent.UserManagement.OrgUpdate(currentOrg.org_id, orgSend).then((res: any) => {
				//console.log(res)
			})  
		}

		navigate('/Users', { replace: false, state:{mode: _mode,  org: true}});
    };

	const handleChange = (change:any, evalue:any) => {
		change(evalue);
	}

	return (
		<div className='add-user'>
			<SettingsBreadcrumb title={'User Management'}  subtitle={mode + ' Organization'}  />
			<h2>{mode} Organization</h2>
			<Form>
				<div className='container form'>
					<h4>Basic Information</h4>
					<Row>
						<Col className='col-md-4'>
							<FloatingLabel controlId="floatingfirstName" label="Organization Name">
								<Form.Control defaultValue={firstName} onChange={e => handleChange(setFirstName, e.target.value)} type="text" placeholder="Company" />
							</FloatingLabel>	

						</Col>
						<Col className='col-md-4'>							
							<FloatingLabel controlId="floatingDescription" label="Organization Description">
								<Form.Control defaultValue={description} onChange={e => handleChange(setDescription, e.target.value)} type="text" placeholder="Company Description" />
							</FloatingLabel>

						</Col>
					</Row>
					<Row>
						<Col className='col-md-4'>
							<FloatingLabel controlId="floatingfirstName" label="Email">
								<Form.Control defaultValue={email} onChange={e => handleChange(setEmail, e.target.value)} type="text" placeholder="Company" />
							</FloatingLabel>	

						</Col>
						<Col className='col-md-4'>							
							<FloatingLabel controlId="floatingDescription" label="EPA ORG ID">
								<Form.Control defaultValue={epa_org_id} onChange={e => handleChange(setEpa_org_id, e.target.value)} type="text" placeholder="Company Description" />
							</FloatingLabel>

						</Col>
					</Row>
					<Row>
						<Col className='col-md-4'>
                                <Form.Label style={{marginLeft: 10}}>Active</Form.Label>
                                <Form.Check
                                    onChange={(e) => {
                                    	console.log(e.target.value, is_active)
                                        handleChange(setIs_active, (e.target.value==='on' ? false : true));
                                    }}
                                    checked={is_active}
                                    className="form-check"  
                                    style={{marginLeft: 20}}             
                                    id={`um-org-edit-check-active`}
                                />
						</Col>
{/*						<Col className='col-md-4'>
                                        <Form.Label style={{marginLeft: 10}}>EPA WQX Submissions</Form.Label>
                                        <Form.Check
                                            onChange={(e) => {
                                            	console.log(e.target.value, is_active)
                                                handleChange(setIs_active, (e.target.value==='on' ? false : true));
                                            }}
                                            checked={is_active}
                                            className="form-check"  
                                            style={{marginLeft: 20}}             
                                            id={`um-org-edit-check-active`}
                                        />
						</Col>*/}
					</Row>

				</div>
				<div className='footer'>
					<Button variant='secondary' onClick={clickCancelHandler}>Cancel</Button>
					<Button variant='primary' onClick={clickAddHandler}>Save</Button>
				</div>
			</Form>
		</div>
	);
}



