// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-col {
  padding-right: 0 !important; }

.submit-btn {
  width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/layout/Filters/time-range-picker.scss"],"names":[],"mappings":"AAAA;EACI,2BAA2B,EAAA;;AAG/B;EACI,WAAW,EAAA","sourcesContent":[".date-col {\n    padding-right: 0 !important;\n}\n\n.submit-btn {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
