import { useEffect, useState, useContext } from 'react';
import { Popup } from 'react-map-gl';
import { useNavigate } from 'react-router-dom'
import { PointContext } from '../../PointContext'
import { AppContext } from '../../AppContext'
import { getCountryFromStorage } from '../../features/utils'
import { uniqueValues } from '../../features/reports/shared/report-data';
import agent from '../../api/agent'
import date from 'date-and-time';

const BasicPopup = ({
    global,
    current,
    setCurrent,
    type,
    score,
    reportUrl }: any) => {

    const navigate = useNavigate()
    const appContext = useContext(AppContext)

    const updateContext = (lat: any, lng: any, city: any) => {

        return agent.Utilities.LatLngToHuc8(lat, lng)
            .then((res: any) => {
                const responseBody = res.body;
                const responseHUC8 = responseBody[0].Huc8;
                if (appContext.updateContext) {
                    appContext.updateContext(responseHUC8, city, global.currentReport, getCountryFromStorage());
                }
            })
            .then(() => global.setLoading(false))
            .finally(() => navigate(reportUrl))
    }

    const updateHuc8 = (lat: any, lng: any) => {
        global.setLoading(true)
        return agent.Utilities.LatLngToCity(lat, lng).then((data: any) => {
            // @ts-ignore
            const city = data.features[0].place_name
            updateContext(lat, lng, city)
        })
    }

    const renderContent = () => {

        const regex = /["\s0-99"]/g

        const keys = Object.keys(current)

        const content = keys.map(key => {
            if (key === 'Date' || key === 'Datetime') {
                const formattedDate = date.format(new Date((current[key])), 'MMMM DD YYYY')
                return <li>Date: {formattedDate}</li>
            }

            if (key === 'Name') {
                return null;
            }

            if (regex.test(key)) {
                return delete current[key]
            }

            if (regex.test(key)) {
                return delete current[key]
            }

            return <li key={'dw-popup-basic-'+key}>{`${key}: ${current[key]}`}</li>
        })

        return (
            <>
                <ul style={{
                    listStyle: 'none',
                    marginTop: '1rem'
                }}>
                    {type === 'SW' && score && (<li>Average 48-Hour Score: {score}</li>)}
                    {type === 'AG' && score && (<li>Overall Score: {score}</li>)}
                    {content}
                    <li style={{ marginTop: 5}} onClick={() => updateHuc8(current.Lat, current.Lng)}>
                        <a
                            style={{
                                color: '#0d6efd',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                fontWeight: 'bold'
                            }}
                        >
                            Go to Report
                        </a>
                    </li>
                </ul>
            </>
        )
    }


    return (
        <Popup
            style={{ position: 'relative' }}
            className={'sc-popup-main'}
            longitude={current.Lng}
            latitude={current.Lat}
            onClose={() => setCurrent(null)}
        >
            <div id="dw-popup">
                <span className='sc-popup-name'>{current.Name}</span>
                {renderContent()}
            </div>
        </Popup>
    )
}

export default BasicPopup