import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faXmarkCircle
} from '@fortawesome/pro-solid-svg-icons';
import {
    Col,
    Row,
    Button,
    Form
} from 'react-bootstrap'
import { getDomainValue, postWQX } from '../lib/data';


const EditProject = (props: any) => {

    const {
        global,
        action,
        setAction,
        data,
        setData,
        title,
        projectsData,
        setProjectsData,
        orgCredentialsType
    } = props;

    const { active, description, id, name, sendToEPA } = data && data

    const [SamplingDesignType, setSamplingDesignType] = useState<any[]>([]); 

    useEffect(() => {
      getDomainValue('SamplingDesignType', setSamplingDesignType);
    }, []);   



    const testObject = { 
       
       
    }

    const handleExit = (event:any) => {
        handleSave(event);
        //global.setLoading(true)
        setTimeout(() => {
            setAction('table')
            //global.setLoading(false)
        }, 2000)
    }


    const updateMon = (property: any, value:any) => {    
        let changeMon = data;
        changeMon[property]=value;
        setData(changeMon)
        //setMonv({property: value})
    }


    const handleSave = (event:any) => {
       
        if(action==='create'){
            const newData = [...projectsData];
            newData.push(data)
          
            setProjectsData(newData)
            //postWQX(data, handleSaveCallback);
            //(dataItem['MonitoringLocIdent_MonitoringLocationIdentifier']||dataItem['MonitoringLocIdent_MonitoringLocationName']){
            let abbrevData = {...testObject} //data //newData[0]

            Object.keys(abbrevData ).forEach((key:string)=>{

                //@ts-ignore
               if(!abbrevData[key]){
                   
                     //@ts-ignore
                     delete abbrevData[key];
                }


            });
            //postWQX(abbrevData, handleSaveCallback);

        } else if (action==='edit'){
            let abbrevData = {...data}

            Object.keys(abbrevData ).forEach((key:string)=>{
                if(abbrevData[key]==='null'){
                     
                }
                if(!abbrevData [key]){
                     
                     delete abbrevData[key];
                }
            });
            //postWQX(abbrevData, handleSaveCallback);

        }
        setAction('table')
    }

    const handleSaveCallback = (success: boolean, result:any, res:any) => {

        if(res && res.errorMessage){
            
        }

    }

    const handleCancel= (event:any) => {
        setAction('table')
    }

    return (
        <>
            <Row className="org-edit-form">
                <h2>{action==='edit' ? "Edit" : "" }{action==='create' ? "Add" : "" }  Project</h2>
                <Col className="col d-flex flex-column col-12">
                    <Row>
                        <Col className="col d-flex col-12">
                            <Form.Group className="m-1 col-6">
                                <Form.Label>Project ID</Form.Label>
                                <Form.Control type="text" defaultValue={data && data.PROJDescription_ProjectIdentifier} />
                            </Form.Group>
                            <Form.Group className="m-1 col-6">
                                <Form.Label>Project Name</Form.Label>
                                <Form.Control type="text" defaultValue={data && data.PROJDescription_ProjectName} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="org-edit-form">
                        <Form.Group className="m-1">
                            <Form.Label>Project Description</Form.Label>
                            <Form.Control as="textarea" rows={3} defaultValue={data && data.PROJDescription_ProjectDescriptionText} />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="m-1" controlId="SamplingDesignType">
                            <Form.Label>Sampling Design Type</Form.Label>
                            <Form.Select size="lg" aria-label="SamplingDesignType" onChange={(e: any) => updateMon('PROJDescription_SamplingDesignTypeCode', e.target.value)}
                            value={data && data["PROJDescription_SamplingDesignTypeCode"]}>
                            {SamplingDesignType  && SamplingDesignType.map((obj:any, index:any) =>
                                (<option key={'wm-proj-options'+index} value={obj.Code}>{obj.Code}</option>)
                            )} 
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col className="col m-2">
                            <Form.Check label="QAPP Approved?" defaultChecked={data && data.PROJDescription_QAPPApprovedIndicator} />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Group className="m-1 col-6">
                            <Form.Label>Approval Agency</Form.Label>
                            <Form.Control type="text" defaultValue={data && data.PROJDescription_QAPPApprovalAgencyName} />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col className="col-2 m-2">
                            <Form.Check label="Active" defaultChecked={active === 'false' ? false : true}/>
                        </Col>
                        <Col className="col-2 m-2">
                            <Form.Check label="Send to EPA" defaultChecked={sendToEPA}  
                              style={(orgCredentialsType === 'none') ? {display: 'none'} : {display:'inline-block'}} />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="org-edit-form mt-3">
                <div className="footer-button-box project">
                    <Button onClick={() => setAction('table')} id="footer-btn" className="p-3 btn btn-primary m-2 cancel-btn">
                        Cancel
                    </Button>
                    <Button onClick={handleExit} id="footer-btn" className="p-3 btn btn-primary m-2">
                        Save & Exit
                    </Button>
                </div>
            </Row>
        </>
    )
}

export default EditProject