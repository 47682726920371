import { Layer, Source } from 'react-map-gl'
import { FillLayer } from 'mapbox-gl';

const VectorTileLayer = ({ 
  id,
  fillColor, 
  opacity, 
  outlineColor, 
  tileSet, 
  sourceLayer, 
  beforeID 
} : any) => {

 
  const VectorTileLayer: FillLayer = {
    id: `${id}-layer`,
    type: "fill",
    "source-layer": `${sourceLayer}`,
    paint: {
      "fill-opacity": opacity,
      "fill-outline-color": `${outlineColor}`,
      'fill-color': fillColor,
    },
  };

    return (
        <>
          <Source
            id={`${id}-layer`}
            type="vector"
            url={tileSet}
          >
            <Layer
              beforeId={beforeID}  
              {...VectorTileLayer} 
            />
          </Source>
        </>
    )
}

export default VectorTileLayer;  
