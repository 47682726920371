import { useEffect, useState, useRef, useCallback, useContext, useMemo, Ref } from "react";
import * as React from 'react';
import {
  Map, MapboxStyle, useMap, MapRef, MapLayerMouseEvent, Popup,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  useControl,
  Layer,
  Source,
  FillLayer,
} from "react-map-gl";
import settings from "../../../settings.json";
import { Form, Button, Row, Col, Table } from "react-bootstrap";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import agent from "../../../api/agent";
import { formatRowValue } from "../../utils";

import LoadingDataAnimation from "../../../components/LoadingDataAnimation";
import { Feature, GeoJsonProperties, Point, Polygon, GeometryCollection, FeatureCollection, Geometry } from 'geojson';
import type { LayerProps } from 'react-map-gl';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import moment from 'moment';
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import * as d3Scale from 'd3-scale';
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import bbox from '@turf/bbox';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { DateTime } from 'luxon';
import { ToastContainer, toast } from 'react-toastify';
import { message_hucrestriction, message_nodatalocation, message_noresult } from '../../../Constants';
import { AppContext } from '../../../AppContext';
import { CatalogsContext } from '../../../CatalogsProvider';
import { Location, useLocation } from 'react-router-dom';
import MapLegend from '../shared/MapLegend';
import MapSlider from '../shared/MapSlider';
import FilterState from '../shared/FilterState';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import MapAddition from  '../shared/MapAddition';
import ReportMap from '../shared/ReportMap';
import ReportTableExpanded from '../shared/ReportTableExpanded';
import {
  useTable, useExpanded, HeaderGroup,
  HeaderProps
} from 'react-table'


import { flyToHUC12sLocation, flyToInitialLocationHandler, getCurrentHuc12LongLat, getHuc12MembersOfHUC8, getCurrentHuc8LongLat } from '../../utils';
import Render from "../../../components/render";
import { RefContext } from '../../../RefContext';
import { startingValues, createFeatureCollection, processStatusMessage, uniqueValues, updateChartData, groupByKey, fitBoundsMapData, findFittingDateRange } from '../shared/report-data';

import './styles.scss'
//--IMAGES
import companyIcon from "../../../assets/company-icon.svg";
import arrowDownIcon from "../../../assets/arrow-down-icon.svg";
import arrowUpIcon from "../../../assets/arrow-up-icon.svg";
import arrowDownDoubleIcon from "../../../assets/arrow-down-double-icon.svg";
import arrowDownDoubleTreeIcon from "../../../assets/arrow-down-double-tree-icon.svg";
import fullscreenIcon from "../../../assets/fullscreen-icon.svg";
import ellipsisIcon from "../../../assets/ellipsis-icon.svg";

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { features } from "process";
import { Line, Bar, getElementAtEvent, getElementsAtEvent } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataset, ChartType, TooltipItem
} from "chart.js";
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export default function WhatsInMyDrinkingWater(props: any) {
  const { global } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);
  const selectedHUC8Value = startingValues('huc', appContext.selectedHUC8, global);
  const selectedCategory = startingValues('ffl', 'UCMR-5', global); //(global.filter1Selected || 'UCMR-5');
  const selectedFilterLocations = global.filterLocationsSelected;
  const selectedContaminants = global.chemicalsSelected;

  let selectedDateRange = global.dateRangeSelected;
/* let selectedDateRange_ = startingValues('frda', [
    new Date(new Date().setDate(new Date().getDate() - 5)),
    new Date(),
  ], global); 

  console.log(selectedDateRange, selectedDateRange_)*/
  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;

  const { current: map } = useMap();
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [cursor, setCursor] = useState<string>('auto');
  const mapRef = useRef<MapRef>();
  const [mapLoaded, setMapLoaded] = useState(false);
  const locationField = 'Pwsid';
  const [mapHoverFilter, setMapHoverFilter] = useState<any>(['in', ['get', locationField], '']);
  const [mapLegendData, setMapLegendData] = useState<any[]>([]);
  const [circleColorStyle, setCircleColorStyle] = useState<any>([]);
  const [mapStyleData, setMapStyleData] = useState<any>([]);
  const [huc8LonLatCatalogArray, setHuc8LonLatCatalogDataArray] = useState<any[]>([]);
  const [huc12InfoCatalogArray, setHuc12InfoCatalogDataArray] = useState<any[]>([]);
  const [userSelectedLocation, setUserSelectedLocation] = useState<any>(null);
  const [userSelectedLocations, setUserSelectedLocations] = useState<any[]>([]);
  const [usLocations, setUsLocations] = useState<any>([]);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [features, setFeatures] = useState({});
  const [featureData, setFeatureData] = useState<FeatureCollection>();
  const [reportData, setReportData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [statusMessage, setStatusMessage] = useState<any>(null);
  const [layerStyle, setLayerStyle] = useState<any>([]);
  const [currentDate, setCurrentDate] = useState<any>(null);
  const chartRef = useRef<any>();
  const [filterLabelDays, setFilterLabelDays] = useState<any>([]);

  const divergingColors = ['#d73027', '#74add1', '#f46d43', '#fdae61', '#fee090', '#ffffbf', '#e0f3f8', '#abd9e9', '#74add1', '#4575b4'];
  const reportTypes = ['UCMR-5', 'UCMR-4', 'UCMR-3', 'UCMR-2', 'UCMR-1 Chemical', 'UCMR-4 Microbial'];
  const chartFields = ["Maximum Analytical Value", "Minimum Analytical Value", "Minimum Reporting Level"];
  const chartFieldsInternal = ["Maxanalyticalvalue", "Minanalyticalvalue", "Meanmrl"];
  const colorsWaterType = {'Groundwater': '#d73027', 'MX': '#74add1', 'Surface water': '#e0f3f8', 'Surface water purchased': '#6a3d9a', 'GU': '#b15928', 'Other': '#abd9e9'} 

 
  const [data, setData] = useState({
    labels: [] as any,
    datasets: [] as any,
  } as const);

  const [config, setConfig] = useState({} as const);
  const [isDataReportLoaded, setIsDataReportLoaded] = useState(false);
  const [isTableSort, setTableSort] = useState(false);
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const [isFullScreenActive, setFullScreenActive] = useState(false);
  const [isFullScreenChartActive, setFullScreenChartActive] = useState(false);
  const fullscreenContainerHandler = () => { setFullScreenActive(!isFullScreenActive); };
  const fullscreenChartContainerHandler = () => { setFullScreenChartActive(!isFullScreenChartActive); };

  
  const [dateRange, setDateRange] = useState<any[]>([])

  function formatDates(inputDates: any) {
    const outputDates: any = [];

    inputDates.forEach((inputDate: any) => {
      const dateObj = new Date(inputDate);
      const day = dateObj.getDate().toString().padStart(2, '0');
      const month = dateObj.toLocaleString('default', { month: 'short' });
      const year = dateObj.getFullYear();


      const formattedDate = `${day} ${month} ${year}`;
      outputDates.push(formattedDate);
    });

    return outputDates;
  }

  useEffect(() => {
    if (global.dateRangeSelected) {
      
      const formatted = formatDates(global.dateRangeSelected)
      console.log(formatted )
      setDateRange(formatted)
    }
  }, [global.dateRangeSelected])

//global.setfilter1Populated(cleanCategories);

  useEffect(() => {
    global.setUserLogged(true);
    if(global.trackerOn) global.setTrackerOn(false)
  }, []);
  
  const { setCurrentRef } = useContext(RefContext);
  useEffect(() => { setCurrentRef?.(mapRef) }, [mapRef])
  const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  useEffect(() => {
    if(global.unregulatedReport){  global.setfilter1Selected('UCMR-5') };
    //global.setfilterLocationsSelected([]);
    global.setDateRangeSelected([]);
    //global.setChemicalsSelected([])
    console.log(global.unregulatedReport, global.filter1Selected)
  }, []);

  useEffect(() => {
    setFilteredData([]);
    setReportData([]);
    setUserSelectedLocations([]);
    global.setfilterLocationsSelected([]);
    global.setChemicalsSelected([])
  }, [selectedHUC8Value]);

  useEffect(() => {
    let filterData = [...filteredData];
    if ((userSelectedLocations) && (userSelectedLocations.length > 0) && (userSelectedLocations.indexOf('All') < 0)) {
      filterData = filterData.filter((o: any) => userSelectedLocations.indexOf(o[locationField]) > -1);
      sortFilterReportData([...filterData], isTableSort)
    } else {
      filterReportData();
    } 
  }, [userSelectedLocations]);

  useEffect(() => {   
    let chartInput = [...filteredData];
    setChartConfig(true);
    const chartdata = updateBarChartData(chartInput, "Contaminant", "Meanmrl", null);
    setTimeout(() => {
      setData(chartdata);
    }
      , 100);
  }, [filteredData]);

  useEffect(() => {
    if(statusMessage){
        global.notify(statusMessage)
    }
  }, [statusMessage]);

  useEffect(() => {
    setStatusMessage("");
    if(selectedHUC8Value && global.hucRestrict.length>0 && (global.hucRestrict.indexOf(selectedHUC8Value) < 0)){
        setIsDataReportLoaded(true);
        return global.notify(message_hucrestriction) 
    }
    setIsDataReportLoaded(false);

    agent.Reports.WhatsInMyDrinkingWater(selectedHUC8Value).then((res: any) => {
      flyToInitialLocationHandler(selectedHUC8Value, mapRef, catalogsContext.huc8Catalog);

      const returnStatus = processStatusMessage(res);
      setStatusMessage(returnStatus.statusMessage)
      if (returnStatus.Abort) {
        setIsDataReportLoaded(true);
        return;
      }
      let returnedData = [...res.body];
      if (!returnedData || !returnedData.length || returnedData.length < 1) {
        setIsDataReportLoaded(true);
        setStatusMessage(message_noresult);
        return;
      }

      for (let dataItem of returnedData) {
        dataItem.datetime = new Date(dataItem["Earliestcollectiondate"]);       
        if(dataItem.Report === 'UCRM-2'){
          dataItem.Report = 'UCMR-2';
        }
        if(dataItem.Report === 'UCRM-3'){
          dataItem.Report = 'UCMR-3';
        }
        if(dataItem.Facilitywatertype === 'GW'){
          dataItem.Facilitywatertype = 'Groundwater';
        }
        if(dataItem.Facilitywatertype === 'Ground water'){
          dataItem.Facilitywatertype = 'Groundwater';
        }
        if(dataItem.Facilitywatertype === 'SW'){
          dataItem.Facilitywatertype = 'Surface water';
        }
      };
      const reportTypeLabels = uniqueValues(returnedData, "Report").sort((x: any, y: any) => x.localeCompare(y));

      //console.log(reportTypeLabels)

     
      const dateExtent = d3Array.extent<any, any>(returnedData, d => d["Earliestcollectiondate"]);
      if(dateExtent && dateExtent[0] && dateExtent[1]){
        let filterDays = [DateTime.fromMillis(dateExtent[0]).toLocaleString(), DateTime.fromMillis(dateExtent[1]).toLocaleString()];        
        setFilterLabelDays(filterDays);
      } 

      //global.setDateRangeSelected(dateExtent);
      const siteTypeLabels = uniqueValues(returnedData, 'Facilitywatertype');
      global.setfilter1Populated(reportTypeLabels);
      global.unregulatedReport ? global.setfilter1Selected('UCMR-3') : global.setfilter1Selected(
        reportTypeLabels[4] ? reportTypeLabels[4] : reportTypeLabels[0]
        );
      
      const extentLevel = d3Array.extent<any, any>(returnedData, d => +d["Meanmrl"])
      const scaleRadius = d3Scale.scaleLinear().domain([0, extentLevel[1]]).range([7, 12]);
     
      for (let dataItem of returnedData) {
        dataItem.centroid_latitude = dataItem.Latitude; //+++
        dataItem.centroid_longitude = dataItem.Longitude; //+++ 
        dataItem.name = dataItem['Pwsid'];
        dataItem.date = dataItem["Earliestcollectiondate"];
        dataItem.formatteddate = DateTime.fromMillis(dataItem["Earliestcollectiondate"]).toLocaleString();
        dataItem.year = moment(dataItem["Earliestcollectiondate"]).format('YYYY');
        dataItem.radius = scaleRadius(dataItem["Meanmrl"]);
      };
      setReportData(returnedData);

      const fData = returnedData.filter((o: any) => o["Report"] === reportTypeLabels[0]);
      updateFilters(fData);
      setFilteredData(fData);

      const legendData = siteTypeLabels.map((x: any, index: number) => ({ typecode: x, color: colorType(x) }));
      setMapLegendData(legendData);
     
      const _mapStyleData = siteTypeLabels.map((x: any, index: number) => ([x, colorType(x)])).flat();
      _mapStyleData.push("#fee090")
      setMapStyleData(_mapStyleData);

      const circleColorStyle: Array<any> = ['match', ['get', 'Facilitywatertype']];
      circleColorStyle.push(_mapStyleData)
      setCircleColorStyle(circleColorStyle);    
      setIsDataReportLoaded(true);
    });
  }, [selectedHUC8Value]);

  useEffect(() => {
    updateLayerStyle();
  }, [isDataReportLoaded]);

  const colorType = (selectedtype: string) => {
    if(colorsWaterType[selectedtype as keyof typeof colorsWaterType]){
      return colorsWaterType[selectedtype as keyof typeof colorsWaterType];
    } else {
      return "#fee090";
    }
  }

  

  const updateFilters = (filterData: any) => {
    const contaminantLabels = uniqueValues(filterData, 'Contaminant');
    global.setChemicalsFilerOptions(['All', ...contaminantLabels]);

    const siteLabels = uniqueValues(filterData, 'Pwsid');
    const siteFilter = siteLabels.map((x: any, index: number) => ({ huc12: x, hucname: x }));
    global.setfilterLocationsPopulated([{ 'hucname': 'All', 'huc12': 'All' }, ...siteFilter]);
  }

  const updateBarChartData = (filterData: any, groupKey: string, selectedCategory: string, huc12InfoCatalogArray: any) => {
    let fDataset = [...filterData].sort((x: any, y: any) => x[groupKey].toLowerCase().localeCompare(y[groupKey].toLowerCase()));
    
    const groupedresults = groupByKey<any, any>(fDataset, i => i[groupKey]);
    const chartLabels = uniqueValues(fDataset, groupKey).sort((x: any, y: any) => x.localeCompare(y))
    const chartdata_data: any[] = [];
    let chartdata_labels: any[] = [];
 
    for (const key in chartFields) {
      const site_data: any[] = [];
      const summedValue = chartFieldsInternal[key];
      for (const key in groupedresults) { 
          const sumVal = d3Array.rollups(
              groupedresults[key],
            xs => d3Array.sum(xs, x => x[summedValue]),
            d => key
          )
          site_data.push(sumVal[0][1]);
      }

      let labelGroup = chartFields[key];
      const colorMatch = d3ScaleChromatic.schemePaired[(+key*2)];
      let colorLine = colorMatch;
      let dataObject = {
        label: labelGroup,
        data: site_data.map((x: any) => +x),
        borderColor: colorLine,
        backgroundColor: colorLine
      }
      chartdata_data.push(dataObject);
    }
    chartdata_labels = chartLabels.map((x: any) => x.substr(0, 25));
    const chartdata = {
      labels: chartdata_labels,
      datasets: chartdata_data,
      borderColor: '#ff6384'
    }

    return chartdata;
  }

  const filterReportData = () => {
    let filterData = [...reportData];
    if (reportData.length > 0) {
   
      if ((selectedCategory) && (selectedCategory !== 'All')) {
        filterData = filterData.filter((o: any) => o["Report"] === selectedCategory);
      }
      updateFilters(filterData);
      
      if ((selectedFilterLocations) && (selectedFilterLocations.length > 0) && (selectedFilterLocations.indexOf('All') < 0)) {
          filterData = filterData.filter((o: any) => selectedFilterLocations.indexOf(o[locationField]) > -1);
      }
      
      if ((selectedContaminants) && (selectedContaminants.length > 0) && (selectedContaminants.indexOf('All') < 0)) {
         filterData = filterData.filter((o: any) => selectedContaminants.indexOf(o['Contaminant']) > -1);
      } 
  
      if ((selectedDateRange) && (selectedDateRange.length > 1)) {
        if (selectedDateRange[0] < selectedDateRange[1]) {
          filterData = filterData.filter((o: any) => o["datetime"] > selectedDateRange[0] && o["datetime"] < selectedDateRange[1]);
        }
      }
      sortFilterReportData([...filterData], isTableSort)
     }
  }

  const sortFilterReportData = (unsortedData: any, ascending: boolean) => {
    let filterData = unsortedData.sort((x:any,y:any) => x["Earliestcollectiondate"] - y["Earliestcollectiondate"] || x["Pwsid"].localeCompare(y["Pwsid"]));
    if(!ascending){
      filterData = unsortedData.sort((x:any,y:any) => y["Earliestcollectiondate"] - x["Earliestcollectiondate"] || y["Pwsid"].localeCompare(x["Pwsid"]))
    }
    setFilteredData(filterData);
  }

  useEffect(() => {
    filterReportData();
  }, [selectedCategory, selectedFilterLocations, selectedDateRange, selectedContaminants, reportData]);

  useEffect(() => {
    global.setfilterLocationsSelected([]);
    global.setChemicalsSelected([])
    setUserSelectedLocations([]);
    //global.setDateRangeSelected([]);
  }, [selectedCategory]);
    


  useEffect(() => {
    const featureCollectionFromReportData = createFeatureCollection([...filteredData], catalogsContext.huc12Catalog, huc12InfoCatalogArray, selectedHUC8Value);
    setFeatureData(featureCollectionFromReportData);
    if (featureCollectionFromReportData.features.length > 0) {
      if (mapRef && mapRef.current) {
        const mapRef_ = mapRef;
        setTimeout(() => {
          fitBoundsMapData(featureCollectionFromReportData, mapRef_)
        }
          , 400);
      }
    }
  }, [filteredData]);

  //console.log(selectedCategory)

  const setChartConfig = (chartAnimation: boolean) => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: true,
      scales: {
        y: {
          display: true,
          title: {
            display: true,
            text: 'Analytical Value',
            color: 'white'
          },
          ticks: {
            color: 'white',         
          }
        },
        x: {
          display: true,
          title: {
            display: true,
            text: selectedCategory + ' Contaminant',
            color: 'white'
          },
          ticks: {
            color: 'white',         
          }
        },
      },
      plugins: {
        legend: {
          position: "bottom" as const,
          labels: {
            usePointStyle: true,
          },
          maxHeight: 70,
        } as const,
        title: {
          display: true,
          text: selectedCategory + " Analytical Value per Contaminant",
          color: 'white',
          align: 'center',
          padding: 10,
          font: {
            size: 20
          }
        } as const,
        tooltip: {
          padding: 10,
          bodyFont: {
            size: 24
          },
          titleFont: {
            size: 24
          },
          boxPadding: 8,
          usePointStyle: true,
          backgroundColor: '#12234f',
          callbacks: {
            label: function (context: any) {
              const fData = filteredData.filter((o: any) => o["Contaminant"].substr(0, 20) === context.label.substr(0, 20));     
              let label = context.dataset.label || ''; 
              if (label) {
                if(fData.length>0){
                  label = ['Max of Analytical Value: ' + d3Format.format(",")(d3Array.sum(fData, d => d["Maxanalyticalvalue"])), 'Min of Analytical Value: ' + d3Format.format(",")(d3Array.sum(fData, d => d["Minanalyticalvalue"])), 'MRL: ' + d3Format.format(",")(d3Array.sum(fData, d => d["Meanmrl"])), 'Earliest Collection Date: ' + (fData[0]['formatteddate'])]
                } else {
                  label = label + ' ' + context.formattedValue;
                }
              }
              return label;
            }
          }
        } as const,
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'xy',
          },
        } as const,
      } as const,
    } as const;
    setConfig(options);
  }

  const onChartClickHandler = (event: any) => {
    const currentClick = getElementAtEvent(chartRef.current, event)[0];
    if (currentClick) {
      const currentClickMetaData = chartRef.current.getDatasetMeta(currentClick.datasetIndex);
      const currentSite = currentClickMetaData.label;
 
    }
  }

  const tableColumns = useMemo(  //DateTime.fromMillis(dateExtent[1]).toLocaleString()
    () => [
      { Header: 'PWSID', accessor: 'Pwsid' },
      { Header: 'Sample Site', accessor: 'Samplesite' },
      { Header: 'Point Type', accessor: 'Samplepointtype' },
      { Header: 'Contaminant', accessor: 'Contaminant' },
      { Header: 'Earliest Collection', accessor: 'Earliestcollectiondate',
          Cell: (props: any) => ( (props.value && !isNaN(props.value)) ?  DateTime.fromMillis(props.value).toLocaleString() : '' ), },
      { Header: 'Max Analytical Value', accessor: 'Maxanalyticalvalue' },
      { Header: 'Mean Analytical Value', accessor: 'Meananalyticalvalue' },
      { Header: 'Min Analytical Value', accessor: 'Minanalyticalvalue' },
      { Header: 'Analytical Result', accessor: 'Analyticalresult' },
      { Header: 'Mean of MRL', accessor: 'Meanmrl' },
      { Header: 'Pk', accessor: 'Pk' },
      { Header: 'Facility Water Type', accessor: 'Facilitywatertype' },
    ],
    []
  );

  const updateLayerStyle = () => {
    const newLayerStyle: LayerProps = {
      id: 'pointlayer',
      type: 'circle' as const,
      paint: {
        'circle-radius': ['get', 'radius'],
        'circle-color': ['match', ['get', 'Facilitywatertype'], 'GU', '#b15928', 'Groundwater', '#d73027', 'MX', '#74add1', 'Surface water', '#e0f3f8', 'Surface water purchased', '#6a3d9a', '#fee090'],
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1,
        'circle-opacity': 0.5
      }
    };

    setLayerStyle(newLayerStyle);
  }

  const hoverLayerStyle: LayerProps = {
    id: 'hoverlayer',
    type: 'circle' as const,
    paint: {
      'circle-radius': ['get', 'radius'],
      'circle-color': '#000099',
      'circle-stroke-color': 'white',
      'circle-stroke-width': 1,
      'circle-opacity': 1
    }
  };

  const onMouseEnter = useCallback((event: any | null) => {
    if (event.features && event.features[0] && event.features[0].properties) {
      let newPopup = event.features[0].properties;
      let newLocation = event.features[0].properties[locationField];
      let newHover = event.features[0].properties[locationField];
      setTimeout(() => {
        setCursor('pointer');
        setPopupInfo(newPopup);
        setUserSelectedLocation(newLocation)
        setMapHoverFilter(['in', ['get', locationField], newHover]);
      }
        , 1);
    }
  }, []);

  const onMouseLeave = useCallback((event: any | null) => {
    setCursor('');
    setTimeout(() => {
      setPopupInfo(null);
      setUserSelectedLocation(null)
      setMapHoverFilter(['in', ['get', locationField], '']);
    }
      , 11);
  }, []);

  useEffect(() => {
    if (usLocations.length > 0) {
      if (userSelectedLocations.indexOf(usLocations[0][locationField]) > -1) {
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', locationField], '']);
      } else {
        setUserSelectedLocations(usLocations[0][locationField]);
        setPopupInfo(usLocations[0]);
        setTimeout(() => {
          setMapHoverFilter(['in', ['get', locationField], usLocations[0][locationField]]);
        }, 111);
      }
    }
  }, [usLocations]);

  const onHandleClick = useCallback((event: any | null) => {
    const feature = event.features && event.features[0];
    if (feature) {
      setUsLocations([feature.properties]);
    }
  }, []);

  const onMapLoad = useCallback(() => {
    if (mapRef && mapRef.current) {
      setMapLoaded(true);

      mapRef.current.resize();
    }
  }, []);


  const pointInPolygonUpdate = (pointsSelected: any) => {
        setUserSelectedLocations(pointsSelected);
        setMapHoverFilter(['in', ['get', locationField], ['literal', pointsSelected]]);
  }

  const onUpdate = useCallback((e: any | null) => {
    if (mapRef && mapRef.current) {
      const pointlayerFeatures = mapRef.current.queryRenderedFeatures(undefined, {
        layers: ["pointlayer"]
      });
      const pointsSelected: any[] = [];
      if (pointlayerFeatures.length > 0) {
        for (let dataItem of pointlayerFeatures) {
          if (dataItem && dataItem.properties && dataItem.properties.lng) {
            const inSelection = booleanPointInPolygon([dataItem.properties.lng, dataItem.properties.lat], e.features[0]);
            if (inSelection) {
              pointsSelected.push(dataItem.properties[locationField]);
            }
          }
        }
        setUserSelectedLocations(pointsSelected);
        setMapHoverFilter(['in', ['get', locationField], ["literal", pointsSelected]]);
      }
    }
  }, []);

  const onDelete = useCallback((e: any | null) => {
    if (mapRef && mapRef.current) {
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', locationField], '']);
    }
  }, []);


  const renderPopup = () => {
    return (
      <Popup
      
        longitude={Number(popupInfo.centroid_longitude)}
        latitude={Number(popupInfo.centroid_latitude)}
        onClose={() => setPopupInfo(null)}
      >
        <div className="popup-container">
          <div className="popup-content-row">
            <span className="popup-content-label">PWSID</span>
            <span className="popup-container-location popup-content-value">{popupInfo["Pwsid"]}</span>
          </div>

          <div className="popup-content-row">
            <span className="popup-content-label">Sample Site</span>
            <span className="popup-content-value">{popupInfo["Samplesite"]}</span>
          </div>

          <div className="popup-content-row">
            <span className="popup-content-label">Address</span>
            <span className="popup-content-value">{popupInfo["Samplepointname"]}</span>
          </div>

        </div>
      </Popup>
    )
  }

  const hoverRowHandler = (event: any) => { }

  const clickRowHandler = (event: any) => {
    if (userSelectedLocations && userSelectedLocations.indexOf(event[locationField]) > -1) {
      setUserSelectedLocations([])
      setPopupInfo(null);
      setMapHoverFilter(['in', ['get', locationField], '']);
    } else {
      setUserSelectedLocations([event[locationField]])
      setMapHoverFilter(['in', ['get', locationField], event[locationField]])
      setPopupInfo(event);
      if (mapRef.current) {
        if (event && event.centroid_longitude) {
          mapRef.current.flyTo({
            center: [
              parseFloat(event.centroid_longitude),
              parseFloat(event.centroid_latitude),
            ],
          });
        }
      }
    }
  };

  const onHoverOut = useCallback((e: any | null) => {
    setMapHoverFilter(['in', ['get', locationField], '']);
    setPopupInfo(null);
  }, []);

  const onTableClick = useCallback((e: any | null) => {

  }, []);

  const deselectFilters = useCallback((e: any | null) => {
    setPopupInfo(null);
    setMapHoverFilter(['in', ['get', locationField], '']);
    setUserSelectedLocations([]);
  }, []);
  
  return (
    <>
      <FullScreenComp handle={fullscreenhandle}>
        <div className="whatsinmydrinkingwater"  id='whatsinmydrinkingwater-report'>
          <ReportHeader global={global} data={filteredData} filterLabelDays={dateRange}
            reportID={"whatsinmydrinkingwater-report"} fullScreenClickHandle={fullscreenhandle.enter} />        
          <div className="container">
            <div className="col">
              <div className="map-report-container">
                <LoadingDataAnimation dataLoading={!isDataReportLoaded} />
                <Map
                  id="WhatsInMyDrinkingWater"
                  mapboxAccessToken={settings.maboxKey}
                 mapStyle={global.mapStyle}
                  onLoad={onMapLoad}
                  onClick={(e) => {
                    global.onMapClick(e)
                    onHandleClick(e)
                  }}
                  onMoveEnd={(e) => {
                    global.setViewport({
                      longitude: e.viewState.longitude,
                      latitude: e.viewState.latitude,
                      zoom: e.viewState.zoom,
                    })
                  }}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  cursor={cursor}
                  preserveDrawingBuffer={true}
                  interactiveLayerIds={['pointlayer']}
                  ref={mapRef as Ref<MapRef>}
                  projection={global.globeView ? 'globe' : 'mercator' as any}
                >
                  {featureData && (
                    <Source id="circlesource" type="geojson" data={featureData}>
                      <Layer {...layerStyle} />
                      <Layer {...hoverLayerStyle} filter={mapHoverFilter} />
                    </Source>
                  )}

                   <MapAddition global={global} 
                                mapRef={mapRef}
                                onDelete={onDelete}
                                PointInPolygonField={locationField}
                                PointInPolygonUpdate={pointInPolygonUpdate}
                                position={'low'}
                                zipOff={true}
                                statusMessage={statusMessage}/>

                  {popupInfo && renderPopup()}

                  <div className='map-legend-container' onClick={deselectFilters}>
                    <MapLegend
                      mapLegendData={mapLegendData}
                      title={'Water Source Type'}
                      global={global} 
                    />
                  </div> 
                    
                </Map>
                
              </div>
            </div>

            <div className="col-7">
              <div className="row chart-container-parent">
                <div className={isFullScreenChartActive ? 'expand-chart-container chart-container' : 'chart-container'}>
                  <div className="report-chart-options">
                    <Button onClick={fullscreenChartContainerHandler}>
                      <img src={fullscreenIcon} />
                    </Button>
                  </div>
                  {featureData && (
                    <div className="chart-line-container">
                      <div className="line-chart">
                        <Bar options={config}
                          data={data}
                          ref={chartRef}
                          onClick={onChartClickHandler}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div
                className={isFullScreenActive ? 'row gx-0 table-chart-row table-parent-container expand-container' : 'row gx-0 table-chart-row table-parent-container'}
                onMouseLeave={onHoverOut}
                onClick={onTableClick}>
                <div className="report-options">
                  <Button variant="light" onClick={fullscreenContainerHandler}>
                    <img src={fullscreenIcon} />
                  </Button>
                </div>
                <ReportTableExpanded
                  data={filteredData}
                  columns={tableColumns}
                  clickRowHandler={clickRowHandler}
                  clickLoadStop={true}
                />
              </div>
              <ReportFooter />
            </div>
          </div>
        </div>
        <MapSlider global={global} />
      </FullScreenComp>
    </>
  );
}

type WhatsInMyDrinkingWaterModel = {
  lat: number;
  lng: number;
};
