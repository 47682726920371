import React, { useState, useEffect, useRef, useContext } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Map, FullscreenControl, NavigationControl, GeolocateControl, Layer, Source } from 'react-map-gl';
import { Row, Col, Container } from 'react-bootstrap';
import { LayerDataContext } from "../../../LayerDataContext";
import { putViewportIntoStorage } from '../../utils';
import WaterImpairmentTable from './WaterImpairmentTable';
import RenderLayerMarkers from '../shared/RenderLayerMarkers';
import RenderLayers from '../shared/RenderLayers';
import MapAddition from '../shared/MapAddition';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import MapLegend from '../shared/MapLegend';
import settings from '../../../settings.json';
import './water-impairments.scss';

const WaterImpairmentsReport = ({ global, reportID }: any) => {

  const [tileName, setTileName] = useState<string>('');
  const [activeTile, setActiveTile] = useState<any>();
  const [tableType, setTableType] = useState<string>('');
  const [tableData, setTableData] = useState<any[]>([]);

  const fullscreenHandle = useFullScreenHandle();
  const { currentLayerData } = useContext<any>(LayerDataContext);

  const mapRef = useRef<any>(null);
  const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  const flyTo = (ref: any, { lat, lng }: any, zoom: number = 6) => {
    if (ref.current) {
      ref.current.flyTo({
        center: [lng, lat],
        essential: true,
        zoom,
      });
    }
  };

  const fetchData = async (map: any, point: [number, number]) => {
    try {
      if (map) {
        const res = await map.queryRenderedFeatures(point);
        return res;
      }
    } catch (error) {
      console.log('Error querying features:', error);
    }
    return [];
  };

  const handleActiveTile = (sourceName: any, name: any, type: any) => {
    let SetLayer: any;
    let sourceId: any;
    let sourceUrl: any;

    if (type === "circle") {
      SetLayer = {
        id: "active-circle-tile",
        type: "circle",
        "source-layer": sourceName,
        paint: {
          "circle-opacity": 1,
          "circle-radius": 8,
          "circle-color": "transparent",
          "circle-stroke-width": 2,
          "circle-stroke-color": [
            "match",
            ["get", "assessmentunitname"],
            name,
            "white",
            "transparent",
          ],
        },
      };
      sourceId = "active-circle-tile";
      sourceUrl = settings.tileSetURLs.NationalImpairedWaterPoints;
    } else if (type === "line") {
      SetLayer = {
        id: "active-line-tile",
        type: 'line',
        "source-layer": sourceName,
        paint: {
          "line-opacity": 1,
          "line-width": 4,
          "line-color": [
            "match",
            ["get", "assessmentunitname"],
            name,
            "white",
            "transparent",
          ],
        },
      };
      sourceId = "active-line-tile";
      sourceUrl = settings.tileSetURLs.NationalImpairedWaterLines;
    } else if (type === "fill") {
      SetLayer = {
        id: "active-fill-tile",
        type: "line",
        "source-layer": sourceName,
        paint: {
          "line-opacity": 1,
          "line-width": 4,
          "line-color": [
            "match",
            ["get", "assessmentunitname"],
            name,
            "white",
            "transparent",
          ],
        },
      };
      sourceId = "active-fill-tile";
      sourceUrl = settings.tileSetURLs.NationalImpairedWaterPolygons;
    }

    return (
      <Source key={sourceId} id={sourceId} type="vector" url={sourceUrl}>
        <Layer {...SetLayer} />
      </Source>
    );
  };


  const handleState = async (map: any, point: any, lngLat: any) => {

    const features = await fetchData(map, point);
    
    if (features && features.length > 0) {
      const { properties, layer } = features[0];
     
      setTileName(properties.assessmentunitname);
      setTableType(layer.type);
      setActiveTile(handleActiveTile(layer['source-layer'], properties.assessmentunitname, layer.type));
      setTableData([properties]);
      flyTo(mapRef, lngLat);
    }
  };

  const handleInitState = async (layer: any, properties: any, lngLat: any) => {
    setTileName(properties.assessmentunitname);
    setTableType(layer.type);
    setActiveTile(handleActiveTile(layer['source-layer'], properties.assessmentunitname, layer.type));
    setTableData([properties]);
    flyTo(mapRef, lngLat);
  };
  
  useEffect(() => {
    setTimeout(() => {
    if (currentLayerData) {
      const { layer, properties, coor } = currentLayerData;
        const point = mapRef.current.getMap().project([coor[1], coor[0]]);
        if(point) handleInitState(layer, properties, { lat: coor[0], lng: coor[1] })
      }
    },500)
  }, [currentLayerData]);

  useEffect(() => {
    const map = mapRef.current
    if (map) {
      const handleClick = async ({ point, lngLat }: any) => handleState(map, point, lngLat);

      map.on('click', handleClick);

      return () => {
        if (map) {
          map.off('click', handleClick);
        }
      };
    }
  }, [mapRef.current]);

  return (
    <FullScreen handle={fullscreenHandle}>
      <Container className='wi-report-container'>
        <ReportHeader
          global={global}
          data={[]}
          reportID={reportID}
          fullScreenClickHandle={fullscreenHandle.enter}
        />
        <Row>
          <Col className='col-12 top d-flex justify-content-center align-items-center'>
            <Col className="wi-map-container w-100">
              <Map
                mapboxAccessToken={settings.maboxKey}
                mapStyle={global.mapStyle}
                ref={mapRef}
                preserveDrawingBuffer={true}
                onMove={(e) => {
                  putViewportIntoStorage({
                    longitude: e.viewState.longitude,
                    latitude: e.viewState.latitude,
                    zoom: e.viewState.zoom,
                  });
                  global.setViewport({
                    longitude: e.viewState.longitude,
                    latitude: e.viewState.latitude,
                    zoom: e.viewState.zoom,
                  });
                }}
              >
                <MapAddition
                  global={global}
                  mapRef={mapRef}
                  position={'low'}
                  zipOff={true}
                  MapSliderAdd={true}
                />
                <MapLegend global={global} legendWidth={280} />
                <RenderLayerMarkers global={global} bounds={mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : []} zipOff={true} wq={true} />
                <RenderLayers global={global} />
                <FullscreenControl />
                <NavigationControl />
                <GeolocateControl />
                {activeTile}
              </Map>
            </Col>
          </Col>
        </Row>
        <Row className='bottom d-flex justify-content-center align-items-center'>
          {tableData.length > 0 && tileName && tableType && (
            <WaterImpairmentTable tileName={tileName} data={tableData} type={tableType} />
          )}
        </Row>
      </Container>
      <ReportFooter />
    </FullScreen>
  );
};

export default WaterImpairmentsReport;
