import { useEffect, useState, useRef, useCallback, useContext, useMemo, Ref } from "react";
import * as React from 'react';
import {
  Map, MapboxStyle, useMap, MapRef, MapLayerMouseEvent, Popup,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  useControl,
  Layer,
  Source,
  FillLayer,
} from "react-map-gl";
import settings from "../../../settings.json";
import { Form, Button, Row, Col, Table } from "react-bootstrap";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import agent from "../../../api/agent";
import { formatRowValue } from "../../utils";
import { RefContext } from '../../../RefContext';
import { ToastContainer, toast } from 'react-toastify';

import { HUC8GeoPoint } from "../../../types/HUC8GeoPoint";
import { HUC12GeoPoint } from "../../../types/HUC12GeoPoint";
import { HUC12InfoPoint } from "../../../types/HUC12InfoPoint";

import LoadingDataAnimation from "../../../components/LoadingDataAnimation";
import { Feature, GeoJsonProperties, Point, Polygon, GeometryCollection, FeatureCollection, Geometry } from 'geojson';
import type { LayerProps } from 'react-map-gl';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import moment from 'moment';
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import bbox from '@turf/bbox';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { DateTime } from 'luxon';
import { message_hucrestriction, message_nodatalocation, message_noresult } from '../../../Constants';
import { AppContext } from '../../../AppContext';
import { CatalogsContext } from '../../../CatalogsProvider';
import { Location, useLocation } from 'react-router-dom';
import MapLegend from '../shared/MapLegend';
import MapSlider from '../shared/MapSlider';
import MapAddition from  '../shared/MapAddition';
import FilterState from '../shared/FilterState';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import ReportMap from '../shared/ReportMap';
import ReportTableExpanded from '../shared/ReportTableExpanded';
import {
  useTable, useExpanded,
  UseExpandedHooks,
  UseExpandedInstanceProps,
  UseExpandedOptions,
  UseExpandedRowProps, HeaderGroup,
  HeaderProps,
  UseExpandedState, Cell
} from 'react-table'


import { flyToHUC12sLocation, flyToInitialLocationHandler, getCurrentHuc12LongLat, getHuc12MembersOfHUC8, getCurrentHuc8LongLat } from '../../utils';
import Render from "../../../components/render";
import { createFeatureCollection, processStatusMessage, uniqueValues, updateChartData, fitBoundsMapData, findFittingDateRange } from '../shared/report-data';

import './styles.scss'
//--IMAGES
import companyIcon from "../../../assets/company-icon.svg";
import arrowDownIcon from "../../../assets/arrow-down-icon.svg";
import arrowUpIcon from "../../../assets/arrow-up-icon.svg";
import arrowDownDoubleIcon from "../../../assets/arrow-down-double-icon.svg";
import arrowDownDoubleTreeIcon from "../../../assets/arrow-down-double-tree-icon.svg";
import fullscreenIcon from '../../../assets/fullscreen-icon.svg';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { features } from "process";
import { Line, getElementAtEvent, getElementsAtEvent } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataset, ChartType, TooltipItem
} from "chart.js";
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export default function GroundwaterLevels(props: any) {
  const { global } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);
  const selectedHUC8Value = appContext.selectedHUC8;
  const selectedCategory = (global.filter1Selected || 'All');
  const selectedFilterLocations = global.filterLocationsSelected;
  let selectedDateRange = global.dateRangeSelected;

  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;

  const { current: map } = useMap();
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [cursor, setCursor] = useState<string>('auto');
  const mapRef = useRef<MapRef>();
  const [mapLoaded, setMapLoaded] = useState(false);
  const locationField = 'USGS Site Number';
  const [mapHoverFilter, setMapHoverFilter] = useState<any>(['in', ['get', locationField], '']);
  const [mapLegendData, setMapLegendData] = useState<any[]>([]);
  const [circleColorStyle, setCircleColorStyle] = useState<any>([]);
  const [mapStyleData, setMapStyleData] = useState<any>([]);
  const [huc8LonLatCatalogArray, setHuc8LonLatCatalogDataArray] = useState<any[]>([]);
  const [huc12InfoCatalogArray, setHuc12InfoCatalogDataArray] = useState<any[]>([]);
  const [userSelectedLocation, setUserSelectedLocation] = useState<any>(null);
  const [userSelectedLocations, setUserSelectedLocations] = useState<any[]>([]);
  const [usLocations, setUsLocations] = useState<any>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [features, setFeatures] = useState({});
  const [featureData, setFeatureData] = useState<FeatureCollection>();
  const [reportData, setReportData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [statusMessage, setStatusMessage] = useState<any>(null);
  const [layerStyle, setLayerStyle] = useState<any>([]);
  const [currentDate, setCurrentDate] = useState<any>(null);
  const [filterLabelDays, setFilterLabelDays] = useState<any>([]);
  const chartRef = useRef<any>();

  const divergingColors = ['#d73027', '#74add1', '#f46d43', '#fdae61', '#fee090', '#ffffbf', '#e0f3f8', '#abd9e9', '#74add1', '#4575b4'];

  const [data, setData] = useState({
    labels: [] as any,
    datasets: [] as any,
  } as const);

  const [config, setConfig] = useState({} as const);

  const [isDataReportLoaded, setIsDataReportLoaded] = useState(false);
  const [isTableSort, setTableSort] = useState(false);
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const [isFullScreenActive, setFullScreenActive] = useState(false);
  const [isFullScreenChartActive, setFullScreenChartActive] = useState(false);
  const fullscreenContainerHandler = () => { setFullScreenActive(!isFullScreenActive); };
  const fullscreenChartContainerHandler = () => { setFullScreenChartActive(!isFullScreenChartActive); };
  const [dateRange, setDateRange] = useState<any[]>([])

  function formatDates(inputDates: any) {
    const outputDates: any = [];

    inputDates.forEach((inputDate: any) => {
      const dateObj = new Date(inputDate);
      const day = dateObj.getDate().toString().padStart(2, '0');
      const month = dateObj.toLocaleString('default', { month: 'short' });
      const year = dateObj.getFullYear();
      console.log(year)

      const formattedDate = `${day} ${month} ${year}`;
      outputDates.push(formattedDate);
    });

    return outputDates;
  }

  useEffect(() => {
    if (global.dateRangeSelected) {
      const formatted = formatDates(global.dateRangeSelected)
      setDateRange(formatted)
    }
  }, [global.dateRangeSelected])

  useEffect(() => {
    global.setUserLogged(true);
    if (global.trackerOn) global.setTrackerOn(false)
  }, []);

  useEffect(() => {
    global.setfilter1Selected('All');
    global.setfilterLocationsSelected([]);
    global.setDateRangeSelected([]);
  }, []);

  useEffect(() => {
    setFilteredData([]);
    setReportData([]);
    setUserSelectedLocations([]);
  }, [selectedHUC8Value]);

  useEffect(() => {
    let filterData = [...filteredData];
    if ((userSelectedLocations) && (userSelectedLocations.length > 0) && (userSelectedLocations.indexOf('All') < 0)) {
      filterData = filterData.filter((o: any) => userSelectedLocations.indexOf(o["USGS Site Number"]) > -1);
      sortFilterReportData([...filterData], isTableSort)
    } else {
      filterReportData();
    }
  }, [userSelectedLocations]);

  useEffect(() => {
    //setIsDataReportLoaded(false);
    let chartInput = [...filteredData];
    if (filteredData.length > 4000) {
      chartInput = chartInput.slice(0, 4000);
    }
    if (filteredData.length > 900) {
      setChartConfig(false);
    } else {
      setChartConfig(true);
    }
    const chartdata = updateChartData(chartInput, "USGS Site Number", "Water Level Feet", null);

    setTimeout(() => {
      setData(chartdata);
      //setIsDataReportLoaded(true);
    }
      , 100);
  }, [filteredData]);

  useEffect(() => {
    if (statusMessage) {
      global.notify(statusMessage)
    }
  }, [statusMessage]);

  const { setCurrentRef } = useContext(RefContext)

  useEffect(() => {

    setStatusMessage("");
    if (selectedHUC8Value && global.hucRestrict.length > 0 && (global.hucRestrict.indexOf(selectedHUC8Value) < 0)) {
      setIsDataReportLoaded(true);
      return global.notify(message_hucrestriction)
    }
    setIsDataReportLoaded(false);
    agent.Reports.GroundwaterLevels(selectedHUC8Value).then((res: any) => {
      flyToInitialLocationHandler(selectedHUC8Value, mapRef, catalogsContext.huc8Catalog);

      const returnStatus = processStatusMessage(res, 'GroundwaterLevels');
      setStatusMessage(returnStatus.statusMessage)
      if (returnStatus.Abort) {
        setIsDataReportLoaded(true);
        return;
      }
      let returnedData = [...res.body];
      if (!returnedData || !returnedData.length || returnedData.length < 1) {
        setIsDataReportLoaded(true);
        return;
      }

      /*      if(returnStatus.statusMessage){
              global.notify(returnStatus.statusMessage);
            }*/

      for (let dataItem of returnedData) {
        dataItem.datetime = new Date(dataItem["Milliseconds Since Unix Epoch"]);
      };

      const newDateRange = findFittingDateRange(returnedData, 900);
      global.setDateRangeSelected(newDateRange);
      selectedDateRange = newDateRange;

      if (selectedDateRange && selectedDateRange[0] && selectedDateRange[1]) {
        setFilterLabelDays([DateTime.fromJSDate(selectedDateRange[0]).toLocaleString(), DateTime.fromJSDate(selectedDateRange[1]).toLocaleString()]);
      }

      for (let dataItem of returnedData) {
        dataItem.centroid_latitude = dataItem.lng; //+++
        dataItem.centroid_longitude = dataItem.lat; //+++ 
        dataItem.name = dataItem["USGS Site Number"];
        dataItem.date = dataItem["Milliseconds Since Unix Epoch"];
        dataItem.year = moment(dataItem["Milliseconds Since Unix Epoch"]).format('YYYY');
      };
      setReportData(returnedData);

      const siteLabels = uniqueValues(returnedData, "USGS Site Number");
      const siteFilter = siteLabels.map((x: any, index: number) => ({ huc12: x, hucname: x }));
      const sitetypeLabels = uniqueValues(returnedData, "Site Type Code");

      global.setfilter1Populated(['All', 'Above', 'Below']);
      global.setfilterLocationsPopulated([{ 'hucname': 'All', 'huc12': 'All' }, ...siteFilter]);

      const arrayUniqueByKey: Array<any> = returnedData.map((x: any) => (
        x["Site Type Code"]));
      const uniqueSet = new Set(arrayUniqueByKey);
      const legendData = Array.from(uniqueSet).map((x: any, index: number) => ({ typecode: x, color: divergingColors[index] }));
      setMapLegendData(legendData);

      const _mapStyleData = Array.from(uniqueSet).map((x: any, index: number) => ([x, divergingColors[index]])).flat();
      setMapStyleData(_mapStyleData);

      const circleColorStyle: Array<any> = ['match', ['get', 'Site Type Code']];
      circleColorStyle.push(circleColorStyle)
      setCircleColorStyle(circleColorStyle);

      setIsDataReportLoaded(true);
    });
  }, [selectedHUC8Value]);

  useEffect(() => {
    updateLayerStyle();
  }, [isDataReportLoaded]);


  const filterReportData = () => {
    let filterData = [...reportData];
    if (reportData.length > 0) {
      if ((selectedFilterLocations) && (selectedFilterLocations.length > 0) && (selectedFilterLocations.indexOf('All') < 0)) {
        filterData = filterData.filter((o: any) => selectedFilterLocations.indexOf(o["USGS Site Number"]) > -1);
      }
      if ((selectedCategory) && (selectedCategory !== 'All')) {
        if (selectedCategory === 'Above') {
          filterData = filterData.filter((o: any) => o["Water Level Feet"] >= 0);
        } else if (selectedCategory === 'Below') {
          filterData = filterData.filter((o: any) => o["Water Level Feet"] <= 0);
        }
      }
      if ((selectedDateRange) && (selectedDateRange.length > 1)) {
        if (selectedDateRange[0] < selectedDateRange[1]) {
          filterData = filterData.filter((o: any) => o["datetime"] > selectedDateRange[0] && o["datetime"] < selectedDateRange[1]);
        }
      }
      if (filterData.length === 0) {
        toast('No records available in that range. ' + moment(selectedDateRange[0]).format('YYYY MM DD') + ' through ' + moment(selectedDateRange[1]).format('YYYY MM DD'))
      }
      sortFilterReportData([...filterData], isTableSort)
    }
  }

  const sortFilterReportData = (unsortedData: any, ascending: boolean) => {
    let filterData = unsortedData.sort((x: any, y: any) => x["Milliseconds Since Unix Epoch"] - y["Milliseconds Since Unix Epoch"] || x["USGS Site Number"].localeCompare(y["USGS Site Number"]));
    if (!ascending) {
      filterData = unsortedData.sort((x: any, y: any) => y["Milliseconds Since Unix Epoch"] - x["Milliseconds Since Unix Epoch"] || y["USGS Site Number"].localeCompare(x["USGS Site Number"]))
    }
    setFilteredData(filterData);
  }

  const tableSortHandler = () => {
    sortFilterReportData([...filteredData], true)
    setTableSort(true);
  };

  const tableSortHandlerUp = () => {
    sortFilterReportData([...filteredData], false)
    setTableSort(false);
  };

  useEffect(() => {
    filterReportData();

    if (selectedDateRange && selectedDateRange[0] && selectedDateRange[1]) {
      setFilterLabelDays([DateTime.fromJSDate(selectedDateRange[0]).toLocaleString(), DateTime.fromJSDate(selectedDateRange[1]).toLocaleString()]);
    }
  }, [selectedCategory, selectedFilterLocations, selectedDateRange, reportData]);

  useEffect(() => {
    const featureCollectionFromReportData = createFeatureCollection([...filteredData], catalogsContext.huc12Catalog, huc12InfoCatalogArray, selectedHUC8Value);
    setFeatureData(featureCollectionFromReportData);
    if (featureCollectionFromReportData.features.length > 0) {
      if (mapRef && mapRef.current) {
        const mapRef_ = mapRef;
        setTimeout(() => {
          fitBoundsMapData(featureCollectionFromReportData, mapRef_)
        }
          , 400);
      }
    }
  }, [filteredData]);

  function ChartReport(data: any, options: any) {
    return (
      <>
        <div className="chart-line-container">
          <div className="line-chart">
            <Line
              options={options} data={data}
            />
          </div>
        </div>
      </>
    );
  }


  const setChartConfig = (chartAnimation: boolean) => {
    if (!chartAnimation) {
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        scales: {
          y: {
            display: true,
            title: {
              display: true,
              text: 'Water Level (ft)',
              color: 'white'
            },
          },
          x: {
            display: true,
            title: {
              display: true,
              text: 'Date of Measured Level',
              color: 'white'
            },
            type: 'time',
            time: {
              tooltipFormat: 'DD T'
            },
          },
        },
        plugins: {
          legend: {
            position: "bottom" as const,
            labels: {
              usePointStyle: true,
            },
            maxHeight: 70,
          } as const,
          title: {
            display: true,
            text: "Groundwater Level [Feet above specific vertical datum]",
            color: 'white',
            align: 'center',
            padding: 10,
            font: {
              size: 20
            }
          } as const,
          tooltip: {
            padding: 10,
            bodyFont: {
              size: 24,
            },
            titleFont: {
              size: 24,
            },
            boxPadding: 8,
            usePointStyle: true,
            backgroundColor: '#12234f',
            callbacks: {
              label: function (context: any) {
                let label = context.dataset.label || '';
                if (label) {
                  label = [
                    context.dataset.label, d3Format.format(',')(context.parsed.y) + ' ft ',
                  ];
                }
                return label;
              },
            },
          } as const,
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: 'xy',
            },
          } as const,
        } as const,
      } as const;

      setConfig(options);

    } else {
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        animation: true,
        scales: {
          y: {
            display: true,
            title: {
              display: true,
              text: 'Water Level (ft)',
              color: 'white'
            },
          },
          x: {
            display: true,
            title: {
              display: true,
              text: 'Date of Measured Level',
              color: 'white'
            },
            type: 'time',
            time: {
              tooltipFormat: 'DD T'
            },
          },
        },
        plugins: {
          legend: {
            position: "bottom" as const,
            labels: {
              usePointStyle: true,
            },
            maxHeight: 70,
          } as const,
          title: {
            display: true,
            text: "Groundwater Level [Feet above specific vertical datum]",
            color: 'white',
            align: 'center',
            padding: 10,
            font: {
              size: 20
            }
          } as const,
          tooltip: {
            padding: 10,
            bodyFont: {
              size: 24,
            },
            titleFont: {
              size: 24,
            },
            boxPadding: 8,
            usePointStyle: true,
            backgroundColor: '#12234f',
            callbacks: {
              label: function (context: any) {
                let label = context.dataset.label || '';
                if (label) {
                  label = [
                    context.dataset.label, d3Format.format(',')(context.parsed.y) + ' ft ',
                  ];
                }
                return label;
              },
            },
          } as const,
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: 'xy',
            },
          } as const,
        } as const,
      } as const;
      setConfig(options);
    }
  }

  const onChartClickHandler = (event: any) => {
    const currentClick = getElementAtEvent(chartRef.current, event)[0];
    if (currentClick) {
      const currentClickMetaData = chartRef.current.getDatasetMeta(currentClick.datasetIndex);
      const currentSite = currentClickMetaData.label;
      if (userSelectedLocations.indexOf(currentSite) > -1) {
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', [locationField]], '']);
      } else {
        const siteData = filteredData.filter((o: any) => o["USGS Site Number"] === currentSite);
        setPopupInfo(siteData[0]);
        setUserSelectedLocation(currentSite)
        setUserSelectedLocations([currentSite])
        //setMapHoverFilter(['in', ['get', 'USGS Site Number'], currentSite]);
      }
    }
  }

  const tableColumns = useMemo(
    () => [
      { Header: 'USGS Site Number', accessor: 'USGS Site Number' },
      { Header: 'Year', accessor: 'year' },
      { Header: 'Water Level (feet)', accessor: 'Water Level Feet' },
      { Header: 'Accuracy Code', accessor: 'Water Level Accuracy Code' },
      { Header: 'Source Code', accessor: 'Water Level Source Code' },
      { Header: 'Method Type', accessor: 'Water Level Method Type' },
    ],
    []
  );

  const updateLayerStyle = () => {
    const newLayerStyle: LayerProps = {
      id: 'pointlayer',
      type: 'circle' as const,
      paint: {
        'circle-radius': 8,
        'circle-color': ['match', ['get', 'Site Type Code'], 'GW', divergingColors[0], 'GW-TH', divergingColors[1], '#ccc'],
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1,
        'circle-opacity': 0.5
      }
    };
    setLayerStyle(newLayerStyle);
  }

  const pointLayer: LayerProps = {
    id: 'circlepoint',
    type: 'circle',
    paint: {
      'circle-radius': 10,
      'circle-color': '#007cbf'
    }
  };

  const hoverLayerStyle: LayerProps = {
    id: 'hoverlayer',
    type: 'circle' as const,
    paint: {
      'circle-radius': 10,
      'circle-color': '#000099',
      'circle-stroke-color': 'white',
      'circle-stroke-width': 1,
      'circle-opacity': 1
    }
  };

  const onMouseEnter = useCallback((event: any | null) => {
    if (event.features && event.features[0] && event.features[0].properties) {

      let newPopup = event.features[0].properties;
      let newLocation = event.features[0].properties[locationField];
      let newHover = event.features[0].properties[locationField];

      setTimeout(() => {
        setCursor('pointer');
        setPopupInfo(newPopup);
        setUserSelectedLocation(newLocation)
        setMapHoverFilter(['in', ['get', locationField], newHover]);
      }
        , 1);
    }
  }, []);

  const onMouseLeave = useCallback((event: any | null) => {
    setCursor('auto');
    setTimeout(() => {
      setPopupInfo(null);
      setUserSelectedLocation(null)
      setMapHoverFilter(['in', ['get', locationField], '']);
    }
      , 11);
  }, []);


  useEffect(() => {
    if (usLocations.length > 0) {
      if (userSelectedLocations.indexOf(usLocations[0][locationField]) > -1) {
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', locationField], '']);
      } else {
        setUserSelectedLocations(usLocations[0][locationField]);
        setPopupInfo(usLocations[0]);
        setTimeout(() => {
          setMapHoverFilter(['in', ['get', locationField], usLocations[0][locationField]]);
        }, 111);
      }
    }
  }, [usLocations]);

  const onHandleClick = useCallback((event: any | null) => {
    const feature = event.features && event.features[0];
    if (feature) {
      setUsLocations([feature.properties]);
    }
  }, []);

  const onMapLoad = useCallback(() => {
    if (mapRef && mapRef.current) {
      setMapLoaded(true);
      mapRef.current.resize();
    }
  }, []);

  const pointInPolygonUpdate = (pointsSelected: any) => {
        setUserSelectedLocations(pointsSelected);
        setMapHoverFilter(['in', ['get', locationField], ['literal', pointsSelected]]);
  }

  const onDelete = useCallback((e: any | null) => {
    if (mapRef && mapRef.current) {
      setUserSelectedLocations([])
      setPopupInfo(null);
      setMapHoverFilter(['in', ['get', locationField], '']);
    }
  }, []);

  const renderPopup = () => {

    return (
      <Popup

        longitude={Number(popupInfo.centroid_longitude)}
        latitude={Number(popupInfo.centroid_latitude)}
        onClose={() => setPopupInfo(null)}
      >
        <div className="popup-container">
          <div className="popup-content-left">
            <p className="popup-content-right-double">USGS Site No.</p>
            <p className="popup-content-right-double">Site Station Name</p>
            <p>HUC8</p>
          </div>
          <div className="popup-content-right">
            <p className="popup-content-right-double popup-content-highlight">{popupInfo["USGS Site Number"]}</p>
            <p className="popup-content-right-double">{popupInfo["Site Name"]}</p>
            <p> {selectedHUC8Value}</p>
          </div>
        </div>
      </Popup>
    )
  }

  const hoverRowHandler = (event: any) => {

  }

  const clickRowHandler = (event: any) => {
    if (userSelectedLocations && userSelectedLocations.indexOf(event[locationField]) > -1) {
      setUserSelectedLocations([])
      setPopupInfo(null);
      setMapHoverFilter(['in', ['get', locationField], '']);
    } else {
      setUserSelectedLocations([event[locationField]])
      setMapHoverFilter(['in', ['get', locationField], event[locationField]])
      setPopupInfo(event);
      if (mapRef.current) {
        if (event && event.centroid_longitude) {
          mapRef.current.flyTo({
            center: [
              parseFloat(event.centroid_longitude),
              parseFloat(event.centroid_latitude),
            ],
          });
        }
      }
    }
  };

  const onHoverOut = useCallback((e: any | null) => {
    setMapHoverFilter(['in', ['get', locationField], '']);
    setPopupInfo(null);
  }, []);

  const onTableClick = useCallback((e: any | null) => {

  }, []);

  const deselectFilters = useCallback((e: any | null) => {
    setPopupInfo(null);
    setMapHoverFilter(['in', ['get', locationField], '']);
    setUserSelectedLocations([]);
  }, []);

  const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  useEffect(() => setCurrentRef?.(mapRef),[mapRef])

  return (
    <>
      <FullScreenComp handle={fullscreenhandle}>
        <div className="groundwater-levels" id='groundwater-levels-report'>
          <ReportHeader global={global} data={filteredData} filterLabelDays={dateRange}
            reportID={"groundwater-levels-report"} fullScreenClickHandle={fullscreenhandle.enter} />
          <div className="container">
            <div className="col">
              <div className="map-report-container">
                <LoadingDataAnimation dataLoading={!isDataReportLoaded} />
                <Map
                  id="GroundwaterLevels"
                  mapboxAccessToken={settings.maboxKey}
                  mapStyle={global.mapStyle}
                  onLoad={onMapLoad}
                  onClick={(e) => {
                    
                    onHandleClick(e)
                  }}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  cursor={cursor}
                  preserveDrawingBuffer={true}
                  interactiveLayerIds={['pointlayer']}
                  ref={mapRef as Ref<MapRef>}
                  onMoveEnd={(e) => {
                    global.setViewport({
                      longitude: e.viewState.longitude,
                      latitude: e.viewState.latitude,
                      zoom: e.viewState.zoom,
                    })
                  }
                  }
                >
                  {featureData && (
                    <Source id="circlesource" type="geojson" data={featureData}>
                      <Layer {...layerStyle} />
                      <Layer {...hoverLayerStyle} filter={mapHoverFilter} />
                    </Source>
                  )}

                    <MapAddition global={global} 
                                mapRef={mapRef}
                                onDelete={onDelete}
                                PointInPolygonField={locationField}
                                PointInPolygonUpdate={pointInPolygonUpdate}
                                position={'low'}
                                zipOff={true}
                                statusMessage={statusMessage}/>

                  {popupInfo && renderPopup()}

                  <div className='map-legend-container' onClick={deselectFilters}>
                    <MapLegend
                      mapLegendData={mapLegendData}
                      title={'Site Type Code'}
                      global={global}
                    />
                  </div>
                </Map>

              </div>
            </div>

            <div className="col-7">
              <div className="row chart-container-parent">
                <div className={isFullScreenChartActive ? 'expand-chart-container chart-container' : 'chart-container'}>
                  <div className="report-chart-options">
                    <Button onClick={fullscreenChartContainerHandler}>
                      <img src={fullscreenIcon} />
                    </Button>
                  </div>
                  {featureData && (
                    <div className="chart-line-container">
                      <div className="line-chart">
                        <Line options={config}
                          data={data}
                          ref={chartRef}
                          onClick={onChartClickHandler}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div
                className={isFullScreenActive ? 'row gx-0 table-chart-row table-parent-container expand-container' : 'row gx-0 table-chart-row table-parent-container'}
                onMouseLeave={onHoverOut}
                onClick={onTableClick}>
                <div className="report-options">
                  <Button variant="light" onClick={fullscreenContainerHandler}>
                    <img src={fullscreenIcon} />
                  </Button>
                </div>
                <ReportTableExpanded
                  data={filteredData}
                  columns={tableColumns}
                  clickRowHandler={clickRowHandler}
                  clickLoadStop={true}
                />
              </div>
              <ReportFooter />
            </div>
          </div>
        </div>
        <MapSlider global={global} />
      </FullScreenComp>
    </>
  );
}

type GroundwaterLevelsModel = {
  state: string;
  "Site Name": string;
  "Site Type Code": string;
  "USGS Site Number": number;
  "Water Level Accuracy Code": string;
  "Water Level Feet": number;
  "Water Level Method Type": string;
  "Water Level Source Code": string;
  lat: number;
  lng: number;
};
