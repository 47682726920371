import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';
import checkCircleImg from '../../assets/check-circle.svg'
import { layerList } from '../../features/utils'

function LayerItem({
    img,
    name,
    selected,
    setSelected,
    disabled,
    global = null,
    country = null,
    state = null,
}: any) {

    const [active, setActive] = useState(selected);

    useEffect(() => {
        setActive(selected);
    }, [selected]);

    const onClick = () => {
        const newActive = !active;
        setActive(newActive);
        setSelected(newActive);
        if (country && global.currentCountry !== country) global.setCurrentCountry(country);
        if (state && global.currentState !== state) global.setCurrentState(state);
    };

    return (
        <div    
            id={name}
            key={uuidv4()}
            style={{ pointerEvents: disabled ? 'none' : 'auto', opacity: disabled ? .3 : 1 }}
            className={active ? 'thumbnail selected' : 'thumbnail'}
            onClick={onClick}
        >
            <img className='layer' src={img} />
            <img className='check' src={checkCircleImg} />
            {disabled && <img className='check' src={checkCircleImg} />}
            <div className='description'>{name}</div>
        </div>
    );
}

export default LayerItem
