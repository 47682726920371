import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

// Register the required components and the zoom plugin
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin // Register the zoom plugin
);

const BarChart = ({ global, reportData }: any) => {
  const { 
    CWATimeRange, 
    setCWAStartTime,
    setCWAMaxTime
  } = global

  const times = global.CWATimeRange || {};
  const { startTime, endTime } = times;


  const [formattedData, setFormattedData] = useState<any>(null);
  const [filteredData, setFilteredData] = useState<null | any[]>()
  
  const convertToUnix = (dateString: string): number => Math.floor(new Date(dateString).getTime());
  const findMinMaxUnix = (timestamps: number[]): { min: number, max: number } => ({
    min: Math.min(...timestamps),
    max: Math.max(...timestamps)
  });

  useEffect(() => {
    if (formattedData) {
      const { labels } = formattedData

      let unixTimestamps: any[] = []
      labels.forEach((label: string) => unixTimestamps.push(convertToUnix(label)))

      if(unixTimestamps.length > 0) {
        const { min, max } = findMinMaxUnix(unixTimestamps)
        setCWAStartTime(min);
        setCWAMaxTime(max);
      }
    }
  }, [formattedData])

  const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const transformData = (inputArray: any[]) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(?::\d{2})?(?:Z)?$/; 
    const output: any = {
      labels: [],
      datasets: []
    };
    
    inputArray.forEach((obj: any, index: number) => {
      let foundDate = false;
    
      Object.keys(obj).forEach(key => {
        if (dateRegex.test(obj[key])) {
          foundDate = true;
          output.labels.push(obj[key]); 
        }
      });
      
      if (!foundDate) {
        output.labels.push(`Unknown Date ${index + 1}`);
      }
    });
  
    
    inputArray.forEach((obj: any, index: any) => {
      Object.keys(obj).forEach(key => {
        if (!dateRegex.test(obj[key])) { 
          let dataset = output.datasets.find((entry: any) => entry.label === key);
          const color = generateRandomColor();
          
          if (!dataset) {
            dataset = {
              label: key,
              data: Array(output.labels.length).fill(0), 
              borderColor: color,
              backgroundColor: color,
              pointBackgroundColor: color,
              fill: false,
              pointRadius: 4,
              pointHoverRadius: 5,
              pointBorderColor: 'white',
              tension: 0.1,
            };
            output.datasets.push(dataset);
          }
  
          
          const dateKey = Object.keys(obj).find(k => dateRegex.test(obj[k]));
          const dateStr = dateKey ? obj[dateKey] : `Unknown Date ${index + 1}`;
          const dateIndex = output.labels.indexOf(dateStr);
  
          if (dateIndex > -1) {
            dataset.data[dateIndex] = obj[key] || 0;
          }
        }
      });
    });
  
    return output;
  };
  
  useEffect(() => {
    if (reportData && reportData.length > 0) {
      const data = transformData(reportData);
      setFormattedData(data);
    }
  }, [reportData]);

  const filterData = (data: any[], min: number, max: number) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(?::\d{2})?(?:Z)?$/;
  
    const filteredData = data.filter((obj) => {
      for (const key in obj) {
        if (dateRegex.test(obj[key])) {
          const unixTimestamp = convertToUnix(obj[key]);
  
          if (unixTimestamp >= min && unixTimestamp <= max) {
            return true;
          }
        }
      }
      return false; 
    });
  
    return transformData(filteredData);
  };

  useEffect(() => {
    if(startTime && endTime) {
      const filtered = filterData(reportData, (startTime * 1000), (endTime * 1000))

      setFilteredData(filtered)
    }
  },[
    formattedData,
    startTime,
    endTime
  ])

  const options: any = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          color: 'white',
          font: {
            size: 16,
          },
        },
        ticks: {
          color: 'white',
        },
      },
      x: {
        display: true,
        title: {
          display: true,
          text: 'Date',
          color: 'white',
          font: {
            size: 16,
          },
        },
        ticks: {
          color: 'white',
        },
      },
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        color: 'white',
        font: {
          size: 18,
        },
      },
      zoom: {
        pan: {
          enabled: true, 
          mode: 'x', 
        },
        zoom: {
          wheel: {
            enabled: true, 
          },
          pinch: {
            enabled: true, 
          },
          mode: 'x', 
        },
      },
    },
  };

  return formattedData && <Bar data={formattedData} options={options} />;
};

export default BarChart;
