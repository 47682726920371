// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cluster-list-marker {
  color: black;
  background: rgba(255, 255, 255, 0);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; }

.list-marker {
  background: none;
  border: none; }

img {
  padding: 0; }

.list-marker img {
  width: 25px; }

.popup-card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem; }
  .popup-card h3 {
    font-size: 16px;
    color: #4fc2d9; }
  .popup-card h4 {
    font-size: 12px; }

.sw h3 {
  font-size: 12px;
  color: white; }

.mapboxgl-popup-content {
  width: 100% !important; }

.surface-water {
  color: black;
  background: rgba(255, 255, 255, 0);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #0692ae; }

.popup-report-list {
  width: 50%;
  margin-bottom: 4px;
  margin-top: -8px; }
  .popup-report-list li {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
    color: #0d6efd; }
`, "",{"version":3,"sources":["webpack://./src/components/water-quality-list/water-quality-list.scss"],"names":[],"mappings":"AAEA;EACE,YAAY;EACZ,kCAAkC;EAClC,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EAEnB,WAAW,EAAA;;AAGb;EACE,gBAAgB;EAChB,YAAY,EAAA;;AAGd;EACE,UAAU,EAAA;;AAGZ;EACE,WAAW,EAAA;;AAGb;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;EACjB,oBAAoB,EAAA;EANtB;IAQI,eAAe;IACf,cAAc,EAAA;EATlB;IAYI,eAAe,EAAA;;AAInB;EAEI,eAAe;EACf,YAAY,EAAA;;AAIhB;EACE,sBAAsB,EAAA;;AAGxB;EACE,YAAY;EACZ,kCAAkC;EAClC,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB,EAAA;;AAG3B;EACE,UAAU;EACV,kBAAkB;EAClB,gBAAgB,EAAA;EAHlB;IAKI,eAAe;IACf,0BAA0B;IAC1B,iBAAiB;IACjB,cAAc,EAAA","sourcesContent":["// Clusters\n\n.cluster-list-marker {\n  color: black;\n  background: rgba(255, 255, 255, 0);\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  // border: 3px solid #0d6efd;\n  width: 40px;\n}\n\n.list-marker {\n  background: none;\n  border: none;\n}\n\nimg {\n  padding: 0;\n}\n\n.list-marker img {\n  width: 25px;\n}\n\n.popup-card {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n  h3 {\n    font-size: 16px;\n    color: #4fc2d9;\n  }\n  h4 {\n    font-size: 12px;\n  }\n}\n\n.sw {\n  h3 {\n    font-size: 12px;\n    color: white;\n  }\n}\n\n.mapboxgl-popup-content {\n  width: 100% !important;\n}\n\n.surface-water {\n  color: black;\n  background: rgba(255, 255, 255, 0);\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 3px solid #0692ae;\n}\n\n.popup-report-list {\n  width: 50%;\n  margin-bottom: 4px;\n  margin-top: -8px;\n  li {\n    cursor: pointer;\n    text-decoration: underline;\n    font-weight: bold;\n    color: #0d6efd;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
