// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../fonts/Urbanist-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Urbanist';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); }

.te-map-title {
  width: auto;
  white-space: nowrap;
  position: absolute;
  left: 59px;
  top: 0px;
  font-size: 1.25rem;
  padding: 5px;
  z-index: 9;
  color: white; }
`, "",{"version":3,"sources":["webpack://./src/theme/_variables.scss","webpack://./src/features/reports/shared/MapTitle.scss"],"names":[],"mappings":"AAqBA;EACE,uBAAuB;EACvB,4CAAmD,EAAA;;ACrBrD;EACI,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,YAAY,EAAA","sourcesContent":["$primaryFontColor: #fff;\n$secundaryFontColor: #4fc2d9;\n$thirdFontColor: #0b1736;\n$topMenuBackgroundColor: #0b1736;\n$sideBackgroundColor: #002044;\n$sideSubMenuBackgroundColor: #0b1736;\n$fontFamily: 'Urbanist';\n$leftMenuWidth: 5rem;\n$rightMenuWidth: 400px;\n$navBrandImageSize: 150px;\n$darkAndLightModeBackgroundColor: #12234f;\n$dropdownFiltersBackgroundColor: #060e21;\n$inputFocusBackgroundColor: #060e21;\n$containerScreenBackgroundColor: #050e25;\n$tableBackgroundColor: #0b1736;\n$secondarySubtitleColor: #838487;\n\n//FORMS\n$inputBackgroundColor: #021d3e;\n$btnPrimary: #257ee0;\n\n@font-face {\n  font-family: 'Urbanist';\n  src: url('../fonts/Urbanist-VariableFont_wght.ttf');\n}\n","@import \"../../../theme/_variables.scss\";\n \n.te-map-title {\n    width: auto;\n    white-space: nowrap;\n    position: absolute;\n    left: 59px; \n    top: 0px;\n    font-size: 1.25rem;\n    padding: 5px;\n    z-index: 9;\n    color: white;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
