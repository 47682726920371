import {
  useMemo, useState, useEffect
} from 'react'
import ReportTable from '../../../reports/shared/ReportTable'
import {
  Button,
  Form
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPenToSquare,
  faTrashCan
} from '@fortawesome/pro-solid-svg-icons';
import { exportToCsv } from '../../../utils';
import { submitWQX } from '../lib/data';

const RolesTable = (props: any) => {

  const {
    setModalOpen,
    modalOpen,
    modalList,
    setModalList,
    setAction,
    setData,
    data,
    settings,
    setSettings
  } = props

  const [tableColumns, setTableColumns] = useState < any[] > ([]);
   

  const handleExport = (event: any) => {
        exportToCsv(data, 'Projects ')
  }

  const handleSubmit = (table: any) => {
      submitWQX(table.row.original, handleSubmitReturn);
  }

  const handleActive= (table: any) => {
      
  }

  const handleSubmitReturn = (result: any) => {
     
  }



    useEffect(() => {
        let tableCol = [];
        tableCol.push(
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: 'Name',
              accessor: 'name',
            },
            {
              Header: 'Description',
              accessor: 'description',
            },
            {
              Header: 'Active',
              accessor: 'active',

                Cell: (props: any) => (
                    <div className="action-box">
                         <Form.Check
                            style={{marginLeft: '30%'}}
                            value={props.value}
                            defaultChecked={props.value}
                            onClick={() => {
                                handleActive(props)            
                            }}
                        />
                    </div>
                ),
            });

        if(modalList && modalList.length > 0){
            for (let dataItem of modalList) {
                if(dataItem.active){
                    tableCol.push( {
                       Header: dataItem.field,
                       accessor: dataItem.field,
                    });
                }
            }
        }

        tableCol.push(  {
                Header: 'Actions',
                accessor: 'actions',
                //@ts-ignore
                Cell: (props: any) => {
                    return (
                        <div className="action-box" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop:5}}>
                            <div
                                onClick={() => { }}
                                className='action-cell'
                            >
                                <FontAwesomeIcon onClick={() => {
                                    setAction('edit')
                                    //setData(rowData)
                                    setData(props.row.original)
                                }} style={{ color: '#4fc2d9', cursor: 'pointer' }} icon={faPenToSquare} />
                            </div>
{/*                            <div
                                onClick={() => {
                                    handleSubmit(props)            
                                }}
                                className='action-cell'
                                style={{padding:5}}
                            >
                                <FontAwesomeIcon style={{ color: '#4fc2d9', cursor: 'pointer' }} icon={faTrashCan} />
                            </div>*/}
                        </div>
                    )
                }
            });

            setTableColumns(tableCol)

    }, [modalList])




  return (
    <div className='roles-container'>
      <div className="header-box">
        <h2>Roles</h2>
        <div className="button-box">
          <Button onClick={() => setAction('create')} id="addNew-btn" className="p-3 btn btn-primary m-2">
            Add New
          </Button>
        </div>
      </div>
      <ReportTable columns={tableColumns} data={data} />
    </div>
  )
}

export default RolesTable