import { useEffect, useState, useRef, useContext } from 'react';
import { Form, Row, Col, Dropdown, Button, ButtonGroup, OverlayTrigger, Popover, Tooltip, Accordion, Spinner } from "react-bootstrap";
import Draggable from 'react-draggable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faQuestion, faCircle } from '@fortawesome/pro-solid-svg-icons'
import { contams } from '../../utils'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { v4 as uuidv4 } from 'uuid';
import bbox from '@turf/bbox';
import center from '@turf/center';
import { fitBoundsMapData } from './report-data';
import { marksNOAAMix } from './MapSliderMarks';
import { GeoJsonProperties,
           Point,
           FeatureCollection,
         } from 'geojson';
import { RefContext } from "../../../RefContext";
import { DateTime } from 'luxon';
import * as d3Time from 'd3-time';
import { getLastUpdatedDate, adjustHexBrightness, adjustHSLABrightness  } from "../../utils";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { legendNwshazard } from "../../../components/Layers/NWSHazard/legendNwshazard";
import { legendNwshazardDefault } from "../../../components/Layers/NWSHazard/legendNwshazardDefault";
import { QPElegend } from "../../../components/Layers/NOAAMix/QPElegend";
import './MapSlider.scss';

const MapSlider = ({
  global
}: {
  global?: any;
}) => {

  const path = window.location.pathname;
  const { currentRef } = useContext(RefContext);
    // @ts-ignore
  const mapInstance = currentRef && currentRef?.current as any;

  const [disableDrag, setDisableDrag] = useState(false)

  let defaultYearMonth = 0;
  const [yearMonthSlider, setYearMonthSlider] = useState(defaultYearMonth); //integer
  const [hourlySlider, setHourlySlider] = useState(24); //integer
  const [hourlySliderLabel, setHourlySliderLabel] = useState(''); //integer

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const [rcvalueSlider, setRcvalueSlider] = useState(6); //QPE Precipitation
  const [observationCategory, setObservationCategory] = useState('observed'); 
  const [legendValues, setLegendValues] = useState<any[]>(legendNwshazardDefault);

  const [legendItems, setLegendItems] = useState<any[]>(legendNwshazardDefault); //nwshazard
  const [loadingActive, setLoadingActive] = useState(false);

  const [legendItemsFHO, setLegendItemsFHO] = useState<any[]>(); //FHO
  const [legendFHOHydromet, setLegendFHOHydromet] = useState();

  useEffect(() => {
    if(global.nwshazard){
      setLoadingActive(true);      
          setTimeout(() => {
            setLoadingActive(false)
          }, 7777);  
    }    
  }, [global.nwshazard]);

  useEffect(() => {
    if(global.NOAAMix){
      updateLegendValues(observationCategory, rcvalueSlider);
    }    
  }, [global.NOAAMix]);

  useEffect(() => {
    if(global.FHO){
      let _imageUrl = `https://mapservices.weather.noaa.gov/experimental/services/owp_fho/MapServer/WFSServer?request=GetFeature&outputFormat=GEOJSON&service=WFS&typeName=owp_fho:Days_1-7`
       fetch(_imageUrl)
          .then((response) => response.text())
          .then((responseText) => {            
            if(responseText){
              let responseItem = JSON.parse(responseText);
              setLegendItemsFHO(responseItem && responseItem.features  && responseItem.features  )
            }
          });
       Hydromet();
    }    
  }, [global.FHO]);

  useEffect(() => {
    if(global.FloodInd){
      let nevent = hourlySlider;
      //@ts-ignore
      let timeRange = d3Time.utcHour.every(1).range(d3Time.utcHour.offset(new Date(), -24), d3Time.utcHour.offset(new Date(), 72));
      if (nevent) {      
        setHourlySliderLabel(DateTime.fromJSDate(timeRange[nevent]).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY) + ' ' + DateTime.local().zoneName)
      }
      setHourlySlider(nevent);
    }    
  }, [global.FloodInd]);

  useEffect(() => {
    let interval:any;
    let id = 'NWSHazard'
    const fetchData = async () => {
      try {
        if (mapInstance) {
          const features = await mapInstance.querySourceFeatures(`${id}-layer`,`${id}-layer`); 
            const HazardTypeLabels = uniqueValues(features, 'Hazard_Type');
            const filtered_list = legendNwshazard.filter(function(f:any) {
                         return (HazardTypeLabels.indexOf(f.label)>-1);
                     })
            setLegendItems(filtered_list)
        }
      } catch (error) {
        console.log(error);
      }
    };

    if(global.nwshazard){
        interval = setTimeout(fetchData, 11155);
    }    
  }, [mapInstance]);



  const uniqueValues = (filteredData:any, itemField:string) => { 
    const uniqueArray = filteredData.map((item:any) => item.properties[itemField])
    .filter((value:any, index:any, self:any) => self.indexOf(value) === index)
    .filter((value:any, index:any, self:any) => value)
    .sort();    
    return uniqueArray;
  };


  const onChangeEddi = (event: any) => {
    if (event.target.value && global.yearMonthRange) {
      let yMonth = global.yearMonthRange[event.target.value];
      global.setSelectedYearMonth(yMonth);
    }
    setYearMonthSlider(event.target.value);
  }

  const onChangeFloodInd = (event: any) => {
    //console.log(d3Time.timeDay.count(d3Time.timeYear(d3Time.utcHour()), d3Time.utcHour()), d3Time.utcHour() , d3Time.utcHour.floor(new Date()), d3Time.utcDay.floor(new Date()) )
    //console.log(d3Time.timeHour.count(d3Time.utcHour.floor(new Date()), d3Time.utcHour()), d3Time.utcHour() , d3Time.utcHour.floor(new Date()), d3Time.utcDay.floor(new Date()) )
    //@ts-ignore
    let timeRange = d3Time.utcHour.every(1).range(d3Time.utcHour.offset(new Date(), -24), d3Time.utcHour.offset(new Date(), 72));
    if (event) {      
      setHourlySliderLabel(DateTime.fromJSDate(timeRange[event]).setZone('local').toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY) + ' ' + DateTime.local().zoneName)
    }
    setHourlySlider(event);
  }

  const onChangeCompleteFloodInd = (event: any) => {
    //@ts-ignore
    let timeRange = d3Time.utcHour.every(1).range(d3Time.utcHour.offset(new Date(), -24), d3Time.utcHour.offset(new Date(), 72));
    if (event) {
      let newDateTime = DateTime.fromJSDate(timeRange[event]).toUTC();
      global.setLayerMode({layer: 'Flood_Ind', category: newDateTime })    

    }
  }

  const onChangeNOAAMix = (event: any) => {
    if (event||event===0) {
    	setRcvalueSlider(event);
    	//@ts-ignore
    	const range = marksNOAAMix[event as index];
    	if(range.label){
    		global.setLayerMode({range:range.label, category: observationCategory})	
        updateLegendValues(observationCategory, range.label)
    	} 	      
    }
  }

  const onChangeObservationCategory = (event: any) => { //NOAAMix QPE
    if (event) {
    	setObservationCategory(event) 
    	//@ts-ignore
    	const range = marksNOAAMix[rcvalueSlider as index];
    	if(range.label){
    		global.setLayerMode({range:range.label, category: event})	
        updateLegendValues(event, range.label)
    	} 	
    }
  }


  const onChangeObservationCategoryDrought = (event: any) => {
    if (event) {
        setObservationCategory(event) 
        global.setLayerMode({layer:'drought', category: event})    
    }
  }

  const updateLegendValues = (obsCategory: any, duration:any) => {
      let dayConversion = {'Today' : '1day', 'Last 7 days' : '7day', '14 days': '14day',  '30 days': '30day', '180 days': '180day', '365 days': '365day', 'YTD': 'ytd'} 
      let obsConversion = {'observed' : 0, 'normal' : 1, 'departure': 2, 'percent': 3} //@ts-ignore
      let newScale = QPElegend.QPE.products[obsConversion[obsCategory]].layers.find((i:any) => i.id === `qpe/${obsCategory}/${dayConversion[duration]}`)//@ts-ignore

      let newRamp = newScale?.colorRamp||0;
      let colorRamp = QPElegend.QPE.colorRamps[newRamp||0];
      if(colorRamp && colorRamp.ramp){
          setLegendValues(colorRamp.ramp)
      }
  }


  const popoverNOAAMix = (
    <Popover id="mapslider-categories-popover" className="mapslider-categories-popover" key={"mapslider-categories-popover-"}>
      <Popover.Header as="h3">NOAA/National Weather Service Precipitation Analysis</Popover.Header>
      <Popover.Body  className="mapslider-categories-popover-body" >

      	<p><span style={{fontWeight: 'bold'}}>"Observed"</span> data is a byproduct of National Weather Service (NWS) operations at the 12 CONUS River Forecast Centers (RFCs), and is displayed as a gridded field with a spatial resolution of roughly 4x4 km.</p>
      	<p><span style={{fontWeight: 'bold'}}>"Normal"</span> precipitation is derived from PRISM climate data, created at Oregon State University. The PRISM gridded climate maps are considered the most detailed, highest-quality spatial climate datasets currently available. The 30 year PRISM normal from 1981-2010 is used for precipitation analysis since 2004. Prior to 2004 the 30 year PRISM normal from 1961-1990 is used.</p>

        <p><span style={{fontWeight: 'bold'}}>"Departure from Normal" and "Percentage of Normal"</span> are generated by simple grid mathematics, where the "Normal" dataset is respectively subtracted from or divided into the "Observed" dataset..</p> 
      	<p>Display and/or analyses requiring spatially distributed departure from normal precipitation for the following time periods last 7 days, last 14 days, last 30 days, last 60 days, last 90 days, last 180 days, month to date, year to date, and water-year to date (Oct 1) for the climatological periods 1981-2010 (used since 2004), 1961-1990 (used before 2004).</p>
 
        <p> </p>
        <a target='_blank' className='report-footer-source-link' href={'https://water.weather.gov/precip/about.php'}>
                {' '}
                {'NOAA/National Weather Service'}
            </a>
      </Popover.Body>
    </Popover>
  );



  const popoverdrought = (
    <Popover id="mapslider-categories-popover" className="mapslider-categories-popover" key={"mapslider-categories-popover-"}>
      <Popover.Header as="h3">U.S. Seasonal Drought Outlook</Popover.Header>
      <Popover.Body  className="mapslider-popover-body" >
        <p>The outlook is issued on the third Thursday or each month and predicts whether drought will persist, develop, improve, or be removed over the next three months or so. </p>
        <p>Climate scientists base future climate outlooks on current patterns in the ocean and atmosphere. They examine projections from climate and weather models and consider recent trends. They also check historical records to see how much precipitation fell when patterns were similar in the past.</p>
        <a  target='_blank' className='report-footer-source-link' href={'https://www.cpc.ncep.noaa.gov/products/expert_assessment/sdo_discussion.php'}>{'Discussion for the Seasonal Drought Outlook'}</a>
        
        <img style={{height: '50vh', width: '100%', marginTop: 3, marginBottom: 3}} src={`https://www.cpc.ncep.noaa.gov/products/expert_assessment/season_drought.png`} />
        <a target='_blank' className='report-footer-source-link' href={'https://www.cpc.ncep.noaa.gov/products/expert_assessment/sdo_summary.php'}>
                {' '}
                {'NOAA/National Weather Service/NCEP'}
        </a>
      </Popover.Body>
    </Popover>
  );


  const popovernwshazard = (
    <Popover id="mapslider-categories-popover" className="mapslider-categories-popover" key={"mapslider-categories-popover-"}>
      <Popover.Header as="h3">NOAA All Hazards — Watches, Warnings, Advisories</Popover.Header>
      <Popover.Body  className="mapslider-popover-body" >
        <p>Single source for comprehensive weather and emergency information. Warnings are broadcast for both natural (such as tsunamis and volcanoes)
          and man-made events (such as Amber Alerts, toxic chemical and biological releases, and terrorist attacks) as necessary.</p>
        <p><span style={{fontWeight: 'bold'}}>Warning: </span>A warning is issued when a hazardous weather or hydrologic event is occurring, is imminent, or has a very high probability of occurring. A warning is used for conditions posing a threat to life or property.</p>
        <p><span style={{fontWeight: 'bold'}}>Watch: </span>A watch is used when the risk of a hazardous weather or hydrologic event has increased significantly, but its occurrence, location, and/or timing is still uncertain. It is intended to provide enough lead time so that those who need to set their plans in motion can do so.</p>
        <p><span style={{fontWeight: 'bold'}}>Advisory: </span>The expected weather condition has a pretty good chance of occurring, even a likely chance of occurring, but typically an advisory is used for less severe type of weather conditions. A Wind Advisory might be issued or a Freezing Rain Advisory issued instead of a High Wind Warning or an ice Storm Warning.</p> 
        <p> </p>
        <a target='_blank' className='report-footer-source-link' href={'https://www.weather.gov/'}>
                {' '}
                {'NOAA/National Weather Service All Hazards'}
        </a>
      </Popover.Body>
    </Popover>
  );

  const popoverstreamflow = (
    <Popover id="map
        slider-categories-popover" className="mapslider-categories-popover" key={"mapslider-categories-popover-"}>
      <Popover.Header as="h3">NWM Streamflow Anomaly Analysis</Popover.Header>
      <Popover.Body  className="mapslider-popover-body" >
        <p>National Water Model(NWM) Stream Analysis is built by processing the analysis assimilation output of the National Water Model river network based data. All output of the river network dataset have the same geospatial extent covering all of CONUS with partial coverage into Canada and Mexico. Currently, the service contains two layers: one depicting streamflow values and the other indicating streamflow anomalies.

            The streamflow layer colorizes streams according to the rate in which water is flowing through the stream while the anomaly layer colorizes streams according to how far their flow values deviate from normal flow values. All flow values are in cubic feet per second (CFS). </p>
         <p> </p>
        <a target='_blank' className='report-footer-source-link' href={'https://www.weather.gov/'}>
                {' '}
                {'NWM Streamflow Anomaly Analysis'}
        </a>
      </Popover.Body>
    </Popover>
  );


  let colorPaletteArray = [{color: '#dcdcdc', value: 0, order:20, orderD:6}, {color: '#7d7d7d', value: 0, order:0, orderD:0}, {color: '#14c8fa', value: 5, order:1, orderD:11}, {color: '#3d85c6', value: 5, order:2, orderD:12}, {color: '#001496', value: 5, order:2.5, orderD:12}, {color: '#00fa14', value: 5, order:3, orderD:9}, {color: '#00a00f', value: 5, order:4, orderD:9.5}, {color: '#00640a', value: 5, order:5, orderD:10}, {color: '#fafa00', value: 5, order:6, orderD:6}, {color: '#ffd966', value: 5, order:7, orderD:5}, {color: '#fa9600', value: 5, order:8, orderD:4}, {color: '#fa0000', value: 5, order:9, orderD:3}, {color: '#af0000', value: 5, order:10, orderD:2}, {color: '#7d0000', value: 5, order:11, orderD:1}, {color: '#db00db', value: 5, order:12, orderD:13}, {color: '#7d4be1', value: 5, order:13, orderD:12}]

  const renderColorPalette = () => {
  	let colorSort = colorPaletteArray.sort(function (a: any, b: any) { return a.order - b.order; });
  	if(observationCategory==='departure'||observationCategory==='percent'){
  		colorSort = colorPaletteArray.sort(function (a: any, b: any) { return a.orderD - b.orderD; });
  	}
    let legendValuesreverse= [...legendValues].reverse()

    return colorSort.map((item: any, index:any) => {
      return (
		<div key={uuidv4()} style={{width: 30, height: 24, backgroundColor: item.color, position: 'relative'}}>
			<span style={{display:'inline-block'}} className={'mapslider-color-span'}>{legendValuesreverse[index][2]}</span> 
		</div>
      )
    })
  }

  const renderColorPaletteStream = () => {
    let colorSort = colorPaletteArray.sort(function (a: any, b: any) { return a.order - b.order; });
    if(observationCategory==='departure'||observationCategory==='percent'){
      colorSort = colorPaletteArray.sort(function (a: any, b: any) { return a.orderD - b.orderD; });
    }
    let legendValuesreverse= [...legendValues].reverse()

    return colorSort.map((item: any, index:any) => {
      return (
    <div key={uuidv4()} style={{width: 30, height: 24, backgroundColor: item.color, position: 'relative'}}>
      <span style={{display:'inline-block'}} className={'mapslider-color-span'}>{legendValuesreverse[index][2]}</span> 
    </div>
      )
    })
  }

  const yearInput = document.getElementById('yearInput')

  useEffect(() => {
    if(yearInput) {
      yearInput.addEventListener("mousedown", () => setDisableDrag(true))
      yearInput.addEventListener("mouseup", () => setDisableDrag(false))
    }
  }, [yearInput])

  const ref = useRef()

  useEffect(() => {
    if (global.yearMonthRange) {
      defaultYearMonth = global.yearMonthRange.length - 1;//defaultYearMonth = global.yearMonthRange[global.yearMonthRange.length-1];
      setYearMonthSlider(defaultYearMonth)
    }
  }, [global.yearMonthRange]);

  const handleLegendCatagories = (input: any) => {
    if (input === '1. Water Scarcity' || input === '2. Flooding' || input === '3. Water Quality' || input === '4. Ecosystem Services Status') {
      global.setWRFCCategory('Physical Risk')
      global.setWRFCRiskCategory(input)
    }

    if (input === '6. Institutions & Governance' || input === '7. Management Instruments' || input === '8. Infrastructure & Finance') {
      global.setWRFCCategory('Regulatory Risk')
      global.setWRFCRiskCategory(input)
    }

    if (input === '9. Cultural Importance' || input === '10. Biodiversity Importance' || input === '11. Media Scrutiny' || input === '12. Conflict') {
      global.setWRFCRiskCategory(input)
    }

    if (input === 'Basin Physical Risk' || input === 'Basin Regulatory Risk' || input === 'Basin Reputational Risk') {
      global.setWRFCCategory('')
      global.setWRFCRiskCategory(input)
    }
  }

  const slider = document.querySelector('.slider-input')
  const toGrab = document.getElementById('toGrab')

  useEffect(() => {
    if (toGrab) {
      toGrab.addEventListener('mousedown', () => {
        toGrab.style.cursor = "move"
      })
      toGrab.addEventListener('mouseup', () => {
        toGrab.style.cursor = "unset"
      })
    }
  }, [toGrab])

  useEffect(() => {
    if (slider) {
      slider.addEventListener('mousedown', () => setDisableDrag(true))
      slider.addEventListener('mouseup', () => setDisableDrag(false))
    }
  }, [slider])


  const renderContams = () => {
    return contams.map((contam:any) => {
      return <option>{contam}</option>
    })
  }

  const renderWRFCOptions = () => {
    const wrfcValueList = ['1. Water Scarcity', '2. Flooding', '3. Water Quality', '4. Ecosystem Services Status', '6. Institutions & Governance', '7. Management Instruments', '8. Infrastructure & Finance', '9. Cultural Importance', '10. Biodiversity Importance', '11. Media Scrutiny', '12. Conflict', 'Basin Physical Risk', 'Basin Regulatory Risk', 'Basin Reputational Risk']

    return wrfcValueList.map((element: any, index: any) => {

      if (index === 0) {
        return (
          <option selected>
            {element}
          </option>
        )
      } const renderWRFCOptions = () => {

        const wrfcValueList = ['1. Water Scarcity', '2. Flooding', '3. Water Quality', '4. Ecosystem Services Status', '6. Institutions & Governance', '7. Management Instruments', '8. Infrastructure & Finance', '9. Cultural Importance', '10. Biodiversity Importance', '11. Media Scrutiny', '12. Conflict', 'Basin Physical Risk', 'Basin Regulatory Risk', 'Basin Reputational Risk']

        return wrfcValueList.map((element: any, index: any) => {

          if (index === 0) {
            return (
              <option selected>
                {element}
              </option>
            )
          }

          return (
            <option>
              {element}
            </option>
          )
        })
      }

      return (
        <option>
          {element}
        </option>
      )
    })
  }


  const renderCustomValueOptions = () => {
    const wrfcValueList = ['Ingredient Sourcing',
      'Coastal Flood Risk',
      'Located within a Protected Area',
      'Riverine Flood Risk',
      'Site Water Security / Distress',
      'Water Management']

    return wrfcValueList.map((element: any, index: any) => {

      if (index === 0) {
        return (
          <option selected>
            {element}
          </option>
        )
      }

      return (
        <option>
          {element}
        </option>
      )
    })
  }

  const handleCustomClose = () => {
    global.customLayer1 && global.setCustomLayer1(false)
    global.customLayer2 && global.setCustomLayer2(false)
    global.customLayer3 && global.setCustomLayer3(false)
    global.customLayer4 && global.setCustomLayer4(false)
    global.customLayer5 && global.setCustomLayer5(false)
    global.customLayer6 && global.setCustomLayer6(false)
    global.customLayer7 && global.setCustomLayer7(false)
    global.customLayer8 && global.setCustomLayer8(false)
    global.customLayer9 && global.setCustomLayer9(false)
    global.customLayer10 && global.setCustomLayer10(false)
    global.customLayer11 && global.setCustomLayer11(false)
    global.customLayer12 && global.setCustomLayer12(false)
    global.customLayer13 && global.setCustomLayer13(false)
    global.customLayer14 && global.setCustomLayer14(false)
    global.customLayer15 && global.setCustomLayer15(false)
    global.customLayer16 && global.setCustomLayer16(false)
    global.customLayer17 && global.setCustomLayer17(false)
    global.customLayer18 && global.setCustomLayer18(false)
    global.customLayer18 && global.setCustomLayer18(false)
    global.customLayer20 && global.setCustomLayer20(false)
  }

  const handleCVICatagories = (input: any) => {
    if (input === 'Raw CVI Overall') global.setCVIValue('raw_cvi_overall')
    if (input === 'CVI Percentile Overall') global.setCVIValue('percentile_cvi_overall')
  }

  const renderCVIOptions = () => {
    const cviValueList = [
      'Raw CVI Overall',
      'CVI Percentile Overall'
    ]

    return cviValueList.map((element: any) => {

      if (element === 'Baseline Vulnerability - Environment') {
        return (
          <option selected>
            {element}
          </option>
        )
      }

      return (
        <option>
          {element}
        </option>
      )
    })

  }

  const [selectedOption, setSelectedOption] = useState('Moderately Persistent Contaminants')
  const radioSelect = (input: any) => {
    const options = [
      'moderately_persistent_contaminants',
      'moderately_persistent_contaminants_with_air_deposition',
      'persistent_contaminants',
      'persistent_contaminants_with_air_deposition',
      'volatile_contaminants',
      'volatile_contaminants_with_air_deposition'
    ]

    global.setContaminate(options[input])
  }

  const renderRadios = () => {
    const select = [
      'Moderately Persistent Contaminants',
      'Moderately Persistent Contaminants with Air Deposition',
      'Persistent Contaminants',
      'Persistent Contaminants with Air Deposition',
      'Volatile Contaminants',
      'Volatile Contaminants with Air Deposition'
    ]

    const handleOptionChange = (event: any, index: any) => {
      setSelectedOption(event.target.value);
      radioSelect(index)
    };

    const options = select.map((e: any, index: any) => {
      return (
        <Form.Check
          type="radio"
          id="epo-radio"
          name="radioGroup"
          label={e}
          value={e}
          checked={selectedOption === e}
          onChange={(e: any) => handleOptionChange(e, index)}
        />
      )
    })

    return (
      <Form>
        {options}
      </Form>
    )
  }

  const [selectedYsOption, setSelectedYsOption] = useState()

  const closeYS = () => {
    global.setYellowstoneLayer(false)
    global.setPersistentContaminants(false)
    global.setModPersistentContaminants(false)
    global.setVolPersistentContaminants(false)
  }

  const renderYsRadios = () => {
    const select = [
      'Persistent Contaminants',
      'Moderately Persistent Contaminants',
      'Volatile Persistent Contaminants',
    ]
    const handleOptionChange = (event: any, index: any) => {
      const str = event.target.value
      setSelectedYsOption(str)
      if (str === 'Persistent Contaminants') {
        global.setPersistentContaminants(true)
        global.setModPersistentContaminants(false)
        global.setVolPersistentContaminants(false)
      }

      if (str === 'Moderately Persistent Contaminants') {
        global.setPersistentContaminants(false)
        global.setModPersistentContaminants(true)
        global.setVolPersistentContaminants(false)
      }

      if (str === 'Volatile Persistent Contaminants') {
        global.setPersistentContaminants(false)
        global.setModPersistentContaminants(false)
        global.setVolPersistentContaminants(true)
      }
    };

    const options = select.map((e: any, index: any) => {
      return (
        <Form.Check
          type="radio"
          id="epo-radio"
          name="radioGroup"
          label={e}
          value={e}
          checked={selectedYsOption === e}
          onChange={(e: any) => handleOptionChange(e, index)}
        />
      )
    })

    return (
      <Form>
        {options}
      </Form>
    )
  }

  const [previousElement, setPreviousElement] = useState<any>()


  const handleZIndex = (id: string) => {
    if (previousElement) previousElement.style.zIndex = '101'
    const slider = document.getElementById(id)
    if (slider) slider.style.zIndex = '200'
    setPreviousElement(slider)
  }

  const handleYearChange = (e: any) => {
    let value = parseInt(e.target.value, 10);

    if (!isNaN(value) && value >= 1850 && value <= currentYear) {
      global.setSelectedYear(value);
    }
  };

const [USGSWUC, setUSGSWUC] = useState('Jan 2016')
const [USGSHuc12Date, setUSGSHuc12Date] = useState('Jan 2016')
const display = path === '/USGSWUCReport' ? 'none' : 'block'

const renderDate = (input: number, reportYear: any = undefined) => {

  const months = [
    "Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec",
    "Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec",
    "Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec",
    "Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec",
    "Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"
  ];
  
  const date_strings = [
    "1/2016", "2/2016", "3/2016", "4/2016", "5/2016", "6/2016",
    "7/2016", "8/2016", "9/2016", "10/2016", "11/2016", "12/2016",
    "1/2017", "2/2017", "3/2017", "4/2017", "5/2017", "6/2017",
    "7/2017", "8/2017", "9/2017", "10/2017", "11/2017", "12/2017",
    "1/2018", "2/2018", "3/2018", "4/2018", "5/2018", "6/2018",
    "7/2018", "8/2018", "9/2018", "10/2018", "11/2018", "12/2018",
    "1/2019", "2/2019", "3/2019", "4/2019", "5/2019", "6/2019",
    "7/2019", "8/2019", "9/2019", "10/2019", "11/2019", "12/2019",
    "1/2020", "2/2020", "3/2020", "4/2020", "5/2020", "6/2020",
    "7/2020", "8/2020", "9/2020", "10/2020", "11/2020", "12/2020"
  ];

  global.setDateString(date_strings[input])
  global.setUSGShuc12DateString(date_strings[input])
  const year = date_strings[input].split('/')[1]
  const month = months[input]
  const dateString = `${month} ${year}`
  global.setUSGSYear(year)
  setUSGSWUC(dateString)
  setUSGSHuc12Date(dateString)
}

  const renderThumb = (style: any) => {
    const thumbStyle = {
      backgroundColor: 'white'
    };
    return (
      <div
        style={{ ...style, ...thumbStyle }}
      />
    );
  }

const renderHazardItems = () => {
    let newArray = [] as any;
    let legend = [...legendItems];
    let wordGroup = ['Flood', 'Hydrologic', 'Wind', 'Thunderstorm', 'Heat', 'Beach', 'Rip', 'Marine', 'Gale', 'Seas', 'Craft']

    const checkSearch = (word:string, index: number) => {
        let newAdditions = legend.filter((item:any) => item.label.indexOf(word) > -1);
        newArray = [...newArray,...newAdditions];
        legend = legend.filter((item:any) => item.label.indexOf(word) < 0);
    }

    for (var i = 0; i < wordGroup.length; i++) {
      checkSearch(wordGroup[i], i)
    }
    newArray = [...newArray,...legend];
    return newArray && newArray.map((rc: any, index:number) => (
            <div className="legend-item" key={'legend-grid-'+ index} style={{display: 'flex', flexDirection: 'row'}} >
                <div className="nwscolor" style={{backgroundColor:`rgba(${rc.symbol.color})`}} >&nbsp;</div>
                <a href={`//forecast.weather.gov/wwamap/wwatxtget.php?cwa=usa&wwa=${encodeURI(rc.label)}`} target={"_blank"}>{rc.label}</a>   
            </div>
          ))    
}

const handleNewLocation = (e: any) => {
  if(e && e.geometry && mapInstance){
      const featureCollectionNew: FeatureCollection = {
         type: "FeatureCollection",
         features: [e]
      };   
      const center_ = center(featureCollectionNew);
      if(center_ && center_.geometry){       
        mapInstance.panTo(center_.geometry.coordinates);
      }
  }
};

let importOrder = [
  "Central Florida",
  "Southern Plains into the Middle Mississippi Valley",
  "Hawaii",
  "Puerto Rico and the U.S. Virgin Islands"
]

const sortByObject: any = importOrder//@ts-ignore
    .reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
        };
}, {});

const renderFHO = () => {
  if(!legendItemsFHO || (legendItemsFHO && legendItemsFHO.length<1)){
    return null
  }
  let sortedFields = [...legendItemsFHO].sort((a: any, b: any) => sortByObject[a.properties.Region] - sortByObject[b.properties.Region]);
  return sortedFields && sortedFields.map((rc: any, index:number) => (
        <div className="legend-item-browse" key={'legend-grid-'+ index} style={{display: 'flex', flexDirection: 'column'}}  onClick={(e: React.MouseEvent) => handleNewLocation(rc)} >
            <p style={{fontWeight: 'bold', paddingTop:4}}>{rc.properties.Region} </p><p style={{paddingLeft:10}} >Category:  {rc.properties.Category}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Issued: {rc.properties.Issued_Time}</p>
            <p></p><p style={{paddingLeft:10}} >{rc.properties.Key_Message_1}</p><p style={{paddingLeft:10}} >{rc.properties.Key_Message_2}</p><p style={{paddingLeft:10}} >{rc.properties.Key_Message_3}</p><p></p>
        </div>
      ))    
}

const Hydromet = () => {
  let _imageUrl = `https://api.weather.gov/products?location=NWC&type=hmd`
   fetch(_imageUrl)
      .then((response) => response.text())
      .then((responseText) => {            
        if(responseText){
          let responseItem = JSON.parse(responseText);
          if(responseItem["@graph"] && responseItem["@graph"][0] && responseItem["@graph"][0]["@id"]){
              HydrometToday(responseItem["@graph"][0]["@id"]);
          }
        }
      });
}

const HydrometToday = (url:string) => {
  let _imageUrl = url;
   fetch(_imageUrl)
      .then((response) => response.text())
      .then((responseText) => {            
        if(responseText){
          let responseItem = JSON.parse(responseText);
       
          if(responseItem && responseItem.productText){
              setLegendFHOHydromet(responseItem.productText)
          }
          
        }
      });
}

const renderFHOH = () => {  //@ts-ignore
  if(legendFHOHydromet && legendFHOHydromet.productText) {//@ts-ignore
    let pText = legendFHOHydromet.productText;

    return 
        <div className="legend-item-browse" key={'legend-grid-'+ 111}  >  
            <p style={{fontWeight: 'bold'}}>{pText} </p>
        </div>    
  }    
  return null
}

  const definedWidth = '100%'; 
  const definedHeight = 500;


  return (
    <>

      {global.eddi && (
        <Draggable disabled={disableDrag}>
          <div id="1" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)} className="mapslider-range-slider">
            <div id="toGrab" style={{ width: '600px' }} className="wrapper eddi">
              <p>Evaporative Demand Drought Index</p>
              <div className="range-wrapper">
                <Form.Range
                  className="slider-input"
                  min={0}
                  max={global.yearMonthRange.length - 1 || 1}
                  value={yearMonthSlider}
                  onChange={onChangeEddi}
                />	
                <strong>Month: {global.yearMonthRange[yearMonthSlider]}</strong>
                <div style={{ right: '0.5%' }} className="close-wrapper">
                  <FontAwesomeIcon onClick={() => global.setEddi(false)} icon={faXmark} />
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      )}

      {global.NOAAMix &&   (
        <Draggable disabled={disableDrag}>
          <div id="1" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)} className="mapslider-range-slider">
            <div id="toGrab" style={{ width: '600px' }} className="wrapper eddi">
              <div style={{ fontSize: '1.2em' }} >QPE: Quantitative Precipitation Estimates</div>
              <div className="range-wrap" style={{ width: '100%', paddingBottom: 30, paddingLeft: 11, paddingRight: 15, paddingTop: 10 }}>

                <div style={{ width: '100%' }}>
                 <Slider
                    min={0} max={6} marks={marksNOAAMix} step={1} value={rcvalueSlider}
                    onChange={onChangeNOAAMix}
                    handleStyle={{
                      height: 22,  
                      width: 22,
                      borderColor: '#4fc2d9',
                      marginTop: -10,
                    }}
                    startPoint={6}
                    dotStyle={{ borderColor: '#4fc2d9', height: 14, width: 14, transform: 'translate(-50%, 20%)' }}
                    railStyle={{ backgroundColor: '#838487' }}
                    trackStyle={{ backgroundColor: '#4fc2d9', height: 5 }} />
                </div>    
                <div style={{ marginTop: 40 }}>
	            	<ButtonGroup >
    				      <Button className={(observationCategory!=='observed') ? "mapslider-slider-button" : "mapslider-slider-button-active"} onClick={(e: any) => onChangeObservationCategory('observed')} variant="primary">Observed</Button>
    				      <Button className={(observationCategory!=='normal') ? "mapslider-slider-button" : "mapslider-slider-button-active"}  onClick={(e: any) => onChangeObservationCategory('normal')} variant="primary" style={{borderLeft: 'thick double #32a1ce !important'}}>Normal</Button>
    				      <Button className={(observationCategory!=='departure') ? "mapslider-slider-button" : "mapslider-slider-button-active"}  onClick={(e: any) => onChangeObservationCategory('departure')} variant="primary" style={{borderLeft: 'thick double #32a1ce !important'}}>Departure from Normal</Button>
    				      <Button className={(observationCategory!=='percent') ? "mapslider-slider-button" : "mapslider-slider-button-active"}  onClick={(e: any) => onChangeObservationCategory('percent')} variant="primary" style={{borderLeft: 'thick double #32a1ce !important'}}>Percent from Normal</Button>
    				    </ButtonGroup>
    				    <OverlayTrigger trigger="click"  rootClose placement="auto" overlay={popoverNOAAMix}>
	                  <Button className={'mapslider-help-button'} >
	                    <FontAwesomeIcon icon={faQuestion}/>
	                  </Button> 
	              </OverlayTrigger> 
        				</div> 
        				<div style={{display:'flex', flexDirection: 'row', width: '100%', height: 30, paddingTop: 20, justifyContent: 'center'}}>
        		          <span style={{ paddingRight: 4 }}>{(observationCategory!=='percent') ? 'Inches' : 'Percent'}</span>{colorPaletteArray && renderColorPalette()}
        				</div>        
                <div style={{ right: '0.5%', top: '2% !important' }} className="close-wrapper">
                  <FontAwesomeIcon onClick={() => global.setNOAAMix(false)} icon={faXmark} />
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      )}


{/*
     {global.FemaFirm && (
        <Draggable disabled={disableDrag}>
          <div id="1" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)} className="mapslider-range-slider nwshazard">
            <div id="toGrab" style={{ width: '470px' }} className="wrapper">
              <div style={{ fontSize: '1.2em' }} >Streamflow</div>

              <div style={{ position: 'absolute', right: 40, top:5 }}>
                <OverlayTrigger  rootClose trigger="click" placement="auto" overlay={popoverstreamflow}>
                  <Button className={'mapslider-help-button'} >
                    <FontAwesomeIcon icon={faQuestion}/>
                  </Button> 
                </OverlayTrigger> 
              </div> 

             <div style={{display:'flex', flexDirection: 'row', width: '100%', height: 30, paddingBottom: 20, justifyContent: 'center'}}>
              <span style={{ paddingRight: 4 }}>{'cfs'}</span>{colorPaletteArray && renderColorPaletteStream()}
            </div> 

              <div  className="close-wrapper">
                <FontAwesomeIcon onClick={() => global.setNwshazard(false)} icon={faXmark} />
              </div>

            </div>
          </div>
        </Draggable>
      )}

*/}

     {global.StreamAnomaly && (
        <Draggable disabled={disableDrag}>
          <div id="1" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)} className="mapslider-range-slider nwshazard">
            <div id="toGrab" style={{ width: '470px' }} className="wrapper">
              <div style={{ fontSize: '1.2em', marginBottom: 20 }} >Streamflow</div>

              <div style={{ position: 'absolute', right: 40, top:5 }}>
                <OverlayTrigger  rootClose trigger="click" placement="auto" overlay={popoverstreamflow}>
                  <Button className={'mapslider-help-button'} >
                    <FontAwesomeIcon icon={faQuestion}/>
                  </Button> 
                </OverlayTrigger> 
              </div> 

              <Form style={{ fontSize: '.9em' }} >
              
                  <div key={`inline-${"type"}`} className="mb-3 wrap-fillwidth">
                    <Form.Check
                      inline
                      label="Streamflow Anomaly"
                      name="group1"
                      type={"radio"}
                      id={`inline-${"type"}-1`}
                    
                      checked={global.StreamAnomalyBrightness !== .1&&global.StreamAnomalyBrightness!== .2}
                      onChange={() => { global.setStreamAnomalyBrightness(0) }}
                    />
                    <Form.Check
                      inline
                      label="Streamflow"
                      name="group1"
                      type={"radio"}
                      id={`inline-${"type"}-2`}
                 
                      checked={global.StreamAnomalyBrightness === .1}
                      onChange={() => { global.setStreamAnomalyBrightness(.1) }}
                    />
                    <Form.Check
                      inline
                      label="Streamflow (local)"
                      type={"radio"}
                      id={`inline-${"type"}-3`}
               
                      checked={global.StreamAnomalyBrightness === .2}
                      onChange={() => { global.setStreamAnomalyBrightness(.2) }}
                    />
                  </div>
  
              </Form>
     
             <div style={{display:'flex', flexDirection: 'row', width: '100%', height: 30, paddingBottom: 20, justifyContent: 'center'}}>
              <span style={{ paddingRight: 4 }}>{'cfs'}</span>{colorPaletteArray && renderColorPaletteStream()}
            </div> 

              <div  className="close-wrapper">
                <FontAwesomeIcon onClick={() => global.setNwshazard(false)} icon={faXmark} />
              </div>

            </div>
          </div>
        </Draggable>
      )}

      {global.FHO && (
        <Draggable disabled={disableDrag}>
          <Accordion style={{ maxHeight: 400 }} id="mapLegendAccordion" className="te-map-legend-accordion te-accordion-slider" defaultActiveKey={"0"} >
            <Accordion.Item className="te-map-legend-accordion" eventKey="0">
              <div id="1" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)} className="mapslider-range-slider FHO">
                <div id="toGrab" style={{ width: 540, maxHeight: 400 }} className="wrapperIg eddi">
                  <Accordion.Header style={{ width: 540, maxHeight: 400 }}  className="te-map-legend-accordion-header">Flood Hazard Outlook</Accordion.Header>
                    <Accordion.Body className="te-map-legend-accordion-body">                       
                      <div style={{ display: 'none', position: 'absolute', right: 40, top:5 }}>
                        <OverlayTrigger  rootClose trigger="click" placement="auto" overlay={popovernwshazard}>
                          <Button className={'mapslider-help-button'} >
                            <FontAwesomeIcon icon={faQuestion}/>
                          </Button> 
                        </OverlayTrigger> 
                      </div> 

                      <div style={{ display: 'none'}}  className="close-wrapper">
                        <FontAwesomeIcon onClick={() => global.setFHO(false)} icon={faXmark} />
                      </div>
                      
                      <div className="range-wrap" style={{ height: 300, width: 540, paddingBottom: 1, paddingLeft: 2, paddingRight: 1, paddingTop: 18 }}>
                        <Scrollbars renderThumbVertical={renderThumb} className="summary-scroll" style={{ width: '100%', height: '100%' }}>  
                          <div  style={{ fontSize: '1.1em', width: 510 }}  >
                             {renderFHO()}    
                             <hr />
                             <h5 style={{ paddingLeft:4 }}> National Hydrologic Discussion </h5>
                             <div className="legend-item-print" key={'legend-grid-'+ 111} style={{whiteSpace: 'pre', width: '100%', padding: 12}} >  
                                    {legendFHOHydromet}
                             </div> 
                          </div>
                        </Scrollbars>  
                      </div>

                      <h5 className="legend-source" style={{marginLeft: 20, marginTop:20}}>
                        Source:
                        <a href={"https://water.noaa.gov/operations/fho"} target={"_blank"}>
                          {" "}
                          NWS National Water Center
                        </a>
                      </h5>
                  </Accordion.Body>
                </div>
              </div>               
            </Accordion.Item>
           
          </Accordion>
        </Draggable>
      )}


     {global.FloodInd && (
        <Draggable disabled={true}>
          <div id="1" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)} className="mapslider-range-slider FloodInd">
            <div id="toGrab" style={{ width: '470px' }} className="wrapper">
              <div style={{ fontSize: '1.2em', marginBottom: 20 }} >Inland-Coastal Flooding Forecast</div>
                <div style={{ display:'flex', flexDirection: 'column', alignItems: 'center' }} >
                  <div  style={{ width:370 }}>
                    <Slider
                    min={0} max={95}  step={1} value={hourlySlider}
                    onChange={onChangeFloodInd}
                    onChangeComplete={onChangeCompleteFloodInd}
                    handleStyle={{
                      height: 22,  
                      width: 22,
                      borderColor: '#4fc2d9',
                      marginTop: -10,
                    }}
                    dotStyle={{ borderColor: '#4fc2d9', height: 14, width: 14, transform: 'translate(-50%, 20%)' }}
                    railStyle={{ backgroundColor: '#838487' }}
                    trackStyle={{ backgroundColor: '#4fc2d9', height: 5 }} />

                    
                  </div>
                  <strong style={{ width:259, marginTop: 20 }}>{hourlySliderLabel}</strong>
                </div>  
              <div style={{ position: 'absolute', top: 10, right: 10 }}  className="close-wrapper-a">
                <FontAwesomeIcon onClick={() => global.setFloodInd(false)} icon={faXmark} />
              </div>

            </div>
          </div>
        </Draggable>
      )}



      {global.nwshazard && (
        <Draggable disabled={disableDrag}>
          <div id="1" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)} className="mapslider-range-slider nwshazard">
            <div id="toGrab" style={{ width: '540px' }} className="wrapper eddi">
              <div style={{ fontSize: '1.2em' }} >NOAA All Hazards — Watches, Warnings, Advisories</div>
              <div className={'nws-legend-spinner-parent'}  style={(loadingActive)?{display:'flex'}:{display:'none'}}>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  /> 
                  <span style={{marginLeft: 10}}>Loading</span>
              </div>
              <div style={{ position: 'absolute', right: 40, top:5 }}>
                <OverlayTrigger  rootClose trigger="click" placement="auto" overlay={popovernwshazard}>
                  <Button className={'mapslider-help-button'} >
                    <FontAwesomeIcon icon={faQuestion}/>
                  </Button> 
                </OverlayTrigger> 
              </div> 
              <div className="range-wrap" style={{ width: '100%', paddingBottom: 1, paddingLeft: 2, paddingRight: 1, paddingTop: 18 }}>
                <div className="nws-legend-swatch">      
                   {renderHazardItems()}                                              
                </div>     
                <div  className="close-wrapper">
                  <FontAwesomeIcon onClick={() => global.setNwshazard(false)} icon={faXmark} />
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      )}

      {global.droughtMonitoringSelected && (
        <Draggable disabled={disableDrag}>
          <div id="1" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)} className="mapslider-range-slider FloodInd">
            <div id="toGrab" style={{ width: 420, minHeight: 270}} className="wrapper eddi">              
                <div style={{ position: 'absolute', left:20, top:4, width: 380, minHeight: 260}}>
                  <ButtonGroup style={{ maxWidth: 200, marginBottom: 10, marginLeft: 10}}>
                    <Button className={(observationCategory!=='observed') ? "mapslider-slider-button" : "mapslider-slider-button-active"} onClick={(e: any) => onChangeObservationCategoryDrought('observed')} variant="primary">Current</Button>
                    <Button className={(observationCategory!=='normal') ? "mapslider-slider-button" : "mapslider-slider-button-active"}  onClick={(e: any) => onChangeObservationCategoryDrought('normal')} variant="primary" style={{borderLeft: 'thick double #32a1ce !important'}}>Monthly Outlook</Button>
                    <Button className={(observationCategory!=='departure') ? "mapslider-slider-button" : "mapslider-slider-button-active"}  onClick={(e: any) => onChangeObservationCategoryDrought('departure')} variant="primary" style={{borderLeft: 'thick double #32a1ce !important'}}>Seasonal Outlook</Button>
                    <Button className={(observationCategory!=='percent') ? "mapslider-slider-button" : "mapslider-slider-button-active"}  onClick={(e: any) => onChangeObservationCategoryDrought('percent')} variant="primary" style={{borderLeft: 'thick double #32a1ce !important'}}>Information Statements</Button>
                  </ButtonGroup>
                  <Scrollbars renderThumbVertical={renderThumb} className="summary-scroll" style={{ width: 395, height: 179 }}>
                  <div  style={{ paddingRight:20 }}>
                    <div style={(observationCategory==='percent') ? {marginTop: 10, display: 'block'} : {display: 'none'}}>
                      <p>National Weather Service drought information statements provide up-to-date reports on the current drought situation for regional Weather Forecast Offices' county warning and forecast area. These timely statements summarize recent weather and hydrologic conditions, discuss local drought impacts, and provide a local drought outlook.</p>
                   </div>         

                    <table style={{ padding: 10, marginTop: 10}}>
                      <tbody>
                        <tr>
                          <td>
                            <FontAwesomeIcon
                              icon={faCircle}
                              size="lg"
                              style={{ color: `${adjustHexBrightness((observationCategory==='normal'|| observationCategory==='departure') ? "#9B634A" : "#f4fb18", global.droughtMonitoringBrightness)}` }}
                            />
                          </td>
                          <td>{(observationCategory==='normal'|| observationCategory==='departure') ? " - Drought persists" : " - D0 (Abnormally Dry)"}</td>
                        </tr>
                        <tr>
                          <td>
                            <FontAwesomeIcon
                              icon={faCircle}
                              size="lg"
                              style={{ color: `${adjustHexBrightness((observationCategory==='normal'|| observationCategory==='departure') ? "#DED2BC" : "#f99010", global.droughtMonitoringBrightness)}` }}

                            />
                          </td>
                          <td>{(observationCategory==='normal'|| observationCategory==='departure') ? " - Drought remains but improves" : " - D1 (Moderate Drought)"}</td>
                        </tr>
                        <tr>
                          <td>
                            <FontAwesomeIcon
                              icon={faCircle}
                              size="lg"
                              style={{ color: `${adjustHexBrightness((observationCategory==='normal'|| observationCategory==='departure') ? "#B2AD69" : "#ea631f", global.droughtMonitoringBrightness)}` }}

                            />
                          </td>
                          <td>{(observationCategory==='normal'|| observationCategory==='departure') ? " - Drought removal likely" : " - D2 (Severe Drought)"}</td>
                        </tr>
                        <tr>
                          <td>
                            <FontAwesomeIcon
                              icon={faCircle}
                              size="lg"
                              style={{ color: `${adjustHexBrightness((observationCategory==='normal'|| observationCategory==='departure') ? "#FFDE63" : "#fa3f19", global.droughtMonitoringBrightness)}` }}

                            />
                          </td>
                          <td>{(observationCategory==='normal'|| observationCategory==='departure') ? " - Drought development likely" : " - D3 (Extreme Drought)"}</td>
                        </tr>
                        <tr>
                          <td>
                            <FontAwesomeIcon
                              icon={faCircle}
                              size="lg"
                              style={{ color: `${adjustHexBrightness((observationCategory==='normal'|| observationCategory==='departure') ? "#ffffff" : "#ab0707", global.droughtMonitoringBrightness)}`, stroke: `${(observationCategory==='normal'|| observationCategory==='departure') ? "aquamarine" : ""}`, strokeWidth: `${(observationCategory==='normal'|| observationCategory==='departure') ? 1 : 1}` }}

                            />
                          </td>
                          <td>{(observationCategory==='normal'|| observationCategory==='departure') ? " - No drought predicted" : " - D4 (Exceptional Drought)"}</td>
                        </tr>
                      </tbody>
                    </table>

                    <div style={(observationCategory==='normal'|| observationCategory==='departure') ? {marginTop: 10} : {display: 'none'}}>
                      <p>Predicts whether drought will persist, develop, improve, or be removed.</p>
                      <p>Climate outlooks describe the chances that conditions will be below-normal, near-normal, or above-normal for the outlook period indicated. The U.S. Seasonal Drought Outlook depicts large-scale trends based on subjectively derived probabilities guided by short- and long-range statistical and dynamical forecasts. </p>

                      <p>The outlook is issued on the third Thursday of each month</p> 
                    </div>

                    <div style={(observationCategory!=='normal'&& observationCategory!=='departure') ? {marginTop: 10} : {display: 'none'}}>
                      <p>The U.S. Drought Monitor depicts the location and intensity of drought across the country using 5 classifications.</p>
                      <p>The U.S. Drought Monitor is a joint effort of the National Drought Mitigation Center, U.S. Department of Agriculture, and National Oceanic and Atmospheric Administration. </p>

                      <p>The U.S. Drought Monitor is released every Thursday morning, with data valid through Tuesday 7am EST.</p> 
                    </div>



                  </div>
                  </Scrollbars>
                  <OverlayTrigger   rootClose trigger="click" placement="auto" overlay={popoverdrought}>
                    <Button className={'mapslider-help-button'} style={{ display: 'none', position: 'absolute', left:20, bottom:14}}>
                      View U.S. Seasonal Drought Outlook
                    </Button> 
                  </OverlayTrigger> 
                </div> 
              <div className="range-wrap" style={{ width: '100%', paddingBottom: 1, paddingLeft: 2, paddingRight: 1, paddingTop: 18 }}>
                <div className="nws-legend-swatch">                  
                </div>
                <div  className="close-wrapper">
                  <FontAwesomeIcon onClick={() => global.setNwshazard(false)} icon={faXmark} />
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      )}

      {(global.terraClimateLayerSelected || global.evapotranspirationSelected) && (
        <Draggable disabled={disableDrag}>
          <div id="2" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)} className="mapslider-range-slider">
            <div id="toGrab" className="wrapper">
              {global.terraClimateLayerSelected ? <p>Terraclimate Precipitation</p> : <p>Terraclimate Evapotranspiration</p>}
              <div className="range-wrapper">
                <Form.Range
                  className="slider-input"
                  min={2000}
                  max={2019}
                  value={global.MTCLValue}
                  onChange={e => global.setMTCLValue(parseInt(e.target.value))}
                />
                <strong>Year: {global.MTCLValue}</strong>
                <div style={{ right: '1%' }} className="close-wrapper">
                  <FontAwesomeIcon onClick={() => {
                    global.setTerraClimateLayerSelected(false)
                    global.setEvapotranspirationSelected(false)
                  }} icon={faXmark} />
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      )}
      {global.NOAASeaLevelRise && (
        <Draggable disabled={disableDrag}>
          <div id="3" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)} className="mapslider-range-slider range-slider-noaa">
            <div id="toGrab" className="wrapper noaa">
              <p>NOAA Sea Level Rise</p>
              <div className="range-wrapper">
                <Form.Range
                  className="slider-input"
                  min={0}
                  max={10}
                  value={global.feetIndex}
                  onChange={e => global.setFeetIndex(parseInt(e.target.value))}
                />
                <strong>Sea Level: {global.feetIndex}ft</strong>
                <div className="close-wrapper">
                  <FontAwesomeIcon onClick={() => global.setNOAASeaLevelRise(false)} icon={faXmark} />
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      )}
      {
        global.EPOLayer && global.hours
        && (
          <Draggable disabled={disableDrag}>
            <div id="4" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)} className="mapslider-range-slider range-slider-noaa">
              <div id="toGrab" className="radio-menu epo">
                <p>East Palestine Outflows</p>
                <Form.Range
                  className='slider-input'
                  min={0}
                  max={300}
                  onClick={() => setDisableDrag(true)}
                  value={global.hoursIndex}
                  onChange={e => global.setHoursIndex(parseInt(e.target.value))}
                />
                <strong>Hour: {global.hoursIndex}</strong>
                {renderRadios()}
                <div className="close-wrapper">
                  <FontAwesomeIcon onClick={() => global.setEPOLayer(false)} icon={faXmark} />
                </div>
              </div>
            </div>
          </Draggable>
        )}
      {
        global.yellowstoneLayer
        && (
          <Draggable disabled={disableDrag}>
            <div id="5" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)} className="mapslider-range-slider range-slider-noaa">
              <div id="toGrab" className="radio-menu ys">
                <p>Yellowstone Contaminants</p>
                <Form.Range
                  className="slider-input"
                  min={0}
                  max={519}
                  onClick={() => setDisableDrag(true)}
                  value={global.hoursIndex}
                  onChange={e => global.setHoursIndex(parseInt(e.target.value))}
                />
                <strong>Hour: {global.hoursIndex}</strong>
                {renderYsRadios()}
                <div className="close-wrapper">
                  <FontAwesomeIcon onClick={() => closeYS()} icon={faXmark} />
                </div>
              </div>
            </div>
          </Draggable>
        )}

      {
        global.wrfcRisk
        && (
          <Draggable>
            <div id="6" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)} className="range-slider range-slider-noaa">
              <div id="toGrab" className="range-select wrfc">
                <p>WRF Country Risk</p>
                <h6>Value:</h6>
                <Form.Select
                  onChange={e => {
                    global.setWRFCValue(e.target.value)
                    handleLegendCatagories(e.target.value)
                  }}
                >
                  {renderWRFCOptions()}
                </Form.Select>
                <div style={{ right: '1%' }} className="close-wrapper">
                  <FontAwesomeIcon onClick={() => global.setWRFCRisk(false)} icon={faXmark} />
                </div>
              </div>
            </div>
          </Draggable>
        )}
      {global.allFireData && (
          <Draggable disabled={disableDrag}>
            <div id="7" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)} className="mapslider-range-slider range-slider-noaa">
              <div id="toGrab" className="wrapper">
                <p>Historical Fire Data</p>
                <div className="range-wrapper">
                  <Form.Range
                    className="slider-input"
                    min={1800}
                    max={currentYear}
                    value={global.fireYear}
                    onChange={e => global.setFireYear(e.target.value)}
                  />
                  <strong>year: {global.fireYear}</strong>
                  <div style={{ right: '1%' }} className="close-wrapper">
                    <FontAwesomeIcon onClick={() => global.setAllFireData(false)} icon={faXmark} />
                  </div>
                </div>
              </div>
            </div>
          </Draggable>
      )}
      {global.rainfallForecast && (
        <Draggable disabled={disableDrag}>
          <div id="8" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)} className="mapslider-range-slider range-slider-noaa">
            <div id="toGrab" className="wrapper">
              <p>Excessive Rainfall Potential Forecast</p>
              <div className="range-wrapper">
                <Form.Range
                  className='slider-input'
                  min={1}
                  max={5}
                  value={global.rainfallDay}
                  onChange={e => global.setRainfallDay(e.target.value)}
                />
                <strong>Day: {global.rainfallDay}</strong>
                <div className="close-wrapper">
                  <FontAwesomeIcon onClick={() => global.setRainfallForecast(false)} icon={faXmark} />
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      )}

      {global.cvi && (
        <Draggable>
          <div id="9" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)} className="range-slider range-slider-noaa">
            <div id="toGrab" className="range-select wrfc cvi">
              <p>Climate Vulnerability Index</p>
              <h6>Value:</h6>
              <Form.Select
                onChange={e => {
                  handleCVICatagories(e.target.value)
                }}
              >
                {renderCVIOptions()}
              </Form.Select>
              <div style={{ right: '3%' }} className="close-wrapper">
                <FontAwesomeIcon onClick={() => global.setCVI(false)} icon={faXmark} />
              </div>
            </div>
          </div>
        </Draggable>
      )}
      {global.customLayer1
        || global.customLayer2
        || global.customLayer3
        || global.customLayer4
        || global.customLayer5
        || global.customLayer6
        || global.customLayer7
        || global.customLayer8
        || global.customLayer9
        || global.customLayer10
        || global.customLayer11
        || global.customLayer12
        || global.customLayer13
        || global.customLayer14
        || global.customLayer15
        || global.customLayer16
        || global.customLayer17
        || global.customLayer18
        || global.customLayer19
        || global.customLayer20 && (
          <Draggable>
            <div id="10" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)} className="range-slider range-slider-noaa">
              <div id="toGrab" className="range-select wrfc">
                <p>Custom Layer Options</p>
                <h6>Value:</h6>
                <Form.Select
                  onChange={e => {
                    global.setCustomDataLayerValue(e.target.value)
                  }}
                >
                  {renderCustomValueOptions()}
                </Form.Select>
                <div style={{ right: '1%' }} className="close-wrapper">
                  <FontAwesomeIcon onClick={handleCustomClose} icon={faXmark} />
                </div>
              </div>
            </div>
          </Draggable>
        )}
      {global.WaterConflict && (
        <Draggable>
          <div id="waterConflict">
            <div id="11" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)}>
              <div id="toGrab">
                <div className="range-slider range-slider-noaa water-conflict">
                  <div style={{
                    position: 'absolute',
                    right: '3%',
                    top: '3%',
                     }} className="close-wrapper">
                    <FontAwesomeIcon onClick={() => global.setWaterConflict(false)} icon={faXmark} />
                  </div>
                  <p>Water Conflict Options</p>
                  <Form.Group controlId="yearInput">
                    <Form.Label>Year:</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter year"
                      value={global.selectedYear}
                      onChange={handleYearChange}
                      min={1850}
                      max={global.currentYear}
                    />
                    <Form.Text className="text-muted">
                      Enter a year between 1850 and {global.currentYear}.
                    </Form.Text>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      )}
      {global.impairedLakes && (
        <Draggable>
          <div id="impairedLakes">
            <div id="12" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)}>
              <div id="toGrab">
                <div className="range-slider impairedLakes range-slider-noaa water-conflict">
                  <div style={{
                    position: 'absolute',
                    right: '3%',
                    top: '3%',
                  }} className="close-wrapper">
                    <FontAwesomeIcon onClick={() => global.setImpairedLakes(false)} icon={faXmark} />
                  </div>
                  <Form.Group controlId="yearInput">
                    <Form.Label>Contaminant:</Form.Label>
                    <Form.Select onChange={(e: any) => global.setWIContaminate(e.target.value)}>
                      {renderContams()}
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      )}
      {/* USGSHuc12 */}
      {global.USGSWUC && (
        <Draggable disabled={disableDrag}>
          <div style={{ display: display }} id="USGSWUC">
            <div id="13" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)}>
              <div id="toGrab">
                <div className="range-slider impairedLakes range-slider-noaa water-conflict">
                  <div style={{
                    position: 'absolute',
                    right: '3%',
                    top: '3%',
                  }} className="close-wrapper">
                    <FontAwesomeIcon onClick={() => global.setUSGSWUC(false)} icon={faXmark} />
                  </div>
                  <Form.Group controlId="yearInput">
                  <p>USGS Monthly Public Water Usage</p>
                    <div className="range-wrapper">
                      <Form.Range
                        className="slider-input USGSWUC"
                        min={0}
                        max={59}
                        onChange={(e:any) => display === 'none' ? renderDate(e.target.value, global.selectedUSGSYear) : renderDate(e.target.value)}
                      />
                      <strong>Month: {USGSWUC}</strong>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      )}
      {global.USGSHuc12 && path !== '/USGSHuc12' && (
        <Draggable disabled={disableDrag}>
          <div id="USGSHuc12">
            <div id="14" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)}>
              <div id="toGrab">
                <div className="range-slider impairedLakes range-slider-noaa water-conflict">
                  <div style={{
                    position: 'absolute',
                    right: '3%',
                    top: '3%',
                  }} className="close-wrapper">
                    <FontAwesomeIcon onClick={() => global.setUSGSHuc12(false)} icon={faXmark} />
                  </div>
                  <Form.Group controlId="yearInput">
                  <p>USGS Monthly Public Water Usage By HUC12</p>
                    <div className="range-wrapper">
                      <Form.Range
                        className="slider-input USGSWUC"
                        min={0}
                        max={59}
                        onChange={(e:any) => renderDate(e.target.value)}
                      />
                      <strong>Month: {USGSHuc12Date}</strong>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      )}
      {global.Hydrobasins && global.viewport.zoom >= 6.25 && (
          <Draggable disabled={disableDrag}>
            <div id="15" onClick={(e: React.MouseEvent) => handleZIndex(e.currentTarget.id)} className="mapslider-range-slider range-slider-noaa">
              <div id="toGrab" className="wrapper">
                <p>Global Hydrobasins</p>
                <br/>
                <Form.Group as={Row} controlId="exampleForm.Select">
                  <Form.Label column sm="3">Kind:</Form.Label>
                  <Col sm="9">
                    <Form.Select onChange={(e:any) => global.setHydrobasinsKind(e.target.value)} aria-label="Select an option">
                      <option value="standard">standard</option>
                      <option value="custom">custom</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
                <div className="range-wrapper">
                  <Form.Range
                    className="slider-input"
                    min={1}
                    max={12}
                    value={global.HydrobasinsLevel}
                    onChange={e => global.setHydrobasinsLevel(e.target.value)}
                  />
                  <strong>Sub-Basin: {global.HydrobasinsLevel}</strong>
                  <div style={{ right: '1%' }} className="close-wrapper">
                    <FontAwesomeIcon onClick={() => global.setHydrobasins(false)} icon={faXmark} />
                  </div>
                </div>
              </div>
            </div>
          </Draggable>
      )}
    </>
  );
};

export default MapSlider