import { useEffect, useState, useRef, useCallback, useContext, useMemo, Ref } from 'react';
import { Button, OverlayTrigger } from 'react-bootstrap';
import { Tooltip as TooltipRB, Form } from 'react-bootstrap';
import settings from '../../../settings.json';
import './styles.scss';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import {
  Map,
  useMap,
  MapRef,
  Popup,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  useControl,
  Layer,
  Source,
} from 'react-map-gl';
import type { LayerProps } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Feature, GeoJsonProperties, Point, FeatureCollection } from 'geojson';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import agent from '../../../api/agent';
import LoadingDataAnimation from '../../../components/LoadingDataAnimation';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import fullscreenIcon from '../../../assets/fullscreen-icon.svg';
import ellipsisIcon from '../../../assets/ellipsis-icon.svg';
import * as d3Format from 'd3-format';
import { formatRowValue } from '../../utils';
import { AppContext } from '../../../AppContext';
import { CatalogsContext } from '../../../CatalogsProvider';
import { Location, useLocation } from 'react-router-dom';
import MapLegend from '../shared/MapLegend';
import MapAddition from  '../shared/MapAddition';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import ReportTableExpanded from '../shared/ReportTableExpanded';
import { HeaderProps } from 'react-table';

import { RefContext } from '../../../RefContext';

import {
  processStatusMessage,
  uniqueValues,
  fitBoundsMapData,
  groupByKey,
} from '../shared/report-data';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend);

export default function NationalStreamQualityAccountingNetwork(props: any) {
  const { global } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);
  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;
  const selectedHUC8Value = appContext.selectedHUC8;
  const selectedFilterLocations = global.filterLocationsSelected;
  const [userSelectedLocation, setUserSelectedLocation] = useState<any>();
  const [userSelectedLocations, setUserSelectedLocations] = useState<any>([]);
  const [usLocations, setUsLocations] = useState<any>([]);

  const [currentRecord, setCurrentRecord] = useState<any>(null);
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const [isFullScreenActive, setFullScreenActive] = useState(false);
  const fullscreenContainerHandler = () => {
    setFullScreenActive(!isFullScreenActive);
  };
  const [isDataReportLoaded, setIsDataReportLoaded] = useState(false);
  const [reportData, setReportData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [featureData, setFeatureData] = useState<FeatureCollection>();
  const [chartData, setChartData] = useState<any>(null);
  const [tableData, setTableData] = useState<any[]>([]);
  const locationField = 'Nasqan Site Flow Id';
  const [mapHoverFilter, setMapHoverFilter] = useState<any>(['in', ['get', locationField], '']);
  const { current: map } = useMap();
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [cursor, setCursor] = useState<string>('auto');
  const [mapLoaded, setMapLoaded] = useState(false);
  const mapRef = useRef<MapRef>();
  const { setCurrentRef } = useContext(RefContext);
  useEffect(() => { setCurrentRef?.(mapRef) }, [mapRef])
  const [mapLegendData, setMapLegendData] = useState<any[]>([]);
  const [circleColorStyle, setCircleColorStyle] = useState<any>([]);
  const [huc12Members, setHuc12Members] = useState<any[]>([]);
  const [huc12InfoCatalogArray, setHuc12InfoCatalogDataArray] = useState<any[]>([]);
  const [layerStyle, setLayerStyle] = useState<any>([]);
  const [statusMessage, setStatusMessage] = useState<any>(null);

  useEffect(() => {
    if(global.trackerOn) global.setTrackerOn(false)
  }, [])

  useEffect(() => {
    global.setUserLogged(true);
  }, [selectedHUC8Value]);

  useEffect(() => {
    let fData = [...reportData];
    if (
      userSelectedLocations &&
      userSelectedLocations.length > 0 &&
      userSelectedLocations.indexOf('All') < 0
    ) {
      fData = filteredData.filter((o: any) => userSelectedLocations.indexOf(o[locationField]) > -1);
    }
    setFilteredData(fData);
  }, [userSelectedLocations]);

  useEffect(() => {
    if(statusMessage){
        global.notify(statusMessage)
    }
  }, [statusMessage]);

  useEffect(() => {
    setIsDataReportLoaded(false);
    setStatusMessage('');
    agent.Reports.NationalStreamQualityAccountingNetwork().then((res: any) => {
      const returnStatus = processStatusMessage(res);
      setStatusMessage(returnStatus.statusMessage);
      if (returnStatus.Abort) {
        setIsDataReportLoaded(true);
        return;
      }
      let returnedData = [...res.body];
      if (!returnedData || !returnedData.length || returnedData.length < 1) {
        setIsDataReportLoaded(true);
        setStatusMessage('No result.');
        return;
      }

      const siteLabels = uniqueValues(returnedData, 'Nasqan Site Type');
      const siteScale: any[] = [];
      for (let dataItem of siteLabels) {
        siteScale.push({
          site: dataItem,
          color: '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6),
        });
      }
      setMapLegendData(
        siteLabels.map((x: any, index: number) => ({
          typecode: siteScale[index]['site'],
          color: siteScale[index].color,
        }))
      );

      for (let dataItem of returnedData) {
        dataItem.Latitude = dataItem.Lat;
        dataItem.Longitude = dataItem.Lng;
        dataItem.lat = dataItem.Latitude;
        dataItem.lng = dataItem.Longitude;
        dataItem.centroid_longitude = dataItem.Longitude;
        dataItem.centroid_latitude = dataItem.Latitude;
        const first = siteScale.filter((obj: any) => {
          return obj['site'] === dataItem['Nasqan Site Type'];
        });
        dataItem.color = first[0].color;
      }

      setReportData(returnedData);
      setFilteredData(returnedData);
      setIsDataReportLoaded(true);
    });
  }, []);

  useEffect(() => {
    updateLayerStyle();
  }, [isDataReportLoaded]);

  useEffect(() => {
    const fDataset = [...filteredData];
    createTableData(fDataset);
    const groupedresults = groupByKey<any, any>(fDataset, i => i[locationField]);
    const newFeatureData: any[] = [];
    for (const key in groupedresults) {
      if (groupedresults[key].length > 0) {
        newFeatureData.push(groupedresults[key][0]);
      }
    }
    const featureCollectionFromReportData = featureCollection(newFeatureData);
    setFeatureData(featureCollectionFromReportData);
    if (featureCollectionFromReportData.features.length > 0) {
      if (mapRef && mapRef.current) {
        const mapRef_ = mapRef;
        setTimeout(() => {
          fitBoundsMapData(featureCollectionFromReportData, mapRef_);
        }, 400);
      }
    }
  }, [filteredData]);

  const createTableData = (fData: any) => {
    const tData = filteredData.map(obj => ({ ...obj }));
    const groupedresults = groupByKey<any, any>(tData, i => i[locationField]);
    const newTableData: any[] = [];
    for (const key in groupedresults) {
      if (groupedresults[key].length > 0) {
        const tData = groupedresults[key];
        const tRecord = [];
        let _tD = {
          centroid_latitude: tData[0]['centroid_latitude'],
          centroid_longitude: tData[0]['centroid_longitude'],
          'Nasqan Site Flow Id': tData[0]['Nasqan Site Flow Id'],
          'Site Flow Name': tData[0]['Site Flow Name'],
          subRows: tData,
        };
        newTableData.push(_tD);
      }
    }
    setTableData(newTableData);
  };

  const featureCollection = (fData: any) => {
    const features: Array<Feature<Point, GeoJsonProperties>> = [];
    for (let dataItem of fData) {
      if (dataItem) {
        const featureWithPoint: Feature<Point> = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [dataItem.centroid_longitude, dataItem.centroid_latitude],
          },
          properties: dataItem,
        };
        features.push(featureWithPoint);
      }
    }
    const featureCollectionFromReportData: FeatureCollection = {
      type: 'FeatureCollection',
      features: features,
    };
    setFeatureData(featureCollectionFromReportData);
    return featureCollectionFromReportData;
  };

  const updateLayerStyle = () => {
    const layerStyle_: LayerProps = {
      id: 'pointlayer',
      type: 'circle' as const,
      paint: {
        'circle-radius': ['get', 'radius'],
        'circle-color': ['get', 'color'],
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1,
        'circle-opacity': 0.99,
      },
    };
    setLayerStyle(layerStyle_);
  };

  const hoverLayerStyle: LayerProps = {
    id: 'hoverlayer',
    type: 'circle' as const,
    paint: {
      'circle-radius': ['get', 'radius'],
      'circle-color': '#000099',
      'circle-stroke-color': 'white',
      'circle-stroke-width': 1,
      'circle-opacity': 1,
    },
  };

  const tableColumns = useMemo(
    () => [
      {
        id: 'expander',
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: HeaderProps<any>) => (
          <span {...getToggleAllRowsExpandedProps()}>{isAllRowsExpanded ? '-' : '+'}</span>
        ),
        Cell: ({ row }: any) =>
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
            >
              {row.isExpanded ? '-' : '+'}
            </span>
          ) : null,
      },
      { Header: 'Site Flow Name', accessor: 'Site Flow Name' },
      {
        Header: 'Site Type',
        accessor: 'Nasqan Site Type',
      },
      {
        Header: 'Constituent',
        accessor: 'Nasqan Constituent',
      },
      {
        Header: 'Constituent Description',
        accessor: 'Nasqan Constituent Description',
      },
      {
        Header: 'Tons',
        accessor: 'Nasqan Tons',
        Cell: (props: any) =>
          props.value ? d3Format.format(',')(+formatRowValue(props.value)) : '',
      },
      {
        Header: 'Tons L90',
        accessor: 'Nasqan Tons L90',
        Cell: (props: any) =>
          props.value ? d3Format.format(',')(+formatRowValue(props.value)) : '',
      },
      {
        Header: 'Tons U90',
        accessor: 'Nasqan Tons U90',
        Cell: (props: any) =>
          props.value ? d3Format.format(',')(+formatRowValue(props.value)) : '',
      },
      {
        Header: 'Conc',
        accessor: 'Nasqan Conc',
        Cell: (props: any) =>
          props.value ? d3Format.format(',')(+formatRowValue(props.value)) : '',
      },

      {
        Header: 'Conc L90',
        accessor: 'Nasqan Conc L90',
        Cell: (props: any) =>
          props.value ? d3Format.format(',')(+formatRowValue(props.value)) : '',
      },
      {
        Header: 'Conc U90',
        accessor: 'Nasqan Conc U90',
        Cell: (props: any) =>
          props.value ? d3Format.format(',')(+formatRowValue(props.value)) : '',
      },
      {
        Header: 'DA',
        accessor: 'Da',
        Cell: (props: any) =>
          props.value ? d3Format.format(',')(+formatRowValue(props.value)) : '',
      },
    ],
    []
  );

  const flyToLocation = (location: any, zoomLevel: number) => {
    if (mapRef.current) {
      if (location && location.centroid_longitude) {
        mapRef.current?.flyTo({
          center: [parseFloat(location.centroid_longitude), parseFloat(location.centroid_latitude)],
          essential: true,
          zoom: zoomLevel,
        });
      }
    }
  };

  const hoverRowHandler = (event: any) => { };

  const clickRowHandler = (event: any) => {
    /*         if(userSelectedLocations.indexOf(event[locationField])>-1){
            //setUserSelectedLocations([])
            setPopupInfo(null);
            flyToLocation(event, 3);
        } else {
            if(event['Nasqan Site Type']){
                //setUserSelectedLocations([event[locationField]])
                setPopupInfo(event);
                if (mapRef.current) {
                    if (event && event.centroid_longitude) {
                        flyToLocation(event, 8);
                    }    
                }
            }
        } */
  };

  const onMouseEnter = useCallback((event: any | null) => {
    if (event.features && event.features[0] && event.features[0].properties) {
      setCursor('pointer');
      setPopupInfo(event.features[0].properties);
      setUserSelectedLocation(event.features[0].properties[locationField]);
      setMapHoverFilter([
        'in',
        ['get', locationField],
        event.features[0].properties[locationField],
      ]);
    }
  }, []);

  const onMouseLeave = useCallback((event: any | null) => {
    setCursor('');
    if (userSelectedLocation) {
      setUserSelectedLocation(null);
    }
    if (event && event.features && event.features[0]) {
      setPopupInfo(null);
      setMapHoverFilter(['in', ['get', locationField], '']);
    }
  }, []);

  useEffect(() => {
    if (usLocations.length > 0) {
      if (userSelectedLocations.indexOf(usLocations[0][locationField]) > -1) {
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', locationField], '']);
        flyToLocation(usLocations[0], 3);
      } else {
        setUserSelectedLocations(usLocations[0][locationField]);
        setPopupInfo(usLocations[0]);
        setTimeout(() => {
          setMapHoverFilter(['in', ['get', locationField], usLocations[0][locationField]]);
        }, 555);
        flyToLocation(usLocations[0], 8);
      }
    }
  }, [usLocations]);

  const onHandleClick = useCallback((event: any | null) => {
    const feature = event.features && event.features[0];
    if (feature) {
      setUsLocations([feature.properties]);
    }
  }, []);

  const onMapLoad = useCallback(() => {
    if (mapRef && mapRef.current) {
      setMapLoaded(true);
      mapRef.current.resize();
    }
  }, []);

  const pointInPolygonUpdate = (pointsSelected: any) => {
        setUserSelectedLocations(pointsSelected);
        setMapHoverFilter(['in', ['get', locationField], ['literal', pointsSelected]]);
  }

  const onDelete = useCallback((e: any | null) => {
    if (mapRef && mapRef.current) {
    setPopupInfo(null);
    setMapHoverFilter(['in', ['get', locationField], '']);
    setUserSelectedLocations([]);
    }
  }, []);

  const onHoverOut = useCallback((e: any | null) => {
    setPopupInfo(null);
    setMapHoverFilter(['in', ['get', locationField], '']);
  }, []);

  const deselectFilters = useCallback((e: any | null) => {
    setPopupInfo(null);
    setMapHoverFilter(['in', ['get', locationField], '']);
    setUserSelectedLocations([]);
  }, []);

  const renderPopup = () => {
    return (
      <Popup
      
        longitude={Number(popupInfo.centroid_longitude)}
        latitude={Number(popupInfo.centroid_latitude)}
        onClose={() => setPopupInfo(null)}
      >
        <div className='popup-container'>
          <h4>{popupInfo['Site Flow Name']}</h4>
          <div className='popup-content'>
            <div className='popup-content-left'>
              <p>Site Type</p>
              <p>Site Flow Id</p>
              <p>Tons</p>
              <p>Constituent</p>
              <p>Description</p>
            </div>
            <div className='popup-content-right'>
              <p>{popupInfo['Nasqan Site Type']}</p>
              <p>{popupInfo['Nasqan Site Flow Id']}</p>
              <p>{d3Format.format(',')(popupInfo['Nasqan Tons'])}</p>
              <p>{popupInfo['Nasqan Constituent']}</p>
              <p>{popupInfo['Nasqan Constituent Description']}</p>
            </div>
          </div>
        </div>
      </Popup>
    );
  };

  const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  return (
    <>
      <FullScreenComp handle={fullscreenhandle}>
        <div className='NationalStreamQualityAccountingNetwork' id='NationalStreamQualityAccountingNetwork-report'>
          <ReportHeader global={global} data={reportData} 
           reportID={"NationalStreamQualityAccountingNetwork-report"} 
           fullScreenClickHandle={fullscreenhandle.enter}/>
          <div className='container'>
            <div className='col main-col'>
              <div className='row gx-0'>
                <div className='map-best-container'>
                  <LoadingDataAnimation dataLoading={!isDataReportLoaded} />
                  <Map
                    id='NationalStreamQualityAccountingNetwork'
                    mapboxAccessToken={settings.maboxKey}
                   mapStyle={global.mapStyle}
                    onLoad={onMapLoad}
                    onClick={(e) => {
                      global.onMapClick(e)
                      onHandleClick(e)
                    }}
                    onMoveEnd={(e) => {
                      global.setViewport({
                        longitude: e.viewState.longitude,
                        latitude: e.viewState.latitude,
                        zoom: e.viewState.zoom,
                      })
                    }}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    preserveDrawingBuffer={true}
                    interactiveLayerIds={['pointlayer']}
                    cursor={cursor}
                    projection={global.globeView ? 'globe' : 'mercator' as any}
                    ref={mapRef as Ref<MapRef>}
                    initialViewState={{
                      longitude: settings.defaultLocationMap.lng,
                      latitude: settings.defaultLocationMap.lat,
                      zoom: 4,
                    }}
                  >
                    {featureData && (
                      <Source id='circlesource' type='geojson' data={featureData}>
                        <Layer {...layerStyle} />
                        <Layer {...hoverLayerStyle} filter={mapHoverFilter} />
                      </Source>
                    )}

                    <MapAddition global={global} 
                                mapRef={mapRef}
                                onDelete={onDelete}
                                PointInPolygonField={locationField}
                                PointInPolygonUpdate={pointInPolygonUpdate}
                                position={'low'}
                                zipOff={true}
                                MapSliderAdd={true}
                                statusMessage={statusMessage}/>

                    <div className='map-legend-container' onClick={deselectFilters}>
                      <MapLegend 
                        mapLegendData={mapLegendData} 
                        title={'Site Type'}
                        global={global} 
                      />
                    </div>
                    {popupInfo && renderPopup()}

                  </Map>

                </div>
              </div>

              <OverlayTrigger
                placement='top-start'
                overlay={<TooltipRB className='pdr-chart-tooltip'></TooltipRB>}
              >
                <div
                  className={
                    isFullScreenActive
                      ? 'row gx-0 table-chart-row table-parent-container expand-container'
                      : 'row gx-0 table-chart-row table-parent-container'
                  }
                  onMouseLeave={onHoverOut}
                >
                  <div className='report-options'>
                    <div className='report-options-button-container'>
                      <Button onClick={fullscreenContainerHandler}>
                        <img src={fullscreenIcon} />
                      </Button>
                    </div>
                  </div>
                  <ReportTableExpanded
                    data={tableData}
                    columns={tableColumns}
                    clickLoadStop={true}
                    clickRowHandler={clickRowHandler}
                    hoverRowHandler={hoverRowHandler}
                  />
                </div>
              </OverlayTrigger>
            </div>
          </div>
          <div className={'attribution-container'}>
            <ReportFooter />
          </div>
        </div>
      
      </FullScreenComp>
    </>
  );
}

type NationalStreamQualityAccountingNetworkModel = {
  date?: number;
  huc12?: string;
};
