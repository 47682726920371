import { Source, Layer, Popup, FillLayer, CircleLayer, LineLayer } from 'react-map-gl';
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import settings from "./../../settings.json";
import { useState, useContext, useEffect, useMemo } from 'react';
import { RefContext } from "./../../RefContext";
import { PointContext } from "./../../PointContext";
import { ActivePopupContext } from './../../ActivePopupContext';
import { v4 as uuidv4 } from 'uuid';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { adjustHexBrightness } from './../../features/utils';
import { DateTime } from 'luxon';
import { Expression, StyleFunction } from 'mapbox-gl';
import EvaluationLayers from "./EvaluationLayers";

const EvaluationLayer = ({ id, url, opacity, global }: any) => {

  let _currentUrl = 'https://mapservices.weather.noaa.gov/experimental/services/owp_fho/MapServer/WFSServer?request=GetFeature&outputFormat=GEOJSON&service=WFS&typeName=owp_fho:Days_1-7'
   let _sfUrl = `https://basemap.nationalmap.gov/arcgis/rest/services/USGSHydroCached/MapServer/tile/{x}/{y}/{z}`
    
_sfUrl = `https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/usda-crop-casma-anomaly-sub-9km/{z}/{x}/{y}.png?time=1734527700000`
_sfUrl = `https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/ce-wrcc-gridmet-eddi-forecast-1wk/{z}/{x}/{y}.png`



  let initialLayer = 'Evaporative Stress Index (ESI)';
  let siteItem = EvaluationLayers && EvaluationLayers.find((item: any) => item.name === initialLayer)

  const [popupInfo, setPopupInfo] = useState<any>([]);
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);
  const [category, setCategory] = useState<any>(siteItem && siteItem.name);
  const [categoryDetails, setCategoryDetails] = useState<any>(siteItem);

  const [currentUrl, setCurrentUrl] = useState<any>(_currentUrl);
  const [sfUrl, setsfUrl] = useState<any>(_sfUrl);
  const [featureData, setFeatureData] = useState<any>();

  const [categories, setCategories] = useState<any>([]);

  const [suppData, setSuppData] = useState<any>();
 
  const { currentRef } = useContext(RefContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
  // @ts-ignore
  const mapInstance = currentRef && currentRef?.current;


  useEffect(() => {
      if(global.layerMode && global.layerMode.layer==='EvaluationLayer'){
        setCategory(global.layerMode.category);
        setCategoryDetails(global.layerMode.site)

        let siteItem = global.layerStatus && global.layerStatus.find((item: any) => item.layerid === 'seagull')
        if(siteItem.layerid === 'seagull' && siteItem.active === true  && !featureData){ //
          retrieveSeagull();
        }
      }
  }, [global.layerMode]);

  useEffect(() => {
      if(global.layerStatus && global.layerStatus[0]){
        let newCat = [] as any;
        global.layerStatus.forEach((layer: any) => {
        
            if(layer.site){
              let siteItem = EvaluationLayers && layer && EvaluationLayers.find((item: any) => item.id === layer.id)
              if(siteItem){
                 if(siteItem.options && !siteItem.currentOption && siteItem.options > 0){
                   let optionItem = siteItem.options[0];
                   siteItem.currentOption = optionItem;
                 }
                 //setEvaluationLayerCategory(siteItem)     
                 //global.setLayerMode({layer: 'EvaluationLayer', category: EvaluationLayerCategory.name, site: siteItem })         
              }

              if(layer.active){
                newCat.push(layer.site)
              }
          }
        })

        setCategories(newCat);

        //setCategoryDetails(global.layerMode.site)

      }
  }, [global.layerStatus, global.layerStatus && global.layerStatus[0]]);


  useEffect(() => {
      if(categoryDetails){
        //console.log('categoryDetails, categoryDetails.currentOption', categoryDetails)
        if(categoryDetails.options && categoryDetails.currentOption && categoryDetails.optionsurl){        
          let newURL = categoryDetails.optionsurl.replace("${optionid}", categoryDetails.currentOption.id) 
          setsfUrl(newURL);
        } else {
          setsfUrl(categoryDetails.url);
        }

        if (mapInstance) {
            mapInstance.off('click', handleClick);
            mapInstance.on('click', handleClick);
        }
        //setFeatureData(null)
        if(popupInfo){  setPopupInfo(null);}
      }
  }, [categoryDetails, categoryDetails.currentOption]);

//getCoverageBounds

/*
https://www.weather.gov/gis/cloudgiswebservices
https://www.weather.gov/gis/IDP-GISRestMetadata
https://www.drought.gov/data-download
https://climatetoolbox.org/tool/Historical-Water-Watcher

https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/ce-wrcc-gridmet-long-blends/3/1/2.png?time=1733513400000
https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/ce-wrcc-gridmet-long-blends/3/1/2.png?time=1733513400000

https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/ce-wrcc-gridmet-short-blends/3/3/3.png?time=1733512500000

https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/temp-anom-30d/3/1/3.png?time=1733512500000

https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/nasa-sport-3km/3/2/4.png?time=1733511600000

https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/usda-crop-casma-anomaly-sub-9km/3/1/2.png?time=1733512500000

https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/json/v1/us/usdm/USDM-current.json?time=1733511600000


https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/precip-total-7d/3/0/1.png?time=1733511600000


https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/json/v1/us/usdm/usdm-change.json?time=1733512500000

https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/noaa-eddi-conus-01wk/3/1/3.png?time=1733511600000


https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/temp-mean-7d/3/3/3.png?time=1733511600000

https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/ce-wrcc-gridmet-long-blends/3/1/2.png?time=1733513400000
https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/ce-wrcc-gridmet-long-blends/3/1/2.png?time=1733513400000

https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/ce-wrcc-gridmet-short-blends/3/3/3.png?time=1733512500000

https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/temp-anom-30d/3/1/3.png?time=1733512500000

https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/nasa-sport-3km/3/2/4.png?time=1733511600000

https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/usda-crop-casma-anomaly-sub-9km/3/1/2.png?time=1733512500000
https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/ce-wrcc-gridmet-pdsi/3/1/1.png?time=1733513400000

https://basemap.nationalmap.gov/ArcGIS/rest/services/USGSHydroCached/MapServer/tile/9/200/105

https://mesonet2.agron.iastate.edu/cache/tile.py/1.0.0/nexrad-n0q-900913/3/1/3.png?1733321330042

https://basemap.nationalmap.gov/arcgis/rest/services/USGSHydroCached/MapServer/tile/6/25/16


https://maps.water.noaa.gov/server/rest/services/nwm/mrf_gfs_5day_max_inundation_extent/MapServer
https://maps.water.noaa.gov/server/rest/services/rfc/rfc_based_5day_max_inundation_extent/FeatureServer
https://maps.water.noaa.gov/server/rest/services/rfc/rfc_based_5day_max_inundation_extent/MapServer
https://maps.water.noaa.gov/server/rest/services/reference/static_nwm_coastal_domain_noaa/MapServer

https://tds-proxy.nkn.uidaho.edu//thredds/wms/MET/summaries//vpp_7d_usdm.nc?service=WMS&request=GetMap&layers=vpp&styles=boxfill%2Fusdmwwet_11&format=image%2Fpng&transparent=true&version=1.3.0&numcolorbands=11&colorscalerange=0%2C10&bovemaxcolor=transparent&belowmincolor=transparent&height=256&width=256&crs=EPSG%3A3857&bbox=-10018754.171394622,2504688.542848655,-7514065.628545966,5009377.085697314


https://mapservices.weather.noaa.gov/vector/rest/services/obs/NWM_Stream_Analysis/MapServer/export?bbox={bbox-epsg-3857}&transparent=true&format=png32&layers=show:${categorySel}&bboxSR=102100&imageSR=3857&f=image`
https://api.water.noaa.gov/nwps/v1/gauges?srid=SRID_UNSPECIFIED

https://mapservices.weather.noaa.gov/eventdriven/rest/services/water/riv_gauges/MapServer/15/query?f=json&spatialRel=esriSpatialRelIntersects&where=status%20%3D%20%27minor%27%20OR%20status%20%3D%20%27moderate%27%20OR%20status%20%3D%20%27major%27&outFields=action%2Cflood%2Cforecast%2Cgaugelid%2Clocation%2Clowthresh%2Cmajor%2Cmoderate%2Cstate%2Cstatus%2Cunits%2Curl%2Cwaterbody%2Cwfo%2Cobjectid&returnGeometry=false

https://mapservices.weather.noaa.gov/vector/rest/services/obs/NWM_Stream_Analysis/MapServer/15/query?where=tile_id%3D0+AND+stream_order%3D5&outFields=Cg_valid_time&returnGeometry=false&f=pjson


https://gispub.epa.gov/arcgis/rest/services/OW/ATTAINS_Assessment/MapServer

https://gispub.epa.gov/arcgis/rest/services/OW/ATTAINS_Assessment/MapServer?f=pjson

https://dashboard.waterdata.usgs.gov/api/gwis/2.1.1/service/site?agencyCode=USGS&siteNumber=295323085151700&open=274632

https://labs.waterdata.usgs.gov/api/nldi/linked-data/epa_nrsa?f=json


https://api.waterdata.usgs.gov/samples-data/summary/USGS-07023000?mimeType=text%2Fcsv

https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/ce-GRIDMET-eddi-1wk/%7Bz%7D/%7Bx%7D/%7By%7D.png

ce-wrcc-snodas-depth-prcntanom/  2024-12-31 07:02  -   
ce-wrcc-snodas-swe-prcntanom/  2024-12-31 07:02  -   
ce-wrcc-snodas-swe-prcntanom_8zooms_3-11-2022/  2024-05-23 12:58  -   


https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/json/v1/sector/fire/fires.geojson?time=1736727300000

https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/json/v1/sector/fire/fireoutlook-day1.json?time=1736727300000



https://lfps.usgs.gov/arcgis/rest/services
https://lfps.usgs.gov/arcgis/rest/services/Landfire_LF240?f=pjson
https://www.usgs.gov/the-national-map-data-delivery/gis-data-download
https://www.sciencebase.gov/catalog/items?q=&filter=tags=Lidar%20Point%20Cloud%20(LPC)
https://www.usgs.gov/national-hydrography/access-national-hydrography-products  

https://hydro.nationalmap.gov/arcgis/rest/services/nhd/MapServer

https://maps.disasters.nasa.gov/arcgis/apps/MinimalGallery/index.html#query=helene&page=2


XMin: -160.20769579499998
YMin: 17.68546757300004
XMax: -64.60816967699998
YMax: 63.63003693600007

legend https://mapservices.weather.noaa.gov/vector/rest/services/obs/NWM_Stream_Analysis/MapServer/20?f=pjson
https://luminocity3d.org/WorldPopDen/tiles2020/8/71/108.png
//
    let newUrl = `https://mapservices.weather.noaa.gov/vector/rest/services/obs/NWM_Stream_Analysis/MapServer/export?bbox={bbox-epsg-3857}&transparent=true&format=png32&layers=show:${layerLevel}&bboxSR=102100&imageSR=3857&f=image`
https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/tile/v1/ciesin-pop-1km/7/34/52.png
https://mapservices.weather.noaa.gov/raster/rest/services/snow/NOHRSC_Snow_Analysis/MapServer?f=json
https://mapservices.weather.noaa.gov/raster/rest/services/snow/NOHRSC_Snow_Analysis/MapServer/7

https://mapservices.weather.noaa.gov/raster/rest/services/snow/NOHRSC_Snow_Analysis/MapServer


https://mapservices.weather.noaa.gov/raster/rest/services/snow/NOHRSC_Snow_Analysis/MapServer/export?dpi=96&transparent=true&format=png32&layers=show:3&bbox=-12523442.714243278,3757032.814272985,-11271098.442818949,5009377.085697312&bboxSR=3857&size=256,256&imageSR=3857&f=image

https://experience.arcgis.com/experience/a610ec1065d14f2bb6a0890529839247/page/Page-1/?views=CIESIN-Gridded-Population


https://mapservices.weather.noaa.gov/raster/rest/services/snow/NOHRSC_Snow_Analysis/MapServer/export?dpi=96&transparent=true&format=png32&layers=show%3A3&bbox=-12108445.64436835%2C4101071.9368550405%2C-7461074.3246308705%2C7036253.823005028&bboxSR=102100&imageSR=102100&size=950%2C600&f=image

swe
https://mapservices.weather.noaa.gov/raster/rest/services/snow/NOHRSC_Snow_Analysis/MapServer/export?dpi=96&transparent=true&format=png32&layers=show%3A7&bbox=-12108445.64436835%2C4101071.9368550405%2C-7461074.3246308705%2C7036253.823005028&bboxSR=102100&imageSR=102100&size=950%2C600&f=image

Landsat, Sentinel-2, MODIS, and VIIRS

https://services1.arcgis.com/jUJYIo9tSA7EHvfZ/arcgis/rest/services/DINS_2025_Palisades_Public_View/FeatureServer/0/query?f=pbf&where=1%3D1&returnGeometry=true&spatialRel=esriSpatialRelIntersects&outFields=*&outSR=102100&resultOffset=0&resultRecordCount=20000&cacheHint=true&quantizationParameters=%7B%22mode%22%3A%22view%22%2C%22originPosition%22%3A%22upperLeft%22%2C%22tolerance%22%3A1.058333333333334%2C%22extent%22%3A%7B%22xmin%22%3A-20037507.842788246%2C%22ymin%22%3A-30240971.45838615%2C%22xmax%22%3A20037507.842788246%2C%22ymax%22%3A30240971.45838615%2C%22spatialReference%22%3A%7B%22wkid%22%3A102100%2C%22latestWkid%22%3A3857%7D%7D%7D

https://services1.arcgis.com/jUJYIo9tSA7EHvfZ/arcgis/rest/services/CA_Perimeters_NIFC_FIRIS_public_view/FeatureServer/0/query?f=pbf&where=OBJECTID%20%3D%201290&returnGeometry=true&spatialRel=esriSpatialRelIntersects&outFields=*&maxRecordCountFactor=2&outSR=102100&resultOffset=0&resultRecordCount=4000&cacheHint=true&quantizationParameters=%7B%22mode%22%3A%22view%22%2C%22originPosition%22%3A%22upperLeft%22%2C%22tolerance%22%3A1.058333333333334%2C%22extent%22%3A%7B%22xmin%22%3A-123.82402947545071%2C%22ymin%22%3A32.55325660800003%2C%22xmax%22%3A-114.6183011944071%2C%22ymax%22%3A43.63834511862799%2C%22spatialReference%22%3A%7B%22wkid%22%3A4269%2C%22latestWkid%22%3A4269%7D%7D%7D



https://sedac.ciesin.columbia.edu/arcgis-gis-server/rest/services/ciesin-popgrid/popgrid_counts/MapServer/export?bbox=-9282512.714951804%2C3041582.229523735%2C-8830005.507503562%2C3216470.1502402164&size=370%2C143&dpi=96&format=png24&transparent=true&bboxSR=3857&imageSR=3857&layers=show%3A13&f=json
https://sedac.ciesin.columbia.edu/arcgis-gis-server/rest/services/ciesin-popgrid/popgrid_counts/MapServer/export?bbox=-9282512.714951804%2C3041582.229523735%2C-8830005.507503562%2C3216470.1502402164&size=370%2C143&dpi=96&format=png24&transparent=true&bboxSR=3857&imageSR=3857&layers=show%3A12&f=json
https://sedac.ciesin.columbia.edu/mapping/popgrid/

https://earth.gov/ghgcenter/api/raster/searches/822d8911ace54263c201fffc56d8e752/tiles/WebMercatorQuad/3/2/3?assets=population-density&colormap_name=ylorrd&rescale=0%2C1000

https://gibs-a.earthdata.nasa.gov/wmts/epsg4326/best/wmts.cgi?TIME=2025-01-05T00:00:00Z&layer=MODIS_Terra_CorrectedReflectance_TrueColor&style=default&tilematrixset=250m&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix=0&TileCol=0&TileRow=0
https://mapservices.weather.noaa.gov/raster/rest/services/snow/NOHRSC_Snow_Analysis/MapServer/export?bbox=-12753365.29532509%2C6589483.334408476%2C-8776193.839590799%2C3766816.753893487&size=813%2C577&dpi=96&format=png32&transparent=true&bboxSR=3857&imageSR=3857&layers=show%3A3&f=image
https://mapservices.weather.noaa.gov/raster/rest/services/snow/NOHRSC_Snow_Analysis/MapServer/?f=json

https://mapservices.weather.noaa.gov/raster/rest/services/snow/NOHRSC_Snow_Analysis/MapServer/legend?f=json
https://mapservices.weather.noaa.gov/raster/rest/services/snow/NOHRSC_Snow_Analysis/MapServer/


https://www.nocodegeography.com/spatial-analisys/people-living-within-30-meter-grid/


SEDAC Gridded World Population Density
Population Density
NASA · Global · Annual · persons/km²

Gridded population density estimates for the years 2000, 2005, 2010, 2015, and 2020 from Gridded Population of the World (GPW) version 4, revision 11

Temporal Extent: 2000 - 2020
Temporal Resolution: Annual, every 5 years
Spatial Extent: Global
Spatial Resolution: 30 arc-seconds (~1 km at equator)
Data Units: Number of persons per square kilometer (persons/km²)
Data Type: Research
Data Latency: 5 years

The OPERA Dynamic Surface Water Extent imagery layer is a Level-3 (L3) product that maps surface water every few days. The resolution is 30 m and the layer has 5 classifications: Not Water, Open Water, Partial Surface Water, Snow/Ice, and Cloud/Cloud Shadow. The input dataset for generating each product is the Harmonized Landsat Sentinel-2 (HLS) dataset. The OPERA Dynamic Surface Water Extent (L3) imagery layer is available through the Observational Products for End-Users from Remote Sensing Analysis (OPERA) project.

References: OPERA_L3_DSWX-HLS_V1

https://firms.modaps.eosdis.nasa.gov/mapserver/wmts/fires/24hrs/{z}/{y}/{x}

https://gibs-b.earthdata.nasa.gov/wmts/epsg4326/best/wmts.cgi?TIME=2024-12-18T00:00:00Z&layer=OPERA_L3_Dynamic_Surface_Water_Extent-HLS&style=default&tilematrixset=31.25m&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=9&TileCol=169&TileRow=107


https://gibs-b.earthdata.nasa.gov/wmts/epsg4326/best/wmts.cgi?TIME=2024-12-12T00:00:00Z&layer=VIIRS_NOAA20_Sea_Ice&style=default&tilematrixset=1km&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=1&TileCol=2&TileRow=0
https://worldview.earthdata.nasa.gov/?v=-381.5386758697137,-140.2050287290815,263.4674878422292,146.69706357136062&l=Reference_Labels_15m(hidden),Reference_Features_15m(hidden),Coastlines_15m,VIIRS_NOAA20_Sea_Ice,BlueMarble_NextGeneration(hidden),Land_Water_Map(hidden),VIIRS_NOAA21_CorrectedReflectance_TrueColor(hidden),VIIRS_NOAA20_CorrectedReflectance_TrueColor(hidden),VIIRS_SNPP_CorrectedReflectance_TrueColor(hidden),MODIS_Aqua_CorrectedReflectance_TrueColor(hidden),MODIS_Terra_CorrectedReflectance_TrueColor(hidden)&lg=true&t=2024-12-10-T08%3A00%3A00Z

https://services.terrascope.be/wmts/v2?layer=WORLDCOVER_2021_S1_VVVHratio&style=&tilematrixset=EPSG:3857&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/png&TileMatrix=EPSG:3857:4&TileCol=7&TileRow=9&TIME=2025-01-05

https://services.terrascope.be/wmts/v2?layer=WORLDCOVER_2020_MAP&style=&tilematrixset=EPSG:3857&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/png&TileMatrix=EPSG:3857:4&TileCol=5&TileRow=9&TIME=2025-01-05


https://www.usgs.gov/the-national-map-data-delivery


https://openveda.cloud/api/raster/searches/3ac1aa707b9835c8db53eca437228b05/tiles/WebMercatorQuad/5/8/11?assets=cog_default&colormap_name=magma&rescale=0%2C200&nodata=0

NLDAS precipitation


https://services3.arcgis.com/T4QMspbfLg3qTGWY/arcgis/rest/services/WFIGS_Incident_Locations_Current/FeatureServer/0/query?outFields=*&where=1%3D1&f=geojson

https://services3.arcgis.com/T4QMspbfLg3qTGWY/arcgis/rest/services/WFIGS_Interagency_Perimeters_Current/FeatureServer/0/query?outFields=*&where=1%3D1&f=geojson

https://www.ospo.noaa.gov/data/spl/kmlfiles/fire/hms_smoke20250112.kml

https://www.ospo.noaa.gov/data/spl/kmlfiles/fire/GOES-WESTfire20250112.kml


https://www.ospo.noaa.gov/data/spl/kmlfiles/fire/MODISTERRAfire20250112.kml


https://www.ospo.noaa.gov/data/spl/kmlfiles/fire/SUOMINPPfire20250112.kml
https://www.ospo.noaa.gov/data/spl/kmlfiles/fire/NOAA20fire20250112.kml
https://www.ospo.noaa.gov/data/spl/kmlfiles/fire/NOAA21fire20250112.kml

https://firms.modaps.eosdis.nasa.gov/mapserver/wms/fires/YourMapKey/fires_viirs_24/?REQUEST=GetMap&WIDTH=1024&HEIGHT=512&BBOX=-180,-90,180,90

https://firms.modaps.eosdis.nasa.gov/mapserver/wms/fires/YourMapKey/?REQUEST=GetMap&layers=fires_modis&TIME=2021-01-01/2021-01-10&WIDTH=512&HEIGHT=512&BBOX=-20037508.3427892480,-20037508.3427892480,20037508.3427892480,20037508.3427892480&SRS=EPSG:3857


https://maps.disasters.nasa.gov/ags03/rest/services/NRT/modis_flood_2_day/ImageServer/exportImage?f=image&renderingRule=%7B%22rasterFunction%22%3A%22modis_flood_lance%22%7D&time=1734134400000%2C1734220800000&bbox=-9544439.27076637%2C3925602.624622763%2C-8053611.471092689%2C5324705.990354258&imageSR=102100&bboxSR=102100&size=1219%2C1144

https://seagull-api.glos.org/api/v2/obs-latest


geometry: {"x":-8826713.437722607,"y":3747663.9432266164,"spatialReference":{"wkid":3857}}
tolerance: 5
returnGeometry: true
mapExtent: {"xmin":-9506697.241347354,"ymin":3495727.4979987424,"xmax":-8611466.766071606,"ymax":4412971.8374206135,"spatialReference":{"wkid":3857}}

mapExtent: {"xmin":-9506697.241347354,"ymin":3495727.4979987424,"xmax":-8611466.766071606,"ymax":4412971.8374206135,"spatialReference":{"wkid":4326}}

*/



  const retrieveSeagull = () => {
    let URLData = 'https://seagull-api.glos.org/api/v1/obs-datasets.geojson'
       fetch(URLData)
            .then(function (response) {
              //@ts-ignore  
              return response.text();
            })
            .then(function (responseText:any) {
              let stations = JSON.parse(responseText);
              if(stations && stations.features){
                const filtered = stations.features.filter((ele: any) => ele.properties.platform_type !== 'sampling_location')
            
                stations.features = filtered;
                 retrieveSeagullPm(stations)
               } else {
                 setFeatureData(undefined)
               }
            });
  }

  const retrieveSeagullPm = (stations: any) => {
      const fipsFileURL = "/json/seagull_short.json";
      fetch(fipsFileURL)
        .then((response) => response.text())
        .then((responseText) => {
          if (responseText) {
            const data = JSON.parse(responseText);
            retrieveSeagullUnits(stations, data);
          }
        });
  }

  const retrieveSeagullUnits = (stations: any, data:any) => {
      const fipsFileURL = "/json/seagull_units.json";
      fetch(fipsFileURL)
        .then((response) => response.text())
        .then((responseText) => {
          if (responseText) {
            const units = JSON.parse(responseText);
            retrieveSeagullCurrent(stations, data, units);
          }
        });
  }

  const retrieveSeagullCurrent = (stations:any, parameters:any, units:any) => {

    let URLData = 'https://seagull-api.glos.org/api/v2/obs-latest'
       fetch(URLData)
            .then(function (response) {          
              //@ts-ignore  
              return response.text();
            })
            .then(function (responseText:any) {    
              let observations = JSON.parse(responseText);         
              let newStations = Object.assign(stations)             
              for (let dataItem of observations) {
                  let newArrayObservations= [] as any;
                  for (let parameter of dataItem.parameters) {
                    let newObs = {} as any;
                    let parameterItem = parameters.find((item: any) => item.parameter_id === parameter.parameter_id)
                    if(parameterItem){
                      let unitItem = units.find((item: any) => item.standard_name === parameterItem.parameter_name)
                      if(unitItem){
                        newObs.unit = unitItem.canonical_unit_id;
                       
                      }
                      newObs.parameter_id = parameter.parameter_id
                      newObs.timestamp = DateTime.fromSeconds(parameter.observations[0].timestamp).toLocaleString(DateTime.DATETIME_SHORT);
                      newObs.value = parameter.observations[0].value
                      newObs.depth = parameter.observations[0].depth
                      newObs.parameter_name = parameterItem.parameter_name
                    
                      if(( (newObs.unit && newObs.unit ==='kelvin')||parameterItem.parameter_name.includes("temperature")) && parameter.observations[0].value){
                          newObs.value = ((parameter.observations[0].value - 273) * (9 / 5) + 32).toFixed(0);
                          newObs.unit = 'Fahrenheit';
                      } else if(newObs.unit && newObs.unit ==='kilograms_per_cubic_meter' && parameter.observations[0].value){
                          newObs.value = ((parameter.observations[0].value ) * 1000).toFixed(1);
                          newObs.unit = 'mg/L';
                      } else if(newObs.unit && newObs.unit ==='siemens_per_meter' && parameter.observations[0].value){
                          newObs.value = ((parameter.observations[0].value ) * 1000).toFixed(1);
                          newObs.unit = 'µS/cm';
                      } else if(( (newObs.unit && newObs.unit ==='meters_per_second')||parameterItem.parameter_name.includes("velocity"))  && parameter.observations[0].value){
                          newObs.value = ((parameter.observations[0].value ) * 1.94384449).toFixed(1);
                          newObs.unit = 'kts';
                      } else if(newObs.unit && newObs.unit ==='pa' && parameter.observations[0].value){
                          newObs.value = ((parameter.observations[0].value ) * 0.00029529983071445).toFixed(2);
                          newObs.unit = 'inHg';
                      } else if(newObs.unit && newObs.unit ==='meters' && parameter.observations[0].value){
                          newObs.value = ((parameter.observations[0].value ) * 3.28084).toFixed(1);
                          newObs.unit = 'ft';
                      } else if(!newObs.unit && parameterItem.parameter_name.includes("fractional_saturation") && parameter.observations[0].value){
                   
                          newObs.unit = 'percent';
                      } else { 
                        //console.log(newObs.parameter_name, newObs.parameter_name.indexOf('temperature'), parameterItem.parameter_name.includes("temperature"), dataItem, newObs.unit, parameterItem)
                      }
                      newArrayObservations.push(newObs)                     
                    }

                  }

                  let siteItem = newStations.features.find((item: any) => item.properties.obs_dataset_id === dataItem.obs_dataset_id)
                  if(siteItem){
                      siteItem.properties.parameters = [];
                      if(siteItem.properties.observations){
                        //console.log('exists', dataItem.obs_dataset_id)
                          siteItem.properties.observations = newArrayObservations;
                      } else {
                          siteItem.properties.observations = newArrayObservations;
                      }
                  }

              }  
              setFeatureData(newStations)
            });

  }


    const handleClick = (event: any) => {
      const layerObject = mapInstance.queryRenderedFeatures(event.point);      
      //console.log(layerObject, mapInstance, mapInstance.getStyle(), categoryDetails, `${id}-${categoryDetails.id}-layer`,categoryDetails.urlclick)
      if (layerObject && layerObject.length>0 && layerObject[0].properties && layerObject[0].layer.id !=="EvaluationLayer-ATTAINS_AssessmentH-layer") {
        if(layerObject[0].properties["longitude"]&&!layerObject[0].properties["centroid_longitude"]){
            layerObject[0].properties["centroid_longitude"]=layerObject[0].properties["longitude"]
        }
        if(layerObject[0].properties["latitude"]&&!layerObject[0].properties["centroid_latitude"]){
            layerObject[0].properties["centroid_latitude"]=layerObject[0].properties["latitude"]
        }

        if(!layerObject[0].properties["centroid_latitude"] && !layerObject[0].properties["centroid_longitude"]){
          if(layerObject[0].geometry?.coordinates){
            layerObject[0].properties["centroid_longitude"]=layerObject[0].geometry.coordinates[0];
            layerObject[0].properties["centroid_latitude"]=layerObject[0].geometry.coordinates[1];
          }
        }


        if(layerObject[0].properties["centroid_longitude"] && !isNaN(layerObject[0].properties["centroid_longitude"])&&layerObject[0].properties["centroid_latitude"] && !isNaN(layerObject[0].properties["centroid_latitude"])){
          setPopupInfo([layerObject[0].properties])
        }
      } else if(categoryDetails.urlclick){
        console.log(event.point, event)
          let newBbox = `${event.lngLat.lng - .004}%2C${event.lngLat.lat - .004}%2C${event.lngLat.lng + .004}%2C${event.lngLat.lat + .004}`;
          let newBboxWide = `${event.lngLat.lng - .07}%2C${event.lngLat.lat - .07}%2C${event.lngLat.lng + .07}%2C${event.lngLat.lat + .07}`

          //newBbox = `{"x":-9049298.06408898,"y":3686514.3205984915,"spatialReference":{"wkid":3857}}`
          //current newBbox = `{"xmin":${event.lngLat.lng - .07},"ymin":${event.lngLat.lat - .07},"xmax":${event.lngLat.lng + .07},"ymax":${event.lngLat.lat + .07},"spatialReference":{"wkid":4326}}`

          //newBbox = `{"x":${event.lngLat.lng},"y":${event.lngLat.lat},"spatialReference":{"wkid":4326}}`
          let newBbox2 = `{"xmin":${event.lngLat.lng - .07},"ymin":${event.lngLat.lat - .07},"xmax":${event.lngLat.lng + .07},"ymax":${event.lngLat.lat + .07},"spatialReference":{"wkid":4326}}`
          //newBbox = `${event.lngLat.lng - .1}%2C${event.lngLat.lat - .1}%2C${event.lngLat.lng + .1}%2C${event.lngLat.lat + .1}`;

          let URLclick = categoryDetails.urlclick?.replaceAll("${optionid}", newBbox ).replaceAll("${optionid2}", newBbox2 );
          //https://www.ndbc.noaa.gov/radial_search.php?lat1=0.000N&lon1=0.000E&uom=E&dist=250

           fetch(URLclick)
            .then((response:any) => response.text())
            .then((responseText:any) => {
                if(responseText && JSON.parse(responseText)){
                  let newInfo = JSON.parse(responseText);
                  if(newInfo && newInfo.features && newInfo.features.length>0 && newInfo.features[0].properties){
                    let newPopup = newInfo.features[0].properties;
                    newPopup.centroid_latitude = event.lngLat.lat;
                    newPopup.centroid_longitude = event.lngLat.lng;
                    setPopupInfo([newPopup])
                  }

                  if(newInfo?.results?.length>0 && newInfo.results[0].attributes){
                    let newPopup = newInfo.results[0].attributes;
                    newPopup.centroid_latitude = event.lngLat.lat;
                    newPopup.centroid_longitude = event.lngLat.lng;
                    setPopupInfo([newPopup])
                  }                  

                }
            })

           let URLclickWide  = categoryDetails.urlclick?.replaceAll("${optionid}", newBboxWide ).replaceAll("${optionid2}", newBbox2);
           fetch(URLclickWide)
            .then((response:any) => response.text())
            .then((responseText:any) => {
                if(responseText && JSON.parse(responseText)){
                  let newInfo = JSON.parse(responseText);
                  if(newInfo?.features?.length>0 && newInfo.features[0].properties){
             
                     setFeatureData(newInfo)
                  } else {
                     setFeatureData(null)
                  }

                }
            })

      } else {
        setPopupInfo(null)
      }

    };


useEffect(() => {
    if (mapInstance) {
        mapInstance.on('click', handleClick);
    }

    return () => {
        if (mapInstance) {
            mapInstance.off('click', handleClick);
        }
    };
}, [mapInstance]);



  const renderThumb = (style: any) => {
    const thumbStyle = {
      backgroundColor: 'white'
    };
    return (
      <div style={{ ...style, ...thumbStyle }} />
    );
  }

  let importOrder = [
    "Hazard_Type",
    "Hazard_Abbreviation",
    "000",
    "Max_Status", "max_status", "Max Status", " Max Status", "Max Status ", 
    "Name",
    "Max_Streamflow__cfs_", 

    "MAXFEATURES",
    "Guage_Location",
    "outlook",
    "valid_time",
    "State",
    "Water_Body",    
    "Observed",
    "Units",
    "Flood_Status",
    "Forecast_Guage_Level_Value",
    "Hydrograph",
    "Region",
    "Category",  
    "Gauge_ID",
    "Issued_Time",
    "Alert_Type",
    "Forecast_Office",
    "Ends",
    "Water_Level_In_Need_of_Action",
    "Secondary_Forecast_Value",
    "NWM_Feature_ID",
    "Associated_NWS_LID",
    "Has_Forecast_Point",
    "Message_ID",
    "GIS_File_Date",
    "GIS_Ingest_Date",
    "Longitude",
    "Latitude",
    "centroid_longitude",
    "centroid_latitude"
  ]


const sortByObject: any = importOrder
    .reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
        };
    }, {});

  const popoverHydro = (
    <Popover style={{height: 580, width: 840}} id="mapslider-categories-popover" className="mapslider-categories-popover" key={"mapslider-categories-popover-"}>
      <Popover.Header as="h3">NOAA/National Weather Service Precipitation Analysis</Popover.Header>
      <Popover.Body  className="mapslider-categories-popover-body" >
            <iframe id="iframeModalWindow" style={{height: 480, width: 740}} src="https://water.noaa.gov/gauges/crmf1#official-CRMF1" name="iframe_modal"></iframe>
      </Popover.Body>
    </Popover>
  );

  const pickColor = (item: string) => {
    if(item){
      if(item==='major'){
        return '#bf7fbf'
      } else if(item==='minor'){
        return '#ffff7f'
      } else if(item==='moderate'){
        return '#ff3232'
      } else {
        return 'white'
      }
    } else {
      return 'white'
    }
  }


  const renderFields = (objectMapArray: any) => {
    return objectMapArray.map((objectMap: any, index: any) => {
/*      if(objectMap.Max_Status){
        objectMap["Max Status"] = objectMap.Max_Status;
      }*/
      let sortedFields = Object.entries(objectMap).sort((a: any, b: any) => sortByObject[a[0]] - sortByObject[b[0]]);

      return sortedFields.map((element: any, index: any) => {
        const skipFields = ['parameters', 'obs_dataset_id', 'layerName', 'counts', 'results', 'radius', 'Message_ID', 'GmlID', 'OBJECTID', 'Object_ID', 'objectid', 'id'];
        if (skipFields.indexOf(element[0]) > -1) {
          return null;
        } else if (element[0] === ('waterbodyreportlink')) {
          return (
            <p className="GD-p-comid" key={uuidv4()}>
              <span style={{ opacity: .9 }}>{'waterbodyreportlink'}</span>
              <span style={{ marginLeft: 10, fontSize: '1.3em', opacity: 1 }}>
                <a href={element[1]} target={'_blank'}>More information</a>
              </span>
            </p>
          )
        } else if ((element[0] === ('org_platform_id')) && (element[1] && element[1].length ===5)) { 
          return (
            <p className="GD-p-comid" key={uuidv4()}>
              <span style={{ opacity: .9 }}>{`NDBC Station Page: ${element[1]}`}</span>
              <span style={{ marginLeft: 10, fontSize: '1.3em', opacity: 1 }}>
                <a href={`https://www.ndbc.noaa.gov/station_page.php?station=${element[1]}&unit=M`} target={'_blank'}>More information</a>
              </span>
            </p>
          )
        } else if (element[0] === ('URL')) { 
          return (
            <p className="GD-p-comid" key={uuidv4()}>
              <span style={{ opacity: .9 }}>{'waterbodyreportlink'}</span>
              <span style={{ marginLeft: 10, fontSize: '1.3em', opacity: 1 }}>
                <a href={element[1]} target={'_blank'}>More information</a>
              </span>
            </p>
          )
        } else if (element[0] === 'observations') {
          let parsedObs = JSON.parse(element[1]);

          return parsedObs && parsedObs.map((elementb: any, indexb:any) => (
            <div>
              <p className="GD-p-comid" key={uuidv4()} >
                <span style={{ opacity: .9 }}>{elementb.parameter_name}:</span><br />
                <span style={{ marginLeft: 30, fontSize: '1.3em', opacity: 1 }}>    {elementb.value} {elementb.unit||''}</span><br />
                <span style={{ marginLeft: 20, fontSize: '1.3em', opacity: 1 }}>    {(elementb.depth && elementb.depth!=='0') ? `depth: ${elementb.depth}` :  ``} </span>
                <span style={{ marginLeft: 50, fontSize: '1.3em', opacity: 1 }}>    {elementb.timestamp} </span>
              </p><br />
            </div>
           )
          )  
        } else if (element[0] === 'Hydrograph') {
          return (
            <div>
              <div>

              </div>
              <p className="GD-p-comid" key={uuidv4()}>
                <span style={{ opacity: .9 }}>{'URL:'}</span>
                <span style={{ marginLeft: 10, fontSize: '1.3em', opacity: 1 }}>
                  <a href={element[1]+'#uk-switcher-1'} target={'_blank'}>Navigate to NOAA's Hydrograph</a>
                </span>
              </p>
            </div>
           
          )  
        } else if (element[0] === 'Forecast_Office' && element[1] && element[1].length > 2) {
          return (
            <p className="GD-p-comid" key={uuidv4()}>
              <span style={{ opacity: .9 }}>{'Forecast_Office'}:</span>
              <span style={{ marginLeft: 10, fontSize: '1.3em', opacity: 1 }}>
                <a href={`https://www.weather.gov/${element[1].substr(0, 10)}`} target={'_blank'}>Navigate to NWS {element[1]}</a>
              </span>
            </p>
          )
        } else if (element[0] === 'centroid_latitude') {
          return (
            <div key={uuidv4()} >
              <p className="GD-p-comid" key={uuidv4()} >
                <span style={{ opacity: .9 }}>{element[0]}:</span>
                <span style={{ marginLeft: 10, fontSize: '1.3em', opacity: 1 }}>    {element[1]} </span>
              </p>
              <hr key={uuidv4()} style={{ marginBottom: 20 }} />
              <hr key={uuidv4()} style={{ marginBottom: 10 }} />
            </div>
          )
        }  else if (element[0] === 'Flood_Status') {

          return (
            <div key={uuidv4()} >
              <p className="GD-p-comid" key={uuidv4()} >
                <span style={{ opacity: .9 }}>{element[0]}:</span>
                <span style={{ marginLeft: 10, fontSize: '1.5em', opacity: 1 }}>
                  <span style={{ color: pickColor(element[1]) }} >{element[1]}</span> 
                </span>
              </p>
              <hr key={uuidv4()} style={{ marginBottom: 10,  width: 200, marginLeft: "20%" }} />
            </div>
          )
        } else if (element[0] === 'Guage_Location') {
       
          return (

            <div key={uuidv4()} style={{ marginTop: 10}}>
                {objectMap && objectMap.Observed && objectMap.Observed!=="null" && ( <h4>Current Observed Values</h4>  )}
                {objectMap && objectMap.Observed && objectMap.Observed==="null" && ( <h4>Forecasted Values</h4>  )}
                {objectMap && objectMap.outlook && objectMap.outlook!=="null" && ( <h4>River Flooding Outlook</h4>  )}
              <p className="GD-p-comid" key={uuidv4()} >
                <span style={{ opacity: .9 }}>{element[0]}:</span>
                <span style={{ marginLeft: 10, fontSize: '1.5em', opacity: 1 }}>
                  <span  >{element[1]}</span> 
                </span>
              </p>
            </div>
          )
        } else if (element[0] === 'outlook') {
       
          return (
                
            <div key={uuidv4()} style={{ marginTop: 10}}>

                {objectMap && objectMap.outlook && objectMap.outlook!=="null" && ( <h4>River Flooding Outlook</h4>  )}
              <p className="GD-p-comid" key={uuidv4()} >
                <span style={{ opacity: .9 }}>{element[0]}:</span>
                <span style={{ marginLeft: 10, fontSize: '1.5em', opacity: 1 }}>
                  <span  >{element[1]}</span> 
                </span>
              </p>
            </div>
          )
        } else if (element[0] === 'Region') {
       
          return (
                
            <div key={uuidv4()} style={{ marginTop: 10}}>

                {objectMap && objectMap.Region && objectMap.Region!=="null" && ( <h4>Flood Hazard Outlook</h4>  )}
              <p className="GD-p-comid" key={uuidv4()} >
                <span style={{ opacity: .9 }}>{element[0]}:</span>
                <span style={{ marginLeft: 10, fontSize: '1.5em', opacity: 1 }}>
                  <span  >{element[1]}</span> 
                </span>
                </p>
            </div>
          )
        }  else if (element[0] === 'Hazard_Type') {
       
          return (
              
            <div key={uuidv4()} style={{ marginTop: 10}}>
 
                {objectMap && objectMap.Hazard_Type && objectMap.Hazard_Type!=="null" && ( <h4>NWS Hazard Alert</h4>  )}
              <p className="GD-p-comid" key={uuidv4()} >
                <span style={{ opacity: .9 }}>{element[0]}:</span>
                <span style={{ marginLeft: 10, fontSize: '1.5em', opacity: 1 }}>
                  <span>{element[1]}</span> 
                </span>
              </p>
            </div>
          )
        } else if (element[0] === 'Name') {

          return (
              
            <div key={uuidv4()} style={{ marginTop: 10}}>

                {objectMap && objectMap.Max_Status && objectMap.Max_Status!=="null" && ( <h4>5day Max Streamflow </h4>  )}
              <p className="GD-p-comid" key={uuidv4()} >
                <span style={{ opacity: .9 }}>{element[0]}:</span>
                <span style={{ marginLeft: 10, fontSize: '1.5em', opacity: 1 }}>
                  <span  >{element[1]}</span> 
                </span>
              </p>
            </div>
          )
        }  else {
          return (
            <p className="GD-p-comid" key={uuidv4()}>  
              <span style={{ opacity: .9 }}>{element[0]}:</span>
              <span style={{ marginLeft: 10, fontSize: '1.3em', opacity: 1 }}>    {element[1]} </span>
            </p>
          )
        }
      })
    })
  }

  const renderPopup = (info: any) => {
  
    if(!info||info.length<1||!info[0].centroid_longitude||!info[0].centroid_latitude){
      return;
    }
    
    const handleClose = () => {
      setPopupInfo(null);
    };

    return (
      <Popup
        longitude={Number(info[0].centroid_longitude)}
        latitude={Number(info[0].centroid_latitude)}
        closeButton={true}
        closeOnClick={true}
        className="wqp"
        onClose={() => {
          setPopupInfo(null);
          
        }}>
        <div className="popup-container" style={{ paddingBottom: 5, lineHeight: '1px' }}>
           <FontAwesomeIcon className="close-btn" icon={faXmark} onClick={handleClose} />
          <Scrollbars renderThumbVertical={renderThumb} className="summary-scroll" style={{ width: '100%', height: '100%', marginTop:15}}>
            {renderFields(info)}
            <div style={{ marginBottom: 20 }} />
          </Scrollbars>
        </div>
      </Popup>
    );
  }
/*




    <Source
      id={`${id}-layerS`}
      key={`${id}-layerS`}
      type="geojson"// @ts-ignore
      data={sfUrl}
    >
      {activePopup && activePopup.id === id && activePopup.content}
      <Layer
        paint={{
          "fill-opacity": opacity,
          "fill-outline-color": "darkblue",
          "fill-color": [
                'match',
                    ['get', 'outlook'],
                    'Likely', `#cc33ff`,
                    'Possible', `yellow`,
                    'Occurring', `red`,
                    'minor', `#ff9900`,
                    "green"
            ],
        }}
        beforeId={settings.defaultBeforeId} 
        type="fill" id={`${id}-layerS`}
        source={`${id}-layerS`}
      />


    </Source>


*/


  const getCoverageBounds = (source:string) => {  //fl -87.63470035600356,24.51490854927549,-80.03257567895679,31.000809213282125
    if(categoryDetails && categoryDetails.currentOption && categoryDetails.currentOption.bounds){
      return categoryDetails.currentOption.bounds;
    }

    if(categoryDetails && categoryDetails.coverage){
      if(categoryDetails.coverage==='CONUS'){ //-125, -65, 24, 50)
        return [-124, 23, -60, 50]
      } else if(categoryDetails.coverage==='Global'){
        return [-180,-85.051129,180,85.051129]
      } else if(categoryDetails.coverage==='Global nc'){
        return [-180,-85.051129,180,85.051129]
      }
    }
    return [-180,-85.051129,180,85.051129]
  }

  // maxzoom={(categoryDetails && categoryDetails.maxzoom) ? categoryDetails.maxzoom : 22}
/*

 //@ts-ignore
                    minzoom={(categoryDetails && categoryDetails.minzoom) ? categoryDetails.minzoom : 0} 


                  minzoom={(categoryDetails && categoryDetails.minzoom) ? categoryDetails.minzoom : 0} 
                  maxzoom={(categoryDetails && categoryDetails.maxzoom) ? categoryDetails.maxzoom : 22} 
*/
  //bounds={getCoverageBounds('source')}

    let colorMapPastel = ['#8dd3c7','#ffffb3','#bebada','#fb8072','#80b1d3','#fdb462','#b3de69','#fccde5','#d9d9d9','#bc80bd'];

    const renderSources = () => {
        if(categories && categories.length > 0){
          //console.log('renderSources', categories)

          return categories.map((layer: any) => {
              //console.log('renderSources', layer)

                    if(layer.type==='json'){

                      /* @ts-ignore */
                      let fillSet = `#ffa400` as Expression 

                      if(layer.fillExpression){
                        fillSet = layer.fillExpression as Expression;
                      }


                      return (  
                              <Source
                                id={`${id}-${layer.id}-layer`}
                                key={`${id}-${layer.id}-layer`}
                                type="geojson"
                                data={layer.url||featureData}  >

                                        {/* @ts-ignore */}
                                  <Layer      
                                    beforeId={settings.defaultBeforeId}
                                    paint={{ "circle-color": fillSet, }}
                                    type="circle" id={`${id}-${layer.id}-layer`}
                                    source={`${id}-${layer.id}-layer`}       
                                   /> 
                     
                                   <Layer      
                                    paint={{ 'text-color' : '#ffffcc',
                                    'text-halo-color': '#253494',
                                    'text-halo-width': 2 }}
                                    layout={{
                                    'text-anchor': 'top',
                                    'text-offset': [0, 0.6],
                                    'text-field': [
                                      'format',
                                      ['get', layer.symbol1||'fire_name'],
                                      { 'font-scale': .7 },
                                      '\n',
                                      {},
                                      ['get', layer.symbol2||'incident_id'],
                                      { 'font-scale': .9 },
                                  ]}}
                                    type="symbol" id={`${id}-${layer.id}-symbol-layer`}
                                    source={`${id}-${layer.id}-layer`}       
                                   /> 
                              </Source>
                        )
                    } else if(layer.type==='jsonfill'){
                      console.log(layer.url)
                      let fillSet = [
                                        'match',
                                            ['get', 'OBJECTID'],
                                            1, "purple",
                                            21, `#669df0`,
                                            7, `#6fff00`,
                                            6, `orange`,
                                            5, "orange",
                                            2, `red`,
                                            3, "blue",
                                            4, "yellow",
                                            "green"
                                    ] as Expression;

                      if(layer.fillExpression){
                        fillSet = layer.fillExpression as Expression;
                      }

                      return (  
                              <Source
                                id={`${id}-${layer.id}-layer`}
                                key={`${id}-${layer.id}-layer`}
                                type="geojson"
                                data={layer.url}>

                                        {/* @ts-ignore */}
                                  <Layer      
                                    beforeId={settings.defaultBeforeId}
                                    paint={{ "fill-color": fillSet, 'fill-outline-color': '#BD1C1C', "fill-opacity" : .2}}
                                    type="fill" id={`${id}-${layer.id}-layer`}
                                    source={`${id}-${layer.id}-layer`}       
                                   /> 
                     
                                   <Layer      
                                    paint={{ 'text-color' : '#ffffcc',
                                    'text-halo-color': '#253494',
                                    'text-halo-width': 2 }}
                                    layout={{
                                      'text-anchor': 'top',
                                      'text-offset': [0, 0.6],
                                      'text-field': [
                                        'format',
                                        ['get', 'WMDNAMEa'],
                                        { 'font-scale': .7 },
                                        '\n',
                                        {},
                                        ['get', 'OBJECTIDa'],
                                        { 'font-scale': .9 },
                                    ]}}
                                    type="symbol" id={`${id}-${layer.id}-symbol-layer`}
                                    source={`${id}-${layer.id}-layer`}       
                                   /> 
                              </Source>
                        )
                    } else if(layer.type==='vector'){
                      console.log(layer.url)

/*
    mapRef.current.on('style.load', () => {
      mapRef.current.addSource('mapbox-dem', {
        type: 'raster-dem',
        url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
        tileSize: 512,
        maxzoom: 14
      });
      mapRef.current.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });
    });
  }, []);
*/

                    const fillLayer: FillLayer = {
                      id: `${id}-${layer.id}-layer`,
                      type: 'fill',
                      source: `${id}-${layer.id}-layer`,
                      'source-layer': `bathy_hex_05`,
                      paint: {
                        "fill-color": "green", //["interpolate", ["cubic-bezier", 1, 1, .4, .7], ["get", "level"], 0, "rgba(0, 0, 0, 0)", .1, "rgba(0, 0, 0, 0)", .2, "#1693A5", .3, "#00d4b0", .4, "hsl(88, 92%, 45%)", .5, "hsl(73, 91%, 49%)", .6, "#fffe0c", .7, "#fbbe1a", .8, "#fcbe1a", .9, "#ff0026", 1, "#e81e76", 1.1, "#ac0606", 1.2, "#720202", 1.3, "#4b0000"]
                      }
                    };


                    const circleLayer: CircleLayer = {
                      id: `${id}-${layer.id}-layer`,
                      type: 'circle',
                      source: `${id}-${layer.id}-layer`,
                      'source-layer': `${layer.sourceLayer}`,
                      paint: {
                        "circle-color": "#4b0000",
                        'circle-radius': 42,
                      }
                    };


                    const lineLayer: LineLayer = {
                      id: `${id}-${layer.id}-layer`,
                      type: 'line',
                      source: `${id}-${layer.id}-layer`,
                      'source-layer': `bathy_hex_05`,
                      paint: {
                        "line-color": "#4b0000",
                        'line-width': 44,
                      }
                    };
                      return (  
                              <Source
                                id={`${id}-${layer.id}-layer`}
                                key={`${id}-${layer.id}-layer`}
                                type="vector"
                                url={'mapbox://glos-mapbox.mts-testing-hexmap_multi3'}>

                                          {/* @ts-ignore */} 
                                  <Layer  {...fillLayer} /> 

                              </Source>
                        )
                    } else if(layer.type==='raster-dem'){
                      console.log(layer.url)

/*
    mapRef.current.on('style.load', () => {
      mapRef.current.addSource('mapbox-dem', {
        type: 'raster-dem',
        url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
        tileSize: 512,
        maxzoom: 14
      });
      mapRef.current.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });
    });
  }, []);
*/
                      //@ts-ignore
                      console.log(currentRef, currentRef?.current, currentRef?.current?.getMap())
                      //mapInstance?.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });


                      return (  
                              <Source
                                id={`mapbox-dem`}
                                key={`mapbox-dem`}
                                type="raster-dem"
                                url={'mapbox://mapbox.mapbox-terrain-dem-v1'}
                                tileSize={512}
                                maxzoom={14}>
                              </Source>
                        )
                    } else {

                        let tileURL = (layer.options && layer.currentOption && layer.optionsurl) ? [layer.optionsurl.replace("${optionid}", layer.currentOption.id)] : [layer.url];
                        if(layer.date){
                          tileURL = [layer.url.replace("${dateid}", layer.datecalc || DateTime.utc().toISODate())]; 
                        }

                      return (
                      
                            <Source
                              id={`${id}-layer`+layer.id}
                              key={`${id}-layer`+layer.id}
                              type="raster"                     
                              tileSize={256}
                              tiles={tileURL}>
                              <Layer      
                                beforeId={settings.defaultBeforeId}
                                paint={{ 'raster-opacity': (layer.opacity) ? layer.opacity : opacity }}
                                type="raster" id={`${id}-layer`+layer.id}
                                source={`${id}-layer`+layer.id}       
                              />
                            </Source>
                        
                      )
                    }
            }
          )
        }
    }



    const _renderSources = () => {
      if(categoryDetails && categoryDetails.type && categoryDetails.type!=='raster'){
        //console.log(categoryDetails.type)
        if(categoryDetails.type==='rasterimage'){
          return (
                  <Source
                    id={`${id}-layerR`}
                    key={`${id}-layerR`}
                    type="raster"
                    maxzoom={(categoryDetails && categoryDetails.maxzoom) ? categoryDetails.maxzoom : 22}
                    bounds={getCoverageBounds('source')}
                    tileSize={256}
                    tiles={[sfUrl]}>
                    <Layer      
                      beforeId={settings.defaultBeforeId}
                      paint={{ 'raster-opacity': opacity }}
                      type="raster" id={`${id}-layerR`}
                      source={`${id}-layerR`}       
                    />
                  </Source>
            )
        }

        if(categoryDetails.type==='vectorpbf'){
          return (
                  <Source
                    id={`${id}-layerRq`}
                    key={`${id}-layerRq`}
                    type="vector"
                    tiles={[sfUrl]}>

                  </Source>
            )
        }


        if(categoryDetails.type==='json'){

          return (  
                  <Source
                    id={`${id}-${categoryDetails.id}-layer`}
                    key={`${id}-${categoryDetails.id}-layer`}
                    type="geojson"
                    data={sfUrl}>

                            {/* @ts-ignore */}
                      <Layer      
                        beforeId={settings.defaultBeforeId}
                        paint={{ "circle-color": `#ffa400`, }}
                        type="circle" id={`${id}-${categoryDetails.id}-layer`}
                        source={`${id}-${categoryDetails.id}-layer`}       
                       /> 
         
                       <Layer      
                        paint={{ 'text-color' : '#ffffcc',
                        'text-halo-color': '#253494',
                        'text-halo-width': 2 }}
                        layout={{
                        'text-anchor': 'top',
                        'text-offset': [0, 0.6],
                        'text-field': [
                          'format',
                          ['get', 'fire_name'],
                          { 'font-scale': .7 },
                          '\n',
                          {},
                          ['get', 'incident_id'],
                          { 'font-scale': .9 },
                      ]}}
                        type="symbol" id={`${id}symbol-layer`}
                        source={`${id}symbol-layer`}       
                       /> 
                  </Source>
            )
        }

      } else {
        return (
        
                <Source
                  id={`${id}-layerR`}
                  key={`${id}-layerR`}
                  type="raster"

                  bounds={getCoverageBounds('source')}
                  tileSize={256}
                  tiles={[sfUrl]}>
                  <Layer      
                    beforeId={settings.defaultBeforeId}
                    paint={{ 'raster-opacity': opacity }}
                    type="raster" id={`${id}-layerR`}
                    source={`${id}-layerR`}       
                  />
                </Source>
            
          )
      }
    }



  return (
    <>
        {renderSources()}
        {popupInfo && renderPopup(popupInfo)}
        {featureData && featureData.features && (        <Source
                            id={`${id}-${categoryDetails.id}H-layer`}
                            key={`${id}-${categoryDetails.id}H-layer`}
                            type="geojson"
                            data={featureData}>
        
                                    {/* @ts-ignore */}
                              <Layer      
                                beforeId={settings.defaultBeforeId}
                                paint={{ "fill-color": `rgba(10,10,10,0)`, "fill-outline-color": `#ffa400`}}
                                type="fill" id={`${id}-${categoryDetails.id}H-layer`}
                                source={`${id}-${categoryDetails.id}H-layer`}       
                               /> 
                 
                               <Layer      
                                paint={{ 'text-color' : '#ffffcc',
                                'text-halo-color': '#253494',
                                'text-halo-width': 2 }}
                                layout={{
                                'text-anchor': 'top',
                                'text-offset': [0, 0.6],
                                'text-field': [
                                  'format',
                                  ['get', 'assessmentunitname'],
                                  { 'font-scale': .7 },
                                  '\n',
                                  {},
                                  ['get', 'assessmentunitidentifier'],
                                  { 'font-scale': .9 },
                              ]}}
                                type="symbol" id={`${id}symbol-layer`}
                                source={`${id}symbol-layer`}       
                               /> 
                          </Source>)}
    </>
  )
}

export default EvaluationLayer