import { useEffect } from 'react';
import { Layer, Source, FillLayer } from 'react-map-gl'

const EnviromentalJustice = (props: any) => {
  const {
    global,
    year,
    id,
    opacity,
    paint
  } = props

  useEffect(() => global.notify('Zoom in to see layer'),[])

  const ejLayer: FillLayer = {
    id: id,
    source: `EJScreen_${year}`,
    "source-layer": `EJScreen_${year}`,
    type: "fill",
    paint: {
      "fill-color": paint,
      "fill-opacity": opacity,
    },
  };

  return (
    <Source
      type="vector"
      url={`mapbox://trueelementsmapping.EJScreen_${year}`}
      id={id}
    >
     <Layer {...ejLayer} />
    </Source>
  )
}

export default EnviromentalJustice