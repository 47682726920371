import { Feature, GeoJsonProperties, Point, Polygon, GeometryCollection, FeatureCollection, Geometry } from 'geojson';
import bbox from '@turf/bbox';
import {DateTime} from 'luxon';
import 'chartjs-adapter-luxon';
import { flyToHUC12sLocation, flyToInitialLocationHandler, getCurrentHuc12LongLat, getHuc12MembersOfHUC8, getCurrentHuc8LongLat } from '../../utils';
import { DatePicker } from 'rsuite';
import * as d3Array from 'd3-array';
import agent from '../../../api/agent';
import { unparse } from 'papaparse';
import queryString from 'query-string';
import ReportsConfigs from '../../../ReportsConfigs';

export const uniqueValues = (filteredData:any, itemField:string) => { 
  const uniqueArray = filteredData.map((item:any) => item[itemField])
  .filter((value:any, index:any, self:any) => self.indexOf(value) === index)
  .filter((value:any, index:any, self:any) => value)
  .sort();    
  return uniqueArray;
};

export const groupByKey = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
list.reduce((previous, currentItem) => {
  const group = getKey(currentItem);
  if (!previous[group]) previous[group] = [];
  previous[group].push(currentItem);
  return previous;
}, {} as Record<K, T[]>);

export const findFittingDateRange = (filterData:any, dataLengthThreshold:number) => { 
    //datetime property
    let returnedData = [...filterData];
    const dateRangeExtent = d3Array.extent<any, any>(returnedData, d => new Date(d["datetime"]));   
    let newDateRange = [...dateRangeExtent];
    newDateRange[0] = DateTime.fromJSDate(newDateRange[1]).minus({years:10}).toJSDate();
    const dataTotalRecords = returnedData.length;
    let dtStart = DateTime.fromJSDate(newDateRange[1]).minus({years:10});
    const dtEnd = DateTime.fromJSDate(newDateRange[1]);
    const dtData = returnedData.filter((o:any) => o["datetime"] > dtStart.toJSDate() && o["datetime"] < dtEnd.toJSDate());
    if(dtData.length > dataLengthThreshold){
      for(var i = 1;i<10;i++) { 
        const _dtStart = DateTime.fromJSDate(newDateRange[1]).minus({years:10-i});
        const _dtEnd= DateTime.now();
        const _dtData = returnedData.filter((o:any) => o["datetime"] > _dtStart.toJSDate() && o["datetime"] < _dtEnd.toJSDate());
        if(_dtData.length < dataLengthThreshold){
          dtStart = _dtStart;
          i=11;
        }
      }
    } else {
      for(var i = 1;i<50;i++) { 
        const _dtStart = DateTime.fromJSDate(newDateRange[1]).minus({years:10+i});
        const _dtEnd= DateTime.now();
        const _dtData = returnedData.filter((o:any) => o["datetime"] > _dtStart.toJSDate() && o["datetime"] < _dtEnd.toJSDate());
        if(_dtData.length > (dataLengthThreshold-100)){
          dtStart = _dtStart;
          i=51;
        }
        if(i===49){
          dtStart = DateTime.fromJSDate(dateRangeExtent[0]);
        }
      }
    }
    newDateRange[0] = dtStart.toJSDate();
    return newDateRange;
}

export const clearFilters = (reportpath:any, global:any, skipDateRange?: boolean) => {   
    const configsArray = ReportsConfigs.filter(config => config.route === reportpath);
    if(configsArray && configsArray.length>0 && configsArray[0].filters){
      if(configsArray[0].filters.hasDateTimeRangeFilter && !skipDateRange){
        global.setDateRangeSelected([new Date(new Date().setDate(new Date().getDate() - 5)), new Date()]);        
      }
      if(configsArray[0].filters.hasLocationsFilter){
        global.setfilterLocationsSelected([]);
      }
      if(configsArray[0].filters.hasFLElementsFilter){
        global.setfilter1Selected('All');
      }
    }
}


export const updateChartData = (filterData:any, groupKey:string, selectedCategory:string, huc12InfoCatalogArray:any) => {   
  const fDataset = [...filterData];
  const groupedresults = groupByKey<any, any>(fDataset, i => i[groupKey]); 
  const chartLabels = uniqueValues(fDataset, "date").sort((x:any,y:any) => x - y)
  const chartdata_data: any[] = [];
  let chartdata_labels: any[] = [];

  //null fills
  for (const key in groupedresults) { 
    let initial = groupedresults[key][0];
    const newChartLine: any[] = [];
    for (var idate in chartLabels) {
      const dateMatch = groupedresults[key].filter((o:any) => o["date"]===chartLabels[idate]);      
      if(dateMatch.length < 1){
        let chartItem = {"date" : chartLabels[idate], 
        'Water Level Feet': null,
        'USGS Site Number' : initial["USGS Site Number"]};
        newChartLine.push(chartItem)
      } else {
        let chartItem = {"date" : chartLabels[idate], 
        'Water Level Feet': dateMatch[0]['Water Level Feet'],
        'USGS Site Number' : dateMatch[0]["USGS Site Number"]};
        newChartLine.push(chartItem)
      }
    }
    groupedresults[key] = newChartLine; 
  }

  for (const key in groupedresults) { 
    let labelGroup = key;
    if(huc12InfoCatalogArray && huc12InfoCatalogArray.length > 0){
      const hucFilter = huc12InfoCatalogArray.filter((o:any) => o["huc12"]===key);
      if (hucFilter && hucFilter.length > 0){
        labelGroup = hucFilter[0].name.substr(0,10);
      }
    }
   
    let colorLine = '#'+(0x1000000+Math.random()*0xffffff).toString(16).substr(1,6);
    let dataObject = {
        spanGaps: true,
        label: labelGroup,
        data: groupedresults[key].map((x: any) => x[selectedCategory]),
        borderColor: colorLine,
        backgroundColor: colorLine
    }    
    chartdata_data.push(dataObject);
    
  }
  chartdata_labels = chartLabels.map((x: any) => new Date(x));

  const chartdata = {
    labels : chartdata_labels,
    datasets :chartdata_data,
    borderColor: '#ff6384'
  }  
 
  return chartdata;
}

export const fitBoundsMapData = (featureCollection:any, mapRef:any) => { 
  if(featureCollection.features.length > 0){
    const bbox_ = bbox(featureCollection);

    if(mapRef && mapRef.current){
      if(bbox_[0] === Infinity){
        return null;
      }
      mapRef.current.fitBounds([[bbox_[0], bbox_[1]], [bbox_[2], bbox_[3]]], {
        padding: {top: 40, bottom:25, left: 45, right: 45}
      })
    }
  }
}  

export const createFeatureCollection = (filteredData:any, huc12Catalog: any[], huc12InfoCatalog: any[], selectedHUC8Value: any) => { 
    const features: Array<Feature<Point, GeoJsonProperties>> = [];

     for (let dataItem of filteredData) {
      if(dataItem){
        let huc12Item = dataItem; 
        if(huc12Item.huc12 && !huc12Item.name){
          huc12Item.name = huc12Item.huc12;
        }
        if(huc12Item.huc12 && !huc12Item.hucname){
          huc12Item.hucname = huc12Item.huc12;
        }
        
        if(huc12InfoCatalog && huc12Catalog && huc12Catalog.length > 0 && huc12InfoCatalog.length > 0){
          huc12Item = getCurrentHuc12LongLat(dataItem.huc12, huc12Catalog);
          const huc12ItemInfo = huc12InfoCatalog.filter((o:any) => o["huc12"]===dataItem.huc12)[0];
        
          if (huc12Item && huc12Item.huc12) {
              huc12Item.name = huc12Item.huc12;
          }

          if(huc12ItemInfo && huc12ItemInfo.name){
              huc12Item.name = huc12ItemInfo.name;
          } 
        }
        if(huc12Item){ 
          const featureWithPoint: Feature<Point> = {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [+huc12Item.centroid_longitude, +huc12Item.centroid_latitude]
              },
            properties: dataItem,
          };
          if(featureWithPoint && featureWithPoint.properties){   
            featureWithPoint.properties.lat = huc12Item.centroid_latitude;
            featureWithPoint.properties.lng = huc12Item.centroid_longitude;        
            featureWithPoint.properties.HUC12 = dataItem.huc12;
            featureWithPoint.properties.HUC8 = selectedHUC8Value;
            featureWithPoint.properties.hucname = huc12Item.name;
          }
          features.push(featureWithPoint);
        }
      }
    }
    const featureCollectionFromReportData: FeatureCollection = {
      type: "FeatureCollection",
      features: features
    }; 

    return featureCollectionFromReportData;
};

export const processStatusMessage = (res:any, source?:any) => { 
  let returnStatus = {"statusMessage" : "", "Abort" : false}
  if(typeof res.errorMessage !=="undefined"){
    if(res.errorMessage.substr(0, 21) === "Response payload size"&&source==="GW"){
      returnStatus.statusMessage = "Select shorter day range";
    } else {
      returnStatus.statusMessage = "Error received";
    }
    returnStatus.Abort = true;
    return returnStatus;
  }       
  if(res.body && res.body.length && res.body.length<1){
    returnStatus.statusMessage = "No Results for this location";
    returnStatus.Abort = true;
  }
  returnStatus.statusMessage = "";
  if(!res.body || !res.body.length ){
    returnStatus.statusMessage = "No Results.";
    returnStatus.Abort = true;
  }
  //returnStatus.statusMessage = "";
  return returnStatus;
};

export const startingValues = (filtername:any, defaultValue:any, global:any, huc8catalog?:any) => {
  let resultValue = defaultValue || '';
  if(filtername === 'ffl'&& global.filter1Selected){
      resultValue = global.filter1Selected;
  } else if(filtername === 'frda'){
    if(global.dateRangeSelected){      
      resultValue = global.dateRangeSelected;
    }
  } else if(filtername === 'fl'){
    resultValue = global.filterLocationsSelected;
  } else if(filtername === 'fm'){
    resultValue = global.selectedMeasurementFilterValue;
  } 

  let searchParse = queryString.parse(window.location.search);  
  const newR = searchParse["r"] as string;
  if(newR==='1'){
      const filterString = searchParse[filtername] as string; 
      if(filterString){
        if(filtername === 'frda'){
          const filterStringB = searchParse['fdrb'] as string;
          if(filterStringB){
            resultValue = [filterString, filterStringB];
          }
        } else if(filtername === 'h12'){
          const filterhuc = searchParse['huc'] as string;
          if(filterhuc && huc8catalog){
            let longlat = getCurrentHuc8LongLat(filterhuc, huc8catalog)
            if(longlat && longlat.centroid_longitude && longlat.centroid_latitude){
              resultValue = {
                    longitude: longlat.centroid_longitude,
                    latitude: longlat.centroid_latitude,
                    zoom: 9,
              };            
            }
          }
        }  else {
          resultValue = filterString;     
        }        
      } else if(filtername === 'viewport'){
        const filterhuc = searchParse['huc'] as string;
        if(filterhuc && huc8catalog){
          let longlat = getCurrentHuc8LongLat(filterhuc, huc8catalog)
          if(longlat && longlat.centroid_longitude && longlat.centroid_latitude){
            resultValue = {
                  longitude: longlat.centroid_longitude,
                  latitude: longlat.centroid_latitude,
                  zoom: 9,
            };            
          }
        }
      }
  }

  return resultValue;
}


const formatQueryText = (word:any, reportconfig:any) => {
    const  capitalizeAll = ["huc"];
    if( capitalizeAll.indexOf(word)>-1){
      return word.toUpperCase();
    }

    if(word === 'fd'){
      return 'Number of Days';
    } 
    if(word === 'fl'){
      return 'Locations';
    } 
    if(word==='fys'){
      return 'Years Selected';
    }
    if(word==='fy'){
      return 'Year Selected';
    }
    if(word === 'fc'){
      return 'Chemicals Selected';
    } 
    if(word === 'fdr'){
      return 'Date Range';
    } 
    if(word === 'fdra'){
      return 'Date Start';
    } 
    if(word === 'fdrb'){
      return 'Date End';
    } 
    if(word === 'ftr'){
      return 'Date Unit';
    } 
    if(word === 'fct'){
      return 'Contaminants';
    } 

    if(word === 'fsi'){
      return 'SIC Codes';
    } 
    
    if(word === 'ffl'){
      if(reportconfig && reportconfig.filters && reportconfig.filters.labelFilter1){
        return reportconfig.filters.labelFilter1;
      } else {
        return 'Filter';
      }
    } 

    if(word.charAt(0) === 'l'){
      if(word === 'lo'){
        return 'Map Layer Outflows'
      } else if(word === 'lh'){
        return "Map Layer HUC's"
      } else if(word === 'lgw'){
        return 'Map Layer G.W. Nitrate Assessment'
      } else if(word === 'lnh'){
        return "Map Layer NHD's"
      } else if(word === 'lp'){
        return 'Map Layer Top 100 Water Polluters'
      } else if(word === 'ldm'){
        return 'Map Layer Drought Monitoring'
      }  else if(word === 'lz'){
        return 'Map Layer Zip Codes'
      } else if(word === 'lpa'){
        return 'Map Layer Principal Aquifiers'
      } else if(word === 'let'){
        return 'Map Layer Evapotranspiration'
      } else if(word === 'ltcp'){
        return 'Map Layer Terraclimate'
      } 

      if(word === 'ldq'){
        return 'Map Layer Drinking Quality'
      } else if(word === 'ltw'){
        return "Map Layer Twitter"
      } else if(word === 'lww'){
        return 'Map Layer Wastewater'
      } else if(word === 'lin'){
        return 'Map Layer Industry'
      } 

      if(word === 'lay'){
        return 'Map Layers';
      } 
      return 'Map Layer ' + word.slice(1);
    } 

    if(word==='mgl'){
      return 'Globe View';
    } else if(word==='mb'){
      return 'Map Bounds';
    } else if(word==='mct'){
      return 'Country';
    } else if(word==='mv'){
      return 'Map Viewport';
    } else if(word==='ms'){
      return 'State';
    }
 
    if(word==='ccds'){
      return 'Dataset';
    } 

    /*seQueryParam('mr', BooleanParam);*/

    return word.charAt(0).toUpperCase() + word.slice(1);
}

const formatQueryValue = (word:any, filtervalue:any) => { 
    if((word.charAt(0) === 'l' || word === 'mgl') && word !== 'lay'){
      if(filtervalue === '1'){
        return 'on';
      } else {
        return 'off';
      }
    } 
    if(word === 'fl' && (!filtervalue || filtervalue.length < 1 || filtervalue === '[]')){    
        return 'off';
    } 

    if(word === 'fdr' && filtervalue && filtervalue[0] && filtervalue[1]){
      let datestart = DateTime.fromMillis(+filtervalue[0]);
      let dateend = DateTime.fromMillis(+filtervalue[1]);
      let ds = "";
      let de = "";
      if(!datestart.isValid){
        datestart = DateTime.fromJSDate(new Date(filtervalue[0]));
        if(!datestart.isValid){
          ds = filtervalue[0];
        } else {
          ds = datestart.toLocaleString();
          }
      }
      if(!dateend.isValid){
        dateend = DateTime.fromJSDate(new Date(filtervalue[1]));
        if(!dateend.isValid){
          de = filtervalue[1];
        } else {
          de = dateend.toLocaleString();
        }
      }
      const returnText = ds + " through " + de; 
      return returnText;
    } 

    return filtervalue;
}

const checkLabel = (checklabel:any) => {
    let label = checklabel
    if(label === 'agWaterTrueQI'||label === 'AGWaterTrueQI'){
      label = 'AGWater'
    } 
    if(label === 'stormWaterTrueQI'||label === 'StormWaterTrueQI'){
      label = 'StormWater'
    } 
    if(label === 'SurfacewaterTrueQI'||label === 'surfacewaterTrueQI'){
      label = 'Surfacewater'
    } 
    if(label === 'Wastewater'){
      label = 'Wastewater'
    } 
    if(label === 'Industry'){
      label = 'Industry'
    } 
    if(label === 'Drinking Quality'){
      label = 'Drinking Quality'
    } 
    return label;
}
    
export const parseQueryFilters = (urlsearch:any, reportpath:any) => {
  let filterArray = [];
  let preArray = [];
  const filterignore = ["r"];
  if (urlsearch) {
    const parsed = queryString.parse(urlsearch);
    const configsArray = ReportsConfigs.filter(config => config.route === reportpath);
    let property: keyof typeof parsed; 

    let layerArray = [], scoreArray = [] as any;
    let scoreCollectionArray = ["Drinking Quality", "Industry", "agWaterTrueQI", "AGWaterTrueQI", "surfacewaterTrueQI", "SurfacewaterTrueQI", "StormWaterTrueQI", "stormWaterTrueQI", "Wastewater"]
  
    for (property in parsed) { 
        let filterName = formatQueryText(property, configsArray[0]);
        let filterValue = formatQueryValue(property, parsed[property]);
        if(filterignore.indexOf(property)<0 && filterValue !== 'off' && filterName !== 'Ent'){
          preArray.push({filterLabel: filterName, filterValue: filterValue, filterType: 'regular'})
        }

        if (filterName.substr(0, 10) === 'Map Layers'){
          if(typeof filterValue === 'string'){
            if(scoreCollectionArray.indexOf(filterValue) > -1){
              let newlabel = checkLabel(filterValue);
              scoreArray.push(newlabel) 
            } else {
              layerArray.push(filterValue.replace('Selected', ''));
            }   
          } else {
            for (const layer of filterValue) {
              if(scoreCollectionArray.indexOf(layer) > -1){
                let newlabel = checkLabel(layer);
                scoreArray.push(newlabel) 
              } else {
                layerArray.push(layer.replace('Selected', ''));
              }    
            }    
          }
        } 
    }



    for (let dataItem of  preArray) { 
        if(dataItem.filterLabel.substr(0, 10) === 'Map Layer '){
          if(scoreCollectionArray.indexOf(dataItem.filterLabel.substr(10)) > -1){
            let newlabel = checkLabel(dataItem.filterLabel.substr(10));
            scoreArray.push(newlabel) 
          } else {           
            layerArray.push(dataItem.filterLabel.substr(10))          
          }
        } else if(dataItem.filterLabel.substr(0, 10) === 'Map Layers'){
          
        } else if(dataItem.filterLabel.substr(0, 5) === 'State'){
          //+++;
        } else {
          filterArray.push(dataItem)
        }
        
    }
    if(layerArray && layerArray.length>0){
      filterArray.push({
          "filterLabel": "Map Layers",
          "filterValue": layerArray,
          "filterType": "regular"
      })
    }
    if(scoreArray && scoreArray.length>0){
      filterArray.push({
          "filterLabel": "Scores",
          "filterValue": scoreArray,
          "filterType": "regular"
      })
    }
  }
  return filterArray;
};


export const exportReportLink = (reportLink: any, linkData: any) => {
  if (reportLink) {
    let sData = linkData.filter((o: any) => +o.timestamp === +reportLink);  
    if (sData.length > 0) {
      const parsed = queryString.parse(sData[0].search);    
      const reportName = sData[0].reportname;
      const reportTime = DateTime.local().toFormat('_ydL_Hms_');
      const huc = sData[0].huc;

      let ffl = 'All' as any;

      const reportConfig = ReportsConfigs.find(x => x.route.substr(1) == reportName );

      if(!reportConfig){
        console.log('Error: no configuration');
        return;
      }

 
/*         if(reportLabel){
        return reportLabel;
      } else {
        return reportname;
      }
*/

      let selectedHUC8Value = '14010005';
      if(huc){
        selectedHUC8Value = huc;
      }

      let daysToQuery = 3;
      if(reportConfig.filters.hasHistoricalRangeForecastFilter){
        if (parsed.fd && !isNaN(+parsed.fd) && +parsed.fd > 0) {
          daysToQuery = +parsed.fd;
        }
      }

      if(reportConfig.filters.hasFLElementsFilter){
        if (parsed.ffl){
          if(parsed.ffl.length > 0 && parsed.ffl !== 'All') {
            ffl = parsed.ffl;
          }
        }
      }

      const daysToLimit = 367;
      let daysToQueryRange = daysToQuery;
      const one_day = 1000 * 60 * 60 * 24;

      let startDate = DateTime.fromJSDate(new Date()).plus({days: 1}).toFormat('yyyyLLdd');

      if(reportConfig.filters.hasDateTimeRangeFilter){ //20230503
        if (parsed.fdr){
          if(parsed.fdr.length > 0) {

          //@ts-ignore
          const daysDiff = Math.floor((parsed.fdr[1]  - parsed.fdr[0])/one_day)+1; //+1
          daysToQueryRange = Math.min(daysDiff, daysToLimit);
          daysToQueryRange = Math.max(1, daysToQueryRange);

          
          //@ts-ignore
          startDate = DateTime.fromJSDate(new Date(parsed.fdr[1])).plus({days: 1}).toFormat('yyyyLLdd');
          console.log(startDate)        


/*             if(word === 'fdr' && filtervalue && filtervalue[0] && filtervalue[1]){
              let datestart = DateTime.fromMillis(+filtervalue[0]);
              let dateend = DateTime.fromMillis(+filtervalue[1]);
              let ds = "";
              let de = "";
              if(!datestart.isValid){
                datestart = DateTime.fromJSDate(new Date(filtervalue[0]));
                if(!datestart.isValid){
                  ds = filtervalue[0];
                } else {
                  ds = datestart.toLocaleString();
                }
              }
              if(!dateend.isValid){
                dateend = DateTime.fromJSDate(new Date(filtervalue[1]));
                if(!dateend.isValid){
                  de = filtervalue[1];
                } else {
                  de = dateend.toLocaleString();
                }
              }
              const returnText = ds + " through " + de; 
            }  */
          }
        }
      }

      let zip = "" as any;  
      if (parsed.fz){
        if(parsed.fz.length > 0 && parsed.fz !== 'All') {
          zip = parsed.fz;
        }
      }
  


      //

      if (reportName === 'AGWaterTrueQIForecast') {
      
          //+++ locations  userlocations
          agent.Reports.AGWaterTrueQIForecast(selectedHUC8Value, daysToQueryRange, startDate).then((res: any) => {
            const responseData = res;
            if (responseData) {
              const filteredData = [...responseData]
              exportToCsv(filteredData, reportName + DateTime.fromMillis(sData[0].timestamp) + sData[0].currentLocation)
            }            
          });
      } else if (reportName === 'StormWaterTrueQIForecast') {
    
        //+++ locations  userlocations
        agent.Reports.StormWaterTrueQIForecast(selectedHUC8Value, daysToQueryRange, startDate).then((res: any) => {
          const responseData = res;
          if (responseData) {
            const filteredData = [...responseData]
            exportToCsv(filteredData, reportName + DateTime.fromMillis(sData[0].timestamp) + sData[0].currentLocation)
          }            
        });
      } else if (reportName === 'DischargeMonitoring') {
    
        //+++ locations  userlocations  Years all
        agent.Reports.DischargeMonitoring(selectedHUC8Value).then((res: any) => {
      
          const responseData = res.body;
          if (responseData) {
            let  filteredData = [...responseData];
            if (ffl){
              filteredData = filteredData.filter((o: any) => o['Pollutant'] === ffl);
            }
            exportToCsv(filteredData, reportName + DateTime.fromMillis(sData[0].timestamp) + sData[0].currentLocation)
          }            
        });

      } else if (reportName === 'PermitDischargeReport') {
    
        //+++ locations  userlocations  Years all
        agent.Reports.PermitDischargeReport(selectedHUC8Value).then((res: any) => {
      
          const responseData = res.body;
          if (responseData) {
            let  filteredData = [...responseData];
            if (ffl){
              filteredData = filteredData.filter((o: any) => o['Permit Status Description'] === ffl);
            }
            exportToCsv(filteredData, reportName + DateTime.fromMillis(sData[0].timestamp) + sData[0].currentLocation)
          }            
        });

      } else if (reportName === 'WhatsInMyWatershed') {
    
        //+++ locations  userlocations  Years all
        agent.Reports.WhatsInMyWatershed(selectedHUC8Value).then((res: any) => {
      
          const responseData = res.body;
          if (responseData) {
            let  filteredData = [...responseData];
            exportToCsv(filteredData, reportName + DateTime.fromMillis(sData[0].timestamp) + sData[0].currentLocation)
          }            
        });

      } else if (reportName === 'GroundwaterLevels') {
        agent.Reports.GroundwaterLevels(selectedHUC8Value).then((res: any) => {
          const responseData = res.body;
          if (responseData) {
            let  filteredData = [...responseData];
             /* if (ffl){
              filteredData = filteredData.filter((o: any) => o['Permit Status Description'] === ffl);
            } */
            exportToCsv(filteredData, reportName + DateTime.fromMillis(sData[0].timestamp) + sData[0].currentLocation)
          }            
        });
      } else if (reportName === 'EPAMajorPermitHolders') {
        agent.Reports.EPAMajorPermitHolders(selectedHUC8Value, 100, 0).then((res: any) => {
          const responseData = res.body;
          if (responseData) {
            let  filteredData = [...responseData];
             /* if (ffl){
              filteredData = filteredData.filter((o: any) => o['Permit Status Description'] === ffl);
            } */
            exportToCsv(filteredData, reportName + DateTime.fromMillis(sData[0].timestamp) + sData[0].currentLocation)
          }            
        });
      } else if (reportName === 'WasteWaterTrueQIScores') {
        agent.Reports.WasteWaterTrueQIScores(selectedHUC8Value).then((res: any) => {
          const responseData = res.body;
          if (responseData) {
            let  filteredData = [...responseData];
             /* if (ffl){
              filteredData = filteredData.filter((o: any) => o['Permit Status Description'] === ffl);
            } */
            exportToCsv(filteredData, reportName + DateTime.fromMillis(sData[0].timestamp) + sData[0].currentLocation)
          }            
        });
      } else if (reportName === 'WhatsInMyDrinkingWater') {
        agent.Reports.WhatsInMyDrinkingWater(selectedHUC8Value).then((res: any) => {
          const responseData = res.body;
          if (responseData) {
            let  filteredData = [...responseData];
             /* if (ffl){
              filteredData = filteredData.filter((o: any) => o['Permit Status Description'] === ffl);
            } */
            exportToCsv(filteredData, reportName + DateTime.fromMillis(sData[0].timestamp) + sData[0].currentLocation)
          }            
        });
      } else if (reportName === 'RiskScreening') {
        agent.Reports.RiskScreening('true').then((res: any) => {
          const responseData = res.body;
          if (responseData) {
            let  filteredData = [...responseData];
             /* if (ffl){
              filteredData = filteredData.filter((o: any) => o['Permit Status Description'] === ffl);
            } */
            exportToCsv(filteredData, reportName + DateTime.fromMillis(sData[0].timestamp) + sData[0].currentLocation)
          }            
        });
      } else if (reportName === 'NationalStreamQualityAccountingNetwork') {
        agent.Reports.NationalStreamQualityAccountingNetwork().then((res: any) => {
          const responseData = res.body;
          if (responseData) {
            let  filteredData = [...responseData];
             /* if (ffl){
              filteredData = filteredData.filter((o: any) => o['Permit Status Description'] === ffl);
            } */
            exportToCsv(filteredData, reportName + DateTime.fromMillis(sData[0].timestamp) + sData[0].currentLocation)
          }            
        });
      } else if (reportName === 'ToxicsReleaseInventory') {
        agent.Reports.ToxicsReleaseInventory(selectedHUC8Value, 0).then((res: any) => {
          const responseData = res.body;
          if (responseData) {
            let  filteredData = [...responseData];
             /* if (ffl){
              filteredData = filteredData.filter((o: any) => o['Permit Status Description'] === ffl);
            } */
            exportToCsv(filteredData, reportName + DateTime.fromMillis(sData[0].timestamp) + sData[0].currentLocation)
          }            
        });
      } else if (reportName === 'LiveWaterSensors') {
        //LiveWaterSensors: (huc8: string, days: number = 0, start: string = '') 
        //hasLocationsFilter: true,
        //hasMeasurementsFilter: true,
        agent.Reports.LiveWaterSensors(selectedHUC8Value, daysToQuery, startDate).then((res: any) => {
          //console.log('LiveWaterSensors', res, selectedHUC8Value, daysToQuery, startDate)
          const responseData = res.body.data;
          if (responseData) {
            let  filteredData = [...responseData];
             /* if (ffl){
              filteredData = filteredData.filter((o: any) => o['Permit Status Description'] === ffl);
            } */
            exportToCsv(filteredData, reportName + DateTime.fromMillis(sData[0].timestamp) + sData[0].currentLocation)
          }            
        });
      } else if (reportName === 'FacilityRegistrySystem') {
        //(huc8: string, page: number = 0, resultsPerPage: number = 100)
        agent.Reports.FacilityRegistrySystem(selectedHUC8Value, 0, 1000).then((res: any) => {
          const responseData = res.body;
          if (responseData) {
            let  filteredData = [...responseData];
             /* if (ffl){
              filteredData = filteredData.filter((o: any) => o['Permit Status Description'] === ffl);
            } */
            exportToCsv(filteredData, reportName + DateTime.fromMillis(sData[0].timestamp) + sData[0].currentLocation)
          }            
        });
      } else if (reportName === 'ConsumerConfidenceReport') {
        agent.Reports.ConsumerConfidenceReport(zip).then((res: any) => {
          const responseData = res.body;
          if (responseData) {
            let  filteredData = [...responseData];
             /* if (ffl){
              filteredData = filteredData.filter((o: any) => o['Permit Status Description'] === ffl);
            } */
            exportToCsv(filteredData, reportName + DateTime.fromMillis(sData[0].timestamp) + sData[0].currentLocation)
          }            
        });
      } else if (reportName === 'Dashboard') {


      } else { 
        agent.Reports.SurfaceWaterFlowRate(huc, daysToQuery).then((res: any) => {
        
          const responseData = res.body;
          if (responseData) {
            exportToCsv(responseData, reportName + DateTime.fromMillis(sData[0].timestamp) + sData[0].currentLocation)
          }
        })
      }
    }
  }
}


export const exportToCsv = (data: any, filename: string) => { 
  const csv = unparse(data);
  let hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
  hiddenElement.target = '_blank';
  hiddenElement.download = filename + '.csv';
  hiddenElement.click();
  if (hiddenElement.parentNode) {
    hiddenElement.parentNode.removeChild(hiddenElement);
  }
  hiddenElement.remove();
}  