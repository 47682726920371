import Map, {
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  Layer,
  Source,
} from 'react-map-gl';
import MapDialsBuilder from '../../../components/MapDialsBuilder';
import DrawControl from '../../../types/DrawControl';
import MapSource from '../../../types/MapSource';
import { MarkerConfig } from '../../../types/MarkerConfig';
import settings from '../../../settings.json';
import { v4 as uuidv4 } from 'uuid';
import { Form } from 'react-bootstrap'
import { ReactElement, useContext, useEffect, useMemo, useRef, useState, Ref } from 'react';
import MapPinsBuilder from '../../../components/MapPinsBuilder';
import TweetList from '../../../components/tweet-list';
import Render from '../../../components/render';
import LoadingSpinner from '../../../components/LoadingSpinner';

import MapTitle from '../shared/MapTitle';
import MapLegend from '../shared/MapLegend';
import MapAddition from  '../shared/MapAddition';
import { MapRef } from 'react-map-gl';
import { RefContext } from '../../../RefContext'

const ReportMap = ({
  source,
  markersConfigs,
  refToMap,
  initialViewState,
  hasPins = false,
  hasDials = false,
  tweets,
  global,
  viewport,
  setViewport,
  bounds,
  loading = false,
  hasDrawControl = false,
  hasNavigationControl = false,
  hasGeolocateControl = false,
  onPinMouseEnterHandler,
  onPinMouseExitHandler,
}: {
  source: MapSource;
  markersConfigs: MarkerConfig[];
  refToMap: any;
  initialViewState?: any;
  loading?: boolean;
  hasPins?: boolean;
  hasDials?: boolean;
  tweets?: any[] | null;
  global?: any;
  viewport?: any;
  setViewport?: any;
  bounds?: any;
  hasDrawControl?: boolean;
  hasNavigationControl?: boolean;
  hasGeolocateControl?: boolean;
  onPinMouseEnterHandler?: (e: any) => void;
  onPinMouseExitHandler?: (e: any) => void;
}): ReactElement => {
  useEffect(() => { }, []);
  const all = global

  const [statusMessage, setStatusMessage] = useState<any>(null); 
  const mapRef = useRef<MapRef>();
  
  useEffect(() => {
    setCurrentRef?.(mapRef)
  }, [mapRef])

  const drillDownMapSources = (mapSource: MapSource, innerSources: any[]) => {
    let filter = mapSource.source?.layerFilter
      ? mapSource.source?.layerFilter
      : ['all'];

    if (mapSource.source) {
      const sourceElement = (
        <Source
          key={uuidv4()}
          id={mapSource.source.id}
          type={mapSource.source.type as 'vector'}
          url={mapSource.source.tilesetURLs}
        >
          <Layer {...mapSource.source.layer} {...{ filter }} />
        </Source>
      );
      innerSources.push(sourceElement);
      drillDownMapSources(mapSource.source, innerSources);
    } else {
      return null;
    }
    return innerSources;
  };

  const mapInitialViewState = initialViewState
    ? initialViewState
    : {
      longitude: settings.defaultLocationMap.lng,
      latitude: settings.defaultLocationMap.lat,
      zoom: 3,
    };

  let filter = source.layerFilter ? source.layerFilter : ['all'];

  const reportBounds = refToMap.current
    ? refToMap.current
      .getMap()
      .getBounds()
      .toArray()
      .flat()
    : null;

  const { setCurrentRef } = useContext(RefContext);
  useEffect(() => { setCurrentRef?.(refToMap) }, [refToMap])

  return (
    <>
      <Map
        ref={refToMap}
        reuseMaps
        mapboxAccessToken={settings.maboxKey}
        initialViewState={mapInitialViewState}
        mapStyle={global.mapStyle}
        preserveDrawingBuffer={true}
        projection={global.globeView ? 'globe' : 'mercator' as any}
        onClick={(e) => {
          global.onMapClick(e)
        }}
        onMoveEnd={(e) => {
          global.setViewport({
            longitude: e.viewState.longitude,
            latitude: e.viewState.latitude,
            zoom: e.viewState.zoom,
          })
        }}
      >
        <Source
          key={uuidv4()}
          id={source.id}
          type={source.type as 'vector'}
          url={source.tilesetURLs}
        >
          <Layer {...source.layer} filter={[...filter]} />

          {drillDownMapSources(source, [])?.map((x) => x)}
        </Source>



        {hasDials ? (
          <MapDialsBuilder markersLocationConfigArray={markersConfigs} />
        ) : null}
        {hasPins ? (
          <MapPinsBuilder
            markersLocationConfigArray={markersConfigs}
            onMouseEnter={onPinMouseEnterHandler}
            onMouseExit={onPinMouseExitHandler}
          />
        ) : null}

                    <MapAddition global={global} 
                                mapRef={mapRef}

                                position={'low'}
                                zipOff={true}
                                MapSliderAdd={true}
                                statusMessage={statusMessage}/>

        {global.twitterSelected && tweets && (
          <TweetList
            tweets={tweets}
            viewport={viewport}
            setViewport={setViewport}
            bounds={bounds}
          />
        )}
        <LoadingSpinner active={loading} />

        <div className='map-legend-container'>
          <MapLegend
            global={global}
            legendWidth={200}
          />
        </div>
      </Map>

    </>

  );
};

export default ReportMap;
