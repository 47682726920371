import {
    useMemo, useState, useEffect
} from 'react'
import ReportTableExpanded from '../../../reports/shared/ReportTableExpanded'
import {
    Button,
    Form,
    Modal
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPenToSquare,
    faEye
} from '@fortawesome/pro-solid-svg-icons';
import { exportToCsv } from '../../../utils';
import { submitWQX, deleteWQX } from '../lib/data';

const WQXHistory = (props:any) => {

    const {
        data,
        setWqxData,
    } = props

    const [currentWqx, setCurrentWqx] = useState({})
    const [tableColumns, setTableColumns] = useState < any[] > ([]);
    const [subsetWQX, setSubsetWQX] = useState < any[] > ([]);
    const [checkRow, setCheckRow] = useState(null);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    useEffect(() => {
        if(data){
            let initialData = [...data];
            let filterData = initialData.filter((o: any) => (o["submissionType"]) ); 
            //if(filterData && filterData.length > 0){
                //setSubsetWQX(filterData);
            //} else {
                setSubsetWQX(data);
            //}                       
        }
    }, [data])

    useEffect(() => {
      if(checkRow && checkRow!==null){
          console.log(checkRow)
        let initialData = [...data];
        //@ts-ignore
        let checkRowId = checkRow.id, checked = checkRow.checked;
        if(checkRow && checkRowId){
            let filterData = initialData.filter((o: any) => (o["id"] === +checkRowId) ); 
            if(filterData && filterData.length > 0){
                console.log(checkRowId, filterData.length, data.length);
                filterData[0].checked = checked;
                setSubsetWQX(initialData);
            }           
        }
        setCheckRow(null);
      }
    }, [checkRow])

    const handleDeleteReturn = (result: any) => {
          
    }

/*        if(checkRow && checkRowId){
            let filterData = initialData.filter((o: any) => (o["id"] !== +checkRowId) ); 
            if(filterData && filterData.length > 0){
                console.log(checkRowId, filterData.length, data.length);
                //deleteWQX(checkRowId, handleDeleteReturn);
                //setWqxData(filterData);
            }           
        }
*/

    const handleShow = (data: any) => {
        setCurrentWqx(data.row.original)
        setShow(true);   
    }

    const handleRowCheck = (event:any, e:any) => {
        console.log(event, e, e.target.checked)  
        if(event && event.row){
            let er = event.row;
            er.checked = e.target.checked;
            setCheckRow(event.row)    
        }        
    }

    let displayfields = Object.entries(currentWqx).filter(([key, value])=> value !== null && value !=='None' && key!=='BiologicalHabitatIndex_IndexCommentText')

    useEffect(() => {
      let tableCol = [];

        tableCol.push({
                Header: 'View',
                accessor: 'actions',
                size: 80,
                Cell: (props: any) => (
                    <div className="action-box">
                        <div
                            onClick={() => { }}
                            className='action-cell'
                        >
                            <FontAwesomeIcon style={{ color: '#4fc2d9', cursor: 'pointer' }} icon={faEye} onClick={() => { handleShow(props)}}  />
                        </div>
                    </div>
                ),
            }, {
                Header: ' ',
                accessor: 'sendToEPA_',
                size: 50,
                //@ts-ignore
                Cell: (props: any) => (
                    <div>
                        <div className="action-box">
                            <Form.Check
                                value={props.value}
                                onClick={(e) => {
                                    handleRowCheck(props, e)        
                                }}
                            />
                        </div>
                    </div>
                ),
            },);

        tableCol.push({
                Header: 'ID',
                accessor: 'id',
                size: 70,
            },
            {
                Header: 'Submission Date',
                accessor: 'submissionDate',
                size: 120,
            },
            {
                Header: 'Submission Type',
                accessor: 'submissionType',
            },

            {
                Header: 'CDX Status',
                accessor: 'cdxStatus',
            },
            {
                Header: 'CDX Status Detail',
                accessor: 'statusDetail',
                minsize:300,
            },
            {
                Header: 'CDX Transaction ID',
                accessor: 'transactionId',
                minsize:200,
            });

      setTableColumns(tableCol)
    }, [])

    return (
        <div className='wqx-container'>
            <div className="header-box">
                <h2>WQX History</h2>
            </div>
             <div className="reference-data-table-container table-scroll">
                <ReportTableExpanded columns={tableColumns} data={subsetWQX} />
            </div>
            <Modal show={show} onHide={handleClose} className='wqx-container-modal'>
                <Modal.Header closeButton>
                  <Modal.Title>WQX Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {displayfields.map(([k,v]) => `${k}: ${v}`).map((link:any, index:number) =>
                         <p key={'popover_' + index}>{link}</p> 
                      )}
                 </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default WQXHistory