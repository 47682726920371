
  export const marks = {
    0: {
      style: {
        textShadow: "white 0 -2px",
        borderRadius: 5,
        width: 28,
        opacity: .8,
        color: "#060e21",
        fontSize: '1rem',
      },
      label: '2yr',
    },
    1: {
      style: {
        textShadow: "white 0 -2px",
        borderRadius: 5,
        width: 28,
        opacity: .8,
        color: "#060e21",
        fontSize: '1rem',
      },
      label: '5yr',
    },
    2: {
      style: {
        textShadow: "white 0 -2px",
        borderRadius: 5,
        width: 28,
        opacity: .8,
        color: "#060e21",
        fontSize: '1rem',
      },
      label: '10yr',
    },
    3: {
      style: {
        textShadow: "white 0 -2px",
        borderRadius: 5,
        width: 28,
        opacity: .8,
        color: "#060e21",
        fontSize: '1rem',
      },
      label: '25yr',
    },
    4: {
      style: {
        textShadow: "white 0 -2px",
        borderRadius: 5,
        width: 28,
        opacity: .8,
        color: "#060e21",
        fontSize: '1rem',
      },
      label: '50yr',
    },
    5: {
      style: {
        textShadow: "white 0 -2px",
        borderRadius: 5,
        width: 33,
        opacity: .8,
        color: "#060e21",
        fontSize: '1rem',
      },
      label: '100yr',
    },
    6: {
      style: {
        textShadow: "white 0 -2px",
        borderRadius: 5,
        width: 37,
        opacity: .8,
        color: "#060e21",
        fontSize: '1rem',
      },
      label: '1000yr',
    },
  };
