import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import ReportTable from "../shared/ReportTable";
import LineChart from './LineChart';
import BarChart from './BarChart';

const NSGComponents = ({ global, title, dataset, currentPage }: any) => {

  const [tableData, setTableData] = useState()
  const [graphData, setGraphData] = useState()

  const renderReportTables = useCallback((data: any) => {
    const pageData = data[currentPage] || [];
    
    if (pageData.length === 0) {
      return <p className="no-data">No data</p>;
    }
  
    const handleHeaders = (input: any[]) => {
      let headers: any = [];
      input.forEach((header: any) => headers.push({ Header: header, accessor: header }));
      return headers.length > 0 ? headers : [];
    };
  
    const keys = Object.keys(pageData[0]);
    return <ReportTable data={pageData} columns={handleHeaders(keys)} />;
  }, [currentPage]);

  const transformData = (input: any[]) => {
    const data: any = input[currentPage];
  
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(?::\d{2})?(?:Z)?$/; 
    const decimalRegex = /^\d+\.\d+$/; 
  
    const gData: any[] = [];
    const tData: any[] = [];
  
    data.forEach((obj: any) => {
      const graphEntry: any = {};
      const tableEntry: any = {};
      let dateValue: string | null = null;
  
      // Handle date formatting and storage
      if (obj.Analysisdate) {
        const originalDate = obj.Analysisdate;
        dateValue = originalDate;
        graphEntry['Analysisdate'] = originalDate;
        tableEntry['Analysisdate'] = originalDate;
      } else {
        Object.keys(obj).forEach(key => {
          const value = obj[key];
  
          // Capture dates using the regex
          if (!dateValue && dateRegex.test(value)) {
            dateValue = value;
            graphEntry[key] = value;
            tableEntry[key] = value;
          }
  
          if (decimalRegex.test(value)) {
            graphEntry[key] = parseFloat(value);
          } else {
            tableEntry[key] = value;
          }
        });
      }
  
      if (dateValue) {
        const unixTimestamp = new Date(dateValue).getTime();  // Convert date to Unix timestamp
        graphEntry.dateValue = unixTimestamp;
        tableEntry.dateValue = unixTimestamp;
        gData.push(graphEntry);
        tData.push(tableEntry);
      }
    });
  
    // Sort by Unix timestamp
    const sortByUnixTimestamp = (array: any[]) => {
      return array.sort((a, b) => a.dateValue - b.dateValue);
    };
  
    const sortedGraphData = sortByUnixTimestamp(gData);
    const sortedTableData = sortByUnixTimestamp(tData);
  
    // Reformat the date into 'YYYY-MM-DD@HH:MM' after sorting
    const reformatDate = (unixTime: number) => {
      const date = new Date(unixTime);
      const formattedDate = date.toISOString().substring(0, 10); // 'YYYY-MM-DD'
      const time = date.toISOString().substring(11, 16); // 'HH:MM'
      return `${formattedDate}@${time}`;
    };
  
    sortedGraphData.forEach(obj => {
      obj.Analysisdate = reformatDate(obj.dateValue);
      delete obj.dateValue;  // Remove the Unix timestamp after formatting
    });
  
    sortedTableData.forEach(obj => {
      obj.Analysisdate = reformatDate(obj.dateValue);
      delete obj.dateValue;
    });
  
    return { gData: sortedGraphData, tData: sortedTableData };
  };
  


  useEffect(() => {
    const { gData, tData }: any = transformData(dataset)
    if(gData.length > 0 && tData.length > 0) {
      setTableData(tData)
      setGraphData(gData)
    }
  }, [dataset])

  const handleComponents = (inputTitle: string, table: any, graph: any) => {
    switch (inputTitle) {
      case "Field data for recording stabilization of ground water well before sampling":
      case "Field data for the parent sample of groundwater chemistry":
      case "Field data for the parent sample of surface water chemistry":
      case "Groundwater chemistry ALK and ANC domain lab data summary data":
      case "Groundwater chemistry from external lab in long format":
      case "Groundwater chemistry summary data per site per bout":
      case "Information related to multisonde cleaning and calibration activities":
      case "Record of SUNA cleaning and calibration activities":
      case "Surface water chemistry ALK and ANC domain lab data summary data":
      case "Surface water chemistry external lab summary data":
      case "Surface water chemistry from external lab in long format":
      case "Surface water chemistry summary data per site per bout":
      case "Surface water stable isotopes field data":
        return (
          <Row>
            <Col className='col-5 line-chart'>
              <LineChart global={global} reportData={graphData} />
            </Col>
            <Col className='col-6 line-chart'>
              {renderReportTables({ [currentPage]: table })}
            </Col>
          </Row>
        )
      case "Nitrate in surface water summarized over 2 minutes from burst measurements taken every":
        return (
          <Row>
            <Col className='col-12 line-chart'>
              <LineChart global={global} reportData={graphData} />
            </Col>
          </Row>
        )
      case "Instantaneous water quality measurements":
        return (
          <Row>
            <Col className='col-12 line-chart'>
              <BarChart global={global} reportData={graphData} />
            </Col>
          </Row>
        )
      default:
        return (
          <Col className='line-chart'>
            {renderReportTables(dataset)}
          </Col>
        )
    }
  }
    


  return (
    <>
      {tableData && graphData && handleComponents(title, tableData, graphData)}
    </>
  )
}

export default NSGComponents