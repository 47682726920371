import {
    useMemo,
    useState,
    useEffect
} from 'react'
import ReportTableExpanded from '../../reports/shared/ReportTableExpanded'
import {
    Button,
    Form,
    Row, Col, Popover, OverlayTrigger,
    FloatingLabel
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import {
    faPenToSquare,
    faTrashCan,
    faCalendar
} from '@fortawesome/pro-solid-svg-icons';
import EditAdminRow from './EditAdminRow'
import FormInputField from './FormInputField'
import { exportToCsv } from '../../utils';
import { submitWQX, deleteWQX, getDomainValue } from './lib/data';
import WM_tablekeys from './WM_tablekeys.json';



const AddActivities = (props: any) => {

    const {
        activities,
        addActivities,
        action,
        setAction,
        setData,
        data,
        activitiesData,
        mLocsData,
        resultsData,
        setResultsData,
        samplesData,
        orgCredentialsType
    } = props

    const [activity, setActivity] = useState<any>({...data})
    const [activity_initial, setActivity_initial] = useState<any>({...data})

    const [startDate, setStartDate] = useState(new Date());

    const [activeIndex, setActiveIndex] = useState<string>('');
    const [activeRow, setActiveRow] = useState<any>({}) 
    
    const dropdownLimit = 2000;
    const [fullCharacteristic, setFullCharacteristic] = useState < any[] > ([]);
    const [currentCharacteristic, setCurrentCharacteristic] = useState < any[] > ([]);

    const [ActivityType, setActivityType] = useState<any[]>([]);
    const [ActivityMedia, setActivityMedia] = useState<any[]>([]);
    const [ActivityMediaSubdivision, setActivityMediaSubdivision] = useState<any[]>([]);
    const [ActivityGroupType, setActivityGroupType] = useState<any[]>([]);
    const [SampleCollectionEquipment, setSampleCollectionEquipment] = useState<any[]>([]);
    const [Assemblage, setAssemblage] = useState<any[]>([]);

    const [Characteristic, setCharacteristic] = useState < any[] > ([]);
    const [AnalyticalMethod, setAnalyticalMethod] = useState < any[] > ([]);
    const [SampleFraction, setSampleFraction] = useState < any[] > ([]);
    const [ResultStatus, setResultStatus ] = useState < any[] > ([]);
    const [ResultValueType, setResultValueType] = useState < any[] > ([]);
    const [AnalyticalMethodContext, setAnalyticalMethodContext] = useState < any[] > ([]);
    const [MeasurementUnit, setMeasurementUnit] = useState < any[] > ([]);

    const [currentRow, setCurrentRow] = useState();
    const [deleteCurrentRow, setDeleteCurrentRow] = useState(false);
    const [wqxCurrentRow, setWqxCurrentRow] = useState(false);  
    const [tableColumns, setTableColumns] = useState < any[] > ([]);


    const limitCharacteristic = (value:any) => {
        //setFullCharacteristic(value);
        setCharacteristic(value.slice(0, dropdownLimit));
    }


    useEffect(() => {
        getDomainValue('MeasurementUnit', setMeasurementUnit);
        getDomainValue('Characteristic', limitCharacteristic);

        getDomainValue('ActivityType', setActivityType);
        getDomainValue('ActivityMedia', setActivityMedia);
        getDomainValue('ActivityMediaSubdivision', setActivityMediaSubdivision);
        getDomainValue('ActivityGroupType', setActivityGroupType);

        getDomainValue('SampleCollectionEquipment', setSampleCollectionEquipment);
        getDomainValue('Assemblage', setAssemblage);

        
        getDomainValue('AnalyticalMethod', setAnalyticalMethod);
        getDomainValue('SampleFraction', setSampleFraction);
        getDomainValue('ResultStatus', setResultStatus);
        getDomainValue('ResultValueType', setResultValueType);
    }, []);

/*    useEffect(() => {
        console.log(MeasurementUnit);

    }, [MeasurementUnit]);

    useEffect(() => {
        console.log(activeIndex);

    }, [activeIndex]);*/

    useEffect(() => {
      if(deleteCurrentRow){
          deleteRow(currentRow);
          setDeleteCurrentRow(false);
      }
    }, [deleteCurrentRow])

    useEffect(() => {
      if(wqxCurrentRow){
          wqxRow(currentRow);
          setWqxCurrentRow(false);
      }
    }, [wqxCurrentRow])

    const handleRow = (props: any) => {
        setCurrentRow(props.row.original)
    }

    const handleSubmit = (row: any) => {
        setWqxCurrentRow(true)     
    }

    const wqxRow = (row: any) => {
        if(row && row.id){
            submitWQX(row, handleSubmitReturn, true, row);
        }
    } 

    const handleSubmitReturn = (success: any, result: any, err?: any, row?:any) => {
        let initialData = [...resultsData];
        if(row && row.id){
            let filterData = initialData.filter((o: any) => (o["id"] === row.id) ); 
            if(filterData && filterData.length > 0){
                //console.log(success, result, err, filterData);
            }           
        }
    }

    const handleDelete = (props: any, e:any) => {
        setDeleteCurrentRow(true)
    }

    const deleteRow = (row: any) => {
        if(row && row.id){
            deleteWQX(row.id, handleDeleteReturn);
            let initialData = [...resultsData];
            let filterData = initialData.filter((o: any) => (o["id"] !== row.id) ); 
            if(filterData && filterData.length > 0){
                setResultsData(filterData);
            }
        }
    }

    const handleDeleteReturn = (result: any) => {
          
    }

    const findVal = (v:any) => {
        //@ts-ignore
      return Object.keys(WM_tablekeys).filter((k) => WM_tablekeys[k].includes(v))
    }

    const translateField = (fieldname: any) => {
        let valSearch = findVal(fieldname);
        if(valSearch && valSearch.length>0){
            return valSearch[0];
        } else {
            return fieldname;
        } 
    }

    const updateActivity = (property: any, value:any) => {
        let changeMon = data;
        changeMon[property]=value;
        setData(changeMon)
        setActivity({property: value})
    }

    const updateResult = (property: any, value:any) => {
        let rData = [...resultsData];
        const rRow = rData[+activeIndex];// &&  rData.find((item: any) => item.id === activeIndex);
        //console.log(property, value, rData, activeIndex, rRow)
        if(rRow){
           rRow[property]=value;         
        }
        setResultsData(rData);
        //setData(changeMon)
        //setActivity({property: value})
    }

    const addResult= () => {
        //console.log(samplesData, resultsData)
        let newData = [...resultsData];
        let newRecord = resultsData[0];
        newRecord.id = null;

        //console.log(Math.max(...resultsData.map((o:any) => +o.id + 1)))
        const nextId = '' + (Math.max(...resultsData.map((o:any) => +o.id + 1)));
        //console.log(nextId)
        let newRow = {
            id: '' + nextId,
            settingName: 'New Setting',
            settingValue: '',
            ResultDescription_CharacteristicName : '',
            ResultDescription_ResultMeasureValue : '',
            ResultDescription_MeasureUnitCode : '',
            actions: '12345'
        };
        newData.push(newRow)
        setResultsData(newData)
        setActiveIndex( '' + ( newData.length-1))
        setActiveRow(newRow)
    }

    const exportCSV = () => {
        exportToCsv(samplesData, 'Results')
    }

/*    const handleDelete= (event:any, e:any) => {
        setAction('table')
    }




*/

    const handleCancel= (event:any) => {
        setAction('table')
    }


  useEffect(() => {
      let tableCol = [];


        tableCol.push(  {
                Header: 'Edit',
                accessor: 'actions',
                size:70,
                //@ts-ignore
                Cell: (props: any) => {
                    return (
                        <div className="action-box">
                            <div
                                onClick={() => {
                                    setAction('edit')
                                    setData(props.row.original)
                                }}
                                className='action-cell'>
                                <FontAwesomeIcon 
                                    onClick={() => {
                                        setAction('edit')
                                        setData(props.row.original)
                                    }} 
                                    style={{ color: '#4fc2d9', cursor: 'pointer' }} 
                                    icon={faPenToSquare} />
                            </div>
                        </div>
                    )
                }
            }, {
                Header: 'Delete',
                accessor: 'actions_',
                size:70,
                //@ts-ignore
                Cell: (props: any) => {
                    return (
                        <div className="action-box">
                            <OverlayTrigger       
                              trigger="click" placement="right" overlay={popover} rootClose={true}>
                            <div
                                onClick={() => {handleRow(props)}}
                                className='action-cell'>
                                <FontAwesomeIcon style={{ marginLeft:18, color: '#4fc2d9', cursor: 'pointer' }} 
                                icon={faTrashCan} />
                            </div>
                            </OverlayTrigger>
                        </div>
                    )
                }
            },);


      tableCol.push(
            {
                Header: 'Characteristics',
                accessor: 'ResultDescription_CharacteristicName',
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    console.log(index, props)
                    if(+index === +activeIndex){
                        // @ts-ignore
                        return   <Form.Select   onChange={(e: any) => updateResult('ResultDescription_CharacteristicName', e.target.value)}
                                            size="lg" aria-label="ActivityType" value={props.row.original && props.row.original.ResultDescription_CharacteristicName}>
                                                {Characteristic  && Characteristic.map((obj:any, index:any) =>
                                                    (<option key={'wm-options'+index} value={obj.Name}>{obj.Name}</option>)
                                                )} 
                                            </Form.Select> 
                    } else {
                        // @ts-ignore
                        return   <div>{props.value}</div>
                    }

  
                }             
            },
            {
                Header: 'Results',
                accessor: 'ResultDescription_ResultMeasureValue',
                minsize: 150,
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    // @ts-ignore
                    return <FormInputField
                        type="text"
                        id={"ResultDescription_ResultMeasureValue"}
                        currentIndex={index}
                        activeIndex={activeIndex && activeIndex}
                        value={props.value}
                        onChange={updateResult}
                    />
                }
            },
            {
                Header: 'Unit',
                accessor: 'ResultDescription_MeasureUnitCode',
                minsize: 150,
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    // @ts-ignore
                    return <Form.Select   onChange={(e: any) => updateResult('ResultDescription_MeasureUnitCode', e.target.value)}
                                        size="lg" aria-label="ResultDescription_MeasureUnitCode" value={props.value}>
                                            <option value={'null'}></option>
                                            {MeasurementUnit  && MeasurementUnit.map((obj:any, index:any) =>
                                                (<option key={'wm-options'+index} value={obj.Name}>{obj.Name}</option>)
                                            )} 
                                        </Form.Select>
                }
            })

      setTableColumns(tableCol);

  }, [])

/*
            {
                Header: 'Actions',
                accessor: 'actions',
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    // @ts-ignore
                    return <EditAdminRow
                        setActiveIndex={setActiveIndex}
                        currentIndex={index}
                    />
                }
            }
        ],
        [activeIndex]
    );
*/

    const popover = (
      <Popover id="popover-delete" className="wm-popover"  >
        <Popover.Header as="h3"  className="wm-popover-header">Delete</Popover.Header>
        <Popover.Body>
          Are you sure?
                <div>
                    <Button id="delete-delete-btn" 
                        onClick={(e) => {
                            handleDelete(props, e); 
                            document.body.click();                 
                        }}
                        className="p-3 btn m-2">
                        Delete
                    </Button>
                    <Button id="delete-cancel-btn" 
                        onClick={() => {
                            document.body.click();
                        }}
                        className="p-3 btn m-2">
                        Cancel
                    </Button>
                </div>
        </Popover.Body>
      </Popover>
    );

    const popoverWQX = (
      <Popover id="popover-wqx" className="wm-popover">
        <Popover.Header as="h3" className="wm-popover-header">WQX Submission</Popover.Header>
        <Popover.Body>
          Send to EPA now?
                <div>
                    <Button id="delete-delete-btn" 
                        onClick={(e) => {
                              handleSubmit(props);
                              document.body.click();
                        }}
                        className="p-3 btn">
                        Yes
                    </Button>
                    <Button id="delete-cancel-btn" 
                        onClick={() => {
                             document.body.click();
                        }}
                        className="p-3 m-2">
                        Cancel
                    </Button>
                </div>
        </Popover.Body>
      </Popover>
    );



    return (
        <Row className='monitoring-location-container'>
            <div className="header-box">
                <h2>{action==='edit' ? "Edit" : "" }{action==='create' ? "Add" : "" } Activity (Samples)</h2>
            </div>
            <Row id="filter-box" style={{ marginRight: '0' }}>
                <div className="add-activity-box">
                    <Form>
                        <Row className="w-100">
                            <Col className="col-4">
                                <Form.Group className="mb-3" controlId="formActivityIdentifier">
                                    <FloatingLabel controlId="floatingActivityIdentifier" label="Activity Identifier">
                                        <Form.Control
                                            size="lg"
                                            type="text"
                                            placeholder="Activity ID"
                                            defaultValue={data && data.ACTIVITYDesc_ActivityIdentifier}
                                        />
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formActivityEndDate">
                                    <FloatingLabel controlId="floatingActivityEndDate" label="Activity End Date">
                                        <Form.Control
                                            size="lg"
                                            type="text"
                                            placeholder="Activity End Date"
                                            defaultValue={data && data.ACTIVITYDesc_ActivityEndDate}
                                        />
                                    </FloatingLabel>
                                </Form.Group>


                                <Form.Group className="mb-3" controlId="floatingActivityType">
                                    <FloatingLabel controlId="floatingActivityType" label="Activity Type">
                                        <Form.Select   onChange={(e: any) => updateActivity('ACTIVITYDesc_ActivityTypeCode', e.target.value)}
                                        size="lg" aria-label="ActivityType" value={data && data.ACTIVITYDesc_ActivityTypeCode}>
                                            {ActivityType  && ActivityType.map((obj:any, index:any) =>
                                                (<option key={'wm-options'+index} value={obj.Name}>{obj.Name}</option>)
                                            )} 
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Row className="d-inline-flex">
                                        <Col className="ml-2 col-4">
                                            <Form.Check
                                                type="checkbox"
                                                defaultChecked={true}
                                                label="Active"
                                            />
                                        </Col>
                                        <Col className="col-8" style={(orgCredentialsType !== 'none') ? {} : {display:'none'}}>
                                            <Form.Check
                                                type="checkbox"
                                                defaultChecked={false}
                                                label="Send to EPA"
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col className="col-4">

                                <Form.Group className="mb-3" controlId="floatingActivityType">
                                    <FloatingLabel controlId="floatingActivityType" label="Activity Media">
                                        <Form.Select   onChange={(e: any) => updateActivity('ACTIVITYDesc_ActivityMediaName', e.target.value)}
                                        size="lg" aria-label="Activity Media" value={activity && activity.ACTIVITYDesc_ActivityMediaName}>
                                            <option value={'null'}></option>
                                            {ActivityMedia  && ActivityMedia.map((obj:any, index:any) =>
                                                (<option key={'wm-options'+index} value={obj.Name}>{obj.Name}</option>)
                                            )} 
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="floatingActivityType">
                                    <FloatingLabel controlId="floatingActivityType" label="Activity Media Subdivision">
                                        <Form.Select   onChange={(e: any) => updateActivity('ACTIVITYDesc_ActivityMediaName', e.target.value)}
                                        size="lg" aria-label="ACTIVITYDesc_ActivityMediaSubDivisionName" value={data && data.ACTIVITYDesc_ActivityMediaSubDivisionName}>
                                            {ActivityMediaSubdivision  && ActivityMediaSubdivision.map((obj:any, index:any) =>
                                                (<option key={'wm-options'+index} value={obj.Name}>{obj.Name}</option>)
                                            )} 
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col className="col-4">
                                <Form.Group className="mb-3" controlId="floatingSampleCollectionEquipment">
                                    <FloatingLabel controlId="floatingSampleCollectionEquipment" label="Sample Collection Equipment">
                                        <Form.Select   onChange={(e: any) => updateActivity('ACTIVITYDesc_SampleCollectionEquipment', e.target.value)}
                                        size="lg" aria-label="Sample Collection Equipment" value={data && data.ACTIVITYDesc_SampleCollectionEquipment}>
                                            {SampleCollectionEquipment  && SampleCollectionEquipment.map((obj:any, index:any) =>
                                                (<option key={'wm-options'+index} value={obj.Name}>{obj.Name}</option>)
                                            )} 
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="floatingAssemblage">
                                    <FloatingLabel controlId="floatingAssemblage" label="Assemblage">
                                        <Form.Select   onChange={(e: any) => updateActivity('ACTIVITYDesc_Assemblage', e.target.value)}
                                        size="lg" aria-label="Assemblage" value={data && data.ACTIVITYDesc_Assemblage}>
                                            {Assemblage  && Assemblage.map((obj:any, index:any) =>
                                                (<option key={'wm-options'+index} value={obj.Name}>{obj.Name}</option>)
                                            )} 
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-end w-100">
                            <div className="add-activities-button-box">
                                <Button onClick={handleCancel}
                                        variant="primary" id="export-btn" type="submit" className="p-3 btn btn-primary m-2">
                                    Cancel
                                </Button>
                                <Button  
                                   onClick={() => {
                                        setAction('table')
                                    }}
                                    variant="primary" type="submit" className="p-3 btn btn-primary m-2">
                                    Save & Exit
                                </Button>
                            </div>
                        </Row>
                    </Form>
                </div>
            </Row>
            <Row className="d-flex justify-content-between" style={{marginTop: 50}}>
                <div className="results-wrapper">
                    <h2>Results</h2>
                    <div className="add-activities-button-box">
                        <Button variant="primary" id="export-btn" type="submit" className="p-3 btn btn-primary m-2"
                                                onClick={() => {
                             exportCSV();
                                        
                                    }}>
                            CSV
                        </Button>
                        <Button variant="primary" type="submit" className="p-3 btn btn-primary m-2"
                                                onClick={() => {
                             addResult();
                                        
                                    }}>
                            Add Result

                        </Button>
                    </div>
                </div>
            </Row>
            <ReportTableExpanded columns={tableColumns} data={resultsData} />
        </Row>
    )
}

export default AddActivities