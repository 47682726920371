import axios, { AxiosResponse } from 'axios';
import json from '../../settings.json';
import { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useLocation, useNavigate } from 'react-router-dom';
import settings from '../../settings.json';
import agent from "../../api/agent";

export async function createUser({
    USER_NAME,
    EMAIL,
    CREDENTIAL,
    FIRST_NAME,
    LAST_NAME
}: any, notify: any, location: any): Promise<void> {
    //@ts-ignore
    const url = settings.apiMethods.KeycloakCreateUser;
    const headers = {
        'Content-Type': 'application/json',
        'X-Api-Key': `${json.apiKey}`,
    };
    const data = JSON.stringify({
        USER_NAME,
        EMAIL,
        CREDENTIAL,
        FIRST_NAME,
        LAST_NAME,
        ENV: settings.apiEnvironment,
    });

    try {
        const response: AxiosResponse = await axios.post(url, data, { headers })

        if(response && response.data && response.data.err_msg){
            console.error('Failed to create user:', response.data.err_msg);
            notify(`Failed to create user: ${response.data.err_msg}`, 'error') 
        } else if(response && response.data && response.data.body){
            console.log('User created:', JSON.parse(response.data.body));
            notify('Account created!')    
            // Check for the procurement_id
            const searchParams = new URLSearchParams(location.search);
            const procurementId = searchParams.get('procurement_account_id'); 
            const x_ms_marketplace_token = searchParams.get('x_ms_marketplace_token'); 
            const customerId = searchParams.get('customer_id'); 



            if(procurementId) {
                // Make the POST request to GCP API
                const gcpApiURL = `${settings.apiMethods.GcpCustomer}?email=${EMAIL}&procurement_account_id=${procurementId}`; 
                try {
                    const gcpResponse: AxiosResponse = await axios.post(gcpApiURL, {}, {
                        headers: {
                            [settings.apiHeader]: `${settings.apiKey}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    console.log('GCP API Response:', gcpResponse.data);
                } catch (err) {
                    console.error('Failed to make a POST request to GCP API:');
                }
            } 
            if(x_ms_marketplace_token) {
                // Make the POST request to Azure API
                const encodedToken = encodeURIComponent(x_ms_marketplace_token);
                const AzureApiURL = `${settings.apiMethods.AzureCustomer}?email=${EMAIL}&x_ms_marketplace_token=${encodedToken}`; 
                try {
                    const gcpResponse: AxiosResponse = await axios.post(AzureApiURL, {}, {
                        headers: {
                            [settings.apiHeader]: `${settings.apiKey}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    console.log('Azure API Response:', gcpResponse.data);
                } catch (err) {
                    console.error('Failed to make a POST request to Azure API:');
                }
            }   
            if (customerId) {
                // Make the POST request to AWS API
                // Construct the resolver URL
                const resolverURL = `${settings.apiMethods.MarketplaceUserProcessor}?email=${EMAIL}&customer_id=${customerId}`;
                // Construct the entitlement URL
                const entitlementURL = `${settings.apiMethods.MarketplaceEntitlementCheck}?email=${EMAIL}`;
            
                try {
                    // Make the first POST request to the resolver URL
                    const resolverResponse = await axios.post(resolverURL, {}, {
                        headers: {
                            [settings.apiHeader]: `${settings.apiKey}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    console.log('Resolver Response:', resolverResponse.data);
            
                    // Make the second POST request to the entitlement URL
                    const entitlementResponse = await axios.post(entitlementURL, {}, {
                        headers: {
                            [settings.apiHeader]: `${settings.apiKey}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    console.log('Entitlement Response:', entitlementResponse.data);
            
                } catch (err) {
                    console.error('Failed to make a POST request:', err);
                }
            }
              

        }

/*        setTimeout(() => {
            window.location.href = `${window.location.origin}/login`
        }, 2000)*/

        
    } catch (error: any) {
        console.error('Failed to create user:', error.response, error);
        notify(`Failed to create user: ${error.response}`, 'error')
    }
}

      

export async function resetPassword({
    USER_NAME
}: any, notify: any): Promise<void> {
    //@ts-ignore
    const url = settings.apiMethods.KeycloakCreateUser;
    const headers = {
        'Content-Type': 'application/json',
        'X-Api-Key': `${json.apiKey}`,
    };
    const data = JSON.stringify({
        USER_NAME, 
        RESET_PASSWORD:'true',
        ENV: settings.apiEnvironment,
    });
   
    try {
        const response: AxiosResponse = await axios.post(url, data, { headers })

        if(response && response.data && response.data.err_msg){
            console.error('Failed to reset password:', response.data.err_msg);
            notify(`Failed to reset password: ${response.data.err_msg}`, 'error') 
        } else if(response && response.data && response.data.body){
            console.log('Password link sent:', JSON.parse(response.data.body));
            notify('Password link sent')         
        }



    } catch (error: any) {
        console.error('Failed to Password reset:', error.response);
        notify(`Failed to Password reset: ${error.response}`, 'error')
    }
}


export async function login(
    {
        username,
        password,
        grant_type = 'password',
        client_id = settings.Keycloak.ClientId,
    }: any,
    notify: any,
    global: any,
    navigateSplash: any,
    setShowNewUserAlert: any
): Promise<string> {
      
    const url = settings.logon;
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };

    const data = new URLSearchParams();
    data.append('grant_type', grant_type);
    data.append('username', username); 
    data.append('password', password); 
    data.append('client_id', client_id);

    try {
       console.log("calling gcp api")
       const gcpApi = `${settings.apiMethods.GcpCustomer}?email=${username}`;
       const gcpResponse = await fetch(gcpApi);
       if (!gcpResponse.ok) {
        throw new Error(`GCP API error: ${gcpResponse.statusText}`);
         }

    } catch (error: any) {
        console.log(error);
        console.error('GCP failed:', error.message);
        //notify(`Failed to login: ${error.message}`, 'error');
    }

     // AWS API call
    try {
        console.log("calling aws api");
        const awsApi = `${settings.apiMethods.MarketplaceUserProcessor}?email=${username}&customer_id=null`;
        const awsResponse = await fetch(awsApi, {
            headers: {
                [settings.apiHeader]: `${settings.apiKey}`,
                'Content-Type': 'application/json'
            }
        });
        if (!awsResponse.ok) {
            throw new Error(`AWS API error: ${awsResponse.statusText}`);
        }

        const entitlementApi = `${settings.apiMethods.MarketplaceEntitlementCheck}?email=${username}`;
        const entitlementResponse = await fetch(entitlementApi, {
            headers: {
                [settings.apiHeader]: `${settings.apiKey}`,
                'Content-Type': 'application/json'
            }
        });
        if (!entitlementResponse.ok) {
            throw new Error(`Entitlement API error: ${entitlementResponse.statusText}`);
        }
    } catch (error: any) {
        console.log(error);
        console.error('AWS failed:', error.message);
    }

    // Azure API call
    try {
        console.log("calling azure api");
        const azureApi = `${settings.apiMethods.AzureCustomer}?email=${username}`;
        const azureResponse = await fetch(azureApi, {
            headers: {
                [settings.apiHeader]: `${settings.apiKey}`,
                'Content-Type': 'application/json'
            }
        });
        if (!azureResponse.ok) {
            throw new Error(`Azure API error: ${azureResponse.statusText}`);
        }
    } catch (error: any) {
        console.log(error);
        console.error('Azure failed:', error.message);
    }

    try {
       const response: AxiosResponse = await axios.post(url, data.toString(),  { transformRequest: [(data, headers) => {
              //@ts-ignore
               delete headers.keycloakToken
               delete headers['x-api-key']
           return data 
        }] })
        
        global.setAuthenticatedUser(true);
        const { access_token, refresh_token } = response.data;
        if(access_token && access_token.length>0){
           let parsedToken = parseToken(access_token);
           if(parsedToken){
               parseUserRoles(parsedToken, global)
           }                                
        }
        sessionStorage.setItem("authKey", access_token);
        sessionStorage.setItem("refreshToken", refresh_token);
        localStorage.setItem('useremail', username);

        notify('Login Successful', 'success');
        localStorage.setItem('currentUser', username || '')
        getUser(username, global, false, setShowNewUserAlert);
        setTimeout(() => {  
            navigateSplash();       
        }, 1000);
        return "Success!";
    } catch (error: any) {
        console.log(error);
        console.error('Login failed:', error.message);
        notify(`Failed to login: ${error.message}`, 'error');
        return `Failed to login`;
    }
}


export const getUser = (username: any, global: any, secondAttempt?: boolean, setShowNewUserAlert?: any) => {
        let userData = {email: username} as userInfo;
        userData.Profiles = ['Researcher']; 
        userData.source = 'login';
        localStorage.setItem('useremail', username)
  

        agent.UserManagement.UserGet('', username).then((res: any) => {
        
            if(res.errorMessage){
                global.setUserRole('User');
                userData.indexkey = Math.random();
                global.setUserInfo(userData); 
            } else {
                if(res && res.body && res.body.length>0){

                    if(typeof res.body === 'string' && res.body.substr(0, 10) === 'Integrated'.substr(0, 10)){
                            console.log('Integrated') //After registering with form
                            sessionStorage.removeItem("userInfo");
                            if(!secondAttempt){
                                getUser(username, global, true, setShowNewUserAlert)  //Send back second time, for User info   
                            }
                            if(setShowNewUserAlert){
                                setShowNewUserAlert(true)
                            } else {
                               
                            }
                    } else if(res.statusCode === 401 && res.body === "Unauthorized"){
                            console.log("Unauthorized")
                            sessionStorage.removeItem("userInfo");
                            global.setUserOrgString("none"); //Deloitte
                            localStorage.setItem('UserOrgString', "none");
                            //localStorage.removeItem('UserOrgString');
                            if(setShowNewUserAlert){
                                setShowNewUserAlert(true)
                            } else {
                                  
                            }
                    }  else {
                        localStorage.setItem('authorized' + username, 'true'); 
                        if(res.body[0] && (res.body[0].org_id === null||!res.body[0].org_id)){
                            global.setUserOrgString("none");

                            userData.data = res.body[0];
                            userData.indexkey = Math.random();
                            global.setUserInfo(userData); 
                            setRoleOrg(userData, global);
                            //Unassigned
                            const newUser = { USER_ID: res.body[0].user_id, ROLE: res.body[0].role, NAME: res.body[0].name, PROFILE: res.body[0].profile, ORG_ID: '11', WQX_REPORT_ID: '11', IS_ACTIVE: true };
                            agent.UserManagement.UserUpdate(res.body[0].user_id, newUser).then((res: any) => {
                                  global.notify('Welcome!') //++ notification
                            })                            

                        } else if (res.body[0] && res.body[0].org_id) {
                            userData.data = res.body[0];
                            userData.indexkey = Math.random();
                            global.setUserInfo(userData); 
                            setRoleOrg(userData, global);
                            //global.setUserRole('User');
                            if(res.body[0].org_id === 1){
                                global.setUserOrgString('True Elements');
                                localStorage.setItem('UserOrgString', 'True Elements');                              
                            } else {
                                agent.UserManagement.OrgGet(res.body[0].org_id).then((resO: any) => {
                                    if(resO && resO.body && resO.body[0] && resO.statusCode === 200) {
                                        global.setUserOrgString(resO.body[0].name);
                                        localStorage.setItem('UserOrgString', resO.body[0].name);
                                    }
                                })                                                        
                            }
                        }
                    }
                         
                }
            }
        }) 

}

export function parseToken(token: any){
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (error: any) {
        console.error(error);
    }
}

export function parseUserRoles(parsedToken: any, props:any, skipnewuser?: boolean){
    let roles = [] as any;
    if(parsedToken){
        if (parsedToken.resourceAccess && parsedToken.resourceAccess['true-elements'] && parsedToken.resourceAccess['true-elements'].roles) {
            const loginRoles = parsedToken.resourceAccess['true-elements'].roles;
            roles = loginRoles;
            props.setUserRoles(roles);
            if (!skipnewuser && loginRoles.length === 1 && loginRoles.indexOf('temp') > -1) {
                //props.setShowNewUserAlert(true);
            }
        }
        if (parsedToken.resource_access && parsedToken.resource_access['true-elements'] && parsedToken.resource_access['true-elements'].roles) {
            const loginRoles = parsedToken.resource_access['true-elements'].roles;
            roles = loginRoles;
            props.setUserRoles(roles);
            if (!skipnewuser && loginRoles.length === 1 && loginRoles.indexOf('temp') > -1) {
                //props.setShowNewUserAlert(true);
            }
        }

    }
    return roles;
}

function setUserPermission(org_id: any, global:any){
    if(org_id === 9){   
        global.setHucRestrict( ['02070011', '02070010', '02070008', '02080110'] ); 
    }
}

function setRoleOrg(_userData:any, global:any){
    global.setUserInfo(_userData);
 
    if(_userData.data && _userData.data.role){
        if(_userData.data.role.toLowerCase() === 'admin' || _userData.data.role.toLowerCase() === 'te-admin' ){
            global.setUserRole(_userData.data.role); 
        } else {
            global.setUserRole('User');    
        }
    } else {
        global.setUserRole('User');
    } 

    if(_userData.data && _userData.data.org_id){
        global.setUserOrg(_userData.data.org_id); 
        setUserPermission(_userData.data.org_id, global);
        if(_userData.data.org_id === 9 && _userData.source === 'login'){
            global.setSelectedHUC8('02070011');
        }
    } else {
        global.setUserOrg(0);
        setUserPermission(0, global);
    } 
}


interface userInfo{
    email: string;
    name?: string;
    Profiles?: any;
    indexkey?: number;
    source?: string;
    roles?: any;
    data?: { role: string, org_id: number }
}
