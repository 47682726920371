import { SpinnerCircular } from "spinners-react";
import "./LoadingDataAnimation.scss";
import Render from "./render";
const spinnerStyle = {
  display: "block",
  margin: "auto",
  marginTop: "20%",
};

const LoadingDataAnimation: React.FC<{ dataLoading: boolean }> = ({
  dataLoading: dataLoaded,
}) => {
  return (
    <>
      <Render condition={dataLoaded}>
        <div className="spinner-overlay">
          <SpinnerCircular
            color="#4FC2D9"
            secondaryColor="#0B1736"
            style={spinnerStyle}
            size={60}
          />
        </div>
      </Render>
    </>
  );
};

export default LoadingDataAnimation;
