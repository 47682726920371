import { useContext, useState, useEffect } from 'react';
import { Layer, Source, Marker, Popup } from 'react-map-gl';
import { CircleLayer } from 'mapbox-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { RefContext } from '../../RefContext';
import { LayerDataContext } from '../../LayerDataContext';
import { PointContext } from '../../PointContext';
import { ActivePopupContext } from '../../ActivePopupContext';

const WaterConflict = ({
    id,
    global = null,
    opacity,
    tileSet,
    sourceLayer,
    radius = 5,
    tableColumns,
    sourceLink,
    sourceLabel,
    reportRoute
}: any) => {

    const [features, setFeatures] = useState<any>(null);
    const [properties, setProperties] = useState<any>(null);

    const { currentRef } = useContext(RefContext);
    const { setCurrentPoint } = useContext(PointContext);
    const { setCurrentLayerData } = useContext(LayerDataContext);
    const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);

    const navigate = useNavigate();

    // @ts-ignore
    const mapInstance = currentRef && currentRef?.current;

    useEffect(() => {
        if (mapInstance) {
            const handleClick = (event: any) => {
                const layerObject = mapInstance.queryRenderedFeatures(event.point);

                if (layerObject.length > 0) {
                    const feature = layerObject[0];
                    setFeatures(feature);
                    setProperties(feature.properties);
                    const lngLat = event.lngLat;
                    setActivePopup({
                        id: `${id}-popup`,
                        content: renderPopup(feature, lngLat)
                    });
                } else {
                    setFeatures(null);
                    setProperties(null);
                }
            };

            mapInstance.on('click', handleClick);

            return () => {
                mapInstance.off('click', handleClick);
            };
        }
    }, [mapInstance]);

    const goTo = ({ lat, lng }: any, data: any) => {
        setCurrentPoint?.({ lat, lng });

        setCurrentLayerData?.({
            ...data, tableColumns, sourceLink, sourceLabel
        });

        setTimeout(() => navigate(reportRoute), 100);
    };

    const renderPopup = (feature: any, lngLat: any) => (
        <Popup
            longitude={lngLat.lng}
            latitude={lngLat.lat}
            onClose={() => setActivePopup({ id: null, content: null })}
        >
            <div style={{
                color: 'white',
                position: 'relative',
                backgroundColor: "#002044",
                padding: '0.25rem',
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
                borderRadius: '4px',
                maxWidth: '150px',
                textAlign: 'left',
            }}>
                <FontAwesomeIcon style={{ position: 'absolute', right: "5%", cursor: 'pointer' }} onClick={() => setActivePopup({ id: null, content: null })} icon={faXmark} />
                <div style={{
                    color: 'white',
                    backgroundColor: "#002044",
                    padding: '0.5rem',
                    borderRadius: '4px',
                    maxWidth: '150px',
                    textAlign: 'left',
                }}>
                    <p>{feature.properties.label}</p>
                    <a
                        style={{
                            color: '#4fc2d9',
                            fontWeight: 'bold',
                            textDecoration: 'underline',
                            backgroundColor: 'transparent',
                            borderRadius: '6px',
                            cursor: 'pointer'
                        }}
                        onClick={() => goTo(lngLat, feature.properties)}
                    >
                        Go to report
                    </a>
                </div>
            </div>
        </Popup>
    );

    const CircleVectorLayer: CircleLayer = {
        id: `${id}-layer`,
        type: "circle",
        'source-layer': `${sourceLayer}`,
        paint: {
            "circle-radius": radius,
            "circle-opacity": opacity,
            'circle-color': [
                "match",
                ["get", "date"],
                `${global.selectedYear}`, '#5BA37F',
                "transparent"
            ],
        },
    };

    return (
        <>
            <Source
                id={`${id}-layer`}
                type="vector"
                url={tileSet}
            >
                <Layer
                    {...CircleVectorLayer}
                />
            </Source>
            {activePopup && activePopup.id === `${id}-popup` && activePopup.content}
        </>
    )
}

export default WaterConflict;
