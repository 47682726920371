import React, { useState, useEffect, useContext, useRef, useCallback, memo } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import {
  Map,
  FullscreenControl,
  NavigationControl,
  GeolocateControl
} from 'react-map-gl';
import settings from '../../../settings.json';
import { PointContext } from "../../../PointContext";
import { RefContext } from '../../../RefContext';
import { Row, Col, Container } from 'react-bootstrap';
import { putViewportIntoStorage, updateLocationContext } from '../../utils';
import MapAddition from '../shared/MapAddition';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import ReportTable from "../shared/ReportTable";
import MapLegend from '../shared/MapLegend';
import agent from '../../../api/agent';
import { SpinnerCircular } from 'spinners-react';
import { Pagination } from 'react-bootstrap';
import { AppContext } from '../../../AppContext'
import 'chartjs-plugin-zoom';
import './wise-report.scss';

const WiseReport = ({ global }: any) => {
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;

  const ref = useRef<any>();
  const { currentPoint } = useContext<any>(PointContext);
  const { setCurrentRef } = useContext(RefContext);
  const { updateContext } = useContext(AppContext);

  const [tableData, setTableData] = useState<any>();
  const [datasets, setDatasets] = useState<string[]>([]);
  const [dataset, setDataset] = useState<any>()
  const [selectedDataset, setSelectedDataset] = useState<string>('');
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(2); 
  const [endOfDataMessage, setEndOfDataMessage] = useState<string | null>(null);

  const notificationCounter = useRef(0);

  const { country, isoCode, lat, lng } = currentPoint

  useEffect(() => {
    if (error && notificationCounter.current === 0) {
      global.notify('No data at this location');
      notificationCounter.current++;
    }
  }, [error]);

  useEffect(() => {
    setCurrentRef?.(ref);
  }, [ref]);

  const getData = async (countryCode: string, dataset: string, page: number = 1) => {
    global.setLoading(true);
    
    try {
      const { body } = await agent.Reports.getWiseData(countryCode, dataset, page);
  
      if (body.length === 0) {
        setEndOfDataMessage("End of data"); // Set the end of data message
        setTableData([]); // Clear the table data
        setTotalPages(page); // No more pages
      } else {
        setEndOfDataMessage(null); // Reset message when data is available
        setTableData(body); // Set table data
        setTotalPages(page + 1); // Increment pages for next call
      }
    } catch (error) {
      setError(true);
    } finally {
      setError(false);
      global.setLoading(false);
    }
  };
  
  useEffect(() => {
    if (isoCode && selectedDataset) {
      getData(isoCode, selectedDataset, currentPage);
    }
  }, [isoCode, selectedDataset, currentPage]);
  
  const getMetaData = async (iso: string) => {
    global.setLoading(true);
    try {
      const { body } = await agent.Reports.getWiseMetaData(iso)
      setDatasets(body)
      setDataset(body[0])
    } catch (error) {
      if (notificationCounter.current === 0) {
        global.notify('No data at this location');
        notificationCounter.current++;
      }
    } finally {
      global.setLoading(false);
    }
  };

  const flyToPoint = (lng: any, lat: any) => {
    if (ref.current) {
      ref.current.flyTo({
        center: [lng, lat],
        essential: true,
        zoom: 6,
      });
    }
  };

  useEffect(() => {
    if (currentPoint && updateContext) {
      const { country, lng, lat } = currentPoint;
      setTimeout(() => updateLocationContext(lat, lng, global.currentReport, country, updateContext), 100);
      setTimeout(() => flyToPoint(lng, lat), 100);
    }
  }, [currentPoint]);

  
  useEffect(() => {
    if (isoCode) {
      getMetaData(isoCode);
    }
  }, [isoCode]);

  useEffect(() => {
    return () => {
      global.setLoading(false);
    };
  }, []);

  const renderReportTables = useCallback((data: any) => {
    if (endOfDataMessage) {
      return <p className="end-of-data-message">{endOfDataMessage}</p>;
    }
  
    if (data.length === 0) {
      return <p className="no-data">No data</p>;
    }
  
    const handleHeaders = (input: any[]) => {
      let headers: any = [];
      input.forEach((header: any) => headers.push({ Header: header, accessor: header }));
      return headers.length > 0 ? headers : [];
    };
  
    const keys = Object.keys(data[0]);
    return <ReportTable data={data} columns={handleHeaders(keys)} />;
  }, [currentPage, endOfDataMessage]);
  
  
  const spinnerStyle = {
    color: '#3861AD',
    overflow: 'visible',
    width: '75px',
    marginTop: '10%'
  };

  useEffect(() => {
    if(dataset) getData(isoCode, dataset, currentPage);
  }, [dataset])

  const handlePageChange = useCallback((page: number) => getData(isoCode, dataset, page), [isoCode, dataset]);
  

  return (
    <FullScreenComp handle={fullscreenhandle}>
      <Container className='wise-report-container'>
        <ReportHeader
          global={global}
          data={[]}
          reportID={"WiseReport"}
          fullScreenClickHandle={fullscreenhandle.enter}
        />
        <Row>
          <Col className='col-12 top d-flex justify-content-center align-items-center'>
            <Col className="wise-map-container w-100">
              <Map
                mapboxAccessToken={settings.maboxKey}
                mapStyle={global.mapStyle}
                ref={ref}
                preserveDrawingBuffer={true}
                onClick={(e:any) => global.onMapClick(e)}
                onMove={(e) => {
                  putViewportIntoStorage({
                    longitude: e.viewState.longitude,
                    latitude: e.viewState.latitude,
                    zoom: e.viewState.zoom,
                  });
                  global.setViewport({
                    longitude: e.viewState.longitude,
                    latitude: e.viewState.latitude,
                    zoom: e.viewState.zoom,
                  });
                }}
              >
                <MapAddition 
                  global={global} 
                  mapRef={ref}
                  position={'low'}
                  zipOff={true}
                  MapSliderAdd={true}
                />
                <MapLegend global={global} legendWidth={280} />
                <FullscreenControl />
                <NavigationControl />
                <GeolocateControl />
              </Map>
            </Col>
          </Col>
        </Row>
        {
          datasets.length > 0 && (
            <Row>
              <Col className='col-12 container-bottom bottom'>
              <div className='dataset-menu-wrapper'>
                  <strong>Dataset:</strong>
                  <select
                    className="form-select"
                    value={selectedDataset}
                    onChange={(e) => {
                      const menuDataset = e.target.value;
                      setSelectedDataset(menuDataset);
                      setCurrentPage(1);
                      setDataset(menuDataset)
                      getData(isoCode, menuDataset, currentPage);
                    }}
                  >
                    {datasets.map((dataset: string, index: number) => (
                      <option value={dataset} key={index}>
                        {dataset}
                      </option>
                    ))}
                  </select>
                </div>
                <Col style={{ marginTop: '1rem' }}>
                  {datasets.length > 0 && (
                    <div className="dataset-select-container">
                        {tableData ? renderReportTables(tableData) : <SpinnerCircular style={spinnerStyle}/>}
                    </div>
                  )}
                </Col>
                <div className='pagination-wrapper'>
                  <Pagination className="pagination-controls">
                    <Pagination.Prev
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Pagination.Prev>
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                      <Pagination.Item
                        key={page}
                        active={page === currentPage}
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                  </Pagination.Next>
                  </Pagination>
                </div>
              </Col>
            </Row>
          )
        }
      </Container>
      <ReportFooter />
    </FullScreenComp>
  );
}

export default memo(WiseReport);