import { useState, useContext, useEffect } from 'react'
import { Layer, Source, FillLayer, Marker } from 'react-map-gl'
import { adjustHexBrightness } from '../../features/utils';
import { useNavigate } from 'react-router-dom';
import { RefContext } from '../../RefContext';
import { PointContext } from '../../PointContext';
import { LayerDataContext } from '../../LayerDataContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'

const USGSPWU = ({
    global,
    id,
    opacity,
    url,
    reportRoute,
    action,
    tableColumns, sourceLink, sourceLabel
  }: any) => {

  const [properties, setProperties] = useState<any>()
  const [open, setOpen] = useState(false)
  const [year, setYear] = useState()
  const location = window.location.pathname
  const { currentRef } = useContext(RefContext)
  const { setCurrentPoint } = useContext(PointContext)
  const { setCurrentLayerData } = useContext(LayerDataContext);

  const getFeaturesAtPoint = async (map: any, event: any) => {
    const features = await map.queryRenderedFeatures(event.point)?.[0]; 
    setProperties(features.properties);
    setOpen(true)
  };
  // @ts-ignore
  const map = currentRef?.current
  const navigate = useNavigate();

  const goTo = ({ lat, lng }: any, data: any) => {
    setCurrentPoint?.({ lat, lng });
    console.log(data)
    setCurrentLayerData?.({
        ...data, tableColumns, sourceLink, sourceLabel, year: global.USGSYear
    });

    setTimeout(() => navigate(reportRoute), 100);
  }

  useEffect(() => { 
    if(map) {
      map.on('click', (e:any) => getFeaturesAtPoint(map, e))
    }
  }, [map])

  const handleClose = () => {
    setOpen(false)
    setProperties(null)
  }

  
  const USGSPWULayer: FillLayer = {
    id,
    source: `water_usage_cities`,
    "source-layer": `water_usage_cities`,
    type: "fill",
    paint: {
        "fill-opacity": opacity,
        "fill-color": [
            "interpolate",
            ["linear"],
            ["get", year ? `1/${year}` : global.dateString],
            0, 'transparent',
            50, `${adjustHexBrightness("#00FF00", global.USGSWUCBrightness, 10)}`,
            100, `${adjustHexBrightness("#00FF00", global.USGSWUCBrightness, 10)}`,
            150, `${adjustHexBrightness("#00FF00", global.USGSWUCBrightness, 10)}`,
            200, `${adjustHexBrightness("#00FF00", global.USGSWUCBrightness, 10)}`,
            250, `${adjustHexBrightness("#80FF00", global.USGSWUCBrightness, 10)}`,
            300, `${adjustHexBrightness("#80FF00", global.USGSWUCBrightness, 10)}`,
            350, `${adjustHexBrightness("#80FF00", global.USGSWUCBrightness, 10)}`,
            400, `${adjustHexBrightness("#80FF00", global.USGSWUCBrightness, 10)}`,
            450, `${adjustHexBrightness("#FFFF00", global.USGSWUCBrightness, 10)}`,
            500, `${adjustHexBrightness("#FFFF00", global.USGSWUCBrightness, 10)}`,
            550, `${adjustHexBrightness("#FFFF00", global.USGSWUCBrightness, 10)}`,
            600, `${adjustHexBrightness("#FFFF00", global.USGSWUCBrightness, 10)}`,
            650, `${adjustHexBrightness("#FFFF00", global.USGSWUCBrightness, 10)}`,
            700, `${adjustHexBrightness("#FF8000", global.USGSWUCBrightness, 10)}`,
            750, `${adjustHexBrightness("#FF8000", global.USGSWUCBrightness, 10)}`,
            800, `${adjustHexBrightness("#FF8000", global.USGSWUCBrightness, 10)}`,
            850, `${adjustHexBrightness("#FF8000", global.USGSWUCBrightness, 10)}`,
            900, `${adjustHexBrightness("#FF8000", global.USGSWUCBrightness, 10)}`,
            950, `${adjustHexBrightness("#FF0000", global.USGSWUCBrightness, 10)}`,
            1000, `${adjustHexBrightness("#FF0000", global.USGSWUCBrightness, 10)}`,
            1050, `${adjustHexBrightness("#FF0000", global.USGSWUCBrightness, 10)}`,
            1100, `${adjustHexBrightness("#FF0000", global.USGSWUCBrightness, 10)}`,
            1150, `${adjustHexBrightness("#FF0000", global.USGSWUCBrightness, 10)}`,
            1200, `${adjustHexBrightness("#FF0000", global.USGSWUCBrightness, 10)}`,
            1250, `${adjustHexBrightness("#FF0000", global.USGSWUCBrightness, 10)}`,
            1300, `${adjustHexBrightness("#FF0000", global.USGSWUCBrightness, 10)}`,
            1350, `${adjustHexBrightness("#FF0000", global.USGSWUCBrightness, 10)}`,
            1400, `${adjustHexBrightness("#FF0000", global.USGSWUCBrightness, 10)}`,
            1450, `${adjustHexBrightness("#FF0000", global.USGSWUCBrightness, 10)}`,
            1550, `${adjustHexBrightness("#FF0000", global.USGSWUCBrightness, 10)}`
        ],
    },
  };

  useEffect(() => {
    if(location === '/USGSWUCReport') {
      setYear(global.selectedUSGSYear.toString())
    }
  },[global.selectedUSGSYear])

  return (
    <>
    <Source
      type="vector"
      url={url}
      id={id}
    >
      <Layer {...USGSPWULayer} />
    </Source>
    {properties && open && (
                <Marker
                    latitude={action.lngLat.lat}
                    longitude={action.lngLat.lng}
                    style={{
                        color: 'white',
                        position: 'relative',
                        backgroundColor: "#002044",
                        padding: '0.25rem',
                        paddingLeft: '0.5rem',
                        paddingRight: '0.5rem',
                        borderRadius: '4px',
                        maxWidth: '150px',
                        textAlign: 'left',
                    }}
                    anchor="bottom"
                >
                    <FontAwesomeIcon style={{ position: 'absolute', right: "5%", cursor: 'pointer' }} onClick={handleClose} icon={faXmark} />
                    <div style={{
                        color: 'white',
                        backgroundColor: "#002044",
                        padding: '0.5rem',
                        borderRadius: '4px',
                        maxWidth: '150px',
                        textAlign: 'left',
                    }}>
                        <p>{properties.MSA}</p>
                        <a
                            style={{
                                color: '#4fc2d9',
                                fontWeight: 'bold',
                                textDecoration: 'underline',
                                backgroundColor: 'transparent',
                                borderRadius: '6px',
                                cursor: 'pointer'
                            }}
                            onClick={() => goTo(action.lngLat, properties)}
                        >
                            Go to report
                        </a>
                    </div>
                </Marker>
            )}
    </>
  )
}

export default USGSPWU