import { useState, useContext } from 'react';
import { Marker, Popup } from 'react-map-gl';
import { LayerDataContext } from '../../LayerDataContext';
import { useNavigate } from 'react-router-dom'
import { PointContext } from '../../PointContext';
import useSupercluster from 'use-supercluster';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import PaginatedPopup from '../PaginatedPopup';
import top100 from '../../assets/top100.png'
import './render-markers.scss'

const RenderMarkers = (props: any) => {
    const { list, bounds, viewport } = props
    const [current, setCurrent] = useState<any | null>(null)
    const [pageNumber, setPageNumber] = useState<number>(0)

    const { setCurrentLayerData } = useContext(LayerDataContext)
    const { setCurrentPoint } = useContext(PointContext)
    const navigate = useNavigate()

    const tableColumns: any = [
        {
            Header: 'Address',
            accessor: 'Address',
        },
        {
            Header: 'Chemical',
            accessor: 'Chemical',
        },
        {
            Header: 'City',
            accessor: 'City',
        },
        {
            Header: 'Company',
            accessor: 'Company',
        },
        {
            Header: 'Facility',
            accessor: 'Facility',
        },
        {
            Header: 'Percent Of Total Company Hazard',
            accessor: 'Percent Of Total Company Hazard',
        },
        {
            Header: 'Potw Transfers Lbs',
            accessor: 'Potw Transfers Lbs',
        },
        {
            Header: 'Split',
            accessor: 'Split',
        },
        {
            Header: 'State',
            accessor: 'State',
        },
        {
            Header: 'Surface Water Releases Lbs',
            accessor: 'Surface Water Releases Lbs',
        },
        {
            Header: 'Toxic 100 Water Rank',
            accessor: 'Toxic 100 Water Rank',
        },
        {
            Header: 'Toxic Hazard',
            accessor: 'Toxic Hazard',
        },
        {
            Header: 'Year',
            accessor: 'Year',
        },
    ]

    const sourceLink: string = 'https://peri.umass.edu/toxic-100-water-polluters-index-current'
    const sourceLabel: string = 'Political Economy Research Institute (PERI) University of Massachusetts Amherst'

    const goTo = (coor: any, data: any) => {

        setCurrentPoint?.({
            lat: coor.lat,
            lng: coor.lng
        })

        setCurrentLayerData?.({
            data, 
            tableColumns, 
            sourceLink, 
            sourceLabel
        })

        setTimeout(() => navigate('/Top100Polluters'), 100)
    }

    const points = list && list.map((point: any, index: number) => ({
        type: "Feature",
        properties: {
            cluster: false,
            index,
            id: `cluster-${index}`,
        },
        geometry: {
            type: "Point",
            coordinates: [point.lng, point.lat],
        }
    }))

    const { clusters } = useSupercluster({
        points,
        bounds,
        zoom: viewport.zoom,
        options: { radius: 150, maxZoom: 7 }
    })

    const renderList = () => {
        return clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates
            const { cluster: isCluster, point_count: pointCount, index } = cluster.properties;

            if (isCluster) {

                return (
                    <Marker
                        key={cluster.id}
                        latitude={latitude}
                        longitude={longitude}
                    >
                        <div
                            className="render-cluster-list-marker"
                            style={{ color: "white" }}
                        >
                            <div
                                className="marker"
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: "rgb(40, 40, 40)"
                                }}
                            ></div>
                            <p>{pointCount}</p>
                        </div>
                    </Marker>
                )
            }

            return (
                <Marker
                    key={cluster.properties.id}
                    latitude={latitude}
                    longitude={longitude}
                    onClick={(e) => {
                        e.originalEvent.stopPropagation();
                        setCurrent(list[index])
                        setPageNumber(0)
                    }}
                >
                    <img
                        width="32px"
                        style={{ cursor: "pointer", borderRadius: '50%' }}
                        src={top100}
                        alt="score_icon"
                    />
                </Marker>
            );
        })
    }

    return (
        <>
            {clusters && renderList()}
            {current && <Popup
                longitude={current.longitude ? current.longitude : current.lng}
                latitude={current.latitude ? current.latitude : current.lat}
                className={'top100-popup'}
                onClose={() => setCurrent(null)}
            >
                <div
                    className='info-box'
                >
                    <FontAwesomeIcon icon={faXmark} onClick={() => setCurrent(undefined)} style={{ position: 'absolute', right: '2%', top: "2%", cursor: 'pointer' }} />
                    <ul style={{ listStyle: "none" }}>
                        <li>Company: {current.info[0].Company}</li>
                        <li>Facility: {current.info[0].Facility}</li>
                        <li>Pollutant: {current.info[0].Chemical}</li>
                    </ul>
                </div>
                <a
                    style={{
                        color: '#4fc2d9',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        textAlign: "center"
                    }}
                    onClick={() => goTo(current, current.info)}>
                    Go to report
                </a>
            </Popup>}
        </>
    )
}

export default RenderMarkers