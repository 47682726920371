// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../fonts/Urbanist-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Urbanist';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); }

.report-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: .75rem;
  color: white;
  margin-right: 1rem; }
  .report-footer h3,
  .report-footer a {
    font-size: 1.25rem; }
  .report-footer a {
    color: #4fc32c;
    text-decoration: none; }
  .report-footer a:hover {
    text-decoration: underline; }
`, "",{"version":3,"sources":["webpack://./src/theme/_variables.scss","webpack://./src/features/reports/shared/ReportFooter.scss"],"names":[],"mappings":"AAqBA;EACE,uBAAuB;EACvB,4CAAmD,EAAA;;ACrBrD;EACI,aAAa;EACb,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,kBAAkB,EAAA;EANtB;;IAUQ,kBAAkB,EAAA;EAV1B;IAcQ,cAAc;IACd,qBAAqB,EAAA;EAf7B;IAmBQ,0BAA0B,EAAA","sourcesContent":["$primaryFontColor: #fff;\n$secundaryFontColor: #4fc2d9;\n$thirdFontColor: #0b1736;\n$topMenuBackgroundColor: #0b1736;\n$sideBackgroundColor: #002044;\n$sideSubMenuBackgroundColor: #0b1736;\n$fontFamily: 'Urbanist';\n$leftMenuWidth: 5rem;\n$rightMenuWidth: 400px;\n$navBrandImageSize: 150px;\n$darkAndLightModeBackgroundColor: #12234f;\n$dropdownFiltersBackgroundColor: #060e21;\n$inputFocusBackgroundColor: #060e21;\n$containerScreenBackgroundColor: #050e25;\n$tableBackgroundColor: #0b1736;\n$secondarySubtitleColor: #838487;\n\n//FORMS\n$inputBackgroundColor: #021d3e;\n$btnPrimary: #257ee0;\n\n@font-face {\n  font-family: 'Urbanist';\n  src: url('../fonts/Urbanist-VariableFont_wght.ttf');\n}\n","@import '../../../theme/_variables.scss';\n\n.report-footer {\n    display: flex;\n    justify-content: flex-end;\n    width: 100%;\n    margin-top: .75rem;\n    color: white;\n    margin-right: 1rem;\n\n    h3,\n    a {\n        font-size: 1.25rem;\n    }\n\n    a {\n        color: #4fc32c;\n        text-decoration: none;\n    }\n\n    a:hover {\n        text-decoration: underline;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
