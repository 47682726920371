import { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { Layer, Source, Popup } from "react-map-gl";
import { CircleLayer } from "mapbox-gl";
import { RefContext } from "../../RefContext";
import { PointContext } from "../../PointContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ActivePopupContext } from "../../ActivePopupContext";
import agent from "../../api/agent";
import "./point-layer.scss";

const PointLayer = ({
    global,
    id,
    action,
    url,
    sourceLayer,
    circleColor,
    opacity,
    callValue,
    reportRoute
}: any) => {

    const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);
    const { setCurrentPoint } = useContext<any>(PointContext);
    const { currentRef } = useContext<any>(RefContext);

    const ref = currentRef?.current;
    const lngLat = action?.lngLat;
    const [features, setFeatures] = useState<any>(null);
    const [callValuesAndFeatures, setCallValuesAndFeatures] = useState<any[]>([]);
    const [wellData, setWellData] = useState<any>()
    const navigate = useNavigate();

    const handleRadius = (zoom: number) => (zoom <= 3 ? 5 : zoom <= 7 ? 5 : zoom <= 9 ? 7 : 9);

  //   const fetchAllWellData = useCallback(async (features: any[]) => {
  //     const api = agent.Reports.OSUWellsGetData;
  //     global.setLoading(true);
  
  //     try {
  //         const data = await Promise.all(features.map(({ properties: { gw_logid } }) => api('lithology', gw_logid)));
  
  //         // Combine the API data with their respective coordinates
  //         const dataWithCoordinates = data.map((item, index) => ({
  //             ...item,
  //             coordinates: features[index].coordinates,  // Attach coordinates from features
  //             gw_logid: features[index].properties.gw_logid, // Attach the gw_logid for reference
  //         }));
  //         setWellData(dataWithCoordinates)
  //         console.log("Fetched Data with Coordinates:", dataWithCoordinates);
  //     } finally {
  //         global.setLoading(false);
  //     }
  // }, []);

    // useEffect(() => {
    //     if (callValuesAndFeatures.length > 0) {
    //         fetchAllWellData(callValuesAndFeatures);
    //     }
    // }, [callValuesAndFeatures, fetchAllWellData]);

    const getLayerData = useCallback(() => {
        if (ref) {
            const loadedFeatures = ref.querySourceFeatures(id, { sourceLayer });
            const features = loadedFeatures.map(({ geometry, properties }: any) => ({
                coordinates: geometry.coordinates,
                properties
            }));
            setCallValuesAndFeatures(features);
        }
    }, [id, ref, sourceLayer]);

    useEffect(() => {
        if (ref) {
            ref.on('idle', getLayerData);
            return () => ref.off('idle', getLayerData);
        }
    }, [ref, getLayerData]);

    const CircleVectorLayer: CircleLayer = useMemo(() => ({
        id: `${id}-layer`,
        type: "circle",
        "source-layer": sourceLayer,
        paint: {
            "circle-radius": handleRadius(global.viewport.zoom),
            "circle-opacity": opacity,
            "circle-color": circleColor,
        },
    }), [id, sourceLayer, global.viewport.zoom, opacity, circleColor]);

    const goToReport = useCallback((lat: number, lng: number, input: string) => {
        setCurrentPoint({ lat, lng, id: input });
        setTimeout(() => navigate(reportRoute), 1000);
    }, [navigate, reportRoute, setCurrentPoint]);

    const getFeaturesAtPoint = useCallback((event: any) => {
        const res = ref?.queryRenderedFeatures(event.point, { layers: [`${id}-layer`] })?.[0];
        if (res?.properties) {
            setFeatures(res.properties);
        }
    }, [id, ref]);

    useEffect(() => {
        if (ref) {
            ref.on('click', getFeaturesAtPoint);
            return () => ref.off('click', getFeaturesAtPoint);
        }
    }, [ref, getFeaturesAtPoint]);

    const memoizedPopup = useMemo(() => {
        if (!features || !lngLat) return null;

        const handleClose = () => {
            setFeatures(null);
            setActivePopup({ id: null, content: null });
        };

        return (
            <Popup
                longitude={lngLat.lng}
                latitude={lngLat.lat}
                onClose={handleClose}
                className="circledata-popup"
            >
                <FontAwesomeIcon className="close-btn" icon={faXmark} onClick={handleClose} />
                {Object.entries(features).map(([key, value]: any) => (
                    <div key={key}>{key}<strong>:</strong> {value}</div>
                ))}
                <a
                    className="report-anchor"
                    onClick={() => goToReport(lngLat.lat, lngLat.lng, features[callValue])}
                >
                    Go to report
                </a>
            </Popup>
        );
    }, [features, lngLat, goToReport, callValue, setActivePopup]);

    useEffect(() => {
        if (memoizedPopup && activePopup.id !== id) {
            setActivePopup({ id, content: memoizedPopup });
        }
    }, [memoizedPopup, activePopup, setActivePopup, id]);

    return (
        <Source id={id} type="vector" url={url}>
            <Layer {...CircleVectorLayer} />
            {activePopup && activePopup.id === id && activePopup.content}
        </Source>
    );
};

export default PointLayer;
