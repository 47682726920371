import { useEffect, useState, useRef, useCallback, useContext, useMemo, Ref } from 'react';
import { Button, OverlayTrigger, Tab, Tabs, Dropdown, Row, Col, Card, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { Tooltip as TooltipRB, Form, Modal, Carousel } from 'react-bootstrap';
import { unparse } from 'papaparse';
import agent from '../../../api/agent';
import LoadingDataAnimation from '../../../components/LoadingDataAnimation';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import fullscreenIcon from '../../../assets/fullscreen-icon.svg';

import html2canvas from 'html2canvas';
import localForage from "localforage";

import * as d3Format from 'd3-format';
import { DateTime } from 'luxon';
import { formatRowValue } from '../../utils';
import { AppContext } from '../../../AppContext';
import { CatalogsContext } from '../../../CatalogsProvider';

import ReportsConfigs from '../../../ReportsConfigs';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import ReportTableExpanded from '../shared/ReportTableExpanded';
import { HeaderProps } from 'react-table';
import { RefContext } from '../../../RefContext';

import { putReport, setLocalReport, getCountryFromStorage } from '../../utils';
import { Location, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown, faFileExport, faFloppyDisk, faGear, faCancel, faRectangleXmark, faRotate, faTrashCan, faList, faTableCellsLarge } from '@fortawesome/pro-solid-svg-icons';
import { mapLayersOff } from '../../utils';

import StepZilla from "react-stepzilla";

import './styles.scss';


export default function WorkControl(props: any) {
  const { global } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);
  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;
  const navigate = useNavigate();
  const selectedHUC8Value = appContext.selectedHUC8;
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const [isFullScreenActive, setFullScreenActive] = useState(false);
  const fullscreenContainerHandler = () => { setFullScreenActive(!isFullScreenActive); };
  const [isDataReportLoaded, setIsDataReportLoaded] = useState(false);

  const [tableData, setTableData] = useState<any[]>([]);
  const [savedData, setSavedData] = useState<any[]>([]);
  const [currentDashboard, setCurrentDashboard] = useState<string>('');

  const nowTime = DateTime.local().toFormat('_ydL_Hms_');
  const nowCity = appContext.currentLocation;

  const Step = () => {return (<></>);};
  const Start = () => {return (<></>);};
  const Complete = () => {return (<></>);};

  const [steps, setSteps] = useState<any[]>([
    { name: 'Start', component: <Start /> }
  ]);
  const [currentStep, setCurrentStep] = useState<any>(0);
  const [Hide, setHide] = useState<any>(true);

  useEffect(() => {
    setCurrentDashboard(global.selectedDashboardProject);
  }, [global.selectedDashboardProject]);

  useEffect(() => {
  
    return () => {
        
    }
  }, [])

  useEffect(() => {
    const tData = global.selectedDashboardProjectLinks;
    if(global.selectedDashboardProjectLinks && tData.length>0){
      setSavedData(tData);
      generateSteps(tData); 
    }
  }, [global.selectedDashboardProjectLinks]);

  const generateSteps = (tData:any) => {
    const newSteps = [];
    if(tData){
      for (var i = 0; i < tData.length; i++) {
        newSteps.push({ name: getReportLabel(tData[i].reportname), url: tData[i].url, component: <Step />  })
      }
      newSteps.push({ name: 'Review and Print', url: '/WorkStart', component: <Complete /> });
      setSteps(newSteps);
    }
  };  

  const getReportLabel = (reportname:string) => {
    const reportLabel = ReportsConfigs.find(x => x.route.substr(1) == reportname)?.name
    if(reportLabel){
      return reportLabel;
    } else {
      return reportname;
    }
  }

  const onStepChange = (event:any) => {
    let dashboardid = currentDashboard;
    let cStep = +currentStep;
    saveReportItem(dashboardid, cStep);

    setCurrentStep(event);
    mapLayersOff(global);
    //const dashboardIndex = savedData[event].dashboard_id;
    const stepIndex = event;
    let dData = [...global.dashboardProjects];
    let fData = dData.filter((o: any) => +o.dashboard_id === +dashboardid);
    
    if(fData && fData.length > 0 && savedData && savedData.length>0 && dashboardid){
      if(savedData[stepIndex ] && savedData[stepIndex].timestamp){

        fData[0].currentStep = savedData[stepIndex].timestamp;
        global.setDashboardProjects(dData);
       
      } else {
        fData[0].currentStep = '1111';
      }
    } else {
      fData[0].currentStep = '1111';
    }
   
    if(savedData && savedData[stepIndex] && savedData[stepIndex].url){
      const rData = savedData[stepIndex];

      if (appContext && appContext.updateContext) {         
        const reportConfig = ReportsConfigs.find(x => x.route == rData.pathname);
        let _routeData = {
          name: "Global Live Water Quality Map",
          parentReportName: "Home",
          root: '/'
        } 
        if(reportConfig && reportConfig.parentReportName){
          _routeData = {
            name: reportConfig.name,
            parentReportName: reportConfig.parentReportName,
            root: reportConfig.route
          }
        }

        setLocalReport(rData.pathname);
        appContext.updateContext(rData.huc, rData.currentCity, _routeData, getCountryFromStorage());
        global.setSelectedHUC8(rData.huc)
      } 

      let nURL = savedData[stepIndex].url;
      if(nURL.indexOf('&r=0') > -1){
        nURL = nURL.replace('&r=0', '&r=1');
      } else {
        nURL = nURL + '&r=1';
      }
  
      try {
        setTimeout(() => {
          navigate(nURL)
        }, 400)
      } catch (err) {
        console.error(err);
      }
      
    } else {
      navigate('/WorkStart')
    }
  };

  const saveReportItem = (dashboardid:any, cStep:any) => {
    const timestamp = DateTime.local().toMillis();
    const newLoc = location.search;
    if (savedData[cStep]){
      const cs = savedData[cStep];
      const originalTimestamp = cs.timestamp;
      const stepLoc = cs.search;
        if ((stepLoc && newLoc &&  stepLoc !== newLoc)||(!cs.workflow)){
          let reportLink_ = {parent: dashboardid, timestamp : timestamp, currentCity: appContext.currentLocation,
             huc: appContext.selectedHUC8, pathname: location.pathname,
              search: location.search, hash: location.hash, sortkey: cs.sortkey, workflow: true};
          let payload = {REPORT_ID: cs.report_id, REPORT_DATA: JSON.stringify(reportLink_), SUBTITLE:""};
          if(location.pathname !== '/WorkStart'){
            agent.Utilities.UpdateReport(payload).then((res: any) => {
              console.log("SetReport", payload, res);   
            })  
          }
          saveToDashboardPng('' + timestamp);  

          localForage.removeItem(''+originalTimestamp, function (err:any) {
            console.log("onStepChange_removeItem", originalTimestamp, err); 
          }); 
        }
    }
  }

  const saveToDashboardPng = (timestamp:string) => {
    const reportElement = document.getElementById(props.reportID);
    if(reportElement){
      setTimeout(() => {
        const aspectRatio = reportElement.offsetHeight/reportElement.offsetWidth;
        const aspectWidth = Math.min(reportElement.offsetWidth, 480);
        const aspectHeight = Math.min(reportElement.offsetHeight, 480*aspectRatio);          
        html2canvas(reportElement).then(canvas => {
          if(canvas){
            canvas.toBlob((blob) => {
              localForage.setItem(timestamp, blob);
            });      
          }
        });
      }, 400);
    }
  }

  const onHide = (event:any) => {
    if(currentStep){
      let dashboardid = currentDashboard;
      try {
        saveReportItem(dashboardid, currentStep);
      } catch (e) {
        console.log('Error: ' + e)
      }
    }

    global.setShowWorkflow(false);
  }; 

  useEffect(() => {
    setHide(global.showWorkflow);
  }, [global.showWorkflow]);


  return (
    <>
        <div className='Workflow' id='WorkControl-report' style={(!Hide) ? {display: 'none'} : {display: 'flex'}  }>
              <div className='workzilla-container'  key={'workzilla-container'+steps.length+global.showWorkflow}>
                <StepZilla steps={steps} onStepChange={onStepChange} startAtStep={currentStep} />
              </div>
              <div>
                  <Button
                    type='button'
                    className='btn btn-default btn-circle dark-mode active'  
                    onClick={onHide}                          
                  >
                    <span style={{ pointerEvents: 'none' }} >Hide</span>
                  </Button>
              </div>
        </div>       

    </>
  );
}
