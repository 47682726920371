import { useContext } from 'react'
import { AppContext } from '../../AppContext'
import { Dropdown, Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faMapMarked } from '@fortawesome/pro-solid-svg-icons';
import { v4 as uuid } from 'uuid';
import { ReportMenuItem, ReportMenuGroup, ReportMenuGroups } from '../models';
import './styles.scss';
import settings from '../../settings.json';
import { putReport } from '../../features/utils'
import { Link, useNavigate } from 'react-router-dom';
import Routes from '../../ReportsRoutes';
import { OrganizationConfig } from '../../utils/OrganizationConfig';
import { resolveReportNameFromRespotsConfigs, checkReportRestrictions } from '../../features/utils';
import RiskCollection from '../../features/reports/Risk/RiskCollection';

const regex = /^(ftp|http|https):\/\/[^ "]+$/;

export default function Reports(props: any) {

  const { global } = props;
  const {
    currentCountry, userRole, userOrg, permitReports, reportRestrict, navTopMenu
  } = props.global;

  // TE user?
  let flagTE = false;
  if(global && global.userInfo && global.userInfo.email){
      const address = global.userInfo.email.split('@');
      if(address && address.length > 1 && address[1] === 'truelements.com'){
          flagTE = true;
      }
  }

  let defaultActiveKey = '0';
  let reportSelection = navTopMenu;
  if(props.reportSelection){
    reportSelection = props.reportSelection;
    defaultActiveKey = '0';
  }

  function checkReportPermissions(route: any) {
    return checkReportRestrictions(reportRestrict, route);
  }

  let reportsGroup = new Array<ReportMenuGroup>();

  if (reportSelection === 'Risk') {
    const SASBGrouptitle = 'TrueQI Site Assessment Reporter';
    const AppleLocations = "Apple Locations"
    const SASBtitle = 'TrueQI Site Assessment';
    let SASBitems = [] as any;
    
    let riskColl = RiskCollection && RiskCollection.map((collection: any, index: number) => (
        (new ReportMenuItem(`${SASBtitle} - ${collection.label}`, `${Routes.Risk}?ent=1&inds=SVHL&rcl=${collection.code}`, checkReportPermissions('/SASB'), [], false, true))
    ));
    let riskMenu = [new ReportMenuItem('By Category', '', false, [], false, true)];

    let riskRepo = [
                  new ReportMenuItem(SASBtitle + ' - Services/Hospitality', Routes.Risk + '?ent=1&inds=SVHL', checkReportPermissions('/SASB'), [], false, true),
                  new ReportMenuItem(SASBtitle + ' - Food and Beverage/Agriculture', Routes.Risk + '?ent=1&inds=FBAG', checkReportPermissions('/SASB'), [], false, true),
    ];

    let riskR = RiskCollection && RiskCollection.map((collection: any, index: number) => (
        (new ReportMenuGroup(`${SASBtitle} - ${collection.label}`, '' + index, riskRepo))
    ));

    if (userRole === 'te-admin' || userOrg === 1 || flagTE) {
      reportsGroup.push(
        new ReportMenuGroup(SASBGrouptitle, '0', riskMenu.concat(riskColl, [
                  
                  new ReportMenuItem('divider', '', false, [], false, true),
                  new ReportMenuItem('By Industry and Sector', '', false, [], false, true),
                  new ReportMenuItem(SASBtitle + ' - Services/Hospitality', Routes.Risk + '?ent=1&inds=SVHL', checkReportPermissions('/SASB'), [], false, true),
                  new ReportMenuItem(SASBtitle + ' - Food and Beverage/Agriculture', Routes.Risk + '?ent=1&inds=FBAG', checkReportPermissions('/SASB'), [], false, true),
                  new ReportMenuItem('divider', '', false, [], false, true),
                  new ReportMenuItem('By Organization', '', false, [], false, true),
                  new ReportMenuItem(SASBtitle + ' - Hotel Chain', Routes.Risk + '?ent=1&ccds=nasdaq&inds=SVHL', checkReportPermissions('/SASB'), [], false),
                  new ReportMenuItem(SASBtitle + ' - RES', Routes.Risk + '?ent=1&ccds=rer&inds=SVHL', checkReportPermissions('/SASB'), [], false),
                  new ReportMenuItem(SASBtitle + ' - Apple', Routes.Risk + '?ent=1&ccds=apple&inds=SVHL', checkReportPermissions('/SASB'), [], false),
                  
                  new ReportMenuItem(SASBtitle + ' - Amane Advisors', Routes.Risk + '?ent=1&ccds=amane&inds=SVHL', checkReportPermissions('/SASB'), [], false),
                  new ReportMenuItem(SASBtitle + ' - WI Waterkeepers', Routes.Risk + '?ent=1&ccds=wiwqx&inds=SVHL', checkReportPermissions('/SASB'), [], false),
                  new ReportMenuItem(SASBtitle + ' - Pepsi', Routes.Risk + '?ent=1&ccds=pepsi&inds=SVHL', checkReportPermissions('/SASB'), [], false),

                  new ReportMenuItem(SASBtitle + ' - Straumann Holdings', Routes.Risk + '?ent=1&ccds=straumann&inds=SVHL', checkReportPermissions('/SASB'), [], false),
                  new ReportMenuItem(SASBtitle + ' - Tetra Tech', Routes.Risk + '?ent=1&ccds=tetra&inds=SVHL', checkReportPermissions('/SASB'), [], false),
                  new ReportMenuItem(SASBtitle + ' - Veolia', Routes.Risk + '?ent=1&ccds=veolia&inds=SVHL', checkReportPermissions('/SASB'), [], false),

                ]), true)
      );
     // global.AppleLocationData &&  
    // reportsGroup.push(new ReportMenuGroup(AppleLocations, '0', [
    //     new ReportMenuItem(SASBtitle + ' - Apple Locations', Routes.Risk + '?ent=1&ccds=apple_locs&inds=SVHL', checkReportPermissions('/SASB'), [], false)
    //   ], true))
    } else if (userOrg === 0) {
     reportsGroup.push(
      new ReportMenuGroup(SASBGrouptitle, '0', [
          new ReportMenuItem(SASBtitle, Routes.Risk + '?ent=1', checkReportPermissions('/SASB'), [], false),
          new ReportMenuItem(SASBtitle + ' - NASDAQ', Routes.Risk + '?ent=1&ccds=nasdaq&inds=SVHL', checkReportPermissions('/SASB'), [], false),
      ], true)
    );
    } else {
      const qOrganizationConfig = OrganizationConfig.find(x => x.org_id === userOrg);
      if(qOrganizationConfig && qOrganizationConfig.ccds){
        reportsGroup.push(
          new ReportMenuGroup(SASBGrouptitle, '0', [
            new ReportMenuItem(SASBtitle, Routes.Risk + '?ent=1&inds=SVHL', checkReportPermissions('/SASB'), [], false),
            new ReportMenuItem(SASBtitle + ` - ${qOrganizationConfig.org_label}`, Routes.Risk + `?ent=1&inds=SVHL&ccds=${qOrganizationConfig.ccds}`, checkReportPermissions('/SASB'), [], false),
          ], true)
        ); 
      } else {
        reportsGroup.push(
        new ReportMenuGroup(SASBGrouptitle, '0', [
          new ReportMenuItem(SASBtitle, Routes.Risk + '?ent=1&inds=SVHL', checkReportPermissions('/SASB'), [], false),
        ], true)
      );       
      }

     
    }

  } else if (reportSelection === 'Climate') {
    reportsGroup.push(
      new ReportMenuGroup('TrueQI Scenario Forecaster', '0', [
        new ReportMenuItem('Climate Precipitation Calculator', Routes.ClimatePrecipitationCalculator + ((userOrg === 9) ? '?ccds=rer' : ''), checkReportPermissions(Routes.ClimatePrecipitationCalculator)),
        new ReportMenuItem('Riverine Stress', Routes.GroundwaterDetermination, checkReportPermissions(Routes.GroundwaterDetermination)),
      ])
    );
  } else {
    // Manuel's Branch FIB Sampling Data and Trends

    const USSWReports = currentCountry === 'United States' ? [
    new ReportMenuItem('AG Water True QI Forecast', Routes.AGWaterTrueQIForecast, checkReportPermissions(Routes.AGWaterTrueQIForecast)),
      new ReportMenuItem("Manuel's Branch FIB Sampling Data and Trends", Routes.FIBReport, checkReportPermissions(Routes.FIBReport)),
      new ReportMenuItem("Climate and Economic Justice Screening Tool", Routes.CEJST, checkReportPermissions(Routes.CEJST)),
      new ReportMenuItem('National Stream Quality Accounting Network', Routes.NationalStreamQualityAccountingNetwork, checkReportPermissions(Routes.NationalStreamQualityAccountingNetwork)),
      new ReportMenuItem(resolveReportNameFromRespotsConfigs(Routes.LiveWaterSensors),Routes.LiveWaterSensors, checkReportPermissions(Routes.LiveWaterSensors)),
      new ReportMenuItem('What’s in my Watershed?', Routes.WhatsInMyWatershed, checkReportPermissions(Routes.WhatsInMyWatershed)),
      // new ReportMenuItem('Water Conflict', Routes.WaterConflict),
      new ReportMenuItem('National Storm Water Quality Database 2020', Routes.NationalStormWaterQualityDatabase, checkReportPermissions(Routes.NationalStormWaterQualityDatabase)),
      new ReportMenuItem('Surface Water Flow Rate', Routes.SurfaceWaterFlowRate, checkReportPermissions(Routes.SurfaceWaterFlowRate)),
      new ReportMenuItem('Daily Lake Mead Levels', Routes.DailyLakeMeadLevelsReport, checkReportPermissions(Routes.DailyLakeMeadLevelsReport)),
      new ReportMenuItem('Permit Discharge Report', Routes.PermitDischargeReport, checkReportPermissions(Routes.PermitDischargeReport)),
      new ReportMenuItem('Facility Registry System/Service', Routes.FacilityRegistrySystem, checkReportPermissions(Routes.FacilityRegistrySystem)),
      new ReportMenuItem('Toxics Release Inventory', Routes.ToxicsReleaseInventoryReport, checkReportPermissions(Routes.ToxicsReleaseInventoryReport)),
      new ReportMenuItem('GFS Weather Forecast & Historical', Routes.GFSHuc12Forecast, checkReportPermissions(Routes.GFSHuc12Forecast)),
      new ReportMenuItem('Storm Water True QI Forecast', Routes.StormWaterTrueQIForecast, checkReportPermissions(Routes.StormWaterTrueQIForecast)),
      new ReportMenuItem('Discharge Monitoring Report', Routes.DischargeMonitoring, checkReportPermissions(Routes.DischargeMonitoring)),
      new ReportMenuItem('EPA Major Permit Holders', Routes.EPAMajorPermitHolders, checkReportPermissions(Routes.EPAMajorPermitHolders)),
      new ReportMenuItem('Chemical Data Reporting', Routes.ChemicalDataReporting, checkReportPermissions(Routes.ChemicalDataReporting)),
      new ReportMenuItem('Gemstat Raw', Routes.MenuGemstatRaw, checkReportPermissions(Routes.MenuGemstatRaw)),
    ] : []

    const MexicoUSSWReports = currentCountry === 'Mexico' ? [
      new ReportMenuItem('Surfacewater Mexico', Routes.SurfacewaterMexico),
      new ReportMenuItem('Groundwater Mexico', Routes.GroundwaterMexico),
    ] : []

    if (currentCountry === 'United States') {
      reportsGroup.push(
        new ReportMenuGroup('Surface Water', '0', USSWReports)
      );
    }

    if (currentCountry === 'Mexico') {
      reportsGroup.push(
        new ReportMenuGroup('Surface Water', '0', MexicoUSSWReports)
      );
    }

    const USDWReports = currentCountry === 'United States' ? [
      new ReportMenuItem('Drinking Water True QI Report', Routes.DrinkingWaterTrueQIReport, checkReportPermissions(Routes.DrinkingWaterTrueQIReport)),
      new ReportMenuItem('Risk Screening Environmental Indicators', Routes.RiskScreening, checkReportPermissions(Routes.RiskScreening)),
      new ReportMenuItem('Groundwater Levels', Routes.GroundwaterLevels, checkReportPermissions(Routes.GroundwaterLevels)),
      new ReportMenuItem(
        "What's in my Drinking Water? (UCMR 1-5)",
        Routes.WhatsInMyDrinkingWater, checkReportPermissions(Routes.WhatsInMyDrinkingWater)
      ),
    ] : []

    reportsGroup.push(
      new ReportMenuGroup('Drinking Water or Water Treatment', '1', USDWReports)
    );

    reportsGroup.push(
      new ReportMenuGroup('PFAS', '3', [
        new ReportMenuItem('PFAS Environmental', Routes.PfasEnvironmental, checkReportPermissions(Routes.PfasEnvironmental)),
        new ReportMenuItem('PFAS Drinking Water', Routes.PfasDrinkingWater, checkReportPermissions(Routes.PfasDrinkingWater)),
        new ReportMenuItem('PFAS Production', Routes.PfasProduction, checkReportPermissions(Routes.PfasProduction)),
      ])
    );

    let betaLinks = [
      new ReportMenuItem('Algae Bloom Predictor', Routes.AlgaeBloomPrediction, checkReportPermissions(Routes.AlgaeBloomPrediction)),
      new ReportMenuItem('Carbon Sequestration Modeler', Routes.CarbonSequestration, checkReportPermissions(Routes.CarbonSequestration)),
      new ReportMenuItem('3D River Runner', `${settings.climateAppURL}/river_runner`, checkReportPermissions('/river_runner')),
    ]

    if (userRole === 'te-admin') {
      betaLinks.push(new ReportMenuItem('Chat (development)', Routes.Chat, checkReportPermissions(Routes.Chat)))
    }

    reportsGroup.push(
     new ReportMenuGroup('Beta Links', '4', betaLinks)
    )   
  }

  if(props.noDropDown){
    return (<ReportGroups items={reportsGroup} />);
  }

  return (
    <Dropdown className='reports-sidebar' drop={'end'}>
      <Dropdown.Toggle id='dropdown-basic' className='sidebar-dropdown' >
        <FontAwesomeIcon icon={faChartLine} className='mr-2' />
        Reports
      </Dropdown.Toggle>
      <Dropdown.Menu  className='safari_only safari_only_m'>
        <div className='title'>
          <FontAwesomeIcon icon={faChartLine} className='mr-2' /> Reports
        </div>
        <hr />
        <ReportGroups items={reportsGroup} />
      </Dropdown.Menu>
    </Dropdown>
  );
}

function ReportItem(props: ReportMenuItem) {

  const { name, parentReportName, root } = props

  const {
    currentLocation,
    selectedHUC8,
    updateContext
  } = useContext(AppContext)

  const navigate = useNavigate()
  const search = window.location.search;

  const handleClick = (event: any) => {
    event.preventDefault()

    document.dispatchEvent(new MouseEvent('click'))
    putReport({ name, parentReportName, root })
    return navigate(root)
  }

  if (props.disabled) {
    return (
      <a
        style={{ cursor: 'default' }}
        className='report-link report-link-disabled'
      >
        {'•  ' + props.name}
      </a>)
  }

  if (name === 'divider') {
    return (<Dropdown.Divider />)
  }

  if (name.substr(0, 2) === 'By') {
    return (<span style={{marginLeft: 17, fontSize:'1em', paddingBottom: 34}}>{name.substr(3)}</span>)
  }
  
  let childItems = [] as any;
  if (props.items) {
    let sortedItems = props.items
    if (!props.nosortitems) {
      sortedItems = props.items.sort(function (a, b) {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      })
    }
    const items = sortedItems.map((item: any) => {
      return <ReportItem key={uuid()} {...item} />;
    });
    childItems = items;
  }

  return (
    <>
      {regex.test(props.root) ? (
        <a
          target={'_blank'}
          href={props.root}
          className='report-link'
          onClick={e => document.dispatchEvent(new MouseEvent('click'))}
        >
          {'•  ' + props.name}
        </a>
      ) : (
        <a
          style={props.ident ? { marginLeft: 40, cursor: 'pointer' } : { cursor: 'pointer' }}
          className='report-link'
          onClick={(event) => handleClick(event)}
        >
          {'•  ' + props.name}
        </a>
      )}
      {childItems ? (childItems) : ""}
    </>
  );
}

function ReportGroup(props: ReportMenuGroup) {
  let sortedItems = props.items;
  if (!props.nosortitems) {
    sortedItems = props.items.sort(function (a, b) {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    })
  }

  const items = sortedItems.map((item: any) => {
    return <ReportItem key={uuid()} {...item} />;
  });

  return (  
    <Accordion.Item eventKey={props.dkey} key={uuid()}>
      <Accordion.Header>{props.name}</Accordion.Header>
      <Accordion.Body>{items}</Accordion.Body>
    </Accordion.Item>
  );
}

function ReportGroups(props: ReportMenuGroups) {
  const groups = props.items.map(item => {
    return <ReportGroup key={uuid()} {...item} />;
  });

  return <Accordion defaultActiveKey='0'>{groups}</Accordion>;
}