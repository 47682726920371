import { useState, useMemo } from 'react';
import { Marker } from 'react-map-gl';
import useSupercluster from 'use-supercluster';
import SWPopup from './SWPopup';
import '../water-quality-list/water-quality-list.scss'

const SurfaceWaterMarkers = (props: any) => {
    const { list, bounds, viewport, global } = props
    const [current, setCurrent] = useState<any | null>(null)


    const points = useMemo(() => list && typeof list==='object' && list.map((point: any, index: number) => ({
        type: "Feature",
        properties: {
            cluster: false,
            index,
            score: point['Overall Surface Water Score'],
            id: `cluster-${index}`,
        },
        geometry: {
            type: "Point",
            coordinates: [point.Lng, point.Lat],
        }
    })), [list])

    const renderAverage = (input: any) => {
        const sum: any = input.reduce((accumulator: any, point: any) => {
            return parseInt(accumulator) + parseInt(point.properties.score)
        }, 0)
        return Math.round(sum / input.length)
    }

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom: viewport.zoom,
        options: { radius: 100, maxZoom: 7 }
    })

    const renderList = () => {
        return clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates
            const { cluster: isCluster, point_count: pointCount, index, score } = cluster.properties;

            if (isCluster) {

                const pointData = supercluster?.getLeaves(parseInt(cluster.id!.toString()))
                const roundedScore = Math.round(renderAverage(pointData))

                return (
                    <Marker
                        key={cluster.id}
                        latitude={latitude}
                        longitude={longitude}
                    >
                        <div
                            className="cluster-list-marker surface-water"
                        >
                            <img
                                width="36px"
                                style={{
                                    cursor: "pointer",
                                    border: '3px solid #0692AE',
                                    borderRadius: "50%"
                                }}
                                src={`https://te-v3-assets.s3.us-west-2.amazonaws.com/${roundedScore}.svg`}
                                alt="score_icon"
                            />
                        </div>
                    </Marker>
                )
            }

            return (
                <Marker
                    key={cluster.properties.id}
                    latitude={latitude}
                    longitude={longitude}
                    onClick={(e) => {
                        e.originalEvent.stopPropagation();
                        setCurrent(list[index])
                    }}
                >
                    <img
                        width="28px"
                        style={{
                            cursor: "pointer",
                            border: '3px solid #0692AE',
                            borderRadius: "50%"
                        }}
                        src={`https://te-v3-assets.s3.us-west-2.amazonaws.com/${Math.round(score)}.svg`}
                        alt="score_icon"
                    />
                </Marker>
            );
        })
    }

    return (
        <>
            {clusters && renderList()}
            {current && <SWPopup
                global={global}
                current={current}
                setCurrent={setCurrent}
            />}
        </>
    )
}

export default SurfaceWaterMarkers