// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wqp {
  max-width: 599px !important; }
  .wqp .mapboxgl-popup-content {
    padding: 5px !important;
    border-radius: 4px;
    width: 100% !important;
    background-color: #18244c;
    padding-bottom: 20px; }
  .wqp .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border-bottom-color: #050E25;
    opacity: .8; }
  .wqp .popup-container {
    opacity: .95;
    font-size: 1.2em;
    font-family: "Urbanist";
    display: flex;
    padding-top: 14px;
    padding-bottom: 32px;
    padding-left: 10px;
    padding-right: 4px;
    height: 259px !important;
    min-width: 390px !important;
    line-height: 1.5em !important;
    min-height: 200px !important;
    max-height: 40vh;
    width: max-content;
    justify-content: flex-start !important;
    text-align: left !important;
    align-content: center !important;
    flex-direction: column !important;
    align-items: start !important;
    overflow-y: auto !important; }
  .wqp .popup-content-highlight {
    color: #49b4cd; }
`, "",{"version":3,"sources":["webpack://./src/components/Layers/WaterQualityPortal/WaterQualityPortal.scss"],"names":[],"mappings":"AACA;EACE,2BAA2B,EAAA;EAD7B;IAII,uBAAuB;IACvB,kBAAkB;IAClB,sBAAsB;IACtB,yBAAyB;IACzB,oBAAoB,EAAA;EARxB;IAYI,4BAA4B;IAC5B,WAAW,EAAA;EAbf;IAiBI,YAAY;IACZ,gBAAgB;IAChB,uBAAuB;IAEvB,aAAa;IAEb,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,kBAAkB;IAElB,wBAAwB;IACxB,2BAA2B;IAC3B,6BAA6B;IAC7B,4BAA4B;IAC5B,gBAAgB;IAChB,kBAAkB;IAElB,sCAAsC;IACtC,2BAA2B;IAC3B,gCAAgC;IAChC,iCAAiC;IACjC,6BAA6B;IAC7B,2BAA2B,EAAA;EAxC/B;IA4CI,cAAc,EAAA","sourcesContent":["\n.wqp {\n  max-width: 599px !important;\n\n  .mapboxgl-popup-content {\n    padding: 5px !important;\n    border-radius: 4px;\n    width: 100% !important;\n    background-color: #18244c;\n    padding-bottom: 20px;\n  }\n\n  .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {\n    border-bottom-color: #050E25;\n    opacity: .8;\n  }\n\n  .popup-container {\n    opacity: .95;\n    font-size: 1.2em;\n    font-family: \"Urbanist\";\n   \n    display: flex;\n\n    padding-top: 14px;\n    padding-bottom: 32px;\n    padding-left: 10px;\n    padding-right: 4px;\n \n    height: 259px !important;\n    min-width: 390px !important;\n    line-height: 1.5em !important;\n    min-height: 200px !important;\n    max-height: 40vh;\n    width: max-content;\n\n    justify-content: flex-start !important;\n    text-align: left !important;   \n    align-content: center !important;   \n    flex-direction: column !important;\n    align-items: start !important;\n    overflow-y: auto !important;\n  }\n\n  .popup-content-highlight {\n    color: #49b4cd;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
