import React, { useEffect, useState, useContext, useMemo, memo, useCallback } from 'react';
import { RefContext } from "../../RefContext";
import { PointContext } from "../../PointContext";
import { Layer, Source, Popup } from 'react-map-gl';
import { adjustHexBrightness } from "../../features/utils";
import { FillLayer, LineLayer } from 'mapbox-gl';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { Col, Nav } from 'react-bootstrap';
import { ActivePopupContext } from '../../ActivePopupContext';

const Hydrobasins = ({ id, global, HydrobasinsLevel, opacity, tileSet, HydrobasinsKind, source, brightness, action }: any) => {
  
  const [popupData, setPopupData] = useState<any>(null);
  const { currentRef } = useContext(RefContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);

  // @ts-ignore
  const ref = currentRef?.current;
  const navigate = useNavigate();

  const lngLat = action?.lngLat;

  // Memoized styles to avoid recalculations
  const kind = useMemo(() => 
    HydrobasinsKind === "standard"
      ? ["standard", adjustHexBrightness("#dd660d", brightness)]
      : ["custom", adjustHexBrightness("#31aa9a", brightness)],
    [HydrobasinsKind, brightness]
  );

  // Memoized Fill and Line layers
  const HydrobasinsFill: FillLayer = useMemo(() => ({
    id,
    type: "fill",
    "source-layer": source,
    minzoom: 6.25,
    paint: {
      "fill-opacity": opacity,
      'fill-color': [
        "match",
        ["get", "kind"],
        ...kind,
        'transparent',
      ],
    },
    filter: ['in', "level", parseInt(HydrobasinsLevel)],
  }), [id, source, opacity, kind, HydrobasinsLevel]);

  const HydrobasinsLine: LineLayer = useMemo(() => ({
    id: `${id}-outline`,
    type: "line",
    "source-layer": source,
    minzoom: 6.25,
    paint: {
      "line-color": "#000000",
      "line-width": 3,
    },
    filter: ['in', "level", parseInt(HydrobasinsLevel)],
  }), [id, source, HydrobasinsLevel]);

  // Function to query map features
  const getFeaturesAtPoint = useCallback(async (map: any, event: any) => {
    const features = await map.queryRenderedFeatures(event.point)?.[0];
    if (features?.properties) {
      setPopupData(features.properties);
    }
  }, []);

  // Function to navigate to the report
  const goToReport = useCallback((input: any[], lat: any, lng: any) => {
    setCurrentPoint({ ...input, lat, lng });
    setTimeout(() => navigate('/HydrobasinsReport'), 1000);
  }, [setCurrentPoint, navigate]);

  // Cleanup effect for the map click event
  useEffect(() => {
    if (ref) {
      const handleClick = (e: any) => getFeaturesAtPoint(ref, e);
      ref.on('click', handleClick);
      return () => ref.off('click', handleClick);
    }
  }, [ref, getFeaturesAtPoint]);

  // Popup handling
  useEffect(() => {
    if (popupData && lngLat) {
      const { lat, lng } = lngLat;

      const popupElement = (
        <Popup longitude={lng} latitude={lat} onClose={() => setPopupData(null)}>
          <FontAwesomeIcon className="close-btn" icon={faXmark} onClick={() => setPopupData(null)} />
          <Col className="col-12 d-flex justify-center flex-column">
            <Nav.Link
              style={{ color: "#4fc2d9", textDecoration: "underline" }}
              onClick={() => goToReport(popupData, lat, lng)}
            >
              Go to Report
            </Nav.Link>
          </Col>
        </Popup>
      );

      setActivePopup({ id, content: popupElement });
    }
  }, [popupData, lngLat, goToReport, id, setActivePopup]);

  return (
    <>
      <Source id={id} type="vector" url={tileSet}>
        <Layer beforeId={`${id}-outline`} {...HydrobasinsFill} />
        <Layer {...HydrobasinsLine} />
      </Source>
      {activePopup?.id === id && activePopup.content}
    </>
  );
};

export default memo(Hydrobasins);
