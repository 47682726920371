
import { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import banner from '../../../assets/chatBanner.svg';
import json from '../../../settings.json';
import './chat-styles.scss'

const Chat = ({ global }: any) => {

    useEffect(() => {
        global.setUserLogged(true)
        global.setLoading(true)
        setTimeout(() => global.setLoading(false), 6000)
    }, [])


    useEffect((): any => {
        const reportsContainer = document.getElementById('reportsContainer')
        if (reportsContainer) {
            reportsContainer.style.height = "100%"

            return () => reportsContainer.style.height = "unset"
        }
    }, [])

    

    return (
        <Col className="col-12 chat-container">
            <Col className="col-12 header">
                <img src={banner} height="50px" alt="banner" />
            </Col>
            <Col className="col-12 iframe-wrapper">
                <iframe src={json.aiChatURL} width="100%" />
            </Col>
        </Col>
    )
};

export default Chat;