import React, { useEffect, useState } from 'react'
import LayerItem from './LayerItem'
import layerList from './LayerList'
import LayerSearch from './LayerSearch/LayerSearch';
import CustomLayerItem from './CustomLayerItem';
import { Dropdown, Row} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup, faGlobe, faUsers } from '@fortawesome/pro-solid-svg-icons'
import Accordion from 'react-bootstrap/Accordion';
import CL from '../../assets/CustomLayer.png'
import ISOs from '../../ISO.json';
import EvaluationLayers from "../../components/Layers/EvaluationLayers";
import { 
   getCustomLocationDataFromStorage,
   getCustomLayerNamesFromStorage
} from '../../features/utils'
import './layers.scss';   

const { flag } = require('country-emoji');

function Layers({ global }: any) {

   const {
      handleCustomLayer,
      handleCustomLayerSelected,
      customLayers, setCustomLayers,
      layerNames, setLayerNames,
      AppleLocationData, setAppleLocationData,
      userOrgString,
      hiddenData, 
   } = global

   const [currentLayer, setCurrentLayer] = useState();
   const [currentLayerList, setCurrentLayerList] = useState(layerList(global));

   useEffect(() => {
      const customLocationData = getCustomLocationDataFromStorage()
      const customLayerNames = getCustomLayerNamesFromStorage()

      setTimeout(() => {   
         if (customLocationData && customLayerNames) {
            setCustomLayers(customLocationData)
            setLayerNames(customLayerNames)
         }
      }, 500)
   }, []);

   const getISOCode = (input: string) => {
      const countries = Object.values(ISOs)
      const isos = Object.keys(ISOs)
      const countryIndex = countries.findIndex(country => country === input)

      return isos[countryIndex]
   }

   const countryCode = getISOCode(global.currentCountry)

   let countriesWithData = ["United States", "Philippines", "Mexico", "Indonesia", "Australia", "Pakistan", "Canada", "Singapore"];
   useEffect(() => {
      if (countriesWithData.length > 0) {
        global.setCountriesWithData(countriesWithData);
      }
    }, [global.setCountriesWithData]);

   let statesWithData = ["Ohio", "Oregon", "Indiana", "Texas", "Montana", "Florida"]
   useEffect(() => {
      if (statesWithData.length > 0) {
        global.setStatesWithData(statesWithData);
      }
    }, [global.setStatesWithData]);


   const handleUserLayers = (input: any) => {
      return input.map((layer: any, i: any) => {
         return <CustomLayerItem
            key={i}
            id={i + 1}
            img={CL}
            data={layer}
            name={`${layerNames[i]}`}
            setSelected={handleCustomLayer}
            selected={handleCustomLayerSelected(i + 1)}
         />
      })
   };

   useEffect(() => {
      //console.log('currentLayer', currentLayer)
      if(currentLayer){
         handleEvaluationChange(currentLayer)
         setCurrentLayer(undefined)
      }        
   }, [currentLayer]);

   const handleEvaluationChange = (evallayer: any) => {
      //console.log('handleEvaluation_', evallayer)

      if (evallayer) {
         let siteItem = EvaluationLayers && EvaluationLayers.find((item: any) => item.id === evallayer)
         if(siteItem){
            if(siteItem.options && !siteItem.currentOption && siteItem.options.length > 0){
              let optionItem = siteItem.options[0];
              siteItem.currentOption = optionItem;
            }
            global.setLayerMode({layer: 'EvaluationLayer', category: evallayer, site: siteItem });

            if(global.layerStatus){
                let newLayerStatus = [...global.layerStatus]
                let layerStat = newLayerStatus && newLayerStatus.find((item: any) => item.layerid === evallayer);
                if(layerStat){
                     layerStat.active = !layerStat.active;
                } else {
                   newLayerStatus.push({layer: evallayer, layerid: evallayer, category: evallayer, site: siteItem, active: true })
                }  
                global.setLayerStatus(newLayerStatus)         
            } else {
               global.setLayerStatus([{layer: evallayer, layerid: evallayer, category: evallayer, site: siteItem, active: true }])
            }
                       
         }
       }
   }

   const handleEvaluation = (evallayer: any) => {
      if (evallayer) {
         let siteItem = EvaluationLayers && EvaluationLayers.find((item: any) => item.id === evallayer)
         if(siteItem){
            setCurrentLayer(evallayer)          
         }
      }
   }

   const activeLayers = (evallayer: any) => {
      if(global.layerStatus && global.layerStatus[0]){
         let activeLayers = global.layerStatus.filter(( name : any) => name.active === true);
         if(activeLayers && activeLayers[0]){
              let siteItem = activeLayers.find((item: any) => item.id === evallayer)

              if(siteItem){
                 return true;
              }
         }
      } 
      return false;
   }

   const assignImage = (category: any) => {
      if (category==='Fire') {
         return require(`../../assets/layer/cat_${"fire"}.png`);
      } else if  (category==='Flood') {
         return require(`../../assets/layer/cat_${"flood"}.png`);
      } else if  (category==='Snow') {
         return require(`../../assets/layer/cat_${"snow"}.png`);
      } else if  (category==='Soil') {
         return require(`../../assets/layer/cat_${"soil"}.png`);
      } else if  (category==='Landcover') {
         return require(`../../assets/layer/cat_${"landcover"}.png`);
      } else  {
         return require(`../../assets/layer/cat_${"water"}.png`);
      }
   }  

   const layers: any = layerList(global);

   const assignLayers = () => {
      let evalLayers = [...EvaluationLayers];
      evalLayers.forEach((dataItem:any, index:number) => {
            if(!dataItem.country){dataItem.country="United States";}
            if(!dataItem.global){ dataItem.global = global;}
            if(!dataItem.img){dataItem.img = assignImage(dataItem.category) ;}
            if(!dataItem.org){dataItem.org = ['te-admin', 'True Elements'];}
            if(!dataItem.catagory){dataItem.catagory = 'Fire';}
            if(!dataItem.setSelected){dataItem.setSelected = function() { handleEvaluation(dataItem.id)}}     
            if(!dataItem.selected){dataItem.selected = global.layerStatus && global.layerStatus[0] && global.layerStatus.find(( name : any) => name.active === true && name.id === dataItem.id)}

            //if(!dataItem.label){dataItem.label = 'Fire';} function() { activeLayers(dataItem.id)}  global.layerStatus  function() { activeLayers(dataItem.id)}
      })

      let layerIt = evalLayers.map((layer: any, index:number) => (
            <LayerItem
            global={global}
            org={layer.org}
            indexed={true}
            catagory={layer.catagory}
            country={layer.country}
            state={layer.state || ''}
            img={layer.img}
            name={layer.name}
            selected={layer.selected}
            setSelected={layer.setSelected}
            inactive={layer.inactive ? layer.inactive : false}
            label={layer.label ? layer.label : ''}
          />
       ))          
       let combinedLayers = layers.concat(layerIt)
       setCurrentLayerList(combinedLayers) 
   }


   useEffect(() => {
      assignLayers();
   }, []);

   
   const renderLayersByProp = (layerProp: string, target: any) => {
      const list: any = currentLayerList.filter((layer: any) => { //
         //if(layer.props['org'].includes(userOrgString)) {
            return layer.props[layerProp] === target && layer.props['inactive'] !== true
         //}   
      })

      const handleList = (input: any) => {
         if (input.length > 0) {
           const rows = [];
           for (let i = 0; i < input.length; i += 3) {
             rows.push(input.slice(i, i + 3));
           }
     
           return (
             <>
               {rows.map((rowItems, rowIndex) => (
                 <Row key={rowIndex}>
                   {rowItems.map((item: any) => item)}
                 </Row>
               ))}
             </>
           );
         } else {
           return null;
         }
       };
       
       return handleList(list)
   }


   return (
      <Dropdown className='layers-sidebar' drop={'end'}>
         <Dropdown.Toggle active={global.openLayerMenu} id="dropdown-basic" className='sidebar-dropdown'>
            <FontAwesomeIcon icon={faLayerGroup} className="mr-2" />
            Layers
         </Dropdown.Toggle>
         <Dropdown.Menu>
            <LayerSearch global={global} layers={currentLayerList}/>
            <Accordion defaultActiveKey='0'>
               <Accordion.Item eventKey="0">
                  <Accordion.Header className="te-map-legend-accordion-header layers-header-primary-level">
                     <div className='title'><FontAwesomeIcon icon={faLayerGroup} className="mr-2" />Data by Country</div>
                  </Accordion.Header>
                  <Accordion.Body>
                     {global.currentCountry === "United States" && (
                        <Accordion>
                           <Accordion.Item eventKey="0">
                              <Accordion.Header className="te-map-legend-accordion-header layers-header-secondary-level">
                                 <div className='title'>{flag(countryCode)} United States</div>
                              </Accordion.Header>
                              <Accordion.Body>
                                 <Accordion>
                                    {global.currentState && (
                                     <Accordion.Item eventKey="0">
                                       <Accordion.Header className="te-map-legend-accordion-header">
                                          <div className='title'>State Specific Data</div>
                                       </Accordion.Header>
                                       <Accordion.Body>
                                       {global.currentState === "Florida" && (
                                             <Accordion>
                                                <Accordion.Item eventKey="0">
                                                   <Accordion.Header className="te-map-legend-accordion-header">
                                                      {global.currentState}
                                                   </Accordion.Header>
                                                   <Accordion.Body>
                                                      {renderLayersByProp("state", "Florida")}
                                                   </Accordion.Body>
                                                </Accordion.Item>
                                             </Accordion>
                                          )}
                                          {global.currentState === "Montana" && (
                                             <Accordion>
                                                <Accordion.Item eventKey="0">
                                                   <Accordion.Header className="te-map-legend-accordion-header">
                                                      {global.currentState}
                                                   </Accordion.Header>
                                                   <Accordion.Body>
                                                      {renderLayersByProp("state", "Montana")}
                                                   </Accordion.Body>
                                                </Accordion.Item>
                                             </Accordion>
                                          )}
                                          {global.currentState === "Indiana" && (
                                             <Accordion>
                                                <Accordion.Item eventKey="0">
                                                   <Accordion.Header className="te-map-legend-accordion-header">
                                                      {global.currentState}
                                                   </Accordion.Header>
                                                   <Accordion.Body>
                                                   {renderLayersByProp("state", "Indiana")}
                                                   </Accordion.Body>
                                                </Accordion.Item>
                                             </Accordion>
                                          )}
                                          {global.currentState === "Texas" && (
                                             <Accordion>
                                                <Accordion.Item eventKey="0">
                                                   <Accordion.Header className="te-map-legend-accordion-header">
                                                      {global.currentState}
                                                   </Accordion.Header>
                                                   <Accordion.Body>
                                                      {renderLayersByProp("state", "Texas")}
                                                   </Accordion.Body>
                                                </Accordion.Item>
                                             </Accordion>
                                          )}
                                          {global.currentState === "Ohio" && (
                                             <Accordion>
                                                <Accordion.Item eventKey="0">
                                                   <Accordion.Header className="te-map-legend-accordion-header">
                                                      {global.currentState}
                                                   </Accordion.Header>
                                                   <Accordion.Body>
                                                      {renderLayersByProp("state", "Ohio")}
                                                   </Accordion.Body>
                                                </Accordion.Item>
                                             </Accordion>
                                          )}
                                          {global.currentState === "Oregon" && (
                                             <Accordion>
                                                <Accordion.Item eventKey="0">
                                                   <Accordion.Header className="te-map-legend-accordion-header">
                                                      {global.currentState}
                                                   </Accordion.Header>
                                                   <Accordion.Body>
                                                      {renderLayersByProp("state", "Oregon")}
                                                   </Accordion.Body>
                                                </Accordion.Item>
                                             </Accordion>
                                          )}
                                          {!statesWithData.find((i:string) => i === global.currentState) && (
                                             <Accordion>
                                                <Accordion.Item eventKey="0">
                                                   <Accordion.Header className="te-map-legend-accordion-header">
                                                      {global.currentState}
                                                   </Accordion.Header>
                                                   <Accordion.Body>
                                                      <Row>
                                                        <p style={{ fontSize: '14px', paddingLeft: '30px'}}>
                                                            Data for {global.currentState} is currently unavailable
                                                         </p> 
                                                      </Row>
                                                   </Accordion.Body>
                                                </Accordion.Item>
                                             </Accordion>
                                          )}
                                       </Accordion.Body>
                                    </Accordion.Item>
                                    )}
                                    <Accordion.Item eventKey="1">
                                       <Accordion.Header className="te-map-legend-accordion-header">
                                          <div className='title'>Aquifer and Groundwater</div>
                                       </Accordion.Header>
                                       <Accordion.Body>
                                          {renderLayersByProp("catagory", "Aquifer and Groundwater")}
                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                       <Accordion.Header className="te-map-legend-accordion-header">
                                          <div className='title'>Climate and Biodiversity</div>
                                       </Accordion.Header>
                                       <Accordion.Body>
                                          {renderLayersByProp("catagory", "Climate and Biodiversity")}
                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                       <Accordion.Header className="te-map-legend-accordion-header">
                                          <div className='title'>Climate and Weather</div>
                                       </Accordion.Header>
                                       <Accordion.Body>
                                          {renderLayersByProp("catagory", "Climate and Weather")}
                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                       <Accordion.Header className="te-map-legend-accordion-header">
                                          <div className='title'>Enviromental Justice Data</div>
                                       </Accordion.Header>
                                       <Accordion.Body>
                                          <Accordion>
                                             <Accordion.Item eventKey="0">
                                                <Accordion.Header className="te-map-legend-accordion-header">Pollution and Sources</Accordion.Header>
                                                <Accordion.Body>
                                                   {renderLayersByProp("subcatagory", "Pollution and Sources")}
                                                </Accordion.Body>
                                             </Accordion.Item>
                                             <Accordion.Item eventKey="1">
                                                <Accordion.Header className="te-map-legend-accordion-header">Socioeconomic Indicators</Accordion.Header>
                                                <Accordion.Body>
                                                  {renderLayersByProp("subcatagory", "Socioeconomic Indicators")}
                                                </Accordion.Body>
                                             </Accordion.Item>
                                          </Accordion>
                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="14">
                                       <Accordion.Header className="te-map-legend-accordion-header">
                                          <div className='title'>Fire</div>
                                       </Accordion.Header>
                                       <Accordion.Body>
                                           {renderLayersByProp("catagory", "Fire")}
                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="141">
                                       <Accordion.Header className="te-map-legend-accordion-header">
                                          <div className='title'>Snow</div>
                                       </Accordion.Header>
                                       <Accordion.Body>
                                           {renderLayersByProp("catagory", "Snow")}
                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1411">
                                       <Accordion.Header className="te-map-legend-accordion-header">
                                          <div className='title'>Soil</div>
                                       </Accordion.Header>
                                       <Accordion.Body>
                                           {renderLayersByProp("catagory", "Soil")}
                                       </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="5">
                                       <Accordion.Header className="te-map-legend-accordion-header">
                                          <div className='title'>Geographic Boundaries and Administrative Areas</div>
                                       </Accordion.Header>
                                       <Accordion.Body>
                                         {renderLayersByProp("catagory", "Geographic Boundaries and Administrative Areas")}
                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                       <Accordion.Header className="te-map-legend-accordion-header">
                                          <div className='title'>Water Contamination and Pollution</div>
                                       </Accordion.Header>
                                       <Accordion.Body>
                                          {renderLayersByProp("catagory", "Water Contamination and Pollution")}
                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="7">
                                       <Accordion.Header className="te-map-legend-accordion-header">
                                          <div className='title'>Watershed and Hydrology</div>
                                       </Accordion.Header>
                                       <Accordion.Body>
                                          {renderLayersByProp("catagory", "Watershed and Hydrology")}
                                       </Accordion.Body>
                                    </Accordion.Item>
                                 </Accordion>
                              </Accordion.Body>
                           </Accordion.Item>
                        </Accordion>
                     )}
                     {global.currentCountry === "Australia" && (
                        <Accordion>
                           <Accordion.Item eventKey="0">
                              <Accordion.Header className="te-map-legend-accordion-header  layers-header-secondary-level">
                                 <div className='title'>{flag(countryCode)} Australia</div>
                              </Accordion.Header>
                              <Accordion.Body>
                                 {renderLayersByProp("country", "Australia")}
                              </Accordion.Body>
                           </Accordion.Item>
                        </Accordion>
                     )}
                     {global.currentCountry === "Pakistan" && (
                        <Accordion>
                           <Accordion.Item eventKey="0">
                              <Accordion.Header className="te-map-legend-accordion-header  layers-header-secondary-level">
                                 <div className='title'>{flag(countryCode)} Pakistan</div>
                              </Accordion.Header>
                              <Accordion.Body>
                               {renderLayersByProp("country", "Pakistan")}
                              </Accordion.Body>
                           </Accordion.Item>
                        </Accordion>
                     )}
                     {global.currentCountry === "Mexico" && (
                        <Accordion>
                           <Accordion.Item eventKey="0">
                              <Accordion.Header className="te-map-legend-accordion-header  layers-header-secondary-level">
                                 <div className='title'>{flag(countryCode)} Mexico</div>
                              </Accordion.Header>
                              <Accordion.Body>
                                 {renderLayersByProp("country", "Mexico")}
                              </Accordion.Body>
                           </Accordion.Item>
                        </Accordion>
                     )}
                     {/*  */}
                     {global.currentCountry === "Singapore" && (
                        <Accordion>
                           <Accordion.Item eventKey="0">
                              <Accordion.Header className="te-map-legend-accordion-header  layers-header-secondary-level">
                                 <div className='title'>{flag(countryCode)} Singapore</div>
                              </Accordion.Header>
                              <Accordion.Body>
                                 {renderLayersByProp("country", "Singapore")}
                              </Accordion.Body>
                           </Accordion.Item>
                        </Accordion>
                     )}
                     {global.currentCountry === "Canada" && (
                        <Accordion>
                           <Accordion.Item eventKey="0">
                              <Accordion.Header className="te-map-legend-accordion-header  layers-header-secondary-level">
                                 <div className='title'>{flag(countryCode)} Canada</div>
                              </Accordion.Header>
                                 <Accordion.Body>
                                    {renderLayersByProp("country", "Canada")}
                                 </Accordion.Body>
                           </Accordion.Item>
                        </Accordion>
                     )}
                
                     {global.currentCountry === "Indonesia" && (
                        <Accordion>
                           <Accordion.Item eventKey="0">
                              <Accordion.Header className="te-map-legend-accordion-header  layers-header-secondary-level">
                                 <div className='title'>{flag(countryCode)} Indonesia</div>
                              </Accordion.Header>
                              <Accordion.Body>
                                 {renderLayersByProp("country", "Indonesia")}
                              </Accordion.Body>
                           </Accordion.Item>
                        </Accordion>
                     )}
                     {global.currentCountry === "Philippines" && (
                        <Accordion>
                           <Accordion.Item eventKey="0">
                              <Accordion.Header className="te-map-legend-accordion-header  layers-header-secondary-level">
                                 <div className='title'>{flag(countryCode)}  Philippines</div>
                              </Accordion.Header>
                              <Accordion.Body>
                                 {renderLayersByProp("country", "Philippines")}
                              </Accordion.Body>
                           </Accordion.Item>
                        </Accordion>
                     )}
                     {!countriesWithData.includes(global.currentCountry) && (
                        <Accordion defaultActiveKey='0'>
                           <Accordion.Item eventKey="0">
                              <Accordion.Header className="te-map-legend-accordion-header layers-header-secondary-level">
                                 <div className='title' style={{ textAlign: 'center' }}>{flag(countryCode)} {global.currentCountry}</div>
                              </Accordion.Header>
                              <Accordion.Body>
                                 <p style={{ textAlign: 'center' }}>Data for {global.currentCountry} is not available at this time</p>
                              </Accordion.Body>
                           </Accordion.Item>
                        </Accordion >)}
                  </Accordion.Body>
               </Accordion.Item>
               {customLayers && customLayers.length > 0 && (
                  <Accordion.Item eventKey="1">
                     <Accordion.Header className="te-map-legend-accordion-header layers-header-primary-level">
                        <div className='title'><FontAwesomeIcon icon={faUsers} className="mr-2" />
                           Organization Location Data
                        </div>
                     </Accordion.Header>
                     <Accordion.Body >
                        <div className="custom-layers-container">
                           {handleUserLayers(customLayers)}
                        </div>
                     </Accordion.Body>
                  </Accordion.Item>
               )}
               {hiddenData && (
                  <Accordion.Item eventKey="1">
                     <Accordion.Header className="te-map-legend-accordion-header layers-header-primary-level">
                        <div className='title'><FontAwesomeIcon icon={faUsers} className="mr-2" />
                           Organization Location Data
                        </div>
                     </Accordion.Header>
                     <Accordion.Body >
                        <div className="custom-layers-container">
                           <LayerItem
                              img={CL} 
                              name='Apple Organization Location Data'
                              selected={AppleLocationData}
                              setSelected={setAppleLocationData}
                              global={global}
                           />
                        </div>
                     </Accordion.Body>
                  </Accordion.Item>
               )}
               <Accordion.Item eventKey="2">
                  <Accordion.Header className="te-map-legend-accordion-header layers-header-primary-level">
                     <div className='title'><FontAwesomeIcon icon={faGlobe} className="mr-2" /> Global Data</div></Accordion.Header>
                  <Accordion.Body >
                     <Accordion>
                        <Accordion.Item eventKey="14111">
                           <Accordion.Header className="te-map-legend-accordion-header">
                              <div className='title'>Radar, Reflectance, Imagery</div>
                           </Accordion.Header>
                           <Accordion.Body>
                               {renderLayersByProp("catagory", "Radar, Reflectance, Imagery")}
                           </Accordion.Body>
                        </Accordion.Item>
                     </Accordion>
                     {renderLayersByProp("catagory", "Global Data")}

                  </Accordion.Body>
               </Accordion.Item>
            </Accordion>
         </Dropdown.Menu>
      </Dropdown>
   );

}

export default React.memo(Layers)