import { useEffect, useContext, useState, memo } from 'react';
import { Popup } from 'react-map-gl';
import { ActivePopupContext } from '../ActivePopupContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { Col, ListGroup  } from 'react-bootstrap'
import './huc-popup-styles.scss';

const HucPopup = ({ global, data }: any) => {
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);
  const [popup, setPopup] = useState<any>();

  const handleClose = () => {
    setPopup(undefined)
    global.setShowPopup(false)
  }

  const renderPopupContent = (obj: any) => (
    <Popup
      longitude={parseFloat(obj.centroid_longitude)}
      latitude={parseFloat(obj.centroid_latitude)}
      onClose={handleClose}
      closeButton={true}
      closeOnClick={true}
      anchor="bottom"
    >
      <Col className="col-12">
        <Col className="d-flex justify-content-between align-items-center mb-2">
          <h5 className="mb-0">HUC Information</h5>
          <FontAwesomeIcon
            icon={faCircleXmark}
            style={{ cursor: 'pointer', color: 'white' }}
            onClick={handleClose}
          />
        </Col>
        <ListGroup as="ul">
          {Object.entries(obj).map(([key, value]: any) => (
            <ListGroup.Item as="li" key={key}>
              <strong>{key}:</strong> {value}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Col>
    </Popup>
  );

  useEffect(() => {
    if (data) {
      const popupContent = renderPopupContent(data)
      setPopup(popupContent);
    }
  }, [data]);


  return popup ? popup : null
};

export default memo(HucPopup);
