import React, { useEffect, useContext, useState, useMemo, useCallback } from "react";
import { Layer, Source, Popup } from "react-map-gl";
import { LineLayer, FillLayer, CircleLayer } from "mapbox-gl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { RefContext } from "../../RefContext";
import { LayerDataContext } from "../../LayerDataContext";
import { ActivePopupContext } from "../../ActivePopupContext";
import { adjustHexBrightness, findTileCentroid } from "../../features/utils";
import './wi-impairment-layer.scss';

type Props = {
  idPoly: string;
  idLines: string;
  idPoints: string;
  global: any;
  opacity: number;
  action: any;
  tileSetPoly: string;
  tileSetLines: string;
  tileSetPoints: string;
  sourceLayerPoly: string;
  sourceLayerLines: string;
  sourceLayerPoints: string;
  reportRoute: string;
  brightness: number;
};

const WaterImpairments: React.FC<Props> = ({
  idPoly,
  idLines,
  idPoints,
  global,
  opacity,
  action,
  tileSetPoly,
  tileSetLines,
  tileSetPoints,
  sourceLayerPoly,
  sourceLayerLines,
  sourceLayerPoints,
  reportRoute,
  brightness,
}) => {
  const [features, setFeatures] = useState<any>(null);
  const [sourceLayer, setSourceLayer] = useState<string | undefined>();
  const [activeTile, setActivetile] = useState<any>()
  const { currentRef } = useContext(RefContext);
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);
  const navigate = useNavigate();
  const { setCurrentLayerData } = useContext<any>(LayerDataContext);

  console.log(features)

  // @ts-ignore
  const mapInstance: any = currentRef && currentRef?.current;

  useEffect(() => {
    if (mapInstance) {
      const handleClick = (event: any) => {
        const coords: any = findTileCentroid(mapInstance, event)

        const layerObject = mapInstance.queryRenderedFeatures(event.point);
        if (layerObject.length > 0) {
          const source = layerObject[0].sourceLayer;
          setSourceLayer(source);
          setFeatures(layerObject[0]);
          setActivePopup({
            id: source,
            content: renderPopup(layerObject[0], coords),
          });
        } else {
          setFeatures(undefined);
          setActivePopup({ id: null, content: null });
        }
      };

      mapInstance.on("click", handleClick);

      return () => {
        mapInstance.off("click", handleClick);
      };
    }
  }, [mapInstance, setActivePopup]);

  const goTo = useCallback(( data: any, coor: any) => {
    const { layer, properties, geometry } = data
    setCurrentLayerData({ layer, properties, geometry, coor });
    setTimeout(() => navigate(reportRoute), 500)
  }, [reportRoute]);

  const FillLayerConfig: FillLayer = useMemo(() => ({
    id: idPoly,
    type: "fill",
    "source-layer": sourceLayerPoly,
    paint: {
      "fill-opacity": opacity,
      "fill-outline-color": `transparent`,
      "fill-color": [
        "case",
        ["match", ["get", "isimpaired"], ["", "Y"], true, false],
        adjustHexBrightness("#B73D0B", brightness),
        ["match", ["get", "isthreatened"], ["Y"], true, false],
        adjustHexBrightness("#E4DB41", brightness),
        ["match", ["get", "isimpaired"], ["N"], true, false],
        adjustHexBrightness("#2D9806", brightness),
        ["match", ["get", "isassessed"], ["N"], true, false],
        adjustHexBrightness("#A43CBA", brightness),
        adjustHexBrightness("#000000", brightness),
      ],
    },
  }), [idPoly, sourceLayerPoly, opacity, brightness]);

  const LineLayerConfig: LineLayer = useMemo(() => ({
    id: idLines,
    type: "line",
    "source-layer": sourceLayerLines,
    paint: {
      "line-opacity": opacity,
      "line-width": 5,
      "line-color": [
        "case",
        ["match", ["get", "isimpaired"], ["", "Y"], true, false],
        adjustHexBrightness("#B73D0B", brightness),
        ["match", ["get", "isthreatened"], ["Y"], true, false],
        adjustHexBrightness("#E4DB41", brightness),
        ["match", ["get", "isimpaired"], ["N"], true, false],
        adjustHexBrightness("#2D9806", brightness),
        ["match", ["get", "isassessed"], ["N"], true, false],
        adjustHexBrightness("#A43CBA", brightness),
        adjustHexBrightness("#000000", brightness),
      ],
    },
  }), [idLines, sourceLayerLines, opacity, brightness]);

  const CircleLayerConfig: CircleLayer = useMemo(() => ({
    id: idPoints,
    type: "circle",
    "source-layer": sourceLayerPoints,
    paint: {
      "circle-opacity": opacity,
      "circle-radius": 5,
      "circle-color": [
        "case",
        ["match", ["get", "isimpaired"], ["", "Y"], true, false],
        adjustHexBrightness("#B73D0B", brightness),
        ["match", ["get", "isthreatened"], ["Y"], true, false],
        adjustHexBrightness("#E4DB41", brightness),
        ["match", ["get", "isimpaired"], ["N"], true, false],
        adjustHexBrightness("#2D9806", brightness),
        ["match", ["get", "isassessed"], ["N"], true, false],
        adjustHexBrightness("#A43CBA", brightness),
        adjustHexBrightness("#000000", brightness),
      ],
    },
  }), [idPoints, sourceLayerPoints, opacity, brightness]);

  const handleActiveTile = (sourceName: any, name: any, type: any) => {
    let SetLayer: any;
    let sourceId: any;
    let sourceUrl: any;

    if (type === "circle") {
      SetLayer = {
        id: "active-circle-tile",
        type: "circle",
        "source-layer": sourceName,
        paint: {
          "circle-opacity": 1,
          "circle-radius": 8,
          "circle-color": "transparent",
          "circle-stroke-width": 2,
          "circle-stroke-color": [
            "match",
            ["get", "assessmentunitname"],
            name,
            "white",
            "transparent",
          ],
        },
      };
      sourceId = "active-circle-tile";
      sourceUrl = tileSetPoints;
    } else if (type === "line") {
      SetLayer = {
        id: "active-line-tile",
        type: 'line',
        "source-layer": sourceName,
        paint: {
          "line-opacity": 1,
          "line-width": 4,
          "line-color": [
            "match",
            ["get", "assessmentunitname"],
            name,
            "white",
            "transparent",
          ],
        },
      };
      sourceId = "active-line-tile";
      sourceUrl = tileSetLines;
    } else if (type === "fill") {
      SetLayer = {
        id: "active-fill-tile",
        type: "line",
        "source-layer": sourceName,
        paint: {
          "line-opacity": 1,
          "line-width": 4,
          "line-color": [
            "match",
            ["get", "assessmentunitname"],
            name,
            "white",
            "transparent",
          ],
        },
      };
      sourceId = "active-fill-tile";
      sourceUrl = tileSetPoly;
    }

    return (
      <Source key={sourceId} id={sourceId} type="vector" url={sourceUrl}>
        <Layer {...SetLayer} />
      </Source>
    );
  };

  useEffect(() => {
    if(features) {
      const { layer, properties } = features
      setActivetile(handleActiveTile(layer['source-layer'], properties.assessmentunitname, layer.type))
    }
  }, [features])

  const renderPopup = useCallback(({ layer, properties, geometry }: any, [lng, lat]: any) => {
    return (
      <Popup
        longitude={lng}
        latitude={lat}
        closeButton={false}
        onClose={() => setActivePopup({ id: null, content: null })}
        anchor="bottom"
      >
        <div className="wi-popup-content">
          <FontAwesomeIcon
            className="close-btn"
            icon={faXmark}
            onClick={() => setActivePopup({ id: null, content: null })}
          />
          <p className="wi-popup-name">
            {properties.assessmentunitname}
          </p>
          <a
            className="wi-popup-link"
            onClick={() => goTo({ layer, properties, geometry }, [lat, lng])}
          >
            Go to report
          </a>
        </div>
      </Popup>
    )
  }, [setActivePopup]);

  return (
    <>
      {global.points && (
        <Source id={idPoints} type="vector" url={tileSetPoints}>
          <Layer {...CircleLayerConfig} />
        </Source>
      )}
      {global.streams && (
        <Source id={idLines} type="vector" url={tileSetLines}>
          <Layer beforeId={idPoints} {...LineLayerConfig} />
        </Source>
      )}
      {global.lakes && (
        <Source id={idPoly} type="vector" url={tileSetPoly}>
          <Layer beforeId={idLines} {...FillLayerConfig} />
        </Source>
      )}
      {activeTile && activeTile}
      {activePopup && activePopup.id === sourceLayer && activePopup.content}
    </>
  );
};

export default WaterImpairments;
