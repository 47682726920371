import { useState, useMemo, useEffect } from 'react';
import { Marker } from 'react-map-gl';
import useSupercluster from 'use-supercluster';
import BasicPopup from '../StormTrueQI/BasicPopup';
import './water-quality-list.scss'

const AGTrueQIScores = ({ global, list, bounds, viewport, haloColor }: any) => {
    const [current, setCurrent] = useState<any | null>(null)
    const [pointScores, setPointScores] = useState<any | null>(null)
    const [pointScore, setPointScore] = useState()

    const points = useMemo(() => list && list.map((point: any, index: number) => ({
        type: "Feature",
        properties: {
            cluster: false,
            index,
            score: point['Overall Score'],
            id: `cluster-${index}`,
        },
        geometry: {
            type: "Point",
            coordinates: [point.Lng, point.Lat],
        }
    })), [list])

    useEffect(() => setPointScores(points), [points])

    const renderAverage = (input: any) => {
        const sum: any = input.reduce((accumulator: any, point: any) => {
            return parseInt(accumulator) + parseInt(point.properties.score)
        }, 0)
        return Math.round(sum / input.length)
    }

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom: viewport.zoom,
        options: { radius: 100, maxZoom: 7 }
    })

    const renderList = useMemo(() => {
        return clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates
            const { cluster: isCluster, point_count: pointCount, index, score } = cluster.properties;

            if (isCluster) {

                const pointData = supercluster?.getLeaves(parseInt(cluster.id!.toString()))

                return (
                    <Marker
                        key={cluster.id}
                        latitude={latitude}
                        longitude={longitude}
                    >
                        <div
                            style={{
                                color: 'black',
                                background: 'rgba(255, 255, 255, 0)',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: `3px solid ${haloColor}`
                            }}
                        >
                            <img
                                width="36px"
                                style={{ cursor: "pointer" }}
                                src={`https://te-v3-assets.s3.us-west-2.amazonaws.com/${renderAverage(pointData)}.svg`}
                                alt="score_icon"
                            />
                        </div>
                    </Marker>
                )
            }

            return (
                <Marker
                    key={cluster.properties.id}
                    latitude={latitude}
                    longitude={longitude}
                    onClick={(e) => {
                        e.originalEvent.stopPropagation();
                        setCurrent(list[index])
                        setPointScore(pointScores[index].properties.score)
                    }}
                >
                    <div
                        style={{
                            color: 'black',
                            background: 'rgba(255, 255, 255, 0)',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: `3px solid ${haloColor}`
                        }}
                    >
                        <img
                            width="28px"
                            style={{ cursor: "pointer" }}
                            src={`https://te-v3-assets.s3.us-west-2.amazonaws.com/${Math.round(score)}.svg`}
                            alt="score_icon"
                        />
                    </div>
                </Marker>
            );
        })
    }, [clusters])

    return (
        <>
            {clusters && renderList}
            {current && <BasicPopup
                type={'AG'}
                score={pointScore}
                current={current}
                reportUrl={'/AGWaterTrueQIForecast'}
                setCurrent={setCurrent}
                global={global}
            />}
        </>
    )
}

export default AGTrueQIScores