import { mapLayersOff, customLayersOff } from '../../utils';

export const MCOpacity = ({ global, top }: any) => {

  const handleOpacity = () => {
    setTimeout(() => {
          const path = window.location.pathname;
          const params = new URLSearchParams(window.location.search);
          const hash = window.location.hash;
          params.set('usrt', '1');
          window.history.replaceState({}, '', `${path}?${params.toString()}${hash}`);
    }, 1);

    setTimeout(() => {
          global.SetMenuRightOpen(true);
    }, 111);
  };

    return (
        <>           
          <div style={{position: 'absolute', top: top, right: 10}} className="mapboxgl-ctrl mapboxgl-ctrl-group">
          	<div className="tools-box">
              <button onClick={handleOpacity} className={"map-opacity-button"}>
                <span className="mapboxgl-ctrl-icon" aria-hidden="true" title="Set map layer Opacity, Brightness and Order" />	                            
              </button>
            </div>
          </div>
        </>
    )
}
                        
export default MCOpacity