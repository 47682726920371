import { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers, faGreaterThan, faContactCard, faRotate, faEraser, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Form, Button, Row, Col, Card, OverlayTrigger } from 'react-bootstrap';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import './Settings.scss';

export default function Settings(props: any){
    const { global } = props;
	global.setUserLogged(true);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const settingsConfiguration = [];
    settingsConfiguration.push({id: 1, title: 'Profile', description: 'View Account Details', ic: faContactCard, route: '/UserInfo' })
  
    if((global.userRole==='admin')||(global.userRole==='te-admin')) {
        settingsConfiguration.push({id: 10, title: 'User Management', description: 'Add users and assign roles', ic: faUsers, route: '/Users' })
    }

    if(global.userInfo.sub === 'a098a045-4ec2-4b98-827c-01626ab7dcb8' ||  ( global.userInfo?.email?.substr(0,4) ==="aure") || global.userInfo.sub ==="7e6e0e1e-afc5-4ba7-b435-9661d01d51bc"){
        settingsConfiguration.push({id: 20, title: global.userRole + '', description: 'Configure additional tools', ic: faRotate, route: '/Users' })
    }

    if((global.userRole==='te-admin')) {
        settingsConfiguration.push({id: 30, title: 'Reset Alerts', description: 'Reset one-time alerts.', ic: faEraser, route: '/' })
    }

	return(
		<div className='Settings'>
            <Row className='settings-title'>
                <h2>Settings</h2>
            </Row>

            <Row xs={1} md={3} className="g-4 settings-grid-row">
                {settingsConfiguration.map((settingscard: any) => (
                <GridCard {...settingscard}  key={'settings-grid-'+ settingscard.id}/>
                ))}
            </Row>
		</div>
	);

    function GridCard(settingscard: any){
        const sid = settingscard.id;
    
        const handleCardClick = (event: any) => {
            if(event.target.id === 'settings-grid-card20'){
                if(global.userRole && global.userRole.toLowerCase() === 'user'){
                    global.setUserRole('admin');
                } else if (global.userRole && global.userRole.toLowerCase()=== 'admin'){
                    global.setUserRole('te-admin');
                } else if (global.userRole && global.userRole.toLowerCase()=== 'te-admin'){
                    global.setUserRole('user');
                }
            } else if(event.target.id === 'settings-grid-card30') {               
                localStorage.removeItem("app_alerthelpA")
                global.notify('Alerts reset!')
            } else {
                navigate(settingscard.route)
            }
        };
    
        return(
            <>
                <Card className='settings-card' 
                    id={'settings-grid-card'+sid}
                    onClick={handleCardClick} 
                    key={'settings-grid-card'+sid}>                    
                        <div className='settings-card-icon-container'>
                            <FontAwesomeIcon
                                icon={settingscard.ic}
                                className='mr-2'
                                style={{ display: 'inline', marginLeft: 0 }}
                            />
                        </div>
                        <Card.Body  className='settings-card-body'>
                            <Card.Title>{settingscard.title}
                                <div className='settings-card-check-a'>
                                </div>
                            </Card.Title>
                            <Card.Text>
                                <span className='settings-card-span'>{settingscard.description}</span>
                            </Card.Text>               
                        </Card.Body>              
                </Card>
            </>
        );
    }
}




