import {
    useMemo,
    useEffect,
    useState
} from 'react'
import {
    Col,
    Row,
    Button,
    Form,
    FloatingLabel
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPenToSquare,
    faTrashCan
} from '@fortawesome/pro-solid-svg-icons';
import { getDomainValue, postWQX, updateWQX } from '../lib/data';

const EditMonitoringLocation = (props: any) => {


    const {
        actions,
        global,
        mLocsData,
        setMLOCSData,
        data,
        setAction,
        setData,
        action
    } = props;

    const [monv, setMonv] = useState<any>({...data})
    const [monv_initial, setMonv_initial] = useState<any>({...data})

    const [VerticalCollectionMethod, setVerticalCollectionMethod] = useState < any[] > ([]);
    const [VerticalReferenceDatum, setVerticalReferenceDatum] = useState < any[] > ([]);
    const [Country, setCountry] = useState < any[] > ([]);
    const [County, setCounty] = useState < any[] > ([]);
    const [CountyFiltered, setCountyFiltered] = useState < any[] > ([]);
    const [State, setState] = useState < any[] > ([]);
    const [StateFiltered, setStateFiltered] = useState < any[] > ([]);
    const [HorizontalCollectionMethod, setHorizontalCollectionMethod] = useState < any[] > ([]);
    const [HorizontalCoordinateReferenceSystemDatum, setHorizontalCoordinateReferenceSystemDatum] = useState < any[] > ([]);
    const [MonitoringLocationType, setMonitoringLocationType] = useState < any[] > ([]);
    const [MeasureUnit, setMeasureUnit] = useState < any[] > ([]);
    const [WellType, setWellType] = useState < any[] > ([]);


    useEffect(() => {
        getDomainValue('Country', setCountry);
        getDomainValue('VerticalCollectionMethod', setVerticalCollectionMethod);
        getDomainValue('VerticalReferenceDatum', setVerticalReferenceDatum);
        getDomainValue('HorizontalCollectionMethod', setHorizontalCollectionMethod);
        getDomainValue('HorizontalReferenceDatum', setHorizontalCoordinateReferenceSystemDatum);
        getDomainValue('State', setState);
        getDomainValue('MeasurementUnit', setMeasureUnit);
        getDomainValue('MonitoringLocationType', setMonitoringLocationType);
        getDomainValue('WellType', setWellType);
        getDomainValue('County', setCounty);
    }, []);


    useEffect(() => {       
        if(data && data.MonitorLocGeo_CountryCode){
             const filterData = [...Country].filter((o: any) => (o["Code"] === data.MonitorLocGeo_CountryCode));            
             if(filterData.length>0){
                 const countryCode = filterData[0]['Code'];
                 const filterState = [...State].filter((o: any) => (o["Country Code"] === countryCode));                
                 setStateFiltered(filterState);
             } else {
                 setStateFiltered(State);
             }
        } else {
            setStateFiltered(State);
        }
    }, [State]);

    useEffect(() => {
        if(data && data.MonitorLocGeo_StateCode){
             const filterData = [...State].filter((o: any) => (o["Code"] === data.MonitorLocGeo_StateCode));           
             if(filterData.length>0){
                 const stateCode = filterData[0]['Code'];
                 const filterCounty = [...County].filter((o: any) => (o["State Code"] === stateCode));              
                 setCountyFiltered(filterCounty);
             } else {
                 setCountyFiltered(County);
             }
        } else {
            setCountyFiltered(County);
        }
    }, [County]);


    const updateMon = (property: any, value:any) => {    
        let changeMon = data;
        changeMon[property]=value;
        setData(changeMon)
        setMonv({property: value})

        if(property === 'MonitorLocGeo_StateCode'){
             const filterData = [...State].filter((o: any) => (o["Name"] === value));     
             if(filterData.length>0){
                 const stateCode = filterData[0]['Code'];
                 const filterCounty = [...County].filter((o: any) => (o["State Code"] === stateCode));
                  setCountyFiltered(filterCounty);
             } else {
                 setCountyFiltered(County);
             }
        } 

        if(property === 'MonitorLocGeo_CountryCode'){
             const filterData = [...Country].filter((o: any) => (o["Name"] === value));      
             if(filterData.length>0){
                 const CountryCode = filterData[0]['Code'];
                 const filterState = [...State].filter((o: any) => (o["Country Code"] === CountryCode));          
                  setStateFiltered(filterState);
             } else {
                 setStateFiltered(State);
             }
        } 
    }

    const testObject = { 
        "ORGDescription_OrganizationDescriptionText": null,
        "ORGDescription_OrganizationIdentifier": "WQXTEST",
        "ORGDescription_OrganizationFormalName": "WQXTEST",
        "ORGElectronicAddress_ElectronicAddress": "TE@TEST.COM",
        "ORGDescription_TribalCode": "001",
        "ORGElectronicAddress_ElectronicAddressText": "amya@trueelements.com",
        "ORGElectronicAddress_ElectronicAddressTypeName": "Email",
        "ORGTelephonic_Telephonic": null,
        "ORGTelephonic_TelephoneNumberText": "800-424-9067",
        "ORGTelephonic_TelephoneNumberTypeName": "Office",
        "ORGTelephonic_TelephoneExtensionNumberText": "None",
/*        "MonitoringLocIdent_MonitoringLocationIdentity": null,
        "MonitoringLocIdent_MonitoringLocationIdentifier": "123",
        "MonitoringLocIdent_MonitoringLocationName": "Mon Location 1",
        "MonitoringLocIdent_MonitoringLocationTypeName": "Seep",
        "MonitoringLocIdent_MonitoringLocationDescriptionText": "Location 200 feet downstream of Raritan inlet",*/

       
    }

    const handleSave= (event:any) => {
       
        if(action==='create'){
            const newData = [...mLocsData];
            newData.push(data)
          
            setMLOCSData(newData)
            //postWQX(data, handleSaveCallback);
            //(dataItem['MonitoringLocIdent_MonitoringLocationIdentifier']||dataItem['MonitoringLocIdent_MonitoringLocationName']){
            let abbrevData = {...testObject} //data //newData[0]

            Object.keys(abbrevData ).forEach((key:string)=>{
               // if(abbrevData[key]==='null'){
                     //console.log(abbrevData[key]);
                //}
                //
                //@ts-ignore
               if(!abbrevData[key]){
                     //console.log(abbrevData[key]);
                     //@ts-ignore
                     delete abbrevData[key];
                }

/*                   delete abbrevData['cdxStatus'];
                   delete abbrevData['id'];
                   delete abbrevData['submissionType'];*/

/*                cdxStatus
               id
                    submissionType*/

            });
            postWQX(abbrevData, handleSaveCallback);

        } else if (action==='edit'){
            let abbrevData = {...data}

            Object.keys(abbrevData ).forEach((key:string)=>{
                if(abbrevData[key]==='null'){
                     
                }
                if(!abbrevData [key]){
                     
                     delete abbrevData[key];
                }
            });
            //postWQX(abbrevData, handleSaveCallback);
            updateWQX(abbrevData, handleSaveCallback);
        }
        setAction('table')
    }

    const handleSaveCallback = (success: boolean, result:any, res:any) => {
        console.log(success, result, res)
        if(res && res.errorMessage){
            
        }

    }

    const handleCancel= (event:any) => {
        setAction('table')
    }

    return (
        <Row className='monitoring-location-container'>
            <div className="header-box">
                <h2>{action==='edit' ? "Edit" : "" }{action==='create' ? "Add" : "" } Monitoring Locations</h2>
            </div>
            <Row id="filter-box" style={{ marginRight: '0' }}>
                <div className="add-activity-box">                   
                    <Form>
                        <Row className="w-100">
                            <Col className="col-4">
                                <Form.Group className="mb-3" controlId="MonitoringLocIdent_MonitoringLocationIdentifier">
                                    <FloatingLabel controlId="MonitoringLocIdent_MonitoringLocationIdentifier" label="Monitoring Location Identifier">
                                        <Form.Control 
                                        onChange={(e: any) => updateMon('MonitoringLocIdent_MonitoringLocationIdentifier', e.target.value)} 
                                        size="lg" type="text" placeholder="Mon Loc ID" defaultValue={data && data.MonitoringLocIdent_MonitoringLocationIdentifier}/>
                                    </FloatingLabel> 
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="8-Digit HUC">
                                   <FloatingLabel controlId="8-Digit HUC" label="8-Digit HUC">
                                        <Form.Control 
                                        onChange={(e: any) => updateMon('MonitoringLocIdent_HUCEightDigitCode', e.target.value)}
                                        size="lg" type="text" placeholder="8-Digit HUC" defaultValue={data && data.MonitoringLocIdent_HUCEightDigitCode}/>
                                    </FloatingLabel> 
                                </Form.Group>
                            </Col>
                            <Col className="col-4">
                                <Form.Group className="mb-3" controlId="floatingMonitoringLocationMonitoringLocationName">
                                    <FloatingLabel controlId="floatingMonitoringLocationMonitoringLocationName" label="Mon Loc Name">
                                        <Form.Control 
                                        onChange={(e: any) => updateMon('MonitoringLocIdent_MonitoringLocationName"', e.target.value)}
                                        size="lg" type="text" placeholder="Monitoring Location Name" defaultValue={data && data.MonitoringLocIdent_MonitoringLocationName}/>
                                    </FloatingLabel> 
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="FloatingLabelHUC12">
                                    <FloatingLabel controlId="FloatingLabelHUC12" label="12-Digit HUC">
                                        <Form.Control 
                                         onChange={(e: any) => updateMon('MonitoringLocIdent_HUCTwelveDigitCode"', e.target.value)}
                                         size="lg" type="text" placeholder="12-Digit HUC" defaultValue={data && data.MonitoringLocIdent_HUCTwelveDigitCode} />
                                    </FloatingLabel> 
                                </Form.Group>
                            </Col>
                            <Col className="col-4">
                                    <Form.Group className="mb-3" controlId="floatingMonitoringLocationType">
                                        <FloatingLabel controlId="floatingMonitoringLocationType" label="Monitoring Location Type">
                                            <Form.Select  onChange={(e: any) => updateMon('MonitoringLocIdent_MonitoringLocationTypeName', e.target.value)}
                                             size="lg" aria-label="MonitoringLocIdent_MonitoringLocationTypeName" value={data && data["MonitoringLocIdent_MonitoringLocationTypeName"]}>
                                                {MonitoringLocationType  && MonitoringLocationType.map((obj:any, index:any) =>
                                                    (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                                )} 
                                            </Form.Select>
                                        </FloatingLabel> 
                                    </Form.Group>


                                <Form.Group className="mb-3" controlId="FloatingLabelTribalLandName">
                                    <FloatingLabel controlId="FloatingLabelTribalLandName" label="Tribal Land Name">
                                        <Form.Control size="lg" type="text" placeholder="Tribal Land Name"  defaultValue={data && data.MonitoringLocIdent_TribalLandName}/>
                                    </FloatingLabel> 
                                </Form.Group>
                            </Col>
                            <Col className="col-12">
                                <Form.Group className="mb-3" controlId="floatingMonitoringLocationDescription">
                                    <FloatingLabel controlId="floatingMonitoringLocationDescription" label="Description">
                                        <Form.Control as="textarea" rows={6} defaultValue={data && data.MonitoringLocIdent_MonitoringLocationDescriptionText} />
                                     </FloatingLabel> 
             
                                </Form.Group>
                            </Col>
                            <Col className="col-4">
                                <Form.Group className="mb-3" controlId="floatingMonitoringLocationLatitude">
                                    <FloatingLabel controlId="floatingMonitoringLocationLatitude" label="Latitude">
                                        <Form.Control
                                            size="lg"
                                            type="text"
                                            placeholder="Latitude"
                                            defaultValue={data && data.MonitorLocGeo_LatitudeMeasure}
                                        />
                                    </FloatingLabel> 
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="floatingMonitoringLocationSourceMapScale">
                                    <FloatingLabel controlId="floatingMonitoringLocationSourceMapScale" label="Source Map Scale">
                                        <Form.Control
                                            size="lg"
                                            type="text"
                                            placeholder="Source Map Scale"
                                            defaultValue={data && data.MonitorLocGeo_SourceMapScale}
                                        />
                                    </FloatingLabel>     
                                </Form.Group>
                            </Col>
                            <Col className="col-4">
                                <Form.Group className="mb-3" controlId="floatingMonitoringLocationLongitudeMeasure">
                                    <FloatingLabel controlId="floatingMonitoringLocationLongitudeMeasure" label="Longitude">
                                        <Form.Control size="lg" type="text" placeholder="Longitude" defaultValue={data && data.MonitorLocGeo_LongitudeMeasure} />
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="floatingMonitoringLocationHorizontalCollectionMethod">
                                    <FloatingLabel controlId="floatingMonitoringLocationHorizontalCollectionMethod" label="Horizontal Collection Method">
                                        <Form.Select   onChange={(e: any) => updateMon('MonitorLocGeo_HorizontalCollectionMethodName', e.target.value)}
                                        size="lg" aria-label="HorizontalCollectionMethod" value={data && data.MonitorLocGeo_HorizontalCollectionMethodName}>
                                            {HorizontalCollectionMethod  && HorizontalCollectionMethod.map((obj:any, index:any) =>
                                                (<option key={'wm-proj-options'+index} value={obj.Name.toUpperCase()}>{obj.Name}</option>)
                                            )} 
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>

                            </Col>
                            <Col className="col-4">
                                <Form.Group className="mb-3" controlId="floatingMonitoringLocationCounty">

                                </Form.Group>

                                <Form.Group className="mb-3" controlId="floatingMonitoringLocationHorizontalCoordinateReference">
                                    <FloatingLabel controlId="floatingMonitoringLocationHorizontalCoordinateReference" label="Horizontal Coordinate Reference">
                                        <Form.Select   onChange={(e: any) => updateMon('MonitorLocGeo_HorizontalCoordinateReferenceSystemDatumName', e.target.value)}
                                        size="lg" aria-label="HorizontalCoordinateReference"  value={data && data.MonitorLocGeo_HorizontalCoordinateReferenceSystemDatumName}>
                                            {HorizontalCoordinateReferenceSystemDatum && HorizontalCoordinateReferenceSystemDatum.map((obj:any, index:any) =>
                                                (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                            )} 
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>

                            {/*  */}
                            <Col className="col-4">
                                <Form.Group className="mb-3" controlId="floatingMonitoringLocationVerticalAccuracyMeasure">
                                    <FloatingLabel controlId="floatingMonitoringLocationVerticalAccuracyMeasure" label="Vertical Accuracy Measure">
                                        <Form.Control size="lg" type="text" placeholder="Vertical Accuracy Measure" defaultValue={data && data.MonitorLocGeo_VerticalAccuracyMeasure} />
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="floatingMonitoringLocationMeasureUnit">
                                    <FloatingLabel controlId="floatingMonitoringLocationMeasureUnit" label="Measure Unit">
                                        <Form.Select   onChange={(e: any) => updateMon('MonitoringLocIdent_MeasureUnitCode', e.target.value)}
                                        size="lg" aria-label="MeasureUnit"  value={data && data.MonitoringLocIdent_MeasureUnitCode}>
                                            {MeasureUnit  && MeasureUnit.map((obj:any, index:any) =>
                                                (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                            )} 
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col className="col-4">
                                <Form.Group className="mb-3" controlId="floatingMonitoringLocationVerticalCollectionMethod">
                                    <FloatingLabel controlId="floatingMonitoringLocationVerticalCollectionMethod" label="Horizontal Collection Method">
                                        <Form.Select  onChange={(e: any) => updateMon('MonitorLocGeo_VerticalCollectionMethodName', e.target.value)}
                                        size="lg" aria-label="VerticalCollectionMethod" value={data && data.MonitorLocGeo_VerticalCollectionMethodName}>
                                            {VerticalCollectionMethod  && VerticalCollectionMethod.map((obj:any, index:any) =>
                                                (<option key={'wm-proj-options'+index} value={obj.Name.toUpperCase()}>{obj.Name}</option>)
                                            )} 
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="floatingMonitoringLocationVerticalCoordinateReference">
                                    <FloatingLabel controlId="floatingMonitoringLocationVerticalCoordinateReference" label="Vertical Coordinate Reference">
                                        <Form.Select  onChange={(e: any) => updateMon('MonitorLocGeo_VerticalCoordinateReferenceSystemDatumName', e.target.value)}
                                        size="lg" aria-label="VerticalCoordinateReference"  value={data && data.MonitorLocGeo_VerticalCoordinateReferenceSystemDatumName}>
                                            {VerticalReferenceDatum && VerticalReferenceDatum.map((obj:any, index:any) =>
                                                (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                            )} 
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col className="col-4">
                                <Form.Group className="mb-3" controlId="floatingMonitoringLocationCounty">
                                    <FloatingLabel controlId="floatingMonitoringLocationCounty" label="County">
                                        <Form.Select   onChange={(e: any) => updateMon('MonitorLocGeo_CountyCode', e.target.value)}
                                        size="lg" aria-label="County"  value={data && data.MonitorLocGeo_CountyCode}>
                                            {CountyFiltered   && CountyFiltered.map((obj:any, index:any) =>
                                                (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                            )} 
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="floatingMonitoringLocationState">
                                    <FloatingLabel controlId="floatingMonitoringLocationState" label="State">
                                        <Form.Select   onChange={(e: any) => updateMon('MonitorLocGeo_StateCode', e.target.value)}
                                        size="lg" aria-label="State"  value={data && data.MonitorLocGeo_StateCode}>
                                            {StateFiltered  && StateFiltered.map((obj:any, index:any) =>
                                                (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                            )} 
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col className="col-12">
                                <Form.Group className="mb-3" controlId="floatingMonitoringLocationCountry">
                                    <FloatingLabel controlId="floatingMonitoringLocationCountry " label="Country">
                                        <Form.Select  onChange={(e: any) => updateMon('MonitorLocGeo_CountryCode', e.target.value)}
                                        size="lg" aria-label="Country"  value={data && data.MonitorLocGeo_CountryCode}>
                                            {Country   && Country .map((obj:any, index:any) =>
                                                (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                            )} 
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="floatingMonitoringLocationWellType">
                                    <FloatingLabel controlId="floatingMonitoringLocationWellType" label="Well Type">
                                        <Form.Select  onChange={(e: any) => updateMon('MonitoringLocWellInfo_WellTypeText', e.target.value)}
                                        size="lg" aria-label="Well Type"  value={data && data.MonitoringLocWellInfo_WellTypeText}>
                                            {WellType  && WellType.map((obj:any, index:any) =>
                                                (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                            )} 
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>

                            </Col>

                        </Row>
                    </Form>
                </div>
            </Row>
            <Row className="d-flex justify-content-end w-100">
                <div className="add-activities-button-box edit">
                    <Button
                        onClick={handleCancel}
                        variant="primary"
                        id="export-btn"
                        type="submit"
                        className="p-3 btn btn-primary m-2">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        variant="primary"
                        type="submit"
                        className="p-3 btn btn-primary m-2"
                    >
                        Save & Exit
                    </Button>
                </div>
            </Row>
        </Row>
    )
}

export default EditMonitoringLocation