import {
    useState,
    Ref,
    useRef,
    useContext,
    useEffect,
    useCallback
} from 'react';
import {
    Map,
    MapRef,
    Source,
    Layer,
    FullscreenControl,
    NavigationControl,
    GeolocateControl,
    LayerProps,
    Popup,
} from 'react-map-gl';
import RightHeader from '../../../components/RightHeader'
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import LoadingSpinner from '../../../components/LoadingSpinner';
import DrawControl from '../../../types/DrawControl';

import fullscreenIcon from '../../../assets/fullscreen-icon.svg';
import { ToastContainer, toast } from 'react-toastify';
import { Location, useLocation } from 'react-router-dom';
import { message_hucrestriction, message_nodatalocation, message_noresult } from '../../../Constants';
import settings from '../../../settings.json';
import { CatalogsContext } from '../../../CatalogsProvider';
import { AppContext } from '../../../AppContext';
import MapSlider from '../shared/MapSlider';
import MapAddition from  '../shared/MapAddition';

import agent from '../../../api/agent';
import { PointContext } from '../../../PointContext'
import { RefContext } from '../../../RefContext';
import {
    flyToInitialLocationHandler,
    flyToLngLat,
    getRandomColor,
    groupBy,
    hasAValue,
    resolveCurrentactiveFilters,
} from '../../utils';
import Globals from '../../../types/GlobalsType';
import TimeReferenceUnit from '../../../types/TimeReferenceUnits';
import { Feature, FeatureCollection, GeoJsonProperties, Point } from 'geojson';
import moment from 'moment';
import { DateTime } from 'luxon';
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import { LOCATIONS_FILTER, MEASUREMENTS_FILTER } from '../../../Constants';
import { Chart } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import LiveWaterSensorsResponseBodyModel from '../../../types/LiveWaterSensorsResponseBodyModel';
import StationDataMeasures from '../../../types/StationDataMeasures';
import MapPointColorDefinition from '../../../types/MapPointColorDefinition';
import StationData from '../../../types/StationData';
import MapLegend from '../shared/MapLegend';
import {
  clearFilters,
  startingValues
} from '../shared/report-data';

import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import './source.scss';

Chart.register(zoomPlugin);

const GFSHuc12Forecast = ({ global }: any) => {
    const appContext = useContext(AppContext);
    const catalogsContext = useContext(CatalogsContext);
    const FullScreenComp = FullScreen as any;
    const fullscreenhandle = useFullScreenHandle() as any;
    const [isFullScreenChartActive, setFullScreenChartActive] = useState(false);
    const { setCurrentPoint, currentPoint } = useContext(PointContext);
    const fullscreenChartContainerClickHandler = () => {
        setFullScreenChartActive(!isFullScreenChartActive);
    };

    const mapRef = useRef<MapRef>();
    const { setCurrentRef } = useContext(RefContext);
    useEffect(() => { setCurrentRef?.(mapRef) }, [mapRef]);

    const location: Location = useLocation();
    //@ts-ignore
    const routeData: any = location.state?.data;

    const [featureClicksCounter, setFeatureClicksCounter] = useState<number>(0);
    const [reportData, setReportData] = useState<any>();

    let startingViewport = startingValues('h12', [global.viewport.longitude, global.viewport.latitude], global, catalogsContext.huc8Catalog);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current?.flyTo({
                center: [
                    global.viewport.longitude,
                    global.viewport.latitude
                ],
                essential: true,
                zoom: 12,
            });
        }
    }, [
        global.viewport.latitude,
        global.viewport.longitude
    ])

    useEffect(() => {
        if (reportData && reportData.length === 0){
            global.notify('Data not found')
        }
     }, [reportData])


    useEffect(() => {
        global.setGFSHuc12s(true)
        if(global.globalHuc12){            
            global.setLoading(true)
            agent
                .Reports
                .GFSHuc12Forecast(
                    global.globalHuc12,
                    global.singleDate
                )
                .then((data: any) => setReportData(data))
                .finally(() => global.setLoading(false))

            return () => global.setGFSHuc12s(false)           
        }

    }, [
        global.singleDate,
        global.globalHuc12
    ])


    const [featureData, setFeatureData] = useState<FeatureCollection>();
    const [stationsMapPointColorsCatalog, setStationsMapPointColorsCatalog] = useState<
        Array<{
            key: string;
            color: string;
        }>
    >();

    const [filterLabelDays, setFilterLabelDays] = useState<any>([]);

    useEffect(() => {
        if (global.singleDate) {
            setFilterLabelDays([global.singleDate])
        }
    }, [global.singleDate])

    const mapInitialViewState = {
        longitude: settings.defaultLocationMap.lng,
        latitude: settings.defaultLocationMap.lat,
        zoom: 3,
    };

    const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

    useEffect(() => {
        global.setUserLogged(true);
    }, []);


    const calcAverage = (input: any) => {
        if (input.length > 0) {
            let sum = 0;
            let count = 0;

            input.forEach(({ rain }: any) => sum += rain);
            input.forEach(({ rain }: any) => (rain>0) ? count += 1 : count += 0 );
            const average = (count>0 ) ? sum / count : 0; 
            return parseFloat(average.toFixed(3));
        } else {
            return 0; 
        }
    };


    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'hour',
                    displayFormats: {
                        hour: 'tt' // Adjust the format as needed
                    }
                },
                title: {
                    display: true,
                    text: 'Hourly Datetime'
                }
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Rainfall in inches'
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            
            dailyAveragePlugin: {
                calculateDailyAverage: (data: any) => {
                    const dailyAverages:any = {};
                    data.forEach((entry: any) => {
                        const date = entry.x.toDateString();
                        if (!dailyAverages[date]) {
                            dailyAverages[date] = { sum: 0, count: 0 };
                        }
                        dailyAverages[date].sum += entry.y;
                        dailyAverages[date].count++;
                    });

                    const labels = Object.keys(dailyAverages);
                    const values = labels.map((date) =>
                        dailyAverages[date].sum / dailyAverages[date].count
                    );

                    return { labels, values };
                },
            },
        },
    };

    const chartRef = useRef<any>();

    const onBarChartClickHandler = (event: any) => {
        const currentBar = getElementAtEvent(chartRef.current, event)[0];
        if (currentBar) {
            const currentBarMetaData = chartRef.current.getDatasetMeta(currentBar.datasetIndex);
            setFeatureClicksCounter(prev => prev + 1);
            // resolveClickedFeatureBehavior(currentBarMetaData.label, true);
        }
    };

    const statusMessage='';


    return (
        <FullScreenComp handle={fullscreenhandle}>
            <div className='toxics-release-inventory' id='GFSHuc12Forecast-report'>
                <ReportHeader global={global} data={reportData}
                    reportID={"GFSHuc12Forecast-report"} filterLabelDays={filterLabelDays} fullScreenClickHandle={fullscreenhandle.enter} />
                <div className='container'>
                    <Row>
                        <Col>
                            <div style={{ position: 'relative' }} className='map-container'>
                                <RightHeader global={global} button={false} />
                                <Map
                                    ref={mapRef as Ref<MapRef>}
                                    preserveDrawingBuffer={true}
                                    mapboxAccessToken={settings.maboxKey}
                                    initialViewState={startingViewport}
                                    mapStyle={global.mapStyle}
                                    projection={global.globeView ? 'globe' : 'mercator' as any}
                                    onClick={(e) => {
                                        global.onMapClick(e)
                                    }}
                                >
                                <MapAddition global={global} 
                                    mapRef={mapRef}
                                    position={'low'}
                                    zipOff={true}
                                    MapSliderAdd={true}
                                    statusMessage={statusMessage}/>


                                    <div className='map-legend-container'>
                                        <MapLegend
                                            global={global}
                                            legendWidth={320}
                                        />
                                    </div>
                                </Map>

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ width: '23rem' }}>
                            <div
                                className={
                                    isFullScreenChartActive
                                        ? 'container--expand-container lws-table-container'
                                        : 'lws-table-container'
                                }
                            >
                                <Row>
                                    <div className='report-options'>
                                        <Button onClick={fullscreenChartContainerClickHandler}>
                                            <img src={fullscreenIcon} />
                                        </Button>
                                    </div>
                                </Row>
                                <Row className='chart-container' style={{ height: '22rem' }}>
                                    <div style={{
                                        textAlign: 'center',
                                        marginTop: '-2rem'
                                    }}>
                                        {reportData && <h4>Daily Average: {calcAverage(reportData)} inches</h4>}
                                    </div>
                                    <Bar
                                        // @ts-ignore
                                        options={options}
                                        data={{
                                            datasets: [
                                                {
                                                    data: reportData && reportData.map((entry: any) => ({ x: new Date(entry.hourly_datetime), y: entry.rain })),
                                                    backgroundColor: 'blue',
                                                },
                                            ],
                                        }}
                                        ref={chartRef}
                                        onClick={onBarChartClickHandler}
                                    />
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
                <ReportFooter />
            </div>
        </FullScreenComp>
    );
}

export default GFSHuc12Forecast