import { useContext, useState, useEffect, useMemo } from "react";
import { RefContext } from "../../../RefContext";
import { CatalogsContext } from "../../../CatalogsProvider";
import { AppContext } from "../../../AppContext";
import { Button, OverlayTrigger, Popover, Tooltip, Dropdown, Form, FloatingLabel, Card, ListGroup } from 'react-bootstrap';
import settings from "../../../settings.json";

import { Marker, Popup } from 'react-map-gl';
import axios from 'axios';
import ReportsConfigs from '../../../ReportsConfigs';
import ISOs from '../../../ISO.json';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faBookmark, faHome, faPipe, faGlassWater, faWater, faArrowRight, faArrowRightLong, faArrowLeft, faArrowLeftLong, faGlobePointer, faTrashCan, faMapLocation, faMap } from '@fortawesome/pro-solid-svg-icons';
import localForage from "localforage";
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import PointsWithinPolygon from '@turf/points-within-polygon';
import bboxPolygon from '@turf/bbox-polygon';
import bbox from '@turf/bbox';
import clustersDbscan from '@turf/clusters-dbscan';
import { Feature, GeoJsonProperties, Point, FeatureCollection } from 'geojson';

import puck from "../../../assets/puck.svg";

import { parse, unparse } from 'papaparse';
import { HUC12InfoPoint } from '../../../types/HUC12InfoPoint';

import agent from "../../../api/agent";

import { getCountryFromStorage } from '../../utils';
import { Location, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import {
  CircularInput,
  CircularTrack,
  CircularProgress,
  CircularThumb
} from 'react-circular-input'

import { uniqueValues } from './report-data';

export const MCFavorites = ({ global, top, reverse }: any) => {

  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;
  const navigate = useNavigate();


  const { currentRef } = useContext(RefContext);
  const catalogsContext = useContext(CatalogsContext);
  const appContext = useContext(AppContext);


  const [huc8InfoCatalogArray, setHuc8InfoCatalogArray] = useState<any[]>([]);
  const [huc12InfoCatalogArray, setHuc12InfoCatalogArray] = useState<any[]>([]);

  const [currentHuc8, setCurrentHuc8] = useState(global.selectedHUC8);
  const [currentHuc12, setCurrentHuc12] = useState(global.globalHuc12);
  const [showDelete, setShowDelete] = useState(null);

  const [marker, setMarker] = useState<any>(null);
  const [eventLocation, setEventLocation] = useState(null);

  const [recentLocation, setRecentLocation] = useState<null | any>(null);

  // @ts-ignore
  const { current } = currentRef;

  useEffect(() => {
    const huc12InfoFileURL = '/huc12_info_update.csv';
    fetch(huc12InfoFileURL)
      .then(response => response.text())
      .then(responseText => {
        const data: HUC12InfoPoint[] | any[] = parse(responseText, {
          header: true,
        }).data;
     
       setHuc12InfoCatalogArray(data);
      });
  }, []);

  useEffect(() => {
    const huc8InfoFileURL = '/huc8s_info.csv';
    fetch(huc8InfoFileURL)
      .then(response => response.text())
      .then(responseText => {
        const data: any[] = parse(responseText, {
          header: true,
        }).data;
       setHuc8InfoCatalogArray(data);
      });
  }, []);

  useEffect(() => {
      let faves = localStorage.getItem('favorites');
      if(faves){
        let parsedFaves = JSON.parse(faves);
        global.setFavoriteLocations(parsedFaves)
      }
  }, []);

  useEffect(() => {
      if(eventLocation && appContext && appContext.currentLocation){
        if(appContext.currentLocation !== eventLocation){
          setMarker(null)
        }
      }
  }, [appContext]);

/*  useEffect(() => {
    localForage.getItem("recentLocations", function(err, value) {    
      if(value){//@ts-ignore
        global.setRecentLocations(value);
      }
    });
  }, []);
*/
/*  useEffect(() => {
    if(global.recentLocations){
      localForage.setItem('recentLocations', global.recentLocations);
    }
  }, [global.recentLocations]);*/

  useEffect(() => {
    if(recentLocation){

      let ls_huc12 = localStorage.getItem('location_huc12')
      if(!recentLocation.huc12){
        if(ls_huc12){
          let parsedHuc12 = JSON.parse(ls_huc12);
          if(parsedHuc12 && parsedHuc12.huc12 && parsedHuc12.huc12.substr(0,8)===recentLocation.huc8){
            recentLocation.huc12 = parsedHuc12.HUC12;
            recentLocation.huc12name = parsedHuc12.NAME;  
            recentLocation.HUC12 = parsedHuc12.HUC12;
            recentLocation.NAME = parsedHuc12.NAME;  
            recentLocation.centroid_latitude = recentLocation.latitude;
            recentLocation.centroid_longitude = recentLocation.longitude;  
          }
        }
      } 

      let rL = [...global.recentLocations];
      let newLength = rL.unshift(recentLocation);
      if(newLength > 20){
        rL.pop();
      }
      global.setRecentLocations(rL)
    }
  }, [recentLocation]);


  const handleCurrentLocation = (event: any) => {   
    if (event && appContext) {
      let newLocations = [...global.favoriteLocations];
      let hucFilter = huc12InfoCatalogArray.find((o: any) => o["huc12"] === global.clickedHUC12);
       //zip
      if(hucFilter) {
         let newLocation = { location: appContext.currentLocation, huc8: appContext.selectedHUC8, country: getCountryFromStorage(), huc12: global.clickedHUC12, huc12name: hucFilter.name, latitude: hucFilter.centroid_latitude, longitude: hucFilter.centroid_longitude  }
          newLocations.push(newLocation)
      }

      if(appContext.selectedHUC8 !== global.clickedHUC12.substr(0,8)){
   
        hucFilter = huc8InfoCatalogArray.find((o: any) => o["huc8"] === appContext.selectedHUC8);
        let newLocation = { location: appContext.currentLocation, huc8: appContext.selectedHUC8, country: getCountryFromStorage(),  huc12: null, huc12name: hucFilter.name, latitude: hucFilter.centroid_latitude, longitude: hucFilter.centroid_longitude  }
         newLocations.push(newLocation)
      }
  
      global.setFavoriteLocations(newLocations)
      localStorage.setItem('favorites', JSON.stringify(newLocations))
  
    }       
  }

  const handleDeleteFavorite = (event: any) => {   
  
    if (event) {
      let originalLocations = [...global.favoriteLocations];
      let newLocations = originalLocations.filter((i:any) => {
          return i.location !== event.location && i.huc8 !== event.huc8; 
      })
      if(newLocations){
        global.setFavoriteLocations(newLocations)
        localStorage.setItem('favorites', JSON.stringify(newLocations))
      }
    }       
  }

  const handleBookmarkRecent = (event: any) => { 

    if (event && event.huc8) {
      let newLocations = [...global.favoriteLocations];
      newLocations.push(event)
      global.setFavoriteLocations(newLocations)
      localStorage.setItem('favorites', JSON.stringify(newLocations))
    }
  }

  const clickMarker = (event : any) => {
    console.log(event)
    setMarker(null);
  }

  const dragMarker = (event : any) => {
    console.log(event)


    if(event.lngLat && event.lngLat.lat && event.lngLat.lng){
      let lat = event.lngLat.lat
      let lng = event.lngLat.lng

      agent.Utilities.LatLngToHuc12(lat, lng).then((res: any) => {
       
          let huc12 = '';
          let huc8 = '';
          if(res.body && res.body[0] && res.body[0].Huc8){
            huc12 = res.body[0].Huc8;
            huc8 = res.body[0].Huc8.substr(0,8);
            global.setSelectedHUC8(huc8)
          }

          agent.Utilities.LatLngToCity(lat, lng).then((res2: any) => {
            
                if(res2.features && res2.features[0]){
                  let place_name = res2.features[0].place_name;
                  let country = global.currentCountry;
                  let state = '';

                   let matching = res2.features[0].context.find(
                       (name:any ) => name.id.substr(0,7) === 'country' 
                    );
                   
                    if(matching){
                      country = matching.text
                      //matching.properties.short_code;
                    }
                 
                   matching = res2.features[0].context.find(
                       (name:any ) => name.id.substr(0,6) === 'region' 
                    );
                
                    if(matching){
                        state = matching.text
                        if(country === 'United States'){
                          state = matching.short_code.substr(3, 2)
                        }
                    }

                   matching = res2.features[0].context.find(
                       (name:any ) => name.id.substr(0,5) === 'state' 
                    );
            
                    if(matching){
/*                           dataItem["Country"] = matching.text
                        cacheObj["Country_sc"] = matching.properties.short_code;*/
                    }

                  if(huc8 && global.currentCountry && place_name){
                      updateLocation(place_name, huc8, country, lat, lng, state, huc12)
                  }                    
                }
            
          });
      });
    }
  }

    const getISOCode = (input: string) => {
        const countries = Object.values(ISOs)
        const isos = Object.keys(ISOs)
        const countryIndex = countries.findIndex(country => country === input)
        return isos[countryIndex]
    }

    const updateLocation = (newCity: any, newHUC:any, newCountry:any, lat:any, lng:any, state:any, newHuc12:any) => {
        const reportConfig = ReportsConfigs.find(x => x.route == window.location.pathname);
        let routeData = {
          name: "Global Live Water Map",
          parentReportName: "Home",
          root: '/'
        } 
        if(reportConfig && reportConfig.parentReportName){
          routeData = {
            name: reportConfig.name,
            parentReportName: reportConfig.parentReportName,
            root: reportConfig.route
          }
        }
                              
        if(newCity && newHUC && appContext && appContext.updateContext){
          //setLocalReport(window.location.pathname);  
          let huc12_st = '';
          let hucValidated = newHUC;

          if(newHuc12){
              global.setGlobalHuc12(newHuc12)
              huc12_st = newHuc12;
          } else if(hucValidated.length === 12||hucValidated.length === 11){
              hucValidated = newHUC.substr(0, 8);
              let huc12 = hucValidated;
              if(huc12.length === 11){
                  huc12 = '0'+ huc12;
              }
              global.setGlobalHuc12(huc12)
              huc12_st = huc12;
              //setClickedHUC12
          }
          if(hucValidated.length === 7){
            hucValidated = '0'+ hucValidated;
          }



          //global.setSearchCenter([selectedPlaceLat, selectedPlaceLng]);
          
          let newLocation = {location: newCity, huc8: hucValidated, country: newCountry,  HUC12: huc12_st,  huc12: huc12_st, huc12name: null, latitude: lat, longitude: lng, centroid_latitude: lat, states: state, centroid_longitude: lng};


          setRecentLocation(newLocation)

          if(huc12_st){
            global.setClickedHUC12(huc12_st)
            global.setGlobalHuc12(huc12_st)
            localStorage.setItem('location_huc12', JSON.stringify(newLocation)); //location for Breadcrumb
            global.setResultsDataset([newLocation]);
          } else {
            global.setResultsDataset([]);  
          }

          setEventLocation(newCity);
          appContext.updateContext(hucValidated, newCity, routeData, newCountry); 
          global.setSelectedHUC8(hucValidated)
        }
    }

  const defineMarker = (event: any) => {
  
          if(current){
              setMarker(            <Marker
              longitude={event.longitude}
              latitude={event.latitude}
              draggable={true}
              onClick={clickMarker}
              onDragEnd={dragMarker}
            >
             <div onTouchEnd={clickMarker}>
              <img src={puck} />
             </div>
            </Marker>)
          }
  } 


  const handleGoToFavorite = (event: any) => {   
    if (event) {
        if(appContext && appContext.updateContext && event.huc8){
          appContext.updateContext(
                      event.huc8,
                      event.location,
                      global.currentReport,
                      event.country||getCountryFromStorage()  
                    )       
            global.setSelectedHUC8(event.huc8)       
        }

        if(event.HUC12){
          global.setResultsDataset([event]); 
        }

        if(event.latitude && event.longitude){
          current?.flyTo({
            essential: true,
            center: [event.longitude, event.latitude],
            zoom: 10,
          });   

          if(current){
              setEventLocation(event.location);
              defineMarker(event);
          }
        }
    }       
  }

  const clickDeleteHandler = (event : any) => {

    event.stopPropagation();
    if(showDelete!==null && global.favoriteLocations[showDelete]){
        
        handleDeleteFavorite(global.favoriteLocations[showDelete])
    }
    setShowDelete(null)
  }

  const clickCancelHandler = (event : any) => {
    event.stopPropagation();
    setShowDelete(null)
  }

  const handleOnDeleteClick = (event : any) => {
      setShowDelete(event);
  }

  const refreshFavorites = (event : any) => {

      localForage.getItem("recentLocations", function(err, value) {
          console.log('rF', value, global.recentLocations)
          if(value){//@ts-ignore
            global.setRecentLocations(value);
          }
      });
  }

  const handleHome= (event : any) => {

  }

   const popoverDelete = (
      <Popover id="popover-settings" style={{minWidth: 300}}>
        <Popover.Header as="h3">Remove?</Popover.Header>
        <Popover.Body>
          <div style={{display: 'flex', flexDirection: 'column'}}>
              <Form>
                  <Button className='users-button' style={{ marginRight: 5, marginLeft: 10, width: 70 }}
                      onClick={clickDeleteHandler}>
                      Yes
                  </Button>
                  <Button className='users-button' style={{ marginRight: 5, marginLeft: 70, width: 70 }}
                      onClick={clickCancelHandler}>
                      No
                  </Button>
              </Form>
          </div>  
        </Popover.Body>
      </Popover>
    );


  const popover = (
    <Popover id="popover-settings" style={{minWidth: 400}}>
      <Popover.Header as="h3">Favorite Locations</Popover.Header>
      <Popover.Body>
            <div style={{display: 'flex', flexDirection: 'column'}}> 
                <span>Favorites</span>
                <Card className={'favorites-card'} style={{ maxHeight: 300, marginTop: 10 }}>
                  <ListGroup className={'favorites-list'} style={{  maxHeight: 300, overflow:'auto' }} variant="flush">
                    {global.favoriteLocations && global.favoriteLocations.map((location: any, index: any) => (
                           <ListGroup.Item  className={'favorites-list-item'} key={location.location + index} >
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} >  
                              <span>{location.location}</span><span>{location.state}</span>
                              <div style={{ display: 'flex'}}>
                                <button title="Home" style={location.home !== true ? {color: 'white', display: 'none'} : {}} onClick={() => handleHome(location)}  className={'favorites-list-item-button'}><FontAwesomeIcon icon={faHome} size='lg' className="fs-icon-app fa-thin" /></button>

                                <button title="Go To"  onClick={() => handleGoToFavorite(location)}  className={'favorites-list-item-button'}><FontAwesomeIcon icon={faMapLocation} size='lg' className="fs-icon-app" /></button>
                                <FontAwesomeIcon style={{alignSelf: 'center', height: 42, width: 2, transform: 'scaleY(2)'}} icon={faPipe} size='lg' className="fs-icon-app fa-thin" />
                                <OverlayTrigger  show={showDelete===index} rootClose  overlay={popoverDelete}>
                                   <button  title="Delete"  onClick={() => handleOnDeleteClick(index)}   className={'favorites-list-item-button'}><FontAwesomeIcon icon={faTrashCan} size='lg' className="fs-icon-app" /></button>      
                                </OverlayTrigger>

                              </div>
                            </div>

                              <div style={(global.drinkingWaterQualitySelected) ? {display:'flex', marginTop: 20, marginBottom: 20, marginLeft: 20} : {display:'none'}}>
                                {location.dw && location.dw.score && (<div>    
                                    <CircularInput value={parseInt(location.dw.score)*.01} radius={40}>
                                      <CircularTrack />
                                      <CircularProgress />
                                      <text x={40} y={40} fill="white" stroke="white" textAnchor="middle" dy="0.3em" fontWeight="bold">
                                          {location.dw.score}
                                      </text>
                                    </CircularInput>  
                                    <div style={{marginTop: 15, marginLeft: -5}}>
                                      <FontAwesomeIcon style={{}} icon={faGlassWater} size='lg' className="fs-icon-app" />
                                      <span style={{marginLeft: 5, marginTop: 5}}>Drinking</span>
                                    </div>
                                </div>)}    

                                {location.dw && location.dw.score && (<div  style={{marginLeft: 5}}>   
                                     
                                    <p>Zip Code: {location.dw.zip}</p>
                                    <p>Water Company: {location.dw.pwsid}</p>  

                                </div>)}
                              </div>
                              
                               <div style={(global.surfacewaterTrueQI) ? {display:'flex', marginTop: 20, marginBottom: 20, marginLeft: 20} : {display:'none'}}>
                                                              
                                  {location.sw && location.sw.score && (<div>    
                                      <CircularInput value={parseInt(location.sw.score)*.01} radius={40}>
                                        <CircularTrack />
                                        <CircularProgress />
                                        <text x={40} y={40} fill="white" stroke="white" textAnchor="middle" dy="0.3em" fontWeight="bold">
                                            {location.sw.score}
                                        </text>
                                      </CircularInput>  
                                      <div style={{marginTop: 15, marginLeft: -5}}>
                                        <FontAwesomeIcon style={{}} icon={faWater} size='lg' className="fs-icon-app" />
                                        <span style={{marginLeft: 5, marginTop: 5}}>Surface</span>
                                      </div>
                                  </div>)}  


                                  {location.sw && location.sw.score && (<div  style={{marginLeft: 5}}>   

                                  </div>)}

                                </div>
                            </ListGroup.Item>
                     ))}
                  </ListGroup>
                </Card>
                <ListGroup className={'favorites-list favorites-list-add'} style={{ display: 'none', maxHeight: 300, overflow:'auto', marginTop: 20, marginBottom: 30}} variant="flush">
                  <ListGroup.Item onClick={handleCurrentLocation} className={'favorites-list-item favorites-list-add-item'} ><FontAwesomeIcon style={{marginRight: 5}} icon={faPlus} size='lg' className="fs-icon-app" /> Add Current Map Location</ListGroup.Item>
                </ListGroup>
 
                <hr />

                <span>Recent</span>
                <Card className={'favorites-card'} style={{ maxHeight: 300, marginTop: 10 }}>
                  <ListGroup className={'favorites-list'} style={{  maxHeight: 300, overflow:'auto' }} variant="flush">
                    {global.recentLocations.map((location: any, index: any) => (
                           <ListGroup.Item style={{ display: 'flex', justifyContent: 'space-between' }}  className={'favorites-list-item'} key={location.location + index} >
                              <span>{location.location}</span>
                              <div style={{ display: 'flex'}}>
                                <button title="Go To" onClick={() => handleGoToFavorite(location)}  className={'favorites-list-item-button'}><FontAwesomeIcon icon={faMapLocation} size='lg' className="fs-icon-app" /></button>
                                <FontAwesomeIcon style={{alignSelf: 'center', height: 42, width: 2, transform: 'scaleY(2)'}} icon={faPipe} size='lg' className="fs-icon-app fa-thin" /> 
                                <button title="Bookmark Location" onClick={() => handleBookmarkRecent(location)}  className={'favorites-list-item-button'}><FontAwesomeIcon icon={faBookmark} size='lg' className="fs-icon-app" /></button>
                              </div>
                            </ListGroup.Item>
                     ))}
                  </ListGroup>
                </Card>
              </div>  
      </Popover.Body>
    </Popover>
  );


    return (
        <>           
          <div style={(reverse) ? {position: 'absolute', bottom: top, left: 10} : {position: 'absolute', top: top, left: 10}} className="mapboxgl-ctrl mapboxgl-ctrl-group">
            <div className="tools-box">
              <OverlayTrigger trigger="click" rootClose placement="right" overlay={popover}>
                <button className={"map-bookmark-button map-button"}>
                  <span className="mapboxgl-ctrl-icon" aria-hidden="true" title="Favorites" onClick={refreshFavorites} />
                </button>
              </OverlayTrigger>
            </div>
            {marker && marker}
          </div>
        </>
    )
}
                        
export default MCFavorites