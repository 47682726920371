export const countryOutlines = [
    "Czechia", "#FFF700",
        "Albania", "#FFF700",
        "Andorra", "#FFF700",
        "Armenia", "#FFF700",
        "Austria", "#FFF700",
        "Azerbaijan", "#FFF700",
        "Belarus", "#FFF700",
        "Belgium", "#FFF700",
        "Bosnia and Herzegovina", "#FFF700",
        "Bulgaria", "#FFF700",
        "Croatia", "#FFF700",
        "Cyprus", "#FFF700",
        "Czech Republic", "#FFF700",
        "Denmark", "#FFF700",
        "Estonia", "#FFF700",
        "Finland", "#FFF700",
        "France", "#FFF700",
        "Georgia", "#FFF700",
        "Germany", "#FFF700",
        "Greece", "#FFF700",
        "Hungary", "#FFF700",
        "Iceland", "#FFF700",
        "Ireland", "#FFF700",
        "Italy", "#FFF700",
        "Kosovo", "#FFF700",
        "Latvia", "#FFF700",
        "Liechtenstein", "#FFF700",
        "Lithuania", "#FFF700",
        "Luxembourg", "#FFF700",
        "Malta", "#FFF700",
        "Moldova", "#FFF700",
        "Monaco", "#FFF700",
        "Montenegro", "#FFF700",
        "Netherlands", "#FFF700",
        "North Macedonia", "#FFF700",
        "Norway", "#FFF700",
        "Poland", "#FFF700",
        "Portugal", "#FFF700",
        "Romania", "#FFF700",
        "San Marino", "#FFF700",
        "Serbia", "#FFF700",
        "Slovakia", "#FFF700",
        "Slovenia", "#FFF700",
        "Spain", "#FFF700",
        "Sweden", "#FFF700",
        "Switzerland", "#FFF700",
        "Turkey", "#FFF700",
        "Ukraine", "#FFF700",
        "United Kingdom", "#FFF700",
        "Vatican City", "#FFF700",
]

export const countryFills = [
        "Czechia", "#0F2A8E",       
        "Albania", "#0F2A8E",
        "Andorra", "#0F2A8E",
        "Armenia", "#0F2A8E",
        "Austria", "#0F2A8E",
        "Azerbaijan", "#0F2A8E",
        "Belarus", "#0F2A8E",
        "Belgium", "#0F2A8E",
        "Bosnia and Herzegovina", "#0F2A8E",
        "Bulgaria", "#0F2A8E",
        "Croatia", "#0F2A8E",
        "Cyprus", "#0F2A8E",
        "Czech Republic", "#0F2A8E",
        "Denmark", "#0F2A8E",
        "Estonia", "#0F2A8E",
        "Finland", "#0F2A8E",
        "France", "#0F2A8E",
        "Georgia", "#0F2A8E",
        "Germany", "#0F2A8E",
        "Greece", "#0F2A8E",
        "Hungary", "#0F2A8E",
        "Iceland", "#0F2A8E",
        "Ireland", "#0F2A8E",
        "Italy", "#0F2A8E",
        "Kosovo", "#0F2A8E",
        "Latvia", "#0F2A8E",
        "Liechtenstein", "#0F2A8E",
        "Lithuania", "#0F2A8E",
        "Luxembourg", "#0F2A8E",
        "Malta", "#0F2A8E",
        "Moldova", "#0F2A8E",
        "Monaco", "#0F2A8E",
        "Montenegro", "#0F2A8E",
        "Netherlands", "#0F2A8E",
        "North Macedonia", "#0F2A8E",
        "Norway", "#0F2A8E",
        "Poland", "#0F2A8E",
        "Portugal", "#0F2A8E",
        "Romania", "#0F2A8E",
        "San Marino", "#0F2A8E",
        "Serbia", "#0F2A8E",
        "Slovakia", "#0F2A8E",
        "Slovenia", "#0F2A8E",
        "Spain", "#0F2A8E",
        "Sweden", "#0F2A8E",
        "Switzerland", "#0F2A8E",
        "Turkey", "#0F2A8E",
        "Ukraine", "#0F2A8E",
        "United Kingdom", "#0F2A8E",
        "Vatican City", "#0F2A8E",
]

export const countries = [
        "Czechia",        
        "Albania", 
        "Andorra", 
        "Armenia", 
        "Austria", 
        "Azerbaijan", 
        "Belarus", 
        "Belgium", 
        "Bosnia and Herzegovina", 
        "Bulgaria", 
        "Croatia", 
        "Cyprus", 
        "Czech Republic", 
        "Denmark", 
        "Estonia", 
        "Finland", 
        "France", 
        "Georgia", 
        "Germany", 
        "Greece", 
        "Hungary", 
        "Iceland", 
        "Ireland", 
        "Italy", 
        "Kosovo", 
        "Latvia", 
        "Liechtenstein", 
        "Lithuania", 
        "Luxembourg", 
        "Malta", 
        "Moldova", 
        "Monaco", 
        "Montenegro", 
        "Netherlands", 
        "North Macedonia", 
        "Norway", 
        "Poland", 
        "Portugal", 
        "Romania", 
        "San Marino", 
        "Serbia", 
        "Slovakia", 
        "Slovenia", 
        "Spain", 
        "Sweden", 
        "Switzerland", 
        "Turkey", 
        "Ukraine", 
        "United Kingdom", 
        "Vatican City"
]