import { v4 as uuidv4 } from 'uuid';

export class MapLayer {
  public id: string;
  public type: string;
  public source: string | null;
  public 'source-layer': string;
  public paint: {};
  'minzoom'?: number | undefined
  layerFilter?: string[];

  constructor(
    sourceLayer: string,
    type: string,
    paint: {},
    minzoom?: number,
    layerFilter?: string[],
    id?: string,
  ) {
    this.id = id || uuidv4();
    this.type = type;
    this.source = null;
    this.paint = paint;
    this['source-layer'] = sourceLayer;
    this['minzoom'] = minzoom;
    this.layerFilter = layerFilter;
  }
}

export default MapLayer;
