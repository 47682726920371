import { useEffect, useState, useContext, useMemo, useRef } from "react";
import { render} from 'react-dom'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { LayerDataContext } from "../../../LayerDataContext";
import { PointContext } from "../../../PointContext";
import { AppContext } from "../../../AppContext";
import { RefContext } from "../../../RefContext";
import settings from "../../../settings.json";
import RenderLayers from "../shared/RenderLayers";
import RenderLayerMarkers from "../shared/RenderLayerMarkers";
import ReportHeader from "../shared/ReportHeader";
import { Row, Col } from "react-bootstrap";
import MapSlider from "../shared/MapSlider";
import agent from "../../../api/agent";
import {
  Map,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  LineLayer,
  Layer, 
  Source
} from "react-map-gl";
import MapLegend from "../shared/MapLegend";
import Accordion from 'react-bootstrap/Accordion';
import { 
  faMaximize, 
  faMinimize, 
  faChevronDown,
  faChevronUp,
  faXmark,
  faPlus,
  faPanorama,
  faMagnifyingGlass,
  faUsers,
  faLocationCrosshairs,
  faChartLine,
  faArrowUpFromLine,
  faChartPie,
  faCheck,  
  faCrosshairs
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CEJST_Feilds } from '../../../features/utils';
import "./cejst-report.scss";

const CEJSTReport = ({
  global,
  reportID
}: any) => {
  const { currentPoint } = useContext<any>(PointContext);
  const { currentLocation } = useContext(AppContext);
  const { currentRef, setCurrentRef } = useContext<any>(RefContext);
  const { currentLayerData } = useContext<any>(LayerDataContext);

  const [geoIdData, setGeoIdData] = useState()
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const [GEOID10, setGEOID10] = useState()
  const [ethOpen, setEthOpen] = useState(false)
  const [ageOpen, setAgeOpen] = useState(false)
  const [flagged, setFlagged] = useState(false)
  const [reportData, setReportData] = useState<any>()

  useEffect(() => {
    if (!global.CEJST) global.setCEJST(true);
    return () => {
      global.setCEJST(false);
    };
  }, []);

  useEffect(() => {
    global.setUserLogged(true);
    setCurrentRef(mapRef);
    setTimeout(() => global.setCEJSTPopup(false), 100)
  }, []); 

  useEffect(() => {
    if(currentLocation) {
      agent.Utilities.CityToLatLng(currentLocation)
      .then(({ features }:any) => {
        const point = features[0]
        const { center } = point
        const [lng, lat] = center
        flyToPoint(lng, lat, global.viewport.zoom)
      })
    }
  },[currentLocation])


  const flyToPoint = (lng: any, lat: any, zoom: any) => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [lng, lat],
        essential: true,
        zoom: zoom,
      });
    }
  };

  useEffect(() => {
    if(currentPoint) {
      const { geoId, lng, lat, zoom } = currentPoint
      flyToPoint(lng, lat, zoom)
      setGEOID10(geoId)
    }
  },[currentPoint])

  const mapRef = useRef<any>();
  const reportBounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : null;

  const format = (input: any[]) => {
    if (input.length > 0) {
      return input.map((obj) => {
        const { tableColumns, ...rest } = obj;
        return rest;
      });
    } else {
      return [];
    }
  };

  const mapInstance = currentRef && currentRef.current
  const lngLat = global.action && global.action.lngLat 

  useEffect(() => {
    if(lngLat) {
      flyToPoint(lngLat.lng, lngLat.lat, global.viewport.zoom)
    }
  },[lngLat])

  const getFeaturesAtPoint = async (map: any, event: any) => {
    const features = await map.queryRenderedFeatures(event.point)?.[0]; 
    setGeoIdData(features.properties);
    setGEOID10(features.properties.GEOID10)
  };
  
  useEffect(() => { 

  if(mapInstance) {
    mapInstance.on('click', (e:any) => {
      getFeaturesAtPoint(mapInstance, e)
    })
   }
  }, [mapInstance])

  useEffect(() => {
    const element = document.querySelector('.flagged-btn');
    if (element) {
      setFlagged(true);
    } else {
      setFlagged(false);
    }
  }, [geoIdData])

  const CEJSTLayerOutline: LineLayer = useMemo(() => ({
        id: "CEJST-line-layer",
        type: "line",
        "source-layer": "CEJST",
        paint: {
            "line-opacity": 1,
            'line-width': 3,
            "line-color": [
              "match",
              ["get", "GEOID10"],
              `${GEOID10}`, 'black',
              "transparent"
            ],
        },
  }), [GEOID10]);

  const handleClose = () => {
    setGEOID10(undefined)
    setGeoIdData(undefined)
  }

  const handlePercentile = (properties: any) => {
    if(isNaN(properties)) {
      return 'data unavailable'
    }

    const handleSuffix = (inputStr: string) => {
        switch (inputStr) {
            case '0':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
                return 'th';
            case '1':
                return 'st';
            case '2':
                return 'nd';
            case '3':
                return 'rd';
            default:
                return '';
        }
    }

    const score = Math.round(properties * 100)
    const array = score.toString().split('')
    const lastNum = array[array.length - 1]
    const suffix = handleSuffix(lastNum)
    const percentileString = `${score}${suffix}`
    return percentileString
  }

  const handlePercentileValue = (value: any) => {
    if (!geoIdData) {
      return (
        <>
          <strong>{' --- '}</strong>
          <p>no data</p>
        </>
      );
    }
  
    const data = geoIdData[value];
  
    if (value === "P100_PFS" && data >= 0.65) {
      return (
        <>
          <strong className="flagged-score">{handlePercentile(data)}</strong>
          <p>above 65th percentile</p>
        </>
      );
    }
  
    if (value === "P100_PFS" && data < 0.65) {
      return (
        <>
          <strong>{handlePercentile(data)}</strong>
          <p>not above 65th percentile</p>
        </>
      );
    }
  
    if (value === "HSEF" && data >= 0.1) {
      return (
        <>
          <strong className="flagged-score">{100 * data + '%'}</strong>
          <p>above 10%</p>
        </>
      );
    }
  
    if (value === "HSEF" && data < 0.1) {
      return (
        <>
          <strong>{100 * data + '%'}</strong>
          <p>not above 10%</p>
        </>
      );
    }
  
    if (data >= 0.9) {
      return (
        <>
          <strong className="flagged-score">{handlePercentile(data)}</strong>
          <p>above 90th percentile</p>
        </>
      );
    }
  
    return (
      <>
        <strong>{handlePercentile(data)}</strong>
        <p>not above 90th percentile</p>
      </>
    );
  };

  const handleBooleanValue = (value: any) => {
    if (!geoIdData) {
      return (
        <>
          <strong>{' --- '}</strong>
          <p>data not present</p>
        </>
      );
    }
  
    const data = geoIdData[value];
  
    if (data === 1) {
      return (
        <>
          <strong className="flagged-score">Yes</strong>
          <p>data present</p>
        </>
      );
    } else {
      return (
        <>
          <strong>{' --- '}</strong>
          <p>data not present</p>
        </>
      );
    }
  };

  const handleFlaggedCatagory = (catagory: any[]) => {
    if (!geoIdData) {
      return ""; 
    }
  
    for (let i of catagory) {
      if (geoIdData[i] >= 0.9) {
        return "flagged-btn";
      }
  
      if (i === "HSEF" && geoIdData[i] >= 0.1) {
        return "flagged-btn";
      }
  
      if (i === "P100_PFS" && geoIdData[i] >= 0.65) {
        return "flagged-btn";
      }
  
      if (
        ["AML_ET", "FUDS_RAW", "FUDS_ET"].includes(i) &&
        geoIdData[i] === 1
      ) {
        return "flagged-btn";
      }
    }

    return "";
  };

  const handleAgeInfo = () => {
      return ageOpen === false ? <>Open{' '}<FontAwesomeIcon onClick={() => setAgeOpen(true)} className="text-icon DM" icon={faChevronDown}/></> : <>Close{' '}<FontAwesomeIcon onClick={() => setAgeOpen(false)} className="text-icon DM" icon={faChevronUp}/></>
  }

  const handleRaceInfo = () => {
      return ethOpen === false ? <>Open{' '}<FontAwesomeIcon onClick={() => setEthOpen(true)} className="text-icon DM" icon={faChevronDown}/></> : <>Close{' '}<FontAwesomeIcon onClick={() => setEthOpen(false)} className="text-icon DM" icon={faChevronUp}/></>
  }

  const returnAlias = (input: any) => {
    const handleField = CEJST_Feilds.find((field: any) => field.name === input);
    return handleField ? handleField.alias : '';
  };

  const round = (number:any) => {
    return parseFloat(number.toFixed(1));
  }


  const handleAgeDemo = (data: any) => {
    const { AGE_10, AGE_MIDDLE, AGE_OLD } = data

    return (
      <div className="demo-index">
        <div className="feilds">
          <p>{returnAlias('AGE_10')}</p>
          <p>{returnAlias('AGE_MIDDLE')}</p>
          <p>{returnAlias('AGE_OLD')}</p>
        </div>
        <div className="values">
          <p>{round(AGE_10 * 100) + '%'}</p>
          <p>{round(AGE_MIDDLE * 100) + '%'}</p>
          <p>{round(AGE_OLD * 100) + '%'}</p>
        </div>
      </div>
    )
  }

  const handleEthDemo = (data: any) => {
    const { DM_W, DM_H, DM_T, DM_HI, DM_A, DM_AI, DM_B, DM_O } = data

    return (
      <div className="demo-index">
        <div className="feilds">
          <p>{returnAlias('DM_W')}</p>
          <p>{returnAlias('DM_H')}</p>
          <p>{returnAlias('DM_T')}</p>
          <p>{returnAlias('DM_A')}</p>
          <p>{returnAlias('DM_B')}</p>
          <p>{returnAlias('DM_O')}</p>
          <p>{returnAlias('DM_HI')}</p>
          <p>{returnAlias('DM_AI')}</p>
        </div>
        <div className="values">
          <p>{round(DM_W * 100) + '%'}</p>
          <p>{round(DM_H * 100) + '%'}</p>
          <p>{round(DM_T * 100) + '%'}</p>
          <p>{round(DM_A * 100) + '%'}</p>
          <p>{round(DM_B * 100) + '%'}</p>
          <p>{round(DM_O * 100) + '%'}</p>
          <p>{round(DM_HI * 100) + '%'}</p>
          <p>{round(DM_AI * 100) + '%'}</p>
        </div>
      </div>
    )
  }

  const renderFlagged = (input: boolean) => {
    if(input) {
      return (
        <div className="disadvantaged-desc">
          This tract is considered disadvantaged because it meets more than 1 burden threshold <strong>AND</strong> the associated socioeconomic threshold.
        </div>
      )
    } else {
      return (
        <div className="disadvantaged-desc">
          This tract is not considered disadvantaged. It does not meet any burden thresholds <strong>OR</strong> at least one associated socioeconomic threshold.
        </div>
      )
    }
  }
  const handleExportPercentile = (input: any) => {
    if(isNaN(input)) {
      return 'data unavailable'
    }
    const handleSuffix = (inputStr: string) => {
      switch (inputStr) {
        case '0':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
          return 'th';
        case '1':
          return 'st';
        case '2':
          return 'nd';
        case '3':
          return 'rd';
        default:
          return '';
      }
    }
  
    const score = Math.round(input * 100);
    const array = score.toString().split('');
    const lastNum = array[array.length - 1];
    const suffix = handleSuffix(lastNum);
    const percentileString = `${score}${suffix} Percentile`;
    return percentileString;
  }
  
  const returnBoolStr = (input: any) => input === 1 ? 'Data present' : 'Data not present';
  
  const buildExportData = (input: any) => {    
    const keys = [
      "GEOID10", "CF", "SF", "TPF",
      "DM_W", "DM_H", "DM_T", "DM_HI", "DM_A", "DM_AI", "DM_B", "DM_O",
      "EALR_PFS", "EBLR_PFS", "EPLR_PFS", "FLD_PFS", "WFR_PFS",
      "EBF_PFS", "PM25F_PFS",
      "AF_PFS", "DF_PFS", "HDF_PFS", "LLEF_PFS",
      "HBF_PFS", "IS_PFS", "KP_PFS", "LPF_PFS",
      "AML_ET", "FUDS_RAW", "FUDS_ET", "RMP_PFS", "NPL_ET",
      "DSF_PFS", "N_TRN", "TF_PFS",
      "UST_PFS", "WF_PFS",
      "LIF_PFS", "LMI_PFS", "P100_PFS", "UF_PFS", "HSEF",
    ]
    let exportobject: any = {};
    const percentileValue: any = /\((percent(?:ile)?)\)/g;
    const booleanValue: any = /False/g;
    const percentValue: any = /Percent/g
    const percentileBool: any = /Greater than or equal to the 90th percentile/g
  
    keys.forEach((key: any) => {
      const cejestAlias: any = CEJST_Feilds.find((i: any) => i.name === key)?.alias;
  
      if (cejestAlias) {
        if (percentileValue.test(cejestAlias)) {
          exportobject[cejestAlias.replace(percentileValue, '').trim()] = handleExportPercentile(input[key]);
        }
        if (booleanValue.test(cejestAlias)) {
          exportobject[cejestAlias] = returnBoolStr(input[key]);
        }
        if (["DM_W", "DM_H", "DM_T", "DM_HI", "DM_A", "DM_AI", "DM_B", "DM_O", "HSEF"].includes(key)) {
          exportobject[cejestAlias] = (Math.round(input[key] * 100) + '%') 
        }
        if (percentileBool.test(cejestAlias)) {
          exportobject[cejestAlias] = input[key] === 1 ? 'True' : 'False'
        }
        if(["GEOID10", "CF", "SF", "TPF"].includes(key)){
          exportobject[cejestAlias] = (input[key])
        }        
      }
    });
  
    return exportobject; 
  }

  useEffect(() => {
    if(geoIdData){
      const obj = buildExportData(geoIdData)
      setReportData([obj])
    }
  },[geoIdData])


  const handleContent = (input:any = undefined) => {

    if(input) {
      const { GEOID10, CF, SF, TPF } = input
      return (
        <div className="accordions-container">
          <FontAwesomeIcon className="close-icon" onClick={handleClose} icon={faXmark} />
          <div className="geoID-info">
            <section className="top">
                <div className="tract-info">
                  <h5>Tract information</h5>
                  <p>Number: {GEOID10}</p>
                  <p>County: {CF}</p>
                  <p>State: {SF}</p>
                  <p>Population: {TPF}</p>
                </div>
              </section>
              <section className="mid">
                <div className="tract-demo">
                  <h5>Tract demographics </h5>
                  <p>Race / Ethnicity {'('}{handleRaceInfo()}{')'}</p>
                  {ethOpen && handleEthDemo(input)}
                  <p>Age {'('}{handleAgeInfo()}{')'}</p>
                  {ageOpen && handleAgeDemo(input)}
                </div>
              </section>
              <section className="bottom">
                <div className="is-disadvantaged">
                  <h5>Identified as disadvantaged?</h5>
                  {flagged ? <strong className="flagged-score">YES</strong> : <strong>NO</strong>}
                  {renderFlagged(flagged)}
                </div>    
              </section>
          </div>
          <div className="accordions">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header className={`${handleFlaggedCatagory(["EALR_PFS", "EBLR_PFS", "EPLR_PFS", "EPLR_PFS", "FLD_PFS", "WFR_PFS"])}`}>
                  Climate change
                </Accordion.Header>
                <Accordion.Body>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Expected agriculture loss rate</h5>
                      <p>Economic loss to agricultural value resulting from natural hazards each year</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("EALR_PFS")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Expected building loss rate</h5>
                      <p>Economic loss to building value resulting from natural hazards each year</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("EBLR_PFS")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Expected population loss rate</h5>
                      <p>Fatalities and injuries resulting from natural hazards each year</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("EPLR_PFS")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Projected flood risk</h5>
                      <p>Projected risk to properties from projected floods, from tides, rain, riverine and storm surges within 30 years</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("FLD_PFS")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Projected wildfire risk</h5>
                      <p>Projected risk to properties from wildfire from fire fuels, weather, humans, and fire movement in 30 years</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("WFR_PFS")}
                    </div>
                  </div>
                  <strong className="and">AND</strong>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Low income</h5>
                      <p>People in households where income is less than or equal to twice the federal poverty level, not including students enrolled in higher ed</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("P100_PFS")}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header className={`${handleFlaggedCatagory(["EBF_PFS", "PM25F_PFS", "P100_PFS"])}`}>
                  Energy
                </Accordion.Header>
                <Accordion.Body>
                <div className="data-container">
                    <div className="desc">
                      <h5>Energy cost</h5>
                      <p>Average annual energy costs divided by household income</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("EBF_PFS")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>PM2.5 in the air</h5>
                      <p>Level of inhalable particles, 2.5 micrometers or smaller</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("PM25F_PFS")}
                    </div>
                  </div>
                  <strong className="and">AND</strong>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Low income</h5>
                      <p>People in households where income is less than or equal to twice the federal poverty level, not including students enrolled in higher ed</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("P100_PFS")}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header className={`${handleFlaggedCatagory(["AF_PFS", "DF_PFS", "HDF_PFS", "LLEF_PFS", "P100_PFS"])}`}>
                  Health
                </Accordion.Header>
                <Accordion.Body>
                <div className="data-container">
                    <div className="desc">
                      <h5>Asthma</h5>
                      <p>Share of people who have been told they have asthma</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("AF_PFS")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Diabetes</h5>
                      <p>Share of people ages 18 years and older who have diabetes other than diabetes during pregnancy</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("DF_PFS")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Heart disease</h5>
                      <p>Share of people ages 18 years and older who have been told they have heart disease</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("HDF_PFS")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Low life expectancy</h5>
                      <p>Average number of years a person can expect to live</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("LLEF_PFS")}
                    </div>
                  </div>
                  <strong className="and">AND</strong>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Low income</h5>
                      <p>People in households where income is less than or equal to twice the federal poverty level, not including students enrolled in higher ed</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("P100_PFS")}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header className={`${handleFlaggedCatagory(["HBF_PFS", "IS_PFS", "KP_PFS", "LPF_PFS", "P100_PFS"])}`}>
                  Housing
                </Accordion.Header>
                <Accordion.Body>
                <div className="data-container">
                    <div className="desc">
                      <h5>Housing cost</h5>
                      <p>Share of households making less than 80% of the area median family income and spending more than 30% of income on housing</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("HBF_PFS")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Lack of green space</h5>
                      <p>Amount of land, not including crop land, that is covered with artificial materials like concrete or pavement</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("IS_PFS")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Lack of indoor plumbing</h5>
                      <p>Share of homes without indoor kitchens or plumbing</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("KP_PFS")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Lead paint</h5>
                      <p>Share of homes that are likely to have lead paint</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("LPF_PFS")}
                    </div>
                  </div>
                  <strong className="and">AND</strong>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Low income</h5>
                      <p>People in households where income is less than or equal to twice the federal poverty level, not including students enrolled in higher ed</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("P100_PFS")}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header className={`${handleFlaggedCatagory(["AML_ET", "FUDS_RAW", "FUDS_ET", "RMP_PFS", "NPL_ET", "P100_PFS"])}`}>
                  Legacy Pollution
                </Accordion.Header>
                <Accordion.Body>
                <div className="data-container">
                    <div className="desc">
                      <h5>Abandoned mine land</h5>
                      <p>Presence of one or more abandoned mine land within the tract</p>
                    </div>
                    <div className="score">
                      {handleBooleanValue("AML_ET")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Formerly Used Defense Sites</h5>
                      <p>Presence of one or more Formerly Used Defense Site within the tract</p>
                    </div>
                    <div className="score">
                      {handleBooleanValue("FUDS_RAW") || handleBooleanValue("FUDS_ET")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Proximity to hazardous waste facilities</h5>
                      <p>Count of hazardous waste facilities within 5 kilometers</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("TSDF_PFS")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Proximity to Risk Management Plan facilities</h5>
                      <p>Count of Risk Management Plan (RMP) facilities within 5 kilometers</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("RMP_PFS")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Proximity to Superfund sites</h5>
                      <p>Count of proposed or listed Superfund (or National Priorities List (NPL)) sites within 5 kilometers</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("NPL_ET")}
                    </div>
                  </div>
                  <strong className="and">AND</strong>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Low income</h5>
                      <p>People in households where income is less than or equal to twice the federal poverty level, not including students enrolled in higher ed</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("P100_PFS")}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header className={`${handleFlaggedCatagory(["DSF_PFS", "N_TRN", "TF_PFS", "P100_PFS"])}`}>
                  Transportation
                </Accordion.Header>
                <Accordion.Body>
                <div className="data-container">
                    <div className="desc">
                      <h5>Diesel particulate matter exposure</h5>
                      <p>Amount of diesel exhaust in the air</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("DSF_PFS")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Transportation barriers</h5>
                      <p>Average of relative cost and time spent on transportation</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("N_TRN")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Traffic proximity and volume</h5>
                      <p>Count of vehicles at major roads within 500 meters</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("TF_PFS")}
                    </div>
                  </div>
                  <strong className="and">AND</strong>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Low income</h5>
                      <p>People in households where income is less than or equal to twice the federal poverty level, not including students enrolled in higher ed</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("P100_PFS")}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header className={`${handleFlaggedCatagory(["UST_PFS", "WF_PFS", "P100_PFS"])}`}>
                  Water and Wastewater
                </Accordion.Header>
                <Accordion.Body>
                <div className="data-container">
                    <div className="desc">
                      <h5>Underground storage tanks and releases</h5>
                      <p>Formula of the density of leaking underground storage tanks and number of all active underground storage tanks within 1500 feet of the census tract boundaries</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue('UST_PFS')}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Wastewater discharge</h5>
                      <p>Modeled toxic concentrations at parts of streams within 500 meters</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("WF_PFS")}
                    </div>
                  </div>
                  <strong className="and">AND</strong>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Low income</h5>
                      <p>People in households where income is less than or equal to twice the federal poverty level, not including students enrolled in higher ed</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("P100_PFS")}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header className={`${handleFlaggedCatagory(["LIF_PFS", "LMI_PFS", "P100_PFS", "UF_PFS", "HSEF"])}`}>
                  Workforce Development
                </Accordion.Header>
                <Accordion.Body>
                <div className="data-container">
                    <div className="desc">
                      <h5>Linguistic isolation</h5>
                      <p>Share of households where no one over age 14 speaks English very well</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("LIF_PFS")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Low median income</h5>
                      <p>Comparison of median income in the tract to median incomes in the area</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("LMI_PFS")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Poverty</h5>
                      <p>Share of people in households where income is at or below 100% of the Federal poverty level</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("P100_PFS")}
                    </div>
                  </div>
                  <div className="data-container">
                    <div className="desc">
                      <h5>Unemployment</h5>
                      <p>Number of unemployed people as a part of the labor force</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("UF_PFS")}
                    </div>
                  </div>
                  <strong className="and">AND</strong>
                  <div className="data-container">
                    <div className="desc">
                      <h5>High school education</h5>
                      <p>Percent of people ages 25 years or older whose high school education is less than a high school diploma</p>
                    </div>
                    <div className="score">
                      {handlePercentileValue("HSEF")}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      )
    }

    return (
      <div className="content-legend">
        <section className="desc">
          <h5>How to use the map:</h5>
          <p>
            Zoom in <FontAwesomeIcon className="text-icon" icon={faPlus}/>, 
            search <FontAwesomeIcon className="text-icon" icon={faMagnifyingGlass}/>, 
            or locate yourself <FontAwesomeIcon className="text-icon" icon={faLocationCrosshairs}/>{' '}
            and select to see information about any census tract.
          </p>
        </section>
        <section className="exxpanded">
          <h5>Things to know:</h5>
          <p>
            The tool uses census tracts 
            {' '}<FontAwesomeIcon className="text-icon" icon={faPanorama}/> 
            {' '}Census tracts are a small unit of geography. 
            They generally have populations 
            {' '}<FontAwesomeIcon className="text-icon" icon={faUsers}/>{' '} informing the user of communities
            of between 1,200 - 8,000 people.
          </p>
          <p>
            Communities that are disadvantaged live in tracts 
            that experience burdens. These tracts are colored
            on the map.
          </p>
          <p>
            The tool ranks most of the burdens using percentiles.
            <FontAwesomeIcon className="text-icon" icon={faChartLine}/>{' '}
            Percentiles show how much burden each tract experiences 
            when compared to other tracts.
          </p>
          <p>
            Thresholds{' '}<FontAwesomeIcon className="text-icon" icon={faArrowUpFromLine}/>, 
            or cutoffs, are used to determine if communities 
            in a tract are disadvantaged. Certain burdens use percentages{' '} 
            <FontAwesomeIcon className="text-icon" icon={faChartPie}/>{' '}
            or a simple yes/no <FontAwesomeIcon className="text-icon" icon={faCheck}/>
          </p>
          <p>
            Land within the boundaries of Federally Recognized Tribes and point 
            locations for Alaska Native Villages are highlighted on the map. 
            These communities are also considered disadvantaged.
          </p>
        </section>
      </div>
    )
  }

  return (
    <>
      <MapSlider global={global} />
      <FullScreenComp handle={fullscreenhandle}>
        <div id={reportID}>
          <Col className="col-12 d-flex justify-content-center cejst-container">
            <ReportHeader
              global={global}
              data={reportData && reportData}
              mapRef={mapRef}
              reportID={reportID}
              fullScreenClickHandle={fullscreenhandle.enter}
            />
            <div className="cejst-content">
              <Col className="col-9 cejst-map-container position-relative">
                  <Map
                    id="CEJSTReport-map"
                    mapboxAccessToken={settings.maboxKey}
                    mapStyle={global.mapStyle}
                    preserveDrawingBuffer={true}
                    ref={mapRef}
                    projection={global.globeView ? "globe" : "mercator" as any}
                    onClick={(e) => global.onMapClick(e)}
                  >
                    <FullscreenControl />
                    <NavigationControl />
                    <GeolocateControl />
                    <RenderLayers global={global} />
                    <RenderLayerMarkers
                      global={global}
                      bounds={reportBounds}
                      zipOff={true}
                    />

                    {GEOID10 && (
                      <Source
                      id={"CEJST-line-layer"}
                      type="vector"
                      url={"mapbox://trueelementsmapping.CEJST-src"}
                    >
                      <Layer  
                        {...CEJSTLayerOutline} 
                      />
                    </Source>
                    )}
                    <div className="map-legend-container">
                      <MapLegend legendWidth={300} global={global} />
                    </div>
                  </Map>
              </Col>
              <Col className="col-3 report-data">
                {handleContent(geoIdData)}
              </Col>
            </div>
          </Col>
          <footer style={{
              textAlign: 'left',
              fontWeight: 'bold',
              marginLeft: "1%",
              marginBottom: '1rem'
            }}>
              <span
                style={{
                  fontSize: '18px',
                  color: 'white',
                  marginRight: '4px'
                }}
              >
                Source:
              </span>
              <span>
                <a
                  style={{ color: '#4fc32c', textDecoration: 'none' }}
                  href="https://screeningtool.geoplatform.gov/en"
                  target="_blank"
                >
                  Climate and Economic Justice Screening Tool (CJST)
                </a>
              </span>
            </footer>
        </div>
      </FullScreenComp>
    </>
  );
};

export default CEJSTReport;


