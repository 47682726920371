import { useState } from 'react'
import { Form } from 'react-bootstrap'

const RenderFilterOptions = (props: any) => {

    const { 
        filter, 
        setCheckFilter,
        isChecked 
    } = props

    const [checked, setChecked] = useState<boolean>(isChecked)

    const checkHandler = () => {
        if(isChecked) {
            if(checked === null) {
                setChecked(true)
                setCheckFilter((prev: any) => prev.filter((word: any) => word !== filter))
            }
            
            if(checked === true) {
                setChecked(false)
                setCheckFilter((prev: any) => [...prev].concat(filter)) 
            }
    
            if(checked === false) {
                setChecked(true)
                setCheckFilter((prev: any) => prev.filter((word: any) => word !== filter))
            }
        } else {
            if(checked === null) {
                setChecked(true)
                setCheckFilter((prev: any) => [...prev].concat(filter)) 
            }
    
            if(checked === true) {
                setChecked(false)
                setCheckFilter((prev: any) => prev.filter((word: any) => word !== filter))
            }
    
            if(checked === false) {
                setChecked(true)
                setCheckFilter((prev: any) => [...prev].concat(filter)) 
            }
        }
        
    }

    return (
        <>
            <Form.Check
                id={`${filter}`}
                label={`${filter}`}
                name={`${filter}`}
                checked={checked}
                type='checkbox'
                onChange={checkHandler}
            />
        </>
    )
}

export default RenderFilterOptions