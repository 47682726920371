import { useEffect, useState, useContext, useMemo, useRef } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { LayerDataContext } from "../../../LayerDataContext";
import { PointContext } from "../../../PointContext";
import { AppContext } from "../../../AppContext";
import { RefContext } from "../../../RefContext";
import ReportTable from "../shared/ReportTable";
import agent from "../../../api/agent";
import settings from "../../../settings.json";
import MapAddition from  '../shared/MapAddition';
import ReportHeader from "../shared/ReportHeader";
import { Row, Col } from "react-bootstrap";
import MapSlider from "../shared/MapSlider";
import { Bar } from 'react-chartjs-2';
import {
  Map,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
} from "react-map-gl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MapLegend from "../shared/MapLegend";
import { faMaximize, faMinimize } from "@fortawesome/pro-solid-svg-icons";
import "./layer-data-styles.scss";

const LayerDataReport = ({
  global,
  reportID,
  centerMarker,
  WaterConflict = false,
  graph = false,
  filtered = false
}: any) => {
  const { currentPoint } = useContext<any>(PointContext);
  const { setCurrentRef } = useContext<any>(RefContext);
  const { currentLayerData } = useContext<any>(LayerDataContext);

  const [reportData, setReportData] = useState<any>();
  const [initYear, setInitYear] = useState()
  const [chartData, setChartData] = useState<any>()
  const [reportDataArray, setReportDataArray] = useState<any>();
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;

  const transformDate = (input:any) => {

    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const year = input.split('/')[1]
    const month = months[parseInt(input.split('/')[0]) - 1]
  
    return `${month} ${year}`
  }

  const color = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return "#" + randomColor.padStart(6, "0");
  };

  const buildChartData = (keys: any, values: any) => {
    const dataSet = keys.map((label: any, index: number) => {
      
      return {
        backgroundColor: color(),
        data: {
          'Million Gallons/Day': values[index]
        },
        key: index,
        label: transformDate(label)
      }
    })

    const chartDataset = { datasets: dataSet }
    setChartData(chartDataset)
  }
  
  const handleValues = (keys:any, data:any) => {
    return keys.map((key:any) => data[key])
  }

  function isEmpty(obj: object) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }
    return true;
  }

  const formatChartData = (input:any, year:any) => {
    if(!input || isEmpty(input[0])){
        return;
    }

    let dates: any = []
    const keys = Object.keys(input[0])
    keys.forEach((key:any) => {
      if(key.split('/')[1] === year) {
        dates.push(key)
      }
    })

    const dateObjects = dates.map((dateStr: any) => {
      const [month, year] = dateStr.split('/');
      return new Date(`${year}-${month}-01`);
    });

    dateObjects.sort((a:any, b:any) => a - b);

    const sortedDates = dateObjects.map((dateObj: any) => {
      const month = (dateObj.getMonth() + 1).toString()
      const year = dateObj.getFullYear();
      return `${month}/${year}`;
    });

    const values = handleValues(sortedDates, input[0])

    buildChartData(sortedDates, values)
  }

  useEffect(() => {
    if(reportData) {
      formatChartData(reportData, currentLayerData.year)
    }
  }, [reportData])

  useEffect(() => {
    setTimeout(() => {
      const usgsYear = global.selectedUSGSYear.toString()
      if(reportData && currentLayerData.year !== usgsYear) {
        formatChartData(reportData, usgsYear)
      } else {
        formatChartData(reportData, '2016')
      }
    }, 100)
  },[global.selectedUSGSYear])

  useEffect(() => {
    if (WaterConflict) global.setWaterConflict(true);

    return () => {
      global.setWaterConflict(false);
    };
  }, []);

  const handleReportData = (input: any[]) => {
    if (input[0].data) {
      setReportDataArray(input[0].data);
      setReportData(input);
    } else {
      setReportData(input);
    }
  };

  useEffect(() => {
    global.setUserLogged(true);
    setCurrentRef(mapRef);
  }, []);

  useEffect(() => {
    if (currentLayerData) {
      handleReportData([currentLayerData]);
    }
  }, [currentLayerData]);

  const tableColumns = useMemo(
    () => reportData && reportData[0].tableColumns,
    [reportData]
  );

  const flyToPoint = (lng: any, lat: any, zoom: any) => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [parseFloat(lng), parseFloat(lat)],
        essential: true,
        zoom: zoom,
      });
    }
  };

  const { updateContext } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (currentPoint && mapRef) {
        const { lat, lng } = currentPoint;
        if (lat && lng) {
          agent.Utilities.LatLngToCity(lat, lng).then(({ features }: any) => {
            const placeName = features[0].place_name || ""
            updateContext?.(
              "",
              placeName,
              global.currentReport,
              global.currentCountry
            );
          });
          flyToPoint(lng, lat, 8);
        }
      }
    }, 500);
  }, [currentPoint]);

  const mapRef = useRef<any>();


  const [max, setMax] = useState(false);

  const format = (input: any[]) => {
    if (input.length > 0) {
      return input.map((obj) => {
        const { tableColumns, ...rest } = obj;
        return rest;
      });
    } else {
      return [];
    }
  };
 
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: 'Million Gallons per Day (MGD)',
          color: 'white',
          font: {
            size: 16,
          },
        },
        ticks: {
          color: 'white',
        },
      },
      x: {
        display: true,
        title: {
          display: true,
          text: 'Date',
          color: 'white',
          font: {
            size: 16,
          },
        },
        ticks: {
          color: 'white',
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
        },
        maxHeight: 47,
      } as const,
      title: {
        display: true,
        // text: selectedMeasurementFilterValue,
        color: 'white',
        align: 'center',
        padding: 10,
        font: {
          size: 20,
        },
      } as const,
      tooltip: {
        padding: 10,
        bodyFont: {
          size: 24,
        },
        titleFont: {
          size: 24,
        },
        boxPadding: 8,
        usePointStyle: true,
        backgroundColor: '#12234f',
      } as const,
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        } as const,
      } as const,
    } as const,
  } as const;

  return (
    <>
      <MapSlider global={global} />
      <FullScreenComp handle={fullscreenhandle}>
        <div id={reportID}>
          <Col className="col-12 d-flex justify-content-center abh-container">
            <ReportHeader
              global={global}
              data={reportData && format(reportData)}
              mapRef={mapRef}
              reportID={reportID}
              fullScreenClickHandle={fullscreenhandle.enter}
            />
            <Row>
              <Col className="col-12 abh-map-container position-relative">
                {currentPoint && (
                  <Map
                    id="LayerDataReport"
                    mapboxAccessToken={settings.maboxKey}
                    mapStyle={global.mapStyle}
                    preserveDrawingBuffer={true}
                    ref={mapRef}
                    projection={global.globeView ? "globe" : "mercator" as any}
                    onClick={(e) => global.onMapClick(e)}
                  >
                    <MapAddition global={global} 
                                mapRef={mapRef}
                                position={'low'}
                                zipOff={true}
                                MapSliderAdd={true}/>
                    <div className="map-legend-container">
                      <MapLegend legendWidth={300} global={global} />
                    </div>
                  </Map>
                )}
                {centerMarker && <div className="center-marker"></div>}
              </Col>
            </Row>
            <Row className={`table-row`}>
              <Col
                className={`col-12 abh-table-container ${graph && 'height-unset'} ${
                  max ? "expanded-abh" : ""
                }`}
              >
                {reportData && reportData[0].tableColumns &&  (
                  <>
                    <div className="minmax">
                      <FontAwesomeIcon
                        onClick={() => setMax(!max)}
                        icon={max ? faMinimize : faMaximize}
                      />
                    </div>
                    <ReportTable
                      customWidth={"90vw"}
                      data={reportDataArray ? reportDataArray : reportData}
                      columns={tableColumns}
                    />
                  </>
                )}
              </Col>
            </Row>
            {graph && (
            <Row className={`table-row`}>
              <Col
                className={`col-12 abh-graph-container ${
                  max ? "expanded-abh" : ""
                }`}
              >
                {graph && chartData && <Bar options={options} data={chartData} />}
              </Col>
            </Row>
            )}
            <br />
            <h4 style={{ fontSize: "20px" }}>
              Source:{" "}
              <a
                href={reportData && reportData[0].sourceLink}
                target={"_blank"}
              >
                {reportData && reportData[0].sourceLabel}
              </a>
            </h4>
          </Col>
        </div>
      </FullScreenComp>
    </>
  );
};

export default LayerDataReport;
