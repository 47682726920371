import { useState, useEffect } from 'react'
import {
    Button,
    Form
} from 'react-bootstrap'

const Modal = (props: any) => {

    const {
        setModalOpen,
        modalOpen,
        list,
        setModalList
    } = props

    //console.log(props, list)
    const [filterList, setFilterList] = useState<any>(list);
    const [isDataReportLoaded, setIsDataReportLoaded] = useState(true);

    const handleSave = (event: any) => {

        setModalList([...list]);
        setModalOpen(!modalOpen);      
    }

    const handleChange= (event: any) => {
        //console.log(event, event.target, event.target.id, event.target.value)
        let newList = list.filter((o: any) => o['field'] === event.target.id);
        //console.log(event, event.target, event.target.id, event.target.value)
         newList[0].active = !newList[0].active;    
/*        if(event.target.value === 'on'){
            newList[0].active = false;
        } else {
            newList[0].active = true;         
        }*/
        //console.log(list)
        //setModalList(list)
        setFilterList(list)
        setIsDataReportLoaded(!isDataReportLoaded);
    }

    return (
        <div className="modal-container">
            <div className="modal-wrapper">
                <div className="modal">
                    <div className="modal-header">
                        <p>Select fields to include in view</p>
                    </div>
                    <div className="modal-body" key={'modal_' + isDataReportLoaded}>
                        {filterList.map((item: any) => <Form.Check onChange={handleChange} id={item.field} checked={item.active} key={'modal_' + item.field + item.active} type="checkbox" label={item.field}/>)}
                    </div>
                    <div className="modal-footer">
                        <div className="modal-button-container">
                            <Button onClick={handleSave}>
                                Save
                            </Button>
                            <Button onClick={() => setModalOpen(!modalOpen)}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal