import { useContext, useState, useEffect } from 'react';
import { Layer, Source, Popup } from 'react-map-gl';
import { FillLayer } from 'mapbox-gl';
import { RefContext } from '../../../RefContext';
import { ActivePopupContext } from '../../../ActivePopupContext';

import './epic.scss';

const EpicLayer = (props: any) => {
    
    const {
        id,
        fillColor,
        opacity,
        outlineColor,
        tileSet,
        sourceLayer
    } = props;

    const [features, setFeatures] = useState<any>(null);
    const [lngLat, setLngLat] = useState<any>(null);

    const { currentRef } = useContext(RefContext);
    const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);

    // @ts-ignore
    const mapInstance = currentRef && currentRef?.current;

    useEffect(() => {
        if (mapInstance) {
            const handleMouseMove = (event: any) => {
                const layerObject = mapInstance.queryRenderedFeatures(event.point);
                if (layerObject && layerObject[0]) {
                    const feature = layerObject[0];
                    const lngLat = event.lngLat;
                    setFeatures(feature);
                    setLngLat(lngLat);
                    setActivePopup({
                        id,
                        content: renderPopup(feature.properties, lngLat)
                    });
                }
            };

            mapInstance.on('mousemove', handleMouseMove);

            return () => {
                mapInstance.off('mousemove', handleMouseMove);
            };
        }
    }, [mapInstance]);

    const renderPopup = (properties: any, lngLat: any) => {
        const handleClose = () => {
            setFeatures(null);
            setLngLat(null);
            setActivePopup({ id: null, content: null });
        };

        return (
            <Popup
                longitude={lngLat.lng}
                latitude={lngLat.lat}
                onClose={handleClose}
                closeButton={true}
                closeOnClick={true}
                anchor="bottom"
            >
                <div className="layer-info">
                    <p>{properties.pws_name}</p>
                    <p>{properties.pwsid}</p>
                    <p>{properties.tier}</p>
                </div>
            </Popup>
        );
    };

    const VectorTileLayer: FillLayer = {
        id: `${id}-layer`,
        type: "fill",
        "source-layer": sourceLayer,
        paint: {
            "fill-opacity": opacity,
            "fill-outline-color": outlineColor,
            'fill-color': fillColor,
        },
    };

    return (
        <>
            <Source id={`${id}-layer`} type="vector" url={tileSet}>
                <Layer {...VectorTileLayer} />
            </Source>
            {activePopup && activePopup.id === id && activePopup.content}
        </>
    );
};

export default EpicLayer;
