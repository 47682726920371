import { useState, useEffect, useContext, useRef } from "react";
import { AppContext } from "../../../AppContext";
import { Form, Accordion } from "react-bootstrap";
import { parse } from "papaparse";
import Map, { FullscreenControl, Source, Layer } from "react-map-gl";
import RenderLayers from "../shared/RenderLayers";
import RenderLayerMarkers from "../shared/RenderLayerMarkers";
import settings from "../../../settings.json";
import {
  faChevronRight,
  faChevronLeft,
  faMaximize,
  faMinimize,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReportMarkers from "../../../components/ReportMarkers/ReportMarkers";
import Breadcrumb from "../shared/Breadcrumb";
import { RefContext } from "../../../RefContext";
import ReportItem from "./ReportItem";
import _ from "lodash";
import { getReportFromStorage } from "../../utils";
import ReportTable from "../shared/ReportTable";
import MapSlider from "../shared/MapSlider";
import ReportFooter from "../shared/ReportFooter";
import ReportHeader from "../shared/ReportHeader";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import "./styles.scss";

const MexicoReport = (props: any) => {
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;

  const MEXICO_REPORTS = "MEXICO_REPORTS";

  const { global, api, currentReport } = props;

  const {
    updateContext,
    currentLocation,
    selectedHUC8: basinId,
  } = useContext(AppContext);

  const [reports, setReports] = useState<any>([]);
  const [activeIndex, setActiveIndex] = useState<any>(0);
  const [activeReport, setActiveReport] = useState<any>();
  const [centroidData, setCentroidData] = useState<any>();
  const [newList, setNewList] = useState<any>();
  const [headers, setHeaders] = useState<any>();
  const [type, setType] = useState<any>();
  const [open, setOpen] = useState(false);
  const [back, setBack] = useState(0);
  const [next, setNext] = useState(4);

  const handleFilters = (input: any, reportList: any) => {
    if (global.checkFilter.length > 0) {
      const array: any = reportList;
      for (let i = 0; i < array.length; i++) {
        let name = input[i];
        _.remove(array, (n: any) => n.sitename === name);
      }
      setNewList(array);
    } else {
      setNewList(reportList);
    }
    // @ts-ignore
    setReports(JSON.parse(localStorage.getItem(MEXICO_REPORTS)));
  };

  useEffect(() => {
    handleFilters(global.checkFilter, reports);
  }, [global.checkFilter]);

  const flyToLocation = (input: any, zoom: number) => {
    if (input !== undefined) {
      return mapRef.current?.flyTo({
        center: [parseFloat(input.longitude), parseFloat(input.latitude)],
        essential: true,
        zoom,
      });
    }
  };

  useEffect(() => {
    const siteNames =
      reports && reports.map((report: any) => report.sitename).sort();
    global.setCheckFilters(siteNames);
  }, [reports]);

  useEffect(() => {
    const geoMex = "/csv/mexico_geocoded.csv";
    fetch(geoMex)
      .then((response) => response.text())
      .then((responseText) => {
        const data = parse(responseText, {
          header: true,
        }).data;
        setCentroidData(data);
      });
  }, []);

  const [innerData, setInnerData] = useState<any>();
  const [tableColumns, setTableColumns] = useState<any>();
  const [tableData, setTableData] = useState<any>();

  const buildColumns = (input: any) => {
    const labels = Object.keys(input[0][0]);
    const columns = labels.map((element) => {
      return {
        Header: element,
        accessor: element,
      };
    });
    setTableColumns(columns);
  };

  const buildTableData = (input: any, index: number, period: number) => {
    const data = [input[index][period]];
    setTableData(data);
  };

  useEffect(() => {
    innerData && buildColumns(innerData);
    innerData && buildTableData(innerData, activeIndex, global.period);
  }, [innerData, activeIndex, global.period]);

  const transformData = (input: object) => {
    const translateTopKeys = (input: any[]) => {
      const translateData = (input: any[]) => {
        const inner = input.map((ele: any[]) => {
          return ele.map((object: any) => {
            return {
              "48hr Background Toxicity - Daphina Magna or Planktonic Crustacean (48hr BT DM/PC)":
                object.tox_d_48_fon_ut === "nan"
                  ? "data not available"
                  : object.tox_d_48_fon_ut,
              "48hr BT DM/PC Quality Classification":
                object.quality_tox_d_48_fon === "nan"
                  ? "data not available"
                  : object.quality_tox_d_48_fon,
              "48-hour Surface Toxicity - Daphina Magna or Planktonic Crustacean (48hr ST DM/PC)":
                object.tox_d_48_sup_ut === "nan"
                  ? "data not available"
                  : object.tox_d_48_sup_ut,
              "48hr ST DM/PC Quality Classification":
                object.quality_tox_d_48_sup === "nan"
                  ? "data not available"
                  : object.quality_tox_d_48_sup,
              "48hr Flowing Water Toxicity - Daphina Magna or Planktonic Crustacean (48hr FWT DM/PC)":
                object.tox_d_48_ut === "nan"
                  ? "data not available"
                  : object.tox_d_48_ut,
              "48hr FWT DM/PC Quality Classification":
                object.quality_tox_d_48 === "nan"
                  ? "data not available"
                  : object.quality_tox_d_48,
              "Biochemical Oxygen Demand (DBO) [mg/L]":
                object.dbo_mgl === "nan"
                  ? "data not available"
                  : object.dbo_mgl,
              "DBO Quality Classification":
                object.classification_dbo === "nan"
                  ? "data not available"
                  : object.classification_dbo,
              "Chemical Oxygen Demand (DQO) [mg/L]":
                object.dqo_mgl === "nan"
                  ? "data not available"
                  : object.dqo_mgl,
              "DQO Quality Classification":
                object.classification_dqo === "nan"
                  ? "data not available"
                  : object.classification_dqo,
              "Total Suspended Solids (TSS) [mg/L]":
                object.sst_mgl === "nan"
                  ? "data not available"
                  : object.sst_mgl,
              "TSS Quality Classification":
                object.classification_sst === "nan"
                  ? "data not available"
                  : object.classification_sst,
              "Fecal Coliform (FC) 100mL":
                object.coli_fec_nmp_100ml === "nan"
                  ? "data not available"
                  : object.coli_fec_nmp_100ml,
              "FC Quality Classification":
                object.quality_coli_fec === "nan"
                  ? "data not available"
                  : object.quality_coli_fec,
              "E. Coli NMP [100ml]":
                object.e_coli_nmp_100ml === "nan"
                  ? "data not available"
                  : object.e_coli_nmp_100ml,
              "E. Coli NMP [100ml] Quality Classification":
                object.quality_e_coli === "nan"
                  ? "data not available"
                  : object.quality_e_coli,
              "Enteroc NMP [100mL]":
                object.quality_e_coli === "nan"
                  ? "data not available"
                  : object.quality_e_coli,
              "Quality Classification Enteroc NMP [100mL]":
                object.quality_enteroc === "nan"
                  ? "data not available"
                  : object.quality_enteroc,
              "Surface Water Dissolved Oxygen Saturation (SWDOSP) %":
                object.od_porc_sup === "nan"
                  ? "data not available"
                  : object.od_porc_sup,
              "SWDOSP Quality Classification":
                object.quality_od_porc_sup === "nan"
                  ? "data not available"
                  : object.quality_od_porc_sup,
              Period:
                object.year === "nan" ? "data not available" : object.year,
            };
          });
        });
        setInnerData(inner);
      };

      const getData = (input: any[]) => {
        const listData = input.map((object: any) => {
          return object.data;
        });
        translateData(listData);
      };

      const topKeys = input.map((object: any, index: any) => {
        return {
          State: object.state,
          Municipality: object.municipality,
          "Monitoring Agency": object.basinorganization,
          Basin: object.basin,
          "Key Code": object["Key Code"],
          "Water Body": object.waterbody,
          "Waterbody Type": object.type_,
          "Waterbody Subtype": object.subtype,
          data: object.data,
        };
      });
      getData(topKeys);
    };

    const keyCodes = Object.keys(input);
    const siteData = Object.values(input);
    const transformedData = siteData.map((object: object, index: number) => {
      return { "Key Code": keyCodes[index], ...object };
    });
    translateTopKeys(transformedData);
  };

  useEffect(() => {
    global.setUserLogged(true);
    global.setHUCsMexico(true);
    global.setLoading(true);
    setType(getReportFromStorage());
    api(basinId)
      .then(({ body }: any) => {
        transformData(body);
        let array = [];
        for (const property in body) {
          array.push(body[property]);
        }
        setReports(array);
        localStorage.setItem(MEXICO_REPORTS, JSON.stringify(array));
      })
      .then(() => {
        const siteNames =
          reports && reports.map((report: any) => report.sitename).sort();
        updateContext?.(basinId, currentLocation, currentReport, "Mexico");
        global.setCheckFilters(siteNames);
      })
      .finally(() => global.setLoading(false));

    return () => global.setHUCsMexico(false);
  }, []);

  useEffect(() => {
    global.setLoading(true);
    setReports([]);
    api(basinId)
      .then(({ body }: any) => {
        let array = [];
        for (const property in body) {
          array.push(body[property]);
        }
        setReports(array);
        localStorage.setItem(MEXICO_REPORTS, JSON.stringify(array));
      })
      .then(() => {
        const siteNames =
          reports && reports.map((report: any) => report.sitename).sort();
        global.setCheckFilters(siteNames);
      })
      .finally(() => global.setLoading(false));
  }, [basinId]);

  useEffect(() => {
    if (reports) {
      flyToLocation(reports[0], 10);
      setActiveReport(reports[0]);
    }
  }, [reports]);

  const mapRef = useRef<any>();
  const { setCurrentRef } = useContext(RefContext);
  useEffect(() => {
    setCurrentRef?.(mapRef);
  }, [mapRef]);

  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : null;

  const centerBasin = () => {
    const coor = centroidData.filter((obj: any) => obj.BASIN_ID == basinId);
    coor && flyToLocation(coor[0], 9);
  };

  const renderReports = (input: any) => {
    return input
      .slice(back, next)
      .map((report: any, index: any) => (
        <ReportItem
          report={report}
          key={index}
          index={index}
          activeIndex={activeIndex}
          setActiveReport={setActiveReport}
          setActiveIndex={setActiveIndex}
          flyToLocation={flyToLocation}
        />
      ));
  };

  const pageBack = () => {
    let itemPerPage = 4;
    if (back > 0) {
      setNext((prev) => prev - itemPerPage);
      setBack((prev) => prev - itemPerPage);
      setActiveIndex((prev: any) => prev + itemPerPage);
    }
  };

  const pageNext = () => {
    let itemPerPage = 4;
    let itemSlice = reports && reports.slice(back, next).length;
    let terminalNum = reports && reports.length % itemPerPage;
    if (itemSlice !== terminalNum) {
      setNext((prev) => prev + itemPerPage);
      setBack((prev) => prev + itemPerPage);
      setActiveIndex((prev: any) => prev + itemPerPage);
    }
  };

  return (
    <>
      <FullScreenComp handle={fullscreenhandle}>
        <ReportHeader
          global={global}
          reportID={"SurfacewaterMexico-report"}
          fullScreenClickHandle={fullscreenhandle.enter}
        />
        <section className="mexico-sw-map-container">
          <div className="mexico-sw-map">
            <div className="basin-id">
              <h3>Basin ID: {basinId}</h3>
            </div>
            <button className="center-basin" onClick={centerBasin}>
              Center Basin
            </button>
            <Map
              longitude={global.viewport.longitude}
              latitude={global.viewport.latitude}
              zoom={global.viewport.zoom}
              mapboxAccessToken={settings.maboxKey}
              initialViewState={global.viewport}
              projection={global.globeView ? "globe" : "mercator" as any}
              mapStyle={global.mapStyle}
              ref={mapRef}
              onMove={(e) => {
                global.setViewport({
                  longitude: e.viewState.longitude,
                  latitude: e.viewState.latitude,
                  zoom: e.viewState.zoom,
                });
              }}
            >
              <FullscreenControl />
              <RenderLayerMarkers global={global} bounds={bounds} />
              <RenderLayers global={global} mapRef={mapRef} />
              {newList && newList.length > 0 ? (
                <ReportMarkers list={newList} />
              ) : (
                <ReportMarkers list={reports} />
              )}
            </Map>
            {global.terraClimateLayerSelected |
              global.evapotranspirationSelected && (
              <div className="range-slider">
                <Form.Range
                  min={2000}
                  max={2019}
                  value={global.MTCLValue}
                  onChange={(e) =>
                    global.setMTCLValue(parseInt(e.target.value))
                  }
                />
                <strong>Year: {global.MTCLValue}</strong>
              </div>
            )}
          </div>
        </section>
        <section className="mxsw-report-container">
          <h3
            style={{
              fontSize: "22px",
              color: "white",
              marginTop: "1rem",
              marginBottom: "-8px",
            }}
          >
            Site Name
          </h3>
          <div className="report-select">
            {reports && (
              <>
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  onClick={pageBack}
                  icon={faChevronLeft}
                />
                {newList && newList.length > 0
                  ? renderReports(newList)
                  : renderReports(reports)}
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  onClick={pageNext}
                  icon={faChevronRight}
                />
              </>
            )}
          </div>
          <div>
            <div className="mxsw-report-data-container">
              <Accordion className="site-info-accordion" defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Site Info</Accordion.Header>
                  <Accordion.Body>
                    <div className="station-info">
                      <h3 style={{ fontSize: "18px" }}>Site Info</h3>
                      {activeReport && type.name === "Surfacewater Mexico" && (
                        <div className="station-container">
                          <div>
                            <h5>
                              <strong>Basin:</strong> {activeReport.basin}
                            </h5>
                            <h5>
                              <strong>Organization:</strong>{" "}
                              {activeReport.basinorganization}
                            </h5>
                            <h5>
                              <strong>Municipality:</strong>{" "}
                              {activeReport.municipality}
                            </h5>
                            <h5>
                              <strong>State:</strong> {activeReport.state}
                            </h5>
                          </div>
                          <div>
                            <h5>
                              <strong>Subtype:</strong> {activeReport.subtype}
                            </h5>
                            <h5>
                              <strong>Waterbody:</strong>{" "}
                              {activeReport.waterbody}
                            </h5>
                            <h5>
                              <strong>Type:</strong> {activeReport.type_}
                            </h5>
                          </div>
                        </div>
                      )}
                      {activeReport && type.name === "Groundwater Mexico" && (
                        <ul className="mx-data-list">
                          <li className="mx-data-item">
                            <strong>Aquifer:</strong> {activeReport.aquifer}
                          </li>
                          <li className="mx-data-item">
                            <strong>Organization:</strong>{" "}
                            {activeReport.basinorganization}
                          </li>
                          <li className="mx-data-item">
                            <strong>Municipality:</strong>{" "}
                            {activeReport.municipality}
                          </li>
                          <li className="mx-data-item">
                            <strong>State:</strong> {activeReport.state}
                          </li>
                          <li className="mx-data-item">
                            <strong>Subtype:</strong> {activeReport.subtype}
                          </li>
                        </ul>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="report-table-container">
              <div className="table-wrapper">
                {tableColumns && tableData && (
                  <ReportTable data={tableData} columns={tableColumns} />
                )}
              </div>
            </div>
          </div>
          <div className="report-footer-wrapper">
            <ReportFooter />
          </div>
        </section>
      </FullScreenComp>
    </>
  );
};

export default MexicoReport;
