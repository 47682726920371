import React, { useState, useMemo, useEffect } from 'react';
import { Form, Button, Row, Col, Nav, Modal, Pagination } from 'react-bootstrap';
import ReportTableExpanded from '../reports/shared/ReportTableExpanded';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faGreaterThan, faGear, faCancel, faRectangleXmark, faRotate, faTrashCan, faList, faTableCellsLarge } from '@fortawesome/pro-solid-svg-icons';
import { faUser, faUsers, faSliders, faBuilding, faTarpDroplet, faHandHoldingDroplet,  faDraftingCompass, faArrowUpFromGroundWater,  faMagnifyingGlassLocation, faGlobe, faHouse, faSeedling, faDroplet } from '@fortawesome/pro-solid-svg-icons';
import ReportsConfigs from '../../ReportsConfigs';

import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';


export default function UserProfiles(props: any){
	const { global } = props;

    
    const settingsConfiguration = [] as any;
    let reportCollection = ['Water Statistics & Measures', "Climate and Economic Justice Screening Tool", 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 1, title: 'Researcher', description: 'Includes:', ic: faMagnifyingGlassLocation, reportCollection: reportCollection })
    
    reportCollection = ['What’s in my Drinking Water?', 'Drinking Water True QI Report', 'Water Statistics & Measures', "Climate and Economic Justice Screening Tool", 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 10, title: 'Regulator', description: 'Includes:', ic: faSliders, reportCollection: reportCollection })
    
    reportCollection = ['Permit Discharge Report', 'Drinking Water True QI Report', 'Water Statistics & Measures', "Climate and Economic Justice Screening Tool", 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 20, title: 'Operator', description: 'Includes:', ic: faArrowUpFromGroundWater, reportCollection: reportCollection })
    
    reportCollection = ['AG Water True QI Forecast', 'What’s in my Drinking Water?', 'Drinking Water True QI Report', 'Water Statistics & Measures', "Climate and Economic Justice Screening Tool", 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 30, title: 'Agriculture Manager', description: 'Includes:', ic: faSeedling, reportCollection: reportCollection })

    reportCollection = ['AG Water True QI Forecast', 'What’s in my Drinking Water?', 'Drinking Water True QI Report', 'Water Statistics & Measures', "Climate and Economic Justice Screening Tool", 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 40, title: 'Commericial Manager', description: 'Includes:', ic: faBuilding, reportCollection: reportCollection })

    reportCollection = ['AG Water True QI Forecast', 'What’s in my Drinking Water?', 'Drinking Water True QI Report', 'Water Statistics & Measures', "Climate and Economic Justice Screening Tool", 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 50, title: 'Planning Engineer', description: 'Includes:', ic: faDraftingCompass, reportCollection: reportCollection })
   
    reportCollection = ['AG Water True QI Forecast', 'What’s in my Drinking Water?', 'Drinking Water True QI Report', 'Water Statistics & Measures', "Climate and Economic Justice Screening Tool", 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 70, title: 'Starter Profile', description: 'Includes:', ic: faDroplet, reportCollection: reportCollection })

    reportCollection = ['AG Water True QI Forecast', 'What’s in my Drinking Water?', 'Drinking Water True QI Report', 'Water Statistics & Measures', "Climate and Economic Justice Screening Tool", 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 80, title: 'Citizen Waterkeeper', description: 'Includes:', ic: faHandHoldingDroplet, reportCollection: reportCollection })

    settingsConfiguration.sort((x: any, y: any) => x['title'].localeCompare(y['title']));

    const [reportData, setReportData] = useState<any[]>(settingsConfiguration);
    const [filteredData, setFilteredData] = useState<any[]>(settingsConfiguration);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteMode, setDeleteMode] = useState<string>('');
    const [isDataReportLoaded, setIsDataReportLoaded] = useState(false);

    const [currentUser, setCurrentUser] = useState<object>({});
    const [editUser, setEditUser] = useState<number>(-1);
    const [deleteUser, setDeleteUser] = useState<number>(-1);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    
 


                  
    useEffect(() => {
        if(editUser > -1){
            let fData = [...settingsConfiguration].filter((o: any) => +o.id === +editUser);
            if(fData && fData.length > 0){
                setCurrentUser(fData[0])
                navigate('/add-profile', { replace: false, state:{mode: 'edit',  user : fData[0], settingsConfiguration: settingsConfiguration}});
            } 
        }
    }, [editUser]);

    useEffect(() => {
        if(deleteUser > -1){
            let fData = [...filteredData].filter((o: any) => +o.id === +deleteUser);
            if(fData && fData.length > 0){
                setShowDeleteModal(true);
                setDeleteMode('user'); 
                setCurrentUser(fData[0])
            }
        }
    }, [deleteUser]);

    const tableColumns = useMemo(
        () => [
          { Header: 'Name', accessor: 'title' },
          { Header: 'Includes', accessor: 'reportCollection', 
            Cell: (props: any) => (
                props.value.toString()
            ),
          },
          {
            Header: 'Actions',
            accessor: 'id',
            Cell: (props: any) => (
              <div className='ct-table-container-button' style={{width: 130}}>
                <Button
                    type='button'
                    className='ct-table-button'
                    onClick={clickEditUserHandler}
                    value={props.value}
                    id={'edituser_' + props.value}>
                       
                    <FontAwesomeIcon icon={faPenToSquare} size='xs' />
                </Button>
                <Button
                    type='button'
                    className='ct-table-button'
                    onClick={clickDeleteHandler}
                    value={props.value}
                    id={'deleteuser_' + props.value}>
                    <FontAwesomeIcon icon={faTrashCan} size='xs' />
                </Button>
              </div>
            ),
          }
        ],
        []
    );
    
    const clickRowHandler = (event: any) => {

    };
    

    const clickAddUserHandler = (event: any) => {
        navigate('/add-profile', { replace: false, state:{mode: 'add',  settingsConfiguration : settingsConfiguration, ReportsConfigs:ReportsConfigs}});
    };

    const clickEditUserHandler = (event: any) => {
        if (event && event.target && event.target.value) {
            setEditUser(event.target.value);
        }
    };

    const handleDeleteCancel = () => {
        setShowDeleteModal(false);
    };
    
    const handleDeleteConfirm = (event:any) => {  
        setShowDeleteModal(false); 

        let cuid = 11111111;        
        if(currentUser) {
            // @ts-ignore
            cuid = +currentUser.id;
        }
        setDeleteMode('');
     
        let rData = [...reportData].filter((o: any) => +o.id !== +cuid);
        if(rData && rData.length > 0){
            setReportData(rData)
            setCurrentUser([])
        }   

        let fData = [...filteredData].filter((o: any) => +o.id !== +cuid);
        if(fData && fData.length > 0){
            setFilteredData(fData)
        } 
    };
    
    const clickDeleteHandler = (event: any) => {      
        if (event && event.target && event.target.value) {
            setDeleteUser(event.target.value);
        }
    };

    const onChangeSearch = (event: any) => {
        if (event && event.target && event.target.value && event.target.value.length > 2) {
            let fData = [...reportData].filter((o: any) => o.title.indexOf(event.target.value) > -1);
            setFilteredData(fData);
        } else {
            setFilteredData(reportData);
        }       
    };

	return (
		<div className='userprofiles'>
            <Modal show={showDeleteModal} onHide={handleDeleteCancel}>
              <Modal.Header closeButton>
                <Modal.Title>Confirm deletion</Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button variant="secondary"  onClick={handleDeleteCancel}>
                  Cancel
                  </Button>
                <Button variant="primary" id={'confirm' + 'dashtimsestamp'} onClick={handleDeleteConfirm}>
                  Delete
                </Button>
              </Modal.Footer>  
            </Modal>   

            <div className='users-container'>                
                <div className='users-tool-container'>
                    <Form.Group  className='users-search' controlId="formSearchProfiles">
                        <Form.Label></Form.Label>
                        <Form.Control onChange={onChangeSearch} type="text" placeholder="Search" />
                    </Form.Group>
                    <Button className='users-button' onClick={clickAddUserHandler}>
                        Add Profile
                    </Button>
                </div>

                <div className='table-parent-container'>
                        <ReportTableExpanded
                            data={filteredData}
                            columns={tableColumns}
                            clickRowHandler={clickRowHandler}
                            clickLoadStop={true}
                        />
                </div>
            </div>

		</div>
	);

}



