import settings from "../../../settings.json";

const baseEvaluations = ['Overview', 'Climate Change Precipitation Risk', 'Site Water Security / Distress', 'Site Flood Zone Ratings', 'Site Surface Water Quality Threshold', 'PFAS Detection in Proximity', 'Superfund in Close Proximity', 'Drought Status', 'Located within a Protected Area', 'Riverine Flood Risk', 'Coastal Flood Risk', 'Wildfire'];



const base440a2 =  'd Risk';
//baseEvaluations and active in RiskCategories set default active tabs
const RiskGroupings = [{ 
    title: 'Food',
    label: 'Food and Beverage',
    type : 'Sector',
    code : 'FB',
    evaluations: baseEvaluations.concat(['Water Management', 'Ingredient Sourcing'])
  }, {
    title: 'Agriculture Products',
    label: 'Agriculture Products',
    type : 'Industry',
    code : 'AG',
    sectorParent: 'FB', 
    sectorParentTitle: 'Food and Beverage', 
    sasbsections: ['140a.1', '440a.1'], 
    s140a1: '(1) Total water withdrawn, (2) total water consumed; percentage of each in regions with High or Extremely High Baseline Water Stress',
    s140a1_fields: [{active: 'true', title:'Water Consumed'}, {active: 'true', title:'Water Withdrawn'}],
    s140a1_unit: 'thousands of cubic meters',
    s440a2 : 'Percentage of agricultural raw materials sourced from regions with High or Extremely High Baseline Water Stress',
    s440a1_fields: [{active: 'true', title:'Agriculture Products'}],
    s440a1_unit: 'By Cost',
    s440a1_label : 'Percentage of agricultural raw materials sourced from regions with High or Extremely High Baseline Water Stress',
    evaluations: baseEvaluations.concat(['Water Management', 'Ingredient Sourcing'])
  }, {
    title: 'Non-Alcoholic Beverages',
    label: 'Non-Alcoholic Beverages',
    type : 'Industry',
    code : 'NB',
    sectorParent: 'FB', 
    sectorParentTitle: 'Food and Beverage', 
    sasbsections: ['140a.1', '440a.1'], 
    s140a1: '(1) Total water withdrawn, (2) total water consumed; percentage of each in regions with High or Extremely High Baseline Water Stress',
    s140a1_fields: [{active: 'true', title:'Water Consumed'}, {active: 'true', title:'Water Withdrawn'}],
    s140a1_unit: 'thousands of cubic meters',
    s440a1 : 'Percentage of beverage ingredients sourced from regions with High or Extremely High Baseline Water Stress',
    s440a1_fields: [{active: 'true', title:'Beverage Ingredients'}],
    s440a1_unit: 'By Cost',
    s440a1_label : 'Percentage of beverage ingredients sourced from regions with High or Extremely High Baseline Water Stress',
    evaluations: baseEvaluations.concat(['Water Management', 'Ingredient Sourcing'])
  }, {
    title: 'Alcoholic Beverages',
    label: 'Alcoholic Beverages',
    type : 'Industry',
    code : 'AB',
    sectorParent: 'FB', 
    sectorParentTitle: 'Food and Beverage',
    sasbsections: ['140a.1', '440a.1'], 
    s140a1: '(1) Total water withdrawn, (2) total water consumed; percentage of each in regions with High or Extremely High Baseline Water Stress',
    s140a1_fields: [{active: 'true', title:'Water Consumed'}, {active: 'true', title:'Water Withdrawn'}],
    s140a1_unit: 'thousands of cubic meters',
    s440a1 : 'Percentage of beverage ingredients sourced from regions with High or Extremely High Baseline Water Stress',
    s440a1_fields: [{active: 'true', title:'Beverage Ingredients'}],
    s440a1_unit: 'By Cost',
    s440a1_label : 'Percentage of beverage ingredients sourced from regions with High or Extremely High Baseline Water Stress',
    evaluations: baseEvaluations.concat(['Water Management', 'Ingredient Sourcing'])
  }, {
    title: 'Meat, Poultry & Dairy',
    label: 'Meat, Poultry & Dairy',
    type : 'Industry',
    code : 'MP',
    sectorParent: 'FB',
    sectorParentTitle: 'Food and Beverage',
    sasbsections: ['140a.1', '440a.1'], 
    s140a1: '(1) Total water withdrawn, (2) total water consumed; percentage of each in regions with High or Extremely High Baseline Water Stress',
    s140a1_fields: [{active: 'true', title:'Water Consumed'}, {active: 'true', title:'Water Withdrawn'}],
    s140a1_unit: 'thousands of cubic meters',
    s440a1 : 'Percentage of animal feed sourced from regions with High or Extremely High Baseline Water Stress',
    s440a1_fields: [{active: 'true', title:'Animal Feed'}],
    s440a1_unit: 'Per Metric Ton',
    s440a1_label : 'Percentage of animal feed sourced from regions with High or Extremely High Baseline Water Stress',
    s440a2 : 'Percentage of contracts with producers located in regions with High or Extremely High Baseline Water Stress',
  
    evaluations: baseEvaluations.concat(['Water Management', 'Ingredient Sourcing'])
  }, {
    title: 'Restaurants',
    label: 'Restaurants',
    type : 'Industry',
    code : 'RN',
    sectorParent: 'FB', 
    sectorParentTitle: 'Food and Beverage',
    sasbsections: ['140a.1'],
    s140a1: '(1) Total water withdrawn, (2) total water consumed; percentage of each in regions with High or Extremely High Baseline Water Stress',
    s140a1_fields: [{active: 'true', title:'Water Consumed'}, {active: 'true', title:'Water Withdrawn'}],
    s140a1_unit: 'thousands of cubic meters',
    evaluations: baseEvaluations.concat(['Water Management'])
  }, {
    title: 'Processed Foods',
    label: 'Processed Foods',
    type : 'Industry',
    code : 'PF',
    sectorParent: 'FB', 
    sectorParentTitle: 'Food and Beverage',
    sasbsections: ['140a.1', '440a.1'],
    s140a1: '(1) Total water withdrawn, (2) total water consumed; percentage of each in regions with High or Extremely High Baseline Water Stress',
    s140a1_fields: [{active: 'true', title:'Water Consumed'}, {active: 'true', title:'Water Withdrawn'}],
    s140a1_unit: 'thousands of cubic meters',
    s440a1 : 'Percentage of food ingredients sourced from regions with High or Extremely High Baseline Water Stress',
    s440a1_fields: [{active: 'true', title:'Food Ingredients'}],
    s440a1_unit: 'By Cost',
    evaluations: baseEvaluations.concat(['Water Management', 'Ingredient Sourcing'])
  }, { 
    title: 'Services',
    label: 'Services',
    type : 'Sector',
    code : 'SV',
    evaluations: baseEvaluations.concat(['Water Management'])
  }, {
    title: 'Hotels & Lodging',
    label: 'Hotels & Lodging',
    type : 'Industry',
    code : 'HL',
    sectorParent: 'SV', 
    sectorParentTitle: 'Services',
    sasbsections: ['140a.1', '160a.1', '450a.1'],
    s140a1: '(1) Total water withdrawn, (2) total water consumed; percentage of each in regions with High or Extremely High Baseline Water Stress',
    s140a1_fields: [{active: 'true', title:'Water Consumed'}, {active: 'true', title:'Water Withdrawn'}],
    s140a1_unit: '',
    s160a1 : 'Number of Lodging Facilities located in or near areas of protected conservation status or endangered species habitat',
    s160a1_label : 'Lodging Facilities located in or near areas of protected conservation status or endangered species habitat',
    s160a1_fields: [{active: 'true', title:'Lodging Facilities'}],
    s160a1_unit: '',
    s450a1 : 'Number of Lodging Facilities located in 100-year flood zones',
    s450a1_label : 'Lodging Facilities located in 100-year flood zones',
    s450a1_fields: [{active: 'true', title:'Lodging Facilities'}],
    s450a1_unit: '',
    evaluations: baseEvaluations.concat(['Water Management']),
  }
  
];

export default RiskGroupings;
