import { useEffect, useState, useMemo } from 'react'
import NOAASeaLevelRise from "../../../components/Layers/NOAASeaLevelRise"
import { adjustHexBrightness } from '../../utils'

const RenderSeaLevelRise = ({ index, tileSet, sourceLayer, id, global }: any) => {

    const [layers, setLayers] = useState([])

    
    const handleFlood = (index: number, opacity: number, brightness: number) => {
        const NSLRComponents = [
            <NOAASeaLevelRise
                key={'a'}
                id={`sea-level0-${id}`}
                beforeId="low"
                opacity={opacity}
                outlineColor={'transparent'}
                tileSet={tileSet}
                sourceLayer={sourceLayer}
                fillColor={
                    [
                        "match",
                        ["get", "ft"],
                        ["0ft"],
                        `${adjustHexBrightness("#040E62", brightness)}`,
                        `transparent`
                    ]}
            />,
            <NOAASeaLevelRise
                key={`b`}
                id={`sea-level1-${id}`}
                beforeId={`sea-level0-${id}`}
                opacity={opacity}
                outlineColor={'transparent'}
                tileSet={tileSet}
                sourceLayer={sourceLayer}
                fillColor={[
                    "match",
                    ["get", "ft"],
                    ["1ft"],
                    `${adjustHexBrightness("#051498", brightness)}`,
                    `transparent`
                ]}
            />,
            <NOAASeaLevelRise
                key={`c`}
                id={`sea-level2-${id}`}
                beforeId={`sea-level1-${id}`}
                opacity={opacity}
                outlineColor={'transparent'}
                tileSet={tileSet}
                sourceLayer={sourceLayer}
                fillColor={[
                    "match",
                    ["get", "ft"],
                    ["2ft"],
                    `${adjustHexBrightness("#061BD3", brightness)}`,
                    `transparent`
                ]}
            />,
            <NOAASeaLevelRise
                key={`d`}
                id={`sea-level3-${id}`}
                beforeId={`sea-level2-${id}`}
                opacity={opacity}
                outlineColor={'transparent'}
                tileSet={tileSet}
                sourceLayer={sourceLayer}
                fillColor={[
                    "match",
                    ["get", "ft"],
                    ["3ft"],
                    `${adjustHexBrightness("#0718A8", brightness)}`,
                    `transparent`
                ]}
            />,
            <NOAASeaLevelRise
                key={`e`}
                id={`sea-level4-${id}`}
                beforeId={`sea-level3-${id}`}
                opacity={opacity}
                outlineColor={'transparent'}
                tileSet={tileSet}
                sourceLayer={sourceLayer}
                fillColor={[
                    "match",
                    ["get", "ft"],
                    ["4ft"],
                    `${adjustHexBrightness("#643EE5", brightness)}`,
                    `transparent`
                ]}
            />,
            <NOAASeaLevelRise
                key={`f`}
                id={`sea-level5-${id}`}
                beforeId={`sea-level4-${id}`}
                opacity={opacity}
                outlineColor={'transparent'}
                tileSet={tileSet}
                sourceLayer={sourceLayer}
                fillColor={[
                    "match",
                    ["get", "ft"],
                    ["5ft"],
                    `${adjustHexBrightness("#5B6AE7", brightness)}`,
                    `transparent`
                ]}
            />,
            <NOAASeaLevelRise
                key={`g`}
                id={`sea-level6-${id}`}
                beforeId={`sea-level5-${id}`}
                opacity={opacity}
                outlineColor={'transparent'}
                tileSet={tileSet}
                sourceLayer={sourceLayer}
                fillColor={[
                    "match",
                    ["get", "ft"],
                    ["6ft"],
                    `${adjustHexBrightness("#3D7BE5", brightness)}`,
                    `transparent`
                ]}
            />,
            <NOAASeaLevelRise
                key={`h`}
                id={`sea-level7-${id}`}
                beforeId={`sea-level6-${id}`}
                opacity={opacity}
                outlineColor={'transparent'}
                tileSet={tileSet}
                sourceLayer={sourceLayer}
                fillColor={[
                    "match",
                    ["get", "ft"],
                    ["7ft"],
                    `${adjustHexBrightness("#17ABE6", brightness)}`,
                    `transparent`
                ]}
            />,
            <NOAASeaLevelRise
                key={`i`}
                id={`sea-level8-${id}`}
                beforeId={`sea-level7-${id}`}
                opacity={opacity}
                outlineColor={'transparent'}
                tileSet={tileSet}
                sourceLayer={sourceLayer}
                fillColor={[
                    "match",
                    ["get", "ft"],
                    ["8ft"],
                    `${adjustHexBrightness("#5FC5E8", brightness)}`,
                    `transparent`
                ]}
            />,
            <NOAASeaLevelRise
                key={`j`}
                id={`sea-level9-${id}`}
                beforeId={`sea-level8-${id}`}
                opacity={opacity}
                outlineColor={'transparent'}
                tileSet={tileSet}
                sourceLayer={sourceLayer}
                fillColor={[
                    "match",
                    ["get", "ft"],
                    ["9ft"],
                    `${adjustHexBrightness("#8DE0E8", brightness)}`,
                    "transparent"
                ]}
            />,
            <NOAASeaLevelRise
                key={`k`}
                id={`sea-level10-${id}`}
                beforeId={`sea-level9-${id}`}
                opacity={opacity}
                outlineColor={'transparent'}
                tileSet={tileSet}
                sourceLayer={sourceLayer}
                fillColor={[
                    "match",
                    ["get", "ft"],
                    ["10ft"],
                    `${adjustHexBrightness("#B1E3E8", brightness)}`,
                    `transparent`
                ]}
            />
        ]

        
        setLayers([])
        for (let i = 0; i < index; i++) {
            // @ts-ignore
            setLayers(prev => [...prev].concat(NSLRComponents[i]))
        }  
    }

    

    

    useEffect(() => {
        handleFlood(index + 1, global.NOAASeaLevelRiseOpacity, global.NOAASeaLevelRiseBrightness)
    }, [
        index,
        global.NOAASeaLevelRiseOpacity, 
        global.NOAASeaLevelRiseBrightness
    ])

    return (
        <>
            <NOAASeaLevelRise
                key={`l`}
                id="low"
                opacity={global.NOAASeaLevelRiseOpacity}
                outlineColor={'transparent'}
                tileSet={tileSet}
                sourceLayer={sourceLayer}
                fillColor={[
                    "match",
                    ["get", "type"],
                    ["low"],
                    `${adjustHexBrightness("#08C64D", global.NOAASeaLevelRiseBrightness)}`,
                    `transparent`
                ]}
            />
            {layers.map(ele => ele)}
        </>
    )
}

export default RenderSeaLevelRise
