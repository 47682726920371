import { Layer, Source } from 'react-map-gl'
import { useEffect, useState, useContext } from 'react'
import { RefContext } from '../../../RefContext'
import { v4 as uuid } from 'uuid';

const TerraclimateLayer = ({
  global,
  fillColor,
  sourceUrl,
  terraSource,
  opacity
}: any) => {

  console.log(opacity)
  
  const [filter, setFilter] = useState(["==", "year", 2019])
  const { currentRef } = useContext(RefContext)

  const years = [
    2000, 2001, 2002, 2003, 2004,
    2005, 2006, 2007, 2008, 2009,
    2010, 2011, 2012, 2013, 2014,
    2015, 2016, 2017, 2018, 2019
  ]

  const handleInitialPosition = ({ longitude, latitude }: any, zoom: any) => {
    // @ts-ignore
    if (currentRef?.current) {
      // @ts-ignore
      currentRef.current.flyTo({
        center: [
          longitude,
          latitude,
        ],
        essential: true,
        zoom,
      });
    }
  }

  useEffect(() => {
  
    handleInitialPosition(global.viewport, 2)

    return () => {
      handleInitialPosition(global.viewport, 5)
    }

  }, [currentRef])

  useEffect(() => {
    setFilter(["==", "year", global.MTCLValue])
  }, [global.MTCLValue])


  const renderLayers = () => {
    return years.map((year: any) => {
      const terraLayer = {
        id: `terraclimate_${year}`,
        source: `${terraSource}${year}`,
        "source-layer": `${terraSource}${year}`,
        type: "fill",
        filter: filter,
        paint: {
          "fill-color": fillColor,
          "fill-opacity": opacity,
        },
        maxzoom: 5
      };
      return (
        <Source
          id={`terraclimate_${year}`}
          type="vector"
          url={`${sourceUrl}${year}`}
          key={uuid()}
        >
          {/* @ts-ignore */}
          <Layer {...terraLayer} />
        </Source>
      )

    })
  }


  return (
    <>
      {renderLayers()}
    </>
  )
}

export default TerraclimateLayer