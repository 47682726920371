// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userlayer-popup .mapboxgl-popup-content div {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.top .img_box .img {
  display: block;
  height: 75px;
  width: 75px; }
`, "",{"version":3,"sources":["webpack://./src/components/UserLayer/user-layer.scss"],"names":[],"mappings":"AAAA;EAGK,aAAa;EACb,sBAAsB;EACtB,uBAAuB,EAAA;;AAK5B;EAGG,cAAc;EACd,YAAY;EACZ,WAAW,EAAA","sourcesContent":[".userlayer-popup {\n\t.mapboxgl-popup-content {\n\t  div {\n\t    display: flex;\n\t    flex-direction: column;\n\t    justify-content: center;\n\t  }\n\t}\n}\n\n.top {\n\t.img_box {\n\t\t.img {\n\t\t\tdisplay: block;\n\t\t\theight: 75px;\n\t\t\twidth: 75px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
