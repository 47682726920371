import {
    useEffect,
    useState,
} from "react";
import {
    Marker,
    Layer,
    Source,
    Popup,
    CircleLayer
} from "react-map-gl";
import { useNavigate } from "react-router-dom";
import {
    Feature,
    GeoJsonProperties,
    Point,
    FeatureCollection,
} from 'geojson';
import {
    fitBoundsMapData,
} from '../../features/reports/shared/report-data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'

import './user-layer.scss'

const UserLayer = ({ global, mapRef, data, id, apple, CWA = undefined, color = "orange", opacity = 0.7 }: any) => {
    const [dataArray, setDataArray] = useState<any>();
    const [featureData, setFeatureData] = useState<FeatureCollection>();
    const [features, setFeatures] = useState<any>();
    const [cwaPopup, setCWAPopup] = useState<any>();

    const navigate = useNavigate();

    const tabActive = 'Overview';
    const mapHoverFilter = ['in', ['get', 'name'], ''];

    const newFeatureCollection = (fData: any) => {
        const features: Array<Feature<Point, GeoJsonProperties>> = [];
        for (let dataItem of fData) {
            if (dataItem) {
                let huc12Item = dataItem;
                if (huc12Item) {
                    const featureWithPoint: Feature<Point> = {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [+huc12Item.Longitude, +huc12Item.Latitude],
                        },
                        properties: dataItem,
                    };
                    if (featureWithPoint && featureWithPoint.properties) {
                        featureWithPoint.properties.lat = huc12Item.Latitude;
                        featureWithPoint.properties.lng = huc12Item.Longitude;
                    }
                    features.push(featureWithPoint);
                }
            }
        }
        const featureCollectionFromReportData: FeatureCollection = {
            type: 'FeatureCollection',
            features: features,
        };
        return featureCollectionFromReportData;
    };

    const handleFeatureData = (arr: any, setFcn: any) => {
        const newFeatureSet: any = newFeatureCollection([...arr]);
        setFcn(newFeatureSet);

        if (newFeatureSet.features.length > 0) {
            if (mapRef && mapRef.current) {
                const mapRef_ = mapRef;
                setTimeout(() => {
                    fitBoundsMapData(newFeatureSet, mapRef_);
                }, 400);
            }
        }
    }

    useEffect(() => {
        if (dataArray) handleFeatureData(dataArray, setFeatureData);
    }, [dataArray]);

    const handleClose = () => {
        setCWAPopup(undefined);
        setFeatures(undefined);
    };

    type CWA = {
        Description: string;
        Name: string;
        ID: number;
        Image_link: string;
        Type: string;
        lat: number;
        lng: number;
    }

    const linkStyleCWA = {
        color: '#4fc2d9',
        fontWeight: 'bold',
        textDecoration: 'underline',
        borderRadius: '6px'
    }

    const linkStyle = {
        color: '#4fc2d9',
        fontWeight: 'bold',
        textDecoration: 'underline',
        padding: '0.5rem',
        borderRadius: '6px'
    }

    const createCWAPopup = ({ Description, Name, ID, Image_link, Type, lat, lng }: CWA) => {
        setCWAPopup(
            <Popup
                longitude={lng}
                latitude={lat}
                style={{ width: '350px' }}
                anchor="bottom"
                onClose={handleClose}
            >
                <FontAwesomeIcon style={{ position: 'absolute', right: "3%", top: "4%", cursor: 'pointer' }} onClick={handleClose} icon={faXmark} />
                <section className="top">
                    <div>
                      <p>Name: <strong>{Name}</strong></p>
                      <p>ID: <strong>{ID}</strong></p>
                      <p>Type: <strong>{Type}</strong></p>
                      <p>Description: {Description}</p>
                    </div> 
                </section>
                <section className="bottom">
                    <a href={Image_link} target={"_blank"} style={linkStyleCWA} >Image Link</a>
                </section>       
            </Popup>
        )
    };

    const lngLat = global.action && global.action.lngLat

    useEffect(() => {
        if (CWA && features && lngLat) {
            const data = { ...lngLat, ...features}
            createCWAPopup(data);
        }
    }, [CWA, features, lngLat]);

    useEffect(() => {
        if (data) setDataArray(data);
    }, [data]);

    const mapInstance = mapRef.current;
/*
    useEffect(() => {
        let img;
        const fetchData = async () => {
            await fetch('https://drive.usercontent.google.com/download?id=1EEzYND237aNzx6aM5ZYEZ-bQsJInCcI6')
            .then((response:any) => response.blob())
            .then((blob:any) => {
                const objectURL = URL.createObjectURL(blob);
                // Use the objectURL to display the image
                img = objectURL; // Log the objectURL for testing
              })
              .catch(error => {
                console.error('Error fetching image:', error);
              });
        }
        fetchData()
    },[])*/

    useEffect(() => {
        mapInstance && mapInstance.on('click', (event: any) => {
            let renderedFeatures = mapInstance.queryRenderedFeatures(event.point)?.[0];
            if(renderedFeatures && renderedFeatures.properties && renderedFeatures.source  && renderedFeatures.source.substr(0,12)==="custom-layer"){
                setFeatures(renderedFeatures.properties);
            }
        });
    }, [mapInstance]);

    const pointColor = features && features[global.customDataLayerValue];

    const layerStylePoint: CircleLayer = {
        id: 'custom-layer' + id,
        type: 'circle' as const,
        paint: {
            'circle-radius': 12,
            'circle-color': pointColor ? pointColor : "#4fc22c",
            'circle-stroke-color': 'white',
            'circle-stroke-width': 2,
            'circle-opacity': 0.7,
        },
    };
    
    const Location_Name = features && features.Location_Name
    const lat = features && features.lat
    const lng = features && features.lng

    return (
        <>
            {featureData && (
                <Source id={('custom-layer' + id)} type='geojson' data={featureData} key={tabActive}>
                    <Layer {...layerStylePoint} />
                </Source>
            )}
            {!apple && features && features.Longitude && features.Latitude && (
                <Marker
                    longitude={parseFloat(features?.Longitude)}
                    latitude={parseFloat(features?.Latitude)}
                    anchor="bottom"
                    style={{
                        cursor: 'pointer',
                        background: '#002044',
                        color: "white",
                        border: '1px solid #ccc',
                        padding: '8px',
                        borderRadius: '4%',
                        textAlign: 'center'
                    }}
                >
                    <h5>{features.name}</h5>
                    <a
                        onClick={() => navigate('/Risk')}
                        style={linkStyle}
                    >
                        Go to Report
                    </a>
                </Marker>
            )}
            {cwaPopup}
        </>
    )
}

export default UserLayer;