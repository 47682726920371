//DEPRECATED
import { useEffect, useState, useRef, useCallback, useContext, useMemo, Ref } from "react";
import { Form, Button, Row, Col, Table } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import './styles.scss';
import InputForm from '../../../components/input-form';
import Map, {
  FullscreenControl,
  Source,
  Layer,
  NavigationControl,
  GeolocateControl,
  useControl,
} from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import settings from '../../../settings.json';
import Render from '../../../components/render';
import MapAddition from  '../shared/MapAddition';
import { RefContext } from '../../../RefContext'
//--IMAGES
import companyIcon from '../../../assets/company-icon.svg';
import arrowDownIcon from '../../../assets/arrow-down-icon.svg';
import arrowUpIcon from '../../../assets/arrow-up-icon.svg';
import arrowDownDoubleIcon from '../../../assets/arrow-down-double-icon.svg';
import arrowDownDoubleTreeIcon from '../../../assets/arrow-down-double-tree-icon.svg';
import fullscreenIcon from '../../../assets/fullscreen-icon.svg';
import ellipsisIcon from '../../../assets/ellipsis-icon.svg';

export default function WaterStatisticsMeasures(props: any) {
  const { global } = props;
  global.setUserLogged(true);
  if(global.trackerOn) global.setTrackerOn(false)

  const [dateRange, setDateRange] = useState();
  const [dateIni, setDateIni] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [HUC8, setHUC8] = useState();
  const [source, setSource] = useState();
  const [facilityName, setFacilityName] = useState();

  const dummieData = [
    {
      data: ['Surface Water', '8.10', '', '', '', '', '', '', '', ''],
      sub: [
        [
          'Wednesday, April 1, 2020',
          '5.13',
          '',
          '',
          '',
          '7.85',
          '0.07',
          '148.60',
          '79.70',
          '',
        ],
        [
          'Monday, June 29, 2020',
          '5.54',
          '',
          '',
          '',
          '7.85',
          '0.07',
          '151.60',
          '79.70',
          '',
        ],
        ['Monday, July 13, 2020', '5.16', '', '', '', '', '', '', '', ''],
        [
          'Wednesday, December 9, 2020',
          '9.26',
          '',
          '',
          '',
          '7.85',
          '0.07',
          '151.60',
          '79.70',
          '',
        ],
      ],
    },
    {
      data: [
        'Deep Creek @ HYWY 44',
        '',
        '',
        '',
        '',
        '6.84',
        '0.08',
        '169.00',
        '62.96',
        '',
      ],
      sub: [
        [
          'Wednesday, April 1, 2020',
          '5.13',
          '',
          '',
          '',
          '7.85',
          '0.07',
          '148.60',
          '79.70',
          '',
        ],
        [
          'Monday, June 29, 2020',
          '5.54',
          '',
          '',
          '',
          '7.85',
          '0.07',
          '151.60',
          '79.70',
          '',
        ],
        ['Monday, July 13, 2020', '5.16', '', '', '', '', '', '', '', ''],
        [
          'Wednesday, December 9, 2020',
          '9.26',
          '',
          '',
          '',
          '7.85',
          '0.07',
          '151.60',
          '79.70',
          '',
        ],
      ],
    },
  ];

  return (
    <div className='water-statistics-measures'>
      <h2>Water Statics {'&'} Measures</h2>
      <div className='filter-container form'>
        {/* <DateRangePicker
          initialSettings={{ startDate: "1/1/2014", endDate: "3/1/2014" }}
        >
          <input className="form-control date-range" />
        </DateRangePicker> */}
        <InputForm value={HUC8} setValue={setHUC8} placeholder='HUC8' />
        <InputForm value={source} setValue={setSource} placeholder='Source' />
        <InputForm
          value={facilityName}
          setValue={setFacilityName}
          placeholder='Facility Name'
        />
        <Button className='fullscreen'>
          <img src={fullscreenIcon} />
        </Button>
        <Button className='more-options'>
          <img src={ellipsisIcon} />
        </Button>
      </div>
      <div className='container'>
        <div className='map-container'>
          <Map
            mapboxAccessToken={settings.maboxKey}
            initialViewState={{
              longitude: settings.defaultLocationMap.lng,
              latitude: settings.defaultLocationMap.lat,
              zoom: 3,
            }}
            mapStyle={global.mapStyle}
          >

                    <MapAddition global={global} 

                                position={'low'}
                                zipOff={true}
                                MapSliderAdd={true}
                                />

          </Map>
          <TableReport data={dummieData} />
        </div>
      </div>
    </div>
  );
}

function TableReport(props: any) {
  const { data } = props;

  return (
    <>
      <div className='report-options'>
        <Button>
          <img src={arrowDownIcon} />
        </Button>
        <Button>
          <img src={arrowUpIcon} />
        </Button>
        <Button>
          <img src={arrowDownDoubleIcon} />
        </Button>
        <Button>
          <img src={arrowDownDoubleTreeIcon} />
        </Button>
        <Button>
          <img src={fullscreenIcon} />
        </Button>
        <Button>
          <img src={ellipsisIcon} />
        </Button>
      </div>
      <div className='table-container'>
        <Table>
          <thead>
            <tr>
              <th style={{ width: '250px' }}>Location</th>
              <th>
                Dissolved
                <br />
                Oxigen [mg/l]
              </th>
              <th>
                Fecal Coliform
                <br />
                [cfu/100ml]
              </th>
              <th>Height [ft]</th>
              <th>
                Organic
                <br />
                Carbon [mg/l]
              </th>
              <th>pH [0-14]</th>
              <th>Salinity [ppt]</th>
              <th>
                Specific
                <br />
                Conductance [uS/CM]
              </th>
              <th>Temperature [°F]</th>
              <th>Turbidity [NTU]</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item: any) => (
              <RowItem data={item} key={Math.random() * 1000} />
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

function RowItem(props: any) {
  const { location, data } = props;
  const [open, setOpen] = useState(false);

  const items = data.data.map((col: any, i: number) => {
    if (i != 0) return <td>{col}</td>;
    else
      return (
        <td className={!open ? 'principal' : 'principal active'}>{col}</td>
      );
  });

  const sub = data.sub.map((sub: any, i: number) => (
    <tr className='sub'>
      {sub.map((col: any) => (
        <td>{col}</td>
      ))}
    </tr>
  ));

  const onClick = () => {
    if (open) setOpen(false);
    else setOpen(true);
  };

  return (
    <>
      <tr onClick={onClick}>{items}</tr>
      <Render condition={open}>{sub}</Render>
    </>
  );
}

