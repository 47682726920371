import { useState, useEffect } from 'react'
import {
    Form,
    Col,
    Row,
    Button,
    Table,
    Tabs,
    Tab
} from 'react-bootstrap';
import { getDomainValue, postWQX } from './lib/data';

const TranslationModal = ({
    setTranslationModal,
    translationModal,
    importTranslation,
    setImportTranslation,
    data,
    setData  
}:any) => {

    const dropdownLimit = 2000;
    const [fullCharacteristic, setFullCharacteristic] = useState < any[] > ([]);
    const [currentCharacteristic, setCurrentCharacteristic] = useState < any[] > ([]);

    const [Characteristic, setCharacteristic] = useState < any[] > ([]);

    const limitCharacteristic = (value:any) => {
        setFullCharacteristic(value);
        setCharacteristic(value.slice(0, dropdownLimit));
    }

    useEffect(() => {
        getDomainValue('Characteristic', limitCharacteristic);
    }, []);

    const updateMon = (property: any, value:any) => {
      let changeMon = data;
      changeMon[property]=value;
      setData(changeMon)
    }

    const saveImportTranslation = () => {
        setTranslationModal(!translationModal); 
        let newData = [...importTranslation];
        newData.push(data);
        setImportTranslation(newData);
    }

   const onChangeCharSearch = (event: any) => {     
         if (event && event.target && event.target.value && event.target.value.length > 1) {
            let fData = [...fullCharacteristic].filter((o: any) => o["Name"].toLowerCase().indexOf(event.target.value.toLowerCase()) > -1);
            setCharacteristic(fData.slice(0,dropdownLimit));
        } else {
            setCharacteristic(fullCharacteristic.slice(0,dropdownLimit));
        }       
  }

    return (
        <div className="modal-container">
            <div className="modal-wrapper translation-modal-wrapper">
                <div className="modal">
                    <div className="modal-header">
                        <p>Add New Translation</p>
                    </div>
                    <div className="modal-body">
                        <Col className="col-12">
                            <Row>
                                <Col className="col-12 d-flex justify-content-between">
                                    <Col className="col-4">
                                        <p>Characteristic Name:</p>
                                    </Col>
                                    <Col className="col-8">
                                       <div className='users-tool-container'  style={{minWidth: 80, display: 'inline-flex'}}>
                                          <Form.Group  className='users-search' controlId="formSearch" style={{display: 'inline-flex', height: 'fit-content', marginTop: 5}}>
                                              <Form.Control onChange={onChangeCharSearch} type="text" placeholder="Search" />
                                          </Form.Group>
                                      </div>
                                        <Form.Group className="mb-3" controlId="floatingMonitoringLocationType" style={{marginTop:10}}>                                    
                                            <Form.Select  onChange={(e: any) => updateMon('Characteristic', e.target.value)}
                                             size="lg" aria-label="MonitoringLocIdent_MonitoringLocationTypeName" defaultValue={""}>
                                                {Characteristic  && Characteristic.map((obj:any, index:any) =>
                                                    (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                                )} 
                                            </Form.Select>                                    
                                        </Form.Group>
                                    </Col>
                                </Col>
                            </Row>
                            <Row style={{marginTop:20}}>
                                <Col className="col-12 d-flex justify-content-between">
                                    <Col className="col-4">
                                        <Form.Label>Detection Limit:</Form.Label>
                                    </Col>
                                    <Col className="col-8">
                                        <Form.Control size="sm" type="text" 
                                         onChange={(e: any) => updateMon('DetectionLimit', e.target.value)}/>
                                    </Col>
                                </Col>
                            </Row>
                            <Row style={{marginTop:20}}>
                                <Col className="col-12 d-flex justify-content-between">
                                    <Col className="col-4">
                                        <Form.Label>Lower Quant Limit:</Form.Label>
                                    </Col>
                                    <Col className="col-8">
                                        <Form.Control size="sm" type="text" 
                                         onChange={(e: any) => updateMon('LowerQuantLimit', e.target.value)}/>
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                    <div className="modal-footer translation-modal-footer">
                        <div className="modal-button-container">
                            <Button onClick={() => saveImportTranslation()}>
                                Save
                            </Button>
                            <Button onClick={() => setTranslationModal(!translationModal)}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TranslationModal