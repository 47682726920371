import {
    useMemo,
    useEffect,
    useState
} from 'react'
import ReportTable from '../../../reports/shared/ReportTable'
import {
    Col,
    Row,
    Button,
    Form
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPenToSquare,
    faTrashCan
} from '@fortawesome/pro-solid-svg-icons';
import { setDatasets } from 'react-chartjs-2/dist/utils';
import MonitoringLocsTable from './MonitoringLocsTable';
import EditMonitoringLocation from './EditMonitoringLocation';


const MonitoringLocs = (props: any) => {

    const {
        setEditMonitoringLocation,
        setMonitoringLocs,
        modalOpen,
        setModalOpen,
        modalList,
        setModalList,
        mLocsData,
        setMLOCSData,
        orgCredentialsType
    } = props


    let newObj = {
        "ORGDescription_OrganizationDescriptionText": null,
        "ORGDescription_OrganizationIdentifier": "WQXTEST",
        "ORGDescription_OrganizationFormalName": "WQXTEST",
        "ORGDescription_TribalCode": "TE",
        "ORGElectronicAddress_ElectronicAddress": "TE@TEST.COM",
    /*    "ORGElectronicAddress_ElectronicAddressText": null,
        "ORGElectronicAddress_ElectronicAddressTypeName": null,
        "ORGTelephonic_Telephonic": null,
        "ORGTelephonic_TelephoneNumberText": null,
        "ORGTelephonic_TelephoneNumberTypeName": null,
        "ORGTelephonic_TelephoneExtensionNumberText": null,
        "ORGOrganizationAddress_OrganizationAddress": null,
        "ORGOrganizationAddress_AddressTypeName ": null,
        "ORGOrganizationAddress_AddressText": null,
        "ORGOrganizationAddress_SupplementalAddressText": null,
        "ORGOrganizationAddress_LocalityName": null,
        "ORGOrganizationAddress_StateCode": null,
        "ORGOrganizationAddress_PostalCode": null,
        "ORGOrganizationAddress_CountryCode": null,
        "ORGOrganizationAddress_CountyCode": null,*/
        "MonitoringLocIdent_MonitoringLocationIdentity": null,
        "MonitoringLocIdent_MonitoringLocationIdentifier": null,
        "MonitoringLocIdent_MonitoringLocationName": null,
        "MonitoringLocIdent_MonitoringLocationTypeName": null,
        "MonitoringLocIdent_MonitoringLocationDescriptionText": null,
        "MonitoringLocIdent_HUCEightDigitCode": null,
        "MonitoringLocIdent_HUCTwelveDigitCode": null,
        "MonitoringLocIdent_TribalLandIndicator": null,
        "MonitoringLocIdent_TribalLandName": null,
        "MonitoringLocIdent_AlternateMonitoringLocationIdentity": null,
        "MonitoringLocIdent_MonitoringLocationIdentifierContext": null,
        "MonitoringLocIdent_DrainageAreaMeasure": null,
        "MonitoringLocIdent_MeasureValue": null,
        "MonitoringLocIdent_MeasureUnitCode": null,
        "MonitoringLocIdent_ContributingDrainageAreaMeasure": null,
        "MonitorLocGeo_MonitoringLocationGeospatial": null,
        "MonitorLocGeo_LatitudeMeasure": null,
        "MonitorLocGeo_LongitudeMeasure": null,
        "MonitorLocGeo_SourceMapScale": null,
        "MonitorLocGeo_HorizontalAccuracyMeasure": null,
        "MonitorLocGeo_MeasureValue": null,
        "MonitorLocGeo_MeasureUnitCode": null,
        "MonitorLocGeo_VerticalAccuracyMeasure": null,
        "MonitorLocGeo_HorizontalCollectionMethodName": null,
        "MonitorLocGeo_HorizontalCoordinateReferenceSystemDatumName": null,
        "MonitorLocGeo_VerticalMeasure": null,
        "MonitorLocGeo_VerticalCollectionMethodName": null,
        "MonitorLocGeo_VerticalCoordinateReferenceSystemDatumName": null,
        "MonitorLocGeo_CountryCode": null,
        "MonitorLocGeo_StateCode": null,
        "MonitorLocGeo_CountyCode": null,
        "MonitoringLocWellInfo_WellInfo": null,
        "MonitoringLocWellInfo_WellTypeText": null,
        "MonitoringLocWellInfo_AquiferTypeName": null,
        "MonitoringLocWellInfo_NationalAquiferCode": null,
        "MonitoringLocWellInfo_AquiferInfo": null,
        "MonitoringLocWellInfo_LocalAquiferCode": null,
        "MonitoringLocWellInfo_LocalAquiferCodeContext": null,
        "MonitoringLocWellInfo_LocalAquiferName": null,
        "MonitoringLocWellInfo_LocalAquiferDescriptionText": null,
        "MonitoringLocWellInfo_FormationTypeText": null,
        "MonitoringLocWellInfo_WellHoleDepthMeasure": null,
        "MonitoringLocWellInfo_MeasureValue": null,
        "MonitoringLocWellInfo_MeasureUnitCode": null,
        "MonitoringLocWellInfo_ConstructionDate": null,
        "MonitoringLocWellInfo_WellDepthMeasure": null,
        "MonitoringLocationBinaryObject_AttachedBinaryObject": null,
        "MonitoringLocationBinaryObject_BinaryObjectFileName": null,
        "MonitoringLocationBinaryObject_BinaryObjectFileTypeCode": null
    }


    const [data, setData] = useState(newObj);
    const [action, setAction] = useState('table');

    const modalArray = [
                                'HUC8',
                                'HUC12',
                                'Tribal Land',
                                'Horizontal Collection',
                                'Horizontal Ref Datum',
                                'Vertical Measure',
                                'County Code',
                                'State Code',
                                'Country Code',
                                'Well Type',
                                'Aquifier Name',
                                'Formation Type',
                                'Wellhole Depth'
                            ];

    const modalArrayFields = [] as any;
    for (var i = 0; i < modalArray.length; i++) {
        //@ts-ignore
         modalArrayFields.push({ field: modalArray[i], active: false})
    }

    useEffect(() => {
      const table_mlocs_r = localStorage.getItem('wm_table_mlocs_r');
      if(!table_mlocs_r || table_mlocs_r!=='3'){
          localStorage.removeItem('wm_table_mlocs');
          localStorage.setItem('wm_table_mlocs_r', '3')
      }
    }, [])

    useEffect(() => {
        if(!modalList || modalList.length!==modalArray.length){
              const table_mlocs = localStorage.getItem('wm_table_mlocs');
              if(table_mlocs){
                  const tablefields = JSON.parse(table_mlocs);
                  if(tablefields && tablefields.length >0){
                      setModalList(tablefields)
                  } else {
                      setModalList(modalArrayFields);
                  }
              } else {
                  setModalList(modalArrayFields);
              }
        }
    }, [])

    useEffect(() => {
        if(modalList && modalList.length===modalArray.length){
           localStorage.setItem('wm_table_mlocs', JSON.stringify(modalList));
        }
    }, [modalList])


    const renderScreen = () => {
        if (action === 'table') {
            return <MonitoringLocsTable
                setModalOpen={setModalOpen}
                modalOpen={setModalOpen}
                modalList={modalList}
                setModalList={setModalList}
                action={action}
                setAction={setAction}
                data={data}
                setData={setData}
                mLocsData={mLocsData}
                setMLOCSData={setMLOCSData}
                modalArrayFields={modalArrayFields}
                orgCredentialsType={orgCredentialsType}
            />
        }

        if(action === 'create') {

            //@ts-ignore
            //setData(newObj)
            return <EditMonitoringLocation
                title={'Create'}
                action={action}
                setAction={setAction}
                data={data}
                setData={setData}
                mLocsData={mLocsData}
                setMLOCSData={setMLOCSData}
            />
        }

        if (action === 'edit') {
            return <EditMonitoringLocation
                title={'Edit'}
                action={action}
                setAction={setAction}
                data={data}
                setData={setData}
                mLocsData={mLocsData}
                setMLOCSData={setMLOCSData}
            />
        }
    }



    return (
        <>
            {renderScreen()}
        </>
    )
}

export default MonitoringLocs