import { useContext, useState, useMemo, useEffect } from "react";
import { Layer, Source, Popup } from "react-map-gl";
import { CircleLayer } from "mapbox-gl";
import { RefContext } from "../../../RefContext";
import { AppContext } from "../../../AppContext";
import { PointContext } from "../../../PointContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ActivePopupContext } from "../../../ActivePopupContext";
import "./cvl-styles.scss";
import agent from "../../../api/agent";

const CircleVectorLayer = ({
  id,
  global = null,
  circleColor,
  tileSet,
  sourceLayer,
  ausBore = false,
  gemstat = false,
  wws = false,
  zoom
}: any) => {
  const navigate = useNavigate();
  const [features, setFeatures] = useState<any>();
  const { setCurrentPoint } = useContext(PointContext);
  const { updateContext } = useContext(AppContext);
  const { currentRef } = useContext(RefContext);
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);
  const [content, setContent] = useState<any>();

  useEffect(() => {
    if (features) {
      renderInfo(features.properties);
    }
  }, [features]);

  // @ts-ignore
  const mapInstance = currentRef && currentRef?.current;

  useMemo(
    () =>
      mapInstance &&
      mapInstance.on("click", (event: any) => {
        const layerObject = mapInstance.queryRenderedFeatures(event.point);
        if (layerObject !== undefined && layerObject.length > 0) {
          const feature = layerObject[0];
          setFeatures(feature);
          setActivePopup({
            id,
            content: renderPopups(feature, event.lngLat)
          });
        }
      }),
    [mapInstance]
  );

  const renderInfo = (input: any) => {
    const keys = Object.keys(input);
    const info = keys.map((key: any, i: number) => (
      <li key={i}>
        {key}: {input[key]}
      </li>
    ));
    setContent(info);
  };

  const goTo = (input: any) => {
    setCurrentPoint?.({
      id: input.HydroID,
      lat: input.Latitude,
      lng: input.Longitude,
    });
    setTimeout(() => navigate("/AusBoreData"), 100);
  };

  const handleRadius = (input: number) => {
    if (input <= 3) {
        return 5;
    } else if (input >= 4 && input <= 7) {
        return 5;
    } else if (input >= 8 && input <= 9) {
        return 7;
    } else if (input >= 9) {
        return 9;
    }
  }

  const CircleVectorLayer: CircleLayer = {
    id: `${id}-layer`,
    type: "circle",
    "source-layer": `${sourceLayer}`,
    paint: {
      "circle-radius": handleRadius(zoom),
      "circle-color": circleColor,
      "circle-opacity": 1,
    },
  };

  const lng =
    features &&
    parseFloat(features.properties.Longitude || features.properties.longitude);
  const lat =
    features &&
    parseFloat(features.properties.Latitude || features.properties.latitude);

  useEffect(() => {
    if (lat && lng) {
      agent.Utilities.LatLngToCity(lat, lng).then((data: any) => {
        if (
          updateContext &&
          data &&
          data.features &&
          data.features.length > 0 &&
          data.features[0].place_name
        ) {
          updateContext(
            "",
            data.features[0].place_name,
            {},
            global.currentCountry
          );
        }
      });
    }
  }, [lng, lat]);

  const handleWWSPopup = (input: any, lngLat: any) => {
    return (
      <Popup
        longitude={lngLat.lng}
        latitude={lngLat.lat}
        className={"wws-popup-main"}
        onClose={() => setActivePopup(null)}
      >
        <FontAwesomeIcon
          icon={faXmark}
          onClick={() => setActivePopup(null)}
          style={{
            color: "white",
            position: "absolute",
            top: "5%",
            right: "3%",
            cursor: "pointer",
          }}
        />
        <div className="wws-popup-body">
          <p>Station Name: {input.properties.station_name}</p>
          <p>Site Number: {input.properties.site_no}</p>
          <p>huc8: {input.properties.huc8}</p>
          <p>huc12:{input.properties.huc12}</p>
          <p>latitude: {input.properties.latitude}</p>
          <p>longitude: {input.properties.longitude}</p>
          <p>timezone: {input.properties.timezone}</p>
          <p><a href={`https://waterdata.usgs.gov/monitoring-location/${input.properties.site_no}`} target={'_blank'}>USGS Monitoring Location Info</a></p>
        </div>
      </Popup>
    );
  };

  const handleDefaultPopup = (input: any, lngLat: any) => {
    return (
      <Popup
        longitude={lngLat.lng}
        latitude={lngLat.lat}
        className={"epa-popup-main"}
        onClose={() => setActivePopup(null)}
      >
        <FontAwesomeIcon
          icon={faXmark}
          onClick={() => setActivePopup(null)}
          style={{
            color: "white",
            position: "absolute",
            top: "5%",
            right: "3%",
            cursor: "pointer",
          }}
        />
        <div className="epa-popup-body">
          <p>Site Name: {input.properties.Site_Name}</p>
          <p>State: {input.properties.State}</p>
          <p>City: {input.properties.City}</p>
          <p>County: {input.properties.County}</p>
          <p>Date: {input.properties.Proposed_Date}</p>
          <p>EPA ID: {input.properties.Site_EPA_ID}</p>
          <p>Site Score: {input.properties.Site_Score}</p>
          <p>Status: {input.properties.Status}</p>
          <p>
            Site Progress Profile:
            {
              <div
                dangerouslySetInnerHTML={{
                  __html: input.properties.Site_Progress_Profile,
                }}
              />
            }
          </p>
          <p>
            Site Listing Narrative:
            {
              <div
                dangerouslySetInnerHTML={{
                  __html: input.properties.Site_Listing_Narrative,
                }}
              />
            }
          </p>
          <p>
            Proposed FR Notice:
            {
              <div
                dangerouslySetInnerHTML={{
                  __html: input.properties.Proposed_FR_Notice,
                }}
              />
            }
          </p>
          <p>
            Final FR Notice:
            {
              <div
                dangerouslySetInnerHTML={{
                  __html: input.properties.Final_FR_Notice,
                }}
              />
            }
          </p>
        </div>
      </Popup>
    );
  };

  const handleAusBorePopup = (input: any, lngLat: any) => {
    return (
      <Popup
        longitude={lngLat.lng}
        latitude={lngLat.lat}
        className={"ausbore-popup-main"}
        onClose={() => setActivePopup(null)}
      >
        <FontAwesomeIcon
          icon={faXmark}
          onClick={() => setActivePopup(null)}
          style={{
            color: "white",
            position: "absolute",
            top: "5%",
            right: "3%",
            cursor: "pointer",
          }}
        />
        <div className="ausbore-popup-body">
          <p>Agency: {input.properties.Agency}</p>
          <p>Drilled Date:{input.properties.DrilledDat}</p>
          <p>Drilled Depth: {input.properties.DrilledDep}</p>
          <p>Land Elevation: {input.properties.LandElev}</p>
          <p>Salinity: {input.properties.Salinity}</p>
          <p>Water Level: {input.properties.WaterLevel}</p>
          <a onClick={() => goTo(input.properties)}>Go to report</a>
        </div>
      </Popup>
    );
  };

  const renderPopups = (features: any, lngLat: any) => (
    <>
      {wws && handleWWSPopup(features, lngLat)}
      {ausBore && handleAusBorePopup(features, lngLat)}
      {!wws && !ausBore && !gemstat && handleDefaultPopup(features, lngLat)}
    </>
  );

  return (
    <>
      <Source id={`${id}-layer`} type="vector" url={tileSet}>
        <Layer {...CircleVectorLayer} />
        {activePopup && activePopup.id === id && activePopup.content}
      </Source>
    </>
  );
};

export default CircleVectorLayer;
