import React from 'react'
import './zoom-widget.scss'

const ZoomWidget = ({ zoom }: any) => {
  return (
    <div className='zoom-widget'>
     <span>{zoom.toFixed(2)}</span> 
    </div>
  )
}

export default ZoomWidget
