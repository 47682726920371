import { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { Layer, Source, Popup } from "react-map-gl";
import { CircleLayer } from "mapbox-gl";
import { RefContext } from "../../RefContext";
import { PointContext } from "../../PointContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ActivePopupContext } from "../../ActivePopupContext";
import agent from "../../api/agent";
import "./point-layer.scss";

const PointLayer = ({
    global,
    id,
    action,
    url,
    sourceLayer,
    circleColor,
    opacity,
    callValue,
    reportRoute
}: any) => {

    const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);
    const { setCurrentPoint } = useContext<any>(PointContext);
    const { currentRef } = useContext<any>(RefContext);

    const ref = currentRef?.current;
    const lngLat = action?.lngLat;
    const [features, setFeatures] = useState<any>(null);
    const navigate = useNavigate();

    const handleRadius = (zoom: number) => (zoom <= 3 ? 5 : zoom <= 7 ? 5 : zoom <= 9 ? 7 : 9);


    const CircleVectorLayer: CircleLayer = useMemo(() => ({
        id: `${id}-layer`,
        type: "circle",
        "source-layer": sourceLayer,
        paint: {
            "circle-radius": handleRadius(global.viewport.zoom),
            "circle-opacity": opacity,
            "circle-color": circleColor,
            "circle-stroke-color": 'rgba(201,225,238,0)',
            "circle-stroke-width": 8,
        },
    }), [id, sourceLayer, global.viewport.zoom, opacity, circleColor]);

    const goToReport = useCallback((lat: number, lng: number, input: string) => {
        setCurrentPoint({ lat, lng, id: input });
        setTimeout(() => navigate(reportRoute), 1000);
    }, [navigate, reportRoute, setCurrentPoint]);

    const getFeaturesAtPoint = useCallback((event: any) => {
  
        const res = ref?.queryRenderedFeatures(event.point, { layers: [`${id}-layer`] })?.[0];
  
        if (res?.properties) {
            setFeatures(res.properties);

            if(res.geometry && res.geometry.coordinates && res.properties[callValue]){ 
                 setCurrentPoint({ lat: res.geometry.coordinates[1], lng: res.geometry.coordinates[0], id: res.properties[callValue] });
            }          
        }
    }, [id, ref]);

    useEffect(() => {
        if (ref) {
            ref.on('click', getFeaturesAtPoint);
            return () => ref.off('click', getFeaturesAtPoint);
        }
    }, [ref, getFeaturesAtPoint]);

    const memoizedPopup = useMemo(() => {
        if (!features || !lngLat) return null;

        const handleClose = () => {
            setFeatures(null);
            setActivePopup({ id: null, content: null });
        };

        return (
            <Popup
                longitude={lngLat.lng}
                latitude={lngLat.lat}
                onClose={handleClose}
                className="circledata-popup"
            >
                <FontAwesomeIcon className="close-btn" icon={faXmark} onClick={handleClose} />
                {Object.entries(features).map(([key, value]: any) => (
                    <div key={key}>{key}<strong>:</strong> {value}</div>
                ))}
                <a
                    className="report-anchor"
                    onClick={() => goToReport(lngLat.lat, lngLat.lng, features[callValue])}
                >
                    Go to report
                </a>
            </Popup>
        );
    }, [features, lngLat, goToReport, callValue, setActivePopup]);

    useEffect(() => {
        if (memoizedPopup && activePopup.id !== id) {
            setActivePopup({ id, content: memoizedPopup });
        }
    }, [memoizedPopup, activePopup, setActivePopup, id]);

    return (
        <Source id={id} type="vector" url={url}>
            <Layer {...CircleVectorLayer} />
            {activePopup && activePopup.id === id && activePopup.content}
        </Source>
    );
};

export default PointLayer;
