import {
  Layer,
  Source,
  FillLayer,
} from "react-map-gl";
import { adjustHexBrightness } from "../../features/utils";
import settings from "../../settings.json";

const NitrateAssessmentLayer = ({ opacity, global }: any) => {
  const nitrateAssessmentFillLayer: FillLayer = {
    id: "nitrateassessmentlayer",
    type: "fill",
    "source-layer": settings.tileSetURLs.gwNitrateAssessment_sourcelayer,
    paint: {
      "fill-opacity": opacity,
      // "fill-outline-color": `transparent`,
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'z'],
        1,
        `${adjustHexBrightness('#01893A', global.gwNitrateAssessmentSelectedBrightness)}`,
        1.5,
        `${adjustHexBrightness('#FFE607', global.gwNitrateAssessmentSelectedBrightness)}`,
        3,
        `${adjustHexBrightness('#FD9C00', global.gwNitrateAssessmentSelectedBrightness)}`,
        4.5,
        `${adjustHexBrightness('#F50000', global.gwNitrateAssessmentSelectedBrightness)}`
      ],
    },
  };

  return (
    <>
      <Source
        id="nitrateassessment-source"
        type="vector"
        url={settings.tileSetURLs.gwNitrateAssessment}
      >
        <Layer beforeId={'aerialway'}
          {...nitrateAssessmentFillLayer}
        />
      </Source>
    </>
  )
}

export default NitrateAssessmentLayer;


