import { useEffect, useState, useMemo, useRef, useContext, memo } from 'react';
import { Button, OverlayTrigger, Popover, FloatingLabel, Form, Dropdown, Alert, Spinner, Tooltip } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { parse, unparse } from 'papaparse';
import { parseQueryFilters } from '../shared/report-data';
import { Location, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faUpload, faXmark, faTrafficLight, faFileArrowDown, faArrowsToCircle, faFilePdf, faTint, faQuestion, faClipboardList, faDroplet, faPieChart } from '@fortawesome/pro-solid-svg-icons';
import calendarIcon from '../../../assets/calendar-icon.svg';
import trueQ from '../../../assets/trueQ.svg';
import localForage from "localforage";
import html2canvas from 'html2canvas';
import { jsPDF, ImageOptions } from "jspdf";
import autoTable from 'jspdf-autotable';
import queryString from 'query-string';
import {
  QueryParamProvider,
  useQueryParam,                   
  BooleanParam,
  StringParam
} from 'use-query-params';
import DatePicker from 'react-datepicker';
import center from '@turf/center';
import debounce from 'lodash.debounce';
import { v4 as uuidv4 } from 'uuid';
import 'react-datepicker/dist/react-datepicker.css';
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import * as d3Scale from 'd3-scale';
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import { ThemeType, Color } from 'jspdf-autotable';
import { RiskAssessment } from "../PrintReports/RiskAssessment";
import { RiskAssessmentPrimary } from "../PrintReports/RiskAssessmentPrimary";
import { GroundwaterReport } from "../PrintReports/GroundwaterReport";
import Routes from '../../../ReportsRoutes';
import { OrganizationConfig }from '../../../utils/OrganizationConfig';
import axios from 'axios';
import settings from "../../../settings.json";
import ReportTable from './ReportTable';
import { getDomainValue } from '../WaterMonitoring/lib/data';
import states from '../../../json/states.json'
import { RefContext } from "../../../RefContext";
import { renderRestrictions } from '../../utils';
import ContactAdmin from '../../../layout/ContactAdmin';
import TestFeatures from './TestFeatures';
import CharacteristicGroup from '../WaterMonitoring/json/CharacteristicGroup.json'; 
import "./FilterState.scss";
    
const FilterState = ({       
    data,
    mode = 'date',
    global,
    reportID,
    data2,
    data3
  }: {
    data: any;
    mode?: string;
    global?: any;
    reportID?: any;
    data2?: any;
    data3?: any;
  }) => {

  const navigate = useNavigate();
  const mediaQuery = window.matchMedia('(max-width: 677px)');
  const mediaQuery9 = window.matchMedia('(max-width: 977px)');

  const { currentRef } = useContext(RefContext);//@ts-ignore
  const { current } = currentRef;

  const [layerName, setLayerName] = useState();

  const [dataSet, setDataSet] = useState<any[]>([]);
  const [dataLimited, setDataLimited] = useState<any[]>([]);
  const [fullData, setFullData] = useState<any[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const [wqpFilter, setWQPFilter] = useState<any[]>([]);

  const [boundingBoxActive, setBoundingBoxActive] = useState(false);
  const [queryActive, setQueryActive] = useState(false);

  const [field1, setField1] = useState(localStorage.getItem("fmrf1") || ''); //Characteristic
  const [field2, setField2] = useState(localStorage.getItem("fmrf2") || null);//State
  const [field3, setField3] = useState(localStorage.getItem("fmrf3") || null);//County
  const [field4, setField4] = useState(localStorage.getItem("fmrf4") || null); //CharacteristicGroup
  const [field5, setField5] = useState(localStorage.getItem("fmrf5") || null); //Country

  const [fieldMLT, setFieldMLT] = useState(null);
  const [fieldOrg, setFieldOrg] = useState(null);
  const [fieldHuc, setFieldHuc] = useState(null);
  const [fieldCharacteristicType, setFieldCharacteristicType] = useState(localStorage.getItem("fmrct") || null); //fmrct
  const [fieldCharacteristicName, setFieldCharacteristicName] = useState(localStorage.getItem("fmrfn") || null);
  const [qdateS, setQdateS] = useState();
  const [qdateE, setQdateE] = useState();
  const [fieldMinsample, setFieldMinsample] = useState(null);
  const [fieldMinresult, setFieldMinresult] = useState(null);
  const [fieldSummarize, setFieldSummarize] = useState(false);


  const [yearsFilt, setYearsFilt] = useState('');
  const [reportType, setReportType] = useState('MonitoringLocation');
  const [resultMessage, setResultMessage] = useState('');

  const [countryFips, setCountryFips] = useState(localStorage.getItem("fmrnf") || null);
  const [stateFips, setStateFips] = useState(localStorage.getItem("fmrsf") || null);
  const [countyFips, setCountyFips] = useState(localStorage.getItem("fmrcf") || null);

  const [CountyFiltered, setCountyFiltered] = useState < any[] > ([]);
  const [StateFiltered, setStateFiltered] = useState < any[] > ([]); 
  const [CountryFiltered, setCountryFiltered] = useState < any[] > ([]);

  //Domain Values
  const [Country, setCountry] = useState < any[] > ([]);
  const [County, setCounty] = useState < any[] > ([]);
  const [State, setState] = useState < any[] > ([]);
  const [MonitoringLocationType, setMonitoringLocationType] = useState < any[] > ([]);
  const [HorizontalCollectionMethod, setHorizontalCollectionMethod] = useState < any[] > ([]);
  const [HorizontalCoordinateReferenceSystemDatum, setHorizontalCoordinateReferenceSystemDatum] = useState < any[] > ([]);  
  const [MeasureUnit, setMeasureUnit] = useState < any[] > ([]);
  const [WellType, setWellType] = useState < any[] > ([]);
  const [CharacteristicType, setCharacteristicType] = useState < any[] > ([]);
  const [Organizations, setOrganizations] = useState < any[] > ([]);
  const [VerticalCollectionMethod, setVerticalCollectionMethod] = useState < any[] > ([]);
  const [VerticalReferenceDatum, setVerticalReferenceDatum] = useState < any[] > ([]);
  const [siteTypes, setSiteTypes] = useState < any[] > ([]);



  const [accessFM, setAccessFM] =  useState(renderRestrictions(global.userOrgString, 'Field Water Data Monitor', global));  
  const [statusMessage, setStatusMessage] = useState('');
  const updateData = (data: any, error: any) => {
        if(error){
            global.notify(error);
            setDataSet([]);
            setFullData([]);
            setDataLimited([]);           
        } else {
          if(data){
            let newData = [...data]
            if(data[0]["Country Code"]){
                newData = [...data].filter((word: any) => word["Country Code"] === 'US')
            }
            if(data[0]["State Code"] && field2 && states.find(x => x.name === field2)){
                let stateC = states.find(x => x.name === field2);
                //@ts-ignore
                newData = [...data].filter((word: any) => word["State Code"] === stateC.abbreviation  || '')
            }            
            if(data[0]["Group Name"] && field4){
                //@ts-ignore
                newData = [...data].filter((word: any) => word["Group Name"] === field4 || '')
            }            

            setDataSet(data);
            setFullData(newData);
            setDataLimited(newData.slice(0,2222));  
          }             
        }
    }


   useEffect(() => {
      if(mode === 'reportcomp'){
        if(!boundingBoxActive && global.filterBoundingBox && global.filterBoundingBox.length > 0 && global.waterQualityPortal){
          setBoundingBoxActive(true);
        }
      }
    }, [global.filterBoundingBox]);

   useEffect(() => {
      if(mode === 'reportcomp'){
        if(global.waterQualityPortal && global.waterQualityPortalData && global.waterQualityPortalData.length > 0 && global.waterQualityPortalLocations.features && global.waterQualityPortalLocations.features.length > 0){
            let identifierField = 'MonitoringLocationIdentifier';
            global.waterQualityPortalData.forEach((dataItem:any, index:number) => {
                  let findLoc = global.waterQualityPortalLocations.features.find((i:any) => i.properties[identifierField] === dataItem[identifierField]);
                  if(findLoc){
                    dataItem = Object.assign( dataItem, findLoc.properties )
                  }
            });  
        }
      }
    }, [global.waterQualityPortalData]);


   
//Data

   useEffect(() => {
       if(mode === 'reportcomp'){
          getDomainValue('Country', setCountry);
          //getDomainValue('VerticalCollectionMethod', setVerticalCollectionMethod);
          //getDomainValue('VerticalReferenceDatum', setVerticalReferenceDatum);
          //getDomainValue('HorizontalCollectionMethod', setHorizontalCollectionMethod);
          //getDomainValue('HorizontalReferenceDatum', setHorizontalCoordinateReferenceSystemDatum);
          getDomainValue('State', setState);
          //getDomainValue('MeasurementUnit', setMeasureUnit);
          getDomainValue('MonitoringLocationType', setMonitoringLocationType);
          //getDomainValue('WellType', setWellType);
          getDomainValue('County', setCounty);
          //getDomainValue('CharacteristicGroup', setCharacteristicType);
          setCharacteristicType(CharacteristicGroup)
          getDomainValue('ORGANIZATION', setOrganizations);
          getDomainValue('siteType', setSiteTypes);
          setActiveIndex(1);
          getDomainValue('Characteristic', updateData);

           }
    }, []);


   useEffect(() => {
       if(mode === 'reportcomp' && Country && Country.length>0){
           let sFilter = Country.sort((x: any, y: any) => x['Name'].localeCompare(y['Name']));//.filter((o: any) => o['Code'] === 'US');
           setCountryFiltered(sFilter);
       }
    }, [Country]);

   useEffect(() => {
       if(mode === 'reportcomp' && State && State.length>0){
           let sFilter = State.filter((o: any) => o['Country Code'] === (countryFips || 'US'));
           setStateFiltered(sFilter);
       }
    }, [State]);

   useEffect(() => {
       if(mode === 'reportcomp' && State && State.length>0){
           let sFilt = states.find((o: any) => o['name'] === field2);
           let sFilter = County.filter((o: any) => o['State Code'] === sFilt?.abbreviation);
           setCountyFiltered(sFilter);
       }
    }, [County]);

    //++ Year Filter
    if(mode === 'year'){
      return (
        <>
            {data && (
            <div className='FilterState'>
                <div className="fs-container fs-container-date">
                        <img src={calendarIcon} className='calendar-icon fs-calendar-icon' />
                        <span className="fs-span">Year:</span>
                        <span className="fs-span">{data}</span>                      
                </div>   
            </div>
            )}
        </>
      );
    //++ Filters Applied
    } else if(mode === 'applied'){
      const popoverLink = (event:any) => {   
        let filterArray = parseQueryFilters(data.search, data.pathname);
        if(filterArray  && filterArray.length > 0 && data.pathname){
          return (
            <Popover id="filterstate-filter-popover" className='filterstate-popover'>
              <Popover.Header as="h3">Filters applied:</Popover.Header>
              <Popover.Body>
              {filterArray.map((link:any, index:number) => 
                <p key={'popover_filterstate' + index}><img src={trueQ} style={(link.filterLabel==='Scores'? {display:'inline-block', transform: 'translate(-5px, -2px)'} : {display: 'none'})} />{link.filterLabel + ': ' + link.filterValue}</p>
              )}
              </Popover.Body>
            </Popover>
          );
        } else {  
          return (
            <Popover id="filterstate-filter-popover" className='filterstate-popover'>
              <Popover.Header as="h3">Filters applied: none</Popover.Header>
            </Popover>
          );       
        }  
      };

      return (
        <>
            {data && (
            <OverlayTrigger  rootClose key={'dashboard-grid-pop_'+'link.timestamp'} 
              trigger={['hover', 'focus']} placement="auto" overlay={popoverLink("link.timestamp")}>
              <div className='FilterState'  id="filterstate-filter-div">
                  <div className="fs-container"  id="filterstate-fs-container">
                    <FontAwesomeIcon icon={faFilter} size='lg' className="fs-icon-app" />
                    <span className="fs-span-app">Filters applied</span>
                  </div>   
              </div>
            </OverlayTrigger>
              )}
        </>  
      );
    } else if(mode === 'none'){  


      return (
        <>

        </>
      );
    //++ navigate to SASB page
    } else if(mode === 'risk'){
        const onClickHandler = (e: any) => {
          navigate(Routes.Risk+window.location.search);
        };

        const orgRestrictions = [11111, 9]; //++++

      return (
        <>
            {data && (orgRestrictions.indexOf(global.userOrg) < 0) && (    
              <div className='FilterState'  id="filterstate-filter-div">
                  <div className="fs-container-wide"  id="filterstate-fs-container" onClick={onClickHandler}>
                    <FontAwesomeIcon icon={faTrafficLight} size='lg' className="fs-icon-app" />
                <span className="fs-span-wide">TrueQI Site Assessment Report</span>
                  </div>   
              </div>
            )}
        </>
      );

    } else if(mode === 'climatecalc'){
        const onClickHandler = (e: any) => {
          navigate(Routes.ClimatePrecipitationCalculator + window.location.search);
        };


      return (
        <>
            {data && (    
              <div className='FilterState'  id="filterstate-filter-div">
                  <div className="fs-container-wide"  id="filterstate-fs-container" onClick={onClickHandler}>
                    <FontAwesomeIcon icon={faTint} size='lg' className="fs-icon-app" />
                <span className="fs-span-wide">Climate Precipitation Calculator</span>
                  </div>   
              </div>
            )}
        </>
      );

    //++ SASB Report Download  
    } else if(mode === 'sasb'){
      const onClickExport = (e: any) => {
        if(data && data2){

          //+++ global.orgname
          let orgname = 'TrueQI Site Assessment Report';

          let searchParse = queryString.parse(window.location.search);
          if(searchParse["ccds"]){
            let Organization = OrganizationConfig.find(x => x.ccds === searchParse["ccds"]);
            //OrganizationConfig
            
            if(searchParse["ccds"]==='nasdaq'){
              orgname = 'Hotel Chain';
            } else if(searchParse["ccds"]==='rer'){
              orgname = 'RES';          
            } else if(searchParse["ccds"]==='apple'){
              orgname = 'Apple';          
            } else if(searchParse["ccds"]==='amane'){
              orgname = 'Amane Advisors';          
            } else if(searchParse["ccds"]==='pepsi'){
              orgname = 'Pepsi';
            } else {
              orgname = searchParse["ccds"] as string; 
            }
          }                
                        
          let industryCode = "SVHL";
          if(searchParse["inds"] && searchParse["inds"].length === 4){
            industryCode = searchParse["inds"].toString();
            //console.log(searchParse["inds"])
          }           

          if(e.target.form && e.target.form[0] && e.target.form[0].value && e.target.form[0].value.length>0){
              orgname = e.target.form[0].value;
          }          

          if(e.target.form && e.target.form[2] && e.target.form[2].checked){
            RiskAssessmentPrimary(data, data2, data3, orgname, industryCode);
          } else {
            RiskAssessment(data, data2, data3,   orgname, industryCode);
          }  
        }   
      };     

      const popoverLink = (event:any) => {   
          return (
            <Popover id="filterstate-filter-popover" style={{minWidth: 370}} className='filterstate-popover'>
              <Popover.Header style={{whiteSpace: 'nowrap'}} as="h3">TrueQI Site Assessment Report</Popover.Header>
              <Popover.Body>
                <div style={{minWidth: 340, fontSize: 18}}>
                  <Form>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Organization Name (optional)</Form.Label> 
                        <Form.Control type='text' style={{maxWidth: 320, fontSize: 14}}/>

                        <Form.Label style={{marginTop: 18}}>Sites: {data.length} </Form.Label> 
                        <div  style={{marginTop: 18}} key={`detailedreport`} className="mb-3">
                           <Form.Check 
                            type={'radio'}     
                            id={`detailedreport`}
                            label={`Detailed Report`}     
                            name="group1"            
                          />        
                           <Form.Check   
                            type={'radio'}
                            id={`summaryreport`}
                            label={`Summary Report`}
                            name="group1"
                          />
                        </div>    
      
                      </Form.Group>

                          <div style={{marginTop: 10, textAlign: 'center'}}>
                              <hr style={{maxWidth: 330}}/>
                              <Button className='users-button'                                           
                                onClick={onClickExport}>
                                <FontAwesomeIcon style={{marginRight:10}} icon={faFileArrowDown } size='lg' className="fs-icon-app" />
                                Export Report
                              </Button>
                          </div>
                  </Form>
                </div>
              </Popover.Body>
            </Popover>
          );
      };

      return (
        <>
            {data && data2 && (
            <OverlayTrigger  rootClose key={'dashboard-grid-pop_'+'link.timestamp'} 
              trigger={['click']} placement="auto" overlay={popoverLink("link.timestamp")}>
              <div className='FilterState'  id="filterstate-filter-div">
                  <div className="fs-container-wide"  id="filterstate-fs-container">
                    <FontAwesomeIcon icon={faFilePdf } size='lg' className="fs-icon-app" />
                    <span className="fs-span-wide">TrueQI Site Assessment Report</span>
 
                  </div>    
              </div>
            </OverlayTrigger>             
            )}  
        </>
      );
    } else if(mode === 'import'){
        const onClickHandler = (e: any) => {
          global.setGuiSwitch('riskimport');
          //navigate(Routes.ClimatePrecipitationCalculator + window.location.search);
        };
 

      return (
        <>
            {data && (    
              <div className='FilterState'  id="filterstate-filter-div">
                  <div className="fs-container-wide"  id="filterstate-fs-container" onClick={onClickHandler}>
                    <FontAwesomeIcon icon={faUpload} size='lg' className="fs-icon-app" />
                <span className="fs-span-wide">Import</span>
                  </div>   
              </div>
            )}
        </>
      );

    //++ Upload Locations
    } else if(mode === 'upload'){
    //let statusMessage = "";

    

    const onClickUpload = (e: any) => {
      setStatusMessage('Processing...')
      interface data {
        data?: any
        errors?: any;
      }
      if (e.target.form && e.target.form[0].files && e.target.form[0].files[0]) {
        let file = e.target.form[0].files[0];
        let fileName = file.name.split('.')[0];

        const reader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = () => {
          var data = parse(reader.result as string, {
            header: true,
          });

          let resultMessage = '';

          if (data.errors && data.errors.length > 0) {
            global.notify('Some errors during import.');
            resultMessage = 'Some errors during import. ' + JSON.stringify(data.errors[0]);
            setStatusMessage(resultMessage);
            console.log(data.errors[0])
            console.log(data)
          }   

          const cleanData = [];
          for (let dataItem of data.data) {//@ts-ignore
           if (dataItem["Location_Name"] || dataItem["Location_Name [character varying]"]) {
             cleanData.push(dataItem);
           }
          }

          if (cleanData && cleanData.length > 0) {
            //@ts-ignore  
            if (!cleanData[0]["Location_Name"] && !cleanData[0]["Location_Name [character varying]"]) {
              global.notify('Missing Location_Name field.  Please check and verify csv file.');
              setStatusMessage('Missing Location_Name field.  Please check and verify csv file.');
            } else {
              const locationData: any = {
                name: layerName ? layerName : fileName,
                locations: cleanData
              }
              resultMessage = resultMessage + ((resultMessage.length>0) ? Array(10).fill('\xa0').join('') : '') + ' Locations uploaded: ' + locationData.locations.length;
              global.notify('Locations uploaded: ' + locationData.locations.length);
              global.handleCustomLocationData(locationData.locations, locationData.name)
              setStatusMessage(resultMessage);
            }
          } else {
              resultMessage = resultMessage + ' No data found.  Please check and verify csv file.';
              global.notify('No data found.  Please check and verify csv file.');
              setStatusMessage(resultMessage);
          }
        };
      }
    };

    const onClickClear = (e: any) => {
      global.setCustomLocations([]);
      localForage.setItem('riska', []);
      setStatusMessage('Locations cleared.')
      //setqccds('');
    };

    const onClickExport = (e: any) => {
      exportToCsv(global.customLocations);
      setStatusMessage('Exporting.')
    };

    const exportToCsv = (data: any) => {
      const nowTime = DateTime.local().toFormat('_ydL_Hms_');


      const csv = unparse(data);
      let hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      hiddenElement.target = '_blank';
      hiddenElement.download = 'ClimateCalculator_filename_' + nowTime + '.csv';
      hiddenElement.click();
      if (hiddenElement.parentNode) {
        hiddenElement.parentNode.removeChild(hiddenElement);
      }
      hiddenElement.remove();
    }

    const process = (fulldata: any) => {
      setStatusMessage('Processing...')
      const numberSites = Math.floor(Math.random() * 14) + 1;
      const keyStart = Math.floor(Math.random() * 999) + 1;
      const yearInterest = 2023 + Math.floor(Math.random() * 30) + 1;
      const data = [] as any;
      for (var i = keyStart; i < (keyStart + numberSites); i++) {
        data.push(fulldata.addresses[i])  
      }

      let j = 0;
      for (let dataItem of data) {
        dataItem["Location_Name"] = 'Location ' + j;
        dataItem["Location_Address"] = dataItem["address1"];
        dataItem["City"] = dataItem["city"];
        dataItem["State"] = dataItem["state"];
        dataItem["Zip_Code"] = dataItem["postalCode"];
        dataItem["Country"] = "US";
        dataItem["Latitude"] = dataItem.coordinates["lat"];
        dataItem["Longitude"] = dataItem.coordinates["lng"];
        dataItem["Year_Built"] = 1950 + Math.floor(Math.random() * 73) + 1;
        dataItem["Future Year of Interest"] = yearInterest;


        /* const input = `${dataItem["Location_Address"]} ${dataItem["City"]} ${dataItem["State"]} ${dataItem["Zip_Code"]}`
               let URL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?&&country=US&limit=3&access_token=${settings.maboxKey}`;
          axios.get(URL)
       .then((response) => {
         if(response.status === 200 && response.data && response.data.features && response.data.features[0]&& response.data.features[0].center){
           console.log(response.data.features[0].center)
           dataItem["center"] = response.data.features[0].center;
         }
       });*/

        j++;
      }

      setStatusMessage('Locations uploaded: ' + data.length)
      setTimeout(() => {
        global.setCustomLocations(data)
      }, 1);
    }

    const generateAddress = (e: any) => {
      const fipsFileURL = "/test/addresses-us-1000.min.json";
      fetch(fipsFileURL)
        .then((response) => response.text())
        .then((responseText) => {
          if (responseText) {
            const data = JSON.parse(responseText);
            process(data);
          }
        });
    }

    const locationUploader = (e: any) => {
      global.setGuiSwitch('riskimport');
    }


    //disabled={!global.customLocations || global.customLocations.length<1 }

    const popoverLink = (event: any) => {
      return (
        <Popover id="filterstate-filter-popover-upload" className='filterstate-popover'>
          <Popover.Header as="h3" style={{paddingLeft: 7}}>Upload Locations:</Popover.Header>
          <Popover.Body>
            <div style={{ minWidth: 340, fontSize: 14 }}>
              <h4>Basic Uploader</h4>
              <Form>
                <Form.Group controlId="formFile" className="mb-3" style={{ display: 'flex', alignItems: 'center', fontSize: 18 }}>
                  {/*  <Form.Label>Upload file</Form.Label> */}
                  1. <Form.Control type="file" accept=".csv" style={{ maxWidth: 340, fontSize: 18, marginLeft: 10 }} />
                </Form.Group>
                <Form.Group className="d-flex justify-content-between align-items-center">
                  <Form.Label style={{ fontSize: 18}}>2.  Name Layer:{'  '}</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e: any) => setLayerName(e.target.value)}
                    style={{ maxWidth: '63%', fontSize: 14, marginLeft: 0 }}
                  />
                </Form.Group>
                <div style={{ marginTop: 18, display: 'flex', alignItems: 'center', fontSize: 18 }}>
                  3. <Button className='users-button' style={{ marginRight: 5, marginLeft: 10 }}
                    onClick={onClickUpload}>
                    Upload selected CSV File
                  </Button>
                  <span></span>

                </div>

                
                  <div style={{ marginTop: 10, marginBottom: 20, minHeight: 20, maxWidth: 359 }}>
                      <span style={{ fontWeight: 'bold' }}>{statusMessage}</span>
                  </div>
                  <div style={{ marginTop: 20, maxWidth: 359 }}>
                    <p>
                      To add your own data to the map, import a csv file of locations. After it has been successfully loaded, a temporary layer will appear in the Layers Menu under "Organization Location Data".
                      Data added to the map will not be stored.  On closing the browser, the data layers may be removed.
                    </p>

                    <p style={{ textDecoration: 'underline' }}>CSV file import:</p>
                    Required fields:
                    Location_Name, Location_Address,
                    City, State, Zip_Code, Country, Year_Built,
                    Latitude, Longitude
                    <p style={{ marginTop: 5 }}>Optional: Future Year of Interest</p>
                    <p style={{ marginTop: 5 }}><a href="/climatesasb/sasb_upload_locations.csv" target="_blank"> Download Example CSV</a></p>
                  </div>

                <div style={{ marginTop: 10 }}>

                  <hr style={{ maxWidth: 359 }} />


                  <Button className='users-button'

                    onClick={onClickExport}>
                    <span>Export Processed Locations as CSV</span>
                  </Button>


                  <Button style={{ marginTop: 20 }} className='users-button'
                    onClick={onClickClear}>
                    Clear Locations from Map
                  </Button>


                  <div style={{ marginTop: 20, display: 'none' }}>
                    <Button className='users-button'
                      onClick={generateAddress}>
                      Generate Address data
                    </Button>
                  </div>
                </div>
              </Form>
              <hr style={{ maxWidth: 359 }} />
              <div style={{ marginTop: 20}}>
                    <Button className='users-button'
                      onClick={locationUploader}>
                      Uploader Wizard
                    </Button>
              </div>
            </div>
          </Popover.Body>
        </Popover>
      );
    };
    return (
      <>
        {data && (
          <OverlayTrigger  rootClose key={'dashboard-grid-pop_' + 'link.timestamp'}
            trigger={['click']} placement="auto" overlay={popoverLink("link.timestamp")}>
            <div className='FilterState' id="filterstate-filter-div">
              <div className="fs-container-wide" id="filterstate-fs-container">
                <FontAwesomeIcon icon={faUpload} size='lg' className="fs-icon-app" />
                <span className="fs-span-app">Upload Locations</span>
              </div>
            </div>
          </OverlayTrigger>
        )}
      </>
    ); 
    } else if(mode === 'groundwater'){
        const onClickHandler = (e: any) => {
          //console.log(e, data, data2, data3)
          if(data){
            data2();
            //GroundwaterReport(data)
          }         
        };

        const orgRestrictions = [11111, 9]; //++++

      return (
        <>
            {data && (orgRestrictions.indexOf(global.userOrg) < 0) && (    
              <div className='FilterState'  id="filterstate-filter-div">
                  <div className="fs-container-wide"  id="filterstate-fs-container" onClick={onClickHandler}>
                    <FontAwesomeIcon icon={faFileArrowDown} size='lg' className="fs-icon-app" />
                <span className="fs-span-wide">Report Download</span>
                  </div>   
              </div>
            )}
        </>
      );
    //++  Query WQX
    } else if(mode === 'reportcomp'){
     
      const rowLimit = 2000;
      const mWidth = 800;
      const siteTypesA = [{"value":"Aggregate groundwater use","providers":"NWIS STORET"},{"value":"Aggregate groundwater use ","providers":"STORET"},{"value":"Aggregate surface-water-use","providers":"NWIS STORET"},{"value":"Aggregate water-use establishment","providers":"NWIS"},{"value":"Atmosphere","providers":"NWIS STORET"},{"value":"Estuary","providers":"NWIS STORET"},{"value":"Facility","providers":"NWIS STORET"},{"value":"Glacier","providers":"NWIS"},{"value":"Lake, Reservoir, Impoundment","providers":"NWIS STORET"},{"value":"Land","providers":"NWIS STEWARDS STORET"},{"value":"Not Assigned","providers":"STEWARDS"},{"value":"Ocean","providers":"NWIS STORET"},{"value":"Spring","providers":"NWIS STORET"},{"value":"Stream","providers":"NWIS STEWARDS STORET"},{"value":"Subsurface","providers":"NWIS"},{"value":"Well","providers":"NWIS STORET"},{"value":"Wetland","providers":"NWIS STORET"}];


      const queryData = () => {
        let testing = false;
        if(!global.waterQualityPortal){ global.setWaterQualityPortal(true) }
        let resultsIncluded = (global.waterQualityPortalDataType=== 'results') ? true : false;
        let resultsLimitIncluded = (global.waterQualityPortalDataType=== 'ResultDetectionQuantitationLimit') ? true : false;

        setStatusMessage("");                
        let resultsURL = "";
        let resultsLimitURL = "";

        let mimeType = "&mimeType=geojson";
        let baseURL = `https://www.waterqualitydata.us/data/Station/search?zip=no`;
        let baseResultsURL = `https://www.waterqualitydata.us/data/Result/search?zip=no&mimeType=csv`;
        let baseResultsLimitURL = `https://www.waterqualitydata.us/data/ResultDetectionQuantitationLimit/search?zip=no&mimeType=csv`;

        baseURL = `https://www.waterqualitydata.us/data/Station/search?zip=no`;

/*        if(global.waterQualityPortalDataType=== 'locations'){ //reportType === 'MonitoringLocation' //@ts-ignore
          baseURL = `https://www.waterqualitydata.us/data/Station/search?zip=no`;
        } else if(global.waterQualityPortalDataType=== 'results'){//@ts-ignore
          mimeType = "&mimeType=csv"
          baseURL = `https://www.waterqualitydata.us/data/Result/search?zip=no`
        } else {  //&startDateLo=10-01-2021
          baseURL = `https://www.waterqualitydata.us/data/Station/search?zip=no`;
        }
*/
/*        if(mimeType === 'geojson'){//@ts-ignore
          baseURL = `https://www.waterqualitydata.us/data/Station/search?zip=no`;
        }
*/
        let summarizeURL = '';
        if(fieldSummarize){
          summarizeURL = `&dataProfile=summaryMonitoringLocation`;
        }

        //dataProfile=periodOfRecord 153 &dataProfile=periodOfRecord
        //dataProfile=summaryMonitoringLocation &dataProfile=summaryMonitoringLocation

        //"yearlySummary":}
        //"yearlySummary":""}

        let locationURL = '';

        //bBox=43.580600239673856%2C-88.19290445790669%2C-90.19290445790669%2C42.580600239673856

        if(countyFips && stateFips){
          
          locationURL = `&statecode=${countryFips||"US"}%3A${stateFips}&countycode=${countryFips||"US"}%3A${stateFips}%3A${countyFips}`;
        } else if (!countyFips && stateFips) {
          locationURL = `&statecode=${countryFips||"US"}%3A${stateFips}`;
        }
        if(countryFips){
          locationURL = locationURL + `&countrycode=${countryFips}`;
        }
        //countycode=US%3A countrycode=US
        console.log(locationURL, countryFips, countyFips, stateFips)

        if(boundingBoxActive && global.filterBoundingBox && global.filterBoundingBox[0]){
          locationURL = locationURL + `&bBox=${global.filterBoundingBox[0]}%2C${global.filterBoundingBox[1]}%2C${global.filterBoundingBox[2]}%2C${global.filterBoundingBox[3]}`;
            console.log(locationURL)
        }

        let characteristicURL = '';
        if(field1&&field1!=='Any'){
          characteristicURL = `&characteristicName=${encodeURIComponent(field1)}`
          global.setWaterQualityPortalDataMetric(field1);
        } else if (field4 && !field1&&field4!=='Any') {
          characteristicURL =  `&characteristicType=${encodeURIComponent(field4)}`
        }

        let organizationURL = '';    
        if(fieldOrg&&fieldOrg!=='Any'){
          const orgFind = Organizations.find((nation: any) => nation.ID === fieldOrg);
          if(orgFind && orgFind["ID"]){
            organizationURL = `&organization=${orgFind["ID"]}`
          }          
        } 

        let mltURL = '';
        if(fieldMLT&&fieldMLT!=='Any'){
          mltURL = `&siteType=${encodeURIComponent(fieldMLT)}`
        } 

        let hucURL = ''; //14010001 //@ts-ignore
        if(fieldHuc&&fieldHuc!=='Any'){
          hucURL = `&huc=${fieldHuc}`;
          locationURL = '';
        } 


        let dateURL = ''; 
        if(qdateS&&qdateE){
          dateURL = `&startDateLo=${DateTime.fromJSDate(qdateS).toFormat('LL-dd-yyyy')}&startDateHi=${DateTime.fromJSDate(qdateE).toFormat('LL-dd-yyyy')}`
        } 

        let minimumURL = ''; 
        if(fieldMinsample){
          minimumURL = `&minactivities=${fieldMinsample}`
        } 

        let minimumRURL = ''; 
        if(fieldMinresult){     
          minimumRURL = `&minresults=${fieldMinresult}`
        } 

        let searchURL = `${baseURL}${locationURL}${characteristicURL}${summarizeURL}${organizationURL}${mltURL}${hucURL}${dateURL}${minimumURL}${minimumRURL}${mimeType}`
        resultsURL = `${baseResultsURL}${locationURL}${characteristicURL}${organizationURL}${mltURL}${hucURL}${dateURL}${minimumURL}${minimumRURL}`
        resultsLimitURL = `${baseResultsLimitURL}${locationURL}${characteristicURL}${organizationURL}${mltURL}${hucURL}${dateURL}${minimumURL}${minimumRURL}`

        //onsole.log(searchURL, resultsURL, resultsLimitURL)

        if(testing){
          console.log('testing')
          let newData = TestFeatures;
          global.setWaterQualityPortalLocations(newData);
          global.notify(newData.features.length + ` Locations found.`);
          setResultMessage(newData.features.length + ` Locations found.`);
          setQueryActive(false);
          return true;
        }



        setQueryActive(true);

          fetch(searchURL)
          .then((response) => {       
             if (!response.ok) {
               
                setResultMessage(`Error processing request`);
                global.notify(`Error processing request`);
                setQueryActive(false); 
                return Promise.reject(response);
                return response.text()
             } else {
                return response.text()
             }
           })
          .then((responseText) => {
              //console.log(responseText)
              setQueryActive(false); //@ts-ignore
              if(responseText){
              
                
                if(reportType === 'Results'){
                   let newResults = parse(responseText, {header: true}).data


                } else {
                   let newData = JSON.parse(responseText);
                   if(newData && newData.features && newData.features.length>0){
                    let nExtent = [0, 50];
                    //console.log(newData.features)   

                    
                    const rExtent = d3Array.extent<any, any>(newData.features, d => +d['properties']['activityCount']);
                    let rScale = d3Scale.scaleLinear()
                              .domain(nExtent)
                              .range([0, 4]).clamp(true);

                   //console.log(rExtent)    
                    if(rExtent[0] && rExtent[1]){
                       rScale = d3Scale.scaleLinear()
                              .domain([rExtent[0], rExtent[1]])
                              .range([0, 5]).clamp(true);
                    }     

                    for (let dataItem of newData.features) {
                      dataItem.properties.radius = 8;
                      if(dataItem.properties && dataItem.properties.activityCount){
                       dataItem.properties.radius = 8 + rScale(+dataItem.properties.activityCount)
                      }

                      if(dataItem.properties && dataItem.geometry && dataItem.geometry && (!dataItem.properties.centroid_longitude || !dataItem.properties.centroid_latitude)){
                            dataItem.properties.centroid_longitude = dataItem.geometry?.coordinates[0];
                            dataItem.properties.centroid_latitude = dataItem.geometry?.coordinates[1];
                            dataItem.properties.layerName = 'custom'; 
                            if(dataItem.properties && dataItem.properties.activityCount){
                                dataItem.properties.activity = +dataItem.properties.activityCount;
                            }
                            if(dataItem.properties && dataItem.properties.resultCount){
                                dataItem.properties.result = +dataItem.properties.resultCount;
                            }                
                      }
                    }   


               

                        //data2(newData)
                        global.setWaterQualityPortalLocations(newData);
                        global.notify(newData.features.length + ` Locations found.`);
                        setResultMessage(newData.features.length + ` Locations found.`);
                    } else{
                        clearData();
                        global.notify('No results for that query.');
                        setResultMessage('No results for that query.');
                    }
                             
                }

              }
          }).catch(error => {
              if (typeof error.json === "function") {//@ts-ignore
                  error.json().then(jsonError => {
                      console.log("Json error from API");
                      console.log(jsonError);//@ts-ignore
                  }).catch(genericError => {
                      console.log("Generic error from API");
                      console.log(error.statusText);
                  });
              } else {
                  console.log("Fetch error");
                  console.log(error);
              }
          });




        //https://www.waterqualitydata.us/data/Result/search?countrycode=US&statecode=US%3A06&countycode=US%3A06%3A003&mimeType=csv&zip=yes&dataProfile=resultPhysChem&providers=NWIS&providers=STEWARDS&providers=STORET
        if(resultsIncluded){
          //console.log('resultsIncluded')
          fetch(resultsURL)
            .then((response) => {   
                //console.log(response.ok, response, 'response')    
               if (!response.ok) {
                 
                  setResultMessage(`Error processing request`);
                  global.notify(`Error processing request`);
                  setQueryActive(false); 
                  return Promise.reject(response);
                  return response.text()
               } else {
                  return response.text()
               }
             })
            .then((responseText) => {
              let newResults = parse(responseText, {header: true}).data;
              //console.log(newResults.length)
              if(newResults){
                let newR = [...newResults]
                if(newResults.length>3000){
                  newR=[...newResults].slice(0, 3000)
                  global.notify(newResults.length + ' Results. Limiting to 3,000 for performance.' )
                }
                global.setWaterQualityPortalData(newR);
              }

            })    
          //setQueryActive(false);
          //return true;
        }

        if(resultsLimitIncluded){
          //console.log('resultsLimitIncluded')
          fetch(resultsURL)
            .then((response) => {   
                //console.log(response.ok, response, 'response')    
               if (!response.ok) {
                 
                  setResultMessage(`Error processing request`);
                  global.notify(`Error processing request`);
                  setQueryActive(false); 
                  return Promise.reject(response);
                  return response.text()
               } else {
                  return response.text()
               }
             })
            .then((responseText) => {
              let newResults = parse(responseText, {header: true}).data;
              //console.log(newResults.length)
              if(newResults){
                let newR = [...newResults]
                if(newResults.length>3000){
                  newR=[...newResults].slice(0, 3000)
                  global.notify(newResults.length + ' Results. Limiting to 3,000 for performance.' )
                }
                global.setWaterQualityPortalData(newR);
              }

            })    
         }

      };

      const clearData = () => {
           global.setWaterQualityPortalLocations([]);
      }

      const popoverFields = (
        <Popover id="GroundwaterDetermination-categories-popover" className="GD-categories-popover" key={"GroundwaterDetermination-categories-popover--"}>
          <Popover.Header as="h3">User Guide</Popover.Header>
          <Popover.Body  className="GD-categories-popover-body">
            <p>Query the EPA's repository of water quality monitoring data collected by water resource management groups across the country.  Water quality data submitted from the EPA, USGS, and over 900 federal, state and tribal agencies, watershed organizations and other groups.</p>
            <p>Input parameters include location, site, sampling, and date parameters.  Returning site information and locations where samples were collected.</p>
            <p>Any field in the form left blank will cause a search for all parameters in that field.</p>
            <p><span style={{fontWeight: 'bold'}}>Site type</span> indicates a natural or human-made feature affecting the hydrologic conditions measured at a site.  </p>
            <p><span style={{fontWeight: 'bold'}}>Characteristic Group</span> - Groups types of environmental measurements.</p>
            <p><span style={{fontWeight: 'bold'}}>Summarize Characteristic Groups</span>: For the queried sites provides organization, spatial data, and the total # results (equivalent to total number of records) and total # activities (equivalent to total number of collection events) per Characteristic Group.</p>
          </Popover.Body>
        </Popover>
      );

      const popoverBoundingBox = (
        <Popover id="GroundwaterDetermination-categories-popover" className="GD-categories-popover" key={"GroundwaterDetermination-categories-popover--"}>
          <Popover.Header as="h3">Bounding Box</Popover.Header>
          <Popover.Body  className="GD-categories-popover-body">
            <div style={{display:'flex', flexDirection:'row'}}><p>Set a bounding box by using the Polygon Tool on the map.</p><div className={'map-polygontool-button'}></div></div>
            <p>Draw the polygon on the map by clicking vertices with the mouse.</p>
            <p>Drag shape to move to another location or start a new shape.</p>
            <p>A uniform rectangle bounding box will be defined from the shape created.  Based on North and South latitudes and the East and West longitudes</p>            
            <p>Check box to include/exclude bounding box from the WQX query.</p>
          </Popover.Body>
        </Popover>
      );

      const handleReportAreaChange= (e: any) => {
        setActiveIndex(2);
        if(e.target.value === 'State'){
          getDomainValue(e.target.value, updateData);
          queryData();
        }
      };

      const handleField1Click = (e: any) => {
        if(e.target.innerText === 'Characteristic:'){
            setActiveIndex(1);
            getDomainValue('Characteristic', updateData);
        } else if (e.target.innerText === 'State:'){
            setActiveIndex(2);
            getDomainValue('State', updateData);
        } else if (e.target.innerText === 'County:'){
            setActiveIndex(3);
            getDomainValue('County', updateData);
        } else if (e.target.innerText === 'Characteristic Type:'){
            setActiveIndex(4);
            getDomainValue('CharacteristicGroup', updateData);
        }
      };

      const handleCharacteristicClick = (e: any) => {
            setActiveIndex(1);
            getDomainValue('Characteristic', updateData);
      };

      const tableColumns =  [
                {
                  Header: 'Select Value',
                  accessor: 'Name',
              }
          ];

      const onChangeYears= (event: any) => {   
             setYearsFilt(event.target.value);   
      }

      const onChangeSearch = (event: any) => {   
           if (event && event.target && event.target.value && event.target.value.length > 1) {
              let fData = [...fullData].filter((o: any) => o["Name"].toLowerCase().indexOf(event.target.value.toLowerCase()) > -1);
              
              //setDataSet(fData);
              setDataLimited(fData.slice(0,rowLimit));
              //setSearchText(event.target.value);
          } else {
              //setDataSet(fullData);
              setDataLimited(fullData.slice(0,rowLimit));
              //setSearchText('');
          }       
      }

      const cancelQueryData = (event: any) => {
        setActiveIndex(0)
      }

      const clearQueryData = (event: any) => {
        if(activeIndex === 1){setField1('');}
        if(activeIndex === 2){setField2(null);}
        if(activeIndex === 3){setField3(null);}
        if(activeIndex === 4){setField4(null);}
        if(activeIndex === 5){setField5(null);}
      }



      const clickRowHandler = (event: any) => {
        if(activeIndex === 1){
          setField1(event.Name)
          localStorage.setItem("fmrf1", event.Name)
        } else if (activeIndex === 2){
          setField2(event.Name)
          setField3(null)
          setCountyFips(null);
          let stateF = (event["Fips Code"].length === 1) ? '0' + event["Fips Code"] : event["Fips Code"];
          setStateFips(stateF);
          localStorage.setItem("fmrf2", event.Name);
          localStorage.removeItem("fmrf3");
          localStorage.setItem("fmrsf", stateF)
          localStorage.removeItem("fmrcf");
        } else if (activeIndex === 3){
          setField3(event.Name);
          let CountyF = event["County FIPS Code"].substr(2)
        
          setCountyFips(CountyF);
          localStorage.setItem("fmrf3", event.Name)
          localStorage.setItem("fmrcf", CountyF)
        } else if (activeIndex === 4){
          setField4(event.Name);
          setField1('')
          localStorage.setItem("fmrf4", event.Name);
          localStorage.removeItem("fmrf1");  
        }
        setActiveIndex(0)  
    /*      if (userSelectedLocations.indexOf(event[locationField]) > -1) {
          setUserSelectedLocations([]);
          setPopupInfo(null);
        } else {
          setUserSelectedLocations([event[locationField]]);
          setMapHoverFilter(['in', ['get', locationField], event[locationField]]);
          setPopupInfo(event);
          if (mapRef.current) {
            if (event && event.centroid_longitude) {
              mapRef.current?.flyTo({
                center: [parseFloat(event.centroid_longitude), parseFloat(event.centroid_latitude)],
              });
            }
          }
        }*/
      };

         

      const updateSingleDateSelection = (eventProps: any) => {
        const inputDate = new Date(eventProps);
        setQdateS(eventProps)
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, '0');
        const day = String(inputDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

      };
        //setSingleDate(formattedDate)

      const updateSingleDateSelectionEnd = (eventProps: any) => {
        const inputDate = new Date(eventProps);
        setQdateE(eventProps)
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, '0');
        const day = String(inputDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

        //setSingleDate(formattedDate)
      };
      
      const updateMon = (property: any, value:any) => {    
            
            setResultMessage('');
            if(property == 'Country'){
              
                const countryfound = Country.find((nation: any) => nation.Name === value);
             
                if(countryfound){
                  const statefound = State.filter((o: any) => o['Country Code'] === countryfound.Code);
                
                  setStateFiltered(statefound)
                  setCountyFiltered([])
                  setField5(value)
                  setField2(null)
                  setField3(null)

                  setCountryFips(countryfound.Code);
                  setStateFips(null);
                  setCountyFips(null);

                  localStorage.setItem("fmrf5", value);
                  localStorage.removeItem("fmrf2"); 
                  localStorage.removeItem("fmrf3"); 
                  localStorage.removeItem("fmrsf");
                  localStorage.removeItem("fmrcf");
                }





            } else if (property == 'State'){
              const statefound = State.find((nation: any) => nation.Name === value);              
              let sFilt = states.find((o: any) => o['name'] === value);
              let sFilter = County.filter((o: any) => o['State Code'] === sFilt?.abbreviation);
              setCountyFiltered(sFilter);
              setField2(value)
              setField3(null)
              setCountyFips(null);
              if(statefound ){
                let stateF = (statefound ["Fips Code"].length === 1) ? '0' + statefound ["Fips Code"] : statefound ["Fips Code"];
                setStateFips(stateF);           
                localStorage.setItem("fmrsf", stateF)
              } else {
                setStateFips(null);
                localStorage.removeItem("fmrsf")
              }
              localStorage.setItem("fmrf2", value);
              localStorage.removeItem("fmrf3");            
              localStorage.removeItem("fmrcf");
            } else if (property == 'County'){             
              setField3(value);
              const statefound = State.find((nation: any) => nation["Fips Code"] === stateFips && nation["Country Code"] === "US");
              console.log(value, statefound, State, County)
              const countyfound = County.find((nation: any) => nation.Name === value && nation["State Code"] === statefound.Code);
        
              if (countyfound){
                let CountyF = countyfound["County FIPS Code"].substr(2)
               
                setCountyFips(CountyF); 
                localStorage.setItem("fmrcf", CountyF)
              } else {
                setCountyFips(null);
                localStorage.removeItem("fmrcf")
              }
              localStorage.setItem("fmrf3", value)
            } else if (property == 'MonitoringLocationType'){
           
              setFieldMLT(value);
            } else if (property == 'Organizations'){
              setFieldOrg(value);
            } else if (property == 'HUC'){
              setFieldHuc(value);
            } else if (property == 'CharacteristicType'){
              setFieldCharacteristicType(value)
              setField4(value);
              setField1('')
              setFieldCharacteristicName(null)
              localStorage.setItem("fmrct", value) 
              if(fullData && fullData.length > 0){
                let newData = [...dataSet]

                 //@ts-ignore
                newData = [...dataSet].filter((word: any) => word["Group Name"] === value)
                              
                //setDataSet(newData);
                //setFullData(newData);
                setDataLimited(newData.slice(0,2222));  
                }
            } else if (property == 'CharacteristicName'){
              localStorage.setItem("fmrcn", value) 
              setFieldCharacteristicName(value)
              setField1(value)

            } else if (property == 'minsample'){
              setFieldMinsample(value)
              
            } else if (property == 'minresult'){
              setFieldMinresult(value)
              
            }
      }
        
      const renderChartTooltip = (props:any) => (
        <Tooltip id="button-tooltip" {...props}>
          Explore Results with Charts and Tables
        </Tooltip>
      );

      const exportToCsv = (data: any) => {
        const nowTime = DateTime.local().toFormat('_ydL_Hms_');
        const csv = unparse(data);
        let hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'WQPexport_' + nowTime + '.csv';
        hiddenElement.click();
        if (hiddenElement.parentNode) {
          hiddenElement.parentNode.removeChild(hiddenElement);
        }
        hiddenElement.remove();
      }

      const exportToJson = (data: any) => {
        const nowTime = DateTime.local().toFormat('_ydL_Hms_');
        const csv = JSON.stringify(data);
        let hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'WQPexport_features_' + nowTime + '.json';
        hiddenElement.click();
        if (hiddenElement.parentNode) {
          hiddenElement.parentNode.removeChild(hiddenElement);
        }
        hiddenElement.remove();
      }

      const handleClickFlyTo = (event: any) => {
        if(current && global.waterQualityPortalLocations && global.waterQualityPortalLocations.features && global.waterQualityPortalLocations.features.length>0){
          let centroidR = center(global.waterQualityPortalLocations);
          if(centroidR && centroidR.geometry && centroidR.geometry.coordinates){
              current?.flyTo({
                essential: true,
                center: centroidR.geometry.coordinates,
                zoom: 9,
              });
          }  
        }
      }

      const handleClickChart = (event: any) => {
           if(!renderRestrictions(global.userOrgString, 'Field Water Data Monitor', global)){
              global.notify('Contact for access to Field Monitoring')
           } else {
              //navigate(Routes.ContactAdmin + window.location.search + '&wm=tr');  
               //return <ContactAdmin global={global} logged={true}/>;
              navigate(Routes.WaterMonitoring+ window.location.search + '&wm=tr'); 
              global.setNavTopMenu('Data Management');
                         
           }
      }

      const handleClickExport = (event: any) => {
           if(global.waterQualityPortalData){
               exportToCsv(global.waterQualityPortalData);
           } else if (!global.waterQualityPortalData && global.waterQualityPortalLocations ){
               exportToJson(global.waterQualityPortalLocations);
           }
      }

      const handleReportTypeChange = (e: any) => {
        //console.log(e, e.target.id, e.target.value)
        setReportType(e.target.value);
        global.setWaterQualityPortalDataType(e.target.value);
      };

      const handlePopoverClose = (event: any) => {

      }

    
      const popoverLink = (event:any) => {   
          return (
            <Popover id="filterstate-query-popover" placement='auto-start' className='filterstate-popover filterstate-popover-query' >
              <Popover.Header as="h3" style={{textAlign: 'center', fontSize: '1.3em'}}>
                <span>EPA Water Quality Portal Tool</span>
                
              </Popover.Header>
              <Popover.Body>
                <div style={{minWidth: '25vw', fontSize: 14, height: 'calc(55vh - 50px)', maxHeight: 540}}>
                  <Form style={{overflowY: 'auto', height: '100%', overflowX: 'clip'}}>
                                <div className="fs-query-input" style={{paddingLeft: 5, height: '100%', display: 'flex'}}>

                                   <div className='fs-column' style={{display: 'none', maxWidth: 225, height: '100%'}}>
                                
                                  </div>  

  
                                  <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderChartTooltip}
                                  >
                                    <div className={'wmr-dq-chart'} 
                                          style={{position: 'absolute', bottom: 0, left: 20, width: 60, height:60}}>

                                        <Button className='users-button'  
                                          style={{width: 50, height:50}}    
                                        /*  disabled={!accessFM}  */                                       
                                          onClick={handleClickChart}>
                                          <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <FontAwesomeIcon icon={faPieChart} size='lg' className="fs-icon-app" />
                                            <span style={{paddingLeft: 2, display: 'inline-block', fontSize:9, paddingTop:4}}>Chart</span>
                                          </div>   
                                        </Button>
                                    </div>
                                  </OverlayTrigger>  
                                  <div className={'wmr-dq-chart'} 
                                        style={{position: 'absolute', bottom: 0, left: 80, width: 60, height:60}}>

                                      <Button className='users-button'  
                                        style={{width: 50, height:50}}    
                                                                 
                                        onClick={handleClickExport}>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                          <FontAwesomeIcon icon={faFileArrowDown} size='lg' className="fs-icon-app" />
                                          <span style={{paddingLeft: 2, display: 'inline-block', fontSize:9, width: 29, alignSelf: 'self-end', paddingTop:4}}>Export</span>
                                        </div> 
                                      </Button>
                                  </div>
                                  <div className={'wmr-dq-chart'} 
                                        style={{position: 'absolute', bottom: 0, left: 140, width: 60, height:60}}>

                                      <Button className='users-button'  
                                        style={{width: 50, height:50}}    
                                                                 
                                        onClick={handleClickFlyTo}>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                          <FontAwesomeIcon icon={faArrowsToCircle} size='lg' className="fs-icon-app" />
                                          <span style={{paddingLeft: 2, display: 'inline-block', fontSize:9, width: 29, alignSelf: 'self-end', paddingTop:4}}>Fly To</span>
                                        </div> 
                                      </Button>
                                  </div>                                  

                                    <div className={'wmr-dq-submit-status'} style={{height: 115}}>

                                     <Form.Group  className="GD-form-type" controlId="formBail" 
                                          style={{height: 22, display: 'flex', flexDirection: 'row', padding: 10, marginLeft: 5, marginBottom:10, marginTop: 10}}
                                          >
                                        <Form.Label style={{width: 150, fontSize: '1.2em', whiteSpace: 'nowrap'}}>Select Return Type</Form.Label>
                                        <div  style={{display: 'flex',flexDirection: 'row', marginLeft: 5}}>

                                            <Form.Check 
                                              type={'radio'}       
                                              id={`MonitoringLocation`}
                                              label={`Site only`}
                                              style={{marginLeft: 5, fontSize: '1.2em'}}
                                              onChange={handleReportTypeChange}
                                              name="groupType"
                                              value='locations'
                                              defaultChecked={(global.waterQualityPortalDataType==='locations') ? true : false}
                                            />
{/*                                              
                                            <Form.Check 
                                              type={'radio'}     
                                              id={`SummaryMonitoringLocation`}
                                              label={`Summary Monitoring Locations`}
                                              name="group1"
                                              value='SummaryMonitoringLocation'                                             
                                            />
*/}
                                            <Form.Check 
                                              type={'radio'}     
                                              id={`Results`}
                                              label={`Results`}
                                              style={{marginLeft: 15, fontSize: '1.2em'}}
                                              onChange={handleReportTypeChange}
                                              name="groupType"
                                              value='results'
                                              defaultChecked={(global.waterQualityPortalDataType==='results') ? true : false}
                                              /> 

                                            <Form.Check 
                                              type={'radio'}     
                                              id={`ResultDetectionQuantitationLimit`}
                                              label={`ResultDetectionQuantitationLimit`}
                                              style={{marginLeft: 15, fontSize: '1.2em'}}
                                              onChange={handleReportTypeChange}
                                              name="groupType"
                                              value='ResultDetectionQuantitationLimit'
                                              defaultChecked={(global.waterQualityPortalDataType==='ResultDetectionQuantitationLimit') ? true : false}
                                              /> 
                                         </div>        
                                    </Form.Group>     

                                      <Alert key={'primary'} 
                                          variant={(resultMessage.substr(0,5)=== 'Error')?'warning':'success'} 
                                          style={(resultMessage)?{display:'block', fontSize:18, width: '90%', marginLeft: '5%'}:{display:'none'}}>
                                        {resultMessage}
                                      </Alert>
                                    </div>
                                    
                                    <div className={'wmr-dq-submit'}>
                                      <Button className='users-button'  
                                        style={{minWidth: 150, maxHeight:40, height: 50}}  

                                        onClick={queryData}>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                         <div style={{minWidth: 23, alignSelf:'center'}}>
                                          <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                             style={(queryActive)?{display:'block'}:{display:'none'}}
                                          />
                                          </div>
                                          <span style={{paddingLeft: 2}}>Query Data</span>
                                        </div> 
                                      </Button>
                                    </div>

                                    <div style={{position:'absolute', bottom: 2, right: 20, display: 'flex', flexDirection: 'column'}}>
                                       <Button className='users-button'  
                                          style={{maxHeight:40, alignSelf: 'center'}}  
                                          onClick={clearData}>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                          <span style={{paddingLeft: 2}}>Clear Data</span>
                                        </div> 
                                      </Button>
                                        <a href={'https://www.waterqualitydata.us/'} target={'_blank'}>
                                          {' '}EPA Water Quality Portal
                                        </a>
                                    </div>

                                    <div className='fs-column' style={{maxWidth: 230, height: '100%'}}>
                                          <Form.Label style={{width: '46%', minWidth: 50, fontSize: '1em'}}>Select Criteria:</Form.Label>
                                          <OverlayTrigger  rootClose trigger="click" placement='auto' overlay={popoverFields}>
                                            <Button className='fs-column-button'>
                                              <FontAwesomeIcon icon={faQuestion}/>
                                            </Button> 
                                          </OverlayTrigger>

                                          <div className='wmr-dq-cont'  style={{display: 'flex', flexDirection: 'column', padding: 5, marginLeft: 10, marginTop: 2}}>
                                            <Form.Label style={{ fontSize: '1em'}}>Location</Form.Label> 
                                            

                                            <Form.Group className="mb-2" controlId="floatingMonitoringLocationType">
                                                <FloatingLabel controlId="floatingMonitoringLocationType" label="Country">
                                                    <Form.Select style={{color:'white'}} onChange={(e: any) => updateMon('Country', e.target.value)}
                                                      aria-label="MonitoringLocIdent_MonitoringLocationTypeName" value={field5||''}>
                                                      <option key={'wm-proj-optionsss'} value={''}>{''}</option>
                                                        {CountryFiltered  && CountryFiltered.map((obj:any, index:any) =>
                                                            (<option  selected={global.currentCountry} key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                                        )} 
                                                    </Form.Select>
                                                </FloatingLabel> 
                                            </Form.Group>


                                             <Form.Group className="mb-2" controlId="floatingMonitoringLocationType">
                                                <FloatingLabel controlId="floatingMonitoringLocationType" label="State">
                                                    <Form.Select  style={{color:'white'}} onChange={(e: any) => updateMon('State', e.target.value)}
                                                      aria-label="MonitoringLocIdent_MonitoringLocationTypeName"  value={field2||'Any'}>
                                                        <option key={'wm-proj-optionxxsss'} value={''}>{''}</option>
                                                        {StateFiltered  && StateFiltered.map((obj:any, index:any) =>
                                                            (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                                        )} 
                                                    </Form.Select>
                                                </FloatingLabel> 
                                            </Form.Group>


                                             <Form.Group className="mb-2" controlId="floatingMonitoringLocationType">
                                                <FloatingLabel controlId="floatingMonitoringLocationType" label="County">
                                                    <Form.Select style={{color:'white'}} onChange={(e: any) => updateMon('County', e.target.value)}
                                                      aria-label="MonitoringLocIdent_MonitoringLocationTypeName"  value={field3||'Any'}>
                                                        <option key={'wm-proj-optionxxsssss'} value={''}>{''}</option>
                                                        {CountyFiltered  && CountyFiltered.map((obj:any, index:any) =>
                                                            (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                                        )} 
                                                    </Form.Select>
                                                </FloatingLabel> 
                                            </Form.Group>


                                            <Form.Group className="mb-2" controlId="floatingMonitoringLocationTypea" style={{marginTop: 13}}>
                                                <FloatingLabel controlId="floatingMonitoringLocationTypea" label="Site Type">
                                                    <Form.Select style={{color:'white'}} onChange={(e: any) => updateMon('MonitoringLocationType', e.target.value)}
                                                      aria-label="MonitoringLocIdent_MonitoringLocationTypeNamea">
                                                      <option key={'wm-proj-options+ssindex'} value={'Any'}>{'Any'}</option>
                                                        {siteTypesA && siteTypesA.map((obj:any, index:any) =>
                                                            (<option key={'wm-proj-optionssssaa'+index} value={obj.value}>{obj.value}</option>)
                                                        )} 
                                                    </Form.Select>
                                                </FloatingLabel> 
                                            </Form.Group>


                                            <Form.Group className="mb-2" controlId="floatingMonitoringLocationType">
                                                <FloatingLabel controlId="floatingMonitoringLocationType" label="Organization">
                                                    <Form.Select style={{color:'white'}} onChange={(e: any) => updateMon('Organizations', e.target.value)}
                                                        aria-label="MonitoringLocIdent_MonitoringLocationTypeName">
                                                      <option key={'wm-proj-options+index'} value={'Any'}>{'Any'}</option>
                                                        {Organizations  && Organizations.sort((x: any, y: any) => x['Name'].localeCompare(y['Name'])).map((obj:any, index:any) =>
                                                            (<option key={'wm-proj-options'+index} value={obj.ID}>{`${obj.Name} : ${obj.ID}`}</option>)
                                                        )} 
                                                    </Form.Select>
                                                </FloatingLabel> 
                                            </Form.Group>

                                          <Form.Group className="mb-2" controlId="8-Digit HUC">
                                             <FloatingLabel controlId="8-Digit HUC" label="8-Digit HUC">
                                                  <Form.Control 
                                                  onChange={(e: any) => updateMon('HUC', e.target.value)}
                                                  size="lg" type="text" placeholder="8-Digit HUC" defaultValue={data && data.MonitoringLocIdent_HUCEightDigitCode}/>
                                              </FloatingLabel> 
                                          </Form.Group>
                                        </div>
                                   </div>           

                                   
                                    <div className='fs-column'  style={{display: 'flex', flexDirection: 'column', padding: 5, marginLeft: 10, marginTop: 10, width: '20%', minWidth: 140, fontSize: '1em'}}>
                                        
                                        <div   style={{display: 'flex', flexDirection: 'row', width: 170, marginTop: 60}}>
                                          <Form.Check     
                                                    id={`check-BoundingBox`}
                                                    label={``}
                                                    name="groupbb"
                                                    value='BoundingBox'
                                                    checked={boundingBoxActive} 
                                                    onChange={() => setBoundingBoxActive(!boundingBoxActive)}
                                                  />
                                           <Form.Label  style={{}}>Bounding Box</Form.Label> 
                                           <OverlayTrigger trigger="click" rootClose placement='auto' overlay={popoverBoundingBox}>
                                            <Button  className='fs-column-button' style={{transform: 'scale(.8) translate(10px, -7px)'}}>
                                              <FontAwesomeIcon icon={faQuestion}/>
                                            </Button> 
                                           </OverlayTrigger>
                                             
                                        </div>

                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                          <Form.Group className="mb-2" controlId="North" style={{width: 140, marginLeft: 10, minWidth:70}}>
                                             <FloatingLabel controlId="North" label="North"  className='fs-column-floating'>
                                                  <Form.Control  
                                                  onChange={(e: any) => updateMon('North', e.target.value)}
                                                  size="lg" type="text" placeholder="North" defaultValue={global && global.filterBoundingBox && global.filterBoundingBox[0]}/>
                                              </FloatingLabel> 
                                          </Form.Group>

                                        </div>    
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                           <Form.Group className="mb-2" controlId="West" style={{width: 80, marginLeft: 1, minWidth:70}}>
                                             <FloatingLabel controlId="West" label="West">
                                                  <Form.Control 
                                                  onChange={(e: any) => updateMon('West', e.target.value)}
                                                  size="lg" type="text" placeholder="West" defaultValue={global && global.filterBoundingBox && global.filterBoundingBox[1]}/>
                                              </FloatingLabel> 
                                          </Form.Group>  

                                            <Form.Group className="mb-2" controlId="East" style={{width: 80, marginLeft: 2, minWidth:70}}>
                                             <FloatingLabel controlId="East" label="East">
                                                  <Form.Control 
                                                  onChange={(e: any) => updateMon('East', e.target.value)}
                                                  size="lg" type="text" placeholder="East" defaultValue={global && global.filterBoundingBox && global.filterBoundingBox[2]}/>
                                              </FloatingLabel> 
                                          </Form.Group>

                                        </div>     


                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                          <Form.Group className="mb-2" controlId="South" style={{width: 140, marginLeft: 10, minWidth:70}}>
                                             <FloatingLabel controlId="South" label="South" className='fs-column-floating'>
                                                  <Form.Control  
                                                  onChange={(e: any) => updateMon('South', e.target.value)}
                                                  size="lg" type="text" placeholder="South" defaultValue={global && global.filterBoundingBox && global.filterBoundingBox[3]}/>
                                              </FloatingLabel> 
                                          </Form.Group>
   
                                        </div>                                      
                                    </div>
                                   
                                    <div className='fs-column fs-column-wqp'  >

                                        <div className='fs-col-results' >
                                            <Form.Label className='wmr-dq-button-label' >{ 'Filter Results'} </Form.Label>
                                        
                                            <div style={{marginLeft: 12}}>

                                              <Form.Group className="mb-2" controlId="floatingMonitoringLocationType">
                                                  <FloatingLabel controlId="floatingMonitoringLocationType" label="Characteristic Type">
                                                      <Form.Select style={{color:'white'}} onChange={(e: any) => updateMon('CharacteristicType', e.target.value)}
                                                        aria-label="MonitoringLocIdent_MonitoringLocationTypeName" value={field4||'Any'}>
                                                        <option key={'wm-proj-optionsss+index'} value={'Any'}>{'Any'}</option>
                                                          {CharacteristicType  && CharacteristicType.map((obj:any, index:any) =>
                                                              (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                                          )} 
                                                      </Form.Select>
                                                  </FloatingLabel> 
                                              </Form.Group>

{/*                                              <div className='wmr-dq-button-cont' style={{marginTop: 20}}>
                                                <Form.Label style={{marginLeft: 5}}>Characteristic</Form.Label>
                                                <Button className='wmr-dq-button'   style={{marginLeft: 35, minHeight: 40, width: '100%'}}                                       
                                                  onClick={handleCharacteristicClick}>
                                                  <Form.Label style={{verticalAlign:'sub'}}  >{field1}</Form.Label>
                                                </Button>
                                                
                                              </div>*/}

{/*
                                              <Form.Group className="mb-2" controlId="floatingMonitoringLocationType">
                                                  <FloatingLabel controlId="floatingMonitoringLocationType" label="Characteristic">
                                                      <Form.Select  onChange={(e: any) => updateMon('CharacteristicType', e.target.value)}
                                                        aria-label="MonitoringLocIdent_MonitoringLocationTypeName" value={field4||'Any'}>
                                                        <option key={'wm-proj-optionsqq'}><Form.Control 
                                                onChange={(e: any) => updateMon('MonitoringLocIdent_HUCEightDigitCode', e.target.value)}
                                                size="lg" type="text" placeholder="8-Digit HUC" /></option>
                                                          {dataLimited  && dataLimited.map((obj:any, index:any) =>
                                                              (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                                          )} 
                                                      </Form.Select>
                                                  </FloatingLabel> 
                                              </Form.Group>*/}

                                              <Form.Group className="mb-2 filter-dropdown-group" controlId="floatingMonitoringLocationType" >
                                                  
                                                      <Dropdown className="filter-dropdown"  onSelect={(e: any) => updateMon('CharacteristicName', e)}
                                                        aria-label="MonitoringLocIdent_MonitoringLocationTypeName" defaultValue={field4||'Any'}>  
                                                        <FloatingLabel className="filter-dropdown-floating" controlId="floatingMonitoringLocationType" label="Characteristic" >                                                  
                                                           <Dropdown.Toggle className="filter-dropdown-toggle" id="dropdown-autoclose-true"  >
                                                            <span style={{color:'white'}} className="filter-dropdown-toggle-span">{field1.substr(0,20)}</span>
                                                          </Dropdown.Toggle>
                                                        </FloatingLabel> 
                                                          <Dropdown.Menu className="filter-dropdown-menu" >
                                                            <Form.Control  className="filter-dropdown-search"
                                                              onChange={onChangeSearch}
                                                              size="lg" type="text" placeholder="Type to filter..."  />
                                                              {/* @ts-ignore */}
                                                              <Dropdown.Item  key={'wm-proj-optiossns'} eventKey={'Any'} value={'Any'}> 
                                                                    <span className="filter-dropdown-item-span">{'Any'}</span></Dropdown.Item>
                                                            {dataLimited && dataLimited.map((obj:any, index:any) =>
                                                            // @ts-ignore
                                                                (<Dropdown.Item  key={'wm-proj-options'+index} eventKey={obj.Name} value={obj.Name}> 
                                                                    <span className="filter-dropdown-item-span">{obj.Name}</span></Dropdown.Item>)
                                                            )} 
                                                          </Dropdown.Menu>
                                                      </Dropdown>
                                                  
                                              </Form.Group>


                                              <div style={{display:'flex', flexDirection: 'column'}}>
                                                <div className={'filter-date-range'} style={{marginTop: 2}}>
                                                  <Form.Label className='wmr-dq-button-form-label' style={{width: '100%'}}>Date Range</Form.Label>

                                                  <div style={{width: '100%', marginLeft: 20, marginTop: 3}}>
                                                    <Form.Label  style={{width: 50}}>Start</Form.Label>
                                                    <img src={calendarIcon} className='calendar-icon' style={{marginRight: 12, verticalAlign:'sub'}} />  
             
                                                    <DatePicker className='wmr-dq-dp'
                                                      selected={qdateS}
                                                      onChange={(date: Date) => updateSingleDateSelection(date)}
                                                    />
                                                  </div>
                                                  
                                                  <div style={{width: '100%', marginLeft: 20}}>
                                                    <Form.Label style={{width: 50}}>End</Form.Label>
                                                    <img src={calendarIcon} className='calendar-icon' style={{marginRight: 12, verticalAlign:'sub'}}/>

                                                    <DatePicker className='wmr-dq-dp'
                                                      selected={qdateE}
                                                      onChange={(date: Date) => updateSingleDateSelectionEnd(date)}
                                                    />
                                                  </div>
                                                </div>

                                                <Form.Group className="mb-2" controlId="minsample" style={{marginTop: 20}}>
                                                   <FloatingLabel controlId="minsample" label="Minimum Sampling Activities Per Site">
                                                        <Form.Control 
                                                        onChange={(e: any) => updateMon('minsample', e.target.value)}
                                                        size="lg" type="text" placeholder="1" />
                                                    </FloatingLabel> 
                                                </Form.Group>

                                                <Form.Group className="mb-2" controlId="minresult">
                                                 <FloatingLabel controlId="minresult" label="Minimum Results Per Site">
                                                      <Form.Control 
                                                      onChange={(e: any) => updateMon('minresult', e.target.value)}
                                                      size="lg" type="text" placeholder="1" />
                                                  </FloatingLabel> 
                                                </Form.Group>

                                                <Form.Check     
                                                  id={`MonitoringLocation`}
                                                  label={`Summarize Characteristic Groups`}
                                                  name="group1"
                                                  value='MonitoringLocation'
                                                  checked={fieldSummarize} 
                                                  onChange={() => setFieldSummarize(!fieldSummarize)}
                                                />

                                              </div>



                                            </div>  
                                        </div>
                                            
{/*                                                  <div className='wmr-dq-button-cont'>
                                                    <Button className='wmr-dq-button'                                        
                                                      onClick={handleField1Click}>
                                                      <Form.Label className='wmr-dq-button-label' >{ 'Characteristic Type:'} </Form.Label>
                                                    </Button>
                                                    <Form.Label className='wmr-dq-button-form-label'>{field4}</Form.Label>
                                                  </div>
*/}


{/*
                                                  <div className='wmr-dq-button-cont'>
                                                    <Button className='wmr-dq-button'                                        
                                                      onClick={handleField1Click}>
                                                      <Form.Label className='wmr-dq-button-label' >{ 'State:'} </Form.Label>
                                                    </Button>                                                  
                                                    <Form.Label className='wmr-dq-button-form-label'>{field2}</Form.Label>
                                                  </div>

                                                  <div className='wmr-dq-button-cont'>
                                                    <Button className='wmr-dq-button'                                         
                                                      onClick={handleField1Click}>
                                                      <Form.Label  className='wmr-dq-button-label' >{ 'County:'} </Form.Label>
                                                    </Button>                                                  
                                                    <Form.Label className='wmr-dq-button-form-label'>{field3}</Form.Label>
                                                  </div>*/}

{/*                                                  <div className='wmr-dq-button-cont'>
                                                    <Form.Label style={{width: 120, minWidth: 120, marginLeft: 10}}>{'Years:'} </Form.Label>
                                                    <Form.Control onChange={onChangeYears} style={{maxWidth: 130}} type="text" value={yearsFilt} placeholder="5" />  
                                                  </div>*/}
                                          </div>

                                      
   




                                  <div className='fs-column' style={(activeIndex) ? {display: 'none', flexDirection: 'column', height: '100%', marginLeft: 70} : {display:'none'}}>

                                      <Form.Group  className='users-search' controlId="formSearch"  style={{maxWidth: 290, minWidth: 120, marginLeft: 10, marginTop: 30}}>                                                 
                                          <Form.Label style={{width: '30%', minWidth: 150, fontSize: '1em'}}>Enter Search:</Form.Label>

                                          <Form.Control onChange={onChangeSearch} type="text" placeholder="Search" />
                                      </Form.Group>

                                      <div style={{display: 'flex', flexDirection: 'row'}}>
                                         <Button className='users-button'
                                               style={{maxWidth: 100, marginTop: 20, marginLeft: 10}}                                           
                                              onClick={clearQueryData}>
                                              Clear
                                        </Button> 
                                              
                                         <Button className='users-button'
                                               style={{maxWidth: 100, marginTop: 20, marginLeft: 10}}                                           
                                              onClick={cancelQueryData}>
                                              Close Search
                                        </Button>                                      
                                      </div>

                                      <div className="reference-data-table-container table-scroll" style={{maxWidth: '25vw', height: '40%', maxHeight: '20vh', marginLeft: 10, marginTop: 20}}>                  
                                          <ReportTable columns={tableColumns} data={dataLimited}
                                                       clickLoadStop={true}
                                                       clickRowHandler={clickRowHandler} />
                                      </div>

                                   </div> 


                           </div>           
  
                  </Form>
                </div>
              </Popover.Body>
            </Popover>
          );
      };
                    
      return (
        <>
            {data && (
            <OverlayTrigger key={'dashboard-grid-pop_'+'link.timestamp'} 
              trigger={['click']} placement="auto"  rootClose  overlay={popoverLink("link.timestamp")}>
              <div className='FilterState'  id="filterstate-filter-div">
                  <div className="fs-container-wide"  id="filterstate-fs-container" style={(mediaQuery9.matches) ? {minWidth: 'unset !important'} : {}}>
                    <FontAwesomeIcon icon={faDroplet} size='lg' className="fs-icon-app" />
                    <span className="fs-span-app" style={{paddingRight: 5}}>{(mediaQuery9.matches) ? 'WQP' : 'Water Quality Portal'}</span> 
      
                  </div>                  
              </div>                    
            </OverlayTrigger>     
            )}
        </>
      );         

    } else {


      return (
        <>
            {data && data[0] && (
            <div className='FilterState'>
                <div className="fs-container fs-container-date">
                        <img style={(mediaQuery.matches) ? {display:'none'} : {}} src={calendarIcon} className='calendar-icon fs-calendar-icon' />
                        <span style={(mediaQuery.matches) ? {display:'none'} : {}} className="fs-span">Date</span>
                        <span className="fs-span">{data[0]}{data[1] ? ' - ' : ''}</span>
                        <span className="fs-span-end">{data[1]}</span>
                </div>   
            </div>
            )}
        </>
      );
    }
};

export default memo(FilterState);