import { DateTime } from 'luxon';
import localForage from "localforage";


export const getWQXStore = (callback: any) => {    
         getStore("wm_wqx", callback);
};

export const getImportTaxaStore = (callback: any) => {    
         getStore("wm_importTaxa", callback);
};

export const setImportTaxaStore = (data: any) => {    
         localForage.setItem("wm_importTaxa", data);
};

export const getImportCharStore = (callback: any) => {    
         getStore("wm_importChar", callback);
};

export const setImportCharStore = (data: any) => {    
         localForage.setItem("wm_importChar", data);
};

export const getImportTranslationStore = (callback: any) => {    
         getStore("wm_importTranslation", callback);
};

export const setImportTranslationStore = (data: any) => {    
         localForage.setItem("wm_importTranslation", data);
};

export const getTimeZoneStore = (callback: any) => {    
         getStore("wm_timezone", callback);
};



export const getOrg_credentials = (callback: any) => {    
         getLocalStore("wm_org_credentials", callback);
};

export const setOrg_credentials = (data: any) => {    
         setLocalStore("wm_org_credentials", data);
};




export const getGeneralApplicationSettingsStore = (callback: any) => {    
         getStore("wm_GeneralApplicationSettings", callback);
};

export const setGeneralApplicationSettingsStore = (data: any) => {    
         localForage.setItem("wm_GeneralApplicationSettings", data);
};





export const getStore = (store: string, callback: any) => {    
         localForage.getItem(store, function(err, value) {
            if(err){
              console.log(err)
            }
            if(value){
              //@ts-ignore
              callback(value)
            }
        })  
};

export const getLocalStore = (store: string, callback: any) => {    
		let storedValue = localStorage.getItem(store);
		if(storedValue){
			callback(storedValue);
		}   
};

export const setLocalStore = (store: string, data: any) => {    
		localStorage.setItem(store, data);
};
