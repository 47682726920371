// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-container {
  position: relative; }
  .chat-container .header {
    background-color: #0b1736;
    padding: 1rem;
    margin-top: 72px;
    position: fixed;
    z-index: 10; }
  .chat-container .report-header {
    height: 55px;
    margin-top: -1.5rem;
    background-color: #0b1736; }
    .chat-container .report-header .header-options-container {
      display: none; }
  .chat-container .iframe-wrapper {
    position: relative;
    height: 100% !important; }
    .chat-container .iframe-wrapper iframe {
      margin-top: 2rem;
      position: absolute;
      height: 100vh !important; }

.loading-spinner svg {
  position: absolute;
  top: 50%; }
`, "",{"version":3,"sources":["webpack://./src/features/reports/Chat/chat-styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB,EAAA;EADpB;IAGI,yBAAyB;IACzB,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,WAAW,EAAA;EAPf;IAWI,YAAY;IACZ,mBAAmB;IACnB,yBAAyB,EAAA;IAb7B;MAeM,aAAa,EAAA;EAfnB;IAmBI,kBAAkB;IAClB,uBAAuB,EAAA;IApB3B;MAsBM,gBAAgB;MAChB,kBAAkB;MAClB,wBAAwB,EAAA;;AAK9B;EAEI,kBAAkB;EAClB,QAAQ,EAAA","sourcesContent":[".chat-container {\n  position: relative;\n  .header {\n    background-color: #0b1736;\n    padding: 1rem;\n    margin-top: 72px;\n    position: fixed;\n    z-index: 10;\n  }\n\n  .report-header {\n    height: 55px;\n    margin-top: -1.5rem;\n    background-color: #0b1736;\n    .header-options-container {\n      display: none;\n    }\n  }\n  .iframe-wrapper {\n    position: relative;\n    height: 100% !important;\n    iframe {\n      margin-top: 2rem;\n      position: absolute;\n      height: 100vh !important;\n    }\n  }\n}\n\n.loading-spinner {\n  svg {\n    position: absolute;\n    top: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
