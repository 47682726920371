import { useEffect, useState, useRef, useCallback, useContext, useMemo, Ref, useLayoutEffect } from "react";
import * as React from 'react';
import {
  Map, MapboxStyle, useMap, MapRef, MapLayerMouseEvent, Popup,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  useControl, 
  Layer,
  Source,
  FillLayer,
} from "react-map-gl";

import { Form, Button, Row, Col, Table, Container, Card, Accordion, Tabs, Tab, Navbar, Nav, Dropdown, Spinner, Image as Bmage, NavItem, OverlayTrigger, Popover, Tooltip as TooltipBs, ListGroup, Modal, Carousel, ProgressBar  } from "react-bootstrap";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Expression, StyleFunction } from 'mapbox-gl';
import agent from "../../../api/agent";
import { formatRowValue } from "../../utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faQuestion, faAreaChart, faMap, faTable, faShower, faLeaf, faTint, faLineChart, faArrowUpFromGroundWater } from '@fortawesome/pro-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import LoadingDataAnimation from "../../../components/LoadingDataAnimation";
import { Feature, GeoJsonProperties, Point, Polygon, LineString, GeometryCollection, FeatureCollection, Geometry } from 'geojson';
import type { LayerProps } from 'react-map-gl';
import { FullScreen, useFullScreenHandle } from "react-full-screen";  
import html2canvas from 'html2canvas';
import { jsPDF, ImageOptions } from "jspdf";
import autoTable from 'jspdf-autotable';
import { ThemeType, Color } from 'jspdf-autotable';
import moment from 'moment';
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import * as d3Scale from 'd3-scale';
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import bbox from '@turf/bbox';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { DateTime } from 'luxon';
import { parse } from 'papaparse';
import { ToastContainer, toast } from 'react-toastify';
import localForage from "localforage";
import { AppContext } from '../../../AppContext';
import { CatalogsContext } from '../../../CatalogsProvider';
import { Location, useLocation } from 'react-router-dom';
import MapLegend from '../shared/MapLegend';
import MapSlider from '../shared/MapSlider';
import MapAddition from  '../shared/MapAddition';
import FilterState from '../shared/FilterState';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import ReportMap from '../shared/ReportMap';
import ReportTableExpanded from '../shared/ReportTableExpanded';
import {
  useTable, useExpanded, HeaderGroup,
  HeaderProps
} from 'react-table'
import { GroundwaterReport } from "../PrintReports/GroundwaterReport";
import RenderLayers from '../shared/RenderLayers';
import RenderLayerMarkers from '../shared/RenderLayerMarkers';
import { flyToHUC12sLocation, flyToInitialLocationHandler, getCurrentHuc12LongLat, getHuc12MembersOfHUC8, getCurrentHuc8LongLat } from '../../utils';
import Render from "../../../components/render";
import { RefContext } from '../../../RefContext';
import { createFeatureCollection, processStatusMessage, uniqueValues, updateChartData, groupByKey, fitBoundsMapData, findFittingDateRange } from '../shared/report-data';
import fullscreenIcon from "../../../assets/fullscreen-icon.svg";
import fullscreenIconW from "../../../assets/fullscreen-icon_w.svg";
import ellipsisIcon from "../../../assets/ellipsis-icon.svg";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { features } from "process";
import * as formik from 'formik'; 

import { setChartConfigModelScenario, setChartConfigPeak, setChartConfigFlow } from './GroundwaterChartConfigs';   
import { dataScenarioStatic, dataFlowStatic, dataPeakStatic, dataSpringStatic, dataSummerStatic, dataFallStatic, dataWinterStatic, dataModelStatic, dataModelScenarioStatic } from './GroundwaterData';
import { inpre, inprelocation, in1, out1, in2, out2, in3, out3, in4, out4reach, inprereach, hucListNoCoverage, dayRunHour, streamFieldList  } from './GroundwaterData';
import { marksR, marksSeason } from './GroundwaterMarks';
import { getLocation, getReach, getScenarioPCRP, getModelPCRP, getGraphs } from './GroundwaterQuery';
import './styles.scss'
import gwRechargeIcon from '../../../assets/groundwaterrecharge.svg';
import {characteristicsLocal, characteristicsNLDI} from './GroundwaterValues';

import axios, { AxiosResponse } from 'axios';
import settings from '../../../settings.json';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { Scrollbars } from 'react-custom-scrollbars-2';
import queryString from 'query-string';
import {
    QueryParamProvider,
    useQueryParam,
    StringParam,
    NumberParam,
    ArrayParam,
    BooleanParam,
    JsonParam,
    DateParam,
    withDefault,
} from 'use-query-params';

import { Line, Bar, Scatter, getElementAtEvent, getElementsAtEvent } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataset, ChartType, TooltipItem
} from "chart.js";
import zoomPlugin from 'chartjs-plugin-zoom';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);
    
     
export default function GroundwaterDetermination(props: any) {
  const { global } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);
  const selectedCategory = (global.filter1Selected || '');
  const selectedFilterLocations = global.filterLocationsSelected;           
  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;                                            
  const colorGreen = "#4fc22c";
  const colorGold= "rgb(246, 178, 107)"; 
  const colorBlue = "rgb(79, 194, 217)"; 
  const colorBack = "rgb(229, 236, 246)";

  const colorChromatic = [
    "interpolateBlues",
    "interpolateBrBG",
    "interpolateBuGn",
    "interpolateBuPu",
    "interpolateCividis",
    "interpolateCool",
    "interpolateCubehelixDefault",
    "interpolateGnBu",
    "interpolateGreens",
    "interpolateGreys",
    "interpolateInferno",
    "interpolateMagma",
    "interpolateOrRd",
    "interpolateOranges",
    "interpolatePRGn",
    "interpolatePiYG",
    "interpolatePlasma",
    "interpolatePuBu",
    "interpolatePuBuGn",
    "interpolatePuOr",
    "interpolatePuRd",
    "interpolatePurples",
    "interpolateRainbow",
    "interpolateRdBu",
    "interpolateRdGy",
    "interpolateRdPu",
    "interpolateRdYlBu",
    "interpolateRdYlGn",
    "interpolateReds",
    "interpolateSinebow",
    "interpolateSpectral",
    "interpolateTurbo",
    "interpolateViridis",
    "interpolateWarm",
    "interpolateYlGn",
    "interpolateYlGnBu",
    "interpolateYlOrBr",
    "interpolateYlOrRd"
  ];



  const { current: map } = useMap();
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [cursor, setCursor] = useState<string>('auto');
  const mapRef = useRef<MapRef>();
  const chartRef = useRef<any>();
  const [userSelectedLocation, setUserSelectedLocation] = useState<any>(null);
  const [userSelectedLocations, setUserSelectedLocations] = useState<any[]>([]);
  const [usLocations, setUsLocations] = useState<any>([]);
  const [hoverLocation, setHoverLocation] = useState<any>([]);
  const [clickLocation, setClickLocation] = useState<any>([]);
  const [statusMessage, setStatusMessage] = useState<any>(null);
  const [comidData, setComidData] = useState<any>(null);
  const [layerStyle, setLayerStyle] = useState<any>([]);
  const [layerStyle2, setLayerStyle2] = useState<any>([]);                 
  const [layerStyle3, setLayerStyle3] = useState<any>([]);
  const [layerStyleEx, setLayerStyleEx] = useState<any>([]);
  const [layerStyleC, setLayerStyleC] = useState<any>([]);
  const [layerStyleHUC, setLayerStyleHUC] = useState<any>([]);
  const [mapLegendData, setMapLegendData] = useState<any[]>([]);
  const [mapLegendPointData, setMapLegendPointData] = useState<any[]>([]);
  const locationField = 'comid';
  const [mapHoverFilter, setMapHoverFilter] = useState<any>(['in', ['get', locationField], '']); //8549637
  const [dataLoaded, setDataLoaded] = useState(false);
  const [validated, setValidated] = useState(false);
  //const [queryActive, setQueryActive] = useState<any>(false);
  const [updateActive, setUpdateActive] = useState<any>(0);

  const [flowBack, setFlowBack] = useState<string>('');
  const [modelYearPrecipitation, setModelYearPrecipitation] = useState<string>('');
  const [scenarioYearPrecipitation, setScenarioYearPrecipitation] = useState<string>('');
  const [yearPrecipitation, setYearPrecipitation] = useState<string>('');
  const [currentModelBase, setCurrentModelBase] = useState<string>('1991-2020');
  const [graphResponse, setGraphResponse] = useState<any>();
  const [jobResponse, setJobResponse] = useState<any>();
  const [modelPCRPdf, setModelPCRPdf] = useState<any>();
  const [prcp2020df, setPrcp2020df] = useState<any>();
  const [prcpdiff, setPrcpdiff] = useState<any>();

  const [huc8_pet, setHuc8_pet] = useState<any>();
  const [huc8_prcp, setHuc8_prcp] = useState<any>();
  const [rechargeValues, setRechargeValues] = useState<any>();
  const [nhldData, setNhldData] = useState<any>();

  const [qsgdh, setqsgdh] = useQueryParam('gdh', StringParam);
  const [qsgdfy, setqsgdfy] = useQueryParam('gdfy', StringParam);
  const [qsgdrs, setqsgdrs] = useQueryParam('gdrs', StringParam);
  const [qsgdry, setqsgdry] = useQueryParam('gdry', StringParam);
  const [qsgdsc, setqsgdsc] = useQueryParam('gdsc', NumberParam);
  const [qsgdsw, setqsgdsw] = useQueryParam('gdsw', NumberParam);
  const [qsgdsp, setqsgdsp] = useQueryParam('gdsp', NumberParam);
  const [qsgdsm, setqsgdsm] = useQueryParam('gdsm', NumberParam);
  const [qsgdsf, setqsgdsf] = useQueryParam('gdsf', NumberParam);
  const [qsgdh12, setqsgdh12] = useQueryParam('gdh12', StringParam);
  const [qsgdds, setqsgdds] = useQueryParam('gdds', BooleanParam);

  const [huc8name, sethuc8name] = useState<any>('');
  const huc12ValueList = [""]; 
  const huc12ValueListName = [] as any;
  const CatcOptions = [{label:'Groundwater Recharge Value', value: 'recharge'}, {label:'Warning Level', value: 'warning'}] as any;
  const [huc12list, setHuc12list] = useState<any[]>(huc12ValueList);
  //const [huc12listMinusSelected, setHuc12list] = useState<any[]>(huc12ValueList);
  const [huc12listName, setHuc12listName] = useState<any[]>(huc12ValueListName);
  const [reachList, setReachList] = useState<any[]>(huc12ValueListName);
  const [filteredData, setFilteredData] = useState<any[]>(huc12ValueListName);
  const [huc12Selection, setHuc12Selection] = useState<any>((qsgdh12)? qsgdh12 : "");
  const [streamBank, setStreamBank] = useState<any[]>([]);
  const [streamBankFeatures, setStreamBankFeatures] = useState<FeatureCollection>();
  const [comidCollection, setComidCollection] = useState<any[]>([]);
  const [basinData, setBasinData] = useState<FeatureCollection>();
  const [updatedComid, setUpdatedComid] = useState<any>('');
  const [catchmentsData, setCatchmentsData] = useState<FeatureCollection>();
  const [currentCharacteristic, setCurrentCharacteristic] = useState<any>('recharge');
  const [currentCharacteristicInfo, setCurrentCharacteristicInfo] = useState<any>('recharge');
  const [catchmentOptions, setCatchmentOptions] = useState<any[]>(characteristicsNLDI);
  const [mapLegendAdd, setMapLegendAdd] = useState<any>();
  const [mainPanelSelection, setMainPanelSelection] = useState(0);

  let savedColorMap = localStorage.getItem('GD_CurrentColorMap')||'interpolateRdBu'; //interpolateRdBu interpolateRdYlGn
  const [currentColorMap, setCurrentColorMap] = useState<any>(savedColorMap);
  const [currentColorMapReverse, setCurrentColorMapReverse] = useState<any>(false);

  let sFields = streamFieldList.sort(function (a: any, b: any) {
                return a.title.localeCompare(b.title);
             })

  let sfls = localStorage.getItem('GD_streamfieldlist');
  if(sfls){
    sFields = JSON.parse(sfls);
  }

  const [sliderOpacity, setSliderOpacity] = useState<number>(100);

  const [streamFields, setStreamFields] = useState<any[]>(sFields);
  const [tableVersion, setTableVersion] = useState<number>(2);
  const [streamCat, setStreamCat] = useState<any[]>([]);
  const [futureYear, setFutureYear] = useState<any>((qsgdfy)? qsgdfy : '2050');
  const [rainSelection, setRainSelection] = useState<string>((qsgdrs)? qsgdrs :'Simple');
  const [rainSelectionYear, setRainSelectionYear] = useState<any>((qsgdry)? qsgdry :'2020');
  const [sliderClimate, setSliderClimate] = useState<number>((qsgdsc)? qsgdsc : 2);
  const [sliderWinter, setSliderWinter] = useState<number>((qsgdsw)? qsgdsw : 2);
  const [sliderSpring, setSliderSpring] = useState<number>((qsgdsp)? qsgdsp : 2);
  const [sliderSummer, setSliderSummer] = useState<number>((qsgdsm)? qsgdsm : 2);
  const [sliderFall, setSliderFall] = useState<number>((qsgdsf)? qsgdsf : 2);
  const [sliderSeeds, setSliderSeeds] = useState<any[]>();
  const [n_clicks, setN_clicks] = useState<number>(0);
  const [gwVersion, setGwVersion] = useState<number>(2);

  const [URL, setURL] = useState<string>(settings.scenarioForecasterAppURL + "/_dash-update-component");

  const [dataModelScenario, setDataModelScenario] = useState({
    labels: [] as any, 
    datasets: [] as any,
  } as const);   
  const [dataModel, setDataModel] = useState({
    labels: [] as any, 
    datasets: [] as any,
  } as const);   
  const [dataScenario, setDataScenario] = useState({
    labels: [] as any, 
    datasets: [] as any,
  } as const);   
  const [dataPeak, setDataPeak] = useState({
    labels: [] as any, 
    datasets: [] as any,
  } as const);  
  const [dataFlow, setDataFlow] = useState({
    labels: [] as any, 
    datasets: [] as any,
  } as const);   
  const [dataSpring, setDataSpring] = useState({
    labels: [] as any, 
    datasets: [] as any,
  } as const);   
  const [dataSummer, setDataSummer] = useState({
    labels: [] as any, 
    datasets: [] as any,
  } as const);   
  const [dataFall, setDataFall] = useState({
    labels: [] as any, 
    datasets: [] as any,
  } as const);   
  const [dataWinter, setDataWinter] = useState({
    labels: [] as any, 
    datasets: [] as any,
  } as const);   

  const [configModelScenario, setConfigModelScenario] = useState({} as const);
  const [configModel, setConfigModel] = useState({} as const);
  const [configScenario, setConfigScenario] = useState({} as const);
  const [configPeak, setConfigPeak] = useState({} as const);
  const [configFlow, setConfigFlow] = useState({} as const);
  const [configFlowA, setConfigFlowA] = useState({} as const);
  const [configSpring, setConfigSpring] = useState({} as const);
  const [configSummer, setConfigSummer] = useState({} as const);
  const [configFall, setConfigFall] = useState({} as const);
  const [configWinter, setConfigWinter] = useState({} as const);
  const chartRefModelScenario = useRef<any>();
  const chartRefModel = useRef<any>();
  const chartRefScenario = useRef<any>();
  const chartRefPeak = useRef<any>();
  const chartRefFlow = useRef<any>();
  const chartRefSpring = useRef<any>();
  const chartRefWinter= useRef<any>();
  const chartRefSummmer = useRef<any>();
  const chartRefFall = useRef<any>();
  const chartRefSummmerp = useRef<any>();

  const [isDataReportLoaded, setIsDataReportLoaded] = useState(true);
  const [isReachLoaded, setIsReachLoaded] = useState(true);
  const [isSeasonLoaded, setIsSeasonLoaded] = useState(true);
  const [isRechargeLoaded, setIsRechargeLoaded] = useState(true);
  const [progress, setProgress] = useState<number>(0);
  const [isNotInProgress, setIsNotInProgress] = useState(true);
  const [navMenu, setNavMenu] = useState<number>(0);
  const [riskMenu, setRiskMenu] = useState<number | null>(null);
  const [streamRisk, setStreamRisk] = useState<any[]>([null, null, null]);
  let loadlast = 'true'; //localStorage.getItem('GD_LoadLast') || 
  const [isLoadLastData, setIsLoadLastData] = useState((loadlast === 'true' ? true : false));
  const [isLoadLastDataAvailable, setIsLoadLastDataAvailable] = useState(false);
  const [isFullScreenSeasonActive, setIsFullScreenSeasonActive] = useState(false);
  const [isFullScreenModelActive, setIsFullScreenModelActive] = useState(false);
  const [isFullScreenFlowActive, setIsFullScreenFlowActive] = useState(false);
  const [isFullScreenMapActive, setIsFullScreenMapActive] = useState(false);
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const [isFullScreenActive, setFullScreenActive] = useState(false);
  const fullscreenContainerHandler = () => { setFullScreenActive(!isFullScreenActive); };
  const { setCurrentRef } = useContext(RefContext);
  const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;
  const [responseCounter, setResponseCounter] = useState<number>(0);
  const [retryCounter, setRetryCounter] = useState<number>(0);
  const [retryAverage, setRetryAverage] = useState<number>(13);

  let selectedHUC8Value = appContext.selectedHUC8;
  const [currentHuc8, setCurrentHuc8] = useState<string>((qsgdh) ? qsgdh : selectedHUC8Value);
  const [huc8, setHuc8] = useState<string>((qsgdh)? qsgdh : selectedHUC8Value); 
  //global.clickedHUC8

  //global.clickedHUC12

  const emptyData = {
      labels: [],
      datasets: [],
      borderColor: '#ff6384'
  };

  const notify = (message: any) => {
    global.notify(message);
  }

  const updateStore = () => {
      if(graphResponse && jobResponse && modelPCRPdf && streamBank){
        //console.log('GD_current_updateStore')     
            //setTimeout(() => {                                                      
                localForage.setItem('GD_current', [currentHuc8, futureYear, rainSelection, rainSelectionYear, sliderClimate, sliderWinter,sliderSpring, sliderSummer, sliderFall, huc12Selection, huc12list, graphResponse, jobResponse, modelPCRPdf, prcp2020df, streamBank, modelYearPrecipitation, scenarioYearPrecipitation]);
                localForage.setItem('GD_charts', [currentHuc8, futureYear, rainSelection, rainSelectionYear, sliderClimate, sliderWinter,sliderSpring, sliderSummer, sliderFall, huc12Selection, huc12list, modelYearPrecipitation, scenarioYearPrecipitation, dataModelScenario, dataScenario, dataModel, dataFlow, dataWinter, dataSpring, dataSummer, dataFall ]);
                localForage.setItem('GD_charts_'+currentHuc8, [currentHuc8, futureYear, rainSelection, rainSelectionYear, sliderClimate, sliderWinter,sliderSpring, sliderSummer, sliderFall, huc12Selection, huc12list, modelYearPrecipitation, scenarioYearPrecipitation, dataModelScenario, dataScenario, dataModel, dataFlow, dataWinter, dataSpring, dataSummer, dataFall ]);
               //localForage.setItem('GD_12_' + huc12Selection,  streamBank);
      }
  }

  const updateRecharge = (recharge:any, nhd:any) => {
    setRechargeValues(recharge)
    setNhldData(nhd)
      //updateSBwithRecharge(recharge, nhd);
  }

  const queryProps: any = {
    n_clicks, currentHuc8, gwVersion, URL, setIsDataReportLoaded, setIsReachLoaded, setIsSeasonLoaded, setIsRechargeLoaded, setIsNotInProgress,
    setModelYearPrecipitation, setScenarioYearPrecipitation,
    sliderClimate, sliderWinter, sliderSpring, sliderSummer, sliderFall, setSliderSeeds,
    rainSelection, rainSelectionYear, futureYear,
    huc12Selection, setStreamBank, setHuc12list, setHuc12Selection, progress, setProgress,
    setGraphResponse, setJobResponse, setModelPCRPdf, setPrcp2020df, setPrcpdiff,
    setRetryCounter, retryAverage,
    notify, updateStore, 
    setHuc8_prcp, setHuc8_pet, setRechargeValues, setNhldData, updateRecharge, 
    huc8name, huc12listName, currentModelBase, modelYearPrecipitation, scenarioYearPrecipitation
  }

  useEffect(() => {   
    setCurrentRef?.(mapRef)
  }, [mapRef])      
                 
  useEffect(() => {
    global.setUserLogged(true);
    if(global.trackerOn) global.setTrackerOn(false)
  }, []);     

  useEffect(() => {
      setqsgdds(true);
      if(isLoadLastData){
        loadLastData();
      } else {
        localForage.getItem('GD_charts', function(err, value) {
              console.log(err, value) 
              if(value){
                  //console.log('localForage.charts', value)
                  let cachedData = value as Array<any>;
                  if(cachedData.length === 21){
                    setIsLoadLastDataAvailable(true);
                  }
              } 
        });
      }
  }, []);

  useEffect(() => {
    updateLayerStyle();
    updateLayerStyle2();
    updateLayerStyle3();
    updateLayerStyleEx();
    updateLayerStyleC();
    //updateLayerStyleHUC();

    setMapLegendData(
      [ { typecode: '0: Very Low' , color: '#00f0d0'}, 
      { typecode: '1: Low' , color: '#afff24'}, 
      { typecode: '2: Moderate' , color: 'yellow'}, 
      { typecode: '3: High' , color: 'orange'}, 
      { typecode: '4: Very High' , color: 'red'},
      ]
     );

      const  newArray = [] as any;
      streamFieldList.forEach((dataItem:any, index:number) => {
        newArray.push({title: dataItem, descr: dataItem, fieldname: dataItem, active: false})
      })
     
  }, []);   

  useEffect(() => {
     if(!retryCounter){ //localStorage.removeItem('GD_retry');
        let gdStore = localStorage.getItem('GD_retry');
        if(gdStore){
          let gdResults = JSON.parse(gdStore);
          if(gdResults.average){
              setRetryAverage(gdResults.average);
          }    
        } else {
          localStorage.setItem('GD_retry', JSON.stringify({average: retryAverage, counts: 1})) 
        }           
     }
  }, []);


  useEffect(() => {  setqsgdh(selectedHUC8Value);  }, [currentHuc8]);
  useEffect(() => {  setqsgdfy(futureYear);  }, [futureYear]);
  useEffect(() => {  setqsgdrs(rainSelection);  }, [rainSelection]);
  useEffect(() => {  setqsgdry(rainSelectionYear);  }, [rainSelectionYear]);
  useEffect(() => {  setqsgdsc(sliderClimate);  }, [sliderClimate]);
  useEffect(() => {  setqsgdsw(sliderWinter);  }, [sliderWinter]);
  useEffect(() => {  setqsgdsp(sliderSpring);  }, [sliderSpring]);
  useEffect(() => {  setqsgdsm(sliderSummer);  }, [sliderSummer]);
  useEffect(() => {  setqsgdsf(sliderWinter);  }, [sliderWinter]);
  useEffect(() => {  setqsgdh12(huc12Selection);  }, [huc12Selection]);

  useEffect(() => {
    if(global.clickedHUC8 && global.clickedHUC8.length ===8){
       setHuc8(global.clickedHUC8);
    }    
  }, [global.clickedHUC8]);   

  useEffect(() => {
    if(global.clickedHUC12 && global.clickedHUC12.length === 12 && global.clickedHUC12!==huc12Selection && huc12list.indexOf(global.clickedHUC12)>-1){
      handleHuc12Option(global.clickedHUC12);
    }    
  }, [global.clickedHUC12]);   

  const filterData = () => { 
    //console.log(reachList)
       let filterData = [...reachList];
       if(riskMenu ===2){filterData = filterData.filter((o: any) => (o["warning"] === 0 || o["warning"] === 1 ));}
       if(riskMenu ===1){filterData = filterData.filter((o: any) => (o["warning"] === 2 ));}
       if(riskMenu ===0){filterData = filterData.filter((o: any) => (o["warning"] === 3 || o["warning"] === 4 ));}    
       setFilteredData(filterData);
  }

  useEffect(() => {    
    filterData();
  }, [reachList, riskMenu])

  useEffect(() => {
    huc12info(huc12list)

    //updateLayerStyleHUC
  }, [ huc12list ]);

  // useEffect(() => {
  //   updateLayerStyleHUC();
  // }, [ huc12Selection ]);

  useEffect(() => {
    setUpdateActive(updateActive+1)
  }, [catchmentsData]);

  useEffect(() => {  
   
    if(sliderSeeds && sliderSeeds.length === 4){
      setSliderWinter(sliderSeeds[0]);
      setSliderSpring(sliderSeeds[1]);
      setSliderSummer(sliderSeeds[2]);
      setSliderFall(sliderSeeds[3]);
    }
  }, [ sliderSeeds ]);

  useEffect(() => {
    if(streamFields){
      setTimeout(() => {                                                      
        localStorage.setItem('GD_streamfieldlist', JSON.stringify(streamFields))    
      }, 3333);  
    }
  }, [streamFields]);

  useEffect(() => { // Update Stream map
    //console.log(streamBank)
    if(streamBank){
      let sbFeatures = updateStreamBank(streamBank);      
      //@ts-ignore
      if(sbFeatures && sbFeatures!==[]){
          //@ts-ignore
          setStreamBankFeatures(sbFeatures||[]);
          fitBoundsMapData(sbFeatures , mapRef);
      }
      updateStore();
    }
  }, [streamBank]);  


  useEffect(() => {   
    //huc8test();
    if(selectedHUC8Value !== currentHuc8){
       setCurrentHuc8(selectedHUC8Value)
       setHuc8(selectedHUC8Value)  
       huc8info(selectedHUC8Value.substr(0,8));       
    }
  }, [selectedHUC8Value]);

  const handleHucChange = (event: any) => {    
    if(event.target && event.target.value){
      console.log(event.target.value)
      let huc = "" + event.target.value;
      setHuc8(huc);
      huc8info(huc.substr(0,8));

      if(huc.length>7 && !isNaN(event.target.value)){
     
        setCurrentHuc8(huc.substr(0,8)); 
      }    
    }
  }

  const handleLocationSubmit = (event: any) => {  
    if( hucListNoCoverage.indexOf(currentHuc8) > -1){
      notify(`No Stream Bank model coverage for HUC: ${currentHuc8}`)
      return null;
    }
    handleLocation();

/*    localForage.getItem('GD_charts_'+ currentHuc8, function(err, value) {
      if(value){    
        let cachedData = value as Array<any>;
        if(cachedData.length === 21){
          loadHUCData(cachedData);
        } else {
          handleLocation();
        }
      } else {
        handleLocation();
      }
    })*/
  }

  const handleLocation = () => { 
    let nclick = n_clicks + 1;
    setN_clicks(nclick);  
    //set
    setDataModelScenario(emptyData);
    setDataFall(emptyData);
    setDataModel(emptyData);
    setDataFlow(emptyData);

    setIsNotInProgress(false);
    resetIndicators();
    setProgress(0);

    getLocation(nclick, 1, queryProps, update); 
  }



  const loadHUCData = (cachedData: any) => {
    console.log('loadHUCData');

              
    setHuc12Selection(cachedData[9]);
    setHuc12list(cachedData[10]);

    setModelYearPrecipitation(cachedData[11]); 
    setScenarioYearPrecipitation(cachedData[12]); 

    setDataModelScenario(cachedData[13]); 
    setDataScenario(cachedData[14]); 
    setDataModel(cachedData[15]); 
    setDataFlow(cachedData[16]); 
    setDataWinter(cachedData[17]); 
    setDataSpring(cachedData[18]); 
    setDataSummer(cachedData[19]); 
    setDataFall(cachedData[20]); 
    setIsDataReportLoaded(true);
    setIsSeasonLoaded(true);

    handleChange('climate-slider.value', sliderClimate)

/*
     setTimeout(() => {                                                      
        localForage.getItem('GD_current', function(err, value) {
            if(value){
                let cachedData = value as Array<any>;
                if(cachedData.length === 18){
                  setGraphResponse(cachedData[11]);
                  setJobResponse(cachedData[12]);                     
                  setModelPCRPdf(cachedData[13]);
                  setPrcp2020df(cachedData[14]); 
                  setStreamBank(cachedData[15]); 
                  setIsReachLoaded(true);
                }
            } 
        });
      }, 3333);  */
  }

/* Test
   const [huc8l, setHuc8l] = useState<any[]>();
   const [hucCounter, setHucCounter] = useState<number>(0);

  useEffect(() => {
    console.log(currentHuc8) 
    //getLocation(n_clicks, 1, queryProps, update);
  }, [currentHuc8]);

  useEffect(() => {
    console.log('graphResponse', currentHuc8) 
    //getLocation(n_clicks, 1, queryProps, update);
  }, [graphResponse]);

  useEffect(() => {
    console.log('streamBank', currentHuc8) 
    if(huc8l && huc8l[hucCounter]){
        //setCurrentHuc8(huc8l[hucCounter].huc8)
    }
    

    if(hucCounter < 5){
      setHucCounter(hucCounter +1);
    }
    //getLocation(n_clicks, 1, queryProps, update);
  }, [streamBank])*/

  const huc8test = () => {    
      let fileURL = "huc8s_info.csv"       
      fetch(fileURL)
          .then((response) => response.text())
          .then((responseText) => {
              const hucCatalog: any = parse(responseText, {
                  header: true,
              }).data;
             // setHuc8l(hucCatalog);
              //setHucCounter(0);
             hucCatalog.forEach((dataItem:any, index:number) => {
                  if(index===0){
                     // console.log(dataItem.huc8);
                      //setHucCounter(1)
                       setCurrentHuc8(dataItem.huc8)        
                  }
              })
          })
  }
  
  useEffect(() => {
     if(retryCounter){  
       let gdStore = localStorage.getItem('GD_retry');
        if(gdStore){
            let gdResults = JSON.parse(gdStore);
            gdResults.average = ((gdResults.average * gdResults.counts) + retryCounter)/(gdResults.counts + 1);
            gdResults.counts = gdResults.counts + 1;
            localStorage.setItem('GD_retry', JSON.stringify({average: gdResults.average, counts: gdResults.counts}))
            if(gdResults.average){
                setRetryAverage(gdResults.average);
            }            
        }
        setRetryCounter(0)
     } else {
        let gdStore = localStorage.getItem('GD_retry');
        if(gdStore){
          let gdResults = JSON.parse(gdStore);
          if(gdResults.average){
              setRetryAverage(gdResults.average);
          }    
        } else {
          localStorage.setItem('GD_retry', JSON.stringify({average: retryAverage, counts: 1})) 
        }           
     }
  }, [retryCounter]);

  useEffect(() => {
    setChartConfigModelScenario('Model Year vs Scenario Year', '', setConfigModelScenario, 0, 1.5);
    setChartConfigModelScenario('Model Year 30 vs 90 day Moving Average', '', setConfigModel, 0, .5);
    setChartConfigModelScenario('Scenario 30 vs 90 day Moving Average', '', setConfigScenario, 0, .5);
    setChartConfigPeak(setConfigSpring, 'Spring', 0, 1800);
    setChartConfigPeak(setConfigSummer, 'Summer', 0, 1800);
    setChartConfigPeak(setConfigFall, 'Fall', 0, 1800);
    setChartConfigPeak(setConfigWinter, 'Winter', 0, 1800);
    setChartConfigFlow(setConfigFlow, 'Flow Duration Curve');
  }, []);  




  const loadLastData = () => {
    console.log('loadLastData');
    localForage.getItem('GD_charts', function(err, value) {
     
        if(value){          
            let cachedData = value as Array<any>;
           
            if(cachedData.length === 21){
              //setIsDataReportLoaded(false);
              setIsReachLoaded(false);
              //setIsSeasonLoaded(false);
              setIsRechargeLoaded(false);
              setIsLoadLastDataAvailable(true);
              setCurrentHuc8(cachedData[0]);
              setHuc8(cachedData[0]); //+
              setFutureYear(cachedData[1]);
              setRainSelection(cachedData[2]);
              setRainSelectionYear(cachedData[3]);
              setSliderClimate(cachedData[4]);
              setSliderWinter(cachedData[5]);
              setSliderSpring(cachedData[6]);
              setSliderSummer(cachedData[7]);
              setSliderFall(cachedData[8]); 

              setHuc12Selection(cachedData[9]);
              setHuc12list(cachedData[10]);

              setModelYearPrecipitation(cachedData[11]); 
              setScenarioYearPrecipitation(cachedData[12]); 

              setDataModelScenario(cachedData[13]); 
              setDataScenario(cachedData[14]); 
              setDataModel(cachedData[15]); 
              setDataFlow(cachedData[16]); 
              setDataWinter(cachedData[17]); 
              setDataSpring(cachedData[18]); 
              setDataSummer(cachedData[19]); 
              setDataFall(cachedData[20]); 
              setIsDataReportLoaded(true);
              setIsSeasonLoaded(true);

              //recharge
            }
        } 
    });

     setTimeout(() => {                                                      
        localForage.getItem('GD_current', function(err, value) {
            if(value){
                let cachedData = value as Array<any>;
                if(cachedData.length === 18){
                  setGraphResponse(cachedData[11]);
                  setJobResponse(cachedData[12]);                     
                  setModelPCRPdf(cachedData[13]);
                  setPrcp2020df(cachedData[14]); 
                  setStreamBank(cachedData[15]); 
                  setIsReachLoaded(true);

                  setIsRechargeLoaded(true);//+++
                }
            } 
        });
      }, 3333);  
  }

  useEffect(() => {
    const huc12FileURL = '/stream/stream_variable_info.csv';
    fetch(huc12FileURL)
      .then((response) => response.text())
      .then((responseText) => {
        const data: any= parse(responseText, {
          header: true,
          skipEmptyLines: true,
        }).data;
        setStreamCat(data);
      });
  }, []);

  const huc8info = (huc8: any) => {   
        let fileURL = "huc8s_info.csv"
        //@ts-ignore
        const data: any[] = parse(fileURL, {
            download: true,
            header: true,
            skipEmptyLines: true,
            step: function(results, parser) {
                if(results && results.data){
                    //@ts-ignore
                   if(results.data.huc8 === huc8){
                     //@ts-ignore                  
                     sethuc8name(results.data.name);
                     parser.abort(); 
                    }
                }
            },
            complete: function(results) {                
            }
        });
  }

  const huc12info = (huc12: any) => {
      let fileURL = "huc12_info.csv"
      let nArray = [] as any;
      let ii=0;
      if(!huc12 || huc12.length === 0 || huc12[0] === ''){
        return;
      }
      for (let dataItem of huc12) {
        let huc12row = dataItem;
        if(huc12row.length===11){
          huc12row = '0' + dataItem;
        }
        let localItem =  localStorage.getItem("huc12info_"+huc12row);
        if(localItem){
          let parseLocalItem = JSON.parse(localItem);
          nArray.push({value: huc12row, name:parseLocalItem.name}) 
          ii = ii+1;
        }
      }

      if(ii===huc12.length){                     
        setHuc12listName(nArray);   
        //console.log('cached')                   
      } else {
        //console.time('huc12info_')
        ii=0;
        //@ts-ignore
        const data: any[] = parse(fileURL, {
            download: true,
            header: true,
            skipEmptyLines: true,
            step: function(results, parser) {//@ts-ignore
                if(results && results.data && results.data.huc12){//@ts-ignore
                  let huc12row = results.data.huc12;
                  if(huc12row.length===11){
                    huc12row = '0' + huc12row;
                  }
                  if(huc12.indexOf(huc12row) >-1){
                    ii = ii+1;  //@ts-ignore
                    nArray.push({value: huc12row, name:results.data.name}) 
                    localStorage.setItem("huc12info_"+huc12row, JSON.stringify(results.data));
                    if(ii===huc12.length){                     
                      setHuc12listName(nArray);
                      //console.log('parser.abort()')
                      parser.abort();                      
                    }
                  } 
                }
            },
            complete: function(results) {
              //console.timeEnd('huc12info')
              setHuc12listName(nArray)             
            }
        });        
      }
  }

  const handleLoadCheckbox = (event: any) => {
    setIsLoadLastData(event);
    localStorage.setItem('GD_LoadLast', JSON.stringify(event));
    if(event===true && !graphResponse){
      loadLastData();
    }
  }

  const handleLoadLastData = (event: any) => {
    loadLastData();
  }

  const handleMainPanelSelection = (event: any) => {
    setMainPanelSelection(event);
    console.log(event, mapRef)
    
    setTimeout(() => { 
      if (mapRef && mapRef.current) {
       mapRef.current.resize();
      }
    }, 1555);
   
  }

  const handlePrintData = (event: any) => {
    //@ts-ignore
    //let flow = document.getElementById('GD-line-flow');  flow.style["display"] = "block";//@ts-ignore
    //flow.style["z-index"] = 0;//@ts-ignore
    //document.getElementById('GD-line-chart-season-container').style["display"] = "block".style["z-index"] = 0;
    let navStart = navMenu;
    setNavMenu(10)
    postPrint('light');

    setTimeout(() => { 
      try {
          GroundwaterReport(queryProps);
          setNavMenu(navStart)
          postPrint(null);
          
          //@ts-ignore
          //document.getElementById('GD-line-flow').style["display"] = "none".style["z-index"] = 'unset';
          //@ts-ignore
           // document.getElementById('GD-line-chart-season-container').style["display"] = "none".style["z-index"] ='unset';

      } catch (error: any) {
          console.log(error);
          setNavMenu(navStart)
          postPrint(null);
      }

    }, 1555);

  }

  const postPrint = (chartTheme: any) => {
      setChartConfigFlow(setConfigFlow, 'Flow Duration Curve', chartTheme);
      setChartConfigPeak(setConfigSpring, 'Spring', 0, 1800, chartTheme);
      setChartConfigPeak(setConfigSummer, 'Summer', 0, 1800, chartTheme);
      setChartConfigPeak(setConfigFall, 'Fall', 0, 1800, chartTheme);
      setChartConfigPeak(setConfigWinter, 'Winter', 0, 1800, chartTheme);
      setChartConfigModelScenario('Model Year vs Scenario Year', '', setConfigModelScenario, 0, 1.5, chartTheme);
      setChartConfigModelScenario('Model Year 30 vs 90 day Moving Average', '', setConfigModel, 0, .5, chartTheme);
      setChartConfigModelScenario('Scenario 30 vs 90 day Moving Average', '', setConfigScenario, 0, .5, chartTheme);
  }

  const onPDF = (data: any) => {
    
  }

  const handleSliderOpacityChange = (event: any) => {
      setTimeout(() => {       
        if(mapRef && mapRef.current){
            mapRef.current.getMap().setPaintProperty('filllayer1', "fill-opacity", event*.01) 
        }
      }, 1);  
      setTimeout(() => {       
        setSliderOpacity(event)
      }, 2222);    
  }

  const handleSliderChange = (event: any) => {  
    if(sliderClimate!==event){
      setSliderClimate(event);
      handleChange('climate-slider.value', event);    
    }
  }

  const handleSliderChangeWinter = (event: any) => {
    setSliderWinter(event);
    handleChange("winter-slider.value", event);
  }

  const handleSliderChangeSpring = (event: any) => {
    setSliderSpring(event);
    handleChange("spring-slider.value", event);
  }

  const handleSliderChangeSummer = (event: any) => {
    setSliderSummer(event);
    handleChange("summer-slider.value", event);
  }

  const handleSliderChangeFall = (event: any) => {
    setSliderFall(event);
    handleChange("fall-slider.value", event);
  }

  const handleFutureYear = (event: any) => {
    setFutureYear(event) 
    handleChange("future-year-selector.value", event);
  }

  const handleSpecificYear = (event: any) => {
    setRainSelectionYear(event);
    handleChange("final-rain-selection.dataYEAR", event);
  }

  const handleModelRainChange = (event: any,) => {
    if (event && event.target.id){  
        setRainSelection(event.target.labels[0].innerText);
        handleChange("final-rain-selection.data", event.target.labels[0].innerText);
    }  
  }

  const handleCharacteristicOption = (event: any) => {  
    setCurrentCharacteristic(event);
    const catc = characteristicsLocal.find((ca: any) => ca.characteristic.characteristic_id === event)
    let maplegendtext = <p></p>;
    if(catc && catc.characteristic && catc.characteristic.characteristic_description){
      maplegendtext = <div style={{marginTop: 20, marginBottom: 20}}>
                          <div style={{}}>
                            <p>{catc.characteristic.characteristic_description}</p> 
                          </div>                        
                        </div>;
    } 
    setMapLegendAdd(maplegendtext)

    if(catc && catc.characteristic){
      setCurrentCharacteristicInfo(catc.characteristic);
    } else {
      setCurrentCharacteristicInfo(null);
    }

    createMapStops(catchmentsData, event);
  }

  const handleColorOption = (event: any) => {  
    setCurrentColorMap(event);
    createMapStops(catchmentsData, currentCharacteristic, event);
    localStorage.setItem('GD_CurrentColorMap', event);
  }

  const handleColorOptionReverse = (event: any) => {  
    setCurrentColorMapReverse(event);
    createMapStops(catchmentsData, currentCharacteristic, null, event);
    localStorage.setItem('GD_CurrentColorMapReverse', event);
  }

  const handleHuc12Option = (event: any) => {  
    setHuc12Selection(event);
    setqsgdh12(event);
    setIsReachLoaded(false);

    const featureCollectionEmpty: FeatureCollection = {
      type: "FeatureCollection",
      features: []
    };     
    setCatchmentsData(featureCollectionEmpty);
    getReach(graphResponse, queryProps, jobResponse, event, null, null, 0);

  }


  const resetIndicators = () => {
      setIsDataReportLoaded(false);
      setIsReachLoaded(false);
      setIsRechargeLoaded(false);
      setNhldData(null);
      setRechargeValues(null)
      setIsSeasonLoaded(false);
  }


  const handleChange = (changedPropIds?:any, changedValue?:any) => {
   
    if(!jobResponse || !modelPCRPdf ){
      return;
    }
    //prcp2020df.data

    let sliderCurrent = [(changedPropIds === 'winter-slider.value') ? changedValue : sliderWinter, (changedPropIds === 'spring-slider.value') ? changedValue : sliderSpring, (changedPropIds === 'summer-slider.value') ? changedValue : sliderSummer, (changedPropIds === 'fall-slider.value') ? changedValue : sliderFall];

    if(changedPropIds === 'climate-slider.value'){
      resetIndicators();
      getScenarioPCRP(sliderCurrent, modelYearPrecipitation, modelPCRPdf.data, null, jobResponse["representative-huc"].data, queryProps, update, jobResponse, [changedPropIds], changedValue )      
    }

    let seasonSliders = ['winter-slider.value', 'spring-slider.value', 'summer-slider.value', 'fall-slider.value']
    if(seasonSliders.indexOf(changedPropIds) > -1){
      resetIndicators();
      getGraphs(sliderCurrent, modelPCRPdf.data, null, prcpdiff.data, jobResponse["representative-huc"].data, queryProps, update, jobResponse, [changedPropIds], changedValue)
      //
    }

    if(changedPropIds === 'future-year-selector.value'){
      resetIndicators();
      getScenarioPCRP(sliderCurrent, modelYearPrecipitation, modelPCRPdf.data, null, jobResponse["representative-huc"].data, queryProps, update, jobResponse, [changedPropIds], changedValue )
       
    }

    //Random
    if(changedPropIds === 'final-rain-selection.data'){
      let respValue = changedValue;
      if(changedValue === 'Specific Year'){
          respValue = +rainSelectionYear;
      }

      resetIndicators();
      getModelPCRP(jobResponse["location-df"].data, jobResponse["representative-huc"].data, queryProps, update, jobResponse, ['final-rain-selection.data'], respValue  ) 
    }

    if(changedPropIds === 'final-rain-selection.dataYEAR'){
      let respValue = +changedValue;
      resetIndicators();
      getModelPCRP(jobResponse["location-df"].data, jobResponse["representative-huc"].data, queryProps, update, jobResponse, ['final-rain-selection.data'], respValue  ) 
    }
  }

  const update = (response: any) => { 
    if(response["whole-year-graph"]){
      let dataUpdate = response["whole-year-graph"].figure.data;
      setDataModelScenario(updateModelChartData(dataUpdate));
    }
    if(response["scenario-ma-graph"]){
      let dataUpdate = response["scenario-ma-graph"].figure.data;
      setDataScenario(updateModelChartData(dataUpdate));
    }
    if(response["model-ma-graph"]){
      let dataUpdate = response["model-ma-graph"].figure.data;
      setDataModel(updateModelChartData(dataUpdate));
    }
    if(response["winter-graph"]){
      let dataUpdate = response["winter-graph"].figure.data;
      setDataWinter(updatePeakChartData( dataUpdate, 'Day of Year', 'prcp'))
    }
    if(response["spring-graph"]){
      let dataUpdate = response["spring-graph"].figure.data;
      setDataSpring(updatePeakChartData( dataUpdate, 'Day of Year', 'prcp'));
    }
    if(response["summer-graph"]){
      let dataUpdate = response["summer-graph"].figure.data;
      setDataSummer(updatePeakChartData( dataUpdate, 'Day of Year', 'prcp'))
    }
    if(response["fall-graph"]){
      let dataUpdate = response["fall-graph"].figure.data;
      setDataFall(updatePeakChartData( dataUpdate, 'Day of Year', 'prcp'));
    }
    if(response["flow-regime-graph"]){
      let dataUpdate = response["flow-regime-graph"].figure.data;
      let flowUpdate = response["flow-regime-graph"].figure.layout;
      setDataFlow(updateFlowChartData( dataUpdate, 'Day of Year', 'prcp', flowUpdate));
    }
    setIsDataReportLoaded(true);
    setIsNotInProgress(true)
    setIsSeasonLoaded(true);
  }

  const updateStreamBank = (filterData: any) => {
    console.log('updateStreamBank', filterData.length);
   
    if (!filterData || filterData.length < 1) {
      return [];
    }    

    let fData = [...filterData];
    const features: Array<Feature<LineString, GeoJsonProperties>> = [];

    let newReachList = [];
    let newReachRisk = [0,0,0];

    let data_line: any[] = [];
    for (let dataItem of fData) {
      let lineset = [];
      let coords = [];
      for (var i = 0; i < dataItem.lat.length; i++) {
         coords.push([dataItem.lon[i], dataItem.lat[i]]);
      }
      let colorNew = dataItem.line.color;
       if(dataItem.line.color === "darkgreen"){
         colorNew = "#00f0d0"
       }
       if(dataItem.line.color === "darkseagreen"){
         colorNew = "#afff24"
       }
      // if(dataItem.line.color === "orange"){
      //   colorNew = "#ffa571"
      // }
      // 8549669

      let Warning = +dataItem.hovertext.split('\n')[1].replace('Warning Level ', '');
      let comid = dataItem.hovertext.split('\n')[0].replace('COMID:', '').replace('.0', '').trim();
      newReachList.push({cbnf: 1, color: colorNew, comid: comid, warning: Warning, warninglevel: dataItem.hovertext.split('\n')[1] });

      if(Warning < 2){
        newReachRisk[2] = newReachRisk[2] + 1;
      } else if (Warning===2){
        newReachRisk[1] = newReachRisk[1] + 1;
      } else if (Warning > 2){
        newReachRisk[0] = newReachRisk[0] + 1;
      }

      const featureWithLineString: Feature<LineString> = {
        type: "Feature",
        geometry: {
            type: "LineString",
            coordinates: coords,
          },
        properties: {'comid': comid, 'hovertext' : dataItem.hovertext.split('\n')[0], 'hovertext2' : dataItem.hovertext.split('\n')[1], color: colorNew, warning: Warning},
      };
      features.push(featureWithLineString);
    }

    const featureCollectionFromReportData: FeatureCollection = {
      type: "FeatureCollection",
      features: features
    }; 

    const featureCollectionEmpty: FeatureCollection = {
      type: "FeatureCollection",
      features: []
    }; 
    setBasinData(featureCollectionEmpty)    

    setReachList(newReachList);
    let StreamTotal = newReachRisk[0] + newReachRisk[1] + newReachRisk[2];
    setStreamRisk( [Math.round((newReachRisk[0]/StreamTotal)*100),Math.round((newReachRisk[1]/StreamTotal)*100), Math.round((newReachRisk[2]/StreamTotal)*100)] );

    //updateComids(newReachList, newReachList);
    updateBasinCatchments(newReachList, featureCollectionFromReportData);

    return featureCollectionFromReportData;
  }

  const fitBoundsMapData = (featureCollection:any, mapRef:any) => { 
    if(featureCollection.features && featureCollection.features.length > 0){
      const bbox_ = bbox(featureCollection);
      if(mapRef && mapRef.current){
        if(bbox_[0] === Infinity){
          return null;
        }
        mapRef.current.fitBounds([[bbox_[0], bbox_[1]], [bbox_[2], bbox_[3]]], {
          padding: {top: 40, bottom:25, left: 45, right: 45}
        })
      }
    }
  }  

  const renderYearOptions = () => {
    let wrfcValueList = [];
    for (var i = 1991; i < 2021; i++) {
         wrfcValueList.push('' + i);
    }

    return wrfcValueList.map((element: any, index:any) => {
        if(element === rainSelectionYear) {
          return (
            <option className="GD-p" key={uuidv4()}  value={element}>
              {element}
            </option>
          )
        }

        return (
            <option className="GD-p" key={uuidv4()} value={element}>
              {element}
            </option>
        )
    })
  }

  const renderFutureYearOptions = () => {
    let wrfcValueList = [];
    for (var i = 2024; i < 2101; i++) {
         wrfcValueList.push('' + i);
    }

    return wrfcValueList.map((element: any, index:any) => {
      if(index === futureYear) {
        return (
          <option className="GD-p" key={uuidv4()}>
            {element}
          </option>
        )
      }

      return (
        <option className="GD-p" key={uuidv4()}>
          {element}
        </option>
      )
    })
  }


  const renderHuc12Options = () => {
    let wrfcValueList = huc12listName.sort((x: any, y: any) => (x.value < y.value ? -1 : 1));

    return wrfcValueList.map((element: any, index:any) => {
      if(index === futureYear) {
        return (
          <option className="GD-p" key={uuidv4()} value={element.value}>
            {element.value} {element.name} 
          </option>
        )
      }

      return (
        <option   className="GD-p" key={uuidv4()} value={element.value}>
          {element.value} {element.name} 
        </option>
      )
    })
  }

  const renderCatchmentOptions = () => {

    return catchmentOptions.map((element: any, index:any) => {
      return (
        <option className="GD-p" key={uuidv4()} value={element.value}>
          {element.label}
        </option>
      )
    })
  }

/*  const renderCatchmentOptionsDropdown = () => {

    return catchmentOptions.map((element: any, index:any) => {
      return (
        <Dropdown.Item className="GD-p" key={uuidv4()} value={element.value}>
          {element.label}
        </Dropdown.Item>
      )
    })
  }*/

  const renderColorOptions = () => {
    return colorChromatic.map((element: any, index:any) => {
      return (
        <option className="GD-p" key={uuidv4()} value={element}>
          {element}
        </option>
      )
    })
  }

  const updateFlowChartData = (filterData: any, groupKey: string, selCategory: string, layout?: any) => {
    if (!filterData || filterData.length < 1) {
      return {
        labels: [],
        datasets: [],
        borderColor: '#ff6384'
      };
    }

    let fData = [...filterData];
    let shapeStops = [30, 200, 2200, 3200];
    if(layout && layout.shapes){
     if(layout.shapes[0] && layout.shapes[0].y1){
         shapeStops = [layout.shapes[0].y0, layout.shapes[0].y1, layout.shapes[1].y1, layout.shapes[2].y1]
     }
    }

    const chartdata_data: any[] = [];
    let chartdata_labels: any[] = [];
    let dayRun = [];
    let ii = 0;
    for (let dataItem of fData) {
      let ValueList = [];
      for (var i = 0; i < dataItem.x.length; i++) {
         ValueList.push({"x" : dataItem.x[i], "y" : dataItem.y[i]}); 
      }
    
      let lineRun = dataItem.y;
      dayRun = dataItem.x;
   
      if(dataItem.line.color=== 'orange'){
        dataItem.line.color = '#e67e00';  
      }

      const colorMatch = d3ScaleChromatic.schemePaired[0];
        let labelGroup = dataItem.name;
        let colorLine = dataItem.line.color; 
        let dataObject = {
          type: 'line' as const,
          label: labelGroup + ' Year',
          data: ValueList, //lineRun,
          borderColor: colorLine,
          borderWidth: ii+2,
          pointRadius: 2,
          tension: .2,
          hoverBorderWidth: 10,
          pointHoverBorderWidth: 10,
          hitRadius: 10,
          z: 3
        }
        chartdata_data.push(dataObject);
        chartdata_labels.push(dataItem.name);
        ii=ii+5;
    };

    let bar0 = {
      type: 'line' as const,
      label: 'Below 85th',
      backgroundColor: 'rgba(229, 236, 246, .9)',
      data: [[0, shapeStops[0]], [1, shapeStops[0]]],
      pointRadius: 0,
      fill: true,
      plugins: {
        legend: {
          position: "bottom" as const,
          labels: {
            usePointStyle: false,
            pointStyle: "rect",
            color: 'blue'
          },
        } as const,
      }
    }
    chartdata_data.push(bar0);

    let bar1 = {
      type: 'line' as const,
      label: 'Between 85th-95th percentile',
      backgroundColor: 'rgba(255, 255, 204, .9)',
      data: [[0, shapeStops[1]], [1, shapeStops[1]]],
      pointRadius: 0,

      fill: true,
      plugins: {
        legend: {
          position: "bottom" as const,
          labels: {
            usePointStyle: false,
            pointStyle: "rect",
            color: 'blue'
          },
        } as const,
      }
    }
    chartdata_data.push(bar1);

    let bar2 = {
      type: 'line' as const,
      label: 'Between 95th-100th percentile',
      backgroundColor: 'rgba(255,188,102, 1)',
      data: [[0, shapeStops[2]], [1, shapeStops[2]]],
      fill: true,
      pointRadius: 0,
    }
    chartdata_data.push(bar2);

    let bar3 = {
      type: 'line' as const,
      label: 'In Scenario Year only',
      backgroundColor: 'rgba(248,150,141, 1)',
      data: [[0, shapeStops[3]], [1, shapeStops[3]]],
      pointRadius: 0,
      fill: true,
    
    }
    chartdata_data.push(bar3);

    const chartdata = {
      labels: dayRun,
      datasets: chartdata_data,
      borderColor: '#ff6384',
    }
   
    return chartdata;
  }

  const updateModelChartData = (filterData: any) => {
    if (!filterData || filterData.length < 1) {
      return {
        labels: [],
        datasets: [],
        borderColor: '#ff6384'
      };
    }

    const chartdata_data: any[] = [];
    let chartdata_labels: any[] = [];
    let dayRun = [];
    for (let dataItem of filterData) {
      let lineRun = dataItem.y;
      dayRun = dataItem.x;
      const colorMatch = d3ScaleChromatic.schemePaired[0];
        let labelGroup = dataItem.name;
        let colorLine = dataItem.line.color; 
        let dataObject = {
          label: labelGroup,
          data: lineRun,
          borderColor: colorLine,
          borderWidth: 3,
          pointRadius: 0,
          tension: .3,
          hoverBorderWidth: 10,
          pointHoverBorderWidth: 10,
          hitRadius: 10,
        }
        chartdata_data.push(dataObject);
        chartdata_labels.push(dataItem.name);
    };

    const chartdata = {
      labels: dayRun,
      datasets: chartdata_data,
      borderColor: '#ff6384'
    }
  
    return chartdata;
  }

  const updateModelScenarioChartData = (filterData: any, groupKey: string, selCategory: string) => {
    if (!filterData || filterData.length < 1) {
      return {
        labels: [],
        datasets: [],
        borderColor: '#ff6384'
      };  
    }

    let fDataset = [...filterData.data];
    const sortField = 'intervalint';
    const keyField = 'month';
    const dataField = 'year';

    const chartdata_data: any[] = [];
    let chartdata_labels: any[] = [];
    const site_data: any[] = [];
    const newdataset: any[] = [];

    let lineRun = [];
    let dayRun = [];
    for (let dataItem of fDataset) {
        lineRun.push(dataItem[2])
        dayRun.push(+dataItem[4])
    };

    const colorMatch = d3ScaleChromatic.schemePaired[0];
    let labelGroup = 'Model Year Precipitation';
    let colorLine = colorMatch;
    let dataObject = {
      label: labelGroup,
      data: lineRun,
        borderColor: colorGold,
      backgroundColor: colorLine,
      borderWidth: 1,
      pointRadius: 0,
      tension: .3,
      hoverBorderWidth: 10,
      pointHoverBorderWidth: 20,
      hitRadius: 10,
    }
    chartdata_data.push(dataObject);
    chartdata_labels.push('');
  
    const chartdata = {
      labels: dayRun,
      datasets: chartdata_data,
      borderColor: '#ff6384'
    }

    return chartdata;
  }

  const updatePeakChartData = (filterData: any, groupKey: string, selCategory: string) => {
    if (!filterData || filterData.length < 1) {
      return {
        labels: [],
        datasets: [],
        borderColor: '#ff6384'
      };
    }

    const chartdata_data: any[] = [];
    let chartdata_labels: any[] = [];
    let dayRun = dayRunHour;

    for (let dataItem of filterData) {
      let lineRun = dataItem.y;
      dayRun = dataItem.x;
      const colorMatch = d3ScaleChromatic.schemePaired[0];
        let labelGroup = dataItem.name;
        let colorLine = (dataItem.name === "Model Peak Runoff") ? colorGold : colorBlue;

        let dataObject = {
          label: labelGroup,
          data: lineRun,
          borderColor: colorLine,
          borderWidth: 3,
          pointRadius: 0,
          tension: .3,
          hoverBorderWidth: 10,
          pointHoverBorderWidth: 20,
          hitRadius: 20,
          }
        chartdata_data.push(dataObject);
        chartdata_labels.push(dataItem.name);
    };

    const chartdata = {
      labels: dayRun,
      datasets: chartdata_data,
      borderColor: '#ff6384'
    }

    return chartdata;
  }

  const popover = (
    <Popover id="GroundwaterDetermination-categories-popover" className="GD-categories-popover" key={"GroundwaterDetermination-categories-popover-"}>
      <Popover.Header as="h3">Shared Socioeconomic Pathways</Popover.Header>
      <Popover.Body  className="GD-categories-popover-body" >
      <p>The SSPs comprise emission narratives and a set of driving forces, in order to facilitate the integrated analysis of future climate impacts, vulnerabilities, adaptation, and mitigation..</p>
        <p>In contrast to RCP scenarios, the new SSP-based scenarios provide economic and social reasons for the assumed emission pathways and changes in land use. Moreover, updated historic emissions of greenhouse gases and aerosols as well as land use changes were incorporated, and improved Integrated Assessment Modellings were used.</p>

        <p><span style={{fontWeight: 'bold'}}>SSP245</span>: This scenario assumes that climate protection measures are being taken. Represents the medium pathway of future greenhouse gas emissions.  As an update to scenario RCP4.5, SSP245 with an additional radiative forcing of 4.5 W/m² by the year 2100. 
        </p><p><span style={{fontWeight: 'bold'}}>SSP370</span>: The upper-middle part of the full range of scenarios, it was newly introduced after the RCP scenarios, closing the gap between RCP6.0 and RCP8.5.
        </p><p><span style={{fontWeight: 'bold'}}>SSP585</span>: This scenario represents the upper boundary of the range of scenarios described in the literature: a ‘high emissions’ scenario.  It can be understood as an update of the CMIP5 scenario RCP8.5, now combined with socioeconomic reasons.
        </p>
        <p></p>
      </Popover.Body>
    </Popover>
  );
/*
  const popover = (
    <Popover id="GroundwaterDetermination-categories-popover" className="GD-categories-popover" key={"GroundwaterDetermination-categories-popover-"}>
      <Popover.Header as="h3">Representative Concentration Pathway</Popover.Header>
      <Popover.Body  className="GD-categories-popover-body" >
        <p>The RCP slider impacts only the scenario year, and adjusts how much precipitation impact will occur, depending on the level of climate change that is predicted.</p>
        <p> A Representative Concentration Pathway (RCP) is a greenhouse gas concentration (not emissions) trajectory adopted by the IPCC. Pathways were used for climate modeling.</p>
        <p>RCP 1.9, is a pathway that limits global warming to below 1.5°C.</p>
        <p>RCP 2.6, is likely to keep global temperature rise below 2°C by 2100.</p>
        <p>RCP 4.5, is described by the IPCC as an intermediate scenario, likely to result in global temperature rise between 2°C and 3°C, by 2100.</p>
        <p>RCP 6, is making the global temperature rise by about 3–4°C by 2100.</p>
        <p>RCP 8.5, generally taken as the basis for worst-case climate change scenarios.</p> 
      </Popover.Body>
    </Popover>
  );
*/


  const popoverFields = (
    <Popover id="GroundwaterDetermination-categories-popover" className="GD-categories-popover" key={"GroundwaterDetermination-categories-popover--"}>
      <Popover.Header as="h3">Rain Year Type</Popover.Header>
      <Popover.Body  className="GD-categories-popover-body">
        <p><span style={{fontWeight: 'bold'}}>Simple</span> takes the 30 years of data for each day of the year and provides the average for each day.</p>
        <p><span style={{fontWeight: 'bold'}}>Random</span> takes a random day from the 30 year period for each day, creating an artificial rain year that behaves the same way as the natural rain.</p>
        <p><span style={{fontWeight: 'bold'}}>Specific Year</span> allows the user to pick any year from the 30 year period and see the actual precipitation.</p>
      </Popover.Body>
    </Popover>
  );

  const popoverFlow= (
    <Popover id="GroundwaterDetermination-categories-popover" className="GD-categories-popover" key={"GroundwaterDetermination-categories-popover--"}>
      <Popover.Header as="h3">Flow Duration Curve</Popover.Header>
      <Popover.Body  className="GD-categories-popover-body">
        <p>The Flow Duration Curve shows what % of the year the area is exposed to certain levels of flow. The blue line is the <span style={{fontWeight: 'bold'}}>model year</span>, and the dark orange line is the <span style={{fontWeight: 'bold'}}>scenario year</span>.  The background color orange means that the flow is between the 95th and 100th percentile of runoff seen during the model year.</p> 
        <p>Anything in the red region means that you're seeing flows in the scenario year that were never seen in the model year</p>
      </Popover.Body>
    </Popover>
  );

  const popoverStream= (
    <Popover id="GroundwaterDetermination-categories-popover" className="GD-categories-popover" key={"GroundwaterDetermination-categories-popover--"}>
      <Popover.Header as="h3">Terms</Popover.Header>
      <Popover.Body  className="GD-categories-popover-body">
        <p><span style={{fontWeight: 'bold'}}>Stream segments</span> - Contiguous section of stream or river between upstream and downstream tributaries (except where segment is a headwater or outlet stream). Stream segments are based on digitized line files of USGS topographic maps (National Hydrography Dataset).</p>
        <p><span style={{fontWeight: 'bold'}}>COMID</span> - Stream segment unique identifier within the NHDPlusV2 framework.</p> 
        <p><span style={{fontWeight: 'bold'}}>Catchment</span> - Portion of landscape that drains directly to a stream segment, excluding any upstream contributions.</p>
        <p><span style={{fontWeight: 'bold'}}>Watershed</span> - Set of hydrologically-connected catchments, including all upstream catchments that flow to any focal catchment.</p>
        <p> </p>
        <p> </p>
        <p> </p>
        <span>
            Source:{' '} 
            <a target='_blank' className='report-footer-source-link' href={'https://www.epa.gov/waterdata/basic-information'}>
                {' '}
                {'NHDPlus (National Hydrography Dataset Plus)'}
            </a>
        </span>
      </Popover.Body>
    </Popover>
  );

  const popoverRecharge = (
    <Popover id="GroundwaterDetermination-categories-popover" className="GD-categories-popover" key={"GroundwaterDetermination-categories-popover--"}>
      <Popover.Header as="h3">Groundwater Recharge</Popover.Header>
      <Popover.Body  className="GD-categories-popover-body">
        <p><span style={{fontWeight: 'bold'}}>Groundwater Recharge</span> - Groundwater recharge refers to the process of water moving downward to replenish the groundwater reservoir, typically originating from precipitation that infiltrates the subsurface and percolates towards the water table. It can be direct, indirect, or focused, with evapotranspiration affecting the amount of water that reaches the groundwater.</p>
        <p>A key indicicator in groundwater resources management for future land use planning. Precautionary works in low recharge potential areas should give priority to long-term planning.</p> 

        <p> </p>
        <p> </p>
        <p> </p>
        <span>
            Source:{' '} 
            <a target='_blank' className='report-footer-source-link' href={'https://www.epa.gov/waterdata/basic-information'}>
                {' '}
                {'NHDPlus (National Hydrography Dataset Plus)'}
            </a>
        </span>
      </Popover.Body>
    </Popover>
  );



  const popoverComidField  = (
    <Popover id="GroundwaterDetermination-streamcat-popover" className="GD-streamcat-popover" key={"GroundwaterDetermination-streamcat-popover--"}>
      <Popover.Header as="h3">StreamCat Metrics</Popover.Header>
      <Popover.Body  className="GD-streamcat-popover-body">

        Areas of Interest or AOIs:
          Variables with Cat appended to names are local catchment-level metrics.

          {streamCat && streamCat.sort(function (a: any, b: any) {
                return a.METRIC_NAME.localeCompare( b.METRIC_NAME);
             }).map((item: any, index:any) => {

            if(item.WEBTOOL_NAME!=='Overview'){
              return (
              <Card key={uuidv4()}>
                <Card.Body>
                  <Card.Title>{item.WEBTOOL_NAME}</Card.Title>
                  <Card.Text>
                    <p>Field Name: {item.METRIC_NAME}</p>
                    <p>Units: {item.METRIC_UNITS}</p>
                    <p>{item.METRIC_DESCRIPTION}</p>
                    <p>Category: {item.INDICATOR_CATEGORY}</p>

                    <p>Data Source: 
                        <a target='_blank' className='report-footer-source-link' href={item.SOURCE_URL}>
                            {' '}
                            {item.SOURCE_NAME}
                        </a>
                     </p>

                   

                  </Card.Text>
                 {/* <Button variant="primary">Toggle</Button>*/}
                </Card.Body>
              </Card>

             )}
            }
          )}       

        <span>
            Source:{' '} 
            <a target='_blank' className='report-footer-source-link' href={'https://www.epa.gov/national-aquatic-resource-surveys/streamcat-metrics-and-definitions'}>
                {' '}
                {'NHDPlus Streamcat metrics'}
            </a>
        </span>
      </Popover.Body>
    </Popover>
  )

  const onClickPopover = (event:any) => {

    let sF = [...streamFields];
    const streamData = sF && event.target.id && sF.find((item: any) => item.title === event.target.id)
    if(streamData){
      streamData.active = !streamData.active;
      setStreamFields(sF);

    }
  }

  const popoverStreamFields = (
    <Popover id="gd-popover" className="GD-stream-popover" key={"gd-categories-popover-" + 11}>
      <Popover.Header as="h3">Select Stream Fields</Popover.Header>
      <Popover.Body className="GD-stream-popover-body">
        <ListGroup onClick={onClickPopover} className="GD-stream-popover-list">
          {streamFields && streamFields.sort(function (a: any, b: any) {
                return a.title - b.title;
             }).map((item: any, index:any) => {
            if(item.title!=='Overview'){
              return (
                  <ListGroup.Item id={item.title} action key={'gd-popover-item'+index} className={item.active===true? 'gd-popover-selected' : 'gd-popover'}>
                    {item.title}
                  </ListGroup.Item>
             )}
            }
          )}                     
        </ListGroup>
      </Popover.Body>
    </Popover>
  );

  const renderPopup = () => {
    return(
      <Popup
          offset={7}
          longitude={Number(popupInfo.centroid_longitude)}
          latitude={Number(popupInfo.centroid_latitude)}
          onClose={() => setPopupInfo(null)}
      >
        <div className=  "popup-container popup-container-GD">
            
           <p>COMID: { popupInfo.comid}</p>
           <p>Warning Level: { popupInfo.warning}</p>
           <p>Coordinates:</p> <p>
           ({popupInfo.centroid_latitude},</p> <p>{popupInfo.centroid_longitude})</p> 
           {popupInfo.recharge && (<p>Recharge value: { popupInfo.recharge}</p>)}
        </div>
      </Popup>
    )
  }

  const updateLayerStyle = () => { 
    const newLayerStyle : LayerProps =  {
      id: 'linelayer',
      type: 'line' as const,
      paint: {
        'line-color': ['get', 'color'],
        'line-width':   2, 
      }
    };
    setLayerStyle(newLayerStyle);
  }

  const updateLayerStyle2 = () => { 
    const newLayerStyle : LayerProps =  {
      id: 'linelayer2',
      type: 'line' as const,
      paint: {
        'line-color': 'rgba(0,0,0,0)',
        'line-width': 15, 
      }
    };  
    setLayerStyle2(newLayerStyle);
  }

  const updateLayerStyle3 = () => { 
    const newLayerStyle : LayerProps =  {
      id: 'linelayer3',
      type: 'line' as const,
      paint: {
        'line-color': 'white',
        'line-width': 5, //@ts-ignore
        'line-blur' : 1,
        'line-gap-width' : 7, 
        'line-opacity' : .5,//@ts-ignore
      }
    };
    setLayerStyle3(newLayerStyle);
  }

  const updateLayerStyleEx = () => { 
    const newLayerStyle : LayerProps =  {
      id: 'linelayer4',
      type: 'line' as const,
      paint: {
        'line-color': 'white',
        'line-width': 2, 
        'line-opacity' : .8,
      }
    };
    setLayerStyleEx(newLayerStyle);
  }

  const updateLayerStyleC = (mapExp?:any) => {
    if(mapExp){
        const newLayerStyle : LayerProps =  {
          id: 'filllayer1',
          type: 'fill' as const,
          beforeId: settings.defaultBeforeId,
          paint: {
            'fill-color':  mapExp, 
            'fill-outline-color' : 'white',
            'fill-opacity' : sliderOpacity*.01,
          }
        };
        setLayerStyleC(newLayerStyle);
    } else {
         const newLayerStyle : LayerProps =  {
          id: 'filllayer1',
          type: 'fill' as const,
          paint: {
            'fill-color':  ["interpolate",
               ["linear"],
               ['get', 'warning'],
               0,
               "hsl(83, 9%, 83%)",
               4,
               "hsl(213, 30%, 57%)"], 
            'fill-outline-color' : 'white',
            'fill-opacity' : .2,
          }
        };
        setLayerStyleC(newLayerStyle);     
    }

  }


/*  const updateLayerStyleEx = () => { 
    const newLayerStyle : LayerProps =  {
      id: 'linelayerEx',
      type: 'fill-extrusion' as const,
      paint: {
        'fill-extrusion-opacity': 0.85,
        'fill-extrusion-color': ['get', 'color'],
        'fill-extrusion-height': ['get', 'selectedfield'], 
      }
    };
    setLayerStyleEx(newLayerStyle);
  }*/


  const updateLayerStyleHUC = () => { 
    const newLayerStyle : LayerProps =  {
      id: 'linelayerHuc',
      "source-layer": 'True Elements - HUC 12s',
      type: 'fill' as const,
      filter: ["in", ['get', 'HUC12'], ["literal", huc12list.filter(x => x !== huc12Selection)]],
      paint: {
        'fill-outline-color': 'white',
        'fill-color' : 'blue',
        'fill-opacity' : .2,
      }
    };
    setLayerStyleHUC(newLayerStyle);
  }

  const onMouseEnter = useCallback((event: any | null) => {  
      const feature = event.features && event.features[0];
      if (feature) {  
        let newInfo = feature.properties;
        newInfo.centroid_longitude = event.lngLat.lng;
        newInfo.centroid_latitude = event.lngLat.lat;
        setPopupInfo(feature.properties);
        setCursor('pointer');  
      }
  }, []);

 const onMouseMove = useCallback((event: any | null) => {
      const feature = event.features && event.features[0];
      if (feature) {  
        let newInfo = feature.properties;
        newInfo.centroid_longitude = event.lngLat.lng;
        newInfo.centroid_latitude = event.lngLat.lat;
        setPopupInfo(feature.properties);
        setCursor('pointer');  
      }
  }, []);

  const onMouseLeave = useCallback((event: any | null) => {
    setCursor('auto');
 
    setPopupInfo(null);
/*    setTimeout(() => {
      setPopupInfo(null);
      setUserSelectedLocation(null)
      setMapHoverFilter(['in', ['get', locationField], '']);
    }
    , 1  1);*/
  }, []);


  const onHandleClick = useCallback((event: any | null) => {
    const feature = event.features && event.features[0];
    if (feature) {      
        if(feature.properties && feature.properties.comid){    
             //console.log(feature.properties.comid, userSelectedLocations, userSelectedLocation)       
            //if(feature.properties.comid !== userSelectedLocation){
              setUserSelectedLocation(feature.properties.comid);
/*            } else {
              setUserSelectedLocation('');
              setComidData(null);
              const featureCollectionEmpty: FeatureCollection = {
                type: "FeatureCollection",
                features: []
              }; 
              setBasinData(featureCollectionEmpty)  
            }*/
      }
/*      if(feature.source && feature.source === "hucsource" && feature.properties && feature.properties.HUC12){
        handleHuc12Option(feature.properties.HUC12);
      }*/
    }
  }, []);

  const renderFullScreenTooltip = (props:any) => {
    let closedFull = !isFullScreenFlowActive && !isFullScreenMapActive && !isFullScreenModelActive && !isFullScreenSeasonActive;
    let tipMessage = (closedFull) ? 'Enter Full Screen' : 'Leave Full Screen';

    return(
    <TooltipBs className="fullscreen-tooltip" {...props} >
      <span>{tipMessage}</span>     
    </TooltipBs>
    )
  }

  let tableColumns = useMemo(
    () => [
      { Header: 'COMID', accessor: 'comid',
        Cell: (props: any) => ( 
            <span style={(userSelectedLocation === 'props.value') ? {textDecoration: `underline`, color: '#4fc2d9'} : {}}>
            {props.value}
          </span>
        ),
      }, {
        Header: 'Warning Level',
        accessor: 'warning',
      }
    ],
    []
  );

  const updateTableColumns = () => {
    tableColumns =  [
          { Header: 'COMID', accessor: 'comid',
            Cell: (props: any) => ( 
                <span style={(userSelectedLocation ===props.value) ? {textDecoration: `underline`, color: '#4fc2d9'} : {}}>
                {props.value}
              </span>
            ),
          }, {
            Header: 'Warning Level',
            accessor: 'warning',
          }
    ];
  }

  let tableColumnsM = useMemo(
    () => [
      { Header: 'COMID', accessor: 'comid',
        Cell: (props: any) => ( 
            <span style={(userSelectedLocation === 'props.value') ? {textDecoration: `underline`, color: '#4fc2d9'} : {}}>
            {props.value}
          </span>
        ),
      }, {
        Header: 'Warning Level',
        accessor: 'warning',

      }, {
        Header: 'WL',
        accessor: 'color',
        Cell: (props: any) => ( 
            <div style={{color: props.value, minWidth: 40, minHeight: 10, backgroundColor: props.value}}>{'     '}</div>
        ),        
      }, {
        Header: 'Recharge Score',
        accessor: 'recharge',
      }, {
        Header: 'Area (sqkm)',
        accessor: 'areasqkm',
      }
    ],
    []
  );
            //areasqkm
            //recharge
            //color
            //CAT_RECHG
/*  useEffect(() => {

      tableColumns = [
      { Header: 'COMID', accessor: 'comid' },
      {
        Header: 'Warning Level',
        accessor: 'warning',
      }]


      if(streamFieldList && streamFieldList.length > 0){
        for (let dataItem of streamFieldList) {
           if(dataItem.title!=='Overview'&&dataItem.active===true){
                tableColumns.push( {
                Header: dataItem.title,
                accessor: dataItem.title,                
              });
            }  
        }  
      }  
      setTableVersion(tableVersion+1);
      
  }, [streamFieldList]);*/



  const updateComids = (streamL:any, newFeatures: any) => {
    let extrScale = d3Scale.scaleLinear()
            .domain([0, 1])
           .range([0, 700]);


      let parameterlist = [];
      if(streamFields && streamFields.length > 0){
        for (let dataItem of streamFields) {
           if(dataItem.active===true){
                parameterlist.push(dataItem.fieldname);
            }  
        }  
      }  
      let parameterCurrent = 'CATAREASQKM';
      let parameters = parameterlist.toString();
   
      if(!parameters || parameters === 't'){
        parameters = 'fert'
      } 
      const propertiesList = streamL.map((feature: any) => feature.comid);
      const propertiesListArr =  Array.from(new Set(propertiesList));


      if(propertiesList && propertiesList.length > 0 && comidCollection && comidCollection.length > 0){
        const comFound = comidCollection.find((com: any) => com.comid === propertiesList[0].comid);
     
        if(comFound){

              let newData = comidCollection;

              const fieldExtent = d3Array.extent<any, any>(newData, d => +d[parameterCurrent]);
              if(fieldExtent && fieldExtent[0]){ //@ts-ignore
                extrScale.domain(fieldExtent);
              }
              
         

              for (let dataItem of newFeatures.features) {
                  const comFoundF = newData.find((com: any) => com.COMID === dataItem.properties.comid);
                 
                  //@ts-ignore
                  if(comFoundF && comFoundF[parameterCurrent]){
                   
                    //@ts-ignore
                      dataItem.properties.selectedfield = extrScale(+comFound[parameterCurrent]);
                  }
              }
      
              setStreamBankFeatures(newFeatures);
          return;
        }
      }

      let comid = 'let comid = userSelectedLocation;;';
      const url = `https://java.epa.gov/StreamCAT/metrics?name=${parameters}&areaOfInterest=catchment&comid=`
      const gdApi = `${url}${propertiesListArr.toString()}`;
      //console.log(`StreamCAT/metrics?name=${parameters}`)
      fetch(gdApi)  
          .then((response:any) => response.text())
          .then((responseText:any) => {
              
              let newData = parse(responseText, {
                  header: true,
                }).data;
              //@ts-ignore
              //let returnFields = Object.entries(newData);
              setComidCollection(newData)
          
              

/*              for (let dataItem of newFeatures.features) {
                  const comFound = newData.find((com: any) => com.comid === dataItem.properties.comid);
                  console.log(comFound )//@ts-ignore
                  if(comFound && comFound[parameterCurrent]){//@ts-ignore
                      dataItem.properties.selectedfield = comFound[parameterCurrent];
                  }
              }*/
            
              //setStreamBankFeatures(sbFeatures||[]);

              //let parameterCurrent = 'CATAREASQKM';
              //let newData = comidCollection;

              const fieldExtent = d3Array.extent<any, any>(newData, d => +d[parameterCurrent]);
              if(fieldExtent && fieldExtent[0]){ //@ts-ignore
                extrScale.domain(fieldExtent);
              }
 
              for (let dataItem of newFeatures.features) {
                  const comFound = newData.find((com: any) => com.COMID === dataItem.properties.comid);
                  //console.log(comFound)
                  //@ts-ignore
                  if(comFound && comFound[parameterCurrent]){//@ts-ignore
                      dataItem.properties.selectedfield = extrScale(+comFound[parameterCurrent]);
                  }
              }
      
              setStreamBankFeatures(newFeatures);
      });
  }

  const updateBasin = (comid:string) => {
      let featureCollectionEmpty: FeatureCollection = {
          type: "FeatureCollection",
          features: []
      }; 
      setBasinData(featureCollectionEmpty)  

      let url = `https://labs.waterdata.usgs.gov/api/nldi/linked-data/comid/${comid}/basin?f=json&simplified=false&splitCatchment=true`
      url = `https://api.epa.gov/waters/v2/watershedsp?p_nhdplusid=${comid}&p_return_watershed=TRUE&p_return_catchment=TRUE&p_fill_watershed_holes=FALSE&p_use_simplified_catchments=TRUE&f=json&api_key=XcG7zwVwPItaicv1FSfffyZ2ozxyMeD7Deox2ib1`
      //console.log('updateBasin', comid, 'watershedsp?p_nhdplusid=')

      fetch(url)
          .then((response:any) => response.text())
          .then((responseText:any) => {  
              if(responseText){
                let newData = JSON.parse(responseText);
                if(newData && newData.catchment && newData.catchment.features){
                    let newFC = newData.catchment
                    if(newData.watershed && newData.watershed.features){
                      //newFC.features.push(newData.watershed.features[0])
                      updatefeatureCollection(newFC.features);
                    }
                }                   
              }
      });
  }

  const updatefeatureCollection = (newData:any) => {
      let featureCollectionE: FeatureCollection = {
          type: "FeatureCollection",
          features: []
      }; 
      if(basinData && basinData.features){
          //featureCollectionE.features = basinData.features;
      }//&split=true
      featureCollectionE.features = featureCollectionE.features.concat(newData);
    
      setBasinData(featureCollectionE);
  }


  const createMapStops = (rData: any, valueInterest: string, colorMap?:any, reverse?:any) => {
  
    if(!rData || !rData.features){
      return null;
    }

    let normalDomain = [0, .15, .35, .45, .55, .6, .7, .8, .9, 1];
    let colorDomain = ['darkblue', 'blue', 'lightblue', 'white', 'white', 'yellow', '#e6c3a3', 'orange', 'red', 'darkred'];

    let matchExpressionZip = ["interpolate",
           ["linear"],
           ['get', valueInterest]] as Expression;

    let pointsExtent = d3Array.extent<any, any>(rData.features, d => +d.properties[valueInterest]);

    if(pointsExtent[1] === 0){
      pointsExtent[1] = 5;
    }

    if(!pointsExtent[1]){
      console.log('!pointsExtent[1]', pointsExtent, valueInterest, rData)
      return null;
    }

    if(pointsExtent[1] === pointsExtent[0]){
      pointsExtent = [pointsExtent[0]*.5, pointsExtent[1]*1.5];
    }

    if(valueInterest === 'recharge'){
      pointsExtent = [30, 90];
    }

    let legendScaleDenormal = d3Scale.scaleLinear()
        .domain([0, 1])
        .range([pointsExtent[0], pointsExtent[1]]);


    let thisColorMap = colorMap || currentColorMap;
    let thisColorMapReverse = reverse || currentColorMapReverse;

    let colorRamp = [] as any;
    for (var i = 0; i < normalDomain.length; i++) {
        colorRamp.push(legendScaleDenormal(normalDomain[i]))
        if(thisColorMapReverse){
          //@ts-ignore
          colorRamp.push(d3ScaleChromatic[thisColorMap](1-normalDomain[i]))  
        } else {
          //@ts-ignore
          colorRamp.push(d3ScaleChromatic[thisColorMap](normalDomain[i]))  
        }   
    }
    //colorRamp.push('rgba(0,0,0,0)'); 

    matchExpressionZip = ["interpolate",
           ["linear"],
           ['get', valueInterest], ...colorRamp]; 


      
    updateLayerStyleC(matchExpressionZip);

    normalDomain = [0, .25, .5, .75, 1];
    const legenddata: any[] = [];

    for (var i = 0; i < normalDomain.length; i++) {
        if(thisColorMapReverse){
          //@ts-ignore
          legenddata.push({ typecode: legendScaleDenormal(normalDomain[i]), color: d3ScaleChromatic[thisColorMap](1-normalDomain[i])})  
        } else {
          //@ts-ignore
          legenddata.push({ typecode: legendScaleDenormal(normalDomain[i]), color: d3ScaleChromatic[thisColorMap](normalDomain[i])}) 
        }   
    }

    const catc = characteristicsLocal.find((ca: any) => ca.characteristic.characteristic_id === currentCharacteristic)
    if(catc){

    }
    setMapLegendPointData(legenddata);
  }

  const updateBasinCatchments = (comids_:any, newFeatureSet:any) => {
    let comids = comids_;
    //console.log('updateBasinCatchments', updatedComid, comids.length)
      if(comids && comids.length > 0 && comids[0].comid && updatedComid !== (comids[0].comid)){
        localForage.getItem('GD_catc_'+comids[0].comid, function(err, value) {
              //console.log(err, value) 
              if(value){
                  //console.log('localForage.GD_catc', comids[0].comid)  
             
                  setCatchmentsData(value as FeatureCollection); 
                  createMapStops(value, currentCharacteristic); //Chloropleth
                  if(comids[0] && !comids[0]['CAT_RECHG']){
                      localStorage.setItem('GD_catc_ch_'+comids[0].comid, "1");
                      updateBasinCatchmentCharacteristics(value); 
                  }
                  updateSBwithGW(value as FeatureCollection, newFeatureSet)
              } else {

                  let url = `https://labs.waterdata.usgs.gov/geoserver/wmadata/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=wmadata%3Acatchmentsp&maxFeatures=10&outputFormat=application%2Fjson&cql_filter=wmadata:featureid=%20%278549637%27`
                  let all = [] as any;
                  fetch(url)
                    .then(response => response.json())
                    .then(data => {
                      
                      return data;
                    })
                    .then(data => {
                      return Promise.all(comids.map((streamseg:any) =>
                        fetch(`https://labs.waterdata.usgs.gov/geoserver/wmadata/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=wmadata%3Acatchmentsp&maxFeatures=10&outputFormat=application%2Fjson&cql_filter=wmadata:featureid=${streamseg.comid}`)
                          .then(resp => resp.json())
                          .then(json => {
                            if(!json || !json.features || json.features.length<1){
                              return null
                            }
                            return json.features[0]
                          })
                      ))
                    })       
                    .then(data => {
                      let newData = [] as any;                    
                      for (let dataItem of data) {
                        if(dataItem && dataItem.properties){
                          newData.push(dataItem);
                        } 
                      }

                      setUpdatedComid(comids[0].comid);
                      console.log('data, newData, comids', data, newData, comids) 

                      for (let dataItem of comids) {  
                          if(dataItem && dataItem.properties && !dataItem.properties.comid && dataItem.properties.featureid){
                            dataItem.properties.comid = dataItem.properties.featureid;
                          }

                        const catc = newData.filter((ca: any) => '' + ca.properties.featureid === dataItem.comid)
                        if(catc && catc.length>0){
                          catc.forEach((dataI:any, index:number) => {
                            dataI.properties = Object.assign(dataI.properties,dataItem); 
                          })                                             
                        }
                     
                      }
                  
                      let featureCollectionC: FeatureCollection = {
                          type: "FeatureCollection",
                          features: newData
                      }; 
                      updateSBwithGW(newData, newFeatureSet)
                      setCatchmentsData(featureCollectionC); 
                      createMapStops(featureCollectionC, currentCharacteristic)       
                      localForage.setItem('GD_catc_'+comids[0].comid, featureCollectionC);
                      updateBasinCatchmentCharacteristics(featureCollectionC)
                      setTimeout(() => {     
                        createMapStops(featureCollectionC, currentCharacteristic) 
                      }, 1111);  
                    })
              }
        });
      }
  }


  useEffect(() => {
    if(rechargeValues && nhldData &&  streamBankFeatures && catchmentsData){
      updateSBwithRecharge(rechargeValues, nhldData);
    }

  }, [rechargeValues, nhldData]);


  const updateSBwithRecharge = (rechargeData:any, nhdData:any) => {
        console.log('updateSBwithRecharge', rechargeData, nhdData, streamBankFeatures, catchmentsData, currentCharacteristic)
        //catchmentsData chloropleth
        if(rechargeData && nhdData && catchmentsData){


          let newoptions = [...characteristicsNLDI];
          let arrIgn = ['comid', 'COMID'];
          let ChArrIgn = ['comid', 'COMID'];    
          if(nhdData.columns){
              nhdData.columns.forEach((dataI:any, index:number) => {
                if(ChArrIgn.indexOf(dataI)<0){
                  newoptions.push({"label": dataI,"value": dataI})
                }   
              })            
          }     

  
            let fData = [...filteredData];

            for (let dataItem of catchmentsData.features) {  
                if(dataItem && dataItem.properties && !dataItem.properties.comid && dataItem.properties.featureid){
                  dataItem.properties.comid = dataItem.properties.featureid;
                }


                if(dataItem && dataItem.properties && dataItem.properties.comid){
                  let catcA = rechargeData[+dataItem.properties.comid];
                  if(catcA){
                    dataItem.properties.recharge=catcA;
                  }
                  let catcB = [] as any
                  if(nhdData.data){ 
                     //@ts-ignore
                    catcB = nhdData.data.find((ca: any) => ca[0] === +dataItem.properties.comid);                                                      
                  }
                  
                  if(dataItem.properties && nhdData.columns){
                    nhdData.columns.forEach((dataI:any, index:number) => {
                      if(arrIgn.indexOf(dataI)<0){
                        if(dataItem.properties && catcB && catcB[index]){
                           //@ts-ignore
                          dataItem.properties[dataI] = catcB[index]                         
                        } else {
                          //console.log('nhdData.columns', dataItem, catcB, index)
                        }

                      }   
                    })

                    //@ts-ignore
                    let tableDataItem = fData.filter((ca: any) => ca.comid === dataItem.properties.comid);
                   
                    if(tableDataItem && tableDataItem.length>0){
                        tableDataItem.forEach((dataI:any, index:number) => {
                           if(dataI && dataItem.properties){
                             dataI.properties = Object.assign(dataI,dataItem.properties);
                           } else {

                             //console.log(dataI, dataItem)
                           }
                             
                        })
                        //tableDataItem = Object.assign(tableDataItem,dataItem.properties);
               
                    } else {
                      //console.log(dataItem.properties.comid)
                    }

                  } else {
                    //console.log(dataItem, 'no properties')
                  }



                } else {
                 // console.log(dataItem, 'no comid')
                }
            }//

            let fc_: FeatureCollection = {
                type: "FeatureCollection",
                features: catchmentsData.features
            }; 

            setFilteredData(fData)
            setCatchmentsData(fc_);     

            setCatchmentOptions(newoptions);
            
            setTimeout(() => {     
              createMapStops(fc_, currentCharacteristic)
            }, 1111);  
            if(catchmentsData.features && catchmentsData.features[0] && catchmentsData.features[0].properties && catchmentsData.features[0].properties.comid){
              localForage.setItem('GD_catc_'+catchmentsData.features[0].properties.comid, fc_);
            }

        }
  }

  const updateSBwithGW = (newData:any, newFeatureSet:any) => {
        //console.log('updateSBwithGW')
        if(newData && newData.length> 0 && newFeatureSet && newFeatureSet.features && newFeatureSet.features.length>0){
            let featureCollectionBank: FeatureCollection = {
                type: "FeatureCollection",
                features: newFeatureSet.features
            }; 

            if( newFeatureSet.features[0] && newFeatureSet.features[0].properties && !newFeatureSet.features[0].properties.recharge){            
                for (let dataItem of newFeatureSet.features) {  
                   if(dataItem && dataItem.properties && !dataItem.properties.comid && dataItem.properties.featureid){
                      dataItem.properties.comid = dataItem.properties.featureid;
                    }
                    if(dataItem && dataItem.properties && dataItem.properties.comid){//@ts-ignore
                      const catc = newData.filter((ca: any) => '' + ca.properties.featureid === dataItem.properties.comid)

                      if(catc && catc.length>0){
                        //catc.properties = Object.assign(catc.properties,dataItem.properties); 
                        catc.forEach((dataI:any, index:number) => {
                          if(dataI && dataI.properties && dataItem.properties){                     
                              dataI.properties = Object.assign(dataI.properties,dataItem.properties);                           
                          }
                        })  
                      }
                    }
                }
               
                setStreamBankFeatures(featureCollectionBank);            
            }
        }
  }

  const updateBasinCatchmentCharacteristics = (fc_:any) => {
      let fc = fc_;
      fc.features = fc_.features     
      if(fc.features && fc.features.length > 0){
            //console.log('updateBasinCatchmentCharacteristics', fc.features.length, '/linked-data/comid')
            let url = `https://labs.waterdata.usgs.gov/geoserver/wmadata/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=wmadata%3Acatchmentsp&maxFeatures=10&outputFormat=application%2Fjson&cql_filter=wmadata:featureid=%20%278549637%27`

            if(fc.features[0].properties && fc.features[0].properties["CAT_RECHG"]){
                //console.log('return')
                return;
            }

            let all = [] as any;

            fetch(url)
              .then(response => response.json())
              .then(data => {
                return data;
              })
              .then(data => {
                return Promise.all(fc.features.map((streamseg:any) =>

                  fetch(`https://labs.waterdata.usgs.gov/api/nldi/linked-data/comid/${streamseg.properties.featureid}/local?f=json`)
                    .then(resp => resp.json())
                    .then(json => {
                      return json
                    })
                ))
              })       
              .then(data => {
                //setUpdatedComid(comids[0].comid);
                for (let dataItem of fc.features) {  
                  if(dataItem && dataItem.properties && !dataItem.properties.comid && dataItem.properties.featureid){
                    dataItem.properties.comid = dataItem.properties.featureid;
                  }
                  const catc = data.find((ca: any) => '' + ca.comid === dataItem.properties.comid)
                  if(catc && catc.characteristics){
                    catc.characteristics.map((cc:any) =>  dataItem.properties[cc["characteristic_id"]] = +cc["characteristic_value"])
                  }  
                }
            
                let featureCollectionC: FeatureCollection = {
                    type: "FeatureCollection",
                    features: fc_.features
                }; 
                //console.log(fc_, currentCharacteristic)
                setCatchmentsData(fc_);     
                createMapStops(fc_, currentCharacteristic)
                localForage.setItem('GD_catc_'+fc_.features[0].properties.comid, fc_);

              })
      }
  }

  useEffect(() => {
      setMapHoverFilter(['in', ['get', locationField], userSelectedLocation]);
      setUserSelectedLocations(userSelectedLocation);
      if(userSelectedLocation && userSelectedLocation!==''){
          let parameterlist = [];
          if(streamFields && streamFields.length > 0){
            for (let dataItem of streamFields) {
               if(dataItem.active===true){
                    parameterlist.push(dataItem.fieldname);
                }  
            }  
          }  
        
          let parameters = parameterlist.toString();
       
          if(!parameters || parameters === 't'){
            parameters = 'fert'
          }

            let comid = userSelectedLocation;
            const url = `https://java.epa.gov/StreamCAT/metrics?name=${parameters}&areaOfInterest=catchment&comid=`
            const gdApi = `${url}${comid}`;
                fetch(gdApi)
                  .then((response:any) => response.text())
                  .then((responseText:any) => {
                      //console.log('StreamCAT/metrics?n')
                      let newData = parse(responseText, {
                          header: true,
                        }).data[0];
                      //@ts-ignore
                      let returnFields = Object.entries(newData);
                      updateBasin(comid);

                       for (let dataItem of returnFields) {
                         let re = /\s*\d{4}/g; let ra= /\s*_\d{4}/g;
                         
                         let year = '';
                         let yearplace = dataItem[0].search(re);
                         if(yearplace > -1){
                           year = dataItem[0].substr(yearplace, 4)
                         }
                        
                         let fieldName = dataItem[0].replace('CAT', '').replace(ra, '').replace(re, '');

                         if( fieldName=== 'WSAREASQKM'){
                           dataItem[0] = 'Watershed Area sq km';//@ts-ignore
                           newData['Watershed Area sq km'] =  newData['WSAREASQKM'];//@ts-ignore
                           delete newData['WSAREASQKM'];
                         }

                        if(fieldName  === 'AREASQKM'){
                           dataItem[0] = 'Catchment Area sq km';//@ts-ignore
                           newData['Catchment Area sq km'] =  newData['CATAREASQKM'];//@ts-ignore
                           delete newData['CATAREASQKM'];
                        }

                           const metric = streamCat.find((streammetric: any) => fieldName.toLowerCase() === streammetric.METRIC_NAME.replace('[AOI]', '').replace('[Year]', '').toLowerCase())
                      
                           if(metric){//@ts-ignore
                                 newData[metric.WEBTOOL_NAME + ' ' + year] =  newData[dataItem[0]] + ' ' + metric.METRIC_UNITS;//@ts-ignore
                                 delete newData[dataItem[0]];                       
                           }
                       }
                      
                      setComidData(newData)
                  });
      }
  }, [userSelectedLocation]);




  const clickRowHandler = (event: any) => {
    if(event && event.comid){
      if(event.comid !== userSelectedLocation){
        setUserSelectedLocation(event.comid);
      } else {
        setUserSelectedLocation('');
        setComidData(null);
        const featureCollectionEmpty: FeatureCollection = {
          type: "FeatureCollection",
          features: []
        }; 
        setBasinData(featureCollectionEmpty)  
      }
    }

/*  
  const [mapHoverFilter, setMapHoverFilter] = useState<any>(['in', ['get', locationField], ["literal", ['8549669', '8549637']]]); //8549637
  if (userSelectedLocations.indexOf(event[locationField]) > -1) {
      setUserSelectedLocations([]);
      setPopupInfo(null);
    } else {
      setUserSelectedLocations([event[locationField]]);
      setMapHoverFilter(['in', ['get', locationField], event[locationField]]);
      setPopupInfo(event);
      if (mapRef.current) {
        if (event && event.centroid_longitude) {
          mapRef.current?.flyTo({
            center: [parseFloat(event.centroid_longitude), parseFloat(event.centroid_latitude)],
          });
        }
      }
    }*/
  };


  const renderComidFields = () => {
    return Object.entries(comidData).map((element: any, index:any) => {
      return (
        <p className="GD-p-comid" key={uuidv4()}>
          {element[0]}: {element[1]} 
        </p>
      )
    })
  }

  const renderThumb = (style: any) => {
    const thumbStyle = {
      backgroundColor: 'white'
    };
    return (
      <div
        style={{ ...style, ...thumbStyle }}
      />
    );
  }

  const renderComidInfo = () => {
 
    return(               
        <div  className='GD-info-stream-container'>
          <div className="report-options">
                <OverlayTrigger trigger="click" rootClose placement="right" overlay={popoverComidField}>
                  <Button className={'GD-help-button'} >
                    <FontAwesomeIcon icon={faQuestion}/>
                  </Button> 
                </OverlayTrigger>  
              <OverlayTrigger trigger="click" rootClose placement="left" overlay={popoverStreamFields}>
                <Button className={'GD-standard-button'} style={{ marginRight: 4}}>
                  <FontAwesomeIcon icon={faGear} />
                </Button>
              </OverlayTrigger>
              <Button onClick={fullscreenContainerHandler} style={{display: 'none'}}>
                <img src={fullscreenIcon} />
              </Button>  
            </div>
            <div ><span className="GD-segments-title">Segment Info</span></div>
            <div className="GD-segments-info"  style={{}}>
              <Scrollbars renderThumbVertical={renderThumb} className="summary-scroll" >
                <div style={{marginTop: 10}}>
                  {comidData && renderComidFields()}
                </div>
              </Scrollbars>
            </div>
        </div>                   
    )
  }

  const popovergear = (
    <Popover id="gd-categories-popover" key={"gd-categories-popover-"} >
      <Popover.Header as="h3">Options</Popover.Header>
      <Popover.Body>


        <Form.Label style={{width: 130, marginBottom: 10}}>Select Color Scale</Form.Label>

        <Form.Select 
            id='GD-dropdown-color'                                    
            value={currentColorMap}
            key={"ColorSelection" + responseCounter}
            onChange={e => {                                           
                handleColorOption(e.target.value)
            }}>
           {renderColorOptions()}
        </Form.Select>

          <Form.Check 
             type={'checkbox'}     
              id={`Reverse`}
              label={`Reverse`}
              style={{marginTop: 20}}
              name="groupReverse"
              value='Reverse'
              checked={currentColorMapReverse}
              onClick={e => {     //@ts-ignore                                      
                handleColorOptionReverse(e.target.checked)
              }}
            /> 

            <div className="map-overlay-inner">

            </div>

            <label style={{marginTop: 30}}>Layer opacity</label>
            <Slider
              min={1} max={100} defaultValue={sliderOpacity}
              onChange={handleSliderOpacityChange}
              handleStyle={{
                height: 22,  
                width: 22,
                borderColor: '#4fc2d9',
                marginTop: -10,
              }}
              dotStyle={{ borderColor: '#4fc2d9', height: 14, width: 14, transform: 'translate(-50%, 20%)' }}
              railStyle={{ backgroundColor: '#838487' }}
              trackStyle={{ backgroundColor: '#4fc2d9', height: 5 }} />

      </Popover.Body>
    </Popover>
  );




  return (
    <>
      <FullScreenComp handle={fullscreenhandle} className={'GroundwaterDetermination-fullscreen'}>
        <Container fluid className="GroundwaterDetermination-container" >
        <div className="GroundwaterDetermination"  id='GroundwaterDetermination-report'>
          <ReportHeader global={global} 
            data={streamBank} 
            data2={handlePrintData}
            data3={queryProps} 
            filterMode={'groundwater'}  
            reportID={"GroundwaterDetermination-report"} 
            filterAppliedOff={true} 
            fullScreenClickHandle={fullscreenhandle.enter} />              
            <Row className="container-row-top"  style={{marginTop:20}}>

                <Row className="container-row-start">

                  <Col  className="GD-Col GD-Col-Start">
                    <Row  className="GD-start">
                       <Form className="GD-user-input">
                            <Row className="GD-input-row">
                               <div style={{paddingLeft: 5}}>
                                    <Form.Group className="GD-form" controlId="formTarget" style={{justifyContent: 'unset'}}>
                                        <Form.Label style={{width: 130, marginBottom: 0}}>Enter Target HUC8 Location</Form.Label>
                                        <Form.Control
                                            key={appContext.selectedHUC8}
                                            size="lg"
                                            type="text"
                                            className="GD-p GD-Form-Input"
                                            style={{maxHeight: 35, maxWidth: 145, marginLeft: 20}}
                                            value={huc8}
                                            placeholder={huc8}
                                            onChange={handleHucChange}
                                        />
                                        <Button
                                          onClick={handleLocationSubmit}
                                          size="lg"
                                          style={{maxHeight: 55, maxWidth: 180, width: 140, lineHeight: '20px', marginTop: 0, marginLeft: 20}}
                                          className="GD-nclicks-button">
                                            Process Location
                                        </Button>
                                          <OverlayTrigger trigger="click" rootClose placement="right" overlay={popover}>
                                            <Button style={{display: 'none'}}>
                                              <FontAwesomeIcon icon={faQuestion}/>
                                            </Button> 
                                        </OverlayTrigger>

                                    </Form.Group>
                               </div>
                            </Row><Row  className="GD-input-row">  
        
                                 <div className="GD-user-input-modelrain" style={{paddingLeft: 5}}>
                                    <Form.Group  className="GD-form" controlId="formBail" 
                                          onChange={handleModelRainChange}>
                                        <Form.Label style={{width: 120, minWidth: 120}}>Select Model Rain Year Type</Form.Label>
                                            <Form.Check 
                                             type={'radio'}     
                                              id={`Simple`}
                                              label={`Simple`}
                                              name="group1"
                                              value='Simple'
                                              checked={(rainSelection==='Simple') ? true :false}
                                             /> 
                                            <Form.Check 
                                             type={'radio'}     
                                              id={`Random`}
                                              label={`Random`}
                                              name="group1"
                                              value='Random'
                                              checked={(rainSelection==='Random') ? true :false}
                                             /> 
                                            <Form.Check 
                                             type={'radio'}     
                                              id={`SpecificYear`}
                                              label={`Specific Year`}
                                              name="group1"
                                              value='Specific Year'
                                              checked={(rainSelection==='Specific Year') ? true :false}
                                             />   
                                             <OverlayTrigger rootClose trigger="click" placement="right" overlay={popoverFields}>
                                              <Button  >
                                                <FontAwesomeIcon icon={faQuestion}/>
                                              </Button> 
                                            </OverlayTrigger>                                           
                                    </Form.Group>
                                  </div>  
                                  </Row><Row >

                                    <div style={{display: 'flex',  flexDirection: 'row'}}>
                                      <Form.Group  controlId="formYear" style={ rainSelection === "Specific Year" ? {display: 'inline-flex',  flexDirection: 'row', marginLeft: 'auto'} : {display: 'none'}}>
                                            <Form.Select 
                                              className="GD-p GD-dropdown-select"
                                              style={{width: 120, maxWidth: 200, marginRight: 40}}
                                              value={rainSelectionYear}
                                              onChange={e => {                                           
                                                  handleSpecificYear(e.target.value)
                                              }}>
                                             {renderYearOptions()}
                                           </Form.Select>


                                      </Form.Group>

                                  </div>

                                 </Row><Row className="GD-input-row">

                             
                                    <Form.Group  controlId="formInterest" style={{display: 'flex',  flexDirection: 'row', maxHeight: 45, paddingLeft: 5}}>
                                        <Form.Label  style={{width: 120}}>Select Future Year of Interest</Form.Label>
                                        <div style={{minWidth: 42}}></div>
                                        <Form.Select 
                                          className="GD-p GD-dropdown-select"
                                          style={{width: 120, maxWidth: 200}}
                                          value={futureYear}
                                          onChange={e => {                                           
                                            handleFutureYear(e.target.value)
                                          }}>
                                         {renderFutureYearOptions()}
                                       </Form.Select>
                                        <OverlayTrigger rootClose trigger="click" placement="right" overlay={popover}>
                                            <Button style={{display: 'none'}}>
                                              <FontAwesomeIcon icon={faQuestion}/>
                                            </Button> 
                                        </OverlayTrigger>
                                    </Form.Group>
                             
                                  </Row>

                        <Row className="GD-slider-container GD-input-row"  >

                          <div className="GD-form"  style={{paddingLeft: 5}} >
                            <Form.Label  style={{width: 120}}>Select Climate Scenario</Form.Label>
                            <div style={{width: '45%'}}>
                              <Slider
                                min={1} max={3} marks={marksR} step={1} value={sliderClimate}
                                onChange={handleSliderChange}
                                handleStyle={{
                                  height: 22,  
                                  width: 22,
                                  borderColor: '#4fc2d9',
                                  marginTop: -10,
                                }}
                                dotStyle={{ borderColor: '#4fc2d9', height: 14, width: 14, transform: 'translate(-50%, 20%)' }}
                                railStyle={{ backgroundColor: '#838487' }}
                                trackStyle={{ backgroundColor: '#4fc2d9', height: 5 }} />
                            </div>
                            <OverlayTrigger rootClose trigger="click" placement="right" overlay={popover}>
                                <Button >
                                  <FontAwesomeIcon icon={faQuestion}/>
                                </Button> 
                            </OverlayTrigger>
                          </div>
                        </Row>

                        </Form>

                        <div className={'GD-progress-container'} style={isNotInProgress ? {display: 'none' }: { display: 'flex' }}>
                          <span className={'GD-progress-containe-span'}>Calculating data for watershed {currentHuc8}.  May take up to 60 seconds.</span>
                          <ProgressBar key={'ProgressBar' + progress} className={'GD-progress'} min={0} max={100} animated label={`${progress}%`}
                             now={progress} visuallyHidden={isNotInProgress ? true : false}   />
                        </div>  
                    </Row> 


                    <Row className="w-100 GD-row-results" style={{marginTop:20}}>
                        <Col className="">
                            <Form.Group className="mb-3" controlId="formBase">
                                <Form.Label className={'GD-info-label'}>Current Model Base</Form.Label>
                                <p className="GD-p-big">{ rainSelection === "Specific Year" ? rainSelectionYear  : currentModelBase}</p>
                            </Form.Group>
                        </Col>    
                        <Col className="">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label className={'GD-info-label'}>Model Year Precipitation</Form.Label>
                                   <p className="GD-p-big">{modelYearPrecipitation}</p>
                            </Form.Group>
                        </Col>  
                        <Col className="">
                            <Form.Group className="mb-3" controlId="formBasicEmail" style={{minHeight: 55}}>
                                <Form.Label className={'GD-info-label'}>Year of Interest Precipitation ({futureYear})</Form.Label>
                                <p className="GD-p-big">{scenarioYearPrecipitation} {scenarioYearPrecipitation ? 'in.' : ''}</p>
                            </Form.Group>
                        </Col>
                    </Row>


                    <Row  className="GD-sliders">
                      <div className="GD-sliders-main">
                                         
                        <Col style={{paddingRight: 10}}>        
                        
                         <Accordion className="site-info-accordion"
                                defaultActiveKey="1"
                                style={{width: '100%', marginTop: 10}}
                            >
                              <Accordion.Item eventKey="0">
                                  <Accordion.Header>Seasonal Impact</Accordion.Header>
                                   <Accordion.Body>

                        <div className="GD-slider-container"  style={{marginTop: 2}}>
                          <span className="GD-slider-title">Winter</span>
                          <div className="GD-slider">
                            <Slider
                              min={0} max={4} marks={marksSeason} step={1} value={sliderWinter}
                              onChange={handleSliderChangeWinter}
                              handleStyle={{
                                height: 22,
                                width: 22,
                                borderColor: '#4fc2d9',
                                marginTop: -10,
                              }}
                              dotStyle={{ borderColor: '#4fc2d9', height: 14, width: 14, transform: 'translate(-50%, 20%)' }}
                              railStyle={{ backgroundColor: '#838487' }}
                              trackStyle={{ backgroundColor: '#4fc2d9', height: 5 }} />
                          </div>
                        </div>
                        <div className="GD-slider-container" style={{marginTop: 30}}>
                          <span className="GD-slider-title">Spring</span>
                          <div className="GD-slider">
                            <Slider
                              min={0} max={4} marks={marksSeason} step={1} value={sliderSpring}
                              onChange={handleSliderChangeSpring}
                              handleStyle={{
                                height: 22,
                                width: 22,
                                borderColor: '#4fc2d9',
                                marginTop: -10,
                              }}
                              dotStyle={{ borderColor: '#4fc2d9', height: 14, width: 14, transform: 'translate(-50%, 20%)' }}
                              railStyle={{ backgroundColor: '#838487' }}
                              trackStyle={{ backgroundColor: '#4fc2d9', height: 5 }} />
                            </div>
                        </div>

                        <div className="GD-slider-container" style={{marginTop: 30}}>
                          <span className="GD-slider-title">Summer</span>
                          <div className="GD-slider">
                            <Slider
                              min={0} max={4} marks={marksSeason} step={1} value={sliderSummer}
                              onChange={handleSliderChangeSummer}
                              handleStyle={{
                                height: 22,
                                width: 22,
                                borderColor: '#4fc2d9',
                                marginTop: -10,
                              }}
                              dotStyle={{ borderColor: '#4fc2d9', height: 14, width: 14, transform: 'translate(-50%, 20%)' }}
                              railStyle={{ backgroundColor: '#838487' }}
                              trackStyle={{ backgroundColor: '#4fc2d9', height: 5 }} />
                          </div>
                        </div>

                        <div className="GD-slider-container" style={{marginTop: 30}}>
                          <span className="GD-slider-title">Fall</span>
                          <div className="GD-slider">
                            <Slider
                              min={0} max={4} marks={marksSeason} step={1} value={sliderFall}
                              onChange={handleSliderChangeFall}
                              handleStyle={{
                                height: 22,
                                width: 22,
                                borderColor: '#4fc2d9',
                                marginTop: -10,
                              }}
                              dotStyle={{ borderColor: '#4fc2d9', height: 14, width: 14, transform: 'translate(-50%, 20%)' }}
                              railStyle={{ backgroundColor: '#838487' }}
                              trackStyle={{ backgroundColor: '#4fc2d9', height: 5 }} />
                          </div>
                        </div>
                                <Col className="" style={{display:'none'}}>
                                    <Form.Check 
                                          type={'checkbox'}     
                                          id={`LoadLastData`}
                                          label={`Load Last Data`}
                                          name="group1"
                                          className="GD-load-button"
                                          
                                          checked={isLoadLastData}
                                          onChange={e => {                                           
                                          handleLoadCheckbox(!isLoadLastData)
                                        }}
                                    /> 
                                </Col>
              
                              </Accordion.Body>
                            </Accordion.Item >      
                          </Accordion>    
                        </Col>
                      </div>
                    </Row>  

                        <div className='GD-info-stream'>                    
                          <div className='GD-table-container'>
                             <div className='GD-segments-header'>
                               <span className="GD-segments-title">Stream Segments</span>
                               <OverlayTrigger  rootClose trigger="click" placement="right" overlay={popoverStream}>
                                <Button className="GD-help-stream" >
                                  <FontAwesomeIcon icon={faQuestion}/>
                                </Button> 
                              </OverlayTrigger>
                             </div>

                            <ReportTableExpanded
                              key={'GD-table'}
                              data={filteredData}
                              columns={tableColumns}
                              clickLoadStop={true}
                              clickRowHandler={clickRowHandler}
                            />
                          </div>  
                          {renderComidInfo()}
                        </div>                     
                  </Col> 



                  <Col  className="GD-Col-R" >
                   <div className={'GD-navbar-container'}>
                    <Navbar className={'GD-navbar'}>
                      <Nav.Item className={(navMenu === 0) ? 'GD-nav-item GD-nav-item-menu GD-nav-item-active' : 'GD-nav-item GD-nav-item-menu'}>
                          <Button variant={(navMenu === 0) ? 'primary' : 'secondary'} 
                                className={'GD-nav-dropdown-button'} 
                                onClick={e => {                                           
                                  setNavMenu(0)
                                }}> 
                            <FontAwesomeIcon  size="2x" icon={faMap}/>
                            <span>Stream Analysis</span>
                          </Button>
                          
                      </Nav.Item>                

                      <Nav.Item className={(navMenu === 1) ? 'GD-nav-item GD-nav-item-menu navbar-nav-item-active' : 'GD-nav-item GD-nav-item-menu'}>
                          <Button variant={(navMenu === 1) ? 'primary' : 'secondary'} className={'GD-nav-dropdown-button'}                           
                          onClick={e => {                                           
                                  setNavMenu(1)
                                }}> 
                            <FontAwesomeIcon  size="2x" icon={faTint }/>
                            <span>Precipitation Change</span>
                          </Button>  
                      </Nav.Item>  

                      <Nav.Item className={(navMenu === 2) ? 'GD-nav-item GD-nav-item-menu GD-nav-item-active' : 'GD-nav-item GD-nav-item-menu'}>
                          <Button variant={(navMenu === 2) ? 'primary' : 'secondary'} className={'GD-nav-dropdown-button'}                           
                          onClick={e => {                                           
                                  setNavMenu(2)
                                }}> 
                            <FontAwesomeIcon   size="2x"  icon={faLeaf}/>
                            <span>Seasonal Impact</span>
                          </Button>
                      </Nav.Item>

                      <Nav.Item className={(navMenu === 3) ? 'GD-nav-item GD-nav-item-menu navbar-nav-item-active' : 'GD-nav-item GD-nav-item-menu'}>
                          <Button  variant={(navMenu === 3) ? 'primary' : 'secondary'}  className={'GD-nav-dropdown-button'}                           
                          onClick={e => {                                           
                                  setNavMenu(3)
                                }}> 
                          <FontAwesomeIcon  size="2x"  icon={faLineChart}/>
                          <span>Flow Duration</span>
                          </Button>
                      </Nav.Item>

                      <Nav.Item className={(navMenu === 4) ? 'GD-nav-item GD-nav-item-menu navbar-nav-item-active' : 'GD-nav-item GD-nav-item-menu'}>
                          <Button  style={{maxWidth: 99}} variant={(navMenu === 4) ? 'primary' : 'secondary'}  className={'GD-nav-dropdown-button'}                           
                          onClick={e => {                                           
                                  setNavMenu(4)
                                }}> 
                                <img src={gwRechargeIcon} style={{fill: 'white'}} className='icon-groundwaterrecharge' />
                          <span >Groundwater Recharge</span>
                          </Button>
                      </Nav.Item>

                    </Navbar>


                    <div className={'GD-navbar-risk-container'} style={(navMenu === 0) ? {display: 'flex'} : {display: 'none'}}>
                      <span className={'GD-navbar-risk-span'}style={{alignSelf: 'center', fontSize: '1em'}}>Risk Assessment</span>
                      <span className={'GD-navbar-risk-sp'} style={{alignSelf: 'center', fontSize: '1em'}}>by percentage of stream length</span>
                      <Navbar className={'GD-navbar-risk'}>

                        <Nav.Item className={(riskMenu === 0 || riskMenu === null) ? 'GD-nav-item GD-risk-item-active' : 'GD-nav-item  GD-risk-item-inactive'}>
                            <Button 
                              variant={'danger'} 
                            className={(riskMenu === 0) ? 'GD-nav-dropdown-button GD-nav-dropdown-button-active' : 'GD-nav-dropdown-button'}
                                  onClick={e => {                                           
                                    (riskMenu!==0 ? setRiskMenu(0) : setRiskMenu(null))
                                  }}> 
                              
                              <span className={'GD-navbar-risk-percentage'}>{(streamRisk[0]!==null) ? streamRisk[0] + '%' : ''}</span>
                            </Button>
                            
                        </Nav.Item>                

                        <Nav.Item className={(riskMenu === 1 || riskMenu === null) ? 'GD-nav-item GD-risk-item-active' : 'GD-nav-item GD-risk-item-inactive'}>
                            <Button variant={'warning'}   
                            className={(riskMenu === 1) ? 'GD-nav-dropdown-button GD-nav-dropdown-button-active' : 'GD-nav-dropdown-button'}                        
                            onClick={e => {                                           
                                    (riskMenu!==1 ? setRiskMenu(1) : setRiskMenu(null))
                                  }}> 
                              
                              <span className={'GD-navbar-risk-percentage'}>{(streamRisk[1]!==null) ? streamRisk[1] + '%' : ''}</span>
                            </Button>  
                        </Nav.Item>  

                        <Nav.Item className={(riskMenu === 2 || riskMenu === null) ? 'GD-nav-item GD-risk-item-active' : 'GD-nav-item GD-risk-item-inactive'}>
                            <Button variant={'success'}      
                            className={(riskMenu === 2) ? 'GD-nav-dropdown-button GD-nav-dropdown-button-active' : 'GD-nav-dropdown-button'}                     
                            onClick={e => {                                           
                                    (riskMenu!==2 ? setRiskMenu(2) : setRiskMenu(null))
                                  }}> 
                              
                              <span className={'GD-navbar-risk-percentage'}>{(streamRisk[2]!==null) ? streamRisk[2] + '%' : ''}</span>
                            </Button>
                        </Nav.Item>

                      </Navbar>
                    </div>
                </div>

                <Col className="column-container GD-line-chart-map-container GD-map-container-parent" 
                       style={(navMenu === 0 || navMenu===10|| navMenu===4)? {display: 'block'} : {display: 'none' }}>
                    <div className={isFullScreenMapActive ? 'GD-expand-map-container GD-map-container' : 'GD-map-container'}>

                      <Row className="GD-map-row">
                            <div style={(!isRechargeLoaded && navMenu===4 )?{display:'block', minWidth: 23, alignSelf:'center', marginBottom: 40}:{display:'none'}}>
                                    <Spinner
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                       
                                    />
                                    <span style={{ marginLeft: 14 }} >Calculating Groundwater Recharge from climate data...</span>
                            </div>
                        <Form.Group className="GD-map-form" controlId="formTarget">
                            <div className="GD-map-header">
                              <Form.Label style={(navMenu === 0) ? {display: 'inline-block'} : {display: 'none'}}>Select HUC12 for detailed Reach Analysis</Form.Label>
                              <div className="GD-map-header-toolset" style={( navMenu===4 ) ? {display: 'flex'} : {display: 'none'}}>
                                <Form.Label style={{marginRight: 5}}>Characteristic to plot: </Form.Label>
                                <Form.Select 
                                    id='GD-dropdown-catchment'                                    
                                    value={currentCharacteristic}
                                    key={"CharacteristicSelection" + responseCounter}
                                    onChange={e => {                                           
                                        handleCharacteristicOption(e.target.value)
                                    }}>
                                   {renderCatchmentOptions()}
                                </Form.Select>
                              </div>    
                              <div className="GD-map-header-toolset" style={( navMenu===4 ) ? {display: 'flex'} : {display: 'none'}}>
                                     <OverlayTrigger rootClose trigger="click" placement="left" overlay={popoverRecharge}>
                                      <Button className="GD-help-flow" style={{ marginRight: 14 }}>
                                        <FontAwesomeIcon icon={faQuestion}/>
                                      </Button> 
                                    </OverlayTrigger>

                                    <Button className={(mainPanelSelection === 0) ? 'GD-map-header-toolset-selected'  : '' } 
                                        onClick={e => {                                           
                                            handleMainPanelSelection(0)
                                        }}
                                     style={{ marginRight: 14 }}>
                                      <FontAwesomeIcon icon={faMap} />
                                    </Button>
                                    <Button className={(mainPanelSelection === 1) ? 'GD-map-header-toolset-selected'  : '' } 
                                        onClick={e => {                                           
                                            handleMainPanelSelection(1)
                                        }}
                                      style={{ marginRight: 14 }}>
                                      <FontAwesomeIcon icon={faTable} />
                                    </Button>
                                <OverlayTrigger rootClose trigger="click" placement="left" overlay={popovergear}>
                                    <Button style={{ marginRight: 14 }}>
                                      <FontAwesomeIcon icon={faGear} />
                                    </Button>
                                  </OverlayTrigger>

                                  <OverlayTrigger
                                      placement="left"
                                      rootClose
                                      delay={{ show: 0, hide: 100 }}
                                      overlay={renderFullScreenTooltip }
                                    >
                                    <Button 
                                        onClick={e => {                                           
                                          setIsFullScreenMapActive(!isFullScreenMapActive);
                                          setTimeout(() => {     
                                            if (mapRef && mapRef.current) {                                                 
                                              mapRef.current.resize(); 
                                            }
                                          }, 111);                              
                                      }}>
                                      <img src={fullscreenIconW} />
                                    </Button> 
                                  </OverlayTrigger>

                              </div> 
                            </div>

                            <Form.Select 
                                className="GD-p GD-dropdown"
                                value={huc12Selection}
                                key={"huc12Selection" + responseCounter}
                                onChange={e => {                                           
                                    handleHuc12Option(e.target.value)
                                }}>
                               {renderHuc12Options()}
                            </Form.Select>
                        </Form.Group>
   
                        <div className="GD-table GD-table-m" style={(mainPanelSelection===1) ? {display:'block'} : {display: 'none'}}>                                          
                            <ReportTableExpanded
                              key={'GD-table-cc'+updateActive}
                              data={filteredData}
                              columns={tableColumnsM}
                              clickLoadStop={true}
                              clickRowHandler={clickRowHandler}
                            />
                        </div>     

                        <div className="GD-map" style={(mainPanelSelection===0) ? {display:'block'} : {display: 'none'}}>
                            
                            <LoadingDataAnimation dataLoading={!isReachLoaded} />



                              <Map
                                  id="GroundwaterDetermination_map"
                                  mapboxAccessToken={settings.maboxKey}   
                                  mapStyle={global.mapStyle}
                                  
                                  onClick={(e) => {
                                    global.onMapClick(e)
                                    onHandleClick(e)
                                  }}
                                  onMouseEnter={onMouseEnter}
                                  onMouseMove={onMouseMove}
                                  onMouseLeave={onMouseLeave}
                                  cursor={cursor}
                                  RTLTextPlugin={""}
                                  preserveDrawingBuffer={true}
                                  interactiveLayerIds={['linelayer2', 'filllayer1']}                  
                                  ref={mapRef as Ref<MapRef>}
                                  onMoveEnd={(e) => {
                                      global.setViewport({
                                        longitude: e.viewState.longitude,
                                        latitude: e.viewState.latitude,
                                        zoom: e.viewState.zoom,
                                      })
                                    }
                                  }
                                >


                                {catchmentsData && (navMenu === 4) && (
                                    <Source id="fillsource" type="geojson" data={catchmentsData} key={'catchmentData'+updateActive}>
                                      <Layer {...layerStyleC} />
                                    </Source> 
                                  )}

                                {basinData && (
                                    <Source id="linesource" type="geojson" data={basinData}>
                                      <Layer {...layerStyleEx} />
                                    </Source> 
                                  )}

                                {streamBankFeatures && (
                                    <Source id="circlesource" type="geojson" data={streamBankFeatures}>
                                      <Layer {...layerStyle}  />
                                      <Layer {...layerStyle2} />
                                      <Layer {...layerStyle3} filter={mapHoverFilter}  />                               
                                    </Source> 
                                  )}

                                <MapAddition global={global} 
                                            mapRef={mapRef}
                                            PointInPolygonField={locationField}
                                            MapSliderAdd={true}
                                            position={'low'}
                                            zipOff={true}
                                            statusMessage={statusMessage}/>

                                  {popupInfo && renderPopup()}

                                 <div className='map-legend-container'>
                                  <MapLegend
                                    mapLegendData={mapLegendData}
                                    title={'Potential Erodibility Warning Level'}
                                    key={'mapLegend'+currentCharacteristic}
                                    legendWidth={250}
                                    global={global}
                                    pointTitle={currentCharacteristic}
                                    pointSubtitle={(currentCharacteristicInfo && currentCharacteristicInfo.units) ? `(${currentCharacteristicInfo.units})` : ''}
                                    pointLegendData={mapLegendPointData}
                                    pointSwitch={(navMenu===4) ? true : false}
                                    pointCheckboxHide={true}
                                    defaultActiveKey={(navMenu===4) ? '1' : '0'}
                                    pointhtmlPassThrough={mapLegendAdd}
                                  />
                                </div>
                                </Map>
                                

                            </div>      
                    
                      </Row>
                    </div>                
                </Col>



                <Col id="GD-line-chart-season-container" className="column-container GD-line-chart-season-container GD-Col-3" 
                    style={(navMenu === 2 ||navMenu===10) ? {display: 'block'} : {display: 'none' }}>
                      <h5>Seasonal Impact on Peak Runoff</h5>
                      <div className={isFullScreenSeasonActive ? 'GD-expand-season-container GD-season-container-' : 'GD-season-container-'}>
                        <LoadingDataAnimation dataLoading={!isSeasonLoaded} />
                        <div className="GD-options-season">

                          <OverlayTrigger
                            placement="left"
                            rootClose
                            delay={{ show: 0, hide: 100 }}
                            overlay={renderFullScreenTooltip }
                          >
                            <Button   
                                onClick={e => {                                           
                                  setIsFullScreenSeasonActive(!isFullScreenSeasonActive)
                              }}>
                              <img src={fullscreenIcon} />
                            </Button> 
                          </OverlayTrigger>
                        </div>
                        <Row className="GD-line-chart-season-row">
                          {dataWinter && (                
                              <div className="GD-line-chart-season">
                                <Line options={configWinter }
                                  data={dataWinter}
                                  ref={chartRefWinter}
                                  className={'GD-Winter'}                            
                                />
                              </div>                   
                          )}
                          {dataSpring && (                       
                              <div className="GD-line-chart-season">
                                <Line options={configSpring }
                                  data={dataSpring}
                                  ref={chartRefSpring}  
                                  className={'GD-Spring'}                         
                                />
                              </div>                        
                          )}
                        </Row>
                        <Row className="GD-line-chart-season-row"  style={{marginTop: 40}}>
                         {dataSummer && (                       
                              <div className="GD-line-chart-season">
                                <Line options={configSummer}
                                  data={dataSummer}
                                  ref={chartRefPeak}     
                                  className={'GD-Summer'}                      
                                />
                              </div>                        
                          )} 
                           {dataFall && (                     
                              <div className="GD-line-chart-season">
                                <Line options={configFall }
                                  data={dataFall}
                                  ref={chartRefFall}  
                                  className={'GD-Fall'}                          
                                />
                              </div>                 
                          )}                
                      </Row>
                    </div>
                </Col>


                <Col  id="GD-line-flow" className="column-container GD-line-chart-season-container GD-Col-3"  
                   style={(navMenu === 3 || navMenu===10) ? {display: 'block'} : {display: 'none' }}>
                     
                        {dataFlow && (
                        <div className={isFullScreenFlowActive ? 'GD-expand-season-container chart-flow-container GD-flow-container' : 'chart-flow-container GD-flow-container'}>     
                          <div className="GD-options-season">                                              
                            <OverlayTrigger trigger="click" rootClose placement="left" overlay={popoverFlow}>
                              <Button className="GD-help-flow">
                                <FontAwesomeIcon icon={faQuestion}/>
                              </Button> 
                            </OverlayTrigger>                       

                            <OverlayTrigger
                              placement="left"
                              rootClose
                              delay={{ show: 0, hide: 100 }}
                              overlay={renderFullScreenTooltip }
                            >
                              <Button 
                                  onClick={e => {                                           
                                    setIsFullScreenFlowActive(!isFullScreenFlowActive)
                                }}>
                                <img src={fullscreenIcon} />
                              </Button> 
                            </OverlayTrigger>
                          </div>
                          <div className="GD-flow-line-chart">
                            <Scatter options={configFlow}
                              data={dataFlow}
                              ref={chartRefFlow}  
                              className={'GD-Flow'}                      
                            />
                          </div>  
                        </div>
                      )}                
                  </Col>


                  <Col className="GD-Col GD-Col-Right GD-Col-3"  
                    style={(navMenu === 1 ||navMenu===10) ? {display: 'block'} : {display: 'none' }}>

                    <div className={isFullScreenModelActive ? 'GD-expand-season-container GD-model-container' : 'GD-model-container'}>
                      <div className="GD-options-season">
                        <OverlayTrigger
                          placement="left"
                          rootClose
                          delay={{ show: 0, hide: 100 }}
                          overlay={renderFullScreenTooltip }
                        >
                          <Button 
                              onClick={e => {                                           
                                setIsFullScreenModelActive(!isFullScreenModelActive)
                            }}>
                            <img src={fullscreenIcon} />
                          </Button> 
                        </OverlayTrigger>

                      </div>
                      <LoadingDataAnimation dataLoading={!isDataReportLoaded}></LoadingDataAnimation>

                      {dataModelScenario && (               
                          <div className="model-line-chart">
                            <Line options={configModelScenario}
                              data={dataModelScenario}
                              ref={chartRefModelScenario}   
                              className={'GD-ModelScenario'}                     
                            />
                          </div>                  
                      )}
                      {dataScenario && (                    
                          <div className="model-line-chart">
                            <Line options={configScenario}
                              data={dataScenario}
                              ref={chartRefScenario}    
                              className={'GD-Scenario'}                     
                            />
                          </div>                  
                      )}
                      {dataModel && (                  
                          <div className="model-line-chart">
                            <Line options={configModel}
                              data={dataModel}
                              ref={chartRefModel}      
                              className={'GD-Model'}                   
                            />
                          </div>                  
                      )}
                      </div>

                  
                </Col>
              <ReportFooter />   
            </Col>       
          </Row>   
          
           </Row>
          </div>
        </Container>

      </FullScreenComp>
    </>
  );
}