import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import * as d3Scale from 'd3-scale';


export const setChartConfig = (category: any) => {
   
      const options = {
        responsive: true, 
        maintainAspectRatio: false,
        animation: true,    
        scales: {
          y: {  
            display: true,
            title: {
              display: true,
              text: 'Results',
              color: 'white'
            }, 
          },
          x: {         
            display: true,
            title: {
              display: true,
              text: 'Date',
              color: 'white'
            }, 
            type: 'time',
            color: 'white',
            time: {
              tooltipFormat: 'DD T'
            },
          },
        },  
        plugins: {
          legend: {
            position: "bottom" as const,
            labels: {
              usePointStyle: true,
            },
            maxHeight: 70,
          } as const,
          title: {
            display: true,
            text: "Results " + category,
            color: 'white',
            align: 'center',
            padding: 10,
            font: {
              size: 18
            }
          } as const, 
          tooltip: {
            padding: 10,
            bodyFont: {
              size: 24,
            },
            titleFont: {
              size: 24,
            },
            boxPadding: 8,
            usePointStyle: true,
            backgroundColor: '#12234f',
            callbacks: {            
              label: function (context: any) {
                let label = context.dataset.label || '';
                if (label) {
                  label = [
                    context.dataset.label, d3Format.format(',')(context.parsed.y),
                  ];
                }
                return label;
              },
            },
          } as const,
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: 'xy',
            },
          } as const,
        } as const,
      } as const;

      return options;
      //setConfig(options);
  }


export const setChartConfigLocations = (category: string) => {
      
      const options = {
        responsive: true, 
        maintainAspectRatio: false,
        animation: true,    
        scales: {
          y: {  
            display: true,
            title: {
              display: true,
              text: 'Results',
              color: 'white'
            }, 
          },
          x: {         
            display: true,
            title: {
              display: true,
              text: 'Locations',
              color: 'white'
            }, 
   
            color: 'white',

          },
        },  
        plugins: {
          legend: {
            position: "bottom" as const,
            color: 'white',
            labels: {
              usePointStyle: true,
              color: 'white',
            },
            maxHeight: 70,
          } as const,
          title: {
            display: true,
            text: "Locations " + category,
            color: 'white',
            align: 'center',
            padding: 10,
            font: {
              size: 18
            }
          } as const, 
          tooltip: {
            padding: 10,
            bodyFont: {
              size: 24,
            },
            titleFont: {
              size: 24,
            },
            boxPadding: 8,
            usePointStyle: true,
            backgroundColor: '#12234f',
            callbacks: {            
              label: function (context: any) {
                let label = context.dataset.label || '';
                if (label) {
                  label = [
                    context.dataset.label, d3Format.format(',')(context.parsed.y),
                  ];
                }
                return label;
              },
            },
          } as const,
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: 'xy',
            },
          } as const,
        } as const,
      } as const;

      return options;
}