import { DateTime } from 'luxon';
import localForage from "localforage";


export const getNewUserAlert = () => {    
    let nuacounter = localStorage.getItem("app_newuseralerta") || 1;
    if(nuacounter < 3){
        return true;
    } else {
        return false
    }
};

export const setNewUserAlert = () => {    
    let nuacounter = localStorage.getItem("app_newuseralerta");
    let newcounter = 1;
    if(nuacounter){
        newcounter = +nuacounter + 1;
    }
    localStorage.setItem("app_newuseralerta", newcounter.toString()) 
};

export const getStore = (store: string, callback: any) => {    
         localForage.getItem(store, function(err, value) {
            if(err){
              console.log(err)
            }
            if(value){
              //@ts-ignore
              callback(value)
            }
        })  
};

export const getLocalStore = (store: string, callback: any) => {    
		let storedValue = localStorage.getItem(store);
		if(storedValue){
			callback(storedValue);
		}   
};

export const setLocalStore = (store: string, data: any) => {    
		localStorage.setItem(store, data);
};
