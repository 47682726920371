import React from 'react'
import './loading-bar.scss'

const LoadingBar = ({ int }: any) => {
    return (
        <div className="outer-bar">
            <div style={{ width: `${int}%` }} className="inner-bar"></div>
        </div>
    )
}

export default LoadingBar