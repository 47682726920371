import React, { useEffect, useState, useContext } from 'react';
import { RefContext } from "../../../RefContext";
import { PointContext } from "../../../PointContext";
import { Layer, Source, Popup } from 'react-map-gl';
import { useNavigate } from 'react-router-dom';
import { FillLayer } from 'mapbox-gl';
import { Col, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { countryOutlines, countryFills, countries } from '.';
import { adjustHexBrightness } from "../../../features/utils";
import { ActivePopupContext } from '../../../ActivePopupContext';

const WiseLayer = ({ 
  id, 
  opacity, 
  tileSet, 
  sourceLayer, 
  beforeID, 
  action,
  brightness
}: any) => {
  
  const [popupData, setPopupData] = useState<any>();
  const { currentRef } = useContext<any>(RefContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);
  const ref = currentRef && currentRef?.current;

  const lngLat = action && action.lngLat;
  const navigate = useNavigate();

  const getFeaturesAtPoint = async (map: any, event: any) => {
    const features = await map.queryRenderedFeatures(event.point)?.[0]; 
    setPopupData(features.properties);
  };

  const handleClose = () => {
    setActivePopup({ id: null, content: null });
    setPopupData(undefined);
  };

  const goTo = (isoCode: string, country: string, lat: any, lng: any) => {
    setCurrentPoint({ country, isoCode, lat, lng });
    setTimeout(() => navigate('/WiseReport'), 300);
  };

  useEffect(() => {
    if (popupData && lngLat) {
      const { lat, lng }: any = lngLat;
      const { name_en, iso_3166_1 } = popupData;
      const popupElement = (
        <Popup
          longitude={lng}
          latitude={lat}
          onClose={handleClose}
          style={{ width: '150px' }}
        >
          <FontAwesomeIcon
            className="close-btn"
            icon={faXmark}
            onClick={handleClose}
          />
          <Col className='col-12 d-flex justify-center flex-column'>
            <Col style={{ marginBottom: '4px', justifyContent: 'center' }} className='col-12 d-flex justify-center'>
              Country: {name_en}
            </Col>
            <Col style={{ justifyContent: 'center' }} className='col-12 d-flex justify-center'>
              <Nav.Link style={{ color: "#4fc2d9", textDecoration: "underline" }} onClick={() => goTo(iso_3166_1, name_en, lat, lng)}>
                Go to Report
              </Nav.Link>
            </Col>
          </Col>
        </Popup>
      );
      setActivePopup({ id, content: popupElement });
    }
  }, [popupData, lngLat]);

  useEffect(() => { 
    if (ref) {
      ref.on('click', (e: any) => getFeaturesAtPoint(ref, e));
    }
  }, [ref]);

  const handleColors = (brightness: number) => {
    return countryFills.map((i: string) => {
      if (i.match(/#/)) {
        return adjustHexBrightness(i, brightness);
      } else {
        return i;
      }
    });
  };

  const WiseLayerFill: FillLayer = {
    id: `${id}-layer`,
    type: "fill",
    "source-layer": `${sourceLayer}`,
    paint: {
      "fill-opacity": opacity,
      "fill-outline-color": [
        "match",
        ["get", "name_en"], 
        ...countryOutlines,
        "transparent"
      ],
      'fill-color': [
        "match",
        ["get", "name_en"], 
        ...handleColors(brightness),
        "transparent"
      ],
    },
    filter: ['in', "name_en", ...countries]
  };

  return (
    <>
      <Source
        id={`${id}-layer`}
        type="vector"
        url={tileSet}
      >
        <Layer
          beforeId={beforeID}
          {...WiseLayerFill}
        />
      </Source>
      {activePopup && activePopup.id === id && activePopup.content}
    </>
  );
};

export default WiseLayer;
