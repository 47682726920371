import React, { useState, useEffect, useContext, useRef } from 'react';
import { Source, Layer, CircleLayer } from 'react-map-gl';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import {
  Map,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
} from 'react-map-gl';
import { PointContext } from "../../../PointContext";
import { RefContext } from '../../../RefContext';
import { Row, Col, Container, Image } from 'react-bootstrap';
import { putViewportIntoStorage } from '../../utils';
import settings from '../../../settings.json';
import RenderLayers from '../shared/RenderLayers';
import RenderLayerMarkers from '../shared/RenderLayerMarkers';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import LineChart from './LineChart';
import MapSlider from '../shared/MapSlider';
import MapLegend from '../shared/MapLegend';
import 'chartjs-plugin-zoom';
import './mec-report.scss';

const MECReport = ({ global, setDates, startDate, endDate }: any) => {

  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  
  const ref = useRef<any>();
  const reportBounds = ref.current ? ref.current.getMap().getBounds().toArray().flat() : null;
  const { currentPoint } = useContext<any>(PointContext);
  const { setCurrentRef } = useContext<any>(RefContext);  
  const [formattedData, setFormattedData] = useState<any>(null);
  const [filteredData, setFilteredData] = useState()

  useEffect(() => { setCurrentRef?.(ref) }, [ref]);
  useEffect(() => global.setUserLogged(true), []);

  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
          ref.current.flyTo({
            center: [-81.87937623, 26.6263850902],
            essential: true,
            zoom: 13.5,
          });
      }
    },300)
  }, []);

  const formatLabel = (input: number) => {
    const date = new Date(input * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    if (startDate && endDate && formattedData) {
      const { labels } = formattedData;
      let filteredDates: any[] = [];
      const start = new Date(startDate).getTime()/1000;
      const end = new Date(endDate).getTime()/1000;

      let dates = handleMECDates(labels);

      dates.forEach((date: any) => {
        if (date >= start && date <= end) {
          filteredDates.push(formatLabel(date));
        }
      });

      if (filteredDates.length > 0) {
        setFilteredData({
          ...formattedData,
          labels: filteredDates,
        });
      }
    }
  }, [
    startDate, 
    endDate, 
    formattedData
  ]);

  const formatData = (keys: any[], values: any[]) => {

    const labels = keys;
    const datasets = [{
      backgroundColor: '#FF66ED',
      borderColor: '#FF66ED',
      pointBackgroundColor: '#FF66ED',
      pointBorderColor: '#FF66ED',
      pointHoverRadius: 5,
      pointRadius: 4,
      tension: 0.1,
      data: values,
      hidden: false,
      label: 'Original Result Value', 
    }];

    return { labels, datasets }
  }

  const handleMECDates = (input:any[]) => {
    let dates: any[] = []
    input.forEach((date: string) => {
      dates.push(((new Date(date)).getTime())/1000)
    })
    return dates
  }

  useEffect(() => {
    if (ref.current) {
      const fetchData = () => {
        const map = ref.current.getMap();
      
        const getFeatures = async () => {
          const features = await map.queryRenderedFeatures(undefined, {
            layers: ['MEC-report-layer']
          });
  
          if (features.length > 0) {
            const { properties } = features[0];
            const keys = Object.keys(properties).filter((i:any) => i !== 'index');
            const dates = handleMECDates(keys)
            setDates(dates)
            const values = keys.map((i:any) => parseInt(properties[i]));
  
            setFormattedData(formatData(keys, values));
          } else {
            console.warn('No features found');
          }
        };
  
        if (map.loaded()) {
          getFeatures();
        } else {
          map.on('load', getFeatures);
        }
      };
  
      fetchData();
    }
  }, [ref.current]);
  
  useEffect(() => {
    global.setMEC(false);
    global.setMECReportMounted(true)
    return () => {
      global.setLoading(false);
      global.setMECReportMounted(false)
    };
  }, []);
  
  const CircleDataLayer: CircleLayer = {
    id: 'MEC-report-layer',
    type: "circle",
    "source-layer": `${"Manuels_E_coli_1-4mpr7n"}`,
    paint: {
      "circle-stroke-width": 2,
      "circle-radius": 9,
      "circle-opacity": 1,
      "circle-stroke-opacity": 1,
      "circle-color": '#FF66ED',
      "circle-stroke-color": 'white'
    }
  };

  return (
    <FullScreenComp handle={fullscreenhandle}>
      <Container className='mec-report-container'>
        <ReportHeader
          global={global}
          data={[currentPoint]}
          reportID={"MECReport"}
          fullScreenClickHandle={fullscreenhandle.enter}
        />
        <Row>
          <Col className='col-12 top d-flex justify-content-center align-items-center'>
            <Col className="mec-map-container col-6">
              <Map
                mapboxAccessToken={settings.maboxKey}
                mapStyle={global.mapStyle}
                ref={ref}
                onMove={(e) => {
                  putViewportIntoStorage({
                    longitude: e.viewState.longitude,
                    latitude: e.viewState.latitude,
                    zoom: e.viewState.zoom,
                  });
                  global.setViewport({
                    longitude: e.viewState.longitude,
                    latitude: e.viewState.latitude,
                    zoom: e.viewState.zoom,
                  });
                }}
              >
                {/* @ts-ignore */}
                <Source id={'MEC-report-layer'} type="vector" url={settings.tileSetURLs.MEC}>
                  <Layer {...CircleDataLayer} />
                </Source>
                <FullscreenControl />
                <NavigationControl />
                <GeolocateControl />
                <RenderLayerMarkers global={global} bounds={reportBounds} zipOff={true} />
                <RenderLayers global={global} mapRef={ref} />
                <MapLegend global={global} legendWidth={280} />
              </Map>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col className='col-12 bottom'>
            <Col className={`line-chart`}>
              {formattedData && (
                <LineChart
                  data={filteredData ? filteredData : formattedData}
                  title={'Manuels Branch E.coli Trends'} 
                />
              )}
            </Col>
          </Col>
        </Row>
        <ReportFooter />
      </Container>
      <MapSlider global={global} />
    </FullScreenComp>
  );
}

export default MECReport;
