import { useState, useEffect } from 'react'
import {
    Col,
    Row,
    Button,
    Form,
    ListGroup
} from 'react-bootstrap'
import FormErrorMessage from 'rsuite/esm/FormErrorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faMinusCircle,
    fa1,
    fa2,
    fa3,
    faPlusCircle,
    faXmarkCircle} from '@fortawesome/pro-solid-svg-icons';
import { getDomainValue } from '../lib/data';

const EditRoles = (props: any) => {

    const {
        global,
        setAction,
        data,
        title,
        orgData, 
        usersData
    } = props;

    const { active, description, id, name, sendToEPA } = data && data

    const handleExit = () => {
        setAction('table')
    }


    const handleSave = () => {
         setAction('table')
    }

    const handleDelete = () => {
/*        global.setLoading(true)
        setTimeout(() => {
            setAction('table')
            global.setLoading(false)
        }, 2000)*/
    }

    return (
         <>
            <Row className="org-edit-form">
                <h2>Edit Role</h2>
                <Row id="filter-box" style={{ marginRight: '0' }}>
                    <div style={{
                        marginBottom: '1rem',
                        marginTop: '1rem'
                    }} className="danger-box">
                        <span className="warning-icon">
                            i
                        </span>
                        <span>
                            This page allows application administration to edit a role and add users to a particular application role.
                        </span>
                    </div>
                    <div className="add-activity-box">
                        <Form>
                            <Row className="w-100">
                                <Col className="col-5">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Role Name</Form.Label>
                                        <Form.Control
                                            size="lg"
                                            type="text"
                                            placeholder="Admin"
                                        />
                                    </Form.Group>
                                    </Col>
                                <Col className="col-7">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Role Description</Form.Label>
                                        <Form.Control size="lg" type="text" />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Row>
            </Row>

            <Row className="user-box mt-2 edit-roll">
                <Col>
                    <Row>
                        <h4 style={{ fontSize: '18px' }}>Available Users</h4>
                    </Row>
                    <Row className='p-2'>
 {/*                       <ul className="user-list">
                            {usersData  && usersData.map((obj:any, index:any) =>
                                (<option key={'wm-users-options'+index} defaultValue={obj.username}>{obj.name}</option>)
                            )} 
                        </ul>*/}    
                            <ListGroup defaultActiveKey="#link1">
                                {usersData  && usersData.map((obj:any, index:any) =>
                                    (<ListGroup.Item  action key={'wm-users-options'+obj.username} eventKey={obj.username}>{obj.name}</ListGroup.Item>)
                                )} 
                            </ListGroup>

                    </Row>
                    <Row className='p-2'>
                      <div className='user-list-control-container'>

                        <div
                            style={{ color: '#18A0FB' }}
                            className="action-container"
                        >
                            <FontAwesomeIcon
                                style={{
                                    color: '#18A0FB',
                                    marginRight: '2px',
                                    cursor: 'pointer'

                                }}
                                icon={faPlusCircle}
                            />
                            Add User
                        </div>
                      </div>
                    </Row>

                </Col>
                <Col>
                    <Row>
                        <h4 style={{ fontSize: '18px' }}>Users in Role</h4>
                    </Row>
                    <Row className='p-2'>
                            <ListGroup defaultActiveKey="#link1">
                                {usersData  && usersData.map((obj:any, index:any) =>
                                    (<ListGroup.Item  action key={'wm-users-options'+obj.username} eventKey={obj.username}>{obj.name}</ListGroup.Item>)
                                )} 
                            </ListGroup>

                    </Row>
                    <Row>
                       <div
                            style={{ color: '#18A0FB' }}
                            className="action-container"
                            onClick={() => {
                                    handleExit()
                                }}
                        >
                            <FontAwesomeIcon
                                style={{
                                    color: '#18A0FB',
                                    marginRight: '2px',
                                    cursor: 'pointer'

                                }}
                                icon={faMinusCircle}
                            />
                            Remove User
                      </div>
                    </Row>
                </Col>
            </Row>
            <Row className="org-edit-form mt-3">
                <div className="footer-button-box">
                    <Button id="footer-btn" className="p-3 btn btn-primary m-2 cancel-btn" onClick={() => {
                                    handleExit()
                                }}>
                        Delete
                    </Button>
                    <Button id="footer-btn" className="p-3 btn btn-primary m-2 edit-btn" onClick={() => {
                                    handleExit()
                                }}>
                        Save
                    </Button>
                </div>
            </Row>
        </>
    )
}

export default EditRoles