import { createContext } from 'react';
import IAppContext from './IAppContext';

const defaultAppContextValue: IAppContext = {
  selectedHUC8: '14010001',
  currentLocation: 'Upper Colorado Region'
};

export const AppContext = createContext(defaultAppContextValue);
const AppContextProvider = AppContext.Provider;

export default AppContextProvider;
