import { useEffect, useState, useRef, useCallback, useContext, useMemo, Ref } from 'react';
import { Button, Card, OverlayTrigger } from 'react-bootstrap';
import { Tooltip as TooltipRB, Form } from 'react-bootstrap';
import settings from '../../../settings.json';
import './styles.scss';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import {
  Map,
  useMap,
  MapRef,
  Popup,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  useControl,
  Layer,
  Source,
} from 'react-map-gl';
import type { LayerProps } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Feature, GeoJsonProperties, Point, FeatureCollection } from 'geojson';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { HUC12InfoPoint } from '../../../types/HUC12InfoPoint';
import agent from '../../../api/agent';
import { parse } from 'papaparse';
import LoadingDataAnimation from '../../../components/LoadingDataAnimation';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import fullscreenIcon from '../../../assets/fullscreen-icon.svg';
import ellipsisIcon from '../../../assets/ellipsis-icon.svg';
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import { formatRowValue } from '../../utils';
import { AppContext } from '../../../AppContext';
import { CatalogsContext } from '../../../CatalogsProvider';
import { Location, useLocation } from 'react-router-dom';
import { message_hucrestriction, message_nodatalocation, message_noresult } from '../../../Constants';
import MapLegend from '../shared/MapLegend';
import MapSlider from '../shared/MapSlider';
import MapTitle from '../shared/MapTitle';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import ReportTableExpanded from '../shared/ReportTableExpanded';
import MapAddition from  '../shared/MapAddition';
import { flyToInitialLocationHandler } from '../../utils';
import { processStatusMessage, uniqueValues, fitBoundsMapData, groupByKey } from '../shared/report-data';
import { RefContext } from '../../../RefContext';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend);


export default function DischargeMonitoring(props: any) {
  const { global } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);
  const selectedHUC8Value = appContext.selectedHUC8; //'15050100';////'14010001'; //'03090205';
  const selectedCategory = global.filter1Selected || 'All';
  const selectedFilterDays = global.filterDaysSelected || 3;
  const selectedYear = global.selectedYearFilter || 2021;
  const selectedYears = global.yearsSelected || 2021;
  const selectedFilterLocations = global.filterLocationsSelected;

  const location: Location = useLocation();
  //@ts-ignore
  /*   const routeData = {
      name: "Discharge Monitoring Report",
      parentReportName: "Surface Water",
      root: "/DischargeMonitoring",
    } */

  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const [isFullScreenActive, setFullScreenActive] = useState(false);
  const [isFullScreenTableActive, setFullScreenTableActive] = useState(false);
  const fullscreenContainerHandler = () => {
    setFullScreenActive(!isFullScreenActive);
  };
  const fullscreenTableContainerHandler = () => {
    setFullScreenTableActive(!isFullScreenTableActive);
  };
  const [isDataReportLoaded, setIsDataReportLoaded] = useState(false);

  const [reportData, setReportData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [chartData, setChartData] = useState<any>(null);

  const [featureData, setFeatureData] = useState<FeatureCollection>();

  const [mapLegendData, setMapLegendData] = useState<any[]>([]);
  const [circleColorStyle, setCircleColorStyle] = useState<any>([]);
  const [categoryExtent, setCategoryExtent] = useState<any>([0, 10]);
  const [mapLegendIntegerScale, setMapLegendIntegerScale] = useState<any>([0, 3, 7, 10]);
  const [layerStyle, setLayerStyle] = useState<any>([]);
  const [hoverLayerStyle, setHoverLayerStyle] = useState<any>([]);
  const [sicCodeScale, setSicCodeScale] = useState<any>([]);

  const { current: map } = useMap();
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [cursor, setCursor] = useState<string>('auto');
  const [mapLoaded, setMapLoaded] = useState(false);
  const mapRef = useRef<MapRef>();
  const [huc12Members, setHuc12Members] = useState<any[]>([]);
  const [huc12InfoCatalogArray, setHuc12InfoCatalogDataArray] = useState<any[]>([]);
  const locationField = 'dmrid';
  const [mapHoverFilter, setMapHoverFilter] = useState<any>(['in', ['get', locationField], '']);
  const [userSelectedLocation, setUserSelectedLocation] = useState<any>();
  const [userSelectedLocations, setUserSelectedLocations] = useState<any>([]);
  const [usLocations, setUsLocations] = useState<any>([]);
  const [statusMessage, setStatusMessage] = useState<any>(null);
  const mapLegendColorScale = ['#1a9850', '#a6d96a', '#fee08b', '#d73027'];


  useEffect(() => {
    global.setfilter1Selected('All');
    if (global.trackerOn) global.setTrackerOn(false)
  }, []);


  const { setCurrentRef } = useContext(RefContext);
  useEffect(() => { setCurrentRef?.(mapRef) }, [mapRef])

  useEffect(() => {
    const huc12InfoFileURL = '/huc12_info.csv';
    fetch(huc12InfoFileURL)
      .then(response => response.text())
      .then(responseText => {
        const data: HUC12InfoPoint[] | any[] = parse(responseText, {
          header: true,
        }).data;
        setHuc12InfoCatalogDataArray(data);
      });
  }, []);

  useEffect(() => {
    global.setUserLogged(true);
  }, []);

  useEffect(() => {
    setFilteredData([]);
    setReportData([]);
    setUserSelectedLocations([]);
  }, [selectedHUC8Value]);

  useEffect(() => {
    let filterData = [...filteredData];
    if (
      userSelectedLocations &&
      userSelectedLocations.length > 0 &&
      userSelectedLocations.indexOf('All') < 0
    ) {
      filterData = filterData.filter((o: any) => userSelectedLocations.indexOf(o[locationField]) > -1);
      setFilteredData(filterData);
    } else {
      filterReportData();
    }
  }, [userSelectedLocations]);

  useEffect(() => {
    filterReportData();
  }, [selectedFilterLocations, selectedCategory, selectedYears]);

  useEffect(() => {
    const featureCollectionFromReportData = featureCollection(filteredData);
    setFeatureData(featureCollectionFromReportData);
    if (featureCollectionFromReportData.features.length > 0) {
      if (mapRef && mapRef.current) {
        const mapRef_ = mapRef;
        setTimeout(() => {
          fitBoundsMapData(featureCollectionFromReportData, mapRef_);
        }, 400);
      }
    }
  }, [filteredData]);

  useEffect(() => {
    if (statusMessage) {
      global.notify(statusMessage)
    }
  }, [statusMessage]);

  useEffect(() => {
    setStatusMessage('');

    if (selectedHUC8Value && global.hucRestrict.length > 0 && (global.hucRestrict.indexOf(selectedHUC8Value) < 0)) {
      setIsDataReportLoaded(true);
      return global.notify(message_hucrestriction)
    }
    setIsDataReportLoaded(false);
    agent.Reports.DischargeMonitoring(selectedHUC8Value).then((res: any) => {
      flyToInitialLocationHandler(selectedHUC8Value, mapRef, catalogsContext.huc8Catalog);
      const returnStatus = processStatusMessage(res);
      setStatusMessage(returnStatus.statusMessage);
      if (returnStatus.Abort) {
        setIsDataReportLoaded(true);
        return;
      }
      let returnedData = [...res.body];
      if (!returnedData || !returnedData.length || returnedData.length < 1) {
        setIsDataReportLoaded(true);
        setStatusMessage(message_noresult);
        return;
      }

      let grouped_data = d3Array.groups(returnedData,
        d => d['Pollutant'], d => d['Frs Id']);
      let maxPollutant = '';
      let maxLocationsNumber = 0;
      for (let index = 0; index < grouped_data.length; index++) {
        if (grouped_data[index][1].length > maxLocationsNumber) {
          maxPollutant = grouped_data[index][0];
          maxLocationsNumber = grouped_data[index][1].length;
        }
      }

      const yearLabels = returnedData
        .map((item: any) => item.Year)
        .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
        .filter((value: any, index: any, self: any) => value && value !== 'Invalid date')
        .sort();
      global.setSelectedYearFilterOptions(['All', ...yearLabels]);

      const yearExtent = d3Array.extent<any, any>(returnedData, d => d['Year']);

      global.setYearsSelected([yearExtent[1].toString()]);

      const filter1Labels = uniqueValues(returnedData, 'Pollutant');
      global.setfilter1Populated(['All', ...filter1Labels]);
      global.setfilter1Selected(filter1Labels[1]);

      const filteredData = returnedData
        .filter((o: any) => o['Year'] === yearExtent[1] && o['Pollutant'] === maxPollutant)
        .sort(
          (x: any, y: any) =>
            x['Frs Id'] - y['Frs Id'] || x['Pollutant'].localeCompare(y['Pollutant'])
        );

      let siccolor = '#CCCCCC';
      for (let dataItem of returnedData) {
        dataItem.dmrid = dataItem['Frs Id'] + dataItem.Lag;
        dataItem.Latitude = dataItem.Lag;
        dataItem.Longitude = dataItem.Lng;
        dataItem.centroid_longitude = dataItem.Lng;
        dataItem.centroid_latitude = dataItem.Lag;
        dataItem.link = dataItem['Npdes Permit Number'] + '&sys=ICP';
        dataItem['Design Flow Number (Mgd)'] = dataItem['Total Pounds (Lb/Year)'];
        dataItem['Design'] = 7 + Math.min(dataItem['Total Twpe (Lb/Eq Year)'], 7);
        dataItem['Design_Color'] = dataItem['Average Daily Flow Mgd'];
      }

      let newCategoryExtent = categoryExtent;
      if (returnedData.length > 0) {
        newCategoryExtent = d3Array.extent<any, any>(
          returnedData,
          d => d['Average Daily Flow Mgd']
        );
        let newMapLegendIntegerScale = [
          d3Format.format('.4')(newCategoryExtent[0]),
          d3Format.format('.4')(newCategoryExtent[1] * 0.3),
          d3Format.format('.4')(newCategoryExtent[1] * 0.7),
          d3Format.format('.4')(newCategoryExtent[1]),
        ];
        if (newCategoryExtent[1] > 1000) {
          newMapLegendIntegerScale = [
            d3Format.format(',.3r')(newCategoryExtent[0]),
            d3Format.format(',.3r')(newCategoryExtent[1] * 0.3),
            d3Format.format(',.3r')(newCategoryExtent[1] * 0.7),
            d3Format.format(',.3r')(newCategoryExtent[1]),
          ];
        }
        setMapLegendIntegerScale(newMapLegendIntegerScale);
        setMapLegendData(
          mapLegendColorScale.map((x: any, index: number) => ({
            typecode: newMapLegendIntegerScale[index],
            color: mapLegendColorScale[index],
          }))
        );
        setCategoryExtent(newCategoryExtent[1] === 0 ? [0, 1] : newCategoryExtent);
      }
      setFilteredData(filteredData);
      setReportData(returnedData);
      setIsDataReportLoaded(true);
      //updateLayerStyle(null);
    });
  }, [selectedHUC8Value]);

  useEffect(() => {
    updateLayerStyle(null);
    updateHoverLayerStyle();
  }, [selectedCategory, isDataReportLoaded]);

  const filterReportData = () => {
    let filterData = [...reportData];
    if (selectedYears && selectedYears.length > 0 && selectedYears.indexOf('All') < 0) {
      filterData = filterData.filter((o: any) => selectedYears.indexOf(o['Year'].toString()) > -1);
    }
    if (selectedCategory && selectedCategory.length > 0 && selectedCategory.indexOf('All') < 0) {
      filterData = filterData.filter((o: any) => selectedCategory.indexOf(o['Pollutant']) > -1);
    }

    filterData
      .slice(0, 3000)
      .sort(
        (x: any, y: any) =>
          x['Frs Id'] - y['Frs Id'] || x['Pollutant'].localeCompare(y['Pollutant'])
      );
    setFilteredData(filterData);
  };

  const featureCollection = (featureData: any) => {
    const features: Array<Feature<Point, GeoJsonProperties>> = [];
    const addedDMR: any[] = [];
    const addedFrsId: any[] = [];
    let reportDate = Date.now();
    const dateSubset_ = featureData;
    for (let dataItem of dateSubset_) {
      if (dataItem) {
        const featureWithPoint: Feature<Point> = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [dataItem.centroid_longitude, dataItem.centroid_latitude],
          },
          properties: dataItem,
        };

        if (addedDMR.indexOf(dataItem[locationField]) < 0 && addedFrsId.indexOf(dataItem['Frs Id']) < 0) {
          features.push(featureWithPoint);
          addedDMR.push(dataItem[locationField]);
          addedFrsId.push(dataItem['Frs Id']);
        }
      }
    }

    const featureCollectionFromReportData: FeatureCollection = {
      type: 'FeatureCollection',
      features: features,
    };
    setFeatureData(featureCollectionFromReportData);
    return featureCollectionFromReportData;
  };

  const fitMapData = (reportData: any) => {
    const featureCollectionFromReportData = {
      type: 'FeatureCollection',
      features: [] as any,
    };
    for (let dataItem of reportData) {
      const feature = {
        type: 'Feature',
        properties: dataItem,
        geometry: {
          type: 'Point',
          coordinates: [dataItem.centroid_longitude, dataItem.centroid_latitude],
        },
      };
      feature.properties.HUC12 = dataItem.huc12;
      feature.properties.HUC8 = selectedHUC8Value;
      featureCollectionFromReportData.features.push(feature);
    }

    if (featureCollectionFromReportData.features.length > 0) {
      if (mapRef && mapRef.current) {
        fitBoundsMapData(featureCollectionFromReportData, mapRef);
      }
    }
  };

  const updateLayerStyle = (newCategoryExtent: any) => {
    const layerStyle_: LayerProps = {
      id: 'pointlayer',
      type: 'circle' as const,
      paint: {
        'circle-radius': ['get', 'Design'],
        'circle-color': [
          'interpolate',
          ['linear'],
          ['get', 'Design_Color'],
          0,
          '#1a9850',
          0.5,
          '#d9ef8b',
          1,
          '#ffffbf',
          1.5,
          '#fee08b',
          2,
          '#fdae61',
          3,
          '#f46d43',
          5,
          '#d73027',
        ],
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1,
        'circle-opacity': 1,
      },
    };
    setLayerStyle(layerStyle_);
  };


  const updateHoverLayerStyle = () => {
    const hoverStyle: LayerProps = {
      id: 'hoverlayer',
      type: 'circle' as const,
      paint: {
        'circle-radius': 12,
        'circle-color': '#faebd7',
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1,
        'circle-opacity': 0.5,
      },
    };

    setHoverLayerStyle(hoverStyle);
  }


  const tableColumns = useMemo(
    () => [
      { Header: 'Year', accessor: 'Year' },
      { Header: 'FRS ID', accessor: 'Frs Id' },
      { Header: 'Facility', accessor: 'Facility' },
      {
        Header: 'City',
        accessor: 'City',
      },
      {
        Header: 'County',
        accessor: 'County',
      },
      {
        Header: 'State',
        accessor: 'State',
      },
      {
        Header: 'SIC',
        accessor: 'Sic ',
      },
      {
        Header: 'NPDES Permit No.',
        accessor: 'Npdes Permit Number',
      },
      {
        Header: 'Major/Non-Major Status',
        accessor: 'Major/Non-Major Status',
      },
      {
        Header: 'Pollutant',
        accessor: 'Pollutant',
      },
      {
        Header: 'Average Daily Flow (mgd)',
        accessor: 'Average Daily Flow Mgd',
        Cell: (props: any) => d3Format.format('.4r')(+formatRowValue(props.value)),
      },
      {
        Header: 'Total Pounds (lb/yr)',
        accessor: 'Total Pounds (Lb/Year)',
        Cell: (props: any) => d3Format.format(',.4r')(+formatRowValue(props.value)),
      },
      {
        Header: 'Total TWP (lb eqyr)',
        accessor: 'Total Twpe (Lb/Eq Year)',
        Cell: (props: any) => d3Format.format('.4r')(+props.value),
      },
      {
        Header: 'Watershed Name',
        accessor: 'Watershed Name',
      },
      {
        Header: 'Detailed Facility Report (Dfr)',
        accessor: 'link',
        Cell: (props: any) => (
          <a
            href={'https://echo.epa.gov/detailed-facility-report?fid=' + props.value}
            target='_blank'
          >
            DFR link
          </a>
        ),
      },
    ],
    []
  );

  const labelForValue = (labelvalue: any, index: number) => {
    return labelvalue;
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: 'Average Daily Flow (mgd)',
          color: 'white',
        },
        max: 8,
      },
      x: {
        display: true,
        title: {
          display: false,
          text: 'Facility Name',
          color: 'white',
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
          color: 'white',
        },
        padding: 10,
      } as const,
      title: {
        display: true,
        text: 'Average Daily Flow (mgd) by Facility',
        color: 'white',
        align: 'center',
        padding: 10,
      } as const,
    } as const,
  } as const;

  const labels = ['Facility'];
  const data = {
    labels,
    datasets: [
      {
        label: 'Facility',
        data: [1],
        backgroundColor: '#18244c',
      },
    ],
  };

  useEffect(() => {
    setChartData(data);
  }, []);

  const hoverRowHandler = (event: any) => {
    const chartlabels = [' '];
    const chartdatasets = [
      {
        label: event.Facility,
        data: [event['Average Daily Flow Mgd']],
        backgroundColor: '#0b61bf',
      },
    ];
    setUserSelectedLocation(event[locationField]);
    setMapHoverFilter(['in', ['get', locationField], event[locationField]]);
    setChartData({ labels: chartlabels, datasets: chartdatasets });
    setPopupInfo(event);
  };

  const clickRowHandler = (event: any) => {
    if (userSelectedLocations.indexOf(event[locationField]) > -1) {
      setUserSelectedLocations([]);
      setPopupInfo(null);
    } else {
      setUserSelectedLocations([event[locationField]]);
      setMapHoverFilter(['in', ['get', locationField], event[locationField]]);
      setPopupInfo(event);
      if (mapRef.current) {
        if (event && event.centroid_longitude) {
          mapRef.current?.flyTo({
            center: [parseFloat(event.centroid_longitude), parseFloat(event.centroid_latitude)],
          });
        }
      }
    }
  };

  const clickCardHandler = (event: any) => {
    /*     if(userSelectedLocation === event[locationField]){
     setUserSelectedLocation(null)
   } else {
     setUserSelectedLocation(event[locationField])
   } */
  };

  const onMouseEnter = useCallback((event: any | null) => {
    if (event.features && event.features[0] && event.features[0].properties) {
      setCursor('pointer');
      setPopupInfo(event.features[0].properties);
      setUserSelectedLocation(event.features[0].properties[locationField]);
      setMapHoverFilter(['in', ['get', locationField], event.features[0].properties[locationField]]);
    }
  }, []);

  const onMouseLeave = useCallback((event: any | null) => {
    setCursor('');
    if (userSelectedLocation) {
      setUserSelectedLocation(null);
    }
    if (event && event.features && event.features[0]) {
      setPopupInfo(null);
      setMapHoverFilter(['in', ['get', locationField], '']);
    }
  }, []);

  useEffect(() => {
    if (usLocations.length > 0) {
      if (userSelectedLocations.indexOf(usLocations[0][locationField]) > -1) {
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', locationField], '']);
      } else {
        setUserSelectedLocations(usLocations[0][locationField]);
        setPopupInfo(usLocations[0]);
        setTimeout(() => {
          setMapHoverFilter(['in', ['get', locationField], usLocations[0][locationField]]);
        }, 111);
      }
    }
  }, [usLocations]);

  const onHandleClick = useCallback((event: any | null) => {
    const feature = event.features && event.features[0];
    if (feature) {

      setUsLocations([feature.properties]);

    }
  }, []);

  const onMapLoad = useCallback(() => {
    if (mapRef && mapRef.current) {
      setMapLoaded(true);

      mapRef.current.resize();
    }
  }, []);

  const pointInPolygonUpdate = (pointsSelected: any) => {
        setUserSelectedLocations(pointsSelected);
        setMapHoverFilter(['in', ['get', locationField], ['literal', pointsSelected]]);
  }


  const onDelete = useCallback((e: any | null) => {
    if (mapRef && mapRef.current) {
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', locationField], '']);
    }
  }, []);


  const onHoverOut = useCallback((e: any | null) => {
    setPopupInfo(null);
  }, []);

  const deselectFilters = useCallback((e: any | null) => {
    setPopupInfo(null);
    setMapHoverFilter(['in', ['get', locationField], '']);
    setUserSelectedLocations([]);
  }, []);

  const renderPopup = () => {
    return (
      <Popup
        longitude={Number(popupInfo.centroid_longitude)}
        latitude={Number(popupInfo.centroid_latitude)}
        onClose={() => setPopupInfo(null)}
      >
        <div className='popup-container'>
          <h4>{popupInfo['hucname']}</h4>
          <div className='popup-content'>
            <div className='popup-content-left'>
              <p className='popup-content-right-triple'>Facility</p>
              <p>SIC Code </p>
              <p>NPDES Permit ID</p>
              <p className='popup-content-right-double'>Major or Non-Major Status</p>
              <p className='popup-content-right-double'>Average Daily Flow [MGD]</p>
            </div>
            <div className='popup-content-right'>
              <p className='popup-content-right-triple popup-content-highlight'>{popupInfo['Facility']}</p>
              <p>{popupInfo['Sic'] || ' none'}</p>
              <p>{popupInfo['Npdes Permit Number']}</p>
              <p className='popup-content-right-double'>{popupInfo['Major/Non-Major Status']}</p>
              <p className='popup-content-right-double'>
                {d3Format.format('.5r')(popupInfo['Average Daily Flow Mgd'])}
              </p>
            </div>
          </div>
        </div>
      </Popup>
    );
  };

  const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  return (
    <>
      <FullScreenComp handle={fullscreenhandle}>
        <div className='DischargeMonitoring' id='DischargeMonitoring-report'>
          <ReportHeader global={global} data={filteredData} mapRef={mapRef}
            reportID={"DischargeMonitoring-report"} fullScreenClickHandle={fullscreenhandle.enter} />

          <div className='container'>
            <div className='col main-col'>
              <div className='row gx-0'>
                <div
                  className={
                    isFullScreenActive
                      ? 'summary-container expand-container-summary'
                      : 'summary-container'
                  }
                >
                  <div className='summary-title'>
                    <span>DMR Summary</span>
                    <Button onClick={fullscreenContainerHandler}>
                      <img src={fullscreenIcon} />
                    </Button>
                  </div>

                  {filteredData &&
                    filteredData.map((variant: any, index: number) => (
                      <Card
                        key={index + Math.random() * 5}
                        id={variant[locationField] + '_' + index}
                        text='light'
                        className={'mb-2 te-primary'}
                        onClick={clickCardHandler}
                      >
                        <Card.Body>
                          <Card.Title>
                            <p>{variant['Major/Non-Major Status']}</p>
                            <p className='summary-field-name'>Major/Non-Major Status</p>
                            <p>{d3Format.format('.4r')(variant['Total Pounds (Lb/Year)'])}</p>
                            <p className='summary-field-name'>Total Pounds (lb/yr)</p>
                            <p className='summary-field-double'>{variant['Pollutant']}</p>
                            <p className='summary-field-name'>Pollutant</p>
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    ))}
                </div>

                <div className='map-best-container'>
                  <LoadingDataAnimation dataLoading={!isDataReportLoaded} />
                  <Map
                    id='DischargeMonitoringReport'
                    mapboxAccessToken={settings.maboxKey}
                    mapStyle={global.mapStyle}
                    onLoad={onMapLoad}
                    onClick={(e) => {
                      global.onMapClick(e)
                      onHandleClick(e)
                    }}
                    onMoveEnd={(e) => {
                      global.setViewport({
                        longitude: e.viewState.longitude,
                        latitude: e.viewState.latitude,
                        zoom: e.viewState.zoom,
                      })
                    }
                    }
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    preserveDrawingBuffer={true}
                    interactiveLayerIds={['pointlayer']}
                    cursor={cursor}
                    ref={mapRef as Ref<MapRef>}
                    projection={global.globeView ? 'globe' : 'mercator' as any}
                  >
                    {featureData && (
                      <Source id='circlesource' type='geojson' data={featureData}>
                        <Layer {...layerStyle} />
                        <Layer {...hoverLayerStyle} filter={mapHoverFilter} />
                      </Source>
                    )}
  
                    <MapAddition global={global} 
                                mapRef={mapRef}
                                onDelete={onDelete}
                                PointInPolygonField={locationField}
                                PointInPolygonUpdate={pointInPolygonUpdate}
                                position={'low'}
                                zipOff={true}
                                statusMessage={statusMessage}/>

                    {popupInfo && renderPopup()}


                    <div className='map-legend-container' onClick={deselectFilters}>
                      <MapLegend
                        mapLegendData={mapLegendData}
                        title={'Average Daily Flow (mgd)'}
                        global={global}
                      />
                    </div>
                  </Map>
                </div>
              </div>

              <OverlayTrigger
                placement='top-start'
                rootClose={true}
                overlay={
                  <TooltipRB className='dmr-chart-tooltip'>
                    <div className='dmr-chart-container'>
                      <Bar options={options} data={chartData} />
                    </div>
                  </TooltipRB>
                }
              >
                <div
                  className={
                    isFullScreenTableActive
                      ? 'expand-container row gx-0 table-chart-row'
                      : 'row gx-0 table-chart-row'
                  }
                  onMouseLeave={onHoverOut}
                >
                  <div className='report-options'>
                    <div className='report-options-button-container'>
                      <Button onClick={fullscreenTableContainerHandler}>
                        <img src={fullscreenIcon} />
                      </Button>
                    </div>
                  </div>
                  <ReportTableExpanded
                    data={filteredData}
                    columns={tableColumns}
                    clickLoadStop={true}
                    clickRowHandler={clickRowHandler}
                    hoverRowHandler={hoverRowHandler}
                  />
                </div>
              </OverlayTrigger>
              <ReportFooter />
            </div>
          </div>
        </div>
        <MapSlider global={global} />
      </FullScreenComp>
    </>
  );
}

type DischargeMonitoringModel = {
  date?: number;
  huc12?: string;
};
