import React from 'react'
import { Line } from 'react-chartjs-2';

const LineChart = ({
    data,
    title,
  }: any) => {

  const options: any = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: 'parts per million',
          color: 'white',
          font: {
            size: 16,
          },
        },
        zoom: {
          enabled: true,
          mode: 'x',
        },
        ticks: {
          color: 'white',
        },
      },
      x: {
        display: true,
        title: {
          display: true,
          text: 'Date',
          color: 'white',
          font: {
            size: 16,
          },
        },
        ticks: {
          color: 'white',
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        color: 'white',
        text: title,
        font: {
          size: 18,
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default LineChart;