export class ReportMenuItem {
  name: string;
  root: string;
  parentReportName?: string;
  disabled?: boolean;
  items?: Array<ReportMenuItem>;
  ident?: boolean;
  nosortitems?: boolean;

  constructor(name: string, root: string, disabled?: boolean, items?: Array<ReportMenuItem>, ident?: boolean, nosortitems?: boolean) {
    this.name = name;
    this.root = root;
    this.disabled = disabled;
    this.items = items;
    this.ident = ident;
    this.nosortitems = nosortitems;
  }
}

export class ReportMenuGroup {
  name: string;
  dkey: string;
  items: Array<ReportMenuItem>;
  nosortitems?: boolean;

  constructor(name: string, dkey: string, items: Array<ReportMenuItem>, nosortitems?: boolean) {
    this.name = name;
    this.dkey = dkey;
    this.nosortitems = nosortitems;

    this.items = items.map((item) => {
      item.parentReportName = name;
      return item;
    });
  }
}

export class ReportMenuGroups {
  items: Array<ReportMenuGroup>;

  constructor(items: Array<ReportMenuGroup>) {
    this.items = items;
  }
}
