import React, { useState, useEffect, useContext, useCallback, useMemo, memo } from 'react';
import { parse } from 'papaparse';
import { LineLayer, FillLayer, Source, Layer } from 'react-map-gl';
import { RefContext } from '../../RefContext';
import HucPopup from '../../components/HucPopup';
import agent from '../../api/agent';
import { adjustHexBrightness } from '../../features/utils';
import './huc-layer.scss';

const HucLayer = ({ global, opacity, brightness, action }: any) => {
  const { currentRef } = useContext(RefContext);
  const { pathname } = window.location;

  // @ts-ignore
  const mapInstance = currentRef?.current;

  const [huc, setHuc] = useState<string>('');
  const [hucCatalog, setHucCatalog] = useState<{ huc8: any[]; huc12: any[] }>({
    huc8: [],
    huc12: []
  });
  const [hucData, setHucData] = useState<any>();

  // Reusable function for fetching and parsing CSV data
  const fetchHucCatalog = useCallback(async (url: string, type: 'huc8' | 'huc12') => {
    try {
      const response = await fetch(url);
      const text = await response.text();
      const catalog = parse(text, { header: true }).data;
      setHucCatalog((prev) => ({ ...prev, [type]: catalog }));
    } catch (error) {
      console.error(`Error fetching ${type} data:`, error);
    }
  }, []);

  useEffect(() => {
    fetchHucCatalog('/huc8s_info.csv', 'huc8');
    fetchHucCatalog('/huc12_info.csv', 'huc12');
  }, [fetchHucCatalog]);

  const findHucData = useCallback(
    (input: string, type: 'huc8' | 'huc12') => {
      const catalog = hucCatalog[type];
      const findCondition = type === 'huc12' ? (item: any) => `0${item.huc12}` === input : (item: any) => item.huc8 === input;
      const data = catalog.find(findCondition);
      setHucData(data);
    },
    [hucCatalog]
  );

  useEffect(() => {
    if (action?.lngLat?.lat && action?.lngLat?.lng) {
      const { lat, lng } = action.lngLat;

      if (global.viewport.zoom >= 0.38 && global.viewport.zoom < 8.5) {
        agent.Utilities.LatLngToHuc8(lat, lng).then(({ body }: any) => {
          const res = body?.[0]?.Huc8;
          if (res) {
            findHucData(res, 'huc8');
            setHuc(res);
            if (pathname !== '/') global.setSelectedHUC8(res);
          }
        });
      } else if (global.viewport.zoom >= 8.5) {
        agent.Utilities.LatLngToHuc12(lat, lng).then(({ body }: any) => {
          const res = body?.[0]?.Huc8;
          findHucData(res, 'huc12');
          setHuc(res);
        });
      }
    }
  }, [action, findHucData, global, pathname]);

  const huc8sLineLayer: LineLayer = useMemo(() => ({
    id: 'huc8-line',
    'source-layer': 'True Elements - huc8s',
    type: 'line',
    paint: {
      'line-opacity': opacity,
      'line-color': '#ffffff',
      'line-width': 2,
    },
    minzoom: 0.38,
  }), [opacity]);

  const huc12sLineLayer: LineLayer = useMemo(() => ({
    id: 'huc12-line',
    'source-layer': 'True Elements - HUC 12s',
    type: 'line',
    paint: {
      'line-opacity': opacity,
      'line-color': '#000000',
      'line-width': 2,
    },
    minzoom: 8.5,
  }), [opacity]);

  const huc12sFillLayer: FillLayer = useMemo(() => ({
    id: 'tile-hover-12',
    source: 'True Elements - HUC 12s',
    'source-layer': 'True Elements - HUC 12s',
    type: 'fill',
    filter: ['==', 'HUC12', huc],
    paint: {
      'fill-color': adjustHexBrightness('#BD1C1C', brightness),
      'fill-opacity': opacity,
      'fill-outline-color': adjustHexBrightness('#BD1C1C', brightness),
    },
  }), [huc, opacity, brightness]);

  const huc8FillLayer: FillLayer = useMemo(() => ({
    id: 'tile-hover-8',
    source: 'True Elements - huc8s',
    'source-layer': 'True Elements - huc8s',
    type: 'fill',
    maxzoom: 8.5,
    filter: ['==', 'HUC8', huc],
    paint: {
      'fill-color': adjustHexBrightness('#008080', brightness),
      'fill-opacity': opacity,
      'fill-outline-color': adjustHexBrightness('#008080', brightness),
    },
  }), [huc, opacity, brightness]);

  return (
    <>
      <Source id="huc8-line" type="vector" url="mapbox://trueelementsmapping.huc8s">
        <Layer {...huc8sLineLayer} />
      </Source>
      <Source id="huc12-line" type="vector" url="mapbox://trueelementsmapping.huc12s">
        <Layer beforeId="huc8-line" {...huc12sLineLayer} />
      </Source>
      <Source type="vector" url="mapbox://trueelementsmapping.huc8s" id="tile-hover-8">
        <Layer {...huc8FillLayer} />
      </Source>
      <Source type="vector" url="mapbox://trueelementsmapping.huc12s" id="tile-hover-12">
        <Layer {...huc12sFillLayer} />
      </Source>
      {hucData && <HucPopup global={global} data={hucData} />}
    </>
  );
};

export default memo(HucLayer);
