import { useEffect, useState, useContext } from 'react';
import { Popup } from 'react-map-gl';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';
import { AppContext } from '../../AppContext'



import '../render-popup.scss'
import agent from '../../api/agent'

const RenderPopupDW = ({ current, setCurrent, global }: any) => {

    const [open, setOpen] = useState(false)
    const [reveal, setReveal] = useState(false)
    const [posX, setPosX] = useState()
    const [posY, setPosY] = useState()

    const { updateContext } = useContext(AppContext)

    const formattedDate = date.format(new Date((current['Date'])), 'MMMM DD YYYY')

    const goTo = useNavigate()

    const setReport = (report: any, navigate: any) => {
        global.setLoading(true)

        const huc8 = agent.Utilities.LatLngToHuc8(current.Lat, current.Lng)
        const location = agent.Utilities.LatLngToCity(current.Lat, current.Lng)

        return Promise.all([huc8, location]).then(data => {
            if (data) {
                // @ts-ignore
                const huc8: any = data[0]?.body[0].Huc8
                // @ts-ignore
                const location = data[1].features[0].place_name

                return updateContext?.(huc8, location, report, 'United States')
            }
        }).then(() => {
            global.setLoading(false)
            goTo(navigate)
            global.setSurfacewaterTrueQI(false)
        })

    }

    return (
        <div className="popup-wrapper">
            <Popup
                style={{ position: 'relative' }}
                className={'sc-popup-main'}
                longitude={current
                    .Lng}
                latitude={current.Lat}
                anchor="bottom"
                onClose={() => setCurrent(null)}
            >
                <div id="dw-popup">
                    <div className="popup-card sw" onClick={() => setOpen(false)}>
                        <h3><strong>Date: {formattedDate}</strong></h3>
                        <h3><strong>Huc12: {current['Huc12']}</strong></h3>
                        <h3><strong>Lat: {current['Lat']}</strong></h3>
                        <h3><strong>Lng: {current['Lng']}</strong></h3>
                        <h3><strong>Wastewater Sector Score: {current['Wastewater Sector Score']}</strong></h3>
                        <ul className="popup-report-list">
                            <li
                                onClick={() => setReport('Discharge Monitoring Report', '/DischargeMonitoring')}
                            >
                                Go to report
                            </li>
                        </ul>
                        <h3><strong>Industry Sector Score: {current['Industry Sector Score']}</strong></h3>
                        <ul className="popup-report-list">
                            <li
                                onClick={() => setReport('Discharge Monitoring Report', '/DischargeMonitoring')}
                            >
                                Go to report
                            </li>
                        </ul>
                        <h3><strong>Ag Sector Score: {current['Ag Sector Score']}</strong></h3>
                        <ul className="popup-report-list">
                            <li
                                onClick={() => setReport('AG Water True QI Forecast', '/AGWaterTrueQIForecast')}
                            >
                                Go to report
                            </li>
                        </ul>
                        <h3><strong>Stormwater sector score: {current['Sw Sector Score']}</strong></h3>
                        <ul className="popup-report-list">
                            <li
                                onClick={() => setReport('Storm Water True QI Forecast', '/StormWaterTrueQIForecast')}
                            >
                                Go to report
                            </li>
                        </ul>
                        <h3><strong>Total Expected Daily Load Pounds: {current['Total Expected Daily Load Pounds']}</strong></h3>
                        <h3><strong>Overall Surface Water Score: {current['Overall Surface Water Score']}</strong></h3>
                    </div>
                </div>
            </Popup>
        </div>
    )
}

export default RenderPopupDW