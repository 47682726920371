// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-overlay {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(29, 32, 30, 0.9);
  width: 100%;
  height: 100%;
  z-index: 30; }
`, "",{"version":3,"sources":["webpack://./src/components/LoadingDataAnimation.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,iCAAiC;EACjC,WAAW;EACX,YAAY;EACZ,WAAW,EAAA","sourcesContent":[".spinner-overlay {\n  position: absolute;\n  left: 0;\n  top: 0;\n  background: rgba(29, 32, 30, 0.9);\n  width: 100%;\n  height: 100%;\n  z-index: 30;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
