import {
  useMemo,
  useState
} from 'react'
import ReportTable from '../../../reports/shared/ReportTable'
import {
  Button,
  Form,
  Row, Col
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import {
  faPenToSquare,
  faTrashCan,
  faCalendar
} from '@fortawesome/pro-solid-svg-icons';

const Import = (props: any) => {

  const {
    setImportDetails,
    setImportAction,
    setImportActionII,
    setGlobalImportRules,
    setAction,
    action,
    data,
    setData,
    entitySelection,
    setEntitySelection,
    projectsData
  } = props

  const [screen, setScreen] = useState()

  const handleContinue = (event: any) => {
      //setData()
      setAction('importaction2')
  }

  const handleType = (event: any) => {
      console.log(event)
      setScreen(event.target.value)
      setEntitySelection(event.target.value)
  }

  const placeholder = ' ';

  const renderImportInterface = () => (
    <Col className="col-4">
      <Form.Group controlId="formFile" className="mb-3">
        <Button size="sm" onClick={() => {
          setGlobalImportRules(true)
          setImportAction(false)
        }}>Set Global Import Rules (optional)</Button>
      </Form.Group>
    </Col>
  )

  const [yesSelected, setYesSelected] = useState(false)
  const [noSelected, setNoSelected] = useState(false)

  const renderTemplateInterface = () => (
    <>
      <Col style={{ marginLeft: '1rem' }} className="col-4">
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Use Custom Import Template:</Form.Label>
          <Form.Check
            checked={yesSelected}
            id="yes"
            type="radio"
            label="yes"
            onChange={() => {
              setYesSelected(true)
              setNoSelected(false)
            }}
          />
          <Form.Check
            checked={noSelected}
            id="no"
            type="radio"
            label="no"
            onChange={() => {
              setYesSelected(false)
              setNoSelected(true)
            }}
          />
        </Form.Group>
      </Col>
      {yesSelected && (
        <>
          <Col className="col-1">
            <Form.Select size="sm">
              <option ></option>
            </Form.Select>
          </Col>
          <Col className="col-4">
            <Button size="sm" type="submit">Edit Custom Import Templates</Button>
          </Col>
        </>
      )}
    </>
  )

  return (
    <Row className='monitoring-location-container'>
      <div className="header-box">
        <h2>Bulk Import Data</h2>
      </div>
      <Row id="filter-box" style={{ marginRight: '0' }}>
        <div className="filter-box bulk-import">
          <h3>Step 1: Select Data To Import</h3>
          <hr />
          <h4
            style={{
              fontSize: '12px',
              fontWeight: 'bold'
            }}
          >
            Select Import Data Structure
          </h4>
          <div className="drop-box">
            <Col className="col-8">
              <Form.Select defaultValue={'DEFAULT'} size="lg" onChange={handleType}>
                <option value="DEFAULT"></option>
                <option>Monitoring Locations</option>
                <option>Activities</option>
                <option>Bio Metrics/Indices</option>
              </Form.Select>
            </Col>
            <Col className="col-4">
              <Button
                id="addNew-btn"
                className="p-2 btn btn-primary m-2"
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  padding: '12px !important',
                }}
              >
                <a
                  style={{
                    textDecoration: 'none',
                    color: 'white'
                  }}
                  download
                  target="_blank"
                  href={'https://dev.openwaters.trueelements.com/App_Docs/MonLoc_ImportTemplate.xlsx'}
                >
                  Download Template
                </a>
              </Button>
            </Col>
          </div>
        </div>
      </Row>
      {screen === 'Monitoring Locations' && (
        <>
          <Row id="filter-box" style={{ marginRight: '0' }}>
            <div className="filter-box bulk-import">
              <h3>Step 2: Define Data Import Rules</h3>
              <hr />
              <div className="drop-box">
                {renderImportInterface()}
              </div>
            </div>
          </Row>
          <Row id="filter-box" style={{ marginRight: '0' }}>
            <div className="filter-box bulk-import">
              <h3 style={{ marginBottom: '12px' }}>Copy and paste your data from a spreadsheet into the text area below:</h3>
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Control as="textarea" rows={7} placeholder={placeholder} 
                      onChange={(e:any) => {
                                    setData(e.target.value)
                                }} />
                </Form.Group>
              </Form>
            </div>
          </Row>
          <div style={{ textAlign: "end" }} className="bottom-button-box">
            <Button
              onClick={handleContinue}
              id="addNew-btn"
              className="p-2 btn btn-primary m-2"
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '1rem !important',
                width: '8rem'
              }}
            >
              Continue
            </Button>
          </div>
        </>
      )}

      {screen === 'Activities' && (
        <>
          <Row id="filter-box" style={{ marginRight: '0' }}>
            <div className="filter-box bulk-import">
              <h3>Step 2: Define Data Import Rules</h3>
              <hr />
              <div className="drop-box">
                {renderImportInterface()}
                {renderTemplateInterface()}
              </div>
            </div>
            <div style={{ marginTop: '-2rem' }} className="filter-box bulk-import">
              Import to Project
              <Form.Select  defaultValue={'DEFAULT'} size="sm">
                <option value="DEFAULT"></option>
                    {projectsData  && projectsData.map((obj:any, index:any) =>
                        (<option key={'wm-options'+index} value={obj["PROJDescription_ProjectName"]}>{obj["PROJDescription_ProjectName"]}</option>)
                    )} 
              </Form.Select>
            </div>
          </Row>
          <Row id="filter-box" style={{ marginRight: '0' }}>
            <div className="filter-box bulk-import">
              <h3 style={{ marginBottom: '12px' }}>Copy and past your data from a spreadsheet into the text area below:</h3>
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Control as="textarea" rows={7} placeholder={placeholder} 
                      onChange={(e:any) => {
                                    setData(e.target.value)
                                }} />
                </Form.Group>
              </Form>
            </div>
          </Row>
          <div style={{ textAlign: "end" }} className="bottom-button-box">
            <Button
              onClick={() => {
                setAction('importaction2')
/*                setImportDetails(false)
                setImportAction(false)
                setImportActionII(true)*/
              }}
              id="addNew-btn"
              className="p-2 btn btn-primary m-2"
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '1rem !important',
                width: '8rem'
              }}
            >
              Continue
            </Button>
          </div>
        </>
      )}
      {screen === 'Bio Metrics/Indices' && (
        <>
          <Row id="filter-box" style={{ marginRight: '0' }}>
            <div className="filter-box bulk-import">
              <h3>Step 2: Define Data Import Rules</h3>
              <hr />
              <div className="drop-box">
                {renderImportInterface()}
              </div>
            </div>
            <div style={{ marginTop: '-2rem' }} className="filter-box bulk-import">
              Import to Project
              <Form.Select  defaultValue={'DEFAULT'} size="sm">
                <option value="DEFAULT"></option>
                    {projectsData  && projectsData.map((obj:any, index:any) =>
                        (<option key={'wm-options'+index} value={obj["PROJDescription_ProjectName"]}>{obj["PROJDescription_ProjectName"]}</option>)
                    )} 
              </Form.Select>
            </div>
          </Row>
          <Row id="filter-box" style={{ marginRight: '0' }}>
            <div className="filter-box bulk-import">
              <h3 style={{ marginBottom: '12px' }}>Copy and past your data from a spreadsheet into the text area below:</h3>
              <Form>
                <p style={{ color: '#3E982E' }}>Import Activity Metrics Here</p>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Control as="textarea" rows={7} placeholder={placeholder} 
                      onChange={(e:any) => {setData(e.target.value)}}/>
                </Form.Group>
              </Form>
              <Form>
                <p style={{ color: '#3E982E' }}>Paste Indices Here</p>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Control as="textarea" rows={7} placeholder={'Indices import not available at this time.'} />
                </Form.Group>
              </Form>
            </div>
          </Row>
          <div style={{ textAlign: "end" }} className="bottom-button-box">
            <Button
              onClick={() => {
                setAction('importaction2')
/*                setImportDetails(false)
                setImportAction(false)
                setImportActionII(true)*/
              }}
              id="addNew-btn"
              className="p-2 btn btn-primary m-2"
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '1rem !important',
                width: '8rem'
              }}
            >
              Continue
            </Button>
          </div>
        </>
      )}
    </Row>
  )
}

export default Import