import { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers, faGreaterThan, faSliders, faBuilding, faTarpDroplet, faHandHoldingDroplet,  faDraftingCompass, faArrowUpFromGroundWater,  faMagnifyingGlassLocation, faGlobe, faHouse, faSeedling, faDroplet } from '@fortawesome/pro-solid-svg-icons';
import { Form, Button, Row, Col, Card, OverlayTrigger, FormCheck, Nav, NavDropdown, Accordion } from 'react-bootstrap';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';

import './Profiles.scss';


export default function Profiles(props: any){
    const { global } = props;
	global.setUserLogged(true);
    const { pathname } = useLocation();
    const navigate = useNavigate();

 
    const settingsConfiguration = [];
    let reportCollection = ['Water Statistics & Measures', "Climate and Economic Justice Screening Tool", 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 1, title: 'Researcher', description: 'Includes:', ic: faMagnifyingGlassLocation, reportCollection: reportCollection })
    
    reportCollection = ['What’s in my Drinking Water?', "Climate and Economic Justice Screening Tool", 'Drinking Water True QI Report', 'Water Statistics & Measures', 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 10, title: 'Regulator', description: 'Includes:', ic: faSliders, reportCollection: reportCollection })
    
    reportCollection = ['Permit Discharge Report', "Climate and Economic Justice Screening Tool", 'Drinking Water True QI Report', 'Water Statistics & Measures', 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 20, title: 'Operator', description: 'Includes:', ic: faArrowUpFromGroundWater, reportCollection: reportCollection })
    
    reportCollection = ['AG Water True QI Forecast', "Climate and Economic Justice Screening Tool", 'What’s in my Drinking Water?', 'Drinking Water True QI Report', 'Water Statistics & Measures', 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 30, title: 'Agriculture Manager', description: 'Includes:', ic: faSeedling, reportCollection: reportCollection })

    reportCollection = ['AG Water True QI Forecast', "Climate and Economic Justice Screening Tool", 'What’s in my Drinking Water?', 'Drinking Water True QI Report', 'Water Statistics & Measures', 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 40, title: 'Commericial Manager', description: 'Includes:', ic: faBuilding, reportCollection: reportCollection })

    reportCollection = ['AG Water True QI Forecast', "Climate and Economic Justice Screening Tool", 'What’s in my Drinking Water?', 'Drinking Water True QI Report', 'Water Statistics & Measures', 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 50, title: 'Planning Engineer', description: 'Includes:', ic: faDraftingCompass, reportCollection: reportCollection })

    reportCollection = ['AG Water True QI Forecast', "Climate and Economic Justice Screening Tool", 'What’s in my Drinking Water?', 'Drinking Water True QI Report', 'Water Statistics & Measures', 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 70, title: 'Starter Profile', description: 'Includes:', ic: faDroplet, reportCollection: reportCollection })

    reportCollection = ['AG Water True QI Forecast', "Climate and Economic Justice Screening Tool", 'What’s in my Drinking Water?', 'Drinking Water True QI Report', 'Water Statistics & Measures', 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 80, title: 'Citizen Waterkeeper', description: 'Includes:', ic: faHandHoldingDroplet, reportCollection: reportCollection })


    return(
		<div className='Profiles'>
            <Row className='profiles-title'>
                <h2>Profiles</h2>
            </Row>

            <Row xs={1} md={3} className="g-4 settings-grid-row">
                {settingsConfiguration.map((settingscard: any) => (
                <GridCard {...settingscard}  key={'settings-grid-'+ settingscard.id}/>
                ))}
            </Row>
		</div>
	);


    function GridCard(settingscard: any){
        const sid = settingscard.id;
    
        const handleCardClick = (event: any) => {
            //navigate(settingscard.route)
        };
  
        const handleSelect = (event: any) => {
            console.log(event)
        };

        return(
            <>
                <Card className='profiles-card' onClick={handleCardClick} key={'profiles-grid-card'+sid}>                    
                        <div className='profiles-card-icon-container'>
                            <FontAwesomeIcon
                                icon={settingscard.ic}
                                className='mr-2'
                                style={{ display: 'inline', marginLeft: 0 }}
                            />
                            <Form.Check
                                
                                label=""/>
                        </div>
                        <Card.Body  className='settings-card-body'>
                            <Card.Title>{settingscard.title}
                                <div className='settings-card-check-a'>
                                </div>
                            </Card.Title>
                            <Card.Text>
                                
                            </Card.Text>      
                            <div>
                                <span className='settings-card-span'>{settingscard.description}</span>
                                <ul>
                                {settingscard.reportCollection.slice(0,2).map((rc: any, index:number) => (
                                    <li key={'profiles-grid-'+ index} >
                                         {rc}       
                                    </li>
                                ))}
                                </ul>
 
                                <Accordion defaultActiveKey="1" flush onSelect={handleSelect}>
                                    <Accordion.Item eventKey={settingscard.title}>
                                        <Accordion.Header>View All</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <ul>                                
                                                    {settingscard.reportCollection.slice(2).map((rc: any, index:number) => (
                                                        <li key={'profiles-grid=ul-'+ index} >
                                                            {rc}       
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                            </div>         
                        </Card.Body>              
                </Card>
            </>
        );
    }
}




