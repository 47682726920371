import { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { Layer, Source, Popup } from 'react-map-gl'
import { FillLayer } from 'mapbox-gl';
import { RefContext } from "../../../RefContext";
import { PointContext } from "../../../PointContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { ActivePopupContext } from "../../../ActivePopupContext";
import { useNavigate } from "react-router-dom";
import "./wsbv-layer.scss";

const VectorLayerReport = ({ 
  id,
  fillColor, 
  opacity, 
  outlineColor, 
  tileSet, 
  sourceLayer, 
  beforeID, 
  action
} : any) => {

  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
  const { currentRef } = useContext<any>(RefContext);

  // @ts-ignore
  const ref = currentRef?.current;
  const lngLat = action?.lngLat;
  const [features, setFeatures] = useState<Record<string, any> | null>(null);

  const getFeaturesAtPoint = useCallback(
    (event: any) => {
      const res = ref?.queryRenderedFeatures(event.point, { layers: [`${id}-layer`] })?.[0];
      if (res?.properties) setFeatures(res.properties);
    },
    [id, ref]
  );

  useEffect(() => {
    if (ref) {
      ref.on("click", getFeaturesAtPoint);
      return () => ref.off("click", getFeaturesAtPoint);
    }
  }, [ref, getFeaturesAtPoint]);

  const nav = useNavigate()

  const goTo = (data: any, { lat, lng }: any) => {
    setCurrentPoint({ ...data, lat, lng })
    setTimeout(() => {nav('/EPAWaterServiceAreaBoundaries')}, 500)
  }

  const memoizedPopup = useMemo(() => {
    if (!features || !lngLat) return null;

    const handleClose = () => {
      setFeatures(null);
      setActivePopup({ id: null, content: null });
    };

    return (
      <Popup
        longitude={lngLat.lng}
        latitude={lngLat.lat}
        onClose={handleClose}
        className="circledata-popup"
      >
        <FontAwesomeIcon className="close-btn" icon={faXmark} onClick={handleClose} />
        <strong>{features["PWS_Name"]}</strong>
        <a
          className="report-anchor"
          onClick={() => goTo(features, lngLat)}
        >
          Go to report
        </a>
      </Popup>
    );
  }, [features, lngLat, setActivePopup]);

  useEffect(() => {
    if (memoizedPopup && activePopup?.id !== id) {
      setActivePopup({ id, content: memoizedPopup });
    }
  }, [memoizedPopup, activePopup, setActivePopup, id]);

 
  const VectorLayerReport: FillLayer = {
    id: `${id}-layer`,
    type: "fill",
    "source-layer": `${sourceLayer}`,
    paint: {
      "fill-opacity": opacity,
      "fill-outline-color": `${outlineColor}`,
      'fill-color': fillColor,
    },
  };

    return (
        <>
          <Source
            id={`${id}-layer`}
            type="vector"
            url={tileSet}
          >
            <Layer
              beforeId={beforeID}  
              {...VectorLayerReport} 
            />
            {activePopup?.id === id && activePopup.content}
          </Source>
        </>
    )
}

export default VectorLayerReport;  
