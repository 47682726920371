import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { AppContext } from '../../../AppContext';
import { RefContext } from '../../../RefContext';
import { faGreaterThan, faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getReportFromStorage,
  getCountryFromStorage,
} from '../../utils';

import { Form } from 'react-bootstrap'
import axios from 'axios'
import agent from '../../../api/agent';
import settings from '../../../settings.json'
import ISOs from '../../../ISO.json'
import './breadcrumb-styles.scss'


const Breadcrumb = (props: any) => {
  const { param = null, global } = props
  const { latitude, longitude } = global.viewport
  const [country, setCountry] = useState<any>()

  const { pathname } = useLocation()
  const { currentRef } = useContext<any>(RefContext);
  const {
    selectedHUC8,
    currentLocation,
    setCurrentLocation,
    updateContext
  } = useContext(AppContext);

  const [report, setReport] = useState<any>()

  const mediaQuery11 = window.matchMedia('(max-width: 1117px)');
  const mediaQuery8 = window.matchMedia('(max-width: 877px)');

  useEffect(() => {
    const reportFromStorage = getReportFromStorage();
    setReport(reportFromStorage)
    setCountry(getCountryFromStorage())   
  }, [getCountryFromStorage()])


  const flyToLocation = async (location: string) => {
    const huc12Find = location.substr(location.indexOf('('), location.indexOf(')'))
    if(huc12Find && huc12Find.length === 14){ 
        const location_huc12 = localStorage.getItem('location_huc12');
        if(location_huc12){
          let location = JSON.parse(location_huc12)
          global.setResultsDataset([location]); 
            global.setViewport({
              zoom: 10,
              latitude: +location.centroid_latitude,
              longitude: +location.centroid_longitude
            }) 
          return;          
        }        
    } 
    global.setLoading(true)

    try {
      const { data } = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${location}.json?&country=${getISOCode(global.currentCountry)}&limit=3&access_token=${settings.maboxKey}`)

      const [lng, lat] = data.features[0].center

      global.setViewport({
        zoom: 12,
        latitude: lat,
        longitude: lng
      })

    } catch (e) {
      console.log(e)
    }
    global.setTrackerOn(false)
    global.setLoading(false)
  }

  const getISOCode = (input: string) => {
    const countries = Object.values(ISOs)
    const isos = Object.keys(ISOs)
    const countryIndex = countries.findIndex(country => country === input)

    return isos[countryIndex]
  }

  return (
    <div className="breadcrumb-container">
      <span className='breadcrumb-subtitle'>
        <span className='breadcrumb-subtitle--parent-section' style={(mediaQuery11.matches) ? {display:'none'} : {}}>
          {report && report.parentReportName}
          &nbsp;&nbsp;
          <FontAwesomeIcon icon={faGreaterThan} size='1x' /> &nbsp;&nbsp;
        </span>
        <span  style={(mediaQuery8.matches) ? {display:'none'} : {}}>
          {report && report.name}
          &nbsp;&nbsp;
          <FontAwesomeIcon icon={faGreaterThan} size='1x' /> &nbsp;&nbsp;
        </span>
        <span onClick={() => flyToLocation(currentLocation)} className="location">{currentLocation}</span>
      </span>
    </div>
  );
};

export default React.memo(Breadcrumb);