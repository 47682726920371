import { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { RefContext } from "../../RefContext";
import { ActivePopupContext } from "../../ActivePopupContext";
import { Layer, Source, LineLayer, FillLayer, Popup } from 'react-map-gl';

const OABLayer = ({
  id,
  lineColor,
  tileSet,
  sourceLayer,
  width,
  minzoom = 0,
  action,
  global,
  opacity
}: any) => {

  const [features, setFeatures] = useState<any>(null);
  const { currentRef } = useContext(RefContext);
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);
  // @ts-ignore
  const mapInstance = currentRef && currentRef?.current;
  const latLng = action && action.lngLat;

  useEffect(() => {
    if (mapInstance) {
      const handleClick = (event: any) => {
        const layerObject = mapInstance.queryRenderedFeatures(event.point);

        if (layerObject.length > 0) {
          const feature = layerObject[0];
          const lngLat = event.lngLat;
          setFeatures(feature);
          setActivePopup({
            id,
            content: renderPopup(feature.properties, lngLat)
          });
        }
      };

      mapInstance.on("click", handleClick);

      return () => {
        mapInstance.off("click", handleClick);
      };
    }
  }, [mapInstance, setActivePopup, id]);

  const renderPopup = (properties: any, lngLat: any) => {
    const handleClose = () => {
      setFeatures(null);
      setActivePopup({ id: null, content: null });
    };

    return (
      <Popup
        longitude={lngLat.lng}
        latitude={lngLat.lat}
        onClose={handleClose}
        closeButton={true}
        closeOnClick={false}
        anchor="bottom"
      >
        <div>
          <FontAwesomeIcon 
            onClick={handleClose} 
            style={{ 
                position: "absolute", 
                top: "4%", 
                right: "2%", 
                cursor: "pointer", 
                fontWeight: "bold" 
              }} 
            icon={faXmark} 
            />
          <div>
            <p>Locality Type: {properties.localityType}</p>
            <p>Verbose Names: {properties.verbose_names}</p>
          </div>
        </div>
      </Popup>
    );
  };

  const VectorLineLayer: LineLayer = {
    id: `${id}-line-layer`,
    type: "line",
    "source-layer": `${sourceLayer}`,
    paint: {
      "line-opacity": opacity,
      "line-color": `${lineColor}`,
      'line-width': width,
    },
    minzoom: minzoom
  };

  const VectorTileLayer: FillLayer = {
    id: `${id}-tile-layer`,
    type: "fill",
    "source-layer": `${sourceLayer}`,
    paint: {
      "fill-opacity": 0,
      "fill-outline-color": "transparent",
      'fill-color': 'transparent',
    },
  };

  return (
    <>
      <Source id={`${id}-tile-layer`} type="vector" url={tileSet}>
        <Layer {...VectorTileLayer} />
      </Source>
      <Source id={`${id}-line-layer`} type="vector" url={tileSet}>
        <Layer {...VectorLineLayer} />
      </Source>
      {activePopup && activePopup.id === id && activePopup.content}
    </>
  );
};

export default OABLayer;
