import Render from "../../../components/render";
import "./report-title.scss";
import exitIcon from "../../../assets/exit-icon.svg";
import { v4 as uuidv4 } from 'uuid';

const TitlePill = ({ condition, setCondition, name, global }:any) => {

  const handleLayerNavString = (layerName:string) =>{ if(layerName && global && global.handleClickScroll){ global.handleClickScroll(layerName)}}

  return (
    <Render condition={condition}>
        <div key={uuidv4()} onClick={() => handleLayerNavString(name)} className="tag rounded-pill">
            {name}
          <img
            id="btn-remove"
            src={exitIcon}
            onClick={() => setCondition(false)}
          />
        </div>
    </Render>
  )
}

export default TitlePill