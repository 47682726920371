import React, { useState, useEffect, useContext, useRef, memo } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import {
  Map,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  Marker
} from 'react-map-gl';
import settings from '../../../settings.json';
import { PointContext } from "../../../PointContext";
import { RefContext } from '../../../RefContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuoyMooring } from '@fortawesome/pro-solid-svg-icons';
import { Row, Col, Container, Image } from 'react-bootstrap';
import { putViewportIntoStorage } from '../../utils'
import RenderLayers from '../shared/RenderLayers';
import RenderLayerMarkers from '../shared/RenderLayerMarkers';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import { parse } from 'papaparse';
import LineChart from './LineChart';
import MapSlider from '../shared/MapSlider';
import MapLegend from '../shared/MapLegend';
import agent from '../../../api/agent';
import 'chartjs-plugin-zoom';
import './cwa-report.scss';

const CWAReport = ({ global }: any) => {
  const [paramDefs, setParamDefs] = useState<any>();
  const [reportData, setReportData] = useState();
  const [transformedData, setTransformedData] = useState();
  const [filteredData, setFilteredData] = useState<any>();
  const [params, setParams] = useState<any>();
  const [param, setParam] = useState();
  const [id, setId] = useState();
  const [error, setError] = useState(false);
  const [initialStartTime, setInitialStartTime] = useState<any>()

  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;

  const ref = useRef<any>();
  const reportBounds = ref.current ? ref.current.getMap().getBounds().toArray().flat() : null;
  const { currentPoint } = useContext<any>(PointContext);
  const { setCurrentRef } = useContext(RefContext);

  useEffect(() => { setCurrentRef?.(ref) }, [ref]);

  const filterByTimestamp = (array: any[], start: number, end: number) => {
    return array.filter((obj:any) => {
      const objTimestamp = parseInt(obj.Timestamp) / 1000;
      return objTimestamp >= start && objTimestamp <= end;
    });
  };

  useEffect(() => {
    if (transformedData && global.CWATimeRange) {
      const { startTime, endTime } = global.CWATimeRange;
      const filtered = filterByTimestamp(transformedData, startTime, endTime);
      setFilteredData(filtered);
    }
  }, [transformedData, global.CWATimeRange]);

  const setInitialDates = (input: any[]) => {
    let timestamps: any[] = [];
    input.forEach(({ Timestamp }: any) => timestamps.push(parseInt(Timestamp)));
    const min = Math.min(...timestamps);
    const max = Math.max(...timestamps);
    setInitialStartTime(min)
    global.setCWAStartTime(min);
    global.setCWAMaxTime(max);
  };

  useEffect(() => {
    if(initialStartTime && transformedData) {
      const start = initialStartTime/1000
      const end = (initialStartTime + 2592000000)/1000
      const filtered = filterByTimestamp(transformedData, start, end);
      setFilteredData(filtered);
    }
  },[
    initialStartTime,
    transformedData
  ])

  useEffect(() => {
    if (transformedData) setInitialDates(transformedData);
  }, [transformedData]);

  useEffect(() => global.setUserLogged(true), []);

  const transformData = (dataArray: any, compareArray: any) => {
    const definitionMap = dataArray.reduce((map: any, obj: any) => {
      map[obj.parameter] = obj.definition;
      return map;
    }, {});
    const data = compareArray.map((obj: any) => {
      for (const key in obj) {
        if (definitionMap[key]) {
          return {
            parameter: key,
            definition: definitionMap[key],
            ...obj,
            value: parseFloat(obj[key].trim())
          };
        }
      }
      return null;
    }).filter((obj: any) => obj !== null);

    setTransformedData(data);
  };

  useEffect(() => {
    if (paramDefs && reportData) transformData(paramDefs, reportData);
  }, [paramDefs, reportData]);

  const trimObjectKeys = (arr: any) => {
    return arr.map((obj: any) => {
      const newObj: any = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const trimmedKey = key.trimStart();
          newObj[trimmedKey] = obj[key];
        }
      }
      return newObj;
    });
  };

  useEffect(() => {
    if (global.CWAParam && paramDefs) {
      const p = paramDefs.find((obj: any) => obj.definition === global.CWAParam);
      setParam(p.parameter);
    }
  }, [global.CWAParam, paramDefs]);

  const fetchReportData = async (pointId: any, parameter: any) => {
    global.setLoading(true);
    return await agent.Reports.CWA(pointId, parameter)
      .then(({ body }: any) => setReportData(trimObjectKeys(body)))
      .catch((error: any) => {
        setError(true);
        console.log(error);
      })
      .finally(() => global.setLoading(false));
  };

  useEffect(() => {
    if (global.CWAParam && id && param) {
      setFilteredData(undefined);
      fetchReportData(id, param);
    }
  }, [global.CWAParam]);

  useEffect(() => {
    if (params) {
      let defs: any = [];
      const huc12FileURL = '/csv/cwa_parameter_defs.csv';
      fetch(huc12FileURL)
        .then((response) => response.text())
        .then((responseText) => {
          const data: any[] = parse(responseText, {
            header: true,
          }).data;
          setParamDefs(data);
          params.forEach((i: any) => {
            const { definition } = data.find((e: any) => e.parameter === i);
            defs.push(definition);
          });
          global.setCWAParams(defs);
          global.setCWAParam(defs[0]);
        });
    }
  }, [params]);

  const resetState = () => {
    setParams(undefined);
    setId(undefined);
    setParam(undefined);
    setParamDefs(undefined);
    setReportData(undefined);
    setTransformedData(undefined);
    setFilteredData(undefined);
    setError(false);
  };

  useEffect(() => {
    if (currentPoint) {
      resetState();
      setTimeout(() => {
        const { ID, lng, lat, param_ids } = currentPoint;
        const pIds = param_ids.split(',');
        setParams(pIds);
        setId(ID);
        fetchReportData(ID, pIds[0]);
        if (ref.current) {
          if (!isNaN(lng) && !isNaN(lat)) {
            ref.current.flyTo({
              center: [lng, lat],
              essential: true,
              zoom: 8.5,
            });
          } else {
            console.error('Invalid coordinates:', { lng, lat });
          }
        }
      }, 300);
    }
  }, [currentPoint]);

  useEffect(() => {
    return () => {
      global.setLoading(false);
    }
  }, []);

  return (
    <FullScreenComp handle={fullscreenhandle}>
      <Container className='cwa-report-container'>
        <ReportHeader
          global={global}
          data={reportData}
          reportID={"CWAreport"}
          fullScreenClickHandle={fullscreenhandle.enter}
        />
        <Row>
          <Col className='col-12 top d-flex justify-content-center align-items-center'>
            <Col className="cwa-map-container col-6">
              <Map
                mapboxAccessToken={settings.maboxKey}
                mapStyle={global.mapStyle}
                ref={ref}
                onClick={(e:any) => global.onMapClick(e)}
                onMove={(e) => {
                  putViewportIntoStorage({
                    longitude: e.viewState.longitude,
                    latitude: e.viewState.latitude,
                    zoom: e.viewState.zoom,
                  });
                  global.setViewport({
                    longitude: e.viewState.longitude,
                    latitude: e.viewState.latitude,
                    zoom: e.viewState.zoom,
                  });
                }}
              >
                {currentPoint && !isNaN(currentPoint.lat) && !isNaN(currentPoint.lng) && (
                  <Marker
                    latitude={currentPoint.lat}
                    longitude={currentPoint.lng}
                  >
                    <FontAwesomeIcon style={{ color: currentPoint.Type === "BUOY" ? "#FCA502" : "#FF4433" }} icon={faBuoyMooring} size='2x' />
                  </Marker>
                )}
                <FullscreenControl />
                <NavigationControl />
                <GeolocateControl />
                <RenderLayerMarkers global={global} bounds={reportBounds} zipOff={true} />
                <RenderLayers global={global} mapRef={ref} />
                <MapLegend global={global} legendWidth={280} />
              </Map>
            </Col>
            <Col className="cwa-img-container col-6">
              <Col className='col-5 info-box'>
                <div>
                  <h4 className='info-text'>Name: {currentPoint.Name}</h4>
                  <h4 className='info-text'>Type: {currentPoint.Type === "SHORLINE" ? "Shoreline sensor" : "Bouy"}</h4>
                  <h4 className='info-text'>ID: {currentPoint.ID}</h4>
                </div>
              </Col>
              {id ? <Image className='img' src={require(`./img/${id}.jpg`)} /> : ''}
            </Col>
          </Col>
        </Row>
        <Row>
          <Col className='col-12 bottom'>
            <Col className={`line-chart ${filteredData ? '' : 'd-flex justify-content-center align-items-center text-center'}`}>
              {
                filteredData ?
                  <LineChart filteredData={filteredData} title={global.CWAParam} /> :
                  <h1 style={{ color: 'white' }}>
                    ...Loading
                  </h1>
              }
              {error && !filteredData && (<p style={{ color: 'white' }}>Data not present at this location</p>)}
            </Col>
          </Col>
        </Row>
        <ReportFooter />
      </Container>
      <MapSlider global={global} />
    </FullScreenComp>
  );
}

export default memo(CWAReport);
