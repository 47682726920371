import UserLayer from './UserLayer'
import AppleLocationData from '../AppleLocationData/AppleLocationData'
import CWALayer from '../CWALayer/CWALayer'

const UserLayers = ({ global, mapRef = {}}: any) => {
  return (
    <>
         {global.AppleLocationData && (
             <AppleLocationData id={0} global={global} mapRef={mapRef}/>
          )}
          {global.customLayer1 && global.customData1 && (
              <UserLayer
                  id={1}
                  data={global.customData1}
                  mapRef={mapRef}
                  global={global}
              />
          )}
          {global.customLayer2 && global.customData2 && (
              <UserLayer
                  id={2}
                  data={global.customData2}
                  mapRef={mapRef}
                  global={global}
              />
          )}
          {global.customLayer3 && global.customData3 && (
              <UserLayer
                  id={3}
                  data={global.customData3}
                  mapRef={mapRef}
                  global={global}
              />
          )}
          {global.customLayer4 && global.customData4 && (
              <UserLayer
                  id={4}
                  data={global.customData4}
                  mapRef={mapRef}
                  global={global}
              />
          )}
          {global.customLayer5 && global.customData5 && (
              <UserLayer
                  id={5}
                  data={global.customData5}
                  mapRef={mapRef}
                  global={global}
              />
          )}
          {global.customLayer6 && global.customData6 && (
              <UserLayer
                  id={6}
                  data={global.customData6}
                  mapRef={mapRef}
                  global={global}
              />
          )}
          {global.customLayer7 && global.customData7 && (
              <UserLayer
                  id={7}
                  data={global.customData7}
                  mapRef={mapRef}
                  global={global}
              />
          )}
          {global.customLayer8 && global.customData8 && (
              <UserLayer
                  id={8}
                  data={global.customData8}
                  mapRef={mapRef}
                  global={global}
              />
          )}
          {global.customLayer9 && global.customData9 && (
              <UserLayer
                  id={9}
                  data={global.customData9}
                  mapRef={mapRef}
                  global={global}
              />
          )}
          {global.customLayer10 && global.customData10 && (
              <UserLayer
                  id={10}
                  data={global.customData10}
                  mapRef={mapRef}
                  global={global}
              />
          )}
          {global.customLayer11 && global.customData11 && (
              <UserLayer
                  id={11}
                  data={global.customData11}
                  mapRef={mapRef}
                  global={global}
              />
          )}
          {global.customLayer12 && global.customData12 && (
              <UserLayer
                  id={12}
                  data={global.customData12}
                  mapRef={mapRef}
                  global={global}
              />
          )}
          {global.customLayer13 && global.customData13 && (
              <UserLayer
                  id={13}
                  data={global.customData13}
                  mapRef={mapRef}
                  global={global}
              />
          )}
          {global.customLayer14 && global.customData14 && (
              <UserLayer
                  id={14}
                  data={global.customData15}
                  mapRef={mapRef}
                  global={global}
              />
          )}
          {global.customLayer15 && global.customData15 && (
              <UserLayer
                  id={15}
                  data={global.customData15}
                  mapRef={mapRef}
                  global={global}
              />
          )}
          {global.customLayer16 && global.customData16 && (
              <UserLayer
                  id={16}
                  data={global.customData16}
                  mapRef={mapRef}
                  global={global}
              />
          )}
          {global.customLayer17 && global.customData17 && (
              <UserLayer
                  id={17}
                  data={global.customData17}
                  mapRef={mapRef}
                  global={global}
              />
          )}
          {global.customLayer18 && global.customData18 && (
              <UserLayer
                  id={18}
                  data={global.customData18}
                  mapRef={mapRef}
                  global={global}
              />
          )}
          {global.customLayer19 && global.customData19 && (
              <UserLayer
                  id={19}
                  data={global.customData19}
                  mapRef={mapRef}
                  global={global}
              />
          )}
          {global.customLayer20 && global.customData20 && (
              <UserLayer
                  id={20}
                  data={global.customData20}
                  mapRef={mapRef}
                  global={global}
              />
          )}
    </>
  )
}

export default UserLayers