import React, { useState, useMemo, useEffect } from 'react';
import { Form, Button, Row, Col, FloatingLabel, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown, faFileExport, faCancel, faRectangleXmark, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
 
import InputForm from '../../components/input-form';
import companyIcon from '../../assets/company-icon.svg';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import SettingsBreadcrumb from '../account/SettingsBreadcrumb';
import agent from '../../api/agent';
import './add-user.scss';

export default function UserInfo(props: any){
	const { global } = props;
	global.setUserLogged(true);

	let _mode = 'View';
	let uInfo = global.userInfo || {};
	let _org = '';
	let _user_id = '';
	let _image_url = '';
 	if(global && global.userInfo){
		if(uInfo.data){
			_org = uInfo.data.org_id;
			_user_id = uInfo.data.user_id;
		}
		if(uInfo.imageurl){
			_image_url = uInfo.imageurl;
		}
	} 

	const [ mode, setMode ] = useState(_mode);
	const [ lastName, setLastName ] = useState(uInfo.family_name || '');
	const [ firstName, setFirstName ] = useState(uInfo.given_name || '');
	const [ emailName, setEmailName ] = useState(uInfo.email || '');
	const [ phoneNumber, setPhoneNumber ] = useState(uInfo.phone || '');
    const [ currentUser, setCurrentUser ] = useState(uInfo || {});
	const [ organization, setOrganization ] = useState(_org || '');
    const [ orgName, setOrgName ] = useState(global.userOrgString);
	const [ role, setRole ] = useState(uInfo.role || '');
	const [ profile, setProfile ] = useState(uInfo.Profiles || []);

	const [profileUrl, setProfileUrl] = useState(_image_url||'profile avatar.png');
	const [profileUrlUpload, setProfileUrlUpload] = useState('');

	const user = {
		firstName,
		setFirstName,
		lastName,
		setLastName,
		emailName,
		setEmailName,
		phoneNumber,
		setPhoneNumber
	};

	const [show, setShow] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const handleOnClick = () => {
	    setShow(!show);
	}
	const handleOnDeleteClick = () => {
	    setShowDelete(!showDelete);
	}

	useEffect(() => {
		if(profileUrl){
			uInfo.imageurl = profileUrl;
			uInfo.indexkey = Math.random();
	        global.setUserInfo(uInfo);
	        sessionStorage.setItem("userInfo", JSON.stringify(uInfo));
		}
    }, [profileUrl]);    

	useEffect(() => {
        agent.UserManagement.UserGet('', uInfo.email).then((res: any) => {
            if(res && res.body){
                const data = [...res.body]
                setCurrentUser(data[0])
            
                if(data[0].name){
                    let namearray = data[0].name.split(' ');
                    if(namearray.length > 1){
                        setLastName(namearray[1])
                    }
                    setFirstName(namearray[0])
                }		
                if(data[0].role){
                    setRole(data[0].role);
					global.setUserRoleString(data[0].role)
                }				
                 /*if(data[0].Profiles){
                    setProfile(data[0].Profiles);
                }		 */
                if(data.length > 0 && data[0].org_id && data[0].org_id>-1){
                    agent.UserManagement.OrgGet(data[0].org_id).then((res: any) => {
                        const orgdata = [] as any;
                        if(res && res.body){
                            const data = res.body
                            if(data.length>0){
                                setOrgName(data[0].name)    
								 global.setUserOrgString(data[0].name)                
                            }
                        }	
                    })   
                }
            }
        })   
		
    }, []);    


    const onClickUpload = (e: any) => {
      if (e.target.form && e.target.form[0].files && e.target.form[0].files[0]) {
        let file = e.target.form[0].files[0];
        let fileName = file.name.split('.')[0];

        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
        	if(e.target && e.target.result){//@ts-ignore
        		const base64Data = e.target.result.split(',')[1];//@ts-ignore       		
        		let image = document.getElementById('profilethumb') as HTMLImageElement;
                if(image && _user_id){
                	
                	//@ts-ignore
                  	if(uInfo.imageurl && uInfo.imageurl !=='profile avatar.png'){
	                    agent.UserManagement.UserImageUpdate(''+_user_id, {"image_data_base64":base64Data}).then(({ body }: any) => {
					  
					      if (body && body.message) {
					      	updateUrl();
					      }
					    });
                  	} else {
	                    agent.UserManagement.UserImagePost(''+_user_id, {"image_data_base64":base64Data}).then(({ body }: any) => {
					   
					      if (body && body.message) {
					      	updateUrl();
					      } else if (body && body.error) {
					      	if(body.error === 'Image with the same ID already exists'){
			                    agent.UserManagement.UserImageUpdate(''+_user_id, {"image_data_base64":base64Data}).then(({ body }: any) => {
							    
							      if (body && body.message) {
							      	updateUrl();
							      }
							    });
					      	} else {
					      		global.notify(body.error)
					      	}					      	
					      } 
					    });                  		
                  	}

                }
        	}
        };
      }        
      setShow(false);
    };

    const onClickCancel = (e: any) => {
    	setShow(false);
    }

	const updateUrl = () => {
		agent.UserManagement.UserImageGet(''+_user_id).then(({ body }: any) => {
		      if (body && body.image_url) {
	      		  setProfileUrl(body.image_url)
		          uInfo.imageurl = body.image_url;
		          uInfo.indexkey = Math.random();
		          global.setUserInfo(uInfo);
		      
		          sessionStorage.setItem("userInfo", JSON.stringify(uInfo));
		      }
	    });
	}

	  const handleFileChange = (e: any) => {
	    if (e.target.files) {
	        if (e.target.files[0]) {
		        let file = e.target.files[0];
		        const reader = new FileReader();
		        reader.readAsDataURL(file);
		        reader.onloadend = (e) => {
		        	if(e.target && e.target.result){//@ts-ignore       		
		        		let image = document.getElementById('profileupload') as HTMLImageElement;
		                if(image && _user_id){//@ts-ignore
		                  	image.src = e.target.result; 
		                  	setProfileUrlUpload('profileupload')
		                }
		        	}
		        };
		      }  
	    }
	  };


	 const popover = (
	    <Popover id="popover-settings" style={{minWidth: 300}}>
	      <Popover.Header as="h3">Upload Image</Popover.Header>
	      <Popover.Body>
	        <div style={{display: 'flex', flexDirection: 'column'}}>
	            <Form>
	                <Form.Group controlId="formFile" className="mb-3" style={{ display: 'flex', alignItems: 'center', fontSize: 18 }}>
	                  {/*  <Form.Label>Upload file</Form.Label> */}
	                  <Form.Control onChange={handleFileChange}  type="file" accept="image/gif, image/jpeg, image/png" style={{ maxWidth: 340, fontSize: 18, marginLeft: 10 }} />
	                </Form.Group>
	               	<img id={'profileupload'} className='card-image' style={{height:170, width: 170, marginLeft: "25%", marginBottom: 20}}/>
	               	<div style={{display: 'flex', justifyContent: 'space-between'}}>
		                <Button className='users-button' style={{ marginRight: 5, marginLeft: 10 } }
		                    onClick={onClickUpload}>
		                    Upload selected image
		                </Button>
		                <Button className='users-button' style={{ marginRight: 5, marginLeft: 10 }}
		                    onClick={onClickCancel}>
		                    Cancel
		                </Button>
	                </div>
	            </Form>
	        </div>  
	      </Popover.Body>
	    </Popover>
	  );


    const clickDeleteHandler = (event : any) => {
	    if (event && _user_id) {
	        agent.UserManagement.UserImageDelete(_user_id).then(({ body }: any) => {
		      if (body) {
		      	setProfileUrl('profile avatar.png')
		      	uInfo.imageurl ='';
	            uInfo.indexkey = Math.random();
	            global.setUserInfo(uInfo);
	            sessionStorage.setItem("userInfo", JSON.stringify(uInfo));
		      }
		    });
	    }
	    setShowDelete(false)
    }

    const clickCancelHandler = (event : any) => {
    	if(_user_id){
	    	agent.UserManagement.UserImageGet(_user_id).then(({ body }: any) => {
		      if (body && body.image_url) {
		      	 let image = document.getElementById('profileget') as HTMLImageElement;
	                if(image && body.image_url){
	                	console.log('body, body.image_url')//@ts-ignore
	                  image.src = body.image_url; 
	                }
		      }
		    });    		
    	}
	    setShowDelete(false)
    }

	 const popoverDelete = (
	    <Popover id="popover-settings" style={{minWidth: 300}}>
	      <Popover.Header as="h3">Remove Image?</Popover.Header>
	      <Popover.Body>
	        <div style={{display: 'flex', flexDirection: 'column'}}>
	            <Form>
	                <Button className='users-button' style={{ marginRight: 5, marginLeft: 10, width: 70 }}
	                    onClick={clickDeleteHandler}>
	                    Yes
	                </Button>
	                <Button className='users-button' style={{ marginRight: 5, marginLeft: 70, width: 70 }}
	                    onClick={clickCancelHandler}>
	                    No
	                </Button>
	            </Form>
	        </div>  
	      </Popover.Body>
	    </Popover>
	  );
    	
    const handleOnFocuOut = (event : any) => {
    	setShow(false)
    }	


	return (
		<div className='add-user'>
			<SettingsBreadcrumb title={'Profile Information'}  subtitle={''}  />
			<h2>Profile Information</h2>
			<Form>
				<div className='container form form-readonly' style={{marginLeft: 20}}>
					<h4>Basic Information</h4>
					<Row>
						<Col className='col-md-4'>
							<FloatingLabel controlId="floatingfirstName" label="First Name">
								<Form.Control defaultValue={firstName}  readOnly type="text" placeholder="name@example.com" />
							</FloatingLabel>	
						</Col>
						<Col className='col-md-4'>
							<FloatingLabel controlId="floatinglastName" label="Last Name">
								<Form.Control defaultValue={lastName} readOnly type="text" placeholder="Last Name" />
							</FloatingLabel>	
						</Col>
						<Col className='col-md-4'>
				            <h5>Profile Image</h5>
				            <img id={'profilethumb'} src={profileUrl} className='card-image' style={{height:170, width: 170}}/>
				            <div style={{display: 'flex', flexDirection: 'row', marginTop: 25, width: 170, justifyContent: 'space-between'}}>
					            <OverlayTrigger show={show} rootClose overlay={popover}>
					            	<Button style={{height:70, width: 75}} onClick={handleOnClick}  >
							            <FontAwesomeIcon icon={faFileArrowDown} style={{marginTop: 7}} />
							            <p>Update</p>
									</Button>
					            </OverlayTrigger>

					            <OverlayTrigger  show={showDelete} rootClose  overlay={popoverDelete}>
					            	<Button style={{height:70, width: 75}}  onClick={handleOnDeleteClick}>
							            <FontAwesomeIcon icon={faTrashCan} style={{marginTop: 7}} />
							            <p>Delete</p>
							        </Button>    	
					            </OverlayTrigger>
				            </div>

                		</Col>
                    </Row>
					<Row>
						<Col className='col-md-4'>
							<FloatingLabel controlId="floatingEmail" label="Email">
								<Form.Control defaultValue={emailName} readOnly type="text" placeholder="Email" />
							</FloatingLabel>
						</Col>
					</Row>
					<Row>
 						{/*<Col className='col-md-4'>
							<FloatingLabel controlId="floatingPhoneNumber" label="Phone Number">
								<Form.Control defaultValue={phoneNumber} onChange={e => handleChange(setPhoneNumber, e.target.value)} type="text" placeholder="Phone Number" />
							</FloatingLabel>
						</Col> */}
						<Col className='col-md-4'>
						  <FloatingLabel controlId="floatingSelect" label="Organization">
                            <Form.Control defaultValue={orgName} readOnly type="text" placeholder="Organization" />

						   </FloatingLabel>
						</Col>
					</Row>

					<h4>Profile {'&'} Roles</h4>
					
					<Row>
						<Col className='col-md-4' >
							<FloatingLabel controlId="floatingEmail" label="Role">
								<Form.Control defaultValue={role.charAt(0).toUpperCase() + role.slice(1)} readOnly  type="text" placeholder="Role" />
								</FloatingLabel>
						</Col>
					</Row>
					
					<Row>
						<Col className='col-md-4'>
							<FloatingLabel controlId="floatingEmail" label="Profiles">
								<Form.Control defaultValue={profile} readOnly  type="text" placeholder="Profiles" />
							</FloatingLabel>
						</Col>
					</Row>
				</div>
				<div className='footer'>
				</div>
			</Form>
		</div>
	);
}


