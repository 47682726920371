import html2canvas from 'html2canvas';
import { jsPDF, ImageOptions } from "jspdf";
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import settings from "../../../settings.json";
import { DateTime } from 'luxon';
import { ThemeType, Color } from 'jspdf-autotable';
import { parseQueryFilters } from '../shared/report-data';

//++ General Report

const colorGreen = "#4fc22c";
const colorYellow = "#e6ee00"; 
const colorYellowDarker = "#d3da00";  
const colorYellowFill = "#FFFFC8";  
const colorRed = "#c22c4f";
const colorUnavailable = "#3c343c";
const colorWhite = "#ffffff";
const colorLightGray = "#717171";
const colorReallyLightGray = "#e2e2e2";



  const onPDF = (data: any, reportConfig: any, GlobalProps:any, reportElement:any) => {

    
    const logoSource = "logo490.png";
    const colorSectionHeader = "#191919";
    let companyName = 'True Elements';
    let reportTitle = (reportConfig && reportConfig.name) ? reportConfig.name : 'True Elements Report';
    let locationInfo = (reportConfig && reportConfig.appContext && reportConfig.appContext.currentLocation) ? reportConfig.appContext.currentLocation : `HUC8: ${GlobalProps.selectedHUC8}`;

    const pageY = 6;
    const textFooter = colorLightGray;
    var totalPagesExp = '{total_pages_count_string}';  
    const titleColor = '#257ee0';
    const textColor = '#232323';
    let locationField = 'name';
    const logoAR = 3.53;
    let pageTitleIndent = 12;


    const climateScenarioDesc = [] as any; 
    const climateScenarioRCP = [] as any;
    const seasonalImpactDesc = [] as any;

    var doc = new jsPDF();
    var imgbr = new Image(); 
    imgbr.src = logoSource;
    var pageSize = doc.internal.pageSize
    var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var date = new Date();
    
    let huc12info = "";
    let filterArray = parseQueryFilters(reportConfig.location.search, reportConfig.location.pathname);

    const headerFooter = () => {
        doc.setTextColor(colorSectionHeader)
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(16)
            //doc.text(companyName, 12, 20)
            doc.line(12, 25, pageWidth-12, 25)

            doc.setFontSize(10)
            doc.setTextColor(colorLightGray)
            doc.setFont('normal');
            doc.text(reportTitle, pageWidth - 30 - reportTitle.length, 8)
             
            // Footer
            var str = 'Page ' + doc.getNumberOfPages()            
            if (typeof doc.putTotalPages === 'function') {
              str = str + ' of ' + totalPagesExp
            }
            doc.setFontSize(10)
            doc.setTextColor(textFooter);

            doc.text(str, pageWidth - 25, pageHeight - pageY)          
            doc.addImage(imgbr, 'PNG', 6, pageHeight - 14, 42.4, 12);   //3.53
            doc.text("© True Elements Inc. 2023", pageWidth/2-20, pageHeight - pageY);
            //color: #257FE0
            //#173248!important;
    }



      //++ Cover Page
      doc.addImage(imgbr, 'PNG', pageWidth/2 - 90, pageHeight/2 -100, 170, 48);  // 3.53
      doc.setFontSize(20)
      doc.setTextColor(titleColor)
      doc.setFont('normal');
      doc.text(reportTitle, 90 - reportTitle.length, pageHeight/2+40) 




      //++ First Page
      doc.addPage();
      headerFooter();

      doc.setTextColor(colorSectionHeader)  
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(12)
      doc.text(`${locationInfo}`, pageTitleIndent , 22)

      var lines = doc.splitTextToSize(climateScenarioRCP[data.sliderClimate], 120);

      let pageOneStart = 40;
      let pageOneLineHeight = 10;
      let pageOneIndent = 23;
      let pageOneSecondColumn = 83;

      doc.setFontSize(12)
      doc.setTextColor('gray')
      doc.setFont('normal');

      let iii = 0;
      for (const filteritem in filterArray ) { 
        //console.log(filteritem, filterArray[iii], filterArray[iii].filterLabel, filteritem[0])//@ts-ignore
        doc.text(`${filterArray[iii].filterLabel}: `, pageOneIndent , pageOneStart + (pageOneLineHeight*iii))
        iii = iii + 1;
      }

      doc.setTextColor('gray')

      iii = 0;
      for (const filteritem in filterArray ) { //@ts-ignore
        //console.log(filterArray[iii], filterArray[iii].filterValue)
        doc.text(`${filterArray[iii].filterValue} `, pageOneSecondColumn , pageOneStart + (pageOneLineHeight*iii))
        iii = iii + 1;
      }




      //++ Second Page    Canvas
      doc.addPage();
      headerFooter();

      const pageClass = ['GD-Winter', 'GD-Spring', 'GD-Summer', 'GD-Fall', 'GD-Scenario', 'GD-ModelScenario']
      const endpageClass = ['GD-Fall', 'GD-Flow', 'GD-Model', 'mapboxgl-canvas']
      const canvas = document.querySelectorAll("canvas");
          
      function swapElement(array:any, indexA:any, indexB:any) {
        var tmp = array[indexA];
        array[indexA] = array[indexB];
        array[indexB] = tmp;
      }

      //@ts-ignore  
      const canvasList = [...document.querySelectorAll("canvas")];
      let modelHeight = 80;
      let seasonHeight = 130;

      let pageWidthA = 100; 
      let pageHeightA = 200;
      let index = 1;

      // traverse the array of canvas 
      canvasList.forEach( (canva:HTMLCanvasElement) => {

        //console.log(canva.height, canva.width)

        if(canva.height === 0){
          canva.height = 444;
        }
        if(canva.width === 0){
          canva.width = 444;
        }
        
        let imageDimension = 170;
        let aspectRatio = canva.height / canva.width;
        let imageHeight = imageDimension*aspectRatio;

        const className = canva.className;

        let x = 20 + 20*(1/canva.height);
        let y = 30;


        if(className === 'mapboxgl-canvas'){
          //te-map-legend

            x=20;
            y=40;
            aspectRatio = canva.height / canva.width;
            imageHeight = imageDimension*aspectRatio;

/*                doc.setFontSize(12)
            doc.setTextColor('gray')
            doc.setFont('normal');
            doc.text(`Target HUC Location: `, pageOneIndent , pageOneStart)*/

            doc.setTextColor(colorSectionHeader)  
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(12)
            doc.text(`${locationInfo}`, pageTitleIndent , 22)

            //doc.setTextColor(textColor)  
            //doc.setFont('helvetica', 'normal');
            //doc.setFontSize(12)
            //doc.text(`HUC8: ${GlobalProps.selectedHUC8} ${huc12info}`, 59 , 34) 

            let legendStart = imageHeight + 70;  //200;
            let legendLineHeight = 8;
            let legendXStart = 30;

            headerFooter();
        } 
        let cont = canva.getContext('2d');

        aspectRatio = canva.height / canva.width;
        imageHeight = imageDimension*aspectRatio;
      
        if(canva && canva.height > 0){
            doc.setFillColor('#12234f')
            doc.rect(x, y, imageDimension, imageDimension*aspectRatio, 'F') 
            doc.addImage(canva, 'PNG', x, y, imageDimension, imageDimension*aspectRatio, `img${index}`, "NONE");
        }
        if (index < canvas.length) {
          //if(endpageClass.indexOf(className)>-1){                 
              doc.addPage();
          //}
        }
        index++;
      });

      const generateReportData = (data: any[]) => {
        const obj = data[0]
        const keys = Object.keys(obj)
        const reportData = keys.map((key:any) => [`${key}: ${obj[key]}`])

        return reportData
    }

    const examineTable = (input: Element): boolean => {
      // Check if the table reference exists
      if (input) {
          // Get all <tr> elements within the table
          let rows:any = input.querySelectorAll('tr');
          if(rows.length === 2) return true
      }
      
      return false;
  };

    console.log(data)

       //+++ Table
      if(reportElement){
        let tableref:any = document.querySelector('.table')
        let tableDementions = examineTable(tableref)

        if(!tableDementions) {
          if(tableref){
             doc.addPage();
             headerFooter();
             if(reportConfig && reportConfig.printTable && reportConfig.printTable==='horizontalPageBreak'){
                autoTable(doc, { styles: { fontSize:5 }, margin: { top: 30 }, horizontalPageBreak: true, horizontalPageBreakRepeat: 0, html: '.table' })         
             } else {
                autoTable(doc, { styles: { fontSize:5 }, margin: { top: 30 },  html: '.table' })         
             }
           }
        }

        const createAltTable = () => {
          const reportData = generateReportData(data)
          doc.addPage();
          autoTable(doc, {
            head: [[reportConfig.name]],
            body: reportData,
          }) 
         }

        const createDataTable = () => {
          if(data && data.length>0){
            const header = Object.keys(data[0]);
            let newArray: any = []
            data.forEach(function (item: any) {
                newArray.push(Object.values(item))
            })

            doc.addPage();
            autoTable(doc, {
              head: [header],
              body: newArray,//@ts-ignore
              styles: {  fontSize: 5, minCellWidth: 13 },  horizontalPageBreak: true, horizontalPageBreakRepeat: 0, 
            })            
          }
         }

         if(tableDementions) {
          createAltTable()
         }

         if(!tableref && data.length === 1){
          createAltTable()
        }

        if(!tableref && data.length > 1){
          createDataTable()
        }
        
      }
      

      //++ End Page
      doc.addPage();
      headerFooter();
             
      doc.setTextColor(textColor)  
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(12)
      doc.text(`Source: ${reportConfig.sourceText}`, pageTitleIndent , 32)          

      doc.setTextColor(textColor)  
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(8)
      doc.text(`${reportConfig.sourceLink}`, pageTitleIndent , 42) 




       //+++ End 
      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp)
      }

      doc.setProperties({ title: reportTitle })
      window.open(doc.output('bloburl'), '_blank');
  }



export const DefaultReport = (data: any, reportConfig: any, GlobalProps:any, reportElement:any) => {   
  console.log(data)
  if(data){
    onPDF(data, reportConfig, GlobalProps, reportElement);
  }
}