import {
    useEffect,
    useState,
    useMemo
} from 'react'
import {
    Col,
    Row,
    Button,
    Form
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPenToSquare
} from '@fortawesome/pro-solid-svg-icons';
import localForage from "localforage";
import ReportTable from '../../reports/shared/ReportTable'
import EditAdminRow from './EditAdminRow'
import FormInputField from './FormInputField';
import { getGeneralApplicationSettingsStore, setGeneralApplicationSettingsStore } from './lib/store';

const Administration = (props:any) => {

    const {
        settingsData,
        setSettingsData
    } = props

    const [activeIndex, setActiveIndex] = useState<string>('');

    const updateMon = (property: any, value:any) => {
        let newData = [...settingsData];      
        const target = newData[+activeIndex];
        if(target){    
            let changeMon = target;
            changeMon[property]=value;
            setSettingsData(newData)
        }
    }

    const newSetting = () => {
        let newData = [...settingsData];
        //@ts-ignore
        const nextId = '' + (Math.max(...settingsData.map(o => +o.id)) + 1);
        newData.push({
            id: '' + nextId,
            settingName: 'New Setting',
            settingValue: '',
            actions: '12345'
        })
        setSettingsData(newData)
        setActiveIndex( '' + nextId);
    }


    const tableColumns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Setting Name',
                accessor: 'settingName',
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    // @ts-ignore
                    return <FormInputField
                        key={'settingName'}
                        type="text"
                        id={"settingName"}
                        currentIndex={index}
                        activeIndex={activeIndex && activeIndex}
                        value={props.value}
                        onChange={(e: any) => updateMon('settingName', e.target.value)}
                    />
                }
            },
            {
                Header: 'Setting Value',
                accessor: 'settingValue',
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    // @ts-ignore
                    return <FormInputField
                        key={'settingValue'}
                        type="text"
                        currentIndex={index}
                        activeIndex={activeIndex && activeIndex}
                        value={props.value}
                        onChange={(e: any) => updateMon('settingValue', e.target.value)}
                    />
                }
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    // @ts-ignore
                    return <EditAdminRow
                        key={'Actions'+index+activeIndex}
                        setActiveIndex={setActiveIndex}
                        activeIndex={activeIndex}
                        currentIndex={index}
                    />
                }
            }
        ],
        [activeIndex]
    );



    return (
        <div className="admin-container">
            <div className="header-box">
                <h2>General Application Settings</h2>
                <div className="button-box">
                    <Button id="addNew-btn" className="p-3 btn btn-primary m-2"                                    
                        onClick={() => {
                             newSetting();
                                        //setAction('table')
                                    }}>
                        Add New
                    </Button>
                </div>
            </div>
            <div className="admin-settings-table">
                <Row style={{
                    paddingLeft: '12px',
                    paddingRight: '18px',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                }}>
                    <div className="danger-box" style={{display: 'none'}}>
                        <span className="warning-icon">
                            i
                        </span>
                        <span>
                            
                        </span>
                    </div>
                </Row>
                <ReportTable columns={tableColumns} data={settingsData} />
            </div>
        </div>
    )
}

export default Administration