import html2canvas from 'html2canvas';
import { jsPDF, ImageOptions } from "jspdf";
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import settings from "../../../settings.json";
import { DateTime } from 'luxon';

const colorGreen = "#4fc22c";
const colorYellow = "#e6ee00"; 
const colorYellowDarker = "#d3da00";  //255,255,200 #FFFFC8
const colorYellowFill = "#FFFFC8";  //255,255,200 
const colorRed = "#c22c4f";
const colorUnavailable = "#3c343c";
const colorWhite = "#ffffff";
const colorLightGray = "#717171";
const colorSectionHeader = "#191919";
const textFooter = colorLightGray;
const locationField = 'name';
const reportTitle = "True QI Disclosure Risk Report Scores";
const logoSource = "logo490.png"; //'logo.png'


const onPDF = (data: any, data2:any, data3:any, mapimage:any, industry:any) => {
	const textColor = '#257ee0';
	const pageY = 6;
	const logoRight = 60;
	const mainMinCellHeight = 50.5;//50.5
	const rowcolor = [[244, 244, 244], [255,255,255], [244, 244, 244], [255,255,255], [244, 244, 244]];

	var doc = new jsPDF()
	let reportID = 'True Elements ' + reportTitle;
	var totalPagesExp = '{total_pages_count_string}'

	var imgbr = new Image();
	imgbr.src = logoSource;


	  const greenMatch = ['Not officially rated as being in a drought', 'AREA WITH REDUCED FLOOD RISK DUE TO LEVEE', '0.2 PCT ANNUAL CHANCE FLOOD HAZARD', 'Increased by less than 10%', 'No PFAS detected', 'Low (<10%)', 'Low - Medium (1 in 1,000 to 2 in 1,000)', 'Low - Medium (10-20%)', 'Low (0 to 1 in 1,000)', 'Low - Medium (1 in 1,000 to 2 in 1,000)', 'Low (0 to 9 in 1,000,000)', 'Low - Medium (9 in 1,000,000 to 7 in 100,000)', 'No Superfund sites within the watershed', 'Not in Protected area', 'Not rated as being in a drought', 'Not officially rated as being in a drought', 'Abnormally Dry (D0)', 'Minimal Flood Hazard'];
	  const yellowMatch = ['Risk has increased by 10% to 25%', 'Superfund watershed directly feeds.', 'PFAS detected in adjacent', 'Medium - High (20-40%)', 'Medium - High (2 in 1,000 to 6 in 1,000)', 'Medium - High (20-40%)', 'Superfund site in watershed that directly feeds this one.', 'Moderate Drought (D1)'];
	  const redMatch = ['Located within Protected Area', 'Superfund watershed site', 'Precipitation Risk is greater than 25%', 'PFAS detected', 'High (6 in 1,000 to 1 in 100)', 'Extremely High (>80%)', 'High (40-80%)', 'Extremely High (more than 2 in 1,000)', 'High (6 in 1,000 to 1 in 100)', 'Extremely High (more than 2 in 1,000)', 'Superfund site has been detected within this watershed.', 'Severe Drought (D2)', 'Extreme Drought (D3)', 'Exceptional Drought (D4)', 'High Flood Hazard'];
	  const unavailableMatch = ['aaa', 'No Score available'];

  let sortField = 'sasbrefid';
  let sasb = [...data3].sort((x: any, y: any) => x[sortField].localeCompare(y[sortField]));

	const secTableBody = [] as any[];
	const secTableBody2 = [] as any[];
	const secTableBody3 = [] as any[];
	const detail1 = [] as any[];
	const detail2 = [] as any[];
	const detail3 = [] as any[];
	const detail4 = [] as any[];
	const detail1Columns = [{ dataKey: 'Site', header: 'Site' }] as any[];
	const detail2Columns = [{ dataKey: 'Site', header: 'Site' }] as any[];
	const detail3Columns = [{ dataKey: 'Site', header: 'Site' }] as any[];
	const detail4Columns = [{ dataKey: 'Site', header: 'Site' }] as any[];
	const attr = [] as any[];

	const reportTabsNew = [...data2];
	const lengthData = data.length; 

	const rectData = {};

	for (let dataReport of reportTabsNew) {   
	  if(dataReport.title !== 'Overview'){
	      let fData = data.filter((o: any) => o[dataReport.title] === colorGreen);
	      let percentGreen = '' + Math.round((fData.length/lengthData)*100);
	      dataReport["resultgreen"] = `${fData.length} sites (${percentGreen}%)`;
	      fData = data.filter((o: any) => o[dataReport.title] === colorYellow);
	      let percentYellow =  '' + Math.round((fData.length/lengthData)*100);            
	      dataReport["resultyellow"] = `${fData.length} sites (${percentYellow}%)`;
	      fData = data.filter((o: any) => o[dataReport.title] === colorRed);
	      let percentRed =  '' + Math.round((fData.length/lengthData)*100);
	      dataReport["resultred"] = `${fData.length} sites (${percentRed}%)`;
	      let titleplus = dataReport["title"].substr(0,10);
	      //@ts-ignore
	      rectData[titleplus] = {title: dataReport.title, green: percentGreen, yellow: percentYellow, red: percentRed};

	  }
	}   

	 if(reportTabsNew && reportTabsNew.length > 0){
	        let i = 0;
	        for (let dataItem of reportTabsNew) {
	            if(dataItem.title!=='Overview'){               
	                let secS = [] as any[];
	                secS.push({result : '', resulttext : dataItem["text"]});
	                secS.push({result : dataItem["resultgreen"], resulttext : dataItem["textgreen"]});
	                secS.push({result : dataItem["resultyellow"], resulttext : dataItem["textyellow"]});
	                secS.push({result : dataItem["resultred"], resulttext : dataItem["textred"]});
	                 if (i < 5){                            
	                       secTableBody.push(secS);
	                 }
	                i++;
	            }
	        }

	        i = 0;
	        for (let dataItem of reportTabsNew) {
	            if(dataItem.title!=='Overview'){
	                let secS = [] as any[];
	                secS.push({result : '', resulttext : dataItem["text"]});
	                secS.push({result : dataItem["resultgreen"], resulttext : dataItem["textgreen"]});
	                secS.push({result : dataItem["resultyellow"], resulttext : dataItem["textyellow"]});
	                secS.push({result : dataItem["resultred"], resulttext : dataItem["textred"]});
	                if (i < 10 && i > 4){
	                     secTableBody2.push(secS);
	                }
	                i++;
	            }
	        }

	        i = 0;
	        for (let dataItem of reportTabsNew) {
	            if(dataItem.title!=='Overview'){
	                let secS = [] as any[];
	                secS.push({result : '', resulttext : dataItem["text"]});
	                secS.push({result : dataItem["resultgreen"], resulttext : dataItem["textgreen"]});
	                secS.push({result : dataItem["resultyellow"], resulttext : dataItem["textyellow"]});
	                secS.push({result : dataItem["resultred"], resulttext : dataItem["textred"]});
	                if (i < 15 && i > 9){
	                     secTableBody3.push(secS);
	                }
	                i++;
	            }
	        }

	        i = 0;
	        for (let dataItem of reportTabsNew) {
	            if(dataItem.title!=='Overview'){
	                if (i < 3){  
	                   detail1Columns.push({dataKey: dataItem.title, header: dataItem.title})
	                   //detail1Columns.push({dataKey: dataItem.title+'1', header: ''})
	                }
	                if (i < 6 && i > 2){
	                  detail2Columns.push({dataKey: dataItem.title, header: dataItem.title})
	                  //detail2Columns.push({dataKey: dataItem.title+'1', header: ''})            
	                }
	                if (i < 9 && i > 5){
	                  detail3Columns.push({dataKey: dataItem.title, header: dataItem.title})
	                  //detail3Columns.push({dataKey: dataItem.title+'1', header: ''})              
	                }
	                if (i < 11 && i > 8){
	                  detail4Columns.push({dataKey: dataItem.title, header: dataItem.title})
	                  //detail4Columns.push({dataKey: dataItem.title+'1', header: ''})             
	                }
	                i++;
	            }               
	        }

	        for (let dataI of data) {
	              const detailBody1 = [] as any[];
	              const detailBody2 = [] as any[];
	              const detailBody3 = [] as any[];
	              const detailBody4 = [] as any[];
	              detailBody1.push(dataI["name"]);
	              detailBody2.push(dataI["name"])
	              detailBody3.push(dataI["name"])
	              detailBody4.push(dataI["name"])
	              i = 0;
	              for (let dataItem of reportTabsNew) {
	                  if(dataItem.title!=='Overview'){
	                      if (i < 3){  
	                         if( dataItem.title === 'Site Flood Zone Ratings'&& dataI[dataItem.title + "result"] ==='Minimal Flood Hazard' && dataI['ra_' + dataItem.title + '_supfield2'] && dataI['ra_' + dataItem.title + '_supfield2']!== 'AREA OF MINIMAL FLOOD HAZARD'){
	                            // detailBody1.push(dataI['ra_' + dataItem.title + '_supfield2']); 
	                         } else {
	                             detailBody1.push(dataI[dataItem.title + "result"]);    
	                         }  
	                        //detailBody1.push(dataI['ra_' + dataItem.title + '_supfield1']);   
	                      }
	                      if (i < 6 && i > 2){
	                           detailBody2.push(dataI[dataItem.title + "result"]);
	                           //detailBody2.push(dataI['ra_' + dataItem.title + '_supfield1']);        
	                      }
	                      if (i < 9 && i > 5){
	                           detailBody3.push(dataI[dataItem.title + "result"]);
	                           //detailBody3.push(dataI['ra_' + dataItem.title + '_supfield1']);             
	                      }
	                      if (i < 11 && i > 8){
	                           detailBody4.push(dataI[dataItem.title + "result"]);
	                           //detailBody4.push(dataI['ra_' + dataItem.title + '_supfield1']);             
	                      }
	                      i++;                       
	                  }
	              }
	              detail1.push(detailBody1);
	              detail2.push(detailBody2);
	              detail3.push(detailBody3);
	              detail4.push(detailBody4);
	        }          

	        i = 0;
	        for (let dataItem of reportTabsNew) {
	            if(dataItem.title!=='Overview'){
	                //let secS = [] as any[];
	                attr.push([dataItem["title"], dataItem["attribution"], dataItem["attribution_url"]]);
	                 if(dataItem["attribution2"]){
	                   attr.push([' ', dataItem["attribution2"], dataItem["attribution_url2"]]);  
	                 }


	                //if (i < 10 && i > 4){
	                //attr.push(secS);    
	                //}
	                i++;
	            }
	        }

	  }
	  //#257ee0 //rgba(37,126,224,255)


	  const tableTLcategory1 = [] as any[];
	  const tableTLcategory2 = [] as any[];
	  const tableTLcategory3 = [] as any[];

	  //[dataItem["title"]+ '\r\n ' +' \r\n' + dataItem["sasbref"]
	  //dataItem["text"] + '\r\n \r\n' + dataItem["textgreen"] + '\r\n \r\n' + dataItem["textyellow"]+ '\r\n \r\n' + dataItem["textred"]
	  if(reportTabsNew && reportTabsNew.length > 0){
	    let i = 0;
	        for (let dataItem of reportTabsNew) {
	          if(dataItem.title!=='Overview'){ //,  '',  '', dataItem["sasbref"]
	            if(i<5){
	              //@ts-ignore
	                const detailT1 = [] as any[];
	                detailT1.push( [dataItem["title"], ''])
	                detailT1.push([''])
	                detailT1.push([''])
	                detailT1.push([''])
	                //detailT1.push([''])
	                let sasbref = '';
	                 if(dataItem["sasbrefid"] && dataItem["sasbrefid"].length>0){
						sasbref =  'SASB: '+ industry.substr(0,2) + '-' + industry.substr(2,2) + '-'  + dataItem["sasbrefid"];
	                 }
	                detailT1.push([sasbref])

	                tableTLcategory1.push(detailT1);
	            }
	            if(i>4 && i<10){

	              //@ts-ignore
	                const detailT1 = [] as any[];
	                detailT1.push( [dataItem["title"], ''])
	                detailT1.push([''])
	                detailT1.push(['']) 
	                detailT1.push([''])
	                //detailT1.push([''])
	                let sasbref = '';
	                 if(dataItem["sasbrefid"] && dataItem["sasbrefid"].length>0){
						sasbref =  'SASB: '+ industry.substr(0,2) + '-' + industry.substr(2,2) + '-'  + dataItem["sasbrefid"];
	                 }
	                detailT1.push([sasbref])


	                tableTLcategory2.push(detailT1);
	            }
	            if(i>9 && i<15){
	            	 
	              //@ts-ignore
	                const detailT1 = [] as any[];
	                detailT1.push( [(dataItem["title"] === 'Water Usage') ? 'Water Management' : dataItem["title"], ''])

			        if(dataItem["fieldsSelected"]&&(dataItem["title"] === 'Water Management' || dataItem["title"] === 'Ingredient Sourcing')){    
				        let ii=0;  
				        for (let dataIm of dataItem["fieldsSelected"]) { 
				            if(dataIm.title !== 'Discharged'){
	/*			                    doc.setTextColor(colorSectionHeader)  
				                    doc.setFont('helvetica', 'normal');
				                    doc.setFontSize(10)*/
				                    let lines2 = doc.splitTextToSize(dataItem['resultredcalc'] && dataItem['resultredcalctop'][ii] +  ' of ' + ((dataItem["title"] === 'Water Management') ? 'Water ' : '') + dataItem["fieldsSelected"][ii].title + ' ' + ((dataItem["title"] === 'Water Management') ? 'is' : 'are') + ' in a basin with High or Extremely High Baseline Water Stress', 165);				                   
				                detailT1.push([dataItem['resultredcalc'] && dataItem['resultredcalctop'][ii] +  ' of ' + ((dataItem["title"] === 'Water Management') ? 'Water ' : '') + dataItem["fieldsSelected"][ii].title + ' ' + ((dataItem["title"] === 'Water Management') ? 'is' : 'are') + ' in a basin with High or Extremely High Baseline Water Stress'])
				                ii++   
				            }  			              
				        }     
			            //let remaining = 4 - ii;
			            while (ii < 4) {
			          		//detailT1.push([''])
						  ii++;
						}
			        } else {
			        	detailT1.push([''])
		                detailT1.push(['']) 
		                detailT1.push([''])
		                detailT1.push([''])	
			        }

	                let sasbref = '';
	                 if(dataItem["sasbrefid"] && dataItem["sasbrefid"].length>0){
						sasbref =  'SASB: '+ industry.substr(0,2) + '-' + industry.substr(2,2) + '-'  + dataItem["sasbrefid"];
	                 }
	                detailT1.push([sasbref])


	                tableTLcategory3.push(detailT1);
	            }

	            i++
	          }
	        }
	  }

	  const tableGrid = [] as any[];
	  const tableGridColumns = [] as any[];
	  for (let dataItem of data) {
	      const tableGridRow = [] as any[];
	      tableGridRow.push(dataItem["name"]);
	      let i = 0;
	      for (let dataI of reportTabsNew) {
	        if(dataI.title!=='Overview'){
	          tableGridRow.push(dataItem[dataI.title]);
	        }
	      }
	      tableGrid.push(tableGridRow);
	  }

	  tableGridColumns.push({dataKey: 'name', header: 'Site'});
	  for (let dataI of reportTabsNew) {
	    if(dataI.title!=='Overview'){
	      tableGridColumns.push({dataKey: dataI.title, header: dataI.title});
	    }
	  }

	  const shortenText = (original: any, category:any) => {
	  	let returnText = "";
	  	if(original){
	  		if(original === 'Superfund site in watershed that directly feeds this one.'){
	  			returnText = 'Superfund watershed directly feeds.'
	  		} else if (original === 'Superfund site has been detected within this watershed.'){
	  			returnText = 'Superfund watershed site'
	  		} else {
	  			returnText = original;
	  		}
	  		
	  	} 
	  	return returnText;
	  }


    
	  const tableGrid_min = [] as any[];
	  const tableGridColumns_min = [] as any[];
	  for (let dataItem of data) {
	      const tableGridRow = [] as any[];
	      tableGridRow.push(dataItem["name"]);
	      let i = 0;	
	      for (let dataI of reportTabsNew) {
	        if(dataI.title!=='Overview'){
	        	if(dataItem[dataI.title] !== colorGreen && dataItem[dataI.title] !== colorUnavailable){
	        		let textResult = shortenText(dataItem[dataI.title+'result'], dataI.title);
	        		tableGridRow.push(textResult);
	        	} else if(dataItem[dataI.title] === colorGreen){
	        		tableGridRow.push('-');
	        	} else {
	        		tableGridRow.push('');
	        	}
	        }
	      }
	      tableGrid_min.push(tableGridRow);
	  }

	  tableGridColumns_min.push({dataKey: 'name', header: 'Site'});
	  for (let dataI of reportTabsNew) {
	    if(dataI.title!=='Overview'){
	      tableGridColumns_min.push({dataKey: dataI.title, header: dataI.title});
	    }
	  }


		var pageSize = doc.internal.pageSize;
		var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
		var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

		let marginLeft = 10;

	  	const headerFooter = () => {
	        // Header
	        doc.setFontSize(10)
	        doc.setTextColor(textColor)
	        doc.setFont('normal');
	        doc.text(reportTitle, marginLeft, 10)

	        // Footer
	        var str = 'Page ' + doc.getNumberOfPages()            
	        if (typeof doc.putTotalPages === 'function') {
	          str = str + ' of ' + totalPagesExp
	        }
	      
	        doc.setTextColor(textFooter)
	        doc.text(str, marginLeft, pageHeight - pageY)
	        doc.addImage(imgbr, 'PNG', pageWidth - logoRight + 15, 3, 35.3, 10);             //1730 x 490    3.53
	        var date = new Date();
	        doc.text(date.toLocaleDateString(), pageWidth - 25, pageHeight - pageY);
    	}



	  //@ts-ignore
	  let reportColumns = { 
	      columns: [
	          { dataKey: 'Resiliency Risk Categories', header: 'Resiliency Risk Categories' },
	          { dataKey: 'rect', header: '' },
	          { dataKey: 'Site Assessment', header: 'Site Assessment' },
	        ],
	      body: [],
	      columnStyles: {
	            2: { cellWidth: 130, minCellHeight: mainMinCellHeight },
	            1: { cellWidth: 22, minCellHeight: mainMinCellHeight },
	            //@ts-ignore
	            0: { cellWidth: 40},
	      },
	      
	      margin: { top: 15, left: 10, bottom: 9 },
	      didDrawPage: function (data:any) {
	      	  headerFooter();	
	      },
	      didDrawCell: function (data:any) {
	      if (data.row.section === 'body' && data.column.dataKey === 'Resiliency Risk Categories') {
	        autoTable(doc, {
	          startY: data.cell.y + 1,
	          margin: { left: data.cell.x + 2 },
	          tableWidth: 40,
	          columnStyles: {
	            0: { cellWidth: 40},
	          },
	          body:  tableTLcategory1[data.row.index],
	          alternateRowStyles: {//@ts-ignore
	            fillColor: rowcolor[data.row.index],
	          },
	          bodyStyles: {//@ts-ignore
	            fillColor: rowcolor[data.row.index],       
	          },
	          willDrawCell: function (data_:any) {
	           if (data_.row.section === 'body' && data_.row.index === 0) {//@ts-ignore
	                doc.setFont('helvetica','bold'); 
	                doc.setFontSize(10)
	            } else if (data_.row.section === 'body' && data_.row.index === 1) {
	                 doc.setFont('normal');  
	            } else if (data_.row.section === 'body' && data_.row.index === 2) {
	                 doc.setFont('normal');  
	            } else if (data_.row.section === 'body' && data_.row.index === 4) {
	                doc.setFontSize(9)//@ts-ignore
	                doc.setFont('helvetica','italic'); 
	            }
	          },
	        })
	      }

	      if (data.row.section === 'body' && data.column.dataKey === 'Site Assessment') {
	        autoTable(doc, {
	          startY: data.cell.y + 1,
	          margin: { left: data.cell.x + 2 },
	          tableWidth: data.cell.width - 4,
	          columns: [   
	            { dataKey: 'result', header: ''},
	            { dataKey: 'resulttext', header: ''}
	          ], 
	          body: secTableBody[data.row.index],
	          columnStyles: {
	            result: { cellWidth: 28},
	            resulttext: { cellWidth: 100 },
	          },
	          alternateRowStyles: {//@ts-ignore
	            fillColor: rowcolor[data.row.index],
	          },
	          bodyStyles: {//@ts-ignore
	            fillColor: rowcolor[data.row.index], 
	            halign: 'left',      
	          },
	          styles: { cellPadding: 1.1 },
	          willDrawCell: function (data_:any) {
	           if (data_.row.section === 'body' && data_.row.index === 1) {
	              //if (data.cell.raw > 750) {
	                doc.setTextColor(colorGreen)  
	            } else if (data_.row.section === 'body' && data_.row.index === 2) {
	                doc.setTextColor(colorYellowDarker) 
	            } else if (data_.row.section === 'body' && data_.row.index === 3) {
	                doc.setTextColor(colorRed) 
	            }
	          },
	        })
	      }


	        if (data.row.section === 'body' && data.column.dataKey === 'rect') {
	            let rectChartLength = 37;
	            let rectChartX = data.cell.x + 14;  
	            let rectChartYStart = 7;    
	            let rectChartXWide = 4;
	            //@ts-ignore 
	            let rowData = rectData[data.row.raw[0].substr(0,10)];      
	            if(rowData){
	                  let yChangeGreen = rectChartLength*(.01 * rowData.green);
	                  doc.setFillColor(colorGreen);
	                  doc.rect(rectChartX , data.cell.y + rectChartYStart, rectChartXWide, yChangeGreen, 'F')
	                  doc.setFillColor(colorYellow);
	                  let yChangeYellow = rectChartLength*(.01 * rowData.yellow);
	                  doc.rect(rectChartX , data.cell.y + rectChartYStart + yChangeGreen, rectChartXWide, yChangeYellow, 'F')
	                  doc.setFillColor(colorRed);
	                  if(rowData.red > 0){
	                        let yChangeRed = rectChartLength*(.01 * rowData.red);
	                        doc.rect(rectChartX, data.cell.y + rectChartYStart + yChangeGreen + yChangeYellow, rectChartXWide, yChangeRed, 'F')
	                  }
	            }
	          }



	    },
	  };
	  //[dataItem["title"]+ '\r\n ' +' \r\n' + dataItem["sasbref"]
	  //dataItem["text"] + '\r\n \r\n' + dataItem["textgreen"] + '\r\n \r\n' + dataItem["textyellow"]+ '\r\n \r\n' + dataItem["textred"]
	  //dataItem["title"]
	  if(reportTabsNew && reportTabsNew.length > 0){
	    let i = 0;
	        for (let dataItem of reportTabsNew) {
	          if(dataItem.title!=='Overview'){
	            if(i<5){
	              //@ts-ignore
	                  reportColumns.body.push([ dataItem["title"],  ' ', '']);
	            }
	            i++
	          }

	        }
	    }


	    //++ Cover Page
/*	    var pageSize = doc.internal.pageSize
	    var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
	    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()*/
	    doc.addImage(imgbr, 'PNG', pageWidth/2 - 90, pageHeight/2 -100, 170, 48);  // 3.53
	    doc.setFontSize(20)
	    doc.setTextColor(textColor)
	    doc.setFont('normal');
	    doc.text(reportTitle, 52, pageHeight/2+40) 
	    doc.addPage();


	    //++ Table Grid
	  const greenGridMatch = ["#4fc22c"];
	  const yellowGridMatch = ["#e6ee00"];
	  const redGridMatch = ["#c22c4f"];
	  const unavailableGridMatch = ["#3c343c"];
	  const rectGridCellHeight = 3;

	  let reportColumnsGrid = { 
	      columns: tableGridColumns,
	      body: tableGrid,
	      styles: { cellPadding: 0.4, fontSize: 6, minCellHeight: 7 }, //@ts-ignore
	      headStyles: { minCellHeight: 9, halign: 'center', valign: 'middle' },
	      willDrawCell: function (data:any) {
	          if (data.row.section === 'body' && greenGridMatch.indexOf(data.cell.raw) > -1) {                    
	              doc.setTextColor(colorGreen);
	              doc.setFontSize(0)
	          } else if (data.row.section === 'body' && yellowGridMatch.indexOf(data.cell.raw) > -1) {                                          
	              doc.setTextColor(colorYellow);
	              doc.setFontSize(0)
	          } else if (data.row.section === 'body' && redGridMatch.indexOf(data.cell.raw) > -1) {                    
	              doc.setTextColor(colorRed);
	              doc.setFontSize(0)
	          } else if (data.row.section === 'body' && unavailableGridMatch.indexOf(data.cell.raw) > -1) {                    
	              doc.setTextColor(colorUnavailable);
	              doc.setFontSize(0)
	          }
	      }, 
	      didDrawCell: function (data:any) {
	          if (data.row.section === 'body' && greenGridMatch.indexOf(data.cell.raw) > -1) {                                  
	              doc.setFillColor(colorGreen);
	              doc.rect(data.cell.x + (data.cell.width - 7)/2 , data.cell.y + (data.cell.height - rectGridCellHeight)/2 , 7, rectGridCellHeight, 'F')
	          } else if (data.row.section === 'body' && yellowGridMatch.indexOf(data.cell.raw) > -1) {                    
	              doc.setFillColor(colorYellow);
	              doc.rect(data.cell.x + (data.cell.width - 7)/2 , data.cell.y + (data.cell.height - rectGridCellHeight)/2 , 7, rectGridCellHeight, 'F') ;
	          } else if (data.row.section === 'body' && redGridMatch.indexOf(data.cell.raw) > -1) {                    
	              doc.setFillColor(colorRed);
	              doc.rect(data.cell.x + (data.cell.width - 7)/2 , data.cell.y + (data.cell.height - rectGridCellHeight)/2 , 7, rectGridCellHeight, 'F') ;
	          }
	      },    
	      didDrawPage: function (data:any) {
	        headerFooter();

	        doc.setTextColor(colorSectionHeader)
	        doc.setFont('helvetica', 'normal');
	        doc.setFontSize(16)
	        doc.text('Assessment Grid', pageWidth/2-19, 20) 
	      },
	      margin: { top: 25, left: 10, bottom: 14, right: 10 },
	  };


	  let reportColumnsGrid_min = { 
	      columns: tableGridColumns_min,
	      body: tableGrid_min,//@ts-ignore
	      styles: { cellPadding: {top: .1, right: .5, bottom: .1, left: .5}, fontSize: 6, minCellHeight: 7,  halign: 'center', valign: 'middle' }, //@ts-ignore
	      headStyles: { minCellHeight: 9, halign: 'center', valign: 'middle' },
	      willDrawCell: function (data:any) {
	          if (data.row.section === 'body' && greenMatch.indexOf(data.cell.raw) > -1) {                    
	                doc.setTextColor(colorGreen)  
	          } else if (data.row.section === 'body' && yellowMatch.indexOf(data.cell.raw) > -1) {                    
	                doc.setTextColor(colorYellowDarker)  
	          } else if (data.row.section === 'body' && redMatch.indexOf(data.cell.raw) > -1) {                    
	                doc.setTextColor(colorRed)  
	          } else if (data.row.section === 'body' && unavailableMatch.indexOf(data.cell.raw) > -1) {                    
	                doc.setTextColor(colorUnavailable)  
	          } else if (data.row.section === 'body' && data.column.dataKey === 'Site Surface Water Quality Threshold' && !isNaN(parseInt(data.cell.raw)) && parseInt(data.cell.raw) > 89 && parseInt(data.cell.raw) < 111) {                    
	                doc.setTextColor(colorGreen)  
	          } else if (data.row.section === 'body' && data.column.dataKey === 'Site Surface Water Quality Threshold' && !isNaN(parseInt(data.cell.raw)) && parseInt(data.cell.raw) > 79 && parseInt(data.cell.raw) < 90) {                    
	                doc.setTextColor(colorYellowDarker)  
	          } else if (data.row.section === 'body' && data.column.dataKey === 'Site Surface Water Quality Threshold' && !isNaN(parseInt(data.cell.raw)) && parseInt(data.cell.raw) > 1 && parseInt(data.cell.raw) < 80) {                    
	                doc.setTextColor(colorRed)  
	          } else if (data.row.section === 'body' && data.cell.raw === '-') {                    
	                doc.setTextColor(colorGreen)   
	          }
	      },      
	      didDrawPage: function (data:any) {
	        headerFooter();  
	        

/*	        doc.setTextColor(colorSectionHeader)
	        doc.setFont('helvetica', 'normal');
	        doc.setFontSize(16)
	        doc.text('Assessment', pageWidth/2-14, 20) */

	      },
	      margin: { top: 25, left: 10, bottom: 14, right: 10 },
	  };


	    //++ Table TrafficLight Part 1
	    autoTable(doc, reportColumns)


	    //++ Table TrafficLight Part 2
	    reportColumns = { 
	      columns: [
	          { dataKey: 'Resiliency Risk Categories', header: 'Resiliency Risk Categories' },
	          { dataKey: 'rect', header: '' },
	          { dataKey: 'Site Assessment', header: 'Site Assessment' },
	        ],
	      body: [],
	      columnStyles: {
	            2: { cellWidth: 135, minCellHeight: mainMinCellHeight },
	            1: { cellWidth: 18, minCellHeight: mainMinCellHeight },
	            //@ts-ignore
	            0: { cellWidth: 37},
	      },//@ts-ignore
	      //styles: { cellPadding: {right: 0} }, //styles: {cellPadding:{top: 1, right: 0, bottom: 1, left: 1}}
	      margin: { top: 15, left: 10, bottom: 9 },
	      didDrawPage: function (data:any) {
	        headerFooter();
	      },
	      didDrawCell: function (data:any) {
	      if (data.row.section === 'body' && data.column.dataKey === 'Resiliency Risk Categories') {
	        autoTable(doc, {
	          startY: data.cell.y + 1,
	          margin: { left: data.cell.x + 2 },
	          tableWidth: 37,
	          columnStyles: {
	            0: { cellWidth: 37},
	          },
	          body:  tableTLcategory2[data.row.index],
	          alternateRowStyles: {//@ts-ignore
	            fillColor: rowcolor[data.row.index],
	          },
	          bodyStyles: {//@ts-ignore
	            fillColor: rowcolor[data.row.index],       
	          },
	          willDrawCell: function (data_:any) {
	           if (data_.row.section === 'body' && data_.row.index === 0) {//@ts-ignore
	                doc.setFont('helvetica','bold'); 
	                doc.setFontSize(10)
	            } else if (data_.row.section === 'body' && data_.row.index === 1) {
	                 doc.setFont('normal');  
	            } else if (data_.row.section === 'body' && data_.row.index === 2) {
	                 doc.setFont('normal');  
	            } else if (data_.row.section === 'body' && data_.row.index === 4) {
	                doc.setFontSize(9)//@ts-ignore
	                doc.setFont('helvetica','italic'); 
	            }
	          },
	        })
	      }

	      if (data.row.section === 'body' && data.column.dataKey === 'Site Assessment') {
	        autoTable(doc, {
	          startY: data.cell.y + 1,
	          margin: { left: data.cell.x + 2 },
	          tableWidth: data.cell.width - 4,
	          columns: [   
	            { dataKey: 'result', header: ''},
	            { dataKey: 'resulttext', header: ''}
	          ], 
	          body: secTableBody2[data.row.index],
	          columnStyles: {
	            result: { cellWidth: 28},  
	            resulttext: { cellWidth: 101 },
	          },
	          alternateRowStyles: {//@ts-ignore
	            fillColor: rowcolor[data.row.index],
	          },
	          bodyStyles: {//@ts-ignore
	            fillColor: rowcolor[data.row.index],  
	            halign: 'left',      
	          },
	          styles: { cellPadding: {top: 1, right: 0, bottom: 1, left: .5} },//{top: number, right: number, bottom: number, left: number}
	          willDrawCell: function (data_:any) {
	           if (data_.row.section === 'body' && data_.row.index === 1) {
	              //if (data.cell.raw > 750) {
	                doc.setTextColor(colorGreen)  
	            } else if (data_.row.section === 'body' && data_.row.index === 2) {
	                doc.setTextColor(colorYellowDarker) 
	            } else if (data_.row.section === 'body' && data_.row.index === 3) {
	                doc.setTextColor(colorRed) 
	            }
	          },
	        })
	      }


	        if (data.row.section === 'body' && data.column.dataKey === 'rect') {
	            let rectChartLength = 37;
	            let rectChartX = data.cell.x + 14;  
	            let rectChartYStart = 7;    
	            let rectChartXWide = 4;
	            //@ts-ignore 
	            let rowData = rectData[data.row.raw[0].substr(0,10)];      
	            if(rowData){
	                  let yChangeGreen = rectChartLength*(.01 * rowData.green);
	                  doc.setFillColor(colorGreen);
	                  doc.rect(rectChartX , data.cell.y + rectChartYStart, rectChartXWide, yChangeGreen, 'F')
	                  doc.setFillColor(colorYellow);
	                  let yChangeYellow = rectChartLength*(.01 * rowData.yellow);
	                  doc.rect(rectChartX , data.cell.y + rectChartYStart + yChangeGreen, rectChartXWide, yChangeYellow, 'F')
	                  doc.setFillColor(colorRed);
	                  if(rowData.red > 0){
	                        let yChangeRed = rectChartLength*(.01 * rowData.red);
	                        doc.rect(rectChartX, data.cell.y + rectChartYStart + yChangeGreen + yChangeYellow, rectChartXWide, yChangeRed, 'F')
	                  }
	            }
	          }



	    },
	  };

	  if(reportTabsNew && reportTabsNew.length > 0){
	    reportColumns.body = [];
	    let i = 0;
	    for (let dataItem of reportTabsNew) {
	      if(dataItem.title!=='Overview'){
	        if(i<10 && i > 4){
	          //@ts-ignore
	              reportColumns.body.push( [dataItem["title"],  '',  '']);
	        }
	        i++
	      }
	    }
	  }
	  autoTable(doc, reportColumns)


	   //++ Table TrafficLight Part 3
	   if(reportTabsNew && reportTabsNew.length > 11){


			reportColumns = { 
				      columns: [
				          { dataKey: 'Resiliency Risk Categories', header: 'Resiliency Risk Categories' },
				          { dataKey: 'rect', header: '' },
				          { dataKey: 'Site Assessment', header: 'Site Assessment' },
				        ],
				      body: [],
				      columnStyles: {
				            2: { cellWidth: 135, minCellHeight: mainMinCellHeight },
				            1: { cellWidth: 18, minCellHeight: mainMinCellHeight },
				            //@ts-ignore
				            0: { cellWidth: 37},
				      },//@ts-ignore
				      //styles: { cellPadding: {right: 0} }, //styles: {cellPadding:{top: 1, right: 0, bottom: 1, left: 1}}
				      margin: { top: 15, left: 10, bottom: 9 },
				      didDrawPage: function (data:any) {
				        headerFooter();
				      },
				      didDrawCell: function (data:any) {
				      if (data.row.section === 'body' && data.column.dataKey === 'Resiliency Risk Categories') {
				        autoTable(doc, {
				          startY: data.cell.y + 1,
				          margin: { left: data.cell.x + 2 },
				          tableWidth: 55,
				          columnStyles: {  
				            0: { cellWidth: 55},    
				          },
				          body:  tableTLcategory3[data.row.index],
				          alternateRowStyles: {//@ts-ignore
				            fillColor: rowcolor[data.row.index],
				          },
				          bodyStyles: {//@ts-ignore
				            fillColor: rowcolor[data.row.index],       
				          },
				          willDrawCell: function (data_:any) {
				           if (data_.row.section === 'body' && data_.row.index === 0) {//@ts-ignore
				                doc.setFont('helvetica','bold'); 
				                doc.setFontSize(10)
				            } else if (data_.row.section === 'body' && data_.row.index === 1) {
				                 doc.setFont('normal');  
				                 doc.setFontSize(7)
				            } else if (data_.row.section === 'body' && data_.row.index === 2) {
				                 doc.setFont('normal');
				                 doc.setFontSize(7)  
				            } else if (data_.row.section === 'body' && data_.row.index === 3) {
				                 doc.setFont('normal');
				                 doc.setFontSize(7)  
				            } else if (data_.row.section === 'body' && data_.row.index === 4) {
				                doc.setFontSize(9)//@ts-ignore
				                doc.setFont('helvetica','italic'); 
				            }
				          },  
				        })
				      }

				      if (data.row.section === 'body' && data.column.dataKey === 'Site Assessment') {
				        autoTable(doc, {
				          startY: data.cell.y + 1,
				          margin: { left: data.cell.x + 2 },
				          tableWidth: data.cell.width - 4,
				          columns: [   
				            { dataKey: 'result', header: ''},
				            { dataKey: 'resulttext', header: ''}
				          ], 
				          body: secTableBody3[data.row.index],
				          columnStyles: {
				            result: { cellWidth: 28},  
				            resulttext: { cellWidth: 101 },
				          },
				          alternateRowStyles: {//@ts-ignore
				            fillColor: rowcolor[data.row.index],
				          },
				          bodyStyles: {//@ts-ignore
				            fillColor: rowcolor[data.row.index],  
				            halign: 'left',      
				          },
				          styles: { cellPadding: {top: 1, right: 0, bottom: 1, left: .5} },//{top: number, right: number, bottom: number, left: number}
				          willDrawCell: function (data_:any) {
				           if (data_.row.section === 'body' && data_.row.index === 1) {
				              //if (data.cell.raw > 750) {
				                doc.setTextColor(colorGreen)  
				            } else if (data_.row.section === 'body' && data_.row.index === 2) {
				                doc.setTextColor(colorYellowDarker) 
				            } else if (data_.row.section === 'body' && data_.row.index === 3) {
				                doc.setTextColor(colorRed) 
				            }
				          },
				        })
				      }


				        if (data.row.section === 'body' && data.column.dataKey === 'rect') {
				            let rectChartLength = 37;
				            let rectChartX = data.cell.x + 14;  
				            let rectChartYStart = 7;    
				            let rectChartXWide = 4;
				            //@ts-ignore 
				            let rowData = rectData[data.row.raw[0].substr(0,10)];      
				            if(rowData){
				                  let yChangeGreen = rectChartLength*(.01 * rowData.green);
				                  doc.setFillColor(colorGreen);
				                  doc.rect(rectChartX , data.cell.y + rectChartYStart, rectChartXWide, yChangeGreen, 'F')
				                  doc.setFillColor(colorYellow);
				                  let yChangeYellow = rectChartLength*(.01 * rowData.yellow);
				                  doc.rect(rectChartX , data.cell.y + rectChartYStart + yChangeGreen, rectChartXWide, yChangeYellow, 'F')
				                  doc.setFillColor(colorRed);
				                  if(rowData.red > 0){
				                        let yChangeRed = rectChartLength*(.01 * rowData.red);
				                        doc.rect(rectChartX, data.cell.y + rectChartYStart + yChangeGreen + yChangeYellow, rectChartXWide, yChangeRed, 'F')
				                  }
				            }
				          }

  

				    },
				  };

				  if(reportTabsNew && reportTabsNew.length > 0){
				    reportColumns.body = [];
				    let i = 0;
				    for (let dataItem of reportTabsNew) {
				      if(dataItem.title!=='Overview'){
				        if(i<15 && i > 9){
				          //@ts-ignore
				              reportColumns.body.push( [dataItem["title"],  '',  '']);
				        }
				        i++
				      }
				    }
				  }
				  autoTable(doc, reportColumns)



		}



	  doc.addPage();
	  //@ts-ignore
	  autoTable(doc, reportColumnsGrid)

	  doc.addPage();
	  //@ts-ignore
	  autoTable(doc, reportColumnsGrid_min)


	  let reportColumnsAdditional = { 
	      columns: detail1Columns,
	      body: detail1,
	      margin: { top: 24, left: 10, bottom: 14 },
	      willDrawCell: function (data:any) {
	          if (data.row.section === 'body' && greenMatch.indexOf(data.cell.raw) > -1) {                    
	                doc.setTextColor(colorGreen)  
	          } else if (data.row.section === 'body' && yellowMatch.indexOf(data.cell.raw) > -1) {                    
	                doc.setTextColor(colorYellowDarker)  
	          } else if (data.row.section === 'body' && redMatch.indexOf(data.cell.raw) > -1) {                    
	                doc.setTextColor(colorRed)  
	          } else if (data.row.section === 'body' && unavailableMatch.indexOf(data.cell.raw) > -1) {                    
	                doc.setTextColor(colorUnavailable)  
	          } else if (data.row.section === 'body' && data.column.dataKey === 'Site Surface Water Quality Threshold' && !isNaN(parseInt(data.cell.raw)) && parseInt(data.cell.raw) > 89 && parseInt(data.cell.raw) < 111) {                    
	                doc.setTextColor(colorGreen)  
	          } else if (data.row.section === 'body' && data.column.dataKey === 'Site Surface Water Quality Threshold' && !isNaN(parseInt(data.cell.raw)) && parseInt(data.cell.raw) > 79 && parseInt(data.cell.raw) < 90) {                    
	                doc.setTextColor(colorYellowDarker)  
	          } else if (data.row.section === 'body' && data.column.dataKey === 'Site Surface Water Quality Threshold' && !isNaN(parseInt(data.cell.raw)) && parseInt(data.cell.raw) > 1 && parseInt(data.cell.raw) < 80) {                    
	                doc.setTextColor(colorRed)  
	          }
	      },    
	      didDrawPage: function (data:any) {
	        headerFooter();
	      },
	      
	  };



	  doc.addPage();
	  doc.setTextColor(colorSectionHeader)
	  doc.setFont('helvetica', 'normal');
	  doc.setFontSize(16)
	  doc.text('Sites', 96, 20)
	  autoTable(doc, reportColumnsAdditional)
	  doc.addPage();
	  
	  reportColumnsAdditional.margin = { top: 18, left: 10, bottom: 14 };
	  reportColumnsAdditional.body = detail2;
	  reportColumnsAdditional.columns = detail2Columns;
	  autoTable(doc, reportColumnsAdditional)
	  doc.addPage();
	  reportColumnsAdditional.body = detail3;
	  reportColumnsAdditional.columns = detail3Columns;
	  autoTable(doc, reportColumnsAdditional)  
	  doc.addPage();  
	  reportColumnsAdditional.body = detail4;
	  reportColumnsAdditional.columns = detail4Columns;                
	  autoTable(doc, reportColumnsAdditional) 


	  let reportColumnsAttr = { 
	       columns: [
	          { dataKey: 'Resiliency Risk Category', header: 'Resiliency Risk Category' },
	          { dataKey: 'Source', header: 'Data Source' },
	          { dataKey: 'Sourceurl', header: '' },

	        ],
	       columnStyles: {
	            2: { cellWidth: 67},
	            1: { cellWidth: 60},
	            0: { cellWidth: 60},
	      },
	      body: attr,
	      willDrawCell: function (data:any) {
	       
	          if (data.row.section === 'body' && data.column.dataKey === 'Sourceurl') {                    
	                doc.textWithLink('', data.cell.x, data.cell.y, {url: data.cell.raw});
	          } else if (data.row.section === 'body' && data.column.dataKey === 'Source') {                    
	                doc.setTextColor(textColor)  
	                doc.link(data.cell.x+1, data.cell.y+1, 15, 200, {url: attr[data.row.index][2]});
	          }
	      },    
	      didDrawPage: function (data:any) {
	        headerFooter();
	        
	        doc.setTextColor(colorSectionHeader)
	        doc.setFont('helvetica', 'normal');
	        doc.setFontSize(16)
	        doc.text('Sources', pageWidth/2-14, 30) 
	      },
	      margin: { top: 35, left: 10, bottom: 14 },
	  };


	  doc.addPage();
	  autoTable(doc, reportColumnsAttr)

	  if (typeof doc.putTotalPages === 'function') {
	    doc.putTotalPages(totalPagesExp)
	  }

	  doc.setProperties({ title: reportTitle })

	  window.open(doc.output('bloburl'), '_blank');

}



export const RiskAssessment = (data: any, reportTabs:any, data3: any, orgname:any, industry:string) => { 
  if(data && reportTabs){
    onPDF(data, reportTabs, data3, null, industry);
  }
}