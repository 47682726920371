import { Ref, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  MapRef,
  Map,
  Source,
  Layer,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  LayerProps,
  Popup,
} from 'react-map-gl';
import { AppContext } from '../../../AppContext';
import { RefContext } from '../../../RefContext';
import { CatalogsContext } from '../../../CatalogsProvider';
import settings from '../../../settings.json';
import { Row, Col, Button, Form } from 'react-bootstrap';
import fullscreenIcon from '../../../assets/fullscreen-icon.svg';
import ReportTable from '../shared/ReportTable';
import { Location, useLocation } from 'react-router-dom';
import MapLegend from '../shared/MapLegend';
import { message_hucrestriction, message_nodatalocation, message_noresult } from '../../../Constants';
import { flyToInitialLocationHandler, flyToLngLat, groupBy } from '../../utils';
import agent from '../../../api/agent';
import Globals from '../../../types/GlobalsType';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { v4 as uuidv4 } from 'uuid';
import DrawControl from '../../../types/DrawControl';
import { Feature, FeatureCollection, GeoJsonProperties, Point } from 'geojson';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import RenderLayerMarkers from '../shared/RenderLayerMarkers';
import RenderLayers from '../shared/RenderLayers';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import MapSlider from '../shared/MapSlider';

import GeneralResponseModel from '../../../api/GeneralResponseModel';
import './styles.scss';

const EPAMajorPermitHolders = (props: any) => {
  const { global } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);
  const selectedYearsFilterValues = global.yearsSelected;

  const mapRef = useRef<MapRef>();
  const { setCurrentRef } = useContext(RefContext)
  useEffect(() => { setCurrentRef?.(mapRef) }, [mapRef])
  const [currentMapRef, setCurrentMapRef] = useState<any>(null);

  const fullscreenhandle = useFullScreenHandle() as any;
  const FullScreenComp = FullScreen as any;

  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;

  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState<Array<any>>([]);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [dataPage, setDataPage] = useState<number>(0);
  const [currentSelectedRowHTMLElement, setCurrentSelectedRowHTMLElement] =
    useState<HTMLElement | null>(null);
  const [currentSelectedRowData, setCurrentSelectedRowData] = useState({});
  const [clickedFacilityFilterValue, setClickedFacilityFilterValue] = useState<string>('');
  const [featureClicksCounter, setFeatureClicksCounter] = useState<number>(0);
  const [currentSelectedFacilityName, setCurrentSelectedFacilityName] = useState<string>('');
  const [featureData, setFeatureData] = useState<FeatureCollection>();
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [cursor, setCursor] = useState<string>('auto');
  const [scrolledToBottom, setScrolledToBottom] = useState<boolean>();
  const [isLastResponseEmpty, setIsLastResponseEmpty] = useState<boolean>(false);

  const [isFullScreenChartActive, setFullScreenChartActive] = useState(false);
  const fullscreenChartContainerClickHandler = () => {
    setFullScreenChartActive(!isFullScreenChartActive);
  };

  const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  useEffect(() => {
    global.setUserLogged(true);
    if (global.trackerOn) global.setTrackerOn(false)
    getReportData();

    const scrollableTable = document.querySelector(
      'div.table-container.table-container--scrollable'
    );

    //console.log('Scrollable table', scrollableTable);

    const scrollHandler = (e: any) => {
      const scrollAdvancement =
        //@ts-ignore
        (scrollableTable as HTMLElement).offsetHeight + scrollableTable.scrollTop;
      //@ts-ignore
      const scrollMiddlePoint = (Math.ceil(scrollableTable.scrollHeight / 10) * 10) / 2;
      const maxRangeValue = scrollMiddlePoint + 40;
      if (
        Math.ceil(scrollAdvancement / 10) * 10 >= scrollMiddlePoint &&
        Math.ceil(scrollAdvancement / 10) * 10 >= maxRangeValue
      ) {
        setScrolledToBottom(prev => !prev);
      }
    };

    scrollableTable?.addEventListener('scroll', scrollHandler);

    return () => {
      clearFilters();
      scrollableTable?.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  const clearFilters = () => {
    setClickedFacilityFilterValue('');
    setCurrentSelectedFacilityName('');
    setCurrentSelectedRowData('');
    (global as Globals).setSelectedYearFilterOptions([]);
  };
  useEffect(() => {
    setLoading(true);
    getReportData(0);
  }, [appContext.selectedHUC8]);

  useEffect(() => {
    if (mapRef.current === currentMapRef) {
    } else {
      setCurrentMapRef(mapRef.current);
    }

    setTimeout(() => {
      flyToInitialLocationHandler(appContext.selectedHUC8, mapRef, catalogsContext.huc8Catalog);
      if (mapRef && mapRef.current) {
        mapRef.current.on('click', 'pointlayer', clickedFeatureHandler);
      }
    }, 1000);

    return () => {
      mapRef.current?.off('click', 'pointlayer', clickedFeatureHandler);
    };
  }, [mapRef.current]);

  useEffect(() => {
    const filteredTableDataByFacility = reportData.filter(
      row => row['Facility Name'] == clickedFacilityFilterValue
    );

    if (filteredTableDataByFacility.length) {
      if (clickedFacilityFilterValue == currentSelectedFacilityName && featureClicksCounter == 2) {
        const sortedData = [...reportData].sort((a, b) => b.Year - a.Year);
        createFeatureCollection(reportData);
        setTableData(sortedData);
        setCurrentSelectedFacilityName('');
        flyToInitialLocationHandler(appContext.selectedHUC8, mapRef, catalogsContext.huc8Catalog);
        setFeatureClicksCounter(0);
      } else if (featureClicksCounter == 2) {
        resolveDataWithFilters(filteredTableDataByFacility);
        createFeatureCollection(filteredTableDataByFacility);
        setCurrentSelectedFacilityName(clickedFacilityFilterValue);
        setFeatureClicksCounter(0);
      }
    }
  }, [featureClicksCounter]);

  useEffect(() => {
    if (currentSelectedFacilityName) {
      const filteredTableDataByFacility = reportData.filter(
        row => row['Facility Name'] == clickedFacilityFilterValue
      );
      resolveDataWithFilters(filteredTableDataByFacility);
    } else {
      resolveDataWithFilters();
    }
  }, [selectedYearsFilterValues]);

  useEffect(() => {
    if (scrolledToBottom != undefined && loading == false && !isLastResponseEmpty) {
      setLoading(true);
      getReportData();

      // setTimeout(() => {
      //   flyToInitialLocationHandler(
      //     appContext.selectedHUC8,
      //     mapRef,
      //     catalogsContext.huc8Catalog
      //   );
      // }, 1500);
    }
  }, [scrolledToBottom]);

  const getReportData = (page: number = dataPage) => {
    const huc8 = appContext.selectedHUC8;
    const selectedHUC8Value = appContext.selectedHUC8;
    if (selectedHUC8Value && global.hucRestrict.length > 0 && (global.hucRestrict.indexOf(selectedHUC8Value) < 0)) {
      setLoading(false);
      return global.notify(message_hucrestriction);
    }
    agent.Reports.EPAMajorPermitHolders(huc8, page).then((res: GeneralResponseModel) => {

      let responseBody: any[] | EPAMajorPermitHoldersResponseBody[] = res.body;

      if (!responseBody) {
        responseBody = [];
      }
      //@@@@@@@@@@@@@@@@@@@@@@
      if (responseBody.length == 0 || (responseBody.length < 100 && page !== 0)) {
        setIsLastResponseEmpty(true);
        setLoading(false);
      } else {
        setDataPage(prevPageNumber => {
          return prevPageNumber + 1;
        });

        setTimeout(() => {
          flyToInitialLocationHandler(appContext.selectedHUC8, mapRef, catalogsContext.huc8Catalog);
        }, 500);

        const mergedData = [...reportData, ...responseBody];

        setReportData(mergedData);
        resolveDataWithFilters(mergedData);

        createFeatureCollection(mergedData);
        resolveFiltersOptions(mergedData);

        setLoading(false);
      }

      //@@@@@@@@@@@@@@@@@@@@@@
      // setDataPage(prevPageNumber => {
      //   return prevPageNumber + 1;
      // });

      // setTimeout(() => {
      //   flyToInitialLocationHandler(appContext.selectedHUC8, mapRef, catalogsContext.huc8Catalog);
      // }, 500);

      // const mergedData = [...reportData, ...responseBody];
      // console.log('Merged Data', mergedData);
      // setReportData(mergedData);
      // resolveDataWithFilters(mergedData);

      // createFeatureCollection(mergedData);
      // resolveFiltersOptions(mergedData);

      // setLoading(false);
    });
  };

  const resolveFiltersOptions = (data: EPAMajorPermitHoldersResponseBody[]) => {
    const dataGroupedByYear = groupBy<EPAMajorPermitHoldersResponseBody, any>(data, x => x.Year);
    const years = Object.keys(dataGroupedByYear);

    (global as Globals).setSelectedYearFilterOptions(years);
  };

  function resolveDataWithFilters(data: EPAMajorPermitHoldersResponseBody[] = reportData) {
    let filteredDataResult = [];

    if (selectedYearsFilterValues.length) {
      for (let year of selectedYearsFilterValues) {
        const result = data.filter(x => x.Year == year);
        filteredDataResult.push(...result);
      }
    } else {
      filteredDataResult = data;
    }

    const sortedData = filteredDataResult.sort((a, b) => b.Year - a.Year);

    setTableData(sortedData);
  }

  const clickedFeatureHandler = useCallback((e: any) => {
    if (e.features?.length) {
      //@ts-ignore
      const clickedFeatureFacilityName = e.features[0].properties['Facility Name'];
      setFeatureClicksCounter(prev => prev + 1);
      setClickedFacilityFilterValue(clickedFeatureFacilityName);
    }
  }, []);

  const createFeatureCollection = (reportData: any) => {
    const features: Array<Feature<Point, GeoJsonProperties>> = [];
    const dataGroupedBySic = groupBy<any, any>(reportData, x => x['Facility Name']);
    for (let industrySector in dataGroupedBySic) {
      const facilities: any[] = dataGroupedBySic[industrySector].reduce(
        (acc, x) =>
          acc.concat(
            //@ts-ignore
            acc.find(y => y['Facility Name'] === x['Facility Name']) ? [] : [x]
          ),
        []
      );
      for (let facility of facilities) {
        const featureWithPoint: Feature<Point> = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              Number(facility['Facility Longitude']),
              Number(facility['Facility Latitude']),
            ],
          },
          properties: {
            City: facility.City,
            County: facility.County,
            ['Facility Name']: facility['Facility Name'],
            State: facility.State,
            ['Npdes Permit Number']: facility['Npdes Permit Number'],
            ['Major Non Major Status']: facility['Major Non Major Status'],
            Longitude: facility['Facility Longitude'],
            Latitude: facility['Facility Latitude'],
          },
        };
        features.push(featureWithPoint);
      }
    }

    const featureCollectionFromReportData: FeatureCollection = {
      type: 'FeatureCollection',
      features: features,
    };
    setFeatureData(featureCollectionFromReportData);
  };

  const mapLegendColorsConfig: MapLegendKey[] = [
    { color: '#2789b5', typecode: 'Major', text: 'Major' },
    { color: '#ccc', typecode: 'Non-Major', text: 'Non-Major' },
  ];

  const mapLegend = new MapLegendMighty('Major or Non-major Status', mapLegendColorsConfig);

  const layerStyle: LayerProps = {
    id: 'pointlayer',
    type: 'circle' as const,
    paint: {
      'circle-radius': 8,
      'circle-color': [
        'match',
        ['get', 'Major Non Major Status'],
        ...mapLegend.flattenColorsCatalogArray(),
        '#fff',
      ],
      'circle-stroke-color': 'white',
      'circle-stroke-width': 1,
      'circle-opacity': 1,
    },
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: 'City',
        accessor: 'City',
      },
      {
        Header: 'County',
        accessor: 'County',
      },
      {
        Header: 'State',
        accessor: 'State',
      },
      {
        Header: 'Major / Non-major Status',
        accessor: 'Major Non Major Status',
      },
      {
        Header: 'Link to Detail Facility Report',
        accessor: 'Link To Dfr',
        Cell: (props: any) => (
          <a
            href={props.value}
            target='_blank'
          >
            DFR link
          </a>
        ),
      },
      {
        Header: 'Npdes Permit Number',
        accessor: 'Npdes Permit Number',
      },
      {
        Header: 'With Facility And Permit Data',
        accessor: (d: any) => d['With Facility And Permit Data'].toString(),
      },
      {
        Header: 'With Facility Info Only',
        accessor: (d: any) => d['With Facility Info Only'].toString(),
      },
      {
        Header: 'With Facility Permit And Dmr Data In Icis Npdes',
        accessor: (d: any) => d['With Facility Permit And Dmr Data In Icis Npdes'].toString(),
      },
      {
        Header: 'With Monitoring Requirements And Effluent Limits',
        accessor: (d: any) => d['With Monitoring Requirements And Effluent Limits'].toString(),
      },
      {
        Header: 'With Monitoring Requirements Only',
        accessor: (d: any) => d['With Monitoring Requirements Only'].toString(),
      },
      {
        Header: 'Year',
        accessor: 'Year',
      },
    ],
    []
  );

  const onMouseEnter = useCallback((event: any | null) => {
    if (event.features && event.features[0]) {
      setCursor('pointer');
      setPopupInfo(event.features[0].properties);
    }
  }, []);
  const onMouseLeave = useCallback(() => {
    setCursor('auto');
    setPopupInfo(null);
  }, []);

  const clickRowHandler = (rowData: any, event: any) => {
    if (rowAlreadySelected(rowData)) {
      if (currentSelectedRowHTMLElement)
        currentSelectedRowHTMLElement.classList.remove('table-row__selected');
      setCurrentSelectedRowHTMLElement(null);
      setCurrentSelectedRowData({});
      createFeatureCollection(reportData);
      setTableData(reportData);
      flyToInitialLocationHandler(appContext.selectedHUC8, mapRef, catalogsContext.huc8Catalog);
    } else {
      if (currentSelectedRowHTMLElement)
        currentSelectedRowHTMLElement.classList.remove('table-row__selected');
      const selectedRow = event.currentTarget as HTMLElement;
      if (selectedRow) {
        selectedRow.className += 'table-row__selected';
        setCurrentSelectedRowHTMLElement(selectedRow);
        setCurrentSelectedRowData(rowData);
      }
      createFeatureCollection([rowData]);
      flyToLngLat(
        mapRef,
        (rowData as EPAMajorPermitHoldersResponseBody)['Facility Longitude'],
        (rowData as EPAMajorPermitHoldersResponseBody)['Facility Latitude']
      );
    }
  };

  const rowAlreadySelected = (rowData: {}) => {
    const selectedRowValuesAsArray: string[] = Object.values(rowData);
    const currectSelectedRowValuesAsArray: string[] = Object.values(currentSelectedRowData);

    return arraysAreEqual(selectedRowValuesAsArray, currectSelectedRowValuesAsArray);
  };

  const arraysAreEqual = (array1: string[], array2: string[]) => {
    if (array1.length === array2.length) {
      return array1.every((element, index) => {
        if (element === array2[index]) {
          return true;
        }

        return false;
      });
    }

    return false;
  };

  const renderPopup = () => {
    return (
      <Popup
        style={{ color: 'white' }}

        longitude={Number(popupInfo?.Longitude)}
        latitude={Number(popupInfo?.Latitude)}
        onClose={() => setPopupInfo(null)}
        className='popup-container--dark'
      >
        <div className='popup-container'>
          <Row>
            <Col> Facility Name:</Col>
            <Col className='popup-content-highlight'>{popupInfo?.['Facility Name']}</Col>
          </Row>
          <Row>
            <Col> City:</Col>
            <Col>{popupInfo?.City}</Col>
          </Row>
          <Row>
            <Col> County:</Col>
            <Col>{popupInfo?.County}</Col>
          </Row>

          <Row>
            <Col> State:</Col>
            <Col>{popupInfo?.State}</Col>
          </Row>
          <Row>
            <Col> Npdes Permit Number:</Col>
            <Col>{popupInfo?.['Npdes Permit Number']}</Col>
          </Row>
          <Row>
            <Col> Major Status:</Col>
            <Col>{popupInfo?.['Major Non Major Status']}</Col>
          </Row>
        </div>
      </Popup>
    );
  };

  const mapInitialViewState = {
    longitude: settings.defaultLocationMap.lng,
    latitude: settings.defaultLocationMap.lat,
    zoom: 3,
  };

  return (
    <FullScreenComp handle={fullscreenhandle}>
      <LoadingSpinner active={loading} />
      <div className='toxics-release-inventory' id='EPAMajorPermitHolders-report'>
        <ReportHeader global={global} data={reportData}
          reportID={"EPAMajorPermitHolders-report"} fullScreenClickHandle={fullscreenhandle.enter} />
        <div className='container'>
          <Row>
            <Col>
              <div className='map-container'>
                <Map
                  ref={mapRef as Ref<MapRef>}
                  preserveDrawingBuffer={true}
                  mapboxAccessToken={settings.maboxKey}
                  initialViewState={mapInitialViewState}
                  mapStyle={global.mapStyle}
                  interactiveLayerIds={['pointlayer']}
                  cursor={cursor}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  projection={global.globeView ? 'globe' : 'mercator' as any}
                  onClick={(e) => {
                    global.onMapClick(e)
                  }}
                  onMove={(e) => {
                    global.setViewport({
                      longitude: e.viewState.longitude,
                      latitude: e.viewState.latitude,
                      zoom: e.viewState.zoom,
                    })
                  }}
                >
                  <FullscreenControl />
                  <NavigationControl />
                  <GeolocateControl />
                  <DrawControl
                    position='top-left'
                    displayControlsDefault={false}
                    controls={{
                      polygon: true,
                      trash: true,
                    }}
                  />
                  <RenderLayerMarkers global={global} bounds={reportBounds} zipOff={true} />
                  <RenderLayers global={global} />

                  {featureData && (
                    <Source id='circlesource' type='geojson' data={featureData}>
                      <Layer {...layerStyle} />
                    </Source>
                  )}
                  {popupInfo && renderPopup()}
                  <div className='map-title'>
                    <p>Viewing: HUC {appContext.selectedHUC8}</p>
                  </div>

                  <div className='map-legend-container'>
                    <MapLegend
                      mapLegendData={mapLegendColorsConfig}
                      title={'Major or Non-major Status'}
                      legendWidth={320}
                      global={global}
                    />
                  </div>
                </Map>

              </div>
            </Col>
          </Row>
          <Row></Row>
          <Row>
            <Col style={{ width: '23rem' }}>
              <div
                className={
                  isFullScreenChartActive ? 'container--expand-container table-container' : ''
                }
              >
                <div className='report-options'>
                  <Button onClick={fullscreenChartContainerClickHandler}>
                    <img src={fullscreenIcon} />
                  </Button>
                </div>
                <ReportTable
                  data={tableData}
                  columns={tableColumns}
                  clickRowHandler={clickRowHandler}
                  clickLoadStop
                />
              </div>
            </Col>
          </Row>
          <ReportFooter />
        </div>
      </div>
      <MapSlider global={global} />
    </FullScreenComp>
  );
};

export default EPAMajorPermitHolders;

type MapLegendKey = {
  color: string;
  text: string;
  typecode?: string;
};
type mapLegentConfig = {
  legendTitle: string;
  keys: MapLegendKey[];
};

class MapLegendMighty {
  constructor(readonly title: string, readonly keys: MapLegendKey[]) { }

  flattenColorsCatalogArray() {
    if (this.keys) {
      return this.keys
        .map(x => {
          return [x.text, x.color];
        })
        .flat();
    }

    return [];
  }
}

type EPAMajorPermitHoldersResponseBody = {
  City: string;
  County: string;
  ['Facility Latitude']: number;
  ['Facility Longitude']: number;
  ['Facility Name']: string;
  ['Link To Dfr']: string;
  ['Major Non Major Status']: string;
  ['Npdes Permit Number']: string;
  State: string;
  ['With Facility And Permit Data']: boolean;
  ['With Facility Info Only']: boolean;
  ['With Facility Permit And Dmr Data In Icis Npdes']: boolean;
  ['With Monitoring Requirements And Effluent Limits']: boolean;
  ['With Monitoring Requirements Only']: boolean;
  Year: number;
};
