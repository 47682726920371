import axios from 'axios';
import settings from '../settings.json'


const instance = axios.create({
  baseURL: settings.apiBaseURl,
  headers: { 
        "Content-type":"application/json", 
    }
})

instance.interceptors.request.use((config: any) =>{
    if(config.headers){
        config.headers[settings.apiHeader] = settings.apiKey;
        config.headers["keycloakToken"] = sessionStorage.getItem('authKey');

    }
    return config;
})

export async function getData(input: string) { 
    try {
        const { data } = await instance.get(
            `${input}?ENV=${settings.apiEnvironment}`
        )
        return data
    } catch (error) {
        console.log(error);  
    }
}

export async function getTweets(input: number = 72) { 
    try {
        const { data } = await instance.get(
            `${settings.apiMethods.getTweets}?ENV=${settings.apiEnvironment}&HOURS=${input}`
        )
        return data
    } catch (error) {
        console.log(error);  
    }
}

export async function getHUCs(input: string ) { 
    try {
        const { data } = await instance.get(
            `${input}?ENV=${settings.apiEnvironment}&HUC8=`
        )
        return data
    } catch (error) {
        console.log(error);  
    }
}
