import {
    useEffect,
    useState,
    useContext,
    useMemo,
    useRef,
    memo
} from 'react'
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { PointContext } from '../../../PointContext';
import { RefContext } from '../../../RefContext';
import { formatDate } from '../../utils';
import ReportTable from '../shared/ReportTable';
import agent from '../../../api/agent';
import settings from '../../../settings.json'
import RenderLayers from '../../reports/shared/RenderLayers'
import RenderLayerMarkers from '../../reports/shared/RenderLayerMarkers'
import ReportHeader from '../shared/ReportHeader';
import MapSlider from '../shared/MapSlider';
import { Row, Col } from 'react-bootstrap'
import {
    Map,
    FullscreenControl,
    NavigationControl,
    GeolocateControl,
} from 'react-map-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faMaximize,
    faMinimize
} from '@fortawesome/pro-solid-svg-icons'
import './gemstat-styles.scss'

const GemstatRaw = ({ global, reportID }: any) => {

    const { currentPoint } = useContext<any>(PointContext)
    const { setCurrentRef } = useContext<any>(RefContext)

    const [reportData, setReportData] = useState<any>()
    const FullScreenComp = FullScreen as any;
    const fullscreenhandle = useFullScreenHandle() as any;

    useEffect(() => {
        global.setUserLogged(true)
        setCurrentRef(mapRef)
    }, [])

    useEffect(() => {
        global.setLoading(true)
        agent
            .Reports
            .GemstatRaw(currentPoint.id)
            .then(({ body }: any) => {
                setReportData(body)
            })
            .finally(() => {
                global.setLoading(false)
                return mapRef.current?.flyTo({
                    center: [
                        currentPoint.lng,
                        currentPoint.lat,
                    ],
                    essential: true,
                    zoom: 13,
                });
            })
    }, [currentPoint])


    const tableColumns = useMemo(
        () => [
            {
                Header: 'Analysis Method Code',
                accessor: 'analysis_method_code',
            },
            {
                Header: 'Data Quality',
                accessor: 'data_quality',
            },
            {
                Header: 'Data Owner',
                accessor: 'depth',
            },
            {
                Header: 'Gems station number',
                accessor: 'gems_station_number',
            },
            {
                Header: 'Parameter Code',
                accessor: 'parameter_code',
            },
            {
                Header: 'PK',
                accessor: 'pk',
            },
            {
                Header: 'Sample Date',
                accessor: 'sample_date',
                Cell: (props: any) => formatDate(props.value),
            },
            {
                Header: 'Sample Time',
                accessor: 'sample_time',
            },
            {
                Header: 'Unit',
                accessor: 'unit',
            },
            {
                Header: 'Value',
                accessor: 'value',
            }
        ],
        []
    );

    const mapRef = useRef<any>()
    const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

    const [max, setMax] = useState(false)

    return (
        <FullScreenComp handle={fullscreenhandle}>
            <div id={reportID}>
                <Col className='col-12 d-flex justify-content-center abh-container'>
                    <ReportHeader
                        global={global}
                        data={reportData}
                        mapRef={mapRef}
                        reportID={reportID}
                        fullScreenClickHandle={fullscreenhandle.enter}
                    />
                    <Row>
                        <Col className='col-12 abh-map-container'>
                            {currentPoint && (
                                <Map
                                    id='AusBoreHoles'
                                    mapboxAccessToken={settings.maboxKey}
                                    mapStyle={global.mapStyle}
                                    preserveDrawingBuffer={true}
                                    ref={mapRef}
                                    projection={global.globeView ? 'globe' : 'mercator' as any}
                                >
                                    <FullscreenControl />
                                    <NavigationControl />
                                    <GeolocateControl />
                                    <RenderLayers global={global} />
                                    <RenderLayerMarkers global={global} bounds={reportBounds} zipOff={true} />


                                </Map>
                            )}
                        </Col>
                    </Row>
                    <Row className={`table-row`}>
                        <Col className={`col-12 abh-table-container ${max ? 'expanded-abh' : ''}`}>
                            {reportData && reportData.length > 0 ? (
                                <>
                                    <div className='minmax'>
                                        <FontAwesomeIcon onClick={() => setMax(!max)} icon={max ? faMinimize : faMaximize} />
                                    </div>
                                    <ReportTable
                                        customWidth={'90vw'}
                                        data={reportData}
                                        columns={tableColumns}
                                    />
                                </>
                            ) : (
                                <p>...loading</p>
                            )}
                        </Col>
                    </Row>
                    <br />
                    <h4>Source: <a href={'https://gemstat.org/'} target={'_blank'}>GEMStat - The global water quality database</a></h4>
                </Col>
            </div>
            <MapSlider global={global} />
        </FullScreenComp>
    )
}

export default memo(GemstatRaw)