import { useEffect, useContext, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Layer, Source, Popup } from "react-map-gl";
import { FillLayer } from "mapbox-gl";
import { RefContext } from "../../../RefContext";
import { LayerDataContext } from "../../../LayerDataContext";
import { PointContext } from "../../../PointContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { adjustHexBrightness } from "../../../features/utils";
import { ActivePopupContext } from "../../../ActivePopupContext";

const FireDataLayer = ({
  id,
  opacity,
  toast = true,
  color,
  outlineColor,
  tileSet,
  sourceLayer,
  action,
  report = false,
  tableColumns = [],
  year = null,
  sourceLink = "",
  sourceLabel = "",
  reportRoute = "",
  initialCoordinates = null,
  zoom = null,
  global,
}: any) => {
  const [features, setFeatures] = useState<any>();
  const [colorMap, setColorMap] = useState<any[]>([]);

  const { currentRef } = useContext(RefContext);
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);

  const latLng = action && action.lngLat;

  const navigate = useNavigate();

  const { setCurrentPoint } = useContext(PointContext);
  const { setCurrentLayerData } = useContext(LayerDataContext);

  const goTo = (coor: any, data: any) => {
    setCurrentPoint?.({
      lat: coor.lat,
      lng: coor.lng,
    });
    setCurrentLayerData?.({
      ...data,
      tableColumns,
      sourceLink,
      sourceLabel,
    });
    setTimeout(() => navigate(reportRoute), 100);
  };

  // @ts-ignore
  const mapInstance = currentRef && currentRef?.current;

  useEffect(() => {
    if (mapInstance) {
      const handleClick = (event: any) => {
        const layerObject = mapInstance.queryRenderedFeatures(event.point)?.[0];
        if (layerObject) {
          setFeatures(layerObject);
          setActivePopup({
            id,
            content: renderPopup(layerObject, event.lngLat)
          });
        }
      };

      mapInstance.on("click", handleClick);

      return () => {
        mapInstance.off("click", handleClick);
      };
    }
  }, [mapInstance]);

  useEffect(() => {
    if (toast) {
      global.notify(
        "Layer visualizations change based upon the year selected, move viewport window to see more"
      );
    }
  }, []);

  useEffect(() => {
    if (currentRef && initialCoordinates) {
      // @ts-ignore
      currentRef?.current.flyTo({
        center: initialCoordinates,
        essential: true,
        zoom,
      });
    }
  }, [currentRef]);

  const FireDataLayer: FillLayer = {
    id: `all-fire-data-layer`,
    type: "fill",
    "source-layer": `${sourceLayer}`,
    paint: {
      "fill-opacity": opacity,
      "fill-outline-color": `${outlineColor}`,
      "fill-color": [
        "match",
        ["get", "FIRE_YEAR"],
        `${year}`,
        `${adjustHexBrightness("#FF0000", global.allFireDataBrightness)}`,
        "transparent",
      ],
    },
  };

  const renderPopup = (feature: any, lngLat: any) => (
    <Popup
      longitude={lngLat.lng}
      latitude={lngLat.lat}
      onClose={() => setActivePopup(null)}
      closeOnClick={false}
    >
      <div style={{ borderRadius: "6px", color: "#FFFFFF", padding: "5px" }}>
        <FontAwesomeIcon icon={faXmark} onClick={() => setActivePopup(null)} style={{ position: 'absolute', right: '2%', top: "2%", cursor: 'pointer' }} />
        {report && (
          <a
            style={{
              color: "#4fc2d9",
              fontWeight: "bold",
              textDecoration: "underline"
            }}
            onClick={() => goTo(lngLat, feature.properties)}
          >
            Go to report
          </a>
        )}
      </div>
    </Popup>
  );

  return (
    <>
      <Source id={`${id}-layer`} type="vector" url={tileSet}>
        {/* @ts-ignore */}
        <Layer {...FireDataLayer} />
        {activePopup && activePopup.id === id && activePopup.content}
      </Source>
    </>
  );
};

export default FireDataLayer;
