import { useContext, useState, useEffect, useRef } from 'react';
import { Layer, Source, Popup } from 'react-map-gl';
import { CircleLayer } from 'mapbox-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { RefContext } from '../../RefContext';
import { ActivePopupContext } from '../../ActivePopupContext';

const OGWTDLayer = ({
    id,
    global = null,
    circleColor,
    opacity,
    tileSet,
    sourceLayer,
    radius = 5,
    action,
    zoom
}: any) => {

    const [features, setFeatures] = useState<any>(null);
    const [properties, setProperties] = useState<any>(null);
    const { currentRef } = useContext(RefContext);
    const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);

    const popupRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (features) {
            setProperties(features.properties);
        } else {
            setProperties(undefined);
        }
    }, [features]);

    // @ts-ignore
    const mapInstance = currentRef && currentRef?.current;
    const latLng = action && action.lngLat;

    useEffect(() => {
        const handleClick = (event: any) => {
            const layerObject = mapInstance.queryRenderedFeatures(event.point);

            if (layerObject.length > 0) {
                const feature = layerObject[0];
                setFeatures(feature);
                setActivePopup({
                    id: `${id}-popup`,
                    content: renderPopup(feature, event.lngLat)
                });
            } else {
                setFeatures(undefined);
            }
        };

        if (mapInstance) {
            mapInstance.on('click', handleClick);
        }

        return () => {
            if (mapInstance) {
                mapInstance.off('click', handleClick);
            }
        };
    }, [mapInstance]);

    const handleRadius = (input: number) => {
        if (input <= 3) {
            return 2;
        } else if (input >= 4 && input <= 7) {
            return 5;
        } else if (input >= 8 && input <= 9) {
            return 7;
        } else if (input >= 9) {
            return 9;
        }
    }

    const CircleVectorLayer: CircleLayer = {
        id: `${id}-layer`,
        type: "circle",
        'source-layer': `${sourceLayer}`,
        paint: {
            "circle-radius": handleRadius(zoom),
            "circle-opacity": opacity,
            'circle-color': circleColor,
        },
    };

    const renderPopup = (feature: any, lngLat: any) => (
        <Popup
            longitude={lngLat.lng}
            latitude={lngLat.lat}
            onClose={() => setActivePopup({ id: null, content: null })}
        >
            <div
                ref={popupRef}
                style={{
                    color: 'white',
                    position: 'relative',
                    backgroundColor: "#002044",
                    padding: '0.25rem',
                    paddingLeft: '0.5rem',
                    paddingRight: '0.5rem',
                    borderRadius: '4px',
                    maxWidth: '250px',
                    textAlign: 'left',
                }}
            >
                <FontAwesomeIcon
                    style={{ position: 'absolute', right: "5%", cursor: 'pointer' }}
                    onClick={() => setActivePopup({ id: null, content: null })}
                    icon={faXmark}
                />
                <div
                    style={{
                        color: 'white',
                        backgroundColor: "#002044",
                        padding: '0.5rem',
                        borderRadius: '4px',
                        maxWidth: '150px',
                        textAlign: 'left',
                    }}
                >
                    <p>Name: {feature.properties.name}</p>
                    <p>Main: {feature.properties.main}</p>
                </div>
            </div>
        </Popup>
    );

    return (
        <>
            <Source
                id={`${id}-layer`}
                type="vector"
                url={tileSet}
            >
                <Layer
                    {...CircleVectorLayer}
                />
            </Source>
            {activePopup && activePopup.id === `${id}-popup` && activePopup.content}
        </>
    )
}

export default OGWTDLayer;
