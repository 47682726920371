
import agent from '../../../../api/agent';
import { parse } from "papaparse";
import { unparse } from 'papaparse';
import { DateTime } from 'luxon';

let domainvalues=[] as any;
let domainvaluesobj={};


export const getWQX = (epaOrgId: any, callback: any) => {    
   if (epaOrgId){
      agent.UserManagement.WQXGet(epaOrgId).then((res: any) => { 
          console.log(res)   
          if(res && res.body && res.body.length > 0 && res.statusCode === 200) {
              let wqxresult = res.body.sort((x: any, y: any) => y.id - x.id);
           
              wqxresult.forEach((dataItem:any, index:number) => {
                  if (dataItem["BiologicalHabitatIndex_IndexCommentText"]  && dataItem["BiologicalHabitatIndex_IndexCommentText"].substr(0,1)==='{'){
                      const status = JSON.parse(dataItem["BiologicalHabitatIndex_IndexCommentText"])
                      
                      let submissionType = dataItem["submissionType"];
                      //console.log(submissionType, !submissionType, !dataItem['MonitoringLocIdent_MonitoringLocationIdentifier'], dataItem['MonitoringLocIdent_MonitoringLocationIdentifier'])
                      if(!submissionType){
                        if(dataItem['MonitoringLocIdent_MonitoringLocationIdentifier']||dataItem['MonitoringLocIdent_MonitoringLocationName']){
                            submissionType = 'Monitoring Location';        
                        }
                        if(dataItem['PROJDescription_ProjectIdentifier']||dataItem['PROJDescription_ProjectName']){
                            submissionType = 'Project';           
                        }
                        if(dataItem['ACTIVITYDesc_ActivityIdentifier']){
                            submissionType = 'Activity';           
                        }
                        if(dataItem['ResultDescription_CharacteristicName']){
                            submissionType = 'Result';                                       
                        }
                        if(dataItem['SAMPLEDescription_MethodIdentifier']){
                            submissionType = 'Result';                                      
                        }                     
                      }   



                      dataItem["cdxStatus"] =  status.status; 
                      dataItem["submissionType"] = submissionType;
                      dataItem["transactionId"] =  status.transactionId;
                      dataItem["statusDetail"] =  status.statusDetail;
                      dataItem["submissionDate"] = status.submissionDate && DateTime.fromMillis(status.submissionDate).toLocaleString();

                  } else {
                      dataItem["cdxStatus"]=  ''; 
                     

                      let submissionType = dataItem["submissionType"];
                      //console.log(submissionType, !submissionType, !dataItem['MonitoringLocIdent_MonitoringLocationIdentifier'], dataItem['MonitoringLocIdent_MonitoringLocationIdentifier'])
                      if(!submissionType){
                        if(dataItem['MonitoringLocIdent_MonitoringLocationIdentifier']||dataItem['MonitoringLocIdent_MonitoringLocationName']){
                            submissionType = 'Monitoring Location';        
                        }
                        if(dataItem['PROJDescription_ProjectIdentifier']||dataItem['PROJDescription_ProjectName']){
                            submissionType = 'Project';           
                        }
                        if(dataItem['ACTIVITYDesc_ActivityIdentifier']){
                            submissionType = 'Activity';           
                        }
                        if(dataItem['ResultDescription_CharacteristicName']){
                            submissionType = 'Result';                                       
                        }
                        if(dataItem['SAMPLEDescription_MethodIdentifier']){
                            submissionType = 'Result';                                      
                        }                     
                      }   
                      dataItem["submissionType"] = submissionType;
                  }
                
              });
        
                callback(wqxresult); 
          }  else if (res && res.body && res.body.length < 1 && res.statusCode === 200) {
            console.log('null', 'a')
                callback([]); 
          }
      }) 
    }
};


export const postWQX = (wqxdocument: any, callback: any, reference?: any) => { 
   console.log(wqxdocument)   
   if (wqxdocument){   
     //if (username&&password){
        //let wqx_obj2 = {'ORGDescription_OrganizationFormalName' : epaOrgId , 'ORGDescription_OrganizationIdentifier' : epaOrgId , 'ORGDescription_TribalCode' : 'TE', 'ORGElectronicAddress_ElectronicAddress' : 'TE@TEST.COM'};
         agent.UserManagement.WQXPost(wqxdocument, wqxdocument).then((res: any) => {    
            console.log(res)
            if(res && res.body && res.body.wqx_id){
                callback(true, res.body.wqx_id, null, reference);

            //const wqx = {"WQX_REPORT_ID":`${res.body.wqx_id}`,"USER_ID":`${username}`,"EMAIL":"test@test.com","PASSWORD":`${password}`,'DOCUMENT_ID': 'TESTCONNECTION', 'DOCUMENT_NAME':''};
      
/*            agent.UserManagement.WQXSubmitter(wqx).then((res: any) => {    
      
                callback(true);
            })   */
          } else {
                callback(false, null, res, reference);
          }
        })
    }
};

export const updateWQX = (wqxdocument: any, callback: any) => {    
  
   if (wqxdocument){
     let wqxd = Object.assign(wqxdocument)
     let wqxid = wqxdocument.id
     
     delete wqxd["id"];
     delete wqxd["submissionType"];


     agent.UserManagement.WQXUpdate(wqxid, wqxd).then((resu: any) => {     
          console.log(resu)
     })


     //if (username&&password){
        //let wqx_obj2 = {'ORGDescription_OrganizationFormalName' : epaOrgId , 'ORGDescription_OrganizationIdentifier' : epaOrgId , 'ORGDescription_TribalCode' : 'TE', 'ORGElectronicAddress_ElectronicAddress' : 'TE@TEST.COM'};
        

/*         agent.UserManagement.WQXUpdate(wqxdocument, wqxdocument).then((res: any) => {    
               console.log(res)
            if(res && res.body && res.body.wqx_id){
                callback(true, res.body.wqx_id);

            } else {
                  callback(false, null, res);
            }
        })*/
    }
};

export const deleteWQX = (wqxid: any, callback: any) => {    
  if (wqxid){
    agent.UserManagement.WQXDelete(wqxid).then((res: any) => {    
        if(res && res.body && res.body.wqx_id){
            callback(true);
        } else {
              callback(true);
        }
    })
  }
};

export const submitWQX = (wqxdocument: any, callback: any, extendedcallback?: boolean, reference?: any, type?: string) => {  

  const epaOrgId = 'WQXTEST';
  const username = 'amya@trueelements.com';
  const password = 'CDXwqxaa01'

  let wqx_id = wqxdocument.id;
  let wqx_document = wqxdocument;

  //username:string, password:string,   
   if (wqxdocument){
      const wqx = {"WQX_REPORT_ID":`${wqx_id}`,"USER_ID":`${username}`,"EMAIL":"test@test.com","PASSWORD":`${password}`,'DOCUMENT_ID': 'TESTCONNECTION', 'DOCUMENT_NAME':''};
       agent.UserManagement.WQXSubmitter(wqx).then((resw: any) => {       
          if(resw && resw.body && resw.body.length>0 ){
              let bbb = resw.body.split('\n')
              let nwObj = {'transactionId':bbb[1].split(':')[1].replace(',', '').substr(1).replace(/'/g, ''), 'status':bbb[2].split(':')[1].replace(',', '').substr(1).replace(/'/g, ''),'statusDetail':bbb[3].split(':')[1].replace(',', '').substr(1).replace(/'/g, ''), 'submissionDate':Date.now(), 'submissionType': type}                    
                 if (epaOrgId){
                    agent.UserManagement.WQXGet(epaOrgId).then((resg: any) => {    
                        if(resg && resg.body && resg.body.length > 0 && resg.statusCode === 200) {                
                              
                           let filterData = resg.body.filter((o: any) => (o["id"] === +wqx_id));
        
                           if(filterData && filterData.length > 0){
                             //@ts-ignore
                               //wqx_document["BiologicalHabitatIndex_IndexCommentText"] =  JSON.stringify(nwObj)
                               //filterData[0]["BiologicalHabitatIndex_IndexCommentText"] =  nwObj

                               filterData[0]["BiologicalHabitatIndex_IndexCommentText"] =  JSON.stringify(nwObj);
                               delete filterData[0]["id"];
                               agent.UserManagement.WQXUpdate(wqx_id, filterData[0]).then((resu: any) => {     
                                   
                               })

                           }

                        }  
                    }) 
                  }
                  if(extendedcallback){
                    callback(true, nwObj, null, reference);
                  } else {
                    callback(true);                                   
                  }
          } else {
            callback(false)
          }               
        })  
    }
};

export const parseImport = (parsetxt: any, callback: any) => {    
   if (parsetxt){
     const data: any[] = parse(parsetxt, {
          header: true,
        }).data;

     callback(data)
    }
};

export const ChangeUserToOrg = (User: any, org_id: any, action: any, callback: any) => {    
    if (User && org_id){
       let orgs = User.profile.push(org_id);
       const newUser = { USER_ID: User.user_id, ROLE: User.role, NAME: User.name, PROFILE: orgs, ORG_ID: User.org_id, WQX_REPORT_ID: User.org_id, IS_ACTIVE: User.is_active };

      agent.UserManagement.UserUpdate(User.user_id, newUser).then((res: any) => {
     
          //if(res && res.body && res.body.length > 0 && res.statusCode === 200) {
          //callback(orgdata);   
      })

    }
};

export const editUser = (User: any, org_id: any, action: any, callback: any) => {   

/*    if (User && org_id){
      let orgs = User.profile.push(org_id);
      const newUser = { ROLE: User.role, NAME: User.name, PROFILE: orgs, ORG_ID: User.org_id, WQX_REPORT_ID: User.org_id, IS_ACTIVE: User.is_active };

      agent.UserManagement.UserUpdate(User.user_id, newUser).then((res: any) => {
     
          //if(res && res.body && res.body.length > 0 && res.statusCode === 200) {
          //callback(orgdata);   
      })

    }*/
};



export const getOrg = (userOrg: any, callback: any, callbackusers: any) => {    
  console.log('getOrg', userOrg)
     if (userOrg){
          const orgid = userOrg;
          agent.UserManagement.OrgGet('' + orgid).then((res: any) => {
              if(res && res.body && res.body.length > 0 && res.statusCode === 200) {
                  const orgdata = res.body;   
                  console.log(res)              
                  callback(orgdata); 
              }
          })

          agent.UserManagement.UserGet(orgid, '').then((res: any) => {
              const orgdata = [] as any;     
              if(res && res.body && res.statusCode === 200){
                  const data = res.body;            
                  let orgName = '';
                  data.forEach((dataItem:any, index:number) => {
                      dataItem.id = index;
                      dataItem.name = dataItem.username;
                  });  
                  callbackusers(data)

                  let updateCounter = data.length;
                  for (var i = 0; i < data.length; i++) {
                    let dataItem = data[i];
                    let uD = data;
                    agent.UserManagement.UserGet(orgid, data[i].username).then((res: any) => {
                        if(res && res.body && res.statusCode === 200){
               
                            //updateData.filter((o: any) => +o.username === +updateData[i].username);
                            Object.assign(dataItem, res.body[0]);
                            updateCounter = updateCounter - 1;
                            if(updateCounter === 0){
                                callbackusers(uD);
                            }
                        }  
                    }); 

                  }
              }
          })  
      }
};

export const orgChange = (Org: any, orgData: any, callback: any, callbackusers: any) => { 
console.log('orgChange', Org, orgData)   
     if (Org && orgData && orgData.length>0){
         agent.UserManagement.OrgGet('' + Org).then((res: any) => {
           console.log(res)
              if(res && res.body && res.body.length > 0 && res.statusCode === 200) {
                    
                let filterArr = orgData.sort((x: any, y: any) => x["name"].localeCompare(y["name"])); 
                let queryFind = orgData.filter((item: any) => {
                   return item.org_id === +Org;});
                console.log(queryFind)
                if(queryFind && queryFind.length>0 && res.body.length>0){
                    queryFind[0] = res.body[0];                            
                    filterArr = orgData.filter((item: any) => {
                       return item.org_id !== +Org;});
                    console.log(filterArr)
                    filterArr.unshift(queryFind[0]);
                }  
                console.log(filterArr)                                  
                callback(filterArr); 


                 // callback(orgdata); 
              }
          })
    
          agent.UserManagement.UserGet(Org, '').then((res: any) => {
              const orgdata = [] as any;     
              if(res && res.body && res.statusCode === 200){
                  const data = res.body;            
                  let orgName = '';
                  data.forEach((dataItem:any, index:number) => {
                      dataItem.id = index;
                      dataItem.name = dataItem.username;
                  });  
                  console.log(data)  
                  callbackusers(data)

                  let updateCounter = data.length;
                  for (var i = 0; i < data.length; i++) {
                    let dataItem = data[i];
                    let uD = data;
                    agent.UserManagement.UserGet(Org, data[i].username).then((res: any) => {
                        if(res && res.body && res.statusCode === 200){
               
                            //updateData.filter((o: any) => +o.username === +updateData[i].username);
                            Object.assign(dataItem, res.body[0]);
                            updateCounter = updateCounter - 1;
                            if(updateCounter === 0){
                                callbackusers(uD);
                            }
                        }  
                    }); 

                  }
              }
          })  
      }
};

export const getAllOrg = (userOrg: any, callback: any, callbackusers: any, currentOrg: any) => {    

          const orgid = '';
          agent.UserManagement.OrgGet('').then((res: any) => {
              //console.log(res)
              if(res && res.body && res.body.length > 0 && res.statusCode === 200) {
                let allorg = res.body;
                let queryOrg = userOrg;
                let currentFind = allorg.find((item: any) => item.org_id === currentOrg);
                let userFind = allorg.find((item: any) => item.org_id === userOrg);  
                if(currentOrg){                 
                  //console.log(currentFind)
                  if(!currentFind || !currentFind.org_id){                      
                      //console.log(userFind)                  
                  } else {
                      queryOrg = currentFind.org_id;
                  }               
                }
                
                agent.UserManagement.OrgGet(queryOrg).then((resA: any) => {                   
                    if(resA && resA.body && resA.body.length > 0 && resA.statusCode === 200) {
                        //console.log(resA.body)
                        let filterArr = allorg.sort((x: any, y: any) => x["name"].localeCompare(y["name"]));
                        let queryFind = allorg.filter((item: any) => {
                           return item.org_id === queryOrg;});
                        if(queryFind && queryFind.length>0 && resA.body.length>0){
                            queryFind[0] = resA.body[0];                            
                            filterArr = allorg.filter((item: any) => {
                               return item.org_id !== queryOrg;});
                            filterArr.unshift(queryFind[0]);
                        }                                    
                        callback(filterArr); 
                    }
                })  

                
                
               agent.UserManagement.UserGet(queryOrg , '').then((res: any) => {
                    const orgdata = [] as any;     
                    if(res && res.body && res.statusCode === 200){
                        const data = res.body            
                        let orgName = '';
                        data.forEach((dataItem:any, index:number) => {
                            dataItem.id = index;
                            dataItem.name = dataItem.username;
                        });  
                        callbackusers(data)

                        let updateCounter = data.length;
                        for (var i = 0; i < data.length; i++) {

                          let dataItem = data[i];
                          let uD = data;
                          agent.UserManagement.UserGet(queryOrg, data[i].username).then((res: any) => {
                              if(res && res.body && res.statusCode === 200){
                     
                                  //updateData.filter((o: any) => +o.username === +updateData[i].username);
                                  Object.assign(dataItem, res.body[0]);
                                  updateCounter = updateCounter - 1;
                                  if(updateCounter === 0){
                                      callbackusers(uD);
                                  }
                              }  
                          }); 

                        }
                    }
                })  




              }   
          })

 
      
};


export const editOrg = (orgInfo: any, mode: any, callback: any) => { 

    let capsEntries = Object.entries(orgInfo).map((entry) => [entry[0].toUpperCase(), entry[1]]);
    let capsObj = Object.fromEntries(capsEntries);
    capsObj.ORG_NAME = capsObj.NAME;
    capsObj.ORG_ID =  orgInfo.org_id;

    if(mode === 'add'){
      //const orgSend = { ORG_NAME: firstName, EPA_ORG_ID: null, DESCRIPTION: description, EMAIL: null, IS_ACTIVE: true };
/*      agent.UserManagement.OrgPost(orgInfo).then((res: any) => {      
        
      }) */
    } else if(mode === 'edit' && capsObj && orgInfo.org_id){
      agent.UserManagement.OrgUpdate(orgInfo.org_id, capsObj).then((res: any) => {
        callback(true)  
      })  
    }

};

export const getDomainValue = (type: any, callback: any) => {
    //@ts-ignore
    const obj_dv = domainvaluesobj[type];
 

    if(type === 'Taxon'){
      const DomainValueURL = '/csv/wqxreference_taxon.csv';
      fetch(DomainValueURL)
        .then(response => response.text())
        .then(responseText => {
          const data = parse(responseText, {
            header: true,
          }).data;
          callback(data, null); 
        });
      return null;
    } else if(type === 'Characteristic'){
      const DomainValueURL = '/csv/wqxreference_characteristic.csv';
      fetch(DomainValueURL)
        .then(response => response.text())
        .then(responseText => {
          const data = parse(responseText, {
            header: true,
          }).data;
         
          callback(data, null); 
        });
      return null;
    }  else if(type === 'ORGANIZATION'){
     
      const DomainValueURL = '/csv/wqxreference_Org.csv';
      fetch(DomainValueURL)
        .then(response => response.text())
        .then(responseText => {
          const data = parse(responseText, {
            header: true,
          }).data;

            
                let namefield = "Code";
                let descriptionfield = "Description";

               
                if(type==='ORGANIZATION') {
                  namefield = "Name";
                  descriptionfield = "ID";
                };     
       
                data.forEach((dataItem:any, index:number) => {
                  if(!dataItem[namefield]){
                      //console.log(namefield, dataItem, dataItem[namefield], index)
                  }
                  dataItem.Name = dataItem[namefield]||dataItem['Domain']||'';
                  dataItem.Description = dataItem[descriptionfield]||dataItem['Domain']||'';;                     
                });  
               callback(data, null);

        });
      return null;
    } else if(type === 'siteType'){
      
        const siteTypes = [
       {
         "Name": "Aggregate groundwater use (NWIS, STORET)",
         "Definition": "An aggregate groundwater site type is used when it is not possible or practical to describe the specific sites as springs or as any type of well including 'multiple wells', or when water-use information is only available for the aggregate. Aggregate sites that span multiple counties should be coded with 000 as the county code, or an aggregate site can be created for each county."
       },
       {
         "Name": "Aggregate surface-water-use (NWIS, STORET)",
         "Definition": "An aggregate surface-water site type is used when it is not possible or practical to describe the specific sites as diversions, outfalls, or land application sites, or when water-use information is only available for the aggregate. Aggregate sites that span multiple counties should be coded with 000 as the county code, or an aggregate site can be created for each county."
       },
       {
         "Name": "Aggregate water use establishment (NWIS)",
         "Definition": "An aggregate class of water-using establishments or individuals that are associated with a specific geographic location and water-use category, such as all the industrial users located within a county. Identified using the national water-use category code and optionally classified using the Standard Industrial Classification (SIC) Code or North American Classification System (NAICS) Code. Used when specific information needed to create sites for the individual facilities or users is not available, or when it is not desirable to store the site-specific information in the database."
       },
       {
         "Name": "Atmosphere",
         "Definition": "A site established primarily to measure meteorological properties or atmospheric deposition."
       },
       {
         "Name": "Estuary",
         "Definition": "A coastal inlet of the sea or ocean; esp. the mouth of a river, where tide water normally mixes with stream water (modified, Webster). Salinity in estuaries typically ranges from 1 to 25 Practical Salinity Units (psu), as compared oceanic values around 35 psu."
       },
       {
         "Name": "Facility",
         "Definition": "A non-ambient location where environmental measurements are expected to be strongly influenced by current or previous activities of humans."
       },
       {
         "Name": "Glacier",
         "Definition": "Body of land ice that consists of recrystallized snow accumulated on the surface of the ground and moves slowly downslope over a period of years or centuries. Since glacial sites move, the lat-long precision for these sites is usually coarse."
       },
       {
         "Name": "Lake, Reservoir, Impoundment",
         "Definition": "An inland body of standing fresh or saline water that is generally too deep to permit submerged aquatic vegetation to take root across the entire body (cf: wetland). This site type includes an expanded part of a river, a reservoir behind a dam, and a natural or excavated depression containing a water body without surface-water inlet and/or outlet."
       },
       {
         "Name": "Land",
         "Definition": "A location on the surface of the earth that is not normally saturated with water. Land sites are appropriate for sampling vegetation, overland flow of water, or measuring land-surface properties such as temperature. (See also: Wetland)."
       },
       {
         "Name": "Not assigned (STEWARDS)",
         "Definition": "A location which lacks Site Type information."
       },
       {
         "Name": "Ocean",
         "Definition": "Site in the open ocean, gulf, or sea. (See also: Estuary)."
       },
       {
         "Name": "Spring",
         "Definition": "A location at which the water table intersects the land surface, resulting in a natural flow of groundwater to the surface. Springs may be perennial, intermittent, or ephemeral."
       },
       {
         "Name": "Stream",
         "Definition": "A body of running water moving under gravity flow in a defined channel. The channel may be entirely natural, or altered by engineering practices through straightening, dredging, and (or) lining."
       },
       {
         "Name": "Subsurface",
         "Definition": "A location below the land surface, but not a well, soil hole, or excavation."
       },
       {
         "Name": "Well",
         "Definition": "A hole or shaft constructed in the earth intended to be used to locate, sample, or develop groundwater, oil, gas, or some other subsurface material."
       },
       {
         "Name": "Wetland",
         "Definition": "Land where saturation with water is the dominant factor determining the nature of soil development and the types of plant and animal communities living in the soil and on its surface (Cowardin, December 1979). Wetlands are found from the tundra to the tropics and on every continent except Antarctica. Wetlands are areas that are inundated or saturated by surface or groundwater at a frequency and duration sufficient to support, and that under normal circumstances do support, a prevalence of vegetation typically adapted for life in saturated soil conditions. Wetlands generally include swamps, marshes, bogs and similar areas. Wetlands may be forested or unforested, and naturally or artificially created."
       }
      ];


      callback(siteTypes, null);
    }  else if(obj_dv){
      callback(obj_dv, null);
    } else {

/*       agent.UserManagement.WQXReferenceData('QAQCCharacteristicValidation').then((res: any) => { 
          console.log(res);
            if(res && res.body && res.body.length > 0 && res.statusCode === 200) {
              //console.log(res.body);
                  const csv = unparse(res.body);
                  let hiddenElement = document.createElement('a');
                  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
                  hiddenElement.target = '_blank';
                  hiddenElement.download = 'QAQCCharacteristicValidation' +'.csv';
                  hiddenElement.click(); 
                  if (hiddenElement.parentNode) {
                    hiddenElement.parentNode.removeChild(hiddenElement);
                  }
                  hiddenElement.remove();
            }
        })  */
         
      agent.UserManagement.WQXReferenceData(type).then((res: any) => {        
          if(res && res.body && res.body.length > 0 && res.statusCode === 200) {
              const data = res.body;        
              if(!data[0]['Name'] || type==='ORGANIZATION'){
                let namefield = "Code";
                let descriptionfield = "Description";

                if(type==='County') {
                  namefield = "County Name";
                  descriptionfield = "State Code";
                };     
                if(type==='ORGANIZATION') {
                  namefield = "Name";
                  descriptionfield = "ID";
                };     
                if(type==='CharacteristicAlias'){
                  namefield = "Alias Name";
                  descriptionfield = "Characteristic Name";
                }  
                if(type==='TaxonAlias'){
                  namefield = "Alias Name";
                  descriptionfield = "Taxon Name";
                }           
                data.forEach((dataItem:any, index:number) => {
                  dataItem.Name = dataItem[namefield];
                  dataItem.Description = dataItem[descriptionfield];                     
                });             
              }

              if(type!=='CharacteristicAlias'&&type!=='TaxonAlias'){
                    //@ts-ignore
                    domainvaluesobj[type] = data;   
              }
              callback(data, null);
          } else if (res.errorMessage){
            callback([], 'Error: Unable to load ' + type);
          }
      })
    }
};

export const testCredentials = (username:string, password:string, callback:any) => {    
      console.log(username, password)
      const epaOrgId = 'WQXTEST';
      const email = username; // 'amya@trueelements.com';
      const passwordover = password;//'CDXwqxaa01'

     //if (username&&password){
        let wqx_obj2 = {'ORGDescription_OrganizationFormalName' : epaOrgId , 'ORGDescription_OrganizationIdentifier' : epaOrgId , 'ORGDescription_TribalCode' : 'TE', 'ORGElectronicAddress_ElectronicAddress' : 'TE@TEST.COM'};
         agent.UserManagement.WQXPost(wqx_obj2, wqx_obj2).then((res: any) => {    
 
            if(res && res.body && res.body.wqx_id){

             const wqx = {"WQX_REPORT_ID":`${res.body.wqx_id}`,"USER_ID":`${email}`,"EMAIL":`${email}`,"PASSWORD":`${passwordover}`,'DOCUMENT_ID': 'TESTCONNECTION', 'DOCUMENT_NAME':'TESTCONNECTION'};

              agent.UserManagement.WQXSubmitter(wqx).then((resw: any) => {    
                
                          console.log(resw)
                          if(resw && resw.body && resw.body.length>0 ){  
                              let bbb = resw.body.split('\n')
 
                              let nwObj = {'transactionId':bbb[1].split(':')[1].replace(',', '').substr(1).replace(/'/g, ''), 'status':bbb[2].split(':')[1].replace(',', '').substr(1).replace(/'/g, ''),'statusDetail':bbb[3].split(':')[1].replace(',', '').substr(1).replace(/'/g, ''), 'submissionDate':Date.now()}

                                 if (epaOrgId){
                                    agent.UserManagement.WQXGet(epaOrgId).then((resg: any) => {    
                                        if(resg && resg.body && resg.body.length > 0 && resg.statusCode === 200) {                


                                             let filterData = resg.body.filter((o: any) => (o["id"] === +res.body.wqx_id));
                                         
                                             if(filterData && filterData.length > 0){
                                               //@ts-ignore
                                                  wqx_obj2["BiologicalHabitatIndex_IndexCommentText"] =  JSON.stringify(nwObj)
                                                 filterData[0]["BiologicalHabitatIndex_IndexCommentText"] =  nwObj
                                                 delete filterData[0]["id"];
                                                 agent.UserManagement.WQXUpdate(res.body.wqx_id, wqx_obj2).then((resu: any) => {     
                                                      console.log(resu)
                                                 })

                                             }

                                        }  
                                    }) 
                                  }


                          }

                  callback(resw, res.body.wqx_id);
              }) 

/*              const wqid = parseInt(res.body.wqx_id) - 10;

             agent.UserManagement.WQXDelete('' + wqid).then((resd: any) => {   
        
                  if(resd && resd.body && resd.body.length > 0 && resd.statusCode === 200) {                
                        //callback(res.body.sort((x: any, y: any) => y.id - x.id)); 
                  }  
              }) */


              //const wqid2 = parseInt(res.body.wqx_id) - 20;

/*             agent.UserManagement.WQXDelete('' + wqid2).then((resd2: any) => {   
               
              }) */

          /*
              agent.UserManagement.WQXGet(epaOrgId).then((res: any) => {    
                  console.log(epaOrgId, res.body)
              }) 

              const wqx = {"WQX_REPORT_ID":`${res.body.wqx_id}`,"USER_ID":`${username}`,"EMAIL":"test@test.com","PASSWORD":`${password}`,'DOCUMENT_ID': 'TESTCONNECTION', 'DOCUMENT_NAME':''};
              agent.UserManagement.WQXSubmitter(wqx).then((res: any) => {    
                  console.log('WQXSubmitter', wqx, res)
                  callback(true);
              })  */ 

      /*          agent.UserManagement.WQXDelete(wqxid).then((res: any) => {   
          console.log(res) 
              if(res && res.body && res.body.length > 0 && res.statusCode === 200) {                
                    //callback(res.body.sort((x: any, y: any) => y.id - x.id)); 
              }  
          }) */

           
            }
         })  
     // }
};

