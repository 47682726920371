import React, { useContext, useState, useEffect } from 'react';
import { Button, OverlayTrigger, Form, Tooltip } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'
import { AppContext } from '../../../AppContext';
import { RefContext } from '../../../RefContext';
import { faGreaterThan, faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getReportFromStorage,
  getCountryFromStorage,
  putCountryIntoStorage
} from '../../utils';

import axios from 'axios'
import agent from '../../../api/agent';
import settings from '../../../settings.json'
import ISOs from '../../../ISO.json'
import './breadcrumb-styles.scss'


const MoveSearch = (props: any) => {
  const { param = null, global } = props
  const { latitude, longitude } = global.viewport
  const [country, setCountry] = useState<any>()

  const { pathname } = useLocation()
  const { currentRef } = useContext<any>(RefContext);
  const {
    selectedHUC8,
    currentLocation,
    setCurrentLocation,
    updateContext
  } = useContext(AppContext);

  const [report, setReport] = useState<any>()
  const [locationInterval, setLocationInterval] = useState<any>()
  const [query, setQuery] = useState()

  useEffect(() => {
    const reportFromStorage = getReportFromStorage();
    setReport(reportFromStorage)
    setCountry(getCountryFromStorage())   
  }, [getCountryFromStorage()])

  const moving = currentRef.current?.isMoving()

  useEffect(() => {
    if (global.trackerOn) {
      if (moving) {
        setLocationInterval(
          window.setInterval(async () => {
            if(latitude && longitude){
              await agent.Utilities.LatLngToCity(latitude, longitude)
                .then(({ features, query }: any) => {
                  const featureCountry = features[0].place_name.split(',')[2].trim()
                  global.setCurrentCountry(featureCountry)
                  features.length > 0 && setCurrentLocation?.(features[0].place_name)
                  putCountryIntoStorage(featureCountry)
                  let huc = featureCountry === 'United States' || featureCountry === 'Mexico' ? selectedHUC8 : ''
                  query && setQuery(query)
                  updateContext?.(
                    huc = !huc ? '' : huc,
                    features[0].place_name,
                    global.currentReport,
                    country
                  )
                })
            }
          }, 500)
        )
      } else {
        clearInterval(locationInterval)
      }
    }

  }, [
    global.trackerOn,
    moving
  ])

  useEffect(() => {
    if (query && query[1] && query[0]) {
      if (country === 'United States') {
        agent.Utilities.LatLngToHuc8(query[1], query[0])
          .then(({ body }: any) => {
            if(body && body[0] && body[0].Huc8){
              const huc = body[0].Huc8
              updateContext?.(
                huc,
                currentLocation,
                global.currentReport,
                'United States'
              );             
            }
          })
      }
      if (country === 'Mexico') {
        agent.Utilities.LatLngToHUCMexico(query[1], query[0])
          .then(({ body }: any) => {
            const basin = body[0]['Basin Id']
            updateContext?.(
              basin,
              currentLocation,
              global.currentReport,
              'Mexico'
            );
          })
      }
    }
  }, [query])

  const renderSelectTooltip = (props:any) => (
     <Tooltip id="button-tooltip" {...props}
       className="app-tooltip">
         Search when I move map
      </Tooltip>
     );

  return (
    <div className="movesearch-container">
      {pathname === '/' && (
      <OverlayTrigger
                placement="auto"
                rootClose
                delay={{ show: 250, hide: 400 }}
                overlay={renderSelectTooltip }>
        <div className="breadcrumb-switch-container">
          <span><Form.Check type="switch" checked={global.trackerOn} onChange={() => global.setTrackerOn(!global.trackerOn)} /></span>
          <span className="breadcrumb-switch-title">Search when I move map</span>
          {/* @ts-ignore */}
          {global.trackerOn ? <FontAwesomeIcon className="on-light" icon={faCircle} size="2xs" style={{ color: 'green' }} /> : <div className="spacer"></div>}
        </div>
      </OverlayTrigger>
      )}
    </div>
  );
};

export default React.memo(MoveSearch);