import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTable, useSortBy, useGroupBy, useExpanded } from 'react-table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';

const ReportTableExpanded = ({
  data,
  columns,
  clickRowHandler = () => { },
  hoverRowHandler = () => { },
  clickLoadStop,
  redBorderStop,
  tableid,
}: {
  data: {}[];
  columns: any[];
  clickRowHandler?: (rowValue: any, event: any) => void;
  hoverRowHandler?: (rowValue: any, event: any) => void;
  clickLoadStop?: boolean;
  redBorderStop?: boolean;
  tableid?: string;
}) => {
  const [isFirstDataLoad, setIsFirstDataLoad] = useState(true);
  const tid = tableid || '-thistable';

  useEffect(() => {
    if (data.length > 0) {
      if (isFirstDataLoad) {
        const tableRowsHTMLElements =
          document.querySelectorAll('table > tbody > tr');
        if (tableRowsHTMLElements.length > 0) {
          const firstRowHtmlElement = tableRowsHTMLElements[0] as HTMLElement;
          if (!clickLoadStop) {
            firstRowHtmlElement.click();
          }
        }
        setIsFirstDataLoad(false);
      }
    }
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: data }, useSortBy,
      useExpanded);
 
  return (
    <>
      <div className='table-container table-container--scrollable' id={'table-container-expanded' + tid}>
        <Table
          className='reportTable'
           id={'table-expanded' + tid}
          {...getTableProps()}
          style={{ maxHeight: '30rem', overflow: 'auto' }}
        >
          <thead className='report-table__header'>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}  
                //@ts-ignore
                        style={column.size ? {width: column.size, insetInlineStart: 0} : (column.minsize ? {minWidth: column.minsize, insetInlineStart: 0} :  (column.maxsize ? {maxWidth: column.maxsize, insetInlineStart: 0} : {insetInlineStart: 0 }))}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted &&
                        (column.isSortedDesc ? <FontAwesomeIcon icon={faSortDown} size='xs' style={{ marginLeft: 4 }} /> : <FontAwesomeIcon icon={faSortUp} size='xs' style={{ marginLeft: 4 }} />)}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);

              // @ts-ignore
              const contaminate = row.original.Contaminant, redFlag = row.original.redFlag;
              const contaminateList = 'PFOS' || 'PFOA' || 'PFNA' || 'PFHxS' || 'PFHpA' || 'PFBS'
              let redBorderFlag = (contaminate === contaminateList && !redBorderStop); //||(redBorderStop && redFlag)

              return (
                <tr
                  style={{ 
                    border: redBorderFlag ? '2px solid red' : 'none'
                  }}
                  {...row.getRowProps()}
                  onClick={(event) => clickRowHandler(row.original, event)}
                  onMouseOver={(event) => hoverRowHandler(row.original, event)}
                >
                  {row.cells.map((cell) => {
                    // @ts-ignore
                    //  console.log(cell.row.original?.Contaminant)
                    let primary = false;

                    //if(cell.column.Header === 'Site Flow Name'){
                    if ((cell.row.isExpanded) && (cell.row.index === 0)) {
                      primary = true;
                    }
                    //}

                    return (
                      <td
                        style={(primary) ? { color: '#4fc2d9' } : undefined}
                        {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ReportTableExpanded;
