// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.source-text {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.25rem;
  color: white; }
  .source-text h3,
  .source-text a {
    font-size: 1.25rem; }
  .source-text a {
    color: #4fc32c;
    text-decoration: none; }

.lws-table-container {
  background-color: #0b1736;
  margin-top: 35px;
  border-radius: 12px;
  box-shadow: 0px 0px 20px #000;
  min-height: 22rem; }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .toxics-release-inventory .header-container h2 {
    font-size: 25px; } }
`, "",{"version":3,"sources":["webpack://./src/features/reports/GFSHuc12Forecast/source.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,WAAW;EACX,mBAAmB;EACnB,YAAY,EAAA;EALd;;IAQI,kBAAkB,EAAA;EARtB;IAWI,cAAc;IACd,qBAAqB,EAAA;;AAIzB;EACE,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;EACnB,6BAA6B;EAC7B,iBAAiB,EAAA;;AAGnB;EACE;IAES,eAAe,EAAA,EAAG","sourcesContent":[".source-text {\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n  margin-top: 1.25rem;\n  color: white;\n  h3,\n  a {\n    font-size: 1.25rem;\n  }\n  a {\n    color: #4fc32c;\n    text-decoration: none;\n  }\n}\n\n.lws-table-container {\n  background-color: #0b1736;\n  margin-top: 35px;\n  border-radius: 12px;\n  box-shadow: 0px 0px 20px #000;\n  min-height: 22rem;\n}\n\n@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {\n  .toxics-release-inventory {\n    .header-container {\n      h2 { font-size: 25px;}\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
