// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fib-report-container {
  overflow: scroll;
  max-width: unset;
  overflow-x: hidden; }
  .fib-report-container .top .fib-img-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; }
    .fib-report-container .top .fib-img-container .info-box {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 8%;
      left: 4%;
      color: white;
      background-color: #002c44bd;
      padding: 5px;
      border-radius: 4px; }
    .fib-report-container .top .fib-img-container .img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto; }
  .fib-report-container .fib-map-container,
  .fib-report-container .fib-img-container,
  .fib-report-container .line-chart {
    margin: 6px;
    height: 40vh;
    padding: 2px;
    border-radius: 4px;
    background-color: #0b1736;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; }
  .fib-report-container .fib-map-container {
    width: 100%; }
  .fib-report-container .line-chart {
    margin: 1rem auto 2px auto !important; }
`, "",{"version":3,"sources":["webpack://./src/features/reports/FIBReport/fib-report.scss"],"names":[],"mappings":"AAAA;EAEE,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB,EAAA;EAJpB;IAOM,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB,EAAA;IAVzB;MAYQ,aAAa;MACb,uBAAuB;MACvB,mBAAmB;MACnB,kBAAkB;MAClB,OAAO;MACP,QAAQ;MACR,YAAY;MACZ,2BAA2B;MAC3B,YAAY;MACZ,kBAAkB,EAAA;IArB1B;MAwBQ,eAAe;MACf,gBAAgB;MAChB,WAAW;MACX,YAAY,EAAA;EA3BpB;;;IAmCI,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,4CAA4C,EAAA;EAxChD;IA6CI,WAAW,EAAA;EA7Cf;IAiDI,qCAAqC,EAAA","sourcesContent":[".fib-report-container {\n\n  overflow: scroll;\n  max-width: unset;\n  overflow-x: hidden;\n  .top {\n    .fib-img-container {\n      position: relative;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      .info-box {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        position: absolute;\n        top: 8%;\n        left: 4%;\n        color: white;\n        background-color: #002c44bd;\n        padding: 5px;\n        border-radius: 4px;\n      }\n      .img {\n        max-width: 100%; \n        max-height: 100%; \n        width: auto; \n        height: auto; \n      }\n    }\n  }\n\n  .fib-map-container,\n  .fib-img-container,\n  .line-chart {\n    margin: 6px;\n    height: 40vh;\n    padding: 2px;\n    border-radius: 4px;\n    background-color: #0b1736;\n    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n    \n  }\n\n  .fib-map-container {\n    width: 100%;\n  }\n\n  .line-chart {\n    margin: 1rem auto 2px auto !important;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
