import { useEffect, useState, useRef, useCallback, useContext, Ref } from 'react';
import * as React from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import settings from '../../../settings.json';
import RightHeader from '../../../components/RightHeader';
import './styles.scss';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import {
  Map,
  useMap,
  MapRef,
  Popup,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  useControl,
  Layer,
  Source,
} from 'react-map-gl';
import type { LayerProps } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Feature, GeoJsonProperties, Point, FeatureCollection } from 'geojson';
import moment from 'moment';
import { DateTime } from 'luxon';
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import bbox from '@turf/bbox';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { ToastContainer, toast } from 'react-toastify';
import { HUC12InfoPoint } from '../../../types/HUC12InfoPoint';
import { RefContext } from '../../../RefContext'
import agent from '../../../api/agent';
import { parse } from 'papaparse';
import LoadingDataAnimation from '../../../components/LoadingDataAnimation';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import fullscreenIcon from '../../../assets/fullscreen-icon.svg';
import ellipsisIcon from '../../../assets/ellipsis-icon.svg';
import {
    QueryParamProvider,
    useQueryParam,
    BooleanParam,
} from 'use-query-params';
import {
  createFeatureCollection,
  processStatusMessage,
  uniqueValues,
  fitBoundsMapData,
  groupByKey,
  clearFilters,
  startingValues
} from '../shared/report-data';
import { message_hucrestriction, message_nodatalocation, message_noresult } from '../../../Constants';
import { AppContext } from '../../../AppContext';
import { CatalogsContext } from '../../../CatalogsProvider';
import { Location, useLocation } from 'react-router-dom';
import MapLegend from '../shared/MapLegend';
import MapSlider from '../shared/MapSlider';
import MapAddition from  '../shared/MapAddition';
import FilterState from '../shared/FilterState';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';


import { flyToInitialLocationHandler, getCurrentHuc12LongLat, getHuc12MembersOfHUC8, getCurrentHuc8LongLat } from '../../utils';

import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend);

export default function StormWaterTrueQIForecast(props: any) {
  const { global } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);

  const selectedHUC8Value = startingValues('huc', appContext.selectedHUC8, global);                                                    
  let selectedCategory = startingValues('ffl', 'All', global);
  const selectedFilterDays = global.filterDaysSelected || 0;
  const selectedFilterLocations = startingValues('fl', '', global);
  let selectedDateRange = startingValues('frda', [
    new Date(new Date().setDate(new Date().getDate() - 5)),
    new Date(),
  ], global); 

// const selectedHUC8Value = appContext.selectedHUC8; //'14010005';//'14010001'; //'03090205';

  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;

  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const [isFullScreenActive, setFullScreenActive] = useState(false);
  const fullscreenContainerHandler = () => {
    setFullScreenActive(!isFullScreenActive);
  };
  const [isDataReportLoaded, setIsDataReportLoaded] = useState(true);
  const [reportData, setReportData] = useState<any[]>([]);
  const [mapLegendData, setMapLegendData] = useState<any[]>([]);
  const [circleColorStyle, setCircleColorStyle] = useState<any>([]);
  const [chartData, setChartData] = useState<any>(null);
  const [summaryData, setSummaryData] = useState<any>(null);
  const [featureData, setFeatureData] = useState<FeatureCollection>();
  const [categoryExtent, setCategoryExtent] = useState<any>([0, 10]);
  const [mapLegendIntegerScale, setMapLegendIntegerScale] = useState<any>([0, 3, 7, 10]);
  const [layerStyle, setLayerStyle] = useState<any>([]);

  const [userSelectedLocation, setUserSelectedLocation] = useState<any>();
  const [userSelectedLocations, setUserSelectedLocations] = useState<any>([]);
  const [usLocations, setUsLocations] = useState<any>([]);

  const { current: map } = useMap();
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [cursor, setCursor] = useState<string>('');
  const [mapLoaded, setMapLoaded] = useState(false);
  const mapRef = useRef<MapRef>();
  const { setCurrentRef } = useContext(RefContext)
  useEffect(() => { setCurrentRef?.(mapRef) }, [mapRef])
  const [huc12Members, setHuc12Members] = useState<any[]>([]);
  const [huc12InfoCatalogArray, setHuc12InfoCatalogDataArray] = useState<any[]>([]);
  const [filterLabelDays, setFilterLabelDays] = useState<any>([]);
  const [statusMessage, setStatusMessage] = useState<any>(null);
  const [currentDate, setCurrentDate] = useState<any>(null);
  const [currentDateData, setCurrentDateData] = useState<any[]>([]);

  const locationField = 'huc12';
  const [mapHoverFilter, setMapHoverFilter] = useState<any>(['in', ['get', locationField], '']);
  const [qsr, setqsr] = useQueryParam('r', BooleanParam);
  const bounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  const divergingColors = [
    '#d73027',
    '#74add1',
    '#f46d43',
    '#fdae61',
    '#fee090',
    '#ffffbf',
    '#e0f3f8',
    '#abd9e9',
    '#74add1',
    '#4575b4',
  ];
  const mapLegendColorScale = ['#1a9850', '#a6d96a', '#fee08b', '#d73027'];
  const Categories = ['Magnesium', 'Nitrogen', 'Phosphorous', 'Potassium', 'Sulfur'];

  const [dateRange, setDateRange] = useState<any[]>([])

  function formatDates(inputDates: any) {
    const outputDates: any = [];
    inputDates.forEach((inputDate: any) => {
      const dateObj = new Date(inputDate);
      const day = dateObj.getDate().toString().padStart(2, '0');
      const month = dateObj.toLocaleString('default', { month: 'short' });
      const year = dateObj.getFullYear();
      const formattedDate = `${day} ${month} ${year}`;
      outputDates.push(formattedDate);
    });
    return outputDates;
  }

  useEffect(() => {
   
       
    global.setUserLogged(true);
    if (global.trackerOn) global.setTrackerOn(false)
  }, []);
  
  useEffect(() => {
    if (global.dateRangeSelected) {
      const formatted = formatDates(global.dateRangeSelected)
      setDateRange(formatted)
    }
  }, [global.dateRangeSelected])

  const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
    }, {} as Record<K, T[]>);

  useEffect(() => {
    global.setfilter1Selected('All');
    global.setfilterDaysSelected(0);
    global.setfilterLocationsSelected([]);

  }, []);

  useEffect(() => {
    if (
      selectedFilterLocations &&
      selectedFilterLocations.length > 0 &&
      selectedFilterLocations.indexOf('All') < 0
    ) {
      if (selectedFilterLocations[0].substr(0, 8) !== selectedHUC8Value)
        global.setfilterLocationsSelected([]);
    }
  }, []);

  let selectedLocation = (popupInfo && popupInfo.HUC12) || ['literal', selectedFilterLocations];

  useEffect(() => {
    const huc12InfoFileURL = '/huc12_info.csv';
    fetch(huc12InfoFileURL)
      .then(response => response.text())
      .then(responseText => {
        const data: HUC12InfoPoint[] | any[] = parse(responseText, {
          header: true,
        }).data;
        setHuc12InfoCatalogDataArray(data);
      });
  }, []);




  useEffect(() => {
    if (!isDataReportLoaded) {
      return;
    }
    setIsDataReportLoaded(false);
    setFilterLabelDays([]);
    setStatusMessage('');
    if (selectedHUC8Value && global.hucRestrict.length > 0 && (global.hucRestrict.indexOf(selectedHUC8Value) < 0)) {
      setIsDataReportLoaded(true);
      return global.notify(message_hucrestriction);
    }
    const daysToLimit = 67;
    let daysToQueryRange = 5;
    let startDate = '';
    const one_day = 1000 * 60 * 60 * 24;
    if (selectedDateRange && selectedDateRange.length > 1) {
      const daysDiff = Math.floor((selectedDateRange[1] - selectedDateRange[0]) / one_day) + 1; //+2 //+1
      daysToQueryRange = Math.min(daysDiff, daysToLimit);  //+++ timezone

      if(daysDiff > daysToLimit){
        global.notify('Queries limited to 60 days.');
      }

      startDate = DateTime.fromJSDate(new Date(selectedDateRange[1])).plus({ days: 1 }).toFormat('yyyyLLdd'); //.plus({days: 2})
    }
    daysToQueryRange = Math.max(1, daysToQueryRange);

    //const daysToQuery = Math.min(Math.max(+selectedFilterDays-1, 0), 40);
    if (selectedHUC8Value) agent
      .Reports
      .StormWaterTrueQIForecast(selectedHUC8Value, daysToQueryRange, startDate)
      .then((res: any) => {
        flyToInitialLocationHandler(selectedHUC8Value, mapRef, catalogsContext.huc8Catalog);
        //'15050100' Chandler  Winkelman, Arizona;
        //'14010001' Colorado Headwaters;
        //03100103 //03090205' Fort Myers Caloosahatchee;
        // 14010005   Grand Junction, 


        if (!res || !res.length || res.length < 1) {
          setIsDataReportLoaded(true);
          setStatusMessage(message_noresult);
          global.notify(message_noresult);
          //+++ Clear chart and map
          return;
        }

        let returnedData = [...res];
        if (!returnedData || !returnedData.length || returnedData.length < 1) {
          setIsDataReportLoaded(true);
          setStatusMessage('No result');
          global.notify('No result');
          return;
        }

        if (!returnedData[0]['date'] && returnedData[0]['datetime']) {
          for (let dataItem of returnedData) {
            dataItem['date'] = dataItem['datetime'];
          }
        }

        const dateExtent = d3Array.extent<any, any>(returnedData, d => d["date"]);
        if (dateExtent && dateExtent[0] && dateExtent[1]) {
          let filterDays = [DateTime.fromMillis(dateExtent[0]).toLocaleString(), DateTime.fromMillis(dateExtent[1]).toLocaleString()];
          setFilterLabelDays(filterDays);
        }
        returnedData = res.sort((x: any, y: any) => x['date'] - y['date']);

        updateElementFilters(returnedData);

        let newCategoryExtent = categoryExtent;
        if (returnedData.length > 0) {
          newCategoryExtent = d3Array.extent<any, any>(returnedData, d => d[selectedCategory]);

          let newMapLegendIntegerScale = [
            d3Format.format('.4')(newCategoryExtent[0]),
            d3Format.format('.4')(newCategoryExtent[1] * 0.3),
            d3Format.format('.4')(newCategoryExtent[1] * 0.7),
            d3Format.format('.4')(newCategoryExtent[1]),
          ];
          if (newCategoryExtent[1] > 1000) {
            newMapLegendIntegerScale = [
              d3Format.format(',.3r')(newCategoryExtent[0]),
              d3Format.format(',.3r')(newCategoryExtent[1] * 0.3),
              d3Format.format(',.3r')(newCategoryExtent[1] * 0.7),
              d3Format.format(',.3r')(newCategoryExtent[1]),
            ];
          }
          setMapLegendIntegerScale(newMapLegendIntegerScale);
          setMapLegendData(
            mapLegendColorScale.map((x: any, index: number) => ({
              typecode: newMapLegendIntegerScale[index],
              color: mapLegendColorScale[index],
            }))
          );
          setCategoryExtent(newCategoryExtent[1] === 0 ? [0, 1] : newCategoryExtent);
        }

        updateLayerStyle(newCategoryExtent[1] === 0 ? [0, 1] : newCategoryExtent);

        let newCurrentDate = returnedData[returnedData.length - 1]['date'];
        let newCurrentDateData = returnedData.filter((o: any) => o['date'] === newCurrentDate);
        setCurrentDate(newCurrentDate);
        setCurrentDateData(newCurrentDateData);

        updateChartData(returnedData);
        updateSummaryData(newCurrentDateData);

        updateLocationFilters(returnedData);

        setReportData(returnedData);
        setIsDataReportLoaded(true);
      });
  }, [selectedDateRange, selectedHUC8Value]);

  const flyToHuc = () => {
    if (mapRef.current) {
      const currentHuc8GeoPoint = getCurrentHuc8LongLat(
        appContext.selectedHUC8,
        catalogsContext.huc8Catalog
      );
      if (currentHuc8GeoPoint) {
        mapRef.current?.flyTo({
          center: [
            parseFloat(currentHuc8GeoPoint.centroid_longitude),
            parseFloat(currentHuc8GeoPoint.centroid_latitude),
          ],
          essential: true,
          zoom: 7,
        });
      }
    }
  };

  useEffect(() => {
    let filteredData = [...reportData];
    if (
      selectedFilterLocations &&
      selectedFilterLocations.length > 0 &&
      selectedFilterLocations.indexOf('All') < 0
    ) {
      filteredData = reportData.filter(
        (o: any) => selectedFilterLocations.indexOf(o[locationField]) > -1
      );
    }

    let newCategoryExtent = categoryExtent;
    if (filteredData.length > 0) {
      newCategoryExtent = d3Array.extent<any, any>(filteredData, d => d[selectedCategory]);

      let newMapLegendIntegerScale = [
        d3Format.format('.4')(newCategoryExtent[0]),
        d3Format.format('.4')(newCategoryExtent[1] * 0.3),
        d3Format.format('.4')(newCategoryExtent[1] * 0.7),
        d3Format.format('.4')(newCategoryExtent[1]),
      ];
      if (newCategoryExtent[1] > 1000) {
        newMapLegendIntegerScale = [
          d3Format.format(',.3r')(newCategoryExtent[0]),
          d3Format.format(',.3r')(newCategoryExtent[1] * 0.3),
          d3Format.format(',.3r')(newCategoryExtent[1] * 0.7),
          d3Format.format(',.3r')(newCategoryExtent[1]),
        ];
      }

      setMapLegendIntegerScale(newMapLegendIntegerScale);
      setMapLegendData(
        mapLegendColorScale.map((x: any, index: number) => ({
          typecode: newMapLegendIntegerScale[index],
          color: mapLegendColorScale[index],
        }))
      );
      setCategoryExtent(newCategoryExtent[1] === 0 ? [0, 1] : newCategoryExtent);
    }
    updateChartData(filteredData);
    updateLayerStyle(newCategoryExtent[1] === 0 ? [0, 1] : newCategoryExtent);

    let newCurrentDateData = filteredData.filter((o: any) => o['date'] === currentDate);
    setCurrentDateData(newCurrentDateData);
    updateSummaryData(newCurrentDateData);
  }, [selectedCategory, selectedFilterLocations, isDataReportLoaded]);

  useEffect(() => {
    if (
      selectedFilterLocations &&
      selectedFilterLocations.length > 0 &&
      selectedFilterLocations.indexOf('All') < 0
    ) {
      selectedLocation = selectedFilterLocations;
      fitMapData(huc12Members, selectedFilterLocations);
    }
  }, [selectedFilterLocations]);

  useEffect(() => {
    let filteredData = [...reportData];

    if (
      userSelectedLocations &&
      userSelectedLocations.length > 0 &&
      userSelectedLocations.indexOf('All') < 0
    ) {
      filteredData = filteredData.filter(
        (o: any) => userSelectedLocations.indexOf(o[locationField]) > -1
      );
    }
    updateChartData(filteredData);
  }, [userSelectedLocations]);

  useEffect(() => {
    const huc12Members = getHuc12MembersOfHUC8(selectedHUC8Value, catalogsContext.huc12Catalog);
    setHuc12Members(huc12Members);
    fitMapData(huc12Members, null);
    featureCollection(currentDateData);
  }, [mapLoaded, reportData]);

  const updateLocationFilters = (filteredData: any) => {
    const groupedresults = groupBy<any, any>(filteredData, i => i.huc12);
    const filter_labels: any[] = [];
    const dateArray = [];
    for (const key in groupedresults) {
      let hucname = key;
      if (huc12InfoCatalogArray && huc12InfoCatalogArray.length > 0) {
        const hucFilter = huc12InfoCatalogArray.filter((o: any) => o[locationField] === key);
        if (hucFilter && hucFilter.length > 0) {
          hucname = hucFilter[0].name;
        }
      }
      if (key !== 'undefined') {
        filter_labels.push({ huc12: key, hucname: hucname });
      }
    }
    const newLocations = filter_labels.sort((x: any, y: any) => (x.hucname < y.hucname ? -1 : 1));
    global.setfilterLocationsPopulated([{ hucname: 'All', huc12: 'All' }, ...newLocations]);
  };

  const updateElementFilters = (returnedData: any) => {
    for (let dataItem of returnedData) {
      const elements = { ...dataItem };
      if (elements) {
        delete elements.HUC8;
        delete elements.HUC12;
        delete elements.huc12;
        delete elements.hucname;
        delete elements.date;
        delete elements.datetime;
        delete elements.lat;
        delete elements.lng;
        let sumData: any[] = [];
        Object.keys(elements).forEach(function (key) {
          sumData.push(elements[key]);
        });
        dataItem.All = sumData.reduce((x, y) => x + y);
      }
    }
    const newCategories = ['All', ...Object.keys(returnedData[0]).slice(2).slice(0, -1)];
    if (newCategories.indexOf(selectedCategory) < 0) {
      global.setfilter1Selected(newCategories[0]);
    }
    global.setfilter1Populated(newCategories);
  };

  const updateChartData = (filteredData: any) => {
    const groupedresults = groupBy<any, any>(filteredData, i => i.huc12);

    const chartLabels = filteredData
      .map((item: any) => item['date'])
      .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
      .filter((value: any, index: any, self: any) => value)
      .sort();

    const chartdata_data: any[] = [];
    let chartdata_labels: any[] = [];
    for (const key in groupedresults) {
      let labelGroup = key;
      let customField = key;
      if (huc12InfoCatalogArray.length > 0) {
        const hucFilter = huc12InfoCatalogArray.filter((o: any) => o[locationField] === key);
        if (hucFilter && hucFilter.length > 0) {
          labelGroup = hucFilter[0].name.substr(0, 10);
          customField = hucFilter[0].name;
        }
      }
      let dataObject = {
        label: labelGroup,
        custom: customField,
        data: groupedresults[key].map((x: any) => x[selectedCategory]),
        backgroundColor: '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6),
      };
      chartdata_labels = chartLabels.map((x: any) => moment(x).format('MMM DD'));
      chartdata_data.push(dataObject);
    }

    const chartdata = {
      labels: chartdata_labels,
      datasets: chartdata_data,
      borderColor: '#ff6384',
    };
    setChartData(chartdata);
  };

  const updateSummaryData = (summaryData: any) => {
    const huc12ItemInfo = huc12InfoCatalogArray //+++ incomplete
      .filter((o: any) => o[locationField].substr(0, 8) === selectedHUC8Value)
      .sort((x: any, y: any) => (x.name < y.name ? -1 : 1));
    const summaryItems: any[] = [];

    const dateSubset = summaryData;
    for (let dataItem of huc12ItemInfo) {
      const dataHuc12 = dateSubset.filter((o: any) => o[locationField] === dataItem.huc12);
      dataItem.elements = [];
      const elements = { ...dataHuc12[0] };
      if (elements) {
        delete elements.HUC8;
        delete elements.HUC12;
        delete elements.huc12;
        delete elements.hucname;
        delete elements.date;
        delete elements.datetime;
        delete elements.lat;
        delete elements.lng;
        const element_data: any[] = [];
        Object.keys(elements).forEach(function (key) {
          element_data.push({ elementkey: key, elementvalue: elements[key] });
        });
        dataItem.element_data = element_data;
        if (Object.keys(elements).length > 1) {
          summaryItems.push(dataItem);
        }
      }
    }

    if (summaryItems.length > 0) {
      setSummaryData(summaryItems);
    }
  };

  const featureCollection = (currentData: any) => {
    const features: Array<Feature<Point, GeoJsonProperties>> = [];
    const addedHUC: any[] = [];
    const dateSubset_ = currentData;
    for (let dataItem of dateSubset_) {
      if (dataItem) {
        let name = '';
        if (dataItem.huc12) {
          name = dataItem.huc12;
        }
        if (dataItem.hucname) {
          name = dataItem.hucname;
        }
        const huc12Item = getCurrentHuc12LongLat(dataItem.huc12, catalogsContext.huc12Catalog);
        const huc12ItemInfo = huc12InfoCatalogArray.filter(
          (o: any) => o[locationField] === dataItem.huc12
        )[0];
        if (huc12Item) {
          if (huc12ItemInfo && huc12ItemInfo.hucname) {
            name = huc12ItemInfo.hucname;
          }

          const featureWithPoint: Feature<Point> = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [+huc12Item.centroid_longitude, +huc12Item.centroid_latitude],
            },
            properties: dataItem,
          };
          if (featureWithPoint && featureWithPoint.properties) {
            featureWithPoint.properties.lat = huc12Item.centroid_latitude;
            featureWithPoint.properties.lng = huc12Item.centroid_longitude;
            featureWithPoint.properties.HUC12 = dataItem.huc12;
            featureWithPoint.properties.HUC8 = selectedHUC8Value;
            featureWithPoint.properties.hucname = name;
          }
          features.push(featureWithPoint);
        }
      }
    }

    const featureCollectionFromReportData: FeatureCollection = {
      type: 'FeatureCollection',
      features: features,
    };
    setFeatureData(featureCollectionFromReportData);
  };

  const fitMapData = (reportData: any, filterData: any) => {
    const featureCollectionFromReportData = {
      type: 'FeatureCollection',
      features: [] as any,
    };
    for (let dataItem of reportData) {
      const feature = {
        type: 'Feature',
        properties: dataItem,
        geometry: {
          type: 'Point',
          coordinates: [dataItem.centroid_longitude, dataItem.centroid_latitude],
        },
      };
      feature.properties.HUC12 = dataItem.huc12;
      feature.properties.HUC8 = selectedHUC8Value;
      if (filterData && filterData.length > 0) {
        if (filterData.indexOf(dataItem.huc12) > -1) {
          featureCollectionFromReportData.features.push(feature);
        }
      } else {
        featureCollectionFromReportData.features.push(feature);
      }
    }
    if (featureCollectionFromReportData.features.length > 0) {
      const bbox_ = bbox(featureCollectionFromReportData);
      if (mapRef && mapRef.current) {
        mapRef.current.fitBounds(
          [
            [bbox_[0], bbox_[1]],
            [bbox_[2], bbox_[3]],
          ],
          {
            padding: { top: 40, bottom: 25, left: 45, right: 45 },
          }
        );
      }
    }
  };

  const updateLayerStyle = (newCategoryExtent: any) => {
    const layerStyle_: LayerProps = {
      id: 'pointlayer',
      type: 'circle' as const,
      paint: {
        'circle-radius': 8,
        'circle-color': [
          'interpolate',
          ['linear'],
          ['get', selectedCategory],
          0,
          '#1a9850',
          newCategoryExtent[1] * 0.1,
          '#66bd63',
          newCategoryExtent[1] * 0.2,
          '#a6d96a',
          newCategoryExtent[1] * 0.3,
          '#d9ef8b',
          newCategoryExtent[1] * 0.4,
          '#ffffbf',
          newCategoryExtent[1] * 0.5,
          '#fee08b',
          newCategoryExtent[1] * 0.7,
          '#fdae61',
          newCategoryExtent[1] * 0.8,
          '#f46d43',
          newCategoryExtent[1],
          '#d73027',
        ],
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1,
        'circle-opacity': 1,
      },
    };
    setLayerStyle(layerStyle_);
  };

  const hoverLayerStyle: LayerProps = {
    id: 'hoverlayer',
    type: 'circle' as const,
    paint: {
      'circle-radius': 10,
      'circle-color': '#000099',
      'circle-stroke-color': 'white',
      'circle-stroke-width': 1,
      'circle-opacity': 1,
    },
  };

  const labelForValue = (labelvalue: any, index: number) => {
    return labelvalue;
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: 'Value(Millions)',
          color: 'white',
        },
      },
      x: {
        display: true,
        title: {
          display: true,
          text: 'Timestamp',
          color: 'white',
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
        },
        maxHeight: 27,
      } as const,
      title: {
        display: true,
        text: 'Predicted Runoff - ' + selectedCategory,
        color: 'white',
        align: 'center',
        padding: 10,
        font: {
          size: 20,
        },
      } as const,
      tooltip: {
        padding: 10,
        bodyFont: {
          size: 24,
        },
        titleFont: {
          size: 24,
        },
        boxPadding: 8,
        usePointStyle: true,
        backgroundColor: '#12234f',
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || '';
            if (label) {
              label = [
                context.dataset.custom,
                'Predicted Runoff - ' + selectedCategory + ': ' + d3Format.format('.4r')(context.parsed.y),
              ];
            }
            return label;
          },
        },
      } as const,
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      } as const,
    } as const,
  } as const;

  const onMouseEnter = useCallback((event: any | null) => {
    if (event.features && event.features[0] && event.features[0].properties) {
      setCursor('pointer');
      setPopupInfo(event.features[0].properties);
      setUserSelectedLocation(event.features[0].properties.huc12);
      setMapHoverFilter(['in', ['get', locationField], event.features[0].properties.huc12]);
    }
  }, []);

  const onMouseLeave = useCallback((event: any | null) => {
    setCursor('');
    if (userSelectedLocation) {
      setUserSelectedLocation(null);
      setPopupInfo(null);
      setMapHoverFilter(['in', ['get', locationField], '']);
    }
    if (event && event.features && event.features[0]) {
      setPopupInfo(null);
      setUserSelectedLocation(null);
      setMapHoverFilter(['in', ['get', locationField], '']);
    }
  }, []);

  useEffect(() => {
    if (usLocations.length > 0) {
      if (userSelectedLocations.indexOf(usLocations[0].huc12) > -1) {
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', locationField], '']);
      } else {
        setUserSelectedLocations(usLocations[0].huc12);
        setPopupInfo(usLocations[0]);
        setTimeout(() => {
          setMapHoverFilter(['in', ['get', locationField], usLocations[0].huc12]);
        }, 555);
      }
    }
  }, [usLocations]);

  const onHandleClick = useCallback((event: any | null) => {
    const feature = event.features && event.features[0];
    if (feature) {
      setUsLocations([feature.properties]);
    }
  }, []);

  const onMapLoad = useCallback(() => {
    if (mapRef && mapRef.current) {
      setMapLoaded(true);
      mapRef.current.resize();
    }
  }, []);

  const pointInPolygonUpdate = (pointsSelected: any) => {
        setUserSelectedLocations(pointsSelected);
        setMapHoverFilter(['in', ['get', locationField], ['literal', pointsSelected]]);
  }

  const onUpdate = useCallback((e: any | null) => {
    if (mapRef && mapRef.current) {
      const pointlayerFeatures = mapRef.current.queryRenderedFeatures(undefined, {
        layers: ['pointlayer'],
      });
      const pointsSelected: any[] = [];

      if (pointlayerFeatures.length > 0) {
        for (let dataItem of pointlayerFeatures) {
          if (dataItem && dataItem.properties && dataItem.properties.lng) {
            const inSelection = booleanPointInPolygon(
              [dataItem.properties.lng, dataItem.properties.lat],
              e.features[0]
            );
            if (inSelection) {
              pointsSelected.push(dataItem.properties[locationField]);
            }
          }
        }

        setUserSelectedLocations(pointsSelected);
        setMapHoverFilter(['in', ['get', locationField], ['literal', pointsSelected]]);
      }
    }
  }, []);

  const onDelete = useCallback((e: any | null) => {
    if (mapRef && mapRef.current) {
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', locationField], '']);
    }
  }, []);


  const renderPopup = () => {
    return (
      <Popup
        longitude={Number(popupInfo.lng)}
        latitude={Number(popupInfo.lat)}
        onClose={() => setPopupInfo(null)}
      >
        <div className='popup-container'>
          <h4 className='popup-content-highlight'>{popupInfo['hucname']}</h4>
          <div className='popup-content'>
            <div className='popup-content-left'>
              <p className='popup-content-right-double'>Contaminant Runoff - {selectedCategory}</p>
              <p>Latest TimeStamp</p>
            </div>
            <div className='popup-content-right'>
              <p className='popup-content-right-double'>
                {d3Format.format('.4r')(popupInfo[selectedCategory])}
              </p>
              <p>{moment(popupInfo['date']).format('YYYY-MM-DD hh a')}</p>
            </div>
          </div>
        </div>
      </Popup>
    );
  };

  const deselectFilters = useCallback((e: any | null) => {
    setPopupInfo(null);
    setMapHoverFilter(['in', ['get', 'dmrid'], '']);
    setUserSelectedLocations([]);
  }, []);

  return (
    <>
      <FullScreenComp handle={fullscreenhandle}>
        <div className='StormWaterTrueQIForecast' id='StormWaterTrueQIForecast-report'>
          <ReportHeader global={global} data={summaryData} filterLabelDays={dateRange}
            reportID={"StormWaterTrueQIForecast-report"} fullScreenClickHandle={fullscreenhandle.enter} />
          <div className='container'>
            <div className='col'>
              <div className='row gx-0'>
                <div className='map-best-container'>
                  <LoadingDataAnimation dataLoading={!isDataReportLoaded} />
                  <RightHeader global={global} button={false} />
                  <Map
                    id='StormWaterTrueQIForecast'
                    mapboxAccessToken={settings.maboxKey}
                    mapStyle={global.mapStyle}
                    onLoad={onMapLoad}
                    onClick={(e) => {
                      global.onMapClick(e)
                      onHandleClick(e)
                    }}
                    onMoveEnd={(e) => {
                      global.setViewport({
                        longitude: e.viewState.longitude,
                        latitude: e.viewState.latitude,
                        zoom: e.viewState.zoom,
                      })
                    }}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    preserveDrawingBuffer={true}
                    interactiveLayerIds={['pointlayer']}
                    projection={global.globeView ? 'globe' : 'mercator' as any}
                    cursor={cursor}
                    ref={mapRef as Ref<MapRef>}
                  >
                    {featureData && (
                      <Source id='circlesource' type='geojson' data={featureData}>
                        <Layer {...layerStyle} />
                        <Layer {...hoverLayerStyle} filter={mapHoverFilter} />
                      </Source>
                    )}

                    <MapAddition global={global} 
                                mapRef={mapRef}
                                onDelete={onDelete}
                                PointInPolygonField={locationField}
                                PointInPolygonUpdate={pointInPolygonUpdate}
                                position={'low'}
                                zipOff={true}
                                MapSliderAdd={true}
                                statusMessage={statusMessage}/>

                    {popupInfo && renderPopup()}

                    <div className='map-legend-container' onClick={deselectFilters}>
                      <MapLegend
                        mapLegendData={mapLegendData}
                        title={'Contaminant Runoff - ' + selectedCategory.substr(0, 30)}
                        global={global}
                        legendWidth={310}
                      />
                    </div>
                  </Map>

                </div>
              </div>

              <div className='row summary-chart-row'>
                <div
                  className={
                    isFullScreenActive
                      ? 'summary-container expand-container-summary'
                      : 'summary-container'
                  }
                >
                  <div className='row summary-header'>
                    <span>Summary</span>
                    <Button
                      className={
                        isFullScreenActive
                          ? 'summary-button expand-summary-button'
                          : 'summary-button'
                      }
                      onClick={fullscreenContainerHandler}
                    >
                      <img src={fullscreenIcon} />
                    </Button>
                  </div>
                  {summaryData &&
                    summaryData.map((variant: any, index: number) => (
                      <Card
                        key={variant.huc12 + index}
                        text='light'
                        className={index % 2 === 0 ? 'mb-2 te-primary' : 'mb-2 te-secondary'}
                      >
                        <Card.Header>{variant.name}</Card.Header>
                        <Card.Body>
                          <Card.Title>
                            <hr />
                            <p>{d3Format.format(',')(+variant.area_acres)}</p>
                            <p className='summary-card-label'>Area Acres</p>
                            <hr />
                            <p>{variant.landuse || 'NA'}</p>
                            <p className='summary-card-label'>Land use</p>
                            <hr />
                            <p>{variant.huc12}</p>
                            <p className='summary-card-label'>HUC12</p>
                            <hr className='summary-card-label-hr' />
                          </Card.Title>
                          <Card.Text>
                            {variant.element_data &&
                              variant.element_data.map((elementItem: any, index: number) => (
                                <span key={'k' + elementItem.elementkey + elementItem.elementvalue}>
                                  <span
                                    key={elementItem.elementkey + elementItem.elementvalue - 1}
                                    className='summary-container-span'
                                  >
                                    {d3Format.format('.5r')(elementItem.elementvalue)}
                                  </span>
                                  <br />
                                  <span
                                    className='summary-card-span-label'
                                    key={elementItem.elementkey + elementItem.elementvalue}
                                  >
                                    {elementItem.elementkey}:
                                  </span>
                                  <br />
                                  <br />
                                </span>
                              ))}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    ))}
                </div>
                <div className='chart-container'>
                  {chartData && <Bar options={options} data={chartData} />}
                </div>
              </div>

              <ReportFooter />
            </div>
          </div>
        </div>
  
      </FullScreenComp>
    </>
  );
}

type StormWaterTrueQIForecastModel = {
  date?: number;
  huc12?: string;
};
