import { Source, Layer, Popup, FillLayer, CircleLayer, LineLayer } from 'react-map-gl';
import settings from "./../../settings.json";
import { useState, useContext, useEffect } from 'react';
import { RefContext } from "./../../RefContext";
import { PointContext } from "./../../PointContext";
import { v4 as uuidv4 } from 'uuid';

const StreamAnomaly = ({ id, url, opacity, global }: any) => {
  let rangeSel = "nws";  
  let categorySel = `14`;  
  let _currentUrl = `https://hazards.fema.gov/arcgis/rest/services/FIRMette/NFHLREST_FIRMette/MapServer/export?bbox={bbox-epsg-3857}&dpi=96&transparent=true&format=png32&layers=show:0,2,4,5,7,8,9,12,13,14,15,18,19,20,21&bboxSR=102100&imageSR=102100&f=image`
  _currentUrl = `https://mapservices.weather.noaa.gov/vector/rest/services/obs/NWM_Stream_Analysis/MapServer/export?bbox={bbox-epsg-3857}&transparent=true&format=png32&layers=show:${categorySel}&bboxSR=102100&imageSR=3857&f=image`


  const [currentUrl, setCurrentUrl] = useState<any>(_currentUrl);
  const [currentRange, setCurrentRange] = useState<any>(categorySel);

  const { currentRef } = useContext(RefContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
  // @ts-ignore
  const mapInstance = currentRef && currentRef?.current;

  useEffect(() => {
    let layerLevel = 14;
    if(global.StreamAnomalyBrightness===.1){
      layerLevel = 0;
    } else if (global.StreamAnomalyBrightness===.2) {
      layerLevel = 7;
    }

    setCurrentUrl(`https://mapservices.weather.noaa.gov/vector/rest/services/obs/NWM_Stream_Analysis/MapServer/export?bbox={bbox-epsg-3857}&transparent=true&format=png32&layers=show:${layerLevel}&bboxSR=102100&imageSR=3857&f=image`);
  }, [global.StreamAnomalyBrightness]);

  return (
    <div>
    <Source
      id={`${id}-layer`}
      key={`${id}-layer`+'currentRange'}
      type="raster"
      tiles={[currentUrl]}>
      <Layer      
        beforeId={settings.defaultBeforeId}
        paint={{ 'raster-opacity': opacity }}
        type="raster" id={`${id}-layer`}
        source={`${id}-ayer`}       
      />
    </Source>

    </div>
  )
}

export default StreamAnomaly