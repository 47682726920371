import { useState, useContext, useEffect, useMemo, useCallback, memo } from 'react';
import { RefContext } from "../../RefContext";
import { PointContext } from "../../PointContext";
import { Layer, Source, Popup } from 'react-map-gl';
import { adjustHexBrightness } from "../../features/utils";
import { FillLayer, LineLayer } from 'mapbox-gl';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { ActivePopupContext } from '../../ActivePopupContext';

const HydrobasinsCombined = ({ id, global, opacity, tileSet, source, brightness, action }: any) => {
  const [popupData, setPopupData] = useState<any>(null);
  const { currentRef } = useContext(RefContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);

  // @ts-ignore
  const ref = currentRef?.current;
  const lngLat = action?.lngLat;
  const navigate = useNavigate();

  // Memoized fill layer configuration
  const HydrobasinsCombinedFill: FillLayer = useMemo(() => ({
    id,
    type: "fill",
    "source-layer": source,
    minzoom: 0.38,
    maxzoom: 6.24,
    paint: {
      "fill-opacity": opacity,
      'fill-color': adjustHexBrightness("#567A9F", brightness),
    },
  }), [id, source, opacity, brightness]);

  // Memoized line layer configuration
  const HydrobasinsCombinedLine: LineLayer = useMemo(() => ({
    id: `${id}-outline`,
    type: "line",
    "source-layer": source,
    minzoom: 0.38,
    maxzoom: 6.24,
    paint: {
      "line-color": "#000000",
      "line-width": 3,
    },
  }), [id, source]);

  // Function to query map features
  const getFeaturesAtPoint = useCallback(async (map: any, event: any) => {
    const features = await map.queryRenderedFeatures(event.point)?.[0];
    if (features?.properties) {
      setPopupData(features.properties);
    }
  }, []);

  // Navigate to report with selected point
  const goToReport = useCallback((input: any[], lat: any, lng: any) => {
    setCurrentPoint({ ...input, lat, lng });
    setTimeout(() => navigate('/HydrobasinsReport'), 1000);
  }, [setCurrentPoint, navigate]);

  // Handle closing the popup
  const handleClose = useCallback(() => {
    setActivePopup({ id: null, content: null });
    setPopupData(null);
  }, [setActivePopup]);

  // Set popup content if data and coordinates are available
  useEffect(() => {
    if (popupData && lngLat) {
      const { lat, lng } = lngLat;
      const popupElement = (
        <Popup longitude={lng} latitude={lat} onClose={handleClose} style={{ width: '150px' }}>
          <FontAwesomeIcon className="close-btn" icon={faXmark} onClick={handleClose} />
          <div className="popup-content">
            <strong>Feature ID: {popupData.id}</strong>
            <br />
            <button onClick={() => goToReport(popupData, lat, lng)}>Go to Report</button>
          </div>
        </Popup>
      );
      setActivePopup({ id, content: popupElement });
    }
  }, [popupData, lngLat, goToReport, id, setActivePopup, handleClose]);

  // Add click event listener to map
  useEffect(() => {
    if (ref) {
      const handleClick = (e: any) => getFeaturesAtPoint(ref, e);
      ref.on('click', handleClick);
      return () => ref.off('click', handleClick);
    }
  }, [ref, getFeaturesAtPoint]);

  return (
    <>
      <Source id={id} type="vector" url={tileSet}>
        <Layer beforeId={`${id}-outline`} {...HydrobasinsCombinedFill} />
        <Layer {...HydrobasinsCombinedLine} />
      </Source>
      {activePopup?.id === id && activePopup.content}
    </>
  );
};

export default memo(HydrobasinsCombined);
