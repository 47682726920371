import { useMap, MapRef, Popup, Layer, Source } from "react-map-gl";
import { useEffect, useContext, useState, useMemo } from 'react';
import { RefContext } from '../../../RefContext';
import { Expression, StyleFunction } from 'mapbox-gl';
import { GeoJsonProperties,
           Point,
           FeatureCollection,
         } from 'geojson';
import { v4 as uuidv4 } from 'uuid';
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import * as d3Scale from 'd3-scale';
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import bbox from '@turf/bbox';
import type { LayerProps } from 'react-map-gl';
import './WaterQualityPortal.scss';
//        
const WaterQualityPortal = (props: any) => {
    const {
        id,
        beforeId = "",
        fillColor,
        opacity,
        outlineColor,
        tileSet,
        sourceLayer,
        global,
        customCategory = 'result',
        data
    } = props

    const initLayerStyle: LayerProps = {
      id: `wqplayer`,
      type: 'circle' as const,
      paint: {
        'circle-radius': 8,
        'circle-color': 'blue',      
      }
    };

    const [layerStyle, setLayerStyle] = useState<any>(initLayerStyle);
    const [featureData, setFeatureData] = useState<any>(data);
    const [popupInfo, setPopupInfo] = useState<any>(null);

    const { currentRef } = useContext(RefContext)
    // @ts-ignore
    const mapInstance = currentRef && currentRef?.current


    useEffect(() => {
        updateLayerStyle();
    }, [])

    useEffect(() => {       
    
        if(data && data.features && data.features.length>0){
            const pointsExtent = d3Array.extent<any, any>(data.features, d => +d.properties[customCategory]);          
            const bbox_ = bbox(data);
            //console.log(currentRef)

            // @ts-ignore
            if(currentRef && currentRef.current){// @ts-ignore
              let cRef = currentRef.current;
              setTimeout(() => {// @ts-ignore
                cRef.fitBounds([[bbox_[0], bbox_[1]], [bbox_[2], bbox_[3]]], {
                  padding: {top: 110, bottom:115, left: 115, right: 115}
                })
              }, 3111);            
            }

          setFeatureData(data);  
          updateLayerStyle(pointsExtent);                   
        } else {
          const featureCollectionEmpty: FeatureCollection = {
             type: "FeatureCollection",
             features: []
          };    
          setFeatureData(featureCollectionEmpty);
        }
    }, [data])

    useMemo(() => mapInstance && mapInstance.on('click', (event: any) => {
        const layerObject = mapInstance.queryRenderedFeatures(event.point);      
        if (layerObject && layerObject.length>0&& layerObject[0].properties) {
          if(layerObject[0].properties["centroid_longitude"] && !isNaN(layerObject[0].properties["centroid_longitude"])&&layerObject[0].properties["centroid_latitude"] && !isNaN(layerObject[0].properties["centroid_latitude"])){
            setPopupInfo(layerObject[0].properties);
          }
        }
    }), [mapInstance])

    useMemo(() => mapInstance && mapInstance.on('mouseenter', 'wqplayer', (event: any) => {
        event.target.getCanvas().style.cursor = "pointer";
    }), [mapInstance])

    useMemo(() => mapInstance && mapInstance.on('mouseleave', 'wqplayer', (event: any) => {
        event.target.getCanvas().style.cursor = "default";
    }), [mapInstance])

    const updateLayerStyle = (mapExp?: any) => {         
        let newCategoryExtent = [0, 40];
        if(mapExp){
          newCategoryExtent = mapExp;
        }

        const colorNormalizedScale = d3Scale.scaleLinear()
              .range([1, 0])
              .domain(newCategoryExtent);

        const newLayerStyle : LayerProps =  {
          id: 'wqplayer',
          type: 'circle' as const,  
          paint: {
            'circle-radius': ['get', 'radius'], //@ts-ignore
            'circle-color': [
              'interpolate-hcl',
              ['linear'],
              ['get', customCategory],
              0,
              colorScalePoints(colorNormalizedScale(newCategoryExtent[0])),
              newCategoryExtent[1] * 0.05,
              colorScalePoints(colorNormalizedScale(newCategoryExtent[1] * 0.05)),
              newCategoryExtent[1] * 0.1,
              colorScalePoints(colorNormalizedScale(newCategoryExtent[1] * 0.1)),
              newCategoryExtent[1] * 0.15,
              colorScalePoints(colorNormalizedScale(newCategoryExtent[1] * 0.15)),
              newCategoryExtent[1] * 0.2,
              colorScalePoints(colorNormalizedScale(newCategoryExtent[1] * 0.2)),
              newCategoryExtent[1] * 0.3,
              colorScalePoints(colorNormalizedScale(newCategoryExtent[1] * 0.3)),
              newCategoryExtent[1] * 0.5,
              colorScalePoints(colorNormalizedScale(newCategoryExtent[1] * 0.5)),
              newCategoryExtent[1] * 0.7,
              colorScalePoints(colorNormalizedScale(newCategoryExtent[1] * 0.7)),
              newCategoryExtent[1],
              colorScalePoints(colorNormalizedScale(newCategoryExtent[1])),
            ],
            'circle-stroke-color': 'white',
            'circle-stroke-width': 1,
            'circle-opacity': 0.8
          }
        };
        setLayerStyle(newLayerStyle);
      }

    const colorScalePoints = (evalValue: number) => {
        const colorValue = d3ScaleChromatic.interpolatePuOr(evalValue);
        return colorValue;
    }


//characteristicGroupResultCount
//https://mywaterway.epa.gov/monitoring-report/STORET/21GPBCH/21GPBCH-PPB1/
//"https://www.waterqualitydata.us/provider/STORET/21GPBCH/21GPBCH-PPB1/"
//OrganizationIdentifier
//MonitoringLocationIdentifier

    const renderFields = (objectMap: object) => {
      console.log(objectMap)
        return Object.entries(objectMap).map((element: any, index:any) => {
          const skipFields = ['layerName', 'counts', 'results', 'radius'];
          if(skipFields.indexOf(element[0])>-1){
            return null;
          } else if (element[0] === 'siteUrl') {
            return (
              <div>
                <p className="GD-p-comid" key={uuidv4()}>
                  <span style={{opacity: .9}}>{'Site info:'}</span>
                  <span style={{marginLeft: 10, fontSize: '1.3em', opacity: 1}}>  
                    <a href={element[1]} target={'_blank'}>Navigate to more site info at EPA</a>
                  </span>
                </p>
                <p className="GD-p-comid" key={uuidv4()}>
                  <span style={{marginLeft: 63, fontSize: '1.3em', opacity: 1}}>  
                    <a href={element[1].replace("www.waterqualitydata.us/provider", "mywaterway.epa.gov/monitoring-report")} target={'_blank'}>Navigate to EPA's Monitoring Report</a>
                  </span>
                </p>
              </div>
              )        
          } else if (element[0] === 'characteristicGroupResultCount') {
            return (
              Object.entries(JSON.parse(element[1])).map((elementb: any, indexb:any) => {
                return (<p className="GD-p-comid" key={uuidv4()}>
                    <span style={{opacity: .9}}>{elementb[0]}:</span>
                    <span style={{marginLeft: 10, fontSize: '1.3em', opacity: 1}}>    {elementb[1]} </span>
                  </p>)

                })
              )        
          } else {
            return (
                <p className="GD-p-comid" key={uuidv4()}>
                  <span style={{opacity: .9}}>{element[0]}:</span>
                  <span style={{marginLeft: 10, fontSize: '1.3em', opacity: 1}}>    {element[1]} </span>
                </p>
              )        
          }
        })
    }

    const renderPopup = () => {
      return(
        <Popup
            longitude={Number(popupInfo.centroid_longitude)}
            latitude={Number(popupInfo.centroid_latitude)}
            closeButton={true}
            closeOnClick={true}
            className="wqp"
            onClose={() => setPopupInfo(null)}>
          <div className="popup-container">
            {renderFields(popupInfo)}
          </div>
        </Popup>
      )
    }
    console.log(`${id}`);
    return (
        <>
            <Source
                id={`${id}`}
                type="geojson"
                data={featureData}
            >
                <Layer
                    
                    {...layerStyle} 
                />
                {popupInfo && renderPopup()}
            </Source>
        </>
    )
}

export default WaterQualityPortal;  