import {
  useState,
  useEffect,
  useContext,
  useMemo,
  useRef
} from 'react'
import {
  Col,
  Row,
  Accordion,
  Form
} from 'react-bootstrap'
import { AppContext } from '../../../AppContext';
import ReportTable from '../../../features/reports/shared/ReportTable'
import Breadcrumb from '../shared/Breadcrumb'
import Map, {
  FullscreenControl,
  Marker,
  NavigationControl,
  GeolocateControl,
} from 'react-map-gl';
import ReportTitle from '../shared/report-title';
import settings from '../../../settings.json';
import { message_hucrestriction, message_nodatalocation, message_noresult, message_unavailable } from '../../../Constants';
import { RefContext } from '../../../RefContext';
import agent from '../../../api/agent';
import RenderLayers from '../shared/RenderLayers'
import RenderLayerMarkers from '../shared/RenderLayerMarkers'
import { putViewportIntoStorage } from '../../utils';
import diamond from '../../../assets/chem-diamond.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import MapSlider from '../shared/MapSlider';
import {
  faChevronRight,
  faChevronLeft,
  faMaximize,
  faMinimize
} from '@fortawesome/pro-solid-svg-icons'
import ReportOptions from '../shared/ReportOptions';
import {
  FullScreen,
  useFullScreenHandle
} from 'react-full-screen';
import MapLegend from '../shared/MapLegend';

import './cdr-styles.scss'

const ChemicalDataReporting = (props: any) => {
  const { global } = props;
  const { selectedHUC8 } = useContext(AppContext);
  const mapRef = useRef<any>();
  const { setCurrentRef } = useContext<any>(RefContext)

  useEffect(() => setCurrentRef?.(mapRef), [])

  const [consumer, setConsumer] = useState()
  const [industrial, setIndustrial] = useState()
  const [manufacturer, setManufacturer] = useState()

  const [table1Expanded, setTable1Expanded] = useState(false)
  const [table2Expanded, setTable2Expanded] = useState(false)
  const [table3Expanded, setTable3Expanded] = useState(false)
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;

  const [lat, setLat] = useState(0)
  const [lng, setLng] = useState(0)

  const bounds = mapRef.current
    ? mapRef.current
      .getMap()
      .getBounds()
      .toArray()
      .flat()
    : null;

  const tableColumns1 = useMemo(() => [
    {
      Header: 'Activity',
      accessor: 'Activity',
    },
    {
      Header: 'Chemical ID',
      accessor: 'Chemicalid',
    },
    {
      Header: 'Chemical ID Type',
      accessor: 'Chemicalidtype',
    },
    {
      Header: 'Chemical ID w/o Dashes',
      accessor: 'Chemicalidwodashes',
    },
    {
      Header: 'Chemical Name',
      accessor: 'Chemicalname',
    },
    {
      Header: 'Chemical Report ID',
      accessor: 'Chemicalreportid',
    },
    {
      Header: 'Domestic Parent Company Name',
      accessor: 'Domesticparentcompanyname',
    },
    {
      Header: 'Domestic PC Address Line 1',
      accessor: 'Domesticpcaddressline1',
    },
    {
      Header: 'Domestic PC Address Line 2',
      accessor: 'Domesticpcaddressline2',
    },
    {
      Header: 'Domestic PC City',
      accessor: 'Domesticpccity',
    },
    {
      Header: 'Domestic PC County Parish',
      accessor: 'Domesticpccountyparish',
    },
    {
      Header: 'Domestic PC Dunbrad Street Number',
      accessor: 'Domesticpcdunbradstreetnumber',
    },
    {
      Header: 'Domestic PC Postal Code',
      accessor: 'Domesticpcpostalcode',
    },
  ], [])

  const tableColumns2 = useMemo(() => [
    {
      Header: 'Domestic PC State',
      accessor: 'Domesticpcstate',
    },
    {
      Header: 'Domestic PV 2019',
      accessor: 'Domesticpv 2019',
    },
    {
      Header: 'EPA Facility Registry ID',
      accessor: 'Epafacilityregistryid',
    },
    {
      Header: 'Epatsca Program ID',
      accessor: 'Epatscaprogramid',
    },
    {
      Header: 'Foreign Parent Company Name',
      accessor: 'Foreignparentcompanyname',
    },
    {
      Header: 'Foreign PC Address Line 1',
      accessor: 'Foreignpcaddressline1',
    },
    {
      Header: 'Foreign PC City',
      accessor: 'Foreignpccity',
    },
    {
      Header: 'Foreignpccountrycode',
      accessor: 'Foreign PC Country Code',
    },
    {
      Header: 'Foreign PC County Parish',
      accessor: 'Foreignpccountyparish',
    },
    {
      Header: 'Foreign pc Dunbrad Street Number',
      accessor: 'Foreignpcdunbradstreetnumber',
    },
    {
      Header: 'Foreign PC Postal Code',
      accessor: 'Foreignpcpostalcode',
    },
    {
      Header: 'Huc8',
      accessor: 'Huc8',
    },
    {
      Header: 'Imported Chem Never At Site',
      accessor: 'Importedchemneveratsite',
    },
  ], [])

  const tableColumns3 = useMemo(() => [
    {
      Header: 'Import PV 2019',
      accessor: 'Importpv 2019',
    },
    {
      Header: 'Joint FC code',
      accessor: 'Jointfccode',
    },
    {
      Header: 'Joint Functca to the rdesc',
      accessor: 'Jointfunctcatotherdesc',
    },
    {
      Header: 'Jointfunctioncategory',
      accessor: 'Joint Function Category',
    },
    {
      Header: 'Max Conc Code',
      accessor: 'Maxconccode',
    },
    {
      Header: 'Maximumconcentration',
      accessor: 'Maximum Concentration',
    },
    {
      Header: 'National lyaggregatedpv 2016',
      accessor: 'Nationallyaggregatedpv 2016',
    },
    {
      Header: 'National lyaggregatedpv 2017',
      accessor: 'Nationallyaggregatedpv 2017',
    },
    {
      Header: 'National lyaggregatedpv 2018',
      accessor: 'Nationallyaggregatedpv 2018',
    },
    {
      Header: 'National lyaggregatedpv 2019',
      accessor: 'Nationallyaggregatedpv 2019',
    },
    {
      Header: 'Pct By P Code',
      accessor: 'Pctbypcode',
    },
    {
      Header: 'Percent By Product',
      accessor: 'Percentbyproduct',
    },
    {
      Header: 'Physical Forms',
      accessor: 'Physicalforms',
    },
  ], [])

  const tableColumns4 = useMemo(() => [
    {
      Header: 'Pv 2016',
      accessor: 'Pv 2016',
    },
    {
      Header: 'Pv 2017',
      accessor: 'Pv 2017',
    },
    {
      Header: 'Pv 2018',
      accessor: 'Pv 2018',
    },
    {
      Header: 'Pv 2019',
      accessor: 'Pv 2019',
    },
    {
      Header: 'Site Address Line 1',
      accessor: 'Siteaddressline1',
    },
    {
      Header: 'Site Address Line 1',
      accessor: 'Siteaddressline2',
    },
    {
      Header: 'Site City',
      accessor: 'Sitecity',
    },
    {
      Header: 'Site County Parish',
      accessor: 'Sitecountyparish',
    },
    {
      Header: 'Site Dunbrad Street Number',
      accessor: 'Sitedunbradstreetnumber',
    },
    {
      Header: 'Site Latitude',
      accessor: 'Sitelatitude',
    },
    {
      Header: 'Site Longitude',
      accessor: 'Sitelongitude',
    },
    {
      Header: 'Site NAICS Activity 1',
      accessor: 'Sitenaicsactivity1',
    },
    {
      Header: 'Site NAICS Activity 2',
      accessor: 'Sitenaicsactivity2',
    }
  ], [])

  const tableColumns5 = useMemo(() => [
    {
      Header: 'Site NAICS Activity 3',
      accessor: 'Sitenaicsactivity3',
    },
    {
      Header: 'Site NAICS Code 1',
      accessor: 'Sitenaicscode1',
    },
    {
      Header: 'Site NAICS Code 2',
      accessor: 'Sitenaicscode2',
    },
    {
      Header: 'Site NAICS Code 3',
      accessor: 'Sitenaicscode3',
    },
    {
      Header: 'Site Name',
      accessor: 'Sitename',
    },
    {
      Header: 'Site Postal Code',
      accessor: 'Sitepostalcode',
    },
    {
      Header: 'Sitestate',
      accessor: 'Site State',
    },
    {
      Header: 'Vexported 2019',
      accessor: 'Vexported 2019',
    },
    {
      Header: 'Vusedonsite 2019',
      accessor: 'Vusedonsite 2019',
    },
    {
      Header: 'Workers',
      accessor: 'Workers',
    },
    {
      Header: 'Workers Caode',
      accessor: 'Workerscode',
    },
  ], [])


  const filterYear = (input: any) => {

    const regex = /(?:(?:19|20)[0-9]{2})/
    const regexString = /^([^0-9]*)$/

    let array = []

    for (let i = 0; i < input.length; i++) {
      let element = input[i]
      if (element.Header.match(regex) && element.Header.match(regex)[0] === global.selectedYearFilter) {
        array.push(element)
      }
    }

    for (let i = 0; i < input.length; i++) {
      let element = input[i]
      if (regexString.test(element.Header)) {
        array.push(element)
      }
    }

    return array
  }

  useEffect(() => {
    global.setSelectedYearFilterOptions(['2016', '2017', '2018', '2019'])
  }, [])

  useEffect(() => {
    global.setUserLogged(true);
    const selectedHUC8Value = selectedHUC8;
    if (selectedHUC8Value && global.hucRestrict.length > 0 && (global.hucRestrict.indexOf(selectedHUC8Value) < 0)) {
      return global.notify(message_hucrestriction)
    }

    global.setLoading(true);

    const getData = async () => {
      const cdrConsumerCommercial: any = agent.Reports.CDRConsumerCommercial(selectedHUC8)
      console.log(selectedHUC8)
      const cdrIndustrial: any = agent.Reports.CDRIndustrial(selectedHUC8)
      const cdrManufacturer: any = agent.Reports.CDRManufactureImport(selectedHUC8)

      return await Promise.all([
        cdrConsumerCommercial,
        cdrIndustrial,
        cdrManufacturer
      ])
        .then((info: any) => info.map((ele: any, index: any) => {
          
          if (!ele.body) {
            global.notify(message_unavailable, 'info');
            return false;
          }

          if (!ele.body[0] && index === 0) {
            global.notify(message_nodatalocation, 'info')
          }

          if (index === 0 && ele.body[0]) {
            setConsumer(ele.body[0].data)
          }

          if (index === 1 && ele.body[0]) {
            setIndustrial(ele.body[0].data)
          }
          if (index === 2 && ele.body[0]) {
            setManufacturer(ele.body[0].data)
          }
          if (ele.body[0]) {
            setLat(parseFloat(ele.body[0].lat))
            setLng(parseFloat(ele.body[0].lng))
          }

        }))
        .then(() => {
          global.setViewport({
            longitude: lng,
            latitude: lat,
            zoom: 6,
          })
        })
        .finally(() => global.setLoading(false))
    }

    getData()
  }, [selectedHUC8])

  const flyToLocation = (xlng: any, xlat: any, zoom: number) => {
    mapRef.current?.flyTo({
      center: [
        parseFloat(xlng),
        parseFloat(xlat),
      ],
      essential: true,
      zoom,
    });
  }

  useEffect(() => {
    lat && lng && flyToLocation(lng, lat, 10);
  }, [lat, lng]);

  return (
    <FullScreenComp handle={fullscreenhandle}>
      <Row className="cdr-report-container">
        <Row className="cdr-map-container">
          <Col className="col-10">
            <Col className="col-12 d-flex">
              <Col className="col-12">
                <ReportHeader
                  global={global}
                  reportID={"ChemicalDataReporting-report"}
                  fullScreenClickHandle={fullscreenhandle.enter}
                />
              </Col>
            </Col>

            <Map
              style={{ display: 'block', height: '40vh' }}
              mapboxAccessToken={settings.maboxKey}
              initialViewState={global.viewport}
              projection={global.globeView ? 'globe' : 'mercator' as any}
              ref={mapRef}
              onClick={(e) => {
                global.onMapClick(e)
              }}
              onMove={(e) => {
                putViewportIntoStorage({
                  longitude: e.viewState.longitude,
                  latitude: e.viewState.latitude,
                  zoom: e.viewState.zoom,
                })
                global.setViewport({
                  longitude: e.viewState.longitude,
                  latitude: e.viewState.latitude,
                  zoom: e.viewState.zoom,
                })
              }
              }
             mapStyle={global.mapStyle}
            >
              <FullscreenControl />
              <NavigationControl />
              <GeolocateControl />
              <RenderLayerMarkers global={global} bounds={bounds} />
              <RenderLayers global={global} mapRef={mapRef} />
              {lat && lng && (
                <Marker longitude={lng} latitude={lat} anchor="bottom" >
                  <div style={{
                    border: '4px solid white',
                    borderRadius: '50%',
                    padding: '0.5rem',
                    backgroundColor: 'teal',
                    opacity: '0.9'
                  }}>
                  </div>
                </Marker>
              )}
              <div className='map-legend-container'>
                <MapLegend
                  global={global}
                  legendWidth={320}
                />
              </div>
            </Map>
            {global.NOAASeaLevelRise && (
              <div
              className="range-slider range-slider-noaa"
                style={{
                  width: '100%',
                  position: 'absolute',
                  right: '6%',
                  top: '17%'
                }}
              >
                <Form.Range
                  style={{ marginBottom: "0" }}
                  min={0}
                  max={10}
                  value={global.feetIndex}
                  onChange={e => global.setFeetIndex(parseInt(e.target.value))}
                />
                <strong>Sea Level: {global.feetIndex}ft</strong>
              </div>
            )}
            {global.terraClimateLayerSelected | global.evapotranspirationSelected && (
              <div
                className="range-slider"
                style={{
                  position: 'absolute',
                  top: '20.5%',
                  right: '10%'
                }}>
                <Form.Range
                  min={2000}
                  max={2019}
                  value={global.MTCLValue}
                  onChange={e => global.setMTCLValue(parseInt(e.target.value))}
                />
                <strong>Year: {global.MTCLValue}</strong>
              </div>
            )}
          </Col>
        </Row>
        <Row className="cdr-accordion-container">
          <Col className="col-10 mt-4">
            {global.dataSet === 'Commercial Chemical Data' && (
              <div className={`tables-container ${table1Expanded ? 'expanded' : ''}`}>
                <h3 style={{
                  color: 'white',
                  textAlign: 'center'
                }}>Commercial Chemical Data</h3>
                <div className="button-box">
                  {
                    table1Expanded ?
                      <button className="paginate-btn" onClick={() => setTable1Expanded(false)}>
                        <FontAwesomeIcon icon={faMinimize} />
                      </button>
                      :
                      <button className="paginate-btn" onClick={() => setTable1Expanded(true)}>
                        <FontAwesomeIcon icon={faMaximize} />
                      </button>
                  }
                </div>
                {consumer && <ReportTable columns={filterYear(tableColumns1)} data={consumer} />}
                {consumer && <ReportTable columns={filterYear(tableColumns2)} data={consumer} />}
                {consumer && <ReportTable columns={filterYear(tableColumns3)} data={consumer} />}
                {consumer && <ReportTable columns={filterYear(tableColumns4)} data={consumer} />}
                {consumer && <ReportTable columns={filterYear(tableColumns5)} data={consumer} />}
              </div>
            )}
            {global.dataSet === 'Industrial Chemical Data' && (
              <div className={`tables-container ${table2Expanded ? 'expanded' : ''}`}>
                <h3 style={{
                  color: 'white',
                  textAlign: 'center'
                }}>Industrial Chemical Data</h3>
                <div className="button-box">
                  {
                    table2Expanded ?
                      <button className="paginate-btn" onClick={() => setTable2Expanded(false)}>
                        <FontAwesomeIcon icon={faMinimize} />
                      </button>
                      :
                      <button className="paginate-btn" onClick={() => setTable2Expanded(true)}>
                        <FontAwesomeIcon icon={faMaximize} />
                      </button>
                  }
                </div>
                {industrial && <ReportTable columns={filterYear(tableColumns1)} data={industrial} />}
                {industrial && <ReportTable columns={filterYear(tableColumns2)} data={industrial} />}
                {industrial && <ReportTable columns={filterYear(tableColumns3)} data={industrial} />}
                {industrial && <ReportTable columns={filterYear(tableColumns4)} data={industrial} />}
                {industrial && <ReportTable columns={filterYear(tableColumns5)} data={industrial} />}
              </div>
            )}
            {global.dataSet === 'Manufacturer Chemical Data' && (
              <div className={`tables-container ${table3Expanded ? 'expanded' : ''}`}>
                <h3 style={{
                  color: 'white',
                  textAlign: 'center'
                }}>Manufacturer Chemical Data</h3>
                <div className="button-box">
                  {
                    table3Expanded ?
                      <button className="paginate-btn" onClick={() => setTable3Expanded(false)}>
                        <FontAwesomeIcon icon={faMinimize} />
                      </button>
                      :
                      <button className="paginate-btn" onClick={() => setTable3Expanded(true)}>
                        <FontAwesomeIcon icon={faMaximize} />
                      </button>
                  }
                </div>
                {manufacturer && <ReportTable columns={filterYear(tableColumns1)} data={manufacturer} />}
                {manufacturer && <ReportTable columns={filterYear(tableColumns2)} data={manufacturer} />}
                {manufacturer && <ReportTable columns={filterYear(tableColumns3)} data={manufacturer} />}
                {manufacturer && <ReportTable columns={filterYear(tableColumns4)} data={manufacturer} />}
                {manufacturer && <ReportTable columns={filterYear(tableColumns5)} data={manufacturer} />}
              </div>
            )}
            <br />
            <footer style={{
              textAlign: 'right',
              fontWeight: 'bold'
            }}>
              <span
                style={{
                  fontSize: '18px',
                  color: 'white',
                  marginRight: '4px'
                }}
              >
                Source:
              </span>
              <span>
                <a
                  style={{ color: '#4fc32c', textDecoration: 'none' }}
                  href="https://www.epa.gov/chemical-data-reporting"
                  target="_blank"
                >
                  EPA Chemical Data Reporting
                </a>
              </span>
            </footer>
          </Col>
        </Row>
      </Row>
      <MapSlider global={global} />
    </FullScreenComp>
  )
}

export default ChemicalDataReporting