import React from 'react'

const TaskManagement = () => {
  return (
        <div className="admin-container">
            <div className="header-box">
                <h2>Task Management</h2>
                <div className="button-box">

                </div>
            </div>

        </div>
  )
}

export default TaskManagement