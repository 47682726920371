// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; }

.report-heading {
  margin-top: 1rem;
  width: 80vw;
  display: flex;
  justify-content: flex-start; }
  .report-heading h3 {
    margin-left: 1.5rem;
    color: white; }

.map-container,
.graph-container {
  margin-top: 1rem;
  width: 80vw;
  height: 50vh;
  padding: 1.5rem;
  border-radius: 12px;
  background-color: #0b1736;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; }

.graph-container {
  height: 30vh;
  background-color: #0b1736; }
  .graph-container h1 {
    color: white; }
`, "",{"version":3,"sources":["webpack://./src/components/BaseReport/base-report.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAA;;AAGf;EACE,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,2BAA2B,EAAA;EAJ7B;IAMI,mBAAmB;IACnB,YAAY,EAAA;;AAIhB;;EAEE,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,4CAA4C,EAAA;;AAG9C;EACE,YAAY;EACZ,yBAAyB,EAAA;EAF3B;IAII,YAAY,EAAA","sourcesContent":[".report-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n\n.report-heading {\n  margin-top: 1rem;\n  width: 80vw;\n  display: flex;\n  justify-content: flex-start;\n  h3 {\n    margin-left: 1.5rem;\n    color: white;\n  }\n}\n\n.map-container,\n.graph-container {\n  margin-top: 1rem;\n  width: 80vw;\n  height: 50vh;\n  padding: 1.5rem;\n  border-radius: 12px;\n  background-color: #0b1736;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n}\n\n.graph-container {\n  height: 30vh;\n  background-color: #0b1736;\n  h1 {\n    color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
