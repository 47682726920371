import { useEffect, useContext, useState } from 'react';
import { RefContext } from '../../RefContext';
import { Layer, Source } from 'react-map-gl'
import { FillLayer } from 'mapbox-gl';

const CountiesLayer = (props: any) => {

    const {
        id,
        opacity,
        lineColor,
        tileSet,
        sourceLayer
    } = props

    const [features, setFeatures] = useState()

    const CountiesLayer = {
        id: `${id}-layer`,
        type: "line",
        "source-layer": `${sourceLayer}`,
        paint: {
            "line-opacity": opacity,
            "line-color": `${lineColor}`,
            'line-width': 2
        },
    };
    const { currentRef } = useContext(RefContext)

    // @ts-ignore
    const mapInstance = currentRef && currentRef?.current

    useEffect(() => {
        mapInstance && mapInstance.on('click', (event: any) => {
            const layerObject = mapInstance.queryRenderedFeatures(event.point)?.[0];
            setFeatures(layerObject)
        })
    }, [mapInstance])

    return (
        <>
            <Source
                id={`${id}-layer`}
                type="vector"
                url={tileSet}
            >
                {/* @ts-ignore */}
                <Layer
                    {...CountiesLayer}
                />
            </Source>
        </>
    )
}

export default CountiesLayer;  
