import React, { useState, useEffect } from 'react';
import { Form, Button, FloatingLabel } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import settings from '../../settings.json';
import { createUser } from './createAndLoginUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faLongArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import swish from '../../assets/swish.svg';
import logoImg from '../../assets/true-elements-logo.svg';
import loginIcon from '../../assets/loginIcon.svg';


interface UserInfo {
    email?: string;
    source?: string;
}

export default function Register(props: any) {
    const [userName, setUserName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [formEmail, setFormEmail] = useState('');
    const [formPass, setFormPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const procurementId = searchParams.get('procurement_account_id'); 
    const { notify } = props;
  
    useEffect(() => {
        const reportsContainer = document.getElementById("reportsContainer");
        const toastContainer = document.querySelector(".Toastify");
        if (reportsContainer) {
            reportsContainer.style.maxWidth = "unset"
        }//@ts-ignore
        if(toastContainer){ toastContainer.style.display = 'none';}
        return () => {//@ts-ignore
            if(toastContainer){ toastContainer.style.display = 'block' }
            if (reportsContainer) {
                reportsContainer.style.maxWidth = "calc(100% - 80px)"
            }
        }
    }, [])
      
    useEffect(() => {
        if (formEmail) {
          setUserName(formEmail)
        }
    }, [formEmail])


    const handleSubmit = () => {
        if (formPass === confirmPass) {
            const data: any = {
                USER_NAME: formEmail,
                EMAIL: formEmail,
                CREDENTIAL: formPass, //'testpassword'
                FIRST_NAME: firstName,
                LAST_NAME: lastName,
            };
            console.log(data)
            createUser(data, notify,location);
            navigate('/Login');
        } 
    };

    const handleReturn = () => {
            navigate(-1);
            //navigate(`/Login?procurement_ccount_id=${procurementId}`);
    };

/*                <FloatingLabel controlId="floatingActivityIdentifier" label="Email" style={{marginTop:20}}>                
                    <Form.Control
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormEmailReset(e.target.value)}
                        className='login-input'
                        size='lg'
                        type='email'
                        placeholder='Email'
                        required
                    />
                </FloatingLabel>
*/

    return (
        <div className='login'>
            <div className='form form-login'>
                <div className='loginOptions'>
                    <img className='swish' src={swish} alt='Swish' />
                    <div className='login-right'>
                        <img className='logo' src={logoImg} alt='Logo' />
                        <div className='input-wrapper rand-wrapper'>

                            <div className='rand-box'>
                               <FloatingLabel controlId="floatingFirst Name" label="First Name" >                
                                    <Form.Control
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                                        className='login-input'
                                        size='lg'
                                        type='text'
                                        
                                        required
                                    />
                                </FloatingLabel>
                                <FloatingLabel controlId="floatingLastName" label="Last Name" > 
                                    <Form.Control
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                                        className='login-input'
                                        size='lg'
                                        type='text'
                                        
                                        required
                                    />
                                </FloatingLabel>
                            </div>
                            <FloatingLabel controlId="floatingEmail" label="Email" > 
                                <Form.Control
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormEmail(e.target.value)}
                                    className='login-input'
                                    size='lg'
                                    type='email'
                                  
                                    required
                                />
                            </FloatingLabel>
                            <FloatingLabel controlId="floatingPassword" label="Password" > 
                                <Form.Control
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormPass(e.target.value)}
                                    className='login-input'
                                    size='lg'
                                    type='password'
                                    placeholder='Password'
                                    value={formPass}
                                    required
                                />
                            </FloatingLabel>
                            <Form.Control
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPass(e.target.value)}
                                className='login-input'
                                size='lg'
                                type='password'
                                placeholder='Confirm Password'
                                value={confirmPass}
                                required
                            />
                            {confirmPass && confirmPass !== formPass && <p className='no-match'>Passwords don't match</p>}
                            {confirmPass && confirmPass === formPass && <p className='match'>Passwords match</p>}
                        </div>

                        <div className='login-btn-box'>
                            <Button onClick={handleSubmit} size='lg' className='login-button' type='submit'>
                                Register Account
                            </Button>
                        </div>
                        <Button
                            style={{backgroundColor: 'unset', marginTop: 40}}
                            onClick={handleReturn}
                            className='btn-options'>
                            <FontAwesomeIcon icon={faLongArrowLeft} className="mr-2 fa-2x" />
                          </Button>
                    </div>
                    <div className='login-left'>
                        <h3>TrueQI Digital Watershed Explorer</h3>
                        <h3>TrueQI Scenario Forecaster</h3>
                        <h3>TrueQI Site Assessment Reporter</h3>
                        <h3>TrueQI Field Data Monitor</h3>
                    </div>
                    <img className='login-icon' src={loginIcon} alt='Login Icon' />

                </div>
            </div>
        </div>
    );
}
