import { useState, useMemo } from 'react';
import { Marker } from 'react-map-gl';
import useSupercluster from 'use-supercluster';
import RenderPopupDW from '../RenderPopupDW';
import './water-quality-list.scss'

const WaterQualityList = (props: any) => {
    const { list, bounds, viewport, global } = props
    const [current, setCurrent] = useState<any | null>(null)

    const filtered_list = list.filter(function(f:any) {
        return (!isNaN(f["latitude"]));
    })

    const points = useMemo(() => filtered_list && filtered_list.map((point: any, index: number) => ({
        type: "Feature",
        properties: {
            cluster: false,
            index,
            score: point.score,
            id: `cluster-${index}`,
        },
        geometry: {
            type: "Point",
            coordinates: [point.longitude, point.latitude],
        }
    })),[list])

    const renderAverage = (input: any) => {
        const sum: any = input.reduce((accumulator: any, point: any) => {
            return parseInt(accumulator) + parseInt(point.properties.score)
        }, 0)
        return Math.round(sum / input.length)
    }

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom: viewport.zoom,
        options: { radius: 100, maxZoom: 7 }
    })

    const renderList = useMemo(() => {
        return clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates
            const { cluster: isCluster, point_count: pointCount, index, score } = cluster.properties;

            if (isCluster) {

                const pointData = supercluster?.getLeaves(parseInt(cluster.id!.toString()))

                return (
                    <Marker
                        key={cluster.id}
                        latitude={latitude}
                        longitude={longitude}
                    >
                        <div
                            className="cluster-list-marker"
                        >
                            <img
                                width="36px"
                                style={{ cursor: "pointer" }}
                                src={`https://te-v3-assets.s3.us-west-2.amazonaws.com/${renderAverage(pointData)}.svg`}
                                alt="score_icon"
                            />
                        </div>
                    </Marker>
                )
            }

            return (
                <Marker
                    key={cluster.properties.id}
                    latitude={latitude}
                    longitude={longitude}
                    style={{
                        color: 'black',
                        background: 'rgba(255, 255, 255, 0)',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: `3px solid #0d6efd`
                    }}
                    onClick={(e) => {
                        e.originalEvent.stopPropagation();
                        setCurrent(filtered_list[index])
                    }}
                >
                    <img
                        width="28px"
                        style={{ cursor: "pointer" }}
                        src={`https://te-v3-assets.s3.us-west-2.amazonaws.com/${score}.svg`}
                        alt="score_icon"
                    />
                </Marker>
            );
        })
    },[clusters])

    return (
        <>
            {clusters && renderList}
            {current && <RenderPopupDW
                reportUrl={['/DrinkingWaterTrueQIReport', '/WhatsInMyDrinkingWater', '/ViolationData']}
                current={current}
                setCurrent={setCurrent}
                global={global}
            />}
        </>
    )
}

export default WaterQualityList