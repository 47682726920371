import { useEffect, useState, useRef, useCallback, useContext, useMemo, Ref } from 'react';
import { Button, OverlayTrigger, Popover, Tab, Tabs, Dropdown, Row, Col, Card, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { Tooltip as TooltipRB, Form, Modal, Carousel, ListGroup, Image } from 'react-bootstrap';

import agent from '../../../api/agent';
import LoadingDataAnimation from '../../../components/LoadingDataAnimation';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import fullscreenIcon from '../../../assets/fullscreen-icon.svg';

import * as d3Format from 'd3-format';
import { DateTime } from 'luxon';
import localForage from "localforage";

import html2canvas from 'html2canvas';
import { jsPDF, ImageOptions } from "jspdf";
import { unparse } from 'papaparse';

import { formatRowValue } from '../../utils';
import { AppContext } from '../../../AppContext';
import { CatalogsContext } from '../../../CatalogsProvider';

import ReportsConfigs from '../../../ReportsConfigs';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import ReportTableExxpanded from '../shared/ReportTableExpanded';
import { HeaderProps } from 'react-table';
import { RefContext } from '../../../RefContext';

import { setLocalReport } from '../../utils';
import { Location, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown, faFileExport, faFloppyDisk, faGear, faCancel, faRectangleXmark, faRotate, faTrashCan, faList, faTableCellsLarge } from '@fortawesome/pro-solid-svg-icons';

import { parseQueryFilters, exportToCsv, exportReportLink } from '../shared/report-data';

//import { Wizard, useWizard } from 'react-use-wizard';

import StepZilla from "react-stepzilla";

import reportsSWFRIcon from '../../../assets/reports_SWFR_f.png';

import reportsEPAIcon from '../../../assets/reports_EPA_f.png';
import reportsGWLIcon from '../../../assets/reports_GWL_f.png'; 

import './styles.scss';


export default function WorkStart(props: any) {
  const { global } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);
  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;
  const navigate = useNavigate();
  const selectedHUC8Value = appContext.selectedHUC8;
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const [isFullScreenActive, setFullScreenActive] = useState(false);
  const fullscreenContainerHandler = () => { setFullScreenActive(!isFullScreenActive); };
  const [isDataReportLoaded, setIsDataReportLoaded] = useState(false);

  const [tableData, setTableData] = useState<any[]>([]);
  const [savedData, setSavedData] = useState<any[]>([]);
  const [currentDashboard, setCurrentDashboard] = useState<string>('');
  const [steps, setSteps] = useState<any[]>([]);
  const [currentStep, setCurrentStep] = useState<any>(0);
  const [imageSource, setImageSource] = useState<any>(); //reportsSWFRIcon

  const [Hide, setHide] = useState<any>(false);

  const Step = () => {return (<></>);};
  const Start = () => {return (<></>);};
  const Complete = () => {return (<></>);};
 
  const nowTime = DateTime.local().toFormat('_ydL_Hms_');
  const nowCity = appContext.currentLocation;

  global.setUserLogged(true);

  useEffect(() => {
    //setImageSource(reportsSWFRIcon);
  }, []);

  useEffect(() => {
    const tData = global.selectedDashboardProjectLinks;
    if(global.selectedDashboardProjectLinks && tData.length>0){
      setSavedData(tData);
      generateSteps(tData); 
    }
  }, [global.selectedDashboardProjectLinks]);

  const generateSteps = (tData:any) => {
    const newSteps = [];
    if(tData){
      for (var i = 0; i < tData.length; i++) {
        newSteps.push({ name: getReportLabel(tData[i].reportname), url: tData[i].url, timestamp: tData[i].timestamp, search: tData[i].search, pathname: tData[i].pathname})
      }
      //newSteps.push({ name: 'Complete and Print', url: '/WorkStart', component: <Complete /> });
      setSteps(newSteps);
    }
  };  

  const getReportLabel = (reportname:string) => {
    const reportLabel = ReportsConfigs.find(x => x.route.substr(1) == reportname)?.name    
    if(reportLabel){
      return reportLabel;
    } else {
      return reportname;
    }
  }

  const exportToPdf = () => {
    const reportName = 'Workflow';//ReportsConfigs.find(x => x.route == location.pathname)?.name || 'Report_download';
    const reportElement = document.getElementById('dashboardimage') as HTMLImageElement;
    if(reportElement){
      const aspectRatio = reportElement.offsetHeight/reportElement.offsetWidth; 
      const image = reportElement.src;
      var doc = new jsPDF()
            for (var i = 0; i < steps.length; i++) {
                const timest = steps[i].timestamp.toString();
                 localForage.getItem(timest).then(function (blob) {
                    if(blob){
                        const objectURL = URL.createObjectURL(blob as Blob);
                      if(i!==0){
                        doc.addPage('a4', 'l');
                      }
                      doc.addImage(objectURL, 'PNG', 0, 0, 210, (210*(aspectRatio)));
                    }      
                }); 
            }
            setTimeout(() => {
                doc.deletePage(1);
                doc.save(reportName + nowTime + nowCity); 
            }, 1400);   
    }
  }

  const exportToCsvBatch = () => {
    for (var i = 0; i < steps.length; i++) {
        exportReportLink(steps[i].timestamp, savedData);
    }
  }

  const onPrint = (event:any) => {
    exportToPdf();
  };

  const popoverLink = (event:any) => {   
    if (event) {
      let sData = steps.filter((o: any) => +o.timestamp === +event);
      if(sData && sData.length > 0 && sData[0].search){
        let filterArray = parseQueryFilters(sData[0].search, sData[0].pathname)
        return (
          <Popover id="dashboard-filter-popover" className='dashboard-popover'>
          <Popover.Header as="h3">Filters applied:</Popover.Header>
          <Popover.Body>
          {filterArray.map((link:any, index:number) => 
            <p key={'popover_' + index}>{link.filterLabel + ': ' + link.filterValue}</p>
          )}
          </Popover.Body>
        </Popover>
        );
      } else {
        return (
          <Popover id="dashboard-filter-popover" className='dashboard-popover'>
            <Popover.Header as="h3">Filters applied: none</Popover.Header>
          </Popover>
        );       
      }
    } else {
      return (
        <Popover id="dashboard-filter-popover" className='dashboard-popover'>
          <Popover.Header as="h3">Filters applied: none</Popover.Header>
        </Popover>
      );
    }    
  };


  const onStepChange = (event:any) => {
    const selectedItem = event.target.getAttribute('data-rr-ui-event-key');
    if(selectedItem){
        setCurrentStep(selectedItem);
            localForage.getItem(selectedItem.toString()).then(function (blob) {
              if(blob){
                const dashImage = document.getElementById('dashboardimage') as HTMLImageElement;
                const objectURL = URL.createObjectURL(blob as Blob);
                setImageSource(objectURL);
                if(dashImage){
                  dashImage.src = objectURL;
                }
              }

            });

        const dataSelection = [...savedData].filter((o: any) => +o.timestamp === +currentDashboard);
        if (dataSelection.length > 0){
            //navigate(dataSelection[0].url)
        }
    }
  };

  return (
    <>
     <div className='Workflow' id='WorkStart-report'>
        <div className='Workflow-start-container'>

            <Row  className='Workflow-list-row'>
                <Col  xs={5}>
                  <Tab.Container id="list-group-tabs" defaultActiveKey="#link1">
                    <Row>
                      <Col>
                        <div  className='Workflow-start-buttons'>
                          <Button
                            type='button'
                            className='btn btn-default btn-circle dark-mode active'  
                            onClick={onPrint}                          
                            >
                            <span style={{ pointerEvents: 'none' }} >Print All</span>
                          </Button>
                          <Button
                            type='button'
                            className='btn btn-default btn-circle dark-mode active'  
                            onClick={exportToCsvBatch}                          
                            >
                            <span style={{ pointerEvents: 'none' }} >Export All</span>
                          </Button>
                        </div>
                        <ListGroup numbered defaultActiveKey={(steps && steps[0] && steps[0].timestamp) ? steps[0].timestamp : 0}>
                        {steps.map((dash:any, index:any) =>  
                          <OverlayTrigger key={'dashboard-grid-pop_'+dash.timestamp + index} 
                            trigger={['hover', 'focus']} placement="bottom" overlay={popoverLink(dash.timestamp)}>      
                              <ListGroup.Item eventKey={dash.timestamp} id={'dash.timestamp' + dash.timestamp} key={'ListGroupItem ' + dash.name+index} 
                                
                                action onClick={onStepChange} >
                                  {dash.name}
                              </ListGroup.Item>
                          </OverlayTrigger>
                        )}
                        </ListGroup> 
                      </Col>
                    </Row>
                  </Tab.Container>
                </Col>            
                <Col>

                  <div className='Workflow-start-image-container'>
                    <Image
                        id='dashboardimage'
                        src={imageSource}
                        rounded
                        fluid
                    />
                  </div>
           
                </Col>
            </Row>
        </div>
      </div>
    </>
  );
}
