import { useEffect, useState, useRef, useCallback, useContext, useMemo, Ref } from "react";
import * as React from 'react';
import {Map, MapboxStyle, useMap, MapRef, MapLayerMouseEvent, Popup,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  useControl,
  Layer,
  Source,
  FillLayer,
} from "react-map-gl";
import settings from "../../../settings.json";
import { Form, Button, Row, Col, Table, FloatingLabel, FormCheck } from "react-bootstrap";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import agent from "../../../api/agent";
import { formatRowValue } from "../../utils";
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import { RefContext } from '../../../RefContext'
import { HUC8GeoPoint} from "../../../types/HUC8GeoPoint";
import { HUC12GeoPoint} from "../../../types/HUC12GeoPoint";
import { HUC12InfoPoint} from "../../../types/HUC12InfoPoint";

import LoadingDataAnimation from "../../../components/LoadingDataAnimation";
import { Feature, GeoJsonProperties, Point, Polygon, GeometryCollection, FeatureCollection, Geometry } from 'geojson';
import type {LayerProps} from 'react-map-gl';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import interpolatePuOr from '../../../assets/interpolatePuOr.png'
import moment from 'moment';
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import * as d3Scale from 'd3-scale';
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import bbox from '@turf/bbox';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import {DateTime} from 'luxon';

import { AppContext } from '../../../AppContext';
import { CatalogsContext } from '../../../CatalogsProvider';
import { Location, useLocation } from 'react-router-dom';
import MapAddition from  '../shared/MapAddition';
import MapLegend from '../shared/MapLegend';
import MapSlider from '../shared/MapSlider';
import FilterState from '../shared/FilterState';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import ReportMap from '../shared/ReportMap';
import ReportTableExpanded from '../shared/ReportTableExpanded';
import { useTable, useExpanded, 
  UseExpandedHooks,
  UseExpandedInstanceProps,
  UseExpandedOptions,
  UseExpandedRowProps,  HeaderGroup,
  HeaderProps,
  UseExpandedState, Cell} from 'react-table'

import { flyToHUC12sLocation, flyToInitialLocationHandler, getCurrentHuc12LongLat, getHuc12MembersOfHUC8, getCurrentHuc8LongLat } from '../../utils';
import Render from "../../../components/render";
import { createFeatureCollection, groupByKey, processStatusMessage, uniqueValues, updateChartData, fitBoundsMapData, findFittingDateRange} from '../shared/report-data';

import './WaterMonitoringReport.scss'
//--IMAGES
import companyIcon from "../../../assets/company-icon.svg";
import arrowDownIcon from "../../../assets/arrow-down-icon.svg";
import arrowUpIcon from "../../../assets/arrow-up-icon.svg";
import arrowDownDoubleIcon from "../../../assets/arrow-down-double-icon.svg";
import arrowDownDoubleTreeIcon from "../../../assets/arrow-down-double-tree-icon.svg";
import fullscreenIcon from '../../../assets/fullscreen-icon.svg';
import { parse, unparse } from "papaparse";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { features } from "process";
import { setChartConfig, setChartConfigLocations  }from './WaterMonitoringReportChartConfig';
import { Line, Bar, getElementAtEvent, getElementsAtEvent } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataset, ChartType, TooltipItem
} from "chart.js";
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);     

export default function WaterMonitoringReport(props: any) {
  const { global, currentOrg } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);
  const selectedHUC8Value = appContext.selectedHUC8;
  const selectedCategory = (global.filter1Selected || 'All');  
  const selectedFilterLocations = global.filterLocationsSelected;
  let selectedDateRange = global.dateRangeSelected;

  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;

  const divergingColors = ['#d73027','#74add1','#f46d43','#fdae61','#fee090','#ffffbf','#e0f3f8','#abd9e9','#74add1','#4575b4'];

  const {current: map} = useMap();
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [cursor, setCursor] = useState<string>('auto');
  const mapRef = useRef<MapRef>();
  const [mapLoaded, setMapLoaded] = useState(false);
  const locationField = 'MonitoringLocIdent_MonitoringLocationIdentifier';
  const [mapHoverFilter, setMapHoverFilter] =  useState<any>(['in', ['get', locationField],  '']);

  const [mapStops, setMapStops] =   useState<any>(['None' ,divergingColors[0],'above',divergingColors[1],'below',divergingColors[1], '#ccc']);
  //'WQXTEST' ,divergingColors[0], 'WIDNR_WQX',divergingColors[1], '#ccc'


  const [mapLegendData, setMapLegendData] =  useState<any[]>([]);
  const [mapLegendTitle, setMapLegendTitle] = useState<string>('Locations');
  const [circleColorStyle, setCircleColorStyle] =  useState<any>([]);
  const [mapStyleData, setMapStyleData] =  useState<any>([]);
  const [huc8LonLatCatalogArray, setHuc8LonLatCatalogDataArray] = useState<any[]>([]);
  const [huc12InfoCatalogArray, setHuc12InfoCatalogDataArray] = useState<any[]>([]);

  const [userSelectedLocation, setUserSelectedLocation] = useState<any>(null);
  const [userSelectedLocations, setUserSelectedLocations] = useState<any[]>([]);
  const [usLocations, setUsLocations] = useState<any>([]);
  
  const [filterLocations, setFilterLocations] = useState<any>([]);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [features, setFeatures] = useState({});
  
  const [statusMessage, setStatusMessage] = useState<any>(null);
  const [layerStyle, setLayerStyle] = useState<any>([]);
  const [hoverLayerStyle, setHoverLayerStyle] = useState<any>([]);
  const [layerStyleCustom, setLayerStyleCustom] = useState<any>([]);
  const [currentDate, setCurrentDate] = useState<any>(null);
  const [filterLabelDays, setFilterLabelDays] = useState<any>([]);
  const [showCustom, setShowCustom] = useState((global.waterQualityPortalLocations && global.waterQualityPortalLocations.features && global.waterQualityPortalLocations.features.length > 0) ? true : false);

  const chartRef = useRef<any>();
  const { setCurrentRef } = useContext(RefContext);
  
  const [tableData, setTableData] = useState<any[]>(props.data);
  const [locations, setLocations] = useState<any[]>(props.data);
  const [results, setResults] = useState<any[]>(props.results);
  const [featureData, setFeatureData] = useState<FeatureCollection>();
  const [reportData, setReportData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [samplingData, setSamplingData] = useState<any[]>([]);
  const [mData, setMData] = useState<FeatureCollection>();
  const [customData, setCustomData] = useState<FeatureCollection>();
  const [customRData, setCustomRData]= useState<any[]>([]);
  const [customTableData, setCustomTableData]= useState<any[]>([]);

  const [data, setData] = useState({
    labels: [] as any,
    datasets: [] as any,
  } as const);
  const [chartData, setChartData] = useState<any>({
    labels: [],
    datasets: [],
    borderColor: '#ff6384',
  });

  const [config, setConfig] = useState({} as const);
  const [configLocations, setConfigLocations] = useState({} as const);

  const [isDataReportLoaded, setIsDataReportLoaded] = useState(true);
  const [isTableSort, setTableSort] = useState(false);
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const [isFullScreenActive, setFullScreenActive] = useState(false);
  const [isFullScreenChartActive, setFullScreenChartActive] = useState(false);
  const fullscreenContainerHandler = () => { setFullScreenActive(!isFullScreenActive);};
  const fullscreenChartContainerHandler = () => { setFullScreenChartActive(!isFullScreenChartActive);};

  const [metrics, setMetrics] = useState<any[]>([]);
  const [category, setCategory] = useState<string>('Total Phosphorous (mg/L)');
  const [customCategory, setCustomCategory] = useState<string>('result');
  const [tColumns, setTColumns] = useState<any[]>([]);
  const [tableDColumns, setTableDColumns] = useState < any[] > ([]);
  let mlData = [{typecode: 'Above', color: 'red'}, {typecode: 'Below', color: 'green'}, {typecode: 'Ungraded', color: divergingColors[1]} ];
  const [tableDisplay, setTableDisplay] = useState<any>('locations');
  const [counter, setCounter] =  useState<any>(0);
  //showCustom


  const resultFields = [
    "OrganizationIdentifier",
    "ActivityIdentifier",
    "ActivityTypeCode",
    "ActivityMediaName",
    "ActivityMediaSubdivisionName",
    "ActivityStartDate",
    "ActivityEndDate",
    "ActivityConductingOrganizationText",
    "CharacteristicName",
    "MonitoringLocationIdentifier",
    "HydrologicCondition",
    "HydrologicEvent",
    "SampleCollectionMethod/MethodIdentifier",
    "ResultSampleFractionText",
    "ResultMeasureValue",
    "ResultMeasure/MeasureUnitCode",
    "ResultValueTypeName",
    "ProviderName"
];

const resultTableColumns = [
    {
        "Header": "OrganizationIdentifier",
        "accessor": "OrganizationIdentifier"
    },
    {
        "Header": "ActivityIdentifier",
        "accessor": "ActivityIdentifier"
    },
    {
        "Header": "ActivityTypeCode",
        "accessor": "ActivityTypeCode"
    },
    {
        "Header": "ActivityMediaName",
        "accessor": "ActivityMediaName"
    },
    {
        "Header": "ActivityMediaSubdivisionName",
        "accessor": "ActivityMediaSubdivisionName"
    },
    {
        "Header": "ActivityStartDate",
        "accessor": "ActivityStartDate"
    },
    {
        "Header": "ActivityEndDate",
        "accessor": "ActivityEndDate"
    },
    {
        "Header": "ActivityConductingOrganizationText",
        "accessor": "ActivityConductingOrganizationText"
    },
    {
        "Header": "MonitoringLocationIdentifier",
        "accessor": "MonitoringLocationIdentifier"
    },
    {
        "Header": "HydrologicCondition",
        "accessor": "HydrologicCondition"
    },
    {
        "Header": "HydrologicEvent",
        "accessor": "HydrologicEvent"
    },
    {
        "Header": "SampleCollectionMethod/MethodIdentifier",
        "accessor": "SampleCollectionMethod/MethodIdentifier"
    },
    {
        "Header": "CharacteristicName",
        "accessor": "CharacteristicName"
    },
    {
        "Header": "ResultSampleFractionText",
        "accessor": "ResultSampleFractionText"
    },
    {
        "Header": "ResultMeasureValue",
        "accessor": "ResultMeasureValue"
    },
    {
        "Header": "ResultMeasure/MeasureUnitCode",
        "accessor": "ResultMeasure/MeasureUnitCode"
    },
    {
        "Header": "ResultValueTypeName",
        "accessor": "ResultValueTypeName"
    },
    {
        "Header": "ProviderName",
        "accessor": "ProviderName"
    }
];



  const updateData = () => { 
    global.setfilter1Selected('All');
    global.setfilterLocationsSelected([]);
    global.setDateRangeSelected([]);

    if(tableData && tableData.length > 0){
      for (let dataItem of tableData) {
        dataItem['Site Type Code'] = 'none';

        //WQX status
        if (dataItem["BiologicalHabitatIndex_IndexCommentText"] && dataItem["BiologicalHabitatIndex_IndexCommentText"].substr(0,1)==='{'){
            const status = JSON.parse(dataItem["BiologicalHabitatIndex_IndexCommentText"])
            dataItem["cdxStatus"] =  status.status; 
            //dataItem["submissionType"] = 'R'; //+++
            dataItem["transactionId"] =  status.transactionId;
            dataItem["statusDetail"] =  status.statusDetail;
            dataItem["submissionDate"] = status.submissionDate && DateTime.fromMillis(status.submissionDate).toLocaleString();
            dataItem["createdDate"] = status.submissionDate && DateTime.fromMillis(status.submissionDate).toLocaleString();
        } else {
            //dataItem["cdxStatus"] =  ;//!dataItem["cdxStatus"]  'Processing'; 
            //dataItem["submissionType"] = ;//'R';
            //dataItem["createdDate"] = status.submissionDate && DateTime.fromMillis(status.submissionDate).toLocaleString();
        }

        dataItem.org = dataItem["ORGDescription_OrganizationIdentifier"];
        dataItem.lng = +dataItem["MonitorLocGeo_LongitudeMeasure"];
        dataItem.lat = +dataItem["MonitorLocGeo_LatitudeMeasure"];
        dataItem.centroid_longitude = +dataItem.lng; //+++
        dataItem.centroid_latitude = +dataItem.lat; //+++ 
        dataItem.name = dataItem["MonitoringLocIdent_MonitoringLocationIdentifier"];  
        dataItem.date = dataItem["createdDate"];
        dataItem.year = '2023'; //+++  //moment(dataItem["Milliseconds Since Unix Epoch"]).format('YYYY');  
      };   

      const filterLabels = uniqueValues(filteredData, 'ORGDescription_OrganizationIdentifier');
      const legendData = filterLabels.map((x:any, index:number)=> ( {typecode: x, color: divergingColors[index]} ));
      setMapLegendData(mlData);
    }
  }  

  useEffect(() => {
    setCurrentRef?.(mapRef)
  }, [mapRef])

  useEffect(() => {
      console.log('showCustom', showCustom, global.waterQualityPortalLocations)
      displayMode();


      //updateLayerStyleCustom();
      //updateData();
      loadSamplingData();
      setMetrics(['Chloride (mg/L)', 'Fecal Coliform (CFU/100 mL)', 'Dissolved Oxygen (mg/L)', 'DO Warm', 'DO Cold', 'E. coli (MPN/100mL)', 'Macroinvertebrates', 'pH', 'Total Phosphorous (mg/L)', 'Phos Stnd', 'P TvF', 'Specific Conductance (µS/cm)', 'Transparency', 'Turbidity (FNU)', 'Water Temperature (°C)', 'Temp Class', 'Water Stnd', 'DO Stnd', 'WARMwater', 'COLDwater',  'Milwauke Riverkeeper Grading'].sort((x: any, y: any) => x.localeCompare(y)));
  }, []); 

  useEffect(() => {
    updateLayerStyle();
    updateHoverLayerStyle();
    //updateLayerStyleCustom();
  }, [isDataReportLoaded]);

  useEffect(() => {
    setFilteredData(tableData)
  }, [tableData]);

  useEffect(() => {
    if(global.waterQualityPortalLocations){
      //setMData(global.waterQualityPortalLocations)
  
      displayMode();
    }
  }, [global.waterQualityPortalLocations]); 

  useEffect(() => {
    if(global.waterQualityPortalData!=='undefined' && showCustom){
 
      setCustomRData(global.waterQualityPortalData)

      const newChartData = updateChartDataResults(global.waterQualityPortalData, 'ActivityStartDate', 'ResultMeasureValue', '');
  
      setChartData(newChartData)
    }
  }, [global.waterQualityPortalData]); 

  useEffect(() => {
    //if(global.waterQualityPortalData){

      //setCustomRData(global.waterQualityPortalData)
      setCategory(global.waterQualityPortalDataMetric)
    //}
  }, [global.waterQualityPortalDataMetric]); 

  useEffect(() => {
    //console.log('customRData')

    if(customRData && customRData.length>0){
       setTableData(customRData);
       let aArr = [] as any;
       const keys = Object.keys(customRData[0])
                       .map((key) => aArr.push({ 'Header': key, 'accessor': key}))
       let k1 = [] as any;
       //

       for (let dataItem of aArr) {
         if(resultFields.indexOf(dataItem['accessor']) > -1){
           k1.push(dataItem);
         }
       }

       //console.log(k1)

       setTableDColumns(k1)
    }
  }, [customRData]); 

  useEffect(() => {
    //props.data
    //customData

    // tableData
    // tableColumns
    // setMapLegendData
    // Chart
    displayMode();
    //console.log(category, tableDisplay, showCustom)
  }, [tableDisplay, showCustom]); 

  const displayMode = () => {
    //console.log(category, tableDisplay, showCustom)
    let mode = (showCustom) ? 'wqp' : 'local'; 
    if(tableDisplay==='results'){
      resultsMode(mode);
    } else {
      locationsMode(mode);
    }
  }

  const locationsMode = (dataset: string) => {
    if(dataset==='local'){
      //console.log('locations', locations, props.data)
      setTableData(props.data);
      setTableDColumns([
            { Header: 'Identifier', accessor: 'MonitoringLocIdent_MonitoringLocationIdentifier' },
            { Header: 'Name', accessor: 'MonitoringLocIdent_MonitoringLocationName' },
            { Header: 'Type', accessor: 'MonitoringLocIdent_MonitoringLocationTypeName' },
            { Header: 'HUC', accessor: 'MonitoringLocIdent_HUCEightDigitCode' },
            { Header: 'Organization', accessor: 'ORGDescription_OrganizationIdentifier' },
            { Header: 'Formal name', accessor: 'ORGDescription_OrganizationFormalName' },
            { Header: 'Activities', accessor: 'activityCount' },
            { Header: 'Results', accessor: 'resultCount' },
            { Header: 'Description', accessor: 'MonitoringLocIdent_MonitoringLocationDescriptionText' },
      ])
      setCategory('');
      let newChartData = updateChartDataLocations(props.data, 'MonitoringLocIdent_MonitoringLocationIdentifier', 'resultCount');
      setChartData(newChartData);
  
      setConfig(setChartConfigLocations(' ')); 
      //console.log('newChartData', config, counter)


    } else {
      
      let newDataSet = [];
      if(global.waterQualityPortalLocations && global.waterQualityPortalLocations.features){
          newDataSet = featuresToDataset(global.waterQualityPortalLocations.features)
      } 
    
      setTableDColumns([
            { Header: 'Identifier', accessor: 'MonitoringLocationIdentifier' },           
            { Header: 'Type', accessor: 'MonitoringLocationTypeName' },
             { Header: 'Name', accessor: 'MonitoringLocationName' },
            {
              Header: 'Site link',
              accessor: 'siteUrl',
              Cell: (props: any) => (
                <a
                  href={props.value}
                  target='_blank'
                >
                  Site link
                </a>
              ),
            },
            { Header: 'Organization', accessor: 'OrganizationIdentifier' },
            { Header: 'Formal name', accessor: 'OrganizationFormalName' },
            { Header: 'Activities', accessor: 'activityCount' },
            { Header: 'Results', accessor: 'resultCount' },
            { Header: 'HUC', accessor: 'HUCEightDigitCode' },
            { Header: 'County', accessor: 'CountyName' },
            { Header: 'Provider', accessor: 'ProviderName' },
             { Header: 'Description', accessor: 'MonitoringLocationDescriptionText' },
      ])

      setTableData(newDataSet);
      setCategory(global.waterQualityPortalDataMetric);
      let newChartData = updateChartDataLocations(newDataSet, 'MonitoringLocationIdentifier', 'resultCount');
      setChartData(newChartData);
      setConfig(setChartConfigLocations(category)); 
    }

    setCounter(counter+1);
    setMapLegendTitle('Locations');
  }

  const resultsMode = (dataset: string) => {
    if(dataset==='local'){
      setTableData(results);
      setTableDColumns([
            { Header: 'Identifier', accessor: 'MonitoringLocIdent_MonitoringLocationIdentifier' },
            { Header: 'Name', accessor: 'MonitoringLocIdent_MonitoringLocationName' },
            { Header: 'Type', accessor: 'MonitoringLocIdent_MonitoringLocationTypeName' },
            { Header: 'HUC', accessor: 'MonitoringLocIdent_HUCEightDigitCode' },
            { Header: 'Organization', accessor: 'ORGDescription_OrganizationIdentifier' },
            { Header: 'Formal name', accessor: 'ORGDescription_OrganizationFormalName' },
            { Header: 'Activities', accessor: 'activityCount' },
            { Header: 'Results', accessor: 'resultCount' },
            { Header: 'Description', accessor: 'MonitoringLocIdent_MonitoringLocationDescriptionText' },
      ])

      setCategory('')
      const newChartData = updateChartDataResults(results, 'ACTIVITYDesc_ActivityStartDate', 'ResultDescription_ResultMeasureValue', '');
      setChartData(newChartData)
    } else {
      setTableDColumns(resultTableColumns)
      //console.log(global.waterQualityPortalData)
      setTableData(global.waterQualityPortalData || []);

      const newChartData = updateChartDataResults(global.waterQualityPortalData, 'ActivityStartDate', 'ResultMeasureValue', '');
      setChartData(newChartData)
      setCategory(global.waterQualityPortalDataMetric);
    }
     setMapLegendTitle('Results');
  }

  const featuresToDataset = (features: any) => {
    let newSet = [];
    if(features){
      for (let dataItem of features) {
        if(dataItem.properties){
          newSet.push(dataItem.properties)  
        }
      }      
    }
    return newSet;
  }

  const colorScalePoints = (evalValue: number) => {
    const colorValue = d3ScaleChromatic.interpolateRdYlGn(evalValue);
    return colorValue;
  }

  useEffect(() => {
    //console.log('mData', mData)

    if(mData && mData.features){ 
        const newData: FeatureCollection = {
          type: 'FeatureCollection',
          features: [...mData.features],
        };
       
        if(newData && newData.features&& newData.features.length>0){
          //console.log(newData, newData.features)
          //const rExtent = d3Array.extent<any, any>(newData.features, d => +d[properties][activityCount]);
/*          const rScale = d3Scale.scaleLinear()
                    .domain(rExtent)
                    .range([0, 4]);*/

          for (let dataItem of newData.features) {
            if(dataItem.properties){
                  dataItem.properties.layerName = 'custom'; 
                  dataItem.properties.radius = 8;
                  if(dataItem.properties && dataItem.properties.activityCount){
                      dataItem.properties.activity = +dataItem.properties.activityCount;
                      //dataItem.properties.radius = 8 + rScale(+dataItem.properties.activityCount);
                  }
                  if(dataItem.properties && dataItem.properties.resultCount){
                      dataItem.properties.result = +dataItem.properties.resultCount;
                  }    
        
                  if(dataItem.geometry && dataItem.geometry && (!dataItem.properties.centroid_longitude || !dataItem.properties.centroid_latitude)){
                        //@ts-ignore
                        dataItem.properties.centroid_longitude = dataItem.geometry?.coordinates[0];//@ts-ignore
                        dataItem.properties.centroid_latitude = dataItem.geometry?.coordinates[1];
                
                  }
            }
          }      
     
          const pointsExtent = d3Array.extent<any, any>(newData.features, d => +d.properties['result']);
          const legendNormalizedScale = d3Scale.scaleLinear()
          .domain([0, 1])
          .range([pointsExtent[0], pointsExtent[1]]);
          const legendScale = d3Scale.scaleLinear()
            .domain([pointsExtent[0], pointsExtent[1]])
            .range([0, 1]);
          const legenddata: any[] = [];
          legenddata.push({ typecode: d3Format.format(".3r")(legendNormalizedScale(1)), color: colorScalePoints(0) })
          legenddata.push({ typecode: d3Format.format(".3r")(legendNormalizedScale(.75)), color: colorScalePoints(.25) })
          legenddata.push({ typecode: d3Format.format(".3r")(legendNormalizedScale(.5)), color: colorScalePoints(.5) })
          legenddata.push({ typecode: d3Format.format(".3r")(legendNormalizedScale(.25)), color: colorScalePoints(.75) })
          legenddata.push({ typecode: d3Format.format(".3r")(legendNormalizedScale(0)), color: colorScalePoints(1) })

    
          setCustomData(newData);
          updateLayerStyleCustom(pointsExtent);
          setMapLegendTitle('Results');
          setMapLegendData(legenddata);

    
          const featureCollection: FeatureCollection = {
            type: 'FeatureCollection',
            features: [...newData.features],
          };  
    
          if(featureData && featureData.features){        
            featureCollection.features.concat(featureData.features)
          }
          if(featureCollection.features.length > 0){
            const bbox_ = bbox(featureCollection);
            if(mapRef && mapRef.current){
                mapRef.current.fitBounds([[bbox_[0], bbox_[1]], [bbox_[2], bbox_[3]]], {
                  padding: {top: 110, bottom:115, left: 115, right: 115}
                })
            }
          }
        } else {
          setCustomData(newData);
        }   
    
    }

  }, [mData]); 





  const analyzeSamplingData  = (datawiw2022:any) => {
    let gradeLevel = 0.1;
    let data = [] as any;

    if(category === 'Total Phosphorous (mg/L)'){
      gradeLevel = 0.1;
    } else if (category === 'Chloride (mg/L)'){
      gradeLevel = 395;
    }

    if(tableData && tableData.length > 0){
      data = [...tableData];


      for (let dataItem of data) {
          dataItem['Site Type Code'] = 'none';
          let siteData = datawiw2022.filter((wo: any) => wo["SWIMS Station"] === dataItem["MonitoringLocIdent_MonitoringLocationIdentifier"].substr(10) && wo[category])
          //console.log(siteData.length)
          if(siteData && siteData.length>0){
            let gradeData = siteData.filter((wo: any) => wo[category] >= gradeLevel)
            let gradeBelowData = siteData.filter((wo: any) => wo[category] < gradeLevel)
            //console.log(gradeData.length, gradeBelowData.length, siteData.length)

            if(gradeData.length > 0){
              dataItem['Site Type Code'] = 'above';
            } else {
              dataItem['Site Type Code'] = 'below';
            }
            //const newChartData = updateChartDataSampling(siteData, 'DATE', category, userSelectedLocation);
            //setChartData(newChartData)           
          } 
      }
      setTableData(data)

    }  
  }


  const loadSamplingData  = () => {
      let statesFileURL = "/temp/wiw2022.csv";
      fetch(statesFileURL)
        .then((response) => response.text())
        .then((responseText) => {
          const datawiw2022: any[] = parse(responseText, {
            header: true,
          }).data;
    
          setSamplingData(datawiw2022);
          //+++   analyzeSamplingData(datawiw2022)
          //@ts-ignore
          //let result = datawiw2022.map(a => a[Object.getOwnPropertyNames(a)]);
   
      });
  }

  useEffect(() => {
/*      setChartConfig();
   
      if(samplingData && userSelectedLocation){
          let siteData = samplingData.filter((wo: any) => wo["SWIMS Station"] === userSelectedLocation.substr(10))

          if(siteData && siteData.length>0){
            const newChartData = updateChartDataSampling(siteData, 'DATE', category, userSelectedLocation);
            setChartData(newChartData)           
          }
          analyzeSamplingData(samplingData);
      } else if(samplingData && !userSelectedLocation){
          analyzeSamplingData(samplingData);
      }*/
  }, [category]); 

  useEffect(() => {    

      let data = [] as any;

      if(props.data && props.data.length > 0){
        data = [...props.data];
        console.log(data)
        for (let dataItem of data) {
          if (dataItem["BiologicalHabitatIndex_IndexCommentText"] && dataItem["BiologicalHabitatIndex_IndexCommentText"].substr(0,1)==='{'){
              const status = JSON.parse(dataItem["BiologicalHabitatIndex_IndexCommentText"])
              dataItem["cdxStatus"] =  status.status; 
              //dataItem["submissionType"] = 'R'; //+++
              dataItem["transactionId"] =  status.transactionId;
              dataItem["statusDetail"] =  status.statusDetail;
              dataItem["submissionDate"] = status.submissionDate && DateTime.fromMillis(status.submissionDate).toLocaleString();
              dataItem["createdDate"] = status.submissionDate && DateTime.fromMillis(status.submissionDate).toLocaleString();
          } else {
              //dataItem["cdxStatus"] =  ;//!dataItem["cdxStatus"]  'Processing'; 
              //dataItem["submissionType"] = ;//'R';
              //dataItem["createdDate"] = status.submissionDate && DateTime.fromMillis(status.submissionDate).toLocaleString();
          }
          dataItem.results = (dataItem.results) ? dataItem.results : 0;
          dataItem.org = dataItem["ORGDescription_OrganizationIdentifier"];
          dataItem.lng = +dataItem["MonitorLocGeo_LongitudeMeasure"];
          dataItem.lat = +dataItem["MonitorLocGeo_LatitudeMeasure"];
          dataItem.centroid_longitude = +dataItem.lng; //+++
          dataItem.centroid_latitude = +dataItem.lat; //+++ 
          dataItem.name = dataItem["MonitoringLocIdent_MonitoringLocationIdentifier"];  
          dataItem.date = dataItem["createdDate"];
          dataItem.year = '2023'; //+++  //moment(dataItem["Milliseconds Since Unix Epoch"]).format('YYYY');  
        }; 

        const filterLabels = uniqueValues(filteredData, 'ORGDescription_OrganizationIdentifier');

        const legendData = filterLabels.map((x:any, index:number)=> ( {typecode: x, color: divergingColors[index]} ));
        setMapLegendData(mlData);

      }

      setTableData(data);

/*          


            if(+currentOrg === 14){
                loadSamplingData();
            }             
*/

  }, [props.data]); 



  const fitBoundsMapData_ = (featureCollection:any, mapRef:any) => { 
    if(featureCollection.features.length > 0){
      const bbox_ = bbox(featureCollection);
      if(mapRef && mapRef.current){
          mapRef.current.fitBounds([[bbox_[0], bbox_[1]], [bbox_[2], bbox_[3]]], {
            padding: {top: 110, bottom:115, left: 115, right: 115}
          })
      }
    }
  }  

  useEffect(() => {
    const featureCollectionFromReportData = featureCollection([...filteredData]);
    if(featureCollectionFromReportData.features.length > 0){
      setTimeout(() => {
        if(mapRef && mapRef.current){
            const mapRef_ = mapRef;

            const featureCollection: FeatureCollection = {
              type: 'FeatureCollection',
              features: [...featureCollectionFromReportData.features],
            };
      
            if(customData && customData.features && customData.features.length > 0){        
              featureCollection.features.concat(customData.features)
            }

            fitBoundsMapData_(featureCollection, mapRef_)
        }          
      }, 400);
    }  
  }, [filteredData]);

  useEffect(() => {  
   let chartInput = [...filteredData];
    setConfig(setChartConfig(category)); 
    setConfigLocations(setChartConfigLocations(category));

    const chartdata = updateChartDataWM(chartInput, locationField, "Water Level", null);
    setTimeout(() => {
      setData(chartdata);
    }, 100);   
  }, [filteredData]); 


  const featureCollection = (featureData: any) => {
    const features: Array<Feature<Point, GeoJsonProperties>> = [];
    const dateSubset_ = featureData;
    for (let dataItem of dateSubset_) {
      if (dataItem && dataItem.centroid_longitude && dataItem.centroid_latitude) {
        const featureWithPoint: Feature<Point> = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [dataItem.centroid_longitude, dataItem.centroid_latitude],
          },
          properties: dataItem,  
        };
          features.push(featureWithPoint);
      }
    }

    const featureCollectionFromReportData: FeatureCollection = {
      type: 'FeatureCollection',
      features: features,
    };
    setFeatureData(featureCollectionFromReportData);

    return featureCollectionFromReportData;
  };

/*  useEffect(() => {
    setFilteredData([]);
    setReportData([]);
    setUserSelectedLocations([]);
  }, [selectedHUC8Value]);*/

  useEffect(() => {   
      let filterData = [...filteredData];
      if((userSelectedLocations)&&(userSelectedLocations.length>0)&&(userSelectedLocations.indexOf('All') < 0)){
        filterData = filterData.filter((o:any) => userSelectedLocations.indexOf(o[locationField]) > -1);
        sortFilterReportData([...filterData], isTableSort)
      } else {
        filterReportData();
      }
  }, [userSelectedLocations]); 



  const updateChartDataLocations = (filterData:any, xField: string, yField: string) => { 
    if(!filterData){
        const chartdata = {
          labels : [],
          datasets :[],
          borderColor: '#ff6384'
        }      
        return chartdata;
    } 
   
    const chartLabels = filterData.map((x: any) => x[xField]);
    const arrData = filterData.map((x: any) => +x[yField]);
    const chartdata_data: any[] = [];
    let chartdata_labels: any[] = [];
    let colorNew = '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6); 
    chartdata_data.push({label: chartLabels, data: arrData, backgroundColor: '#9efe65' });
    chartdata_labels = chartLabels;
    
    const chartdata = {
      labels: chartdata_labels,
      datasets: chartdata_data,
      borderColor: '#ff6384',
    };
    
    return chartdata;
  }



  const updateChartDataWM = (filterData:any, groupKey:string, selectedCategory:string, huc12InfoCatalogArray:any) => {   
    const fDataset = [...filterData];
    const groupedresults = groupByKey<any, any>(fDataset, i => i[groupKey]); 
    const chartLabels = uniqueValues(fDataset, "date").sort((x:any,y:any) => x - y)
    const chartdata_data: any[] = [];
    let chartdata_labels: any[] = [];

    //null fills
    for (const key in groupedresults) { 
      let initial = groupedresults[key][0];
      const newChartLine: any[] = [];
      for (var idate in chartLabels) {
        const dateMatch = groupedresults[key].filter((o:any) => o["date"]===chartLabels[idate]);      
        if(dateMatch.length < 1){
          let chartItem = {"date" : chartLabels[idate], 
          'Water Level Feet': null,
          locationField : initial[locationField]};
          newChartLine.push(chartItem)
        } else {
            let chartItem = {"date" : chartLabels[idate], 
          'Water Level Feet': dateMatch[0]['Water Level Feet'],
          locationField : dateMatch[0][locationField]};
          newChartLine.push(chartItem)
        }
      }
      groupedresults[key] = newChartLine; 
    }

    for (const key in groupedresults) { 
      let labelGroup = key;
      if(huc12InfoCatalogArray && huc12InfoCatalogArray.length > 0){
        const hucFilter = huc12InfoCatalogArray.filter((o:any) => o["huc12"]===key);
        if (hucFilter && hucFilter.length > 0){
          labelGroup = hucFilter[0].name.substr(0,10);
        }
      }
     
      let colorLine = '#'+(0x1000000+Math.random()*0xffffff).toString(16).substr(1,6);
      let dataObject = {
          spanGaps: true,
          label: labelGroup,
          data: groupedresults[key].map((x: any) => x[selectedCategory]),
          borderColor: 'green',
          backgroundColor: colorLine,
          pointStyle: 'circle',
          pointRadius: 5,
          pointHoverRadius: 8,    
          segment: {
            borderColor: '#12234f',
          },
      }    
      chartdata_data.push(dataObject);
        
    }
    chartdata_labels = chartLabels.map((x: any) => new Date(x));

    const chartdata = {
      labels : chartdata_labels,
      datasets :chartdata_data,
      borderColor: '#ff6384'
    }  
   
    return chartdata;
  }


  const updateChartDataResults = (filterData:any, groupKey:string, selectedCat:string, location:string) => { 
    if(!filterData){
        const chartdata = {
          labels : [],
          datasets :[],
          borderColor: '#ff6384'
        }  
   
        return chartdata;
    } 
    const fData = [...filterData].sort((a:any,b:any)=>new Date(a).valueOf() - new Date(b).valueOf());

    let fDataset = [] as any;
    for (let dataItem of fData) {
        if(dataItem[selectedCat]){
              fDataset.push(dataItem)          
        }
    }


    const groupedresults = groupByKey<any, any>(fDataset, i => i[groupKey]); 
    let chartLabels = uniqueValues(fDataset, groupKey).sort((a:any,b:any)=>new Date(a).valueOf() - new Date(b).valueOf())//@ts-ignore
    chartLabels = fDataset.map(a => a[groupKey]).sort((a:any,b:any)=>new Date(a).valueOf() - new Date(b).valueOf())
    //MonitoringLocationIdentifier
    let chartLoc = uniqueValues(fDataset, "MonitoringLocationIdentifier")

    const chartdata_data: any[] = [];
    let chartdata_labels: any[] = [];

 
    //@ts-ignore
    let arrN = fDataset.map(a => a[selectedCat]).sort((a:any,b:any)=>new Date(a).valueOf() - new Date(b).valueOf());
    console.log(arrN, fDataset, chartLoc, filterLocations)

    let arr = [220];
    let arrLimit = Array.from({ length: arrN.length }, () => arr).flat();

 


   // for (const key in groupedresults) { 
      let labelGroup = location + ' ' + selectedCat;

      let colorLine = '#'+(0x1000000+Math.random()*0xffffff).toString(16).substr(1,6);
      let dataObject = {
          spanGaps: true,
          label: labelGroup,
          data: arrN,
          borderColor: 'green',
          backgroundColor: colorLine,
          pointStyle: 'circle',
          pointRadius: 5,
          pointHoverRadius: 8,    
          segment: {
            borderColor: '#12234f',
          },
      }    
      chartdata_data.push(dataObject);


/*      colorLine = 'red';
      dataObject = { 
          spanGaps: true,
          label: 'Acceptable Limit',
          data: arrLimit,
          borderColor: 'red',//@ts-ignore
          borderWidth: 1,
          backgroundColor: colorLine,
          pointStyle: 'circle',
          pointRadius: 0,
          pointHoverRadius: 8,    
          segment: {
            borderColor: 'red',
          },
      }    
      chartdata_data.push(dataObject);*/


        
    //}
    chartdata_labels = chartLabels.map((x: any) => new Date(x));

    const chartdata = {
      labels : chartdata_labels,
      datasets :chartdata_data,
      borderColor: '#ff6384'
    }  
    console.log(chartdata)
    return chartdata;
  }

  const updateChartDataSampling = (filterData:any, groupKey:string, selectedCat:string, location:string) => {   
    const fData = [...filterData].sort((a:any,b:any)=>new Date(a).valueOf() - new Date(b).valueOf());

    let fDataset = [] as any;
    for (let dataItem of fData) {
        if(dataItem[selectedCat]){
              fDataset.push(dataItem)          
        }
    }


    const groupedresults = groupByKey<any, any>(fDataset, i => i[groupKey]); 
    const chartLabels = uniqueValues(fDataset, groupKey).sort((a:any,b:any)=>new Date(a).valueOf() - new Date(b).valueOf())
    const chartdata_data: any[] = [];
    let chartdata_labels: any[] = [];

    //console.log(fDataset, groupKey, selectedCat, groupedresults, chartLabels)
    //@ts-ignore
    let arrN = fDataset.map(a => a[selectedCat]).sort((a:any,b:any)=>new Date(a).valueOf() - new Date(b).valueOf());



   // for (const key in groupedresults) { 
      let labelGroup = location + ' ' + selectedCat;

      let colorLine = '#'+(0x1000000+Math.random()*0xffffff).toString(16).substr(1,6);
      let dataObject = {
          spanGaps: true,
          label: labelGroup,
          data: arrN,
          borderColor: 'green',
          backgroundColor: colorLine,
          pointStyle: 'circle',
          pointRadius: 5,
          pointHoverRadius: 8,    
          segment: {
            borderColor: '#12234f',
          },
      }    
      chartdata_data.push(dataObject);
        
    //}
    chartdata_labels = chartLabels.map((x: any) => new Date(x));

    const chartdata = {
      labels : chartdata_labels,
      datasets :chartdata_data,
      borderColor: '#ff6384'
    } 
    return chartdata;
  }




  const filterReportData = () => {
    let filterData = [...reportData];
    if(reportData.length > 0){
      if((selectedFilterLocations)&&(selectedFilterLocations.length>0)&&(selectedFilterLocations.indexOf('All') < 0)){
        filterData = filterData.filter((o:any) => selectedFilterLocations.indexOf(o[locationField]) > -1);
      }
      if((selectedCategory)&&(selectedCategory !== 'All')){
        if(selectedCategory === 'Above'){
          filterData = filterData .filter((o:any) => o["Water Level Feet"] >= 0);
        } else if(selectedCategory === 'Below'){
          filterData = filterData .filter((o:any) => o["Water Level Feet"] <= 0);   
        }
      }
      if((selectedDateRange)&&(selectedDateRange.length > 1)){
        if(selectedDateRange[0] < selectedDateRange[1]){
          filterData = filterData.filter((o:any) => o["datetime"] > selectedDateRange[0] && o["datetime"] < selectedDateRange[1]);     
        }
      }
      if(filterData.length===0){
        toast('No records available in that range. ' + moment(selectedDateRange[0]).format('YYYY MM DD') + ' through ' + moment(selectedDateRange[1]).format('YYYY MM DD') )
      }
      sortFilterReportData([...filterData], isTableSort)    
    }
  }

  const sortFilterReportData = (unsortedData:any, ascending:boolean) => {
    let filterData = unsortedData.sort((x:any,y:any) => x["Milliseconds Since Unix Epoch"] - y["Milliseconds Since Unix Epoch"] || x[locationField].localeCompare(y[locationField])  );
    if(!ascending){
      filterData = unsortedData.sort((x:any,y:any) => y["Milliseconds Since Unix Epoch"] - x["Milliseconds Since Unix Epoch"] || y[locationField].localeCompare(x[locationField]) )
    }
    setFilteredData(filterData); 
  }

  const tableSortHandler = () => {    
    sortFilterReportData([...filteredData], true)
    setTableSort(true);
  };

  const tableSortHandlerUp = () => {  
    sortFilterReportData([...filteredData], false)
    setTableSort(false);
  };

  useEffect(() => {
    filterReportData();   
    if(selectedDateRange && selectedDateRange[0] && selectedDateRange[1]){
      setFilterLabelDays([DateTime.fromJSDate(selectedDateRange[0]).toLocaleString(), DateTime.fromJSDate(selectedDateRange[1]).toLocaleString()]);
    }
  }, [selectedCategory, selectedFilterLocations, selectedDateRange, reportData]);

  function ChartReport(data: any, options: any) {
    return (
      <>
        <div className="chart-line-container">
          <div className="line-chart">
            <Line          
              options={options} data={data}
            />
          </div>
        </div>  
      </>
    );
  }

  const onChartClickHandler = (event: any) => {
    const currentClick = getElementAtEvent(chartRef.current, event)[0];
    if(currentClick){
      const currentClickMetaData = chartRef.current.getDatasetMeta(currentClick.datasetIndex);
      const currentSite = currentClickMetaData.label;
      if(userSelectedLocations.indexOf(currentSite) > -1){
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', [locationField]], '']);
      } else {
        const siteData = filteredData.filter((o:any) => o[locationField] === currentSite);
        setPopupInfo(siteData[0]);
        setUserSelectedLocation(currentSite)
        setUserSelectedLocations([currentSite])
        //setMapHoverFilter(['in', ['get', 'USGS Site Number'], currentSite]);
      }    
    }
  }

  let tableColumns = useMemo(
    () => [
      { Header: 'Identifier', accessor: 'MonitoringLocIdent_MonitoringLocationIdentifier' },
      { Header: 'Name', accessor: 'MonitoringLocIdent_MonitoringLocationName' },
      { Header: 'Description', accessor: 'MonitoringLocIdent_MonitoringLocationDescriptionText' },
      { Header: 'Type', accessor: 'MonitoringLocIdent_MonitoringLocationTypeName' },
      { Header: 'Submission Status', accessor: 'cdxStatus' },
      { Header: 'Created Date', accessor: 'submissionDate' },
      { Header: 'Organization', accessor: 'OrganizationIdentifier' },
    ],
    []
  );

  useEffect(() => {   
      setTableDColumns([
            { Header: 'Identifier', accessor: 'MonitoringLocIdent_MonitoringLocationIdentifier' },
            { Header: 'Name', accessor: 'MonitoringLocIdent_MonitoringLocationName' },
            
            { Header: 'Type', accessor: 'MonitoringLocIdent_MonitoringLocationTypeName' },
            { Header: 'HUC', accessor: 'MonitoringLocIdent_HUCEightDigitCode' },
            { Header: 'Organization', accessor: 'ORGDescription_OrganizationIdentifier' },
            { Header: 'Formal name', accessor: 'ORGDescription_OrganizationFormalName' },
            { Header: 'Activities', accessor: 'activityCount' },
            { Header: 'Results', accessor: 'resultCount' },
            { Header: 'Description', accessor: 'MonitoringLocIdent_MonitoringLocationDescriptionText' },
          ])
  }, []); 

  const updateLayerStyle = () => { 
    const newLayerStyle : LayerProps =  {
      id: 'pointlayer',
      type: 'circle' as const,
      paint: {
        'circle-radius': 8,
        'circle-color': ['match', ['get', 'Site Type Code'], 'none' ,divergingColors[0],'above',divergingColors[1],'below','blue', '#ccc'],
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1,
        'circle-opacity': 0.5
      }
    };
    setLayerStyle(newLayerStyle);
  }

  const pointLayer: LayerProps = {
    id: 'circlepoint',
    type: 'circle',
    paint: {
      'circle-radius': 4,
      'circle-color': '#007cbf'
    }
  };
        
  const updateHoverLayerStyle = () => {
    const hoverStyle: LayerProps = {
      id: 'hoverlayer',
      type: 'circle' as const,
      paint: {
        'circle-radius': 4, //'circle-radius': ['get', 'radius'],
        'circle-color': ['match', ['get', 'ORGDescription_OrganizationIdentifier'], 'WQXTEST' ,divergingColors[0], 'WIDNR_WQX',divergingColors[1], '#ccc'], //'#000099'
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1,
        'circle-opacity': 1
      }
    };

    setHoverLayerStyle(hoverStyle);
  }

  const updateLayerStyleCustom = (mapExp?: any) => { 
/*    let newExpr = ['match', ['get', 'resultCount'], 'none' ,divergingColors[0],'above',divergingColors[1],'below','green', '#ccc'];
    if(mapExp){
      //newExpr = mapExp;
    }*/

    let newCategoryExtent = [0, 2000];
    if(mapExp){
      newCategoryExtent = mapExp;
    }
    const newLayerStyle : LayerProps =  {
      id: 'customlayer',
      type: 'circle' as const,
      paint: {
        'circle-radius': 8, //@ts-ignore
        'circle-color': [
          'interpolate',
          ['linear'],
          ['get', customCategory],
          0,
          '#1a9850',
          newCategoryExtent[1] * 0.1,
          '#66bd63',
          newCategoryExtent[1] * 0.2,
          '#a6d96a',
          newCategoryExtent[1] * 0.3,
          '#d9ef8b',
          newCategoryExtent[1] * 0.4,
          '#ffffbf',
          newCategoryExtent[1] * 0.5,
          '#fee08b',
          newCategoryExtent[1] * 0.7,
          '#fdae61',
          newCategoryExtent[1] * 0.8,
          '#f46d43',
          newCategoryExtent[1],
          '#d73027',
        ],
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1,
        'circle-opacity': 0.5
      }
    };
    setLayerStyleCustom(newLayerStyle);
  }


  const onMouseEnter = useCallback((event: any | null) => {       
/*    if (event.features && event.features[0] && event.features[0].properties) {
       //console.log(event, event.features)
      let newPopup = event.features[0].properties;
      let newLocation = event.features[0].properties[locationField];
      let newHover = event.features[0].properties[locationField];
    
      setTimeout(() => {
        setCursor('pointer');
        setPopupInfo(newPopup);
        setUserSelectedLocation(newLocation)
        setMapHoverFilter(['in', ['get', locationField], newHover]);
      } 
      , 1);
     }*/
  }, []);

  const onMouseLeave = useCallback((event: any | null) => {
    setCursor('');
/*    setTimeout(() => {
      setPopupInfo(null);
      setUserSelectedLocation(null)
      setMapHoverFilter(['in', ['get', locationField], '']);
    }
    , 11);*/
  }, []);

  
  useEffect(() => {
    if (usLocations.length > 0) {
      if (userSelectedLocations.indexOf(usLocations[0][locationField]) > -1) {
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', locationField], '']);
      } else {
        setUserSelectedLocations(usLocations[0][locationField]);
        setPopupInfo(usLocations[0]);
        setTimeout(() => {
          setMapHoverFilter(['in', ['get', locationField], usLocations[0][locationField]]);
        }, 111);
      }
    }
  }, [usLocations]);





    const renderFields = (objectMap: object) => {
        return Object.entries(objectMap).map((element: any, index:any) => {
          const skipFields = ['layerName', 'counts', 'results', 'radius'];
          if(skipFields.indexOf(element[0])>-1){
            return null;
          } else if (element[0] === 'siteUrl') {
            return (
                <p className="GD-p-comid" key={uuidv4()}>
                  <span style={{opacity: .9}}>{'Site info:'}:</span>
                  <span style={{marginLeft: 10, fontSize: '1.3em', opacity: 1}}>  
                    <a href={element[1]} target={'_blank'}>Navigate to more site info at EPA's WQP</a>
                  </span>
                </p>
              )        
          } else if (element[0] === 'characteristicGroupResultCount') {
            return (
              Object.entries(JSON.parse(element[1])).map((elementb: any, indexb:any) => {
                return (<p className="GD-p-comid" key={uuidv4()}>
                    <span style={{opacity: .9}}>{elementb[0]}:</span>
                    <span style={{marginLeft: 10, fontSize: '1.3em', opacity: 1}}>    {elementb[1]} </span>
                  </p>)

                })
              )        
          } else {
            return (
                <p className="GD-p-comid" key={uuidv4()}>
                  <span style={{opacity: .9}}>{element[0]}:</span>
                  <span style={{marginLeft: 10, fontSize: '1.3em', opacity: 1}}>    {element[1]} </span>
                </p>
              )        
          }
        })
    }
/*  const renderFields = (objectMap: object) => {
    return Object.entries(objectMap).map((element: any, index:any) => {
      const skipFields = ['layerName', 'counts', 'results'];
      if(skipFields.indexOf(element[0])>-1){
        return null;
      } else {
      return (
          <p className="GD-p-comid" key={uuidv4()}>
            {element[0]}: {element[1]} 
          </p>
        )        
      }


    })
  }*/

  const renderPopup = () => {

    if(popupInfo.layerName === 'custom'){
      return(
        <Popup
            longitude={Number(popupInfo.centroid_longitude)}
            latitude={Number(popupInfo.centroid_latitude)}
            closeButton={true}
            closeOnClick={true}
            className="wm-popup-container"
            onClose={() => setPopupInfo(null)}>
          <div className="popup-container" style={{fontSize: '1.2em', display: 'flex', flexDirection: 'column', alignContent: 'center', overflow: 'auto', maxHeight: '40vh', width: 'max-content', alignItems: 'start', lineHeight:'1em'}}>
            {renderFields(popupInfo)}
          </div>
        </Popup>
      )
    } else {
      return(
          <Popup
              longitude={Number(popupInfo.centroid_longitude)}
              latitude={Number(popupInfo.centroid_latitude)}
              className="wm-popup-container"
              onClose={() => setPopupInfo(null)}>
            <div className="popup-container" >
                <div className="popup-content-left">
                  <p className="popup-content-right-double">Location</p>
                  <p className="popup-content-right-double">Name</p>
                  <p className="popup-content-right-double">Type</p>        
                </div>
                <div className="popup-content-right">
                  <p className="popup-content-right-double">{popupInfo["MonitoringLocIdent_MonitoringLocationIdentifier"]}</p>
                  <p className="popup-content-right-double  popup-content-highlight">{popupInfo["MonitoringLocIdent_MonitoringLocationName"]}</p>
                  <p className="popup-content-right-double">{popupInfo["MonitoringLocIdent_MonitoringLocationTypeName"]}</p>            
                </div>
            </div>
          </Popup>
        )      
    }
   
  }
  

  useEffect(() => {
    if (userSelectedLocation && userSelectedLocation.length>10) {
      //console.log(userSelectedLocation, userSelectedLocation.substr(10))
        if(samplingData){
            let siteData = samplingData.filter((wo: any) => wo["SWIMS Station"] === userSelectedLocation.substr(10))
           // console.log(siteData)

          if(siteData && siteData.length>0){
            const newChartData = updateChartDataSampling(siteData, 'DATE', category, userSelectedLocation);
            setChartData(newChartData)           
          } else {
              setChartData({
                  labels: [] as any,
                  datasets: [] as any,
                });
          }

        }
    }
  }, [userSelectedLocation]);

  const hoverRowHandler = (event: any) => {
    //console.log(event)
    //setMapHoverFilter(['in', ['get', locationField], '']);
    //setPopupInfo(null);
  }

  const clickRowHandler = (rowData: any, event: any) => {
    console.log(rowData, rowData["MonitoringLocIdent_MonitoringLocationIdentifier"], rowData["MonitoringLocationIdentifier"], event)
    let newLoc = rowData["MonitoringLocIdent_MonitoringLocationIdentifier"];
    if(rowData["MonitoringLocationIdentifier"]){
      newLoc = rowData["MonitoringLocationIdentifier"];
    }
    if(rowData && newLoc){
      if(newLoc !== userSelectedLocation){
        setUserSelectedLocation(newLoc);
        setMapHoverFilter(['in', ['get', locationField], newLoc]);   
        let newPopup = rowData;
        setCursor('pointer');
        setPopupInfo(newPopup);
      } else {
        setUserSelectedLocation(null);
        setMapHoverFilter(['in', ['get', locationField], '']);   
        let newPopup = null;
        setCursor('pointer');
        setPopupInfo(newPopup);
      }
    }
    
    //dataItem["MonitoringLocIdent_MonitoringLocationIdentifier"]

/*    if(userSelectedLocations && userSelectedLocations.indexOf(event[locationField])>-1){
      setUserSelectedLocations([])
      setPopupInfo(null);
      setMapHoverFilter(['in', ['get', locationField], '']);
    } else {
      setUserSelectedLocations([event[locationField]])
      setMapHoverFilter(['in', ['get', locationField], event[locationField]])
      setPopupInfo(event);
      if (mapRef.current) {
        if (event && event.centroid_longitude) {
            mapRef.current.flyTo({
                center: [
                parseFloat(event.centroid_longitude),
                parseFloat(event.centroid_latitude),
                ],
            });
        }    
      }
    }*/
  };

/*  const clickRowHandler = (event: any) => {
    if (userSelectedLocations.indexOf(event[locationField]) > -1) {
      setUserSelectedLocations([]);
      setPopupInfo(null);
    } else {
      setUserSelectedLocations([event[locationField]]);
      setMapHoverFilter(['in', ['get', locationField], event[locationField]]);
      setPopupInfo(event);
      if (mapRef.current) {
        if (event && event.centroid_longitude) {
          mapRef.current?.flyTo({
            center: [parseFloat(event.centroid_longitude), parseFloat(event.centroid_latitude)],
          });
        }
      }
    }
  };*/

  const onHoverOut = useCallback((e: any | null) => { 
/*    setMapHoverFilter(['in', ['get', locationField], '']);
    setPopupInfo(null);*/
  }, []);

  const onTableClick = useCallback((e: any | null) => { 
      console.log(e, e.target, e.target.value)
  }, []);

  const deselectFilters = useCallback((e: any | null) => { 
    setPopupInfo(null);
    setMapHoverFilter(['in', ['get', locationField], '']);
    setUserSelectedLocations([]);
  }, []);

  const handleChange = (e:any) => {
     //console.log(e, e.target.value)   
      setCategory(e.target.value);
         //setValue && setValue(e.target.value);
  }

  let mlLocations = <div style={{marginTop: 20, marginBottom: 20}}>
                          <div className='eddi-units 'style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span>Fewer Results</span><span>More results</span>
                           </div>
                          <img src={interpolatePuOr} style={{width: '100%', height: 20, transform: 'rotateY(180deg)'}} />
                        </div>;


  const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  //+++  filter={mapHoverFilter}


  const onMapLoad = useCallback(() => {
      if(mapRef && mapRef.current){
          setMapLoaded(true);     

          mapRef.current.resize();
      }
  }, []);

  const onHandleClick = useCallback((event: any | null) => {
      const feature = event.features && event.features[0];
      if (feature) {
       
        //setUsLocations([feature.properties]);   
      }
  }, []);


  const pointInPolygonUpdate = (pointsSelected: any) => {
        setUserSelectedLocations(pointsSelected);
        setMapHoverFilter(['in', ['get', locationField], ['literal', pointsSelected]]);
  }

  const onDelete = useCallback((e: any | null) => { 
      if(mapRef && mapRef.current){
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', locationField], '']);
      }
  }, []);

  //const locationField = 'MonitoringLocIdent_MonitoringLocationIdentifier';

  return (
    <>
      <FullScreenComp handle={fullscreenhandle} className="fullscreen-watermonitoringreport" >
        <div className="watermonitoringreport" id='watermonitoring-report'>
        <ReportHeader global={global}  
           reportID={"watermonitoring-report"} fullScreenClickHandle={fullscreenhandle.enter} 
           filterMode={'reportcomp'} data={setMData} data3={setCustomRData}
           breadcrumboff={true} filterAppliedOff={true} layersmenu={true} sidebarright={true}/>    
          <div className="container" style={{display: 'flex', flexDirection: 'column'}}>


            <div className=""  style={{display: 'flex', flexDirection: 'row', width: 'calc(100vw - 50px)'}}>
              <div className="map-report-container" style={{maxHeight: '50vh', minWidth:'50vw', flexGrow: 1}}>
                <LoadingDataAnimation dataLoading={!isDataReportLoaded} />
                <Map
                  id="WaterMonitoringReport"
                  mapboxAccessToken={settings.maboxKey}   
                  mapStyle={global.mapStyle}
                  onLoad={onMapLoad}
                  onClick={(e) => {
                    
                    onHandleClick(e)
                  }}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  cursor={cursor}
                  preserveDrawingBuffer={true}
                  interactiveLayerIds={['pointlayer', 'customlayer']}                  
                  ref={mapRef as Ref<MapRef>}
                >
                  {featureData && (
                    <Source id="circlesource" type="geojson" data={featureData}>
                      <Layer {...layerStyle}  /> 
                    </Source> 
                  )}

                  {customData && (
                    <Source id="customsource" type="geojson" data={customData}>
                      <Layer {...layerStyleCustom}  /> 
                    </Source> 
                  )}

                   <MapAddition global={global} 
                                mapRef={mapRef}
                                onDelete={onDelete}
                                PointInPolygonField={locationField}
                                PointInPolygonUpdate={pointInPolygonUpdate}
                                position={'low'}
                                zipOff={true}
                                statusMessage={statusMessage}/>


                  {popupInfo && renderPopup()}


                  <div className='map-legend-container' onClick={deselectFilters} >
                      <MapLegend 
                        mapLegendData={[]} 
                        title={mapLegendTitle}
                        global={global} 
                        htmlPassThrough={mlLocations}
                      />
                  </div>
                </Map>
                <MapSlider global={global} />
              </div>

              <div className="chart-container-parent" style={{maxWidth: 590,minWidth: 200, width:520}}>   
                <div className={isFullScreenChartActive ? 'expand-chart-container chart-container' : 'chart-container'}>
                    <div className="report-chart-options">
                      <Button onClick={fullscreenChartContainerHandler}>
                        <img src={fullscreenIcon} />
                      </Button>    
                    </div> 
                    
                          <div className="chart-line-container">
                            <div className="line-chart">
                             {featureData && (tableDisplay==='results') && ( 
                              <Line options={config} 
                                    data={chartData}
                                    ref={chartRef}
                                    onClick={onChartClickHandler}
                                    key={'chartjj'+counter}
                                    style={(tableDisplay==='results') ? {display: 'block'} : {display: 'none'}}
                              />
                              )} 
                              {featureData && (tableDisplay==='locations') && (
                              <Bar options={configLocations} 
                                    data={chartData}
                                    ref={chartRef}
                                    onClick={onChartClickHandler}
                                    key={'chartjj'+counter}
                                    style={(tableDisplay==='locations') ? {display: 'flex'} : {display: 'none'}}
                              />
                              )} 
                            </div>
                          </div>
                     
                </div>  
              </div> 

            </div>     


              <div 
                className={isFullScreenActive ? 'row gx-0 table-chart-row table-parent-container expand-container': 'row gx-0 table-chart-row table-parent-container'}  
                onMouseLeave={onHoverOut}
                onClick={onTableClick}> 

                  <div className="report-options"  style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline', width: '100%', maxHeight: 40, justifyContent: 'space-between'}}>
                      <div>
                        <Form.Group className="" controlId="formData"  style={{display: 'flex', flexDirection: 'row'}}>
                          <Form.Label style={{ marginLeft: 10, marginRight: 5}}>Table Display: </Form.Label>
                          <Form.Check
                                            onChange={() => {
                                              setTableDisplay('locations')
                                            }}
                                            checked={tableDisplay==='locations'}
                                            className="wm-org-cred-check"               
                                            type="radio"
                                            id={`credensss-self`}
                                        />
                          <Form.Label style={{minWidth: 54, marginRight: 5}}>Locations</Form.Label>
                          <Form.Check
                                            onChange={() => {
                                              setTableDisplay('results')
                                            }}
                                            checked={tableDisplay==='results'}
                                            className="wm-org-cred-check"               
                                            type="radio"
                                            id={`credess-self`}
                                        />
                          <Form.Label style={{minWidth: 54, marginRight: 5}}>Results</Form.Label>
                        </Form.Group>  
                      </div>                   
                      <div className='' style={{marginLeft: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                   

                            <Form.Group className="m-1 wm-org-title-bar-form" controlId="formOrg"  style={(tableDisplay==='results') ? {display: 'none', flexDirection: 'row'} : {display: 'none'}}>
                                <Form.Label style={{minWidth: 140, marginRight: 10}}>Selected Metric:</Form.Label>
                                {/* @ts-ignore */}
                                <Form.Select id='wm-organizations' key={'wm-org-'+'ww1' } onChange={handleChange}  value={category} className='wm-organizations-select' size="sm" placeholder='Organizations'>
                                    {metrics  && metrics.map((dash, index) =>
                                        (dash && <option key={'wm-org-optionsaa'+index} value={dash}>{dash}</option>)
                                    )} 
                                </Form.Select>
                            </Form.Group>

                            <FormCheck
                              onChange={() => setShowCustom(!showCustom)}
                              label={"Show WQP Data"}
                              style={{marginRight: 10}}
                              type="switch"
                              checked={showCustom}
                            />

                        </div>
                    <Button variant="light" onClick={fullscreenContainerHandler} style={{maxHeight: 40}}>
                      <img src={fullscreenIcon} />
                    </Button>
                  </div>
                  <ReportTableExpanded
                    key={'tableWMR' + tableDColumns.length}
                    data={tableData}
                    columns={tableDColumns}                
                    clickRowHandler={clickRowHandler}        
                    clickLoadStop={true}    
                  />
                </div> 
                 
 
          </div>
      </div>
     </FullScreenComp>
    </>
  );
}

  