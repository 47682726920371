import { 
    useMemo, 
    useEffect, 
    useState 
} from 'react'
import ReportTable from '../../shared/ReportTable'
import {
  Col,
  Row,
  Button
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPenToSquare
} from '@fortawesome/pro-solid-svg-icons';
import { exportToCsv } from '../../../utils';

const OrganizationInfo = (props:any) => {

    const { 
        action, 
        setAction,
        orgInfo, 
        setOrgID,
        setOrgData,
        orgData,
        currentOrg,
        setCurrentOrg                
    } = props;
  
    const tableColumns = useMemo(
        () => [
          {
            Header: 'EPA Identifier',
            accessor: 'epa_org_id',
          },

          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Description',
            accessor: 'description',
          },

          {
            Header: 'Electronic Address',
            accessor: 'email',
          },

          { Header: 'Is Active', accessor: 'is_active',
            Cell: (props: any) => ((props.value) ? ' ' + props.value : ' '),
          },
          {
            Header: 'Actions',
            accessor: 'org_id',
            Cell: (props: any) => (
              <div
                onClick={() => {
                    setCurrentOrg(props.value)   
                    setAction('edit')        
                }}
                className='action-cell'
              >
                <FontAwesomeIcon style={{ color: '#4fc2d9', cursor: 'pointer' }} icon={faPenToSquare} />
              </div>
            ),
          }
        ],
        []
      );
    

      const handleAdd = (event: any) => {
        setAction('add')
      }

      const handleExport = (event: any) => {
        exportToCsv(orgData, 'OrganizationInfo ')
      }

      return (
        <>
          <div className="header-box">
            <h2>Organization Info</h2>
            <div className="button-box">
              <Button id="export-btn"  onClick={handleExport}  className="p-3 btn btn-outline-primary m-2">
                Export CSV
              </Button>
              <Button onClick={() => setAction('create')} id="addNew-btn" className="p-3 btn btn-primary m-2">
                Add New
              </Button>
            </div>
          </div>
          <div className="organization-info">
            <ReportTable columns={tableColumns} data={orgData || []} />
          </div> 
        </>
      )
}

export default OrganizationInfo