import html2canvas from 'html2canvas';
import { jsPDF, ImageOptions } from "jspdf";
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import settings from "../../../settings.json";
import { DateTime } from 'luxon';
import { ThemeType, Color } from 'jspdf-autotable';

//++ Summary Report
const colorGreen = "#4fc22c";
const colorYellow = "#e6ee00"; 
const colorYellowDarker = "#d3da00";  //255,255,200 #FFFFC8
const colorYellowFill = "#FFFFC8";  //255,255,200 
const colorRed = "#c22c4f";
const colorUnavailable = "#3c343c";
const colorWhite = "#ffffff";
const colorLightGray = "#717171";
const colorReallyLightGray = "#e2e2e2";
const colorSectionHeader = "#191919";
const textFooter = colorLightGray;
const locationField = 'name';
const reportTitle = "True QI Disclosure Risk Report Scores";
const logoSource = "logo490.png";
const logoAR = 3.53;
let companyName = 'NASDAQ';

const onPDF_ = (data: any, data2:any, data3:any, mapimage:any, industry:any) => {

  const textColor = '#257ee0';
  const pageY = 6;
  const logoRight = 60;
  const mainMinCellHeight = 50.5;
  const rowcolor = [[244, 244, 244], [255,255,255], [244, 244, 244], [255,255,255], [244, 244, 244]];

  var doc = new jsPDF()
  let reportID = 'True Elements True QI Disclosure Risk Report Scores';
  var totalPagesExp = '{total_pages_count_string}'

  var imgbr = new Image(); 
  imgbr.src = logoSource;

  let th = 'plain' as ThemeType;
  let themegrid = 'grid' as ThemeType;

  const attr = [] as any[];
  let sortField = 'sasbrefid';
  let sasb = [...data3].sort((x: any, y: any) => x[sortField].localeCompare(y[sortField]));
  
  const reportTabsNew = [...data2];
  const lengthData = data.length;    
  const sasbdata = [...data3];    
  
  const rectData = {};

    const greenMatch = ['Not officially rated as being in a drought', 'AREA WITH REDUCED FLOOD RISK DUE TO LEVEE', '0.2 PCT ANNUAL CHANCE FLOOD HAZARD', 'Increased by less than 10%', 'No PFAS detected', 'Low (<10%)', 'Low - Medium (1 in 1,000 to 2 in 1,000)', 'Low - Medium (10-20%)', 'Low (0 to 1 in 1,000)', 'Low - Medium (1 in 1,000 to 2 in 1,000)', 'Low (0 to 9 in 1,000,000)', 'Low - Medium (9 in 1,000,000 to 7 in 100,000)', 'No Superfund sites within the watershed', 'Not in Protected area', 'Not rated as being in a drought', 'Not officially rated as being in a drought', 'Abnormally Dry (D0)', 'Minimal Flood Hazard'];
    const yellowMatch = ['Risk has increased by 10% to 25%', 'Superfund watershed directly feeds.', 'PFAS detected in adjacent', 'Medium - High (20-40%)', 'Medium - High (2 in 1,000 to 6 in 1,000)', 'Medium - High (20-40%)', 'Superfund site in watershed that directly feeds this one.', 'Moderate Drought (D1)'];
    const redMatch = ['Located within Protected Area', 'Superfund watershed site', 'Precipitation Risk is greater than 25%', 'PFAS detected', 'High (6 in 1,000 to 1 in 100)', 'Extremely High (>80%)', 'High (40-80%)', 'Extremely High (more than 2 in 1,000)', 'High (6 in 1,000 to 1 in 100)', 'Extremely High (more than 2 in 1,000)', 'Superfund site has been detected within this watershed.', 'Severe Drought (D2)', 'Extreme Drought (D3)', 'Exceptional Drought (D4)', 'High Flood Hazard'];
    const unavailableMatch = ['aaa', 'No Score available'];

    var pageSize = doc.internal.pageSize
    var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var date = new Date();


    const headerFooter = () => {
        doc.setTextColor(colorSectionHeader)
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(16)
            doc.text(companyName, 12, 20)
            doc.line(12, 25, pageWidth-10, 25)

            doc.setFontSize(10)
            doc.setTextColor(colorLightGray)
            doc.setFont('normal');
            doc.text(reportTitle, pageWidth - 67, 8)
             
            // Footer
            var str = 'Page ' + doc.getNumberOfPages()            
            if (typeof doc.putTotalPages === 'function') {
              str = str + ' of ' + totalPagesExp
            }
            doc.setFontSize(10)
            doc.setTextColor(textFooter);

            doc.text(str, pageWidth - 25, pageHeight - pageY)          
            doc.addImage(imgbr, 'PNG', 6, pageHeight - 14, 42.4, 12);   //3.53
            doc.text("© True Elements Inc. 2023", pageWidth/2-20, pageHeight - pageY);
            //color: #257FE0
            //#173248!important;
    }

    //dataReport["fieldsSelected"]


  for (let dataReport of reportTabsNew) {   
    if(dataReport.title !== 'Overview'){
        let fData = data.filter((o: any) => o[dataReport.title] === colorGreen);
        let percentGreen = '' + Math.round((fData.length/lengthData)*100);
        dataReport["resultgreen"] = `${fData.length} sites`;
        fData = data.filter((o: any) => o[dataReport.title] === colorYellow);
        let percentYellow =  '' + Math.round((fData.length/lengthData)*100);            
        dataReport["resultyellow"] = `${fData.length} sites`;
        fData = data.filter((o: any) => o[dataReport.title] === colorRed);
        let percentRed =  '' + Math.round((fData.length/lengthData)*100);
        dataReport["resultred"] = `${fData.length} sites`;
        let titleplus = dataReport["title"].substr(0,10);
        //@ts-ignore
        rectData[titleplus] = {title: dataReport.title, green: percentGreen, yellow: percentYellow, red: percentRed, resultgreen: dataReport["resultgreen"], resultyellow : dataReport["resultyellow"], resultred: dataReport["resultred"]};


    }
  }   

   if(reportTabsNew && reportTabsNew.length > 0){
          let i = 0;
          

          i = 0;
          for (let dataItem of reportTabsNew) {
              if(dataItem.title!=='Overview'){
                  //let secS = [] as any[];
                  attr.push([dataItem["title"], dataItem["attribution"], dataItem["attribution_url"]]);
                   if(dataItem["attribution2"]){
                     attr.push([' ', dataItem["attribution2"], dataItem["attribution_url2"]]);  
                   }


                  //if (i < 10 && i > 4){
                  //attr.push(secS);    
                  //}
                  i++;
              }
          }

    }
    //#257ee0 //rgba(37,126,224,255)

//++ Sasb
    let reportColumnsSasb = { 
         columns: [
            { dataKey: 'section', header: 'TOPIC' },
            { dataKey: 'sasbreference', header: 'SASB CODE' },
            { dataKey: 'metric', header: 'METRIC' },
            { dataKey: 'disclosurelabel', header: 'DISCLOSURE' },
          ],
         columnStyles: {
              0: { cellWidth: 40},
              1: { cellWidth: 30},
              2: { cellWidth: 70},
              3: { cellWidth: 48},
        },//@ts-ignore
        body: sasb, //@ts-ignore
        theme: themegrid,
        headStyles: {//@ts-ignore
                    fillColor: textColor,
        },
/*        willDrawCell: function (data:any) {
          if (data.row.section === 'body'){
              if(data.row.index === 0) {
                doc.setDrawColor(colorSectionHeader);
                doc.line(12, data.cell.y, pageWidth-10, data.cell.y);
              } else {                
                doc.setDrawColor(colorReallyLightGray);
                doc.line(12, data.cell.y, pageWidth-15, data.cell.y);
              }
              
          }
            if (data.row.section === 'body' && data.column.dataKey === 'Sourceurl') {                    
                  doc.textWithLink('', data.cell.x, data.cell.y, {url: data.cell.raw});
            } else if (data.row.section === 'body' && data.column.dataKey === 'Source') {                    
                  doc.setTextColor(textColor)  
                  doc.link(data.cell.x+1, data.cell.y+1, 15, 200, {url: sasb[data.row.index][2]});
            }
        },   */ 
        didDrawPage: function (data:any) {
          headerFooter();

          doc.setTextColor(colorSectionHeader)  
          doc.setFont('helvetica', 'bold');
          doc.setFontSize(12)
          doc.text('SASB Disclosure', pageWidth-46, 22) 
        },
        margin: { top: 30, left: 12, bottom: 14 },
    };

    const tableGrid = [] as any[];  
    const tableGridColumns = [] as any[];
    for (let dataItem of data) {
        const tableGridRow = [] as any[];
        tableGridRow.push(dataItem["name"]);
        let i = 0;
        for (let dataI of reportTabsNew) {
          if(dataI.title!=='Overview'){
            tableGridRow.push(dataItem[dataI.title]);
          }
        }
        tableGrid.push(tableGridRow);
    }

    tableGridColumns.push({dataKey: 'name', header: 'Site'});
    for (let dataI of reportTabsNew) {
      if(dataI.title!=='Overview'){
        tableGridColumns.push({dataKey: dataI.title, header: dataI.title});
      }
    }



    const shortenText = (original: any, category:any) => {
      let returnText = "";
      if(original){
        if(original === 'Superfund site in watershed that directly feeds this one.'){
          returnText = 'Superfund watershed directly feeds.'
        } else if (original === 'Superfund site has been detected within this watershed.'){
          returnText = 'Superfund watershed site'
        } else {
          returnText = original;
        }
        
      } 
      return returnText;
    }






    const tableGrid_min = [] as any[];
    const tableGridColumns_min = [] as any[];
    for (let dataItem of data) {
        const tableGridRow = [] as any[];
        tableGridRow.push(dataItem["name"]);
        let i = 0;
        for (let dataI of reportTabsNew) {
          if(dataI.title!=='Overview'){
            if(dataItem[dataI.title] !== colorGreen && dataItem[dataI.title] !== colorUnavailable){
              let textResult = shortenText(dataItem[dataI.title+'result'], dataI.title);
              tableGridRow.push(textResult);
            } else if(dataItem[dataI.title] === colorGreen){
              tableGridRow.push('-');
            } else {
              tableGridRow.push('');
            }
          }
        }
        tableGrid_min.push(tableGridRow);
    }

    tableGridColumns_min.push({dataKey: 'name', header: 'Site'});
    for (let dataI of reportTabsNew) {
      if(dataI.title!=='Overview'){
        tableGridColumns_min.push({dataKey: dataI.title, header: dataI.title});
      }
    }

    
/*    const tableGrid_min = [] as any[];
    const tableGridColumns_min = [] as any[];
    for (let dataItem of data) {
        const tableGridRow = [] as any[];
        tableGridRow.push(dataItem["name"]);
        let i = 0;
        for (let dataI of reportTabsNew) {
          if(dataI.title!=='Overview'){
            if(dataItem[dataI.title]){
              let textResult = shortenText(dataItem[dataI.title+'result'], dataI.title);
              tableGridRow.push(textResult);

            } else {
              tableGridRow.push('');
            }
          }
        }
        tableGrid_min.push(tableGridRow);
    }

    tableGridColumns_min.push({dataKey: 'name', header: 'Site'});
    for (let dataI of reportTabsNew) {
      if(dataI.title!=='Overview'){
        tableGridColumns_min.push({dataKey: dataI.title, header: dataI.title});
      }
    }
*/

    let reportColumns = [] as any[];


      //++ Table Grid
    const greenGridMatch = ["#4fc22c"];
    const yellowGridMatch = ["#e6ee00"];
    const redGridMatch = ["#c22c4f"];
    const unavailableGridMatch = ["#3c343c"];
    const rectGridCellHeight = 3;





    let reportColumnsGrid = { 
        columns: tableGridColumns,
        body: tableGrid,
        styles: { cellPadding: {top: .7, right: .5, bottom: .1, left: .4}, fontSize: 6, minCellHeight: 7 }, //@ts-ignore
        headStyles: { minCellHeight: 9, halign: 'center', valign: 'middle' },
        theme: th,
        willDrawCell: function (data:any) {
          if (data.row.section === 'body'){
              if(data.row.index === 0) {
                doc.setDrawColor(colorSectionHeader);
                doc.line(12, data.cell.y, pageWidth-10, data.cell.y);
              } else {                
                doc.setDrawColor(colorReallyLightGray);
                doc.line(13, data.cell.y, pageWidth-11, data.cell.y);
              }
          }

            if (data.row.section === 'body' && greenGridMatch.indexOf(data.cell.raw) > -1) {                    
                doc.setTextColor(colorGreen);
                doc.setFontSize(0)
            } else if (data.row.section === 'body' && yellowGridMatch.indexOf(data.cell.raw) > -1) {                                          
                doc.setTextColor(colorYellow);
                doc.setFontSize(0)
            } else if (data.row.section === 'body' && redGridMatch.indexOf(data.cell.raw) > -1) {                    
                doc.setTextColor(colorRed);
                doc.setFontSize(0)
            } else if (data.row.section === 'body' && unavailableGridMatch.indexOf(data.cell.raw) > -1) {                    
                doc.setTextColor(colorUnavailable);
                doc.setFontSize(0)
            }
        }, 
        didDrawCell: function (data:any) {
            if (data.row.section === 'body' && greenGridMatch.indexOf(data.cell.raw) > -1) {                                  
                doc.setFillColor(colorGreen);
                doc.rect(data.cell.x + (data.cell.width - 7)/2 , data.cell.y + (data.cell.height - rectGridCellHeight)/2 , 7, rectGridCellHeight, 'F')
            } else if (data.row.section === 'body' && yellowGridMatch.indexOf(data.cell.raw) > -1) {                    
                doc.setFillColor(colorYellow);
                doc.rect(data.cell.x + (data.cell.width - 7)/2 , data.cell.y + (data.cell.height - rectGridCellHeight)/2 , 7, rectGridCellHeight, 'F') ;
            } else if (data.row.section === 'body' && redGridMatch.indexOf(data.cell.raw) > -1) {                    
                doc.setFillColor(colorRed);
                doc.rect(data.cell.x + (data.cell.width - 7)/2 , data.cell.y + (data.cell.height - rectGridCellHeight)/2 , 7, rectGridCellHeight, 'F') ;
            }
        },    
        didDrawPage: function (data:any) {
          headerFooter();

          doc.setTextColor(colorSectionHeader)
          doc.setFont('helvetica', 'bold');
          doc.setFontSize(12);
          doc.text('Assessment Grid', pageWidth-47, 21); 
        },
        margin: { top: 25, left: 12, bottom: 14, right: 10 },
    };


    let reportColumnsGrid_min = { 
        columns: tableGridColumns_min,
        body: tableGrid_min,//@ts-ignore
        styles: { cellPadding: {top: .7, right: .5, bottom: .1, left: 1}, fontSize: 6, minCellHeight: 7,  halign: 'center', valign: 'middle' }, //@ts-ignore
        headStyles: { minCellHeight: 9, halign: 'center', valign: 'middle' },
        theme: th,
        willDrawCell: function (data:any) {
            if (data.row.section === 'body' && greenMatch.indexOf(data.cell.raw) > -1) {                    
                  doc.setTextColor(colorGreen)  
            } else if (data.row.section === 'body' && yellowMatch.indexOf(data.cell.raw) > -1) {                    
                  doc.setTextColor(colorYellowDarker)  
            } else if (data.row.section === 'body' && redMatch.indexOf(data.cell.raw) > -1) {                    
                  doc.setTextColor(colorRed)  
            } else if (data.row.section === 'body' && unavailableMatch.indexOf(data.cell.raw) > -1) {                    
                  doc.setTextColor(colorUnavailable)  
            } else if (data.row.section === 'body' && data.column.dataKey === 'Site Surface Water Quality Threshold' && !isNaN(parseInt(data.cell.raw)) && parseInt(data.cell.raw) > 89 && parseInt(data.cell.raw) < 111) {                    
                  doc.setTextColor(colorGreen)  
            } else if (data.row.section === 'body' && data.column.dataKey === 'Site Surface Water Quality Threshold' && !isNaN(parseInt(data.cell.raw)) && parseInt(data.cell.raw) > 79 && parseInt(data.cell.raw) < 90) {                    
                  doc.setTextColor(colorYellowDarker)  
            } else if (data.row.section === 'body' && data.column.dataKey === 'Site Surface Water Quality Threshold' && !isNaN(parseInt(data.cell.raw)) && parseInt(data.cell.raw) > 1 && parseInt(data.cell.raw) < 80) {                    
                  doc.setTextColor(colorRed)  
            } else if (data.row.section === 'body' && data.cell.raw === '-') {                    
                  doc.setTextColor(colorGreen)   
            }
          if (data.row.section === 'body'){
              if(data.row.index === 0) {
                doc.setDrawColor(colorSectionHeader);
                doc.line(12, data.cell.y, pageWidth-10, data.cell.y);
              } else {                
                doc.setDrawColor(colorReallyLightGray);
                doc.line(13, data.cell.y, pageWidth-11, data.cell.y);
              }
          }

        },      
        didDrawPage: function (data:any) {
         headerFooter();

         
          doc.setTextColor(colorSectionHeader)
          doc.setFont('helvetica', 'bold');
          doc.setFontSize(12)
          doc.text('Risk Assessment', pageWidth-47, 21) 
        },
        margin: { top: 25, left: 12, bottom: 18, right: 10 },
    };


    //++ Sources
    let reportColumnsAttr = { 
         columns: [
            { dataKey: 'Resiliency Risk Category', header: 'Resiliency Risk Category' },
            { dataKey: 'Source', header: 'Data Source' },
            { dataKey: 'Sourceurl', header: '' },

          ],
         columnStyles: {
              2: { cellWidth: 67},
              1: { cellWidth: 60},
              0: { cellWidth: 60},
        },//@ts-ignore
        body: attr, //@ts-ignore
        theme: th,
        willDrawCell: function (data:any) {
          if (data.row.section === 'body'){
              if(data.row.index === 0) {
                doc.setDrawColor(colorSectionHeader);
                doc.line(12, data.cell.y, pageWidth-10, data.cell.y);
              } else {                
                doc.setDrawColor(colorReallyLightGray);
                doc.line(12, data.cell.y, pageWidth-15, data.cell.y);
              }
              
          }
            if (data.row.section === 'body' && data.column.dataKey === 'Sourceurl') {                    
                  doc.textWithLink('', data.cell.x, data.cell.y, {url: data.cell.raw});
            } else if (data.row.section === 'body' && data.column.dataKey === 'Source') {                    
                  doc.setTextColor(textColor)  
                  doc.link(data.cell.x+1, data.cell.y+1, 15, 200, {url: attr[data.row.index][2]});
            }
        },    
        didDrawPage: function (data:any) {
          headerFooter();

          doc.setTextColor(colorSectionHeader)
          doc.setFont('helvetica', 'bold');
          doc.setFontSize(12)
          doc.text('Sources', pageWidth-30, 21) 
        },
        margin: { top: 25, left: 12, bottom: 14 },
    };





   const rectOverview = (dataItem:any, i:number) => {
         let rectStart = 85;
        doc.setTextColor(colorSectionHeader)
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10)
        let titleA =( dataItem["title"] === 'Water Usage' ) ? 'Water Management' : dataItem["title"];
        doc.text(titleA, 18, rowOffset*i+rowAdustment) 

        //@ts-ignore
        let rowData = rectData[dataItem.title.substr(0,10)]; 
       
        if(rowData){
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(8)

          let xChangeGreen = rectChartLength*(.01 * rowData.green);
          let xChangeYellow = rectChartLength*(.01 * rowData.yellow);
          let xChangeRed = rectChartLength*(.01 * rowData.red);

          let yStartAdj = -3;

          if(rowData.green > 0){
            doc.setFillColor(colorGreen)
            doc.rect(rectStart, rowOffset*i+rowAdustment+yStartAdj, xChangeGreen, 5, 'F') 
            //doc.setTextColor(colorGreen)
            doc.text(rowData.green + '%', rectStart + xChangeGreen/2 - 2, rowOffset*i+rowAdustment+yStartAdj - 1) 
          } 

          if(rowData.yellow > 0){
            doc.setFillColor(colorYellow)
            doc.rect(rectStart+ xChangeGreen, rowOffset*i+rowAdustment+yStartAdj, xChangeYellow, 5, 'F') 
            //doc.setTextColor(colorYellowDarker)
            doc.text(rowData.yellow + '%', rectStart + xChangeGreen + (xChangeYellow/2) - 2, rowOffset*i+rowAdustment+yStartAdj  - 1) 
          } 

          if(rowData.red > 0){            
            doc.setFillColor(colorRed)
            doc.rect(rectStart+ xChangeGreen + xChangeYellow, rowOffset*i+rowAdustment+yStartAdj, xChangeRed, 5, 'F') 
            //doc.setTextColor(colorRed)
            doc.text(rowData.red + '%', rectStart + xChangeGreen + xChangeYellow + (xChangeRed/2) - 2, rowOffset*i+rowAdustment+yStartAdj - 1) 
          }
        }

   }

    let rowOffset = 12;
    let rowAdustment = 79;
    let nextLine = 1;
    let rectX = 82;
    let rectWidth = 119;
    let rectA = 122;
    let colorTextStartX = 13; 
    let colorTextStartY = 27; 
    let rectChartLength = 45;
    let rect_y_start = 7;


    const rectPage = (dataItem:any, i:number) => {
    	//+ left column
        doc.setTextColor(colorSectionHeader)
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(12)
        let title = (dataItem["title"] === 'Water Usage') ? 'Water Management' : dataItem["title"];
        doc.text(title, 12, rowOffset*i+rowAdustment) 
         
        doc.setTextColor(colorSectionHeader)
        doc.setFont('helvetica', 'normal');         
        doc.setFontSize(10)
        var lines = doc.splitTextToSize(dataItem["text"], 65);
        doc.text(lines, 12, rowOffset*i+rowAdustment + nextLine ) 
        
          if((dataItem["title"] === 'Water Management' || dataItem["title"] === 'Ingredient Sourcing')){
                  if(dataItem["fieldsSelected"+dataItem["title"]]){   
                    let ii=0;  
                    for (let dataIm of dataItem["fieldsSelected"+dataItem["title"]]) { 
                      if(dataIm.title !== 'Discharged'){
                              doc.setTextColor(colorSectionHeader)  
                              doc.setFont('helvetica', 'normal');
                              doc.setFontSize(10)
                              let lines2 = doc.splitTextToSize(dataItem['resultredcalc'+dataItem["title"]] && dataItem['resultredcalctop'+dataItem["title"]][ii] +  ' of ' + ((dataItem["title"] === 'Water Management') ? 'Water ' : '') + dataItem["fieldsSelected"+dataItem["title"]][ii].title + ' ' + ((dataItem["title"] === 'Water Management') ? 'is' : 'are') + ' in a basin with High or Extremely High Baseline Water Stress', 65);
                              doc.text(lines2, 12, rowOffset*i+rowAdustment + nextLine + 18 + ii*13) 
                              
                       }  
                      ii++
                    }       
                  }
          } else {
              //@ts-ignore
              let cCat = sasbdata && sasbdata.find(x => x.titlesection === dataItem["title"]);
              if(cCat){
                  doc.setTextColor(colorSectionHeader)  
                  doc.setFont('helvetica', 'normal');
                  doc.setFontSize(10)
                  let lines2 = doc.splitTextToSize(cCat.disclosure + ' ' + cCat.metriclabel, 65);
                  doc.text(lines2, 12, rowOffset*i+rowAdustment + nextLine + 18 + 13) 
                    

              }
          }



        doc.setTextColor(colorSectionHeader)
        doc.setFont('helvetica', 'italic');
        doc.setFontSize(10)      
        if(dataItem["sasbrefid"] && dataItem["sasbrefid"].length>0){
         doc.text('SASB: '+ industry.substr(0,2) + '-' + industry.substr(2,2) + '-'  + dataItem["sasbrefid"], 12, rowOffset*i+rowAdustment + rowOffset - 19)          
        }


        //+ right rect
        doc.setFillColor('#bddfeb')
        doc.rect(rectX , rowOffset*i+rowAdustment-3 + rect_y_start, rectWidth, rowOffset-18, 'F') 

        doc.setDrawColor(colorLightGray)
        doc.line(rectX + 2, rowOffset*i+rowAdustment+colorTextStartY - 4 + rect_y_start, rectX + 2, rowOffset*i+rowAdustment+colorTextStartY + 34 + rect_y_start) 
        //@ts-ignore
        let rowData = rectData[dataItem.title.substr(0,10)]; 
       
        if(rowData){
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(8)

          let xChangeGreen = rectChartLength*(.01 * rowData.green);
          let xChangeYellow = rectChartLength*(.01 * rowData.yellow);
          let xChangeRed = rectChartLength*(.01 * rowData.red);

          let yStartAdj = 8;
          //+ stacked bar chart
          if(rowData.green > 0){
            doc.setFillColor(colorGreen)
            doc.rect(rectA , rowOffset*i+rowAdustment+yStartAdj + rect_y_start, xChangeGreen, 5, 'F') 
            //doc.setTextColor(colorGreen)
            doc.text(rowData.green + '%', rectA + xChangeGreen/2 - 2, rowOffset*i+rowAdustment + yStartAdj - 2 + rect_y_start) 
          } 

          if(rowData.yellow > 0){
            doc.setFillColor(colorYellow)
            doc.rect(rectA + xChangeGreen, rowOffset*i+rowAdustment+yStartAdj + rect_y_start, xChangeYellow, 5, 'F') 
            //doc.setTextColor(colorYellowDarker)
            doc.text(rowData.yellow + '%', rectA + xChangeGreen + (xChangeYellow/2) - 2, rowOffset*i+rowAdustment + yStartAdj - 2 + rect_y_start) 
          } 

          if(rowData.red > 0){            
            doc.setFillColor(colorRed)
            doc.rect(rectA + xChangeGreen + xChangeYellow, rowOffset*i+rowAdustment+yStartAdj + rect_y_start, xChangeRed, 5, 'F') 
            //doc.setTextColor(colorRed)
            doc.text(rowData.red + '%', rectA + xChangeGreen + xChangeYellow + (xChangeRed/2) - 2, rowOffset*i+rowAdustment + yStartAdj - 2 + rect_y_start) 
          }
        }

        doc.setFillColor(colorGreen)
        doc.rect(rectX +5, rowOffset*i+rowAdustment+colorTextStartY - 2 + rect_y_start, 5, 5, 'F')
        if(dataItem["textyellow"]&&dataItem["textyellow"].length>0&&dataItem.title!=='Located within a Protected Area'){
          doc.setFillColor(colorYellow)
          doc.rect(rectX +5, rowOffset*i+rowAdustment+colorTextStartY - 2+15 + rect_y_start, 5, 5, 'F')          
        }
        doc.setFillColor(colorRed)
        doc.rect(rectX +5, rowOffset*i+rowAdustment+colorTextStartY - 2+30 + rect_y_start, 5, 5, 'F')

        doc.setTextColor(colorSectionHeader)
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10)
        var lines = doc.splitTextToSize(dataItem["textgreen"], rectWidth-8-8);
        if(lines.length === 1){
          doc.text(lines, rectX + colorTextStartX, rowOffset*i+rowAdustment+colorTextStartY+2 + rect_y_start)  
        } else {
          doc.text(lines, rectX + colorTextStartX, rowOffset*i+rowAdustment+colorTextStartY + rect_y_start)   
        }    
         
  
        doc.setTextColor(colorSectionHeader)
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10)
        var linesy = doc.splitTextToSize(dataItem["textyellow"], rectWidth-8-8);
        

        if(linesy.length === 1){
          doc.text(linesy, rectX + colorTextStartX, rowOffset*i+rowAdustment+colorTextStartY+15+2 + rect_y_start)  
        } else {
          doc.text(linesy, rectX + colorTextStartX, rowOffset*i+rowAdustment+colorTextStartY+15 + rect_y_start)   
        }    

        doc.setTextColor(colorSectionHeader)
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10)
        var linesr = doc.splitTextToSize(dataItem["textred"], rectWidth-8-8);
        if(linesr.length === 1){
          doc.text(linesr, rectX + colorTextStartX, rowOffset*i+rowAdustment+colorTextStartY+30+2 + rect_y_start) 
        } else {
          doc.text(linesr, rectX + colorTextStartX, rowOffset*i+rowAdustment+colorTextStartY+30 + rect_y_start)   
        }      

        doc.setTextColor(colorSectionHeader)
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10)
        //@ts-ignore
        doc.text(rectData[dataItem.title.substr(0,10)].resultgreen, rectX + 20, rowOffset*i+rowAdustment+5 + rect_y_start) 
        doc.setFillColor(colorGreen)
        doc.rect(rectX +15, rowOffset*i+rowAdustment+2.5 + rect_y_start, 3, 3, 'F')


        doc.setTextColor(colorSectionHeader)
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10)
        //@ts-ignore
        doc.text(rectData[dataItem.title.substr(0,10)].resultyellow, rectX + 20, rowOffset*i+rowAdustment+11 + rect_y_start)
        doc.setFillColor(colorYellow)
        doc.rect(rectX +15, rowOffset*i+rowAdustment+8.5 + rect_y_start, 3, 3, 'F')

        doc.setTextColor(colorSectionHeader)
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10)
        //@ts-ignore
        doc.text(rectData[dataItem.title.substr(0,10)].resultred, rectX + 20, rowOffset*i+rowAdustment+17 + rect_y_start)
        doc.setFillColor(colorRed)
        doc.rect(rectX +15, rowOffset*i+rowAdustment+14.5 + rect_y_start, 3, 3, 'F')

    }
  
    rowOffset = 87;
    rowAdustment = 33;
    nextLine = 14;
    rectX = 82;
    rectWidth = 119;
    rectA = 122;
    colorTextStartX = 13; 
    colorTextStartY = 29; 
    rectChartLength = 44;




/*
    headerFooter();
    doc.setTextColor(colorSectionHeader)
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(12)
    doc.text('Overview', pageWidth-32, 21) 

    doc.setTextColor(colorSectionHeader)
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(12)
    doc.text('Risk Assessments', 12, 39) 

    doc.setTextColor(colorSectionHeader)
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10)
    doc.text('Sites Analyzed', pageWidth-47, 45)
    doc.setFontSize(12) 
    doc.text('' +data.length, pageWidth-37, 52) 



    if(reportTabsNew && reportTabsNew.length > 0){ 
      let i = 0;
      for (let dataItem of reportTabsNew) {
        if(dataItem.title!=='Overview'){
          //if(i<3 && i > -1){
            rectOverview(dataItem, i);
          ///}
          i++
        }
      }
    }


    doc.addPage();*/

    //++ Cover Page

          //++ Cover Page
/*      var pageSize = doc.internal.pageSize
      var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()*/
      doc.addImage(imgbr, 'PNG', pageWidth/2 - 90, pageHeight/2 -100, 170, 48);  // 3.53
      doc.setFontSize(20)
      doc.setTextColor(textColor)
      doc.setFont('normal');
      doc.text(reportTitle, 52, pageHeight/2+40) 
      doc.addPage();


    //@ts-ignore
    autoTable(doc, reportColumnsGrid)
    headerFooter();
    

    doc.addPage();
   //@ts-ignore
    autoTable(doc, reportColumnsGrid_min)
    headerFooter();
  
    if(sasb && sasb.length > 0){
          
          doc.addPage();
          //@ts-ignore
          autoTable(doc, reportColumnsSasb);
          headerFooter();
    }

    doc.addPage();
    headerFooter();
    if(reportTabsNew && reportTabsNew.length > 0){ 
      let i = 0;
      for (let dataItem of reportTabsNew) {
        if(dataItem.title!=='Overview'){
          if(i<3 && i > -1){
            rectPage(dataItem, i);
          }
          i++
        }
      }
    }

    doc.addPage();
    headerFooter();
    if(reportTabsNew && reportTabsNew.length > 0){ 
      let i = 0; let j=0;
      for (let dataItem of reportTabsNew) {
        if(dataItem.title!=='Overview'){
          if(i<6 && i > 2){
            rectPage(dataItem, j);
            j++;
          }
          i++
        }
      }
    }

    if(reportTabsNew && reportTabsNew.length > 5){
      doc.addPage();
      headerFooter();    
      let i = 0; let j=0;
      for (let dataItem of reportTabsNew) {
        if(dataItem.title!=='Overview'){
          if(i<9 && i > 5){
            rectPage(dataItem, j);
            j++;
          }
          i++
        }
      }
    }

    if(reportTabsNew && reportTabsNew.length > 9){
      doc.addPage();
      headerFooter();
      let i = 0; let j=0;
      for (let dataItem of reportTabsNew) {
        if(dataItem.title!=='Overview'){
          if(i<12 && i > 8){
            rectPage(dataItem, j);
            j++;   
          }
          i++
        }
      }
    }

    if(reportTabsNew && reportTabsNew.length > 13){
      doc.addPage();
      headerFooter();
      let i = 0; let j=0;
      for (let dataItem of reportTabsNew) {
        if(dataItem.title!=='Overview'){
          if(i<15 && i > 11){
            rectPage(dataItem, j);
            j++;
          }
          i++
        }
      }
    }

    doc.addPage();
    //@ts-ignore
    autoTable(doc, reportColumnsAttr)

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }

    doc.setProperties({ title: reportTitle })
    window.open(doc.output('bloburl'), '_blank');

}



export const RiskAssessmentPrimary = (data: any, reportTabs:any, data3: any, orgname:any, industry:string) => { 
  if(data && reportTabs){
  	if(orgname){
  		companyName = orgname;
  	}
    onPDF_(data, reportTabs, data3, null, industry);
  }
}