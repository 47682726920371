import React from 'react'
import { Layer, Source } from 'react-map-gl'

const HUCsMexico = ({ opacity }: any) => {
    
    const Mexico_Hucs_Layer = {
        id: 'mexico_hucs',
        type: "fill",
        source: 'mexico_hucs',
        "source-layer": `mexico_hucs`,
        paint: {
            "fill-outline-color": "white",
            "fill-opacity": opacity,
            "fill-color": `rgba(30, 160, 200, ${opacity * 0.2})`, 
        },
    };


    return (
        <>
            <Source
                id={'mexico_hucs'}
                type="vector"
                url={`mapbox://trueelementsmapping.mexico_hucs`}
            >
                {/* @ts-ignore */}
                <Layer {...Mexico_Hucs_Layer} />
            </Source>
        </>
    )
}

export default HUCsMexico