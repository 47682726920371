import { useState, useEffect } from 'react'
import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import {
    faPenToSquare,
    faCompactDisc
} from '@fortawesome/pro-solid-svg-icons';

const EditAdminRow = (props: any) => {

    const [active, setActive] = useState(false)

    const {
        setActiveIndex,
        currentIndex,
        activeIndex
    } = props

    if(active){ setActiveIndex(currentIndex) }


    const renderIcon = (input: any) => {
        if (input === false) {
            return <FontAwesomeIcon
                key={'adminrow'+input}
                style={{ cursor: 'pointer' }}
                icon={faPenToSquare}
                onClick={() => {
                    setActive(true)
                    setActiveIndex(currentIndex)
                }}
            />
        }

        if (input === true) {
            return <FontAwesomeIcon
                key={'adminrow'+input}
                style={{ cursor: 'pointer' }}
                icon={faCompactDisc}
                onClick={() => {
                    setActive(false)
                    setActiveIndex()
                }}
            />
        }
    }

    return <>{renderIcon(active)}</>
}

export default EditAdminRow