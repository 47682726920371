import { useEffect, useContext, useState, useRef } from 'react'
import { Layer, Source, Marker } from 'react-map-gl'
import { FillLayer } from 'mapbox-gl';
import { RefContext } from '../../../RefContext';
import { parse } from 'node-html-parser';
import { adjustHexBrightness } from "../../../features/utils";

const WRFCLayer = ({
    id,
    opacity,
    outlineColor,
    tileSet,
    sourceLayer,
    global,
}: any) => {

    const [properties, setProperties] = useState<any>()
    const [paint, setPaint] = useState([
        ['==', ['get', 'ISO3Code'], ''],
        ['interpolate', ['linear'], ['get', ''], 0, 'transparent'],
    ])

    const { currentRef } = useContext(RefContext)

    // @ts-ignore
    const mapInstance = currentRef && currentRef?.current

    
    const zoom = global.viewport.zoom;

    useEffect(() => {
        let interval:any;
        let propertiesList:any = [];

        const fetchData = async () => {
            try {
                if (mapInstance) {
                        
                    const features = await mapInstance.querySourceFeatures(
                        `${id}-layer`, 
                        { sourceLayer }
                    );

                    features.forEach((feature:any) => {
                        const { properties } = feature;
                        if (properties) {
                            propertiesList.push(properties);
                        }
                    });
                    setProperties(propertiesList);
                }
            } catch (error) {
                console.log(error);
            }
        };


        interval = setTimeout(fetchData, 3000);

        return () => clearTimeout(interval);
    }, [mapInstance, zoom]);


    const handleColor = (input:any) => {
        const num = parseFloat(input)

        if (num < 1.0 && num < 1.4) {
            return `${adjustHexBrightness('#E6F8C3', global.wrfcRiskBrightness)}`
        } else if (num > 1.4 && num < 1.8){
            return `${adjustHexBrightness('#E1F67B', global.wrfcRiskBrightness)}`
        } else if (num > 1.8 && num < 2.2) {
            return `${adjustHexBrightness('#F7F966', global.wrfcRiskBrightness)}`
        } else if (num > 2.2 && num < 2.6) {
            return `${adjustHexBrightness('#E8D23A', global.wrfcRiskBrightness)}`
        } else if (num > 2.6 && num < 3.0) {
            return `${adjustHexBrightness('#F5D229', global.wrfcRiskBrightness)}`
        } else if (num > 3.0 && num < 3.4) {
            return `${adjustHexBrightness('#F4AD23', global.wrfcRiskBrightness)}`
        } else if (num > 3.4 && num < 3.8) {
            return `${adjustHexBrightness('#F08C1E', global.wrfcRiskBrightness)}`
        } else if (num > 3.8 && num < 4.2) {
            return `${adjustHexBrightness('#F35E1D', global.wrfcRiskBrightness)}`
        } else if (num > 4.2 && num < 4.6) {
            return `${adjustHexBrightness('#E91A15', global.wrfcRiskBrightness)}`
        } else if (num > 4.6 && num < 5.0) {
            return `${adjustHexBrightness('#D91915', global.wrfcRiskBrightness)}`
        } else if (num > 5.0 && num < 5.8) {
            return `${adjustHexBrightness('#B31715', global.wrfcRiskBrightness)}`
        } else if (num > 5.8 && num < 6.6) {
            return `${adjustHexBrightness('#841814', global.wrfcRiskBrightness)}`
        } else {
            return 'transparent'
        }
    }


    const handlePaint = (input:any, value:any) => {
        const paintList = input.map((e: any) => {
            return [
                ['==', ['get', 'ISO3Code'], e.ISO3Code ? e.ISO3Code : null],
                ['interpolate', ['linear'], ['get', value], parseFloat(e[value]) ? parseFloat(e[value]) : 0, handleColor(e[value])],
            ]
        }) 

        setPaint(paintList.flat())
    }
    
    useEffect(() => {
        if(properties) {
            handlePaint(properties, global.wrfcValue)
        }
    },[
        properties,
        global.wrfcValue
    ])

    const WRFCLayer: FillLayer = {
        id: `${id}-layer`,
        type: "fill",
        "source-layer": `${sourceLayer}`,
        paint: {
            "fill-opacity": opacity,
            "fill-outline-color": `${outlineColor}`,
            'fill-color': [
                'case',
                ...paint,
                'transparent'
            ],
        },
    }

    return (
        <>
            <Source
                id={`${id}-layer`}
                type="vector"
                url={tileSet}
            >
                <Layer
                    {...WRFCLayer}
                />
            </Source>
        </>
    )
}

export default WRFCLayer;  
