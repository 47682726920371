import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Location, useLocation } from 'react-router-dom';
import Globals from '../types/GlobalsType';
import {
    QueryParamProvider,
    useQueryParam,
    StringParam,
    NumberParam,
    ArrayParam,
    BooleanParam,
    JsonParam,
    DateParam,
    withDefault,
} from 'use-query-params';
import { DateTime } from 'luxon';
import { message_hucrestriction, message_nodatalocation } from '../Constants';
import settings from '../settings.json'
import ISOs from '../ISO.json';
import axios from 'axios';
import queryString from 'query-string';
import ReportsConfigs from '../ReportsConfigs';
import { AppContext } from '../AppContext';
import { CatalogsContext } from '../CatalogsProvider';
import { RefContext } from '../RefContext';
import { setLocalReport } from '../features/utils';
import agent from '../api/agent';
import LayerConfig from '../LayerConfig';
import { LayerDataContext } from "../LayerDataContext";
import localForage from "localforage";

const QueryString = (props: any) => {

    // Two main functions:
    // 1) When a user-initiated change happens, update queryString
    // 2) When a new link is navigated, usually flagged by 'r=1', update the application with the parameters


    const { global } = props;
    const appContext = useContext(AppContext);
    const catalogsContext = useContext(CatalogsContext);
    const { currentLayerData, setCurrentLayerData } = useContext(LayerDataContext);

    const { currentRef } = useContext(RefContext);
    const location: Location = useLocation();

    // r -> when flagged true, check querystring and update state 
    const [qsr, setqsr] = useQueryParam('r', BooleanParam);
    //when flagged true, check querystring and update location
    const [qsrl, setqsrl] = useQueryParam('rl', BooleanParam); 

    //data
    const [qsdl, setqsdl] = useQueryParam('dl', BooleanParam);

    // geographical location
    const [qshuc, setqshuc] = useQueryParam('huc', StringParam);
    const [qscity, setqscity] = useQueryParam('city', StringParam);
    const [qsmct, setqsmct] = useQueryParam('mct', StringParam); //country
    const [qsms, setqsms] = useQueryParam('ms', StringParam); 
    const [qsmst, setqsmst] = useQueryParam('mst', StringParam);  //global.currentState
    const [qsiso, setqsiso] = useQueryParam('iso', StringParam); //country iso
    const [qsh12, setqsh12] = useQueryParam('h12', StringParam);

    //dashboard
    //dt dash template
    //dr

    // map layers
    const [qslo, setqslo] = useQueryParam('lo', BooleanParam); //overflow layer
    const [qslh, setqslh] = useQueryParam('lh', BooleanParam); // huc layer
    const [qslgw, setqslgw] = useQueryParam('lgw', BooleanParam);
    const [qslnh, setqslnh] = useQueryParam('lnh', BooleanParam);
    const [qslp, setqslp] = useQueryParam('lp', BooleanParam);
    const [qsldm, setqsldm] = useQueryParam('ldm', BooleanParam);
    const [qslz, setqslz] = useQueryParam('lz', BooleanParam);
    const [qslpa, setqslpa] = useQueryParam('lpa', BooleanParam);
    const [qslet, setqslet] = useQueryParam('let', BooleanParam);

    const [qsltcp, setqsltcp] = useQueryParam('ltcp', BooleanParam);

    const [qsldq, setqsldq] = useQueryParam('ldq', BooleanParam);
    const [qsltw, setqsltw] = useQueryParam('ltw', BooleanParam);
    const [qslww, setqslww] = useQueryParam('lww', BooleanParam);
    const [qslin, setqslin] = useQueryParam('lin', BooleanParam);

    // map layer array for additional layers
    const [qslay, setqslay] = useQueryParam('lay', ArrayParam);

    // scores array for markers
    const [qsmrk, setqsmrk] = useQueryParam('mrk', ArrayParam);

    // filters
    const [qsfd, setqsfd] = useQueryParam('fd', NumberParam);
    const [qsffl, setqsffl] = useQueryParam('ffl', StringParam);
    const [qsfl, setqsfl] = useQueryParam('fl', JsonParam);
    const [qsfys, setqsfys] = useQueryParam('fys', ArrayParam);
    const [qsfy, setqsfy] = useQueryParam('fy', NumberParam);
    const [qsfc, setqsfc] = useQueryParam('fc', ArrayParam);
    const [qsfdr, setqsfdr] = useQueryParam('fdr', ArrayParam);
    const [qsftr, setqsftr] = useQueryParam('ftr', StringParam);
    const [qsfct, setqsfct] = useQueryParam('fct', ArrayParam);
    const [qsfsi, setqsfsi] = useQueryParam('fsi', ArrayParam);
    const [qsfm, setqsfm] = useQueryParam('fm', ArrayParam);
    const [qsfz, setqsfz] = useQueryParam('fz', NumberParam);

    // filter date range
    const [qsfdra, setqsfdra] = useQueryParam('fdra', DateParam);
    const [qsfdrb, setqsfdrb] = useQueryParam('fdrb', DateParam);
    const [qsfds, setqsfds] = useQueryParam('fds', StringParam);

    // map
    const [qsmb, setqsmb] = useQueryParam('mb', JsonParam);
    const [qsmr, setqsmr] = useQueryParam('mr', BooleanParam);

    const [qsmv, setqsmv] = useQueryParam('mv', JsonParam);
    const [qsmgl, setqsmgl] = useQueryParam('mgl', BooleanParam);

    //ent, ccds

    useEffect(() => {
        checkQueryString();
        /*        if(window.location.hostname === 'dev.portal.trueelements.com'||window.location.hostname === 'portal.trueelements.com'){
                    const sheet = new CSSStyleSheet();
                    sheet.replaceSync("iframe#webpack-dev-server-client-overlay { display: none; }");
                    document.adoptedStyleSheets = [sheet];          
                }   */
    }, []);
    
    const handleLocationQuery = async (input: any = null, newCountry?:any) => {
        try {
            const { data } = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?&country=${getISOCode(global.currentCountry)}&limit=3&access_token=${settings.maboxKey}`)
            const [lng, lat] = data.features[0].center
            const location = data.features[0].place_name
            //console.log(location, lat, lng)

           if ((global.currentCountry === 'United States')||(newCountry==='US')) {
                if(lat && lng){
                    return agent.Utilities.LatLngToHuc8(lat, lng).then((res: any) => {
                        const responseBody = res.body;
                        if (responseBody === undefined) return global.notify(message_nodatalocation)
                        const responseHUC8 = responseBody[0].Huc8 ? responseBody[0].Huc8 : ''
                        global.setSearchCenter([lat, lng]);
                        updateLocation(location, responseHUC8, newCountry)
                    });
                }
            }
        } catch (e) {
          console.log(e)
        }
    }

    const getISOCode = (input: string) => {
        const countries = Object.values(ISOs)
        const isos = Object.keys(ISOs)
        const countryIndex = countries.findIndex(country => country === input)
        return isos[countryIndex]
    }

    const updateLocation = (newCity: any, newHUC:any, newCountry?:any) => {
        const reportConfig = ReportsConfigs.find(x => x.route == window.location.pathname);
        let routeData = {
          name: "Global Live Water Map",
          parentReportName: "Home",
          root: '/'
        } 
        if(reportConfig && reportConfig.parentReportName){
          routeData = {
            name: reportConfig.name,
            parentReportName: reportConfig.parentReportName,
            root: reportConfig.route
          }
        }
                              
        if(newCity && newHUC && appContext && appContext.updateContext){
          setLocalReport(window.location.pathname);  
          let hucValidated = newHUC;
          if(hucValidated.length === 12||hucValidated.length === 11){
              hucValidated = newHUC.substr(0, 8);
              let huc12 = hucValidated;
              if(huc12.length === 11){
                  huc12 = '0'+ huc12;
              }
              global.setGlobalHuc12(huc12)
              setqsh12(huc12)
              //setClickedHUC12
          }
          if(hucValidated.length === 7){
            hucValidated = '0'+ hucValidated;
          }
          setqshuc(hucValidated)
          appContext.updateContext(hucValidated, newCity, routeData, global.currentCountry); 
          global.setSelectedHUC8(hucValidated)
        }
    }

    useEffect(() => {
        if (qsrl === true) {
            setTimeout(() => {
                setqsrl(false);
            }, 2222);
            
            let searchParse = queryString.parse(window.location.search);  
            const newCity = searchParse["city"] as string;
            const newHUC = searchParse["huc"] as string;
            const newCountry = searchParse["iso"] as string;
            if(!newCity || !newHUC){
                if(newCity){
                    handleLocationQuery(newCity, newCountry);
                }
            } else {
                updateLocation(newCity, newHUC, newCountry)
            }
        }
    }, [qsrl]);

    useEffect(() => {
        if (qscity !== appContext.currentLocation) {
            setqscity(appContext.currentLocation);
        }
        if (appContext && appContext.selectedHUC8 && appContext.selectedHUC8.length>5 && qshuc !== appContext.selectedHUC8) {
            setqshuc(appContext.selectedHUC8);
        }

        if (qsr === true) {            
            setTimeout(() => {
                setqsr(false);
                checkQueryString();
            }, 2222);
        }
    }, [appContext]);

    useEffect(() => {  
        if(currentLayerData && currentLayerData.hasOwnProperty("tableColumns")){           
            setTimeout(() => {
                setqsdl(true)
            }, 1111);
            localForage.setItem("currentLayerData", currentLayerData)
        }
    }, [currentLayerData]);

    useEffect(() => {
        checkLayer('NSG', global.NSG)
    }, [global.NSG])
    useEffect(() => {
        checkLayer('MEC', global.MEC)
    }, [global.MEC])
    useEffect(() => {
        checkLayer('FHBP', global.FHBP)
    }, [global.FHBP])
    useEffect(() => {
        checkLayer('CWALayer', global.CWALayer)
    }, [global.CWALayer])
    useEffect(() => {
        checkLayer('OWQPDemo', global.OWQPDemo)
    }, [global.OWQPDemo])
    useEffect(() => {
        checkLayer('allFireData', global.allFireData)
    }, [global.allFireData])
    useEffect(() => {
        checkLayer('SkyFiAustin', global.SkyFiAustin)
    }, [global.SkyFiAustin])
    useEffect(() => {
        checkLayer('SkyFiWhiting', global.SkyFiWhiting)
    }, [global.SkyFiWhiting])
    useEffect(() => {
        checkLayer('CEJST', global.CEJST)
    }, [global.CEJST])
    useEffect(() => {
        checkLayer('USGSHuc12', global.USGSHuc12)
    }, [global.USGSHuc12])
    useEffect(() => {
        checkLayer('USGSWUC', global.USGSWUC)
    }, [global.USGSWUC])
    useEffect(() => {
        checkLayer('OABSPH', global.OABSPH)
    }, [global.OABSPH])
    useEffect(() => {
        checkLayer('OABSSG', global.OABSSG)
    }, [global.OABSSG])
    useEffect(() => {
        checkLayer('OABSPK', global.OABSPK)
    }, [global.OABSPK])
    useEffect(() => {
        checkLayer('OABSCA', global.OABSCA)
    }, [global.OABSCA])
    useEffect(() => {
        checkLayer('OABSMX', global.OABSMX)
    }, [global.OABSMX])
    useEffect(() => {
        checkLayer('OABSID', global.OABSID)
    }, [global.OABSID])
    useEffect(() => {
        checkLayer('OABRPK', global.OABRPK)
    }, [global.OABRPK])
    useEffect(() => {
        checkLayer('OABRMX', global.OABRMX)
    }, [global.OABRMX])
    useEffect(() => {
        checkLayer('OABRSG', global.OABRSG)
    }, [global.OABRSG])
    useEffect(() => {
        checkLayer('OABRCA', global.OABRCA)
    }, [global.OABRCA])
    useEffect(() => {
        checkLayer('OABRPH', global.OABRPH)
    }, [global.OABRPH])  
    useEffect(() => {
        checkLayer('OABRID', global.OABRID)
    }, [global.OABRID])
    useEffect(() => {
        checkLayer('NationalWaterImpairements', global.NationalWaterImpairements)
    }, [global.NationalWaterImpairements])
    useEffect(() => {
        checkLayer('OGWTD', global.OGWTD)
    }, [global.OGWTD])
    useEffect(() => {
        checkLayer('OABID', global.OABID)
    }, [global.OABID])
    useEffect(() => {
        checkLayer('OABUS', global.OABUS)
    }, [global.OABUS])
    useEffect(() => {
        checkLayer('OABPH', global.OABPH)
    }, [global.OABPH])
    useEffect(() => {
        checkLayer('OABMX', global.OABMX)
    }, [global.OABMX])
    useEffect(() => {
        checkLayer('customLayer1', global.customLayer1)
    }, [global.customLayer1])
    useEffect(() => {
        checkLayer('customLayer2', global.customLayer2)
    }, [global.customLayer2])
    useEffect(() => {
        checkLayer('customLayer3', global.customLayer3)
    }, [global.customLayer3])
    useEffect(() => {
        checkLayer('customLayer4', global.customLayer4)
    }, [global.customLayer4])
    useEffect(() => {
        checkLayer('customLayer5', global.customLayer5)
    }, [global.customLayer5])
    useEffect(() => {
        checkLayer('customLayer6', global.customLayer6)
    }, [global.customLayer6])
    useEffect(() => {
        checkLayer('customLayer7', global.customLayer7)
    }, [global.customLayer7])
    useEffect(() => {
        checkLayer('customLayer8', global.customLayer8)
    }, [global.customLayer8])
    useEffect(() => {
        checkLayer('customLayer9', global.customLayer9)
    }, [global.customLayer9])
    useEffect(() => {
        checkLayer('customLayer10', global.customLayer10)
    }, [global.customLayer10]) 
    useEffect(() => {
        checkLayer('customLayer11', global.customLayer11)
    }, [global.customLayer11])
    useEffect(() => {
        checkLayer('customLayer12', global.customLayer12)
    }, [global.customLayer12])
    useEffect(() => {
        checkLayer('customLayer13', global.customLayer13)
    }, [global.customLayer13])
    useEffect(() => {
        checkLayer('customLayer14', global.customLayer14)
    }, [global.customLayer14])
    useEffect(() => {
        checkLayer('customLayer15', global.customLayer15)
    }, [global.customLayer15])
    useEffect(() => {
        checkLayer('customLayer16', global.customLayer16)
    }, [global.customLayer16])
    useEffect(() => {
        checkLayer('customLayer17', global.customLayer17)
    }, [global.customLayer17])
    useEffect(() => {
        checkLayer('customLayer18', global.customLayer18)
    }, [global.customLayer18])
    useEffect(() => {
        checkLayer('customLayer19', global.customLayer19)
    }, [global.customLayer19])
    useEffect(() => {
        checkLayer('customLayer20', global.customLayer20)
    }, [global.customLayer20]) 
    useEffect(() => {
        checkLayer('MoBiPSR', global.MoBiPSR)
    }, [global.MoBiPSR])
    useEffect(() => {
        checkLayer('MoBiSRAll', global.MoBiSRAll)
    }, [global.MoBiSRAll])
    useEffect(() => {
        checkLayer('MoBiPI', global.MoBiPI)
    }, [global.MoBiPI])
    useEffect(() => {
        checkLayer('MoBiSRA', global.MoBiSRA)
    }, [global.MoBiSRA])
    useEffect(() => {
        checkLayer('MoBiV', global.MoBiV)
    }, [global.MoBiV])
    useEffect(() => {
        checkLayer('indoWatersheds', global.indoWatersheds)
    }, [global.indoWatersheds])
    useEffect(() => {
        checkLayer('WaterConflict', global.WaterConflict)
    }, [global.WaterConflict])
    useEffect(() => {
        checkLayer('AAV4RiverineFloodRisk', global.AAV4RiverineFloodRisk)
    }, [global.AAV4RiverineFloodRisk])
    useEffect(() => {
        checkLayer('AAV4CoastalFloodRisk', global.AAV4CoastalFloodRisk)
    }, [global.AAV4CoastalFloodRisk])
    useEffect(() => {
        checkLayer('AAV4WaterStress', global.AAV4WaterStress)
    }, [global.AAV4WaterStress])
    useEffect(() => {
        checkLayer('rainfallForecast', global.rainfallForecast)
    }, [global.rainfallForecast])
    useEffect(() => {
        checkLayer('cvi', global.cvi)
    }, [global.cvi])
    useEffect(() => {
        checkLayer('toggleHighwayStyle', global.toggleHighwayStyle)
    }, [global.toggleHighwayStyle])
    useEffect(() => {
        checkLayer('impairedLakes', global.impairedLakes)
    }, [global.impairedLakes])
    useEffect(() => {
        checkLayer('deloitteFema', global.deloitteFema)
    }, [global.deloitteFema])
    useEffect(() => {
        checkLayer('deloitteFemaZones', global.deloitteFemaZones)
    }, [global.deloitteFemaZones])
    useEffect(() => {
        checkLayer('gemstatPH', global.gemstatPH)
    }, [global.gemstatPH])
    useEffect(() => {
       checkLayer('outflowsSelected', global.outflowsSelected);   
    }, [global.outflowsSelected]);

    useEffect(() => {
       
            setqslgw(global.gwNitrateAssessmentSelected);
       
    }, [global.gwNitrateAssessmentSelected]);
    useEffect(() => {
        setqslp(global.pollutionSelected);
    }, [global.pollutionSelected]);
    useEffect(() => {
        setqsldm(global.droughtMonitoringSelected);
    }, [global.droughtMonitoringSelected]);
    useEffect(() => {
        setqslpa(global.principalAquifersSelected);
    }, [global.principalAquifersSelected]);
    useEffect(() => {
        setqsltcp(global.terraClimateLayerSelected);
    }, [global.terraClimateLayerSelected]);
    useEffect(() => {
        setqslet(global.evapotranspirationSelected);
    }, [global.evapotranspirationSelected]);


    useEffect(() => {
        setqsltw(global.twitterSelected);
    }, [global.twitterSelected]);

    
    useEffect(() => {
       
            setqslh(global.hucsSelected);
    
    }, [global.hucsSelected]);
    useEffect(() => {
      
            setqslz(global.zipSelected);
      
    }, [global.zipSelected]);



    // layer collection, set querystring on change

    useEffect(() => {
        checkLayer('OSUWells', global.OSUWells);
    }, [global.OSUWells]);
    useEffect(() => {
        checkLayer('UmatillaWellCombinedBoudaries', global.UmatillaWellCombinedBoudaries);
    }, [global.UmatillaWellCombinedBoudaries]);
    useEffect(() => {
        checkLayer('UmatillaWellPoints', global.UmatillaWellPoints);
    }, [global.UmatillaWellPoints]);
    useEffect(() => {
        checkLayer('OWiFrame', global.OWiFrame);
    }, [global.OWiFrame]);
    useEffect(() => {
        checkLayer('SIS', global.SIS);
    }, [global.SIS]);
    useEffect(() => {
        checkLayer('UmatillaWellCombinedPolygons', global.UmatillaWellCombinedPolygons);
    }, [global.UmatillaWellCombinedPolygons]);
    useEffect(() => {
        checkLayer('Hydrobasins', global.Hydrobasins);
    }, [global.Hydrobasins]);
    useEffect(() => {
        checkLayer('WaterServiceAreaBoundariesV2', global.WaterServiceAreaBoundariesV2);
    }, [global.WaterServiceAreaBoundariesV2]);


    useEffect(() => {
        checkLayer('wildFireBoundaries', global.wildFireBoundaries);
    }, [global.wildFireBoundaries]);

    useEffect(() => {
        checkLayer('domesticWellsSelected', global.domesticWellsSelected);
    }, [global.domesticWellsSelected]);

    useEffect(() => {
        checkLayer('EJDemoSelected', global.EJDemoSelected);
    }, [global.EJDemoSelected]);

    useEffect(() => {
        checkLayer('EJLowIncomeSelected', global.EJLowIncomeSelected);
    }, [global.EJLowIncomeSelected]);
    useEffect(() => {
        checkLayer('EJPOCSelected', global.EJPOCSelected);
    }, [global.EJPOCSelected]);
    useEffect(() => {
        checkLayer('EJUnemploymentSelected', global.EJUnemploymentSelected);
    }, [global.EJUnemploymentSelected]);
    useEffect(() => {
        checkLayer('EJLingIsoSelected', global.EJLingIsoSelected);
    }, [global.EJLingIsoSelected]);
    useEffect(() => {
        checkLayer('EJLessHSSelected', global.EJLessHSSelected);
    }, [global.EJLessHSSelected]);
    useEffect(() => {
        checkLayer('EJFiveAndUnderAgeSelected', global.EJFiveAndUnderAgeSelected);
    }, [global.EJFiveAndUnderAgeSelected]);
    useEffect(() => {
        checkLayer('EJSixtyFourAndOverSelected', global.EJSixtyFourAndOverSelected);
    }, [global.EJSixtyFourAndOverSelected]);
    useEffect(() => {
        checkLayer('EJWasteWaterDischarge', global.EJWasteWaterDischarge);
    }, [global.EJWasteWaterDischarge]);
    useEffect(() => {
        checkLayer('HUCsMexico', global.HUCsMexico);
    }, [global.HUCsMexico]);
    useEffect(() => {
        checkLayer('EJUndergroundStorageTanks', global.EJUndergroundStorageTanks);
    }, [global.EJUndergroundStorageTanks]);
    useEffect(() => {
        checkLayer('EJHazardousWaste', global.EJHazardousWaste);
    }, [global.EJHazardousWaste]);
    useEffect(() => {
        checkLayer('EJRMP', global.EJRMP);
    }, [global.EJRMP]);
    useEffect(() => {
        checkLayer('EJSuperfund', global.EJSuperfund);
    }, [global.EJSuperfund]);

    useEffect(() => {
        checkLayer('GBIF', global.GBIF);
    }, [global.GBIF]);



    useEffect(() => {
        checkLayer('WaterSystemBoundaries', global.waterSystemBoundariesSelected);
    }, [global.waterSystemBoundariesSelected]);
    useEffect(() => {
        checkLayer('WaterSystemBoundaryTier1', global.waterSystemBoundaryTier1);
    }, [global.waterSystemBoundaryTier1]);
    useEffect(() => {
        checkLayer('WaterSystemBoundaryTier2', global.waterSystemBoundaryTier2);
    }, [global.waterSystemBoundaryTier2]);
    useEffect(() => {
        checkLayer('WaterSystemBoundaryTier3', global.waterSystemBoundaryTier3);
    }, [global.waterSystemBoundaryTier3]);
    useEffect(() => {
        checkLayer('aqueductAtlas', global.aqueductAtlas);
    }, [global.aqueductAtlas]);
    useEffect(() => {
        checkLayer('aqueductAtlasCoastalFlood', global.aqueductAtlasCoastalFlood);
    }, [global.aqueductAtlasCoastalFlood]);
    useEffect(() => {
        checkLayer('aqueductAtlasWaterStress', global.aqueductAtlasWaterStress);
    }, [global.aqueductAtlasWaterStress]);
    useEffect(() => {
        checkLayer('aqueductAtlasRiverine', global.aqueductAtlasRiverine);
    }, [global.aqueductAtlasRiverine]);

    useEffect(() => {
        checkLayer('FEMAZones', global.femaZones);
    }, [global.femaZones]);
    useEffect(() => {
        checkLayer('FEMABoundaries', global.femaBoundaries);
    }, [global.femaBoundaries]);
    useEffect(() => {
        checkLayer('NOAALayer', global.NOAALayer);
    }, [global.NOAALayer]);
    useEffect(() => {
        checkLayer('NOAASeaLevelRise', global.NOAASeaLevelRise);
    }, [global.NOAASeaLevelRise]);
    useEffect(() => {
        checkLayer('EPASuperfundSites', global.EPASuperfundSites);
    }, [global.EPASuperfundSites]);

    useEffect(() => {
        checkLayer('EPOLayer', global.EPOLayer);
    }, [global.EPOLayer]);
    useEffect(() => {
        checkLayer('WDPA', global.WDPA);
    }, [global.WDPA]);
    useEffect(() => {
        checkLayer('resFEMA', global.resFEMA);
    }, [global.resFEMA]);
    useEffect(() => {
        checkLayer('biaLayer', global.biaLayer);
    }, [global.biaLayer]);
    useEffect(() => {
        checkLayer('eddi', global.eddi);
    }, [global.eddi]);
    useEffect(() => {
        checkLayer('NOAAMix', global.NOAAMix);
    }, [global.NOAAMix]);
    useEffect(() => {
        checkLayer('nwshazard', global.nwshazard);
    }, [global.nwshazard]);        
    useEffect(() => {
        checkLayer('waterQualityPortal', global.waterQualityPortal);
    }, [global.waterQualityPortal]);
    useEffect(() => {
        checkLayer('EastPalestineOutflows', global.eastPalestineOutflows);
    }, [global.EastPalestineOutflows]);
    useEffect(() => {
        checkLayer('I10Huc12s', global.I10Huc12s);
    }, [global.I10Huc12s]);
    useEffect(() => {
        checkLayer('USCounties', global.usCounties);
    }, [global.usCounties]);

    useEffect(() => {
        checkLayer('austrailianPrincipalHydrology', global.ausPrinHydro);
    }, [global.ausPrinHydro])
    useEffect(() => {
        checkLayer('ANGISLower', global.ANGISLower);
    }, [global.ANGISLower])
    useEffect(() => {
        checkLayer('ANGISMid', global.ANGISMid);
    }, [global.ANGISMid])
    useEffect(() => {
        checkLayer('ANGISUpper', global.ANGISUpper);
    }, [global.ANGISUpper])
    useEffect(() => {
        checkLayer('ABWDLayer', global.ABWDLayer)
    }, [global.ABWDLayer]);

    useEffect(() => {
        checkLayer('ausGemstat', global.ausGemstat)
    }, [global.ausGemstat])

    useEffect(() => {
        checkLayer('gemstat', global.gemstat)
    }, [global.gemstat])

    useEffect(() => {
        checkLayer('philippinesRivers', global.philippinesRivers);
    }, [global.philippinesRivers]);

    useEffect(() => {
        checkLayer('philippinesRiverBasins', global.philippinesRiverBasins);
    }, [global.philippinesRiverBasins]);

    useEffect(() => {
        checkLayer('philRegions', global.philRegions);
    }, [global.philRegions]);

    useEffect(() => {
        checkLayer('phFloodHazard', global.phFloodHazard);
    }, [global.phFloodHazard]);

    useEffect(() => {
        checkLayer('persistentContaminants', global.persistentContaminants);
    }, [global.persistentContaminants]);
    useEffect(() => {
        checkLayer('modPersistentContaminants', global.modPersistentContaminants);
    }, [global.modPersistentContaminants]);

    useEffect(() => {
        checkLayer('volPersistentContaminants', global.volPersistentContaminants);
    }, [global.volPersistentContaminants]);

    useEffect(() => {
        checkLayer('wrfcRisk', global.wrfcRisk);
    }, [global.wrfcRisk]);
    useEffect(() => {
        checkLayer('FBFIB', global.FBFIB);
    }, [global.FBFIB])
    useEffect(() => {
        checkLayer('AppleLocationData', global.AppleLocationData);
    }, [global.AppleLocationData])
    useEffect(() => {
        checkLayer('waterWatchSites', global.waterWatchSites);
    }, [global.waterWatchSites]);
    useEffect(() => {
        checkLayer('MBFIBDT', global.MBFIBDT);
    }, [global.MBFIBDT]);
    useEffect(() => {
        checkLayer('AGWaterTrueQI', global.agWaterTrueQI);
    }, [global.agWaterTrueQI]);
    useEffect(() => {
        checkLayer('StormWaterTrueQI', global.stormWaterTrueQI);
    }, [global.stormWaterTrueQI]);
    useEffect(() => {
        checkLayer('SurfacewaterTrueQI', global.surfacewaterTrueQI);
    }, [global.surfacewaterTrueQI]);
    useEffect(() => {
        checkLayer('h303DTrueQI', global.h303DTrueQI);
    }, [global.h303DTrueQI]);

    useEffect(() => {
        checkLayer('indoHucs', global.indoHucs);
    }, [global.indoHucs]);
    useEffect(() => {
        setqsldq(global.drinkingWaterQualitySelected);
    }, [global.drinkingWaterQualitySelected]);
    useEffect(() => {
        setqslww(global.wastewaterSelected);
    }, [global.wastewaterSelected]);
    useEffect(() => {
        setqslin(global.industrySelected);
    }, [global.industrySelected]);


    // filters, set querystring on change
    useEffect(() => {
        setqsfd(global.filterDaysSelected);
    }, [global.filterDaysSelected]);
    useEffect(() => {
        setqsffl(global.filter1Selected);
    }, [global.filter1Selected]);
    useEffect(() => {//@ts-ignore
        if(global.filterLocationsSelected && global.filterLocationsSelected.length===0){
            setqsfl(null);
        } else {
            setqsfl(global.filterLocationsSelected);
        }
    }, [global.filterLocationsSelected]);
    useEffect(() => {
        setqsfys(global.yearsSelected);
    }, [global.yearsSelected]);
    useEffect(() => {
        setqsfc(global.chemicalsSelected);
    }, [global.chemicalsSelected]);
    useEffect(() => {
        if (global.dateRangeSelected && global.dateRangeSelected[0]) {
            const dt = new Date(global.dateRangeSelected[0])
            setqsfdra(dt);
        }        
        if (global.dateRangeSelected && global.dateRangeSelected[1] ) {
            const dt = new Date(global.dateRangeSelected[1])
            if(qsfdrb){
                let a1 = DateTime.fromJSDate(global.dateRangeSelected[1]);
                let a2 = DateTime.fromJSDate(qsfdrb);
                if(!a1.hasSame(a2, 'day')){
                    setqsfdrb(dt);
                }
            } else {
                setqsfdrb(dt);
            }
        }
    }, [global.dateRangeSelected]);
    useEffect(() => {   
        setqsfds(global.singleDate);
    }, [global.singleDate]);
    useEffect(() => {
        setqsftr(global.timeReferenceUnit);
    }, [global.timeReferenceUnit]);
    useEffect(() => {
        setqsfct(global.contaminantFilters);
    }, [global.contaminantFilters]);
    useEffect(() => {
        setqsfsi(global.filterSICCodesSelected);
    }, [global.filterSICCodesSelected]);
    useEffect(() => {
        setqsfm(global.selectedMeasurementFilterValue);
    }, [global.selectedMeasurementFilterValue]);
    useEffect(() => {
        setqsfy(global.selectedYearFilter);
    }, [global.selectedYearFilter]);
    /*     useEffect(() => {
            setqsfz(global.zipSelected);
        }, [global.zipSelected]); */

    // map and location
/*     useEffect(() => {
         setqsmv(global.viewport);
     }, [global.viewport]);*/

    useEffect(() => {
        setqsmgl(global.globeView);
    }, [global.globeView]);

    useEffect(() => {
        setqsmct(global.currentCountry);
    }, [global.currentCountry]);

    useEffect(() => {
        setqsms(global.selectedState);
    }, [global.selectedState]);

    useEffect(() => {
        setqsmst(global.currentState);
    }, [global.currentState]);

    useEffect(() => {
        setqsh12(global.globalHuc12);
    }, [global.globalHuc12]);


    // Build array of active layers
    const checkLayer = (layername: string, layerselected: boolean) => {
        if (layerselected) {
            //setTimeout(() => { 
            addLayer(layername);
            //}, 555);            
        } else {
            //setTimeout(() => { 
            removeLayer(layername);
            //}, 555);              
        }
    }

    const addLayer = (layername: any) => {
        if (qslay) {
            if (qslay.length < 0) {
                setqslay([layername]);
            }
            else {
                if (qslay.indexOf(layername) < 0) {
                    const layerFilters = qslay;
                    layerFilters.push(layername)
                    setqslay(layerFilters);
                }
            }
        } else {
            setqslay([layername]);
        }
    }

    const removeLayer = (layername: any) => {
        if (qslay && layername) {
            if (qslay.length > 0) {
                if (qslay.indexOf(layername) > -1) {
                    const layerFilters = qslay;
                    layerFilters.splice(qslay.indexOf(layername), 1)
                    setqslay(layerFilters);
                }
            } else {
                setqslay([]);
            }
        }
    }
 
    const onLayer = (layername: any) => {
        if (layername) {
            const setBool = true;
            const layer = LayerConfig.find((lay: any) => lay.selected.toLowerCase() === layername.toLowerCase())
            if(layer){
                console.log(layer)
                let _global = global as any;
                if(_global[layer.setSelected]){
                   _global[layer.setSelected](setBool)
                } 
            } else {
                switch (layername) {
                    case 'MEC':
                        global.setMEC(setBool)
                        break;
                    case 'MBFIBDT':
                        global.setMBFIBDT(setBool)
                        break;
                    case 'NSG':
                        global.setNSG(setBool)
                        break;
                    case 'FBFIB':
                        global.setFBFIB(setBool)
                        break;
                    case 'FHBP':
                        global.setFHBP(setBool)
                        break;
                    case 'CWALayer':
                        global.setCWALayer(setBool)
                        break;
                    case 'OWQPDemo':
                        global.setOWQPDemo(setBool)
                        break
                    case 'AppleLocationData':
                        global.setAppleLocationData(setBool)
                        break

                    case 'indoHucs':
                        global.setIndoHucs(true);
                        break
                    case 'indoWatersheds':
                        global.setIndoWatersheds(true);
                        break

                    case 'cvi':
                        global.setCVI(true);
                        break

                    case 'deloitteFemaZones':
                        global.setDeloitteFemaZones(true);
                        break
                    case 'deloitteFema':
                        global.setDeloitteFema(true);
                        break
                    case 'volPersistentContaminants':
                        global.setVolPersistentContaminants(true);
                        break
                    case 'modPersistentContaminants':
                        global.setModPersistentContaminants(true);
                        break
                    case 'persistentContaminants':
                        global.setPersistentContaminants(true);
                        break
                    case 'wrfcRisk':
                        global.setWRFCRisk(true);
                        break
                    case 'ausGemstat':
                        global.setAusGemstat(true);
                        break

                    case 'officialTweetsSelected':
                        global.setofficialTweetsSelected(true);
                        break;
                    case 'unofficialTweetsSelected':
                        global.setUnofficialTweetsSelected(true);
                        break;


                    case 'FEMAZones' || 'femaZones':
                        global.setFemaZones(true);
                        break;
                    case 'FEMABoundaries' || 'femaBoundaries':
                        global.setFemaBoundaries(true);
                        break;
                    case 'NOAALayer':
                        global.setNOAALayer(true);
                        break;
                    case 'eddi':
                        global.setEddi(true);
                        break
                    case 'NOAAMix':
                        global.setNOAAMix(true);
                        break
                    case 'waterQualityPortal':
                        global.setWaterQualityPortal(true);
                        break
                    case 'resFEMA':
                        global.setResFEMAs(true);
                        break;
                    case 'I10Huc12s':
                        global.setI10Huc12s(true);
                        break;
                    case 'EastPalestineOutflows' || 'eastPalestineOutflows':
                        global.setEastPalestineOutflows(true);
                        break;
                    case 'biaLayer':
                        global.setBIALayer(true);
                        break;
                    case 'USCounties' || 'usCounties':
                        global.setUSCounties(true);
                        break;
                    case 'philippinesRivers':
                        global.philippinesRivers(true);
                        break;
                    case 'philippinesRiverBasins':
                        global.setPhilippinesRiverBasins(true);
                        break;


                    case 'customLayer1':
                        global.setCustomLayer1(true);
                        break
                    case 'customLayer2':
                        global.setCustomLayer2(true);
                        break
                    case 'customLayer3':
                        global.setCustomLayer3(true);
                        break
                    case 'customLayer4':
                        global.setCustomLayer4(true);
                        break
                    case 'customLayer5':
                        global.setCustomLayer5(true);
                        break
                    case 'customLayer6':
                        global.setCustomLayer6(true);
                        break
                    case 'customLayer7':
                        global.setCustomLayer7(true);
                        break
                    case 'customLayer8':
                        global.setCustomLayer8(true);
                        break
                    case 'customLayer9':
                        global.setCustomLayer9(true);
                        break
                    case 'customLayer10':
                        global.setCustomLayer10(true);
                        break    
                    case 'customLayer11':
                        global.setCustomLayer11(true);
                        break  
                    case 'customLayer13':
                        global.setCustomLayer13(true);
                        break
                    case 'customLayer14':
                        global.setCustomLayer14(true);
                        break
                    case 'customLayer15':
                        global.setCustomLayer15(true);
                        break
                    case 'customLayer16':
                        global.setCustomLayer16(true);
                        break
                    case 'customLayer17':
                        global.setCustomLayer17(true);
                        break
                    case 'customLayer18':
                        global.setCustomLayer18(true);
                        break
                    case 'customLayer19':
                        global.setCustomLayer19(true);
                        break
                    case 'customLayer20':
                        global.setCustomLayer20(true);
                        break
                    
                    case 'toggleHighwayStyle':
                        global.setToggleHighwayStyle(true);
                        break
                    
 
                    
                    case 'AGWaterTrueQI' || 'agWaterTrueQI':
                        global.setAgWaterTrueQI(true);
                        break;
                    case 'StormWaterTrueQI' || 'stormWaterTrueQI':
                        global.setStormWaterTrueQI(true);
                        break;
                    case 'SurfacewaterTrueQI' || 'surfacewaterTrueQI':
                        console.log('SurfacewaterTrueQI')
                        global.setSurfacewaterTrueQI(true);
                        break;
                    case 'h303DTrueQI' || 'H303DTrueQI':
                        global.setH303DTrueQI(true);
                        break;
                    }

            }
        }
    }

    const delayStart = (setStart: any, queryValue: any) => {
        setTimeout(() => {
            setStart(queryValue);
        }, 2222);
    }

    // Activate filters and explicit map layers not part of map layer array
    const checkQueryString = () => {
        if (qslo && qslo !== global.outflowsSelected) {
            delayStart(global.setOutflowsSelected, qslo);
        }
        if (qslh && qslh !== global.hucsSelected) {
            delayStart(global.setHucsSelected, qslh);
        }
        if (qslz && qslz !== global.zipSelected) {
            global.setZipSelected(qslz)
        }
        if (qslgw && qslgw !== global.gwNitrateAssessmentSelected) {
            global.setGwNitrateAssessmentSelected(qslgw)
        }
        if (qslnh && qslnh !== global.nhdSelected) {
            global.setNhdSelected(qslnh)
        }
        if (qslp && qslp !== global.pollutionSelected) {
            global.setPollutionSelected(qslp)
        }
        if (qsldm && qsldm !== global.droughtMonitoringSelected) {
            global.setDroughtMonitoringSelected(qsldm)
        }
        if (qslpa && qslpa !== global.principalAquifersSelected) {
            global.setPrincipalAquifersSelected(qslpa)
        }
        if (qsltcp && qsltcp !== global.terraClimateLayerSelected) {
            global.setTerraClimateLayerSelected(qsltcp)
        }
        if (qslet && qslet !== global.evapotranspirationSelected) {
            global.setEvapotranspirationSelected(qslet)
        }

        if (qsldq && qsldq !== global.drinkingWaterQualitySelected) {
            global.setDrinkingWaterQualitySelected(qsldq)
        }
        if (qslww && qslww !== global.wastewaterSelected) {
            global.setWastewaterSelected(qslww)
        }
        if (qslin && qslin !== global.industrySelected) {
            global.setIndustrySelected(qslin)
        }
        if (qsltw && qsltw !== global.twitterSelected) {
            global.setTwitterSelected(qsltw)
        }

        if (qslay) {
            if (qslay) {
                if (qslay.length > 0) {
                    const layerFilters = qslay;
                    for (var i = 0; i < layerFilters.length; i++) {
                        const _lf = layerFilters[i];
                        setTimeout(() => {
                            onLayer(_lf);
                        }, 3333);
                    }
                }
            }
        }


        if (qsffl && qsffl !== global.filter1Selected) {
            global.setfilter1Selected(qsffl);
            setTimeout(() => {
                global.setfilter1Selected(qsffl);
            }, 2222);
        }

        if (qsfd && qsfd !== global.filterDaysSelected) {
            global.setfilterDaysSelected(qsfd);
            setTimeout(() => {
                global.setfilterDaysSelected(qsfd);    
            }, 2222);
        }

        if (qsfdra && qsfdra !== global.dateRangeSelected[0] && qsfdrb) {
            if (global.dateRangeSelected[1] && qsfdrb !== global.dateRangeSelected[1]) {
                global.setDateRangeSelected([qsfdra, qsfdrb]);
                setTimeout(() => {
                    global.setDateRangeSelected([qsfdra, qsfdrb]);
                }, 2222);
            }
        }

        if (qsfds && qsfds !== global.singleDate) {
            global.setSingleDate(qsfds);
            setTimeout(() => {
                global.setSingleDate(qsfds);    
            }, 2222);
        }

        if (qsfys && qsfys !== global.yearsSelected) {
            global.setYearsSelected(qsfys);
            setTimeout(() => {
                global.setYearsSelected(qsfys);
            }, 2222);
        }

        if (qsfl && qsfl !== global.filterLocationsSelected) {
            //global.setfilterLocationsSelected(qsfl);

            setTimeout(() => {
                global.setfilterLocationsSelected(qsfl);
            }, 2222);
        }

        if (qsfct && qsfct !== global.contaminantFilters) {
          
            setTimeout(() => {
                global.setContaminantFilters(qsfct);
            }, 2222);
        }

        if (qsfsi && qsfsi !== global.filterSICCodesSelected) {
      
            setTimeout(() => {
                global.setFilterSICCodesSelected(qsfsi);
            }, 2222);
        }

        if (qsfm && qsfm !== global.selectedMeasurementFilterValue) {
        
            setTimeout(() => {
                global.setSelectedMeasurementFilterValue(qsfm);
            }, 2222);
        }

        if (qsfc && qsfc !== global.chemicalsSelected) {
          
            setTimeout(() => {
                global.setChemicalsSelected(qsfc);
            }, 2222);
        }

        if (qsfy && qsfy !== global.selectedYearFilter) {
          
            setTimeout(() => {
                global.setSelectedYearFilter(qsfy);
            }, 2222);
        }

        /*         if (qsfz&& qsfz!==global.zipSelected){
                    //global.setSelectedYearFilter(qsfy);
                    setTimeout(() => {
                        global.setZipSelected(qsfz);
                      }, 2222);
                }    */

        if (qsmct && qsmct !== global.currentCountry) {
            console.log(global.currentCountry, qsmct, qsiso)
            global.setCurrentCountry(qsmct);
        }

        if (qsmst && qsmst !== global.currentState) {
            console.log(global.currentState, qsmst)
            global.setCurrentState(qsmst);
        }

        if (qsh12 && qsh12 !== global.globalHuc12) {
            global.setGlobalHuc12(qsh12);
        }

        // map viewport
        if (qsmv && qsmv !== global.viewport) {
            const nv = { ...qsmv };
            if (location.pathname === '/SurfaceWaterFlowRate') {
                setTimeout(() => {
                    // @ts-ignore
                    const { current } = currentRef;
                    global.setViewport(nv);

                    if (current && nv.latitude) {
                        current.getMap().jumpTo({
                            center: [nv.longitude, nv.latitude],
                            zoom: nv.zoom
                        });
                    }
                }, 3333);
            }
        }

        if (qsmgl && qsmgl !== global.globeView) {
            setTimeout(() => {
                global.setGlobeView(qsmgl);
            }, 3333);
        }
 
        if(qsdl && qsdl===true){
            localForage.getItem('currentLayerData', function(err, value) {
               if(value){
                    setCurrentLayerData && value && setCurrentLayerData(value);
               }
            });
        }


    }

    return null;
}



export default QueryString;
