import {
  useState,
  useContext,
  useEffect
} from 'react'
import {
  Nav,
  Container,
  Navbar,
  NavDropdown,
  Image,
  Button,
  ButtonGroup,
  Dropdown,
  NavItem,
  NavLink,
  Badge
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoon,
  faQuestion,
  faGear,
  faBell,
  faPowerOff,
  faEnvelope,
  faMicrochipAi
} from '@fortawesome/pro-solid-svg-icons';
import agent from '../api/agent';
import { v4 as uuid } from 'uuid';
import { AppContext } from '../AppContext';
import { Location, useLocation, useNavigate } from 'react-router';
import { putReport, setLocalReport, checkReportRestrictions, renderRestrictions } from '../features/utils';
import settings from '../settings.json'
import { useKeycloak } from '@react-keycloak/web';
import ISOs from '../ISO.json';
import axios from 'axios';
import Reports from './Reports';
import { ReportMenuItem } from './models';
import { message_hucrestriction, message_nodatalocation } from '../Constants';
import ReportsConfigs from '../ReportsConfigs';
import Routes from '../ReportsRoutes';
import SearchInput from './SearchInput';
import customerRestrictions from '../features/account/customerRestrictions.json';
import './navigationbar.scss';

export default function NavigationBar(props: any) {
  const { global } = props;
  const { keycloak } = useKeycloak();
  const { pathname } = useLocation()
  const navigate = useNavigate();
  const location: Location = useLocation();

  const tokenParsed = JSON.stringify(keycloak['idTokenParsed'])

  const nameStartIdx = (tokenParsed) ? tokenParsed.indexOf("name") + 7 : 0;
  const nameEndIdx = (tokenParsed) ? tokenParsed.indexOf("\"", nameStartIdx + 1) : 0;
  let userFullName = (tokenParsed) ? tokenParsed.substring(nameStartIdx, nameEndIdx) : '';

  // No keycloak token, get name from userInfo
  if (!userFullName) {
    if (global.userInfo && global.userInfo.data && global.userInfo.data.name) {
      userFullName = global.userInfo.data.name;
    }
  }

  const [sectionAccess, setSectionAccess] = useState<any[]>([]);
  const [profiles, setProfiles] = useState<any[]>(['Researcher']);
  const [profileUrl, setProfileUrl] = useState('profile avatar.png');
  const { userRole, userOrg, userInfo } = props.global;

  const {
    currentLocation,
    selectedHUC8,
    updateContext
  } = useContext(AppContext)

  const climateLabel = 'TrueQI Scenario Forecaster';
  const riskLabel = 'TrueQI Site Assessment Reporter';
  const insightsLabel = 'TrueQI Watershed Explorer';
  const wmLabel = 'TrueQI Field Monitor';

  let topNav = 'Insights';

  if (location) {
    if (location.pathname === Routes.WaterMonitoring) { topNav = 'Data Management'; }
    else if (location.pathname === Routes.ClimatePrecipitationCalculator) {
      if (location.search.indexOf('ent=1') > -1) {
        topNav = 'Risk';
      } else {
        topNav = 'Climate';
      }
    } else if (location.pathname === Routes.GroundwaterDetermination) {
      topNav = 'Climate';
    } else if (location.pathname === Routes.Risk) {
      topNav = 'Risk';
    }
  }

  const [navtab, setnavtab] = useState(topNav);

  const routeData = {
    name: "Global Live Water Map",
    parentReportName: "Home",
    root: '/'
  }

  useEffect(() => {
    if (userInfo && userInfo.Profiles && userInfo.Profiles.length > 0) {
      setProfiles(userInfo.Profiles);
    }
  }, [global.userInfo])

  useEffect(() => {
    if (userInfo && userInfo.imageurl) {
      setProfileUrl(userInfo.imageurl);
    }
  }, [global.userInfo.imageurl])

  const RiskParent = ["TrueQI Site Assessment Reporter"];
  const ClimateParent = ["TrueQI Scenario Forecaster", "Groundwater"];
  const MonitorParent = ["Water Monitoring"];

  useEffect(() => {
    const reportConfig = ReportsConfigs.find(x => x.route == location.pathname);
    if(reportConfig && reportConfig.parentReportName){
      if(RiskParent.indexOf(reportConfig.parentReportName)>-1){
        topNav = 'Risk';  
      } else if(ClimateParent.indexOf(reportConfig.parentReportName)>-1){
        topNav = 'Climate'; 
      } else if(MonitorParent.indexOf(reportConfig.parentReportName)>-1){
        topNav = 'Data Management'; 
      } else {
        topNav = 'Insights'; 
      }
      if(navtab !== topNav){
        setnavtab(topNav);
      }
    }
  }, [location.pathname])

  useEffect(() => {
    if (navtab !== global.setNavTopMenu) {
      global.setNavTopMenu(navtab)
    }
  }, [navtab])

  useEffect(() => {
    let _sectionAccess = [] as any;
    if (renderRestrictions(global.userOrgString, 'Site Assessment Reporter', global)) {
      _sectionAccess.push('Risk');
    }
    if (renderRestrictions(global.userOrgString, 'Digital Watershed Explorer', global)) {
      _sectionAccess.push('Insights');
    }
    if (renderRestrictions(global.userOrgString, 'Digital Scenario Forecaster', global)) {
      _sectionAccess.push('Climate');
    }
    if (renderRestrictions(global.userOrgString, 'Field Water Data Monitor', global)) {
      _sectionAccess.push('Data Management');
    }
    setSectionAccess(_sectionAccess)
  }, [global.userOrgString])

  const onClickGear = (event: any) => {
    if (pathname === '/Settings') {
      const lastLocation = localStorage.getItem('settings_lastlocation');
      if (lastLocation) {
        const returnLocation = JSON.parse(lastLocation);
        setLocalReport(returnLocation.pathname);
        navigate(returnLocation.pathname + returnLocation.search);
      }
    } else {
      const l_temp = location;
      const ignoreLocations = ['/Users', '/add-users'];
      if (l_temp && l_temp.pathname && ignoreLocations.indexOf(l_temp.pathname) < 0) {
        localStorage.setItem('settings_lastlocation', JSON.stringify(l_temp));
      }
      navigate('/Settings' + l_temp.search);
    }
  };

  const onClickChat= (event: any) => {
    if (pathname === Routes.Chat) {
      const lastLocation = localStorage.getItem('chat_lastlocation');
      if (lastLocation) {
        const returnLocation = JSON.parse(lastLocation);
        setLocalReport(returnLocation.pathname);
        navigate(returnLocation.pathname + returnLocation.search);
      }
    } else {
      const l_temp = location;
      const ignoreLocations = ['/Users', '/add-users'];
      if (l_temp && l_temp.pathname && ignoreLocations.indexOf(l_temp.pathname) < 0) {
        localStorage.setItem('chat_lastlocation', JSON.stringify(l_temp));
      }
      navigate(Routes.Chat + l_temp.search);
    }
  };

  const onClickMoon = (event: any) => {
    global.setSettingsDoNotDisturb(!global.settingsDoNotDisturb);

  };

  const onClickHelp = (event: any) => {
    navigate('/contact');
  };

  const onClickBell = (event: any) => {

  };

  const onClickNotifications = (event: any) => {

  };

  const onClickBrand = (event: any) => {
    sectionNav(navtab);
  };

  const onClickInsights = (event: any) => {
    onSelectNav('Insights');
  }
  
  const onClickClimate = (event: any) => {
    onSelectNav('Climate');
  }

  const onClickClimateMenu = (event: any) => {
    setnavtab('Climate');
    global.setNavTopMenu('Climate');
  }

  const onClickRisk = (event: any) => {
    onSelectNav('Risk');
  }

  const onClickRiskMenu = (event: any) => {
    setnavtab('Risk');
    global.setNavTopMenu('Risk');
  }

  const onClickWM = (event: any) => {
    onSelectNav('Data Management');
  }

  const onClickWMDropDown = (event: any) => {
    setnavtab('Data Management');
    global.setNavTopMenu('Data Management');
  }

  function sectionNav(section: any) {
    if (section === 'Data Management') {
      setLocalReport(Routes.WaterMonitoring);
      navigate(Routes.WaterMonitoring + '?wm=th');
    } else if (section === 'Climate') {
      setLocalReport(Routes.ClimatePrecipitationCalculator);
      navigate(Routes.ClimatePrecipitationCalculator);
    } else if (section === 'Risk') {
      setLocalReport(Routes.Risk); //+++
      navigate(Routes.Risk + '?ent=1&rcl=AL&inds=SVHL'); //+++dataset
    } else {
      setLocalReport(Routes.Default);
      navigate(Routes.Default + window.location.search);
    }
  }

  const onSelectNav = (event: any) => {
    if (event) {
      setnavtab(event);
      global.setNavTopMenu(event);
      sectionNav(event);
    }
  };

  function checkReportPermissions(route: any) {
    return checkReportRestrictions(global.reportRestrict, route);
  }

  const onLogOut = () => {
    sessionStorage.removeItem("authKey");
    if (keycloak?.authenticated) {
      keycloak.logout({ redirectUri: window.location.origin + '/login' }).then(() => {
        keycloak.clearToken();
      });
    }
    global.setAuthenticatedUser(false);
    global.setUserLogged(false);
    //navigate('/login');
  }

  let imageLogo = 'logo.png';
  const mediaQuery_xwd = window.matchMedia('(max-width: 1230px)');
  const mediaQuery_wd = window.matchMedia('(max-width: 720px)');
  const mediaQuery = window.matchMedia('(max-width: 877px)');
  const mediaQuery_sm = window.matchMedia('(max-width: 750px)');
  const SASBtitle = 'True QI Disclosure Suite Analysis';

  const [show, setShow] = useState(false);

  const handleMouseEnter = () => {
    setShow(true);
  };

  const handleMouseLeave = () => {
    setShow(false);
  };

  return (
    <Navbar collapseOnSelect expand='sm' bg='dark' variant='dark'>
      <Container>
        <Navbar.Brand onClick={onClickBrand} >
        <Image
            className='brand'
            src={mediaQuery_wd.matches ? 'logoQiWhite.png' : 'logoTrueQiWhite.png'}
            style={mediaQuery_wd.matches ? { width:48 } : { height:33, width: 107 }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <div style={{ display: show ? 'block' : 'none' }} className='go-back'>
            Click to go back to Product Home
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='navbar-nav-search'>
            <SearchInput global={global} />
          </Nav>
          <Nav key={"navbar-nav-" + global.navTopMenu} onSelect={onSelectNav}
            style={(mediaQuery.matches) ? { display: 'none' } : {}}
            defaultActiveKey={global.navTopMenu} className={'navbar-nav-container'}>


            <Nav.Item className={(global.navTopMenu === 'Insights') ? 'navbar-nav-item navbar-nav-item-active' : 'navbar-nav-item'}>
              <Dropdown as={ButtonGroup} className={'navbar-nav-dropdown navbar-nav-dropdown-split'} >

                <Button disabled={sectionAccess.indexOf('Insights') > -1 ? false : true} className={'navbar-nav-dropdown-button'} onClick={onClickInsights}>{insightsLabel}</Button>
                {/*               <Dropdown.Toggle className={'navbar-nav-dropdown-toggle'} split />
                <Dropdown.Menu className={'navbar-nav-dropdown-menu'}>
                  <Reports global={global} noDropDown={true} reportSelection={'Insights'} />
                </Dropdown.Menu>*/}
              </Dropdown>
            </Nav.Item>

            <Nav.Item className={(global.navTopMenu === 'Climate') ? 'navbar-nav-item navbar-nav-item-active' : 'navbar-nav-item'}>
              <Dropdown as={ButtonGroup} className={'navbar-nav-dropdown navbar-nav-dropdown-split'} >

                <Button disabled={sectionAccess.indexOf('Climate') > -1 ? false : true} className={'navbar-nav-dropdown-button'} onClick={onClickClimate}>{climateLabel} </Button>
                <Dropdown.Toggle disabled={sectionAccess.indexOf('Climate') > -1 ? false : true} className={'navbar-nav-dropdown-toggle'} split />
                <Dropdown.Menu className={'navbar-nav-dropdown-menu'} onClick={onClickClimateMenu}>
                  <Reports global={global} noDropDown={true} reportSelection={'Climate'} />
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Item>

            <Nav.Item className={(global.navTopMenu === 'Risk') ? 'navbar-nav-item navbar-nav-item-active' : 'navbar-nav-item'}>
              <Dropdown as={ButtonGroup} className={'navbar-nav-dropdown navbar-nav-dropdown-split navbar-risk'} >
                <Button disabled={sectionAccess.indexOf('Risk') > -1 ? false : true} className={'navbar-nav-dropdown-button'} onClick={onClickRisk}>{riskLabel} </Button>
                <Dropdown.Toggle disabled={sectionAccess.indexOf('Risk') > -1 ? false : true} className={'navbar-nav-dropdown-toggle'} split />
                <Dropdown.Menu className={'navbar-nav-dropdown-menu'} onClick={onClickRiskMenu}>
                  <Reports global={global} noDropDown={true} reportSelection={'Risk'} />
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Item>
            <Nav.Item className={(global.navTopMenu === 'Data Management') ? 'navbar-nav-item navbar-nav-item-active' : 'navbar-nav-item'}>
              <Dropdown as={ButtonGroup} className={'navbar-nav-dropdown navbar-nav-dropdown-split'} >
                <Button disabled={sectionAccess.indexOf('Data Management') > -1 ? false : true} className={'navbar-nav-dropdown-button'} style={{ minWidth: 82 }} onClick={onClickWM}>{wmLabel}</Button>
                <Dropdown.Toggle disabled={sectionAccess.indexOf('Data Management') > -1 ? false : true} className={'navbar-nav-dropdown-toggle'} split />

                <Dropdown.Menu id={'navbar-nav-dropdown-menu-wm'} className={'navbar-nav-dropdown-menu'}>
                  <Nav.Item className='navbar-nav-dropdown-item' onClick={onClickWMDropDown}>
                    <ReportItem
                      name={'Home'}
                      root={(Routes.WaterMonitoring + '?wm=th')}
                      disabled={false} />
                  </Nav.Item>
                  <Nav.Item className='navbar-nav-dropdown-item' onClick={onClickWMDropDown} >
                    <ReportItem
                      name={'Water Quality'}
                      root={(Routes.WaterMonitoring + '?wm=tq')}
                      disabled={false} />
                  </Nav.Item>
                  <Nav.Item className='navbar-nav-dropdown-item' onClick={onClickWMDropDown} >
                    <ReportItem
                      name={'Water Monitor Report'}
                      root={(Routes.WaterMonitoring + '?wm=tr')}
                      disabled={false} />
                  </Nav.Item>
                </Dropdown.Menu>

              </Dropdown>
            </Nav.Item>
          </Nav>
          <NavDropdown title={((global.navTopMenu === 'Data Management') ? wmLabel : (global.navTopMenu === 'Climate') ? climateLabel : insightsLabel)}
            id='navbar-nav-dropdown-title'
            style={(mediaQuery.matches) ? { display: 'flex' } : { display: 'none' }}
            align="end" onSelect={onSelectNav} className='navbar-nav-dropdown'>
            <Nav.Link className={(global.navTopMenu === 'Insights') ? 'navbar-nav-item navbar-nav-item-active' : 'navbar-nav-item'}
              eventKey="Insights" >{insightsLabel}</Nav.Link>
            <Nav.Link className={(global.navTopMenu === 'Climate') ? 'navbar-nav-item navbar-nav-item-active' : 'navbar-nav-item'}
              eventKey="Climate" >{climateLabel}</Nav.Link>
            <Nav.Link className={(global.navTopMenu === 'Risk') ? 'navbar-nav-item navbar-nav-item-active' : 'navbar-nav-item'}
              eventKey="Risk" >{riskLabel}</Nav.Link>
            <Nav.Link className={(global.navTopMenu === 'Data Management') ? 'navbar-nav-item navbar-nav-item-active' : 'navbar-nav-item'}
              eventKey="Data Management" >{wmLabel}</Nav.Link>
          </NavDropdown>
          <Nav>
            <div className='navbar-tool'>
              <Button
                type='button'
                className="btn btn-circle dark-mode navbar-button-group"
                style={(global.userRole === 'te-admin') ? {display : 'inline-block', marginRight: 13} : {display: 'none'}}
                onClick={onClickChat}>
                <FontAwesomeIcon icon={faMicrochipAi} />
              </Button>
              <Button
                type='button'
                className={`btn btn-circle dark-mode navbar-button-group ${global.settingsDoNotDisturb ? "active" : ""}`}
                onClick={onClickMoon}>
                <FontAwesomeIcon icon={faMoon} />
              </Button>
              <Dropdown as={NavItem} className='navbar-dropdown navbar-help-dropdown'>
                <Dropdown.Toggle as={NavLink} className='navbar-help-toggle'>
                  <FontAwesomeIcon icon={faQuestion} />
                </Dropdown.Toggle>
                <Dropdown.Menu className='navbar-help-menu'>
                  <Dropdown.Item eventKey={'Contact'} href='/contact' target='_blank' className='navbar-help-item'>
                    <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 15 }} />Contact us
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown as={NavItem} className='navbar-dropdown navbar-bell-dropdown'>
                <Dropdown.Toggle as={NavLink} className='navbar-bell-toggle'>
                  <FontAwesomeIcon icon={faBell} />
                  <Badge
                    className={(global.userNotifications && global.userNotifications.length > 0) ? 'navbar-nts-badge navbar-nts-badge-active' : 'navbar-nts-badge'}
                    key={'navbar-nts-badge' + global.userNotifications.length}
                  > {(global.userNotifications && global.userNotifications.length > 0) ? global.userNotifications.length : ''}
                  </Badge>
                </Dropdown.Toggle>
                <Dropdown.Menu className='navbar-bell-menu'>
                  <Dropdown.Item eventKey={'Notifications'} className='navbar-bell-item' onClick={onClickNotifications}>
                    See All Notifications
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Button
                type='button'
                className='btn btn-default btn-circle settings navbar-button-group navbar-button-gear'
                onClick={onClickGear}>
                <FontAwesomeIcon icon={faGear} />
              </Button>
              <UserMenu id='0' name={userFullName} profiles={profiles} global={global} profileurl={profileUrl} />
              &nbsp;&nbsp;&nbsp;
              {(!!keycloak?.authenticated || global.authenticatedUser) && (
                <Button
                  type='button'
                  className='btn btn-default btn-circle notifications'
                  style={(mediaQuery_xwd.matches) ? { display: 'none' } : {}}
                  onClick={onLogOut}>
                  <FontAwesomeIcon icon={faPowerOff} />
                </Button>
              )}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

interface UserMenuModel {
  id: string;
  name: string;
  profiles: any;
  profileurl: string;
  global?: any;
}

function UserMenu(props: UserMenuModel) {
  const { keycloak } = useKeycloak();
  const { id, name, profiles, profileurl, global } = props;

  const mediaQuery_sm = window.matchMedia('(max-width: 999px)')

  const onSelect = (event: any) => {

    if (event === '1' && keycloak?.authenticated) {
      sessionStorage.removeItem("authKey");
      global.setAuthenticatedUser(false);
      global.setUserLogged(false);
      keycloak.logout({ redirectUri: window.location.origin + '/login' }).then(() => {
        keycloak.clearToken();
      })
    } else if (event === '1' && !keycloak?.authenticated) {
      sessionStorage.removeItem("authKey");
      global.setAuthenticatedUser(false);
      global.setUserLogged(false);
    } else if (event === '2') {
      //sessionStorage.setItem("authKey", "1");
      //sessionStorage.setItem("refreshToken", "1");
      navigate('/UserInfo');
      // navigate to profile
    }
  };

  const navigate = useNavigate()

  const dropDownTitle = (
    <>
      <Image id='navbar-profile-avatar' src={profileurl} className='rounded-circle profile-img' />
      <div className='navbar-profile-div' style={(mediaQuery_sm.matches) ? { display: 'none' } : { display: 'inline-flex' }}>
        <span className='navbar-profile-name'>{name}</span>
        <span className='navbar-profile-role'>{profiles[0]}</span>
      </div>
    </>
  );
  return (
    <>
      <NavDropdown title={dropDownTitle} id='profile-menu'
        align="end" onSelect={onSelect} className='navbar-profile-dropdown'>
        <NavDropdown.Item eventKey={2} >Profile</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey={1} >Log Out</NavDropdown.Item>
      </NavDropdown>
    </>
  );
}


function ReportItem(props: ReportMenuItem) {
  const { name, parentReportName, root } = props
  const {
    currentLocation,
    selectedHUC8,
    updateContext
  } = useContext(AppContext)
  const navigate = useNavigate()
  const regex = /^(ftp|http|https):\/\/[^ "]+$/;

  const handleClick = (event: any) => {
    event.preventDefault()
    document.dispatchEvent(new MouseEvent('click'))
    putReport({ name, parentReportName, root })
    return navigate(root)
  }

  if (props.disabled) {
    return (
      <a
        style={{ cursor: 'default' }}
        className='report-link report-link-disabled'
      >
        {'•  ' + props.name}
      </a>)
  }

  let childItems = [] as any;
  if (props.items) {
    let sortedItems = props.items.sort(function (a, b) {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    })
    const items = sortedItems.map((item: any) => {
      return <ReportItem key={uuid()} {...item} />;
    });
    childItems = items;
  }

  return (
    <>
      {regex.test(props.root) ? (
        <a
          target={'_blank'}
          href={props.root}
          className='report-link'
          onClick={e => document.dispatchEvent(new MouseEvent('click'))}
        >
          {props.name}
        </a>
      ) : (
        <a
          style={props.ident ? { marginLeft: 40, cursor: 'pointer' } : { cursor: 'pointer' }}
          className='report-link'
          onClick={(event) => handleClick(event)}
        >
          {props.name}
        </a>
      )}
      {childItems ? (childItems) : ""}
    </>
  );
}