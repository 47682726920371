import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import * as d3Scale from 'd3-scale';


export const setChartConfigModelScenario = (title: string, selectedCategory: string, setConfig: any, min: number, max: number, theme?:string) => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: true,
      scales: {
        y: {
          display: true,
          ticks: {  //@ts-ignore
            callback: function(val: any, index: any) { //@ts-ignore             
              return index % 2 === 0 ? this.getLabelForValue(val) : '';
            },
            color: function (context: any) {
              if(theme==='light'){
                return '#12234f';
              } else {
                return 'white';
              }
            }
          },
          title: {
            display: true,
            text: 'Precipitation (in)',
            color: function (context: any) {
              if(theme==='light'){
                return '#12234f';
              } else {
                return 'white';
              }
            },
          },
        },
        x: {
          display: true,
          title: {
            display: true,
            text: ' Day of Year',
            color: function (context: any) {
              if(theme==='light'){
                return '#12234f';
              } else {
                return 'white';
              }
            },
          },
          ticks: {
            stepSize: 50,
            color: function (context: any) {
              if(theme==='light'){
                return '#12234f';
              } else {
                return 'white';
              }
            },
          }
        },
      },
      plugins: {
        legend: {
          position: "bottom" as const,
          labels: {
            usePointStyle: true,
          },
        } as const,
        title: {
          display: true,
          text: title,
          color: function (context: any) {
              if(theme==='light'){
                return '#12234f';
              } else {
                return 'white';
              }
            },
          align: 'center',
          padding: 10,
          font: {
            size: 20
          }
        } as const,
        tooltip: {
          padding: 10,
          bodyFont: {
            size: 24
          },
          titleFont: {
            size: 24
          },
          intersect: false,
          boxPadding: 8,
          usePointStyle: false,
          backgroundColor: '#12234f',
          callbacks: {
            label: function (context: any) {
              let label = context.dataset.label || '';
              if (label) {
                label = [context.dataset.label, d3Format.format(".2r")(context.parsed.y) + ' prcp']
              }
              return label;
            }
          }
        } as const,
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'xy',
          },
        } as const,
      } as const,
    } as const;
  setConfig(options);
}

export const setChartConfigPeak  = (setConfigStorm: any, title:string, min: number, max: number, theme?:string) => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: true,
      scales: {
        y: {
          display: true,
          ticks: {  //@ts-ignore
            callback: function(val: any, index: any) { //@ts-ignore             
              return index % 2 === 0 ? this.getLabelForValue(val) : '';
            },
              color: function (context: any) {
              if(theme==='light'){
                return '#12234f';
              } else {
                return 'white';
              }
            },
          },
          title: {
            display: true,
            text: 'Runoff (cfs)',
            color: function (context: any) {
              if(theme==='light'){
                return '#12234f';
              } else {
                return 'white';
              }
            },
          },
        },
        x: {
          display: true,
          ticks: {  //@ts-ignore
            callback: function(val: any, index: any) { //@ts-ignore             
              return index % 2 === 0 ? this.getLabelForValue(val) : '';
            },
            color: function (context: any) {
              if(theme==='light'){
                return '#12234f';
              } else {
                return 'white';
              }
            },
          },
          title: {
            display: true,
            text: 'Hour',
            color: function (context: any) {
              if(theme==='light'){
                return '#12234f';
              } else {
                return 'white';
              }
            },
          },
        },
      },
      plugins: {
        legend: {
          position: "bottom" as const,
          labels: {
            usePointStyle: true,
            color: function (context: any) {
              if(theme==='light'){
                return '#12234f';
              } else {
                return 'white';
              }
            },
          },
        } as const,
        title: {
          display: true,
          text: title,
          color: function (context: any) {
              if(theme==='light'){
                return '#12234f';
              } else {
                return 'white';
              }
            },
          align: 'center',
          padding: 10,
          font: {
            size: 20
          }
        } as const,
        tooltip: {
          padding: 10,
          bodyFont: {
            size: 24
          },
          titleFont: {
            size: 24
          },
          
          boxPadding: 8,
          usePointStyle: false,
          backgroundColor: '#12234f',
          callbacks: {
            label: function (context: any) {
              let label = context.dataset.label || '';
              if (label) {
                label = [context.dataset.label, ' ', d3Format.format(".3r")(context.parsed.y) + ' cfs']
              }
              return label;
            }
          }
        } as const,
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'xy',
          },
        } as const,
      } as const,
    } as const;
    setConfigStorm(options);
  }

export const setChartConfigFlow  = (setConfigStorm: any, title: string, theme?:string) => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
          padding: 5
      },
      scales: {
        y: {
          display: true,
          title: {
            display: true,
            text: 'Runoff (cfs)',
            color:  function (context: any) {
              if(theme==='light'){
                return '#12234f';
              } else {
                return 'white';
              }
            },
          },
          ticks: {  //@ts-ignore
            callback: function(val: any, index: any) { //@ts-ignore             
              return index % 2 === 0 ? this.getLabelForValue(val) : '';
            },
            color: function (context: any) {
              if(theme==='light'){
                return '#12234f';
              } else {
                return 'white';
              }
            },
          },
          suggestedMin:0,
          grid: {
            color: '#FFFFFF',
            z: 1
          },
        },
        x: {
          display: true,
          title: {
            display: true,
            text: 'Fraction of Year Exceeded',
            color:  function (context: any) {
              if(theme==='light'){
                return '#12234f';
              } else {
                return 'white';
              }
            },
          },
          ticks: {  //@ts-ignore
            callback: function(val: any, index: any) { //@ts-ignore             
              return index % 2 === 0 ? this.getLabelForValue(val) : '';
            },
            color: function (context: any) {
              if(theme==='light'){
                return '#12234f';
              } else {
                return 'white';
              }
            },
          },
          suggestedMin: 0,
          max: .05,
        },
      },
      plugins: {
        legend: {
          position: "bottom" as const,
          labels: {
            usePointStyle: true,
            color:  function (context: any) {
              if(theme==='light'){
                return '#12234f';
              } else {
                return 'white';
              }
            },
          },
        } as const,
        title: {
          display: true,
          text: title,
          color: function (context: any) {
            if(theme==='light'){
              return '#12234f';
            } else {
              return 'white';
            }
          },
          align: 'center',
          padding: 10,
          font: {
            size: 20
          },
          backgroundColor: '#12234f',
        } as const,
        tooltip: {
          padding: 10,
          bodyFont: {
            size: 24
          },
          titleFont: {
            size: 24
          },
          boxPadding: 8,
          usePointStyle: false,
          backgroundColor: '#12234f',
          callbacks: {
            label: function (context: any) {
              let label = context.dataset.label || '';
              if (label) {
                label = [context.dataset.label + ' Value', d3Format.format(".3r")(context.parsed.y) + ' ']
              }
              return label;
            }
          }
        } as const,
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'xy',
          },
        } as const,
      } as const,
    } as const;
    setConfigStorm(options);
  }
