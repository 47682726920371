import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTable, useSortBy } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';

const ReportTable = ({
  customWidth,
  data,
  columns,
  clickRowHandler = () => {},
  hoverRowHandler = () => {},
  clickLoadStop,
}: {
  customWidth?: string
  data: {}[];
  columns: any[];
  clickRowHandler?: (rowValue: any, event: any) => void;
  hoverRowHandler?: (rowValue: any, event: any) => void;
  clickLoadStop?: boolean;
}) => {
  const [isFirstDataLoad, setIsFirstDataLoad] = useState(true);

  useEffect(() => {
    if (data.length > 0) {
      if (isFirstDataLoad) {
        const tableRowsHTMLElements = document.querySelectorAll('table > tbody > tr');
        if (tableRowsHTMLElements.length > 0) {
          const firstRowHtmlElement = tableRowsHTMLElements[0] as HTMLElement;
          if (!clickLoadStop) {
            firstRowHtmlElement.click();
          }
        }
        setIsFirstDataLoad(false);
      }
    }
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data: data },
    useSortBy
  );

  return (
    <>
      <div className='table-container table-container--scrollable'>
        <Table
          className='reportTable'
          {...getTableProps()}
          style={{ maxHeight: '30rem', overflow: 'auto', width: customWidth }}
        >
          <thead className='report-table__header'>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ position: 'sticky', insetInlineStart: 0 }}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted &&
                        (column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} size='xs' style={{ marginLeft: 4 }} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} size='xs' style={{ marginLeft: 4 }} />
                        ))}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={event => clickRowHandler(row.original, event)}
                  onMouseOver={event => hoverRowHandler(row.original, event)}
                >
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ReportTable;
