import {
    useMemo,
    useState
} from 'react'
import ReportTable from '../../../reports/shared/ReportTable'
import {
    Button,
    Form,
    Row, Col
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import {
    faPenToSquare,
    faTrashCan,
    faCalendar
} from '@fortawesome/pro-solid-svg-icons';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';

import WM_importkeysMLOC from '../WM_importkeysMLOC.json';

import { postWQX, submitWQX } from '../lib/data';

const ImportDetails = (props: any) => {

    const {
        setImportText,
        importText,
        projectsData,
        setAction,
        action,
        data,
        setData,
        parsedData,
        refreshWQX
      } = props

    const [startDate, setStartDate] = useState(new Date());
    const [autoWQX, setAutoWQX] = useState(false);
    const [importData, setImportData] = useState < any[] > (parsedData);

    let wqxfields = [] as any;
    for (const [key, value] of Object.entries(WM_importkeysMLOC)) {
      wqxfields.push(key)
    }

    const findVal = (v:any) => {
        //@ts-ignore
      return Object.keys(WM_importkeysMLOC).filter((k) => WM_importkeysMLOC[k].includes(v))
    }

    const handleImport = (event: any) => {
        console.log('handleImport')
        if(importData && importData.length > 0){
            importData.forEach((dataItem:any, index:number) => {
                console.log(dataItem)
                if(dataItem['includeInImport']){
                    let wqxonly = {...dataItem};
                    let wqxnew = {};
                    for (const [key, value] of Object.entries(wqxonly)) {
                        let valSearch = findVal(key);
                        if(!valSearch || valSearch.length<1){
 
                        } else {
                            if (key === 'Organization'){//@ts-ignore
                                wqxnew['Organization'] = value; 
                            } else if(value){ 
                                //@ts-ignore
                                wqxnew[valSearch[0]] = value; 
                                if(valSearch[0] === 'MonitoringLocIdent_MonitoringLocationDescriptionText'){
                                    //@ts-ignore
                                    wqxnew[valSearch[0]] = value.substr(0, 200);
                                }
                            }                            
                        }
                    }
                    postWQX(wqxnew, handlePostReturn, dataItem); //wqxnew WM_importformORG
                }
            });
            setTimeout(() => {
              refreshWQX();
            }, 3333);              
        }
    }

    const handlePostReturn = (success: any, result: any, err?: any, reference?:any) => {
        // console.log(success, result, err, reference, autoWQX)
         if(!success){
            updateImportData(reference.ImportID, "ImportStatus", "Error")
            updateImportData(reference.ImportID, "ImportErrors", "Error")
         } else {
            updateImportData(reference.ImportID, "id", +result);
            updateImportData(reference.ImportID, "ImportStatus", "Success");
            updateImportData(reference.ImportID, "ImportErrors", "");
            if(autoWQX){
                let initialData = [...importData];
                let filterData = initialData.filter((o: any) => (o["ImportID"] === reference.ImportID) ); 
                if(filterData && filterData.length > 0){
                    filterData[0]["id"] = +result;
                    //console.log(filterData[0], +result)
                    submitWQX(filterData[0], handleSubmitReturn, true, filterData[0]);
                }      
            }
         }
    }

    const handleSubmitReturn = (success: any, result: any, err?: any, reference?:any) => {
             
    }

    const updateImportData = (id:any, field: any, fieldvalue:any) => {
        let initialData = [...importData];
        let filterData = initialData.filter((o: any) => (o["ImportID"] === id) ); 
        if(filterData && filterData.length > 0){
            filterData[0][field] = fieldvalue;
            setImportData(initialData);
        }
    }

    const handleActive= (props: any) => {
         if(props && props.row && props.row.values && props.row.values.ImportID){
             let filterData = importData.filter((o: any) => (o["ImportID"] === props.row.values.ImportID) ); 
             if(filterData && filterData.length > 0){
                filterData[0]["includeInImport"] = !filterData[0]["includeInImport"];
                setImportData(importData);
             }
         }
    }

    const tableColumns = useMemo(
        () => [
            {
                Header: 'Include In Import',
                accessor: 'includeInImport',
                Cell: (props: any) => (
                    <div className="action-box">
                        <Form.Check
                            defaultChecked={props.value}
                            onChange={() => {
                                handleActive(props)            
                            }}
                        />
                    </div>
                ),
            },
            {
                Header: 'Import ID',
                accessor: 'ImportID',
            },
            {
                Header: 'Organization',
                accessor: 'OrganizationFormalName',
            },
            {
                Header: 'MonLoc ID',
                accessor: 'MonitoringLocationIdentifier',
            },
            {
                Header: 'Mon Lac Name',
                accessor: 'MonitoringLocationName',
            },
            {
                Header: 'Mon Lac Desc',
                accessor: "MonitoringLocationDescriptionText",
                Cell: (props: any) => (
                    <div className="wm-table-over" style={{minWidth: 180, maxHeight: 70}}>                      
                          {props.value && (props.value.substr(0, 40) + ((props.value.length>40) ? "..." : ""))}                           
                    </div>
                ),
            },
            {
                Header: 'Import Status',
                accessor: 'ImportStatus',
                Cell: (props: any) => (
                    <div style={(props.value === 'Error') ? {color:'red'} : {}}>                      
                          {props.value}                           
                    </div>
                ),
            },
            {
                Header: 'Import Errors',
                accessor: 'ImportErrors',

            }
        ],
        []
    );


    return (
        <Row className='monitoring-location-container'>
            <div className="header-box">
                <h2>Confirm Monitoring Location Data To Import</h2>
            </div>
            <Row id="filter-box" style={{ marginRight: '0' }}>
                <div className="filter-box import-details">
                    <Row style={{
                        paddingLeft: '12px',
                        paddingRight: '18px'
                    }}>
                        <div className="danger-box">
                            <span className="warning-icon">
                                !
                            </span>
                            <span>
                                Note: You Must Click The “Import Selected Rows” Button To Complete The Import Process
                            </span>
                        </div>
                    </Row>
                    <Row>
                        <div className="bottom-half">
                            <div className="drop-box">
                                <Form.Check
                                    type='checkbox'
                                     onChange={() => {
                                        setAutoWQX(!autoWQX)         
                                    }}
                                    checked={autoWQX}
                                />
                                <h4 style={{
                                    fontSize: '1rem',
                                    marginLeft: '0.5rem'
                                }}
                                >Automatically Submit Imported Data To EPA/WQX
                                </h4>
                            </div>
                            <div className="date-range-box">
                                <Button
                                    style={{
                                        width: '12rem',
                                        height: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                    id="addNew-btn"
                                    className="p-3 btn btn-primary m-2"
                                    onClick={handleImport}
                                >
                                    Import Selected Rows
                                </Button>
                            </div>
                        </div>
                    </Row>
                </div>
            </Row>
            <ReportTable columns={tableColumns} data={importData} />
            <div style={{ textAlign: "end" }} className="bottom-button-box">
                <Button
                    id="addNew-btn"
                    className="p-2 btn btn-primary m-2"
                    style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '1rem !important',
                        width: '8rem'
                    }}
                    onClick={() => {
                        setAction('importaction')
                      }}
                >
                    OK
                </Button>
                <Button
                    id="addNew-btn"
                    className="p-2 btn btn-primary m-2"
                    style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '1rem !important',
                        width: '8rem'
                    }}
                    onClick={() => {
                        setAction('importaction')
                      }}
                >
                    Cancel Import
                </Button>
            </div>
        </Row>
    )
}

export default ImportDetails