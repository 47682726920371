export const legendNwshazardDefault = [
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                128,
                128,
                128,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Air Quality Alert",
        "label": "Air Quality Alert"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                124,
                252,
                0,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Coastal Flood Advisory",
        "label": "Coastal Flood Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                107,
                142,
                35,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Coastal Flood Statement",
        "label": "Coastal Flood Statement"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                112,
                128,
                144,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Dense Fog Advisory",
        "label": "Dense Fog Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                199,
                21,
                133,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Excessive Heat Warning",
        "label": "Excessive Heat Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                128,
                0,
                0,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Excessive Heat Watch",
        "label": "Excessive Heat Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                0,
                255,
                127,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Flood Advisory",
        "label": "Flood Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                0,
                255,
                0,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Flood Warning",
        "label": "Flood Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                46,
                139,
                87,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Flood Watch",
        "label": "Flood Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                221,
                160,
                221,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Gale Warning",
        "label": "Gale Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                192,
                203,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Gale Watch",
        "label": "Gale Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                127,
                80,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Heat Advisory",
        "label": "Heat Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                218,
                165,
                32,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "High Wind Warning",
        "label": "High Wind Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                184,
                134,
                11,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "High Wind Watch",
        "label": "High Wind Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                144,
                238,
                144,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Hydrologic Outlook",
        "label": "Hydrologic Outlook"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                210,
                180,
                140,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Lake Wind Advisory",
        "label": "Lake Wind Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                239,
                213,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Marine Weather Statement",
        "label": "Marine Weather Statement"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                64,
                224,
                208,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Rip Current Statement",
        "label": "Rip Current Statement"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                165,
                0,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Severe Thunderstorm Warning",
        "label": "Severe Thunderstorm Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                219,
                112,
                147,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Severe Thunderstorm Watch",
        "label": "Severe Thunderstorm Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                216,
                191,
                216,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Small Craft Advisory",
        "label": "Small Craft Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                228,
                181,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Special Weather Statement",
        "label": "Special Weather Statement"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                210,
                180,
                140,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Wind Advisory",
        "label": "Wind Advisory"
    }
]