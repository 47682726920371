import { Source, Layer } from 'react-map-gl';

const RasterLayer = ({ id, url, opacity }: any) => {
  return (
    <Source
      id={`${id}-raster-layer`}
      type="raster"
      url={url}
    >
      <Layer
        paint={{ 'raster-opacity': opacity }}
        type="raster" id={`${id}-raster-layer`}
        source={`${id}-raster-layer`}
      />
    </Source>
  )
}

export default RasterLayer