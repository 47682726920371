// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.femafirm .legend-firmette img {
  background-color: lightgray;
  opacity: .9; }

.femafirm .image-backdrop {
  background-color: aliceblue; }
`, "",{"version":3,"sources":["webpack://./src/components/Layers/FemaFirm/FemaFirm.scss"],"names":[],"mappings":"AAAA;EAGM,2BAA2B;EAC3B,WAAW,EAAA;;AAJjB;EASI,2BAA2B,EAAA","sourcesContent":[".femafirm {\n  .legend-firmette {\n    img {\n      background-color: lightgray;\n      opacity: .9;\n    }\n  }\n\n  .image-backdrop {\n    background-color: aliceblue;\n  }  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
