import { useEffect, useContext, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { FillLayer, SymbolLayer } from 'mapbox-gl';
import { RefContext } from '../../../RefContext';

interface FemaFloodZonesProps {
  id: string;
  fillColor: any;
  opacity: number;
  tileSet: string;
  sourceLayer: string;
}

const FemaFloodZones: React.FC<FemaFloodZonesProps> = ({ id, fillColor, opacity, tileSet, sourceLayer }) => {
  const { currentRef } = useContext(RefContext);
  const [features, setFeatures] = useState<any>(null);

  // @ts-ignore
  const mapInstance = currentRef?.current;

  useEffect(() => {
    if (mapInstance) {
      const handleMouseMove = (event: any) => {
        const layerFeatures = mapInstance.queryRenderedFeatures(event.point);
        if (layerFeatures.length > 0) {
          setFeatures(layerFeatures[0]);
        } else {
          setFeatures(null);
        }
      };

      // Attach event listener
      mapInstance.on('mousemove', handleMouseMove);

      // Cleanup on component unmount
      return () => {
        mapInstance.off('mousemove', handleMouseMove);
      };
    }
  }, [mapInstance]);

  // Cover layer for low zoom levels
  const CoverLayer: FillLayer = {
    id: `${id}-cover-layer`,
    type: 'fill',
    'source-layer': sourceLayer,
    paint: {
      'fill-opacity': opacity,
      'fill-outline-color': 'transparent',
      'fill-color': '#FF0000', // Default red color for cover layer
    },
    maxzoom: 8.9,
    minzoom: 3.5,
  };

  // Vector tile layer for higher zoom levels
  const VectorTileLayer: FillLayer = {
    id: `${id}-layer`,
    type: 'fill',
    'source-layer': sourceLayer,
    paint: {
      'fill-opacity': opacity,
      'fill-outline-color': '#d3d3d3',
      'fill-color': fillColor, // Custom fill color passed as a prop
    },
    minzoom: 9,
  };

  // Symbol layer for displaying flood zone labels
  const LabelLayer: SymbolLayer = {
    id: `${id}-label-layer`,
    type: 'symbol',
    'source-layer': sourceLayer,
    layout: {
      'text-field': ['get', 'FLD_ZONE'], // Use the FLD_ZONE property for labels
      'text-size': 18,
      'text-anchor': 'center',
    },
    paint: {
      'text-color': '#000000',
      'text-halo-color': '#ffffff',
      'text-halo-width': 1,
    },
    minzoom: 12, // Labels show only from zoom level 12 and up
  };

  return (
    <Source id={`${id}-source`} type="vector" url={tileSet}>
      {/* Cover Layer for low zoom levels */}
      <Layer {...CoverLayer} />

      {/* Label Layer for flood zone text */}
      <Layer {...LabelLayer} beforeId={`${id}-cover-layer`} />

      {/* Main Vector Tile Layer for higher zoom levels */}
      <Layer {...VectorTileLayer} beforeId={`${id}-label-layer`} />
    </Source>
  );
};

export default FemaFloodZones;
