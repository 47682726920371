import { parse } from 'papaparse';
import { createContext, useEffect, useState } from 'react';
import { ICatalogs } from './types/ICatalogs';
import { HUC12GeoPoint } from './types/HUC12GeoPoint';
import { HUC8GeoPoint } from './types/HUC8GeoPoint';

const defaultValue: ICatalogs = {
  huc8Catalog: [],
  huc12Catalog: [],
};
export const CatalogsContext = createContext(defaultValue);

function CatalogsContextWrapper(props: any) {
  const [huc12LonLatCatalogArray, setHuc12LonLatCatalogDataArray] = useState<
    any[]
  >([]);
  const [huc8LonLatCatalogArray, setHuc8LonLatCatalogDataArray] = useState<
    any[]
  >([]);

  const CatalogContextProvider = CatalogsContext.Provider;

  useEffect(() => {
    const huc12FileURL = '/huc12s_lat_lon.csv';
    fetch(huc12FileURL)
      .then((response) => response.text())
      .then((responseText) => {
        const data: HUC12GeoPoint[] | any[] = parse(responseText, {
          header: true,
        }).data;
        setHuc12LonLatCatalogDataArray(data);
      });
    const huc8FileURL = '/huc8s_lat_lon.csv';
    fetch(huc8FileURL)
      .then((response) => response.text())
      .then((responseText) => {
        const huc8sCatalog: HUC8GeoPoint[] | any[] = parse(responseText, {
          header: true,
        }).data;
        setHuc8LonLatCatalogDataArray(huc8sCatalog);
      });
  }, []);

  const contextValue = {
    huc8Catalog: huc8LonLatCatalogArray,
    huc12Catalog: huc12LonLatCatalogArray,
  };

  return (
    <>
      <CatalogContextProvider value={contextValue}>
        {props.children}
      </CatalogContextProvider>
    </>
  );
}

export default CatalogsContextWrapper;
