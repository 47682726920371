import { Ref, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  MapRef,
  Map,
  Source,
  Layer,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  LayerProps,
  Popup,
} from 'react-map-gl';
import { AppContext } from '../../../AppContext';
import { CatalogsContext } from '../../../CatalogsProvider';
import MapSource from '../../../types/MapSource';
import settings from '../../../settings.json';
import { Row, Col, Button, Form, Accordion } from 'react-bootstrap';
import ReportTable from '../shared/ReportTable';
import { Location, useLocation } from 'react-router-dom';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import { flyToInitialLocationHandler, flyToLngLat } from '../../utils';
import { RefContext } from '../../../RefContext';
import agent from '../../../api/agent';
import {
  ToxicsReleaseInvetoryResponseBodyModel,
  ToxicReleaseInvetoryResponseModel,
} from '../../../api/models';
import Globals from '../../../types/GlobalsType';
import { message_hucrestriction, message_nodatalocation, message_noresult } from '../../../Constants';
import { CHEMICALS_FILTER, INDUSTRY_SECTOR_FILTER, YEAR_FILTER } from '../../../Constants';
import LoadingSpinner from '../../../components/LoadingSpinner';
import bbox from '@turf/bbox';
import { v4 as uuidv4 } from 'uuid';
import DrawControl from '../../../types/DrawControl';
import { Feature, FeatureCollection, GeoJsonProperties, Point } from 'geojson';
import { parse } from 'papaparse';
import { HUC12InfoPoint } from '../../../types/HUC12InfoPoint';
import './styles.scss';
import IndustrySectorsMarkersColors from './IndustrySectorsMarkersColors';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import fullscreenIcon from '../../../assets/fullscreen-icon.svg';
import ellipsisIcon from '../../../assets/ellipsis-icon.svg';
import RenderLayerMarkers from '../shared/RenderLayerMarkers';
import RenderLayers from '../shared/RenderLayers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import MapSlider from '../shared/MapSlider';
import MapLegend from '../shared/MapLegend';

function ToxicsRealeaseInventory(props: any) {
  const { global } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);

  const fullscreenhandle = useFullScreenHandle() as any;
  const FullScreenComp = FullScreen as any;

  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState<Array<ToxicsReleaseInvetoryResponseBodyModel>>([]);
  const [tableData, setTableData] = useState<Array<ToxicsReleaseInvetoryResponseBodyModel>>([]);
  const [dataPage, setDataPage] = useState<number>(0);
  const [currentSelectedRowHTMLElement, setCurrentSelectedRowHTMLElement] =
    useState<HTMLElement | null>(null);
  const [currentSelectedRowData, setCurrentSelectedRowData] = useState({});
  const [huc12InfoCatalogArray, setHuc12InfoCatalogDataArray] = useState<any[]>([]);
  const [featureData, setFeatureData] = useState<FeatureCollection>();
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [cursor, setCursor] = useState<string>('auto');
  const [scrolledToBottom, setScrolledToBottom] = useState<boolean>();
  const [isLastResponseEmpty, setIsLastResponseEmpty] = useState<boolean>(false);


  const mapRef = useRef<MapRef>();
  const { setCurrentRef } = useContext(RefContext);
  useEffect(() => { setCurrentRef?.(mapRef) }, [mapRef])
  const [currentMapRef, setCurrentMapRef] = useState<any>(null);

  const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  const location: Location = useLocation();
  //@ts-ignore
  const routeData = {
    name: 'Toxics Release Inventory',
    root: '/ToxicsReleaseInventory',
    parentReportName: 'Surface Water'
  }

  let selectedChemicals = (global as Globals).chemicalsSelected;
  let selectedIndustrySector = (global as Globals).selectedIndustrySector;
  let selectedYearFilter = (global as Globals).selectedYearFilter;
  const [clickedFacilityFilterValue, setClickedFacilityFilterValue] = useState<string>('');
  const [resetClickedFeatureFilter, setResetClickedFeatureFilter] = useState<boolean>(false);

  const mapFilterDefinitionToHook: any = {
    CHEMICALS_FILTER: selectedChemicals,
    INDUSTRY_SECTOR_FILTER: selectedIndustrySector,
    YEAR_FILTER: selectedYearFilter,
  };

  useEffect(() => {
    const reportContainerHTMLElement = document.querySelector('div.toxics-release-inventory');
    reportContainerHTMLElement?.addEventListener('click', resetClickedFeatureFilterHandler);

    return () =>
      reportContainerHTMLElement?.removeEventListener('click', resetClickedFeatureFilterHandler);
  });
  useEffect(() => {
    setClickedFacilityFilterValue('');
    resolveFiltersOptions(reportData);
    if (
      hasAValue((global as Globals).chemicalsSelected) ||
      hasAValue(selectedIndustrySector) ||
      hasAValue(selectedYearFilter)
    ) {
      resolveDataWithFilters();
    } else {
      setTableData(reportData);
    }
  }, [resetClickedFeatureFilter]);

  const resetClickedFeatureFilterHandler = (e: Event) => {
    if (e.target !== e.currentTarget) return;
    if (clickedFacilityFilterValue) {
      setResetClickedFeatureFilter(prev => !prev);
    }
  };

  const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
    }, {} as Record<K, T[]>);

  useEffect(() => {
    global.setUserLogged(true);
    if (global.trackerOn) global.setTrackerOn(false)

    const huc8 = appContext.selectedHUC8;
    const huc12InfoFileURL = '/huc12_info.csv';
    fetch(huc12InfoFileURL)
      .then(response => response.text())
      .then(responseText => {
        const data: HUC12InfoPoint[] | any[] = parse(responseText, {
          header: true,
        }).data;
        for (let dataItem of data) {
          if (dataItem.huc12.length < 12) {
            dataItem.huc12 = '0' + dataItem.huc12;
          }
        }
        setHuc12InfoCatalogDataArray(data);
      });
    const selectedHUC8Value = appContext.selectedHUC8;
    if(selectedHUC8Value && global.hucRestrict.length>0 && (global.hucRestrict.indexOf(selectedHUC8Value) < 0)){
        setLoading(false);
        return global.notify(message_hucrestriction); 
    }

    agent.Reports.ToxicsReleaseInventory(huc8, dataPage).then(
      (res: ToxicReleaseInvetoryResponseModel) => {
        let responseBody: ToxicsReleaseInvetoryResponseBodyModel[] | undefined = res.body;
        if (!responseBody) responseBody = [];

        setDataPage(prevPageNumber => {
          return prevPageNumber + 1;
        });

        setReportData(responseBody);
        setTableData(responseBody);
        createFeatureCollection(responseBody);
        setLoading(false);

        const groupedDataByChemical = groupBy<any, any>(responseBody, x => x.Chemical);
        const chemicalsOptions = Object.keys(groupedDataByChemical);
        (global as Globals).setChemicalsFilerOptions(chemicalsOptions);

        const dataGroupedByIndustry = groupBy<any, any>(responseBody, x => x['Industry Sector']);


        const industriesNames = Object.keys(dataGroupedByIndustry);
        global.setIndustrySectorFilterPopulated(industriesNames);

        const dataGroupedByYear = groupBy<ToxicsReleaseInvetoryResponseBodyModel, any>(
          responseBody,
          x => x.Year
        );
        const yearsLabels = Object.keys(dataGroupedByYear);
        (global as Globals).setSelectedYearFilterOptions(yearsLabels);
      }
    );

    const scrollableTable = document.querySelector(
      'div.table-container.table-container--scrollable'
    );
    scrollableTable?.addEventListener('scroll', e => {
      const scrollAdvancement =
        (scrollableTable as HTMLElement).offsetHeight + scrollableTable.scrollTop;
      const scrollMiddlePoint = (Math.ceil(scrollableTable.scrollHeight / 10) * 10) / 2;
      const maxRangeValue = scrollMiddlePoint + 40;
      if (
        Math.ceil(scrollAdvancement / 10) * 10 >= scrollMiddlePoint &&
        Math.ceil(scrollAdvancement / 10) * 10 >= maxRangeValue
      ) {
        setScrolledToBottom(prev => !prev);
      }
    });

    return () => {
      cleanUpFilters();
    };
  }, []);

  const cleanUpFilters = () => {
    const globalProps = global as Globals;
    globalProps.setChemicalsSelected([]);
    globalProps.setSelectedIndustrySector([]);
    globalProps.setSelectedYearFilter('');
  };
  useEffect(() => {
    if (scrolledToBottom != undefined && loading == false && !isLastResponseEmpty) {
        const selectedHUC8Value = appContext.selectedHUC8;
        if(selectedHUC8Value && global.hucRestrict.length>0 && (global.hucRestrict.indexOf(selectedHUC8Value) < 0)){
            setLoading(false);
            return global.notify(message_hucrestriction); 
        }
    
      setLoading(true);
      agent.Reports.ToxicsReleaseInventory(appContext.selectedHUC8, dataPage).then(
        (res: ToxicReleaseInvetoryResponseModel) => {
          let responseBody: ToxicsReleaseInvetoryResponseBodyModel[] | undefined = res.body;

          if (!responseBody) responseBody = [];
          const arrayResponseBody = [...responseBody];

          if (arrayResponseBody.length == 0 || arrayResponseBody.length < 100) {
            setIsLastResponseEmpty(true);
            setLoading(false);
          } else {
            let updatedData: ToxicsReleaseInvetoryResponseBodyModel[] = [];
            setReportData(prevResponseData => {
              const mergedData = [...prevResponseData, ...arrayResponseBody];
              updatedData = [...mergedData];
              return mergedData;
            });
            resolveFiltersOptions(updatedData);
            if (
              hasAValue((global as Globals).chemicalsSelected) ||
              hasAValue(selectedIndustrySector) ||
              hasAValue(selectedYearFilter) ||
              hasAValue(clickedFacilityFilterValue)
            ) {
              const mergedCurrentAndResponseData = [...tableData, ...arrayResponseBody];
              resolveDataWithFilters(mergedCurrentAndResponseData);
            } else {
              setTableData(prevResponseData => [...prevResponseData, ...arrayResponseBody]);
            }
            setDataPage(prevPageNumber => prevPageNumber + 1);
            createFeatureCollection(responseBody);
            setLoading(false);

            setTimeout(() => {
              flyToInitialLocationHandler(
                appContext.selectedHUC8,
                mapRef,
                catalogsContext.huc8Catalog
              );
            }, 1500);
          }
        }
      );
    }
  }, [scrolledToBottom]);

  useEffect(() => {
    if (mapRef.current === currentMapRef) {
    } else {
      setCurrentMapRef(mapRef.current);
    }

    setTimeout(() => {
      flyToInitialLocationHandler(appContext.selectedHUC8, mapRef, catalogsContext.huc8Catalog);
      if (mapRef && mapRef.current) {
        mapRef.current.on('click', 'pointlayer', clickedFeatureHandler);
      }
    }, 1000);

    return () => {
      mapRef.current?.off('click', 'pointlayer', clickedFeatureHandler);
    };
  }, [mapRef.current]);

  const clickedFeatureHandler = (e: any) => {
    if (e.features?.length) {
      //@ts-ignore
      const clickedFeatureFacilityName = e.features[0].properties['Facility Name'];
      const filteredTableDataByFacility = reportData.filter(
        row => row['Facility Name'] == clickedFeatureFacilityName
      );
      setClickedFacilityFilterValue(clickedFeatureFacilityName);

      if (filteredTableDataByFacility.length) {
        if (
          hasAValue((global as Globals).chemicalsSelected) ||
          hasAValue((global as Globals).selectedIndustrySector) ||
          hasAValue((global as Globals).selectedYearFilter)
        ) {
          resolveDataWithFilters();
        } else {
          setTableData(filteredTableDataByFacility);
        }
      }
    }
  };

  useEffect(() => {
    if (
      hasAValue(selectedChemicals) ||
      hasAValue(selectedIndustrySector) ||
      hasAValue(selectedYearFilter)
    ) {
      resolveDataWithFilters();
    } else {
      setTableData(reportData);
      createFeatureCollection(reportData);
    }
  }, [selectedChemicals, selectedIndustrySector, selectedYearFilter]);

  const hasAValue = (variable: any) => {
    if (typeof variable == 'string') {
      return variable !== '';
    }

    if (Array.isArray(variable)) {
      return variable.length > 0;
    }
    return true;
  };

  useEffect(() => {
    setLoading(true);
    const huc8 = appContext.selectedHUC8;
    agent.Reports.ToxicsReleaseInventory(huc8, dataPage).then(
      (res: ToxicReleaseInvetoryResponseModel) => {
        let responseBody: ToxicsReleaseInvetoryResponseBodyModel[] | undefined = res.body;
        if (!responseBody) responseBody = [];
        setReportData(responseBody);
        setTableData(responseBody);
        createFeatureCollection(responseBody);

        const groupedDataByChemical = groupBy<any, any>(responseBody, x => x.Chemical);
        const chemicalsOptions = Object.keys(groupedDataByChemical);
        (global as Globals).setChemicalsFilerOptions(chemicalsOptions);

        const dataGroupedByIndustry = groupBy<any, any>(responseBody, x => x['Industry Sector']);
        const industriesNames = Object.keys(dataGroupedByIndustry);
        global.setIndustrySectorFilterPopulated(industriesNames);

        const dataGroupedByYear = groupBy<ToxicsReleaseInvetoryResponseBodyModel, any>(
          responseBody,
          x => x.Year
        );
        const yearsLabels = Object.keys(dataGroupedByYear);
        (global as Globals).setSelectedYearFilterOptions(yearsLabels);

        setLoading(false);
      }
    );
  }, [appContext.selectedHUC8]);

  const createFeatureCollection = (reportData: any) => {
    const features: Array<Feature<Point, GeoJsonProperties>> = [];
    const addedHUC: any[] = [];
    const dataGroupedByIndustrySector = groupBy<ToxicsReleaseInvetoryResponseBodyModel, any>(
      reportData,
      x => x['Industry Sector']
    );
    for (let industrySector in dataGroupedByIndustrySector) {
      const industryFacilities: ToxicsReleaseInvetoryResponseBodyModel[] =
        dataGroupedByIndustrySector[industrySector].reduce(
          (acc, x) =>
            acc.concat(
              //@ts-ignore
              acc.find(y => y['Facility Name'] === x['Facility Name']) ? [] : [x]
            ),
          []
        );
      for (let facility of industryFacilities) {
        const featureWithPoint: Feature<Point> = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [facility.Longitude, facility.Latitude],
          },
          properties: {
            ['Industry Sector']: facility['Industry Sector'],
            ['Industry Sector Code']: facility['Industry Sector Code'],
            Chemical: facility.Chemical,
            ['Facility Name']: facility['Facility Name'],
            City: facility.City,
            County: facility.County,
            Address: facility['Street Address'],
            Longitude: facility.Longitude,
            Latitude: facility.Latitude,
          },
        };
        features.push(featureWithPoint);
      }
    }

    const featureCollectionFromReportData: FeatureCollection = {
      type: 'FeatureCollection',
      features: features,
    };
    setFeatureData(featureCollectionFromReportData);
  };

  function resolveCurrentactiveFilters(): string[] {
    const currentActiveFilters = [];

    if ((global as Globals).chemicalsSelected.length > 0) {
      currentActiveFilters.push(CHEMICALS_FILTER);
    }
    if ((global as Globals).selectedIndustrySector.length > 0) {
      currentActiveFilters.push(INDUSTRY_SECTOR_FILTER);
    }
    if ((global as Globals).selectedYearFilter != '') {
      currentActiveFilters.push(YEAR_FILTER);
    }

    return currentActiveFilters;
  }

  const resolveFiltersOptions = (data: ToxicsReleaseInvetoryResponseBodyModel[]) => {
    const groupedDataByChemical = groupBy<any, any>(data, x => x.Chemical);
    const chemicalsOptions = Object.keys(groupedDataByChemical);
    (global as Globals).setChemicalsFilerOptions(chemicalsOptions);

    const dataGroupedByIndustry = groupBy<any, any>(data, x => x['Industry Sector']);
    const industriesNames = Object.keys(dataGroupedByIndustry);
    global.setIndustrySectorFilterPopulated(industriesNames);

    const dataGroupedByYear = groupBy<ToxicsReleaseInvetoryResponseBodyModel, any>(
      data,
      x => x.Year
    );
    const yearsLabels = Object.keys(dataGroupedByYear);
    (global as Globals).setSelectedYearFilterOptions(yearsLabels);
  };

  function resolveDataWithFilters(data: ToxicsReleaseInvetoryResponseBodyModel[] = reportData) {
    const currentactiveFilters = resolveCurrentactiveFilters();
    let dataUnderWork = data;
    for (let filter of currentactiveFilters) {
      const groupedData = groupData(dataUnderWork, filter);
      const currentGroupsOfData = Object.keys(groupedData);

      const selection = mapFilterDefinitionToHook[filter];

      if (typeof selection == 'string') {
        if (currentGroupsOfData.includes(selection)) {
          dataUnderWork = [...groupedData[selection]];
        }
      } else {
        let result: any[] = [];

        if (selection.length > 0) {
          for (let group of selection) {
            if (currentGroupsOfData.includes(group)) {
              const concatenatedResult = result.concat(groupedData[group]);
              result = [...concatenatedResult];
            }
          }
        } else {
          result = reportData;
        }
        dataUnderWork = result;
      }
    }
    if (clickedFacilityFilterValue) {
      const dataUnfiltered = [...dataUnderWork];
      dataUnderWork = dataUnfiltered.filter(x => x['Facility Name'] == clickedFacilityFilterValue);
    }
    setTableData(dataUnderWork);
  }

  function groupData(data: any[], filter: string) {
    switch (filter) {
      case CHEMICALS_FILTER:
        return groupBy<ToxicsReleaseInvetoryResponseBodyModel, any>(data, x => x.Chemical);
        break;
      case INDUSTRY_SECTOR_FILTER:
        return groupBy<ToxicsReleaseInvetoryResponseBodyModel, any>(
          data,
          x => x['Industry Sector']
        );
        break;

      case YEAR_FILTER:
        return groupBy<ToxicsReleaseInvetoryResponseBodyModel, any>(data, x => x.Year);
        break;

      default:
        return {};
        break;
    }
  }

  const fitMapData = (reportData: any) => {
    const featureCollectionFromReportData = {
      type: 'FeatureCollection',
      features: [] as any,
    };
    for (let dataItem of reportData) {
      const feature = {
        type: 'Feature',
        properties: { HUC12: dataItem.huc12, HUC8: appContext.selectedHUC8 },
        geometry: {
          type: 'Point',
          coordinates: [dataItem.Longitude, dataItem.Latitude],
        },
      };
      featureCollectionFromReportData.features.push(feature);
    }
    if (featureCollectionFromReportData.features.length > 0) {
      const bbox_ = bbox(featureCollectionFromReportData);
      if (mapRef && mapRef.current) {
        mapRef.current.fitBounds(
          [
            [bbox_[0], bbox_[1]],
            [bbox_[2], bbox_[3]],
          ],
          {
            padding: { top: 40, bottom: 25, left: 45, right: 45 },
          }
        );
      }
    }
  };

  const clickRowHandler = (rowData: any, event: any) => {
    if (rowAlreadySelected(rowData)) {
      if (currentSelectedRowHTMLElement)
        currentSelectedRowHTMLElement.classList.remove('table-row__selected');
      setCurrentSelectedRowHTMLElement(null);
      setCurrentSelectedRowData({});
      createFeatureCollection(reportData);
      flyToInitialLocationHandler(appContext.selectedHUC8, mapRef, catalogsContext.huc8Catalog);
    } else {
      if (currentSelectedRowHTMLElement)
        currentSelectedRowHTMLElement.classList.remove('table-row__selected');
      const selectedRow = event.currentTarget as HTMLElement;

      if (selectedRow) {
        selectedRow.className += 'table-row__selected';
        setCurrentSelectedRowHTMLElement(selectedRow);
        setCurrentSelectedRowData(rowData);
      }
      createFeatureCollection([rowData]);
      flyToLngLat(
        mapRef,
        (rowData as ToxicsReleaseInvetoryResponseBodyModel).Longitude,
        (rowData as ToxicsReleaseInvetoryResponseBodyModel).Latitude
      );
    }
  };

  const rowAlreadySelected = (rowData: {}) => {
    const selectedRowValuesAsArray: string[] = Object.values(rowData);
    const currectSelectedRowValuesAsArray: string[] = Object.values(currentSelectedRowData);

    return arrayAreEqual(selectedRowValuesAsArray, currectSelectedRowValuesAsArray);
  };

  const arrayAreEqual = (array1: string[], array2: string[]) => {
    if (array1.length === array2.length) {
      return array1.every((element, index) => {
        if (element === array2[index]) {
          return true;
        }

        return false;
      });
    }

    return false;
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Year',
        accessor: 'Year',
      },
      {
        Header: 'Facility Name',
        accessor: 'Facility Name',
      },
      {
        Header: 'Street Address',
        accessor: 'Street Address',
      },
      {
        Header: 'City',
        accessor: 'City',
      },
      {
        Header: 'County',
        accessor: 'County',
      },
      {
        Header: 'St',
        accessor: 'St',
      },
      {
        Header: 'Industry Sector Code',
        accessor: 'Industry Sector Code',
      },
      {
        Header: 'Industry Sector',
        accessor: 'Industry Sector',
      },
      {
        Header: 'Chemical',
        accessor: 'Chemical',
      },
      {
        Header: 'Carcinogen',
        accessor: 'Carcinogen',
      },
      {
        Header: 'Classification',
        accessor: 'Classification',
      },
      {
        Header: 'One Time Release',
        accessor: 'One Time Release',
      },
      {
        Header: 'Potw Total Transfers',
        accessor: 'Potw Total Transfers',
      },
      {
        Header: 'Potw Trns Rlse',
        accessor: 'Potw Trns Rlse',
      },
      {
        Header: 'Potw Trns Trt',
        accessor: 'Potw Trns Trt',
      },
      {
        Header: 'On Site Release Total',
        accessor: 'On Site Release Total',
      },
      {
        Header: 'Off Site Release Total',
        accessor: 'Off Site Release Total',
      },
      {
        Header: 'Off Site Treated Total',
        accessor: 'Off Site Treated Total',
      },
      {
        Header: 'Total Releases',
        accessor: 'Total Releases',
      },
      {
        Header: 'Total Transfer',
        accessor: 'Total Transfer',
      },
      {
        Header: 'Frs Id',
        accessor: 'Frs Id',
      },
      {
        Header: 'Latitude',
        accessor: 'Latitude',
      },
      {
        Header: 'Longitude',
        accessor: 'Longitude',
      },
    ],
    []
  );

  const mapInitialViewState = {
    longitude: settings.defaultLocationMap.lng,
    latitude: settings.defaultLocationMap.lat,
    zoom: 3,
  };

  const drillDownMapSources = (mapSource: MapSource, innerSources: any[]) => {
    let filter = mapSource.source?.layerFilter;

    if (mapSource.source) {
      const sourceElement = (
        <Source
          key={uuidv4()}
          id={mapSource.source.id}
          type={mapSource.source.type as 'vector'}
          url={mapSource.source.tilesetURLs}
        >
          <Layer {...mapSource.source.layer} {...{ filter }} />
        </Source>
      );
      innerSources.push(sourceElement);
      drillDownMapSources(mapSource.source, innerSources);
    } else {
      return null;
    }
    return innerSources;
  };

  const onUpdate = useCallback((e: any | null) => {
    if (mapRef && mapRef.current) {
      const stationFeatures = mapRef.current.queryRenderedFeatures(
        [e.features[0].geometry.coordinates[0][0], e.features[0].geometry.coordinates[0][2]],
        {
          layers: ['pointlayer'],
        }
      );
    }
  }, []);

  const layerStyle: LayerProps = {
    id: 'pointlayer',
    type: 'circle' as const,
    paint: {
      'circle-radius': 8,
      'circle-color': ['match', ['get', 'Industry Sector Code'], ...IndustrySectorsMarkersColors],
      'circle-stroke-color': 'white',
      'circle-stroke-width': 1,
      'circle-opacity': 1,
    },
  };

  const onMouseEnter = useCallback((event: any | null) => {
    if (event.features && event.features[0]) {
      setCursor('pointer');
      setPopupInfo(event.features[0].properties);
    }
  }, []);
  const onMouseLeave = useCallback(() => {
    setCursor('auto');
    setPopupInfo(null);
  }, []);
  const bounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  const renderPopup = () => {
    return (
      <Popup
        style={{ color: 'white' }}
  
        longitude={Number(popupInfo.Longitude)}
        latitude={Number(popupInfo.Latitude)}
        onClose={() => setPopupInfo(null)}
        className='popup-container--dark'
      >
        <div className='popup-container'>
          <Row>
            <Col> Facility Name:</Col>
            <Col className='popup-content-highlight'>{popupInfo['Facility Name']}</Col>
          </Row>
          <Row>
            <Col> Chemical:</Col>
            <Col>{popupInfo['Chemical']}</Col>
          </Row>
          <Row>
            <Col> Industry Sector:</Col>
            <Col>{popupInfo['Industry Sector']}</Col>
          </Row>
          <Row>
            <Col> City:</Col>
            <Col>{popupInfo['City']}</Col>
          </Row>
          <Row>
            <Col> County:</Col>
            <Col>{popupInfo['County']}</Col>
          </Row>
          <Row>
            <Col> Address:</Col>
            <Col>{popupInfo['Street Address']}</Col>
          </Row>
        </div>
      </Popup>
    );
  };

  return (
    <FullScreenComp handle={fullscreenhandle}>
      <div className='toxics-release-inventory' id='toxics-release-inventory-report'>
        <ReportHeader global={global} data={reportData}
          reportID={"toxics-release-inventory-report"} fullScreenClickHandle={fullscreenhandle.enter} />
        <div className='container'>
          <Row>
            <Col>
              <div className='map-container'>
                <LoadingSpinner active={loading} />

                {tableData.length > 0 && loading == false && (
                  <>
                    <Map
                      ref={mapRef as Ref<MapRef>}
                      preserveDrawingBuffer={true}
                      mapboxAccessToken={settings.maboxKey}
                      initialViewState={mapInitialViewState}
                     mapStyle={global.mapStyle}
                      interactiveLayerIds={['pointlayer']}
                      cursor={cursor}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                      projection={global.globeView ? 'globe' : 'mercator' as any}
                      onClick={(e) => global.onMapClick(e)}
                      onMove={(e) => {
                        global.setViewport({
                          longitude: e.viewState.longitude,
                          latitude: e.viewState.latitude,
                          zoom: e.viewState.zoom,
                        })
                      }}
                    >
                      <FullscreenControl />
                      <NavigationControl />
                      <GeolocateControl />
                      <DrawControl
                        position='top-left'
                        displayControlsDefault={false}
                        controls={{
                          polygon: true,
                          trash: true,
                        }}
                      />
                      <RenderLayerMarkers global={global} bounds={reportBounds} zipOff={true} />
                      <RenderLayers global={global} mapRef={mapRef} />

                      {featureData && (
                        <Source id='circlesource' type='geojson' data={featureData}>
                          <Layer {...layerStyle} />
                        </Source>
                      )}
                      {popupInfo && renderPopup()}
                      {global.terraClimateLayerSelected || global.evapotranspirationSelected && (
                        <div style={{ bottom: "bottom: -5%;" }} className="range-slider" >
                          <Form.Range
                            style={{ marginBottom: '0' }}
                            min={2000}
                            max={2019}
                            value={global.MTCLValue}
                            onChange={e => global.setMTCLValue(parseInt(e.target.value))}
                          />
                          <strong>Year: {global.MTCLValue}</strong>
                        </div>
                      )}
                      <div className='map-title'>
                        <p>Viewing: HUC {appContext.selectedHUC8}</p>
                        {/* <p>{statusMessage}</p> */}
                      </div>
                      <div className='map-legend-container'>
                        <MapLegend
                          global={global}
                          legendWidth={320}
                        />
                      </div>
                    </Map>
                    {global.NOAASeaLevelRise && (
                      <div
                        style={{
                          width: '100%',
                          position: 'absolute',
                          right: '1.5%',
                          top: '62%'
                        }}
                        className="range-slider range-slider-noaa"
                      >
                        <Form.Range
                          style={{ marginBottom: "0" }}
                          min={0}
                          max={10}
                          value={global.feetIndex}
                          onChange={e => global.setFeetIndex(parseInt(e.target.value))}
                        />
                        <strong>Sea Level: {global.feetIndex}ft</strong>
                      </div>
                    )}
                    {global.terraClimateLayerSelected | global.evapotranspirationSelected && (
                      <div
                        className="range-slider"
                        style={{
                          position: 'absolute',
                          top: '80%',
                          right: '0%'
                        }}>
                        <Form.Range
                          style={{ marginBottom: '0' }}
                          min={2000}
                          max={2019}
                          value={global.MTCLValue}
                          onChange={e => global.setMTCLValue(parseInt(e.target.value))}
                        />
                        <strong>Year: {global.MTCLValue}</strong>
                      </div>
                    )}
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Legend</Accordion.Header>
                        <Accordion.Body>
                          <div className='quality-references'>
                            <table>
                              <tbody>
                                {/* <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#7f0800' }} />
                                  </td>
                                  <td> - Low</td>
                                </tr> */}
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#00cc0c' }} />
                                  </td>
                                  <td> - Beverages</td>
                                </tr>
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#0c00cc' }} />
                                  </td>
                                  <td> - Transportation Equipment</td>
                                </tr>
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#d10d00' }} />
                                  </td>
                                  <td> - Paper</td>
                                </tr>
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#4C5C78' }} />
                                  </td>
                                  <td> - Chemical Wholesalers</td>
                                </tr>
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#754D82' }} />
                                  </td>
                                  <td> - Electrical Equipment</td>
                                </tr>
                                {/* <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#CCC09F' }} />
                                  </td>
                                  <td> - 5 feet</td>
                                </tr> */}
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#F7A12F' }} />
                                  </td>
                                  <td> - Petroleum Bulk Terminals</td>
                                </tr>
                                {/* <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#29CCCC' }} />
                                  </td>
                                  <td> - 7 feet</td>
                                </tr> */}
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#894A92' }} />
                                  </td>
                                  <td> - Machinery</td>
                                </tr>
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#F232FF' }} />
                                  </td>
                                  <td> - Miscellaneous Manufacturing</td>
                                </tr>
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ff1100' }} />
                                  </td>
                                  <td> - Hazardous Waste</td>
                                </tr>
                                {/* <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#BD3CE5' }} />
                                  </td>
                                  <td> - 10 feet</td>
                                </tr> */}
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#da680f' }} />
                                  </td>
                                  <td> - Other</td>
                                </tr>
                                {/* <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#AE9690' }} />
                                  </td>
                                  <td> - 10 feet</td>
                                </tr> */}
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ba581f' }} />
                                  </td>
                                  <td> - Electric Utilities</td>
                                </tr>
                                {/* <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#3cff00' }} />
                                  </td>
                                  <td> - 10 feet</td>
                                </tr> */}
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#559807' }} />
                                  </td>
                                  <td> - Textiles</td>
                                </tr>
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#1a9850' }} />
                                  </td>
                                  <td> - Food</td>
                                </tr>
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#66bd63' }} />
                                  </td>
                                  <td> - Computers and Electronic Products</td>
                                </tr>
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#c7b585' }} />
                                  </td>
                                  <td> - Primary Metals</td>
                                </tr>
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#9ba832' }} />
                                  </td>
                                  <td> - Chemicals</td>
                                </tr>
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#edde07' }} />
                                  </td>
                                  <td> - Wood Products</td>
                                </tr>
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#d61125' }} />
                                  </td>
                                  <td> - Petroleum</td>
                                </tr>
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#6e151e' }} />
                                  </td>
                                  <td> - Fabricated Metals</td>
                                </tr>
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#365587' }} />
                                  </td>
                                  <td> - Plastics and Rubber</td>
                                </tr>
                                <tr>
                                  <td>
                                    <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#9ebae8' }} />
                                  </td>
                                  <td> - Nonmetallic Mineral Product</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                )}

              </div>
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '23rem', marginTop: 7 }}>
              <ReportTable
                customWidth={'90vw'}
                data={tableData}
                columns={tableColumns}
                clickRowHandler={clickRowHandler}
              />
            </Col>
          </Row>
          <ReportFooter />
        </div>
      </div>
      <MapSlider global={global} />
    </FullScreenComp>
  );
}

export default ToxicsRealeaseInventory;
