import { parse } from 'papaparse';
import { Layer, Source, FillLayer } from 'react-map-gl'
import {
    useState,
    useEffect,
    useMemo,
    useContext,
    useCallback
} from 'react'
import settings from '../../settings.json'
import HucPopup from '../../components/HucPopup'
import agent from '../../api/agent';
import { RefContext } from '../../RefContext';
import { message_hucrestriction } from '../../Constants';
import { adjustHexBrightness } from '../../features/utils';


const TileHover12 = ({ global, mapRef, mode, opacity, brightness }: any) => {

    const { pathname } = window.location;

    const [huc, setHuc] = useState<any>('')
    const [huc12, setHuc12] = useState<any>()
    const [hucCatalog, setHUCCatalogue] = useState<any>()
    const [hucData, setHucData] = useState()
    const [huc12Data, setHuc12Data] = useState()

    const hucRestrict = global.hucRestrict;

    const { currentRef } = useContext<any>(RefContext)
    const mapInstance = currentRef && currentRef?.current

    useEffect(() => {

        const huc12FileURL = '/huc12_info.csv';
        fetch(huc12FileURL)
            .then((response) => response.text())
            .then((responseText) => {
                const hucCatalog: any = parse(responseText, {
                    header: true,
                }).data;
                setHUCCatalogue(hucCatalog);
            })
    }, [])

    useEffect(() => {
        const foundHUC = huc12 && hucCatalog && hucCatalog.find((i: any) => {
            return i.huc12 == huc12
        })
        setHuc12Data(foundHUC)
    }, [huc12, hucCatalog])

    useEffect((): any => {
        if (global.action && global.action.lngLat && global.action.lngLat.lat && global.action.lngLat.lng) {
            const lat = global.action && global.action.lngLat.lat
            const lng = global.action && global.action.lngLat.lng

            agent
                .Utilities
                .LatLngToHuc12(lat, lng) //returns Huc12 as 'Huc8'
                .then(({ body }: any) => {
                    const res = body && body[0]?.Huc8
                    if (res && body[0] && body[0].Huc8 && body[0].Huc8.length > 5) {
                        if(pathname !== '/' )(global.setSelectedHUC8(res.substr(0,8))) 
                        if (res.charAt(0) === '0') {
                            const newRes = res.substring(1)
                            setHuc12(newRes)
                        } else {
                            setHuc12(res)
                        }
                    }
                })
        }
    }, [
        global.action?.lngLat.lng,
        global.action?.lngLat.lat
    ])


    const huc12sLayers: FillLayer = {
        id: `tile-hover-12`,
        source: 'True Elements - HUC 12s',
        "source-layer": 'True Elements - HUC 12s',
        type: 'fill',
        // beforeId: settings.defaultBeforeId,
        filter: [
            '==',
            'HUC12',
            !huc12 ? '' : huc12
        ],
        paint: {
            "fill-color": `${adjustHexBrightness('#BD1C1C', brightness)}`,
            "fill-opacity": opacity,
            'fill-outline-color': `${adjustHexBrightness('#BD1C1C', brightness)}`
        },
    }

    useEffect(() => {
        if (huc12) {
            global.setGlobalHuc12(huc12)
        }
    }, [huc12])

    useEffect(() => {
        return () => setHuc12(undefined)
    }, [])

    return (
        <>
            <Source
                type="vector"
                url={settings.tileSetURLs.HUC12}
                id={`tile-hover-12`}
            >
                <Layer {...huc12sLayers} />
            </Source>
            {huc12Data && huc12 !== '' && <HucPopup global={global} info={hucData} huc12Data={huc12Data} />}
        </>
    )
}

export default TileHover12

