const MapPincircle = ({
  color = 'blue',
  height = 50,
  onMouseEnter = () => {},
  onMouseExit = () => {},
}: {
  color?: string;
  height?: number;
  onMouseEnter?: (e: any) => void;
  onMouseExit?: (e: any) => void;
}): JSX.Element => {
  return (
    <svg
      height={height}
      width={height}
      onMouseEnter={(e) => onMouseEnter(e)}
      onMouseLeave={onMouseExit}
    >
      <circle
        cx='10'
        cy='10'
        r='10'
        stroke='black'
        strokeWidth='1'
        fill={color}
      />
    </svg>
  );
};

export default MapPincircle;
