// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.google-map-container {
  width: calc(100% - 80px);
  height: calc(100% - 72px);
  position: absolute;
  z-index: 0; }

.right-header-component {
  width: 360px; }

.drinking-water {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #0d6efd;
  padding: 18px; }
`, "",{"version":3,"sources":["webpack://./src/features/reports/GoogleMapsDemo/google-map-styles.scss"],"names":[],"mappings":"AAAA;EACI,wBAAwB;EACxB,yBAAyB;EACzB,kBAAkB;EAClB,UAAU,EAAA;;AAGd;EACI,YACJ,EAAA;;AAEA;EACI,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,aAAa,EAAA","sourcesContent":[".google-map-container {\n    width: calc(100% - 80px);\n    height: calc(100% - 72px);\n    position: absolute;\n    z-index: 0;\n}\n\n.right-header-component {\n    width: 360px\n}\n\n.drinking-water {\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border: 3px solid #0d6efd;\n    padding: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
