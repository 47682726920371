import {
    useRef,
    useEffect,
    useState
} from 'react'

import { Row, Col, Card, Button, Alert, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faQuestion, faGear, faBell, faPowerOff, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import SplashCard from './SplashCard'
import trueQIDisclosure from '../../assets/trueQIDisclosure.jpg'
import waterMolecule from '../../assets/waterMolecule.jpg'
import seaLevelRise from '../../assets/seaLevelRise.jpg'
import hucSplash from '../../assets/hucSplash.png'
import logo from '../../assets/te-logo.png'
import rect1 from '../../assets/Rectangle139.png'
import rect2 from '../../assets/Rectangle142.png'
import rect3 from '../../assets/Rectangle144.png'
import rectM from '../../assets/RectangleWM.png'
import splashqi from '../../assets/splashqi.svg'
import splashBanner from '../../assets/splashBanner.svg'
import Routes from '../../ReportsRoutes';
import './splash.scss';

import { useKeycloak } from '@react-keycloak/web';
import { Location, Navigate, useLocation, useNavigate } from 'react-router';
import { getNewUserAlert, setNewUserAlert } from '../../utils/store';
import { message_welcome, message_welcome_newuser } from '../../Constants';


const Splash = ({ global, userOrgString, setShowNewUserAlert, showNewUserAlert }: any) => {
    const navigate = useNavigate();
    const { keycloak } = useKeycloak();
    const ref = useRef(0)
    const count = ref?.current + 1

    const [role, setRole] = useState()

    const closeShowNewUserAlert = () => {
        setNewUserAlert();
        setShowNewUserAlert(false);
    };

    useEffect(() => {
        const reportsContainer = document.getElementById("reportsContainer");
        const toastContainer = document.querySelector(".Toastify");
        if (reportsContainer) {
            reportsContainer.style.width = "100vw !important"
            reportsContainer.style.maxWidth = "unset"
            reportsContainer.style.overflow = "hidden"
        } //@ts-ignore
        if(toastContainer){ toastContainer.style.display = 'none';}
  
        return () => {//@ts-ignore
            if(toastContainer){ toastContainer.style.display = 'block' }
            if (reportsContainer) {
                reportsContainer.style.width = "100vw !important"
                reportsContainer.style.maxWidth = "calc(100% - 80px)"
                reportsContainer.style.overflow = "auto"
            }
        }
    }, [])


    const onLogOut = () => {
        if (keycloak?.authenticated) {
            
            keycloak.logout({ redirectUri: window.location.origin + '/login' }).then(() => {
                keycloak.clearToken();
            });
        }

        global.setAuthenticatedUser(false);
        sessionStorage.removeItem("authKey");
        global.setUserLogged(false);
        navigate('/login');
    }


    return (
        <Col ref={ref} className="col-12 splash-container">
            <div style={{ height: 'auto', position: 'relative' }}>
                <img src={splashBanner} className='splash-banner' />
                <Button
                    type='button'
                    className='btn btn-default btn-circle notifications splash-logout'
                    onClick={onLogOut}>
                    <FontAwesomeIcon icon={faPowerOff} />
                </Button>
            </div>
            <Alert variant="success" closeVariant={'white'} className={"app-alert-main"} show={showNewUserAlert && getNewUserAlert()} onClose={() => closeShowNewUserAlert()} dismissible>
                <Alert.Heading>{message_welcome}</Alert.Heading>
                <p>
                    Thank you for logging onto True Elements Insights. </p><p>Your account is still being created.  A notification will be sent when your subscribed access is available.
                </p>
                <Image className={'app-alert-main-img'} src='logo.png' />
            </Alert>
            <div className="sheen"></div>
            <Row style={{ width: '100%' }}>
                <Col className="col-12 flex-column d-flex justify-content-center align-items-center h-50 splash-top">
                    <h3>Welcome to True Elements <img src={logo} /></h3>
                    <h4>The water intelligence product you chose is highlighted below</h4>
                    <br />
                </Col>
            </Row>
            <Row className="col-12 flex-row d-flex justify-content-around align-items-center h-50 splash-bottom">
                <SplashCard
                    org={userOrgString}
                    id={'WatershedExplorer'}
                    img={rect3}
                    text={'For deep, accurate understanding of watershed dynamics and analytics that provide critical insights for water resource management and planning​'}
                    key={userOrgString + 1}
                    url={Routes.Default}
                    heading={'Digital Watershed Explorer'}
                    topNav={'Insights'}
                    global={global}
                />

                <SplashCard
                    org={userOrgString}
                    id={'ScenarioForecaster'}
                    key={userOrgString + 2}
                    img={rect2}
                    text={'For deep insights into future water quality and quantity issues to support strategic decision making for management of risk to assets, locations or other risk concerns'}
                    url={Routes.ClimatePrecipitationCalculator}
                    heading={'Digital Scenario Forecaster'}
                    topNav={'Climate'}
                    global={global}
                />

                <SplashCard
                    org={userOrgString}
                    key={userOrgString + 3}
                    id={'AssessmentReporter'}
                    img={rect1}
                    text={'For fast, accurate, easy to understand information for project planning or disclosure reporting'}
                    url={Routes.Risk + '?ent=1&inds=SVHL'}
                    heading={'Site Assessment Reporter'}
                    topNav={'Risk'}
                    global={global}
                />

                <SplashCard
                    org={userOrgString}
                    key={userOrgString + Routes.WaterMonitoring}
                    id={'DataMonitor'}
                    img={rectM}
                    text={'For convenient management continuously updated water sensor, sample or lab data enhanced by additional data layers to provide accurate, reliable insights into site specific water quality and quantity issues'}
                    url={Routes.WaterMonitoring}
                    heading={'Field Water Data Monitor'}
                    topNav={'Data Management'}
                    global={global}
                />
            </Row>
        </Col>
    )
}

export default Splash