import React, { useState, useMemo, useEffect } from 'react';
import { Form, Button, Row, Col, FloatingLabel, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown, faFileExport, faCancel, faRectangleXmark, faTrashCan } from '@fortawesome/pro-solid-svg-icons';

import InputForm from '../../components/input-form';
import companyIcon from '../../assets/company-icon.svg';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import SettingsBreadcrumb from '../account/SettingsBreadcrumb';
import agent from '../../api/agent';

import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import { parse, unparse } from 'papaparse';

export default function TestInfo(props: any){
	const { global } = props;
	global.setUserLogged(true);

	let _mode = 'View';
	let uInfo = global.userInfo || {};
	let _org = '';
	let _user_id = '';


	const [testData, setTestData] = useState<any[]>([]);

	useEffect(() => {
/*		const huc12InfoFileURL = '/temp/CCR.csv';
		    fetch(huc12InfoFileURL)
		      .then(response => response.text())
		      .then(responseText => {
		        let data: any[] = parse(responseText, {
		          header: true,
		        }).data;
		        console.log(data);
		        for (let dataItem of data) {
		        	if(dataItem.CCR_Website){
		        		let newURL = new URL(dataItem.CCR_Website) //@ts-ignore
		        		//let site = URL(dataItem.CCR_Website);
		        		let hostname = newURL.hostname
		        		dataItem.hostname = newURL.hostname
		        	}
                }
		        setTestData(data)
		      });*/
    }, []);    

	useEffect(() => {
		if(testData){

		}
    }, [testData]);   


	  const renderTestData = () => {


	    return testData.map((item: any, index:any) => {
	      return (
			<div key={uuidv4()} style={{width: 400, height: 114, position: 'relative'}}>
				<span style={{display:'inline-block'}} className={'mapslider-color-span'}>{item.PWS_ID} {item.hostname}</span> 
				<p><a  href={item.CCR_Website}><img style={{width: 50, height: 50, marginRight: 20}} src={`https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${item.hostname}&size=128`} />{item.Water_System_Name}</a></p>
			</div>
	      )
	    })
	  }

	return (
		<div className='add-user'>
			<SettingsBreadcrumb title={'Test Information'}  subtitle={''}  />
			<h2>Test Information</h2>

			<div  style={{height: 'calc(100vh - 200px)', width: '90vw', display: 'flex', flexWrap: 'wrap'}}>
				{renderTestData()}
			</div>


		</div>
	);
}


