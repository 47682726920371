// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.watershed-map-container {
  margin-top: 1rem;
  width: 40vw !important;
  padding: 0;
  border-radius: 5px;
  background-color: #0b1736;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 37vh;
  box-shadow: 0px 0px 20px #000; }
  .watershed-map-container .mapboxgl-popup-content {
    background-color: #18244c;
    color: white; }

.report__container-background {
  margin-top: 1rem;
  border-radius: 5px; }
`, "",{"version":3,"sources":["webpack://./src/features/reports/WhatsInMyWatershed/styles.scss"],"names":[],"mappings":"AAAA;EACI,gBAAgB;EAChB,sBAAsB;EACtB,UAAU;EACV,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,6BAA6B,EAAA;EAXjC;IAcQ,yBAAyB;IACzB,YAAY,EAAA;;AAIpB;EACI,gBAAgB;EAChB,kBAAkB,EAAA","sourcesContent":[".watershed-map-container {\n    margin-top: 1rem;\n    width: 40vw !important;\n    padding: 0;\n    border-radius: 5px;\n    background-color: #0b1736;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 37vh;\n    box-shadow: 0px 0px 20px #000;\n\n    .mapboxgl-popup-content { \n        background-color: #18244c;\n        color: white;\n     }\n}\n\n.report__container-background {\n    margin-top: 1rem;\n    border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
