import { useContext, useEffect } from 'react';
import { AppContext } from '../../../AppContext';
import { Button, Dropdown } from 'react-bootstrap';
import { useFullScreenHandle } from 'react-full-screen';
import fullscreenIcon from '../../../assets/fullscreen-icon.svg';
import ellipsisIcon from '../../../assets/ellipsis-icon.svg';
import FilterState from './FilterState';
import { mapLayersOff, customLayersOff } from '../../utils';
import { unparse } from 'papaparse';
import { Location, useLocation } from 'react-router-dom';
import ReportsConfigs from '../../../ReportsConfigs';
import html2canvas from 'html2canvas';
import { jsPDF, ImageOptions } from "jspdf";
import autoTable from 'jspdf-autotable';
import localForage from "localforage";
import { DateTime } from 'luxon';
import hideMenuRightIcon from '../../../assets/hide-right-menu-icon.svg';
import showMenuRightIcon from '../../../assets/show-right-menu-icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faLayerGroup } from '@fortawesome/pro-solid-svg-icons';
import dashboardIcon from '../../../assets/dashboard.svg';
import Layers from '../../../layout/Layers/Layers';
import { DefaultReport } from "../PrintReports/DefaultReport";
import { message_hucrestriction, message_nodatalocation, message_erroroccured } from '../../../Constants';
import shpwrite from '@mapbox/shp-write';
import agent from '../../../api/agent';
import { RefContext } from '../../../RefContext';
import MoveSearch from './MoveSearch';

import queryString from 'query-string';
import {
  QueryParamProvider,
  useQueryParam,                   
  BooleanParam,
  StringParam
} from 'use-query-params';


const ReportOptions = (props: any) => {
  const { global } = props;
  const location: Location = useLocation();
  const fullscreenhandle = useFullScreenHandle() as any;
  const reportName = ReportsConfigs.find(x => x.route == location.pathname)?.name || 'Report_download';
  const appContext = useContext(AppContext);
  const nowTime = DateTime.local().toFormat('_ydL_Hms_');
  const nowCity = appContext.currentLocation;
  const { currentRef } = useContext(RefContext);
  const restrictedRoutesView = ['/DailyLakeMeadLevelsReport']
  const restrictedRoutesLayers = ['/DailyLakeMeadLevelsReport']

  //when flagged true, export data
  const [qsex, setqsex] = useQueryParam('ex', BooleanParam); 
  const [qsexf, setqsexf] = useQueryParam('exf', StringParam);  //export format -- csv, json

  useEffect(() => {
    if (props.data && props.data.length> 0 && qsex && qsex === true) {

      if(qsexf && qsexf==='json'){
        exportToJSON();
        setqsexf(null);
        setqsex(null);
      } else {
        exportToCsv();
        setqsex(null);
      }
      
    }
  }, [props.data]);

  useEffect(() => {
    populateDashboard();
  }, []);

  const onSelectOptions = (event: any, e: any) => {
    if (event === "TrueQI Layers") {
      e.stopPropagation();
      setTimeout(() => {
        global.SetMenuRightOpen(true);
      }, 400);
    } else if (event === "Clear Map Data Layers") {
      mapLayersOff(global);
    } else if (event === "Export as csv") {
      exportToCsv();
    } else if (event === "Export as PDF") {
      exportToPdf();
    } else if (event === "Export as PNG") {
      exportToPng();
    } else if (event === "Export as SHP") {
      exportToSHP();
    } else if (event === "Clear Uploaded Layers") {
      localStorage.removeItem('CustomLocationData_Key')
      global.setCustomLocations([])
      customLayersOff(global)
      window.location.reload()
    }
  };

  const onSelectDashboardOptions = (e: any, event: any) => {
    //if(e !== 'All'){
    saveReportLink(e);
    //}
  };

  const populateDashboard = () => {
    //console.log('populateDashboard', localStorage.getItem('useremail'))
    if (!global.dashboardProjects || (global.dashboardProjects && global.dashboardProjects.length < 1)) {
      let useremail = 'user';
      const ue_ls = localStorage.getItem('useremail');
      if (ue_ls && ue_ls.length > 4) {
        useremail = ue_ls;
      }

      agent.Utilities.GetDashboard(useremail).then((res: any) => {
        if (res && res.body) {
          if ((res.statusCode === 200) && (res.body.length === 0)) {
            const firstProject = { TITLE: 'Project', DESCRIPTION: '', EMAIL: useremail };
            agent.Utilities.CreateDashboard(firstProject).then((res: any) => {
              console.log('firstProject', res)
              if (res && res.body && res.body.dashboard_id) {
                const newDashboardId = res.body.dashboard_id;
                const newDash = [{ title: 'Project', description: '', email: useremail, dashboard_id: newDashboardId, timestamp: newDashboardId }];
                global.setDashboardProjects(newDash);
                global.setSelectedDashboardProject(newDash[0].dashboard_id);
              }
            })
          } else if ((res.statusCode === 200) && (res.body.length > 0)) {
            let returnData = [...res.body]
            for (let dataItem of returnData) {
              dataItem.timestamp = dataItem.dashboard_id;
            }
            returnData.sort(
              (x: any, y: any) =>
                  x['timestamp'] - y['timestamp']
            );
            global.setDashboardProjects(returnData);
            if (!global.selectedDashboardProject) {
              global.setSelectedDashboardProject(returnData[0].dashboard_id);
            }
          }
        }
      });
    }
  };

  const saveReportLink = (parentid: string) => {
    let dashboardid = parentid;
    if (parentid === 'All') {
      if (!global.selectedDashboardProject) {
        if (global.dashboardProjects && global.dashboardProjects.length > 0)
          global.setSelectedDashboardProject(global.dashboardProjects[0].dashboard_id);
      } else {
        dashboardid = global.selectedDashboardProject.dashboard_id;
      }
    }

    if (global && location && dashboardid) {
      const timestamp = DateTime.local().toMillis();

      let reportLink_ = { parent: dashboardid, timestamp: timestamp, currentLocation: appContext.currentLocation, huc: appContext.selectedHUC8, pathname: location.pathname, search: location.search, hash: location.hash };

      if (reportLink_.pathname === "/GroundwaterDetermination") {
        //reportLink_.search = reportLink_.search+'&gddsh='
      }

      let payload = { DASHBOARD_ID: dashboardid, REPORT_DATA: JSON.stringify(reportLink_), SUBTITLE: "" };
      console.log(reportLink_)
      agent.Utilities.CreateReport(payload).then((res: any) => {
        console.log("SetReport", payload, res);
        if (res && res.errorMessage) {
          global.notify(message_erroroccured);
          console.log('Error: saveReportLink ' + parentid + res.errorMessage);
          return;
        } else {
          const notifyMessage = reportName + ' (' + appContext.currentLocation + ') link saved to Dashboard';
          global.notify(notifyMessage);
        }
      })

      saveToDashboardPng('' + timestamp);

    } else {
      console.log('Error: saveReportLink ' + parentid);
      global.notify(message_erroroccured);
    }

  }

  const saveToDashboardPng = (timestamp: string) => {

    const reportElement = document.getElementById(props.reportID);
    //console.log(props, reportElement) 
    if (reportElement) {
      setTimeout(() => {
        const aspectRatio = reportElement.offsetHeight / reportElement.offsetWidth;
        const aspectWidth = Math.min(reportElement.offsetWidth, 480);
        const aspectHeight = Math.min(reportElement.offsetHeight, 480 * aspectRatio);
        html2canvas(reportElement, { logging: false, allowTaint: true }).then(canvas => {
          if (canvas) {
            //var ctx = extra_canvas.getContext('2d');
            //if (ctx) {
            //ctx.fillStyle = "#FF0000";
            //ctx.fillRect(0, 0, canvas.width, canvas.height);

            canvas.toBlob((blob) => {
              localForage.setItem(timestamp, blob);
            });
          }
        });
      }, 400);
    }
  }

  const exportToPng = () => {
    const reportElement = document.getElementById(props.reportID);
    if (reportElement) {
      setTimeout(() => {
        const aspectRatio = reportElement.offsetHeight / reportElement.offsetWidth;
        const aspectWidth = reportElement.offsetWidth;
        const aspectHeight = reportElement.offsetWidth;
        html2canvas(reportElement).then(canvas => {
          if (canvas) {
            const image = canvas.toDataURL("image/png", 1.0);
            let hiddenElement = document.createElement('a');
            hiddenElement.download = reportName + nowTime + nowCity;
            hiddenElement.href = image;
            hiddenElement.click();
            if (hiddenElement.parentNode) {
              hiddenElement.parentNode.removeChild(hiddenElement);
            }
            hiddenElement.remove();
          }
        });
      }, 400);
    }
  }

  const exportToThumbnail = () => {
    const reportElement = document.getElementById(props.reportID);
    if (reportElement) {
      setTimeout(() => {
        const aspectRatio = reportElement.offsetHeight / reportElement.offsetWidth;
        const aspectWidth = Math.min(reportElement.offsetWidth, 480);
        const aspectHeight = Math.min(reportElement.offsetHeight, 480 * aspectRatio);
        html2canvas(reportElement, { logging: false, allowTaint: true }).then(canvas => {
          var extra_canvas = document.createElement("canvas");
          extra_canvas.setAttribute('width', '480px');
          extra_canvas.setAttribute('height', aspectHeight + 'px');
          var ctx = extra_canvas.getContext('2d');
          if (ctx) {

            ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, aspectWidth, aspectHeight);
            var dataURL = extra_canvas.toDataURL("image/png", 1.0);
            let hiddenElement = document.createElement('a');
            hiddenElement.download = reportName + nowTime + nowCity;
            hiddenElement.href = dataURL;
            hiddenElement.click();
            if (hiddenElement.parentNode) {
              hiddenElement.parentNode.removeChild(hiddenElement);
            }
            hiddenElement.remove();
          }
        });
      }, 400);
    }
  }

  const exportToPdf = () => {
  
    const reportElement = document.getElementById(props.reportID)||document.getElementById("reportsContainer");

    if (reportElement) {
      let reportConfig = ReportsConfigs.find(x => x.route == location.pathname);
      
      setTimeout(() => {//@ts-ignore
        reportConfig.appContext = appContext;//@ts-ignore
        reportConfig.location = location;
        const aspectRatio = reportElement.offsetHeight / reportElement.offsetWidth;
        DefaultReport(props.data, reportConfig, props.global, reportElement);
      


        /*        html2canvas(reportElement, { logging: false }).then(canvas => {
                  const image = canvas.toDataURL("image/png", 1.0);
                  var doc = new jsPDF()
                  doc.addImage(image, 'PNG', 0, 0, 210, (210 * (aspectRatio)));
        
                  const tableElement = document.getElementById('table-expanded-thistable');
                  if (tableElement && props.reportID === 'risk-report') {
                    doc.addPage();
                    autoTable(doc, { html: '#table-expanded-thistable' })  //useCss: true
                  } else if (tableElement && props.reportID !== 'risk-report') {
                    doc.addPage();
                    autoTable(doc, { html: '#table-expanded-thistable', horizontalPageBreak: true, horizontalPageBreakRepeat: 'City' })
                  }
        
                  doc.setProperties({
                    title: reportName + nowTime + nowCity
                  })
        
                  //window.open(doc.output('bloburl'), '_blank');
                  doc.save(reportName + nowTime + nowCity);
                });*/
      }, 400);
    }
  }

  const exportToCsv = () => {
    const csv = unparse(props.data);
    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = reportName + nowTime + nowCity + '.csv';
    hiddenElement.click();
    if (hiddenElement.parentNode) {
      hiddenElement.parentNode.removeChild(hiddenElement);
    }
    hiddenElement.remove();
  }

  const exportToJSON = () => {
    const csv = JSON.stringify(props.data);
    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = reportName + nowTime + nowCity + '.json';
    hiddenElement.click();
    if (hiddenElement.parentNode) {
      hiddenElement.parentNode.removeChild(hiddenElement);
    }
    hiddenElement.remove();
  }


  const exportToSHP = () => {
    // @ts-ignore
    const mapInstance = currentRef && currentRef?.current

    if (mapInstance) {//@ts-ignore
      let mapLayers = mapInstance.getStyle().layers?.map(a => a.id);
      let layerCollection = ['pointlayer', 'linelayer', 'nitrateassessmentlayer', 'outflowslayer', 'AllFireData', '2023_wildfires', '2023_wildfires', 'water_watch_sites', '303d_Impaired_Lakes', '303d_Impaired_Rivers', 'WRF_Country_Risk', 'gemstat_stations_australia', 'gemstat_stations_philippines', 'gemstat_stations_usa', 'flood_hazard_1_50000', 'philippines_with_regions', 'Australia_Bores', 'NGIS_Aquifer', 'NGIS_Aquifer', 'NGIS_Aquifer', 'Principle_Hydrogeology', 'BIA_National_LAR', 'philippine_rivers', 'philippine_basins', 'counties-2agbeg', 'res_mask', 'res_mask', 'I10_huc12s', 'nhd_hr_nhdflowline_one', 'nhd_hr_nhdflowline_two', 'nhd_hr_nhdwaterbody', 'nhd_hr_nhdarea', 'deloitte_fema_zones', 'deloitte_fema', 'RES_FEMA_LAYER', 'FEMA_Flood_Hazard_Zones', 'FEMA_Flood_Hazard_Zones', 'Aqueduct_Water_Risk_Atlas_Annual', 'Aqueduct_Water_Risk_Atlas_Annual', 'Aqueduct_Water_Risk_Atlas_Annual', 'FEMA_Flood_Hazard_Boundaries', 'water_systems_service_boundary1m', 'water_systems_service_boundary1m', 'water_systems_service_boundary1m', 'WDPA_WDOECM_Feb2023_Public_USA', 'LA_SLR', 'MS_SLR', 'MD_SLR', 'MA_SLR', 'NH_SLR', 'NJ_SLR', 'NY_SLR', 'OR_SLR', 'WA_SLR', 'SC_SLR', 'PR_SLR', 'Guam_SLR', 'USVI_SLR', 'CNMI_SLR', 'AS_SLR', 'CA_SLR', 'TX_SLR', 'NC_SLR', 'VA_SLR', 'HI_SLR', 'FL_TBW_slr_final_dist', 'ME_SLR', 'PA_SLR', 'DE_SLR', 'CT_SLR', 'DC_SLR', 'RI_SLR', 'AL_SLR', 'EPA_Superfund_NPL', 'east_palestine_model', 'east_palestine_model'];
      let layerlist = [] as any;

      if (mapLayers.indexOf('bridge-oneway-arrow-white') > -1 && mapLayers.indexOf('aerialway') > -1) {
        let filterLayers = mapLayers.slice(mapLayers.indexOf('bridge-oneway-arrow-white') + 1, mapLayers.indexOf('aerialway'));
        if (filterLayers && filterLayers.length < 10) {
          layerCollection = layerCollection.concat(filterLayers)
        }
      }

      if (mapLayers.indexOf('continent-label') > -1 && mapLayers.indexOf('gl-draw-polygon-fill-inactive.cold') > -1) {
        let filterLayers = mapLayers.slice(mapLayers.indexOf('continent-label') + 1, mapLayers.indexOf('gl-draw-polygon-fill-inactive.cold'));
        if (filterLayers && filterLayers.length < 10) {
          layerCollection = layerCollection.concat(filterLayers)
        }
      }

      //@ts-ignore
      const filteredArray = mapLayers.filter(value => layerCollection.includes(value));

      const layerFeatures = mapInstance.queryRenderedFeatures(undefined, {
        layers: filteredArray,
      });
      console.log(layerFeatures)
      const options = {
        folder: `exportshp`,
        filename: `exportshp_${props.reportID}_${props.global.selectedHUC8}`,
        outputType: "blob",
        compression: "DEFLATE",
        types: {
          point: "points",
          polygon: "polygons",
          polyline: "lines",
        },
      };

      if (layerFeatures) {
        setTimeout(() => {
          if (shpwrite) {
            //@ts-ignore
            shpwrite.download({
              type: 'FeatureCollection',
              features: layerFeatures, //@ts-ignore
            }, options);
          }
        }, 400);
      }


      /*         
                if(shpwrite){
                  //@ts-ignore
                  shpwrite.download(props.features, options);
                }
      */
    }
  }


  const showLayers = (event: any) => {
    //e.stopPropagation();    
    setTimeout(() => {
      global.SetMenuRightOpen(true);
    }, 400);
  };

  const onClickMenu = (event: any) => {
    if (global.MenuRightOpen) {
      setTimeout(() => {
        global.SetMenuRightOpen(true);
      }, 400);
    }
    else {
      setTimeout(() => {
        global.SetMenuRightOpen(true);
      }, 400);
    }
  };
  
  //    {( (global.waterQualityPortal || (props.filterMode && (props.filterMode.indexOf('reportcomp') > -1))) && <FilterState data={props.data} global={global}   mode={'reportcomp'} reportID={props.reportID} />)}


  return (
    <div className={`header-options-container text-right ${(props.absolute) ? "header-options-container-absolute" : ""}`} >
      <MoveSearch global={global} />
      <FilterState global={global}    data={props.filterLabelDays} mode={props.filterMode} />
      {(props.filterMode && props.filterMode.indexOf('groundwater') > -1 && <FilterState data={props.data3} data2={props.data2} global={global} mode={'groundwater'} />)}
      {(props.filterMode && props.filterMode.indexOf('report') > -1 && <FilterState data={props.data} data2={props.data2} global={global} reportID={props.reportID} mode={'report'} />)}
      {(props.filterMode && props.filterMode.indexOf('risk') > -1 && <FilterState data={location} global={global} mode={'risk'} />)}
      {(props.filterMode && props.filterMode.indexOf('climatecalc') > -1 && <FilterState data={location} global={global} mode={'climatecalc'} />)}
      {(props.filterMode && props.filterMode.indexOf('upload') > -1 && <FilterState data={location} global={global} mode={'upload'} />)}
      {(props.filterMode && props.filterMode.indexOf('import') > -1 && <FilterState data={location} global={global} mode={'import'} />)}
      {(props.filterMode && props.filterMode.indexOf('sasb') > -1 && <FilterState data={props.data} data2={props.data2} data3={props.data3} global={global} mode={'sasb'} reportID={props.reportID} />)}
      <FilterState data={props.data} global={global}   mode={'reportcomp'} reportID={props.reportID} />
      {(!props.filterAppliedOff && <FilterState global={global} data={location} mode={'applied'} />)}

      <Button className="btn-right-menu" onClick={onClickMenu}>
        <img
          src={!global.MenuRightOpen ? showMenuRightIcon : hideMenuRightIcon}
        />
      </Button>

      <Button
        onClick={props.fullScreenClickHandle}
        className='fullscreen float-end'
        style={{ marginRight: 5 }}
      >
        <img id="fullscreenIMG" src={fullscreenIcon} />
      </Button>

      {props.sidebarright && (<Button className='btn-right-menu' onClick={onClickMenu}>
        <img
          src={!global.MenuRightOpen ? showMenuRightIcon : hideMenuRightIcon}
        />
      </Button>)}

      {props.layersmenu && (<Layers global={global} {...global}/>)}

      <Button
        onClick={showLayers}
        className='btn-options'
        style={props.layersmenudepr ? { display: 'inline-flex', marginLeft: 5 } : { display: 'none' }}
      >
        <FontAwesomeIcon icon={faLayerGroup} className="mr-2" />
      </Button>

      <Dropdown navbar={true} onSelect={onSelectDashboardOptions} className="dropdown-more-options-container">
        <Dropdown.Toggle className="dropdown-more-options dropdown-dashboard">
          <img id="dashboardIMG" src={dashboardIcon} style={{ transform: 'translateY(.5px) scale(1.5)', marginRight: 5 }} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Header>Save to Dashboard</Dropdown.Header>
          <Dropdown.Divider />
          {global.dashboardProjects.map((dash: any, index: any) =>
            <Dropdown.Item key={'dropdown-more-options' + dash.timestamp + index} eventKey={dash.timestamp}>{'  • ' + dash.title}</Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown navbar={true} onSelect={onSelectOptions} className="dropdown-more-options-container">
        <Dropdown.Toggle className="dropdown-more-options">
          <img src={ellipsisIcon} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item eventKey="TrueQI Layers">True Elements Scores & Network Menu</Dropdown.Item>
          <Dropdown.Item eventKey="Clear Map Data Layers">Clear all Map Layers</Dropdown.Item>
          <Dropdown.Item eventKey="Clear Uploaded Layers">Clear Uploaded Layers</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item eventKey="Export as csv">Export as CSV</Dropdown.Item>
          <Dropdown.Item eventKey="Export as PDF">Export as PDF</Dropdown.Item>
          <Dropdown.Item eventKey="Export as PNG">Export as PNG</Dropdown.Item>
          <Dropdown.Item eventKey="Export as SHP">Export as SHP</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ReportOptions;
