import { useState, useEffect } from 'react';
import OrganizationInfo from './OrganizationInfo'
import EditOrganization from './EditOrganization'

const WaterQuality = (props: any) => {

    const {
        global,
        action,
        setAction,
        data,
        setOrgData,
        orgData,
        usersData,
        usersDataAssigned,
        setUsersDataAssigned,
        currentOrg,
        setCurrentOrg,
        orgCredentialsType,
        setOrgCredentialsType,
        wqxCredentials,
        setWqxCredentials
    } = props

    const renderScreen = () => {
        if (action === 'table') {
            return <OrganizationInfo
                global={global}
                action={action}
                setAction={setAction}
                orgID={currentOrg}
                setOrgID={setCurrentOrg}
                setOrgData={setOrgData}
                orgData={orgData}
                currentOrg={currentOrg}
                setCurrentOrg={setCurrentOrg}
                orgCredentialsType={orgCredentialsType}
                setOrgCredentialsType={setOrgCredentialsType}
                wqxCredentials={wqxCredentials}
                setWqxCredentials={setWqxCredentials}
            />
        }
        
        if (action === 'edit') {
            return <EditOrganization
                title={'Edit'}
                global={global}
                action={action}
                setAction={setAction}
                orgID={currentOrg}
                setOrgID={setCurrentOrg}
                data={data}
                usersData={usersData}
                usersDataAssigned={usersDataAssigned}
                setUsersDataAssigned={setUsersDataAssigned}
                orgCredentialsType={orgCredentialsType}
                setOrgCredentialsType={setOrgCredentialsType}
                wqxCredentials={wqxCredentials}
                setWqxCredentials={setWqxCredentials}
            />
        }

        if (action === 'create') {
            return <EditOrganization
                title={'Create'}
                global={global}
                action={action}
                setAction={setAction}
                orgID={1111}
                setOrgID={setCurrentOrg}
                usersData={usersData} 
                usersDataAssigned={usersDataAssigned}
                setUsersDataAssigned={setUsersDataAssigned}
                data={[{"org_id": 1111, "name": "New Name", "email": "", "description": "", "is_active": true, "epa_org_id": ""}]}
                currentOrg={currentOrg}
                setCurrentOrg={setCurrentOrg}
                orgCredentialsType={orgCredentialsType}
                setOrgCredentialsType={setOrgCredentialsType}
                wqxCredentials={wqxCredentials}
                setWqxCredentials={setWqxCredentials}
            />
        }
    }

    return <>{renderScreen()}</>
}

export default WaterQuality