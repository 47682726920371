import { useState, useContext, useEffect } from 'react'
import { Layer, Source, FillLayer, Marker } from 'react-map-gl'
import { adjustHexBrightness } from '../../features/utils';
import { useNavigate } from 'react-router-dom';
import { RefContext } from '../../RefContext';
import { PointContext } from '../../PointContext';
import { LayerDataContext } from '../../LayerDataContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'


const USGSHuc12Layer = ({
    global,
    sourceLayer,
    id,
    opacity,
    url,
    reportRoute,
    action,
    brightness,
    tableColumns, sourceLink, sourceLabel
  }: any) => {

  const [properties, setProperties] = useState<any>()
  const [open, setOpen] = useState(false)

  const { currentRef } = useContext(RefContext)
  const { setCurrentPoint } = useContext(PointContext)
  const { setCurrentLayerData } = useContext(LayerDataContext);

  const getFeaturesAtPoint = async (map: any, event: any) => {
    const features = await map.queryRenderedFeatures(event.point)?.[0]; 
    setProperties(features.properties);
    setOpen(true)
  };
  // @ts-ignore
  const map = currentRef?.current
  const navigate = useNavigate();

  const goTo = ({ lat, lng }: any, data: any) => {
    setCurrentPoint?.({ lat, lng });

    setCurrentLayerData?.({
        ...data, tableColumns, sourceLink, sourceLabel, year: global.USGSYear
    });

    setTimeout(() => navigate(reportRoute), 100);
  }

  useEffect(() => { 
    if(map) {
      map.on('click', (e:any) => getFeaturesAtPoint(map, e))
    }
  }, [map])

  const handleClose = () => {
    setOpen(false)
    setProperties(null)
  }
  
  const USGSHuc12Paint: FillLayer = {
    id,
    source: id,
    "source-layer": `${sourceLayer}`,
    type: "fill",
    paint: {
        "fill-opacity": opacity,
        "fill-color": [
            "interpolate",
            ["linear"],
            ["to-number", ["get", global.dateString]], 
            0, 'transparent',
            0.05,`${adjustHexBrightness("#3366CC", brightness, 10)}`,
            0.1, `${adjustHexBrightness("#3255c9", brightness, 10)}`,
            0.5, `${adjustHexBrightness("#3425db", brightness, 10)}`,
            1,   `${adjustHexBrightness("#8A2BE2", brightness, 10)}`,
            5,   `${adjustHexBrightness("#9932CC", brightness, 10)}`,
            10,  `${adjustHexBrightness("#800080", brightness, 10)}`,
            15,  `${adjustHexBrightness("#9400D3", brightness, 10)}`,
            20,  `${adjustHexBrightness("#d400ff", brightness, 10)}`,
            30,  `${adjustHexBrightness("#00FF00", brightness, 10)}`,
            60,  `${adjustHexBrightness("#80FF00", brightness, 10)}`,
            90,  `${adjustHexBrightness("#FFFF00", brightness, 10)}`,
            120, `${adjustHexBrightness("#FF8000", brightness, 10)}`,
            150, `${adjustHexBrightness("#FF0000", brightness, 10)}`,
          ]
    },
  };

  return (
    <>
    <Source
      type="vector"
      url={url}
      id={id}
    >
      <Layer {...USGSHuc12Paint} />
    </Source>
    {properties && open && (
                <Marker
                    latitude={action.lngLat.lat}
                    longitude={action.lngLat.lng}
                    style={{
                        color: 'white',
                        position: 'relative',
                        backgroundColor: "#002044",
                        padding: '0.25rem',
                        paddingLeft: '0.5rem',
                        paddingRight: '0.5rem',
                        borderRadius: '4px',
                        maxWidth: '150px',
                        textAlign: 'left',
                    }}
                    anchor="bottom"
                >
                    <FontAwesomeIcon style={{ position: 'absolute', right: "5%", cursor: 'pointer' }} onClick={handleClose} icon={faXmark} />
                    <div style={{
                        color: 'white',
                        backgroundColor: "#002044",
                        padding: '0.5rem',
                        borderRadius: '4px',
                        maxWidth: '150px',
                        textAlign: 'left',
                    }}>
                        <p>HUC12: {properties.huc12}</p>
                        <a
                            style={{
                                color: '#4fc2d9',
                                fontWeight: 'bold',
                                textDecoration: 'underline',
                                backgroundColor: 'transparent',
                                borderRadius: '6px',
                                cursor: 'pointer'
                            }}
                            onClick={() => goTo(action.lngLat, properties)}
                        >
                            Go to report
                        </a>
                    </div>
                </Marker>
            )}
    </>
  )
}

export default USGSHuc12Layer