import React, { useState, useEffect, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { adjustHexBrightness } from '../../features/utils';

const EastPalestine = ({
    global,
    id,
    opacity,
    outlineColor,
    tileSet,
    sourceLayer,
    data,
}: any) => {
    const [properties, setProperties] = useState<any[]>([]);
    const [paint, setPaint] = useState<any[]>([
        ['==', ['get', 'huc12'], '050301010606'],
        ['interpolate', ['linear'], ['get', 'volatile_contaminants_with_air_deposition'], 0.1070297040930324, '#EE0903'],
    ]);

    useEffect(() => {
        const { features } = data;

        const huc12List: string[] = [];
        const propertiesList: any[] = [];

        features.forEach((feature: any) => {
            const { properties } = feature;

            if (properties.huc12) {
                huc12List.push(properties.huc12);
                propertiesList.push(properties);
            }
        });

        global.setHoursIndex(0);

        const hours: number[] = Array.from({ length: 300 }, (_, i) => i);

        global.setHours(hours);
        global.setHoursIndex(0);
        setProperties(propertiesList);
    }, [data]);

    const handleColor = (input: any) => {
        const num = parseFloat(input);

        if (num < 1e-7) {
            return `${adjustHexBrightness('#067A01', global.EPOLayerBrightness)}`;
        } else if (num < 1e-5) {
            return `${adjustHexBrightness('#EEF004', global.EPOLayerBrightness)}`;
        } else if (num < 1e-4) {
            return `${adjustHexBrightness('#EF9E03', global.EPOLayerBrightness)}`;
        } else if (num < 0.01) {
            return `${adjustHexBrightness('#EE0903', global.EPOLayerBrightness)}`;
        } else {
            return 'transparent';
        }
    };

    const handlePaint = (input: any) => {
        const paintList = input.map(({ value, huc12 }: any) => [
            ['==', ['get', 'huc12'], huc12],
            ['interpolate', ['linear'], ['get', global.contaminate], value, handleColor(value)],
        ]);

        setPaint(paintList.flat());
    };

    useEffect(() => {
        const list = properties.map((e: any) => ({ value: e[global.hoursIndex], huc12: e.huc12 }));
        handlePaint(list);
    }, [
        global.hoursIndex, 
        global.contaminate, 
        properties
    ]);

    const vectorLineLayer = {
        id: `${id}-line-layer`,
        type: 'line',
        'source-layer': sourceLayer,
        paint: {
            'line-opacity': opacity,
            'line-color': outlineColor,
            'line-width': 3,
        },
    };

    const vectorTileLayer = useMemo(() => ({
        id: `${id}-layer`,
        type: 'fill',
        'source-layer': sourceLayer,
        paint: {
            "fill-opacity": opacity,
            'fill-outline-color': outlineColor,
            'fill-color': [
                'case', 
                ...paint, 
                'transparent'
            ],
        },
    }), [
        id, 
        sourceLayer, 
        outlineColor, 
        paint,
        opacity
    ]);

    return (
        <>
            <Source id={`${id}-line-layer`} type="vector" url={tileSet}>
                {/* @ts-ignore */}
                <Layer {...vectorLineLayer} />
            </Source>
            <Source id={`${id}-layer`} type="vector" url={tileSet}>
                {/* @ts-ignore */}
                <Layer {...vectorTileLayer} />
            </Source>
        </>
    );
};

export default EastPalestine;