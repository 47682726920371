import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './time-range-picker.scss';

const TimeRangePicker = ({ onSubmit, initialStartTime, maxTime }: any) => {
  const [startHour, setStartHour] = useState(0);
  const [endHour, setEndHour] = useState(0);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [maxDate, setMaxDate] = useState<Date | null>(null);
  const [initialSetupDone, setInitialSetupDone] = useState(false);

  // Function to format date to MM/DD/YYYY
  const formatDate = (date: Date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  useEffect(() => {
    if (initialStartTime) {
      const initialStart = new Date(initialStartTime);
      const initialEnd = new Date(initialStart);
      initialEnd.setDate(initialStart.getDate() + 30);

      const maxDate = new Date(maxTime);

      setStartDate(initialStart);
      setEndDate(initialEnd);
      setStartHour(initialStart.getHours());
      setEndHour(initialEnd.getHours());
      setMaxDate(maxDate);

      setInitialSetupDone(true);
    }
  }, [initialStartTime]);

  useEffect(() => {
    if (initialSetupDone) {
      handleSubmit();
    }
  }, [initialSetupDone]);

  const handleSubmit = (e?: any) => {
    if (e) e.preventDefault();

    if (startDate && endDate) {
      const startDateTime = Math.floor(new Date(startDate).setHours(startHour, 0, 0, 0) / 1000);
      const endDateTime = Math.floor(new Date(endDate).setHours(endHour, 0, 0, 0) / 1000);

      onSubmit({
        startTime: startDateTime,
        endTime: endDateTime,
      });
    }
  };

  const renderHoursOptions = () => {
    const options = [];
    for (let i = 0; i < 24; i++) {
      options.push(
        <option key={i} value={i}>
          {i < 10 ? `0${i}:00` : `${i}:00`}
        </option>
      );
    }
    return options;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col className="col-8 date-col">
          <Form.Group controlId="formStartDate">
            <Form.Label className="small">Start Date</Form.Label>
            <DatePicker
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
              dateFormat="MM/dd/yyyy"
              className="form-control-sm"
              maxDate={maxDate}
              showPopperArrow={false}
              placeholderText="MM/DD/YYYY"
            />
          </Form.Group>
        </Col>
        <Col className="col-4">
          <Form.Group controlId="formStartTime">
            <Form.Label className="small">Start Time</Form.Label>
            <Form.Control
              className="form-control-sm"
              as="select"
              value={startHour}
              onChange={(e) => setStartHour(parseInt(e.target.value))}
              required
            >
              {renderHoursOptions()}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col className="col-8 date-col">
          <Form.Group controlId="formEndDate">
            <Form.Label className="small">End Date</Form.Label>
            <DatePicker
              selected={endDate}
              onChange={(date: Date) => setEndDate(date)}
              dateFormat="MM/dd/yyyy"
              className="form-control-sm"
              minDate={startDate}
              maxDate={maxDate}
              showPopperArrow={false}
              placeholderText="MM/DD/YYYY"
            />
          </Form.Group>
        </Col>
        <Col className="col-4">
          <Form.Group controlId="formEndTime">
            <Form.Label className="small">End Time</Form.Label>
            <Form.Control
              className="form-control-sm"
              as="select"
              value={endHour}
              onChange={(e) => setEndHour(parseInt(e.target.value))}
              required
            >
              {renderHoursOptions()}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col className="col-12 d-flex justify-content-center">
          <Button
            className="mt-2 submit-btn"
            variant="primary"
            type="submit"
            size="sm"
          >
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default TimeRangePicker;
