// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wi-popup-content {
  width: 100%; }
  .wi-popup-content .close-btn {
    padding: 2px;
    font-weight: bolder;
    font-size: 14px; }
  .wi-popup-content .wi-popup-name,
  .wi-popup-content .wi-popup-link {
    padding: 4px; }
  .wi-popup-content .wi-popup-link {
    cursor: pointer; }
`, "",{"version":3,"sources":["webpack://./src/components/WaterImpairments/wi-impairment-layer.scss"],"names":[],"mappings":"AAAA;EACI,WAAW,EAAA;EADf;IAGQ,YAAY;IACZ,mBAAmB;IACnB,eAAe,EAAA;EALvB;;IASqB,YAAY,EAAA;EATjC;IAUqB,eAAe,EAAA","sourcesContent":[".wi-popup-content {\r\n    width: 100%;\r\n    .close-btn {\r\n        padding: 2px;\r\n        font-weight: bolder;\r\n        font-size: 14px;\r\n    }\r\n\r\n    .wi-popup-name,\r\n    .wi-popup-link { padding: 4px; }\r\n    .wi-popup-link { cursor: pointer; }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
