import { Layer, Source } from 'react-map-gl'
import { FillLayer } from 'mapbox-gl';
import { useEffect, useContext } from 'react';
import { RefContext } from '../../../RefContext'

const NOAASeaLevelRise = ({
    id,
    beforeId = "",
    fillColor,
    opacity,
    outlineColor,
    tileSet,
    sourceLayer
}: any) => {


    const NOAASeaLevelRise: FillLayer = {
        id: `${id}`,
        type: "fill",
        "source-layer": `${sourceLayer}`,
        paint: {
            "fill-opacity": opacity,
            "fill-outline-color": `${outlineColor}`,
            'fill-color': fillColor,
        },
    };

    return (
        <>
            <Source
                id={`${id}`}
                type="vector"
                url={tileSet}
            >
                <Layer
                    beforeId={beforeId}
                    {...NOAASeaLevelRise}
                />
            </Source>
        </>
    )
}

export default NOAASeaLevelRise;  
