// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-btn {
  color: white;
  position: absolute;
  top: 5%;
  right: 3%;
  font-weight: bolder;
  cursor: pointer; }
`, "",{"version":3,"sources":["webpack://./src/components/Layers/CVILayer/cvi-layer.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,kBAAkB;EAClB,OAAO;EACP,SAAS;EACT,mBAAmB;EACnB,eAAe,EAAA","sourcesContent":[".close-btn {\r\n    color: white;\r\n    position: absolute;\r\n    top: 5%;\r\n    right: 3%;\r\n    font-weight: bolder;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
