import {
  useMemo,
  useState,
  useEffect
} from 'react'
import ReportTable from '../../../reports/shared/ReportTable'
import {
  Button,
  Form,
  Row, Col,
  FloatingLabel
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import {
  faPenToSquare,
  faTrashCan,
  faCalendar
} from '@fortawesome/pro-solid-svg-icons';
import Import from './Import'
import ImportII from './ImportII';
import ImportDetails from './ImportDetails'
import GlobalImportRules from '../GlobalImportRules';
import { exportToCsv } from '../../../utils';

const Imports = (props: any) => {

  const {
    setImportDetails,
    setImportAction,
    setImportActionII,
    setGlobalImportRules,
    projectsData,
    orgData,
    refreshWQX,
    currentOrg
  } = props


  const [data, setData] = useState<any[]>([]);
  const [action, setAction] = useState('importaction')

  const [entitySelection, setEntitySelection] = useState('')
  const [parsedData, setParsedData] = useState<any[]>([]);

  const [importText, setImportText] = useState<any[]>([]); 

  const renderScreens = () => {

      if (action === 'importaction') {
        return <Import
                          setImportDetails={setImportDetails}
                          setImportAction={setImportAction}
                          setImportActionII={setImportActionII}
                          setGlobalImportRules={setGlobalImportRules}
                          importText={importText}
                          setImportText={setImportText}
                          action={action}
                          setAction={setAction}
                          setData={setData}
                          data={data}
                          projectsData={projectsData}
                          entitySelection={entitySelection}
                          setEntitySelection={setEntitySelection}
                          currentOrg={currentOrg}
                      />
      }

      if (action === 'importaction2') {
        return <ImportII
                          setImportDetails={setImportDetails}
                          setImportAction={setImportActionII}
                          importText={importText}
                          setImportText={setImportText}
                          action={action}
                          setAction={setAction}
                          setData={setData}
                          data={data}
                          setParsedData={setParsedData}
                          orgData={orgData}
                          projectsData={projectsData}
                          entitySelection={entitySelection}
                          setEntitySelection={setEntitySelection}
                          currentOrg={currentOrg}
                      />
      }

      if (action === 'importdetails') {
        return <ImportDetails 
                          importText={importText}
                          setImportText={setImportText}
                          action={action}
                          setAction={setAction}
                          setData={setData}//
                          data={data}         //
                          parsedData={parsedData}  //
                          projectsData={projectsData}      
                          refreshWQX={refreshWQX} 
                          currentOrg={currentOrg}                              
                      />
      }
  }

  return <>{renderScreens()}</>
}


export default Imports