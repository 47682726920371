import { useState, useEffect, useMemo } from 'react'
import Render from "../../../components/render";
import "./report-title.scss";
import exitIcon from "../../../assets/exit-icon.svg";
import { v4 as uuidv4 } from 'uuid';
import TitlePill from './TitlePill';
import LayerConfig from '../../../LayerConfig';

interface Props {
  title?: string;
  rootLabel?: string;
  global?: any;
  resptitle?: any;
}

export default function ReportTitle(props: Props) {
  return (
    <div
      className={
        props.resptitle ? "title-container smaller-title" : "title-container"
      }
    >
      <p>{props.rootLabel}</p>
      <h1>{props.title}</h1>
      <LayersFilter global={props.global} {...props.global} />
    </div>
  );
}

function LayersFilter({
  global,

  resFEMA, setResFEMA,

  twitterSelected, setTwitterSelected,

  waterSystemBoundariesSelected, setWaterSystemBoundariesSelected,
  waterSystemBoundaryTier1, setWaterSystemBoundaryTier1,
  waterSystemBoundaryTier2, setWaterSystemBoundaryTier2,
  waterSystemBoundaryTier3, setWaterSystemBoundaryTier3,
  femaZones, setFemaZones,
  femaBoundaries, setFemaBoundaries,


  deloitteFemaZones, setDeloitteFemaZones,
  deloitteFema, setDeloitteFema,

  toggleHighwayStyle, setToggleHighwayStyle,

  customLayer1, setCustomLayer1,
  customLayer2, setCustomLayer2,
  customLayer3, setCustomLayer3,
  customLayer4, setCustomLayer4,
  customLayer5, setCustomLayer5,
  customLayer6, setCustomLayer6,
  customLayer7, setCustomLayer7,
  customLayer8, setCustomLayer8,
  customLayer9, setCustomLayer9,
  customLayer10, setCustomLayer10,
  customLayer11, setCustomLayer11,
  customLayer12, setCustomLayer12,
  customLayer13, setCustomLayer13,
  customLayer14, setCustomLayer14,
  customLayer15, setCustomLayer15,
  customLayer16, setCustomLayer16,
  customLayer17, setCustomLayer17,
  customLayer18, setCustomLayer18,
  customLayer19, setCustomLayer19,
  customLayer20, setCustomLayer20,

  AppleLocationData, setAppleLocationData,
  Hydrobasins, setHydrobasins,
  drinkingWaterQualitySelected, setDrinkingWaterQualitySelected,
  wastewaterSelected, setWastewaterSelected,
  industrySelected, setIndustrySelected,
  surfacewaterTrueQI, setSurfacewaterTrueQI,
  agWaterTrueQI, setAgWaterTrueQI,
  stormWaterTrueQI, setStormWaterTrueQI,
  Wise, setWise,
  x
}: any) {

  const [orderedPills, setOrderedPills] = useState<any[]>([])

  let pillObjects = useMemo(() => {

      let unconfigedObjects = [
      {
        name: "WISE Water Framework Directive Database",
        condition: Wise,
        setCondition: setWise,
      },    
      {
        name: 'African Hydrobasins',
        condition: Hydrobasins,
        setCondition: setHydrobasins,
      }, 
      {
        name: 'Apple Organization Location Data',
        condition: AppleLocationData,
        setCondition: setAppleLocationData,
      },
      {
        name: "User Custom Layer 1",
        condition: customLayer1,
        setCondition: setCustomLayer1
      },
      {
        name: "User Custom Layer 2",
        condition: customLayer2,
        setCondition: setCustomLayer2
      },
      {
        name: "User Custom Layer 3",
        condition: customLayer3,
        setCondition: setCustomLayer3
      },
      {
        name: "User Custom Layer 4",
        condition: customLayer4,
        setCondition: setCustomLayer4
      },
      {
        name: "User Custom Layer 5",
        condition: customLayer5,
        setCondition: setCustomLayer5
      },
      {
        name: "User Custom Layer 6",
        condition: customLayer6,
        setCondition: setCustomLayer6
      },
      {
        name: "User Custom Layer 7",
        condition: customLayer7,
        setCondition: setCustomLayer7
      },
      {
        name: "User Custom Layer 8",
        condition: customLayer8,
        setCondition: setCustomLayer8
      },
      {
        name: "User Custom Layer 9",
        condition: customLayer9,
        setCondition: setCustomLayer9
      },
      {
        name: "User Custom Layer 10",
        condition: customLayer10,
        setCondition: setCustomLayer10
      },
      {
        name: "User Custom Layer 11",
        condition: customLayer11,
        setCondition: setCustomLayer11
      },
      {
        name: "User Custom Layer 12",
        condition: customLayer12,
        setCondition: setCustomLayer12
      },
      {
        name: "User Custom Layer 13",
        condition: customLayer13,
        setCondition: setCustomLayer13
      },
      {
        name: "User Custom Layer 14",
        condition: customLayer14,
        setCondition: setCustomLayer14
      },
      {
        name: "User Custom Layer 15",
        condition: customLayer15,
        setCondition: setCustomLayer15
      },
      {
        name: "User Custom Layer 16",
        condition: customLayer16,
        setCondition: setCustomLayer16
      },
      {
        name: "User Custom Layer 17",
        condition: customLayer17,
        setCondition: setCustomLayer17
      },
      {
        name: "User Custom Layer 18",
        condition: customLayer18,
        setCondition: setCustomLayer18
      },
      {
        name: "User Custom Layer 19",
        condition: customLayer19,
        setCondition: setCustomLayer19
      },
      {
        name: "User Custom Layer 20",
        condition: customLayer20,
        setCondition: setCustomLayer20
      },
      {
        name: "Highways & Bridges",
        condition: toggleHighwayStyle,
        setCondition: setToggleHighwayStyle
      },
      {
        name: "Deloitte Zones",
        condition: deloitteFema,
        setCondition: setDeloitteFema
      },
      {
        name: "Deloitte Fema Zones",
        condition: deloitteFemaZones,
        setCondition: setDeloitteFemaZones
      },
      {
        name: "RES FEMA",
        condition: resFEMA,
        setCondition: setResFEMA
      },
      {
        name: "FEMA Flood Hazard Boundaries",
        condition: femaBoundaries,
        setCondition: setFemaBoundaries
      },
      {
        name: "Water System Boundaries",
        condition: waterSystemBoundariesSelected,
        setCondition: setWaterSystemBoundariesSelected
      }
    ];

    const layerArray = LayerConfig.map(layer => ({
            name: layer["name"],
            condition: global[layer["selected"]],
            setCondition: global[layer["setSelected"]]
    }))

    return layerArray.concat(unconfigedObjects)
  }, [global]) 
 
  useEffect(() => {
    const names = global.pillNames
    let pills: any[] = []


    pillObjects.forEach((pill:any) => {
      if(pill.condition) {
        if(pill.name === "WQP") {
          pills.push(pill)
        }
        if(pill.name === "Top 100 Polluters") {
          pills.push(pill)
        }

        if(pill.name === "Highways & Bridges") {
          pills.push(pill)
        }

        if(pill.name === "User Custom Layer 1") {
          pills.push(pill)
        }
        
        if(pill.name === "User Custom Layer 2") {
          pills.push(pill)
        }
        
        if(pill.name === "User Custom Layer 3") {
          pills.push(pill)
        }
        
        if(pill.name === "User Custom Layer 4") {
          pills.push(pill)
        }
        
        if(pill.name === "User Custom Layer 5") {
          pills.push(pill)
        }
        
        if(pill.name === "User Custom Layer 6") {
          pills.push(pill)
        }
        
        if(pill.name === "User Custom Layer 7") {
          pills.push(pill)
        }
        
        if(pill.name === "User Custom Layer 8") {
          pills.push(pill)
        }
        
        if(pill.name === "User Custom Layer 9") {
          pills.push(pill)
        }
        
        if(pill.name === "User Custom Layer 10") {
          pills.push(pill)
        }
        
        if(pill.name === "User Custom Layer 11") {
          pills.push(pill)
        }
        
        if(pill.name === "User Custom Layer 12") {
          pills.push(pill)
        }
        
        if(pill.name === "User Custom Layer 13") {
          pills.push(pill)
        }
        
        if(pill.name === "User Custom Layer 14") {
          pills.push(pill)
        }
        
        if(pill.name === "User Custom Layer 15") {
          pills.push(pill)
        }
        
        if(pill.name === "User Custom Layer 16") {
          pills.push(pill)
        }
        
        if(pill.name === "User Custom Layer 17") {
          pills.push(pill)
        }
        
        if(pill.name === "User Custom Layer 18") {
          pills.push(pill)
        }
        
        if(pill.name === "User Custom Layer 19") {
          pills.push(pill)
        }
        
        if(pill.name === "User Custom Layer 20") {
          pills.push(pill)
        }

        if(pill.name === 'Apple Organization Location Data') {
          pills.push(pill)
        }
        
      }
    })

    names.forEach((name:any) => {
      const pill = pillObjects.find((pill:any) => pill.name === name)
      pills.push(pill)
    })

    setOrderedPills(pills)
  },[global.pillNames, pillObjects])

  return (
    <div className="layers-filters">
      {orderedPills.map((pill: any) => <TitlePill key={uuidv4()} global={global} {...pill}/>)}
      <TitlePill name={"Drinking Water Quality"} global={global} condition={drinkingWaterQualitySelected} setCondition={setDrinkingWaterQualitySelected}/>
      <TitlePill name={"Wastewater"} global={global} condition={wastewaterSelected} setCondition={setWastewaterSelected}/>
      <TitlePill name={"Industry Water"} global={global} condition={industrySelected} setCondition={setIndustrySelected}/>
      {/* <TitlePill name={"303(d) Watershed Health"} global={global} condition={global.h303DTrueQI} setCondition={global.setH303DTrueQI}/> */}
      <TitlePill name={"Surface Water True QI"} global={global} condition={surfacewaterTrueQI} setCondition={setSurfacewaterTrueQI}/>
      <TitlePill name={"Agriculture Water True QI"} global={global} condition={agWaterTrueQI} setCondition={setAgWaterTrueQI}/>
      <TitlePill name={"Storm Water True QI"} global={global} condition={stormWaterTrueQI} setCondition={setStormWaterTrueQI}/>
      <TitlePill name={"X.com"} global={global} condition={twitterSelected} setCondition={setTwitterSelected}/>
      <TitlePill name={"Toxic 100 Water Polluters"} global={global} condition={global.pollutionSelected} setCondition={global.setPollutionSelected}/>
    </div>
  );
}



