import { useState, useEffect, useContext, useMemo, useRef } from "react";
import { AppContext } from "../../../AppContext";
import { Form, Tabs, Tab } from "react-bootstrap";
import { Accordion } from "react-bootstrap";
import { parse } from "papaparse";
import Map, { FullscreenControl, Source, Layer } from "react-map-gl";
import RenderLayers from "../shared/RenderLayers";
import RenderLayerMarkers from "../shared/RenderLayerMarkers";
import settings from "../../../settings.json";
import ReportTable from "../shared/ReportTable";
import agent from "../../../api/agent";
import {
  faChevronRight,
  faChevronLeft,
  faMaximize,
  faMinimize,
} from "@fortawesome/pro-solid-svg-icons";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReportMarkers from "../../../components/ReportMarkers/ReportMarkers";
import Breadcrumb from "../shared/Breadcrumb";
import { RefContext } from "../../../RefContext";
import ReportItem from "./ReportItem";
import _ from "lodash";
import { getReportFromStorage } from "../../utils";
import ReportFooter from "../shared/ReportFooter";
import ReportHeader from "../shared/ReportHeader";
import "./styles.scss";

const GroundwaterMexico = (props: any) => {
  const MEXICO_REPORTS = "MEXICO_REPORTS";

  const { global, api, currentReports } = props;

  const { updateContext, selectedHUC8: basinId } = useContext(AppContext);

  const [reports, setReports] = useState<any>([]);
  const [activeIndex, setActiveIndex] = useState<any>(1);
  const [activeReport, setActiveReport] = useState<any>();
  const [centroidData, setCentroidData] = useState<any>();
  const [newList, setNewList] = useState<any>();
  const [type, setType] = useState<any>();
  const [open, setOpen] = useState(false);
  const [back, setBack] = useState(0);
  const [next, setNext] = useState(4);
  const [dataKeys, setDataKeys] = useState<any>();
  const [tableData, setTableData] = useState<any[]>();
  const [tableHeaders, setTableHeaders] = useState<any[]>();

  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;

  const handleFilters = (input: any, reportList: any) => {
    // setReports(reportList)
    if (global.checkFilter.length > 0) {
      const array: any = reportList;
      for (let i = 0; i < array.length; i++) {
        let name = input[i];
        _.remove(array, (n: any) => n.sitename === name);
      }
      setNewList(array);
    } else {
      setNewList(reportList);
    }
    // @ts-ignore
    setReports(JSON.parse(localStorage.getItem(MEXICO_REPORTS)));
  };

  useEffect(() => {
    handleFilters(global.checkFilter, reports);
  }, [global.checkFilter]);

  const buildTableData = (input: any) => {
    let array: any = [];
    let array2: any = [];
    for (let key in input) {
      array2.push(key);
      array.push(input[key]);
    }
    setDataKeys(array);
  };

  useMemo(() => {
    const data = dataKeys && {
      Year: dataKeys[0],
      "Total Alkalinity Value (TAV) mg/L": dataKeys[1],
      "TAV Quality": dataKeys[2],
      "Conductivity, microSiemens/cm (conduct mscm)": dataKeys[3],
      "Conduct mscm Quality": dataKeys[4],
      "Total Dissolved Solids (TDS) mg/L": dataKeys[5],
      "TDS Agriculture Quality": dataKeys[7],
      "TDS Salinity Quality": dataKeys[8],
      "Total Fluorides (F-) mg/L": dataKeys[9],
      "(F-) Quality": dataKeys[10],
      "Hardness mg/L": dataKeys[11],
      "Hardness Quality": dataKeys[12],
      "Fecal Coliforms (FC) per 100ml": dataKeys[13],
      "FC quality": dataKeys[14],
      "Nitrates/Nitrogen mg/L": dataKeys[15],
      "Nitrates/Nitrogen Quality": dataKeys[16],
      "Total Arsenic (TA) mg/L": dataKeys[17],
      "TA Quality": dataKeys[18],
      "Total Cadmium (TC) mg/L": dataKeys[19],
      "TC Quality": dataKeys[20],
      "Total Chromium (TCh) mg/L": dataKeys[21],
      "TCh Quality": dataKeys[22],
      "Total Mercury (Hg) mg/L": dataKeys[23],
      "Hg Quality": dataKeys[24],
      "Lead mg/L": dataKeys[25],
      "Lead Quality": dataKeys[26],
      "Manganese (Mn) mg/L": dataKeys[27],
      "Mn Quality": dataKeys[28],
      "Iron (Fe) mg/L": dataKeys[29],
      "Fe Quality": dataKeys[30],
      "Level of Pollution": dataKeys[31],
      Contaminates: dataKeys[32],
      "Alkalinity Classification": dataKeys[33],
      "Condensation Classification": dataKeys[34],
      "Agricultural Classification": dataKeys[35],
      "Salinity Classification": dataKeys[36],
      "Fluoride Classification": dataKeys[37],
      "Hardness Classification": dataKeys[38],
      "CF Classification": dataKeys[39],
      "Nitrates Classification": dataKeys[40],
      "Arsenic Classification": dataKeys[41],
      "Cadmium Classification": dataKeys[42],
      "Chromium Classification": dataKeys[43],
      "Mercury Classification": dataKeys[44],
      "Lead Classification": dataKeys[45],
      "Manganese Classification": dataKeys[46],
      "Iron Classification": dataKeys[47],
    };
    setTableData([data]);
  }, [dataKeys]);

  const buildHeaders = (input: any) => {
    const headers = [];
    for (let key in input[0]) {
      headers.push({ Header: key, accessor: key });
    }
    setTableHeaders(headers);
  };

  useMemo(() => tableData && buildHeaders(tableData), [tableData]);

  useEffect(() => {
    const num = global.period === 0 ? 1 : 0;
    const tableData = activeReport && activeReport.data[num];
    buildTableData(tableData);
  }, [activeReport, global.period]);

  const max = {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-evenly",
    width: "98%",
    position: "absolute",
    top: "8%",
    height: "100vh",
    zIndex: "10",
  };

  const min = {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-evenly",
    width: "98%",
    top: "8%",
  };

  const flyToLocation = (input: any, zoom: number) => {
    if (input !== undefined) {
      return mapRef.current?.flyTo({
        center: [parseFloat(input.longitude), parseFloat(input.latitude)],
        essential: true,
        zoom,
      });
    }
  };

  useEffect(() => {
    const siteNames =
      reports && reports.map((report: any) => report.sitename).sort();
    global.setCheckFilters(siteNames);
  }, [reports]);

  useEffect(() => {
    const geoMex = "/csv/mexico_geocoded.csv";
    fetch(geoMex)
      .then((response) => response.text())
      .then((responseText) => {
        const data = parse(responseText, {
          header: true,
        }).data;
        setCentroidData(data);
      });
  }, []);

  useEffect(() => {
    // @ts-ignore
    updateContext("2900", "Mexico City", currentReports);
    global.setUserLogged(true);
    global.setHUCsMexico(true);
    global.setLoading(true);
    setType(getReportFromStorage());
    api("2900")
      .then(({ body }: any) => {
        let array = [];
        let stringed = [];
        for (const property in body) {
          array.push(body[property]);
        }
        setReports(array);
        localStorage.setItem(MEXICO_REPORTS, JSON.stringify(array));
      })
      .then(() => {
        const siteNames =
          reports && reports.map((report: any) => report.sitename).sort();
        global.setCheckFilters(siteNames);
      })
      .finally(() => global.setLoading(false));

    return () => global.setHUCsMexico(false);
  }, []);

  useEffect(() => {
    global.setLoading(true);
    api(basinId)
      .then(({ body }: any) => {
        let array = [];
        for (const property in body) {
          array.push(body[property]);
        }
        setReports(array);
        localStorage.setItem(MEXICO_REPORTS, JSON.stringify(array));
      })
      .then(() => {
        const siteNames =
          reports && reports.map((report: any) => report.sitename).sort();
        global.setCheckFilters(siteNames);
      })
      .finally(() => global.setLoading(false));
  }, [basinId]);

  useEffect(() => {
    if (reports) {
      flyToLocation(reports[0], 10);
      setActiveReport(reports[0]);
    }
  }, [reports]);

  const mapRef = useRef<any>();
  const { setCurrentRef } = useContext(RefContext);
  useEffect(() => {
    setCurrentRef?.(mapRef);
  }, [mapRef]);

  const waterData = activeReport && activeReport.data;

  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : null;

  const centerBasin = () => {
    const coor = centroidData.filter((obj: any) => obj.BASIN_ID == basinId);
    coor && flyToLocation(coor[0], 9);
  };

  const renderReports = (input: any) => {
    return input
      .slice(back, next)
      .map((report: any, index: any) => (
        <ReportItem
          report={report}
          key={index}
          index={index}
          activeIndex={activeIndex}
          setActiveReport={setActiveReport}
          setActiveIndex={setActiveIndex}
          flyToLocation={flyToLocation}
        />
      ));
  };

  const pageBack = () => {
    let itemPerPage = 4;
    if (back > 0) {
      setNext((prev) => prev - itemPerPage);
      setBack((prev) => prev - itemPerPage);
      setActiveIndex(null);
    }
  };

  const pageNext = () => {
    let itemPerPage = 4;
    let itemSlice = reports && reports.slice(back, next).length;
    let terminalNum = reports && reports.length % itemPerPage;
    if (itemSlice !== terminalNum) {
      setNext((prev) => prev + itemPerPage);
      setBack((prev) => prev + itemPerPage);
      setActiveIndex(null);
    }
  };

  const renderList = (input: any) => {
    const renderli = () => {
      let listItems = [];
      for (const property in input) {
        listItems.push(
          <li className="mx-data-item">
            <strong>{property}:</strong> {input[property]}
          </li>
        );
      }
      return listItems;
    };

    return <ul className="mx-data-list">{renderli()}</ul>;
  };

  return (
    <>
      <FullScreenComp handle={fullscreenhandle}>
        <ReportHeader
          global={global}
          reportID={"GroundwaterMexico-report"}
          fullScreenClickHandle={fullscreenhandle.enter}
        />
        <section className="mexico-sw-map-container">
          <div className="mexico-sw-map">
            <div className="basin-id">
              <h3>Basin ID: {basinId}</h3>
            </div>
            <button className="center-basin" onClick={centerBasin}>
              Center Basin
            </button>
            <Map
              longitude={global.viewport.longitude}
              latitude={global.viewport.latitude}
              zoom={global.viewport.zoom}
              mapboxAccessToken={settings.maboxKey}
              initialViewState={global.viewport}
              projection={global.globeView ? "globe" : "mercator" as any}
              mapStyle={global.mapStyle}
              ref={mapRef}
              onMove={(e) => {
                global.setViewport({
                  longitude: e.viewState.longitude,
                  latitude: e.viewState.latitude,
                  zoom: e.viewState.zoom,
                });
              }}
            >
              <FullscreenControl />
              <RenderLayerMarkers global={global} bounds={bounds} />
              <RenderLayers global={global} mapRef={mapRef} />
              {newList && newList.length > 0 ? (
                <ReportMarkers list={newList} />
              ) : (
                <ReportMarkers list={reports} />
              )}
            </Map>
            {global.terraClimateLayerSelected |
              global.evapotranspirationSelected && (
              <div className="range-slider">
                <Form.Range
                  min={2000}
                  max={2019}
                  value={global.MTCLValue}
                  onChange={(e) =>
                    global.setMTCLValue(parseInt(e.target.value))
                  }
                />
                <strong>Year: {global.MTCLValue}</strong>
              </div>
            )}
          </div>
        </section>
        <section className="mxsw-report-container">
          <h3
            style={{
              fontSize: "22px",
              color: "white",
              marginTop: "1rem",
              marginBottom: "-8px",
            }}
          >
            Report Select
          </h3>
          <div className="report-select">
            {reports && (
              <>
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  onClick={pageBack}
                  icon={faChevronLeft}
                />
                {newList && newList.length > 0
                  ? renderReports(newList)
                  : renderReports(reports)}
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  onClick={pageNext}
                  icon={faChevronRight}
                />
              </>
            )}
          </div>
          <div className="mxsw-report-data-container">
            <Accordion className="site-info-accordion" defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Site Info</Accordion.Header>
                <Accordion.Body>
                  <div className="station-info">
                    {activeReport && type.name === "Surfacewater Mexico" && (
                      <ul className="mx-data-list">
                        <li className="mx-data-item">
                          <strong>Organization:</strong>{" "}
                          {activeReport.basinorganization}
                        </li>
                        <li className="mx-data-item">
                          <strong>Municipality:</strong>{" "}
                          {activeReport.municipality}
                        </li>
                        <li className="mx-data-item">
                          <strong>State:</strong> {activeReport.state}
                        </li>
                        <li className="mx-data-item">
                          <strong>Subtype:</strong> {activeReport.subtype}
                        </li>
                        <li className="mx-data-item">
                          <strong>Waterbody:</strong> {activeReport.waterbody}
                        </li>
                        <li className="mx-data-item">
                          <strong>Type:</strong> {activeReport.type_}
                        </li>
                      </ul>
                    )}
                    {activeReport && type.name === "Groundwater Mexico" && (
                      <ul className="mx-data-list">
                        <li className="mx-data-item">
                          <strong>Aquifer:</strong> {activeReport.aquifer}
                        </li>
                        <li className="mx-data-item">
                          <strong>Organization:</strong>{" "}
                          {activeReport.basinorganization}
                        </li>
                        <li className="mx-data-item">
                          <strong>Municipality:</strong>{" "}
                          {activeReport.municipality}
                        </li>
                        <li className="mx-data-item">
                          <strong>State:</strong> {activeReport.state}
                        </li>
                        <li className="mx-data-item">
                          <strong>Subtype:</strong> {activeReport.subtype}
                        </li>
                      </ul>
                    )}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="report-table-container">
            <div className="table-wrapper ground-water">
              {tableHeaders && tableData && (
                <ReportTable data={tableData} columns={tableHeaders} />
              )}
            </div>
          </div>
          <div className="report-footer-wrapper">
          <ReportFooter />
          </div>
          
        </section>
      </FullScreenComp>
    </>
  );
};

export default GroundwaterMexico;
