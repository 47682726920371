// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wi-report-container {
  width: 95vw;
  overflow: scroll;
  max-width: unset;
  overflow-x: hidden; }
  .wi-report-container .wi-map-container {
    margin: 6px;
    height: 40vh;
    padding: 2px;
    border-radius: 4px;
    background-color: #0b1736;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; }
  .wi-report-container .bottom {
    height: 40vh;
    overflow: auto;
    padding: 1.5rem;
    margin-top: -1rem;
    height: 500px;
    position: relative; }
    .wi-report-container .bottom .wi-table-wrapper {
      position: absolute;
      top: 5%; }
      .wi-report-container .bottom .wi-table-wrapper .tile-title {
        text-align: center;
        color: white;
        margin-bottom: 1.5%; }
      .wi-report-container .bottom .wi-table-wrapper .wi-wrapper {
        padding: 0; }
      .wi-report-container .bottom .wi-table-wrapper table th, .wi-report-container .bottom .wi-table-wrapper table td {
        font-size: 14px;
        padding: 1%;
        text-align: center; }
`, "",{"version":3,"sources":["webpack://./src/features/reports/WaterImpairmentsReport/water-impairments.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB,EAAA;EAJtB;IAOM,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,4CAA4C,EAAA;EAZlD;IAgBM,YAAY;IACZ,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,kBAAkB,EAAA;IArBxB;MAwBQ,kBAAkB;MAClB,OAAO,EAAA;MAzBf;QA4BU,kBAAkB;QAClB,YAAY;QACZ,mBAAmB,EAAA;MA9B7B;QAkCU,UAAU,EAAA;MAlCpB;QAuCY,eAAe;QACf,WAAW;QACX,kBAAkB,EAAA","sourcesContent":[".wi-report-container {\r\n    width: 95vw;\r\n    overflow: scroll;\r\n    max-width: unset;\r\n    overflow-x: hidden;\r\n  \r\n    .wi-map-container {\r\n      margin: 6px;\r\n      height: 40vh;\r\n      padding: 2px;\r\n      border-radius: 4px;\r\n      background-color: #0b1736;\r\n      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\r\n    }\r\n      \r\n    .bottom {\r\n      height: 40vh;\r\n      overflow: auto;\r\n      padding: 1.5rem;\r\n      margin-top: -1rem;\r\n      height: 500px;\r\n      position: relative;\r\n\r\n      .wi-table-wrapper {\r\n        position: absolute;\r\n        top: 5%;\r\n\r\n        .tile-title {\r\n          text-align: center;\r\n          color: white;\r\n          margin-bottom: 1.5%;\r\n        }\r\n\r\n        .wi-wrapper {\r\n          padding: 0;\r\n        }\r\n\r\n        table {\r\n          th, td {\r\n            font-size: 14px;\r\n            padding: 1%;\r\n            text-align: center; \r\n          }\r\n        }\r\n      }\r\n    } \r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
