import {
  useState, useEffect
} from 'react'
import ProjectsTable from './ProjectsTable'
import EditProject from './EditProject'
import {
  Button,
  Form
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPenToSquare,
  faTrashCan
} from '@fortawesome/pro-solid-svg-icons';

const Projects = (props: any) => {

  const {
    setModalOpen,
    modalOpen,
    modalList,
    setModalList,
    projectsData,
    setProjectsData,
    settings,
    setSettings,
    orgCredentialsType
  } = props

  const [data, setData] = useState(projectsData)
  const [action, setAction] = useState('table')
  
  const modalArray = [
                      'SAMP_DESIGN_TYPE_CD',
                      'QAPP_A'
                          ];

  const modalArrayFields = [] as any;
  for (var i = 0; i < modalArray.length; i++) {
      //@ts-ignore
       modalArrayFields.push({ field: modalArray[i], active: false})
  }

   
  useEffect(() => {
    if(!modalList || modalList.length!==modalArray.length){
        const table_mlocs = localStorage.getItem('wm_table_projs');   
        if(table_mlocs){
            const tablefields = JSON.parse(table_mlocs);          
            if(tablefields && tablefields.length >0){
                setModalList(tablefields)
            } else {
                setModalList(modalArrayFields);
            }
        } else {
            setModalList(modalArrayFields);
        }
    }
  }, [])

  useEffect(() => {
      if(modalList && modalList.length===modalArray.length){
          localStorage.setItem('wm_table_projs', JSON.stringify(modalList));
      }
  }, [modalList])


  const renderScreens = () => {
    if (action === 'table') {
      return <ProjectsTable
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        modalList={modalList}
        setModalList={setModalList}
        action={action}
        setAction={setAction}
        data={projectsData}
        setData={setData}
        settings={settings}
        setSettings={setSettings}
        setProjectsData={setProjectsData}
        orgCredentialsType={orgCredentialsType}
      />
    }

    if(action === 'create') {
      return <EditProject
        title={"Create"}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        setModalList={setModalList}
        action={action}
        setAction={setAction}
        data={''}
        setData={setData}
        projectsData={projectsData}
        setProjectsData={setProjectsData}
        orgCredentialsType={orgCredentialsType}
      />
    }

    if (action === 'edit') {
      return <EditProject
        title={'Edit'}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        setModalList={setModalList}
        action={action}
        setAction={setAction}
        data={data}
        setData={setData}
        projectsData={projectsData}
        setProjectsData={setProjectsData}
        orgCredentialsType={orgCredentialsType}
      />
    }
  }

  return <>{renderScreens()}</>
}

export default Projects