import { useEffect, useContext, useState, useCallback } from 'react';
import { Layer, Source, Popup, FillLayer } from 'react-map-gl';
import { useNavigate } from 'react-router-dom';
import { RefContext } from '../../../RefContext';
import { LayerDataContext } from '../../../LayerDataContext';
import { PointContext } from '../../../PointContext';
import { ActivePopupContext } from '../../../ActivePopupContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { adjustHexBrightness } from "../../../features/utils";
import './cvi-layer.scss';

const CVILayer = ({
  id,
  tileSet,
  opacity,
  sourceLayer,
  global,
  action,
  tableColumns,
  sourceLink = '',
  sourceLabel = '',
  reportRoute = '',
  beforeId = ''
}: any) => {

  const [features, setFeatures] = useState<any>(null);
  const [percentile, setPercentile] = useState<string>('');

  const latLng = action?.lngLat;
  const navigate = useNavigate();

  const { currentRef } = useContext(RefContext);
  const { setCurrentPoint } = useContext(PointContext);
  const { setCurrentLayerData } = useContext(LayerDataContext);
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);

  // @ts-ignore
  const mapInstance = currentRef && currentRef?.current;

  const goTo = useCallback((coor: any, data: any) => {
    setCurrentPoint?.({ lat: coor.lat, lng: coor.lng });
    setCurrentLayerData?.({ ...data, tableColumns, sourceLink, sourceLabel });
    setTimeout(() => navigate(reportRoute), 100);
  }, [setCurrentPoint, setCurrentLayerData, tableColumns, sourceLink, sourceLabel, navigate, reportRoute]);

  const handlePercentile = useCallback((properties: any) => {
    const suffixMap: any = { '1': 'st', '2': 'nd', '3': 'rd' };
    const score = Math.round(properties['percentile_cvi_overall'] * 100);
    const lastNum = score.toString().slice(-1);
    const suffix = suffixMap[lastNum] || 'th';
    setPercentile(`${score}${suffix} National Vulnerability Percentile`);
  }, []);

  useEffect(() => {
    if (features) {
      handlePercentile(features);
    }
  }, [features, handlePercentile]);

  useEffect(() => {
    global.notify('High fidelity layer, zoom for more detail');
  }, []);

  useEffect(() => {
    if (mapInstance) {
      const handleClick = (event: any) => {
        const layerObject = mapInstance.queryRenderedFeatures(event.point)?.[0];
        if (layerObject) {
          setFeatures(layerObject.properties);
        }
      };
      mapInstance.on('click', handleClick);

      return () => {
        mapInstance.off('click', handleClick);
      };
    }
  }, [mapInstance]);

  const paintOptions = useCallback((input: string = 'percentile_cvi_overall') => {
    const colorStops: (number | string)[] = [
      0, adjustHexBrightness('#ffffff', global.cviBrightness),
      0.1, adjustHexBrightness('#0000AA', global.cviBrightness),
      0.2, adjustHexBrightness('#000099', global.cviBrightness),
      0.3, adjustHexBrightness('#000088', global.cviBrightness),
      0.4, adjustHexBrightness('#000077', global.cviBrightness),
      0.5, adjustHexBrightness('#000066', global.cviBrightness),
      0.6, adjustHexBrightness('#000055', global.cviBrightness),
      0.7, adjustHexBrightness('#000044', global.cviBrightness),
      0.8, adjustHexBrightness('#000033', global.cviBrightness),
      0.9, adjustHexBrightness('#000022', global.cviBrightness),
      0.95, adjustHexBrightness('#000011', global.cviBrightness),
    ];
    return ['interpolate', ['linear'], ['get', input], ...colorStops];
  }, [global.cviBrightness]);

  const CVILayerConfig: FillLayer = {
    id: `${id}-layer`,
    type: "fill",
    "source-layer": sourceLayer,
    paint: {
      "fill-opacity": opacity,
      // @ts-ignore
      "fill-color": paintOptions(global.cviValue),
    },
  };

  useEffect(() => {
    if (features && latLng) {
      const handleClose = () => {
        setFeatures(null);
        setActivePopup({ id: null, content: null });
      };

      const newPopup = (
        <Popup
          key={id}
          longitude={latLng.lng}
          latitude={latLng.lat}
          anchor="bottom"
          onClose={handleClose}
        >
          <FontAwesomeIcon className="close-btn" icon={faXmark} onClick={handleClose} />
          <div style={{ padding: '0.75rem', borderRadius: '4px', position: 'relative', height: '5.5rem' }}>
            <p style={{ color: '#FFFFFF', textAlign: 'center' }}>{features.Name}</p>
            <p style={{ color: '#FFFFFF' }}>{percentile}</p>
            <a
              style={{ color: '#4fc2d9', fontWeight: 'bold', textDecoration: 'underline', padding: '0.5rem', borderRadius: '6px', position: 'absolute', left: '30%' }}
              onClick={() => goTo(latLng, features)}
            >
              Go to report
            </a>
          </div>
        </Popup>
      );
      setActivePopup({ id, content: newPopup });
    }
  }, [features, latLng]);

  return (
    <>
      <Source id={`${id}-layer`} type="vector" url={tileSet}>
        <Layer beforeId={`${beforeId}-layer`} {...CVILayerConfig} />
      </Source>
      {activePopup && activePopup.id === id && activePopup.content}
    </>
  );
};

export default CVILayer;
