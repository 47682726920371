//@ts-nocheck                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
import React, { useState, useMemo, useEffect } from 'react';
import { Form, Button, Row, Col, FloatingLabel } from 'react-bootstrap';	
import './add-user.scss';
import InputForm from '../../components/input-form';
import companyIcon from '../../assets/company-icon.svg';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import SettingsBreadcrumb from '../account/SettingsBreadcrumb';
import agent from '../../api/agent';
import ReportsRoutes from './../../ReportsRoutes';
import {DateTime} from 'luxon';
import ReportTableExpanded from '../reports/shared/ReportTableExpanded';
import { useTable, useExpanded, 
  UseExpandedHooks,
  UseExpandedInstanceProps,
  UseExpandedOptions,
  UseExpandedRowProps,  HeaderGroup,
  HeaderProps,
  UseExpandedState, Cell} from 'react-table'


export default function AddUser(props: any){
	const { global } = props;
	global.setUserLogged(true);
	const location = useLocation();
	const navigate = useNavigate();

	let _mode = 'Add';
	let _last_name = '';
	let _first_name = '';
	let _email = '';
	let _phone = '';
	let _profile = '';
	let _isactive = true;
	let _user = {};
	let _org = '';
	let _organizationsData = [];
	let _profilesData = [];

	if(location && location.state){
		if(location.state.user){
			//console.log(location.state.user)
			if(location.state.user.name){
				let namearray = location.state.user.name.split(' ');
				_last_name = namearray[1];
				_first_name = namearray[0];
			}
			_email= location.state.user.email;
			_phone= location.state.user.phone1;
			_user = location.state.user;
			_profile = location.state.user.profile;
			_isactive =  location.state.user.is_active;
		}
		if(location.state.currentOrg){
			_org = location.state.currentOrg;
		}
		if(location.state.organizationsData){
			_organizationsData = location.state.organizationsData;
		}
		if(location.state.mode === 'edit'){
			_mode = 'Edit';
		}
		if(location.state.profilesData){
			_profilesData = location.state.profilesData;
		}		
	}
	
	const [ mode, setMode ] = useState(_mode);

	const [ firstName, setFirstName ] = useState(_first_name);
	const [ lastName, setLastName ] = useState(_last_name);
	const [ emailName, setEmailName ] = useState(_email);
	const [ phoneNumber, setPhoneNumber ] = useState(_phone);
	const [ organization, setOrganization ] = useState(_org);
	const [ isactive, setIsactive ] = useState(_isactive);
	const [ orgName, setOrgName ] = useState('');
	const [ role, setRole ] = useState('User');
	const [ profile, setProfile ] = useState(_profile);
	const [ currentUser, setCurrentUser ] = useState(_user);
	const [ organizationsData, setOrganizationsData ] = useState<any[]>(_organizationsData);
	const [ profilesData, setProfilesData ] = useState<any[]>(_profilesData);
	const [ userProfilesData, setUserProfilesData ] = useState<any[]>([]);

	const [ userkc, setUserkc ] = useState(null);
	const [ userRoles, setUserRoles ] = useState<any[]>([])
	const [ availableRoles, setAvailableRoles ] = useState<any[]>([])
	const [ changeFlag, setChangeFlag ] = useState(0);
	const [ userHistory, setUserHistory ] = useState<any[]>([])

	const [ clientID, setClientID ] = useState(''); //6707363a-e551-474b-9cdf-5ba8e22424f0
	let baseRoles = ['admin', 'te-admin'];


	const user = {
		firstName,
		setFirstName,
		lastName,
		setLastName,
		emailName,
		setEmailName,
		phoneNumber,
		setPhoneNumber,
		isactive,
		setIsactive
	};

	useEffect(() => {	
		agent.UserKeycloak.GetClient().then((res: any) => { 
			console.log(res)
	      if(res && res.length > 0 && res[0].id){
	      	setClientID(res[0].id)
	      }
	    })		
    }, []);   

	useEffect(() => {
		if(emailName){
			agent.UserKeycloak.GetUser(emailName).then((res: any) => { //++
				console.log('GetUser', res)
		      if(res && res.length > 0 && res[0].id){
		      	setUserkc(res[0])
		      }
		    })		
		}
    }, [emailName]);   
	//a098a045-4ec2-4b98-827c-01626ab7dcb8

	useEffect(() => {
		
		if(userRoles && userRoles.length>0){
			let RoleN = 'User';
	  		let matching = userRoles.find(
			    ({ name }) => name === 'te-admin'
			);									
			if(matching){
				RoleN = 'te-admin';
			} else {
				matching = userRoles.find(
				    ({ name }) => name === 'admin'
				);	
				if(matching){
					RoleN = 'admin';
				}
			}
		
			setRole(RoleN)			
		}
    }, [userRoles]);   

	const updateCurrentUserRoles = (aroles:any) => {
		agent.UserKeycloak.CurrentUserRoles(userkc.id, clientID).then((resC: any) => {
		    if(resC && resC.length > 0 && resC[0].clientRole){
		      	setUserRoles(resC);
		      	for (let dataItem of resC) {
		      		dataItem.checked = true;
		      		const matching = aroles.find(
					    ({ name }) => name === resC.name
					);									
					if(matching){
						matching.checked = true;
					} else {
						aroles.push(dataItem)
					}
		      	}
		      	setAvailableRoles(aroles.sort((x: any, y: any) => x['name'].localeCompare(y['name'])));
		      	setChangeFlag(changeFlag+1);
		    } else {
		      	setUserRoles([]);		      	  
		    }
	    })
	 }

	useEffect(() => {
		console.log(userkc)
		if(userkc && userkc.id){
			agent.UserKeycloak.AvailableUserRoles(userkc.id, clientID).then((res: any) => {
				console.log(userkc.id, clientID, res, 'aaa')
		        if(res && res.length > 0 && res[0].clientRole){
		        	let aroles = res.sort((x: any, y: any) => x['name'].localeCompare(y['name']));
		      	    setAvailableRoles(aroles);
		      	    updateCurrentUserRoles(aroles);	
		        } else {
		      	  setAvailableRoles([]);		      	  
		        }
			})

			agent.UserKeycloak.GetLoginUserId(userkc.id).then((res: any) => { 
				const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
				console.log('GetLoginUserId', res, tz)
		      if(res && res.length > 0){
		      	let newData = [...res]  	
				for (let dataItem of newData) {
					if(dataItem.time){
						dataItem.timeform = DateTime.fromMillis(dataItem.time).toString(DateTime.DATETIME_MED);
						dataItem.timelocal = DateTime.fromMillis(dataItem.time).toLocaleString(DateTime.DATETIME_MED);
					}	//const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
				}
		      	setUserHistory(newData)
		      } else {
		      	setUserHistory([])	
		      }
		    })	

		}
    }, [userkc]);    

	useEffect(() => {
		if(mode==='Edit'){
			agent.UserManagement.UserGet(organization, currentUser.username).then((res: any) => {
				if(res && res.body){
					const data = [...res.body]
					setCurrentUser(data[0])
						
					if(data[0].name){
						let namearray = data[0].name.split(' ');
							if(namearray.length > 1){
							setLastName(namearray[1])
						}
						setFirstName(namearray[0])
					}		
					if(data[0].role){
						setRole(data[0].role);
					}	

					if(data[0].profile && data[0].profile.length>0){
						let splitProfile = data[0].profile.split(',');
						let userProfiles = [];
						let newProfilesData = [...profilesData];
						for (var i = 0; i < splitProfile.length; i++) {
							if(!isNaN(+splitProfile[i])){
								userProfiles.push(+splitProfile[i]);
								let fData = newProfilesData.filter((o: any) => +o.id === +splitProfile[i]);
 								if(fData && fData.length>0){
 									fData[0].checked = true;	
 								}
							} 
                        }
						setUserProfilesData(userProfiles);	
						setProfilesData(newProfilesData)
						setProfile(userProfiles);
					}				
				}
			})   
		}
    }, []);    


/*	useEffect(() => {

    }, [currentUser, profilesData, userProfilesData]);    
*/

	const handleOrg = (e:any) => {
		setOrganization(e.target.value);
	 }

	const clickAddOrgHandler = (event: any) => {
		navigate('/add-organization');
	}

	const clickCancelHandler = (event: any) => {
        navigate('/Users');
    };

	const clickAddHandler = (event: any) => {
		if(emailName && emailName.length> 0){	
			
			let RoleN = 'User';
      		let matching = userRoles.find(
			    ({ name }) => name === 'te-admin'
			);									
			if(matching||role==='te-admin'){
				RoleN = 'te-admin';
			} else {
				matching = userRoles.find(
				    ({ name }) => name === 'admin'
				);	
				if(matching || role === 'admin'){
					RoleN = 'admin';
				}
			}
		
			if(mode==='Add'){
				const newUser = { EMAIL: emailName, ROLE: RoleN, NAME: firstName + ' ' + lastName, PROFILE: profile, USERNAME: emailName, ORG_ID: organization, IS_ACTIVE: isactive };
				agent.UserManagement.UserPost(newUser).then((res: any) => {
					navigate('/Users');
				})
			} else if(mode==='Edit'){
					if(organization && currentUser){
					const newUser = { USER_ID: currentUser.user_id, ROLE: RoleN, NAME: firstName + ' ' + lastName, PROFILE: profile, ORG_ID: organization, WQX_REPORT_ID: organization, IS_ACTIVE: isactive };
					agent.UserManagement.UserUpdate(currentUser.user_id, newUser).then((res: any) => {
						navigate('/Users');
					}) 
				}
			}			
		}
    };

    const handleChange = (change:any, evalue:any) => {
		change(evalue);
	}

    const handleRole = (evalue:any) => {
		//console.log(evalue);
	}
	
    const handleProfilesChange = (changeid:any, evalue:any, fieldname?:any) => {
		let newProfilesData = [...profilesData];
		let fData = newProfilesData.filter((o: any) => +o.id === +changeid);
		if(fData && fData.length>0){
			fData[0].checked = evalue;	
		}

		let newuserProfilesData = [...userProfilesData];
		if(evalue){
			if(userProfilesData.indexOf(changeid)<0){
				newuserProfilesData.push(changeid); //+++
			}
		} else {
			if(userProfilesData.indexOf(changeid)>-1){
				newuserProfilesData.splice(userProfilesData.indexOf(changeid), 1);
			}		
		}


		if(currentUser){
			let newcurrentUser = currentUser;
			currentUser.profile = newuserProfilesData.toString();
			setCurrentUser(newcurrentUser);	
			setProfile(newuserProfilesData.toString());
		}

		setProfilesData(newProfilesData);
/*
		if((changeid===1||changeid==='1') && evalue && newuserProfilesData.indexOf(6)<0){
			console.log('1')
			global.setPermitReports([ReportsRoutes.ClimatePrecipitationCalculator, ReportsRoutes.NationalStormWaterQualityDatabase2020, ReportsRoutes.RiskScreening, ReportsRoutes.NationalStreamQualityAccountingNetwork]);
		}

		if(newuserProfilesData.indexOf(6)>-1 && evalue){
			console.log('all')
			global.setPermitReports();
		}*/

		if((changeid===1||changeid==='1') && evalue){
			console.log('1')
			global.setPermitReports([ReportsRoutes.ClimatePrecipitationCalculator, ReportsRoutes.NationalStormWaterQualityDatabase2020, ReportsRoutes.RiskScreening, ReportsRoutes.NationalStreamQualityAccountingNetwork]);
		}

		if(newuserProfilesData.indexOf(6)>-1 && evalue){
			console.log('all')
			global.setPermitReports();
		}
	}

   const handleRolesChange = (changeid:any, evalue:any, fieldname?:any) => {
   		let newRolesData = [...availableRoles];
  		const matching = newRolesData.find(
		    ({ id }) => id === changeid
		);	
		if(matching){
			matching.checked = evalue;
			const roleChange =   [
		       {
		          "id": matching.id,
		          "name": matching.name
		        }
			];

			if(evalue){
			    agent.UserKeycloak.AddUserRole(userkc.id, clientID, roleChange).then((res: any) => {
			    	
			    })

			    if(baseRoles.indexOf(matching.name) > -1){
			    	setRole(matching.name)
			    } else {
			    	//updateCurrentUserRoles(availableRoles);
			    }
			
			} else {
				
			    agent.UserKeycloak.DeleteUserRole(userkc.id, clientID, roleChange).then((res: any) => {
			      
			    })

			    if(baseRoles.indexOf(matching.name) > -1){
			    	//updateCurrentUserRoles(availableRoles);// setRole('user') //Admin? +++
			    }
				
			}	
			setAvailableRoles(newRolesData);		
		}
	}


  let tableColumns = useMemo(
    () => [
      { Header: 'Time', accessor: 'timelocal' },
      { Header: 'Type', accessor: 'type' },
      { Header: 'IP Address', accessor: 'ipAddress' },
      { Header: 'Grant Type', accessor: 'details.grant_type' },
      { Header: 'Identity Provider', accessor: 'details.identity_provider' },
      { Header: 'Identity', accessor: 'details.identity_provider_identity' },
      { Header: 'Identity User', accessor: 'details.username' },
    ],
    []
  );


	return (
		<div className='add-user'>
			<SettingsBreadcrumb title={'User Management'}  subtitle={mode + ' User'}  />
			<h2>{mode} User</h2>
			<Form>
				<div className='container form'>
					<h4>Basic Information</h4>
					<Row>
						<Col className='col-md-4'>
							<FloatingLabel controlId="floatingfirstName" label="First Name">
								<Form.Control defaultValue={firstName} onChange={e => handleChange(setFirstName, e.target.value)} type="text" placeholder="name@example.com" />
							</FloatingLabel>	
						</Col>
						<Col className='col-md-4'>
							<FloatingLabel controlId="floatinglastName" label="Last Name">
								<Form.Control defaultValue={lastName} onChange={e => handleChange(setLastName, e.target.value)} type="text" placeholder="Last Name" />
							</FloatingLabel>	
						</Col>
						<Col className='col-md-4'>
							<FloatingLabel controlId="floatingEmail" label="Email">
								<Form.Control style={{marginBottom: 0}} defaultValue={emailName} onChange={e => handleChange(setEmailName, e.target.value)} type="text" placeholder="Email" />
							</FloatingLabel>
							{ (!emailName || emailName === '') && (<span>Add email address to populate available roles</span>)}
						</Col>
					</Row>
					<Row>
{/* 						<Col className='col-md-4'>
							<FloatingLabel controlId="floatingPhoneNumber" label="Phone Number">
								<Form.Control defaultValue={phoneNumber} onChange={e => handleChange(setPhoneNumber, e.target.value)} type="text" placeholder="Phone Number" />
							</FloatingLabel>
						</Col> */}
						<Col className='col-md-4'>
						  <FloatingLabel controlId="floatingSelect" label="Organization">
						  {/*	<Form.Control defaultValue={orgName}  type="text" placeholder="Organization" />*/}
			
							<Form.Select defaultValue={organization}  onChange={handleOrg} placeholder='Select'>
							{organizationsData.map((dash, index) =>
								<option key={'users-add-options'+index} value={dash.org_id}>{dash.name}</option>
							)} 
							</Form.Select>
						   </FloatingLabel>
						</Col>
					</Row>
					<div className='add-company' style={{display: 'none'}}>
						<img src={companyIcon}/>
						<a  onClick={clickAddOrgHandler}>Add Organization</a>
					</div>

				
					<div className={'role-check-container'}>
						<div className='role-check'>
							<Form.Check type='checkbox' checked={isactive} onChange={e => setIsactive(e.target.checked)}  label={'Is Active'} value={'isactive'} />
						</div>
					</div>



					<h4 style={{marginTop: 20}}>Profile {'&'} Roles</h4>
{/*					<h5>Roles</h5>
					<Row>
						<Col className='col-md-4'>
							<CheckBoxOption checked={role.toLowerCase()==='te-admin'}  onChange={setRole}  displayName='True Elements Admin' name='te-admin'/>
						</Col>
						<Col className='col-md-4'>
							<CheckBoxOption checked={role.toLowerCase()==='admin'}  onChange={setRole}  displayName='Administrator' name='admin'/>
						</Col>
						<Col className='col-md-4'>
							<CheckBoxOption checked={role.toLowerCase()==='user'}  onChange={setRole} displayName='Regular' name='user'/>
						</Col>
					</Row>
*/}
					<h5 style={{marginLeft: 20}}>Roles</h5>
					<div className={'role-check-container'} key={'users-add-'+availableRoles.length+changeFlag} style={{marginLeft: 30}}>
						{availableRoles.map((dash, index) =>
							<div className='role-check' key={'users-add-PrC'+index+availableRoles.length}>
									<Form.Check type='checkbox' checked={(dash.checked) ? true : false} onChange={e => handleRolesChange(dash.id, e.target.checked, 'roles')} key={'users-add-PrCheck'+index}  label={dash.name} value={dash.id} />
      						</div>
						)} 
					</div>

					<h5 style={{marginLeft: 20,marginTop: 20}}>Profiles</h5>
					<div className={'role-check-container'} style={{marginLeft: 30}}>
						{profilesData.map((dash, index) =>
							<div className='role-check' key={'users-add-PrC'+index}>
									<Form.Check type='checkbox' checked={(dash.checked) ? true : false} onChange={e => handleProfilesChange(dash.id, e.target.checked, 'profile')} key={'users-add-PrCheck'+index}  label={dash.name} value={dash.id} />
      						</div>
						)} 
					</div>

					<div className='footer'  style={{marginTop: 20, textAlign: 'right'}}>
						<Button variant='secondary' onClick={clickCancelHandler}>Cancel</Button>
						<Button variant='primary' onClick={clickAddHandler}>Save User</Button>
					</div>

					<h5 style={{marginLeft: 20,marginTop: 20}}>Login History</h5>
					<div className={'role-check-container'} style={{marginLeft: 30}}>
		                  <ReportTableExpanded
		                    key={'tableuserHistory'}
		                    data={userHistory}
		                    columns={tableColumns}                		                  
		                    clickLoadStop={true}    
		                  />
					</div>

{/*


							<RoleCheck displayName={dash.name} name={dash.name} checked={(dash.checked) ? true : false} onChange={e => handleProfilesChange(dash.id, e, 'profile')} key={'users-add-PrRoleCheck'+index} value={dash.id}>{dash.name}</RoleCheck>
					<Row>


						<Col className='col-md-4'>
							<RoleCheck displayName='Operator' name='operator'/>
							<RoleCheck displayName='Commercial Manager' name='commercial-manager'/>
							<RoleCheck displayName='Planning Engineer' name='planning-engineer'/>
						</Col>
						<Col className='col-md-4'>
							<RoleCheck displayName='Regulator' name='regulator'/>
							<RoleCheck displayName='Agriculture Manager' name='agriculture-manager'/>
							<RoleCheck displayName='Starter Profile' name='starter-profile'/>
						</Col>
						<Col className='col-md-4'>
							<RoleCheck displayName='Citizen Waterkeeper' name='citizen-waterkeeper-engineer'/>
						</Col>
						<Col className='col-md-4'>
							<h5>Custom Profiles</h5>
							<Form.Select placeholder='Select'>
								<option>Select Custom profiles</option>
							</Form.Select>
						</Col>
					</Row>*/}
				</div>

			</Form>
		</div>
	);
}

function BasicInformation({firstName, setFirstName, lastName, setLastName, emailName, setEmailName, phoneNumber, setPhoneNumber}: any){
	return(
		<>

		</>
	);
}


function ProfileAndRoles(props: any){
	return(
		<>
			
		</>
	);
}

function CheckBoxOption(props: any){
   const { name, displayName, checked, onChange } = props;

   const handleCheckbox = (change:any, evalue:any, fieldname?:any) => {

   		if(fieldname && fieldname==='profile'){
   			//console.log(change, evalue, checked, fieldname)
   		} else {
	  		if(evalue==='on'){
				change(name)
			} else {
				change('User');
			} 			
   		}
   }

   return(
   		<Form.Check type='checkbox' onChange={e => handleCheckbox(onChange, e)}  checked={checked} label={displayName} id={name}/>
   );
}

function RoleCheck(props: any){
   const { id, displayName } = props;
   return(
      <div className='role-check'>
      	<CheckBoxOption {...props}/>
		<Form.Select className='reports-dropdownlist' style={{display: 'none'}}>
			<option>View reports</option>
		</Form.Select>
      </div>
   );
}

