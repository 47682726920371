import {
  useMemo,
  useState
} from 'react'
import ReportTable from '../../../reports/shared/ReportTable'
import {
  Button,
  Form,
  Row, Col,
  FloatingLabel
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import {
  faPenToSquare,
  faTrashCan,
  faCalendar
} from '@fortawesome/pro-solid-svg-icons';
import { parseImport } from '../lib/data';

const ImportII = (props: any) => {

  const {
    setImportDetails,
    setImportAction,
    projectsData,
    setAction,
    action,
    data,
    setData,
    setParsedData,
    orgData,
    entitySelection,
    setEntitySelection,
    currentOrg
  } = props


  const handleFilter = (event: any) => {
    //console.log(event); 
  }

  const handleContinue = (event: any) => {
    //console.log(event, data);
    parseImport(data, parseResult)
    //setAction('importdetails')
  }

  const parseResult = (result: any) => {
    console.log(orgData, currentOrg, entitySelection)
    let updateData = result;
    //submissionType
    //cdxStatus:
    let importOrg = 'True Elements'
      if(orgData && orgData.length>0){ 
        let queryFind = orgData.filter((item: any) => {
                     return +item.org_id === +currentOrg;});
        if(queryFind && queryFind.length>0){
           if(queryFind[0].name){
              importOrg = queryFind[0].name;
           }             
        } else if(orgData[0].name) {
              importOrg = orgData[0].name;
        }
    }       

    console.log(importOrg)

    let i=1;
    for (let dataItem of updateData) {
      //dataItem['Description'] = dataItem['Description Text'];
      //dataItem['Description_'] = dataItem["'  Description Text'"];
      //dataItem['Organization'] = orgData && orgData[0].name;

      //+++
      dataItem['Organization'] = importOrg;
      //dataItem['OrganizationIdentifier'] = orgData && orgData[0].epa_org_id;

     // cdxStatus:  submissionType: undefined
     dataItem['submissionType'] = entitySelection;
     dataItem['cdxStatus'] = 'Complete';


      dataItem['ImportID'] = i;
      dataItem['ImportStatus'] = '';
      dataItem['ImportErrors'] = '';
      dataItem["includeInImport"] = true;
      i++;
    }
    console.log(result)
    setParsedData(result)
    setAction('importdetails')
  }

  return (
    <Row className='monitoring-location-container'>
      <div className="header-box">
        <h2>Bulk Import Data</h2>
      </div>
      <Row id="filter-box" style={{ marginRight: '0' }}>
        <div className="filter-box bulk-import">
          <h3>Step 2: Select Data To Import</h3>
          <hr />
          <Row>
            <Col className="col-4">
              <h4
                style={{
                  fontSize: '12px',
                  fontWeight: 'bold'
                }}
              >
                Select Import Data Structure
              </h4>
              <div id="importII" className="drop-box">
                <Col className="col-8">
                  <Form.Select className="w-100" size="lg">
                    <option value="MLOC">Monitoring Locations</option>
                    <option value="SAMP">Sample Results - 1 row per result</option>
                    <option value="SAMP_CT">Sample Results - 1 row per sample (1 column per result)</option>
                    <option value="BIO_METRIC">Bio Metrics</option>
                  </Form.Select>
                </Col>
              </div>
            </Col>
            <Col className="col-4">
              <h4
                style={{
                  fontSize: '12px',
                  fontWeight: 'bold'
                }}
              >
                Select Import Logic
              </h4>
              <div id="importII" className="drop-box">
                <Col className="col-8">
                  <Form.Select className="w-100 TEMPLATE_NAME" size="lg"> 
                    <option>SNEPO Field Data</option>
                  </Form.Select>
                </Col>
              </div>
            </Col>
            <Col className="col-4">
              <h4
                style={{
                  fontSize: '12px',
                  fontWeight: 'bold'
                }}
              >
                Import to Project
              </h4>
              <div id="importII" className="drop-box">
                <Col className="col-8">
                    <Form.Group className="mb-3" controlId="floatingActivityType">                    
                        <Form.Select   onChange={(e: any) => handleFilter(e.target.value)}
                        size="lg" aria-label="projects Data">
                            {projectsData  && projectsData.map((obj:any, index:any) =>
                                (<option key={'wm-options'+index} value={obj["PROJDescription_ProjectName"]}>{obj["PROJDescription_ProjectName"]}</option>)
                            )} 
                        </Form.Select>                     
                    </Form.Group>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      </Row>
      <Row id="filter-box" style={{ marginRight: '0' }}>
        <div className="filter-box bulk-import">
          <h3 style={{ marginBottom: '12px' }}>Copy and past your data from a spreadsheet into the text area below:</h3>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Control as="textarea" rows={7} defaultValue={data}/>
            </Form.Group>
          </Form>
        </div>
      </Row>
      <div style={{ textAlign: "end" }} className="bottom-button-box">
        <Button
          onClick={handleContinue}
          id="addNew-btn"
          className="p-2 btn btn-primary m-2"

          style={{
            fontSize: '14px',  
            fontWeight: 'bold',
            padding: '1rem !important',
            width: '8rem'
          }}
        >
          Continue
        </Button>
      </div>
    </Row>
  )
}

export default ImportII