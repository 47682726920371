import { Ref, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  MapRef,
  Map,
  Source,
  Layer,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  LayerProps,
  Popup,
} from 'react-map-gl';
import { AppContext } from '../../../AppContext';
import { CatalogsContext } from '../../../CatalogsProvider';
import MapSource from '../../../types/MapSource';
import settings from '../../../settings.json';
import { Row, Col, Button, Form } from 'react-bootstrap';
import fullscreenIcon from '../../../assets/fullscreen-icon.svg';
import ReportTable from '../shared/ReportTable';
import { Location, useLocation } from 'react-router-dom';
import { message_hucrestriction, message_nodatalocation, message_noresult } from '../../../Constants';
import { flyToInitialLocationHandler, flyToLngLat, groupBy, hasAValue } from '../../utils';
import agent from '../../../api/agent';
import { ToxicsReleaseInvetoryResponseBodyModel } from '../../../api/models';
import Globals from '../../../types/GlobalsType';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { v4 as uuidv4 } from 'uuid';

import { Feature, FeatureCollection, GeoJsonProperties, Point } from 'geojson';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import MapLegend from '../shared/MapLegend';
import MapSlider from '../shared/MapSlider';
import MapAddition from  '../shared/MapAddition';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';


import GeneralResponseModel from '../../../api/GeneralResponseModel';
import { RefContext } from '../../../RefContext'
import './styles.scss';

const FacilityRegistrySystem = (props: any) => {
  const { global } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);

  const fullscreenhandle = useFullScreenHandle() as any;
  const FullScreenComp = FullScreen as any;

  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState<Array<FacilityRegistrySystemResponseBodyModel>>([]);
  const [tableData, setTableData] = useState<Array<FacilityRegistrySystemResponseBodyModel>>([]);
  const [dataPage, setDataPage] = useState<number>(0);
  const [currentSelectedRowHTMLElement, setCurrentSelectedRowHTMLElement] =
    useState<HTMLElement | null>(null);
  const [currentSelectedRowData, setCurrentSelectedRowData] = useState({});
  const [featureData, setFeatureData] = useState<FeatureCollection>();
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [cursor, setCursor] = useState<string>('auto');
  const [scrolledToBottom, setScrolledToBottom] = useState<boolean>();
  const [isLastResponseEmpty, setIsLastResponseEmpty] = useState<boolean>(false);
  const [mapLegendData, setMapLegendData] = useState<any[]>([]);

  const mapRef = useRef<MapRef>();
  const { setCurrentRef } = useContext(RefContext)
  useEffect(() => { setCurrentRef?.(mapRef) }, [mapRef])
  const [currentMapRef, setCurrentMapRef] = useState<any>(null);

  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;

  let selectedSICCodes = (global as Globals).filterSICCodesSelected;
  const [clickedFacilityFilterValue, setClickedFacilityFilterValue] = useState<string>('');
  const [currentSelectedFacilityName, setCurrentSelectedFacilityName] = useState<string>('');
  const [resetClickedFeatureFilter, setResetClickedFeatureFilter] = useState<boolean>(false);

  const [isFullScreenChartActive, setFullScreenChartActive] = useState(false);
  const fullscreenChartContainerClickHandler = () => {
    setFullScreenChartActive(!isFullScreenChartActive);
  };


  useEffect(() => {
    const reportContainerHTMLElement = document.querySelector('div.toxics-release-inventory');
    reportContainerHTMLElement?.addEventListener('click', resetClickedFeatureFilterHandler);

    return () =>
      reportContainerHTMLElement?.removeEventListener('click', resetClickedFeatureFilterHandler);
  });

  useEffect(() => {
    setClickedFacilityFilterValue('');
    if (hasAValue(selectedSICCodes.length)) {
    } else {
      setTableData(reportData);
    }
  }, [resetClickedFeatureFilter]);

  useEffect(() => {
    const filteredTableDataByFacility = reportData.filter(
      row => row['Facility'] == clickedFacilityFilterValue
    );

    if (filteredTableDataByFacility.length) {
      if (clickedFacilityFilterValue == currentSelectedFacilityName) {
        createFeatureCollection(reportData);
        setTableData(reportData);
        flyToInitialLocationHandler(appContext.selectedHUC8, mapRef, catalogsContext.huc8Catalog);
      } else {
        setTableData(filteredTableDataByFacility);
      }
    }
    if (clickedFacilityFilterValue !== '') {
      setCurrentSelectedFacilityName(clickedFacilityFilterValue);
    }
  }, [clickedFacilityFilterValue]);

  const resetClickedFeatureFilterHandler = (e: Event) => {
    if (clickedFacilityFilterValue) {
      setResetClickedFeatureFilter(prev => !prev);
    }
  };

  useEffect(() => {
    global.setUserLogged(true);
    if (global.trackerOn) global.setTrackerOn(false)
    getReportData();

    const scrollableTable = document.querySelector(
      'div.table-container.table-container--scrollable'
    );
    scrollableTable?.addEventListener('scroll', e => {
      const scrollAdvancement =
        (scrollableTable as HTMLElement).offsetHeight + scrollableTable.scrollTop;
      const scrollMiddlePoint = (Math.ceil(scrollableTable.scrollHeight / 10) * 10) / 2;
      const maxRangeValue = scrollMiddlePoint + 40;
      if (
        Math.ceil(scrollAdvancement / 10) * 10 >= scrollMiddlePoint &&
        Math.ceil(scrollAdvancement / 10) * 10 >= maxRangeValue
      ) {
        setScrolledToBottom(prev => !prev);
      }
    });

    return () => {
      cleanUpFilters();
    };
  }, []);

  const cleanUpFilters = () => {
    const globalProps = global as Globals;
    globalProps.setFilterSICCodesFitlerOptions([]);
    globalProps.setFilterSICCodesSelected([]);
  };
  useEffect(() => {
    if (scrolledToBottom != undefined && loading == false && !isLastResponseEmpty) {
      const selectedHUC8Value = appContext.selectedHUC8;
      if (selectedHUC8Value && global.hucRestrict.length > 0 && (global.hucRestrict.indexOf(selectedHUC8Value) < 0)) {
        return global.notify(message_hucrestriction)
      }

      setLoading(true);
      agent.Reports.FacilityRegistrySystem(appContext.selectedHUC8, dataPage).then(
        (res: GeneralResponseModel) => {
          let responseBody: FacilityRegistrySystemResponseBodyModel[] | undefined = res.body;

          if (!responseBody) responseBody = [];
          const arrayResponseBody = [...responseBody];

          if (arrayResponseBody.length == 0 || arrayResponseBody.length < 100) {
            setIsLastResponseEmpty(true);
            setLoading(false);
          } else {
            let updatedData: FacilityRegistrySystemResponseBodyModel[] = [];
            setReportData(prevResponseData => {
              const mergedData = [...prevResponseData, ...arrayResponseBody];
              updatedData = [...mergedData];
              return mergedData;
            });
            resolveFiltersOptions(updatedData);
            if (hasAValue(selectedSICCodes.length) || hasAValue(clickedFacilityFilterValue)) {
              const mergedCurrentAndResponseData = [...tableData, ...arrayResponseBody];
              resolveDataWithFilters(mergedCurrentAndResponseData);
            } else {
              setTableData(prevResponseData => [...prevResponseData, ...arrayResponseBody]);
            }
            setDataPage(prevPageNumber => prevPageNumber + 1);
            createFeatureCollection(responseBody);
            setLoading(false);

            setTimeout(() => {
              flyToInitialLocationHandler(
                appContext.selectedHUC8,
                mapRef,
                catalogsContext.huc8Catalog
              );
            }, 1500);
          }
        }
      );
    }
  }, [scrolledToBottom]);

  const getReportData = (page: number = dataPage) => {
    const huc8 = appContext.selectedHUC8;
    const selectedHUC8Value = appContext.selectedHUC8;
    if (selectedHUC8Value && global.hucRestrict.length > 0 && (global.hucRestrict.indexOf(selectedHUC8Value) < 0)) {
      setLoading(false);
      return global.notify(message_hucrestriction)
    }
    agent.Reports.FacilityRegistrySystem(huc8, page).then((res: GeneralResponseModel) => {
      let responseBody: any[] | undefined = res.body;
      if (!responseBody) responseBody = [];

      setDataPage(prevPageNumber => {
        return prevPageNumber + 1;
      });

      setReportData(responseBody);
      resolveDataWithFilters(responseBody);
      createFeatureCollection(responseBody);
      resolveFiltersOptions(responseBody);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (mapRef.current === currentMapRef) {
    } else {
      setCurrentMapRef(mapRef.current);
    }

    setTimeout(() => {
      flyToInitialLocationHandler(appContext.selectedHUC8, mapRef, catalogsContext.huc8Catalog);
      if (mapRef && mapRef.current) {
        mapRef.current.on('click', 'pointlayer', clickedFeatureHandler);
      }
    }, 1000);

    return () => {
      mapRef.current?.off('click', 'pointlayer', clickedFeatureHandler);
    };
  }, [mapRef.current]);

  const clickedFeatureHandler = (e: any) => {
    if (e.features?.length) {
      //@ts-ignore
      const clickedFeatureFacilityName = e.features[0].properties['Facility'];
      setClickedFacilityFilterValue(clickedFeatureFacilityName);
    }
  };

  useEffect(() => {
    if (selectedSICCodes.length) {
      resolveDataWithFilters();
    } else {
      setTableData(reportData);
      createFeatureCollection(reportData);
    }
  }, [selectedSICCodes]);

  useEffect(() => {
    setLoading(true);
    getReportData(0);
    const legendData = [{ typecode: 'STATIONARY', color: '#365587' }, { typecode: 'FACILITY', color: '#9ebae8' }];
    setMapLegendData(legendData);
  }, [appContext.selectedHUC8]);

  const getSeparatedUniqueSICCodesAsArray = (sicCodesArray: string[]) => {
    const onlyUnique = (value: any, index: any, self: any) => {
      return self.indexOf(value) === index;
    };

    const resultArray = [];

    for (let sicCode of sicCodesArray) {
      if (hasAValue(sicCode)) {
        if (sicCode.indexOf(',') >= 0) {
          const separatedSICCodes = sicCode.split(',');
          for (sicCode of separatedSICCodes) {
            resultArray.push(sicCode.replace(/\s+/g, ''));
          }
        } else {
          resultArray.push(sicCode);
        }
      }
    }

    return resultArray.filter(onlyUnique);
  };

  const createFeatureCollection = (reportData: any) => {
    const features: Array<Feature<Point, GeoJsonProperties>> = [];
    const dataGroupedBySic = groupBy<FacilityRegistrySystemResponseBodyModel, any>(
      reportData,
      x => x['Frs Id']
    );
    for (let industrySector in dataGroupedBySic) {
      const facilities: FacilityRegistrySystemResponseBodyModel[] = dataGroupedBySic[
        industrySector
      ].reduce(
        (acc, x) =>
          acc.concat(
            //@ts-ignore
            acc.find(y => y['Facility Name'] === x['Facility Name']) ? [] : [x]
          ),
        []
      );
      for (let facility of facilities) {
        const featureWithPoint: Feature<Point> = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [Number(facility.Longitude), Number(facility.Latitude)],
          },
          properties: {
            SIC: facility.Sic,
            ['FRS ID']: facility['Frs Id'],
            Facility: facility.Facility,
            Longitude: facility.Longitude,
            Latitude: facility.Latitude,
            ['Site Type']: facility['Site Type'],
          },
        };
        features.push(featureWithPoint);
      }
    }

    const featureCollectionFromReportData: FeatureCollection = {
      type: 'FeatureCollection',
      features: features,
    };
    setFeatureData(featureCollectionFromReportData);
  };

  const resolveFiltersOptions = (data: FacilityRegistrySystemResponseBodyModel[]) => {
    const dataGroupedBySic = groupBy<FacilityRegistrySystemResponseBodyModel, any>(
      data,
      x => x.Sic
    );
    const sicCodes = Object.keys(dataGroupedBySic);
    const uniqueSicCodes = getSeparatedUniqueSICCodesAsArray([...sicCodes, ...selectedSICCodes]);

    (global as Globals).setFilterSICCodesFitlerOptions(uniqueSicCodes);
  };

  function resolveDataWithFilters(data: FacilityRegistrySystemResponseBodyModel[] = reportData) {
    let filteredDataResult = [];

    if (selectedSICCodes.length) {
      for (let sicCode of selectedSICCodes) {
        const result = data.filter(x => x.Sic == sicCode);
        filteredDataResult.push(...result);
      }
    } else {
      filteredDataResult = data;
    }

    setTableData(filteredDataResult);
  }

  const clickRowHandler = (rowData: any, event: any) => {
    if (rowAlreadySelected(rowData)) {
      if (currentSelectedRowHTMLElement)
        currentSelectedRowHTMLElement.classList.remove('table-row__selected');
      setCurrentSelectedRowHTMLElement(null);
      setCurrentSelectedRowData({});
      createFeatureCollection(reportData);
      setTableData(reportData);
      flyToInitialLocationHandler(appContext.selectedHUC8, mapRef, catalogsContext.huc8Catalog);
    } else {
      if (currentSelectedRowHTMLElement)
        currentSelectedRowHTMLElement.classList.remove('table-row__selected');
      const selectedRow = event.currentTarget as HTMLElement;

      if (selectedRow) {
        selectedRow.className += 'table-row__selected';
        setCurrentSelectedRowHTMLElement(selectedRow);
        setCurrentSelectedRowData(rowData);
      }
      createFeatureCollection([rowData]);
      flyToLngLat(
        mapRef,
        (rowData as ToxicsReleaseInvetoryResponseBodyModel).Longitude,
        (rowData as ToxicsReleaseInvetoryResponseBodyModel).Latitude
      );
    }
  };

  const rowAlreadySelected = (rowData: {}) => {
    const selectedRowValuesAsArray: string[] = Object.values(rowData);
    const currectSelectedRowValuesAsArray: string[] = Object.values(currentSelectedRowData);

    return arrayAreEqual(selectedRowValuesAsArray, currectSelectedRowValuesAsArray);
  };

  const arrayAreEqual = (array1: string[], array2: string[]) => {
    if (array1.length === array2.length) {
      return array1.every((element, index) => {
        if (element === array2[index]) {
          return true;
        }

        return false;
      });
    }

    return false;
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: 'FRS ID',
        accessor: 'Frs Id',
      },
      {
        Header: 'Facility',
        accessor: 'Facility',
      },
      {
        Header: 'Address',
        accessor: 'Address',
      },
      {
        Header: 'City',
        accessor: 'City',
      },
      {
        Header: 'SIC',
        accessor: 'Sic',
      },
      {
        Header: 'SIC Description',
        accessor: 'Sic Description',
      },
      {
        Header: 'Source',
        accessor: 'Source',
      },
      {
        Header: 'Facility Detail Report URL',
        accessor: 'Facility Detail Report Url',
        Cell: (props: any) => (
          <a
            href={props.value}
            target='_blank'
          >
            FDR link
          </a>
        ),
      },
      {
        Header: 'NAICS',
        accessor: 'Naics',
      },
      {
        Header: 'NAICS Description',
        accessor: 'Naics Description',
      },
      {
        Header: 'Site Type',
        accessor: 'Site Type',
      },
      {
        Header: 'Interest Type',
        accessor: 'Interest Type',
      },
    ],
    []
  );

  const mapInitialViewState = {
    longitude: settings.defaultLocationMap.lng,
    latitude: settings.defaultLocationMap.lat,
    zoom: 3,
  };

  const drillDownMapSources = (mapSource: MapSource, innerSources: any[]) => {
    let filter = mapSource.source?.layerFilter;

    if (mapSource.source) {
      const sourceElement = (
        <Source
          key={uuidv4()}
          id={mapSource.source.id}
          type={mapSource.source.type as 'vector'}
          url={mapSource.source.tilesetURLs}
        >
          <Layer {...mapSource.source.layer} {...{ filter }} />
        </Source>
      );
      innerSources.push(sourceElement);
      drillDownMapSources(mapSource.source, innerSources);
    } else {
      return null;
    }
    return innerSources;
  };

  const pointInPolygonUpdate = (pointsSelected: any) => {
        //setUserSelectedLocations(pointsSelected);
        //setMapHoverFilter(['in', ['get', locationField], ['literal', pointsSelected]]);
  }


  const onUpdate = useCallback((e: any | null) => {
    if (mapRef && mapRef.current) {
      const stationFeatures = mapRef.current.queryRenderedFeatures(
        [e.features[0].geometry.coordinates[0][0], e.features[0].geometry.coordinates[0][2]],
        {
          layers: ['pointlayer'],
        }
      );
    }
  }, []);

   const onDelete = useCallback((e: any | null) => {
    if (mapRef && mapRef.current) {
/*        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', 'Huc12'], '']);*/
    }
  }, []);


  const layerStyle: LayerProps = {
    id: 'pointlayer',
    type: 'circle' as const,
    paint: {
      'circle-radius': 8,
      'circle-color': [
        'match',
        ['get', 'Site Type'],
        'STATIONARY',
        '#365587',
        'FACILITY',
        '#9ebae8',
        '#fff',
      ],
      'circle-stroke-color': 'white',
      'circle-stroke-width': 1,
      'circle-opacity': 1,
    },
  };

  const onMouseEnter = useCallback((event: any | null) => {
    if (event.features && event.features[0]) {
      setCursor('pointer');
      setPopupInfo(event.features[0].properties);
    }
  }, []);
  const onMouseLeave = useCallback(() => {
    setCursor('');
    setPopupInfo(null);
  }, []);

  const renderPopup = () => {
    return (
      <Popup
        style={{ color: 'white' }}

        longitude={Number(popupInfo?.Longitude)}
        latitude={Number(popupInfo?.Latitude)}
        onClose={() => setPopupInfo(null)}
        className='popup-container--dark'
      >
        <div className='popup-container'>
          <Row>
            <Col> SIC:</Col>
            <Col>{popupInfo?.SIC}</Col>
          </Row>
          <Row>
            <Col> FRS ID:</Col>
            <Col>{popupInfo?.['FRS ID']}</Col>
          </Row>
          <Row>
            <Col> Facility:</Col>
            <Col>{popupInfo?.Facility}</Col>
          </Row>
        </div>
      </Popup>
    );
  };

  const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  return (
    <FullScreenComp handle={fullscreenhandle}>
      <div className='toxics-release-inventory' id='FacilityRegistrySystem-report'>
        <ReportHeader global={global} data={reportData} mapRef={mapRef}
          reportID={"FacilityRegistrySystem-report"} fullScreenClickHandle={fullscreenhandle.enter} />
        <div className='container'>
          <Row>
            <Col>
              <div className='map-container'>
                <LoadingSpinner active={loading} />

                {tableData.length > 0 && loading == false && (
                  <>
                    <Map
                      ref={mapRef as Ref<MapRef>}
                      preserveDrawingBuffer={true}
                      mapboxAccessToken={settings.maboxKey}
                      initialViewState={mapInitialViewState}
                      mapStyle={global.mapStyle}
                      interactiveLayerIds={['pointlayer']}
                      cursor={cursor}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                      projection={global.globeView ? 'globe' : 'mercator' as any}
                      onMove={(e) => {
                        global.setViewport({
                          longitude: e.viewState.longitude,
                          latitude: e.viewState.latitude,
                          zoom: e.viewState.zoom,
                        })
                      }}
                    >

                    <MapAddition global={global} 
                                mapRef={mapRef}
                                onDelete={onDelete}
               
                                position={'low'}
                                zipOff={true}
                                MapSliderAdd={true}
                                />

                      {featureData && (
                        <Source id='circlesource' type='geojson' data={featureData}>
                          <Layer {...layerStyle} />
                        </Source>
                      )}
                      {popupInfo && renderPopup()}

                      <div className='map-legend-container'>
                        <MapLegend
                          mapLegendData={mapLegendData}
                          title={'Site Type'}
                          legendWidth={320}
                          global={global}
                        />
                      </div>
                    </Map>
                  </>
                )}

              </div>
            </Col>
          </Row>
          <Row></Row>
          <Row>
            <Col style={{ width: '23rem' }}>
              <div
                className={
                  isFullScreenChartActive ? 'container--expand-container table-container' : ''
                }
              >
                <div className='report-options'>
                  <Button onClick={fullscreenChartContainerClickHandler}>
                    <img src={fullscreenIcon} />
                  </Button>
                </div>
                <ReportTable
                  data={tableData}
                  columns={tableColumns}
                  clickRowHandler={clickRowHandler}
                  clickLoadStop
                />
              </div>
            </Col>
          </Row>
          <ReportFooter />
        </div>
      </div>

    </FullScreenComp>
  );
};

export default FacilityRegistrySystem;

type FacilityRegistrySystemResponseBodyModel = {
  Address: string;
  City: string;
  Facility: string;
  'Facility Detail Report Url': string;
  'Frs Id': string;
  'Interest Type': string;
  Latitude: Number;
  Longitude: Number;
  Naics: string;
  'Naics Description': string;
  Sic: string;
  'Sic Description': string;
  'Site Type': string;
  Source: string;
};
