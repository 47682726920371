import "./MapTitle.scss";

const MapTitle = ({
  selectedHUC8Value,
  statusMessage
}: {
  selectedHUC8Value?: string;
  statusMessage?: string;
}) => {


  return (
    <>
      <div className='te-map-title'>
        <p>Viewing: HUC {selectedHUC8Value}</p>
        <p>{statusMessage}</p>
      </div>     
    </>
  );
};

export default MapTitle;