
  export const marksR = {
    1: {
      style: {     
        color: "green",
        fontSize: '1rem',
      },
      label: 'SSP245',
    },
    2: {
      style: {   
        color: "yellow",
        fontSize: '1rem',
      },
      label: 'SSP370',
    },
    3: {
      style: { 
        color: "red",
        fontSize: '1rem',
      },
      label: 'SSP585',
    },
  };


  export const marksR_ = {
    0: {
      style: {
   
       
        color: "green",
        fontSize: '1rem',
      },
      label: 'RCP 1.9',
    },
    1: {
      style: {
 
    
        color: "chartreuse",
        fontSize: '1rem',
      },
      label: 'RCP 2.6',
    },
    2: {
      style: {

        color: "yellow",
        fontSize: '1rem',
      },
      label: 'RCP 4.5',
    },
    3: {
      style: {

      
        color: "orange",
        fontSize: '1rem',
      },
      label: 'RCP 6.0',
    },
    4: {
      style: {
   
   
        color: "red",
        fontSize: '1rem',
      },
      label: 'RCP 8.5',
    },
  };


  export const marksSeason = {
    0: {
      style: {
        
        borderRadius: 5,
        
       
        color: "green",
        fontSize: '1rem',
      },
      label: 'None',
    },
    1: {
      style: {
     
        borderRadius: 5,
        
    
        color: "chartreuse",
        fontSize: '1rem',
      },
      label: 'Low',
    },
    2: {
      style: {
       
        borderRadius: 5,
       
        
        color: "yellow",
        fontSize: '1rem',
      },
      label: 'Moderate',
    },
    3: {
      style: {
       
        borderRadius: 5,
        
      
        color: "orange",
        fontSize: '1rem',
      },
      label: 'High',
    },
    4: {
      style: {
     
        borderRadius: 5,
     
   
        color: "red",
        fontSize: '1rem',
      },
      label: 'Very High',
    },
  };