// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.global-hydrobasins-map-container {
  height: 100%;
  width: 100%;
  padding: 1rem; }
  .global-hydrobasins-map-container .simple-map-container {
    padding: 1rem;
    height: 100%; }
    .global-hydrobasins-map-container .simple-map-container .map {
      width: 95vw;
      height: 40vh;
      border-radius: 4px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(255, 255, 255, 0.1); }
  .global-hydrobasins-map-container .simple-table-container {
    overflow: auto;
    padding: 1rem; }
  .global-hydrobasins-map-container .footer {
    position: absolute;
    bottom: 3%;
    right: 3%; }
`, "",{"version":3,"sources":["webpack://./src/features/reports/SimpleTableReport/simple-table-report.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,WAAW;EACX,aAAa,EAAA;EAHjB;IAKQ,aAAa;IACb,YAAY,EAAA;IANpB;MAQY,WAAW;MACX,YAAY;MACZ,kBAAkB;MAClB,6EAA6E,EAAA;EAXzF;IAiBQ,cAAc;IACd,aAAa,EAAA;EAlBrB;IAsBQ,kBAAkB;IAClB,UAAU;IACV,SAAS,EAAA","sourcesContent":[".global-hydrobasins-map-container {\r\n    height: 100%;\r\n    width: 100%;\r\n    padding: 1rem;\r\n    .simple-map-container {\r\n        padding: 1rem;\r\n        height: 100%;\r\n        .map {\r\n            width: 95vw;\r\n            height: 40vh;\r\n            border-radius: 4px;\r\n            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(255, 255, 255, 0.1);\r\n        }\r\n    \r\n    }\r\n\r\n    .simple-table-container {\r\n        overflow: auto;\r\n        padding: 1rem;\r\n    }\r\n\r\n    .footer {\r\n        position: absolute;\r\n        bottom: 3%;\r\n        right: 3%;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
