import React from 'react';
import "./FemaFirm.scss";

function FemaFirmLegend() {


  //, backgroundColor: 'white', color: 'black' 

  return (                    
    <div
      id="esri_dijit_Legend_0"
      style={{ position: "relative"}}
    >

      <div
        id="legend-firmette-pr"
        className="femafirm"
        style={{ display: "block" }}
      >

        <div
          id="esri_dijit_Legend_0_NFHLREST_FIRMette_9129_0"
          className="legend-firmette"
        
        >
          <table width="95%" className="esrLabel">
            <tbody>
              <tr>
                <td align="left">NFHL Availability</td>
              </tr>
            </tbody>
          </table>
          <table  style={{marginLeft: 5}}>
            <tbody>
              <tr >
                <td>
                <img                    
                     style={{ opacity: 1, backgroundImage: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARElEQVQ4je3MMREAIAwDwAzdYcyKIrRWRp10rAOYuENAx7yAtxOxkDnRgSxD5sTeoyV0h7VEH4UKFSpU+EKy4N6zkXUBoeoNlB87x2UAAAAASUVORK5CYII="' }}

                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARElEQVQ4je3MMREAIAwDwAzdYcyKIrRWRp10rAOYuENAx7yAtxOxkDnRgSxD5sTeoyV0h7VEH4UKFSpU+EKy4N6zkXUBoeoNlB87x2UAAAAASUVORK5CYII=" alt="NFHL Data Available"/>
                </td><td  style={{paddingLeft: 5}}>NFHL Data Available</td>
              </tr>
            </tbody>
          </table>
        </div>
         <div
          id="esri_dijit_Legend_0_NFHLREST_FIRMette_9129_20"
          className="legend-firmette"
          style={{ display: "block", marginTop: 5  }}
        >
          <table width="95%" className="esriLegendLayerLabel">
            <tbody>
              <tr>
                <td align="left">Flood Hazard Zones</td>
              </tr>
            </tbody>
          </table>
          <table
            cellPadding={0}
            cellSpacing={0}
            width="95%"
            className="esriLegendLayer"
          >
            <tbody>
              <tr>
                <td width={35} align="center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAPUlEQVQ4je3MQQ0AIAwEwW1SB6AB/6J41sPxgQQBfd4ImGRrEEw6iMqbrZYwIFuij0OHDh06fKEoomkTdQCGBQhapxAr5AAAAABJRU5ErkJggg=="
                     
                    style={{ opacity: 1, backgroundImage: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAPUlEQVQ4je3MQQ0AIAwEwW1SB6AB/6J41sPxgQQBfd4ImGRrEEw6iMqbrZYwIFuij0OHDh06fKEoomkTdQCGBQhapxAr5AAAAABJRU5ErkJggg=="' }}
                  />
                </td>
                <td>
                  <table width="95%" dir="ltr">
                    <tbody>
                      <tr>
                        <td align="left">1% Annual Chance Flood Hazard</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td width={35} align="center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAOklEQVQ4je3MMREAIAwEwZtJHIAGpKI2FgL01F/+Cti8m0EzUQgqaSaHJQmBVEUOHTp06PAPg5JtQT0Apwf8VYsjHwAAAABJRU5ErkJggg=="
                     
                     style={{ opacity: 1, backgroundImage: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAOklEQVQ4je3MMREAIAwEwZtJHIAGpKI2FgL01F/+Cti8m0EzUQgqaSaHJQmBVEUOHTp06PAPg5JtQT0Apwf8VYsjHwAAAABJRU5ErkJggg=="' }}
                  />
                </td>
                <td>
                  <table width="95%" dir="ltr">
                    <tbody>
                      <tr>
                        <td align="left">0.2% Annual Chance Flood Hazard</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>


              <tr>
                <td width={35} align="center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAvklEQVQ4ja3USw6CMBCA4Z+kN9Cde68Bd8B7EBLiCjAgB4OVHkR3vUNZKA1Yy6N0dp1JviYzkxG81IGAI6NQp4ClCN7KTCqk+GLnIdfG0SJWZ5XlFxDjdxtHhM9uEbulpbWuQR+YBn1hAGLNANZg4aOjvUTTHu7FgHlwKzYLumBW0BX7C+7BDHAvNgF9YBr0hQGIIslpPGFFkiOa690LVmcVTVrOL/YWbGiZFXTBrKAr9gEVkp+DswYDzM1QyB4wonrh/3vLoQAAAABJRU5ErkJggg=="
                     
                                    style={{ opacity: 1, backgroundImage: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAvklEQVQ4ja3USw6CMBCA4Z+kN9Cde68Bd8B7EBLiCjAgB4OVHkR3vUNZKA1Yy6N0dp1JviYzkxG81IGAI6NQp4ClCN7KTCqk+GLnIdfG0SJWZ5XlFxDjdxtHhM9uEbulpbWuQR+YBn1hAGLNANZg4aOjvUTTHu7FgHlwKzYLumBW0BX7C+7BDHAvNgF9YBr0hQGIIslpPGFFkiOa690LVmcVTVrOL/YWbGiZFXTBrKAr9gEVkp+DswYDzM1QyB4wonrh/3vLoQAAAABJRU5ErkJggg=="' }}
  
                  />
                </td>
                <td>
                  <table width="95%" dir="ltr">
                    <tbody>
                      <tr>
                        <td align="left">Regulatory Floodway </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td width={35} align="center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA+0lEQVQ4jZXVMQ6CMBQG4L9Jb6Cbu2dgK4M30IuQMCoN6GRMjAfxCobGQbyHbNyAoS5CSnjtq/9Cwmu+ljweSHzsAgJLONmfDzjeTvBFtJYuWHTyh63d+5v304sBQL1Nkd5rYidAUotVY4KgaowXnYAxGIeOYAgziSJrFCo5rMpL6KxAea1QXDSLSrsS3scaMADjNYSqxsybQmFDOBQguuzDYlASDGEx6AzksCGPRIFaSb7Y5BQ4US+DepeStRkYmgIOI8EQymFekEI5bGimFK1lp0DnZRQ2npCdgkhsBDk0FpuA/6K+AZCw6OB8H3RWoO/74C/A3XwSi+4LBeWnk0JNcqMAAAAASUVORK5CYII="
                     
                                        style={{ opacity: 1, backgroundImage: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA+0lEQVQ4jZXVMQ6CMBQG4L9Jb6Cbu2dgK4M30IuQMCoN6GRMjAfxCobGQbyHbNyAoS5CSnjtq/9Cwmu+ljweSHzsAgJLONmfDzjeTvBFtJYuWHTyh63d+5v304sBQL1Nkd5rYidAUotVY4KgaowXnYAxGIeOYAgziSJrFCo5rMpL6KxAea1QXDSLSrsS3scaMADjNYSqxsybQmFDOBQguuzDYlASDGEx6AzksCGPRIFaSb7Y5BQ4US+DepeStRkYmgIOI8EQymFekEI5bGimFK1lp0DnZRQ2npCdgkhsBDk0FpuA/6K+AZCw6OB8H3RWoO/74C/A3XwSi+4LBeWnk0JNcqMAAAAASUVORK5CYII="' }}
  
                  />
                </td>
                <td>
                  <table width="95%" dir="ltr">
                    <tbody>
                      <tr>
                        <td align="left">Special Floodway</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td width={35} align="center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQElEQVQ4jWP59KRSmIHxrwgDNcB/5jcsEMP+qVHFQEYGBhaqGIQERg0cNXDUwFEDRw2EGfif+Q0DI5VM+8/8BgBecAvH7FmRZQAAAABJRU5ErkJggg=="
                     
          
                                        style={{ opacity: 1, backgroundImage: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQElEQVQ4jWP59KRSmIHxrwgDNcB/5jcsEMP+qVHFQEYGBhaqGIQERg0cNXDUwFEDRw2EGfif+Q0DI5VM+8/8BgBecAvH7FmRZQAAAABJRU5ErkJggg=="' }}
  
                  />
                </td>
                <td>
                  <table width="95%" dir="ltr">
                    <tbody>
                      <tr>
                        <td align="left">Area of Undetermined Flood Hazard</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td width={35} align="center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABFklEQVQ4ja3UPaqEMBAH8D+4ELDNDbyA6BECNsJrBEvBwtY7CR5D8AKCINiqnZ3GKiHdFrsvj8f6sa47VciQH5lMmFuapj/4YtwAwDRNEEL0ZtM0U1VV9OhwkiR6rZSCEOIBEkJgGIZOuq5Lu66b2rbdRfM8RxzH+DU0uBZhGFIAu+gwDMiyTKMAtsGzaBRFx+AZtCxLOI5zDL6L9n3/B5ZlCc/zLqPAs+S+718e91NUl7zWsU/Qf2/4DfSlKVfR1S5fQTe/zVl0nme6C55F67qeANDDj/0uats25Zw/bmhZFsZxvIwCz5IZY1iW5fRk2QSVUgiCgEopJynlJqqUQlEUYIyt5jQohIAQAr7vH05pAOCcb+bumMjMNyKUx2EAAAAASUVORK5CYII="
                     
                    style={{ opacity: 1 }}
                  />
                </td>
                <td>
                  <table width="95%" dir="ltr">
                    <tbody>
                      <tr>
                        <td align="left">
                          Future Conditions 1% Annual Chance Flood Hazard
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td width={35} align="center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABCUlEQVQ4ja3TPYrCQBjG8T8YCFoJdhYWYjpBWJhOULSy2EptrPQAuYKgeAJrC/s9QGDBBWuPoKxgkU7sIikEi2g+1mwyMXm6mXfmx9s8iq7rn2QYBaBX2KGpJwDmP7D5jf40EfnzuHEt+e/2dgXDEg6oqSdqOZP2GrbHaGxQ57z4oPQyUPFAQBr7GoZgvigArRWYZnrMBeMii0mBMtj2CKNv6PdjQFmsvYZyOWbDJJg/oeC7WCiYBnsB02IBMAvMBSciH16nhJgL/i36u5gLZoF1qxKgLDbrwKgJy0sEmASbtuBw8224tyugBrHpxqvTf+lWnc0Ot4fxBA1LYFjCe1l0ii6T5SV4vgPxPm4jb17nRQAAAABJRU5ErkJggg=="
                     
                    style={{ opacity: 1 }}
                  />
                </td>
                <td>
                  <table width="95%" dir="ltr">
                    <tbody>
                      <tr>
                        <td align="left">Area with Reduced Risk Due to Levee</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td width={35} align="center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA2UlEQVQ4ja3SPQ6CQBCG4ZewtQ129sQjKHgCNLHAexgTAyF6Ao9ga8c5SPQchBIbW6NYEAmI/O90+83uU8yOeESehvIak6vR5ERTPSIv/n1HosYiDd/6N7NsvxFbL6d3lKdeaigg8mfL9gluYSN2Oa+0qn4GysAyUBYGINp8QBssuIZYG784w6EYUA92xWrBPlgl2Bf7Cw7BSuBQrADKwDJQFgYgnK3J0ZlLwZytiTg6JvAejB32C9ydUb/Y3bAZULPYfbBKsC+WgokaoxQvtsEA3J1RDBI1/gBXCY1cSr7k5AAAAABJRU5ErkJggg=="
                     
                    style={{ opacity: 1 }}
                  />
                </td>
                <td>
                  <table width="95%" dir="ltr">
                    <tbody>
                      <tr>
                        <td align="left">Area with Risk Due to Levee</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="esri_dijit_Legend_0_NFHLREST_FIRMette_9129_2"
          className=""
          style={{ display: "block", marginTop: 5 }}
        >
          <table width="95%" className="esriLegendLayerLabel">
            <tbody>
              <tr>
                <td align="left">LOMRs</td>
              </tr>
            </tbody>
          </table>
          <table
            cellPadding={0}
            cellSpacing={0}
            width="95%"
            className="esriLegendLayer"
          >
            <tbody>
              <tr>
                <td width={35} align="center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASklEQVQ4jWNhiHn2n4GKgAXBZDxAmVH/HZAMZDzAsETSkSLzYp7vZ2D478BCWCVpYNTAUQNHDRw1kK4G/neAlGeUAJQCFiFAKQAAETwPUTvHEKYAAAAASUVORK5CYII="
                     
                    style={{ opacity: 1 }}
                  />
                </td>
                <td>
                  <table width="95%" dir="ltr">
                    <tbody>
                      <tr>
                        <td align="left">Effective</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="esri_dijit_Legend_0_NFHLREST_FIRMette_9129_4"
          className=""
          style={{ display: "block", marginTop: 5 }}
        >
          <table width="95%" className="esriLegendLayerLabel">
            <tbody>
              <tr>
                <td align="left">Political Jurisdictions</td>
              </tr>
            </tbody>
          </table>
          <table
            cellPadding={0}
            cellSpacing={0}
            width="95%"
            className="esriLegendLayer"
          >
            <tbody>
              <tr>
                <td width={35} align="center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAS0lEQVQ4je3UsQ0AIQwEwbXkjo6aqBbX5A8QEjnWR97skgnP5yQjKEkCj4C1asAxwM8ww16wTBIusKoGG2ywwZ/B82fPoLSftiIJPqB4DueeS8fLAAAAAElFTkSuQmCC"
                     
                    style={{ opacity: 1 }}
                  />
                </td>
                <td>
                  <table width="95%" dir="ltr">
                    <tbody>
                      <tr>
                        <td align="left" />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="esri_dijit_Legend_0_NFHLREST_FIRMette_9129_5"
          className=""
          style={{ display: "block", marginTop: 5 }}
        >
          <table width="95%" className="esriLegendLayerLabel">
            <tbody>
              <tr>
                <td align="left">Profile Baselines</td>
              </tr>
            </tbody>
          </table>
          <table
            cellPadding={0}
            cellSpacing={0}
            width="95%"
            className="esriLegendLayer"
          >
            <tbody>
              <tr>
                <td width={35} align="center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAMElEQVQ4jWNhoDJgGTVw1MBBZaDPiv0UmbQlwhHVQCoBhIFQG6hnIJXAqIGjBpIBALQXBJ5Q3oTYAAAAAElFTkSuQmCC"
                     
                    style={{ opacity: 1 }}
                  />
                </td>
                <td>
                  <table width="95%" dir="ltr">
                    <tbody>
                      <tr>
                        <td align="left" />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="esri_dijit_Legend_0_NFHLREST_FIRMette_9129_7"
          className=""
          style={{ display: "none" }}
        >
          <table width="95%" className="esriLegendLayerLabel">
            <tbody>
              <tr>
                <td align="left">Cross-Sections</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="esri_dijit_Legend_0_NFHLREST_FIRMette_9129_8"
          className=""
          style={{ display: "none" }}
        >
          <table width="95%" className="esriLegendLayerLabel">
            <tbody>
              <tr>
                <td align="left">Base Flood Elevations</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="esri_dijit_Legend_0_NFHLREST_FIRMette_9129_9"
          className=""
          style={{ display: "block" }}
        >
          <table width="95%" className="esriLegendLayerLabel">
            <tbody>
              <tr>
                <td align="left">Levees</td>
              </tr>
            </tbody>
          </table>
          <table
            cellPadding={0}
            cellSpacing={0}
            width="95%"
            className="esriLegendLayer"
          >
            <tbody>
              <tr>
                <td width={35} align="center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAP0lEQVQ4jWNhoDJgGTVw6BnYgEbDgAMUH4Biog2sx2EgTM6eVANJBqMG0t7ARhziB3DJEZsOiZYb+mE4DA0EALPXCJCW4WPdAAAAAElFTkSuQmCC"
                       className={'image-backdrop'}
                                      style={{ opacity: 1, backgroundImage: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAP0lEQVQ4jWNhoDJgGTVw6BnYgEbDgAMUH4Biog2sx2EgTM6eVANJBqMG0t7ARhziB3DJEZsOiZYb+mE4DA0EALPXCJCW4WPdAAAAAElFTkSuQmCC"' }}

                  />
                </td>
                <td>
                  <table width="95%" dir="ltr">
                    <tbody>
                      <tr>
                        <td align="left" />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="esri_dijit_Legend_0_NFHLREST_FIRMette_9129_12"
          className=""
          style={{ display: "block" }}
        >
          <table width="95%" className="esriLegendLayerLabel">
            <tbody>
              <tr>
                <td align="left">Coastal Transects</td>
              </tr>
            </tbody>
          </table>
          <table
            cellPadding={0}
            cellSpacing={0}
            width="95%"
            className="esriLegendLayer"
          >
            <tbody>
              <tr>
                <td width={35} align="center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAO0lEQVQ4jWNhoDJgGTVw1MBBZuB/KH2AgYHBEYf6/QwMDA5oYijqaepCRiLU43I5VgOpAkYNHDWQDAAAFY8Ekzl3QeEAAAAASUVORK5CYII="
                     className={'image-backdrop'}
                    style={{ opacity: 1 }}
                  />
                </td>
                <td>
                  <table width="95%" dir="ltr">
                    <tbody>
                      <tr>
                        <td align="left" />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="esri_dijit_Legend_0_NFHLREST_FIRMette_9129_13"
          className=""
          style={{ display: "block" }}
        >
          <table width="95%" className="esriLegendLayerLabel">
            <tbody>
              <tr>
                <td align="left">Transect Baselines</td>
              </tr>
            </tbody>
          </table>
          <table
            cellPadding={0}
            cellSpacing={0}
            width="95%"
            className="esriLegendLayer"
          >
            <tbody>
              <tr>
                <td width={35} align="center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAK0lEQVQ4jWNhoDJgGTVw1MBBZuB/Cs1iRDeQKgDZQEZqG0gVMGrgqIFkAACB5QFSIZLASgAAAABJRU5ErkJggg=="
                       className={'image-backdrop'}
                    style={{ opacity: 1 }}
                  />
                </td>
                <td>
                  <table width="95%" dir="ltr">
                    <tbody>
                      <tr>
                        <td align="left" />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="esri_dijit_Legend_0_NFHLREST_FIRMette_9129_14"
          className=""
          style={{ display: "none" }}
        >
          <table width="95%" className="esriLegendLayerLabel">
            <tbody>
              <tr>
                <td align="left">General Structures</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="esri_dijit_Legend_0_NFHLREST_FIRMette_9129_15"
          className=""
          style={{ display: "none" }}
        >
          <table width="95%" className="esriLegendLayerLabel">
            <tbody>
              <tr>
                <td align="left">River Mile Markers</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="esri_dijit_Legend_0_NFHLREST_FIRMette_9129_18"
          className=""
          style={{ display: "block" }}
        >
          <table width="95%" className="esriLegendLayerLabel">
            <tbody>
              <tr>
                <td align="left">Limit of Moderate Wave Action</td>
              </tr>
            </tbody>
          </table>
          <table
            cellPadding={0}
            cellSpacing={0}
            width="95%"
            className="esriLegendLayer"
          >
            <tbody>
              <tr>
                <td width={35} align="center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAjklEQVQ4je3TIQoCURQF0KMMTLMaTEb3MOAm3IBbMEweg8VgFQYsBvfhCrS7BLOYRMsUP+MI+osyNz3u5x9eeYnISVrwVabY4hYDHGKNLjYxwCVSzLHD9Rsww6SaB5hh8SnYwSrocpQ4vwMLjIO3PkZB18MBpxprj6JpwwuONf294c/ThlHyP5fSgr8MPgC+txG/pLjlHgAAAABJRU5ErkJggg=="
                    className={'image-backdrop'}   
                    style={{ opacity: 1 }}
                  />
                </td>
                <td>
                  <table width="95%" dir="ltr">
                    <tbody>
                      <tr>
                        <td align="left" />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="esri_dijit_Legend_0_NFHLREST_FIRMette_9129_19"
          className=""
          style={{ display: "block" }}
        >
          <table width="95%" className="esriLegendLayerLabel">
            <tbody>
              <tr>
                <td align="left">Flood Hazard Boundaries</td>
              </tr>
            </tbody>
          </table>
          <table
            cellPadding={0}
            cellSpacing={0}
            width="95%"
            className="esriLegendLayer"
          >
            <tbody>
              <tr>
                <td width={35} align="center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARUlEQVQ4jWNhoDJgGTVw1EAKDPxtIrifEoNYz7x3RDGQecZmB0oMZDCxYUAxkFoAbuDfDN8DVDUQFgZUM5BaYNTAkWAgAC/8CsIiHIqWAAAAAElFTkSuQmCC"
                     
                    style={{ opacity: 1 }}
                  />
                </td>
                <td>
                  <table width="95%" dir="ltr">
                    <tbody>
                      <tr>
                        <td align="left">Limit Lines</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td width={35} align="center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAM0lEQVQ4jWNhoDJgGTVw1MDBZOD////3U2IQIyOjI4qB1AJwA2E2UM1AaoFRA0cNJAMAAIB3BJX+1au7AAAAAElFTkSuQmCC"
                     
                    style={{ opacity: 1 }}
                  />
                </td>
                <td>
                  <table width="95%" dir="ltr">
                    <tbody>
                      <tr>
                        <td align="left">SFHA / Flood Zone Boundary</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td width={35} align="center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAbElEQVQ4je3RwQ2AIAyF4f/AXuJklM1wMr3UiEmLEfTGuwAFvpAS+DhhghMEQICk8wJkHbvAWGHnOioqLVCAxQGtJOP8BshvPRRnf3fqBVhboJfMvYd13cwTKPqaxNXPoV9GL7tAD/gqExzPAV+UEVDt3+iTAAAAAElFTkSuQmCC"
                     
                    style={{ opacity: 1 }}
                  />
                </td>
                <td>
                  <table width="95%" dir="ltr">
                    <tbody>
                      <tr>
                        <td align="left">Flowage Easement Boundary</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
       
        <div
          id="esri_dijit_Legend_0_NFHLREST_FIRMette_9129_21"
          className=""
          style={{ display: "block" }}
        >
          <table width="95%" className="esriLegendLayerLabel">
            <tbody>
              <tr>
                <td align="left">Primary Frontal Dunes</td>
              </tr>
            </tbody>
          </table>
          <table
            cellPadding={0}
            cellSpacing={0}
            width="95%"
            className="esriLegendLayer"
          >
            <tbody>
              <tr>
                <td width={35} align="center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAaklEQVQ4je3PwQmAMBBE0b8hJQh2YxUKlihqCZ5ShD0I1pB4U0RxheSWzHn2MWtJHFvAAkaAoaXHsKsXnuqtJyPLDUTo8EwK2mAAcOpCYEOo4cfKj1xgwKkve1atc4IyM8Qse4CpUsAcwANU+RLzYMImdAAAAABJRU5ErkJggg=="
                     
                    style={{ opacity: 1 }}
                  />
                </td>
                <td>
                  <table width="95%" dir="ltr">
                    <tbody>
                      <tr>
                        <td align="left" />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        id="esri_dijit_Legend_0_msg"
        className="esriLegendMsg"
        style={{ display: "none" }}
      >
        No legend
      </div>
    </div>
  );
}


export default FemaFirmLegend;
