import React from 'react';
import { MapSource } from '../../types/MapSource'
import { Layer, Source } from 'react-map-gl'
import { useState, useEffect, useContext } from 'react'
import settings from '../../settings.json'
import { v4 as sourceId } from 'uuid';
import { RefContext } from '../../RefContext';

const HUCListLine = (props: any) => {

  const {
    global,
    data,
    colorHUC8,
    colorHUC12,
    width,
    huc8Width,
    opacity,
    mapRef,
    huc8ID,
    huc12ID,
    huc12MinZoom = 8,
    huc8sOn = true
  } = props

  const [huc8Filter, setHuc8Filter] = useState([])
  const [features, setFeatures] = useState()
  const [huc12Filter, setHuc12Filter] = useState([
    'in',
    'HUC12',
  ])

  const { currentRef } = useContext(RefContext)

  const buildMap = () => {
    let huc8s = data.map((huc8: any) => huc8.huc8)
    setHuc8Filter(huc8s)
    let outer = data.map((huc8: any) => {
      return huc8.huc12s.map((huc12: any) => huc12.huc_12_code)
    })
    setHuc12Filter((oldState) =>
      [...oldState].concat(outer.flat())
    )
  }

  // @ts-ignore
  const mapInstance = currentRef && currentRef?.current

  useEffect(() => {
    if (mapInstance) {
      mapInstance.on('click', (event: any) => {
        const layerObject = mapInstance.queryRenderedFeatures();
        setFeatures(layerObject)
      })
    }
  }, [mapInstance])


  useEffect(() => {
    if (data) {
      buildMap()
    }
  }, [])

  const huc12sLayerPaint = {
    'line-opacity': opacity,
    'line-color': `${colorHUC12}`,
    'line-width':  {
      'base': .2,
      'stops': [
          [5, .1],
          [8, 1],
          [14, 5],
          [17, 10]
        ]
      },
  };

  const huc12sLayer: any = {
    "source-layer": 'True Elements - HUC 12s',
    type: 'line',
    paint: huc12sLayerPaint,
    minzoom: huc12MinZoom
  }

  const huc12sSource = {
    type: 'vector',
    tilesetURLs: settings.tileSetURLs.HUC12,
    layer: huc12sLayer,
    layerFilter: props.switch ? huc12Filter : ['all']
  }

  const huc8sLayerPaint = {
    'line-opacity': opacity,
    'line-color': `${colorHUC8}`,
    'line-width': huc8Width,
  };

  const huc8slayer: any = {
    "source-layer": 'True Elements - huc8s',
    type: 'line',
    paint: huc8sLayerPaint,
    minzoom: 1
  }

  const huc8sSource: any = {
    type: 'vector',
    tilesetURLs: settings.tileSetURLs.HUC8,
    layer: huc8slayer,
    layerFilter: props.switch ? huc8Filter : ['all'],
    source: huc12sSource
  };


  const drillDownMapSources = (mapSource: MapSource, innerSources: any[]) => {
    let filter = mapSource.source?.layerFilter;

    if (mapSource.source) {
      const sourceElement = (
        <Source
          key={sourceId()}
          id={huc12ID}
          type={mapSource.source.type as 'vector'}
          url={mapSource.source.tilesetURLs}
        >
          <Layer {...mapSource.source.layer} beforeId={settings.defaultBeforeId} {...{ filter }} />
        </Source>
      );
      innerSources.push(sourceElement);
      drillDownMapSources(mapSource.source, innerSources);
    } else {
      return null;
    }
    return innerSources;
  };

  const renderSources = () => {
    return (
      <Source
        key={sourceId()}
        id={huc8ID}
        type={huc8sSource.type as 'vector'}
        url={huc8sSource.tilesetURLs}
      >
        {drillDownMapSources(huc8sSource, [])?.map((x) => x)}
        {huc8sOn && (
          <Layer
            {...huc8sSource.layer}
            filter={huc8sSource.layerFilter}
            beforeId={settings.defaultBeforeId}
          />
        )}
      </Source>
    )
  }
  return (
    <>
      {renderSources()}
    </>
  )
}

export default HUCListLine