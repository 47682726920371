import html2canvas from 'html2canvas';
import { jsPDF, ImageOptions } from "jspdf";
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import settings from "../../../settings.json";
import { DateTime } from 'luxon';
import { ThemeType, Color } from 'jspdf-autotable';

//++ Summary Report
const colorGreen = "#4fc22c";
const colorYellow = "#e6ee00"; 
const colorYellowDarker = "#d3da00";  //255,255,200 #FFFFC8
const colorYellowFill = "#FFFFC8";  //255,255,200 
const colorRed = "#c22c4f";
const colorUnavailable = "#3c343c";
const colorWhite = "#ffffff";
const colorLightGray = "#717171";
const colorReallyLightGray = "#e2e2e2";
const colorSectionHeader = "#191919";
const textFooter = colorLightGray;
const locationField = 'name';
const reportTitle = "True QI Disclosure Risk Report Scores";
const logoSource = "logo490.png";
const logoAR = 3.53;
let companyName = 'NASDAQ';


//https://www.epa.gov/national-aquatic-resource-surveys/streamcat-dataset-readme
//https://www.epa.gov/national-aquatic-resource-surveys



  const onPDF = (data: any) => {
    const logoSource = "logo490.png";
    const colorSectionHeader = "#191919";
    const colorLightGray = "#717171";
    let companyName = 'True Elements';
    let reportTitle = 'Riverine Stress';
    const pageY = 6;
    const textFooter = colorLightGray;
    var totalPagesExp = '{total_pages_count_string}';  
    const titleColor = '#257ee0';
    const textColor = '#232323';

    const colorGreen = "#4fc22c";
    const colorYellow = "#e6ee00"; 
    const colorYellowDarker = "#d3da00";  //255,255,200 #FFFFC8
    const colorYellowFill = "#FFFFC8";  //255,255,200 
    const colorRed = "#c22c4f";

    const climateScenarioDesc = ['The RCP slider impacts only the scenario year, and adjusts how much precipitation impact will occur, depending on the level of climate change that is predicted.']; 
    const climateScenarioRCP = ['RCP 1.9, is a pathway that limits global warming to below 1.5°C.',
    'RCP 2.6, is likely to keep global temperature rise below 2°C by 2100.',
    'RCP 4.5, is described by the IPCC as an intermediate scenario, likely to result in global temperature rise between 2°C and 3°C, by 2100.',
    'RCP 6, is making the global temperature rise by about 3–4°C by 2100.',
    'RCP 8.5, generally taken as the basis for worst-case climate change scenarios.'];
    const seasonalImpactDesc = ['None','Low','Moderate','High','Very High'];

    var doc = new jsPDF();
    var imgbr = new Image(); 
    imgbr.src = logoSource;

    var pageSize = doc.internal.pageSize
    var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var date = new Date();

    let pageTitleIndent = 12;

    const headerFooter = () => {
        doc.setTextColor(colorSectionHeader)
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(16)
            //doc.text(companyName, 12, 20)
            doc.line(12, 25, pageWidth-12, 25)

            doc.setFontSize(10)
            doc.setTextColor(colorLightGray)
            doc.setFont('normal');
            doc.text(reportTitle, pageWidth - 37, 8)
             
            // Footer
            var str = 'Page ' + doc.getNumberOfPages()            
            if (typeof doc.putTotalPages === 'function') {
              str = str + ' of ' + totalPagesExp
            }
            doc.setFontSize(10)
            doc.setTextColor(textFooter);

            doc.text(str, pageWidth - 25, pageHeight - pageY)          
            doc.addImage(imgbr, 'PNG', 6, pageHeight - 14, 42.4, 12);   //3.53
            doc.text("© True Elements Inc. 2023", pageWidth/2-20, pageHeight - pageY);
            //color: #257FE0
            //#173248!important;
    }

    let huc12info = "";
    if(data.huc12listName && data.huc12listName.length>0){
      console.log(huc12info, data.huc12listName, data.huc12Selection, data.huc12listName.find((item: any) => item.value === data.huc12Selection))
      huc12info = data.huc12listName.find((item: any) => item.value === data.huc12Selection).name;
      console.log(data.huc12listName.find((item: any) => item.value === data.huc12Selection).name)
    }

    console.log(data)

      //++ Cover Page
      doc.addImage(imgbr, 'PNG', pageWidth/2 - 90, pageHeight/2 -100, 170, 48);  // 3.53
      doc.setFontSize(20)
      doc.setTextColor(titleColor)
      doc.setFont('normal');
      doc.text(reportTitle, 83, pageHeight/2+40) 

      //++ First Page
      doc.addPage();
      headerFooter();

      doc.setTextColor(colorSectionHeader)  
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(12)
      doc.text(`${data.currentHuc8} ${data.huc8name}`, pageTitleIndent , 22)

      var lines = doc.splitTextToSize(climateScenarioRCP[data.sliderClimate], 120);

      let pageOneStart = 40;
      let pageOneLineHeight = 10;
      let pageOneIndent = 23;
      let pageOneSecondColumn = 83;

      doc.setFontSize(12)
      doc.setTextColor('gray')
      doc.setFont('normal');
      doc.text(`Target HUC Location: `, pageOneIndent , pageOneStart)
      doc.text(`Model Year Rain Type: `, pageOneIndent , pageOneStart + pageOneLineHeight )  
      doc.text(`Future Year of Interest: `, pageOneIndent , pageOneStart + (pageOneLineHeight*2) ) 
      doc.text(`Current Model Base: `, pageOneIndent , pageOneStart + (pageOneLineHeight*3)  ) 

      doc.text(`Climate Scenario: `, pageOneIndent , pageOneStart + (pageOneLineHeight*4) ) 

      doc.text(`Seasonal Impact - Winter: `, pageOneIndent , pageOneStart + (pageOneLineHeight*6) ) 
      doc.text(`- Spring: `, pageOneIndent + 31 , pageOneStart + (pageOneLineHeight*7) ) 
      doc.text(`- Summer: `, pageOneIndent  + 31, pageOneStart + (pageOneLineHeight*8) ) 
      doc.text(`- Fall: `, pageOneIndent  + 31, pageOneStart + (pageOneLineHeight*9) ) 

      doc.text(`Model Year Precipitation: `, pageOneIndent , pageOneStart + (pageOneLineHeight*11) ) 
      doc.text(`Scenario Year Precipitation: `, pageOneIndent , pageOneStart + (pageOneLineHeight*12) ) 



      doc.setTextColor(textColor)
      doc.text(`${data.currentHuc8} ${data.huc8name}`, pageOneSecondColumn, pageOneStart)
      doc.text(`${data.rainSelection} ${(data.rainSelection==='Specific Year') ? ` (${data.rainSelectionYear})` : ''}`, pageOneSecondColumn, pageOneStart + (pageOneLineHeight) )   
      doc.text(`${data.futureYear}`, pageOneSecondColumn, pageOneStart + (pageOneLineHeight*2) )
      doc.text(`${data.currentModelBase}`, pageOneSecondColumn, pageOneStart + (pageOneLineHeight*3) )
      doc.text(`${lines[0]}`, pageOneSecondColumn, pageOneStart + (pageOneLineHeight*4) )
      doc.text(`${lines[1]}`, pageOneSecondColumn, pageOneStart + (pageOneLineHeight*5) )
      doc.text(`${seasonalImpactDesc[data.sliderWinter]}`, pageOneSecondColumn, pageOneStart + (pageOneLineHeight*6) )
      doc.text(`${seasonalImpactDesc[data.sliderSpring]}`, pageOneSecondColumn, pageOneStart + (pageOneLineHeight*7) )
      doc.text(`${seasonalImpactDesc[data.sliderSummer]}`, pageOneSecondColumn, pageOneStart + (pageOneLineHeight*8) )
      doc.text(`${seasonalImpactDesc[data.sliderFall]}`, pageOneSecondColumn, pageOneStart + (pageOneLineHeight*9) )

      doc.text(`${data.modelYearPrecipitation}`, pageOneSecondColumn, pageOneStart + (pageOneLineHeight*11) )
      doc.text(`${data.scenarioYearPrecipitation} in.`, pageOneSecondColumn, pageOneStart + (pageOneLineHeight*12) )


      //++ Second Page
      doc.addPage();
      headerFooter();

      const pageClass = ['GD-Winter', 'GD-Spring', 'GD-Summer', 'GD-Fall', 'GD-Scenario', 'GD-ModelScenario']
      const endpageClass = ['GD-Fall', 'GD-Flow', 'GD-Model', 'mapboxgl-canvas']
      const canvas = document.querySelectorAll("canvas");
          
      function swapElement(array:any, indexA:any, indexB:any) {
        var tmp = array[indexA];
        array[indexA] = array[indexB];
        array[indexB] = tmp;
      }
          
          //swapElement(canvas, 0, 8);
          //@ts-ignore  
          const canvasList = [...document.querySelectorAll("canvas")];
          swapElement(canvasList, 0, 6);
          swapElement(canvasList, 1, 7);
          swapElement(canvasList, 2, 8);
          swapElement(canvasList, 3, 5);
          swapElement(canvasList, 4, 7);
          //swapElement(canvasList, 5, 6);
          swapElement(canvasList, 6, 8);

          console.log(canvasList)
          let modelHeight = 80;
          let seasonHeight = 130;

          let pageWidthA = 100; 
          let pageHeightA = 200;
          let index = 1;
          // traverse the array of canvas 
          canvasList.forEach( (canva:HTMLCanvasElement) => {

            //console.log(canva.height, canva.width)
            if(canva.height === 0){
              canva.height = 444;
            }
            if(canva.width === 0){
              canva.width = 444;
            }
            
            let imageDimension = 170;
            let aspectRatio = canva.height / canva.width;
            let imageHeight = imageDimension*aspectRatio;

            const className = canva.className;
            let x = 50;
            let y = 50;
            if(className === 'GD-Winter'){
                x=20;
                y = 30;
                imageDimension = 80;
                aspectRatio = canva.height / canva.width;
                imageHeight = imageDimension*aspectRatio;

                doc.setTextColor(colorSectionHeader)  
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(12)
                doc.text('Seasonal Impact on Peak Runoff', pageTitleIndent , 22) 
                headerFooter();

                seasonHeight = imageHeight + 30;
            }
            if(className === 'GD-Spring'){
                x=110;
                y = 30;
                imageDimension = 80;
                aspectRatio = canva.height / canva.width;
                imageHeight = imageDimension*aspectRatio;
            }
            if(className === 'GD-Summer'){
                x=20;
                y=seasonHeight;
                imageDimension = 80;
                aspectRatio = canva.height / canva.width;
                imageHeight = imageDimension*aspectRatio;
            }
            if(className === 'GD-Fall'){
                x=110;
                y=seasonHeight;
                imageDimension = 80;
                aspectRatio = canva.height / canva.width;
                imageHeight = imageDimension*aspectRatio;
            }

            if(className === 'mapboxgl-canvas'){
                let length = 0;
                if(huc12info && huc12info.length >0){
                  length = huc12info.length;
                }

                x=20;
                y=40;
                aspectRatio = canva.height / canva.width;
                imageHeight = imageDimension*aspectRatio;

                doc.setTextColor(colorSectionHeader)  
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(12)
                doc.text('Stream Bank Erosion Modeler', pageTitleIndent , 22)  

                doc.setTextColor(textColor)  
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(12)
                doc.text(`HUC12: ${data.huc12Selection} ${huc12info}`, 80 - huc12info.length , 34) 

                let legendStart = Math.min(imageHeight + 70, 250);  //200;
                let legendLineHeight = 5;//8;
                let legendXStart = 30;
                console.log(legendStart, imageHeight)

                doc.text('Potential Erodibility Warning Level', legendXStart, legendStart) 

                doc.text('0: Very Low', legendXStart+15, legendStart + legendLineHeight)              
                doc.setFillColor('#00f0d0')
                doc.rect(legendXStart, (legendStart - 3) + legendLineHeight, 10, 3, 'F')                 
                
                doc.text('1: Low', legendXStart+15, legendStart + legendLineHeight*2)
                doc.setFillColor(colorGreen)
                doc.rect(legendXStart, (legendStart - 3) + legendLineHeight*2, 10, 3, 'F')
                
                doc.text('2: Moderate', legendXStart+15, legendStart + legendLineHeight*3)
                doc.setFillColor(colorYellow)
                doc.rect(legendXStart, (legendStart - 3) + legendLineHeight*3, 10, 3, 'F')
                
                doc.text('3: High', legendXStart+15, legendStart + legendLineHeight*4)
                doc.setFillColor('orange')
                doc.rect(legendXStart, (legendStart - 3) + legendLineHeight*4, 10, 3, 'F')
                
                doc.text('4: Very High', legendXStart+15, legendStart + legendLineHeight*5)
                doc.setFillColor('red')
                doc.rect(legendXStart, (legendStart - 3) + legendLineHeight*5, 10, 3, 'F')

                headerFooter();
            }

            if(className === 'GD-Model'){
                x=30;
                y=30 + modelHeight*2;
                imageDimension = 150;
                if(aspectRatio > .5){
                  imageDimension = 120;
                  x=50;
                }
                aspectRatio = canva.height / canva.width;
                imageHeight = imageDimension*aspectRatio;
                headerFooter();
            }
            if(className === 'GD-Scenario'){
                x=30;
                y=30 + modelHeight;
                imageDimension = 150;
                aspectRatio = canva.height / canva.width;
                if(aspectRatio > .5){
                  imageDimension = 120;
                  x=50;
                }
                imageHeight = imageDimension*aspectRatio;
            }
            if(className === 'GD-ModelScenario'){
                x=30;
                y=30;
                imageDimension = 150;
                aspectRatio = canva.height / canva.width;
                if(aspectRatio > .5){
                  imageDimension = 120;
                  x=50;
                }
                imageHeight = imageDimension*aspectRatio;

                doc.setTextColor(colorSectionHeader)  
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(12)
                doc.text('Model Year Forecast', pageTitleIndent , 22)
                headerFooter();

                modelHeight = imageHeight+15;                
            }


            if(className === 'GD-Flow'){
              imageDimension = 100;
              aspectRatio = canva.height / canva.width;
              imageHeight = imageDimension*aspectRatio;
              doc.setTextColor(colorSectionHeader)  
              doc.setFont('helvetica', 'bold');
              doc.setFontSize(12)
              doc.text('Flow Duration Curve', pageTitleIndent , 22) 
              headerFooter();
            }


            let cont = canva.getContext('2d');
            //@ts-ignore
/*            if(cont){
              console.log(cont)
                cont.scale(.1, .1)
            }*/


            aspectRatio = canva.height / canva.width;
            imageHeight = imageDimension*aspectRatio;
            //console.log(canva, canva.height )
            if(canva && canva.height > 0){
                doc.addImage(canva, 'PNG', x, y, imageDimension, imageDimension*aspectRatio, `img${index}`, "NONE");
            }
            if (index < canvas.length) {
              if(endpageClass.indexOf(className)>-1){                 
                  doc.addPage();
              }
            }
            index++;
          });


    let th = 'plain' as ThemeType;
    let attr = [{'Source' : 'Global Historical Climatology Network daily (GHCNd)', 'Sourceurl' : 'https://www.ncei.noaa.gov/products/land-based-station/global-historical-climatology-network-daily' , 'url' : 'https://www.ncei.noaa.gov/products/land-based-station/global-historical-climatology-network-daily'}];
    attr.push({'Source' : 'National Aquatic Resource Surveys StreamCat ', 'Sourceurl' : 'https://www.epa.gov/national-aquatic-resource-surveys/streamcat-dataset' , 'url' : 'https://www.epa.gov/national-aquatic-resource-surveys/streamcat-dataset'});


    //++ Sources
    let reportColumnsAttr = { 
         columns: [
            { dataKey: 'Source', header: 'Data Source' },
            { dataKey: 'Sourceurl', header: '' },

          ],
         columnStyles: {
              1: { cellWidth: 120},
              0: { cellWidth: 70},
        },//@ts-ignore
        body: attr, //@ts-ignore
        theme: th,
        willDrawCell: function (data:any) {
          if (data.row.section === 'body'){
              if(data.row.index === 0) {
                doc.setDrawColor(colorSectionHeader);
                doc.line(12, data.cell.y, pageWidth-10, data.cell.y);
              } else {                
                doc.setDrawColor(colorReallyLightGray);
                doc.line(12, data.cell.y, pageWidth-15, data.cell.y);
              }
              
          }
            if (data.row.section === 'body' && data.column.dataKey === 'Sourceurl') {                    
                  doc.textWithLink('', data.cell.x, data.cell.y, {url: data.cell.raw});
            } else if (data.row.section === 'body' && data.column.dataKey === 'Source') {                    
                  doc.setTextColor(textColor)  
                  doc.link(data.cell.x+1, data.cell.y+1, 15, 200, {url: attr[data.row.index].url});
            }
        },    
        didDrawPage: function (data:any) {
          headerFooter();

          doc.setTextColor(colorSectionHeader)
          doc.setFont('helvetica', 'bold');
          doc.setFontSize(12)
          doc.text('Sources', pageWidth-30, 21) 
        },
        margin: { top: 25, left: 12, bottom: 14 },
    };



      doc.addPage();
      //@ts-ignore
      autoTable(doc, reportColumnsAttr)



      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp)
      }

      doc.setProperties({ title: reportTitle })
      window.open(doc.output('bloburl'), '_blank');
  }



export const GroundwaterReport = (data: any) => { 
  if(data){
    onPDF(data);
  }
}