import { 
  useEffect, useContext, 
  useState, useMemo, 
  useCallback 
} from 'react';
import { Layer, Source, Popup } from 'react-map-gl';
import { FillLayer } from 'mapbox-gl';
import { RefContext } from '../../RefContext';
import { PointContext } from '../../PointContext';
import { adjustHexBrightness } from "../../features/utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import { CEJST_Feilds } from '../../features/utils';
import { useNavigate } from 'react-router-dom';

const CEJSTLayer = ({ 
    id,
    global,
    brightness, 
    opacity, 
    tileSet, 
    sourceLayer,
    zoom, 
    action
  }: any) => {

  const { currentRef } = useContext(RefContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
//@ts-ignore
  const mapInstance = currentRef && currentRef.current;
  const nav = useNavigate()

  const [properties, setProperties] = useState<any[]>([]);
  const [flaggedObjects, setflaggedObjects] = useState<string[]>();
  const [popupData, setPopupData] = useState()
  const [open, setOpen] = useState(false)
  const [popup, setPopup] = useState<any>()
  const [paint, setPaint] = useState([
    ['==', ['get', 'GEOID10'], '06065043254'],
    ['interpolate', ['linear'], ['get', 'LHE'], 1, 'transparent']
  ])


  const lngLat = action && action.lngLat 

  const goTo = (geoId: string) => {
    setTimeout(() => setCurrentPoint({
      geoId,
      lng: lngLat.lng,
      lat: lngLat.lat,
      zoom
    }))
    nav('/CEJST')
  }
  

  const getFeaturesAtPoint = async (map: any, event: any) => {
    const features = await map.queryRenderedFeatures(event.point)?.[0]; 
    setPopupData(features.properties);
    setOpen(true)
  };
  
  
    useEffect(() => { 
    if(mapInstance) {
      mapInstance.on('click', (e:any) => getFeaturesAtPoint(mapInstance, e))
    }
  }, [mapInstance])
  
  useEffect(() => {
    let interval:any;
    const fetchData = async () => {
      try {
        if (mapInstance) {
          const features = await mapInstance.querySourceFeatures(
            `${id}-layer`, 
            { sourceLayer }
            );
            
          const propertiesList = features.map((feature: any) => feature.properties).filter(Boolean);
          setProperties(propertiesList);
        }
      } catch (error) {
        console.log(error);
      }
    };
    interval = setTimeout(fetchData, 3000);
  }, [mapInstance, zoom, id, sourceLayer]);

  const keys_bool = [
    "EPL_ET","EAL_ET","EBL_ET","WFR_ET","PLHSE","EB_ET","PM25_ET","A_ET",
    "A_ET","HD_ET","LLE_ET","HB_ET","IS_ET","KP_ET","LPP_ET","TSDF_ET",
    "NPL_ET","DS_ET","TD_ET","UST_ET","WD_ET","LISO_ET","LMI_ET","IAULHSE",
  ];

  const keys_90 = [
    "EALR_PFS","EBLR_PFS","EPLR_PFS","FLD_PFS","WFR_PFS","LMI_PFS","IALMIL_76","IAPLHS_77",
    "EBF_PFS","PM25F_PFS","AF_PFS","DF_PFS","HDF_PFS","LLEF_PFS","LPF_PFS","HBF_PFS",
    "IS_PFS","KP_PFS","TSDF_PFS","RMP_PFS","NPL_ET","DSF_PFS","TD_PFS","UST_PFS",
    "WF_PFS","LIF_PFS","P100_PFS","P200_I_PFS","IAULHS_78","UF_PFS","IA_UN_ET","IAULHS_78",
  ]

const findHighestKeyValues = (obj: any) => {
  const values: number[] = [];
  const sortedKeys: string[] = [];

  keys_90.forEach((key: string) => {
      if (!isNaN(obj[key]) && obj[key] < 1) {
          values.push(obj[key]);
          sortedKeys.push(key);
      }
  });

  values.sort((a, b) => b - a);

  const result: { [key: string]: number } = {};
  for (let i = 0; i < Math.min(2, values.length); i++) {
      result[sortedKeys[values.indexOf(values[i])]] = values[i];
  }

  return result;
}

  const findHighestKeyValue = (obj: any) => {
    let values: any = []

    keys_90.forEach((key:any) => {
     if(!isNaN(obj[key] && obj[key] < 1)){
       values.push(obj[key])
     } 
    })

    const highestNumber = Math.max(...values)
    const highestKey = keys_90[values.indexOf(highestNumber)]
   
    return highestKey ? { highestKey, highestNumber } : null;
  }

  const handlePercentile = (properties: any) => {

    const handleSuffix = (input: string) => {
        switch (input) {
            case '0':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
                return 'th';
            case '1':
                return 'st';
            case '2':
                return 'nd';
            case '3':
                return 'rd';
            default:
                return '';
        }
    }

    const score = Math.round(properties * 100)
    const array = score.toString().split('')
    const lastNum = array[array.length - 1]
    const suffix = handleSuffix(lastNum)
    const percentileString = `${score}${suffix} Percentile`
    return percentileString
  }

  const handleClose = () => {
    setPopupData(undefined)
    setPopup(undefined)
    setOpen(false)
  } 

  const createPopup = (tractId: string, key: string, percent: number, lat: number, lng: number, obj: any) => {
    const secondaryFlags = Object.keys(obj)
    const trimString = (str: string): string => {
      const regex = /\((percent(?:ile)?)\)/g;
      return str.replace(regex, '').trim();
    };

    const flag: any = CEJST_Feilds.find((i:any) => i.name === key)
    const secondFlag: any = CEJST_Feilds.find((i:any) => i.name === secondaryFlags[0])
    const thirdFlag: any = CEJST_Feilds.find((i:any) => i.name === secondaryFlags[1])

    const linkStyle: any = {
      textAlign: 'right',
      textDecoration: 'underline',
      cursor: 'pointer',
      marginRight: '4%',
      color: '#4FC2D9'
    }

    setPopup(
      <Popup 
        longitude={lng} 
        latitude={lat}
        style={{ width: '350px' }}
        anchor="bottom"
        onClose={handleClose}
      >
        <FontAwesomeIcon style={{ position: 'absolute', right: "3%", top: "4%", cursor: 'pointer' }} onClick={handleClose} icon={faXmark} />
        <p>Tract ID: {tractId}</p>
        <ul style={{ listStyle: 'lower-roman', paddingLeft: '1.5rem' }}>
          <li><p>{handlePercentile(percent)}{' - '}{flag ? trimString(flag.alias) : 'no data'}</p></li>
          <li><p>{handlePercentile(obj[secondaryFlags[0]])}{' - '}{secondFlag ? trimString(secondFlag.alias) : 'no data'}</p></li>
          <li><p>{handlePercentile(obj[secondaryFlags[1]])}{' - '}{thirdFlag ? trimString(thirdFlag.alias) : 'no data'}</p></li>
        </ul>
        <a style={linkStyle} onClick={() => goTo(tractId)}>Go to report</a>
      </Popup>
    )
  }

  const handlePopupData = (input: any) => {

    const { lat, lng } = lngLat
    const { highestKey, highestNumber }: any = findHighestKeyValue(input)
    const obj = findHighestKeyValues(input)

    createPopup(input.GEOID10, highestKey, highestNumber, lat, lng, obj)
  }

  useEffect(() => {
    if(popupData) {
      handlePopupData(popupData)
    }
  },[popupData])


  useEffect(() => global.setCEJSTPopup(true), [])

  const handleColor = (input: any) => {

    let wholeNumber = input * 100;
    let base = 90 - wholeNumber;
    
    switch (base) {
      case 0:
        return '#00FFFF';
      case -1:
        return '#008000';
      case -2:
        return '#00FF00';
      case -3:
        return '#32CD32';
      case -4:
        return '#7FFF00';
      case -5:
        return '#ADFF2F';
      case -6:
        return '#FFFF00';
      case -7:
        return '#FFD700';
      case -8:
        return '#FFA500';
      case -9:
        return '#FF4500';
      case -10:
        return '#FF0000';
      default:
        return 'transparent'
    }
    
  }

  const handlePaint = (input:any) => {
    const paintList = input.map((e: any) => {
        const { highestKey, highestNumber }: any = findHighestKeyValue(e)

        return [
            ['==', ['get', "GEOID10"], e.GEOID10 ? e.GEOID10 : null],
            ['interpolate', ['linear'], ['get', highestKey ? highestKey : ''], highestNumber ? highestNumber : null, adjustHexBrightness(handleColor(highestNumber), brightness + 1)],
        ]
    }) 

    setPaint(paintList.flat())
}

useEffect(() => {
    if(flaggedObjects) {
        handlePaint(flaggedObjects)
    }
}, [flaggedObjects])

const checkValues = useCallback((obj:any) => {  
    const hasHighValues = Object.keys(obj).some(key => keys_90.includes(key) && obj[key] >= 0.90);
    
    return hasHighValues ? obj : null
  },[])


  useEffect(() => {
    if (properties.length > 0) {
      let paintSet: string[] = [];
      const removeNullElements = (array:any) => array.filter((item:any) => item !== null);

      properties.forEach((set: any) => {  
            paintSet.push(checkValues(set))
      });

      const cleanSet = removeNullElements(paintSet)
      setflaggedObjects(cleanSet);
    }
  }, [properties, brightness]);


  const CEJSTLayerConfig: FillLayer = useMemo(() => ({
    id: `${id}-layer`,
    type: "fill",
    "source-layer": sourceLayer,
    paint: {
      "fill-opacity": opacity,
      "fill-outline-color": "brown",
      'fill-color': [
        'case',
        ...paint,
        `${adjustHexBrightness('#ffffff', brightness + 1)}`
      ],
    },
  }), [id, sourceLayer, opacity, paint]);


  return (
    <>
      <Source
        id={`${id}-layer`}
        type="vector"
        url={tileSet}
      >
        <Layer
          {...CEJSTLayerConfig} 
        />
      </Source>
      {global.CEJSTPopup && open && popup}
    </>
  );
  
}

export default CEJSTLayer;  
