import { useEffect, useState, useRef, useCallback, useContext, useMemo, Ref } from "react";
import * as React from 'react';
import {
  Map, MapboxStyle, useMap, MapRef, MapLayerMouseEvent, Popup,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  useControl,
  Layer,
  Source,
  FillLayer,
} from "react-map-gl";
import settings from "../../../settings.json";
import { Form, Button, Row, Col, Table, Container } from "react-bootstrap";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import agent from "../../../api/agent";
import { formatRowValue } from "../../utils";

import LoadingDataAnimation from "../../../components/LoadingDataAnimation";
import { Feature, GeoJsonProperties, Point, Polygon, GeometryCollection, FeatureCollection, Geometry } from 'geojson';
import type { LayerProps } from 'react-map-gl';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import moment from 'moment';
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import * as d3Scale from 'd3-scale';
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import bbox from '@turf/bbox';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { DateTime } from 'luxon';
import { ToastContainer, toast } from 'react-toastify';
import { message_hucrestriction, message_nodatalocation, message_noresult } from '../../../Constants';
import { AppContext } from '../../../AppContext';
import { CatalogsContext } from '../../../CatalogsProvider';
import { Location, useLocation } from 'react-router-dom';
import MapLegend from '../shared/MapLegend';
import MapAddition from  '../shared/MapAddition';
import FilterState from '../shared/FilterState';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import ReportMap from '../shared/ReportMap';
import ReportTableExpanded from '../shared/ReportTableExpanded';
import {
  useTable, useExpanded, HeaderGroup,
  HeaderProps
} from 'react-table'


import { flyToHUC12sLocation, flyToInitialLocationHandler, getCurrentHuc12LongLat, getHuc12MembersOfHUC8, getCurrentHuc8LongLat } from '../../utils';
import Render from "../../../components/render";
import { RefContext } from '../../../RefContext';
import { createFeatureCollection, processStatusMessage, uniqueValues, updateChartData, groupByKey, fitBoundsMapData, findFittingDateRange } from '../shared/report-data';

import './styles.scss'
//--IMAGES
import companyIcon from "../../../assets/company-icon.svg";
import arrowDownIcon from "../../../assets/arrow-down-icon.svg";
import arrowUpIcon from "../../../assets/arrow-up-icon.svg";
import arrowDownDoubleIcon from "../../../assets/arrow-down-double-icon.svg";
import arrowDownDoubleTreeIcon from "../../../assets/arrow-down-double-tree-icon.svg";
import fullscreenIcon from "../../../assets/fullscreen-icon.svg";
import ellipsisIcon from "../../../assets/ellipsis-icon.svg";

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { features } from "process";
import { Line, Bar, getElementAtEvent, getElementsAtEvent } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartDataset, ChartType, TooltipItem, registerables
} from "chart.js";
import zoomPlugin from 'chartjs-plugin-zoom';
import MapSlider from "../shared/MapSlider";

import { Radar } from 'react-chartjs-2';
import { result } from "lodash";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  Filler,
  zoomPlugin
);

export default function PfasEnvironmental(props: any) {
  const { global } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);
  const selectedHUC8Value = appContext.selectedHUC8;

  const selectedCategory = (global.filter1Selected || 'UCMR-4');
  let selectedDateRange = global.dateRangeSelected;

  const selectedFilterLocations = global.filterLocationsSelected;
  const selectedContaminants = global.chemicalsSelected;
  const selectedYear = global.selectedYearFilter;

  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;

  const { current: map } = useMap();
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [cursor, setCursor] = useState<string>('auto');
  const mapRef = useRef<MapRef>();
  const [mapLoaded, setMapLoaded] = useState(false);
  const locationField = 'Location Name';
  const [mapHoverFilter, setMapHoverFilter] = useState<any>(['in', ['get', locationField], '']);
  const [mapLegendData, setMapLegendData] = useState<any[]>([]);
  const [circleColorStyle, setCircleColorStyle] = useState<any>([]);
  const [mapStyleData, setMapStyleData] = useState<any>([]);
  const [huc8LonLatCatalogArray, setHuc8LonLatCatalogDataArray] = useState<any[]>([]);
  const [huc12InfoCatalogArray, setHuc12InfoCatalogDataArray] = useState<any[]>([]);
  const [userSelectedLocation, setUserSelectedLocation] = useState<any>(null);
  const [userSelectedLocations, setUserSelectedLocations] = useState<any[]>([]);
  const [usLocations, setUsLocations] = useState<any>([]);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [features, setFeatures] = useState({});
  const [featureData, setFeatureData] = useState<FeatureCollection>();
  const [reportData, setReportData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [statusMessage, setStatusMessage] = useState<any>(null);
  const [layerStyle, setLayerStyle] = useState<any>([]);
  const [currentDate, setCurrentDate] = useState<any>(null);
  const chartRef = useRef<any>();
  const [filterLabelDays, setFilterLabelDays] = useState<any>([]);

  const divergingColors = ['#d73027', '#74add1', '#f46d43', '#fdae61', '#fee090', '#ffffbf', '#e0f3f8', '#abd9e9', '#74add1', '#4575b4'];
  const reportTypes = ['UCMR-4', 'UCMR-3', 'UCMR-2', 'UCMR-1 Chemical', 'UCMR-4 Microbial'];
  const chartFields = ["Maximum Analytical Value", "Minimum Analytical Value", "Minimum Reporting Level"];
  const chartFieldsInternal = ["Maxanalyticalvalue", "Minanalyticalvalue", "Meanmrl"];
  const colorsWaterType = {'PFAS Detected': '#d73027', 'Present Below Quantification Limit': '#6a3d9a','Below Reporting Limit': '#74add1', 'Not Detected': '#e0f3f8',  'GU': '#b15928', 'Other': '#abd9e9'} 

 
  const [data, setData] = useState({
    labels: [] as any,
    datasets: [] as any,
  } as const);

  const [config, setConfig] = useState({} as const);
  const [isDataReportLoaded, setIsDataReportLoaded] = useState(false);
  const [isTableSort, setTableSort] = useState(false);
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const [isFullScreenActive, setFullScreenActive] = useState(false);
  const [isFullScreenChartActive, setFullScreenChartActive] = useState(false);
  const fullscreenContainerHandler = () => { setFullScreenActive(!isFullScreenActive); };
  const fullscreenChartContainerHandler = () => { setFullScreenChartActive(!isFullScreenChartActive); };


  useEffect(() => {
    global.setUserLogged(true);
    if(global.trackerOn) global.setTrackerOn(false)
  }, []);

  const { setCurrentRef } = useContext(RefContext);
  useEffect(() => { setCurrentRef?.(mapRef) }, [mapRef])
  const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  useEffect(() => {
    global.setfilterLocationsSelected([]);
    global.setChemicalsSelected([])
  }, []);

  useEffect(() => {
    setFilteredData([]);
    setReportData([]);
    setUserSelectedLocations([]);
    global.setfilterLocationsSelected([]);
    global.setChemicalsSelected([])
  }, [selectedHUC8Value]);

  useEffect(() => {
    let filterData = [...filteredData];
    if ((userSelectedLocations) && (userSelectedLocations.length > 0) && (userSelectedLocations.indexOf('All') < 0)) {
      filterData = filterData.filter((o: any) => userSelectedLocations.indexOf(o[locationField]) > -1);
      sortFilterReportData([...filterData], isTableSort)
    } else {
      filterReportData();
    } 
  }, [userSelectedLocations]);

  useEffect(() => {   
    let chartInput = [...filteredData];
    setChartConfig(true);
    const chartdata = updateLineChartData(chartInput, "Pfas Chemical Name", "Result Measure Value");

    setTimeout(() => {
            if(chartdata){
                setData(chartdata);
            }
        }
      , 100); 
  }, [filteredData]);

  useEffect(() => {
    if(statusMessage){
        global.notify(statusMessage)
    }
  }, [statusMessage]);

  useEffect(() => {
    setIsDataReportLoaded(false);
    queryData(0, []);
  }, [selectedHUC8Value]);

  const checkData = (page: any, data:any) => {
    if(page===4){
        updateData(data);
        return;
    } else {
        queryData(page+1, data)
    }
  }

  const queryData = (page: any, data:any) => {
    setStatusMessage("")
    if(selectedHUC8Value && global.hucRestrict.length>0 && (global.hucRestrict.indexOf(selectedHUC8Value) < 0)){
        setIsDataReportLoaded(true);
        return global.notify(message_hucrestriction) 
    }

    agent.Reports.PFA(selectedHUC8Value, page, 100).then((res: any) => {
        if(page === 0){
            flyToInitialLocationHandler(selectedHUC8Value, mapRef, catalogsContext.huc8Catalog);
        }

        const returnStatus = processStatusMessage(res);
        setStatusMessage(returnStatus.statusMessage)
        if (returnStatus.Abort) {
          setIsDataReportLoaded(true);
          return;
        }

        let returnedData = [...res.body];
        if (!returnedData || !returnedData.length || returnedData.length < 1) {
          setIsDataReportLoaded(true);
          setStatusMessage(message_noresult);
          return;
        }

        returnedData = [...res.body].concat(data);     
        if(res.body.length > 99){
            checkData(page, returnedData);
        } else {
            updateData(returnedData);
        }
    });
  }


  const updateData = (returnedData: any) => {
    const chemLabels = uniqueValues(returnedData, 'Pfas Chemical Name');
    global.setChemicalsFilerOptions(['All', ...chemLabels]);
    const yearLabels = uniqueValues(returnedData, 'Year');
    global.setSelectedYearFilterOptions(['All', ...yearLabels]);
    const siteTypeLabels = uniqueValues(returnedData, 'Location Name');
    const siteLabels = uniqueValues(returnedData, 'Location Name');
    const siteFilter = siteLabels.map((x: any, index: number) => ({ huc12: x, hucname: x }));
    global.setfilterLocationsPopulated([{ 'hucname': 'All', 'huc12': 'All' }, ...siteFilter]);
    const resultLabels = uniqueValues(returnedData, 'Result Detection');
    global.setfilter1Populated(resultLabels)

    for (let dataItem of returnedData) {
        if(dataItem["Project File Url"]==='-'){
            dataItem["Project File Url"] = null;
        }

        let dt = '';
        if(dataItem["Analysisstartdate"] && !isNaN(dataItem["Analysisstartdate"]) && dataItem["Analysisstartdate"]>0){
            dt = DateTime.fromMillis(dataItem["Analysisstartdate"]).toLocaleString();
        }
        dataItem.datetime = dt;
        dataItem.centroid_latitude = dataItem.Latitude; //+++
        dataItem.centroid_longitude = dataItem.Longitude; //+++ 
        dataItem.latlng = '' + dataItem.Latitude + dataItem.Longitude; 
        dataItem.sortindex = colorTypeSort(dataItem['Result Detection']);
        dataItem.radius = 12;
        dataItem.name = dataItem['Location Name'];
    };

    setReportData(returnedData);

    const fData = returnedData;
    //updateFilters(fData);
    setFilteredData(fData);

    const _legendData = resultLabels.map((x: any, index: number) => ({ sortindex: colorTypeSort(x), typecode: x, color: colorType(x) }));
    const legendData = _legendData.sort((x: any, y: any) => x['sortindex'] - y['sortindex']);
    setMapLegendData(legendData);

    const _mapStyleData = resultLabels.map((x: any, index: number) => ([x, colorType(x)])).flat();
    _mapStyleData.push("#fee090")
    setMapStyleData(_mapStyleData);

    const circleColorStyle: Array<any> = ['match', ['get', 'Result Detection']];
    circleColorStyle.push(_mapStyleData)
    setCircleColorStyle(circleColorStyle);    
    setIsDataReportLoaded(true); 
  }

  useEffect(() => {
    updateLayerStyle();
  }, [isDataReportLoaded]);

  const colorType = (selectedtype: string) => {
    if(colorsWaterType[selectedtype as keyof typeof colorsWaterType]){
      return colorsWaterType[selectedtype as keyof typeof colorsWaterType];
    } else {
      return "#fee090";
    }
  }

  const colorTypeSort = (selectedtype: string) => {
    if(selectedtype ==='PFAS Detected'){return 0;}
    else if(selectedtype ==='Present Below Quantification Limit'){return 1;}
    else if(selectedtype ==='Below Reporting Limit'){return 2;}
    else if(selectedtype ==='Not Detected'){return 3;}
    else {return 4;}
  }


  const updateFilters = (filterData: any) => {
    const contaminantLabels = uniqueValues(filterData, 'Pfas Chemical Name');
    //global.setChemicalsFilerOptions(['All', ...contaminantLabels]);

    const siteLabels = uniqueValues(filterData, 'Pwsid');
    const siteFilter = siteLabels.map((x: any, index: number) => ({ huc12: x, hucname: x }));
    //global.setfilterLocationsPopulated([{ 'hucname': 'All', 'huc12': 'All' }, ...siteFilter]);
  }

  
  const updateLineChartData = (filterData: any, groupKey: string, selectedCategory: string) => {
    if(!filterData || filterData.length < 1){
        return;
    }

 /*    const groupedresultsRaw = groupByKey<any, any>(filterData, i => i[locationField]);
    const site_data: any[] = [];
    for (const key in groupedresultsRaw) {
      const sumVal = d3Array.rollups(
        groupedresultsRaw[key],
        xs => d3Array.sum(xs, x => x['Result Measure Value']),
        d => key
      )

      site_data.push(sumVal[0][1]);
    }

 */
    let fDataset = [...filterData].filter((o: any) => filterData[0][locationField].indexOf(o[locationField]) > -1).sort((x: any, y: any) => x[groupKey].toLowerCase().localeCompare(y[groupKey].toLowerCase()));

    const yearLabels = uniqueValues(fDataset, 'Year');
    if(yearLabels.length > 1 && selectedContaminants.length !==1 && selectedContaminants.indexOf('All')<0){
        fDataset = [...fDataset].filter((o: any) => yearLabels[yearLabels.length-1] === o['Year'])
    }
 
    const mappedValuesResult = fDataset.map((x: any) => (x["Result Measure Value"]===null) ? 0 : x["Result Measure Value"]);
    const mappedValuesMeasure = fDataset.map((x: any) => x["Detection Quantitation  Measure Value"]);
    const groupedresults = groupByKey<any, any>(fDataset, i => i[groupKey]);
    const chartLabels = uniqueValues(fDataset, groupKey).sort((x: any, y: any) => x.localeCompare(y))
    const chartdata_data: any[] = [];
    let chartdata_labels: any[] = [];


    const chartGroup = [];
    chartGroup.push({name: "Detection Quantitation  Measure Value", data: mappedValuesMeasure, radius: 3, radiushover: 5, color: '#6a3d9a', fill: false, colorfill: '#6a3d9a'});
    chartGroup.push({name: "Result Measure Value", data: mappedValuesResult, radius: 8, radiushover: 12, color:d3ScaleChromatic.schemePaired[0], fill: true, colorfill: 'rgba(201,225,238,.7)'});
    for (let dataItem of chartGroup) {
            let dataObject = {
            fill: dataItem.fill,
            label: dataItem.name,
            data: dataItem.data,
            borderColor: dataItem.color,
            backgroundColor: dataItem.colorfill,
            pointRadius: dataItem.radius,
            pointHoverRadius: dataItem.radiushover
        }
        chartdata_data.push(dataObject);
    }

    chartdata_labels = chartLabels.map((x: any) => x.substr(0, 25));
    const chartdata = {
      labels: chartdata_labels,
      datasets: chartdata_data,
      borderColor: '#ff6384',
    }

    return chartdata; 
  }

  const filterReportData = () => {
    let filterData = [...reportData];
    if (reportData.length > 0) {
      if (selectedYear && selectedYear !== 'All') {
        const filterYearData = filterData.filter((o: any) => (+o["Year"] === +selectedYear));
          filterData = filterYearData;
      }

      if ((selectedFilterLocations) && (selectedFilterLocations.length > 0) && (selectedFilterLocations.indexOf('All') < 0)) {
          filterData = filterData.filter((o: any) => selectedFilterLocations.indexOf(o[locationField]) > -1);
      }
      
      if ((selectedContaminants) && (selectedContaminants.length > 0) && (selectedContaminants.indexOf('All') < 0)) {
         filterData = filterData.filter((o: any) => selectedContaminants.indexOf(o['Pfas Chemical Name']) > -1);
      } 

      sortFilterReportData([...filterData], isTableSort)
     }
  }

  const sortFilterReportData = (unsortedData: any, ascending: boolean) => {
    let filterData = unsortedData.sort((x:any,y:any) => x["Year"] - y["Year"] || x[locationField].localeCompare(y[locationField])  );
    if(!ascending){
      filterData = unsortedData.sort((x:any,y:any) => y["Year"] - x["Year"] || y[locationField].localeCompare(x[locationField]) )
    }
    setFilteredData(filterData); 
  }

  useEffect(() => {
    filterReportData();
  }, [selectedYear, selectedFilterLocations, selectedContaminants, reportData]);


  useEffect(() => {
    const filteredMapData = [] as any;
    const fieldGroup = 'latlng';
    const sortGroup = 'sortindex';
    const groupedresults = groupByKey<any, any>(filteredData, i => i[fieldGroup]);
    const chartLabels = uniqueValues(filteredData, fieldGroup)
    for (var i = 0; i < chartLabels.length; i++) {
        const sortedData = [...groupedresults[chartLabels[i]]].sort((x: any, y: any) => x[sortGroup] - y[sortGroup]);
        filteredMapData.push(sortedData[0])
    } 

    const featureCollectionFromReportData = createFeatureCollection([...filteredMapData], catalogsContext.huc12Catalog, huc12InfoCatalogArray, selectedHUC8Value);
    setFeatureData(featureCollectionFromReportData);
    if (featureCollectionFromReportData.features.length > 0) {
      if (mapRef && mapRef.current) {
        const mapRef_ = mapRef;
        setTimeout(() => {
          fitBoundsMapData(featureCollectionFromReportData, mapRef_)
        }
          , 400);
      }
    }
  }, [filteredData]);

  const setChartConfig = (chartAnimation: boolean) => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: true,
      scales: {
        y: {
          display: true,
          title: {
            display: true,
            text: 'Measure Value',
            color: 'white'
          },
          ticks: {
            color: 'white',         
          }
        },
        x: {
          display: true,
          title: {
            display: true,
            text: 'PFAS Chemical Name',
            color: 'white'
          },
          ticks: {
            color: 'white',         
          }
        },
      },
      plugins: {
        legend: {
          position: "bottom" as const,
          labels: {
            usePointStyle: true,
          },
          maxHeight: 30,
        } as const,
        title: {
          display: true,
          text: "Result and Detection Limit by PFAS Chemical",
          color: 'white',
          align: 'center',
          padding: 10,
          font: {
            size: 20
          }
        } as const,
        tooltip: {
          padding: 10,
          bodyFont: {
            size: 24
          },
          titleFont: {
            size: 24
          },
          boxPadding: 8,
          usePointStyle: true,
          backgroundColor: '#12234f',
          callbacks: {
            label: function (context: any) {
              const fData = filteredData.filter((o: any) => o["Pfas Chemical Name"].substr(0, 20) === context.label.substr(0, 20));                   
              let label = context.dataset.label || ''; 
              if (label) {
                if(fData.length>0){
                  const thisData = fData[0]; 
                  const thisArray = [];
                  thisArray.push('Result Detection: ' + thisData["Result Detection"])
                  thisArray.push('Result Measure Value: ' + thisData["Result Measure Value"])
                  thisArray.push('Result Unit Of Measure: ' + thisData["Result Unit Of Measure"])

                  thisArray.push('Detection Quantitation Measure Value: ' + thisData["Detection Quantitation  Measure Value"])
                  thisArray.push('Detection Quantitation Unit Of Measure: ' + thisData["Detection Quantitation  Unit Of Measure"])
                  thisArray.push('Count of Result Detection: ' + thisData["Count"])
                  thisArray.push('Year: ' + thisData["Year"])
                  label = thisArray; 
                } else {
                  label = label + ' ' + context.formattedValue;
                } 
              }
              return label;
            }
          }
        } as const,
      } as const,
    } as const;
    setConfig(options);
  }

  const onChartClickHandler = (event: any) => {
    const currentClick = getElementAtEvent(chartRef.current, event)[0];
    if (currentClick) {
      const currentClickMetaData = chartRef.current.getDatasetMeta(currentClick.datasetIndex);
      const currentSite = currentClickMetaData.label;
 
    }
  }

  const tableColumns = useMemo(
    () => [
      { Header: 'Year', accessor: 'Year' },
      { Header: 'Location', accessor: 'Location Name' },
      { Header: 'PFAS Chemical', accessor: 'Pfas Chemical Name' },
      { Header: 'Result Detection', accessor: 'Result Detection' },
      { Header: 'Result Measure Value', accessor: 'Result Measure Value' },
      { Header: 'Result Unit of Measure', accessor: 'Result Unit Of Measure' },
      { Header: 'Detection Value', accessor: 'Detection Quantitation  Measure Value' },
      { Header: 'Detection Unit Of Measure', accessor: 'Detection Quantitation  Unit Of Measure' },
      { Header: 'Count', accessor: 'Count' },
      {
        Header: 'EJScreen Report',
        accessor: 'Ejscreen Report',
        Cell: (props: any) => (
          <a
            href={props.value}
            target='_blank'
          >
            EJScreen
          </a>
        ),
      },
      { Header: 'EPA Region', accessor: 'Epa Region' },
      { Header: 'State', accessor: 'State Territory Or Tribe' },
      { Header: 'Organization', accessor: 'Organization' },
      { Header: 'Environmental Media Name', accessor: 'Environmental Media Name' },
      { Header: 'Activity Identifier', accessor: 'Activity Identifier' },
      { Header: 'Activity Type Code', accessor: 'Activity Type Code' },
      { Header: 'Analysis Start Date', accessor: 'datetime' },
      { Header: 'Project Identifier', accessor: 'Project Identifier' },
      { Header: 'Monitoring Location Identifier', accessor: 'Monitoring Location Identifier' },
      {
        Header: 'Project File',
        accessor: 'Project File Url',
        Cell: (props: any) => (
          <a
            href={props.value}
            target='_blank'
          >
            {(props.value) ? 'Project File' : 'N/A'}
          </a>
        ),
      },
    ],
    []
  ); 

  const updateLayerStyle = () => {
    const newLayerStyle: LayerProps = {
      id: 'pointlayer',
      type: 'circle' as const,
      paint: {
        'circle-radius': ['get', 'radius'],
        'circle-color': ['match', ['get', 'Result Detection'], 'Below Reporting Limit', '#74add1', 'Not Detected', '#e0f3f8', 'PFAS Detected', '#d73027', 'Present Below Quantification Limit', '#6a3d9a', 'Surface water purchased', '#6a3d9a', '#fee090'],
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1,
        'circle-opacity': 0.8
      }
    };

    setLayerStyle(newLayerStyle);
  }

  const hoverLayerStyle: LayerProps = {
    id: 'hoverlayer',
    type: 'circle' as const,
    paint: {
      'circle-radius': ['get', 'radius'],
      'circle-color': '#000099',
      'circle-stroke-color': 'white',
      'circle-stroke-width': 1,
      'circle-opacity': 1
    }
  };

  const onMouseEnter = useCallback((event: any | null) => {
    if (event.features && event.features[0] && event.features[0].properties) {
      let newPopup = event.features[0].properties;
      let newLocation = event.features[0].properties[locationField];
      let newHover = event.features[0].properties[locationField];
      setTimeout(() => {
        setCursor('pointer');
        setPopupInfo(newPopup);
        setUserSelectedLocation(newLocation)
        setMapHoverFilter(['in', ['get', locationField], newHover]);
      }
        , 1);
    }
  }, []);

  const onMouseLeave = useCallback((event: any | null) => {
    setCursor('');
    setTimeout(() => {
      setPopupInfo(null);
      setUserSelectedLocation(null)
      setMapHoverFilter(['in', ['get', locationField], '']);
    }
      , 11);
  }, []);

  useEffect(() => {
    if (usLocations.length > 0) {
      if (userSelectedLocations.indexOf(usLocations[0][locationField]) > -1) {
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', locationField], '']);
      } else {
        setUserSelectedLocations(usLocations[0][locationField]);
        setPopupInfo(usLocations[0]);
        setTimeout(() => {
          setMapHoverFilter(['in', ['get', locationField], usLocations[0][locationField]]);
        }, 111);
      }
    }
  }, [usLocations]);

  const onClick = useCallback((event: any | null) => {
    const feature = event.features && event.features[0];
    if (feature) {
      setUsLocations([feature.properties]);
    }
  }, []);

  const onMapLoad = useCallback(() => {
    if (mapRef && mapRef.current) {
      setMapLoaded(true);

      mapRef.current.resize();
    }
  }, []);

  const pointInPolygonUpdate = (pointsSelected: any) => {
        setUserSelectedLocations(pointsSelected);
        setMapHoverFilter(['in', ['get', locationField], ['literal', pointsSelected]]);
  }

  const onDelete = useCallback((e: any | null) => {
    if (mapRef && mapRef.current) {
      setPopupInfo(null);
      setMapHoverFilter(['in', ['get', locationField], '']);
      setUserSelectedLocations([]);
    }
  }, []);


  const renderPopup = () => {
    return (
      <Popup
        
        longitude={Number(popupInfo.centroid_longitude)}
        latitude={Number(popupInfo.centroid_latitude)}
        onClose={() => setPopupInfo(null)}
      >
        <div className="popup-container">
          <div className="popup-content-row">
            <span className="popup-content-label">Location</span>
            <span className="popup-container-location popup-content-value">{popupInfo["Location Name"]}</span>
          </div>

          <div className="popup-content-row">
            <span className="popup-content-label">Organization</span>
            <span className="popup-content-value">{popupInfo["Organization"]}</span>
          </div>

          <div className="popup-content-row">
            <span className="popup-content-label">Monitoring Location Identifier</span>
            <span className="popup-content-value">{popupInfo["Monitoring Location Identifier"]}</span>
          </div>

          <div className="popup-content-row">
            <span className="popup-content-label">Project Identifier</span>
            <span className="popup-content-value">{popupInfo["Project Identifier"]}</span>
          </div>

        </div>
      </Popup>
    )
  }

  const hoverRowHandler = (event: any) => { }

  const clickRowHandler = (event: any) => {
    if (userSelectedLocations && userSelectedLocations.indexOf(event[locationField]) > -1) {
      setUserSelectedLocations([])
      setPopupInfo(null);
      setMapHoverFilter(['in', ['get', locationField], '']);
    } else {
      setUserSelectedLocations([event[locationField]])
      setMapHoverFilter(['in', ['get', locationField], event[locationField]])
      setPopupInfo(event);
      if (mapRef.current) {
        if (event && event.centroid_longitude) {
          mapRef.current.flyTo({
            center: [
              parseFloat(event.centroid_longitude),
              parseFloat(event.centroid_latitude),
            ],
          });
        }
      }
    }
  };

  const onHoverOut = useCallback((e: any | null) => {
    setMapHoverFilter(['in', ['get', locationField], '']);
    setPopupInfo(null);
  }, []);

  const onTableClick = useCallback((e: any | null) => {

  }, []);

  const deselectFilters = useCallback((e: any | null) => {
    setPopupInfo(null);
    setMapHoverFilter(['in', ['get', locationField], '']);
    setUserSelectedLocations([]);
  }, []);
  
  return (
    <>
      <FullScreenComp handle={fullscreenhandle} className={'pfas-fullscreen'}>
        <Container fluid className="pfasenvironmental-container" >
        <div className="pfasenvironmental"  id='pfasenvironmental-report'>
          <ReportHeader global={global} data={filteredData} 
            reportID={"pfasenvironmental-report"} fullScreenClickHandle={fullscreenhandle.enter} />              
            <Row className="container-row-top">
              <Col m={1} className="column-container">
                <div className="map-report-container">
                    <LoadingDataAnimation dataLoading={!isDataReportLoaded} />
                    <Map
                    id="pfasenvironmental"
                    mapboxAccessToken={settings.maboxKey}
                    mapStyle={global.mapStyle}
                    onLoad={onMapLoad}
                    onClick={(e) => {
                        global.onMapClick(e)
                        onClick(e)
                    }}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    cursor={cursor}
                    preserveDrawingBuffer={true}
                    interactiveLayerIds={['pointlayer']}
                    ref={mapRef as Ref<MapRef>}
                    projection={global.globeView ? 'globe' : 'mercator' as any}
                    >
                    {featureData && (
                        <Source id="circlesource" type="geojson" data={featureData}>
                        <Layer {...layerStyle} />
                        <Layer {...hoverLayerStyle} filter={mapHoverFilter} />
                        </Source>
                    )}

                    <MapAddition global={global} 
                                mapRef={mapRef}
                                onDelete={onDelete}
                                PointInPolygonField={locationField}
                                PointInPolygonUpdate={pointInPolygonUpdate}
                                position={'low'}
                                zipOff={true}
                                statusMessage={statusMessage}/>

                    {popupInfo && renderPopup()}

                    <div className='map-legend-container' onClick={deselectFilters}>
                        <MapLegend
                          mapLegendData={mapLegendData}
                          title={'Result'}
                          global={global} 
                        />
                    </div>             
                  </Map>
                </div>             
              </Col>

              <Col m={1} className="column-chart column-container">
                <div className="row chart-container-parent">
                    <div className={isFullScreenChartActive ? 'expand-chart-container chart-container' : 'chart-container'}>
                        <div className="report-chart-options">
                            <Button onClick={fullscreenChartContainerHandler}>
                            <img src={fullscreenIcon} />
                            </Button>
                        </div>
                        {featureData && (
                            <div className="chart-line-container">
                                <div className="line-chart" >
                                    <Line options={config}
                                        data={data}
                                        ref={chartRef}
                                        onClick={onChartClickHandler}
                                    /> 
                

                                </div>
                            </div>
                        )}
                    </div>
                </div>
              </Col>
            </Row>

            <Row className="container-row-bottom">     
                <div
                    className={isFullScreenActive ? 'row gx-0 table-chart-row table-parent-container expand-container' : 'row gx-0 table-chart-row table-parent-container'}
                    onMouseLeave={onHoverOut}
                    onClick={onTableClick}>
                    <div className="report-options">
                        <Button variant="light" onClick={fullscreenContainerHandler}>
                            <img src={fullscreenIcon} />
                        </Button>
                    </div>
                    <ReportTableExpanded
                        data={filteredData}
                        columns={tableColumns}
                        clickRowHandler={clickRowHandler}
                        clickLoadStop={true}
                    />
                </div>                            
            </Row>

            <Row>
                <ReportFooter />
            </Row>

          </div>
        </Container>
        <MapSlider global={global} />
      </FullScreenComp>
    </>
  );
}

type PfasEnvironmentalModel = {
  lat: number;
  lng: number;
};
