import { useState, useEffect } from 'react'

const ReportItem = (props: any) => {

    const {
        report,
        flyToLocation,
        activeIndex,
        setActiveReport,
        index,
        setActiveIndex
    } = props

    const handleClick = () => {
        setActiveIndex(index)
        if (report) {
            setActiveReport(report)
            flyToLocation(report, 15)
        }
    }

    return (
        <div
            className={activeIndex === index ? 'report-item active' : 'report-item'}
            onDoubleClick={() => flyToLocation(report, 10)}
            onClick={handleClick}
        >{report.sitename}</div>
    )
}

export default ReportItem