import axios, { AxiosResponse } from 'axios';
import settings from '../../../settings.json';
import localForage from "localforage";
import { inpre, inprelocation, in1, in1v2, out1, in2, out2, in3, in2v2, in3v2, in3v3, out3, in4, inprereach, inreach, inreachoptions, inreachrecharge } from './GroundwaterData';




/*
Initial query

"..all-us-stations.data...location-df.data...representative-huc.data...loc-output.children...reach-dataframe.data...warning-dict.data...soil-dict.data...bank-dict.data...lcd.data.."
(poll)

  =>
["reach-dataframe.data"]    huc12
out: "..huc12-selector.options...huc12-selector.value.."

["final-rain-selection.data", "location-df.data"]
"..model-prcp-value.children...model-prcp-df.data...season-seeds.data.."

["target-location.value", "climate-slider.value", "model-prcp-df.data"]
"..scenario-prcp-value.children...prcp-diff.data.."

["target-location.value", "winter-slider.value", "spring-slider.value", "summer-slider.value",…]
"..whole-year-graph.figure...scenario-ma-graph.figure...model-ma-graph.figure...winter-graph.figure...spring-graph.figure...summer-graph.figure...fall-graph.figure...flow-regime-graph.figure...model-hourly-rainfall.data...scenario-hourly-rainfall.data.."

["reach-dataframe.data", "warning-dict.data", "huc12-selector.value", "model-hourly-rainfall.data",…]
"reach-graph.figure"



Update HUC


[{id: "target-location", property: "value", value: "15050100"}]  poll
"..all-us-stations.data...location-df.data...representative-huc.data...loc-output.children...reach-dataframe.data...warning-dict.data...soil-dict.data...bank-dict.data...lcd.data.."

["location-df.data"]
"..model-prcp-value.children...model-prcp-df.data...season-seeds.data.."

["reach-dataframe.data"]
"..huc12-selector.options...huc12-selector.value.."

["target-location.value", "model-prcp-df.data"]
"..scenario-prcp-value.children...prcp-diff.data.."

["target-location.value", "representative-huc.data", "model-prcp-df.data", "prcp-diff.data"] non-poll
"..whole-year-graph.figure...scenario-ma-graph.figure...model-ma-graph.figure...winter-graph.figure...spring-graph.figure...summer-graph.figure...fall-graph.figure...flow-regime-graph.figure...model-hourly-rainfall.data...scenario-hourly-rainfall.data.."

["model-hourly-rainfall.data", "scenario-hourly-rainfall.data", "reach-dataframe.data",…] poll
"reach-graph.figure"


*/





/*
User initiated updates 

all get graphs, get reach graph

climate-slider  Update prcp-diff       
future year selector  Update prcp-diff
winter-slider
final-rain-selection    model-prcp-df, prcp-diff
initial 

["final-rain-selection.data"]
["model-prcp-df.data"]
["final-rain-selection.data", "model-prcp-df.data", "prcp-diff.data"]
"..whole-year-graph.figure
["model-hourly-rainfall.data", "scenario-hourly-rainfall.data"]


["climate-slider.value"]
"..scenario-prcp-value.children...prcp-diff.data.."
["prcp-diff.data"]
"..whole-year-graph.figure...scenario-ma-graph.figure...model-ma-graph.figure...winter-graph.figure...spring-graph.figure...summer-graph.figure...fall-graph.figure...flow-regime-graph.figure...model-hourly-rainfall.data...scenario-hourly-rainfall.data.."
model-hourly scenario-hourly

future year selector
prcp diff
"..whole-year-graph.figure
model-hourly scenario-hourly

["winter-slider.value"]
"..whole-year-graph.figure
["model-hourly-rainfall.data", "scenario-hourly-rainfall.data"]

*/




  export const getLocation = (nclick: any, retry:number, props:any, callback:any) => { //inprelocation
    if(retry && retry < 2){
      getPre1(nclick, retry, props, callback);  
    } else {
      getPre2(nclick, retry, props, callback); 
    }   
  }

  const returnError = (props:any, err:any, notifymessage?:string) => { 
          props.setIsNotInProgress(true);
          props.setIsDataReportLoaded(true);
          props.setIsReachLoaded(true);
          props.setIsRechargeLoaded(true);
          props.setIsSeasonLoaded(true);
          props.notify((notifymessage) ? notifymessage : "Error processing");
          console.log(err);
  }

  const getPre1 = (nclick: any, retry:number, props:any, callback:any) => { 
    let URL = props.URL;
 
    let locationRequest = {} as locationRequest;
    Object.assign(locationRequest, inprelocation); 
    locationRequest.inputs[0].value = nclick; 
    locationRequest.state[0].value = props.currentHuc8;
    axios.get(URL)
      .then((response) => {  
          if(response.status === 200){
                setTimeout(() => {                                                      
                  getPre2(nclick, retry, props, callback);  
                }, 222);                              
          }  
    }).catch(err => {returnError(props, err); }); 
  }


  const getPre2 = (nclick: any, retry:number, props:any, callback:any) => {
     const requestbody = {
      "output": "climate-slider.value",
      "outputs": {
        "id": "climate-slider",
        "property": "value"
      },
      "inputs": [
        {
          "id": "climate-slider",
          "property": "value", 
          "value": 2
        }
      ],
      "changedPropIds": []
    } as locationRequest;

    let locationRequest = {} as locationRequest;
    Object.assign(locationRequest, requestbody); 
    locationRequest.inputs[0].value = props.sliderClimate;

    let URL = props.URL;
    axios.post(URL, locationRequest)
      .then((response) => {  
          if(response.status === 200 ){
                getPre3(nclick, retry, props, callback);
          }  
    }).catch(err => {returnError(props, err); }); 
  }


  const getPre3 = (nclick: any, retry:number, props:any, callback:any) => {
    let URL = props.URL;     
    //@ts-ignore
    const requestbody = {"output":"specified-year.style","outputs":{"id":"specified-year","property":"style"},"inputs":[{"id":"rain-type-selector","property":"value","value":"Simple"}],"changedPropIds":["rain-type-selector.value"]};
    let locationRequest = {} as locationRequest;
    Object.assign(locationRequest, requestbody); 
    let yearSelect = props.rainSelection;
    if(props.rainSelection === 'Specific Year'){
      yearSelect = props.rainSelectionYear;
    }   
    locationRequest.inputs[0].value = yearSelect;

    axios.post(URL, locationRequest)
      .then((response) => {  
          if(response.status === 200 ){
                getPre4(nclick, retry, props, callback);
          }  
    }).catch(err => {returnError(props, err); }); 
  }
   

  const getPre4 = (nclick: any, retry:number, props:any, callback:any) => {
    let URL = props.URL;      
    //@ts-ignore
    const requestbody = {"output":"specified-year.style","outputs":{"id":"specified-year","property":"style"},"inputs":[{"id":"rain-type-selector","property":"value","value":"Simple"}],"changedPropIds":[]};
    let locationRequest = {} as locationRequest;
    Object.assign(locationRequest, requestbody); 
    let yearSelect = props.rainSelection;
    if(props.rainSelection === 'Specific Year'){
      yearSelect = props.rainSelectionYear;
    }   
    locationRequest.inputs[0].value = yearSelect;

    axios.post(URL, locationRequest)
      .then((response) => {  
          if(response.status === 200 ){
                getPreLocation(nclick, retry, props, callback);
          }  
    }).catch(err => {returnError(props, err); }); 
  }
   
  const targetLocation = {"output": "target-location.value",
    "outputs": {
      "id": "target-location",
      "property": "value"
    },
    "inputs": [
      {
        "id": "submit-button-state",
        "property": "n_clicks",
        "value": 0
      }
    ],
    "changedPropIds": [],
    "state": [
      {
        "id": "target-location",
        "property": "value"
      }
    ]
  };

  const getTargetLocation = (nclick: any, retry:number, props:any, callback:any) => {
    let URL = props.URL;
    const requestbody = targetLocation;
    let locationRequest = {} as locationRequest;
    Object.assign(locationRequest, requestbody); 
    locationRequest.inputs[0].value = nclick;  
    locationRequest.state[0].value = props.currentHuc8;

    axios.post(URL, locationRequest)
      .then((response) => {  
        
          if(response.status === 200 ){
                //getPreLocation(nclick, retry, props, callback);
          }  
    }).catch(err => {returnError(props, err); }); 
  }

  const getPreLocation = (nclick: any, retry:number, props:any, callback:any) => { //inpre
    let URL = props.URL;     
    let locationRequest = {} as locationRequest;
    if(props.gwVersion === 2) {
      Object.assign(locationRequest, inprereach);  
      locationRequest.inputs[0].value = nclick; 
      locationRequest.state[0].value = props.currentHuc8;//props.currentHuc8; "02080107"
      getTargetLocation(nclick, retry, props, callback);
    } else {
      Object.assign(locationRequest, inprelocation); 
      locationRequest.inputs[0].value = nclick;
      locationRequest.state[0].value = props.currentHuc8;
    }

    axios.post(URL, locationRequest)
      .then((response) => {  
   
          if(response.status === 200 && response.data){
              getJobv2(nclick, retry, props, callback, response.data.cacheKey, response.data.job);
          }

    }).catch(err => {returnError(props, err); });
  }


  const getJobv2 = (nclick: any, retry:number, props:any, callback:any, cacheKey:any, job: any) => { //in4   v2+++
    //console.log('gJv2_', inprereach, props.huc12Selection)

    localForage.getItem('GDquery_' + props.currentHuc8, function(err, value) {//@ts-ignore
          if(retry < 2 && value && value.response["location-df"] && value.response["location-df"].data){
              console.log('CACHE')//@ts-ignore
              getModelPCRP(value.response["location-df"].data, value.response["representative-huc"].data, props, callback, value.response);
              
          } else{
              let locationRequest = {} as locationRequest;
              Object.assign(locationRequest, inprereach); 
              locationRequest.inputs[0].value = nclick; 
              locationRequest.state[0].value = props.currentHuc8; //+++
              let URL = `${props.URL}?cacheKey=${cacheKey}&job=${job}`;

              axios.post(URL, locationRequest)
                .then((response2) => {
                   
                    if(response2.status === 200 || response2.status === 204){ //&& response2.data
                      if(response2.data.response && response2.data.response["location-df"]){
                        //localForage.setItem('GDquery_' + props.currentHuc8, response2.data);
                        props.setRetryCounter(retry);
                        getModelPCRP(response2.data.response["location-df"].data, response2.data.response["representative-huc"].data, props, callback, response2.data.response);
                      } else {
                          console.log('gJv2', retry, response2.data)
                          if(retry && retry < 75){  
                            let retrySegment = Math.min(Math.round(retry * (100/(props.retryAverage+2))), 100);
                            props.setProgress(retrySegment); 
                            setTimeout(() => {                                                                      
                              getJobv2(retry, retry+1, props, callback, cacheKey, job); 
                            }, 1222);
                          } else {
                            returnError(props, "timeout", "Timed out while processing");
                          }
                      }
                    }
              }).catch(err => {returnError(props, err); });    


          }
    });


  }

  const getJob = (nclick: any, retry:number, props:any, callback:any) => { //inpre
/*    console.log('gJ', nclick, props.currentHuc8, retry)

    let locationRequest = {} as locationRequest;
    Object.assign(locationRequest, inpre); 
    locationRequest.inputs[0].value = nclick; 
    locationRequest.state[0].value = props.currentHuc8;

    let URL = props.URL;
    axios.post(URL, locationRequest)
      .then((response) => {  console.log(response)
          if(response.status === 200 && response.data && response.data.job){
            URL = `${props.URL}?cacheKey=${response.data.cacheKey}&job=${response.data.job}` 
            axios.post(URL, locationRequest)
              .then((response2) => {
                  console.log(response2, response.data.job, response2.data.response)
                  if(response2.status === 200 && response2.data && response2.data){
                      if(response2.data.response && response2.data.response["location-df"]){
                        console.log("location-df", response2.data.response["location-df"])
                        //setLocationDF(response2.data.response["location-df"])
                        getModelPCRP(response2.data.response["location-df"].data, response2.data.response["representative-huc"].data, props, callback);
                      } else {
                        if(retry && retry < 12){
                          //getLocation(nclick, retry+1); //+++ nclick n_clicks
                          setTimeout(() => {                                                      
                            getLocation(retry, retry+1, props, callback); 
                          }, 111);
                        } else {
                          props.setIsDataReportLoaded(true);
                          props.setIsSeasonLoaded(true);
                        }
                      }
                  }
            }).catch(err => {returnError(props, err); });    
          }  
    })*/
  }

  export const getModelPCRP = (locdf: any, representativehuc:any, props:any, callback:any, jobresponse?:any, changedPropIds?: any, updatedValue?: any) => { //in1
    console.log('ModelPCRP', props.currentHuc8)
    let locationRequest = {} as locationRequest;
    Object.assign(locationRequest, in1v2);   //in1
    locationRequest.inputs[0]["value"] = locdf;  
    locationRequest.inputs[1]["value"] = props.rainSelection;  
    let rSelection = props.rainSelection;

    let CPI = ["climate-slider.value",
        "prcp-2020-df.data",
        "model-prcp-df.data"];

    if(changedPropIds){
        locationRequest.changedPropIds = changedPropIds;
        CPI = changedPropIds;
        if(changedPropIds && changedPropIds[0] === "final-rain-selection.data"){
            CPI = ["model-prcp-df.data"];  //"prcp-2020-df.data", 
            if(updatedValue||updatedValue===0){
              locationRequest.inputs[1]["value"] = updatedValue;
              rSelection = updatedValue;
            }
        }
    }
    locationRequest.inputs[1]["value"] = (rSelection === 'Specific Year') ?  +props.rainSelectionYear : rSelection;  //final-rain-selection
 
    let URL = props.URL;
    axios.post(URL, locationRequest)
      .then((response) => {   
     
          if(response.status === 200 && response.data && response.data.response){
              if(response.data.response["model-prcp-value"]){
                  //console.log("model-prcp-value", response.data.response["model-prcp-value"], response.data.response["prcp-2020-df"])
                  //setLocationDF(response2.data.response["location-df"])
                  props.setModelYearPrecipitation(response.data.response["model-prcp-value"].children)
                  props.setModelPCRPdf(response.data.response["model-prcp-df"]);
                  if(response.data.response["prcp-2020-df"] ){
                      props.setPrcp2020df(response.data.response["prcp-2020-df"]);
                  }
                  
                  let sliderseeds = [+props.sliderWinter,+props.sliderSpring,+props.sliderSummer,+props.sliderFall];

                  if(response.data.response["season-seeds"] && response.data.response["season-seeds"].data){
                    let sdata = response.data.response["season-seeds"].data;
                    sliderseeds = [sdata["1"], sdata["2"], sdata["3"], sdata["4"]];
                    props.setSliderSeeds(sliderseeds)
                  }
                  
                  //setYearPrecipitation(response.data.response["prcp-2020-df"])

                  //, response.data.response["prcp-2020-df"].data
                  getScenarioPCRP(sliderseeds, response.data.response["model-prcp-value"].children, response.data.response["model-prcp-df"].data, null, representativehuc, props, callback, jobresponse, CPI, rSelection);
                  //getPCRPDiff(sliderseeds, response.data.response["model-prcp-value"].children, response.data.response["model-prcp-df"].data, null, representativehuc, props, callback, jobresponse, CPI, rSelection);
              }
          }  
    }).catch(err => {returnError(props, err); }); 
  }



//in2v2
/*
 export const getPCRPDiff = (sliderseeds:any, modelpcrp: any, modeldf: any, prcpdf: any, representativehuc:any, props:any, callback:any, jobresponse?:any, changedPropIds?: any, updatedValue?: any) => { 
    console.log('getPCRPDiff', props.currentHuc8)
    let locationRequest = {} as locationRequest;
    Object.assign(locationRequest, in2v2 );   //in2v2 
    locationRequest.inputs[0]["value"] = props.currentHuc8;  //target-location
    locationRequest.inputs[1]["value"] = modeldf;   //model-prcp-df"
    locationRequest.inputs[2]["value"] = +props.futureYear; 
    locationRequest.inputs[3]["value"] = +props.sliderClimate; 

    let rSelection = props.rainSelection;

    let CPI = ["model-prcp-df.data"];

    if(changedPropIds){
        locationRequest.changedPropIds = changedPropIds;
        CPI = changedPropIds;
        if(changedPropIds && changedPropIds[0] === "final-rain-selection.data"){
            CPI = ["model-prcp-df.data"];  //"prcp-2020-df.data", 
            if(updatedValue||updatedValue===0){
              //locationRequest.inputs[1]["value"] = updatedValue;
              rSelection = updatedValue;
            }
        }
    }
    //locationRequest.inputs[1]["value"] = (rSelection === 'Specific Year') ?  +props.rainSelectionYear : rSelection;  //final-rain-selection
   

    console.log(CPI, changedPropIds, locationRequest, updatedValue, rSelection)  
    let URL = props.URL;
    axios.post(URL, locationRequest)
      .then((response) => { 
     
          if(response.status === 200 && response.data && response.data.response){
              if(response.data.response["model-prcp-value"]){

                  
                  //props.setModelPCRPdf(response.data.response["model-prcp-df"]);
                  if(response.data.response["prcp-diff"] ){ //prcp-2020-df
                      props.setPrcp2020df(response.data.response["prcp-diff"]);//prcp-2020-df
                  }


                  //scenario-prcp-value
                  

                  //setYearPrecipitation(response.data.response["prcp-2020-df"])

                  getScenarioPCRP(sliderseeds, modelpcrp, modeldf, response.data.response["prcp-diff"].data, representativehuc, props, callback, jobresponse, CPI, rSelection);
              }
          }  
    }).catch(err => {returnError(props, err); }); 
  }
*/


  export const getScenarioPCRP = (sliderseeds:any, modelpcrp: any, modeldf: any, prcpdf: any, representativehuc:any, props:any, callback:any, jobresponse?:any, changedPropIds?: any, updatedValue?: any) => { //in2
    let CPI = [
        "winter-slider.value",
        "spring-slider.value",
        "summer-slider.value",
        "autumn-slider.value",
        "final-rain-selection.data",
        "representative-huc.data",
        "model-prcp-df.data" //*  "prcp-diff.data"
        
    ];

    let locationRequest = {} as locationRequest;
    Object.assign(locationRequest, in2v2);  //in2

    locationRequest.inputs[0]["value"] = props.currentHuc8;  //target-location   prcpdf; 
    locationRequest.inputs[1]["value"] = modeldf; 
    locationRequest.inputs[2]["value"] = +props.futureYear; 
    locationRequest.inputs[3]["value"] = +props.sliderClimate; 
    if(changedPropIds){
        locationRequest.changedPropIds = changedPropIds;
        CPI = changedPropIds;
        if(changedPropIds && changedPropIds[0] === "climate-slider.value" && changedPropIds.length === 1){
            CPI = ["prcp-diff.data"];
            if(updatedValue||updatedValue===0){
              locationRequest.inputs[3]["value"] = +updatedValue;
            }
        }

        if(changedPropIds && changedPropIds[0] === "future-year-selector.value"){
            //CPI = ["prcp-diff.data"];
            if(updatedValue||updatedValue===0){
              locationRequest.inputs[2]["value"] = +updatedValue;
            }
        }
    }

  

    let URL = props.URL;
    axios.post(URL, locationRequest) 
      .then((response) => { 
          if(response.status === 200 && response.data && response.data.response){
              if(response.data.response["prcp-diff"]){
                 // console.log("prcp-diff", response.data.response["prcp-diff"], response.data.response["prcp-diff"].data, response.data.response["scenario-prcp-value"].children)
                  //setLocationDF(response2.data.response["location-df"])
                  props.setPrcpdiff(response.data.response["prcp-diff"]);
                  props.setScenarioYearPrecipitation(response.data.response["scenario-prcp-value"].children)
                  getGraphs(sliderseeds, modeldf, prcpdf, response.data.response["prcp-diff"].data, representativehuc, props, callback, jobresponse, CPI, updatedValue);
              }
          }  
    }).catch(err => {returnError(props, err); }); 
  }


  export const getGraphs = (sliderseeds:any, modeldf: any, prcpdf: any, pcrpdiff: any, representativehuc:any, props:any, callback:any, jobresponse?:any, changedPropIds?: any, updatedValue?: any) => { //in3
    console.log('Graphs', changedPropIds, updatedValue, props, jobresponse, props.currentHuc8)

    let locationRequest = {} as locationRequest;
    let yearSelect = props.rainSelection;
    if(props.rainSelection === 'Specific Year'){
      yearSelect = +props.rainSelectionYear;
    }

/*    Object.assign(locationRequest, in3v3);  

    locationRequest.inputs[0]["value"] = props.currentHuc8;
    locationRequest.inputs[1]["value"] = +props.sliderWinter; 
    locationRequest.inputs[2]["value"] = +props.sliderSpring; 
    locationRequest.inputs[3]["value"] = +props.sliderSummer;
    locationRequest.inputs[4]["value"] = +props.sliderFall; 
    locationRequest.inputs[5]["value"] = pcrpdiff; 
    locationRequest.inputs[6]["value"] = modeldf; 
    locationRequest.inputs[7]["value"] = yearSelect;
    locationRequest.inputs[8]["value"] = representativehuc;*/

    Object.assign(locationRequest, in3v3);  //in3v2
    locationRequest.inputs[0]["value"] = props.currentHuc8;
    locationRequest.inputs[1]["value"] = +sliderseeds[0]; 
    locationRequest.inputs[2]["value"] = +sliderseeds[1]; 
    locationRequest.inputs[3]["value"] = +sliderseeds[2];
    locationRequest.inputs[4]["value"] = +sliderseeds[3]; 
    locationRequest.inputs[5]["value"] = pcrpdiff; 
    locationRequest.inputs[6]["value"] = modeldf; 
    locationRequest.inputs[7]["value"] = yearSelect;
    locationRequest.inputs[8]["value"] = representativehuc;


    let cpis = [
      "winter-slider.value",
      "spring-slider.value",
      "summer-slider.value",
      "autumn-slider.value",
      "final-rain-selection.data",
      "representative-huc.data",
      "model-prcp-df.data",
      "prcp-diff.data"
    ];

    if(changedPropIds){
      locationRequest.changedPropIds = cpis;//changedPropIds;

      if(updatedValue||updatedValue===0){
        if(changedPropIds[0] === "winter-slider.value"){
            locationRequest.inputs[1]["value"] = +updatedValue;
        }
        if(changedPropIds[0] === "spring-slider.value"){
            locationRequest.inputs[2]["value"] = +updatedValue;
        }
        if(changedPropIds[0] === "summer-slider.value"){
            locationRequest.inputs[3]["value"] = +updatedValue;
        }
        if(changedPropIds[0] === "fall-slider.value"){
            locationRequest.inputs[4]["value"] = +updatedValue;
        }    
        if(changedPropIds[0] === "final-rain-selection.data"){
            locationRequest.inputs[7]["value"] = updatedValue;
        }                       
      }   
    }
   
    //end

    let URL = props.URL;
    axios.post(URL, locationRequest)
      .then((response) => {  
      
          if(response.status === 200 && response.data && response.data.response){
              if(response.data.response["whole-year-graph"]){
        
                  callback(response.data.response);

                  if(props.gwVersion === 2) {
                    getReachOptions(response.data.response, props, jobresponse);   
                  } 

              }
          }  
    }).catch(err => {returnError(props, err); }); 
  }

const getReachOptions = (graphresponse:any, props:any, jobresponse:any) => { //inreach
    //console.log('ReachOptions', graphresponse, jobresponse)
    //props.updateResponseStore(graphresponse, jobresponse);
    props.setJobResponse(jobresponse);
    props.setGraphResponse(graphresponse);

    let locationRequest = {} as locationRequest;
    Object.assign(locationRequest, inreachoptions);  
    locationRequest.inputs[0]["value"] = jobresponse["reach-dataframe"].data; 

    let URL = props.URL;
    axios.post(URL, locationRequest)
      .then((response) => {  
        
          if(response.status === 200 && response.data && response.data.response["huc12-selector"]){
              props.setHuc12list(response.data.response["huc12-selector"].options);

              let huc12next = props.huc12Selection;

              if(response.data.response["huc12-selector"].options && response.data.response["huc12-selector"].options.indexOf(props.huc12Selection) <0 ){
                props.setHuc12Selection(response.data.response["huc12-selector"].value);
                huc12next = response.data.response["huc12-selector"].value
              }

              //props.setHuc12Selection(response.data.response["huc12-selector"].value);
              //props.setStreamBank(response.data.response["reach-graph"].figure.data);
              getReach(graphresponse, props, jobresponse, huc12next, null, null, 0); 

          } 
    }).catch(err => {returnError(props, err); });  
  }

  export const getReach = (graphresponse:any, props:any, jobresponse:any, huc12selector:any, cacheKey?:any, job?:any, retry?:any) => { //inreach
    //console.log('Reach', graphresponse, props, jobresponse)  
    let locationRequest = {} as locationRequest;
    Object.assign(locationRequest, inreach);     
    locationRequest.inputs[0]["value"] = huc12selector; 
    locationRequest.inputs[1]["value"] = jobresponse["reach-dataframe"].data; 
    locationRequest.inputs[2]["value"] = graphresponse["model-hourly-rainfall"].data; 
    locationRequest.inputs[3]["value"] = graphresponse["scenario-hourly-rainfall"].data;
    locationRequest.inputs[4]["value"] = jobresponse["warning-dict"].data;  
/*    locationRequest.inputs[5]["value"] = jobresponse["huc8_pet"].data; 
    locationRequest.inputs[6]["value"] = jobresponse["huc8_prcp"].data;
    locationRequest.inputs[7]["value"] = +props.sliderClimate;  
    locationRequest.inputs[8]["value"] = +props.futureYear;*/

    let URL = props.URL;
    if(cacheKey && job){
      URL = `${props.URL}?cacheKey=${cacheKey}&job=${job}`
    }
    axios.post(URL, locationRequest)
      .then((response) => {  
          
          if(response.status === 200 && response.data && response.data.response){
              if(response.data.response["reach-graph"]){
                console.log("StreamBank", response, response.data)
                     
                  
                props.setStreamBank(response.data.response["reach-graph"].figure.data);

                getRecharge(graphresponse, props, jobresponse, huc12selector, null, null, 0); 


/*                if(response.data.response["huc8_pet"]){
                  props.setHuc8_pet(response.data.response["huc8_pet"])
                }
                if(response.data.response["huc8_prcp"]){
                  props.setHuc8_prcp(response.data.response["huc8_prcp"])
                }*/
/*                if(response.data.response["nh"]){
                  props.setHuc8_pet(response.data.response["huc8_pet"])
                }
                if(response.data.response["huc8_pet"]){
                  props.setHuc8_pet(response.data.response["huc8_pet"])
                }*/


                setTimeout(() => {      
                  props.setIsReachLoaded(true);                                                
                  //props.updateStore();
                }, 1444);  

              }
          } else if ((response.status === 200||response.status === 204) && !response.data.response && response.data.cacheKey){
           
            if(retry<50){              
                setTimeout(() => {                                                      
                    getReach(graphresponse, props, jobresponse, huc12selector, response.data.cacheKey, response.data.job, retry + 1); 
                }, 1411);              
            } else {
              returnError(props, 'timeout', 'Timed out while processing');
            }
          }  else if ((response.status === 200||response.status === 204) && !response.data.response && cacheKey){
           
            if(retry<50){              
                setTimeout(() => {                                                      
                    getReach(graphresponse, props, jobresponse, huc12selector, cacheKey, job, retry + 1); 
                }, 1411);              
            } else {
              returnError(props, 'timeout', 'Timed out while processing');
            }
          } 
    }).catch(err => {returnError(props, err); });  
  }



  export const getRecharge = (graphresponse:any, props:any, jobresponse:any, huc12selector:any, cacheKey?:any, job?:any, retry?:any) => { //inreach
    console.log('getRecharge', retry)  
    let locationRequest = {} as locationRequest;
    Object.assign(locationRequest, inreachrecharge);     
    locationRequest.inputs[0]["value"] = huc12selector; 
    locationRequest.inputs[1]["value"] = jobresponse["huc8_pet"].data; 
    locationRequest.inputs[2]["value"] = jobresponse["huc8_prcp"].data;
    locationRequest.inputs[3]["value"] = +props.sliderClimate;  
    locationRequest.inputs[4]["value"] = +props.futureYear;

    let URL = props.URL;
    if(cacheKey && job){
      URL = `${props.URL}?cacheKey=${cacheKey}&job=${job}`
    }
    axios.post(URL, locationRequest)
      .then((response) => {  

          if(response.status === 200 && response.data && response.data.response){
            console.log("Recharge", response, response.data.response)
            if(response.data.response["recharge_dict"] && response.data.response["recharge_dict"].data){
                props.setRechargeValues(response.data.response["recharge_dict"].data);
            }
            if(response.data.response["nhd_data"] && response.data.response["nhd_data"].data){
                props.setNhldData(JSON.parse(response.data.response["nhd_data"].data))
                props.updateRecharge(response.data.response["recharge_dict"].data, JSON.parse(response.data.response["nhd_data"].data))
            }

             setTimeout(() => {      
                props.setIsRechargeLoaded(true);                                                
                //props.updateStore();
              }, 1111); 


          } else if ((response.status === 200||response.status === 204) && !response.data.response && response.data.cacheKey){
           
            if(retry<80){              
                setTimeout(() => {                                                      
                    getRecharge(graphresponse, props, jobresponse, huc12selector, response.data.cacheKey, response.data.job, retry + 1); 
                }, 3411);              
            } else {
              returnError(props, 'timeout', 'Timed out while processing');
            }
          }  else if ((response.status === 200||response.status === 204) && !response.data.response && cacheKey){
           
            if(retry<80){              
                setTimeout(() => {                                                      
                    getRecharge(graphresponse, props, jobresponse, huc12selector, cacheKey, job, retry + 1); 
                }, 3411);              
            } else {
              returnError(props, 'timeout', 'Timed out while processing - getRecharge');
            }
          } 
    }).catch(err => {returnError(props, err); });  
  }




interface locationRequest {
  inputs: any;
  state: any;
  output: any;
  outputs: any;
  changedPropIds: any;
}
