import {
  useMemo,
  useState,
  useEffect
} from 'react'
import ReportTable from '../../../reports/shared/ReportTable'
import {
  Button,
  Form,
  Row, Col,
  FloatingLabel
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import {
  faPenToSquare,
  faTrashCan,
  faCalendar
} from '@fortawesome/pro-solid-svg-icons';
import ActivitiesTable from './ActivitiesTable';
import AddActivities from '../AddActivities';
import { exportToCsv } from '../../../utils';

const Activities = (props: any) => {

  const {
    activities,
    setActivities,
    addActivities,
    setAddActivities,
    modalOpen,
    setModalOpen,
    modalList,
    setModalList,
    activitiesData,
    setActivitiesData,
    mLocsData,
    resultsData,
    setResultsData,
    samplesData,
    orgCredentialsType
  } = props

  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate()-41115)));
  const [endDate, setEndDate] = useState(new Date());
  const [monitoringLocation, setMonitoringLocation] = useState('')

  const [data, setData] = useState(activitiesData)
  const [action, setAction] = useState('table')

  const [filteredData, setFilteredData] = useState(activitiesData)

  const modalArray = [
              'SAMP_ACT_END_DT',
              'SAMP_COLL_METHOD',
              'SAMP_COLL_EQUIP',
              'SAMP_PREP',
              'SAMP_DEPTH'
                          ];

  const modalArrayFields = [] as any;
  for (var i = 0; i < modalArray.length; i++) {
      //@ts-ignore
       modalArrayFields.push({ field: modalArray[i], active: false})
  }

  useEffect(() => {
    if(!modalList || modalList.length!==modalArray.length){
          const table_mlocs = localStorage.getItem('wm_table_activs');
          if(table_mlocs){
              const tablefields = JSON.parse(table_mlocs);
              if(tablefields && tablefields.length >0){
                  setModalList(tablefields)
              } else {
                  setModalList(modalArrayFields);
              }
          } else {
              setModalList(modalArrayFields);
          }
    }
  }, [])

  useEffect(() => {
    if(modalList && modalList.length===modalArray.length){
      localStorage.setItem('wm_table_activs', JSON.stringify(modalList));
    }
  }, [modalList])



const renderScreens = () => {

    if (action === 'table') {
      return <ActivitiesTable
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        modalList={modalList}
        setModalList={setModalList}
        action={action}
        setAction={setAction}
        data={activitiesData}
        setData={setData}
        activitiesData={activitiesData}
        setActivitiesData={setActivitiesData}
        mLocsData={mLocsData}
        resultsData={resultsData}
        samplesData={samplesData}
        orgCredentialsType={orgCredentialsType}
      />
    }

    if(action === 'create') {
      return <AddActivities
        title={"Create"}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        setModalList={setModalList}
        action={action}
        setAction={setAction}
        data={''}
        activitiesData={activitiesData}
        mLocsData={mLocsData}
        resultsData={resultsData}
        setResultsData={setResultsData}
        samplesData={samplesData}
        orgCredentialsType={orgCredentialsType}
      />
    }

    if (action === 'edit') {
      return <AddActivities
        title={'Edit'}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        setModalList={setModalList}
        action={action}
        setAction={setAction}
        setData={setData}
        data={data}
        activitiesData={activitiesData}
        mLocsData={mLocsData}
        resultsData={resultsData}
        setResultsData={setResultsData}
        samplesData={samplesData}
        orgCredentialsType={orgCredentialsType}
      />
    }
  }

  return <>{renderScreens()}</>
}


export default Activities