import {
    useState,
    useEffect,
    useCallback
} from 'react'
import {
    GoogleMap,
    MarkerClusterer,
    Marker,
    InfoWindow
} from '@react-google-maps/api';
import { getData } from '../../../api/getData'
import RightHeader from '../../../components/RightHeader'
import MapStablizer from './MapStablizer';
import settings from '../../../settings.json'
import GooglePopup from './GooglePopup';
import './google-map-styles.scss'

const zipcodes = require('zipcodes');

const GoogleMapsDemo = (props: any) => {

    const { global } = props;

    const [drinkingWaterQuality, setDrinkingWaterQuality] = useState()
    const [clusters, setClusters] = useState<any>()
    const [markers, setMarkers] = useState<any>()
    const [averages, setAverages] = useState<any>()
    const [options, setOptions] = useState<any>()
    const [center, setCenter] = useState<any>()
    const [current, setCurrent] = useState<any>()


    const googleMap = document.querySelector('.google-map-wrapper')

    useEffect(() => { 
        if(googleMap) {
            googleMap.addEventListener('click', (event: any) => {
                if(global.googleMenuOpen){
                   global.setGoogleMenuOpen(false)  
                }
            })
        }
    }, [googleMap])


    useEffect(() => {
        if (global.drinkingWaterQualitySelected && !drinkingWaterQuality) {
            getData(settings.apiMethods.getDrinkingWaterTrueQI).then(data => {
                const zipified = data.body.map((element: any) => ({
                    ...zipcodes.lookup(element['Zip Code']),
                    score: element.Score,
                }));
                setDrinkingWaterQuality(zipified);
                global.setLoading(false);
            });
            global.setLoading(true);
        } else {
            global.setLoading(false);
        }
    }, [global.drinkingWaterQualitySelected]);

    useEffect(() => {
        global.setUserLogged(true)
    }, [])


    useEffect(() => {
        const points = averages && averages.map((average: any) => {
            return {
                height: 36,
                width: 36,
                textColor: 'transparent',
                url: `https://te-v3-assets.s3.us-west-2.amazonaws.com/${average}.svg`,
            }
        })
        setOptions(points)
    }, [averages])

    const handleClusterAverages = useCallback((input: any) => {
        let markerAverages: any = []
        input.map((cluster: any) => {
            let sum = 0
            const regex = /\d{2,3}/g
            cluster.map((marker: any) => {
                const markerScore = marker.icon.match(regex)[0]
                sum += parseInt(markerScore)
            })
            markerAverages.push((Math.round(sum / cluster.length)))
        })
        setAverages(markerAverages);
    }, [markers])


    useEffect(() => markers && handleClusterAverages(markers), [markers])


    const handleMarkers = useCallback((input: any) => {
        let clusterMarkers: any = []
        input.map((cluster: any) => {
            clusterMarkers.push(cluster.getMarkers())
        })
        setMarkers(clusterMarkers);
    }, [clusters])

    useEffect(() => clusters && handleMarkers(clusters), [clusters])

    const onLoad = useCallback((clusterer: any) => setClusters(clusterer.clusters), [])

    const handleCenter = useCallback((e: any) => global.setViewport({
        latitude: e.latLng.lat(),
        longitude: e.latLng.lng(),
        zoom: global.viewport.zoom
    }), [])

    const renderDWQIMarkers = (input: any) => {

        return (
            <MarkerClusterer
                onClusteringEnd={(clusterer) => setClusters(clusterer.clusters)}
                clusterClass="drinking-water"
                onLoad={onLoad}
                styles={options && options}
                enableRetinaIcons
            >
                {(cluster: any) => {
                    return input.map((point: any) => (
                        <Marker
                            clusterer={cluster}
                            onClick={() => setCurrent(point)}
                            icon={`https://te-v3-assets.s3.us-west-2.amazonaws.com/${point.score}.svg`}
                            position={{ lat: parseFloat(point.latitude), lng: parseFloat(point.longitude) }}
                        />
                    ))
                }
                }
            </MarkerClusterer>
        )
    }



    return (
        <>
            <RightHeader global={global} otherButton={true} />
            <div className="google-map-wrapper">
                <GoogleMap
                    zoom={global.viewport.zoom}
                    center={{ lat: parseFloat(global.viewport.latitude), lng: parseFloat(global.viewport.longitude) }}
                    onClick={(e: any) => handleCenter(e)}
                    mapContainerClassName="google-map-container"
                >
                    {global.drinkingWaterQualitySelected && drinkingWaterQuality && renderDWQIMarkers(drinkingWaterQuality)}
                    {current && <GooglePopup
                        current={current}
                        setCurrent={setCurrent}
                        global={global}
                        reportUrl={['/DrinkingWaterTrueQIReport', '/WhatsInMyDrinkingWater']}
                    />}
                </GoogleMap>
            </div>
        </>
    )
}

export default GoogleMapsDemo