import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2';

const LineChart = ({
    data,
    title,
    point0,
    setPoint0,
    point1,
    setPoint1,
    point2,
    setPoint2,
    point3,
    setPoint3,
    point4,
    setPoint4,
    point5,
    setPoint5,
    point6,
    setPoint6,
    setDates,
    startDate,
    endDate,
    setExportData,
    dates
  }: any) => {

  const formatDataForChartJS = (input: any, labels: any) => {
 
      const formattedData = {
          labels: labels,
          datasets: input.map(({ title, data }:any) => {
            if(title === "MB - Tidal") {
              return {
                label: title,
                data: data,
                borderColor: '#FF5733',
                backgroundColor: '#FF5733',
                pointRadius: 4,
                pointHoverRadius: 5,
                pointBackgroundColor: '#FF5733',
                pointBorderColor: '#FF5733',
                tension: 0.1,
                hidden: false
              }
            }
            if(title === "MB4 - US41") {
              return {
                label: title,
                data: data,
                borderColor: '#33FF57',
                backgroundColor: '#33FF57',
                pointRadius: 4,
                pointHoverRadius: 5,
                pointBackgroundColor: '#33FF57',
                pointBorderColor: '#33FF57',
                tension: 0.1,
                hidden: false
              }
            }
            if(title === "MB1 - E. Trailer Park") {
              return {
                label: title,
                data: data,
                borderColor: '#3357FF',
                backgroundColor: '#3357FF',
                pointRadius: 4,
                pointHoverRadius: 5,
                pointBackgroundColor: '#3357FF',
                pointBorderColor: '#3357FF',
                tension: 0.1,
                hidden: false
              }
            }
            if(title === "MB2 - C. Trailer Park") {
              return {
                label: title,
                data: data,
                borderColor: '#FF33A6',
                backgroundColor: '#FF33A6',
                pointRadius: 4,
                pointHoverRadius: 5,
                pointBackgroundColor: '#FF33A6',
                pointBorderColor: '#FF33A6',
                tension: 0.1,
                hidden: false
              }
            }
            if(title === "MB3 - W. Trailer Park") {
              return {
                label: title,
                data: data,
                borderColor: '#33FFF5',
                backgroundColor: '#33FFF5',
                pointRadius: 4,
                pointHoverRadius: 5,
                pointBackgroundColor: '#33FFF5',
                pointBorderColor: '#33FFF5',
                tension: 0.1,
                hidden: false
              }
            }
            if(title === "MB - Cortez") {
              return {
                label: title,
                data: data,
                borderColor: '#FF8C33',
                backgroundColor: '#FF8C33',
                pointRadius: 4,
                pointHoverRadius: 5,
                pointBackgroundColor: '#FF8C33',
                pointBorderColor: '#FF8C33',
                tension: 0.1,
                hidden: false
              }
            }
            if(title === "MB - Fowler") {
              return {
                label: title,
                data: data,
                borderColor: '#B833FF',
                backgroundColor: '#B833FF',
                pointRadius: 4,
                pointHoverRadius: 5,
                pointBackgroundColor: '#B833FF',
                pointBorderColor: '#B833FF',
                tension: 0.1,
                hidden: false
              }
            }
          })  
      };
                    
      return formattedData;
  };

  const chartData = formatDataForChartJS(data, data[0].labels)
  const [filteredData, setFilteredData] = useState<any>()

  const formatDate = (input: any) => {  
    const date = new Date(input * 1000);
    const month = date.getMonth() + 1; 
    const day = date.getDate();
    const year = date.getFullYear();
    
    return `${month}/${day}/${year}`;
  }

  const convertToUnix = (input:any) => {
    const [month, day, year] = input.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    const unixTimestamp = Math.floor(date.getTime()/1000);
    
    return unixTimestamp;
  }

  useEffect(() => {
    if(data) {
      let dates: any[] = []
      const labels = data[0].labels
      labels.forEach((label:string) => {
        dates.push(convertToUnix(label))
      })
      setDates(dates)
    }
  },[data])

  useEffect(() => {
    if(startDate && endDate && dates && data) {
      const start = new Date(startDate).getTime()/1000;
      const end = new Date(endDate).getTime()/1000;

      const labels: any[] = []
      dates.forEach((date:any) => {
        if(date >= start && date <= end) {
          labels.push(formatDate(date))
        }
      })

      if(labels.length > 0) setFilteredData(formatDataForChartJS(data, labels))
    }
  },[
    startDate, 
    endDate,
    dates,
    data
  ])

  useEffect(() => {
    if(filteredData) setExportData(filteredData)
  },[filteredData])

  const handlePointVisibility = (text: string) => {
    switch (text) {
      case "MB - Tidal":
        setPoint0(!point0)
        break;
      case "MB4 - US41":
        setPoint1(!point1)
        break;
      case "MB1 - E. Trailer Park":
        setPoint2(!point2)
        break;
      case "MB2 - C. Trailer Park":
        setPoint3(!point3)
        break;
      case "MB3 - W. Trailer Park":
        setPoint4(!point4)
        break;
      case "MB - Cortez":
        setPoint5(!point5)
        break;
      case "MB - Fowler": 
        setPoint6(!point6)
        break; 
      default:
        break;
    }
  }

  const options: any = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: 'parts per million',
          color: 'white',
          font: {
            size: 16,
          },
        },
        zoom: {
          enabled: true,
          mode: 'x',
        },
        ticks: {
          color: 'white',
        },
      },
      x: {
        display: true,
        title: {
          display: true,
          text: 'Date',
          color: 'white',
          font: {
            size: 16,
          },
        },
        ticks: {
          color: 'white',
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        onClick: (e:any, legendItem:any, legend: any) => {
          const index = legendItem.datasetIndex;
          const { text } = legendItem
          handlePointVisibility(text)
          const chart = legend.chart;
          const meta = chart.getDatasetMeta(index);
          const bool = chart.data.datasets[index].hidden
          meta.hidden = meta.hidden === null ? !chart.data.datasets[index].hidden : null;

          // Redraw the chart
          chart.update();
        }
      },
      title: {
        display: true,
        color: 'white',
        text: title,
        font: {
          size: 18,
        },
      },
    },
  };

  return <Line data={filteredData ? filteredData : chartData} options={options} />;
};

export default LineChart;