// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#AusBoreHoles {
  width: 90vw !important;
  height: 40vh !important; }

.abh-container {
  flex-direction: column;
  height: 100%; }
  .abh-container .abh-map-container {
    display: flex;
    justify-content: center;
    align-items: center; }
    .abh-container .abh-map-container th,
    .abh-container .abh-map-container td {
      color: white; }
  .abh-container .table-row {
    position: relative;
    display: flex;
    justify-content: center; }
    .abh-container .table-row .minmax {
      position: absolute;
      top: 0%;
      right: 0%;
      z-index: 10000;
      width: 25px; }
      .abh-container .table-row .minmax svg {
        color: white; }
  .abh-container .abh-table-container {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 10;
    height: 40vh;
    overflow: auto;
    width: 90vw; }
    .abh-container .abh-table-container th,
    .abh-container .abh-table-container td {
      color: white; }
  .abh-container h4 {
    text-align: right;
    color: white;
    margin-right: 1rem; }
    .abh-container h4 a {
      color: #4fc32c;
      font-size: 18px; }

.expanded-abh {
  margin-top: -21% !important;
  height: 75vh !important;
  max-height: unset; }
`, "",{"version":3,"sources":["webpack://./src/features/reports/GemstatRaw/gemstat-styles.scss"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,uBAAuB,EAAA;;AAGzB;EACE,sBAAsB;EACtB,YAAY,EAAA;EAFd;IAKI,aAAa;IACb,uBAAuB;IACvB,mBAAmB,EAAA;IAPvB;;MAUM,YAAY,EAAA;EAVlB;IAeI,kBAAkB;IAClB,aAAa;IACb,uBAAuB,EAAA;IAjB3B;MAmBM,kBAAkB;MAClB,OAAO;MACP,SAAS;MACT,cAAc;MACd,WAAW,EAAA;MAvBjB;QAyBQ,YAAY,EAAA;EAzBpB;IA+BI,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,cAAc;IACd,WAAW,EAAA;IAtCf;;MAyCM,YAAY,EAAA;EAzClB;IA8CI,iBAAiB;IACjB,YAAY;IACZ,kBAAkB,EAAA;IAhDtB;MAkDM,cAAc;MACd,eAAe,EAAA;;AAKrB;EACE,2BAA2B;EAC3B,uBAAuB;EACvB,iBAAiB,EAAA","sourcesContent":["#AusBoreHoles {\n  width: 90vw !important;\n  height: 40vh !important;\n}\n\n.abh-container {\n  flex-direction: column;\n  height: 100%;\n\n  .abh-map-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    th,\n    td {\n      color: white;\n    }\n  }\n\n  .table-row {\n    position: relative;\n    display: flex;\n    justify-content: center;\n    .minmax {\n      position: absolute;\n      top: 0%;\n      right: 0%;\n      z-index: 10000;\n      width: 25px;\n      svg {\n        color: white;\n      }\n    }\n  }\n\n  .abh-table-container {\n    margin-top: 1rem;\n    display: flex;\n    justify-content: center;\n    position: relative;\n    z-index: 10;\n    height: 40vh;\n    overflow: auto;\n    width: 90vw;\n    th,\n    td {\n      color: white;\n    }\n  }\n\n    h4 {\n    text-align: right;\n    color: white;\n    margin-right: 1rem;\n    a {\n      color: #4fc32c;\n      font-size: 18px;\n    }\n  }\n}\n\n.expanded-abh {\n  margin-top: -21% !important;\n  height: 75vh !important;\n  max-height: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
