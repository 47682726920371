import { useState, useEffect, useContext, memo } from 'react';
import states from '../../../json/state_centroids.json';
import countries from '../../../json/country_centroids.json';
import { RefContext } from '../../../RefContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faArrowRight, faXmark } from '@fortawesome/pro-solid-svg-icons';

import {
  Form,
  FormControl,
  Button,
  InputGroup,
  Container,
  Row,
  ListGroup
} from 'react-bootstrap';
import './layer-search.scss';

const LayerSearch = ({ global, layers }: any) => {

  const [query, setQuery] = useState('');
  const [list, setList] = useState<any>([]);
  const [suggestions, setSuggestions] = useState<any>([]);
  const { currentRef } = useContext<any>(RefContext);
  
  const handleQuery = (input: any) => {
    const regex = new RegExp(`.*${input}.*`, 'i');
    const queried: any = layers.filter((layer: any) => {
      const { name, country, catagory, subcatagory, state } = layer.props;
      return (regex.test(name) || regex.test(country) || regex.test(catagory) || regex.test(subcatagory) || regex.test(state) ) && layer.props['inactive']!==true;
    });
    setList(queried);

    const suggested: any = queried.slice(0, 3).map((layer: any) => layer.props.name);
    setSuggestions(suggested);
  };

  const flyTo = (lng: any, lat: any) => {
    const { pathname } = window.location;
    if (pathname === '/') return;

    return currentRef.current.flyTo({
      center: [lng, lat],
      essential: true,
      zoom: global.viewport.zoom,
    });
  };

  useEffect(() => {
    if (global.currentCountry && currentRef && global.currentCountry!=='Global') {      
      
      const { lat, long }: any = countries.find(({ name }) => name === global.currentCountry);
      setTimeout(() => flyTo(long, lat), 500);
      if (global.currentState) {
        const { stateCentroids }: any = states.find(({ countryName }) => countryName === global.currentCountry);
        const { latitude, longitude } = stateCentroids.find(({ name }: any) => name === global.currentState);
        setTimeout(() => flyTo(longitude, latitude), 500);
      }
    }
  }, [
    global.currentCountry,
    global.currentState
  ]);

  useEffect(() => {
    if (query) {
      handleQuery(query);
    } else {
      setList([]);
      setSuggestions([]);
    }
  }, [query]);

  const handleClear = () => {
    setQuery('');
    setList([]);
    setSuggestions([]);
  };

  const handleSuggestionClick = (name: any) => {
    const matchedLayer = layers.find((layer: any) => layer.props.name === name);
    if (matchedLayer) {
      setList([matchedLayer]);
      setSuggestions([]);
      setQuery(name);
    }
  };

  const handleList = (input: any) => {
    if (input.length > 0) {
      const rows = [];
      for (let i = 0; i < input.length; i += 3) {
        rows.push(input.slice(i, i + 3));
      }

      return (
        <Container className='queried-layers'>
          {rows.map((rowItems, rowIndex) => (
            <Row key={rowIndex} className="thumbnail-container mb-3 mt-3">
              {rowItems.map((item: any) => item)}
            </Row>
          ))}
        </Container>
      );
    } else {
      return null;
    }
  };

  const renderSuggestions = (suggestions: any[]) => {
    if (suggestions.length > 0) {
      return (
        <Container className='suggestions-list'>
          <ListGroup>
            {suggestions.map((suggestion: any, index: any) => (
              <ListGroup.Item key={index} action onClick={() => handleSuggestionClick(suggestion)}>
                {suggestion}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Container>
      );
    } else {
      return null;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSuggestions([]);
  };

  return (
    <>
      <Form className="layer-search" onSubmit={handleSubmit}>
        <InputGroup>
          <InputGroup.Text className='icon-wrapper'>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </InputGroup.Text>
          <FormControl
            type="text"
            placeholder="Search Layers"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Button variant="primary" className="btn-submit" type="submit">
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
          {query && (<Button variant="secondary" className="btn-clear" onClick={handleClear} type="button">
                      <FontAwesomeIcon icon={faXmark} />
                    </Button>
          )}
        </InputGroup>
      </Form>
      {renderSuggestions(suggestions)}
      {handleList(list)}
    </>
  );
};

export default memo(LayerSearch);
