import { WhatsInMyWatershedBodyModel } from '../../../api/models';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const DonutChart = ({
  rowData,
  labels,
}: {
  rowData: WhatsInMyWatershedBodyModel;
  labels: string[];
}): JSX.Element => {
  const getPlainData = () => {
    const plainData = [];
    for (let label of labels) {
      plainData.push(rowData[label as keyof WhatsInMyWatershedBodyModel]);
    }

    return plainData;
  };

  const getDonutChartConfig = () => {
    const colorsGenerator = (): string[] => {
      const colors = [
        'rgb(36, 132, 185)',
        'rgb(219, 215, 16)',
        'rgb(94, 79, 162)',
        'rgb(166, 105, 153)',
        'rgb(227, 41, 49)',
        'rgb(255, 96, 2)',
      ];
      return colors;
    };

    const donutChartConfig = {
      labels: labels,
      datasets: [
        {
          label: 'Percentage values',
          data: getPlainData(),
          backgroundColor: colorsGenerator(),
          borderColor: colorsGenerator(),
          borderWidth: 1,
        },
      ],
      options: {
        legend: {
          display: false,
          position: 'right',
          maxWidth: 100,
          onClick: null,
        },
      },
    };

    return donutChartConfig;
  };
  const legend = {
    display: true,
    labels: {
      font: {
        size: 14,
      },
    },
  };
  return (
    <>
      {rowData ? (
        <Doughnut
          data={getDonutChartConfig()}
          height={400}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            color: '#ffff',
            plugins: {
              legend: {
                ...legend,
              },
            },
          }}
        />
      ) : (
        <h2>Loading data...</h2>
      )}
    </>
  );
};

export default DonutChart;
