// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.huc-popup .btn-close-container {
  width: 100%;
  position: relative;
  margin-bottom: 1.1rem; }
  .huc-popup .btn-close-container svg {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer; }

.mapboxgl-popup-content {
  background-color: #002044 !important; }
`, "",{"version":3,"sources":["webpack://./src/components/huc-popup-styles.scss"],"names":[],"mappings":"AAAA;EAEI,WAAW;EACX,kBAAkB;EAClB,qBAAqB,EAAA;EAJzB;IAMM,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,eAAe,EAAA;;AAKrB;EACE,oCAAoC,EAAA","sourcesContent":[".huc-popup {\n  .btn-close-container {\n    width: 100%;\n    position: relative;\n    margin-bottom: 1.1rem;\n    svg {\n      position: absolute;\n      top: 0;\n      right: 0;\n      cursor: pointer;\n    }\n  }\n}\n\n.mapboxgl-popup-content {\n  background-color: #002044 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
