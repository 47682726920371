import checkCircleImg from '../../assets/check-circle.svg'

function CustomLayerItem({
    img,
    id,
    name,
    selected,
    setSelected,
    disabled,
    data
}: any) {

    const onClick = () => {
        setSelected(id, data)
    };

    return (
        <div
            style={{ pointerEvents: disabled ? 'none' : 'auto', opacity: disabled ? .3 : 1 }}
            className={selected ? 'custom-layer thumbnail selected' : 'custom-layer thumbnail'}
            onClick={onClick}
        >
            <img className='layer' src={img} />
            <img className='check' src={checkCircleImg} />
            {disabled && <img className='check' src={checkCircleImg} />}
            <div className='description'>{name}</div>
        </div>
    );
}

export default CustomLayerItem