import { useState, Ref, useRef, useContext, useEffect, useCallback } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import Breadcrumb from '../shared/Breadcrumb';
import { useFullScreenHandle } from 'react-full-screen';
import FilterState from '../shared/FilterState';
import ReportName from '../shared/ReportName';
import ReportOptions from './ReportOptions';
import { Location, useLocation } from 'react-router-dom';
import { setLocalReport} from '../../utils';
import './ReportHeader.scss';

const ReportHeader = (props: any) => {
    const { global } = props;
    const location: Location = useLocation();
    setLocalReport(location.pathname);
    const mediaQuery = window.matchMedia('(max-width: 877px)');
    return (
    <div className='report-header'>
        <Row style={{paddingRight:0}}>
          <div className={(mediaQuery.matches) ? 'header-container content-wrap'  : 'header-container'}>
          {!props.titleoff && (
            <ReportName headerTitle={props.headerTitle} subHeader={props.subHeader}/>
          )}
            <ReportOptions {...props} global={global} />
          </div>
        </Row>
        {!global.globeView && !props.breadcrumboff && (
          <Breadcrumb {...props} global={global} />
        )}
    </div>
  );
};

export default ReportHeader;