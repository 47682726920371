import {
    useState,
    Ref,
    useRef,
    useContext,
    useEffect,
    useCallback
} from 'react';

import {
    Map,
    MapRef,
    Source,
    Layer,
    FullscreenControl,
    NavigationControl,
    GeolocateControl,
    LayerProps,
    Popup,
} from 'react-map-gl';
import { Feature, GeoJsonProperties, Point, FeatureCollection } from 'geojson';
import RenderLayerMarkers from './RenderLayerMarkers';
import RenderLayers from './RenderLayers';
import MapLegend from '../shared/MapLegend';
import MapSlider from '../shared/MapSlider';
import MapTitle from '../shared/MapTitle';
import DrawControl from '../shared/DrawControl';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import PointsWithinPolygon from '@turf/points-within-polygon';
import bboxPolygon from '@turf/bbox-polygon';
import bbox from '@turf/bbox';
import { AppContext } from '../../../AppContext';
import { PointContext } from '../../../PointContext';
import { updateCentroidState, updateGeolocateState } from '../../../features/utils'
import settings from "../../../settings.json";
import HucSearch from "../../../components/HucList/HucSearch";
import MCToolset from  './MCToolset';
import { parse, unparse } from 'papaparse';
import { HUC12InfoPoint } from '../../../types/HUC12InfoPoint';
import { RefContext } from '../../../RefContext';
import ZoomWidget from './ZoomWidget';

function MapAddition({
    global,
    mapRef,
    position,
    zipOff,
    statusMessage,
    initialLayers,
    onUpdate,
    onDelete,
    drawPlacement,
    PointInPolygonUpdate,
    PointInPolygonField,
    PointInPolygonLayer,
    PointInPolygonFeatures,
    MapSliderAdd,
    MapLegendWidth 

}: any) {

/*

                    <MapAddition global={global} 
                                mapRef={mapRef}

                                // Draw Polygon tool functions (optional)
                                onUpdate
                                onDelete={onDelete} //if included, include the draw polygon tool
                                PointInPolygonField={locationField}
                                PointInPolygonUpdate={pointInPolygonUpdate}
                                
                                // Map Tools positioning, MCToolset (optional) 
                                position={'low'}
                                
                                //Markers
                                zipOff={true}
                                
                                //Include Map Slider (optional)
                                MapSliderAdd={true}

                                //Include Map Legend (optional)
                                MapLegendWidth={280}

                                //Add status message to map (optional)
                                statusMessage={statusMessage}/>

                                //Include specified layers as a map tool (optional)
                                initialLayers
  */

    const defaultLayerName = 'pointlayer';

    //mapboxgl-ctrl-top-left
    const appContext = useContext(AppContext);
    const { currentRef } = useContext(RefContext)
    const { currentPoint } = useContext(PointContext);
    const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

    // @ts-ignore
    const mapInstance = currentRef && currentRef?.current

    const [selectedPolygons, setSelectedPolygons] = useState<any[]>([]);

    const [huc12InfoCatalogArray, setHuc12InfoCatalogArray] = useState<any[]>([]);

    useEffect(() => {
      const huc12InfoFileURL = '/huc12_info_update.csv';
      fetch(huc12InfoFileURL)
        .then(response => response.text())
        .then(responseText => {
          const data: HUC12InfoPoint[] | any[] = parse(responseText, {
            header: true,
          }).data;
       
         setHuc12InfoCatalogArray(data);
        });
    }, []);


    useEffect(() => {
      if(selectedPolygons && selectedPolygons.length>0) {
            //updateHucLayer();       
      }
    },[selectedPolygons])  

    const updateHucLayer = () => {
        let newPolygons = [...global.filterPolygons];
        newPolygons.push(selectedPolygons[0]) //@ts-ignore
        
        let fromDraw, polygonOnly = [] as any;//@ts-ignore
        if(currentRef && currentRef.current){  //@ts-ignore
          //@ts-ignore
          let styl = currentRef.current.getStyle();
          if(styl.sources && styl.sources["mapbox-gl-draw-cold"] && styl.sources["mapbox-gl-draw-hot"]){
            fromDraw = [...styl.sources["mapbox-gl-draw-cold"].data.features,...styl.sources["mapbox-gl-draw-hot"].data.features]
          }
          if(fromDraw){
            polygonOnly = fromDraw.filter((item:any) => item.geometry.type === 'Polygon');
            
          }
        }
        newPolygons = polygonOnly;
        global.setFilterPolygons(newPolygons)

        let huc12Centroids = newFeatureCollection(huc12InfoCatalogArray); 
        //sources
        //mapbox-gl-draw-cold data  features
        let newDataset = [] as any;
        newPolygons.forEach((dataItem:any, index:number) => {//@ts-ignore
              let ptsWithin = PointsWithinPolygon(huc12Centroids,dataItem);

          if(ptsWithin && ptsWithin.features && ptsWithin.features.length>0){
             const h12Array = ptsWithin.features.map((item:any) => item.properties)
        
             newDataset = [...newDataset,...h12Array];  
          }
        });  


        global.setResultsDataset(newDataset)
    }



    const newFeatureCollection = (fData: any) => {
      const features: Array<Feature<Point, GeoJsonProperties>> = [];
      for (let dataItem of fData) {
        const featureWithPoint: Feature<Point> = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [+dataItem.centroid_longitude, +dataItem.centroid_latitude],
          },
          properties: dataItem,
        };
        features.push(featureWithPoint);
      }
      const featureCollectionFromData: FeatureCollection = {
        type: 'FeatureCollection',
        features: features,
      };
      return featureCollectionFromData;
    };

    const onUpdateDefault = useCallback((e: any | null) => {
      if (mapRef && mapRef.current) {
          if(e.features && e.features.length>0 && e.features[0].geometry.type=== 'Polygon'){
                let newPolygons = [] as any;
                newPolygons.push(e.features[0])
                setSelectedPolygons(newPolygons);
                global.setFilterBoundingBox(bbox(e.features[0]));
          }
    
          if(PointInPolygonUpdate){    
              const pointlayerFeatures = mapRef.current.queryRenderedFeatures(undefined, {
                layers: PointInPolygonLayer||[defaultLayerName],
              });
              const pointsSelected: any[] = [];
              const featuresSelected: any[] = [];
              if (pointlayerFeatures.length > 0) {
              
                for (let dataItem of pointlayerFeatures) {
                  if (dataItem && dataItem.properties && !dataItem.properties.lng) {
                      dataItem.properties.lng = dataItem.geometry.coordinates[0];
                  }
                  if (dataItem && dataItem.properties && !dataItem.properties.lat) {
                      dataItem.properties.lat = dataItem.geometry.coordinates[1];
                  }
           
                  if (dataItem && dataItem.properties && dataItem.properties.lng && dataItem.properties.lat && e.features[0]) {
                    console.log(e.features[0], typeof e.features[0])
                    const inSelection = booleanPointInPolygon(
                      [dataItem.properties.lng, dataItem.properties.lat],
                      e.features[0]
                    );
                    if (inSelection) {
                      pointsSelected.push(dataItem.properties[PointInPolygonField]);
                      featuresSelected.push(dataItem.properties)
                    }
                  }
                }
                if(PointInPolygonFeatures){
                    PointInPolygonFeatures(featuresSelected); 
                } else {
                    PointInPolygonUpdate(pointsSelected); 
                }
                  
              }
          }

          if(onUpdate){
            onUpdate(e)
          }
      }
    }, []);

    const onDeleteDefault = useCallback((e: any | null) => {
/*      setTimeout(() => {
        updateHucLayer();
      }, 2200); */ 
      if (mapRef && mapRef.current) {

      }
      if(onDelete && e){
        onDelete(e);
      }
    }, []);

    const updateHuc12 = (viewport: any, layerObject:any): void => {
        global.setClickedHUC12(layerObject.properties.HUC12);
        global.setGlobalHuc12(layerObject.properties.HUC12);
        global.setViewport(viewport);
        if (appContext.updateContext) {
          appContext.updateContext(
            layerObject.properties.HUC12.substr(0,8),
            `${layerObject.properties.NAME} (${layerObject.properties.HUC12})`,
            global.currentReport,
            'United States'
          );
        }
    };

    useEffect(() => {
      const huc12SourceLayer = 'huc12s-line';
      if(global.clickedFeatures && global.clickedFeatures.length > 0){
          let firstFeature = global.clickedFeatures[0];
          if(firstFeature.source && firstFeature.source===huc12SourceLayer && firstFeature.properties.HUC12 && currentPoint){
            firstFeature.properties['huc12'] = firstFeature.properties.HUC12
            global.setResultsDataset([firstFeature.properties])
            //@ts-ignore
              updateHuc12({ zoom: 10, latitude: +currentPoint.lat, longitude:+currentPoint.lng}, firstFeature)              
          }
      }
    }, [global.clickedFeatures])

    const handleGeolocate = ({ coords }: any) => {
      const { latitude, longitude } = coords;
      updateGeolocateState( latitude, longitude, global.currentReport = '', appContext.updateContext)
    };
      //position={drawPlacement||'top-left'}
    return (
          <div>
            {global.resultsDataset && global.resultsDataset.length>0 && (
              <>
                <HucSearch
                  opacity={global.OABRMXOpacity}
                  fillColor={'#BD1C1C'}
                  outlineColor={'black'}
                  fillColorHUC8= {'transparent'}
                  outlineColorHUC8={'transparent'} 
                  fillColorHUC12={'rgba(189, 28, 28, .9)'}
                  outlineColorHUC12={'#030630'} 
                  switch={true}
                  huc8ID={"huc8line"}
                  huc12ID={"huc12s-line"}
                  huc8only={false}
                  huc12Zoom={1}
                  data={global.resultsDataset}
                  setData={global.setResultsDataset}
                  updateHuc12={updateHuc12}
                />
              </>
            )}

            <MCToolset global={global} mapRef={mapRef} position={position} initialLayers={initialLayers}/>
            <FullscreenControl />
            <NavigationControl />
            <GeolocateControl onGeolocate={handleGeolocate}/>
            <RenderLayerMarkers global={global} bounds={reportBounds} zipOff={true} />
            <RenderLayers global={global} mapRef={mapRef} beforeID={settings.defaultBeforeId}/>
            {statusMessage && (<MapTitle selectedHUC8Value={appContext.selectedHUC8} statusMessage={statusMessage} />)}
            {MapSliderAdd && (<MapSlider global={global} />)}
            {MapLegendWidth && (<MapLegend global={global}  legendWidth={MapLegendWidth} />)}
            <DrawControl
                      position={'top-left'}
                      displayControlsDefault={false}
                      onCreate={onUpdateDefault}
                      onUpdate={onUpdateDefault}
                      onDelete={onDeleteDefault}
                      controls={{
                        polygon: true,
                        trash: true,
                      }}
                    />
            
          </div>
    );
}

export default MapAddition