import { useState } from 'react'
import {
    Col,
    Row,
    Button,
    Form
} from 'react-bootstrap';

const FormInputField = (props: any) => {
    
    const {
        currentIndex,
        activeIndex,
        value,
        onChange,
        id
    } = props

    const [disabled] = useState(currentIndex !== activeIndex)
   
    return (
        <div
            className={
            currentIndex === activeIndex
                ? "form-input-wrapper active-input"
                : "form-input-wrapper"}
        >
            <Form.Control type="text" defaultValue={value} disabled={disabled} onChange={onChange} id={id}/>
        </div>
    )
}

export default FormInputField