import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from './keycloak'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import axios from 'axios'


const eventLogger = (event: unknown, error: unknown) => {
  console.log('onKeycloakEvent', 'event: ', event, 'error: ', error)

  if(event === 'onAuthRefreshError'|| event === 'onAuthError'){
    window.location.href = `${window.location.origin}/login`;
  }
}

const tokenLogger = (tokens: any) => {
  console.log('onKeycloakTokens', tokens)
  if(tokens && tokens.token){
    sessionStorage.setItem("authKey", tokens.token);
  }
}

ReactDOM.render(  
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}
    >
     
        <App />
     
    </ReactKeycloakProvider>
  ,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

