
import { Marker } from 'react-map-gl'
import useSupercluster from 'use-supercluster';

const zipcodes = require('zipcodes')

const RenderZipCodeMarkers = (props: any) => {

    const { bounds, viewport } = props

    const codes = Object.keys(zipcodes.codes)
    const postalCodes = codes.map((code:any) => {
        return zipcodes.lookup(code)
    })


    const points: any = postalCodes.map((point: any, index: number) => ({
        type: "Feature",
        properties: {
            cluster: false,
            index,
            zip: point.zip,
            id: `cluster-${index}`,
        },
        geometry: {
            type: "Point",
            coordinates: [point.longitude, point.latitude],
        }
    }))

    const { clusters } = useSupercluster({
        points,
        bounds,
        zoom: viewport.zoom,
        options: { radius: 1000, maxZoom: 10, minZoom: 8 }
    })

    const renderList = () => {
        return clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates
            const { cluster: isCluster, zip, index } = cluster.properties;

            if (isCluster) {


                return (
                    <Marker
                        key={cluster.id}
                        latitude={latitude}
                        longitude={longitude}
                        style={{ display: "none"}}
                    >
                    </Marker>
                )
            }

            return (
                <Marker
                    key={cluster.properties.id}
                    latitude={latitude}
                    longitude={longitude}
                    style={{ 
                        color: 'orange',
                        fontSize: '16px',
                        fontWeight: 'bold'
                    }}
                >
                  {zip}
                </Marker>
            );
        })
    }

  return (
    <>
        {renderList()}
    </>
  )
}

export default RenderZipCodeMarkers