import { useEffect, useState, useRef, useCallback, useContext, Ref } from 'react';
import { Button, Form, Dropdown } from 'react-bootstrap';
import settings from '../../../settings.json';
import './styles.scss';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import {
  Map,
  useMap,
  MapRef,
  Popup,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  useControl,
  Layer,
  Source,
} from 'react-map-gl';
import type { LayerProps } from 'react-map-gl';
import { RefContext } from '../../../RefContext'
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Feature, GeoJsonProperties, Point, FeatureCollection } from 'geojson';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { DateTime } from 'luxon';
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import { ToastContainer, toast } from 'react-toastify';
import agent from '../../../api/agent';
import LoadingDataAnimation from '../../../components/LoadingDataAnimation';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import moment from 'moment';
import fullscreenIcon from '../../../assets/fullscreen-icon.svg';

import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import { message_hucrestriction, message_nodatalocation, message_noresult } from '../../../Constants';
import { AppContext } from '../../../AppContext';
import { CatalogsContext } from '../../../CatalogsProvider';
import { Location, useLocation } from 'react-router-dom';

import { flyToInitialLocationHandler, getHuc12MembersOfHUC8 } from '../../utils';

import {
  processStatusMessage,
  uniqueValues,
  fitBoundsMapData,
  groupByKey,
} from '../shared/report-data';
import MapLegend from '../shared/MapLegend';
import MapSlider from '../shared/MapSlider';
import MapAddition from  '../shared/MapAddition';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend);

export default function SurfaceWaterFlowRate(props: any) {
  const { global } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);
  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;
  const selectedHUC8Value = appContext.selectedHUC8;
 
  const selectedCategory = global.filter1Selected || 'Water Flow (CF/S)';
  const selectedFilterDays = global.filterDaysSelected || 5;
  const selectedFilterLocations = global.filterLocationsSelected;
  const selectedDateRange = global.dateRangeSelected || '';

  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const [isFullScreenActive, setFullScreenActive] = useState(false);
  const fullscreenContainerHandler = () => {
    setFullScreenActive(!isFullScreenActive);
  };

  const { current: map } = useMap();
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [cursor, setCursor] = useState<string>('');
  const chartRef = useRef<any>();

  const [reportData, setReportData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [chartData, setChartData] = useState<any>(null);
  const [featureData, setFeatureData] = useState<FeatureCollection>();

  const [huc12Members, setHuc12Members] = useState<any[]>([]);
  const [huc12InfoCatalogArray, setHuc12InfoCatalogDataArray] = useState<any[]>([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapLegendData, setMapLegendData] = useState<any[]>([]);
  const [circleColorStyle, setCircleColorStyle] = useState<any>([]);

  const locationField = 'site_no';
  const [mapHoverFilter, setMapHoverFilter] = useState<any>(['in', ['get', locationField], '']);
  const [userSelectedLocation, setUserSelectedLocation] = useState<any>();
  const [userSelectedLocations, setUserSelectedLocations] = useState<any>([]);
  const [usLocations, setUsLocations] = useState<any>([]);

  const [isDataReportLoaded, setIsDataReportLoaded] = useState(false);
  const [statusMessage, setStatusMessage] = useState<any>(null);
  const [filterLabelDays, setFilterLabelDays] = useState<any>([]);
  const [preserveDrawingBuffer, setPreserveDrawingBuffer] = useState(false);
  const [layerStyle, setLayerStyle] = useState<any>([]);

  const Categories = ['Water Flow (CF/S)', 'Water Stage (% Flood)', 'Water Stage (Ft)'];
  const daysToLimit = 367;
  
  const mapRef = useRef<MapRef>();
  const { setCurrentRef } = useContext(RefContext);
  useEffect(() => { setCurrentRef?.(mapRef) }, [mapRef]);
  const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  
  const [dateRange, setDateRange] = useState<any[]>([])

  function formatDates(inputDates: any) {
    const outputDates: any = [];

    inputDates.forEach((inputDate: any) => {
      const dateObj = new Date(inputDate);
      const day = dateObj.getDate().toString().padStart(2, '0');
      const month = dateObj.toLocaleString('default', { month: 'short' });
      const year = dateObj.getFullYear();
      console.log(year)

      const formattedDate = `${day} ${month} ${year}`;
      outputDates.push(formattedDate);
    });

    return outputDates;
  }

  useEffect(() => {
    if (global.dateRangeSelected) {
      const formatted = formatDates(global.dateRangeSelected)
      setDateRange(formatted)
    }
  }, [global.dateRangeSelected])
 
  useEffect(() => {
    global.setfilter1Selected('');
    global.setfilterLocationsSelected([]);
  }, []);

  useEffect(() => {
    setChartData([]);
    setReportData([]);
    setUserSelectedLocations([]);
  }, [selectedHUC8Value]);

  useEffect(() => {
    if (selectedFilterLocations && selectedFilterLocations.length > 0) {
      if (selectedFilterLocations[0].substr(0, 8) !== selectedHUC8Value)
        global.setfilterLocationsSelected([]);
    }
    const huc12Members = getHuc12MembersOfHUC8(selectedHUC8Value, catalogsContext.huc12Catalog);
    setHuc12Members(huc12Members);
  }, []);

  let selectedLocation = (popupInfo && popupInfo.HUC12) || ['literal', selectedFilterLocations];

  useEffect(() => {
    global.setUserLogged(true);
    if(global.trackerOn) global.setTrackerOn(false)
  }, []);

  useEffect(() => {
    let fData = [...reportData];
    if (
      selectedFilterLocations &&
      selectedFilterLocations.length > 0 &&
      selectedFilterLocations.indexOf('All') < 0
    ) {
      fData = fData.filter((o: any) => selectedFilterLocations.indexOf(o['station_name']) > -1);
    }
    if (reportData.length > 0) {
      setFilteredData(fData);
    }
  }, [selectedFilterLocations, selectedCategory]);

  useEffect(() => {
    let fData = [...reportData];
    if (
      userSelectedLocations &&
      userSelectedLocations.length > 0 &&
      userSelectedLocations.indexOf('All') < 0
    ) {
      fData = fData.filter((o: any) => userSelectedLocations.indexOf(o[locationField]) > -1);
    }
    if (reportData.length > 0) {
      updateChartData(fData);
    }
  }, [userSelectedLocations]);

  const uniqueValuesNonSort = (filteredData:any, itemField:string) => { 
    const uniqueArray = filteredData.map((item:any) => item[itemField])
    .filter((value:any, index:any, self:any) => self.indexOf(value) === index)
    .filter((value:any, index:any, self:any) => value)
    return uniqueArray;
  };

  const updateChartData = (fData: any) => {
    fData = fData.sort((x: any, y: any) => x['date'] - y['date']);
    const chartLabels = uniqueValuesNonSort(fData, 'datetimeunique');
    const groupedresults = groupByKey<any, any>(fData, i => i.station_name.substr(0, 15)); //Huc12
    const chartdata_data: any[] = [];
    let chartdata_labels: any[] = [];
   
    for (const key in groupedresults) {
      let labelGroup = key;
      let customField = groupedresults[key][0].station_name || '';

      let dataObject = {
        label: labelGroup,
        custom: customField,
        data: groupedresults[key].map((x: any) => +x[selectedCategory]),
        backgroundColor: '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6),
      };
      chartdata_data.push(dataObject);
    }
    chartdata_labels = chartLabels.map((x: any) => x);
    const newchartdata = {
      labels: chartdata_labels,
      datasets: chartdata_data,
      borderColor: '#ff6384',
    };

    setChartData(newchartdata);
  };

  useEffect(() => {
    updateLayerStyle();
  }, [isDataReportLoaded])

  useEffect(() => {
    if(statusMessage){
        global.notify(statusMessage)
    }
  }, [statusMessage]);

  useEffect(() => {
    setStatusMessage('');
    if(selectedHUC8Value && global.hucRestrict.length>0 && (global.hucRestrict.indexOf(selectedHUC8Value) < 0)){
        setIsDataReportLoaded(true);
        return global.notify(message_hucrestriction) 
    }
    
    setIsDataReportLoaded(false);

    let daysToQuery = 5;
    let startDate = '';
    const one_day = 1000 * 60 * 60 * 24;
    if(selectedDateRange && selectedDateRange.length>1){  
      const daysDiff = Math.floor((selectedDateRange[1]  - selectedDateRange[0])/one_day)+1;
      daysToQuery = Math.min(daysDiff, daysToLimit);
      startDate = DateTime.fromJSDate(new Date(selectedDateRange[1])).plus({days: 1}).toFormat('yyyyLLdd');
    }
    daysToQuery = Math.max(1, daysToQuery);

    /* if(selectedDays > daysToQuery){
      toast('Limited to ' + daysToLimit + ' days.');
    } */

    agent.Reports.SurfaceWaterFlowRate(selectedHUC8Value, daysToQuery, startDate).then((res: any) => {
      flyToInitialLocationHandler(selectedHUC8Value, mapRef, catalogsContext.huc8Catalog);
      const returnStatus = processStatusMessage(res);
      setStatusMessage(returnStatus.statusMessage);

      if (returnStatus.Abort) {
        setIsDataReportLoaded(true);
        return;
      }
      let returnedData = [...res.body];
      if (!returnedData || !returnedData.length || returnedData.length < 1) {
        setIsDataReportLoaded(true);
        setStatusMessage(message_noresult);
        return;
      }

      const dateExtent = d3Array.extent<any, any>(returnedData, d => d["flow_datetime"]);

      if(selectedDateRange && selectedDateRange.length>1){
        let filterDays = [DateTime.fromJSDate(new Date(selectedDateRange[0])).toLocaleString(), DateTime.fromJSDate(new Date(selectedDateRange[1])).toLocaleString()];        
        setFilterLabelDays(filterDays);
      } else if(dateExtent && dateExtent[0] && dateExtent[1]){
        let filterDays = [DateTime.fromMillis(dateExtent[0]).toLocaleString(), DateTime.fromMillis(dateExtent[1]).toLocaleString()];        
        setFilterLabelDays(filterDays);
      } 

      // Populate filters
      global.setfilter1Populated(Categories);

      const newLocations = uniqueValues(returnedData, 'station_name');
      const siteFilter = newLocations.map((x: any, index: number) => ({ huc12: x, hucname: x }));
      global.setfilterLocationsPopulated([{ huc12: 'All', hucname: 'All' }, ...siteFilter]);

      for (let dataItem of returnedData) {
        dataItem.datetime = DateTime.fromJSDate(new Date(dataItem['flow_datetime']))
          .startOf('day')
          .toJSDate(); 
        dataItem.datetimeunique = DateTime.fromJSDate(new Date(dataItem['flow_datetime']))
          .startOf('day')
          .toFormat('MMM dd yy'); 
        dataItem.date = dataItem['flow_datetime'];
        dataItem.lat = dataItem.latitude;
        dataItem.lng = dataItem.longitude;
        dataItem.centroid_latitude = dataItem.latitude;
        dataItem.centroid_longitude = dataItem.longitude;
        dataItem.hucname = dataItem.station_name; //+++

        let elements = [];
        if (dataItem['Water Flow (CF/S)']) {
          elements.push('Water Flow (CF/S)');
        }
        if (dataItem['Water Stage (% Flood)']) {
          elements.push(' Water Stage (% Flood)');
        }
        if (dataItem['Water Stage (Ft)']) {
          elements.push(' Water Stage (Ft)');
        }
        dataItem.elements = elements.toString();
      }

      setReportData(returnedData);

      setIsDataReportLoaded(true);
    });
  }, [selectedFilterDays, global.timeReferenceUnit, selectedHUC8Value, selectedDateRange]);

  useEffect(() => {
    setFilteredData([...reportData]);
  }, [reportData]); 

  useEffect(() => {
    updateChartData(filteredData);
    const uniqueLocations = [];
    const uniqueLocationsData = [];
    for (let dataItem of filteredData) {
      if (uniqueLocations.indexOf(dataItem.site_no) < 0) {
        uniqueLocations.push(dataItem.site_no);
        uniqueLocationsData.push(dataItem);
      }
    }

    const featureCollectionFromReportData = newFeatureCollection(
      uniqueLocationsData,
      catalogsContext.huc12Catalog,
      selectedHUC8Value
    );
    setFeatureData(featureCollectionFromReportData);
    if (featureCollectionFromReportData.features.length > 0) {
      if (mapRef && mapRef.current) {
        const mapRef_ = mapRef;
        setTimeout(() => {
          fitBoundsMapData(featureCollectionFromReportData, mapRef_);
        }, 400);
      }
    }
  }, [filteredData]);

  const newFeatureCollection = (fData: any, huc12Catalog: any[], selectedHUC8Value: any) => {
    const features: Array<Feature<Point, GeoJsonProperties>> = [];
    for (let dataItem of fData) {
      if (dataItem) {
        let huc12Item = dataItem;
        if (huc12Item) {
          const featureWithPoint: Feature<Point> = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [+huc12Item.centroid_longitude, +huc12Item.centroid_latitude],
            },
            properties: dataItem,
          };
          if (featureWithPoint && featureWithPoint.properties) {
            featureWithPoint.properties.lat = huc12Item.centroid_latitude;
            featureWithPoint.properties.lng = huc12Item.centroid_longitude;
            featureWithPoint.properties.HUC12 = dataItem.huc12;
            featureWithPoint.properties.HUC8 = selectedHUC8Value;
            featureWithPoint.properties.hucname = huc12Item.name;
          }
          features.push(featureWithPoint);
        }
      }
    }
    const featureCollectionFromReportData: FeatureCollection = {
      type: 'FeatureCollection',
      features: features,
    };
    return featureCollectionFromReportData;
  };

  const measurementUnit = (category: string) => {
    let mUnit = 'Ft';
    if (category === 'Water Flow (CF/S)') {
      mUnit = 'CF/S';
    } else if (category === 'Water Stage (% Flood)') {
      mUnit = '% Flood';
    }
    return mUnit;
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: 'Max of Reading',
          color: 'white',
        },
      },
      x: {
        display: true,
        title: {
          display: true,
          text: 'Date',
          color: 'white',
        },
        type: 'category',
      },
    },
    options: {
      interaction: {
        mode: 'nearest',
      },
    } as const,
    plugins: {
      legend: {
        position: 'bottom' as const,
        maxHeight: 47,
        labels: {
          usePointStyle: true,
        },
      } as const,
      title: {
        display: true,
        text: selectedCategory,
        color: 'white',
        align: 'center',
        padding: 10,
        font: {
          size: 20,
        },
      } as const,
      tooltip: {
        padding: 10,
        bodyFont: {
          size: 24,
        },
        titleFont: {
          size: 24,
        },
        boxPadding: 8,
        usePointStyle: true,
        backgroundColor: '#12234f',
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || '';
            if (label) {
              label = [
                context.dataset.custom,
                'Max Reading ' + context.parsed.y + ' ' + measurementUnit(selectedCategory),
              ];
            }
            /* const dataLocation = filteredData.filter((o:any) => o["station_name"]===context.dataset.custom);    
            if(dataLocation.length > 0){
                setPopupInfo(dataLocation[0]);    
                setTimeout(() => {
                   setMapHoverFilter(['in', ['get', locationField], ["literal", dataLocation[0].site_no]]);
                }, 1); 
            } */
            return label;
          },
        },
      } as const,
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      } as const,
    } as const,
  } as const;

  const onChartClickHandler = (event: any) => {
    const currentClick = getElementAtEvent(chartRef.current, event)[0];
    if (currentClick) {
      const currentClickMetaData = chartRef.current.getDatasetMeta(currentClick.datasetIndex);
      const currentSite = currentClickMetaData.label;
      let huc12selected = null;
      let hdata = [...reportData];
      let siteData = {};
      for (let dataItem of hdata) {
        if (
          dataItem.station_name &&
          dataItem.station_name.substr(0, 15) === currentSite.substr(0, 15)
        ) {
          huc12selected = dataItem.site_no;
          siteData = dataItem;
        }
      }
      if (huc12selected) {
        if (userSelectedLocations.indexOf(huc12selected) > -1) {
          setPopupInfo(null);
          setUserSelectedLocations([]);
          setMapHoverFilter(['in', ['get', locationField], '']);
        } else {
          setPopupInfo(siteData);
          setUserSelectedLocation(huc12selected);
          setUserSelectedLocations([huc12selected]);
          setMapHoverFilter(['in', ['get', locationField], currentSite]);
        }
      }
    }
  };
/*
  const layerStyle: LayerProps = {
    id: 'pointlayer',
    type: 'circle' as const,
    paint: {
      'circle-radius': 10,
      'circle-color': 'blue',
      'circle-stroke-color': 'white',
      'circle-stroke-width': 1,
      'circle-opacity': 0.5,
    },
  };
*/

  const updateLayerStyle = () => {
      const newLayerStyle:  LayerProps = {
      id: 'pointlayer',
      type: 'circle' as const,
      paint: {
        'circle-radius': 10,
        'circle-color': 'blue',
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1,
        'circle-opacity': 0.5,
      },
    };

    setLayerStyle(newLayerStyle);
  }

  const hoverLayerStyle: LayerProps = {
    id: 'hoverlayer',
    type: 'circle' as const,
    paint: {
      'circle-radius': 12,
      'circle-color': '#faebd7',
      'circle-stroke-color': 'white',
      'circle-stroke-width': 1,
      'circle-opacity': 0.5,
    },
  };

  const onMouseEnter = useCallback((event: any | null) => {
    if (event.features && event.features[0] && event.features[0].properties) {
      setCursor('pointer');
      setPopupInfo(event.features[0].properties);
      setUserSelectedLocation(event.features[0].properties.Huc12);
      setMapHoverFilter(['in', ['get', locationField], event.features[0].properties.site_no]);
    }
  }, []);

  const onMouseLeave = useCallback((event: any | null) => {
    setCursor('');
    if (userSelectedLocation) {
      setUserSelectedLocation(null);
      setPopupInfo(null);
      setMapHoverFilter(['in', ['get', locationField], '']);
    }
    if (event && event.features && event.features[0]) {
      setPopupInfo(null);
      setMapHoverFilter(['in', ['get', locationField], '']);
    }
  }, []);

  useEffect(() => {
    if (usLocations.length > 0) {
      if (userSelectedLocations.indexOf(usLocations[0].site_no) > -1) {
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', locationField], '']);
      } else {
        setUserSelectedLocations(usLocations[0].site_no);
        setPopupInfo(usLocations[0]);
        setTimeout(() => {
          setMapHoverFilter(['in', ['get', locationField], usLocations[0].site_no]);
        }, 555);
      }
    }
  }, [usLocations]);

  const onHandleClick = useCallback((event: any | null) => {
    const feature = event.features && event.features[0];
    if (feature) {
      setUsLocations([feature.properties]);
    }
  }, []);

  const onMapLoad = useCallback(() => {
    if (mapRef && mapRef.current) {
      setMapLoaded(true);

      mapRef.current.resize();
    }
  }, []);

  const pointInPolygonUpdate = (pointsSelected: any) => {
        setUserSelectedLocations(pointsSelected);
        setMapHoverFilter(['in', ['get', locationField], ['literal', pointsSelected]]);
  }

  const onDelete = useCallback((e: any | null) => {
    if (mapRef && mapRef.current) {
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', locationField], '']);
    }
  }, []);

  const renderPopup = () => {
    return (
      <Popup
        
        longitude={Number(popupInfo.lng)}
        latitude={Number(popupInfo.lat)}
        onClose={() => setPopupInfo(null)}
      >

        <div className="popup-container">
          <h4>{popupInfo['station_name']}</h4>
          <div className="popup-content-row">
            <span className="popup-content-label">Elements</span>
            <span className="popup-content-value">{popupInfo["elements"]}</span>
          </div>
          <div className="popup-content-row">
            <span className="popup-content-label">Huc12</span>
            <span className="popup-content-value">{popupInfo["huc12"]}</span>
          </div>
        </div>
      </Popup>
    );
  };

  const deselectFilters = useCallback((e: any | null) => {
    setPopupInfo(null);
    setMapHoverFilter(['in', ['get', locationField], '']);
    setUserSelectedLocations([]);
  }, []);

  return (
    <>
      <FullScreenComp handle={fullscreenhandle}>
        <div className='SurfaceWaterFlowRate'  id='SurfaceWaterFlowRate-report'>
          <ReportHeader global={global} data={filteredData} mapRef={mapRef} 
            reportID={"SurfaceWaterFlowRate-report"} fullScreenClickHandle={fullscreenhandle.enter} 
            filterLabelDays={dateRange} />
          <div className='container'>
            <div className='col main-col'>
              <div className='row gx-5'>
                <div className='map-best-container'>
                  <LoadingDataAnimation dataLoading={!isDataReportLoaded} />
                  <Map
                    id='SurfaceWaterFlowRate'
                    mapboxAccessToken={settings.maboxKey}
                    mapStyle={global.mapStyle}
                    onLoad={onMapLoad}
                    onClick={(e) => {
                      global.onMapClick(e)
                      onHandleClick(e)
                    }}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    interactiveLayerIds={['pointlayer']}
                    preserveDrawingBuffer={true}
                    cursor={cursor}
                    ref={mapRef as Ref<MapRef>}
                    projection={global.globeView ? 'globe' : 'mercator' as any}
                    onMoveEnd={(e) => {
                        global.setViewport({
                          longitude: e.viewState.longitude,
                          latitude: e.viewState.latitude,
                          zoom: e.viewState.zoom,
                        })
                      }
                    }
                  >
                    {featureData && (
                      <Source id='circlesource' type='geojson' data={featureData}>
                        <Layer {...layerStyle} />
                        <Layer {...hoverLayerStyle} filter={mapHoverFilter} />
                      </Source>
                    )}

                    <MapAddition global={global} 
                                mapRef={mapRef}
                                onDelete={onDelete}
                                PointInPolygonField={locationField}
                                PointInPolygonUpdate={pointInPolygonUpdate}
                                position={'low'}
                                zipOff={true}
                                statusMessage={statusMessage}/>

                    {popupInfo && renderPopup()}

                    <div className='map-legend-container'>
                      <MapLegend
                        global={global}    
                        legendWidth={320}                    
                      />
                   </div>
                  </Map>    
                </div>
              </div>
              <div className='row gx-5'>
                <div
                  className={
                    isFullScreenActive ? 'expand-container chart-container' : 'chart-container'
                  }
                >
                  <div className='report-options'>
                    <Button onClick={fullscreenContainerHandler}>
                      <img src={fullscreenIcon} />
                    </Button>
                  </div>
                  {chartData && (
                    <Bar
                      options={options}
                      data={chartData}
                      ref={chartRef}
                      onClick={onChartClickHandler}
                    />
                  )}
                </div>
              </div>
            </div>
            <ReportFooter />
          </div>
        </div>
        <MapSlider global={global} />      
      </FullScreenComp>
    </>
  );
}
