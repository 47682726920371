import {
    useEffect,
    useState,
    useContext,
    useMemo,
    useRef
} from 'react'
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { PointContext } from '../../../PointContext';
import { RefContext } from '../../../RefContext';
import { formatDate } from '../../utils';
import ReportTable from '../shared/ReportTable';
import agent from '../../../api/agent';
import settings from '../../../settings.json'
import RenderLayers from '../../reports/shared/RenderLayers'
import RenderLayerMarkers from '../../reports/shared/RenderLayerMarkers'
import ReportHeader from '../shared/ReportHeader';
import MapSlider from '../shared/MapSlider';
import { Row, Col } from 'react-bootstrap'
import {
    Map,
    FullscreenControl,
    NavigationControl,
    GeolocateControl,
} from 'react-map-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faMaximize,
    faMinimize
} from '@fortawesome/pro-solid-svg-icons'
import './ausbh-styles.scss'

const AustralianBoreData = ({ global }: any) => {

    const { currentPoint } = useContext<any>(PointContext)
    const { setCurrentRef } = useContext<any>(RefContext)

    const [reportData, setReportData] = useState<any>()
    const FullScreenComp = FullScreen as any;
    const fullscreenhandle = useFullScreenHandle() as any;
    const [isFullScreenActive, setFullScreenActive] = useState(false);
    const [isFullScreenTableActive, setFullScreenTableActive] = useState(false);

    useEffect(() => {
        global.setUserLogged(true)
        setCurrentRef(mapRef)
    }, [])

    const fullscreenContainerHandler = () => {
        setFullScreenActive(!isFullScreenActive);
    };
    const fullscreenTableContainerHandler = () => {
        setFullScreenTableActive(!isFullScreenTableActive);
    };


    useEffect(() => {
        global.setLoading(true)
        agent
            .Reports
            .AusBoreData(currentPoint.id)
            .then(({ body }: any) => {
                setReportData(body)
            })
            .finally(() => {
                global.setLoading(false)
                return mapRef.current?.flyTo({
                    center: [
                        currentPoint.lng,
                        currentPoint.lat,
                    ],
                    essential: true,
                    zoom: 13,
                });
            })
    }, [currentPoint])


    const tableColumns = useMemo(
        () => [
            {
                Header: 'Bore Date',
                accessor: 'bore_date',
                Cell: (props: any) => formatDate(props.value),
            },
            {
                Header: 'Bore ID',
                accessor: 'bore_id',
            },
            {
                Header: 'Data Owner',
                accessor: 'data_owner',
            },
            {
                Header: 'Hydro Code',
                accessor: 'hydrocode',
            },
            {
                Header: 'Hydro ID',
                accessor: 'hydroid',
            },
            {
                Header: 'OBS Point Datum',
                accessor: 'obs_point_datum',
            },
            {
                Header: 'PK',
                accessor: 'pk',
            },
            {
                Header: 'Quality Flag',
                accessor: 'quality_flag',
            },
            {
                Header: 'Result',
                accessor: 'result',
            }
        ],
        []
    );

    const mapRef = useRef<any>()
    const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

    const [max, setMax] = useState(false)



    return (
        <FullScreenComp handle={fullscreenhandle}>
            <Col className='col-12 d-flex justify-content-center abh-container'>
                <ReportHeader
                    global={global}
                    data={reportData}
                    mapRef={mapRef}
                    reportID={"DischargeMonitoring-report"}
                    fullScreenClickHandle={fullscreenhandle.enter}
                />
                <Row>
                    <Col className='col-12 abh-map-container'>
                        {currentPoint && (
                            <Map
                                id='AusBoreHoles'
                                mapboxAccessToken={settings.maboxKey}
                                mapStyle={global.mapStyle}
                                preserveDrawingBuffer={true}
                                onClick={(e) => global.onMapClick(e)}
                                ref={mapRef}
                                projection={global.globeView ? 'globe' : 'mercator' as any}
                            >
                                <FullscreenControl />
                                <NavigationControl />
                                <GeolocateControl />



                                <RenderLayers global={global} />
                                <RenderLayerMarkers global={global} bounds={reportBounds} zipOff={true} />


                            </Map>
                        )}
                    </Col>
                </Row>
                <Row className={`table-row`}>
                    <Col className={`col-12 abh-table-container ${max ? 'expanded-abh' : ''}`}>
                        {reportData && reportData.length > 0 ? (
                            <>
                                <div className='minmax'>
                                    <FontAwesomeIcon onClick={() => setMax(!max)} icon={max ? faMinimize : faMaximize} />
                                </div>
                                <ReportTable
                                    customWidth={'90vw'}
                                    data={reportData}
                                    columns={tableColumns}
                                />
                            </>
                        ) : (
                            <p>...loading</p>
                        )}
                    </Col>
                </Row>
                <h4>Source: <a href={'http://www.bom.gov.au/water/groundwater/insight/#/bore/locationwater'} target={'_blank'}>Bore information - Water levels - Australian Groundwater Insight (bom.gov.au)</a></h4>
            </Col>
            <MapSlider global={global} />
        </FullScreenComp>
    )
}

export default AustralianBoreData