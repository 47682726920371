import { useEffect, useState, useContext } from 'react';
import { Popup } from 'react-map-gl';
import { useNavigate } from 'react-router-dom'
import { PointContext } from '../../../PointContext'
import { AppContext } from '../../../AppContext'
import { getCountryFromStorage } from '../../../features/utils'
import { uniqueValues } from '../../../features/reports/shared/report-data';
import agent from '../../../api/agent'
import {
    InfoWindow
} from '@react-google-maps/api';

const GooglePopup = (props: any) => {

    const { current, setCurrent, reportUrl, global } = props
    const { setCurrentPoint } = useContext(PointContext)
    const appContext = useContext(AppContext)
    const [open, setOpen] = useState<boolean>(false)
    const [reveal, setReveal] = useState<boolean>(false)

    const navigate = useNavigate()



    const updateContext = (lat: any, lng: any, city: any, report: any) => {

        return agent.Utilities.LatLngToHuc8(lat, lng)
            .then((res: any) => {
                const responseBody = res.body;
                const responseHUC8 = responseBody[0].Huc8;
                if (appContext.updateContext) {
                    appContext.updateContext(responseHUC8, city, global.currentReport, getCountryFromStorage());
                }

                if (report !== reportUrl[0]) {
                    if (res.body && res.body.length > 0 && responseHUC8) {
                        const urlPFASdw = `/PfasDrinkingWater?huc=${responseHUC8}&city=${encodeURI(city)}&fc=All&ffl=All&ldq=1&r=1`;
                        const urlUCMR = `/WhatsInMyDrinkingWater?huc=${responseHUC8}&city=${encodeURI(city)}&ffl=UCMR-3&ldq=1&fc=PFBS&fc=PFHpA&fc=PFHxS&fc=PFNA&fc=PFOA&fc=PFOS&r=1`;
                        const urlPFASenv = `/PfasEnvironmental?huc=${responseHUC8}&city=${encodeURI(city)}&ldq=1&fy=All&fc=All&r=1`;
                        const contaminateList = ['PFOS', 'PFOA', 'PFNA', 'PFHxS', 'PFHpA', 'PFBS'];
                        let reportURLDataAvailable = urlPFASdw;

                        if (report === 'UCMR') { reportURLDataAvailable = urlUCMR; }
                        if (report === 'PFAS Environmental') { reportURLDataAvailable = urlPFASenv; }

                        global.setLoading(false);
                        global.setUnregulatedReport(true);
                        navigate(reportURLDataAvailable);
                    }
                } else {
                    global.setLoading(false)
                    navigate(report)
                }
            })
    }

    const updateHuc8 = (lat: any, lng: any, report: any) => {
        global.setLoading(true)
        return agent.Utilities.LatLngToCity(lat, lng).then((data: any) => {
            // @ts-ignore
            const city = data.features === [] ? data.query : current.city
            updateContext(lat, lng, city, report)
        })
    }


    return (
        <>
            <InfoWindow
                position={{ lat: current.latitude, lng: current.longitude }}
            >
                <div id="dw-popup">
                    <div className="popup-card" onClick={() => setOpen(false)}>
                        <h3><strong>{current.city && `${current.city},`} {current.state}</strong></h3>
                        {current.zip && (<h4><strong>Zip Code:</strong> {current.zip}</h4>)}
                        <h4>Coordinates: {current.longitude ? current.longitude : current.lng}, {current.latitude ? current.latitude : current.lat}</h4>
                        {current.pounds && (<h4><strong>Pounds:</strong> {current.pounds}</h4>)}
                        {current.twpe && (<h4><strong>twpe:</strong> {current.twpe}</h4>)}
                        <a
                            className="popup-context-item-render-popup"
                            style={{ cursor: 'pointer', paddingRight: 7 }}
                            onClick={() => {
                                setCurrentPoint?.(current)
                                updateHuc8(
                                    current.latitude || current.lat,
                                    current.longitude || current.lng,
                                    reportUrl[0]
                                )
                            }}
                        >
                            Go To Regulated Report
                        </a>
                        <a
                            className="popup-context-item-render-popup"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setReveal(!reveal);
                            }}
                        >
                            Go to Unregulated Report
                        </a>
                        <ul style={(reveal) ? { display: 'inline-block' } : { display: 'none' }}>
                            <li className="popup-hover-popup">
                                <a
                                    className="popup-context-item-render-popup"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setCurrentPoint?.(current)
                                        updateHuc8(
                                            current.latitude || current.lat,
                                            current.longitude || current.lng,
                                            "PFAS Drinking Water"
                                        )
                                    }}
                                >
                                    PFAS Drinking Water
                                </a>
                            </li>
                            <li className="popup-hover-popup">
                                <a
                                    className="popup-context-item-render-popup"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setCurrentPoint?.(current)
                                        updateHuc8(
                                            current.latitude || current.lat,
                                            current.longitude || current.lng,
                                            "UCMR"
                                        )
                                    }}
                                >
                                    UCMR
                                </a>
                            </li>
                            <li className="popup-hover-popup">
                                <a
                                    className="popup-context-item-render-popup"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setCurrentPoint?.(current)
                                        updateHuc8(
                                            current.latitude || current.lat,
                                            current.longitude || current.lng,
                                            "PFAS Environmental"
                                        )
                                    }}
                                >
                                    PFAS Environmental
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </InfoWindow>
        </>
    )
}

export default GooglePopup