//@ts-nocheck
import React, { useState } from 'react';
import { Form, Button, ListGroup } from 'react-bootstrap';
import './add-profile.scss';
import InputForm from '../../components/input-form';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import SettingsBreadcrumb from '../account/SettingsBreadcrumb';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faGreaterThan, faGear, faCancel, faRectangleXmark, faRotate, faTrashCan, faList, faTableCellsLarge } from '@fortawesome/pro-solid-svg-icons';
import { faUser, faUsers, faSliders, faBuilding, faTarpDroplet, faHandHoldingDroplet,  faDraftingCompass, faArrowUpFromGroundWater,  faMagnifyingGlassLocation, faGlobe, faHouse, faSeedling, faDroplet } from '@fortawesome/pro-solid-svg-icons';


export default function AddProfile(props: any){
	const { global } = props;
	global.setUserLogged(true);
	global.setUserLogged(true);
	const location = useLocation();
	const navigate = useNavigate();

    const settingsConfiguration = [] as any;
    let reportCollection = ['Water Statistics & Measures', "Climate and Economic Justice Screening Tool", 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 1, title: 'Researcher', description: 'Includes:', ic: faMagnifyingGlassLocation, reportCollection: reportCollection })
    
    reportCollection = ['What’s in my Drinking Water?', 'Drinking Water True QI Report', 'Water Statistics & Measures', "Climate and Economic Justice Screening Tool", 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 10, title: 'Regulator', description: 'Includes:', ic: faSliders, reportCollection: reportCollection })
    
    reportCollection = ['Permit Discharge Report', 'Drinking Water True QI Report', 'Water Statistics & Measures', "Climate and Economic Justice Screening Tool", 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 20, title: 'Operator', description: 'Includes:', ic: faArrowUpFromGroundWater, reportCollection: reportCollection })
    
    reportCollection = ['AG Water True QI Forecast', "Climate and Economic Justice Screening Tool", 'What’s in my Drinking Water?', 'Drinking Water True QI Report', 'Water Statistics & Measures', 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 30, title: 'Agriculture Manager', description: 'Includes:', ic: faSeedling, reportCollection: reportCollection })

    reportCollection = ['AG Water True QI Forecast', "Climate and Economic Justice Screening Tool", 'What’s in my Drinking Water?', 'Drinking Water True QI Report', 'Water Statistics & Measures', 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 40, title: 'Commericial Manager', description: 'Includes:', ic: faBuilding, reportCollection: reportCollection })

    reportCollection = ['AG Water True QI Forecast', "Climate and Economic Justice Screening Tool", 'What’s in my Drinking Water?', 'Drinking Water True QI Report', 'Water Statistics & Measures', 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 50, title: 'Planning Engineer', description: 'Includes:', ic: faDraftingCompass, reportCollection: reportCollection })
   
    reportCollection = ['AG Water True QI Forecast', "Climate and Economic Justice Screening Tool", 'What’s in my Drinking Water?', 'Drinking Water True QI Report', 'Water Statistics & Measures', 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 70, title: 'Starter Profile', description: 'Includes:', ic: faDroplet, reportCollection: reportCollection })

    reportCollection = ['AG Water True QI Forecast', "Climate and Economic Justice Screening Tool", 'What’s in my Drinking Water?', 'Drinking Water True QI Report', 'Water Statistics & Measures', 'Surface Water Flow Rate', 'Groundwater Levels']
    settingsConfiguration.push({id: 80, title: 'Citizen Waterkeeper', description: 'Includes:', ic: faHandHoldingDroplet, reportCollection: reportCollection })


	let fullreportCollection = ['AG Water True QI Forecast', 'What’s in my Drinking Water?', 'Drinking Water True QI Report', 'Water Statistics & Measures', 'Surface Water Flow Rate', 'Groundwater Levels']
	let profilesCollection = [];

	let templates = settingsConfiguration;

	let _mode = 'Add';
	let _name = '';
	if(location && location.state){
		if(location.state.user && location.state.user.title){
			_name = location.state.user.title;
			reportCollection = location.state.user.reportCollection;
		}

		if(location.state.settingsConfiguration){
			profilesCollection = location.state.settingsConfiguration;
		}

		if(location.state.mode === 'edit'){
			_mode = 'Edit';
		}
	}

	const [ mode, setMode ] = useState(_mode);

	const [ name, setName ] = useState(_name);
	const [ addReport, setAddReport ] = useState('');
	const [reportData, setReportData] = useState<any[]>(reportCollection);

	const [ listaActive, setListaActive ] = useState();

    const onChangeSearch = (event: any) => {
        if (event && event.target && event.target.value && event.target.value.length > 2) {
            let fData = [...profilesCollection].filter((o: any) => o.title.indexOf(event.target.value) > -1);
			setFilteredData(fData);
        } else {
            setFilteredData(reportData);
        }       
    };

	const clickTemplateHandler = (event: any) => {
        if (event && event.target && event.target.value) {
            let fData = [...profilesCollection].filter((o: any) => o.title  === event.target.value);
			if(fData && fData.length > 0){
				reportCollection = fData[0].reportCollection;
				setReportData(fData[0].reportCollection);
			}
        }  
    };

	const onClickList = (event: any) => {
		setListaActive(event.target.value);
    };

	const clickCancelHandler = (event: any) => {
        navigate('/Users');
    };

	const clickAddHandler = (event: any) => {
        navigate('/Users');			
	}

	const renderTemplates = () => {
		return settingsConfiguration.map(template => {
		  if(template.title){
		  	return <option key={`profile-template-${template.title}`}>{template.title}</option>
		  }
		})
	  }

	const renderReports = () => {
		//{reportData.join()}
		return reportData.map(template => {
			if(template){
			return <ListGroup.Item key={`profile-reports-${template}`}>{template}</ListGroup.Item>
			}
		})
	}

	return (
		<div className='add-profile'>
			<SettingsBreadcrumb title={'User Management'}  subtitle={mode + ' Profile'}  />
			<h2>{mode} Profile</h2>
			<div className='container form'>
				<InputForm placeholder='Name' setValue={setName} value={name} type='text'/>

				<h4>Template</h4>
				<p>You can pick a template to pre-populate the reports of others profiles</p>
				<Form.Select placeholder='Select'   onClick={clickTemplateHandler}>
					{renderTemplates()}
				</Form.Select>
				<h4>Add</h4>
				<InputForm placeholder='Add'  onChange={onChangeSearch}  setValue={setAddReport} value={addReport} type='text' className='search-right'/>
				{/*<Button className='btn-secundary'>Add</Button>*/}
				<h4>Current Reports</h4>					
				<ListGroup  className='profile-reports-list' key={'profile-reports-list' + reportData.length}>
					{renderReports()}
				</ListGroup>
				<div className='footer'>
					<Button variant='secondary'  onClick={clickCancelHandler}>Cancel</Button>
					<Button variant='primary'  onClick={clickAddHandler}>Save Changes</Button>
				</div>
			</div>

		</div>
	);
}