// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reports-sidebar .dropdown-menu.show {
  width: 420px; }

.reports-sidebar .report-link-disabled {
  color: gray; }
  .reports-sidebar .report-link-disabled:hover {
    color: gray !important; }
`, "",{"version":3,"sources":["webpack://./src/layout/Reports/styles.scss"],"names":[],"mappings":"AAAA;EAEE,YAAY,EAAA;;AAFd;EAME,WAAW,EAAA;EANb;IASG,sBAAsB,EAAA","sourcesContent":[".reports-sidebar{\n\t.dropdown-menu.show{\n\t\twidth: 420px;\n\t}\n\n\t.report-link-disabled {\n\t\tcolor: gray;\n\n\t\t&:hover{\n\t\t\tcolor: gray !important;\t\t\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
