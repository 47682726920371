import React from 'react';
import { SpinnerCircular } from 'spinners-react';

import './LoadingSpinner.scss';

const LoadingSpinner = (props: any) => {
  const { active, style } = props;

  return (
    <div
      className='loading-spinner'
      style={{ display: active ? 'flex' : 'none', ...style }}
    >
      <SpinnerCircular />
    </div>
  );
};

export default LoadingSpinner;
