// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#LiveWaterSensors-report .source-text {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.25rem;
  color: white; }
  #LiveWaterSensors-report .source-text h3,
  #LiveWaterSensors-report .source-text a {
    font-size: 1.25rem; }
  #LiveWaterSensors-report .source-text a {
    color: #4fc32c;
    text-decoration: none; }

#LiveWaterSensors-report .lws-table-container {
  background-color: #0b1736;
  margin-top: 35px;
  border-radius: 12px;
  box-shadow: 0px 0px 20px #000;
  min-height: 22rem; }

#LiveWaterSensors-report #mapLegendAccordion {
  display: block;
  right: 8%; }
`, "",{"version":3,"sources":["webpack://./src/features/reports/LiveWaterSensors/source.scss"],"names":[],"mappings":"AAAA;EAEI,aAAa;EACb,yBAAyB;EACzB,WAAW;EACX,mBAAmB;EACnB,YAAY,EAAA;EANhB;;IASM,kBAAkB,EAAA;EATxB;IAYM,cAAc;IACd,qBAAqB,EAAA;;AAb3B;EAkBI,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;EACnB,6BAA6B;EAC7B,iBAAiB,EAAA;;AAtBrB;EA0BI,cAAc;EACd,SAAS,EAAA","sourcesContent":["#LiveWaterSensors-report {\n  .source-text {\n    display: flex;\n    justify-content: flex-end;\n    width: 100%;\n    margin-top: 1.25rem;\n    color: white;\n    h3,\n    a {\n      font-size: 1.25rem;\n    }\n    a {\n      color: #4fc32c;\n      text-decoration: none;\n    }\n  }\n\n  .lws-table-container {\n    background-color: #0b1736;\n    margin-top: 35px;\n    border-radius: 12px;\n    box-shadow: 0px 0px 20px #000;\n    min-height: 22rem;\n  }\n\n  #mapLegendAccordion {\n    display: block;\n    right: 8%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
