import {
    Route,
    Routes,
    Navigate,
    useLocation
} from 'react-router-dom';
import { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Form
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBuilding,
    faLocation,
    faLaptop,
    faFileExport,
    faPaperclip,
    faHistory,
    faUser,
    faGears,
    faGear,
    faSquare
} from '@fortawesome/pro-solid-svg-icons';
import agent from '../../../api/agent';
import localForage from "localforage";
import queryString from 'query-string';
import {
  QueryParamProvider,
  useQueryParam,
  BooleanParam,
  StringParam
} from 'use-query-params';
import { useNavigate } from 'react-router';

import { renderRestrictions } from '../../utils';
import { getOrg, getWQX, getAllOrg, orgChange, deleteWQX } from './lib/data';
import * as store from './lib/store';
import Home from './Home'
import WaterQuality from './WaterQuality'
import ReferenceData from './ReferenceData'
import Administration from './Administration'
import Help from './Help'

import WaterMonitoringReport from './WaterMonitoringReport';

import Organizations from './sidebar-menu/Organizations'
import OrganizationInfo from './WaterQuality/OrganizationInfo'
import MonitoringLocs from './sidebar-menu/MonitoringLocs'
import Projects from './sidebar-menu/Projects'
import Activities from './sidebar-menu/Activities'
import AddActivities from './AddActivities'
import Imports from './sidebar-menu/Imports'
import Import from './sidebar-menu/Import'
import ImportII from './sidebar-menu/ImportII';
import ImportDetails from './sidebar-menu/ImportDetails'
import GlobalImportRules from './GlobalImportRules';
import WQXHistory from './sidebar-menu/WQXHistory'
import UserManagement from './sidebar-menu/UserManagement'
import RollManagement from './sidebar-menu/RollManagement'
import AppSettings from './sidebar-menu/AppSettings'
import TaskManagement from './sidebar-menu/TaskManagement'
import EditMonitoringLocation from './sidebar-menu/EditMonitoringLocation'

import Modal from './Modal'
import CharacteristicModal from './CharacteristicModal';
import TranslationModal from './TranslationModal';

import { parse, unparse } from "papaparse";
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import * as d3Scale from 'd3-scale';

import './water-monitoring.scss'

const Insights = (props: any) => {

    const [home, setHome] = useState(true)
    const [report, setReport] = useState(false)
    const [waterQuality, setWaterQuality] = useState(false)
    const [referenceData, setReferenceData] = useState(false)
    const [administration, setAdministration] = useState(false)
    const [help, setHelp] = useState(false)

    const [organizations, setOrganizations] = useState(false)
    const [projects, setProjects] = useState(false)
    const [activities, setActivities] = useState(false)
    const [importAction, setImportAction] = useState(false)
    const [importActionII, setImportActionII] = useState(false)
    const [wqxHistory, setWQXHistory] = useState(false)
    const [addActivities, setAddActivities] = useState(false)
    const [importDetails, setImportDetails] = useState(false)
    const [userManagement, setUserManagement] = useState(false)
    const [adminMenu, setAdminMenu] = useState(false)
    const [editRoll, setEditRoll] = useState(false)
    const [appSettings, setAppSettings] = useState(false)
    const [taskManagement, setTaskManagement] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [modalList, setModalList] = useState()

    const [editMonitoringLocation, setEditMonitoringLocation] = useState(false)
    const [addMonitoringLocation, setAddMonitoringLocation] = useState(false)
    const [monitoringLocs, setMonitoringLocs] = useState(false)

    const [globalImportRules, setGlobalImportRules] = useState(false)

    const [characteristicModal, setCharacteristicModal] = useState(false)
    const [translationModal, setTranslationModal] = useState(false)

    const [action, setAction] = useState('table')
    const [data, setData] = useState()

    const [orgData, setOrgData] = useState<any[]>([]);
        
    const [usersData, setUsersData] = useState<any[]>([]); 
    const [usersDataAssigned, setUsersDataAssigned] = useState<any[]>([]); 
    const [rolesData, setRolesData] = useState<any[]>([]); 
    const [tasksData, setTasksData] = useState<any[]>([]); 

    const [mLocsData, setMLOCSData] = useState<any[]>([]); 
    const [projectsData, setProjectsData] = useState<any[]>([]); 
    const [activitiesData, setActivitiesData] = useState<any[]>([]); 
    const [resultsData, setResultsData] = useState<any[]>([]); 
    const [samplesData, setSamplesData] = useState<any[]>([]); 

    const [importText, setImportText] = useState<any[]>([]); 
    const [importData, setImportData] = useState<any[]>([]); 
    const [timeZone, setTimeZone] = useState('Central');
    const [importTaxa, setImportTaxa] = useState<any[]>([]); 
    const [importChar, setImportChar] = useState<any[]>([]); 
    const [importTranslation, setImportTranslation] = useState<any[]>([]); 

    const [settings, setSettings] = useState({});
    const [settingsData, setSettingsData] = useState<any[]>([]); 
    const [noEPA, setNoEPA] = useState(false); 
    const [wqxCredentials, setWqxCredentials] = useState<any[]>([]); 
    const [orgCredentialsType, setOrgCredentialsType] = useState('te'); // self, te, none
    const [wqxData, setWqxData] = useState<any[]>([]); 

    const { global } = props
    const navigate = useNavigate();
    const [currentOrg, setCurrentOrg] = useState(global.userOrg || 0);

    const [qwm, setqwm] = useQueryParam('wm', StringParam);

    const [samplingData, setSamplingData] = useState<any[]>([]); 

    const mediaQuery = window.matchMedia('(max-width: 877px)');

    useEffect(() => {
        global.setUserLogged(true);

/*        if(!renderRestrictions(global.userOrgString, 'Field Water Data Monitor', global)){
            navigate('/Splash' + window.location.search); //'/ContactAdmin' 
        }*/
    }, [])

    useEffect(() => {
        if(!qwm){
          //set
        } else if(qwm==='th'){
          navigateHome();
        } else if(qwm==='tq'){
          navigateWaterQuality();
        } else if(qwm==='tr'){
          navigateWaterMonitoringReport();
        } else if(qwm==='td'){
          navigateReferenceData();
        } else if(qwm==='ta'){
          navigateAdministration();
        } else if(qwm==='tp'){
          navigateHelp();
        }
     }, [qwm]);

    useEffect(() => {
        store.getWQXStore(setWqxData);

        //settings
        const timezone = store.getTimeZoneStore(setTimeZone);
        store.getImportTaxaStore(setImportTaxa);
        store.getImportCharStore(setImportChar);
        store.getImportTranslationStore(setImportTranslation);

        store.getOrg_credentials(setOrgCredentialsType)             
    }, [])

    let SettingsData_Default = [{
            id: '1',
            settingName: 'Default EPA Org ID',
            settingValue: '',
            actions: '12345'
        },{
            id: '2',
            settingName: 'CDX Submitter',
            settingValue: '',
            actions: '12345'
        }, {
            id: '3',
            settingName: 'CDX Submitter Password',
            settingValue: '',
            actions: '12345'
        }, {
            id: '13',
            settingName: 'No Submissions to EPA',
            settingValue: 'false',
            actions: '12345'
        }
    ]






  useEffect(() => {




  }, []);



    // Load Settings
    useEffect(() => {
          localForage.getItem('wm_GeneralApplicationSettings', function(err, value) {
            if(err){
              console.log(err)
              setSettingsData(SettingsData_Default)
            }
            //@ts-ignore
            if(value && value.length>0){
              setSettingsData(value as Array<any>)
            } else {
              setSettingsData(SettingsData_Default)
            }
         })   
    }, [])

    // User changes persistence
    useEffect(() => {
        if(importTaxa && importTaxa.length > 0){
            store.setImportTaxaStore(importTaxa);
        }
    }, [importTaxa])

    useEffect(() => {
        if(importChar && importChar.length > 0){
            store.setImportCharStore(importChar);
        }
    }, [importChar])

    useEffect(() => {
        if(importTranslation && importTranslation.length > 0){
            store.setImportTranslationStore(importTranslation);
        }
    }, [importTranslation])

    useEffect(() => {
        if(orgCredentialsType){
            store.setOrg_credentials(orgCredentialsType)
        }
    }, [orgCredentialsType])

    useEffect(() => {
        if(settingsData){
            store.setGeneralApplicationSettingsStore(settingsData);
        }
    }, [settingsData])

    //localStorage.setItem('useremail', global.userInfo.email);

    const setAllOrgs = (orgdata:any) => {
        console.log(orgdata)
        if (orgdata) {
            setOrgData(orgdata)
           // getOrg(global.userOrg, setOrgData, setUsersData);  
        }
    };


    // Get Organizations linked to logged in User
    useEffect(() => {
        //console.log('global.userOrg', global.userOrg, global.userRole, global)

        let flagAll = false;
        if(global.userInfo && global.userInfo.email){
            const address = global.userInfo.email.split('@');
            if(address && address.length > 1 && address[1] === 'truelements.com'){
                flagAll = true;
            }
        }

        if (global.userOrg && global.userOrg > -1){
            if((global.userRole === 'te-admin')||(flagAll)){
                getAllOrg(global.userOrg, setAllOrgs, setUsersData, currentOrg);
            } else {
                getOrg(global.userOrg, setOrgData, setUsersData);    
            }           
        }
    }, [global.userOrg])

    const loadSamplingData  = () => {
        let statesFileURL = "/temp/wiw2022.csv";
        fetch(statesFileURL)
          .then((response) => response.text())
          .then((responseText) => {
            const datawiw2022: any[] = parse(responseText, {
              header: true,
            }).data;
            console.log('wiw2022', datawiw2022)
            setSamplingData(datawiw2022);
        });
    }



    useEffect(() => {
        console.log('currentOrg', currentOrg)
        if (currentOrg  && orgData){
            orgChange(currentOrg, orgData, setOrgData, setUsersData); 
            if(+currentOrg === 14){
                loadSamplingData();
            }             
        }
    }, [currentOrg])

    const pullWQX = (orgdata:any) => {
        console.log(orgdata)
        if(orgdata && orgdata.length>0){
            let epaOrg = 'WIDNR_WQX';
            //console.log(orgData[0])

            let queryFind = orgdata.filter((item: any) => {
                         return +item.org_id === +currentOrg;});
            if(queryFind && queryFind.length>0){
               if(queryFind[0].epa_org_id){
                    epaOrg = queryFind[0].epa_org_id;
               }             
            }

/*            if(orgData[0].epa_org_id){
                epaOrg = orgData[0].epa_org_id;
            }*/
            console.log(epaOrg)
            getWQX(epaOrg, setWqxData);       //+++ Testing

           /* setTimeout(() => {
                getWQX('WIDNR_WQX', additionalWQX); 
            }
            , 4444);*/

        }
    };

    useEffect(() => {   
        console.log('orgData', orgData, currentOrg)
        pullWQX(orgData)
    }, [orgData])

    const additionalWQX = (addwqxdata:any) => {
        console.log(addwqxdata)
        if (addwqxdata) {
            let newWQX = [...wqxData].concat(addwqxdata)
            setWqxData(newWQX)
            //console.log(addwqxdata, wqxData)   
        }
    };


    useEffect(() => {
        if(usersData && usersData.length>0 && global.userOrg){
            if(global.userRole == 'te-admin'){
                setUsersDataAssigned(usersData)
            } else {
                const usersAssigned = localStorage.getItem('wm_usersassigned' + global.userOrg);      
                if(!usersAssigned){
                    //console.log('unassigned')
                    localStorage.setItem('wm_usersassigned' + global.userOrg, JSON.stringify(usersData));
                } else {
                    const usersArray = JSON.parse(usersAssigned);
                    setUsersDataAssigned(usersArray)
                }               
            }
        }
    }, [usersData])

    useEffect(() => {
        if(!wqxCredentials){
            const settings_cdxuser = localStorage.getItem('wm_settings_cdxuser');
            const settings_cdxpass = localStorage.getItem('wm_settings_cdxpass');
            console.log(settings_cdxuser, settings_cdxpass)
            let cdxuser = settings_cdxuser || 'WQXTEST';
            if(!settings_cdxuser){
                localStorage.setItem('wm_settings_cdxuser', 'WQXTEST');
            } 
            let cdxpass = settings_cdxpass || 'WQXTEST';
            if(!settings_cdxpass){
                localStorage.setItem('wm_settings_cdxpass', 'WQXTEST');
            } 
            setWqxCredentials([cdxuser, cdxpass]);
        }
    }, [])

    useEffect(() => {     
        if(!rolesData || rolesData.length<1){
            let roles = [];
            const wm_roles = localStorage.getItem('wm_roles');
            if(wm_roles){
               roles = JSON.parse(wm_roles);
            } else {
               roles = [{id:1, 'name' : 'admin', 'description' : 'Administration', active: true}, {id: 2, 'name' : 'user', 'description' : 'General User', active: true}];
            }
            setRolesData(roles);
        }
    }, [])

/*    useEffect(() => {
        console.log('usersDataAssigned', global.userOrg, usersDataAssigned)
       
    }, [usersDataAssigned])*/

    useEffect(() => {
        if(wqxData && wqxData.length>0){          
            const mLocs = [];
            const projs = [];
            const actvs = [];
            const results = [];
            const samples = [];
            for (let dataItem of wqxData) {
                if(dataItem['MonitoringLocIdent_MonitoringLocationIdentifier']||dataItem['MonitoringLocIdent_MonitoringLocationName']){
                      mLocs.push(dataItem)          
                }
                if(dataItem['PROJDescription_ProjectIdentifier']||dataItem['PROJDescription_ProjectName']){
                      projs.push(dataItem)          
                }
                if(dataItem['ACTIVITYDesc_ActivityIdentifier']){
                      actvs.push(dataItem)          
                }
                if(dataItem['ResultDescription_CharacteristicName']){
                      results.push(dataItem)          
                }
                if(dataItem['SAMPLEDescription_MethodIdentifier']){
                      samples.push(dataItem)          
                }
            }
            setMLOCSData(mLocs);
            setProjectsData(projs);
            setActivitiesData(actvs);
            setResultsData(results)
            setSamplesData(results)
            localForage.setItem("wm_wqx", wqxData);
        } else {
            setMLOCSData([]);
            setProjectsData([]);
            setActivitiesData([]);
            setResultsData([])
            setSamplesData([])
        }
    }, [wqxData])

    const refreshWQX = () => {
        if (wqxData) {
           // getWQX('WQXTEST', setWqxData);
            pullWQX(orgData)    
        }
    };

    useEffect(() => {
        //const newData = [{taskid: 1, userid:1, username:'mcs12', orgid: 1, orgname:'TRUEELEMENTS', created: null, status: null}]
        //setTasksData(newData);
    }, [])

    /*const getWQXResult = (result: any) => {
        setWqxData(result);
    };*/

    const onChangeOrg = (event: any) => {
        console.log(event, event.target, event.target.value)
        if (event && event.target && event.target.value) {
            setCurrentOrg(event.target.value);    
        }
    };

    const navigateHome = () => {
        setHome(true);
        setWaterQuality(false)
        setReferenceData(false)
        setAdministration(false)
        setHelp(false);
        setReport(false);
        setVisible('topmenu', 'Home');
    }

    const navigateWaterMonitoringReport = () => {
        setHome(false);
        setWaterQuality(false)
        setReferenceData(false)
        setAdministration(false)
        setHelp(false);
        setReport(true);
        setVisible('topmenu', 'Report');
    }

    const navigateWaterQuality = () => {
        setHome(false);
        setWaterQuality(true)
        setReferenceData(false)
        setAdministration(false)
        setHelp(false);
        setReport(false);
        setVisible('topmenu', 'Water Quality');
    }

    const navigateReferenceData = () => {
        setHome(false);
        setWaterQuality(false)
        setReferenceData(true)
        setAdministration(false)
        setHelp(false);
        setReport(false);
        setVisible('topmenu', 'Reference Data');
    }

    const navigateAdministration = () => {
        setHome(false);
        setWaterQuality(false)
        setReferenceData(false)
        setAdministration(true)
        setHelp(false);
        setReport(false);
        setVisible('topmenu', 'Administration');
    }

    const navigateHelp = () => {
        setHome(false);
        setWaterQuality(false)
        setReferenceData(false)
        setAdministration(false)
        setHelp(true);
        setReport(false);
        setVisible('topmenu', 'Help');
    }

    const setVisible = (path: any, selection: any) => {
        if(path==='topmenu'){
            const adminmenu =  (selection === 'Administration') ? true : false;
           /* setHome(false);
            setWaterQuality(false)
            setReferenceData(false)
            setAdministration(false)
            setHelp(true);
            setReport(false)
            */
            setAdminMenu(adminmenu)
            setOrganizations(false)
            setMonitoringLocs(false)
            setProjects(false)
            setActivities(false)
            setImportAction(false)
            setWQXHistory(false)
            setUserManagement(false)
            setEditRoll(false)
            setAppSettings(false)
            setTaskManagement(false)

            setAddActivities(false)           
            setImportDetails(false)
            setImportActionII(false)
            setEditMonitoringLocation(false)
            setModalOpen(false)
            setAddMonitoringLocation(false)
            setGlobalImportRules(false)
            setTranslationModal(false)
        } else if(path==='workflow'){
            setHome(false);
            setWaterQuality(false)
            setReferenceData(false)
            setAdministration(false)
            setHelp(false);
            setAdminMenu(false)
            /*setOrganizations(true)
            setMonitoringLocs(false)
            setProjects(false)
            setActivities(false)
            setImportAction(false)
            setWQXHistory(false)*/
            setUserManagement(false)
            setEditRoll(false)
            setAppSettings(false)
            setTaskManagement(false)               

            setAddActivities(false)      
            setImportDetails(false)
            setImportActionII(false)          
            setEditMonitoringLocation(false)
            setModalOpen(false)
            setAddMonitoringLocation(false)
            setGlobalImportRules(false)
            setTranslationModal(false)
        } else if(path==='admin'){
            setHome(false);
            setWaterQuality(false)
            setReferenceData(false)
            //setAdministration(false)
            setHelp(false);
            setOrganizations(false)          
            setMonitoringLocs(false)
            setProjects(false)
            setActivities(false)
            setImportAction(false)
            setWQXHistory(false)
            /* setUserManagement(false)
            setEditRoll(false)
            setAppSettings(false)
            setTaskManagement(false) */   

            setAddActivities(false)
            setImportDetails(false)
            setImportActionII(false)
            setEditMonitoringLocation(false)
            setModalOpen(false)
            setAddMonitoringLocation(false)
            setGlobalImportRules(false)
            setTranslationModal(false)
        }
    }

    return (
        <div className={`outer-dashboard-container ${mediaQuery.matches && 'outer-dashboard-container-sm'}`}>
            {!home && !referenceData && !help && !report && (
                <Col className={`col-2 mt-5 sidebar-left`} >
                    {!adminMenu ? (
                        <ul className="sidebar-list">
                            <li onClick={() => {
                                setOrganizations(true)                        
                                setMonitoringLocs(false)
                                setProjects(false)
                                setActivities(false)
                                setImportAction(false)
                                setWQXHistory(false)
                                setVisible('workflow', 'Organizations');
                            }}  className={`sidebar-item ${organizations && 'sidebar-item-active'} `}>
                                <FontAwesomeIcon className="list-icon" style={{ color: organizations ? '#4fc2d9' : 'white' }} icon={faBuilding} />
                                Organizations
                            </li>
                            <li onClick={() => {
                                setOrganizations(false)
                                setMonitoringLocs(true)
                                setProjects(false)
                                setActivities(false)
                                setImportAction(false)
                                setWQXHistory(false)
                                setVisible('workflow', 'Monitoring Locs');
                            }} className={monitoringLocs ? 'sidebar-item sidebar-item-active' : 'sidebar-item'}>
                                <FontAwesomeIcon className="list-icon" style={{ color: monitoringLocs ? '#4fc2d9' : 'white' }} icon={faLocation} />
                                <span>Monitoring Locs</span>
                            </li>
                            <li onClick={() => {
                                setOrganizations(false)
                                setMonitoringLocs(false)
                                setProjects(true)
                                setActivities(false)
                                setImportAction(false)
                                setWQXHistory(false)
                                setVisible('workflow', 'Projects');
                            }} className={projects ? 'sidebar-item sidebar-item-active' : 'sidebar-item'}>
                                <FontAwesomeIcon className="list-icon" style={{ color: projects ? '#4fc2d9' : 'white' }} icon={faPaperclip} />
                                Projects
                            </li>
                            <li onClick={() => {
                                setOrganizations(false)
                                setMonitoringLocs(false)
                                setProjects(false)
                                setActivities(true)
                                setImportAction(false)    
                                setWQXHistory(false)
                                setVisible('workflow', 'Activities');
                            }} className={activities ? 'sidebar-item sidebar-item-active' : 'sidebar-item'}>
                                <FontAwesomeIcon className="list-icon" style={{ color: activities ? '#4fc2d9' : 'white' }} icon={faLaptop} />
                                Activities
                            </li>
                            <li onClick={() => {
                                setOrganizations(false)
                                setMonitoringLocs(false)
                                setProjects(false)
                                setActivities(false)
                                setImportAction(true)    
                                setWQXHistory(false)
                                setVisible('workflow', 'Import');
                            }} className={importAction ? 'sidebar-item sidebar-item-active' : 'sidebar-item'}>
                                <FontAwesomeIcon className="list-icon" style={{ color: importAction ? '#4fc2d9' : 'white' }} icon={faFileExport} />
                                Import
                            </li>
                            <li onClick={() => {
                                setOrganizations(false)
                                setMonitoringLocs(false)
                                setProjects(false)
                                setActivities(false)
                                setAddActivities(false)
                                setImportAction(false)
                                setWQXHistory(true)
                                setVisible('workflow', 'WQX History');
                            }} className={wqxHistory ? 'sidebar-item sidebar-item-active' : 'sidebar-item'}>
                                <FontAwesomeIcon className="list-icon" style={{ color: wqxHistory ? '#4fc2d9' : 'white' }} icon={faHistory} />
                                <span>WQX History</span>
                            </li>
                        </ul>
                    )
                        :
                        (
                            <ul className="sidebar-list">
                                <li onClick={() => {
                                    setUserManagement(true)
                                    setEditRoll(false)
                                    setAppSettings(false)
                                    setAdministration(false)
                                    setTaskManagement(false)
                                    setVisible('admin', 'User Management');
                                }} className={userManagement ? 'sidebar-item sidebar-item-active' : 'sidebar-item'}>
                                    <FontAwesomeIcon className="list-icon" style={{ color: organizations ? '#4fc2d9' : 'white' }} icon={faUser} />
                                    <span>User Management</span>
                                </li>
                                <li onClick={() => {
                                    setUserManagement(false)
                                    setEditRoll(true)
                                    setAppSettings(false)
                                    setAdministration(false)
                                    setTaskManagement(false)
                                    setVisible('admin', 'Role Management');
                                }} className={editRoll ? 'sidebar-item sidebar-item-active' : 'sidebar-item'}>
                                    <FontAwesomeIcon className="list-icon" style={{ color: monitoringLocs ? '#4fc2d9' : 'white' }} icon={faGears} />
                                    <span>Role Management</span>
                                </li>
                                <li onClick={() => {
                                    setUserManagement(false)
                                    setEditRoll(false)
                                    setAppSettings(false)
                                    setAdministration(true)
                                    setTaskManagement(false)
                                    setVisible('admin', 'App Settings');
                                }} className={administration ? 'sidebar-item sidebar-item-active' : 'sidebar-item'}>
                                    <FontAwesomeIcon className="list-icon" style={{ color: projects ? '#4fc2d9' : 'white' }} icon={faGear} />
                                    <span>App Settings</span>
                                </li>
{/*                                <li onClick={() => {
                                    setUserManagement(false)
                                    setEditRoll(false)
                                    setAppSettings(false)
                                    setAdministration(false)
                                    setTaskManagement(true)
                                    setVisible('admin', 'Task Management');
                                }} className={taskManagement ? 'sidebar-item sidebar-item-active' : 'sidebar-item'}>
                                    <FontAwesomeIcon className="list-icon" style={{ color: activities ? '#4fc2d9' : 'white' }} icon={faSquare} />
                                    Task Management
                                </li>*/}
                            </ul>
                        )
                    }
                </Col>
            )}
            {/* @ts-ignore */}
            <Col style={{ width: home && '100%' }} className="col-10 insight-navbar">
                <ul className="navbar-list">
                    <li onClick={() => {setqwm('th')}} className={home ? 'navbar-item active' : 'navbar-item'}>Home</li>
                    <li onClick={() => {setqwm('tq')}} className={waterQuality ? 'navbar-item active' : 'navbar-item'}>Water Quality</li>
                    <li onClick={() => {setqwm('tr')}} className={report ? 'navbar-item active' : 'navbar-item'}>Monitoring Report</li>
                    <li onClick={() => {setqwm('td')}} className={referenceData ? 'navbar-item active' : 'navbar-item'}>Reference Data</li>
                    <li onClick={() => {setqwm('ta')}} className={administration || userManagement ? 'navbar-item active' : 'navbar-item'}>Administration</li>
                    <li onClick={() => {setqwm('tp')}} className={help ? 'navbar-item active' : 'navbar-item'}>Help</li>
                </ul>
                <div className='wm-org-title-bar'>
                    <Form.Group className="m-1 wm-org-title-bar-form" controlId="formOrg">
                        <Form.Label>Organization:</Form.Label>
                        {/* @ts-ignore */}
                        <Form.Select id='wm-organizations' key={'wm-org-'+'1' + orgData.length}  onChange={onChangeOrg} value={currentOrg} className='wm-organizations-select' size="sm" placeholder='Organizations'>
                            {orgData  && orgData.sort((x: any, y: any) => x["name"].localeCompare(y["name"])).map((dash, index) =>
                                (dash.name && <option key={'wm-org-options'+index} value={dash.org_id}>{dash.name}</option>)
                            )} 
                        </Form.Select>
                    </Form.Group>
                </div>
            </Col>
            <Col>
                <div className="inner-dashboard-container">
                    {home && <Home
                        home={home}
                        setHome={setHome}
                        setWaterQuality={setWaterQuality}
                        setReferenceData={setReferenceData}
                        setAdministration={setAdministration}
                        setHelp={setHelp}
                        setReport={setReport}
                        action={action}
                        setAction={setAction}
                        orgData={orgData}
                        setOrgData={setOrgData}
                        setMonitoringLocs={setMonitoringLocs}
                        setImportAction={setImportAction}
                        setProjects={setProjects}
                        setUserManagement={setUserManagement}
                        setOrganizations={setOrganizations}
                        setAddActivities={setAddActivities}
                        setActivities={setActivities}
                        setWQXHistory={setWQXHistory}
                        setImportDetails={setImportDetails}
                        setImportActionII={setImportActionII}
                        setEditRoll={setEditRoll}
                        setAppSettings={setAppSettings}
                        setTaskManagement={setTaskManagement}
                        setEditMonitoringLocation={setEditMonitoringLocation}
                        setModalOpen={setModalOpen}
                        activitiesData={activitiesData}
                        mLocsData={mLocsData}
                        projectsData={projectsData}
                        resultsData={resultsData}
                        setTasksData={setTasksData}
                        tasksData={tasksData}
                    />}
                    {(waterQuality || organizations) && <WaterQuality
                        key={'wm-wq' + currentOrg}
                        global={global}
                        action={action}
                        setAction={setAction}
                        data={orgData}
                        orgData={orgData}
                        setOrgData={setOrgData}                        
                        usersData={usersData}
                        usersDataAssigned={usersDataAssigned}
                        setUsersDataAssigned={setUsersDataAssigned}
                        currentOrg={currentOrg}
                        setCurrentOrg={setCurrentOrg}
                        orgCredentialsType={orgCredentialsType}
                        setOrgCredentialsType={setOrgCredentialsType}
                        wqxCredentials={wqxCredentials}
                        setWqxCredentials={setWqxCredentials}
                    />}
                    {referenceData && <ReferenceData global={global} />}
                    {administration && <Administration 
                        settingsData={settingsData}
                        setSettingsData={setSettingsData}                       
                    />}
                    {help && <Help />}
                    {report && <WaterMonitoringReport 
                        key={'wm-wq-os'+currentOrg} 
                        global={global} data={mLocsData} 
                        results={resultsData}
                        currentOrg={currentOrg} />}
{/*                    {organizations && <WaterQuality
                        key={'wm-wq-options'+currentOrg}
                        global={global}
                        action={action}
                        setAction={setAction}
                        data={orgData}
                        setOrgData={setOrgData}
                        orgData={orgData}
                        usersData={usersData}
                        usersDataAssigned={usersDataAssigned}
                        setUsersDataAssigned={setUsersDataAssigned}
                        currentOrg={currentOrg}
                        setCurrentOrg={setCurrentOrg}
                    />}*/}
                    {monitoringLocs && <MonitoringLocs
                        setEditMonitoringLocation={setEditMonitoringLocation}
                        setAddMonitoringLocation={setAddMonitoringLocation}
                        setMonitoringLocs={setMonitoringLocs}
                        modalOpen={modalOpen}
                        setModalOpen={setModalOpen}
                        modalList={modalList}
                        setModalList={setModalList}
                        setData={setData}
                        mLocsData={mLocsData}
                        setMLOCSData={setMLOCSData}
                        orgCredentialsType={orgCredentialsType}
                    />}
                    {projects && <Projects
                        modalOpen={modalOpen}
                        setModalOpen={setModalOpen}
                        modalList={modalList}
                        setModalList={setModalList}            
                        settings={settings}
                        setSettings={setSettings}
                        projectsData={projectsData}
                        setProjectsData={setProjectsData}
                        orgCredentialsType={orgCredentialsType}
                    />}
                    {activities && <Activities
                        modalOpen={modalOpen}    
                        setModalOpen={setModalOpen}
                        modalList={modalList}
                        setModalList={setModalList}
                        addActivities={addActivities}
                        setAddActivities={setAddActivities}
                        activities={activities}
                        setActivities={setActivities}
                        activitiesData={activitiesData}
                        setActivitiesData={setActivitiesData}
                        mLocsData={mLocsData}
                        resultsData={resultsData}
                        setResultsData={setResultsData}
                        samplesData={samplesData}
                        setSamplesData={setSamplesData}
                        orgCredentialsType={orgCredentialsType}
                    />}
                    {addActivities && <AddActivities
                        action={action}
                        setAction={setAction}
                        addActivities={addActivities}
                        setAddActivities={setAddActivities}
                        activities={activities}
                        setActivities={setActivities}                     
                        resultsData={resultsData}
                        samplesData={samplesData}
                        setResultsData={setResultsData}
                        setSamplesData={setSamplesData}
                        orgCredentialsType={orgCredentialsType}
                    />}
                    {importAction && <Imports
                        setImportDetails={setImportDetails}
                        setImportAction={setImportAction}
                        setImportActionII={setImportActionII}
                        setGlobalImportRules={setGlobalImportRules}
                        importText={importText}
                        setImportText={setImportText}
                        orgData = {orgData}
                        projectsData={projectsData}
                        refreshWQX={refreshWQX}
                        currentOrg={currentOrg}
                    />}
                    {wqxHistory && <WQXHistory data={wqxData} setWqxData={setWqxData} />}
                    {userManagement && <UserManagement 
                        orgData={orgData}
                        usersData={usersData}/>}
                    {editRoll && <RollManagement 
                        orgData={orgData}
                        usersData={usersData}
                        rolesData={rolesData}
                        />}
                    {appSettings && <AppSettings />}
                    {taskManagement && <TaskManagement />}
                    {editMonitoringLocation && <EditMonitoringLocation
                        setMonitoringLocs={setMonitoringLocs}
                        setEditMonitoringLocation={setEditMonitoringLocation}
                        data={data}
                    />}
                    {globalImportRules && <GlobalImportRules
                        setCharacteristicModal={setCharacteristicModal}
                        setTranslationModal={setTranslationModal}
                        setGlobalImportRules={setGlobalImportRules}
                        setImportAction={setImportAction}
                        timeZone={timeZone}
                        setTimeZone={setTimeZone}
                        importChar={importChar}
                        setImportChar={setImportChar}
                        importTaxa={importTaxa}
                        setImportTaxa={setImportTaxa}
                        importTranslation={importTranslation}
                        setImportTranslation={setImportTranslation}
                        setData={setData}
                    />}
                </div>
            </Col>
            {modalOpen && <Modal
                setModalOpen={setModalOpen}
                modalOpen={modalOpen}
                list={modalList}
                setModalList={setModalList}
            />}
            {characteristicModal && <CharacteristicModal
                setCharacteristicModal={setCharacteristicModal}
                characteristicModal={characteristicModal}
                importChar={importChar}
                setImportChar={setImportChar}
                data={data}
                setData={setData}
            />}
            {translationModal && <TranslationModal
                setTranslationModal={setTranslationModal}
                translationModal={translationModal}
                importTranslation={importTranslation}
                setImportTranslation={setImportTranslation}
                data={data}
                setData={setData}
            />}
        </div>
    )
}

export default Insights
