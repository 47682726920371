// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marker-container-epic {
  background-color: white; }

.layer-info {
  padding: 10px;
  border-radius: 6px;
  height: 6rem !important; }
  .layer-info p {
    color: white;
    margin: 0;
    padding: 2px 0; }

.popup .mapboxgl-popup-content {
  max-width: none; }
`, "",{"version":3,"sources":["webpack://./src/components/Layers/EpicLayer/epic.scss"],"names":[],"mappings":"AAAA;EACI,uBAAuB,EAAA;;AAG3B;EACI,aAAa;EACb,kBAAkB;EAClB,uBAAuB,EAAA;EAH3B;IAKQ,YAAY;IACZ,SAAS;IACT,cAAc,EAAA;;AAItB;EACI,eAAe,EAAA","sourcesContent":[".marker-container-epic {\n    background-color: white;\n}\n\n.layer-info {\n    padding: 10px;\n    border-radius: 6px;\n    height: 6rem !important;\n    p {\n        color: white;\n        margin: 0;\n        padding: 2px 0;\n    }\n}\n\n.popup .mapboxgl-popup-content {\n    max-width: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
