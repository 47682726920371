import { useState, useEffect, useContext } from 'react';
import { Dropdown, Accordion, Button, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndustry, faSlidersH, faChartColumn, faRulerHorizontal, faLocationPin, faCalendarWeek } from '@fortawesome/pro-solid-svg-icons';
import DatePicker from 'react-datepicker';
import TimeRangePicker from './TimeRangePicker';
import { DateTime } from 'luxon';
import calendarIcon from '../../assets/calendar-icon.svg';
import pinMarkerIcon from '../../assets/pin-marker-icon.svg';
import Globals from '../../types/GlobalsType';
import Render from '../../components/render';
import { Location, useLocation } from 'react-router-dom';
import ReportsConfigs from '../../ReportsConfigs';
import { ReportConfiguration } from '../../types/ReportConfiguration';
import RenderFilterOptions from '../../components/FilterComponents/RenderFilterOptions';
import countries from '../../json/country_centroids.json'
import states from '../../json/states.json'
import settings from '../../settings.json'
import debounce from 'lodash.debounce';
import MECDateRangeFilter from './MECDateRangeFilter'
import { RefContext } from '../../RefContext';
import { AppContext } from '../../AppContext'
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { RenderCheck } from '../../components/RenderCheck'
import { getCountryFromStorage, putCountryIntoStorage } from '../../features/utils'
import './styles.scss';
import 'react-datepicker/dist/react-datepicker.css';
import agent from '../../api/agent';

export default function Filters(props: any) {

  const { currentRef } = useContext<any>(RefContext);
  const { updateContext } = useContext(AppContext);


  const {
    hucsSelected, setHucsSelected,
    gwNitrateAssessmentSelected, setGwNitrateAssessmentSelected,
    outflowsSelected, setOutflowsSelected,
    nhdSelected, setNhdSelected,
    filter1Selected, setfilter1Selected,
    filter1Populated, setfilter1Populated,
    filterDaysSelected, setfilterDaysSelected,
    filterLocationsSelected, setfilterLocationsSelected,
    filterLocationsPopulated, setfilterLocationsPopulated,
    industrySectorFilterPopulated, setIndustrySectorFilterPopulated,
    selectedIndustrySector,
    setSelectedIndustrySector,
    setSelectedYearFilter,
    selectedYearFilter,
    selectedYearFilterOptions,
    setSelectedYearFilterOptions,
    yearsSelected,
    setYearsSelected,
    dateRangeSelected,
    setDateRangeSelected,
    chemicalsFilerOptions,
    chemicalsSelected,
    setChemicalsFilerOptions,
    setChemicalsSelected,
    timeReferenceUnit,
    setTimeReferenceUnit,
    setCheckFilter,
    checkFilters,
    selectedMeasurementFilterValue,
    setSelectedMeasurementFilterValue,
    selectedMeasurementFilterOptions,
    setDates,
    filterSICCodesFitlerOptions,
    setFilterSICCodesFitlerOptions,
    filterSICCodesSelected,
    setFilterSICCodesSelected,
    globeView,
    setCurrentCountry,
    setSelectedState,
    setSelectedStateCoordinates,
    setCookieTrailState,
    twitterSelected,
    officialTweetsSelected,
    setofficialTweetsSelected,
    unofficialTweetsSelected,
    setUnofficialTweetsSelected,
    officialTweetsPresent,
    setOfficialTweetsPresent,
    unofficialTweetsPresent,
    setUnOfficialTweetsPresent,
    reportNames,
    setReportNames,
    reportNamesSelected,
    setReportNamesSelected,
    setLoading,
    period,
    setPeriod,
    unregulatedReport,
    dataSet,
    setDataSet,
    popSet,
    setPopSet,
    popValue,
    setPopValue,
    singleDate, setSingleDate,
    NationalWaterImpairements,
    points, setPoints,
    streams, setStreams,
    lakes, setLakes,
    USGSWUC,
    selectedUSGSYear, setSelectedUSGSYear,
    CWAParam, setCWAParam,
    CWAParams, setCWATimeRange,
    CWAStartTime,
    CWAMaxTime,
    FBFIBDates, setFBFIBDates,
    FBFIBDate, setFBFIBDate,
    MECDates, setMECDates,
    MECStartDate, setMECStartDate,
    MECEndDate, setMECEndDate,
    notify
  }: Globals = props;



  const lastMonth = () => {
    const date = new Date()
    return new Date(date.setDate(date.getDate() - 30))
  }

  const toDate = (input: any) => {
    return `${input.getMonth() + 1}/${input.getDate()}/${input.getFullYear()}`
  }

  const [backDate, setBackDate] = useState<any>(toDate(lastMonth()))
  const [forwardDate, setForwardDate] = useState<any>(toDate(new Date()))

  const [date1, setDate1] = useState<Date>(new Date())
  const [date2, setDate2] = useState<Date>(lastMonth())

  const handleBackDate = (input: any) => {
    const date: any = toDate(input)
    setBackDate(date)
  }

  const handleForwardDate = (input: any) => {
    const date: any = toDate(input)
    setForwardDate(date)
  }

  const submitDates = (e: any) => {
    e.preventDefault()
    setDates({
      startDate: backDate,
      endDate: forwardDate
    })
  }

  const location: Location = useLocation();
  const { pathname } = location;

  let HistoricalRangeForecast = { direction: 'Next [up to] 48 Hours', length: 0, period: 'days' };

  const resolveFiltersConfigForReport = (currentLocation: Location): ReportConfiguration => {
    const createDummyConfig = () =>
    ({
      name: 'Not Found',
      route: 'Not Found',
      filters: {
        hasDateTimeRangeFilter: false,
        hasHistoricalRangeFilter: false,
        hasHistoricalRangeForecastFilter: false,
        hasChemicalsFilter: false,
        hasFLElementsFilter: false,
        hasIndustrySectorFilter: false,
        hasLocationsFilter: false,
        hasYearsFilter: false,
      },
    } as ReportConfiguration);

    const configsArray = ReportsConfigs.filter(config => config.route == currentLocation.pathname);

    return configsArray.length == 1 ? configsArray[0] : createDummyConfig();
  };

  const filtersConfiguration = resolveFiltersConfigForReport(location);



  function updateFilter1(props: any) {
    console.log(props.target.id)
    setfilter1Selected(props.target.id);
  }

  function updateMeasurementsFilter(props: any) {
    setSelectedMeasurementFilterValue(props.target.id);
  }

  function updateFilterDaysForm(props: any) {
    let filterNumber = props.target.form[1].value;
    if (+props.target.form[2].value === 1) {
      filterNumber = filterNumber * 365;
    } else if (+props.target.form[2].value === 2) {
      filterNumber = filterNumber * 30;
    }
    setfilterDaysSelected(filterNumber);
  }

  const updateFilterDays = (props: any) => {
    debounce(() => {
      setfilterDaysSelected(props.target.valueAsNumber);
    }, 1000)();
  };

  const updateTimeReferenceUnit = (props: any) => {
    setTimeReferenceUnit(Number(props.target.value));
  };

  function updateFilterLocations(eventProps: any) {
    const selectedLocations = [...filterLocationsSelected];
    if (eventProps.target.checked) {
      selectedLocations.unshift(eventProps.target.id);
      setfilterLocationsSelected(selectedLocations);
    } else {
      const updatedFilterLocationsSelected = filterLocationsSelected.filter(
        x => x != eventProps.target.id
      );
      setfilterLocationsSelected(updatedFilterLocationsSelected);
    }
  }

  function updateFilterSICCodes(eventProps: any) {
    const selectedSICCodes = [...filterSICCodesSelected];
    if (eventProps.target.checked) {
      selectedSICCodes.unshift(eventProps.target.id);
      setFilterSICCodesSelected(selectedSICCodes);
    } else {
      const updatedFilterSICCodesSelected = filterSICCodesSelected.filter(
        x => x != eventProps.target.id
      );
      setFilterSICCodesSelected(updatedFilterSICCodesSelected);
    }
  }

  const updateIndustrySectorFilterSelection = (eventProps: any) => {
    const industriesSelected = [...selectedIndustrySector];
    if (eventProps.target.checked) {
      industriesSelected.push(eventProps.target.id);
      setSelectedIndustrySector(industriesSelected);
    } else {
      const updatedIndustriesSelected = industriesSelected.filter(x => x != eventProps.target.id);
      setSelectedIndustrySector(updatedIndustriesSelected);
    }
  };

  const updateChemicalsFilterSelection = (eventProps: any) => {
    const selectedChemicals = [...chemicalsSelected];
    if (eventProps.target.checked) {
      selectedChemicals.push(eventProps.target.id);
      setChemicalsSelected(selectedChemicals);
    } else {
      const updatedChemicalsSelected = selectedChemicals.filter(x => x != eventProps.target.id);
      setChemicalsSelected(updatedChemicalsSelected);
    }
  };

  const updateYearFilterSelection = (eventProps: any) => {

    if (filtersConfiguration.filters.yearsFilterType) {
      const selectedYears = [...yearsSelected];
      if (eventProps.target.checked) {
        selectedYears.push(eventProps.target.id);
        setYearsSelected(selectedYears);
      } else {
        const updatedYearsSelected = selectedYears.filter(x => x != eventProps.target.id);
        setYearsSelected(updatedYearsSelected);
      }
    } else {
      setSelectedYearFilter(eventProps.target.id);
    }
  };

  const [qdate, setQdate] = useState(new Date())

  const updateSingleDateSelection = (eventProps: any) => {
    const inputDate = new Date(eventProps);
    setQdate(eventProps)
    
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    setSingleDate(formattedDate)
  };

  const updateDateRangeStartSelection = (eventProps: any) => {
    if (
      eventProps < DateTime.now().plus({ years: 55 }) &&
      eventProps > DateTime.now().minus({ years: 100 })
    ) {
      const selectedYears = [...dateRangeSelected];
      if (selectedYears.length === 2) {
        selectedYears[0] = eventProps;
      } else {
        selectedYears.push(eventProps);
        selectedYears.push(new Date());
      }

      if(selectedYears[0] > selectedYears[1]){
         notify('Ensure end date is after start date.')
      }
      
      setDateRangeSelected(selectedYears);
    }
  };

  const updateDateRangeEndSelection = (eventProps: any) => {
    if (
      eventProps < DateTime.now().plus({ years: 55 }) &&
      eventProps > DateTime.now().minus({ years: 100 })
    ) {
      const selectedYears = [...dateRangeSelected];
      if (selectedYears.length === 2) {
        selectedYears[1] = eventProps;
      } else {
        selectedYears.push(new Date());
        selectedYears.push(eventProps);
      }

      if(selectedYears[0] > selectedYears[1]){
         notify('Ensure end date is after start date.')
      }

      setDateRangeSelected(selectedYears);
    }
  };

  const renderStates = () => {
    return states.map(state => {
      return <option key={`inline-${state.name}`}>{state.name}</option>
    })
  }

  const handleStateChange = async (input: any) => {
    try {
      const { data } = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?&country=US&limit=3&access_token=${settings.maboxKey}`)
      const [lng, lat] = data.features[0].center
      setSelectedStateCoordinates({ lng, lat })

    } catch (e) {
      console.log(e)
    }
    const abv = states.filter(state => state.name === input)
    setSelectedState(abv[0].abbreviation)
    setCookieTrailState(input)
  }


  const renderSiteNames = (input: any) => {


    const alterList = (input: any, checked: boolean) => {

      if (!checked) {
        setReportNamesSelected((prev: any) => [...prev.filter((i: string) => i !== input)])
      }

      if (checked) {
        setReportNamesSelected((prev: any) => [...prev, input])
      }


    }

    const list = input.map((report: any) => (
      <RenderCheck
        label={report}
        alterList={alterList}
      />
    ))

    return (
      <div id="mexico-scroll" style={{
        height: '50vh',
        position: 'relative',
        overflow: 'scroll'
      }}>
        {list}
      </div>
    )
  }


  const handlePeriodChange = (e: any) => {
    if (e.target.value === '2020') {
      setPeriod(0)
    }
    if (e.target.value === '2012-2020') {
      setPeriod(1)
    }
  }

  const handleDataSetChange = (e: any) => {
    setDataSet(e.target.value)
  }

  const onPopChange = (value: string) => {
    setPopValue(value)
  }

  const renderPopServedValues = () => {
    return popSet && popSet.sort().map((value: string) => {
      return <option value={value}>{value}</option>
    })
  }

  const switchUSGSYear = () => {
    const handleChange = (event: any) => {
      setSelectedUSGSYear(parseInt(event.target.value));
    };
  
    return (
      <Form>
        <Form.Check
          inline
          label="2016"
          type="radio"
          id="year2016"
          value={2016}
          checked={selectedUSGSYear === 2016}
          onChange={handleChange}
        />
        <Form.Check
          inline
          label="2017"
          type="radio"
          id="year2017"
          value={2017}
          checked={selectedUSGSYear === 2017}
          onChange={handleChange}
        />
        <Form.Check
          inline
          label="2018"
          type="radio"
          id="year2018"
          value={2018}
          checked={selectedUSGSYear === 2018}
          onChange={handleChange}
        />
        <Form.Check
          inline
          label="2017"
          type="radio"
          id="year2017"
          value={2019}
          checked={selectedUSGSYear === 2019}
          onChange={handleChange}
        />
        <Form.Check
          inline
          label="2020"
          type="radio"
          id="year2020"
          value={2020}
          checked={selectedUSGSYear === 2020}
          onChange={handleChange}
        />
      </Form>
    );
  };

  const cwaRadioList = (input:any) => 
    (
      <Form>
        {input.sort().map((param:any, index:any) => (
          <Form.Check
            key={index}
            type="radio"
            id={`param-radio-${index}`}
            label={param}
            checked={param === CWAParam}
            onChange={() => setCWAParam(param)}
          />
        ))}
      </Form>
    );

    const fibRadioList = (input:any) => 
      (
        <Form>
          {input.sort().map((date:any, index:any) => (
            <Form.Check
              key={index}
              type="radio"
              id={`date-radio-${index}`}
              label={date}
              checked={date === FBFIBDate}
              onChange={() => setFBFIBDate(date)}
            />
          ))}
        </Form>
      );

  return (
    <Dropdown className='filters-sidebar' drop={'end'}>
      <Dropdown.Toggle id='dropdown-basic'  className='sidebar-dropdown'>
        <FontAwesomeIcon icon={faSlidersH} className='mr-2' />
        Filters
      </Dropdown.Toggle>

      <Dropdown.Menu style={{minHeight:400}}>
        <div className='title'>
          <FontAwesomeIcon icon={faSlidersH} className='mr-2' /> Filters
        </div>

        <div className='container'>
          {props.filterGroup && props.filterGroup}
          <hr />
          {/* @ts-ignore */}
          <Render condition={filtersConfiguration.filters.hasMECDateRangeFilter}>
          {MECDates && (
            <MECDateRangeFilter
             dates={MECDates} 
             startDate={MECStartDate}
             setStartDate={setMECStartDate}
             endDate={MECEndDate}
             setEndDate={setMECEndDate}
            />
          )}
          </Render>
          {/* @ts-ignore */}
          <Render condition={filtersConfiguration.filters.hasFIBFilter}>
          {FBFIBDates && fibRadioList(FBFIBDates)}
          </Render>
          {/* @ts-ignore */}
          <Render condition={filtersConfiguration.filters.hasTimeRangePicker}>
            <TimeRangePicker 
              onSubmit={(timeRange: any) => setCWATimeRange(timeRange)} 
              initialStartTime={CWAStartTime}
              maxTime={CWAMaxTime}
            />
          </Render>
          <hr />
          {/* @ts-ignore */}
          <Render condition={filtersConfiguration.filters.hasCWAParams}>
          {CWAParams && cwaRadioList(CWAParams)}
          </Render>
          {/* @ts-ignore */}
          <Render condition={filtersConfiguration.filters.hasUSGSYears}>
            <h5>
              <img src={calendarIcon} className='calendar-icon' />
              Year
            </h5>
            {switchUSGSYear()}
          </Render>
          {/* @ts-ignore */}
          <Render condition={filtersConfiguration.filters.hasStatesFilter}>
            <h5>
              Switch State
              <br key={uuidv4()} />
              <Form.Select style={{ marginTop: 5, marginBottom: 10 }} onChange={(e) => handleStateChange(e.target.value)}>
                {renderStates()}
              </Form.Select>
            </h5>
            <hr />
          </Render>
          <Render condition={filtersConfiguration.filters.hasPopFilter && popSet}>
            <h5>
              Population Served
              <br key={uuidv4()} />
              <Form.Select
                onChange={(e) => onPopChange(e.target.value)}
                style={{ marginTop: 5, marginBottom: 10 }}
              >
                <option selected>All</option>
                {renderPopServedValues()}
              </Form.Select>
            </h5>
            <hr />
          </Render>
          {/* @ts-ignore */}
          <Render condition={filtersConfiguration.filters.hasSingleDateFilter}>
            <h5>Date</h5>
            <Form>
              <div className={'filter-date-range'}>
                <DatePicker
                  selected={qdate ? qdate : dateRangeSelected[0]}
                  onChange={(date: Date) => updateSingleDateSelection(date)}
                />
                <img src={calendarIcon} className='calendar-icon' />
              </div>
            </Form>
            <hr />
          </Render>
          <Render condition={filtersConfiguration.filters.hasDateTimeRangeFilter}>
            <h5>Date Range</h5>
            <Form>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
              <span style={{width: 44, marginLeft: 7}}>Start: </span>
              <div className={'filter-date-range'}>
                <DatePicker
                  selected={dateRangeSelected[0]}
                  className={'filter-date-range-pick'}
                  onChange={(date: Date) => updateDateRangeStartSelection(date)}
                />
                <img src={calendarIcon} className='calendar-icon' style={{paddingLeft: 7}}/>
              </div>
            </div> 

            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
              <span style={{width: 44, marginLeft: 7}}>End: </span>
              <div className={'filter-date-range'}>
                <DatePicker
                  selected={dateRangeSelected[1]}
                  className={'filter-date-range-pick'}
                  onChange={(date: Date) => updateDateRangeEndSelection(date)}
                />
                <img src={calendarIcon} className='calendar-icon' style={{paddingLeft: 7}} />
              </div>
            </div> 

            </Form>
            <hr />
          </Render>
          <Render condition={filtersConfiguration.filters.hasHistoricalRangeFilter}>
            <h5>
              <img src={calendarIcon} className='calendar-icon' />
              Historical Date Range
            </h5>
            <hr />
            <Render
              condition={!filtersConfiguration.filters.removeForecastFromHistoricalRangeFilter}
            >
              <Form.Select>
                <option value='1'>Next</option>
                <option value='2'>Last</option>
              </Form.Select>
            </Render>
            <input type='number' defaultValue={3} onChange={updateFilterDays} />
            <Form.Select defaultValue={3} onChange={updateTimeReferenceUnit}>
              <option value='1'>Years</option>
              <option value='2'>Months</option>
              <option value='3'>Days</option>
            </Form.Select>
            <hr />
          </Render>
          <Render condition={pathname === '/GroundwaterMexico' || pathname === '/SurfacewaterMexico'}>
            <strong>Filter by Period</strong>
            <Form.Select defaultValue={1} onChange={e => handlePeriodChange(e)}>
              <option selected>2020</option>
              <option>2012-2020</option>
            </Form.Select>
            <strong>Filter by Sitename</strong>
            {checkFilters.sort().map(
              (filter: string) =>
                <RenderFilterOptions
                  filter={filter}
                  isChecked={true}
                  setCheckFilter={setCheckFilter}
                />)}
          </Render>
          <Render condition={NationalWaterImpairements}>
            <Form.Check
              onChange={() => setLakes(!lakes)}
              label="Lakes & Watersheds"
              checked={lakes}
            />
            <Form.Check
              onChange={() => setStreams(!streams)}
              label="Rivers & Streams"
              checked={streams}
            />
            <Form.Check
              onChange={() => setPoints(!points)}
              label="Points"
              checked={points}
            />
          </Render>
          <Render condition={twitterSelected}>
            <Form.Check
              onChange={() => setofficialTweetsSelected(!officialTweetsSelected)}
              label="Official Tweets"
              checked={officialTweetsSelected && officialTweetsPresent}
              disabled={!officialTweetsPresent}
            />
            <Form.Check
              onChange={() => setUnofficialTweetsSelected(!unofficialTweetsSelected)}
              label="Unofficial Tweets"
              checked={unofficialTweetsSelected && unofficialTweetsPresent}
              disabled={!unofficialTweetsPresent}
            />
          </Render>
          {/* @ts-ignore */}
          <Render condition={filtersConfiguration.filters.hascheckFilter}>
            <strong>Filter by Contaminant</strong>
            {checkFilters.sort().map(
              (filter: string) =>
                <RenderFilterOptions
                  filter={filter}
                  isChecked={false}
                  setCheckFilter={setCheckFilter}
                />)}
          </Render>
          <Render condition={filtersConfiguration.filters.hasHistoricalRangeForecastFilter}>
            <h5>
              <img src={calendarIcon} className='calendar-icon' />
              Forecast Date Range
            </h5>
            <hr />
            <Form onChange={updateFilterDaysForm}>
              <Form.Select defaultValue={1}>
                <option value='1'>48 Hour Forecast plus prior</option>
              </Form.Select>
              <input type='number' defaultValue={0} />
              <Form.Select defaultValue={3}>
                <option value='1'>Years</option>
                <option value='2'>Months</option>
                <option value='3'>Days</option>
              </Form.Select>
            </Form>
            <hr />
          </Render>
          <Render condition={filtersConfiguration.filters.hasAccordionFilter || false}>
            <h5>
              <FontAwesomeIcon
                icon={faIndustry}
                className='mr-2'
                style={{ display: 'inline', marginLeft: 0, width: 'unset' }}
              />{'        Sector and Industry '}

            </h5>
            <Accordion className="" defaultActiveKey={filter1Selected || "Food and Beverage"} >
              {filter1Populated.map((label: any) => {
                return (<Accordion.Item key={`inlineddd-${label}`} className="" eventKey={label}>
                  <div className="te-filter" style={{ width: 222 }}>
                    <Accordion.Header className="te-filter-accordion-header">{label}</Accordion.Header>
                    <Accordion.Body className="te-filter-accordion-body">
                      {selectedMeasurementFilterOptions.filter((o: any) => o["sectorParentTitle"] === label).map((labels: any, index: any) => (
                        <div key={`inline-${labels.title}`} className='mb-3'>
                          <p></p>
                          <Form.Check
                            onClick={updateMeasurementsFilter}
                            label={labels.title}
                            name='group1'
                            type='radio'
                            id={`${labels.title}`}
                            defaultChecked={selectedMeasurementFilterValue === labels.title}
                            style={{ marginLeft: 10 }}
                          />
                        </div>
                      ))}
                    </Accordion.Body>
                  </div>
                </Accordion.Item>)
              })
              }
            </Accordion>

            <hr />
          </Render>
          <Render condition={filtersConfiguration.filters.hasFLElementsFilter}>
            <h5>
              <FontAwesomeIcon
                icon={faRulerHorizontal}
                className='mr-2'
                style={{ display: 'inline', marginLeft: 0, width: 'unset' }}
              />{' '}
              {filtersConfiguration.filters.labelFilter1}
            </h5>
            {filter1Populated.map((label: any) => {

              if (label === 'UCMR-5') {
                return (
                  <div key={`inline-${label}`} className='mb-3'>
                    <p></p>
                    <Form.Check
                      onClick={updateFilter1}
                      label={label}
                      name='group1'
                      defaultChecked={true}
                      type='radio'
                      id={`${label}`}
                    />
                  </div>
                )
              }

              if (label === filter1Selected) {
                return (
                  <div key={`inline-${label}`} className='mb-3'>
                    <p></p>
                    <Form.Check
                      onClick={updateFilter1}
                      label={label}
                      name='group1'
                      defaultChecked={true}
                      type='radio'
                      id={`${label}`}
                    />
                  </div>
                )
              }

              return (
                <div key={`inline-${label}`} className='mb-3'>
                  <p></p>
                  <Form.Check
                    onClick={updateFilter1}
                    label={label}
                    name='group1'
                    type='radio'
                    id={`${label}`}
                  />
                </div>
              )
            })}
            <hr />
          </Render>
          <Render condition={filtersConfiguration.filters.hasMeasurementsFilter}>
            <h5 className='ml-0'>
              <FontAwesomeIcon
                icon={faRulerHorizontal}
                className='mr-2'
                style={{ display: 'inline', marginLeft: 0, width: 'unset' }}
              />{' '}
              {filtersConfiguration.filters.labelMeasurementsFilter || 'Measurements'}
            </h5>
            {selectedMeasurementFilterOptions.map((label: any) => (
              <div key={`inline-${label}`} className='mb-3'>
                <p></p>
                <Form.Check
                  onClick={updateMeasurementsFilter}
                  label={label}
                  name='group1'
                  type='radio'
                  id={`${label}`}
                  defaultChecked={label == 'Streamflow'}
                />
              </div>
            ))}
            <hr />
          </Render>
          <Render condition={filtersConfiguration.filters.hasChemicalsFilter}>
            <h5>
              <img src={pinMarkerIcon} className='pin-marker-icon' />
              {filtersConfiguration.filters.labelChemicalsFilter || 'Chemicals'}
            </h5>
            {chemicalsFilerOptions.map((label: any) => {
              return (
                <div key={`inline-${label}`} className='mb-3'>
                  <p></p>
                  <Form.Check
                    onClick={updateChemicalsFilterSelection}
                    label={label}
                    name='group1'
                    id={`${label}`}
                  />
                </div>
              )
            })}
            <hr />
          </Render>
          {/* @ts-ignore */}
          <Render condition={filtersConfiguration.filters.hasDateFilter}>
            <h5>
              Time Period
            </h5>
            <Form className={(pathname === '/DailyLakeMeadLevelsReport' ? 'filter-date-range-padding' : '')}>

              <div className={'filter-date-range LM'}>
                <DatePicker selected={date2} onChange={(date: Date) => {
                  setDate2(date)
                  handleBackDate(date)
                }} />
                <p style={{
                  fontSize: '12px',
                  marginLeft: '4px',
                  fontWeight: 'bold'
                }}>Least Recent</p>
              </div>
              <div className={'filter-date-range LM'}>
                <DatePicker selected={date1} onChange={(date: Date) => {
                  setDate1(date)
                  handleForwardDate(date)
                }} />
                <p style={{
                  fontSize: '12px',
                  marginLeft: '4px',
                  fontWeight: 'bold'
                }}>Most Recent</p>
              </div>
              <div className="date-submit">
                <button
                  onClick={e => submitDates(e)}
                  disabled={date2 > date1 ? true : false}
                  className="date-submit-btn"
                >
                  Submit
                </button>
              </div>
            </Form>
            <hr />
          </Render>
          <Render condition={filtersConfiguration.filters.hasIndustrySectorFilter}>
            <h5>
              <FontAwesomeIcon
                icon={faIndustry}
                style={{
                  display: 'inline',
                  marginLeft: 0,
                  paddingRight: '0.5rem',
                  width: 'unset'
                }}
              />
              Industry Sectors
            </h5>
            {industrySectorFilterPopulated.map((label: any) => (
              <div key={`inline-${label}`} className='mb-3'>
                <p></p>
                <Form.Check
                  onClick={updateIndustrySectorFilterSelection}
                  label={label}
                  name='group1'
                  id={`${label}`}
                />
              </div>
            ))}
            <hr />
          </Render>
          <Render condition={filtersConfiguration.filters.hasYearsFilter}>
            <h5>
              <img src={calendarIcon} className='calendar-icon' />
              Year
            </h5>
            {selectedYearFilterOptions.map((label: any) => (
              <div key={`inline-${label}`} className='mb-3'>
                <p></p>
                <Form.Check
                  onClick={updateYearFilterSelection}
                  label={label}
                  name='group1'
                  type={filtersConfiguration.filters.yearsFilterType ? 'checkbox' : 'radio'}
                  id={`${label}`}
                />
              </div>
            ))}
            <hr />
          </Render>
          <Render condition={filtersConfiguration.filters.hasLocationsFilter}>
            <h5>
              <img src={pinMarkerIcon} className='pin-marker-icon' />
              {filtersConfiguration.filters.labelLocationsFilter || 'Locations'}
            </h5>
            <div className='filter-locations'>
              {filterLocationsPopulated.map((label: any, index: number) => (
                <div key={`inline-${label.huc12}${index}`} className='filter-locations-items'>
                  <p></p>
                  <Form.Check
                    onClick={updateFilterLocations}
                    label={label.hucname || label.name}
                    name='group1'
                    type='checkbox'
                    id={`${label.huc12 || label.name}`}
                  />
                </div>
              ))}
            </div>
          </Render>
          <Render condition={filtersConfiguration.filters.hasSICCodesFilter || false}>
            <h5>
              <img src={pinMarkerIcon} className='pin-marker-icon' />
              {filtersConfiguration.filters.labelFilter1}
            </h5>
            <div className='filter-locations'>
              {filterSICCodesFitlerOptions.map((label: any, index: number) => {
                return (
                  <div key={`inline-${label.huc12}${index}`} className='filter-locations-items'>
                    <p></p>
                    <Form.Check
                      onClick={updateFilterSICCodes}
                      label={label}
                      name='group1'
                      type='checkbox'
                      id={`${label}`}
                    />
                  </div>
                )
              })}
            </div>
          </Render>
          <Render condition={pathname === '/ChemicalDataReporting'}>
            Select Data Set
            <br />
            <Form.Select onChange={handleDataSetChange}>
              <option>Commercial Chemical Data</option>
              <option>Industrial Chemical Data</option>
              <option>Manufacturer Chemical Data</option>
            </Form.Select>
          </Render>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function CheckBoxOption(props: any) {
  const { id, displayName } = props;
  return (
    <Form.Check>
      <Form.Check.Input type='checkbox' isValid />
      <Form.Check.Label>{displayName}</Form.Check.Label>
    </Form.Check>
  );
}
