import { useEffect, useContext, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { FillLayer } from 'mapbox-gl';
import { RefContext } from '../../../RefContext';

const FemaLayer = (props: any) => {

    const { currentRef } = useContext(RefContext)
    const {
        id,
        fillColor,
        opacity,
        outlineColor,
        tileSet,
        sourceLayer,
        beforeID = ''
    } = props

    const [features, setFeatures] = useState()


    // @ts-ignore
    const mapInstance = currentRef && currentRef?.current

    useEffect(() => {
        if (mapInstance) {
          mapInstance.on('mousemove', (event: any) => {
            const layerObject = mapInstance.queryRenderedFeatures(event.point);
            if(layerObject){
                setFeatures(layerObject?.[0])
            }      
          })
        }
    }, [mapInstance])

    const VectorTileLayer: FillLayer = {
        id: `${id}-layer`,
        type: "fill",
        "source-layer": `${sourceLayer}`,
        paint: {
            "fill-opacity": opacity,
            "fill-outline-color": `${outlineColor}`,
            'fill-color': fillColor,
        },
    };

    return (
        <>
            <Source
                id={`${id}-layer`}
                type="vector"
                url={tileSet}
            >
                <Layer
                    beforeId={beforeID}
                    {...VectorTileLayer}
                />
            </Source>
        </>
    )
}

export default FemaLayer;  
