import { Location, useLocation } from 'react-router-dom';
import ReportsConfigs from '../../../ReportsConfigs';

const ReportName = (props: any) => {
  const location: Location = useLocation();

  return <h2>{props.headerTitle ? props.headerTitle : ReportsConfigs.find(x => x.route == location.pathname)?.name|| ''}{(props.subHeader) ? " " + props.subHeader : ""}</h2>;
};

export default ReportName;
