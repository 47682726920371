import { useContext, useEffect, useMemo, useRef, useState, Ref } from 'react';
import { Button, Col, Row, Form } from 'react-bootstrap';
import agent from '../../../api/agent';
import { WhatsInMyWatershedBodyModel } from '../../../api/models';
import { HUC12GeoPoint } from '../../../types/HUC12GeoPoint';
import { MarkerConfig } from '../../../types/MarkerConfig';
import ReportMap from '../shared/ReportMap';
import settings from './../../../settings.json';
import { FillLayer } from 'mapbox-gl';

import { RefContext } from '../../../RefContext'
import MapLayer from '../../../types/MapLayer';
import MapSource from '../../../types/MapSource';
import ReportTable from '../shared/ReportTable';
import Render from '../../../components/render';
import { formatRowValue } from '../../utils';
import { Bar } from 'react-chartjs-2';
import DonutChart from '../shared/DonutChart';
import { MapRef } from 'react-map-gl';
import { HUC8GeoPoint } from '../../../types/HUC8GeoPoint';
import { message_hucrestriction, message_nodatalocation, message_noresult } from '../../../Constants';
import mapboxgl from 'mapbox-gl';
import { AppContext } from '../../../AppContext';
import { Location, useLocation } from 'react-router-dom';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { CatalogsContext } from '../../../CatalogsProvider';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import MapTitle from '../shared/MapTitle';
import MapSlider from '../shared/MapSlider';
import MapLegend from '../shared/MapLegend';

import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import './styles.scss';

const WhatsInMyWatershed = ({ global }: { global: any }): JSX.Element => {
  const { selectedHUC8, setHUC8 } = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);
  const { setCurrentRef } = useContext(RefContext);
  const mapRef = useRef<MapRef>();
  const fullscreenhandle = useFullScreenHandle() as any;
  const FullScreenComp = FullScreen as any;

  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState<Array<WhatsInMyWatershedBodyModel>>([]);
  const [huc12LayerFilterExpression, setHuc12LayerFilterExpression] = useState(['in', 'HUC12']);
  const [markersConfigs, setMarkersConfigs] = useState<MarkerConfig[]>([]);

  const [showReportTable, setShowReportTable] = useState(true);
  const [currentSelectedRowHTMLElement, setCurrentSelectedRowHTMLElement] =
    useState<HTMLElement | null>(null);
  const [currentSelectedRowData, setCurrentSelectedRowData] = useState({});
  const [hoveredPinInfo, setHoveredPinInfo] = useState(null);
  const [cursor, setCursor] = useState<string>('auto');
  
  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;



  useEffect(() => {    
    const huc8 = selectedHUC8;
    const selectedHUC8Value = huc8
    if(selectedHUC8Value && global.hucRestrict.length>0 && (global.hucRestrict.indexOf(selectedHUC8Value) < 0)){
        setLoading(false);
        return global.notify(message_hucrestriction); 
    }
 
    if(huc8){
      setLoading(true);

      agent.Reports.WhatsInMyWatershed(huc8).then((res: any) => {
 
        const relatedHUC12Codes = res.body.map((x: any) => x.huc12);
        setHuc12LayerFilterExpression(oldState =>
          [...oldState].slice(0, 2).concat(relatedHUC12Codes)
        );
        setReportData(res.body);
        markersConfigBuilder(res.body);
        setCurrentSelectedRowData(res.body[0]);
        setLoading(false);
        flyToInitialLocationHandler();
      });    
    }
  }, [selectedHUC8]);

  useEffect(() => {
    flyToInitialLocationHandler();
  }, [mapRef.current]);

  useEffect(() => {
    const selectedHUC8Value = selectedHUC8;
    if(selectedHUC8Value && global.hucRestrict.length>0 && (global.hucRestrict.indexOf(selectedHUC8Value) < 0)){
        setLoading(false);
        return global.notify(message_hucrestriction); 
    }
    global.setUserLogged(true);
    global.notify("Many EPA Watershed Index Online (WSIO) Land Use Types are subsets of of other main types.  Percentage totals are ~100% for a Watershed")
    if (global.trackerOn) global.setTrackerOn(false)
    const huc8 = selectedHUC8;
    agent.Reports.WhatsInMyWatershed(huc8).then((res: any) => {
      const relatedHUC12Codes = res.body.map((x: any) => x.huc12);
      setHuc12LayerFilterExpression(oldState => [...oldState].concat(relatedHUC12Codes));

      setReportData(res.body);
      markersConfigBuilder(res.body);
      setLoading(false);
    });
  }, []);

  // useEffect(() => {
  //   markersConfigBuilder(reportData);
  // }, [huc12LonLatCatalogArray]);

  const flyToInitialLocationHandler = () => {
    if (mapRef.current && selectedHUC8) {
      const currentHuc8GeoPoint = getCurrentHuc8LongLat(selectedHUC8);
      if (currentHuc8GeoPoint) {
        mapRef.current?.flyTo({
          center: [
            parseFloat(currentHuc8GeoPoint.centroid_longitude),
            parseFloat(currentHuc8GeoPoint.centroid_latitude),
          ],
          essential: true,
          zoom: 7,
        });
      }
    }
  };

  const getCurrentHuc8LongLat = (
    selectedHUC8Value: string,
    catalog?: any[]
  ): HUC8GeoPoint | undefined => {
    if (catalog) return catalog.find(x => x.huc8 == selectedHUC8Value);
    return catalogsContext.huc8Catalog.find(x => x.huc8 == selectedHUC8Value);
  };
  const getCurrentHuc12LongLat = (huc12Value: string): HUC12GeoPoint | undefined => {
    return catalogsContext.huc12Catalog.find(x => x.huc12 == huc12Value);
  };

  const markersConfigBuilder = (reportData: WhatsInMyWatershedBodyModel[]) => {
    const huc12DataArrayFromReportData = reportData.map(x => ({
      huc12: x.huc12,
      name: x.name_huc12_watershed,
    }));
    const markerConfigObjects: MarkerConfig[] = [];
    for (let huc12DataItem of huc12DataArrayFromReportData) {
      const searchResult = catalogsContext.huc12Catalog.find(
        catalogItem => catalogItem.huc12 == huc12DataItem.huc12
      );
      if (searchResult !== undefined) {
        markerConfigObjects.push({
          name: huc12DataItem.name,
          huc: huc12DataItem.huc12,
          geoPoint: {
            latitude: searchResult.centroid_latitude,
            longitude: searchResult.centroid_longitude,
          },
        });
      }
    }
    setMarkersConfigs(markerConfigObjects);
  };

  const flyToHUC12sLocation = (huc12: string) => {
    if (mapRef.current && huc12) {
      const currentHuc12GeoPoint = getCurrentHuc12LongLat(huc12);
      if (currentHuc12GeoPoint) {
        mapRef.current?.flyTo({
          center: [
            parseFloat(currentHuc12GeoPoint.centroid_longitude),
            parseFloat(currentHuc12GeoPoint.centroid_latitude),
          ],
          essential: true,
          zoom: 15,
        });
      }
    }
  };

  const clickRowHandler = (rowData: any, event: any) => {
    if (currentSelectedRowHTMLElement)
      currentSelectedRowHTMLElement.classList.remove('table-row__selected');
    const selectedRow = event.currentTarget as HTMLElement;

    if (selectedRow) {
      selectedRow.className += 'table-row__selected';
      setCurrentSelectedRowHTMLElement(selectedRow);
      setCurrentSelectedRowData(rowData);

      flyToHUC12sLocation(rowData.huc12);
    }
  };

  ///
  // START - Sources Config
  ///

  const huc12sLayerPaint = {
    'fill-opacity': 0.7,
    'fill-outline-color': 'blue',
  };
  const huc12sLayer = new MapLayer('True Elements - HUC 12s', 'fill', huc12sLayerPaint);
  const huc12sSource = new MapSource(
    'vector',
    settings.tileSetURLs.HUC12,
    huc12sLayer as FillLayer,
    [...huc12LayerFilterExpression]
  );

  const huc8sLayerPaint = {
    'fill-opacity': 0.6,
    'fill-outline-color': 'black',
  };
  const huc8slayer = new MapLayer('True Elements - huc8s', 'fill', huc8sLayerPaint);
  const huc8sSource = new MapSource(
    'vector',
    settings.tileSetURLs.HUC8,
    huc8slayer as FillLayer,
    ['==', 'HUC8', selectedHUC8],
    huc12sSource
  );

  /// END - Source Config

  const tableColumns = useMemo(
    () => [
      { Header: 'Name', accessor: 'name_huc12_watershed' },
      { Header: 'Majority County', accessor: 'Majority County' },
      { Header: 'Area (sqm)', accessor: 'Area (mi)' },
      {
        Header: 'Avg Annual Rainfall (in)',
        accessor: 'Average Annual Precipitation (in)',
      },
      {
        Header: '% Land',
        accessor: '% Land',
        Cell: (props: any) => formatRowValue(props.value),
      },
      {
        Header: '% Water',
        accessor: '% Water',
        Cell: (props: any) => formatRowValue(props.value),
      },
      {
        Header: '% Urban',
        accessor: '% Urban',
        Cell: (props: any) => formatRowValue(props.value),
      },
      {
        Header: '% Agricultural',
        accessor: '% Agricultural',
        Cell: (props: any) => formatRowValue(props.value),
      },
      {
        Header: '% Forest',
        accessor: '% Forest',
        Cell: (props: any) => formatRowValue(props.value),
      },
      {
        Header: '% Wetlands',
        accessor: '% Wetlands',
        Cell: (props: any) => formatRowValue(props.value),
      },
      {
        Header: '% Protected',
        accessor: '% Protected',
        Cell: (props: any) => formatRowValue(props.value),
      },
      {
        Header: '% Grassland',
        accessor: '% Grassland',
        Cell: (props: any) => formatRowValue(props.value),
      },
      {
        Header: '# Major Pollution Discharge Permits',
        accessor: '# Major Pollution Discharge Permits',
      },
    ],
    []
  );
  const labelsStackedBarsChart = reportData.map(dataObject => dataObject.name_huc12_watershed);
  const properties = ['% Agricultural', '% Forest', '% Urban', '% Water', '% Grassland', '% Shrub'];
  const getStackedChartDatasets = (data: {}[]) => {
    let sets: any[] = [];

    if (reportData.length > 0) {
      for (let property of properties) {
        const dataObject = reportData.map(dataObject => {
          return dataObject[property as keyof WhatsInMyWatershedBodyModel];
        });
        sets.push({ label: property, data: dataObject });
      }
    }

    const datasets = sets.map(set => ({
      label: set.label,
      data: set.data,
      backgroundColor: `rgb(${Math.random() * 255}, ${Math.random() * 255} , ${Math.random() * 255
        })`,
    }));
    return datasets;
  };

  const data = {
    labels: labelsStackedBarsChart,
    datasets: getStackedChartDatasets(reportData),
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: '',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const onPinHoveredHandler = (info: { longitude: string; latitude: string; text: string }) => {
    if (mapRef.current) {
      new mapboxgl.Popup({ closeOnClick: true, closeButton: false })
        .setLngLat([parseFloat(info.longitude), parseFloat(info.latitude)])
        .setHTML(`<span style="color: white ; fontSize:14px">${info.text}</span>`)
        .addTo(mapRef.current.getMap());
    }
  };

  const onPinMouseExitHandler = () => {
    const popups = document.getElementsByClassName('mapboxgl-popup');

    if (popups.length) {
      popups[0].remove();
    }

    if (mapRef.current) {
    }
  };

  return (
   <FullScreenComp handle={fullscreenhandle}>
    <div className='water-statistics-measures' id='whatsinmywatershed-report'>
      <ReportHeader global={global} data={reportData}
        reportID={"whatsinmywatershed-report"} fullScreenClickHandle={fullscreenhandle.enter} />    
      <div className='container'>
        <Row>
          <Col md={6}>
            <div style={{ position: 'relative' }} className='watershed-map-container'>
              <LoadingSpinner active={loading} />

              <ReportMap
                source={huc8sSource}
                markersConfigs={markersConfigs}
                refToMap={mapRef}
                hasPins={true}
                onPinMouseEnterHandler={onPinHoveredHandler}
                onPinMouseExitHandler={onPinMouseExitHandler}
                {...{ global }}
                hasDrawControl={true}
                hasNavigationControl={true}
                hasGeolocateControl={true}
              />
              
            </div>
          </Col>
          <Col md={6}>
            <div className='report__container-background' style={{ height: '37vh' }}>
              <DonutChart
                rowData={useMemo(() => {
                  return currentSelectedRowData;
                }, [currentSelectedRowData])}
                labels={properties}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Col>
              <Button onClick={() => setShowReportTable(true)}>See Table</Button>
              <Button className='m-2' onClick={() => setShowReportTable(false)}>
                See Bar Chart
              </Button>
            </Col>
            <Col></Col>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <Render condition={showReportTable}>
              <ReportTable
                data={reportData}
                columns={tableColumns}
                clickRowHandler={clickRowHandler}
              />
            </Render>
            <Render condition={!showReportTable}>
              <div className='table-container'>
                <Bar options={options} data={data} />
              </div>
            </Render>
          </Col>
        </Row>
      </div>
      <ReportFooter />
    </div>
      <MapSlider global={global} />
   </FullScreenComp>
  );
};

export default WhatsInMyWatershed;
