import React from 'react';

function FHO() {

  return (                    
      <div className="quality-references eddi">
        <h5>Flood Hazard Outlook (FHO)</h5>
        <p>Displays real-time information about flood hazards and streamflow conditions from NOAA/NWS. Check regional Potential Flooding Impacts via forecasts, and more. Be sure to read the legend carefully for detailed analysis.</p>
        <p>
        	&nbsp;
        </p>
        <p>
           The Experimental Flood Hazard Outlook (FHO) is intended to provide a coordinated inland hydrologic flood graphic for use in regional and national Impact-Based Decision Support Services (IDSS) briefings, and support broad messaging of flood threats to National Weather Service (NWS) stakeholders and Federal water partners.  This experimental product combines a depiction of current flood conditions based on the latest river level observations with an assessment of flood risk, leveraging various sources of NWS water resources forecast information.  This experimental product is issued twice daily, at 12Z and 21Z.  
        </p>
        <p>

        </p>


        <table>
          <tbody>
          
            <tr  >
              <td>
               <div style={{width: 50, height: 30}}></div>
              </td>
              <td><b >Potential Flooding Impacts</b></td>
            </tr>

            <tr  >
              <td>
               <div style={{background:"#ff4dd7", width: 40, height: 20}}></div>
              </td>
              <td>Catastrophic - exceedingly rare and widespread flash flood and/or river flooding expected</td>
            </tr>
            <tr  >
              <td>
                <div style={{background:"#669df0", width: 40, height: 20}}></div>
              </td>
              <td>Considerable - significant flash flooding and/or moderate/major river flooding expected</td>
            </tr>
            <tr  >
              <td>
                <div style={{background:"#d2efff", width: 40, height: 20}}></div>
              </td>
              <td>Limited - base flash flooding and/or minor river flooding is expected</td>
            </tr>
            <tr  >
              <td>
                <p />
              </td>
            </tr>
       
            <tr  style={{paddingTop: 15}}>
              <td>
               <div style={{width: 50, height: 30}}></div>
              </td>
              <td><b >Timing</b></td>
            </tr>

             <tr  >
              <td>
                <div style={{width: 40, height: 20, backgroundColor: 'white', backgroundImage: 'repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 5px, rgba(0, 0, 0, 0.8) 5px, rgba(0, 0, 0, 0.8) 6px)', backgroundSize: ' 8px 8px' }} />
              </td>
              <td>Through Day 3</td>
            </tr>
            <tr  >
              <td>
                  <div style={{width: 40, height: 20, backgroundColor: 'white', backgroundImage: 'repeating-linear-gradient(45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 5px, rgba(0, 0, 0, 0.8) 5px, rgba(0, 0, 0, 0.8) 6px)', backgroundSize:' 8px 8px'}}></div>
              </td>
              <td>Day 4 through 7</td>
            </tr>
            <tr  >
              <td style={{}}   >
               <div style={{width: 40, height: 20, backgroundColor: 'white', backgroundImage: 'repeating-linear-gradient(45deg, rgba(0, 0, 0, 0),rgba(0, 0, 0, 0) 5px, rgba(0, 0, 0, 0.7) 5px, rgba(0, 0, 0, 0.7) 6px),repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 5px, rgba(0, 0, 0, 0.7) 5px, rgba(0, 0, 0, 0.7) 6px)', backgroundSize:' 8px 8px, 8px 8px'}} ></div>
              </td>    
              <td>Through Day 7</td>
            </tr>

            <tr  >
              <td style={{}}   >
               <div style={{width: 40, height: 20}} ></div>
              </td>    
              <td>FHO updated around 7AM and 3PM Central Time. Each day (through Day 7) of the outlook is a 1200 UTC - 1200 UTC period.</td>
            </tr>
            
          </tbody>
        </table>

        <a   style={{ marginLeft: 40, marginBottom: 10}}    href={"https://water.noaa.gov/operations/fho"} target={"_blank"}>
            {" "}
            NWS National Water Prediction Service
          </a>
          

        <div style={{width: 50, height: 20}}></div>
        <p><b  >RIVER GAUGE STAGES</b></p>
        <p />
    <table style={{ width: "287pt", borderCollapse: "collapse", color: "white"}} >
      <tbody>
        <tr style={{ height: "15.75pt" }}>
          <td style={{ width: "77.0pt", border: "solid white 1.0pt", padding: "0in 5.4pt", height: "15.75pt" }} valign="bottom">
            <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
              <b><span style= {{ color: "white" }}>Forecasted Stage</span></b>
            </p>
          </td>
          <td style={{ width: "272.0pt", border: "solid white 1.0pt", borderLeft: "none", padding: "0in 5.4pt", height: "15.75pt" }} valign="bottom">
            <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
              <b><span style= {{ color: "white" }}> Description</span></b>
            </p>
          </td>
        </tr>
        <tr style={{ height: "30.0pt" }}>
          <td style={{ width: "77.0pt", border: "solid white 1.0pt", borderTop: "none", padding: "0in 5.4pt", height: "30.0pt" }} valign="bottom">
            <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
              <span style= {{ color: "white" }}>Major Flood</span>
            </p>
          </td>
          <td style={{ width: "272.0pt", borderTop: "none", borderLeft: "none", borderBottom: "solid white 1.0pt", borderRight: "solid white 1.0pt", padding: "0in 5.4pt", height: "30.0pt" }} valign="bottom">
            <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
              <span style= {{ color: "white" }}>Extensive inundation of structures and roads, significant evacuations of people and/or transfer of property to higher elevations.</span>
            </p>
          </td>
        </tr>
        <tr style={{ height: "30.0pt" }}>
          <td style={{ width: "77.0pt", border: "solid white 1.0pt", borderTop: "none", padding: "0in 5.4pt", height: "30.0pt" }} valign="bottom">
            <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
              <span style= {{ color: "white" }}>Moderate Flood</span>
            </p>
          </td>
          <td style={{ width: "272.0pt", borderTop: "none", borderLeft: "none", borderBottom: "solid white 1.0pt", borderRight: "solid white 1.0pt", padding: "0in 5.4pt", height: "30.0pt" }} valign="bottom">
            <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
              <span style= {{ color: "white" }}>Some inundation of structures and roads near stream, evacuations of people and/or transfer of property to higher elevations.</span>
            </p>
          </td>
        </tr>
        <tr style={{ height: "15.0pt" }}>
          <td style={{ width: "77.0pt", border: "solid white 1.0pt", borderTop: "none", padding: "0in 5.4pt", height: "15.0pt" }} valign="bottom">
            <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
              <span style= {{ color: "white" }}>Minor Flood</span>
            </p>
          </td>
          <td style={{ width: "272.0pt", borderTop: "none", borderLeft: "none", borderBottom: "solid white 1.0pt", borderRight: "solid white 1.0pt", padding: "0in 5.4pt", height: "15.0pt" }} valign="bottom">
            <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
              <span style= {{ color: "white" }}>Minimal or no property damage, but possibly some public threat (e.g., inundation of roads).</span>
            </p>
          </td>
        </tr>
        <tr style={{ height: "30.0pt" }}>
          <td style={{ width: "77.0pt", border: "solid white 1.0pt", borderTop: "none", padding: "0in 5.4pt", height: "30.0pt" }} valign="bottom">
            <p style={{ marginBottom: "0in", lineHeight: "normal", paddingBottom: "30%" }}>
              <span style= {{ color: "white" }}>Action</span>
            </p>
          </td>
          <td style={{ width: "272.0pt", borderTop: "none", borderLeft: "none", borderBottom: "solid white 1.0pt", borderRight: "solid white 1.0pt", padding: "0in 5.4pt", height: "30.0pt" }} valign="bottom">
            <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
              <span style= {{ color: "white" }}>An established gage height which when reached by a rising stream, lake, or reservoir represents the level where action is taken in preparation for possible significant hydrologic activity</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
       

        <p />
           <p> <b>Observed River Stages</b></p>

          <table>
            <tbody>
              <tr  >
                <td>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAsUlEQVQ4je3Tyw3DIAyA4f+QawZI18gAuMtkDcwaLFMyAGuQAVigh4Cqqm0UokpVq/rEQ3yyZdPx5uj+4HeCCtiyDsBczg6BF0Cm0/o+5iAxBwEMcG4FtWLTUBIcLH5x+KRS7h8y3QLN2MsNK1H3PqltBWXsZeP6eRzqcswB1gY1ga6UdVe2X1wF51ZQAeOTSsyBWr5PWrPTVhDW0dCYgynjAuBeYXvAmunu+I2//FnwCh+xN+Puqtx/AAAAAElFTkSuQmCC"
                    alt="Major Flood"
                  />
                </td>
                <td>Major Flood</td>
              </tr>
              <tr  >
                <td>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAjklEQVQ4je3TSwoDIQyA4X8x9zI92cSTNXOzLkzQYVB0KJSWZuMj+JGgbrw5tj/4naACu88NOHzvFvgEJE4biIEACXisghpYlLcDuSTE85dKR2CSBouItZbpEigySPbi1i3baZgHs3qHbdu5SscqqEDScrNIs+mmroJQnoYaJKtm7mEzYFPUXPzGX/4s+AKAUx1MCMvW+QAAAABJRU5ErkJggg=="
                    alt="Moderate Flood"
                  />
                </td>
                <td>Moderate Flood</td>
              </tr>
              <tr  >
                <td>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAo0lEQVQ4je3Tyw3CMAyA4f/QWWCMmHuZKc5M6R0zRjMNhzgghFo1FRIC4Uselj7ZcjLw5hj+4HeCCkTfG3D1u13gBRA9uzYjVhAgAKdeUBsWx3oRR0gTaEY8/1LpGhjk8MBatLNmYi8oclzJLsSuKdtcl14weVtPbacJrAB12l2gAkEzYjO09jXfq9NeEOrTUCsEfy4AaQnbArZKN8dv/OXPgjcHDSqwhQAg4wAAAABJRU5ErkJggg=="
                    alt="Minor Flood"
                  />
                </td>
                <td>Minor Flood</td>
              </tr>
              <tr  >
                <td>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAlklEQVQ4je2TSwrDMAxE3yL3snIySyfL5GZdVE4pwWkcCqWls/FH+DGD5Ik3a/oDvxPoQM29gDXvLgEXwDyfS5iEAQWYR4HeYDX91QoR4I5lfef0CFjMHrCmdnanjgLN7KDa0aUuS/dlFBgZ6yl2xAZcR4EOFHdMghY/Oy46o/Mq8gy4RMlxAYge7AywOT2t3/jLnwXeAPSmKknUMOh/AAAAAElFTkSuQmCC"
                    alt="Action"
                  />
                </td>
                <td>Action</td>
              </tr>
              <tr  >
                <td>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAkElEQVQ4je3TSw6DMAxF0TtgXzEri7MyzM4Y1HGp+AijSlWreoBIIo6eFTPw5hr+4HeCClR/N2D2vVvgBEh8bgiGAAUYs6AG1vNVoAGK+Pkm6RlYkBXWq6+VmgUFOTk9qHu3bKtnAmze1mvbLag5CypQUASDaF8jnWZBeIyGYhQfl55xF7sCPjNdrN/4lz8LLnNWHUwMQVG3AAAAAElFTkSuQmCC"
                    alt="No Flood"
                  />
                </td>
                <td>No Flood</td>.
              </tr>
              <tr  >
                <td>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAvklEQVQ4je3TsQ3CMBBA0V8wBmyQBQKFzRRhkFDHqZ1BnCmAIvEC3iAZhMK2EAKiOEJCIK6yz9LTne684s2x+oPfCSqgCuczcAm5ReAJkPnhCMDoOjm4XgIC2KeCKmJ5UfpMUWLbBmu0DO8PlU6BYpNtb1iIeLdGV6mgXGe7iefnsWjKo+vADygJrENbd23btmFwPfhpJ4EKENZoObqO2L41OlanUkHwq6EG14uwLgD1K2wOGCudHb/xlz8LXgHA6DgEvOgftQAAAABJRU5ErkJggg=="
                    alt="Flood Category Not Defined"
                  />
                </td>
                <td>Flood Category Not Defined</td>
              </tr>
              <tr  >
                <td>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAtUlEQVQ4je3TMQ7CMAxA0T906MjavRcoF0jYequmt/IGvUB6gexZM2ZkaCKEgKqpkBAIT0ksPdmyU/HmqP7gd4IGGNL5AkzpbRd4BnR/PADgfNTORw0o4FQKmoz13QLSgcwBsUGn/EOla6Bqm/qGpch3sWEoBXXb1Cvp57Frys5HWAZUBI6prbu2ZQ4ZnEpBAyixQTsfye2LDbk6UwrCshrG+ajSugCMr7AtYK50c/zGX/4seAWDYjjgVTJaHgAAAABJRU5ErkJggg=="
                    alt="Low Water Threshold"
                  />
                </td>
                <td>Low Water Threshold</td>
              </tr>
              <tr  >
                <td>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAs0lEQVQ4je3T0QnCMBCA4f+h+7ROkDhIK52k6SQh6SDqBMlt5EMTQaqlKYIo3lOSg4877lLx5qj+4HeCBhjS+QJc09su8AzoU98BEKNoCaIBBRxLQZOxrm8B6GjxdsJZr1N+UekaqOpDfcdy5LuzfigFddPUK+nnsWvKMQrMAyoCx9TWQ9veTkgQmKddBBpAOet1jEJu31mfqzOlIMyrYSSISusCML7CtoC50s3xG3/5s+ANJ/83rKvk9loAAAAASUVORK5CYII="
                    alt="Data Not Current"
                  />
                </td>
                <td>Data Not Current</td>
              </tr>
              <tr  >
                <td>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAApUlEQVQ4je3TzQ3CMAyG4ffQJdI1MkDCZE0nAwb41rDX4NAECRWqpkJCIHzKj/TIjp2BN8fwB78TLMBU1xfgWs8OgWcgxxgBcPdsZhlIwKkXLA1rIIAkJOV6v8p0C0zjOD5gwH0vaeoFcwhh4/p5HOqyu8PSoC5wrmWt3tDMYOl2F1iAJCm7O618SS270gvCMhrFzFIdF4D5FbYHbJnujt/4y58Fb5n/OIGYCY/tAAAAAElFTkSuQmCC"
                    alt="Out of Service"
                  />
                </td>
                <td>Out of Service</td>
              </tr>
            </tbody>
          </table>
          <p><span style={{ marginLeft: 40, fontSize: '.8em'}}> River gauges update frequency: 30 Minutes</span></p>



           <p style={{ marginTop: 40}}> <b>Forecasted River Stages</b></p>

              <p>River forecast data from the National Weather Service's National Water Prediction Service (NWPS) provide valuable information about the chances of flood occurring. These river stage/flow forecasts show the maximum forecast flood category.</p>

                <p>The majority of the observed water level data displayed on the NWPS web pages originates from the U.S. Geological Survey (USGS) National Streamflow Information Program, which maintains a national network of streamgages. In addition, real-time water level information is collected from other federal, state, and local streamgage networks.</p>    


          <table>
            <tbody>



              <tr  >
                <td>
                  <div className="legend-circle" style={{marginLeft: 10, marginRight: 7, borderRadius: '50%', width: 7, height: 7,  backgroundColor: 'transparent', boxShadow: '0px 0px 0px 4px rgba(204, 51, 255, .7)'}}></div>
                </td>
                <td>Major Flood</td>
              </tr>
              <tr  >
                <td>
                  <div className="legend-circle" style={{marginLeft: 10, marginRight: 7, borderRadius: '50%', width: 7, height: 7, backgroundColor: 'transparent', boxShadow: '0px 0px 0px 4px rgba(255, 0, 0, .7)'}}></div>
                </td>
                <td>Moderate Flood</td>
              </tr>
              <tr  >
                <td>
                  <div className="legend-circle" style={{marginLeft: 10, marginRight: 7, borderRadius: '50%', width: 7, height: 7, backgroundColor: 'transparent', boxShadow: '0px 0px 0px 4px rgba(255, 165, 0, .7)'}}></div>
                </td>
                <td>Minor Flood</td>
              </tr>
    
            </tbody>
          </table>

          <a   style={{ marginLeft: 40, marginBottom: 10}}    href={"https://www.drought.gov/about/partners/national-weather-service-nws"} target={"_blank"}>
            {" "}
            NWS National Water Prediction Service
          </a>

          
          <p  style={{marginTop: 30}}> <b>Significant River Flooding Outlooks</b></p>
          <p>          Provide a general outlook for significant river flooding. It is not intended to depict small-scale events such as localized flooding and/or flash flooding, nor will it depict coastal and/or tidal flooding.
 		  </p>
           <table style={{marginLeft: 30}}>
            <tbody>

              <tr >
                <td>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAVklEQVQ4je3TsQnAMAwF0TN4r8ibOZPpZ7M0wbi2VMQkV6p4CIQqydVvgxa0NIOeAJ5AHxuagfua1BpIHLDbUX7wZeAlYaXkgZ3ndQJpBscg2j5HSesGmnwM0xyYl8QAAAAASUVORK5CYII="
                    alt="Possible"
                  />
                </td>
                <td>Possible</td>
                              <p style={{marginBottom: '.0001pt', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: ''}}>Weather conditions indicate that significant
                  flooding could occur. Such flooding is neither certain nor imminent.</span></p>
              </tr>
              <tr >
                <td>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAWUlEQVQ4jWNhoDJgGdkGOlBo1gFkA/dTwcBGBgaGBrgLHSQZGPZ7kWeS4zYGhgPPGewZGIZapIwaOMgMPHjgOYMD41zqGdjAAM06FIADyAbCBSgFQydSqAYA31cNlt9cSacAAAAASUVORK5CYII="
                    alt="Likely"
                  />
                </td>
                <td>Likely</td>
                              <p style={{marginBottom: '.0001pt', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: ''}}>Weather conditions indicate that significant
                  flooding can be expected during the outlook period.</span></p>
              </tr>
              <tr >
                <td>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAaElEQVQ4je3SMQqAMAyF4X/ovZqezHoy48l0qEIUpySL0AclQ+EjkFdITpmgOB39Arcg2CwogBx+SYAO9BtcvKvJ9RSq3TAtE8wDdwVRfEXUMVYLdqC2WLHVgjCaHgF5g48Pb/5z5bScitkSEi4jiU0AAAAASUVORK5CYII="
                    alt="Occurring"
                  />
                </td>
                <td>Occurring</td>
                              <p style={{marginBottom: '.0001pt', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: ''}}>Significant flooding is already occurring or is
                  forecast to occur during the outlook period.</span></p>
              </tr>
            </tbody>
          </table>
          <p>Updated: Daily at 20:00Z</p>
          <a  style={{marginLeft: 30}} href={"https://www.wpc.ncep.noaa.gov/nationalfloodoutlook/"} target={"_blank"}>
            {" "}
            Significant River Flooding source
          </a>

          <p style={{ marginTop: 40}}> <b>NWS Hazard Alerts</b></p>
<div className="nws-legend-swatch">
  <div
    className="legend-item"
    style={{ display: "flex", flexDirection: "row" }}
  >
    <div className="nwscolor" style={{ backgroundColor: "rgb(124, 252, 0)", width: 30, marginLeft: 20, marginRight: 10, marginTop: 2 }}>
      &nbsp;
    </div>
    <a
      href="//forecast.weather.gov/wwamap/wwatxtget.php?cwa=usa&wwa=Coastal%20Flood%20Advisory"
      target="_blank"
    >
      Coastal Flood Advisory
    </a>
  </div>	
  <div
    className="legend-item"
    style={{ display: "flex", flexDirection: "row" }}
  >
    <div className="nwscolor" style={{ backgroundColor: "rgb(107, 142, 35)", width: 30, marginLeft: 20, marginRight: 10, marginTop: 2 }}>
      &nbsp;
    </div>
    <a
      href="//forecast.weather.gov/wwamap/wwatxtget.php?cwa=usa&wwa=Coastal%20Flood%20Statement"
      target="_blank"
    >
      Coastal Flood Statement
    </a>
  </div>
  <div
    className="legend-item"
    style={{ display: "flex", flexDirection: "row" }}
  >
    <div className="nwscolor" style={{ backgroundColor: "rgb(0, 255, 127)", width: 30, marginLeft: 20, marginRight: 10, marginTop: 2 }}>
      &nbsp;
    </div>
    <a
      href="//forecast.weather.gov/wwamap/wwatxtget.php?cwa=usa&wwa=Flood%20Advisory"
      target="_blank"
    >
      Flood Advisory
    </a>
  </div>
  <div
    className="legend-item"
    style={{ display: "flex", flexDirection: "row" }}
  >
    <div className="nwscolor" style={{ backgroundColor: "rgb(0, 255, 0)", width: 30, marginLeft: 20, marginRight: 10, marginTop: 2 }}>
      &nbsp;
    </div>
    <a
      href="//forecast.weather.gov/wwamap/wwatxtget.php?cwa=usa&wwa=Flood%20Warning"
      target="_blank"
    >
      Flood Warning
    </a>
  </div>
  <div
    className="legend-item"
    style={{ display: "flex", flexDirection: "row" }}
  >
    <div className="nwscolor" style={{ backgroundColor: "rgb(46, 139, 87)", width: 30, marginLeft: 20, marginRight: 10, marginTop: 2 }}>
      &nbsp;
    </div>
    <a
      href="//forecast.weather.gov/wwamap/wwatxtget.php?cwa=usa&wwa=Flood%20Watch"
      target="_blank"
    >
      Flood Watch
    </a>
  </div>
  <div
    className="legend-item"
    style={{ display: "flex", flexDirection: "row" }}
  >
    <div className="nwscolor" style={{ backgroundColor: "rgb(144, 238, 144)", width: 30, marginLeft: 20, marginRight: 10, marginTop: 2 }}>
      &nbsp;
    </div>
    <a  
      href="http://forecast.weather.gov/wwamap/wwatxtget.php?cwa=usa&wwa=Hydrologic%20Outlook"
      target="_blank"
    >
      Hydrologic Outlook
    </a>
  </div>
 
</div>


<p style={{ marginTop: 40}}> <b>5 Days - Max Flood Status Forecast</b></p>
<p>Depicts maximum forecast streamflow over the next 5 days derived from the official River Forecast Center (RFC) forecast routed downstream through the National Water Model (NWM) stream network. Maximum streamflows are available downstream of RFC forecast points whose forecast reaches action status or greater. Updated hourly.</p>
<table  style={{ marginLeft: 10}}>
  <tbody>
    <tr >
      <td>
        <img style={{ marginLeft: 10}}
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAPUlEQVQ4jWNhoDJgGTWQYsBCuRGoYNRAKoXhGeP//6lgFoPJWUZGFhiDgUqAhVoGwcCogZQDFiqYMcQMBAAx3gVUiWnFZgAAAABJRU5ErkJggg=="
          alt="Major"
        />
      </td>
      <td style={{ marginLeft: 10}}>Major</td>
    </tr>
    <tr >
      <td>
        <img style={{ marginLeft: 10}}
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAOUlEQVQ4jWNhoDJgGTWQYsBCuRGoYNRAKoXhfzCiHDAyMDCywBgMVAIs1DIIBkYNpBywUMGMIWYgAFyaAlPvXayzAAAAAElFTkSuQmCC"
          alt="Moderate"
        />
      </td>
      <td style={{ marginLeft: 10}}>Moderate</td>
    </tr>
    <tr >
      <td>
        <img style={{ marginLeft: 10}}
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAPElEQVQ4je3QIQ4AMAxCUZpw151pp+1URTWYJXyFegLCHAPKUSd2AU0f9kUbLNRBcQZM0QVNAfVoMD4DH4bDA7p5H5D2AAAAAElFTkSuQmCC"
          alt="Minor"
        />
      </td>
      <td style={{ marginLeft: 10}}>Minor</td>
    </tr>
    <tr >
      <td>
        <img style={{ marginLeft: 10}}
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAOUlEQVQ4je3QIQ4AQAwCQZrw/y9zquI0mCasQo2ACMeCdvSJv4KhDyUoYGEGwx0IxRS0FfRjwDgGPoNSA1RRBNyVAAAAAElFTkSuQmCC"
          alt="Action"
        />
      </td>
      <td style={{ marginLeft: 10}}>Action</td>
    </tr>
  </tbody>
</table>

    <a  style={{marginLeft: 30, marginBottom:20}}
      href="https://www.weather.gov/media/owp/operations/rfc_fim_fact_sheet.pdf"
      target="_blank"
    >
      Max Flood Status Forecast
    </a>

        <td>
        </td>

        <h5 className="legend-source" style={{marginLeft: 20, marginTop:20}}>
          Source:
          <a href={"https://water.noaa.gov/operations/fho"} target={"_blank"}>
            {" "}
            NOAA
          </a>
        </h5>
        <hr />
      </div>
  );
}


export default FHO;


