import React, { useEffect, useRef } from 'react';
import { Col } from 'react-bootstrap';

const CylindricalLithologyChart = ({ data, reportData }: any) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Set canvas dimensions
    canvas.width = 350;
    canvas.height = 340 + (data.length*12);

    const padding = 25; // Add padding at the top and bottom

    // Calculate total height and apply padding
    const totalHeight = data.reduce((sum: number, segment: any) => sum + segment.height, 0);
    const scaleFactor = (canvas.height - 2 * padding) / (totalHeight + (data.length*17)); // Scale to fit within padding
   
    let currentY = padding; // Start drawing after padding
    const minSegmentHeight = 12; // Ensure text has space to fit
    const segmentSpacing = 2; // Adds space between segments
    const leftPadding = 20;

    let title = reportData && reportData[0] && reportData[0].gw_logid || "";
    ctx.fillStyle = 'black';
    ctx.font = 'bold 16px Arial';
    ctx.textBaseline = 'middle';
    ctx.fillText(title, leftPadding + 5, 10);

    data.forEach((segment: any) => {
      const { color, height, label } = segment;
      let scaledHeight = height * scaleFactor;

      // Ensure small segments are still visible
      if (scaledHeight < minSegmentHeight) {
        scaledHeight = minSegmentHeight;
      }

      // Draw lithology rectangle
      ctx.fillStyle = color;
      ctx.fillRect(leftPadding, currentY, 100, scaledHeight);

      // Handle text placement
      ctx.fillStyle = 'black';
      ctx.font = '14px Arial';
      ctx.textBaseline = 'middle'; // Aligns text properly

      if (scaledHeight >= 1216) {
        // Draw label inside the segment if there's space
        ctx.fillText(label, leftPadding + 5, currentY + scaledHeight / 2);
      } else {
        // Draw label outside for small segments
        ctx.fillText(label, leftPadding + 110, currentY + scaledHeight / 2);
      }

      // Draw segment border
      ctx.strokeStyle = 'black';
      ctx.lineWidth = 2;
      ctx.strokeRect(leftPadding, currentY, 100, scaledHeight);

      currentY += scaledHeight + segmentSpacing; // Add spacing between segments
    });
  }, [data]);

  return (
    <Col className="d-flex flex-row justify-content-around lithology-chart">
      <Col className="canvas-container">
        <canvas ref={canvasRef} />
      </Col>
    </Col>
  );
};

export default CylindricalLithologyChart;
