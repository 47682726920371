import { useEffect, useState } from 'react' 
import { Line } from 'react-chartjs-2';

const LineChart = ({ filteredData, title }: any) => {

  const [randomColor, setRandomColor] = useState('transparent')
  
  const timestamps = filteredData.map((data:any) => new Date(parseInt(data.Timestamp)).toLocaleString('en-US', { timeZone: 'UTC' })).reverse();
  const values = filteredData.map((data:any) => parseFloat(data.value)).reverse();

  const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    setRandomColor(generateRandomColor())
  }, [title])

  const data = {
    labels: timestamps,
    datasets: [
      {
        label: title,
        data: values,
        fill: false,
        borderColor: randomColor, 
        backgroundColor: randomColor, 
        pointRadius: 4, 
        pointHoverRadius: 5, 
        pointBackgroundColor: randomColor, 
        pointBorderColor: 'white', 
        tension: 0.1,
      },
    ],
  };

  console.log(data)
  
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: title,
          color: 'white',
          font: {
            size: 16,
          },
        },
        zoom: {
          enabled: true,
          mode: 'x',
        },
        ticks: {
          color: 'white',
        },
      },
      x: {
        display: true,
        title: {
          display: true,
          text: 'Date',
          color: 'white',
          font: {
            size: 16,
          },
        },
        ticks: {
          color: 'white',
        },
      },
    },
    plugins: {
      legend: {
        position: 'top' as const,
      } as const,
      title: {
        display: true,
        color: 'white',
        text: title,
        font: {
          size: 18,
        },
      } as const,
    } as const,
  } as const;

  return <Line data={data} options={options} />
};

export default LineChart;
