import { Layer, Source } from 'react-map-gl'

const LineLayer = (props: any) => {

    const {
        id,
        opacity,
        beforeId = '',
        lineColor,
        tileSet,
        sourceLayer,
        width,
        minzoom = 0
    } = props

    const VectorLineLayer: any = {
        id: `${id}-layer`,
        type: "line",
        "source-layer": `${sourceLayer}`,
        paint: {
            "line-opacity": opacity,
            "line-color": `${lineColor}`,
            'line-width': width,
        },
        minzoom: minzoom
    };

    return (
        <>
            <Source
                id={`${id}-layer`}
                type="vector"
                url={tileSet}
            >
                <Layer
                    beforeId={beforeId}
                    {...VectorLineLayer}
                />
            </Source>
        </>
    )
}

export default LineLayer;  
