// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layer-info {
  color: white;
  width: 150px;
  padding: 6px;
  border-radius: 4px;
  text-align: center;
  position: relative;
  height: 50px; }
`, "",{"version":3,"sources":["webpack://./src/components/bia-styles.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,YAAY,EAAA","sourcesContent":[".layer-info {\n    color: white;\n    width: 150px;\n    padding: 6px;\n    border-radius: 4px;\n    text-align: center;\n    position: relative;\n    height: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
