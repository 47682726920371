import imga from '../../../assets/nhd-layer.svg'
import checkCircleImg from '../../../assets/check-circle.svg'
import img from '../../../assets/layer/layer_NOAAMix.png'

export const MCLayerItem = ({ global, top, layer }: any) => {

  const handleSelect = () => {
 
    setTimeout(() => {
      if(layer && layer.setSelected && global[layer.setSelected]){
          global[layer.setSelected](!global[layer.selected]);
      }         
    }, 111);
  };

    return (
        <>           
          <div style={{position: 'absolute', top: top, right: 10}} className="mapboxgl-ctrl mapboxgl-ctrl-group">
          	<div className="tools-box">
              <button onClick={handleSelect} className={(layer && layer.selected && global[layer.selected]) ? "map-layeritem-button map-layeritem-button-active" : "map-layeritem-button" }>
                <span className="mapboxgl-ctrl-icon" aria-hidden="true" title={(layer.name) ? layer.name : "Toggle Map layer"}>
                  <img className='layer' src={require(`../../../assets/${layer.image}`)} />                                
                </span>	                            
              </button>
            </div>
          </div>
        </>
    )
}
                        
export default MCLayerItem