// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outer-bar {
  width: 200px;
  height: 25px;
  position: absolute;
  z-index: 60;
  background-color: white;
  left: 45%;
  top: 45%;
  padding: 0; }
  .outer-bar .inner-bar {
    background-color: #4fc22c;
    height: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    transition: width 0.3s ease-in-out;
    width: 0; }
  .outer-bar:hover .inner-bar {
    width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/components/LoadingBar/loading-bar.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,uBAAuB;EACvB,SAAS;EACT,QAAQ;EACR,UAAU,EAAA;EARZ;IAWI,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,kCAAkC;IAClC,QAAQ,EAAA;EAjBZ;IAsBM,WAAW,EAAA","sourcesContent":[".outer-bar {\n  width: 200px;\n  height: 25px;\n  position: absolute;\n  z-index: 60;\n  background-color: white;\n  left: 45%;\n  top: 45%;\n  padding: 0;\n\n  .inner-bar {\n    background-color: #4fc22c;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    position: absolute;\n    transition: width 0.3s ease-in-out;\n    width: 0; \n  }\n\n  &:hover {\n    .inner-bar {\n      width: 100%; \n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
