// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.api-container-map-container {
  height: 100%;
  width: 100%;
  padding: 1rem; }
  .api-container-map-container .api-map-container {
    padding: 1rem;
    height: 100%; }
    .api-container-map-container .api-map-container .map {
      width: 95vw;
      height: 40vh;
      border-radius: 4px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(255, 255, 255, 0.1); }
  .api-container-map-container .select-menu-container {
    background-color: #0B1735;
    height: 84px;
    width: 25%;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4), 0px 2px 6px rgba(255, 255, 255, 0.05); }
    .api-container-map-container .select-menu-container .dataset-label {
      text-align: center;
      width: 100%;
      color: white;
      margin-bottom: 0; }
    .api-container-map-container .select-menu-container .dataset-select-menu {
      width: 60%;
      margin: auto; }
  .api-container-map-container .api-table-container {
    overflow: auto;
    padding: 1rem; }
  .api-container-map-container .footer {
    position: absolute;
    bottom: 3%;
    right: 3%; }
`, "",{"version":3,"sources":["webpack://./src/features/reports/APITableReport/api-table-report.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,WAAW;EACX,aAAa,EAAA;EAHjB;IAKQ,aAAa;IACb,YAAY,EAAA;IANpB;MAQY,WAAW;MACX,YAAY;MACZ,kBAAkB;MAClB,6EAA6E,EAAA;EAXzF;IAiBQ,yBAAyB;IACzB,YAAY;IACZ,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,kFAAkF,EAAA;IAtB1F;MAwBY,kBAAkB;MAClB,WAAW;MACX,YAAY;MACZ,gBAAgB,EAAA;IA3B5B;MA+BY,UAAU;MACV,YAAY,EAAA;EAhCxB;IAqCQ,cAAc;IACd,aAAa,EAAA;EAtCrB;IA0CQ,kBAAkB;IAClB,UAAU;IACV,SAAS,EAAA","sourcesContent":[".api-container-map-container {\r\n    height: 100%;\r\n    width: 100%;\r\n    padding: 1rem;\r\n    .api-map-container {\r\n        padding: 1rem;\r\n        height: 100%;\r\n        .map {\r\n            width: 95vw;\r\n            height: 40vh;\r\n            border-radius: 4px;\r\n            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(255, 255, 255, 0.1);\r\n        }\r\n    \r\n    }\r\n\r\n    .select-menu-container {\r\n        background-color: #0B1735;\r\n        height: 84px;\r\n        width: 25%;\r\n        margin: auto;\r\n        border-radius: 8px;\r\n        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4), 0px 2px 6px rgba(255, 255, 255, 0.05);\r\n        .dataset-label {\r\n            text-align: center;\r\n            width: 100%;\r\n            color: white;\r\n            margin-bottom: 0;\r\n        }\r\n\r\n        .dataset-select-menu {\r\n            width: 60%;\r\n            margin: auto;\r\n        }\r\n    }\r\n\r\n    .api-table-container {\r\n        overflow: auto;\r\n        padding: 1rem;\r\n    }\r\n\r\n    .footer {\r\n        position: absolute;\r\n        bottom: 3%;\r\n        right: 3%;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
