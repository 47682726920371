import { Source, Layer, Popup } from 'react-map-gl';
import settings from "../../../settings.json";
import { useState, useContext } from 'react';
import { RefContext } from "../../../RefContext";
import { PointContext } from "../../../PointContext";
import { v4 as uuidv4 } from 'uuid';

const FemaFirm = ({ id, url, opacity, global }: any) => {
  let rangeSel = "nws";  
  let categorySel = "observed";  
  let _currentUrl = `https://hazards.fema.gov/arcgis/rest/services/FIRMette/NFHLREST_FIRMette/MapServer/export?bbox={bbox-epsg-3857}&dpi=96&transparent=true&format=png32&layers=show:0,2,4,5,7,8,9,12,13,14,15,18,19,20,21&bboxSR=102100&imageSR=102100&f=image`

  const [currentUrl, setCurrentUrl] = useState<any>(_currentUrl);
  const [currentRange, setCurrentRange] = useState<any>(rangeSel);
  const [currentCategory, setCurrentCategory] = useState<any>(categorySel);  

  const { currentRef } = useContext(RefContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
  // @ts-ignore
  const mapInstance = currentRef && currentRef?.current;

  //National Flood Hazard Layer (NFHL)

  //current effective flood hazard data. FEMA provides the flood hazard data to support the National Flood Insurance Program. You can use the information to better understand your level of flood risk and type of flooding. 

  // NFHL digital data covers over 90% of the U.S. population

  // https://hazards.fema.gov/arcgis/rest/services/FIRMette/NFHLREST_FIRMette//MapServer/WMSServer?request=GetCapabilities&service=WMS

  // JSON, geoJSON, PBF

  // The NFHL data are from FEMA’s FIRM databases.

  // https://hazards-fema.maps.arcgis.com/home/item.html?id=35e0a4cbf41048728e4009c1424473f0
  
  //https://gitc.earthdata.nasa.gov/wmts/epsg4326/best/MODIS_Combined_Flood_3-Day/default/250m/{z}/{x}/{y}.png
  //https://gitc.earthdata.nasa.gov/wmts/epsg4326/best/MODIS_Combined_Flood_3-Day/default/250m/1/1/1.png

  return (
    <Source
      id={`${id}-layer`}
      key={`${id}-layer`+'currentRange'}
      type="raster"
      tiles={[currentUrl]}
      tileSize={256} >
      <Layer      
        beforeId={settings.defaultBeforeId}
        paint={{ 'raster-opacity': opacity }}
        type="raster" id={`${id}-layer`}
        source={`${id}-layer`}       
      />
    </Source>
  )
}

export default FemaFirm