import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import splashqi from '../../assets/splashqi.svg';
import splashBTN from '../../assets/splash-btn.svg';
import customerRestrictions from '../../features/account/customerRestrictions.json';
import { putReport, setLocalReport, getCountryFromStorage, checkReportRestrictions } from '../../features/utils';

const SplashCard = ({
    img,
    id,
    text,
    url,
    heading,
    org, 
    topNav,
    global,
}: any) => {

    const [animation, setAnimation] = useState({});
    const [modal, setModal] = useState(false);
    const [strIdentifier, setStrIdentifier] = useState<any>()
    const [disabled, setDisabled] = useState(true);

    const renderRestrictions = () => {
        let disabled = true;
        const matchingRestriction = customerRestrictions.find(({ name }) => name === org);        
        if(org === "True Elements"){
            disabled = false;
        } else if (matchingRestriction && matchingRestriction.name !== "True Elements") {
            // @ts-ignore
            const matchingRoute = matchingRestriction.routes.find(route => route.route === url);
            if (matchingRoute) {
                setStrIdentifier(matchingRoute.identifier);               
                if (matchingRoute.reportAccess !== heading) {
                    disabled = true;
                } else {
                    disabled = false;
                }
            }
        } else {
            disabled = true; 
        }
       
        if(global && global.userRoles && global.userRoles.length>0){
            let userRoles = global.userRoles;    
            if(id === 'WatershedExplorer' && (userRoles.indexOf('digital-watershed')>-1)){
                disabled = false;
            } else if(id === 'ScenarioForecaster' && (userRoles.indexOf('scenario-forecaster')>-1)){
                disabled = false;
            } else if(id === 'AssessmentReporter' && (userRoles.indexOf('site-assessment-reporter')>-1)){
                disabled = false;
            } else if(id === 'DataMonitor' && (userRoles.indexOf('field-data-monitor')>-1)){
                disabled = false;
            }
          
            if((userRoles.indexOf('te-admin')>-1)||(userRoles.indexOf('aws_subscriber')>-1)){
                disabled = false;
            }
        }
        setDisabled(disabled)
    };

    useEffect(() => {
        renderRestrictions()
    }, [])

   useEffect(() => {
        renderRestrictions()
    }, [global.userRoles])

    const navigate = useNavigate()

    const animateEnter = () => {
        const style = {
            marginBottom: '1rem',
            transition: 'margin-bottom 0.3s ease',
        };
        setAnimation(style);
    };

    const animateLeave = () => {
        const style = {
            marginBottom: '0',
            transition: 'width 0.3s ease',
        };
        setAnimation(style);
    };

    useEffect(() => {
        const imgElement = document.getElementById(id);
        const mouseEnter = () => animateEnter();
        const mouseLeave = () => animateLeave();

        if (imgElement) {
            imgElement.addEventListener('mouseenter', mouseEnter);
        }

        if (imgElement) {
            imgElement.addEventListener('mouseleave', mouseLeave);
        }

        return () => {
            if (imgElement) {
                imgElement.removeEventListener('mouseenter', mouseEnter);
                imgElement.removeEventListener('mouseleave', mouseLeave);
            }
        };
    }, [id]);



    return (
        <Col
            className="col-2 splash-img-container"
            id={id}
            onClick={() => {
                if (!disabled) {
                    //setLocalReport(url);
                    //global.setNavTopMenu(topNav);
                    navigate(`${url}${strIdentifier ? '?ent=1&ccds=' + strIdentifier : ''}`)
                }
            }}
            onMouseEnter={() => { disabled && setModal(true) }}
            onMouseLeave={() => { disabled && setModal(false) }}

        >
            <div
                style={{ display: disabled ? 'block' : 'none' }}
                className="disabled"
            >
            </div>
            <img
               
                className="splash-img"
                src={img}
                alt="alt_img"

            />
            <div className="splash-text-box">
                <div className="splash-heading">
                    <h4>
                        <img
                            className="splash-qi"
                            src={splashqi}
                        />
                        {heading}
                    </h4>
                </div>
                <div className="splash-text">
                    <p>{text}</p>
                </div>
                <button className='splash-btn'>
                    {disabled ? 'Get Access' : 'Go to product'} <img src={splashBTN} />
                </button>
            </div>
            {modal && (
                <div className='splash-modal'>
                    <p>
                        Interested in learning how this product
                        can address your needs?

                        <br />

                        Please contact your Customer Success Manager
                    </p>
                </div>
            )}
        </Col>
    );
};

export default SplashCard;