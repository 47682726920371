import { useContext, useState } from "react";
import { RefContext } from "../../../RefContext";
import { CatalogsContext } from "../../../CatalogsProvider";
import { AppContext } from "../../../AppContext";
import bbox from '@turf/bbox';
import centroid from '@turf/centroid';
import center from '@turf/center';
import { GeoJsonProperties,
           Point,
           FeatureCollection,
         } from 'geojson';

export const MCCenter = ({ global, top }: any) => {
  const { currentRef } = useContext(RefContext);
  const catalogsContext = useContext(CatalogsContext);
  const appContext = useContext(AppContext);

  const [browsedSources, setBrowsedSources] = useState<any[]>([]);

  const handleCenter = () => {
    // @ts-ignore
    const { current } = currentRef;
    const mapSourcesDefault = [
        "mapbox://mapbox.satellite",
        "composite",
        "mapbox-gl-draw-cold",
        "mapbox-gl-draw-hot",
        "huc12s-line"
    ];

    let sources = current && current.getStyle() && current.getStyle().sources;
    if(sources){
   
      let filteredArr =  Object.entries(sources).filter((k:any) => mapSourcesDefault.indexOf(k[0]) < 0 && k[1].type === 'geojson')
      if(filteredArr && filteredArr.length>0){ 
        let nextBrowse = filteredArr[0];
        let findBrowse = filteredArr.find((k:any) => browsedSources.indexOf(k[0]) < 0 )

        if(findBrowse){
          setBrowsedSources([...browsedSources,findBrowse[0]])
          nextBrowse = findBrowse;
        } else {
          setBrowsedSources([nextBrowse[0]])
        }

        //@ts-ignore
        let rFeatureSet = nextBrowse[1].data;
        if(rFeatureSet && rFeatureSet.features && rFeatureSet.features.length>0){
          let centroidR = center(rFeatureSet);
          if(centroidR && centroidR.geometry && centroidR.geometry.coordinates){
              current?.flyTo({
                essential: true,
                center: centroidR.geometry.coordinates,
                zoom: 8,
              });
          }          
        }
      }
    }
  };

    return (
        <>           
          <div style={{position: 'absolute', top: top, right: 10}} className="mapboxgl-ctrl mapboxgl-ctrl-group">
              	<div className="tools-box">
                    <button onClick={handleCenter}  className={"map-center-button"}>
                      <span className="mapboxgl-ctrl-icon" aria-hidden="true" title="Fly to data" />
                    </button>
                  </div>
              </div>
        </>
    )
}
                        
export default MCCenter