import { useEffect, useState, useRef, useCallback, useContext, Ref } from 'react';
import { Button, Form } from 'react-bootstrap';
import settings from '../../../settings.json';
import RightHeader from '../../../components/RightHeader';
import './styles.scss';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import {
  Map,
  useMap,
  MapRef,
  Popup,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  useControl,
  Layer,
  Source,
} from 'react-map-gl';
import type { LayerProps } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { FeatureCollection } from 'geojson';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import moment from 'moment';
import { DateTime } from 'luxon';
import { ToastContainer, toast } from 'react-toastify';
import MapAddition from  '../shared/MapAddition';


import assert from 'assert';
import {
    QueryParamProvider,
    useQueryParam,
    BooleanParam,
} from 'use-query-params';
import queryString from 'query-string';

import { HUC12InfoPoint } from '../../../types/HUC12InfoPoint';
import agent from '../../../api/agent';
import { parse } from 'papaparse';
import LoadingDataAnimation from '../../../components/LoadingDataAnimation';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import fullscreenIcon from '../../../assets/fullscreen-icon.svg';
import ellipsisIcon from '../../../assets/ellipsis-icon.svg';
import { message_hucrestriction, message_nodatalocation, message_noresult, message_erroroccured } from '../../../Constants';
import { AppContext } from '../../../AppContext';
import { CatalogsContext } from '../../../CatalogsProvider';
import { Location, useLocation } from 'react-router-dom';
import { setLocalReport } from '../../utils'
import MapLegend from '../shared/MapLegend';
import MapSlider from '../shared/MapSlider';
import MapTitle from '../shared/MapTitle';
import FilterState from '../shared/FilterState';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import ReportsConfigs from '../../../ReportsConfigs';
import { flyToInitialLocationHandler, getHuc12MembersOfHUC8 } from '../../utils';



import RenderLayers from '../shared/RenderLayers';
import RenderLayerMarkers from '../shared/RenderLayerMarkers';
import { RefContext } from '../../../RefContext'
import {
  createFeatureCollection,
  processStatusMessage,
  uniqueValues,
  fitBoundsMapData,
  groupByKey,
  clearFilters,
  startingValues
} from '../shared/report-data';
//import shpwrite from 'shp-write';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend);

export default function AGWaterTrueQIForecast(props: any) {
  const { global } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);
  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;

  const selectedHUC8Value = startingValues('huc', appContext.selectedHUC8, global);                                                    
  let selectedCategory = startingValues('ffl', 'Nitrogen', global);
  const selectedFilterDays = global.filterDaysSelected || 0;
  const selectedFilterLocations = startingValues('fl', '', global);
  let selectedDateRange = startingValues('frda', [
    new Date(new Date().setDate(new Date().getDate() - 5)),
    new Date(),
  ], global); 
  const locationField = 'Huc12';
  
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const [isFullScreenActive, setFullScreenActive] = useState(false);
  const fullscreenContainerHandler = () => {
    setFullScreenActive(!isFullScreenActive);
  };
  const [qsr, setqsr] = useQueryParam('r', BooleanParam);
  const { current: map } = useMap();
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [cursor, setCursor] = useState<string>('');
  const mapRef = useRef<MapRef>();
  const chartRef = useRef<any>();

  const [reportData, setReportData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [chartData, setChartData] = useState<any>(null);
  const [featureData, setFeatureData] = useState<FeatureCollection>();

  const [huc12Members, setHuc12Members] = useState<any[]>([]);
  const [huc12InfoCatalogArray, setHuc12InfoCatalogDataArray] = useState<any[]>([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapLegendData, setMapLegendData] = useState<any[]>([]);
  const [circleColorStyle, setCircleColorStyle] = useState<any>([]);
  const [mapHoverFilter, setMapHoverFilter] = useState<any>(['in', ['get', locationField], '']);
  const mapLegendColorScale = ['#1a9850', '#a6d96a', '#fee08b', '#d73027'];
  const mapLegendIntegerScale = [0, 3, 7, 10];
  const [userSelectedLocation, setUserSelectedLocation] = useState<any>();
  const [userSelectedLocations, setUserSelectedLocations] = useState<any>([]);
  const [usLocations, setUsLocations] = useState<any>([]);

  const [isDataReportLoaded, setIsDataReportLoaded] = useState(true);
  const [statusMessage, setStatusMessage] = useState<any>(null);

  const [currentDate, setCurrentDate] = useState<any>(null);
  const [cDate, setCDate] = useState<any>(null);
  const [currentDateData, setCurrentDateData] = useState([]);
  const [playState, setPlayState] = useState<string>();

  const [filterLabelDays, setFilterLabelDays] = useState<any>([]);

  const { setCurrentRef } = useContext(RefContext);

  const [dateRange, setDateRange] = useState<any[]>([])

  function formatDates(inputDates: any) {
    const outputDates: any = [];
    inputDates.forEach((inputDate: any) => {
      const dateObj = new Date(inputDate);
      const day = dateObj.getDate().toString().padStart(2, '0');
      const month = dateObj.toLocaleString('default', { month: 'short' });
      const year = dateObj.getFullYear();
      const formattedDate = `${day} ${month} ${year}`;
      outputDates.push(formattedDate);
    });
    return outputDates;
  }

  const [isDraw, setDraw] = useState(false);

  useEffect(() => { 
    if (global.dateRangeSelected){
      const formatted = formatDates(global.dateRangeSelected)
      setDateRange(formatted)
    }
  }, [global.dateRangeSelected])

  useEffect(() => {

    clearFilters(location.pathname, global, qsr||false);
    global.setfilterDaysSelected(0);
    if (global.trackerOn) global.setTrackerOn(false)
  }, []);

  useEffect(() => {
    setCurrentRef?.(mapRef)
  }, [mapRef])

  const Categories = ['Magnesium', 'Nitrogen', 'Phosphorous', 'Potassium', 'Sulfur'];

  useEffect(() => {
    setChartData([]);
    setReportData([]);
    setUserSelectedLocations([]);
  }, [selectedHUC8Value]);

  useEffect(() => {
    if (selectedFilterLocations && selectedFilterLocations.length > 0) {
      if (selectedFilterLocations[0].substr(0, 8) !== selectedHUC8Value)
        global.setfilterLocationsSelected([]);
    }
    const huc12Members = getHuc12MembersOfHUC8(selectedHUC8Value, catalogsContext.huc12Catalog);
    setHuc12Members(huc12Members);

      /*const selectedCategory = global.filter1Selected || 'Nitrogen';
      const selectedFilterDays = global.filterDaysSelected || 0;
      const selectedDateRange = global.dateRangeSelected || '';*/

  }, []);

  let selectedLocation = (popupInfo && popupInfo.HUC12) || ['literal', selectedFilterLocations];

  useEffect(() => {
    global.setUserLogged(true);
  }, []);

  useEffect(() => {
    const huc12InfoFileURL = '/huc12_info.csv';
    fetch(huc12InfoFileURL)
      .then(response => response.text())
      .then(responseText => {
        const data: HUC12InfoPoint[] | any[] = parse(responseText, {
          header: true,
        }).data;
        for (let dataItem of data) {
          if (dataItem.huc12.length < 12) {
            dataItem.huc12 = '0' + dataItem.huc12;
          }
        }
        setHuc12InfoCatalogDataArray(data);
      });
  }, []);

  useEffect(() => {
    if (huc12InfoCatalogArray && huc12InfoCatalogArray.length > 0) {
      const groupedresults = groupByKey<any, any>(reportData, i => i.Huc12);
      const filter_labels: any[] = [];
      for (const key in groupedresults) {
        let hucname = key;
        if (huc12InfoCatalogArray && huc12InfoCatalogArray.length > 0) {
          let huc12Item = huc12InfoCatalogArray.filter((o: any) => o['huc12'] === key);
          if(huc12Item&& huc12Item.length>0){
            hucname = huc12Item[0].name;
          }
        }
        filter_labels.push({ huc12: key, hucname: hucname });
      }

      const newLocations = filter_labels.sort((x: any, y: any) => (x.hucname < y.hucname ? -1 : 1));
      global.setfilterLocationsPopulated([{ huc12: 'All', hucname: 'All' }, ...newLocations]);
    }
    updateChartData(reportData);
  }, [reportData]);

  useEffect(() => {
    let filteredData = [...reportData];
    if (
      selectedFilterLocations &&
      selectedFilterLocations.length > 0 &&
      selectedFilterLocations.indexOf('All') < 0
    ) {
      filteredData = filteredData.filter(
        (o: any) => selectedFilterLocations.indexOf(o[locationField]) > -1
      );
    }
    if (reportData.length > 0) {
      updateChartData(filteredData);
    }
    setFilteredData(filteredData);
  }, [selectedFilterLocations, selectedCategory]);

  useEffect(() => {
    let filteredData = [...reportData];
    if (
      userSelectedLocations &&
      userSelectedLocations.length > 0 &&
      userSelectedLocations.indexOf('All') < 0
    ) {
      filteredData = filteredData.filter(
        (o: any) => userSelectedLocations.indexOf(o[locationField]) > -1
      );
    }
    if (reportData.length > 0) {
      updateChartData(filteredData);
    }
  }, [userSelectedLocations]);

  const updateChartData = (fData: any) => {
    const chartLabels = uniqueValues(fData, 'date').sort((x: any, y: any) => x - y);
    const groupedresults = groupByKey<any, any>(fData, i => i.Huc12);
    const chartdata_data: any[] = [];
    let chartdata_labels: any[] = [];
    for (const key in groupedresults) {
      let labelGroup = key;
      let customField = key;
      if (huc12InfoCatalogArray.length > 0) {
        const hucFilter = huc12InfoCatalogArray.filter((o: any) => o['huc12'] === key);
        if (hucFilter && hucFilter.length > 0) {
          labelGroup = hucFilter[0].name.substr(0, 10);
          customField = hucFilter[0].name;
        }
      }
      let dataObject = {
        label: labelGroup,
        custom: customField,
        data: groupedresults[key].map((x: any) => x[selectedCategory]),
        backgroundColor: '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6),
      };
      chartdata_labels = chartLabels.map((x: any) => moment(x).format('MMM DD'));
      chartdata_data.push(dataObject);
    }
    const chartdata = {
      labels: chartdata_labels,
      datasets: chartdata_data,
      borderColor: '#ff6384',
    };
    
    setChartData(chartdata);
  };

  useEffect(() => {
    if(statusMessage){
        global.notify(statusMessage)
    }
  }, [statusMessage]);

  useEffect(() => {
    setStatusMessage('');
    if (!isDataReportLoaded) {
      return;
    }
    if(selectedHUC8Value && global.hucRestrict.length>0 && (global.hucRestrict.indexOf(selectedHUC8Value) < 0)){
        return global.notify(message_hucrestriction) 
    }
   
    setIsDataReportLoaded(false);

    setFilterLabelDays([]);
    const daysToLimit = 67;
    let daysToQueryRange = 5;
    let startDate = '';
    const one_day = 1000 * 60 * 60 * 24;
    if(selectedDateRange && selectedDateRange.length>1){  
      const daysDiff = Math.floor((selectedDateRange[1]  - selectedDateRange[0])/one_day)+1; //+1
      daysToQueryRange = Math.min(daysDiff, daysToLimit);

      if(daysDiff > daysToLimit){
        global.notify('Queries limited to 60 days.');
      }

      startDate = DateTime.fromJSDate(new Date(selectedDateRange[1])).plus({days: 1}).toFormat('yyyyLLdd'); //.plus({days: 2})
    }
    daysToQueryRange = Math.max(1, daysToQueryRange);
    
    //const daysToQuery = Math.min(+selectedFilterDays, 59);  //15050100
    agent.Reports.AGWaterTrueQIForecast(selectedHUC8Value, daysToQueryRange, startDate).then((res: any) => {
      
      flyToInitialLocationHandler(selectedHUC8Value, mapRef, catalogsContext.huc8Catalog);
    
      if (!res || !res.length || res.length < 1) {
        setIsDataReportLoaded(true);
        setStatusMessage(message_noresult);
        //+++ Clear chart and map
        return;
      }

      let returnedData = [...res];
      if (!returnedData || !returnedData.length || returnedData.length < 1) {
        setIsDataReportLoaded(true);
        setStatusMessage('No result');
        return;
      }
      
      for (let dataItem of returnedData) {
        if(!dataItem['Prediction Timestamp']){
            dataItem['Prediction Timestamp'] = dataItem['Date'];
        }
        const elements = dataItem;
        if (elements) {  
          Object.keys(elements).forEach(function (key) {
            if(elements[key]==='None'){
              elements[key] = 0;
            }
          }) 
        }
      }


      const dateExtent = d3Array.extent<any, any>(returnedData, d => d["Prediction Timestamp"]);
      if (dateExtent && dateExtent[0] && dateExtent[1]) {
        let filterDays = [DateTime.fromMillis(dateExtent[0]).toLocaleString(), DateTime.fromMillis(dateExtent[1]).toLocaleString()];
        /*const dateTwoDays = DateTime.now().plus({days: 2})
        const dateDiff = dateTwoDays.diff(DateTime.fromMillis(dateExtent[1]), 'days').days;
         if(dateDiff>0){
          let fillForecastDay = Object.assign({}, returnedData[0]);
          fillForecastDay['Prediction Timestamp'] = dateTwoDays.toMillis();
          returnedData.push(fillForecastDay);
          filterDays[1] = dateTwoDays.toLocaleString();
        }  */
        setFilterLabelDays(filterDays);
      }

      returnedData = returnedData.sort(
        (x: any, y: any) => x['Prediction Timestamp'] - y['Prediction Timestamp']
      );

      const sumData = [...returnedData];
      for (let dataItem of sumData) {
        const elements = { ...dataItem };
        if (elements) {
          delete elements.Huc12;
          delete elements.Date;
          delete elements['Prediction Timestamp'];
          let sumData: any[] = [];
          Object.keys(elements).forEach(function (key) {
            if(elements[key]!=='None'){
              sumData.push(elements[key]);
            } else {
              sumData.push(0)
            }
          });
          dataItem.All = sumData.reduce((x, y) => x + y);
        }
      }

      const newCategories = [...Object.keys(returnedData[0]).slice(2).slice(0, -1)]; 
      let cleanCategories = newCategories.filter(time => time !== 'Prediction Timestamp' && time !== locationField && time !== 'Date');
      cleanCategories = ['All', ...cleanCategories];

      if (cleanCategories.indexOf(selectedCategory) < 0) {
        global.setfilter1Selected(cleanCategories[0]);
      } else {
        global.setfilter1Selected(selectedCategory)
      }
      global.setfilter1Populated(cleanCategories);  

      for (let dataItem of returnedData) {
        if(dataItem['Prediction Timestamp']){
          dataItem.datetime = new Date(dataItem['Prediction Timestamp']);
          dataItem.date = dataItem['Prediction Timestamp'];
        }
        if(dataItem['Date']){
          dataItem.datetime = new Date(dataItem['Date']);
          dataItem.date = dataItem['Date'];
        }       
      }
      
      setIsDataReportLoaded(true);
      for (let dataItem of returnedData) {
        dataItem.huc12 = dataItem.Huc12;
      }
      setReportData(returnedData);
      setFilteredData(returnedData);

      const groupedresults = groupByKey<any, any>(returnedData, i => i.Huc12);
      const filter_labels: any[] = [];
      for (const key in groupedresults) {
        let hucname = key;
        if (huc12InfoCatalogArray && huc12InfoCatalogArray.length > 0) {
          let huc12Item = huc12InfoCatalogArray.filter((o: any) => o['huc12'] === key);
          if(huc12Item&& huc12Item.length>0){
            hucname = huc12Item[0].name;
          }
        }
        filter_labels.push({ huc12: key, hucname: hucname });
      }
      const newLocations = filter_labels.sort((x: any, y: any) => (x.hucname < y.hucname ? -1 : 1));
      global.setfilterLocationsPopulated([{ huc12: 'All', hucname: 'All' }, ...newLocations]);
    }).catch((error) => {
      console.error(error);
      setIsDataReportLoaded(true);
      setStatusMessage(message_erroroccured);
      return;
    });
  }, [selectedDateRange, selectedHUC8Value]);

/*  useEffect(() => {
    const legendData = mapLegendColorScale.map((x: any, index: number) => ({
      typecode: mapLegendIntegerScale[index],
      color: mapLegendColorScale[index],
    }));
    setMapLegendData(legendData);
    updateChartData(reportData);

    updateFeatureCollection(reportData)
  }, [reportData]);
*/
  useEffect(() => {
    updateFeatureCollection(reportData)
  }, [huc12InfoCatalogArray]);

  useEffect(() => {
     updateFeatureCollection(reportData)
  }, [catalogsContext.huc12Catalog]);

  useEffect(() => {
    const legendData = mapLegendColorScale.map((x: any, index: number) => ({
      typecode: mapLegendIntegerScale[index],
      color: mapLegendColorScale[index],
    }));
    setMapLegendData(legendData);
    updateChartData(filteredData);

    updateFeatureCollection(filteredData)
  }, [filteredData]);

  const updateFeatureCollection = (reportData: any) => {
    if (reportData && catalogsContext.huc12Catalog && huc12InfoCatalogArray && catalogsContext.huc12Catalog.length > 0) {
        const featureCollectionFromReportData = createFeatureCollection(
          [...reportData],
          catalogsContext.huc12Catalog,
          huc12InfoCatalogArray,
          selectedHUC8Value
        );
        setFeatureData(featureCollectionFromReportData);
        if (featureCollectionFromReportData.features.length > 0) {
          if (mapRef && mapRef.current) {
            const mapRef_ = mapRef;
            setTimeout(() => {
              fitBoundsMapData(featureCollectionFromReportData, mapRef_);
            }, 400);
          }
        }
    }
  }


  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: 'Reading [lbs/acre]',
          color: 'white',
        },
      },
      x: {
        display: true,
        title: {
          display: true,
          text: 'Timestamp',
          color: 'white',
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
        },
        maxHeight: 27,
      } as const,
      title: {
        display: true,
        text: selectedCategory + ' Runoff [lbs/acre]',
        color: 'white',
        align: 'center',
        padding: 10,
        font: {
          size: 20,
        },
      } as const,
      tooltip: {
        padding: 10,
        bodyFont: {
          size: 24,
        },
        titleFont: {
          size: 24,
        },
        boxPadding: 8,
        usePointStyle: true,
        backgroundColor: '#12234f',
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || '';
            if (label) {
              label = [
                context.dataset.custom,
                selectedCategory + ' Runoff ' + d3Format.format('.4r')(context.parsed.y) + ' [lbs/acre]',
              ];
            }
            return label;
          },
        },
      } as const,
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      } as const,
    } as const,
  } as const;

  const onChartClickHandler = (event: any) => {
    const currentClick = getElementAtEvent(chartRef.current, event)[0];
    if (currentClick) {
      const currentClickMetaData = chartRef.current.getDatasetMeta(currentClick.datasetIndex);
      const currentSite = currentClickMetaData.label;
      let huc12selected = null;

      if (huc12Members) {
        let huc12data = [...huc12Members];
        for (let dataItem of huc12data) {
          if (dataItem.name && dataItem.name.substr(0, 10) === currentSite) {
            huc12selected = dataItem.huc12;
          }
        }
      }

      if (huc12selected) {
        if (userSelectedLocations.indexOf(huc12selected) > -1) {
          setPopupInfo(null);
          setUserSelectedLocations([]);
          setMapHoverFilter(['in', ['get', [locationField]], '']);
        } else {
          const siteData = filteredData.filter((o: any) => o[locationField] === currentSite);
          setPopupInfo(siteData[0]);
          setUserSelectedLocation(huc12selected);
          setUserSelectedLocations([huc12selected]);
          //setMapHoverFilter(['in', ['get', locationField], currentSite]);
        }
      }
    }
  };

  const layerStyle: LayerProps = {
    id: 'pointlayer',
    type: 'circle' as const,
    paint: {
      'circle-radius': 8,
      'circle-color': [
        'interpolate',
        ['linear'],
        ['get', selectedCategory],
        0,
        '#1a9850',
        1,
        '#66bd63',
        2,
        '#a6d96a',
        3,
        '#d9ef8b',
        4,
        '#ffffbf',
        5,
        '#fee08b',
        7,
        '#fdae61',
        8,
        '#f46d43',
        10,
        '#d73027',
      ],
      'circle-stroke-color': 'white',
      'circle-stroke-width': 1,
      'circle-opacity': 1,
    },
  };

  const hoverLayerStyle: LayerProps = {
    id: 'hoverlayer',
    type: 'circle' as const,
    paint: {
      'circle-radius': 10,
      'circle-color': '#000099',
      'circle-stroke-color': 'white',
      'circle-stroke-width': 1,
      'circle-opacity': 1,
    },
  };

  const onMouseEnter = useCallback((event: any | null) => {
    if (event.features && event.features[0] && event.features[0].properties) {
      setCursor('pointer');
      setPopupInfo(event.features[0].properties);
      setUserSelectedLocation(event.features[0].properties.Huc12);
      setMapHoverFilter(['in', ['get', locationField], event.features[0].properties.Huc12]);
    }
  }, []);

  const onMouseLeave = useCallback((event: any | null) => { 
    setCursor('');
   

    if (userSelectedLocation) {
      setUserSelectedLocation(null);
      setPopupInfo(null);
      setMapHoverFilter(['in', ['get', locationField], '']);
    }
    if (event && event.features && event.features[0]) {
      setPopupInfo(null);
      setMapHoverFilter(['in', ['get', locationField], '']);
    }
  }, []);

  useEffect(() => {
    if (usLocations.length > 0) {
      if (userSelectedLocations.indexOf(usLocations[0].Huc12) > -1) {
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', locationField], '']);
      } else {
        setUserSelectedLocations(usLocations[0].Huc12);
        setPopupInfo(usLocations[0]);
        setTimeout(() => {
          setMapHoverFilter(['in', ['get', locationField], usLocations[0].Huc12]);
        }, 555);
      }
    }
  }, [usLocations]);



     
  const onMapLoad = useCallback(() => {
    if (mapRef && mapRef.current) {
      setMapLoaded(true);
      let mp = mapRef.current.getMap(); 
      console.log(mapRef.current, mp)
      mapRef.current.resize();
    }
  }, []);





  const pointInPolygonUpdate = (pointsSelected: any) => {
        setUserSelectedLocations(pointsSelected);
        setMapHoverFilter(['in', ['get', locationField], ['literal', pointsSelected]]);
  }

  const onDelete = useCallback((e: any | null) => {
    if (mapRef && mapRef.current) {
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', locationField], '']);
    }
  }, []);

  const renderPopup = () => {
    return (
      <Popup
        longitude={Number(popupInfo.lng)}
        latitude={Number(popupInfo.lat)}
        onClose={() => setPopupInfo(null)}
      >
        <div className='popup-container'>
          <h4>{popupInfo['hucname']}</h4>
          <div className='popup-content'>
            <div className='popup-content-left'>
              <p>{selectedCategory} Runoff</p>  
            </div>
            <div className='popup-content-right'>
              <p>{d3Format.format('.4r')(popupInfo[selectedCategory])}</p>
            </div>
          </div>
        </div>
      </Popup>
    );
  };

  const deselectFilters = useCallback((e: any | null) => {
    setPopupInfo(null);
    setMapHoverFilter(['in', ['get', locationField], '']);
    setUserSelectedLocations([]);
  }, []);

  const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  const onHandleClick = useCallback((event: any | null) => {
    const feature = event.features && event.features[0];
    if (feature) {
      setUsLocations([feature.properties]);

    }
  }, []);

  return (
    <>
      <FullScreenComp handle={fullscreenhandle}>
        <div className='AGWaterTrueQIForecast' id='AGWaterTrueQIForecast-report'>
          <ReportHeader global={global} data={filteredData} filterLabelDays={dateRange}
            
            reportID={"AGWaterTrueQIForecast-report"} fullScreenClickHandle={fullscreenhandle.enter} />
          <div className='container'>
            <div className='col main-col'>
              <div className='row gx-5'>
                <div className='map-best-container'>
                  <LoadingDataAnimation dataLoading={!isDataReportLoaded} />
                  <RightHeader global={global} button={false} />
                  <Map
                    id='AGWaterTrueQIForecast'
                    mapboxAccessToken={settings.maboxKey}
                    mapStyle={global.mapStyle}
                    cursor={cursor} 
                    onClick={(e) => {
                      global.onMapClick(e)
                      onHandleClick(e)
                    }}
                    onMoveEnd={(e) => {
                      global.setViewport({
                        longitude: e.viewState.longitude,
                        latitude: e.viewState.latitude,
                        zoom: e.viewState.zoom,
                      })
                    }}

                    onLoad={onMapLoad}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    preserveDrawingBuffer={true}
                    interactiveLayerIds={['pointlayer']}
                
                    ref={mapRef as Ref<MapRef>}
                    projection={global.globeView ? 'globe' : 'mercator' as any}
                  >
                    {featureData && (
                      <Source id='circlesource' type='geojson' data={featureData}>
                        <Layer {...layerStyle} />
                        <Layer {...hoverLayerStyle} filter={mapHoverFilter} />
                      </Source>
                    )}

                    <MapAddition global={global} 
                                mapRef={mapRef}
                                onDelete={onDelete}
                                PointInPolygonField={locationField}
                                PointInPolygonUpdate={pointInPolygonUpdate}
                                position={'low'}
                                zipOff={true}
                                statusMessage={statusMessage}/>



                    {popupInfo && renderPopup()}


                    <div className='map-legend-container' onClick={deselectFilters}>
                      <MapLegend
                        mapLegendData={mapLegendData}
                        title={selectedCategory + ' Runoff' + ' [lbs/acre]'}
                        legendWidth={300}
                        global={global}
                      />
                    </div>
                  </Map>
                </div>
              </div>
              <div className='row gx-5'>
                <div
                  className={
                    isFullScreenActive ? 'expand-container chart-container' : 'chart-container'
                  }
                >
                  <div className='report-options'>
                    <Button onClick={fullscreenContainerHandler}>
                      <img src={fullscreenIcon} />
                    </Button>
                  </div>
                  {chartData && (
                    <Bar
                      options={options}
                      data={chartData}
                      ref={chartRef}
                      onClick={onChartClickHandler}
                    />
                  )}
                </div>
              </div>
              <ReportFooter />
            </div>
          </div>
        </div>
        <MapSlider global={global} />
      </FullScreenComp>
    </>
  );
}