import { useEffect, useState, useRef, useCallback, useContext, useMemo, Ref } from "react";
import * as React from 'react';
import {
  Map, MapboxStyle, useMap, MapRef, MapLayerMouseEvent, Popup,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  useControl,
  Layer,
  Source,
  FillLayer,
} from "react-map-gl";
import { Expression, StyleFunction } from 'mapbox-gl';
import settings from "../../../settings.json";
import { Form, Container, Button, Row, Col, Table, Card, Overlay, OverlayTrigger, Accordion } from "react-bootstrap";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import agent from "../../../api/agent";
import { formatRowValue } from "../../utils";

import { HUC8GeoPoint } from "../../../types/HUC8GeoPoint";
import { HUC12GeoPoint } from "../../../types/HUC12GeoPoint";
import { HUC12InfoPoint } from "../../../types/HUC12InfoPoint";

import LoadingDataAnimation from "../../../components/LoadingDataAnimation";
import { Feature, GeoJsonProperties, Point, Polygon, GeometryCollection, FeatureCollection, Geometry } from 'geojson';
import type { LayerProps } from 'react-map-gl';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { parse } from "papaparse";


import moment from 'moment';
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import * as d3Scale from 'd3-scale';
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import bbox from '@turf/bbox';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { DateTime } from 'luxon';
import { ToastContainer, toast } from 'react-toastify';
import { AppContext } from '../../../AppContext';
import { CatalogsContext } from '../../../CatalogsProvider';
import { Location, useLocation } from 'react-router-dom';
import MapLegend from '../shared/MapLegend';
import MapAddition from  '../shared/MapAddition';
import FilterState from '../shared/FilterState';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import ReportMap from '../shared/ReportMap';
import ReportTableExpanded from '../shared/ReportTableExpanded';
import {
  useTable, useExpanded, HeaderGroup,
  HeaderProps,
  UseExpandedState, Cell
} from 'react-table'


import { RefContext } from '../../../RefContext'
import { flyToHUC12sLocation, flyToInitialLocationHandler, getCurrentHuc12LongLat, getHuc12MembersOfHUC8, getCurrentHuc8LongLat } from '../../utils';
import Render from "../../../components/render";
import { createFeatureCollection, processStatusMessage, uniqueValues, updateChartData, fitBoundsMapData, groupByKey } from '../shared/report-data';

import './styles.scss'
import fullscreenIcon from "../../../assets/fullscreen-icon.svg";
import ellipsisIcon from "../../../assets/ellipsis-icon.svg";

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { Scrollbars } from 'react-custom-scrollbars-2';
import { features } from "process";
import { Line, Bar, getElementAtEvent, getElementsAtEvent } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataset, ChartType, TooltipItem
} from "chart.js";
import zoomPlugin from 'chartjs-plugin-zoom';
import { convertToObject } from "typescript";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export default function RiskScreening(props: any) {
  const { global } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);
  const selectedHUC8Value = appContext.selectedHUC8;
  const selectedCategory = (global.filter1Selected || 'State');
  const selectedChemical = (global.chemicalsSelected || 'Chromium');
  const selectedFilterLocations = global.filterLocationsSelected;

  const selectedYear = (global.selectedYearFilter || '2019');
  const [selectedCounty, setSelectedCounty] = useState<string>('');

  const [selectedState, setSelectedState] = useState<string>('');
  const [currentState, setCurrentState] = useState<string>('');


  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;

  const { current: map } = useMap();
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [cursor, setCursor] = useState<string>('auto');
  const mapRef = useRef<MapRef>();
  const [mapLoaded, setMapLoaded] = useState(false);
  const locationField = 'County';
  const [mapHoverFilter, setMapHoverFilter] = useState<any>(['in', ['get', locationField], '']);
  const [mapLegendData, setMapLegendData] = useState<any[]>([]);
  const [fipsFilter, setFipsFilter] = useState<any[]>([]);

  const [mapExpression, setMapExpression] = useState<Expression>(['match', ['get', 'name'], 'WASHINGTON', '#cccccc', '#ffffff']);
  const [mapFilterExpression, setMapFilterExpression] = useState<Expression>(['in', 'isCounty', 'true']);
  const [mapExpressionState, setMapExpressionState] = useState<Expression>(['match', ['get', 'name'], 'WASHINGTON', '#cccccc', '#ffffff']);
  const [mapFilterExpressionState, setMapFilterExpressionState] = useState<Expression>(['in', 'isState', true]); //['!=', 'name', 'Colorado']
  const [layerStyleState, setLayerStyleState] = useState<any>([]);

  const [circleColorStyle, setCircleColorStyle] = useState<any>([]);
  const [mapStyleData, setMapStyleData] = useState<any>([]);
  const [huc8LonLatCatalogArray, setHuc8LonLatCatalogDataArray] = useState<any[]>([]);
  const [fipsDataArray, setFipsDataArray] = useState<any[]>([]);
  const [stateCentroidsDataArray, setStateCentroidsDataArray] = useState<any[]>([]);

  const [userSelectedLocation, setUserSelectedLocation] = useState<any>(null);
  const [userSelectedLocations, setUserSelectedLocations] = useState<any[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [features, setFeatures] = useState({});

  const [reportData, setReportData] = useState<any[]>([]); //State data
  const [filteredData, setFilteredData] = useState<any[]>([]);//County Data
  const [nationalData, setNationalData] = useState<any[]>([]);


  const [statusMessage, setStatusMessage] = useState<any>(null);
  const [layerStyle, setLayerStyle] = useState<any>([]);
  const [currentDate, setCurrentDate] = useState<any>(null);
  const chartRef = useRef<any>();

  const [data, setData] = useState({
    labels: [] as any,
    datasets: [] as any,
  } as const);

  const [config, setConfig] = useState({} as const);

  const [isDataReportLoaded, setIsDataReportLoaded] = useState(false);
  const [isTableSort, setTableSort] = useState(false);
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const [isFullScreenActive, setFullScreenActive] = useState(false);
  const [isFullScreenChartActive, setFullScreenChartActive] = useState(false);
  const fullscreenContainerHandler = () => { setFullScreenActive(!isFullScreenActive); };
  const fullscreenChartContainerHandler = () => { setFullScreenChartActive(!isFullScreenChartActive); };

  const { setCurrentRef } = useContext(RefContext);
  const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;
  useEffect(() => {
    setCurrentRef?.(mapRef)
  }, [mapRef])

  const reportTypes = ['County', 'Chemical'];
  const chartFields = ['Sum of Pollution Index'];
  const highlightColor = '#49b4cd';

  const mapInitialViewState = {
    longitude: settings.defaultLocationMap.lng,
    latitude: settings.defaultLocationMap.lat,
    zoom: 3,
  };

  let scda = [] as any;

  useEffect(() => {
    const statesFileURL = "/csv/CenPop2020_Mean_ST.csv";
    fetch(statesFileURL)
      .then((response) => response.text())
      .then((responseText) => {
        const data: any[] = parse(responseText, {
          header: true,
        }).data;
        scda = [...data];
        setStateCentroidsDataArray(data);
      });
  }, []);

  type abbrev = {
    [key: string]: string,
  };

  const states_abbrv = {
    'Alabama': 'AL',
    'Alaska': 'AK',
    'American Samoa': 'AS',
    'Arizona': 'AZ',
    'Arkansas': 'AR',
    'California': 'CA',
    'Colorado': 'CO',
    'Connecticut': 'CT',
    'Delaware': 'DE',
    'District Of Columbia': 'DC',
    'Federated States Of Micronesia': 'FM',
    'Florida': 'FL',
    'Georgia': 'GA',
    'Guam': 'GU',
    'Hawaii': 'HI',
    'Idaho': 'ID',
    'Illinois': 'IL',
    'Indiana': 'IN',
    'Iowa': 'IA',
    'Kansas': 'KS',
    'Kentucky': 'KY',
    'Louisiana': 'LA',
    'Maine': 'ME',
    'Marshall Islands': 'MH',
    'Maryland': 'MD',
    'Massachusetts': 'MA',
    'Michigan': 'MI',
    'Minnesota': 'MN',
    'Mississippi': 'MS',
    'Missouri': 'MO',
    'Montana': 'MT',
    'Nebraska': 'NE',
    'Nevada': 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Northern Mariana Islands': 'MP',
    'Ohio': 'OH',
    'Oklahoma': 'OK',
    'Oregon': 'OR',
    'Palau': 'PW',
    'Pennsylvania': 'PA',
    'Puerto Rico': 'PR',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    'Tennessee': 'TN',
    'Texas': 'TX',
    'Utah': 'UT',
    'Vermont': 'VT',
    'Virgin Islands': 'VI',
    'Virginia': 'VA',
    'Washington': 'WA',
    'West Virginia': 'WV',
    'Wisconsin': 'WI',
    'Wyoming': 'WY'
  } as abbrev;

  useEffect(() => {
    global.setUserLogged(true);
    if (global.trackerOn) global.setTrackerOn(false)
  }, []);

  useEffect(() => {
    global.setfilter1Selected('');
    global.setChemicalsSelected([])
  }, []);

  useEffect(() => {
    setFilteredData([]);
    setReportData([]);
  }, [selectedHUC8Value]);

  useEffect(() => {
    if (reportData && reportData.length > 0 && !reportData[0]['Avg Adjusted Rsei Score']) {
      filterReportData();
    }
  }, [selectedCategory, selectedCounty, selectedChemical]);

  useEffect(() => {
    let filterData = [...reportData];
    if (reportData.length > 0) {
      if ((selectedState) && (selectedState.length > 0) && (selectedState.indexOf('All') < 0)) {
        filterData = filterData.filter((o: any) => (o["State"] === selectedState));
      }

      if ((selectedCounty) && (+selectedCounty > -1)) {
        filterData = filterData.filter((o: any) => (+o["Fips"] === +selectedCounty));
      }

      const chemicalLabels = uniqueValues(filterData, "Chemical");
      global.setChemicalsFilerOptions(['All', ...chemicalLabels]);
    }
  }, [selectedCounty]);

  useEffect(() => {
    if (selectedState && currentState !== selectedState) {
      global.setChemicalsSelected([])
      global.setfilter1Selected('County');
      setCurrentState(selectedState);
      LoadState(states_abbrv[selectedState]);
    }
  }, [selectedState]);

  useEffect(() => {
    let filteredYear = +selectedYear;
    let filterData = [...nationalData];
    if (selectedYear !== 'All') {
      filterData = filterData.filter((o: any) => (o["Year"] === filteredYear));
    }
    if (selectedCategory === 'State') {
      let groupKey = 'State';
      updateLegend(filterData, groupKey);
      setFilteredData(filterData);
      updateNationalBarChartData(filterData);
      const mapExpressionStops = createMapStopsNational(filterData, "", 'Trueqi Points', 'State');
      updateLayerStyleState(mapFilterExpressionState, mapExpressionStops);
    } else {
      filterReportData();
    }
  }, [selectedYear]);

  const LoadState = (selectedStAbbrev: string) => {
    setIsDataReportLoaded(false);
    agent.Reports.RiskScreening(selectedStAbbrev).then((res: any) => {
      let returnedData = [...res];
      setReportData(returnedData);
      setIsDataReportLoaded(true);
      let rData = [...returnedData]
      const chemicalLabels = uniqueValues(rData, "Chemical");
      global.setChemicalsFilerOptions(['All', ...chemicalLabels]);
      global.setfilter1Populated(reportTypes);
      const yearExtent = d3Array.extent<any, any>(rData, d => d["Year"]);
      const yearLabels = rData.map((item: any) => item.Year)
        .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
        .filter((value: any, index: any, self: any) => value && value !== 'Invalid date')
        .sort();
      global.setSelectedYearFilterOptions(['All', ...yearLabels]);
      let filteredYear = +selectedYear;
      if (yearLabels.indexOf(+selectedYear) < 0) {
        filteredYear = yearExtent[1]
      }
      const countyLabels = uniqueValues(rData, "Fips");
      setSelectedCounty(countyLabels[1])

      rData = rData.filter((o: any) => (o["Year"] === filteredYear));
      createMapStops(rData, selectedState, 'County', 'County');
      rData = rData.filter((o: any) => (o["Fips"] === countyLabels[0]));
      setFilteredData(rData);
      //global.setSelectedYearFilter(yearExtent[1]);
    });
  }


  useEffect(() => {
    if (filteredData.length > 0 && selectedCategory !== 'State') {
      createBarChartData(filteredData);

      let groupKey = 'State';
      if (selectedState) {
        groupKey = 'County';
      }
      if (!selectedCounty) {
        updateLegend(filteredData, groupKey);
      }
    }
  }, [filteredData]);

  const filterReportData = () => {
    let filterData = [...reportData];
    if (reportData.length > 0) {
      if ((selectedState) && (selectedState.length > 0) && (selectedState.indexOf('All') < 0)) {
        filterData = filterData.filter((o: any) => (o["State"] === selectedState));
      }

      if ((selectedCounty) && (+selectedCounty > -1)) {
        filterData = filterData.filter((o: any) => (+o["Fips"] === +selectedCounty));
      }

      if ((selectedChemical) && (selectedChemical.length > 0) && (selectedChemical.indexOf('All') < 0)) {
        filterData = filterData.filter((o: any) => (selectedChemical.indexOf(o["Chemical"]) > -1));
      }
      if (selectedYear) {
        const filterYearData = filterData.filter((o: any) => (+o["Year"] === +selectedYear));
        if (filterYearData.length > 0) {
          filterData = filterYearData;
        }
      }

      sortFilterReportData(filterData, true);
    }
  }


  useEffect(() => {
    setIsDataReportLoaded(false);
    agent.Reports.RiskScreening('true').then((res: any) => {
      //statusMessage
      let returnedData = [...res];

      for (let dataItem of returnedData) {
        dataItem['Trueqi Points'] = dataItem['Avg Trueqi Points'];
        dataItem['Adjusted Rsei Score'] = dataItem['Avg Adjusted Rsei Score'];
        dataItem['Raw Rsei Score'] = dataItem['Avg Raw Rsei Score'];
        dataItem['Adjusted Rsei Score'] = dataItem['Avg Adjusted Rsei Score'];
        dataItem['Tiering Breakpoints'] = dataItem['Avg Tiering Breakpoints'];
        dataItem['Trueqi Tier'] = dataItem['Avg Trueqi Tier'];
        dataItem['Base Trueqi'] = dataItem['Avg Base Trueqi'];
      }

      let rData = [...returnedData]
      setIsDataReportLoaded(true);

      global.setfilter1Populated(reportTypes);
      const yearExtent = d3Array.extent<any, any>(rData, d => d["Year"]);
      const yearLabels = rData.map((item: any) => item.Year)
        .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
        .filter((value: any, index: any, self: any) => value && value !== 'Invalid date')
        .sort();
      global.setSelectedYearFilterOptions(['All', ...yearLabels]);
      global.setfilter1Populated(reportTypes);
      global.setSelectedYearFilter(yearExtent[1]);
      const chemicalLabels = uniqueValues(rData, "Chemical");
      global.setChemicalsFilerOptions(['All', ...chemicalLabels.slice(0, 10)]);
      setReportData(returnedData);
      setNationalData(returnedData);

      let groupKey = 'State';
      let filteredYear = +selectedYear;
      if (yearLabels.indexOf(+selectedYear) < 0) {
        filteredYear = yearExtent[1]
      }
      const filterData = [...rData].filter((o: any) => (o["Year"] === filteredYear));
      updateLegend(filterData, groupKey);
      updateNationalBarChartData(filterData);
      const mapExpressionStops = createMapStopsNational(filterData, "", 'Trueqi Points', 'State');
      updateLayerStyleState(mapFilterExpressionState, mapExpressionStops);

      sortFilterReportData(rData, isTableSort);
    });
  }, [selectedHUC8Value]);


  useEffect(() => {
    updateLayerStyle();
    updateLayerStyleState(mapFilterExpressionState, mapExpressionState);
    //flyToLocation(selectedState);
  }, [isDataReportLoaded]);

  const sortFilterReportData = (unsortedData: any, ascending: boolean) => {
    if (unsortedData && unsortedData.length > 0 && !unsortedData[0]['Avg Adjusted Rsei Score']) {
      let filterData = unsortedData.sort((x: any, y: any) => x["Year"] - y["Year"] || x[selectedCategory].localeCompare(y[selectedCategory]));
      if (!ascending) {
        filterData = unsortedData.sort((x: any, y: any) => y["Year"] - x["Year"] || y[selectedCategory].localeCompare(x[selectedCategory]))
      }
      setFilteredData(filterData);
    } else if (unsortedData && unsortedData.length > 0 && unsortedData[0]['Avg Adjusted Rsei Score']) {
      let filterData = unsortedData.sort((x: any, y: any) => x["Year"] - y["Year"] || x[selectedCategory].localeCompare(y[selectedCategory]));
      if (!ascending) {
        filterData = unsortedData.sort((x: any, y: any) => y["Year"] - x["Year"] || y[selectedCategory].localeCompare(x[selectedCategory]))
      }
      setFilteredData(filterData);
    }
  }

  const updateLegend = (rData: any, groupKey: string) => {
    const slCategory = 'Trueqi Points';
    const groupedresultsRaw = groupByKey<any, any>(rData, i => i[groupKey]);
    const site_data: any[] = [];
    for (const key in groupedresultsRaw) {
      const sumVal = d3Array.rollups(
        groupedresultsRaw[key],
        xs => d3Array.sum(xs, x => x[slCategory]),
        d => key
      )
      site_data.push(sumVal[0][1]);
    }
    const pointsExtent = d3Array.extent<any, any>(site_data, d => d);
    const legendScale = d3Scale.scaleLinear()
      .domain([0, 1])
      .range([pointsExtent[0], pointsExtent[1]]);

    const legenddata: any[] = [];
    legenddata.push({ typecode: d3Format.format(",.5r")(legendScale(0)), color: d3ScaleChromatic.interpolateBlues(0) })
    legenddata.push({ typecode: d3Format.format(",.5r")(legendScale(.25)), color: d3ScaleChromatic.interpolateBlues(.25) })
    legenddata.push({ typecode: d3Format.format(",.5r")(legendScale(.5)), color: d3ScaleChromatic.interpolateBlues(.5) })
    legenddata.push({ typecode: d3Format.format(",.5r")(legendScale(.75)), color: d3ScaleChromatic.interpolateBlues(.75) })
    legenddata.push({ typecode: d3Format.format(",.5r")(legendScale(1)), color: d3ScaleChromatic.interpolateBlues(1) })
    setMapLegendData(legenddata);
  }

  const createMapStops = (rawData: any, selState: string, selCategory: string, groupKey: string) => {
    let matchExpressionZip = ['match', ['get', 'county']] as Expression;
    let matchExpressionFilter = ['in', 'county'] as Expression;
    const yearLabels = uniqueValues(rawData, "Year");
    const yearExtent = d3Array.extent<any, any>(rawData, d => d["Year"]);
    let rData = rawData;
    if (selectedYear !== 'All') {
      let filteredYear = +selectedYear;
      if (yearLabels.indexOf(+selectedYear) < 0) {
        filteredYear = yearExtent[1]
      }
      rData = rawData.filter((o: any) => (o["Year"] === filteredYear));
    }

    const groupedresultsRaw = groupByKey<any, any>(rData, i => i['Fips']);
    const site_data: any[] = [];
    for (const key in groupedresultsRaw) {
      const sumVal = d3Array.rollups(
        groupedresultsRaw[key],
        xs => d3Array.sum(xs, x => x['Trueqi Points']),
        d => key
      )
      site_data.push(sumVal[0][1]);
    }

    const countyLabels = uniqueValues(rData, "County");
    const yExtent = d3Array.extent<any, any>(site_data, d => d);
    const normalizeScale = d3Scale.scaleLinear()
      .domain([yExtent[0], yExtent[1]])
      .range([0, 1]);

    const filter_data: any[] = [];
    for (const key in groupedresultsRaw) {
      const sumVal = d3Array.rollups(
        groupedresultsRaw[key],
        xs => d3Array.sum(xs, x => x['Trueqi Points']),
        d => key
      )
      matchExpressionFilter.push(+key);
      matchExpressionZip.push(+key);
      matchExpressionZip.push(d3ScaleChromatic.interpolateBlues(normalizeScale(sumVal[0][1])));
      filter_data.push(+key)
    }
    setMapFilterExpression(matchExpressionZip);
    matchExpressionZip.push('rgba(0,0,0,0)');
    setMapExpression(matchExpressionZip);
    updateLayerS(matchExpressionFilter, matchExpressionZip);
  }

  const createMapStopsNational = (rawData: any, selState: string, selCategory: string, groupKey: string) => {
    let matchExpressionZip = ['match', ['get', 'name']] as Expression;
    const yearLabels = uniqueValues(rawData, "Year");
    const yearExtent = d3Array.extent<any, any>(rawData, d => d["Year"]);
    let filteredYear = +selectedYear;
    if (yearLabels.indexOf(+selectedYear) < 0) {
      filteredYear = yearExtent[1]
    }
    const rData = rawData.filter((o: any) => (o["Year"] === filteredYear));

    const yExtent = d3Array.extent<any, any>(rData, d => d["Trueqi Points"]);
    const normalizeScale = d3Scale.scaleLinear()
      .domain([yExtent[0], yExtent[1]])
      .range([0, 1]);

    for (let dataItem of rData) {
      if (dataItem['State']) {
        matchExpressionZip.push(dataItem['State']);
        matchExpressionZip.push(d3ScaleChromatic.interpolateBlues(normalizeScale(dataItem["Trueqi Points"])));
      }
    }
    matchExpressionZip.push('rgba(0,0,0,0)');
    setMapExpressionState(matchExpressionZip);
    return matchExpressionZip;
  }

  const createBarChartData = (fData: any) => {
    let chartInput = [...fData];
    if (fData.length > 7000) {
      chartInput = chartInput.slice(0, 7000);
    }

    setChartConfig(selectedCategory);
    const chartdata = updateBarChartData(chartInput, selectedCategory, "Trueqi Points");

    setTimeout(() => {
      setData(chartdata);
    }
      , 100);
  }

  const updateNationalBarChartData = (fData: any) => {
    let summedValue = 'Trueqi Points'; //
    setChartConfig('State');

    let gKey = 'State';
    let fDataset = [...fData];
    const chartLabels = uniqueValues(fDataset, gKey);
    const chartdata_data: any[] = [];
    let chartdata_labels: any[] = [];
    const site_data: any[] = [];

    const pointsExtent = d3Array.extent<any, any>(fDataset, d => +d[summedValue]);
    const legendScale = d3Scale.scaleLinear()
      .domain([0, 1])
      .range([pointsExtent[0], pointsExtent[1]]);
    const legenddata: any[] = [];
    legenddata.push({ typecode: d3Format.format(",.5r")(pointsExtent[0]), color: d3ScaleChromatic.interpolateBlues(0) })
    legenddata.push({ typecode: d3Format.format(",.5r")(legendScale(.25)), color: d3ScaleChromatic.interpolateBlues(.25) })
    legenddata.push({ typecode: d3Format.format(",.5r")(legendScale(.5)), color: d3ScaleChromatic.interpolateBlues(.5) })
    legenddata.push({ typecode: d3Format.format(",.5r")(legendScale(.75)), color: d3ScaleChromatic.interpolateBlues(.75) })
    legenddata.push({ typecode: d3Format.format(",.5r")(pointsExtent[1]), color: d3ScaleChromatic.interpolateBlues(1) })
    setMapLegendData(legenddata);

    let labelGroup = 'Sum of Pollution Index (TrueQI_Points)';
    let colorLine = highlightColor;
    let dataObject = {
      label: labelGroup,
      data: fDataset.map((x: any) => +x[summedValue]),
      customField: 'State',
      borderColor: colorLine,
      backgroundColor: colorLine,
      //borderRadius: Number.MAX_VALUE,
    }
    chartdata_data.push(dataObject);

    chartdata_labels = chartLabels.map((x: any) => x.substr(0, 15));
    const chart_labels = fDataset.map((x: any) => x[gKey]);
    const chartdata = {
      labels: chartdata_labels,
      datasets: chartdata_data,
      borderColor: '#ff6384'
    }

    setData(chartdata);
    return chartdata;
  }

  const updateBarChartData = (filterData: any, groupKey: string, selCategory: string) => {
    let summedValue = 'Trueqi Points';
    let gKey = global.filter1Selected;
    setChartConfig(gKey);
    let fDataset = [...filterData]; //+++
    if (selCategory = 'State') {
      fDataset = [...reportData];
    }
    if ((selectedChemical) && (selectedChemical.length > 0) && (selectedChemical.indexOf('All') < 0)) {
      fDataset = fDataset.filter((o: any) => (selectedChemical.indexOf(o["Chemical"]) > -1));
    }

    const groupedresults = groupByKey<any, any>(fDataset, i => i[gKey]);
    const chartLabels = uniqueValues(fDataset, gKey).sort((x: any, y: any) => x - y)
    const chartdata_data: any[] = [];
    let chartdata_labels: any[] = [];
    const site_data: any[] = [];

    for (const key in groupedresults) {
      const sumVal = d3Array.rollups(
        groupedresults[key],
        xs => d3Array.sum(xs, x => x[summedValue]),
        d => key
      )
      site_data.push(sumVal[0][1]);
    }

    let labelGroup = 'Sum of Pollution Index (TrueQI_Points)';
    let colorLine = '#49b4cd';
    let dataObject = {
      label: labelGroup,
      data: site_data,
      customField: gKey,
      borderColor: colorLine,
      backgroundColor: colorLine,

    }
    chartdata_data.push(dataObject);

    chartdata_labels = chartLabels.map((x: any) => x.substr(0, 15));
    const chartdata = {
      labels: chartdata_labels,
      datasets: chartdata_data,
      borderColor: '#ff6384'
    }

    return chartdata;
  }

  const flyToLocation = (selectedSt: string) => {
    if (mapRef.current) {
      if (stateCentroidsDataArray) {
        let stateCentroid = stateCentroidsDataArray.filter((o: any) => o['STNAME'] === selectedSt);
        if (stateCentroid.length > 0) {
          const currentGeoPoint = { "centroid_longitude": stateCentroid[0].LONGITUDE, "centroid_latitude": stateCentroid[0].LATITUDE };

          mapRef.current?.flyTo({
            center: [
              parseFloat(currentGeoPoint.centroid_longitude),
              parseFloat(currentGeoPoint.centroid_latitude),
            ],
            essential: true,
            zoom: 5,
          });
        }
      }
    }
  }

  const setChartConfig = (selCategory: string) => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: true,
      scales: {
        y: {
          display: true,
          title: {
            display: true,
            text: 'Sum of Pollution Index',
            color: 'white'
          },
        },
        x: {
          display: true,
          title: {
            display: true,
            text: selCategory,
            color: 'white'
          },
        },
      },
      plugins: {
        legend: {
          position: "bottom" as const,
          labels: {
            usePointStyle: true,
          },
          maxHeight: 24,
        } as const,
        title: {
          display: true,
          text: "Sum of Pollution Index by " + selCategory,
          color: 'white',
          align: 'center',
          padding: 10,
          font: {
            size: 20
          }
        } as const,
        tooltip: {
          padding: 10,
          bodyFont: {
            size: 24
          },
          titleFont: {
            size: 24
          },
          boxPadding: 8,
          usePointStyle: false,
          backgroundColor: '#12234f',
          callbacks: {
            label: function (context: any) {
              let label = context.dataset.label || '';
              if (label) {
                label = ['TrueQI Points: ' + d3Format.format(",.5r")(context.parsed.y)]
              }
              return label;
            }
          }
        } as const,
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'xy',
          },
        } as const,
      } as const,
    } as const;
    setConfig(options);
  }

  const onChartClickHandler = (event: any) => {
    const currentClick = getElementAtEvent(chartRef.current, event)[0];
    if (currentClick) {
      const currentClickMetaData = chartRef.current.getDatasetMeta(currentClick.datasetIndex);
      const currentSite = currentClickMetaData.label;

      if (currentClickMetaData._dataset.customField === 'State') {
        const fipsCheck = nationalData.filter((o: any) => o['State'] === currentSite);
        if (fipsCheck.length > 0) {
          const selectSt = currentSite;
          //let rData = [...rawData].filter((o:any) => o['State'] === selectSt);
          setSelectedState(selectSt);
          let matchExpressionFilterState = ['all', ['in', 'isState', true], ['!=', 'name', selectSt]] as Expression;
          setMapFilterExpressionState(matchExpressionFilterState);
          updateLayerStyleState(matchExpressionFilterState, mapExpressionState);
          flyToLocation(selectSt);
        }
      }
    }
  }

  const tableColumns = useMemo(
    () => [
      { Header: 'Year', accessor: 'Year' },
      { Header: 'State', accessor: 'State' },
      { Header: 'County', accessor: 'County' },
      { Header: 'Tri Facility Name', accessor: 'Tri Facility Name' },
      { Header: 'Industry Sector', accessor: 'Industry Sector' },
      { Header: 'Chemical', accessor: 'Chemical' },
      { Header: 'TrueQI Points', accessor: 'Trueqi Points', Cell: (props: any) => d3Format.format(",.5r")(+formatRowValue(props.value)), },
      { Header: 'Adj RSEI Score', accessor: 'Adjusted Rsei Score', Cell: (props: any) => d3Format.format(",.5r")(+formatRowValue(props.value)), },
      { Header: 'Raw RSEI Score', accessor: 'Raw Rsei Score', Cell: (props: any) => d3Format.format(",.5r")(+formatRowValue(props.value)), },
      { Header: 'Tri Facility ID', accessor: 'Tri Facility Id' },
    ],
    []
  );

  const updateLayerStyle = () => {   //
    const newLayerStyle: LayerProps = {
      id: 'pointlayer',
      'source-layer': 'state_county_population_2014_cen',
      type: 'fill' as const,
      beforeId: 'aerialway',
      'filter': mapFilterExpression,
      paint: {
        'fill-color': mapExpression,
        'fill-opacity': 0.75
      }
    };
    setLayerStyle(newLayerStyle);
  }

  const updateLayerS = (mapFilterExp: any, mapExp: any) => { //County
    const newLayerStyle: LayerProps = {
      id: 'pointlayer',
      'source-layer': 'state_county_population_2014_cen',
      type: 'fill' as const,
      beforeId: 'aerialway',
      'filter': mapFilterExp,
      paint: {
        'fill-color': mapExp,
        'fill-opacity': 0.75
      }
    };
    setLayerStyle(newLayerStyle);
  }

  const updateLayerStyleState = (mapFilterExp: any, mapExp: any) => { //National
    const newLayerStyleState: LayerProps = {
      id: 'pointlayerstate',
      'source-layer': 'state_county_population_2014_cen',
      type: 'fill' as const,
      beforeId: 'aerialway',
      'filter': mapFilterExp,
      paint: {
        'fill-color': mapExp,
        'fill-opacity': 0.75
      }
    };
    setLayerStyleState(newLayerStyleState);
  }

  const hoverLayerStyle: LayerProps = {
    id: 'hoverlayer',
    type: 'circle' as const,
    paint: {
      'circle-radius': 10,
      'circle-color': '#000099',
      'circle-stroke-color': 'white',
      'circle-stroke-width': 1,
      'circle-opacity': 1
    }
  };

  const populatePopup = (event: any | null, feature: any | null) => {

    let newPopup = event.features[0].properties;
    let newLocation = event.features[0].properties[locationField];
    let newHover = event.features[0].properties[locationField];
    setCursor('pointer');
    newPopup.centroid_longitude = event.lngLat.lng;
    newPopup.centroid_latitude = event.lngLat.lat;

    if (event.features[0].properties.isState) {
      if (newPopup.isState) {
        newPopup.type = 'state';
        newPopup.fips = newPopup['state'];
        //newPopup.name =  newPopup['name'];
        //setPopupInfo(newPopup);
      }
    } else if (event.features[0].properties.isCounty) {
      //const cName = newPopup["name"].substr(0, newPopup["name"].length-7).toUpperCase(); 
      const fData = reportData.filter((o: any) => o["County"] === newPopup.name);

      if (fData.length > 0) {
        if (newPopup.isState) {
          newPopup.type = 'state';
          newPopup.fips = newPopup['state'];
        } else if (newPopup.isCounty) {
          newPopup.type = 'county';
          newPopup.fips = newPopup['county'];
        } else {
          newPopup.type = 'n/a';
        }
        //setPopupInfo(newPopup);  
      }
    }
  }

  const onMouseEnter = useCallback((event: any | null) => {
    const feature = event.features && event.features[0];
    if (feature) {
      //populatePopup(event, feature);
    }
  }, []);

  const onMouseLeave = useCallback((event: any | null) => {
    setCursor('');
    setPopupInfo(null);
    //setUserSelectedLocation(null)
  }, []);

  const onHandleClick = useCallback((event: any | null) => {
    const feature = event.features && event.features[0];
    if (feature) {
      //populatePopup(event, feature);
      let rawData = reportData;
      if (rawData.length < 1) {
        rawData = filteredData;
      }
      if (event.features[0].properties.isState) {
        const selectSt = event.features[0].properties.name;
        if (selectSt === 'Texas') {
          toast('Texas data unavailable');
          return;
        }
        setSelectedState(selectSt);

        let matchExpressionFilterState = ['all', ['in', 'isState', true], ['!=', 'name', selectSt]] as Expression;
        setMapFilterExpressionState(matchExpressionFilterState);
        updateLayerStyleState(matchExpressionFilterState, mapExpressionState);
        flyToLocation(selectSt);
        //setSelectedCounty("");
      } else if (event.features[0].properties.isCounty) {
        setSelectedCounty(event.features[0].properties.county);
      }
    }
  }, []);

  const onMapLoad = useCallback(() => {
    if (mapRef && mapRef.current) {
      setMapLoaded(true);
      mapRef.current.resize();
    }
  }, []);

  const pointInPolygonUpdate = (pointsSelected: any) => {
/*        setUserSelectedLocations(pointsSelected);
        setMapHoverFilter(['in', ['get', locationField], ['literal', pointsSelected]]);*/
  }

  const onUpdate = useCallback((e: any | null) => {
    if (mapRef && mapRef.current) {
      const pointlayerFeatures = mapRef.current.queryRenderedFeatures(undefined, {
        layers: ["pointlayer"]
      });
      const pointsSelected: any[] = [];

    }
  }, []);

  const onDelete = useCallback((e: any | null) => {
    if (mapRef && mapRef.current) {
      setUserSelectedLocations([]);
    }
  }, []);


  const renderPopup = () => {
    return (
      <Popup

        longitude={Number(popupInfo.centroid_longitude)}
        latitude={Number(popupInfo.centroid_latitude)}
        onClose={() => setPopupInfo(null)}
      >
        <div className="popup-container">
          <h2><p>{popupInfo["name"]}</p></h2>
          <div className="popup-content-left">
            <p className="popup-content-right"></p>
            <p className="popup-content-right">Population</p>
            <p></p>
          </div>
          <div className="popup-content-right">
            <p className="popup-content-right">{popupInfo["name"]}</p>
            <p className="popup-content-right">{popupInfo["population"]}</p>
            <p className="popup-content-right">{popupInfo['Trueqi Points']}</p>
          </div>
        </div>
      </Popup>
    )
  }

  const hoverRowHandler = (event: any) => {

  }

  const clickRowHandler = (event: any) => {
    /* 
        if (event&&event.County){
            if(selectedCounty !== event.County){
                setSelectedCounty(event.County); 
            } else {
                setSelectedCounty(""); 
            }             
        }
     */
  };

  const onHoverOut = useCallback((e: any | null) => {
    setMapHoverFilter(['in', ['get', locationField], '']);
    setPopupInfo(null);
  }, []);

  const onTableClick = useCallback((e: any | null) => {

  }, []);

  const deselectFilters = useCallback((e: any | null) => {
    setPopupInfo(null);
    setMapHoverFilter(['in', ['get', locationField], '']);
    setUserSelectedLocations([]);
    setSelectedCounty("");
    setSelectedState("");
  }, []);

  const renderThumb = (style: any) => {
    const thumbStyle = {
      backgroundColor: 'white'
    };
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        {...props} />
    );
  }


  return (
    <>
      <FullScreenComp handle={fullscreenhandle}>
        <Container fluid>
          <div className="risk-screening" id='risk-screening-report'>
            <ReportHeader global={global} data={filteredData} filterMode={'year'}
              reportID={"risk-screening-report"} filterLabelDays={selectedYear} fullScreenClickHandle={fullscreenhandle.enter} />
            <Row className="container-row-top">
              <Col xs={1} className="column-summary">
                <div className={isFullScreenActive ? 'summary-container expand-container-summary' : 'summary-container'} >
                  <div className="summary-title">
                    <span>State Summary</span>
                    <Button onClick={fullscreenContainerHandler}>
                      <img src={fullscreenIcon} />
                    </Button>
                  </div>
                  <Scrollbars renderThumbVertical={renderThumb} className="summary-scroll" style={{ width: 112, height: '24vh' }}>
                    {filteredData &&
                      filteredData.map((variant: any, index: number) => (
                        <Card
                          key={index + (Math.random() * 5)}
                          id={variant.dmrid + '_' + index}
                          text='light'
                          className={"mb-2 te-primary"}
                        >
                          <Card.Body>
                            <Card.Title>
                              <p className="summary-field-double summary-field-highlight">{variant["State"]}</p>
                              <p className="summary-field-name">State</p>
                              <p>{d3Format.format(",.5r")(variant["Trueqi Points"])}</p>
                              <p className="summary-field-name">True_QI</p>
                              <p className="summary-field-double">{variant["Chemical"]}</p>
                              <p className="summary-field-name">Chemical</p>
                            </Card.Title>
                          </Card.Body>
                        </Card>
                      ))}
                  </Scrollbars>
                </div>

                <div className={isFullScreenActive ? 'summary-container summary-container-secondary expand-container-summary' : 'summary-container-secondary summary-container'} >

                  <div className="summary-title">
                    <span>County Summary</span>
                    <Button onClick={fullscreenContainerHandler}>
                      <img src={fullscreenIcon} />
                    </Button>
                  </div>
                  <Scrollbars renderThumbVertical={renderThumb} className="summary-scroll" style={{ width: 112, height: '24vh' }}>
                    {filteredData && selectedCategory !== 'State' &&
                      filteredData.map((variant: any, index: number) => (
                        <Card
                          key={index + (Math.random() * 5)}
                          id={variant.dmrid + '_' + index}
                          text='light'
                          className={"mb-2 te-primary"}
                        >
                          <Card.Body>
                            <Card.Title>
                              <p className="summary-field-double summary-field-highlight">{variant["County"]}</p>
                              <p className="summary-field-name">County</p>
                              <p>{d3Format.format(",.5r")(variant["Trueqi Points"])}</p>
                              <p className="summary-field-name">True_QI</p>
                              <p className="summary-field-double">{variant["Chemical"]}</p>
                              <p className="summary-field-name">Chemical</p>
                            </Card.Title>
                          </Card.Body>
                        </Card>
                      ))}
                  </Scrollbars>
                </div>

              </Col>
              <Col m={2} className="column-chart">
                <div className={isFullScreenChartActive ? 'expand-chart-container chart-container' : 'chart-container'}>
                  <div className="report-chart-options">
                    <Button onClick={fullscreenChartContainerHandler}>
                      <img src={fullscreenIcon} />
                    </Button>
                  </div>
                  {data && (

                    <div className="line-chart">
                      <Bar options={config}
                        data={data}
                        ref={chartRef}
                        onClick={onChartClickHandler}
                      />
                    </div>

                  )}
                </div>
              </Col>
              <Col m={2}>
                <div className="map-report-container">
                  <LoadingDataAnimation dataLoading={!isDataReportLoaded} />
                  <Map
                    id="RiskScreening"
                    mapboxAccessToken={settings.maboxKey}
                   mapStyle={global.mapStyle}
                    initialViewState={mapInitialViewState}
                    onClick={(e) => {
                      global.onMapClick(e)
                      onHandleClick(e)
                    }}
                    onMoveEnd={(e) => {
                      global.setViewport({
                        longitude: e.viewState.longitude,
                        latitude: e.viewState.latitude,
                        zoom: e.viewState.zoom,
                      })
                    }}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    cursor={cursor}
                    preserveDrawingBuffer={true}
                    interactiveLayerIds={['pointlayer', 'pointlayerstate']}
                    ref={mapRef as Ref<MapRef>}
                    projection={global.globeView ? 'globe' : 'mercator' as any}
                  >
                    <MapAddition global={global} 
                                mapRef={mapRef}
                                onDelete={onDelete}
                                PointInPolygonField={locationField}
                                PointInPolygonUpdate={pointInPolygonUpdate}
                                position={'low'}
                                zipOff={true}
                                MapSliderAdd={true}
                                statusMessage={statusMessage}/>

                    {reportData && (
                      <Source id="circlesource" type="vector" url="mapbox://mapbox.660ui7x6">
                        <Layer {...layerStyleState} />
                        <Layer {...layerStyle} />
                      </Source>
                    )}


                    {popupInfo && renderPopup()}

                    <div className='map-legend-container' onClick={deselectFilters}>
                      <MapLegend
                        mapLegendData={mapLegendData}
                        title={'Sum of Pollution Index'}
                        subtitle={'(TrueQI_Points)'}
                        global={global}
                      />
                    </div>
                  </Map>


                </div>
              </Col>
            </Row>
            <Row className="container-row-bottom">
              <div
                className={isFullScreenActive ? 'row gx-0 table-chart-row table-parent-container expand-container' : 'row gx-0 table-chart-row table-parent-container'}
                onMouseLeave={onHoverOut}
                onClick={onTableClick}>
                <div className="report-options-table">
                  <span className="report-options-table-title">EPA's Risk Screening Environmental Indicators RSEI with Pollution Index (TrueQI Points)</span>
                  <Button variant="light" onClick={fullscreenContainerHandler}>
                    <img src={fullscreenIcon} />
                  </Button>
                </div>
                <ReportTableExpanded
                  data={filteredData.slice(0, 500)}
                  columns={tableColumns}
                  clickRowHandler={clickRowHandler}
                  clickLoadStop={true}
                />
              </div>
              <ReportFooter />
            </Row>
          </div>
        </Container>
 
      </FullScreenComp>
    </>
  );

}

type RiskScreeningModel = {
  state: string;
};
