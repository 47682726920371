import { useContext } from "react";
import { RefContext } from "../../../RefContext";
import { CatalogsContext } from "../../../CatalogsProvider";
import { AppContext } from "../../../AppContext";

export const MCGlobe = ({ global, top }: any) => {
  const { currentRef } = useContext(RefContext);
  const catalogsContext = useContext(CatalogsContext);
  const appContext = useContext(AppContext);

  const getCurrentHuc8LongLat = (
    selectedHUC8Value: string,
    catalog?: any[]
  ) => {
    if (catalog) return catalog.find((x) => x.huc8 == selectedHUC8Value);
    return catalogsContext.huc8Catalog.find((x) => x.huc8 == selectedHUC8Value);
  };

  const handleViewSwitch = () => {

    

    setTimeout(() => {
      global.setGlobeView(!global.globeView);
    }, 1555);
    // @ts-ignore
    const { current } = currentRef;

    current.getMap().setFog({});
    global.globeView && current.getFog();

    if (current) {
      const currentHuc8GeoPoint = getCurrentHuc8LongLat(
        appContext.selectedHUC8
      );

      if (currentHuc8GeoPoint) {
        // @ts-ignore
        current.flyTo({
          center: [
            parseFloat(currentHuc8GeoPoint.centroid_longitude),
            parseFloat(currentHuc8GeoPoint.centroid_latitude),
          ],
          essential: true,
          zoom: global.globeView === false ? 2 : 13,
        });
      } else {
        // @ts-ignore
        current.flyTo({
          center: [39.828175, -98.5795],
          essential: true,
          zoom: global.globeView === false ? 2 : 13,
        });
      }
    }
  };

    return (
        <>           
          <div style={{position: 'absolute', top: top, right: 10}} className="mapboxgl-ctrl mapboxgl-ctrl-group">
          	<div className="tools-box">
                <button onClick={handleViewSwitch} className={(global.globeView) ? "map-globe-button-active map-globe-button" : "map-globe-button"}>
                  <span className="mapboxgl-ctrl-icon map-globe-button-span" aria-hidden="true" title="View Globe" />
                </button>
              </div>
          </div>
        </>
    )
}
                        
export default MCGlobe