import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faXmarkCircle, faPlusCircle, faMinusCircle
} from '@fortawesome/pro-solid-svg-icons';
import {
    Col,
    Row,
    Button,
    Form,
    ListGroup
} from 'react-bootstrap'
import WMKeys from '../WMKeys.json'
import agent from '../../../../api/agent'
import { editOrg, testCredentials, editUser } from '../lib/data';
import * as store from '../lib/store';


const EditOrganization = (props: any) => {

    const {
        global,
        setAction,
        action,
        orgID,
        data = null,
        title,
        usersData,
        usersDataAssigned,
        setUsersDataAssigned,
        setOrgID,
        orgCredentialsType,
        setOrgCredentialsType
    } = props;


    const [org, setOrg] = useState<any>()
    const [orgId, setOrgId] = useState<any>('')
    const [orgName, setOrgName] = useState<any>('')
    const [orgEmail, setOrgEmail] = useState<any>('')
    const [tribalCode, setTribalCode] = useState<any>('')
    const [phoneExtension, setPhoneExtension] = useState<any>('')
    const [phoneNumber, setPhoneNumber] = useState<any>('')
    const [description, setDescription] = useState<any>('')
    const [selfCredUser, setSelfCredUser] = useState<any>('')
    const [selfCredPass, setSelfCredPass] = useState<any>('')

    const [selfCred, setSelfCred] = useState(orgCredentialsType ==='self' ? true : false)
    const [owCred, setOWCred] = useState(orgCredentialsType ==='te' ? true : false)
    const [noCred, setNoCred] = useState(orgCredentialsType ==='none' ? true : false)
    const [credentialsVerified, setCredentialsVerified] = useState<boolean>(false);

    const [currentListUser, setCurrentListUser] = useState<boolean>(false);
    const [currentListUserName, setCurrentListUserName] = useState<any>('')
    const [currentListUserIn, setCurrentListUserIn] = useState<boolean>(false);
    const [currentListUserNameIn, setCurrentListUserNameIn] = useState<any>('')
    const [addAsAdmin, setAddAsAdmin] = useState<boolean>(false);
    const [addedUser, setAddedUser] = useState<any>('');
    const [usersDataAssigned_original, setUsersDataAssigned_original] = useState<any[]>((usersDataAssigned) ? [...usersDataAssigned] : []); 

    const sendBody = {
        ORGDescription_OrganizationIdentifier: orgId,
        ORGDescription_OrganizationFormalName: orgName,
        ORGElectronicAddress_ElectronicAddressText: orgEmail,
        ORGDescription_TribalCode: tribalCode,
        ORGTelephonic_Telephonic: phoneExtension,
        ORGTelephonic_TelephoneNumberText: phoneNumber,
        ORGDescription_OrganizationDescriptionText: description
    }

    useEffect(() => {    
        if(action==='edit'){
            const target = data && data.find((item: any) => item.org_id === orgID)
            setOrg(target)
        } else {
            if(data && data.length>0){
                const target = data[0];
                setOrg(target)                
            }  
        }
    }, [])


/*    const handleSubmit = async () => (
        console.log(WMKeys,sendBody),
        await agent.WaterMonitoring
        .WMSubmit(JSON.stringify({ ...WMKeys,...sendBody }))
        .then((res:any) => console.log(res))
    )*/

    useEffect(() => {
        const settings_cdxuser = localStorage.getItem('wm_settings_cdxuser');
        const settings_cdxpass = localStorage.getItem('wm_settings_cdxpass');
        const settings_credentialsVerified = localStorage.getItem('wm_credentialsVerified');

        if(settings_cdxuser){
            setSelfCredUser(settings_cdxuser);
        } 
 
        if(settings_cdxpass){
            setSelfCredPass(settings_cdxpass);
        } 

        if(settings_credentialsVerified){
            setCredentialsVerified(true);
        } 
    }, []);

    useEffect(() => {
        if(selfCredUser){
            localStorage.setItem('wm_settings_cdxuser', selfCredUser)
        }
    }, [selfCredUser]);

    useEffect(() => {
        if(selfCredPass){
            localStorage.setItem('wm_settings_cdxpass', selfCredPass)
        }
    }, [selfCredPass]);

    useEffect(() => {
        if(credentialsVerified){
            localStorage.setItem('wm_credentialsVerified', 'true')
        }
    }, [credentialsVerified]);

    const updateOrg = (property: any, value:any) => {   
        let changeOrg = org;
        changeOrg[property]=value;
        setOrg(changeOrg)
    }

    const handleSubmit = (event: any) => {
        const orgSend = { ORG_NAME: orgName, EPA_ORG_ID: orgId, DESCRIPTION: description, EMAIL: orgEmail, IS_ACTIVE: true }
        editOrg(org, 'edit', handleSubmitResult);
        setAction('table')
    }

    const handleSubmitResult = (result: any) => {
      
    }

    const handleCancel = (event: any) => {
        setUsersDataAssigned(usersDataAssigned_original)       
        localStorage.setItem('wm_usersassigned' + global.userOrg, JSON.stringify(usersDataAssigned_original));
        setAction('table')
    }

    const handleTestCredentials = (event: any) => {
        if(selfCred && selfCredUser && selfCredPass){
            testCredentials(selfCredUser, selfCredPass, handleTestCredentialsResult)
        }
    }

    const handleTestCredentialsResult = (result: any, wqxid: any) => {
        if(result && result.body && result.body.length>0 ){
            let parseArray = result.body.split('\n')
            let nwObj = {'transactionId':parseArray[1].split(':')[1].replace(',', '').substr(1).replace(/'/g, ''), 'status':parseArray[2].split(':')[1].replace(',', '').substr(1).replace(/'/g, ''),'statusDetail':parseArray[3].split(':')[1].replace(',', '').substr(1).replace(/'/g, '')}
            //@ts-ignore
            if(nwObj.status === 'Processing'){
                setCredentialsVerified(true);
            }
        }
    }

    const handleAddUser = (event: any) => {
        //editUser('1', '1', 'edit', handleAddUserCallback)
            //(User: any, org_id: any, action: any, callback: any)
        let currentSet = [];
        if(usersDataAssigned){
            currentSet = usersDataAssigned;
        }
 
        let filterData = usersData.filter((o: any) => (o["username"] === currentListUserName));
        let filtercurrentSet = [];
        if(currentSet && currentSet.length>0){
           filtercurrentSet =  currentSet.filter((o: any) => (o["username"] === currentListUserName));
        }
       
        if(filterData && filterData.length > 0 && filtercurrentSet.length < 1){
            if(addAsAdmin){
                filterData[0].profile='admin';
            } else {
                filterData[0].profile='null';              
            }
            currentSet.push(filterData[0])
           
            setUsersDataAssigned(currentSet)       
            localStorage.setItem('wm_usersassigned' + global.userOrg, JSON.stringify(usersDataAssigned));
            setAddedUser(currentListUserName)
        }
    }

    const handleAddUserCallback = (result: any) => {
      
    }

    const handleRemoveUser = (event: any) => {

        let currentSet = usersDataAssigned;

        let filterData = currentSet.filter((o: any) => (o["username"] !== currentListUserNameIn));


        setUsersDataAssigned(filterData)
        localStorage.setItem('wm_usersassigned' + global.userOrg, JSON.stringify(usersDataAssigned));
        setCurrentListUserIn(false)
        setCurrentListUserNameIn('');
/*       t)

        editUser('1', '1', 'edit', handleAddUserCallback)
            //(User: any, org_id: any, action: any, callback: any)


*/
    }


    const handleUserClick = (event: any) => {
  
        setCurrentListUser(true)
        setCurrentListUserName(event.target.dataset.rrUiEventKey);
    }

    const handleUserClickIn = (event: any) => {
      
        setCurrentListUserIn(true)
        setCurrentListUserNameIn(event.target.dataset.rrUiEventKey);
    }

    //+++ defaultValue={org && org.selfcreduser}

    return (
        <>
            <Row className="org-edit-form">
                <h2>{title} Organization</h2>
                <Col className="col d-flex flex-column col-6">
                    <Row>
                        <Col className="col d-flex col-12">
                            <Form.Group className="m-1 col-6">
                                <Form.Label>EPA Organization ID</Form.Label>
                                <Form.Control
                                    type="text"
                                    defaultValue={org && org.epa_org_id}
                                    onChange={(e: any) => updateOrg('epa_org_id', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="m-1 col-6">
                                <Form.Label>Organization Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    defaultValue={org && org.name}
                                    onChange={(e: any) => updateOrg('name', e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Group className="m-1">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                defaultValue={org && org.email}
                                onChange={(e: any) => updateOrg('email', e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                </Col>
                <Col className="col d-flex flex-column col-6">
                    <Row>
                        <Col className="col d-flex col-12">
                            <Form.Group className="m-1 col-6">
                                <Form.Label>Tribal Code</Form.Label>
                                <Form.Control
                                    defaultValue={org && org.tribalCode}
                                    onChange={(e: any) => setTribalCode(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="m-1 col-6">
                                <Form.Label>Phone Extension</Form.Label>
                                <Form.Control
                                    defaultValue={org && org.ext}
                                    onChange={(e: any) => setPhoneExtension(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Group className="m-1">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                defaultValue={org && org.phone}
                                onChange={(e: any) => setPhoneNumber(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            <Row className="org-edit-form">
                <Form.Group className="m-1">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        defaultValue={org && org.description}
                        onChange={(e: any) => updateOrg('description', e.target.value)}
                    />
                </Form.Group>
            </Row>
            <Row className="org-edit-form mt-3">
                <h4 style={{ marginBottom: '1rem' }}>Credentials for Submitting to EPA</h4>
                <Row className="p-2 cred-box">
                    <Row className="mt-1">
                        <div className={(selfCred && !credentialsVerified) ? "org-warning-box" : "wm-org-display-none"} >
                            <FontAwesomeIcon icon={faXmarkCircle} />
                            <p>This organization is unable to submit to the EPA. Please correct this below</p>
                        </div>
                    </Row>
                    <Row className="mt-4">
                        <Form>
                            <div className="d-flex">
                                <Col className="d-flex" style={{flexDirection: 'column'}}>
                                    <div  style={{flexDirection: 'row'}}>
                                        
                                        <Form.Check
                                            onChange={() => {
                                                setSelfCred(true)
                                                setOWCred(false)
                                                setNoCred(false)
                                                setOrgCredentialsType('self')
                                            }}
                                            checked={selfCred}
                                            className="wm-org-cred-check"               
                                            type="radio"
                                            id={`credentials-self`}
                                        />
                                        <Form.Label style={{maxWidth: '15vw'}}>Submit to EPA using my own NAAS credentials</Form.Label>
                                    </div>

                                </Col>

                                <Col className="d-flex" style={{height: 'min-content'}}>


                                    <Form.Check
                                        onChange={() => {
                                            setSelfCred(false)
                                            setOWCred(true)
                                            setNoCred(false)
                                            setOrgCredentialsType('te')
                                        }}
                                        className="wm-org-cred-check"
                                        checked={owCred}
                                        type="radio"
                                        id={`credentials-trueelememnts`}
                                    />
                                      <Form.Label style={{maxWidth: '15vw'}}>Submit to EPA using True Elements global NAAS credentials</Form.Label>
                                </Col>
                                 <Col className="d-flex" style={{height: 'min-content'}}>
                                    <Form.Check
                                        onChange={() => {
                                            setSelfCred(false)
                                            setOWCred(false)
                                            setNoCred(true)
                                            setOrgCredentialsType('none')
                                        }}
                                        className="wm-org-cred-check"
                                        checked={noCred}
                                        type="radio"
                                        id={`credentials-trueelememnts`}
                                    />
                                    <Form.Label  style={{maxWidth: '15vw'}}>No Submissions to EPA</Form.Label>
                                </Col>

                            </div>
                        </Form>
                    </Row>
                    <Row>
                        <div className={(selfCred) ? "wm-org-cred" : "wm-org-display-none"} >
                             <Form.Group className="" style={{width: 170}}>
                                <Form.Label style={{whiteSpace: 'nowrap'}} >CDX Submitter</Form.Label>
                                <Form.Control
                                    defaultValue={selfCredUser}
                                    onChange={(e: any) => setSelfCredUser(e.target.value)}
                                />
                            </Form.Group>
                             <Form.Group className=""  style={{width: 170, marginLeft:10}}>
                                <Form.Label>CDX Password</Form.Label>
                                <Form.Control
                                    defaultValue={selfCredPass}
                                    onChange={(e: any) => setSelfCredPass(e.target.value)}
                                />
                            </Form.Group>
                                <Button  style={{whiteSpace: 'nowrap', marginLeft:20}} onClick={handleTestCredentials}
                                 id="TestCredentials-btn" className="btn btn-primary">
                                    Test My Credentials
                                </Button>
                        </div>
                    </Row>
                    <Row className={(owCred) ? "mt-3" : "wm-org-display-none"}>
                        <a
                            style={{color: '#18A0FB',textDecoration: 'underline'}}>
                            Check if True Elements is Authorized to submit for your organization
                        </a>
                    </Row>
                </Row>
            </Row>
            <Row className="user-box mt-5">
                <Col>
                    <Row>
                        <h4 style={{ fontSize: '18px' }}>Available Users</h4>
                    </Row>
                    <Row className='p-2'>
                            <ListGroup defaultActiveKey="#link1" >
                                {usersData  && usersData.map((obj:any, index:any) =>
                                    (<ListGroup.Item  action key={'wm-users-options'+obj.id} onClick={handleUserClick} eventKey={obj.username}>{obj.name}</ListGroup.Item>)
                                )} 
                            </ListGroup>
                    </Row>
                    <Row className='p-2'  style={{ minHeight: 50 }}>
                      <div className='user-list-control-container'
                          style={(currentListUser) ? {display: 'flex'} : {display: 'none'}}>
                        <div className='user-list-control-check'>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox" style={{display: 'flex'}} >
                                <Form.Check
                                    type="checkbox"
                                    defaultChecked={false}
                                    onChange={() => {
                                            setAddAsAdmin(!addAsAdmin)
                                            
                                        }}
                                />
                                <Form.Label>Add as Admin</Form.Label>
                            </Form.Group>
                        </div>
                        <div
                            style={{color: '#18A0FB', cursor: 'pointer'}}
                            className="action-container"
                            onClick={handleAddUser}
                        >
                            <FontAwesomeIcon
                                style={{
                                    color: '#18A0FB',
                                    marginRight: '2px',
                                    cursor: 'pointer'

                                }}
                                icon={faPlusCircle}
                            />
                            Add User
                        </div>
                      </div>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <h4 style={{ fontSize: '18px' }}>Users in organization</h4>
                    </Row>
                    <Row className='p-2'>
                            <ListGroup defaultActiveKey="#link1" key={'wm-user'+ ((usersDataAssigned) ? usersDataAssigned.length : '000')}>
                                {usersDataAssigned  && usersDataAssigned.map((obj:any, index:any) =>
                                    (<ListGroup.Item  action key={'wm-users-tions'+obj.id+index+usersDataAssigned.length} 
                                        onClick={handleUserClickIn} 
                                        eventKey={obj.username}>{obj.name}{(obj.profile==='admin') ? " (Admin)" : ""}</ListGroup.Item>)
                                )} 
                            </ListGroup>
                    </Row>
                    <Row className='p-2'  style={{ minHeight: 50 }}>
                        <div
                            style={(currentListUserIn) ? {display: 'block', color: '#18A0FB' } : {display: 'none'}}
                            className="action-container"
                            onClick={handleRemoveUser}
                        >
                            <FontAwesomeIcon
                                style={{
                                    color: '#18A0FB',
                                    marginRight: '2px',
                                    cursor: 'pointer'

                                }}
                                icon={faMinusCircle}
                            />
                            Remove User
                        </div>
                    </Row>
                </Col>
            </Row>

            <Row className="org-edit-form mt-3">
                <div className="footer-button-box">
                    <Button onClick={() => setAction('table')} id="footer-btn" className="p-3 btn btn-primary m-2 cancel-btn">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} id="footer-btn" className="p-3 btn btn-primary m-2">
                        Save & Exit
                    </Button>
                    <Button id="footer-btn" className="p-3 btn btn-primary m-2 edit-btn" style={{display: 'none'}}>
                        Edit Default Data
                    </Button>
                </div>
            </Row>
        </>
    )
}

export default EditOrganization