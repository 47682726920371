import {
  CHEMICALS_FILTER,
  INDUSTRY_SECTOR_FILTER,
  LOCALSTORAGE_DEFAULTS_KEY,
  LOCATIONS_FILTER,
  MEASUREMENTS_FILTER,
  YEAR_FILTER,
  REPORT_KEY
} from '../Constants';
import AppContextDefaults from '../types/AppContextDefaults';
import { MutableRefObject } from 'react';
import { MapRef } from 'react-map-gl';
import { HUC12GeoPoint } from '../types/HUC12GeoPoint';
import { HUC8GeoPoint } from '../types/HUC8GeoPoint';
import ReportsConfigs from '../ReportsConfigs';
import Globals from '../types/GlobalsType';
import { Location, useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { jsPDF, ImageOptions } from "jspdf";
import { unparse, parse } from 'papaparse';
import { DateTime } from 'luxon';
import customerRestrictions from '../features/account/customerRestrictions.json';
import LayerConfig from '../LayerConfig';
import agent from '../api/agent';

export function getLastUpdatedDate(month: any, day: any) {
  // Get the current date
  const currentDate = new Date();

  // Set the target date to June 1st of the current year
  const targetDate = new Date(currentDate.getFullYear(), month, day);

  // Calculate the time difference in milliseconds
  const timeDiff = currentDate.getTime() - targetDate.getTime();

  // Calculate the number of weeks
  const weekCount = Math.floor(timeDiff / (1000 * 3600 * 24 * 7));

  // Calculate the last updated date based on the number of weeks
  const lastUpdatedDate = new Date(targetDate.getTime() + weekCount * (1000 * 3600 * 24 * 7));

  // Format the date as dd mmmm yyyy
  const targetDay = lastUpdatedDate.getDate();
  const targetMonth = lastUpdatedDate.toLocaleString('en-US', { month: 'long' });
  const year = lastUpdatedDate.getFullYear();
  const formattedDate = `${targetMonth} ${targetDay}, ${year}`;

  return formattedDate;
}

const convertToNumber = (value: string | number) => {
  const valueToFloat = parseFloat(value.toString());
  return valueToFloat ? valueToFloat : 0;
};

const isNumber = (value: string | number) => {
  if (!value) return false;
  const valueToFloat = parseFloat(value.toString());
  return !isNaN(valueToFloat);
};

const isHUC8 = (value: string | number) => value.toString().length == 8;
const isHUC12 = (value: string | number) => value.toString().length == 12;

const formatNumber = (value: number) => value.toFixed(2);

export const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

export const hasAValue = (variable: any) => {
  if (typeof variable == 'string') {
    return variable !== '';
  }

  if (Array.isArray(variable)) {
    return variable.length > 0;
  }
  return true;
};

export function resolveCurrentactiveFilters(global: Globals): string[] {
  const currentActiveFilters = [];

  if ((global as Globals).chemicalsSelected.length > 0) {
    debugger;
    currentActiveFilters.push(CHEMICALS_FILTER);
  }
  if ((global as Globals).selectedIndustrySector.length > 0) {
    currentActiveFilters.push(INDUSTRY_SECTOR_FILTER);
  }
  if ((global as Globals).selectedYearFilter != '') {
    currentActiveFilters.push(YEAR_FILTER);
  }
  if ((global as Globals).filterLocationsSelected.length > 0) {
    currentActiveFilters.push(LOCATIONS_FILTER);
  }
  if ((global as Globals).selectedMeasurementFilterValue != '') {
    currentActiveFilters.push(MEASUREMENTS_FILTER);
  }

  return currentActiveFilters;
}

export const checkReportRestrictions = (reportRestrict: any, route: any) => {
  // Disable? return true to disable report link 
  if (reportRestrict && reportRestrict.length > 0 && reportRestrict.indexOf(route) > -1) {
    return false;
  } else if (!reportRestrict || (reportRestrict && reportRestrict.length === 0)) {
    return false;
  } else {
    return false; //true //permitReports national huclist
  }
}

export const renderRestrictions = (org: any, section: any, global:any) => {
    let renderSection = false;

    /*    if(!org){
          return;
        }*/
    const matchingRestriction = customerRestrictions.find(({ name }) => name === org);
    if (org === "True Elements") {
      return true;
    } else if (matchingRestriction && matchingRestriction.name !== "True Elements") {
      // @ts-ignore
      const matchingRoute = matchingRestriction.routes.find(route => route.reportAccess === section);
      if (matchingRoute) {
        return true;
      }
    }
    if (global && global.userRoles && global.userRoles.length > 0) {
      let userRoles = global.userRoles;
      if (section === 'Digital Watershed Explorer' && (userRoles.indexOf('digital-watershed') > -1)) {
        renderSection = true;
      } else if (section === 'Digital Scenario Forecaster' && (userRoles.indexOf('scenario-forecaster') > -1)) {
        renderSection = true;
      } else if (section === 'Site Assessment Reporter' && (userRoles.indexOf('site-assessment-reporter') > -1)) {
        renderSection = true;
      } else if (section === 'Field Water Data Monitor' && (userRoles.indexOf('field-data-monitor') > -1)) {
        renderSection = true;
      }
      if ((userRoles.indexOf('te-admin') > -1) || (userRoles.indexOf('aws_subscriber') > -1)) {
        renderSection = true;
      }
    }
    return renderSection;
};

export const testRestrictions = (org: any, section: any, global:any) => {
  if(!renderRestrictions(org, section, global)){
    //navigate('/Splash' + window.location.search);
    global.notify('Contact for access');
  } else {
    return true;
  }
}

export const formatDate = (input: any) => {
  const date = new Date(input);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // January is month 0
  const year = date.getFullYear();

  return `${day} ${month} ${year}`
}

////
// Table row values Utilities
////
export const formatRowValue = (value: string | number) => {
  if (isNumber(value)) {
    return isHUC8(value) || isHUC12(value) ? value : formatNumber(convertToNumber(value));
  }
  value = value ?? 'NA';
  return value;
};

///
// AppContext utils
///

export const getDefaultsFromLocalStorage = (): AppContextDefaults => {
  const defaultsStringFromLocalStorage = localStorage.getItem(LOCALSTORAGE_DEFAULTS_KEY);
  if (defaultsStringFromLocalStorage) {
    const { location, huc8 }: AppContextDefaults = JSON.parse(defaultsStringFromLocalStorage);

    return { location, huc8 };
  }

  return {
    huc8: '02070010',
    location: 'Washington',
  };
};
export const getDefaultHuc8 = (storeObject: AppContextDefaults): string => storeObject.huc8;
export const getDefaultLocation = (storeObject: AppContextDefaults): string => storeObject.location;

export const putReport = (input: object) => {
  return localStorage.setItem(REPORT_KEY, JSON.stringify({ ...input }))
}

export const getReportFromStorage = () => {
  const defaultObjectFromStorage = localStorage.getItem(REPORT_KEY);
  if (defaultObjectFromStorage) {
    const report = JSON.parse(defaultObjectFromStorage);
    return report
  }
  return { name: 'Global Live Water Map', parentReportName: 'Home', root: '/' }
};


export const putCountryIntoStorage = (input: string) => localStorage.setItem('Country_Key', JSON.stringify(input))
const countryItem = localStorage.getItem('Country_Key')
export const getCountryFromStorage = () => countryItem && JSON.parse(countryItem || '')

export const putStateIntoStorage = (input: string) => localStorage.setItem('State_Key', JSON.stringify(input))
const stateItem = localStorage.getItem('State_Key')
export const getStateFromStorage = () => stateItem && JSON.parse(stateItem || '')

export const putViewportIntoStorage = (input: object) => localStorage.setItem('Viewport_Key', JSON.stringify(input))
const viewportItem = localStorage.getItem('Viewport_Key')
export const getViewportFromStorage = () => viewportItem && JSON.parse(viewportItem || '')

export const putCustomLocationDataIntoStorage = (input: any[]) => localStorage.setItem('CustomLocationData_Key', JSON.stringify(input))
const CustomLocationDataItem = localStorage.getItem('CustomLocationData_Key')

export const getCustomLocationDataFromStorage = () => CustomLocationDataItem && JSON.parse(CustomLocationDataItem || '')

export const putCustomLayerNamesIntoStorage = (input: any[]) => localStorage.setItem('CustomLayerNames_Key', JSON.stringify(input))
const CustomLayerNamesItem = localStorage.getItem('CustomLayerNames_Key')

export const getCustomLayerNamesFromStorage = () => CustomLayerNamesItem && JSON.parse(CustomLayerNamesItem || '')

export const setLocalReport = (pathname: string) => {
  const configsArray = ReportsConfigs.filter(config => config.route === pathname);
  const reportFromStorage = getReportFromStorage();
  if (configsArray && configsArray[0] && configsArray[0].name) {
    if (reportFromStorage.name !== configsArray[0].name) {
      let configName = configsArray[0].name;
      if (reportFromStorage.name === 'Climate Precipitation Calculator' && pathname === '/ClimatePrecipitationCalculator') {
        configName = 'Climate Precipitation Calculator'
      }
      const localRouteData = {
        name: configName,
        parentReportName: configsArray[0].parentReportName,
        root: configsArray[0].route,
      }
      putReport(localRouteData);
    }
  }
};


export const resolveReportNameFromRespotsConfigs = (route: string): string =>
  ReportsConfigs.find(x => x.route == route)?.name || '';

///
// MapUtils
///
export const getCurrentHuc12LongLat = (
  huc12Value: string,
  huc12Catalog: any[]
): HUC12GeoPoint | undefined => {
  return huc12Catalog.find(x => x.huc12 == huc12Value);
};

export const flyToHUC12sLocation = (
  huc12: string,
  mapRef: MutableRefObject<MapRef | undefined>,
  huc12Catalog: any[]
) => {
  if (mapRef.current) {
    const currentHuc12GeoPoint = getCurrentHuc12LongLat(huc12, huc12Catalog);
    if (currentHuc12GeoPoint) {
      mapRef.current?.flyTo({
        center: [
          parseFloat(currentHuc12GeoPoint.centroid_longitude),
          parseFloat(currentHuc12GeoPoint.centroid_latitude),
        ],
        essential: true,
        zoom: 15,
      });
    }
  }
};

export const flyToInitialLocationHandler = (
  huc8: string,
  mapRef: MutableRefObject<MapRef | undefined>,
  huc8Catalog: any[]
) => {
  if (mapRef.current) {
    const currentHuc8GeoPoint = getCurrentHuc8LongLat(huc8, huc8Catalog);
    if (currentHuc8GeoPoint) {
      mapRef.current?.flyTo({
        center: [
          parseFloat(currentHuc8GeoPoint.centroid_longitude),
          parseFloat(currentHuc8GeoPoint.centroid_latitude),
        ],
        essential: true,
        zoom: 7,
      });
    }
  }
};

export const flyToLngLat = (
  mapRef: MutableRefObject<MapRef | undefined>,
  longitude: number,
  latitude: number
) => {
  if (mapRef.current) {
    mapRef.current?.flyTo({
      center: [longitude, latitude],
      essential: true,
      zoom: 12,
    });
  }
};

export const getCurrentHuc8LongLat = (
  selectedHUC8Value: string,
  huc8Catalog: any[]
): HUC8GeoPoint | undefined => {
  return huc8Catalog.find(x => x.huc8 == selectedHUC8Value);
};

export const getHuc12MembersOfHUC8 = (huc8Value: string, huc12Catalog: any[]): any[] => {
  return huc12Catalog.filter((o: any) => o['huc12'].substr(0, 8) === huc8Value);
};

export const exportToCsv = (data: any, title: any = 'csv') => {
  const nowTime = DateTime.local().toFormat('_ydL_Hms_');
  const csv = unparse(data);
  let hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
  hiddenElement.target = '_blank';
  hiddenElement.download = title + nowTime + '.csv';
  hiddenElement.click();
  if (hiddenElement.parentNode) {
    hiddenElement.parentNode.removeChild(hiddenElement);
  }
  hiddenElement.remove();
}

export const customLayersOff = (global: Globals) => {
  const setBool = false;
  global.setCustomLayer1(setBool)
  global.setCustomLayer2(setBool)
  global.setCustomLayer3(setBool)
  global.setCustomLayer4(setBool)
  global.setCustomLayer5(setBool)
  global.setCustomLayer6(setBool)
  global.setCustomLayer7(setBool)
  global.setCustomLayer8(setBool)
  global.setCustomLayer9(setBool)
  global.setCustomLayer10(setBool)
}

export const scoreDialsOff = (global: Globals) => {
  const setBool = false;
  global.setTwitterSelected(setBool);
  global.setDrinkingWaterQualitySelected(setBool);
  global.setWastewaterSelected(setBool);
  global.setIndustrySelected(setBool);
  global.setSurfacewaterTrueQI(setBool);
  global.setStormWaterTrueQI(setBool);
  global.setAgWaterTrueQI(setBool);
  global.setH303DTrueQI(setBool);
}


export const mapLayersOff = (global: Globals) => {
  const setBool = false;

  let _global = global as any;
  LayerConfig.filter((item) => item.type === 'maplayer').forEach((layer:any) => { 
      if(_global[layer.setSelected] && _global[layer.selected]!==setBool){
          _global[layer.setSelected](setBool)
      }      
  });
  global.setGFSHuc12s(false);
  global.setResultsDataset([]);
  // global.setWaterQualityPortal(false)
  customLayersOff(global)
  scoreDialsOff(global)

  let EvaluationLay = global.layerStatus && global.layerStatus[0] && [...global.layerStatus].forEach((layer:any) => { 
      if(layer && layer.active){
          layer.active = setBool;
      }      
  });
  global.setLayerStatus(EvaluationLay)
}


// Generates a linear-gradient from an array of [number, hexString] or [hexString] of any length

export const generateLinearGradient = (paletteArray: any) => {
  const colors = paletteArray.filter((element: any, index: any) => index % 2 === 1);
  const linearGradient: any = `linear-gradient(to right, ${colors.join(', ')})`;

  return linearGradient
};

export const pollutantColor = (input: string) => {
  switch (input) {
    case 'Mercury, Dioxin, Total Phosphorus, PCBs':
      return '#ff5733'
    case 'Total Phosphorus':
      return '#3498db'
    case 'Mercury, Total Phosphorus':
      return '#2ecc71'
    case 'Unknown Pollutant':
      return 'white'
    case 'Mercury, PCBs':
      return '#e74c3c'
    case 'Mercury, PFOS, PCBs':
      return 'red'
    case 'Sediment/Total Suspended Solids': 
      return '#9b59b6'
    case 'Fecal Coliform':
      return 'brown'
    case 'PCBs':
      return '#1abc9c'
    case 'Mercury, PFOS, Total Phosphorus, Sediment/Total Suspended Solids, PCBs':
      return '#e67e22'
    case 'Mercury, Total Phosphorus, Unknown Pollutant':
      return '#34495e'
    case 'Lead, Zinc, Sediment/Total Suspended Solids, Unknown Pollutant':
      return '#c0392b'
    default:
      break;
  }
}

export const contams = [
  "All",
  "Mercury, Contaminated Sediments",
  "High Phosphorus, Levels, Low DO",
  "Degraded Habitat Recreational Restrictions - Pathogens",
  "PCB, Contaminated Sediments, Mercury, Contaminated Fish Tissue",
  "Elevated Water Temperature",
  "Recreational Restrictions - PathogensChronic Aquatic Toxicity",
  "Degraded Habitat",
  "PCBs, Contaminated Fish Tissue, Elevated pH",
  "Dioxin Contaminated Fish Tissue",
  "Impairment Unknown, High Phosphorus Levels",
  "Impairment Unknown, Acute Aquatic Toxicity",
  "Degraded Habitat, Excess Algal Growth",
  "Degraded Biological Community, Excess Algal Growth",
  "NA, Excess Algal Growth",
  "Impairment Unknown, Recreational Restrictions - Pathogens",
  "PCB Contaminated Sediments",
  "Chronic Aquatic Toxicity, Low DO",
  "PFOS, Contaminated Fish Tissue, Excess Algal Growth",
  "Degraded Biological Community, Low DO",
  "Elevated pH",
  "Recreational Restrictions - PathogensDegraded Habitat",
  "Impairment Unknown, NA",
  "High Phosphorus Levels, NA",
  "Low DO, High Phosphorus Levels",
  "Eutrophication",
  "Elevated Water Temperature, Degraded Habitat",
  "Turbidity, Impairment Unknown",
  "PCBs, Contaminated Fish Tissue, Impairment Unknown",
  "Degraded Biological Community, High Phosphorus Levels",
  "PFOS, Contaminated Fish Tissue, Mercury Contaminated Fish Tissue",
  "Low DO",
  "Degraded Habitat, Low DO",
  "Degraded Biological Community, Acute Aquatic Toxicity",
  "Recreational Restrictions - Pathogens Eutrophication",
  "Dioxin Contaminated Sediments",
  "Recreational Restrictions - Pathogens",
  "Impairment Unknown, Degraded Habitat",
  "PAHs Contaminated Sediments",
  "Degraded Habitat, Degraded Submerged Aquatic Vegetation (SAV)",
  "Recreational Restrictions - Pathogens Excess Algal Growth",
  "Degraded Biological Community",
  "PCBs, Contaminated Fish Tissue, Fish Kills",
  "PCB Contaminated Sediments, Excess Algal Growth",
  "Impairment Unknown, Excess Algal Growth",
  "Elevated Water Temperature, Excess Algal Growth",
  "Elevated Water Temperature, PFOS Contaminated Fish Tissue",
  "Excess Algal Growth",
  "Degraded Habitat, Lead Contaminated Sediments",
  "High Phosphorus Levels, High Phosphorus Levels",
  "Unspecified Metals, Contaminated Sediments",
  "High Phosphorus Levels, Impairment Unknown",
  "Impairment Unknown, PCB Contaminated Sediments",
  "PCBs, Contaminated Fish Tissue, PCBs Contaminated Fish Tissue",
  "Low DO, Mercury Contaminated Fish Tissue",
  "Impairment Unknown",
  "Recreational Restrictions - Pathogens PCBs Contaminated Fish Tissue",
  "High Phosphorus Levels, Excess Algal Growth",
  "Recreational Restrictions - Pathogens Elevated Water Temperature",
  "Chronic Aquatic Toxicity",
  "Chronic Aquatic Toxicity, Impairment Unknown",
  "Eutrophication, Mercury Contaminated Fish Tissue",
  "Recreational Restrictions - Pathogens High Phosphorus Levels",
  "Elevated pH, Excess Algal Growth",
  "Low DO, Excess Algal Growth",
  "Elevated Water Temperature, High Phosphorus Levels",
  "Degraded Biological Community, PAHs Contaminated Sediments",
  "Impairment Unknown, PFOS Contaminated Fish Tissue",
  "Turbidity",
  "Chronic Aquatic Toxicity, Excess Algal Growth",
  "Low DO, Impairment Unknown",
  "Chronic Aquatic Toxicity, Mercury Contaminated Fish Tissue",
  "Degraded Biological Community, Elevated pH",
  "PFOS, Contaminated Fish Tissue",
  "Elevated Water Temperature, Mercury Contaminated Fish Tissue",
  "Degraded Habitat, Mercury Contaminated Fish Tissue",
  "Lead Contaminated Sediments",
  "Low DO, PCBs, Contaminated Fish Tissue",
  "Degraded Habitat, Impairment Unknown",
  "Acute Aquatic Toxicity",
  "High Phosphorus Levels, PCBs Contaminated Fish Tissue",
  "High Phosphorus Levels, Recreational Restrictions - Pathogens",
  "Impairment Unknown, Elevated pH",
  "Degraded Biological Community, Mercury Contaminated Fish Tissue",
  "Dieldrin Contaminated Sediments",
  "High Phosphorus Levels, Mercury Contaminated Fish Tissue",
  "Degraded Biological Community, Impairment Unknown",
  "Recreational Restrictions - Blue Green Algae",
  "Degraded Biological Community, PCBs Contaminated Fish Tissue",
  "DDT Contaminated Sediments",
  "Impairment Unknown, Chronic Aquatic Toxicity",
  "Recreational Restrictions - Pathogens, Impairment Unknown",
  "PCBs, Contaminated Fish Tissue",
  "Recreational Restrictions - Pathogens, Mercury Contaminated Fish Tissue",
  "Chronic Aquatic Toxicity, High Phosphorus Levels",
  "PCBs Contaminated Fish Tissue, Degraded Biological Community",
  "Impairment Unknown, Mercury Contaminated Fish Tissue",
  "Chronic Aquatic Toxicity, Eutrophication",
  "PCBs, Contaminated Fish Tissue, Excess Algal Growth",
  "High Phosphorus Levels, Degraded Habitat",
  "Degraded Habitat, High Phosphorus Levels",
  "Impairment Unknown, Low DO",
  "Eutrophication, Excess Algal Growth",
  "PCBs, Contaminated Fish Tissue, Mercury Contaminated Fish Tissue",
  "Recreational Restrictions - Pathogens Low DO",
  "High Phosphorus Levels",
  "Degraded Habitat, Eutrophication",
  "Impairment Unknown, Impairment Unknown",
  "Elevated Human Health Risks - Toxics",
  "Degraded Habitat, Elevated pH",
  "Recreational Restrictions - Pathogens Unspecified Metals, Contaminated Sediments",
  "Sediment/Total Suspended Solids, Mercury Contaminated Fish Tissue",
  "Mercury Contaminated Fish Tissue",
  "PCB Contaminated Sediments, Impairment Unknown",
  "Elevated Water Temperature, Impairment Unknown",
  "Impairment Unknown, PCBs Contaminated Fish Tissue"
].sort()

export const adjustHexBrightness = (color: string, adjustment: number = 0, multiplier: number = 5) => {
  color = color.replace('#', '');

  adjustment *= multiplier;

  let r = parseInt(color.substring(0, 2), 16);
  let g = parseInt(color.substring(2, 4), 16);
  let b = parseInt(color.substring(4, 6), 16);

  r = Math.min(255, Math.max(0, r + adjustment));
  g = Math.min(255, Math.max(0, g + adjustment));
  b = Math.min(255, Math.max(0, b + adjustment));

  // Format each component to ensure it's a two-digit hex number
  const toHex = (value: number) => value.toString(16).padStart(2, '0');

  const adjustedHexColor = `#${toHex(r)}${toHex(g)}${toHex(b)}`.toUpperCase();

  return adjustedHexColor;
};


export const adjustHSLABrightness = (hslaColor: string, adjustment: number = 0, multiplyer: number = 5) => {
  if(adjustment === 0) return hslaColor;
  const match: any = hslaColor.match(/-?\d+\.?\d*/g);

  adjustment *= multiplyer

  let h = parseInt(match[0]);
  let s = parseFloat(match[1]);
  let l = parseFloat(match[2]);
  let a = parseFloat(match[3]);

  l = Math.min(100, Math.max(0, l + adjustment));
  s = Math.min(100, Math.max(0, s));
  a = Math.min(1, Math.max(0, a));

  const adjustedHSLAColor = `hsla(${h}, ${s}%, ${l}%, ${a})`;

  return adjustedHSLAColor;
};

export const adjustRGBABrightness = (rgbaString: string, adjustment: number = 0, multiplier: number = 5) => {
  if(adjustment === 0) return rgbaString;
  const match: any = rgbaString.match(/-?\d+\.?\d*/g);

  const [red, green, blue, alpha] = match

  adjustment *= multiplier

  const newRed: number = Math.max(Math.min(Math.round(red + (adjustment * 255)), 255), 0);
  const newGreen: number = Math.max(Math.min(Math.round(green + (adjustment * 255)), 255), 0);
  const newBlue: number = Math.max(Math.min(Math.round(blue + (adjustment * 255)), 255), 0);

  return `rgba(${newRed},${newGreen},${newBlue},${alpha})`;
};

export const CEJST_Feilds = [
  {
  "name": "OBJECTID",
  "type": "esriFieldTypeOID",
  "alias": "OBJECTID",
  "domain": null
  },
  {
  "name": "Shape",
  "type": "esriFieldTypeGeometry",
  "alias": "Shape",
  "domain": null
  },
  {
  "name": "GEOID10",
  "type": "esriFieldTypeString",
  "alias": "GEOID10",
  "length": 80,
  "domain": null
  },
  {
  "name": "SF",
  "type": "esriFieldTypeString",
  "alias": "State/Territory",
  "length": 80,
  "domain": null
  },
  {
  "name": "CF",
  "type": "esriFieldTypeString",
  "alias": "County Name",
  "length": 80,
  "domain": null
  },
  {
  "name": "DF_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Diagnosed diabetes among adults aged greater than or equal to 18 years (percentile)",
  "domain": null
  },
  {
  "name": "AF_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Current asthma among adults aged greater than or equal to 18 years (percentile)",
  "domain": null
  },
  {
  "name": "HDF_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Coronary heart disease among adults aged greater than or equal to 18 years (percentile)",
  "domain": null
  },
  {
  "name": "DSF_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Diesel particulate matter exposure (percentile)",
  "domain": null
  },
  {
  "name": "EBF_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Energy burden (percentile)",
  "domain": null
  },
  {
  "name": "EALR_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Expected agricultural loss rate (Natural Hazards Risk Index) (percentile)",
  "domain": null
  },
  {
  "name": "EBLR_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Expected building loss rate (Natural Hazards Risk Index) (percentile)",
  "domain": null
  },
  {
  "name": "EPLR_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Expected population loss rate (Natural Hazards Risk Index) (percentile)",
  "domain": null
  },
  {
  "name": "HBF_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Housing burden (percent) (percentile)",
  "domain": null
  },
  {
  "name": "LLEF_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Low life expectancy (percentile)",
  "domain": null
  },
  {
  "name": "LIF_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Linguistic isolation (percent) (percentile)",
  "domain": null
  },
  {
  "name": "LMI_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Low median household income as a percent of area median income (percentile)",
  "domain": null
  },
  {
  "name": "PM25F_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "PM2.5 in the air (percentile)",
  "domain": null
  },
  {
  "name": "HSEF",
  "type": "esriFieldTypeDouble",
  "alias": "Percent individuals age 25 or over with less than high school degree",
  "domain": null
  },
  {
  "name": "P100_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Percent of individuals < 100% Federal Poverty Line (percentile)",
  "domain": null
  },
  {
  "name": "P200_I_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Percent of individuals below 200% Federal Poverty Line, imputed and adjusted (percentile)",
  "domain": null
  },
  {
  "name": "AJDLI_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Meets the less stringent low income criterion for the adjacency index?",
  "domain": null
  },
  {
  "name": "LPF_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Percent pre-1960s housing (lead paint indicator) (percentile)",
  "domain": null
  },
  {
  "name": "KP_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Share of homes with no kitchen or indoor plumbing (percent) (percentile)",
  "domain": null
  },
  {
  "name": "NPL_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Proximity to NPL sites (percentile)",
  "domain": null
  },
  {
  "name": "RMP_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Proximity to Risk Management Plan (RMP) facilities (percentile)",
  "domain": null
  },
  {
  "name": "TSDF_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Proximity to hazardous waste sites (percentile)",
  "domain": null
  },
  {
  "name": "TPF",
  "type": "esriFieldTypeDouble",
  "alias": "Total population",
  "domain": null
  },
  {
  "name": "TF_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Traffic proximity and volume (percentile)",
  "domain": null
  },
  {
  "name": "UF_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Unemployment (percent) (percentile)",
  "domain": null
  },
  {
  "name": "WF_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Wastewater discharge (percentile)",
  "domain": null
  },
  {
  "name": "UST_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Leaky underground storage tanks (percentile)",
  "domain": null
  },
  {
  "name": "N_WTR",
  "type": "esriFieldTypeInteger",
  "alias": "Water Factor (Definition N)",
  "domain": null
  },
  {
  "name": "N_WKFC",
  "type": "esriFieldTypeInteger",
  "alias": "Workforce Factor (Definition N)",
  "domain": null
  },
  {
  "name": "N_CLT",
  "type": "esriFieldTypeInteger",
  "alias": "Climate Factor (Definition N)",
  "domain": null
  },
  {
  "name": "N_ENY",
  "type": "esriFieldTypeInteger",
  "alias": "Energy Factor (Definition N)",
  "domain": null
  },
  {
  "name": "N_TRN",
  "type": "esriFieldTypeInteger",
  "alias": "Transportation Factor (Definition N)",
  "domain": null
  },
  {
  "name": "N_HSG",
  "type": "esriFieldTypeInteger",
  "alias": "Housing Factor (Definition N)",
  "domain": null
  },
  {
  "name": "N_PLN",
  "type": "esriFieldTypeInteger",
  "alias": "Pollution Factor (Definition N)",
  "domain": null
  },
  {
  "name": "N_HLTH",
  "type": "esriFieldTypeInteger",
  "alias": "Health Factor (Definition N)",
  "domain": null
  },
  {
  "name": "SN_C",
  "type": "esriFieldTypeInteger",
  "alias": "Definition N community, including adjacency index tracts",
  "domain": null
  },
  {
  "name": "SN_T",
  "type": "esriFieldTypeString",
  "alias": "Identified as disadvantaged due to tribal overlap",
  "length": 80,
  "domain": null
  },
  {
  "name": "DLI",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for diabetes and is low income?",
  "domain": null
  },
  {
  "name": "ALI",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for asthma and is low income?",
  "domain": null
  },
  {
  "name": "PLHSE",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for households at or below 100% federal poverty level and has low HS attainment?",
  "domain": null
  },
  {
  "name": "LMILHSE",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for low median household income as a percent of area median income and has low HS attainment?",
  "domain": null
  },
  {
  "name": "ULHSE",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for unemployment and has low HS attainment?",
  "domain": null
  },
  {
  "name": "EPL_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for expected population loss",
  "domain": null
  },
  {
  "name": "EAL_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for expected agricultural loss",
  "domain": null
  },
  {
  "name": "EBL_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for expected building loss",
  "domain": null
  },
  {
  "name": "EB_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for energy burden",
  "domain": null
  },
  {
  "name": "PM25_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for pm2.5 exposure",
  "domain": null
  },
  {
  "name": "DS_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for diesel particulate matter",
  "domain": null
  },
  {
  "name": "TP_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for traffic proximity",
  "domain": null
  },
  {
  "name": "LPP_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for lead paint and the median house value is less than 90th percentile",
  "domain": null
  },
  {
  "name": "HRS_ET",
  "type": "esriFieldTypeString",
  "alias": "Tract-level redlining score meets or exceeds 3.25",
  "length": 80,
  "domain": null
  },
  {
  "name": "KP_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for share of homes without indoor plumbing or a kitchen",
  "domain": null
  },
  {
  "name": "HB_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for housing burden",
  "domain": null
  },
  {
  "name": "RMP_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for RMP proximity",
  "domain": null
  },
  {
  "name": "NPL_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for NPL (superfund sites) proximity",
  "domain": null
  },
  {
  "name": "TSDF_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for proximity to hazardous waste sites",
  "domain": null
  },
  {
  "name": "WD_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for wastewater discharge",
  "domain": null
  },
  {
  "name": "UST_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for leaky underwater storage tanks",
  "domain": null
  },
  {
  "name": "DB_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for diabetes",
  "domain": null
  },
  {
  "name": "A_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for asthma",
  "domain": null
  },
  {
  "name": "HD_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for heart disease",
  "domain": null
  },
  {
  "name": "LLE_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for low life expectancy",
  "domain": null
  },
  {
  "name": "UN_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for unemployment",
  "domain": null
  },
  {
  "name": "LISO_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for households in linguistic isolation",
  "domain": null
  },
  {
  "name": "POV_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for households at or below 100% federal poverty level",
  "domain": null
  },
  {
  "name": "LMI_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for low median household income as a percent of area median income",
  "domain": null
  },
  {
  "name": "IA_LMI_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Low median household income as a percent of territory median income in 2009 exceeds 90th percentile",
  "domain": null
  },
  {
  "name": "IA_UN_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Unemployment (percent) in 2009 exceeds 90th percentile",
  "domain": null
  },
  {
  "name": "IA_POV_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Percentage households below 100% of federal poverty line in 2009 exceeds 90th percentile",
  "domain": null
  },
  {
  "name": "TC",
  "type": "esriFieldTypeDouble",
  "alias": "Total threshold criteria exceeded",
  "domain": null
  },
  {
  "name": "CC",
  "type": "esriFieldTypeDouble",
  "alias": "Total categories exceeded",
  "domain": null
  },
  {
  "name": "IAULHSE",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for unemployment and has low HS education in 2009 (island areas)?",
  "domain": null
  },
  {
  "name": "IAPLHSE",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for households at or below 100% federal poverty level and has low HS education in 2009 (island areas)?",
  "domain": null
  },
  {
  "name": "IALMILHSE",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for low median household income as a percent of area median income and has low HS education in 2009 (island areas)?",
  "domain": null
  },
  {
  "name": "IALMIL_76",
  "type": "esriFieldTypeDouble",
  "alias": "Low median household income as a percent of territory median income in 2009 (percentile)",
  "domain": null
  },
  {
  "name": "IAPLHS_77",
  "type": "esriFieldTypeDouble",
  "alias": "Percentage households below 100% of federal poverty line in 2009 for island areas (percentile)",
  "domain": null
  },
  {
  "name": "IAULHS_78",
  "type": "esriFieldTypeDouble",
  "alias": "Unemployment (percent) in 2009 for island areas (percentile)",
  "domain": null
  },
  {
  "name": "LHE",
  "type": "esriFieldTypeInteger",
  "alias": "Low high school education",
  "domain": null
  },
  {
  "name": "IALHE",
  "type": "esriFieldTypeInteger",
  "alias": "Low high school education in 2009 (island areas)",
  "domain": null
  },
  {
  "name": "IAHSEF",
  "type": "esriFieldTypeDouble",
  "alias": "Percent individuals age 25 or over with less than high school degree in 2009",
  "domain": null
  },
  {
  "name": "N_CLT_EOMI",
  "type": "esriFieldTypeInteger",
  "alias": "At least one climate threshold exceeded",
  "domain": null
  },
  {
  "name": "N_ENY_EOMI",
  "type": "esriFieldTypeInteger",
  "alias": "At least one energy threshold exceeded",
  "domain": null
  },
  {
  "name": "N_TRN_EOMI",
  "type": "esriFieldTypeInteger",
  "alias": "At least one traffic threshold exceeded",
  "domain": null
  },
  {
  "name": "N_HSG_EOMI",
  "type": "esriFieldTypeInteger",
  "alias": "At least one housing threshold exceeded",
  "domain": null
  },
  {
  "name": "N_PLN_EOMI",
  "type": "esriFieldTypeInteger",
  "alias": "At least one pollution threshold exceeded",
  "domain": null
  },
  {
  "name": "N_WTR_EOMI",
  "type": "esriFieldTypeInteger",
  "alias": "At least one water threshold exceeded",
  "domain": null
  },
  {
  "name": "N_HLTH_88",
  "type": "esriFieldTypeInteger",
  "alias": "At least one health threshold exceeded",
  "domain": null
  },
  {
  "name": "N_WKFC_89",
  "type": "esriFieldTypeInteger",
  "alias": "At least one workforce threshold exceeded",
  "domain": null
  },
  {
  "name": "FPL200S",
  "type": "esriFieldTypeInteger",
  "alias": "Is low income (imputed and adjusted)?",
  "domain": null
  },
  {
  "name": "N_WKFC_91",
  "type": "esriFieldTypeInteger",
  "alias": "Both workforce socioeconomic indicators exceeded",
  "domain": null
  },
  {
  "name": "TD_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for DOT travel barriers",
  "domain": null
  },
  {
  "name": "TD_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "DOT Travel Barriers Score (percentile)",
  "domain": null
  },
  {
  "name": "FLD_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Share of properties at risk of flood in 30 years (percentile)",
  "domain": null
  },
  {
  "name": "WFR_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Share of properties at risk of fire in 30 years (percentile)",
  "domain": null
  },
  {
  "name": "FLD_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for share of properties at risk of flood in 30 years",
  "domain": null
  },
  {
  "name": "WFR_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for share of properties at risk of fire in 30 years",
  "domain": null
  },
  {
  "name": "ADJ_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Is the tract surrounded by disadvantaged communities?",
  "domain": null
  },
  {
  "name": "IS_PFS",
  "type": "esriFieldTypeDouble",
  "alias": "Share of the tract's land area that is covered by impervious surface or cropland as a percent (percentile)",
  "domain": null
  },
  {
  "name": "IS_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Greater than or equal to the 90th percentile for share of the tract's land area that is covered by impervious surface or cropland as a percent",
  "domain": null
  },
  {
  "name": "AML_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Is there at least one abandoned mine in this census tract, where missing data is treated as False?",
  "domain": null
  },
  {
  "name": "FUDS_RAW",
  "type": "esriFieldTypeString",
  "alias": "Is there at least one Formerly Used Defense Site (FUDS) in the tract?",
  "length": 80,
  "domain": null
  },
  {
  "name": "FUDS_ET",
  "type": "esriFieldTypeInteger",
  "alias": "Is there at least one Formerly Used Defense Site (FUDS) in the tract, where missing data is treated as False?",
  "domain": null
  },
  {
  "name": "IMP_FLG",
  "type": "esriFieldTypeString",
  "alias": "Income data has been estimated based on neighbor income",
  "length": 80,
  "domain": null
  },
  {
  "name": "DM_B",
  "type": "esriFieldTypeDouble",
  "alias": "Percent Black or African American",
  "domain": null
  },
  {
  "name": "DM_AI",
  "type": "esriFieldTypeDouble",
  "alias": "Percent American Indian / Alaska Native",
  "domain": null
  },
  {
  "name": "DM_A",
  "type": "esriFieldTypeDouble",
  "alias": "Percent Asian",
  "domain": null
  },
  {
  "name": "DM_HI",
  "type": "esriFieldTypeDouble",
  "alias": "Percent Native Hawaiian or Pacific",
  "domain": null
  },
  {
  "name": "DM_T",
  "type": "esriFieldTypeDouble",
  "alias": "Percent two or more races",
  "domain": null
  },
  {
  "name": "DM_W",
  "type": "esriFieldTypeDouble",
  "alias": "Percent White",
  "domain": null
  },
  {
  "name": "DM_H",
  "type": "esriFieldTypeDouble",
  "alias": "Percent Hispanic or Latino",
  "domain": null
  },
  {
  "name": "DM_O",
  "type": "esriFieldTypeDouble",
  "alias": "Percent other races",
  "domain": null
  },
  {
  "name": "AGE_10",
  "type": "esriFieldTypeDouble",
  "alias": "Percent age under 10",
  "domain": null
  },
  {
  "name": "AGE_MIDDLE",
  "type": "esriFieldTypeDouble",
  "alias": "Percent age 10 to 64",
  "domain": null
  },
  {
  "name": "AGE_OLD",
  "type": "esriFieldTypeDouble",
  "alias": "Percent age over 64",
  "domain": null
  },
  {
  "name": "TA_COU_116",
  "type": "esriFieldTypeDouble",
  "alias": "Number of Tribal areas within Census tract for Alaska",
  "domain": null
  },
  {
  "name": "TA_COUNT_C",
  "type": "esriFieldTypeDouble",
  "alias": "Number of Tribal areas within Census tract",
  "domain": null
  },
  {
  "name": "TA_PERC",
  "type": "esriFieldTypeDouble",
  "alias": "Percent of the Census tract that is within Tribal areas",
  "domain": null
  },
  {
  "name": "TA_PERC_FE",
  "type": "esriFieldTypeDouble",
  "alias": "Percent of the Census tract that is within Tribal areas, for display",
  "domain": null
  },
  {
  "name": "UI_EXP",
  "type": "esriFieldTypeString",
  "alias": "UI_EXP",
  "length": 80,
  "domain": null
  },
  {
  "name": "THRHLD",
  "type": "esriFieldTypeDouble",
  "alias": "THRHLD",
  "domain": null
  },
  {
  "name": "Shape.STLength()",
  "type": "esriFieldTypeDouble",
  "alias": "Shape_Length",
  "domain": null
  },
  {
  "name": "Shape.STArea()",
  "type": "esriFieldTypeDouble",
  "alias": "Shape_Area",
  "domain": null
  }
]

export const handleFibColor = (input:any) => {
  const int = parseFloat(input);
  if (int >= 0 && int <= 35) {
    return '#92D051'; 
  } else if (int >= 36 && int <= 70) {
    return '#FEFF00'; 
  } else if (int >= 71 && int <= 250) {
    return '#FF0000'; 
  } else if (int > 250) {
    return '#CB019A'; 
  } else {
    return '#92D051'; 
  }
}

export const fetchHuc8 = async (lat: any, lng: any, global: any) => {
  let pointHuc8: any;
  global.setLoading(true);
  const res = await agent.Utilities.LatLngToHuc8(lat, lng)
    .then(({ body }: any) => (pointHuc8 = body[0].Huc8))
    .catch((error: any) => console.log(error))
    .finally(() => global.setLoading(false));

  return pointHuc8;
};

export const layerList: any[] = []


export const updateCentroidState = (bbox: any[], report: any, updateState: any) => {
  let city: any, country: any, state: any, huc: any;
  let [west, south, east, north] = bbox; 

  const lng = (west + east) / 2;
  const lat = (south + north) / 2;

  const hucPromise = agent
    .Utilities
    .LatLngToHuc8(lat, lng)
    .then(({ body }: any) => huc = body[0]?.Huc8 || '');

  const cityPromise = agent
    .Utilities
    .LatLngToCity(lat, lng)
    .then(({ features }: any) => {
      const { place_name } = features[0];
      const [cityName, stateName, countryName] = place_name.split(',');
      city = cityName;
      country = countryName;
      state = stateName;
    });

  Promise.all([hucPromise, cityPromise]).then(() => {
    if (city && country && state) updateState(huc, `${city}, ${state}`, report, country);
  });
}

export const updateGeolocateState = (lat: any, lng: any, report: any, updateState: any) => {
  let city: any, country: any, state: any, huc: any;

  const hucPromise = agent
    .Utilities
    .LatLngToHuc8(lat, lng)
    .then(({ body }: any) => huc = body[0]?.Huc8 || '');

  const cityPromise = agent
    .Utilities
    .LatLngToCity(lat, lng)
    .then(({ features }: any) => {
      const { place_name } = features[0];
      const [cityName, stateName, countryName] = place_name.split(',');
      city = cityName;
      country = countryName;
      state = stateName;
    });

  Promise.all([hucPromise, cityPromise]).then(() => {
    if (city && country && state) updateState(huc, `${city}, ${state}`, report, country);
  });
}

export const findTileCentroid = (mapInstance: any, event: any) => {

  const calculateBoundingBox = (coords: any) => {
    let minLng = Infinity, minLat = Infinity;
    let maxLng = -Infinity, maxLat = -Infinity;

    coords.forEach(([lng, lat]: any) => {
      if (lng < minLng) minLng = lng;
      if (lat < minLat) minLat = lat;
      if (lng > maxLng) maxLng = lng;
      if (lat > maxLat) maxLat = lat;
    });

    return { minLng, minLat, maxLng, maxLat };
  };

  const findCenter = ({ minLng, minLat, maxLng, maxLat }: any) => {
    const centerLng = (minLng + maxLng) / 2;
    const centerLat = (minLat + maxLat) / 2;
    return [centerLng, centerLat];
  };

  const features = mapInstance.queryRenderedFeatures(event.point);

  if (features.length > 0) {
    const { geometry } = features[0];
    let coordinates;

    if (geometry.type === 'Point') {
      coordinates = [geometry.coordinates];
    } else if (geometry.type === 'LineString') {
      coordinates = geometry.coordinates; 
    } else if (geometry.type === 'Polygon') {
      coordinates = geometry.coordinates[0]; 
    } else {
      const { lat, lng } = event.lngLat
      return [lng, lat]; 
    }

    const bbox = calculateBoundingBox(coordinates);
    const center = findCenter(bbox);

    return center;
  }

  return null; 
};

export const updateLocationContext = async (lat: any, lng: any, report: any, country: any, updateContext: any) => {

  const place: any = await agent.Utilities.LatLngToCity(lat, lng)
  const huc: any = await agent.Utilities.LatLngToHuc8(lat, lng)

  Promise.all([huc, place]).then(() => {

    const huc8: any = huc.body[0].Huc8
    const placeName = place.features[0].place_name

    if (huc8 && placeName) updateContext(huc8, placeName, report, country);
  });

}
