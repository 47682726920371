
export const legendNwshazard = [
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                192,
                192,
                192,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "911 Telephone Outage",
        "label": "911 Telephone Outage"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                192,
                192,
                192,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Administrative Message",
        "label": "Administrative Message"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                128,
                128,
                128,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Air Quality Alert",
        "label": "Air Quality Alert"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                128,
                128,
                128,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Air Stagnation Advisory",
        "label": "Air Stagnation Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                105,
                105,
                105,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Ashfall Advisory",
        "label": "Ashfall Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                169,
                169,
                169,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Ashfall Warning",
        "label": "Ashfall Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                205,
                133,
                63,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Avalanche Advisory",
        "label": "Avalanche Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                30,
                144,
                255,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Avalanche Warning",
        "label": "Avalanche Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                244,
                164,
                96,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Avalanche Watch",
        "label": "Avalanche Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                64,
                224,
                208,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Beach Hazards Statement",
        "label": "Beach Hazards Statement"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                69,
                0,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Blizzard Warning",
        "label": "Blizzard Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                173,
                255,
                47,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Blizzard Watch",
        "label": "Blizzard Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                189,
                183,
                107,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Blowing Dust Advisory",
        "label": "Blowing Dust Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                228,
                196,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Blowing Dust Warning",
        "label": "Blowing Dust Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                255,
                255,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Blue Alert",
        "label": "Blue Alert"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                216,
                191,
                216,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Brisk Wind Advisory",
        "label": "Brisk Wind Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                255,
                255,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Child Abduction Emergency",
        "label": "Child Abduction Emergency"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                182,
                193,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Civil Danger Warning",
        "label": "Civil Danger Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                182,
                193,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Civil Emergency Message",
        "label": "Civil Emergency Message"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                124,
                252,
                0,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Coastal Flood Advisory",
        "label": "Coastal Flood Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                107,
                142,
                35,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Coastal Flood Statement",
        "label": "Coastal Flood Statement"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                34,
                139,
                34,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Coastal Flood Warning",
        "label": "Coastal Flood Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                102,
                205,
                170,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Coastal Flood Watch",
        "label": "Coastal Flood Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                112,
                128,
                144,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Dense Fog Advisory",
        "label": "Dense Fog Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                240,
                230,
                140,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Dense Smoke Advisory",
        "label": "Dense Smoke Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                189,
                183,
                107,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Dust Advisory",
        "label": "Dust Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                228,
                196,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Dust Storm Warning",
        "label": "Dust Storm Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                139,
                69,
                19,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Earthquake Warning",
        "label": "Earthquake Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                127,
                255,
                0,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Evacuation - Immediate",
        "label": "Evacuation - Immediate"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                199,
                21,
                133,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Excessive Heat Warning",
        "label": "Excessive Heat Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                128,
                0,
                0,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Excessive Heat Watch",
        "label": "Excessive Heat Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                0,
                0,
                255,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Extreme Cold Warning",
        "label": "Extreme Cold Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                0,
                0,
                255,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Extreme Cold Watch",
        "label": "Extreme Cold Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                233,
                150,
                122,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Extreme Fire Danger",
        "label": "Extreme Fire Danger"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                140,
                0,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Extreme Wind Warning",
        "label": "Extreme Wind Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                160,
                82,
                45,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Fire Warning",
        "label": "Fire Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                222,
                173,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Fire Weather Watch",
        "label": "Fire Weather Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                139,
                0,
                0,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Flash Flood Statement",
        "label": "Flash Flood Statement"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                139,
                0,
                0,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Flash Flood Warning",
        "label": "Flash Flood Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                46,
                139,
                87,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Flash Flood Watch",
        "label": "Flash Flood Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                0,
                255,
                127,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Flood Advisory",
        "label": "Flood Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                0,
                255,
                0,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Flood Statement",
        "label": "Flood Statement"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                0,
                255,
                0,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Flood Warning",
        "label": "Flood Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                46,
                139,
                87,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Flood Watch",
        "label": "Flood Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                72,
                61,
                139,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Freeze Warning",
        "label": "Freeze Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                0,
                255,
                255,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Freeze Watch",
        "label": "Freeze Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                0,
                128,
                128,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Freezing Fog Advisory",
        "label": "Freezing Fog Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                218,
                122,
                214,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Freezing Rain Advisory",
        "label": "Freezing Rain Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                0,
                191,
                255,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Freezing Spray Advisory",
        "label": "Freezing Spray Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                100,
                149,
                237,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Frost Advisory",
        "label": "Frost Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                221,
                160,
                221,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Gale Warning",
        "label": "Gale Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                192,
                203,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Gale Watch",
        "label": "Gale Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                148,
                0,
                211,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Hard Freeze Warning",
        "label": "Hard Freeze Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                65,
                105,
                225,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Hard Freeze Watch",
        "label": "Hard Freeze Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                75,
                0,
                130,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Hazardous Materials Warning",
        "label": "Hazardous Materials Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                216,
                191,
                216,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Hazardous Seas Warning",
        "label": "Hazardous Seas Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                72,
                61,
                139,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Hazardous Seas Watch",
        "label": "Hazardous Seas Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                238,
                232,
                170,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Hazardous Weather Outlook",
        "label": "Hazardous Weather Outlook"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                127,
                80,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Heat Advisory",
        "label": "Heat Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                0,
                191,
                255,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Heavy Freezing Spray Warning",
        "label": "Heavy Freezing Spray Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                188,
                143,
                143,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Heavy Freezing Spray Watch",
        "label": "Heavy Freezing Spray Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                186,
                85,
                211,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "High Surf Advisory",
        "label": "High Surf Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                34,
                139,
                34,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "High Surf Warning",
        "label": "High Surf Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                218,
                165,
                32,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "High Wind Warning",
        "label": "High Wind Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                184,
                134,
                11,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "High Wind Watch",
        "label": "High Wind Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                205,
                92,
                92,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Hurricane Force Wind Warning",
        "label": "Hurricane Force Wind Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                153,
                50,
                204,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Hurricane Force Wind Watch",
        "label": "Hurricane Force Wind Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                228,
                181,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Hurricane Local Statement",
        "label": "Hurricane Local Statement"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                220,
                20,
                60,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Hurricane Warning",
        "label": "Hurricane Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                0,
                255,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Hurricane Watch",
        "label": "Hurricane Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                0,
                255,
                127,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Hydrologic Advisory",
        "label": "Hydrologic Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                144,
                238,
                144,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Hydrologic Outlook",
        "label": "Hydrologic Outlook"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                139,
                0,
                139,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Ice Storm Warning",
        "label": "Ice Storm Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                72,
                209,
                204,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Lake Effect Snow Advisory",
        "label": "Lake Effect Snow Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                0,
                139,
                139,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Lake Effect Snow Warning",
        "label": "Lake Effect Snow Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                135,
                206,
                250,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Lake Effect Snow Watch",
        "label": "Lake Effect Snow Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                210,
                180,
                140,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Lake Wind Advisory",
        "label": "Lake Wind Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                124,
                252,
                0,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Lakeshore Flood Advisory",
        "label": "Lakeshore Flood Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                107,
                142,
                35,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Lakeshore Flood Statement",
        "label": "Lakeshore Flood Statement"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                34,
                139,
                34,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Lakeshore Flood Warning",
        "label": "Lakeshore Flood Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                102,
                205,
                170,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Lakeshore Flood Watch",
        "label": "Lakeshore Flood Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                192,
                192,
                192,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Law Enforcement Warning",
        "label": "Law Enforcement Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                192,
                192,
                192,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Local Area Emergency",
        "label": "Local Area Emergency"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                165,
                42,
                42,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Low Water Advisory",
        "label": "Low Water Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                239,
                213,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Marine Weather Statement",
        "label": "Marine Weather Statement"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                75,
                0,
                130,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Nuclear Power Plant Warning",
        "label": "Nuclear Power Plant Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                75,
                0,
                130,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Radiological Hazard Warning",
        "label": "Radiological Hazard Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                20,
                147,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Red Flag Warning",
        "label": "Red Flag Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                64,
                224,
                208,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Rip Current Statement",
        "label": "Rip Current Statement"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                165,
                0,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Severe Thunderstorm Warning",
        "label": "Severe Thunderstorm Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                219,
                112,
                147,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Severe Thunderstorm Watch",
        "label": "Severe Thunderstorm Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                0,
                255,
                255,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Severe Weather Statement",
        "label": "Severe Weather Statement"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                250,
                128,
                114,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Shelter In Place Warning",
        "label": "Shelter In Place Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                152,
                251,
                152,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Short Term Forecast",
        "label": "Short Term Forecast"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                216,
                191,
                216,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Small Craft Advisory",
        "label": "Small Craft Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                216,
                191,
                216,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Small Craft Advisory For Rough Bar",
        "label": "Small Craft Advisory For Rough Bar"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                216,
                191,
                216,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Small Craft Advisory for Hazardous Seas",
        "label": "Small Craft Advisory For Hazardous Seas"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                216,
                191,
                216,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Small Craft Advisory for Winds",
        "label": "Small Craft Advisory For Winds"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                199,
                21,
                133,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Snow Squall Warning",
        "label": "Snow Squall Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                165,
                0,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Special Marine Warning",
        "label": "Special Marine Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                228,
                181,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Special Weather Statement",
        "label": "Special Weather Statement"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                181,
                36,
                247,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Storm Surge Warning",
        "label": "Storm Surge Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                219,
                127,
                247,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Storm Surge Watch",
        "label": "Storm Surge Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                148,
                0,
                211,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Storm Warning",
        "label": "Storm Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                228,
                181,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Storm Watch",
        "label": "Storm Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                0,
                0,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Tornado Warning",
        "label": "Tornado Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                255,
                0,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Tornado Watch",
        "label": "Tornado Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                228,
                181,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Tropical Depression Local Statement",
        "label": "Tropical Depression Local Statement"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                228,
                181,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Tropical Storm Local Statement",
        "label": "Tropical Storm Local Statement"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                178,
                34,
                34,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Tropical Storm Warning",
        "label": "Tropical Storm Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                240,
                128,
                128,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Tropical Storm Watch",
        "label": "Tropical Storm Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                210,
                105,
                30,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Tsunami Advisory",
        "label": "Tsunami Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                253,
                99,
                71,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Tsunami Warning",
        "label": "Tsunami Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                0,
                255,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Tsunami Watch",
        "label": "Tsunami Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                228,
                181,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Typhoon Local Statement",
        "label": "Typhoon Local Statement"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                220,
                20,
                60,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Typhoon Warning",
        "label": "Typhoon Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                0,
                255,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Typhoon Watch",
        "label": "Typhoon Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                47,
                79,
                79,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Volcano Warning",
        "label": "Volcano Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                210,
                180,
                140,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Wind Advisory",
        "label": "Wind Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                175,
                238,
                238,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Wind Chill Advisory",
        "label": "Wind Chill Advisory"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                176,
                196,
                222,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Wind Chill Warning",
        "label": "Wind Chill Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                95,
                158,
                160,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Wind Chill Watch",
        "label": "Wind Chill Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                255,
                105,
                180,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Winter Storm Warning",
        "label": "Winter Storm Warning"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                70,
                130,
                180,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Winter Storm Watch",
        "label": "Winter Storm Watch"
    },
    {
        "symbol": {
            "type": "esriSFS",
            "style": "esriSFSSolid",
            "color": [
                123,
                104,
                238,
                255
            ],
            "outline": {
                "type": "esriSLS",
                "style": "esriSLSSolid",
                "color": [
                    110,
                    110,
                    110,
                    255
                ],
                "width": 0.4
            }
        },
        "value": "Winter Weather Advisory",
        "label": "Winter Weather Advisory"
    }
];

const configObj_ = {
 "layers": [
  {
   "currentVersion": 10.91,
   "cimVersion": "2.9.0",
   "id": 0,
   "name": "CurrentWarnings",
   "type": "Feature Layer",
   "description": "",
   "geometryType": "esriGeometryPolygon",
   "sourceSpatialReference": {
    "wkid": 102100,
    "latestWkid": 3857,
    "xyTolerance": 0.001,
    "zTolerance": 0.001,
    "mTolerance": 0.001,
    "falseX": -20037700,
    "falseY": -30241100,
    "xyUnits": 10000,
    "falseZ": -100000,
    "zUnits": 10000,
    "falseM": -100000,
    "mUnits": 10000
   },
   "copyrightText": "",
   "parentLayer": null,
   "subLayers": [],
   "minScale": 0,
   "maxScale": 0,
   "referenceScale": 0.0,
   "drawingInfo": {
    "renderer": {
     "type": "uniqueValue",
     "field1": "phenom",
     "field2": "sig",
     "uniqueValueInfos": [
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         0,
         0,
         0,
         0
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          57,
          121,
          57,
          255
         ],
         "width": 2
        }
       },
       "value": "FF,W",
       "label": "Flash Flood Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         0,
         0,
         0,
         0
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          230,
          152,
          0,
          255
         ],
         "width": 2
        }
       },
       "value": "MA,W",
       "label": "Special Marine Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         0,
         0,
         0,
         0
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          255,
          255,
          0,
          255
         ],
         "width": 2
        }
       },
       "value": "SV,W",
       "label": "Severe Thunderstorm Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         0,
         0,
         0,
         0
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          255,
          0,
          0,
          255
         ],
         "width": 2
        }
       },
       "value": "TO,W",
       "label": "Tornado Warning"
      }
     ],
     "fieldDelimiter": ",",
     "authoringInfo": {
      "colorRamp": {
       "type": "multipart",
       "colorRamps": [
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          252,
          194,
          214,
          255
         ],
         "toColor": [
          252,
          194,
          214,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          179,
          252,
          185,
          255
         ],
         "toColor": [
          179,
          252,
          185,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          184,
          231,
          252,
          255
         ],
         "toColor": [
          184,
          231,
          252,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          190,
          182,
          252,
          255
         ],
         "toColor": [
          190,
          182,
          252,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          252,
          234,
          184,
          255
         ],
         "toColor": [
          252,
          234,
          184,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          252,
          179,
          244,
          255
         ],
         "toColor": [
          252,
          179,
          244,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          215,
          252,
          229,
          255
         ],
         "toColor": [
          215,
          252,
          229,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          235,
          212,
          252,
          255
         ],
         "toColor": [
          235,
          212,
          252,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          230,
          252,
          182,
          255
         ],
         "toColor": [
          230,
          252,
          182,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          252,
          213,
          197,
          255
         ],
         "toColor": [
          252,
          213,
          197,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          192,
          209,
          252,
          255
         ],
         "toColor": [
          192,
          209,
          252,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          184,
          252,
          244,
          255
         ],
         "toColor": [
          184,
          252,
          244,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          241,
          252,
          207,
          255
         ],
         "toColor": [
          241,
          252,
          207,
          255
         ]
        }
       ]
      }
     }
    },
    "scaleSymbols": true,
    "transparency": 0,
    "labelingInfo": null
   },
   "defaultVisibility": true,
   "extent": {
    "xmin": -1.84796620668E7,
    "ymin": 2144793.039500002,
    "xmax": -7675556.680299999,
    "ymax": 1.1183506100100003E7,
    "spatialReference": {
     "wkid": 102100,
     "latestWkid": 3857,
     "xyTolerance": 0.001,
     "zTolerance": 0.001,
     "mTolerance": 0.001,
     "falseX": -2.2041257773920003E7,
     "falseY": -30241100,
     "xyUnits": 1.441480358985159E8,
     "falseZ": -100000,
     "zUnits": 10000,
     "falseM": -100000,
     "mUnits": 10000
    }
   },
   "hasAttachments": false,
   "htmlPopupType": "esriServerHTMLPopupTypeAsHTMLText",
   "displayField": "cap_id",
   "typeIdField": null,
   "subtypeFieldName": null,
   "subtypeField": null,
   "defaultSubtypeCode": null,
   "fields": [
    {
     "name": "objectid",
     "type": "esriFieldTypeOID",
     "alias": "objectid",
     "domain": null
    },
    {
     "name": "prod_type",
     "type": "esriFieldTypeString",
     "alias": "Hazard Type",
     "length": 40,
     "domain": null
    },
    {
     "name": "msg_type",
     "type": "esriFieldTypeString",
     "alias": "Message Type",
     "length": 3,
     "domain": null
    },
    {
     "name": "phenom",
     "type": "esriFieldTypeString",
     "alias": "Hazard Abbreviation",
     "length": 2,
     "domain": null
    },
    {
     "name": "url",
     "type": "esriFieldTypeString",
     "alias": "URL",
     "length": 254,
     "domain": null
    },
    {
     "name": "expiration",
     "type": "esriFieldTypeString",
     "alias": "Expiration",
     "length": 25,
     "domain": null
    },
    {
     "name": "onset",
     "type": "esriFieldTypeString",
     "alias": "Onset",
     "length": 25,
     "domain": null
    },
    {
     "name": "ends",
     "type": "esriFieldTypeString",
     "alias": "Ends",
     "length": 25,
     "domain": null
    },
    {
     "name": "issuance",
     "type": "esriFieldTypeString",
     "alias": "Issuance",
     "length": 25,
     "domain": null
    },
    {
     "name": "sig",
     "type": "esriFieldTypeString",
     "alias": "Alert Type",
     "length": 1,
     "domain": null
    },
    {
     "name": "wfo",
     "type": "esriFieldTypeString",
     "alias": "Forecast Office",
     "length": 4,
     "domain": null
    },
    {
     "name": "event",
     "type": "esriFieldTypeString",
     "alias": "Event",
     "length": 4,
     "domain": null
    },
    {
     "name": "idp_filedate",
     "type": "esriFieldTypeDate",
     "alias": "GIS File Date",
     "length": 8,
     "domain": null
    },
    {
     "name": "idp_ingestdate",
     "type": "esriFieldTypeDate",
     "alias": "GIS Ingest Date",
     "length": 8,
     "domain": null
    },
    {
     "name": "cap_id",
     "type": "esriFieldTypeString",
     "alias": "Message ID",
     "length": 254,
     "domain": null
    },
    {
     "name": "shape",
     "type": "esriFieldTypeGeometry",
     "alias": "shape",
     "domain": null
    }
   ],
   "geometryField": {
    "name": "shape",
    "type": "esriFieldTypeGeometry",
    "alias": "shape"
   },
   "indexes": null,
   "subtypes": [],
   "relationships": [],
   "canModifyLayer": true,
   "canScaleSymbols": false,
   "hasLabels": false,
   "capabilities": "Map,Query,Data",
   "maxRecordCount": 2000,
   "supportsStatistics": true,
   "supportsExceedsLimitStatistics": true,
   "supportsAdvancedQueries": true,
   "supportedQueryFormats": "JSON, geoJSON, PBF",
   "isDataVersioned": false,
   "ownershipBasedAccessControlForFeatures": {"allowOthersToQuery": true},
   "useStandardizedQueries": true,
   "supportedSpatialRelationships": [
    "esriSpatialRelIntersects",
    "esriSpatialRelContains",
    "esriSpatialRelCrosses",
    "esriSpatialRelEnvelopeIntersects",
    "esriSpatialRelIndexIntersects",
    "esriSpatialRelOverlaps",
    "esriSpatialRelTouches",
    "esriSpatialRelWithin",
    "esriSpatialRelRelation"
   ],
   "advancedQueryCapabilities": {
    "useStandardizedQueries": true,
    "supportsStatistics": true,
    "supportsPercentileStatistics": true,
    "supportsHavingClause": true,
    "supportsOrderBy": true,
    "supportsDistinct": true,
    "supportsCountDistinct": true,
    "supportsPagination": true,
    "supportsLod": false,
    "supportsQueryWithLodSR": false,
    "supportsTrueCurve": true,
    "supportsQueryWithDatumTransformation": true,
    "supportsReturningQueryExtent": true,
    "supportsQueryWithDistance": true,
    "supportsSqlExpression": true,
    "supportsTimeRelation": true,
    "supportsSqlFormat": false,
    "supportsQueryAnalytic": true
   },
   "supportsDatumTransformation": true,
   "advancedQueryAnalyticCapabilities": {
    "supportsLinearRegression": true,
    "supportsAsync": false,
    "supportsPercentileAnalytic": false
   },
   "dateFieldsTimeReference": null,
   "preferredTimeReference": null,
   "datesInUnknownTimezone": false,
   "hasGeometryProperties": true,
   "geometryProperties": {
    "shapeAreaFieldName": "st_area(shape)",
    "shapeLengthFieldName": "st_perimeter(shape)",
    "units": "esriMeters"
   },
   "hasMetadata": true,
   "isDataArchived": false,
   "archivingInfo": {
    "supportsQueryWithHistoricMoment": false,
    "startArchivingMoment": -1
   },
   "supportsCoordinatesQuantization": true,
   "supportsDynamicLegends": true
  },
  {
   "currentVersion": 10.91,
   "cimVersion": "2.9.0",
   "id": 1,
   "name": "WatchesWarnings",
   "type": "Feature Layer",
   "description": "",
   "geometryType": "esriGeometryPolygon",
   "sourceSpatialReference": {
    "wkid": 102100,
    "latestWkid": 3857,
    "xyTolerance": 0.001,
    "zTolerance": 0.001,
    "mTolerance": 0.001,
    "falseX": -20037700,
    "falseY": -30241100,
    "xyUnits": 10000,
    "falseZ": -100000,
    "zUnits": 10000,
    "falseM": -100000,
    "mUnits": 10000
   },
   "copyrightText": "",
   "parentLayer": null,
   "subLayers": [],
   "minScale": 0,
   "maxScale": 0,
   "referenceScale": 0.0,
   "drawingInfo": {
    "renderer": {
     "type": "uniqueValue",
     "field1": "prod_type",
     "uniqueValueInfos": [
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         192,
         192,
         192,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "911 Telephone Outage",
       "label": "911 Telephone Outage"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         192,
         192,
         192,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Administrative Message",
       "label": "Administrative Message"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         128,
         128,
         128,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Air Quality Alert",
       "label": "Air Quality Alert"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         128,
         128,
         128,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Air Stagnation Advisory",
       "label": "Air Stagnation Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         105,
         105,
         105,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Ashfall Advisory",
       "label": "Ashfall Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         169,
         169,
         169,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Ashfall Warning",
       "label": "Ashfall Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         205,
         133,
         63,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Avalanche Advisory",
       "label": "Avalanche Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         30,
         144,
         255,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Avalanche Warning",
       "label": "Avalanche Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         244,
         164,
         96,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Avalanche Watch",
       "label": "Avalanche Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         64,
         224,
         208,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Beach Hazards Statement",
       "label": "Beach Hazards Statement"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         69,
         0,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Blizzard Warning",
       "label": "Blizzard Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         173,
         255,
         47,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Blizzard Watch",
       "label": "Blizzard Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         189,
         183,
         107,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Blowing Dust Advisory",
       "label": "Blowing Dust Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         228,
         196,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Blowing Dust Warning",
       "label": "Blowing Dust Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         255,
         255,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Blue Alert",
       "label": "Blue Alert"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         216,
         191,
         216,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Brisk Wind Advisory",
       "label": "Brisk Wind Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         255,
         255,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Child Abduction Emergency",
       "label": "Child Abduction Emergency"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         182,
         193,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Civil Danger Warning",
       "label": "Civil Danger Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         182,
         193,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Civil Emergency Message",
       "label": "Civil Emergency Message"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         124,
         252,
         0,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Coastal Flood Advisory",
       "label": "Coastal Flood Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         107,
         142,
         35,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Coastal Flood Statement",
       "label": "Coastal Flood Statement"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         34,
         139,
         34,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Coastal Flood Warning",
       "label": "Coastal Flood Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         102,
         205,
         170,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Coastal Flood Watch",
       "label": "Coastal Flood Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         112,
         128,
         144,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Dense Fog Advisory",
       "label": "Dense Fog Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         240,
         230,
         140,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Dense Smoke Advisory",
       "label": "Dense Smoke Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         189,
         183,
         107,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Dust Advisory",
       "label": "Dust Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         228,
         196,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Dust Storm Warning",
       "label": "Dust Storm Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         139,
         69,
         19,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Earthquake Warning",
       "label": "Earthquake Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         127,
         255,
         0,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Evacuation - Immediate",
       "label": "Evacuation - Immediate"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         199,
         21,
         133,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Excessive Heat Warning",
       "label": "Excessive Heat Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         128,
         0,
         0,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Excessive Heat Watch",
       "label": "Excessive Heat Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         0,
         0,
         255,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Extreme Cold Warning",
       "label": "Extreme Cold Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         0,
         0,
         255,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Extreme Cold Watch",
       "label": "Extreme Cold Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         233,
         150,
         122,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Extreme Fire Danger",
       "label": "Extreme Fire Danger"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         140,
         0,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Extreme Wind Warning",
       "label": "Extreme Wind Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         160,
         82,
         45,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Fire Warning",
       "label": "Fire Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         222,
         173,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Fire Weather Watch",
       "label": "Fire Weather Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         139,
         0,
         0,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Flash Flood Statement",
       "label": "Flash Flood Statement"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         139,
         0,
         0,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Flash Flood Warning",
       "label": "Flash Flood Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         46,
         139,
         87,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Flash Flood Watch",
       "label": "Flash Flood Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         0,
         255,
         127,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Flood Advisory",
       "label": "Flood Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         0,
         255,
         0,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Flood Statement",
       "label": "Flood Statement"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         0,
         255,
         0,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Flood Warning",
       "label": "Flood Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         46,
         139,
         87,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Flood Watch",
       "label": "Flood Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         72,
         61,
         139,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Freeze Warning",
       "label": "Freeze Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         0,
         255,
         255,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Freeze Watch",
       "label": "Freeze Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         0,
         128,
         128,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Freezing Fog Advisory",
       "label": "Freezing Fog Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         218,
         122,
         214,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Freezing Rain Advisory",
       "label": "Freezing Rain Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         0,
         191,
         255,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Freezing Spray Advisory",
       "label": "Freezing Spray Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         100,
         149,
         237,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Frost Advisory",
       "label": "Frost Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         221,
         160,
         221,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Gale Warning",
       "label": "Gale Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         192,
         203,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Gale Watch",
       "label": "Gale Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         148,
         0,
         211,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Hard Freeze Warning",
       "label": "Hard Freeze Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         65,
         105,
         225,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Hard Freeze Watch",
       "label": "Hard Freeze Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         75,
         0,
         130,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Hazardous Materials Warning",
       "label": "Hazardous Materials Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         216,
         191,
         216,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Hazardous Seas Warning",
       "label": "Hazardous Seas Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         72,
         61,
         139,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Hazardous Seas Watch",
       "label": "Hazardous Seas Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         238,
         232,
         170,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Hazardous Weather Outlook",
       "label": "Hazardous Weather Outlook"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         127,
         80,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Heat Advisory",
       "label": "Heat Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         0,
         191,
         255,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Heavy Freezing Spray Warning",
       "label": "Heavy Freezing Spray Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         188,
         143,
         143,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Heavy Freezing Spray Watch",
       "label": "Heavy Freezing Spray Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         186,
         85,
         211,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "High Surf Advisory",
       "label": "High Surf Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         34,
         139,
         34,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "High Surf Warning",
       "label": "High Surf Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         218,
         165,
         32,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "High Wind Warning",
       "label": "High Wind Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         184,
         134,
         11,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "High Wind Watch",
       "label": "High Wind Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         205,
         92,
         92,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Hurricane Force Wind Warning",
       "label": "Hurricane Force Wind Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         153,
         50,
         204,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Hurricane Force Wind Watch",
       "label": "Hurricane Force Wind Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         228,
         181,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Hurricane Local Statement",
       "label": "Hurricane Local Statement"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         220,
         20,
         60,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Hurricane Warning",
       "label": "Hurricane Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         0,
         255,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Hurricane Watch",
       "label": "Hurricane Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         0,
         255,
         127,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Hydrologic Advisory",
       "label": "Hydrologic Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         144,
         238,
         144,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Hydrologic Outlook",
       "label": "Hydrologic Outlook"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         139,
         0,
         139,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Ice Storm Warning",
       "label": "Ice Storm Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         72,
         209,
         204,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Lake Effect Snow Advisory",
       "label": "Lake Effect Snow Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         0,
         139,
         139,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Lake Effect Snow Warning",
       "label": "Lake Effect Snow Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         135,
         206,
         250,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Lake Effect Snow Watch",
       "label": "Lake Effect Snow Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         210,
         180,
         140,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Lake Wind Advisory",
       "label": "Lake Wind Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         124,
         252,
         0,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Lakeshore Flood Advisory",
       "label": "Lakeshore Flood Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         107,
         142,
         35,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Lakeshore Flood Statement",
       "label": "Lakeshore Flood Statement"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         34,
         139,
         34,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Lakeshore Flood Warning",
       "label": "Lakeshore Flood Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         102,
         205,
         170,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Lakeshore Flood Watch",
       "label": "Lakeshore Flood Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         192,
         192,
         192,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Law Enforcement Warning",
       "label": "Law Enforcement Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         192,
         192,
         192,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Local Area Emergency",
       "label": "Local Area Emergency"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         165,
         42,
         42,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Low Water Advisory",
       "label": "Low Water Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         239,
         213,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Marine Weather Statement",
       "label": "Marine Weather Statement"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         75,
         0,
         130,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Nuclear Power Plant Warning",
       "label": "Nuclear Power Plant Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         75,
         0,
         130,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Radiological Hazard Warning",
       "label": "Radiological Hazard Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         20,
         147,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Red Flag Warning",
       "label": "Red Flag Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         64,
         224,
         208,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Rip Current Statement",
       "label": "Rip Current Statement"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         165,
         0,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Severe Thunderstorm Warning",
       "label": "Severe Thunderstorm Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         219,
         112,
         147,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Severe Thunderstorm Watch",
       "label": "Severe Thunderstorm Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         0,
         255,
         255,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Severe Weather Statement",
       "label": "Severe Weather Statement"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         250,
         128,
         114,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Shelter In Place Warning",
       "label": "Shelter In Place Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         152,
         251,
         152,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Short Term Forecast",
       "label": "Short Term Forecast"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         216,
         191,
         216,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Small Craft Advisory",
       "label": "Small Craft Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         216,
         191,
         216,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Small Craft Advisory For Rough Bar",
       "label": "Small Craft Advisory For Rough Bar"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         216,
         191,
         216,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Small Craft Advisory for Hazardous Seas",
       "label": "Small Craft Advisory For Hazardous Seas"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         216,
         191,
         216,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Small Craft Advisory for Winds",
       "label": "Small Craft Advisory For Winds"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         199,
         21,
         133,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Snow Squall Warning",
       "label": "Snow Squall Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         165,
         0,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Special Marine Warning",
       "label": "Special Marine Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         228,
         181,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Special Weather Statement",
       "label": "Special Weather Statement"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         181,
         36,
         247,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Storm Surge Warning",
       "label": "Storm Surge Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         219,
         127,
         247,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Storm Surge Watch",
       "label": "Storm Surge Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         148,
         0,
         211,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Storm Warning",
       "label": "Storm Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         228,
         181,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Storm Watch",
       "label": "Storm Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         0,
         0,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Tornado Warning",
       "label": "Tornado Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         255,
         0,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Tornado Watch",
       "label": "Tornado Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         228,
         181,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Tropical Depression Local Statement",
       "label": "Tropical Depression Local Statement"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         228,
         181,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Tropical Storm Local Statement",
       "label": "Tropical Storm Local Statement"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         178,
         34,
         34,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Tropical Storm Warning",
       "label": "Tropical Storm Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         240,
         128,
         128,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Tropical Storm Watch",
       "label": "Tropical Storm Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         210,
         105,
         30,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Tsunami Advisory",
       "label": "Tsunami Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         253,
         99,
         71,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Tsunami Warning",
       "label": "Tsunami Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         0,
         255,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Tsunami Watch",
       "label": "Tsunami Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         228,
         181,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Typhoon Local Statement",
       "label": "Typhoon Local Statement"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         220,
         20,
         60,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Typhoon Warning",
       "label": "Typhoon Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         0,
         255,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Typhoon Watch",
       "label": "Typhoon Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         47,
         79,
         79,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Volcano Warning",
       "label": "Volcano Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         210,
         180,
         140,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Wind Advisory",
       "label": "Wind Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         175,
         238,
         238,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Wind Chill Advisory",
       "label": "Wind Chill Advisory"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         176,
         196,
         222,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Wind Chill Warning",
       "label": "Wind Chill Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         95,
         158,
         160,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Wind Chill Watch",
       "label": "Wind Chill Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         255,
         105,
         180,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Winter Storm Warning",
       "label": "Winter Storm Warning"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         70,
         130,
         180,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Winter Storm Watch",
       "label": "Winter Storm Watch"
      },
      {
       "symbol": {
        "type": "esriSFS",
        "style": "esriSFSSolid",
        "color": [
         123,
         104,
         238,
         255
        ],
        "outline": {
         "type": "esriSLS",
         "style": "esriSLSSolid",
         "color": [
          110,
          110,
          110,
          255
         ],
         "width": 0.4
        }
       },
       "value": "Winter Weather Advisory",
       "label": "Winter Weather Advisory"
      }
     ],
     "fieldDelimiter": ",",
     "authoringInfo": {
      "colorRamp": {
       "type": "multipart",
       "colorRamps": [
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          192,
          167,
          217,
          255
         ],
         "toColor": [
          192,
          167,
          217,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          209,
          181,
          169,
          255
         ],
         "toColor": [
          209,
          181,
          169,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          199,
          217,
          240,
          255
         ],
         "toColor": [
          199,
          217,
          240,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          214,
          154,
          176,
          255
         ],
         "toColor": [
          214,
          154,
          176,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          240,
          170,
          228,
          255
         ],
         "toColor": [
          240,
          170,
          228,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          232,
          197,
          222,
          255
         ],
         "toColor": [
          232,
          197,
          222,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          155,
          167,
          204,
          255
         ],
         "toColor": [
          155,
          167,
          204,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          227,
          164,
          163,
          255
         ],
         "toColor": [
          227,
          164,
          163,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          154,
          145,
          204,
          255
         ],
         "toColor": [
          154,
          145,
          204,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          229,
          192,
          240,
          255
         ],
         "toColor": [
          229,
          192,
          240,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          235,
          213,
          188,
          255
         ],
         "toColor": [
          235,
          213,
          188,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          224,
          171,
          208,
          255
         ],
         "toColor": [
          224,
          171,
          208,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          235,
          192,
          197,
          255
         ],
         "toColor": [
          235,
          192,
          197,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          210,
          197,
          237,
          255
         ],
         "toColor": [
          210,
          197,
          237,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          212,
          185,
          150,
          255
         ],
         "toColor": [
          212,
          185,
          150,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          165,
          198,
          232,
          255
         ],
         "toColor": [
          165,
          198,
          232,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          173,
          181,
          237,
          255
         ],
         "toColor": [
          173,
          181,
          237,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          207,
          169,
          187,
          255
         ],
         "toColor": [
          207,
          169,
          187,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          167,
          187,
          204,
          255
         ],
         "toColor": [
          167,
          187,
          204,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          186,
          174,
          207,
          255
         ],
         "toColor": [
          186,
          174,
          207,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          209,
          158,
          222,
          255
         ],
         "toColor": [
          209,
          158,
          222,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          240,
          197,
          180,
          255
         ],
         "toColor": [
          240,
          197,
          180,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          240,
          182,
          205,
          255
         ],
         "toColor": [
          240,
          182,
          205,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          204,
          149,
          155,
          255
         ],
         "toColor": [
          204,
          149,
          155,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          179,
          150,
          212,
          255
         ],
         "toColor": [
          179,
          150,
          212,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          222,
          173,
          179,
          255
         ],
         "toColor": [
          222,
          173,
          179,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          167,
          163,
          209,
          255
         ],
         "toColor": [
          167,
          163,
          209,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          186,
          190,
          227,
          255
         ],
         "toColor": [
          186,
          190,
          227,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          205,
          171,
          207,
          255
         ],
         "toColor": [
          205,
          171,
          207,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          209,
          175,
          237,
          255
         ],
         "toColor": [
          209,
          175,
          237,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          222,
          158,
          205,
          255
         ],
         "toColor": [
          222,
          158,
          205,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          219,
          176,
          158,
          255
         ],
         "toColor": [
          219,
          176,
          158,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          204,
          160,
          149,
          255
         ],
         "toColor": [
          204,
          160,
          149,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          171,
          178,
          204,
          255
         ],
         "toColor": [
          171,
          178,
          204,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          229,
          177,
          240,
          255
         ],
         "toColor": [
          229,
          177,
          240,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          205,
          182,
          224,
          255
         ],
         "toColor": [
          205,
          182,
          224,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          168,
          164,
          222,
          255
         ],
         "toColor": [
          168,
          164,
          222,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          230,
          163,
          176,
          255
         ],
         "toColor": [
          230,
          163,
          176,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          240,
          213,
          204,
          255
         ],
         "toColor": [
          240,
          213,
          204,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          232,
          198,
          167,
          255
         ],
         "toColor": [
          232,
          198,
          167,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          207,
          157,
          188,
          255
         ],
         "toColor": [
          207,
          157,
          188,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          237,
          187,
          180,
          255
         ],
         "toColor": [
          237,
          187,
          180,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          187,
          174,
          235,
          255
         ],
         "toColor": [
          187,
          174,
          235,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          204,
          173,
          175,
          255
         ],
         "toColor": [
          204,
          173,
          175,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          217,
          198,
          184,
          255
         ],
         "toColor": [
          217,
          198,
          184,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          170,
          189,
          240,
          255
         ],
         "toColor": [
          170,
          189,
          240,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          190,
          181,
          230,
          255
         ],
         "toColor": [
          190,
          181,
          230,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          237,
          192,
          224,
          255
         ],
         "toColor": [
          237,
          192,
          224,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          191,
          200,
          224,
          255
         ],
         "toColor": [
          191,
          200,
          224,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          222,
          189,
          201,
          255
         ],
         "toColor": [
          222,
          189,
          201,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          201,
          159,
          204,
          255
         ],
         "toColor": [
          201,
          159,
          204,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          227,
          172,
          223,
          255
         ],
         "toColor": [
          227,
          172,
          223,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          240,
          187,
          230,
          255
         ],
         "toColor": [
          240,
          187,
          230,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          240,
          221,
          204,
          255
         ],
         "toColor": [
          240,
          221,
          204,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          233,
          199,
          237,
          255
         ],
         "toColor": [
          233,
          199,
          237,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          151,
          162,
          207,
          255
         ],
         "toColor": [
          151,
          162,
          207,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          204,
          206,
          240,
          255
         ],
         "toColor": [
          204,
          206,
          240,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          175,
          192,
          222,
          255
         ],
         "toColor": [
          175,
          192,
          222,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          207,
          169,
          159,
          255
         ],
         "toColor": [
          207,
          169,
          159,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          166,
          184,
          224,
          255
         ],
         "toColor": [
          166,
          184,
          224,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          157,
          183,
          207,
          255
         ],
         "toColor": [
          157,
          183,
          207,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          175,
          201,
          222,
          255
         ],
         "toColor": [
          175,
          201,
          222,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          219,
          186,
          217,
          255
         ],
         "toColor": [
          219,
          186,
          217,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          175,
          158,
          222,
          255
         ],
         "toColor": [
          175,
          158,
          222,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          222,
          169,
          160,
          255
         ],
         "toColor": [
          222,
          169,
          160,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          230,
          163,
          192,
          255
         ],
         "toColor": [
          230,
          163,
          192,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          204,
          173,
          149,
          255
         ],
         "toColor": [
          204,
          173,
          149,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          240,
          205,
          204,
          255
         ],
         "toColor": [
          240,
          205,
          204,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          165,
          163,
          230,
          255
         ],
         "toColor": [
          165,
          163,
          230,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          219,
          178,
          204,
          255
         ],
         "toColor": [
          219,
          178,
          204,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          174,
          174,
          214,
          255
         ],
         "toColor": [
          174,
          174,
          214,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          240,
          194,
          216,
          255
         ],
         "toColor": [
          240,
          194,
          216,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          222,
          186,
          206,
          255
         ],
         "toColor": [
          222,
          186,
          206,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          196,
          170,
          240,
          255
         ],
         "toColor": [
          196,
          170,
          240,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          211,
          159,
          214,
          255
         ],
         "toColor": [
          211,
          159,
          214,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          218,
          181,
          232,
          255
         ],
         "toColor": [
          218,
          181,
          232,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          189,
          206,
          240,
          255
         ],
         "toColor": [
          189,
          206,
          240,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          220,
          192,
          237,
          255
         ],
         "toColor": [
          220,
          192,
          237,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          219,
          186,
          171,
          255
         ],
         "toColor": [
          219,
          186,
          171,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          207,
          147,
          201,
          255
         ],
         "toColor": [
          207,
          147,
          201,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          154,
          184,
          217,
          255
         ],
         "toColor": [
          154,
          184,
          217,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          182,
          165,
          204,
          255
         ],
         "toColor": [
          182,
          165,
          204,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          212,
          163,
          177,
          255
         ],
         "toColor": [
          212,
          163,
          177,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          235,
          202,
          183,
          255
         ],
         "toColor": [
          235,
          202,
          183,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          199,
          176,
          209,
          255
         ],
         "toColor": [
          199,
          176,
          209,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          217,
          182,
          224,
          255
         ],
         "toColor": [
          217,
          182,
          224,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          219,
          185,
          156,
          255
         ],
         "toColor": [
          219,
          185,
          156,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          153,
          155,
          204,
          255
         ],
         "toColor": [
          153,
          155,
          204,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          235,
          192,
          188,
          255
         ],
         "toColor": [
          235,
          192,
          188,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          203,
          161,
          214,
          255
         ],
         "toColor": [
          203,
          161,
          214,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          216,
          172,
          230,
          255
         ],
         "toColor": [
          216,
          172,
          230,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          178,
          166,
          222,
          255
         ],
         "toColor": [
          178,
          166,
          222,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          187,
          189,
          240,
          255
         ],
         "toColor": [
          187,
          189,
          240,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          240,
          180,
          221,
          255
         ],
         "toColor": [
          240,
          180,
          221,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          227,
          172,
          202,
          255
         ],
         "toColor": [
          227,
          172,
          202,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          212,
          150,
          183,
          255
         ],
         "toColor": [
          212,
          150,
          183,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          202,
          179,
          230,
          255
         ],
         "toColor": [
          202,
          179,
          230,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          232,
          188,
          231,
          255
         ],
         "toColor": [
          232,
          188,
          231,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          209,
          163,
          186,
          255
         ],
         "toColor": [
          209,
          163,
          186,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          182,
          174,
          214,
          255
         ],
         "toColor": [
          182,
          174,
          214,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          204,
          185,
          167,
          255
         ],
         "toColor": [
          204,
          185,
          167,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          177,
          151,
          204,
          255
         ],
         "toColor": [
          177,
          151,
          204,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          183,
          173,
          240,
          255
         ],
         "toColor": [
          183,
          173,
          240,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          230,
          163,
          201,
          255
         ],
         "toColor": [
          230,
          163,
          201,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          217,
          180,
          189,
          255
         ],
         "toColor": [
          217,
          180,
          189,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          219,
          195,
          232,
          255
         ],
         "toColor": [
          219,
          195,
          232,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          232,
          175,
          174,
          255
         ],
         "toColor": [
          232,
          175,
          174,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          207,
          149,
          147,
          255
         ],
         "toColor": [
          207,
          149,
          147,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          168,
          153,
          209,
          255
         ],
         "toColor": [
          168,
          153,
          209,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          217,
          180,
          178,
          255
         ],
         "toColor": [
          217,
          180,
          178,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          237,
          180,
          188,
          255
         ],
         "toColor": [
          237,
          180,
          188,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          212,
          165,
          165,
          255
         ],
         "toColor": [
          212,
          165,
          165,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          224,
          177,
          195,
          255
         ],
         "toColor": [
          224,
          177,
          195,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          235,
          167,
          213,
          255
         ],
         "toColor": [
          235,
          167,
          213,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          193,
          151,
          207,
          255
         ],
         "toColor": [
          193,
          151,
          207,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          209,
          157,
          200,
          255
         ],
         "toColor": [
          209,
          157,
          200,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          230,
          172,
          186,
          255
         ],
         "toColor": [
          230,
          172,
          186,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          239,
          189,
          240,
          255
         ],
         "toColor": [
          239,
          189,
          240,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          178,
          192,
          235,
          255
         ],
         "toColor": [
          178,
          192,
          235,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          204,
          147,
          162,
          255
         ],
         "toColor": [
          204,
          147,
          162,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          207,
          165,
          232,
          255
         ],
         "toColor": [
          207,
          165,
          232,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          217,
          197,
          171,
          255
         ],
         "toColor": [
          217,
          197,
          171,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          240,
          204,
          215,
          255
         ],
         "toColor": [
          240,
          204,
          215,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          209,
          151,
          190,
          255
         ],
         "toColor": [
          209,
          151,
          190,
          255
         ]
        },
        {
         "type": "algorithmic",
         "algorithm": "esriCIELabAlgorithm",
         "fromColor": [
          232,
          171,
          237,
          255
         ],
         "toColor": [
          232,
          171,
          237,
          255
         ]
        }
       ]
      }
     }
    },
    "scaleSymbols": true,
    "transparency": 0,
    "labelingInfo": null
   },
   "defaultVisibility": true,
   "extent": {
    "xmin": -1.84796620668E7,
    "ymin": 2144793.039500002,
    "xmax": -7675556.680299999,
    "ymax": 1.1183506100100003E7,
    "spatialReference": {
     "wkid": 102100,
     "latestWkid": 3857,
     "xyTolerance": 0.001,
     "zTolerance": 0.001,
     "mTolerance": 0.001,
     "falseX": -2.2041257773920003E7,
     "falseY": -30241100,
     "xyUnits": 1.441480358985159E8,
     "falseZ": -100000,
     "zUnits": 10000,
     "falseM": -100000,
     "mUnits": 10000
    }
   },
   "hasAttachments": false,
   "htmlPopupType": "esriServerHTMLPopupTypeAsHTMLText",
   "displayField": "cap_id",
   "typeIdField": null,
   "subtypeFieldName": null,
   "subtypeField": null,
   "defaultSubtypeCode": null,
   "fields": [
    {
     "name": "objectid",
     "type": "esriFieldTypeOID",
     "alias": "objectid",
     "domain": null
    },
    {
     "name": "prod_type",
     "type": "esriFieldTypeString",
     "alias": "Hazard Type",
     "length": 40,
     "domain": null
    },
    {
     "name": "msg_type",
     "type": "esriFieldTypeString",
     "alias": "Message Type",
     "length": 3,
     "domain": null
    },
    {
     "name": "phenom",
     "type": "esriFieldTypeString",
     "alias": "Hazard Abbreviation",
     "length": 2,
     "domain": null
    },
    {
     "name": "url",
     "type": "esriFieldTypeString",
     "alias": "URL",
     "length": 254,
     "domain": null
    },
    {
     "name": "expiration",
     "type": "esriFieldTypeString",
     "alias": "Expiration",
     "length": 25,
     "domain": null
    },
    {
     "name": "onset",
     "type": "esriFieldTypeString",
     "alias": "Onset",
     "length": 25,
     "domain": null
    },
    {
     "name": "ends",
     "type": "esriFieldTypeString",
     "alias": "Ends",
     "length": 25,
     "domain": null
    },
    {
     "name": "issuance",
     "type": "esriFieldTypeString",
     "alias": "Issuance",
     "length": 25,
     "domain": null
    },
    {
     "name": "event",
     "type": "esriFieldTypeString",
     "alias": "Event",
     "length": 4,
     "domain": null
    },
    {
     "name": "sig",
     "type": "esriFieldTypeString",
     "alias": "Alert Type",
     "length": 1,
     "domain": null
    },
    {
     "name": "wfo",
     "type": "esriFieldTypeString",
     "alias": "Forecast Office",
     "length": 4,
     "domain": null
    },
    {
     "name": "idp_filedate",
     "type": "esriFieldTypeDate",
     "alias": "GIS File Date",
     "length": 8,
     "domain": null
    },
    {
     "name": "idp_ingestdate",
     "type": "esriFieldTypeDate",
     "alias": "GIS Ingest Date",
     "length": 8,
     "domain": null
    },
    {
     "name": "cap_id",
     "type": "esriFieldTypeString",
     "alias": "Message ID",
     "length": 254,
     "domain": null
    },
    {
     "name": "shape",
     "type": "esriFieldTypeGeometry",
     "alias": "shape",
     "domain": null
    }
   ],
   "geometryField": {
    "name": "shape",
    "type": "esriFieldTypeGeometry",
    "alias": "shape"
   },
   "indexes": null,
   "subtypes": [],
   "relationships": [],
   "canModifyLayer": true,
   "canScaleSymbols": false,
   "hasLabels": false,
   "capabilities": "Map,Query,Data",
   "maxRecordCount": 2000,
   "supportsStatistics": true,
   "supportsExceedsLimitStatistics": true,
   "supportsAdvancedQueries": true,
   "supportedQueryFormats": "JSON, geoJSON, PBF",
   "isDataVersioned": false,
   "ownershipBasedAccessControlForFeatures": {"allowOthersToQuery": true},
   "useStandardizedQueries": true,
   "supportedSpatialRelationships": [
    "esriSpatialRelIntersects",
    "esriSpatialRelContains",
    "esriSpatialRelCrosses",
    "esriSpatialRelEnvelopeIntersects",
    "esriSpatialRelIndexIntersects",
    "esriSpatialRelOverlaps",
    "esriSpatialRelTouches",
    "esriSpatialRelWithin",
    "esriSpatialRelRelation"
   ],
   "advancedQueryCapabilities": {
    "useStandardizedQueries": true,
    "supportsStatistics": true,
    "supportsPercentileStatistics": true,
    "supportsHavingClause": true,
    "supportsOrderBy": true,
    "supportsDistinct": true,
    "supportsCountDistinct": true,
    "supportsPagination": true,
    "supportsLod": false,
    "supportsQueryWithLodSR": false,
    "supportsTrueCurve": true,
    "supportsQueryWithDatumTransformation": true,
    "supportsReturningQueryExtent": true,
    "supportsQueryWithDistance": true,
    "supportsSqlExpression": true,
    "supportsTimeRelation": true,
    "supportsSqlFormat": false,
    "supportsQueryAnalytic": true
   },
   "supportsDatumTransformation": true,
   "advancedQueryAnalyticCapabilities": {
    "supportsLinearRegression": true,
    "supportsAsync": false,
    "supportsPercentileAnalytic": false
   },
   "dateFieldsTimeReference": null,
   "preferredTimeReference": null,
   "datesInUnknownTimezone": false,
   "hasGeometryProperties": true,
   "geometryProperties": {
    "shapeAreaFieldName": "st_area(shape)",
    "shapeLengthFieldName": "st_perimeter(shape)",
    "units": "esriMeters"
   },
   "hasMetadata": true,
   "isDataArchived": false,
   "archivingInfo": {
    "supportsQueryWithHistoricMoment": false,
    "startArchivingMoment": -1
   },
   "supportsCoordinatesQuantization": true,
   "supportsDynamicLegends": true
  }
 ],
 "tables": []
};
