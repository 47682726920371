import { useEffect, useState } from 'react';
import { Table, OverlayTrigger, Popover } from 'react-bootstrap';
import { useTable, useSortBy, useGroupBy, useExpanded } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';

const ReportTableExpandedPopup = ({
  data,
  columns,
  clickRowHandler = () => {},
  hoverRowHandler = () => {},
  popoverRowHandler = () => {},
  clickLoadStop,
}: {
  data: {}[];
  columns: any[];
  clickRowHandler?: (rowValue: any, event: any) => void;
  hoverRowHandler?: (rowValue: any, event: any) => void;
  popoverRowHandler?: (rowValue: any, event: any) => any;
  clickLoadStop?: boolean;
}) => {
  const [isFirstDataLoad, setIsFirstDataLoad] = useState(true);

  const popoverLink = (event:any) => {   
      if(event && event.timestamp){
        return popoverRowHandler(event.timestamp, event.timestamp);
      }
  };

  useEffect(() => {
    if (data.length > 0) {
      if (isFirstDataLoad) {
        const tableRowsHTMLElements =
          document.querySelectorAll('table > tbody > tr');
        if (tableRowsHTMLElements.length > 0) {
          const firstRowHtmlElement = tableRowsHTMLElements[0] as HTMLElement;
          if(!clickLoadStop){
            firstRowHtmlElement.click();
          }
        }
        setIsFirstDataLoad(false);
      }
    }
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: data }, useSortBy, 
        useExpanded);
  return (
    <>
      <div className='table-container table-container--scrollable'>
        <Table
          className='reportTable'
          {...getTableProps()}
          style={{ maxHeight: '30rem', overflow: 'auto' }}
        >
          <thead className='report-table__header'>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}  
                        style={{ position: 'sticky', insetInlineStart: 0 }}>
                    {column.render('Header')}
                    <span>
                    {column.isSorted &&
                        (column.isSortedDesc ? <FontAwesomeIcon icon={faSortDown} size='xs' style={{ marginLeft:4 }}/> : <FontAwesomeIcon icon={faSortUp} size='xs' style={{ marginLeft:4 }}/> )}
                    </span>
                </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
             
              return (
                <OverlayTrigger key={'dashboard-table-pop_'+row.index}               
                    trigger={['hover', 'focus']} placement="auto" overlay={popoverLink(row.original)}>
                <tr
                  {...row.getRowProps()}
                  onClick={(event) => clickRowHandler(row.original, event)}
                  onMouseOver={(event) => hoverRowHandler(row.original, event)}
                >
                  {row.cells.map((cell) => {
                     
                    let primary = false;
          
                    //if(cell.column.Header === 'Site Flow Name'){
                        if((cell.row.isExpanded)&&(cell.row.index === 0)){
                            primary = true;
                        }
                    //}
                    //console.log(cell)
                    return (
                      <td style={(primary) ? {color: '#4fc2d9'} : undefined} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
                </OverlayTrigger>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ReportTableExpandedPopup;
