import { useMemo, useState } from 'react';
import LoadingDataAnimation from '../../../components/LoadingDataAnimation';
import Map, {
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  Layer,
  Source,
} from 'react-map-gl';
import settings from './../../../settings.json';
import { MarkerConfig } from '../../../types/MarkerConfig';
import MapDialsBuilder from '../../../components/MapDialsBuilder';
import { MapSource } from '../../../types/MapSource';
import DrawControl from '../../../types/DrawControl';
import { v4 as uuidv4 } from 'uuid';
import ReportTable from '../shared/ReportTable';
import { Row, Col, Button } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Render from '../../../components/render';
import DonutChart from '../shared/DonutChart';
import ReportMap from '../shared/ReportMap';

const SimpleMapAndTableReport = ({
  title,
  source,
  markersConfigs,
  columns,
  reportData,
  mapRef,
  global,
}: {
  title: string;
  source: MapSource;
  markersConfigs: MarkerConfig[];
  columns: any[];
  reportData: {}[];
  mapRef?: any;
  global?: any;
}) => {
  const [isDataReportLoaded, setIsDataReportLoaded] = useState(true);
  console.log('Source', source);
  // const drillDownMapSources = (mapSource: MapSource, innerSources: any[]) => {
  //   let filter = mapSource.source?.layerFilter;

  //   if (mapSource.source) {
  //     const sourceElement = (
  //       <Source
  //         key={uuidv4()}
  //         id={mapSource.source.id}
  //         type={mapSource.source.type as 'vector'}
  //         url={mapSource.source.tilesetURLs}
  //       >
  //         <Layer {...mapSource.source.layer} {...{ filter }} />
  //       </Source>
  //     );
  //     innerSources.push(sourceElement);
  //     drillDownMapSources(mapSource.source, innerSources);
  //   } else {
  //     return null;
  //   }
  //   return innerSources;
  // };
  // const mapElementWithFilter = (
  //   <Map
  //     reuseMaps
  //     mapboxAccessToken={settings.maboxKey}
  //     initialViewState={{
  //       longitude: settings.defaultLocationMap.lng,
  //       latitude: settings.defaultLocationMap.lat,
  //       zoom: 3,
  //     }}
  //    mapStyle={global.mapStyle}
  //   >
  //     <Source
  //       key={uuidv4()}
  //       id={source.id}
  //       type={source.type as 'vector'}
  //       url={source.tilesetURLs}
  //     >
  //       <Layer {...source.layer} filter={source.layerFilter} />

  //       {drillDownMapSources(source, [])?.map((x) => x)}
  //     </Source>
  //     <MapDialsBuilder markersLocationConfigArray={markersConfigs} />
  //     <FullscreenControl />
  //     <NavigationControl />
  //     <GeolocateControl />
  //     <DrawControl
  //       position='top-left'
  //       displayControlsDefault={false}
  //       controls={{
  //         polygon: true,
  //         trash: true,
  //       }}
  //     />
  //   </Map>
  // );
  // const mapElementNoFilter = (
  //   <Map
  //     reuseMaps
  //     mapboxAccessToken={settings.maboxKey}
  //     initialViewState={{
  //       longitude: settings.defaultLocationMap.lng,
  //       latitude: settings.defaultLocationMap.lat,
  //       zoom: 3,
  //     }}
  //    mapStyle={global.mapStyle}
  //   >
  //     <Source
  //       id={source.id}
  //       type={source.type as 'vector'}
  //       url={source.tilesetURLs}
  //     >
  //       <Layer {...source.layer} />

  //       {drillDownMapSources(source, [])?.map((x) => x)}
  //     </Source>
  //     <MapDialsBuilder markersLocationConfigArray={markersConfigs ?? []} />
  //     <FullscreenControl />
  //     <NavigationControl />
  //     <GeolocateControl />
  //     <DrawControl
  //       position='top-left'
  //       displayControlsDefault={false}
  //       controls={{
  //         polygon: true,
  //         trash: true,
  //       }}
  //     />
  //   </Map>
  // );

  return (
    <>
      <div className='water-statistics-measures'>
        <h2>{title}</h2>
        <div className='container'>
          <div className='map-container'>
            {/* <LoadingDataAnimation dataLoading={!isDataReportLoaded} /> */}

            {/* {source.layerFilter !== undefined
            ? mapElementWithFilter
            : mapElementNoFilter} */}
            <Map
              reuseMaps
              mapboxAccessToken={settings.maboxKey}
              initialViewState={{
                longitude: settings.defaultLocationMap.lng,
                latitude: settings.defaultLocationMap.lat,
                zoom: 3,
              }}
             mapStyle={global.mapStyle}
            >
              <Source
                key={uuidv4()}
                id={source.id}
                type={source.type as 'vector'}
                url={source.tilesetURLs}
              >
                <Layer {...source.layer} filter={source.layerFilter} />
              </Source>
            </Map>

            {/* <ReportTable data={reportData} columns={columns} /> */}
          </div>
        </div>
      </div>

      <div className='water-statistics-measures'>
        <h2>What's in my Watershed?</h2>
        {/* <Breadcrumb  /> */}
        <div className='container'>
          <Row>
            <Col>
              <div className='map-container'>
                <LoadingSpinner
                  active={false}
                  style={{ marginLeft: '-12rem' }}
                />

                <ReportMap
                  source={source}
                  markersConfigs={markersConfigs}
                  refToMap={mapRef}
                  hasPins={true}
                  // onPinMouseEnterHandler={onPinHoveredHandler}
                  // onPinMouseExitHandler={onPinMouseExitHandler}
                  {...{ global }}
                  hasDrawControl={true}
                  hasNavigationControl={true}
                  hasGeolocateControl={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col className='source-footer-label'>
              Source: USGS, NOAA, Water Watch, DB Hydro, and Open Weather.
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default SimpleMapAndTableReport;
