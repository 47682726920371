import React from 'react'

const Help = () => {
    return (
        <>
            <div id={'help-container'} style={{ padding: "0px 0px 0px 15px" }}>
                <h3>Support Materials:</h3>
                <br />
                <div className="wm-indent">
{/*                    <b>Administration and User's Guide:</b>
                    <div className="wm-indent">
                        <div className="wm-smallnote">Document describes how to use Water Monitoring.</div>
                        <a id="ctl00_MainContent_hlUsersGuide" href="https://dev.openwaters.trueelements.com/App_Docs/UsersGuide.docx" target="_blank">Link</a>
                    </div>
                    <br /><br />*/}

                    <b>Monitoring Location Import Template (Excel):</b>
                    <div className="wm-indent">
                        <div className="wm-smallnote">This template is used to bulk import a list of monitoring locations.</div>
                        <a id="ctl00_MainContent_hlMonLoc" href="https://dev.openwaters.trueelements.com/App_Docs/MonLoc_ImportTemplate.xlsx" target="_blank">Link</a>
                    </div>
                    <br /><br />

                    <b>Sample Import Template (Excel):</b>
                    <div className="wm-indent">
                        <div className="wm-smallnote">This template is used when your characteristics are arranged in rows. This master template can be used for standard field and laboratory sampling, habitat
                            assessments, and biological monitoring.
                        </div>
                        <a id="ctl00_MainContent_hlSamp" href="https://dev.openwaters.trueelements.com/App_Docs/Samp_ImportTemplate.xlsx" target="_blank">Link</a>
                    </div>

                    <br /><br /><b>Sample Crosstab Template (Excel):</b>
                    <div className="wm-indent">
                        <div className="wm-smallnote">This template is used when your characteristics are arranged in columns. This is typical of csv files that export directly off of multiprobes (YSI, Manta, etc).</div>
                        <a id="ctl00_MainContent_hlSampCT" href="https://dev.openwaters.trueelements.com/App_Docs/SampCT_ImportTemplate.xlsx" target="_blank">Link</a>
                    </div>

                    <br /><br />
                </div>

                <h3>Email Support:</h3>
                <div className="wm-indent">
                    <div className="wm-smallnote">Please contact your Customer Relationship Manager.</div>
          {/*          <a id="ctl00_MainContent_hlEmail" href="mailto:te@tetest.org">True Elements</a>*/}
                </div>
            </div>
        </>
    )
}

export default Help