import MCGlobe from  './MCGlobe';
import MCClearLayers from  './MCClearLayers';
import MCOpacity from  './MCOpacity';
import MCCenter from  './MCCenter';
import MCSettings from  './MCSettings';
import MCPoint from  './MCPoint';
import MCFavorites from  './MCFavorites';
import MCLayerItem from  './MCLayerItem';
import LayerConfig from '../../../LayerConfig';
import './MCToolset.scss';

function MCToolset({
    global,
    mapRef,
    position,
    initialLayers
}: any) {

    let top = 58;
    let spaceBetween = 38;
    if(position === 'low'){
        top = 186;
        spaceBetween = 39;
    } else if (position === 'low-adjusted') {
        top = 194;
        spaceBetween = 39;
    }

    let layersAvail = [] as any;
    if(initialLayers && initialLayers.length>0){
      layersAvail = LayerConfig.filter((item) => initialLayers.indexOf(item.id) > -1);
    }

    return (
          <div>
              <MCGlobe global={global} mapRef={mapRef} top={top} />
              <MCOpacity global={global} mapRef={mapRef} top={top + spaceBetween} />
              <MCCenter global={global} mapRef={mapRef} top={top + (spaceBetween*2)} />

              <MCSettings global={global} mapRef={mapRef} top={(position === 'low-adjusted') ? 90 : 90} />
              <MCFavorites global={global} mapRef={mapRef} top={(position === 'low-adjusted') ? 130 : 130} />
              <MCPoint global={global} mapRef={mapRef} top={(position === 'low-adjusted') ? 170 : 170} />              
              <MCClearLayers global={global} mapRef={mapRef} top={(position === 'low-adjusted') ? 280 : 280} />
              
              {layersAvail && layersAvail.map((layer:any, index:any) => (<MCLayerItem global={global} mapRef={mapRef} top={top + (spaceBetween*(3 + index))} layer={layer} />))}
          </div>
    );
}

export default MCToolset