import { FillLayer } from 'react-map-gl';
import { v4 as uuidv4 } from 'uuid';

export class MapSource {
  public id: string;
  public type: string;
  public tilesetURLs: string;
  public layer: FillLayer;
  public source?: MapSource;
  public layerFilter?: string[];

  constructor(
    type: string,
    tilesetURLs: string,
    layer: FillLayer,
    layerFilter?: string[],
    source?: MapSource
  ) {
    this.id = uuidv4();
    this.type = type;
    this.tilesetURLs = tilesetURLs;
    layer.source = this.id;
    this.layer = layer;
    this.source = source ? source : undefined;
    this.layerFilter = layerFilter ? layerFilter : undefined;
  }
}

export default MapSource;
