// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-spinner {
  justify-content: center;
  position: absolute;
  z-index: 200;
  height: 100vh !important;
  width: 100vw !important;
  align-items: center;
  background-color: #00000040;
  top: 0; }
`, "",{"version":3,"sources":["webpack://./src/components/LoadingSpinner.scss"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;EACZ,wBAAwB;EACxB,uBAAuB;EACvB,mBAAmB;EACnB,2BAA2B;EAC3B,MAAM,EAAA","sourcesContent":[".loading-spinner {\n  justify-content: center;\n  position: absolute;\n  z-index: 200;\n  height: 100vh !important;;\n  width: 100vw !important;\n  align-items: center;\n  background-color: #00000040;\n  top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
