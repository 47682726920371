import { useState } from 'react';
import { Form } from 'react-bootstrap';

export const RenderCheck = (props:any) => {

    const {
        label,
        alterList
    } = props

    const [checked, setChecked] = useState(true)

    const handleChange = () => {
        setChecked(!checked)
        alterList(label, checked)
    }

  return (
    <Form.Check
        label={label}
        checked={checked}
        onChange={handleChange}
    />
  )
}
