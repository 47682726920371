import { useState, useEffect } from 'react'
import {
    Col,
    Row,
    Table,
    Button,
    Accordion
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faMinusCircle,
    fa1,
    fa2,
    fa3,
    fa4,
    fa5,
    fa6
} from '@fortawesome/pro-solid-svg-icons';
import {
  QueryParamProvider,
  useQueryParam,
  BooleanParam,
  StringParam
} from 'use-query-params';
import ReportTable from '../../reports/shared/ReportTable';
import './water-monitoring.scss'

const Home = (props: any) => {

    const {
        setHome,
        setWaterQuality,
        setReferenceData,
        setAdministration,
        setHelp,
        setAction,
        home,
        setOrgData,
        setMonitoringLocs,
        setImportAction,
        setProjects,
        setUserManagement,
        setOrganizations,
        setAddActivities,
        setActivities,
        setWQXHistory,
        setImportDetails,
        setImportActionII,
        setEditRoll,
        setAppSettings,
        setTaskManagement,
        setEditMonitoringLocation,
        setModalOpen,
        activitiesData,
        mLocsData,
        projectsData,
        orgData, 
        resultsData,
        tasksData,
        setTasksData
    } = props

    const [qwm, setqwm] = useQueryParam('wm', StringParam);

    const [orgName, setOrgName] = useState('');
    const [orgCount, setOrgCount] = useState('');
    const [resultsCount, setResultsCount] = useState(resultsData.length);
    const [samplesCount, setSamplesCount] = useState('0');
    const [projectCount, setProjectCount] = useState(projectsData.length);
    const [samplesPendingCount, setSamplesPendingCount] = useState('0');

    const [tableColumns, setTableColumns] = useState < any[] > ([]);
    const [tableData, setTableData] = useState < any[] > ([]);

    useEffect(() => {
        if (orgData && orgData.length>0 && orgData[0].name){
            setOrgName(orgData[0].name);
            setOrgCount(orgData.length);
        }
    }, [orgData])

    useEffect(() => {
        setProjectCount(projectsData.length);
        setResultsCount(resultsData.length)
        //setSamplesCount
    }, [resultsData])

    const handleTaskAction = (taskid: any, action:any) => { 
        const unfilteredData = [...tasksData];
        let filterData = unfilteredData.filter((o: any) => o.taskid === taskid);
        if(filterData && filterData.length>0){
            filterData[0].status = action;
            setTasksData(unfilteredData);
            let filterTableData = unfilteredData.filter((o: any) => o.status === null);
            setTableData(filterTableData)            
        }
    }

    useEffect(() => {
        if(tasksData){
            const unfilteredData = [...tasksData];
            let filterData = unfilteredData.filter((o: any) => o.status === null);
            setTableData(filterData)
        }      
    }, [tasksData])
    

    useEffect(() => {
        let tableCol = [] as any;
        tableCol.push(
                 {
                     Header: 'User',
                     accessor: 'username',
                 },
                 {
                     Header: 'Organization',
                     accessor: 'orgname',
                 }, {
                Header: 'Actions',
                accessor: 'taskid',
                //@ts-ignore
                Cell: (props: any) => {
                    return (
                        <div className="action-box" style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                            <div
                                onClick={() => {
                                        handleTaskAction(props.value, 'accept')
                                }}
                                className='action-cell'
                                style={{cursor: 'pointer' }}
                            >
                                <FontAwesomeIcon  style={{ color: '#18A0FB', cursor: 'pointer', marginRight:3 }} icon={faCheckCircle} />
                                Accept
                            </div>
                            <div className="vertical-seperator"></div>
                            <div
                                onClick={() => {
                                    handleTaskAction(props.value, 'reject')           
                                }}
                                className='action-cell'
                                style={{cursor: 'pointer' }}
                            >
                                <FontAwesomeIcon style={{ color: '#FF6666', cursor: 'pointer', marginRight:3 }} icon={faMinusCircle} />
                                Reject
                            </div>
                        </div>
                    )
                }
            })

            setTableColumns(tableCol)

    }, [])

    return (
        <div id={home && "organization-dashboard-container"}>
            <h2>Organization Dashboard</h2>
            <div className="organization-dashboard-row" style={{display: 'flex', flexWrap: 'wrap' }}>
                <Col className="metrics-container metrics-container-parent">
                    <Row className="metrics-container-top">
                        <Col className="metric-headings">
                            <h5>Data Collection Metrics</h5>
                            <p>{orgName} Data Counts</p>
                        </Col>
                        <Col className="organization-totals">
                            <h5>Organizations</h5>
                            <div className="number-of-organizations">
                                <h2>{orgCount}</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="metrics-container-bottom">
                        <Col className='metric-container'>
                            <h2 className="metric">{projectCount}</h2>
                            <p className="metric-title">Number of Projects</p>
                        </Col>
                        <Col className='metric-container'>
                            <h2 className="metric">{resultsCount}</h2>
                            <p className="metric-title">Number of Results</p>
                        </Col>
                        <Col className='metric-container'>
                            <h2 className="metric">{samplesCount}</h2>
                            <p className="metric-title">Number of samples</p>
                        </Col>
                        <Col className='metric-container'>
                            <h2 className="metric">{samplesPendingCount}</h2>
                            <p className="metric-title">Samples pending transfer to EPA</p>
                        </Col>
                    </Row>
                </Col>
                <Col className="col admin-task-container">
                    <Row className="admin-task-top">
                        <h5>Admin Tasks</h5>
                        <p>Pending Users Requiring your approval</p>
                    </Row>
                    <Row className="admin-task-bottom">
                        <ReportTable columns={tableColumns} data={tableData} />
                        {/*<Table className="admin-task-table">
                            <thead className="admin-task-table-head">
                                <tr className="table-header-row">
                                    <th className="admin-task-header">User ID</th>
                                    <th className="admin-task-header">Organization</th>
                                    <th className="admin-task-header">Action</th>
                                </tr>
                            </thead>
                            <tbody className="admin-task-table-body">
                                <tr className="table-body-row">
                                    <td className="table-data">mcs12</td>
                                    <td className="table-data">TRUEELEMENTS_QI_WQX</td>
                                    <td className="table-data action">
                                        <div
                                            style={{ color: '#18A0FB' }}
                                            className="action-container"
                                        >
                                            <FontAwesomeIcon
                                                style={{
                                                    color: '#18A0FB',
                                                    marginRight: '2px',
                                                    cursor: 'pointer'

                                                }}
                                                icon={faCheckCircle}
                                            />
                                            Accept
                                        </div>
                                        <div className="vertical-seperator"></div>
                                        <div
                                            style={{ color: '#FF6666' }}
                                            className="action-container"
                                        >
                                            <FontAwesomeIcon
                                                style={{
                                                    color: '#FF6666',
                                                    marginRight: '2px',
                                                    cursor: 'pointer'

                                                }}
                                                icon={faMinusCircle}
                                            />
                                            Reject
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>*/}
                    </Row>
                </Col>
            </div>
            <Row>
                <Col id="organization-dashboard-container-bottom" className="getting-started-container col-11">
                    <h5>Getting Started Guide</h5>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <FontAwesomeIcon size="xs" className="list-icon" style={{
                                    color: 'white',
                                    border: '2px solid white',
                                    borderRadius: '50%',
                                    width: '10px',
                                    height: '10px',
                                    padding: '3px',
                                    marginRight: '8px'
                                }} icon={fa1} />
                                Create or Join an organization
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    To use True Elements, you must first be linked with an Organization. This is the water monitoring agency you represent. If you intend to submit your data to EPA, this organization must first be created by EPA in their WQX system. Otherwise, if you never intend to submit your data to EPA, you can create any Organization ID you wish.
                                </Row>
                                <Row>
                                    <Col className="col-3">
                                        <Button onClick={() => {
                                            setqwm('tq');

 /*                                           setWaterQuality(true)
                                            setAdministration(false)
                                            setHelp(false);
                                            setHome(false);
                                            setReferenceData(false)
                                            setAdministration(false)
                                            setHelp(false);
                                            setUserManagement(false)
                                            setOrganizations(false)
                                            setAddActivities(false)
                                            setActivities(false)
                                            setWQXHistory(false)
                                            setImportDetails(false)
                                            setImportActionII(false)
                                            setEditRoll(false)
                                            setAppSettings(false)
                                            setTaskManagement(false)
                                            setEditMonitoringLocation(false)
                                            setModalOpen(false)*/
                                        }} className="p-3 btn btn-primary mt-2">
                                            Get Started
                                        </Button>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <FontAwesomeIcon size="xs" className="list-icon" style={{
                                    color: 'white',
                                    border: '2px solid white',
                                    borderRadius: '50%',
                                    width: '10px',
                                    height: '10px',
                                    padding: '3px',
                                    marginRight: '8px'
                                }} icon={fa2} />
                                Authorize True Elements to submit data
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    In order to submit data to EPA using True Elements, you must contact EPA and request that they authorize True Elements to submit data.
                                </Row>
                                <Row>
                                    <Col className="col-3">
                                        <Button onClick={() => {
                                            setqwm('tq')
                                            setAction('edit')


/*                                          setHome(false);
                                            setWaterQuality(false)
                                            setReferenceData(false)
                                            setAdministration(false)
                                            setHelp(false);
                                            setWaterQuality(true)

                                            setUserManagement(false)
                                            setOrganizations(false)
                                            setAddActivities(false)
                                            setActivities(false)
                                            setWQXHistory(false)
                                            setImportDetails(false)
                                            setImportActionII(false)
                                            setEditRoll(false)
                                            setAppSettings(false)
                                            setTaskManagement(false)
                                            setEditMonitoringLocation(false)
                                            setModalOpen(false)*/
                                            setOrgData([{
                                                addressType: "",
                                                description: "",
                                                electronicAddress: "",
                                                ext: "",
                                                id: "",
                                                name: "",
                                                phone: "",
                                                phoneType: "",
                                                reportId: "",
                                                tribalCode: ""
                                            }])
                                        }} className="p-3 btn btn-primary mt-2">
                                            Get Started
                                        </Button>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                <FontAwesomeIcon size="xs" className="list-icon" style={{
                                    color: 'white',
                                    border: '2px solid white',
                                    borderRadius: '50%',
                                    width: '10px',
                                    height: '10px',
                                    padding: '3px',
                                    marginRight: '8px'
                                }} icon={fa3} />
                                Enter 1 or more monitoring locations
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    Click to enter a monitoring location record.
                                </Row>
                                <Row>
                                    <div className="double-btn-box">
                                        <Button onClick={() => {
                                            setqwm('tq');
/*                                            setHome(false);
                                            setWaterQuality(false)
                                            setReferenceData(false)
                                            setAdministration(false)
                                            setHelp(false);
                                            setWaterQuality(false)
                                            setImportAction(false)
                                            setUserManagement(false)
                                            setOrganizations(false)
                                            setAddActivities(false)
                                            setActivities(false)
                                            setWQXHistory(false)
                                            setImportDetails(false)
                                            setImportActionII(false)
                                            setEditRoll(false)
                                            setAppSettings(false)
                                            setTaskManagement(false)*/
                                            setEditMonitoringLocation(false)
                                            setModalOpen(false)
                                            setMonitoringLocs(true)
                                        }} className="p-3 btn btn-primary">
                                            Enter
                                        </Button>
                                        <Button onClick={() => {
                                            setHome(false);
                                            setWaterQuality(false)
                                            setReferenceData(false)
                                            setAdministration(false)
                                            setHelp(false);
                                            setWaterQuality(false)
                                            setImportAction(true)
                                            setMonitoringLocs(false)
                                            setUserManagement(false)
                                            setOrganizations(false)
                                            setAddActivities(false)
                                            setActivities(false)
                                            setWQXHistory(false)
                                            setImportDetails(false)
                                            setImportActionII(false)
                                            setEditRoll(false)
                                            setAppSettings(false)
                                            setTaskManagement(false)
                                            setEditMonitoringLocation(false)
                                            setModalOpen(false)
                                        }} className="p-3 btn btn-primary">
                                            Import
                                        </Button>
                                    </div>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>
                                <FontAwesomeIcon size="xs" className="list-icon" style={{
                                    color: 'white',
                                    border: '2px solid white',
                                    borderRadius: '50%',
                                    width: '10px',
                                    height: '10px',
                                    padding: '3px',
                                    marginRight: '8px'
                                }} icon={fa4} />
                                Enter 1 or more projects
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    One or more projects have been created. Click to view.
                                </Row>
                                <Row>
                                    <div className="double-btn-box">
                                        <Button onClick={() => {
                                            setqwm('tq');
                                            setHome(false);
                                            setWaterQuality(false)
                                            setReferenceData(false)
                                            setAdministration(false)
                                            setHelp(false);
                                            setWaterQuality(false)
                                            setImportAction(false)
                                            setMonitoringLocs(false)
                                            setProjects(true)
                                            setUserManagement(false)
                                            setOrganizations(false)
                                            setAddActivities(false)
                                            setActivities(false)
                                            setWQXHistory(false)
                                            setImportDetails(false)
                                            setImportActionII(false)
                                            setEditRoll(false)
                                            setAppSettings(false)
                                            setTaskManagement(false)
                                            setEditMonitoringLocation(false)
                                            setModalOpen(false)
                                        }} className="p-3 btn btn-primary">
                                            View
                                        </Button>
                                        <Button onClick={() => {
                                            setHome(false);
                                            setWaterQuality(false)
                                            setReferenceData(false)
                                            setAdministration(false)
                                            setHelp(false);
                                            setWaterQuality(false)
                                            setImportAction(true)
                                            setMonitoringLocs(false)
                                            setProjects(false)
                                            setUserManagement(false)
                                            setOrganizations(false)
                                            setAddActivities(false)
                                            setActivities(false)
                                            setWQXHistory(false)
                                            setImportDetails(false)
                                            setImportActionII(false)
                                            setEditRoll(false)
                                            setAppSettings(false)
                                            setTaskManagement(false)
                                            setEditMonitoringLocation(false)
                                            setModalOpen(false)
                                        }} className="p-3 btn btn-primary">
                                            Import
                                        </Button>
                                    </div>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>
                                <FontAwesomeIcon size="xs" className="list-icon" style={{
                                    color: 'white',
                                    border: '2px solid white',
                                    borderRadius: '50%',
                                    width: '10px',
                                    height: '10px',
                                    padding: '3px',
                                    marginRight: '8px'
                                }} icon={fa5} />
                                Pick default settings for your organization
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    Organization default data (e.g. Default Timezone) has been defined. Click to view.
                                </Row>
                                <Row>
                                    <Col className="col-3">
                                        <Button onClick={() => {
                                            setHome(false);
                                            setWaterQuality(false)
                                            setReferenceData(false)
                                            setAdministration(false)
                                            setHelp(false);
                                            setAction('edit')
                                            setWaterQuality(true)
                                            setUserManagement(false)
                                            setOrganizations(false)
                                            setAddActivities(false)
                                            setActivities(false)
                                            setWQXHistory(false)
                                            setImportDetails(false)
                                            setImportActionII(false)
                                            setEditRoll(false)
                                            setAppSettings(false)
                                            setTaskManagement(false)
                                            setEditMonitoringLocation(false)
                                            setModalOpen(false)
                                        }} className="p-3 btn btn-primary mt-2">
                                            Get Started
                                        </Button>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>
                                <FontAwesomeIcon size="xs" className="list-icon" style={{
                                    color: 'white',
                                    border: '2px solid white',
                                    borderRadius: '50%',
                                    width: '10px',
                                    height: '10px',
                                    padding: '3px',
                                    marginRight: '8px'
                                }} icon={fa6} />
                                Enter 1 or more activites
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    You must enter a monitoring location and a project before you begin to create activities.
                                </Row>
                                <Row>
                                    <Col className="col-3">
                                        <Button onClick={() => {
                                            setHome(false);
                                            setWaterQuality(false)
                                            setReferenceData(false)
                                            setAdministration(false)
                                            setHelp(false);
                                            setAction('table')
                                            setWaterQuality(false)
                                            setImportAction(false)
                                            setUserManagement(false)
                                            setOrganizations(false)
                                            setAddActivities(false)
                                            setActivities(true)
                                            setWQXHistory(false)
                                            setImportDetails(false)
                                            setImportActionII(false)
                                            setEditRoll(false)
                                            setAppSettings(false)
                                            setTaskManagement(false)
                                            setEditMonitoringLocation(false)
                                            setModalOpen(false)
                                        }} className="p-3 btn btn-primary mt-2">
                                            Get Started
                                        </Button>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
        </div>
    )
}

export default Home

