// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zoom-widget {
  width: 29px;
  height: 29px;
  color: black;
  border-radius: 4px;
  position: fixed;
  right: 11px;
  top: 422px;
  font-weight: bolder;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19); }
`, "",{"version":3,"sources":["webpack://./src/features/reports/shared/zoom-widget.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,UAAU;EACV,mBAAmB;EACnB,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,wEAAwE,EAAA","sourcesContent":[".zoom-widget {\r\n    width: 29px;\r\n    height: 29px;\r\n    color: black;\r\n    border-radius: 4px;\r\n    position: fixed;\r\n    right: 11px;\r\n    top: 422px;\r\n    font-weight: bolder;\r\n    background: white;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
