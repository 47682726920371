const Contact = `<html>
<style>
    h1 {
        color:white;
        text-align: center;
    }

    input[type=text],
    select,
    textarea {
        width: 45%;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        margin-top: 6px;
        margin-bottom: 16px;
        resize: vertical
    }

    input[type=submit] {
        background-color: #257EE0;
        color: white;
        padding: 12px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
    input[type=submit]:hover {
        background-color: #0B60BF;
    }

    .container {
        border-radius: 5px;
        background-color: #050E25;
        padding: 20px;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    label { margin-bottom: -1.7rem }
</style>
<h1> Submit Feedback</h1>
<div className="container">
<form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
    <input type=hidden name="oid" value="00D5e000003Tr4h">
    <input type=hidden name="retURL" value="https://trueelements.com">
    <label style="color:white;" for="first_name">First Name:&nbsp</label><br>
    <input id="first_name" name="first_name" size="20" type="text" /><br>
    <label style="color:white;" for="last_name">Last Name:&nbsp</label><br>
    <input id="last_name" name="last_name" size="20" type="text" /><br>
    <label style="color:white;" for="email">Email:&nbsp</label><br>
    <input id="email" name="email" size="20" type="text" /><br>
    <label style="color:white;" for="company">Company:&nbsp</label><br>
    <input id="company" name="company" size="20" type="text" /><br>
    <label style="color:white;" for="city">City:&nbsp</label><br>
    <input id="city" name="city" size="20" type="text" /><br>
    <label style="color:white;" for="state">State/Province:&nbsp</label><br>
    <input id="state" name="state" size="20" type="text" /><br>
    <label style="color:white;" for="description">Description:&nbsp</label><br>
    <textarea name="description" style="height:200px"></textarea><br>
    <input type="submit" name="submit">
</form>
</div>

</html>`;
const ContactForm = () => <div dangerouslySetInnerHTML={{ __html: Contact }} />;
export default ContactForm;
