import Keycloak from 'keycloak-js';
import json from './settings.json';

// Setup Keycloak instance with initialization options from settings.json
const keycloak = new Keycloak({
  url: json.Keycloak.Url,
  realm: json.Keycloak.Realm,
  clientId: json.Keycloak.ClientId,
  onLoad: 'check-sso',
 // silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`
});

export default keycloak;
