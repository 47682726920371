import {
    useMemo,
    useEffect,
    useState
} from 'react'
import ReportTable from '../../reports/shared/ReportTable'
import {
    Col,
    Row,
    Button,
    Form
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPenToSquare,
    faTrashCan
} from '@fortawesome/pro-solid-svg-icons';
import LoadingDataAnimation from "../../../components/LoadingDataAnimation";
import { exportToCsv } from '../../utils';
import { getDomainValue } from './lib/data';

const ReferenceData = (props: any) => {

    const { global } = props

    const rowLimit = 2000;
    const [data, setData] = useState<any[]>([]);
    const [dataLimited, setDataLimited] = useState<any[]>([]);
    const [fullData, setFullData] = useState<any[]>([]);
    const [activeIndex, setActiveIndex] = useState()
    const [isDataReportLoaded, setIsDataReportLoaded] = useState(true);

    const tableColumns = useMemo(
        () => [
            {
                Header: 'Value',
                accessor: 'Name',
            },
            {
                Header: 'Description',
                accessor: 'Description',
            },
            {
                Header: 'Last Updated',
                accessor: 'Last Change Date',
            }
        ],
        []
    );

    const onChangeSearch = (event: any) => {     
         if (event && event.target && event.target.value && event.target.value.length > 1) {
            let fData = [...fullData].filter((o: any) => o["Name"].toLowerCase().indexOf(event.target.value.toLowerCase()) > -1);
            setData(fData);
            setDataLimited(fData.slice(0,rowLimit));
            //setSearchText(event.target.value);
        } else {
            setData(fullData);
            setDataLimited(fullData.slice(0,rowLimit));
            //setSearchText('');
        }       
    }

    const updateData = (data: any, error: any) => {
        if(error){
            global.notify(error);
            setData([]);
            setFullData([]);
            setDataLimited([]);
            setIsDataReportLoaded(true);  
        } else {
            setData(data);
            setFullData(data);
            setDataLimited(data.slice(0,rowLimit));
            setIsDataReportLoaded(true);         
        }
    }

    const handleChange = (event: any) => {
        if(event.target.value && isNaN(event.target.value)){
            setIsDataReportLoaded(false);
            getDomainValue(event.target.value, updateData);
        }
        if(!event.target.value || parseInt(event.target.value) < 0){
            setData([]);
            setFullData([]);
            setDataLimited([]);
        } 
        if(event.target.value){
            setActiveIndex(event.target.value) 
        }
    }

    const handleExport = (event: any) => {
        exportToCsv(data, 'ReferenceData');
    }

    return (
            <> 
              <div id={"referencedata-container"}>
              <LoadingDataAnimation dataLoading={!isDataReportLoaded} />
                <div className="header-box fr-data">
                    <h2>Reference Data</h2>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between' }}>

                    <div className='org-table-header'>
                        <div className='wm-org-title-bar'>
                            <Form.Group className="m-1 wm-org-title-bar-form" controlId="formOrg">
                                <Form.Label>Select Reference Table:</Form.Label>
                                {/* @ts-ignore */}
                                <Form.Select id='wm-refs' key={'wm-ref-'+'1' }  onChange={handleChange} value={activeIndex} className='wm-reference-select' size="sm" placeholder='Reference'>
                                    <option value=""></option>
                                    <option value="ORGANIZATION">--ORGANIZATION--</option>
                                    <option value="ORGANIZATION">Organizations</option>
                                    <option value="Tribe">Tribes</option>
                                    <option value="AddressType">Address Type</option>
                                   
                                    <option value="ElectronicAddressType">Electronic Address Type</option>
                                    
                                    <option value="PhoneType">Phone Type</option>
                                    <option value="-2">--PROJECTS--</option>
                                    <option value="SamplingDesignType">Sampling Design Type</option>
                                    <option value="-3">--MONITORING LOCATION--</option>
                                    <option value="County">County</option>
                                    <option value="Country">Country</option>
                                    <option value="HorizontalCollectionMethod">Horizontal Collection Method</option>
                                    <option value="HorizontalReferenceDatum">Horizontal Reference Datum</option>
                                    <option value="MonitoringLocationType">Monitoring Location Type</option>
                                    <option value="State">State</option>
                                    <option value="VerticalCollectionMethod">Vertical Collection Method</option>
                                    <option value="VerticalReferenceDatum">Vertical Reference Datum</option>
                                    <option value="WellFormationType">Well Formation Type</option>
                                    <option value="WellType">Well Type</option>
                                    <option value="-4">--ACTIVITY--</option>
                                    <option value="ActivityMedia">Activity Media</option>
                                    <option value="ActivityMediaSubdivision">Activity Media Subdivision</option>
                                    <option value="ActivityType">Activity Type</option>
                                    <option value="ActivityGroupType">Activity Group Type</option>
                                    <option value="ActivityRelativeDepth">Activity Relative Depth</option>
                                    <option value="Assemblage">Assemblage</option>
                                    <option value="BiologicalIntent">Biological Intent</option>
                                    <option value="MetricType">Metric Type</option>
                                    <option value="NetType">Net Type</option>
                                    <option value="SampleCollectionEquipment">Sample Collection Equipment</option>
                                    <option value="SampleCollectionEquipmentType">Sample Collection Equipment Type</option>
                                    <option value="SampleCollectionMethod">Sample Collection Method</option>
                                    <option value="SampleCollectionMethodContext">Sample Collection Method Context</option>
                                    <option value="ContainerColor">Sample Container Color</option>
                                    <option value="ContainerType">Sample Container Type</option>
                                    <option value="SamplePrepMethod">Sample Prep Method</option>
                                    <option value="SampleTissueAnatomy">Sample Tissue Anatomy</option>
                                    <option value="ThermalPreservative">Thermal Preservative Used</option>
                                    <option value="TimeZone">TimeZone</option>
                                    <option value="ToxicityTestType">Toxicity Test Type</option>
                                    <option value="-5">--RESULTS--</option>
                                    <option value="AnalyticalMethod">Analytical Method</option>
                                    <option value="AnalyticalMethodContext">Analytical Method Context</option>
                                    <option value="CellForm">Cell Form</option>
                                    <option value="CellShape">Cell Shape</option>
                                    <option value="Characteristic">Characteristic</option>
                                    <option value="CharacteristicAlias">Characteristic Alias</option>
                                    <option value="CharacteristicGroup">Characteristic Group</option>
                                    <option value="CharacteristicPickListValue">Characteristic Pick List</option>
                                    {/* <option value="QAQCCharacteristicValidation">QAQC Characteristic Validation</option>*/}
                                    <option value="QuantitationLimitType">Detection Quantitation Limit Type</option>
                                    <option value="FrequencyClassDescriptor">Frequency Class Descriptor</option>
                                    <option value="FrequencyClassDescriptorType">Frequency Class Descriptor Type</option>
                                    <option value="Habit">Habit</option>
                                    <option value="HabitatSelectionMethod">Habitat Selection Method</option>
                                    <option value="MeasurementUnit">Measure Unit</option>
                                    <option value="MethodSpeciation">Method Speciation</option>
                                    <option value="ResultDetectionCondition">Result Detection Condition</option>
                                    <option value="ResultMeasureQualifier">Result Measure Qualifier</option>
                                    <option value="SampleFraction">Result Sample Fraction</option>
                                    <option value="ResultSamplingPointType">Result Sampling PointType</option>
                                    <option value="ResultStatus">Result Status</option>
                                    <option value="ResultTemperatureBasis">Result Temperature Basis</option>
                                    <option value="ResultTimeBasis">Result Time Basis</option>
                                    <option value="ResultValueType">Result Value Type</option>
                                    <option value="ResultWeightBasis">Result Weight Basis</option>
                                    <option value="ResultStatisticalBase">Statistical Base</option>
                                    <option value="Taxon">Taxon</option>
                                    <option value="TaxonAlias">Taxon Alias</option>
                                    <option value="TaxonGroup">Taxon Group</option>
                                    <option value="TaxonRank">Taxon Rank</option>
                                    <option value="Voltinism">Voltinism</option>  
                                    <option value="-5">--ADDITIONAL--</option>       
                                    <option value="AquiferType">Aquifer Type</option> 
                                    <option value="AliasType">Alias Type</option>
                                    <option value="GearProcedureUnit">Gear Procedure Unit</option> 
                                    <option value="HydrologicCondition">Hydrologic Condition</option> 
                                    <option value="HydrologicEvent">Hydrologic Event</option>                                     
                                    <option value="NationalAquifer">National Aquifer</option> 
                                    <option value="NetType">Net Type</option> 
                                    <option value="LocalAquifer">Local Aquifer</option>  
                                    <option value="LocalAquiferContext">Local Aquifer Context</option>  
                                    <option value="ReferenceLocationType">Reference Location Type</option>  
                                    <option value="RelativeDepth">Relative Depth</option>     
                                    <option value="TargetCount">RTargetCount</option>  
       
                                </Form.Select>
                            </Form.Group>
                        </div>         
                    </div>

                    <div className='users-tool-container'>
                        <Form.Group  className='users-search' controlId="formSearch">
                            <Form.Label></Form.Label>
                            <Form.Control onChange={onChangeSearch} type="text" placeholder="Search" />
                        </Form.Group>
                    </div>


                    <div className="button-box">
                        <Button id="export-btn" onClick={handleExport}  className="p-3 btn btn-outline-primary m-2">
                            Export CSV
                        </Button>
                    </div>
                </div>
                <div className="reference-data-table-container table-scroll">                  
                    <ReportTable columns={tableColumns} data={dataLimited} />
                </div>
              </div>
            </>
    )
}

export default ReferenceData