import { useEffect, useState } from 'react';
import AppleLocs from '../Layers/AppleLocs/AppleLocs'
import { parse } from 'papaparse';

const AppleLocationData = ({ global, mapRef, id }: any) => {

  const [data, setData] = useState()

  useEffect(() => {

    const appleSites = '/csv/apple_sites.csv';
    fetch(appleSites)
        .then((response) => response.text())
        .then((responseText) => {
            const appleSitesCatalog: any = parse(responseText, {
                header: true,
            }).data;
            setData(appleSitesCatalog);
        })
}, [])

  return <AppleLocs id={id} global={global} data={data} mapRef={mapRef} apple={true} />
}

export default AppleLocationData
