// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-anchor {
  color: #4fc2d9;
  font-weight: bold;
  text-decoration: underline;
  background-color: transparent;
  border-radius: 6px;
  cursor: pointer; }
`, "",{"version":3,"sources":["webpack://./src/components/Layers/VectorLayerReport/wsbv-layer.scss"],"names":[],"mappings":"AAAA;EACI,cAAc;EACd,iBAAiB;EACjB,0BAA0B;EAC1B,6BAA6B;EAC7B,kBAAkB;EAClB,eAAe,EAAA","sourcesContent":[".report-anchor {\n    color: #4fc2d9;\n    font-weight: bold;\n    text-decoration: underline;\n    background-color: transparent;\n    border-radius: 6px;\n    cursor: pointer;\n}\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
