import { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { Layer, Source, Popup } from "react-map-gl";
import { CircleLayer } from "mapbox-gl";
import { RefContext } from "../../RefContext";
import { PointContext } from "../../PointContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { ActivePopupContext } from "../../ActivePopupContext";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Draggable from "react-draggable";
import "./owif-layer.scss";

interface OWiFrameProps {
  global: { viewport: { zoom: number } };
  id: string;
  action: { lngLat: { lng: number; lat: number } } | null;
  url: string;
  sourceLayer: string;
  circleColor: string;
  opacity: number;
}

const OWiFrame = ({
  global,
  id,
  action,
  url,
  sourceLayer,
  circleColor,
  opacity,
}: OWiFrameProps) => {
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
  const { currentRef } = useContext<any>(RefContext);

    // @ts-ignore
  const ref = currentRef?.current;
  const lngLat = action?.lngLat;
  const [features, setFeatures] = useState<Record<string, any> | null>(null);
  const [iFrame, setiFrame] = useState<JSX.Element | undefined>();


  // Handle radius based on zoom level
  const handleRadius = (zoom: number): number => {
    if (zoom <= 7) return 2;
    if (zoom > 12) return 5;
    if (zoom > 10) return 3;
    return 2;
  };

  // Circle layer configuration
  const CircleVectorLayer: CircleLayer = useMemo(
    () => ({
      id: `${id}-layer`,
      type: "circle",
      "source-layer": sourceLayer,
      paint: {
        "circle-radius": handleRadius(global.viewport.zoom),
        "circle-opacity": opacity,
        "circle-color": circleColor,
      },
    }),
    [id, sourceLayer, global.viewport.zoom, opacity, circleColor]
  );

  // Query features at a point
  const getFeaturesAtPoint = useCallback(
    (event: any) => {
      const res = ref?.queryRenderedFeatures(event.point, { layers: [`${id}-layer`] })?.[0];
      if (res?.properties) setFeatures(res.properties);
    },
    [id, ref]
  );

  useEffect(() => {
    if (ref) {
      ref.on("click", getFeaturesAtPoint);
      return () => ref.off("click", getFeaturesAtPoint);
    }
  }, [ref, getFeaturesAtPoint]);

  // Handle iframe rendering
  const handleIframeClose = () => setiFrame(undefined);
  

  const handleiFrame = (input: string, name: string) => {
    setiFrame(
      <Draggable handle=".draggable-handle">
        <Container className="mt-4 iframe-container">
          <Row className="justify-content-center">
            <Col md={8}>
              <Card>
                <FontAwesomeIcon
                  className="close-btn"
                    icon={faXmark}
                    onClick={handleIframeClose}
                  />
                <Card.Header className="draggable-handle">Well ID: {name}</Card.Header>
                <Card.Body>
                  <div
                    style={{
                      position: "relative",
                      paddingBottom: "56.25%",
                      height: 0,
                      overflow: "hidden",
                    }}
                  >
                    <iframe
                      src={input}
                      title={`Well ID: ${name}`}
                      id="iframe-ow"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        border: 0,
                      }}
                      allowFullScreen
                    ></iframe>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Draggable>
    );
  };

  // Popup logic
  const memoizedPopup = useMemo(() => {
    if (!features || !lngLat) return null;

    const handleClose = () => {
      setFeatures(null);
      setActivePopup({ id: null, content: null });
    };

    return (
      <Popup
        longitude={lngLat.lng}
        latitude={lngLat.lat}
        onClose={handleClose}
        className="circledata-popup"
      >
        <FontAwesomeIcon className="close-btn" icon={faXmark} onClick={handleClose} />
        <strong>{features["well_id"]}</strong>
        <a
          className="report-anchor"
          onClick={() => handleiFrame(features["file_url"], features["well_id"])}
        >
          Open PDF
        </a>
      </Popup>
    );
  }, [features, lngLat, setActivePopup]);

  // Sync active popup with state
  useEffect(() => {
    if (memoizedPopup && activePopup?.id !== id) {
      setActivePopup({ id, content: memoizedPopup });
    }
  }, [memoizedPopup, activePopup, setActivePopup, id]);

  return (
    <>
      <Source id={id} type="vector" url={url}>
        <Layer {...CircleVectorLayer} />
        {activePopup?.id === id && activePopup.content}
      </Source>
      {iFrame}
    </>
  );
};

export default OWiFrame;