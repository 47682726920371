import { useState, useEffect } from 'react'
import {
  Form,
  Col,
  Button,
  Tabs,
  Tab
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPenToSquare,
    faTrashCan
} from '@fortawesome/pro-solid-svg-icons';
import ReportTable from '../../reports/shared/ReportTable';
import { getDomainValue, postWQX } from './lib/data';


const GlobalImportRules = ({
  setCharacteristicModal,
  setTranslationModal,
  setGlobalImportRules,
  setImportAction,
  timeZone,
  setTimeZone,
  importChar,
  setImportChar,
  importTaxa,
  setImportTaxa,
  importTranslation,
  setImportTranslation,
  setData
}: any) => {

  const [activeKey, setActiveKey] = useState('General Defaults')
  const taxaLimit = 2000;

  const [Taxon, setTaxon] = useState < any[] > ([]);
  const [fullTaxon, setFullTaxon] = useState < any[] > ([]);
  const [currentTaxon, setCurrentTaxon] = useState < any[] > ([]);

  useEffect(() => {
      getDomainValue('Taxon', limitTaxon);
  }, []);

  const limitTaxon = (value:any) => {
    setFullTaxon(value);
    setTaxon(value.slice(0, taxaLimit));
  }

  const handleTimeZone = (event: any) => {
      if(event){
        localStorage.setItem('wm_timezone', event);
        setTimeZone(event);
      }    
  }

  const handleAddCharacteristic = (event: any) => {
      let newCharacteristic = {"Characteristic" : "", "MeasurementUnit": "km", "DetectionLimit": "", "LowerQuantLimit":"", "UpperQuantLimit":"", "AnalysisMethod":"", "SampleFraction":"", "ResultStatus":"", "ResultValueType":""}
      setData(newCharacteristic)
      setCharacteristicModal(true)
      //setActiveKey("Import Translations")
  }

  const handleCharDelete = (event: any) => {
      let newData = [...importChar];
      let fData = newData.filter((o: any) => o['Characteristic'] !== event);
      setImportChar(fData);
  }

  const handleAddTaxa = (event: any) => {
      let newData = [...importTaxa];
      let fData = newData.filter((o: any) => o['Name'] === event);
      if(fData.length<1){
        newData.push({"Name" : currentTaxon});
        setImportTaxa(newData);
      }
  }

  const handleTaxaDelete = (event: any) => {
      let newData = [...importTaxa];
      let fData = newData.filter((o: any) => o['Name'] !== event);
      setImportTaxa(fData);
  }

   const onChangeTaxaSearch = (event: any) => {     
        if (event && event.target && event.target.value && event.target.value.length > 1) {
            let fData = [...fullTaxon].filter((o: any) => o["Name"].toLowerCase().indexOf(event.target.value.toLowerCase()) > -1);         
            setTaxon(fData.slice(0, taxaLimit));
        } else {
            setTaxon(fullTaxon.slice(0, taxaLimit));
        }       
  }

  const handleAddTranslation = () => {
      let newTranslation = {"Characteristic" : "", "DetectionLimit": "", "LowerQuantLimit":"", "UpperQuantLimit":""}
      setData(newTranslation);
      setTranslationModal(true);
  }

  const handleTranslationDelete = (event: any) => {
      let newData = [...importTranslation];
      let fData = newData.filter((o: any) => o['Characteristic'] !== event);
      setImportTranslation(fData);
  }


  const tableColumnsChar = [];
  tableColumnsChar.push(
                 {
                     Header: 'Characteristic',
                     accessor: 'Characteristic',
                 },
                 {
                     Header: 'Measurement Unit',
                     accessor: 'MeasurementUnit',
                 }, 
                 {
                     Header: 'Detection Limit',
                     accessor: 'DetectionLimit',
               }, 
                 {
                     Header: 'Lower Quant Limit',
                     accessor: 'LowerQuantLimit',
                 }, 
                 {
                     Header: 'Upper Quant Limit',
                     accessor: 'UpperQuantLimit',
                 }, 
                 {
                     Header: 'Analysis Method',
                     accessor: 'AnalysisMethod',
                 }, 
                 {
                     Header: 'Sample Fraction',
                     accessor: 'SampleFraction',
                 }, 
                 {
                     Header: 'Result Status',
                     accessor: 'ResultStatus',
                 }, 
                 {
                     Header: 'Result Value Type',
                     accessor: 'ResultValueType',
                 }, 
                 {
                Header: 'Actions',
                accessor: 'actions',
                //@ts-ignore
                Cell: (props: any) => {
                    return (
                        <div className="action-box">
                            <div
                                onClick={() => {
                                    handleCharDelete(props.row.values.Characteristic)            
                                }}
                                className='action-cell'
                            >
                                <FontAwesomeIcon style={{ marginLeft:18, color: '#4fc2d9', cursor: 'pointer' }} icon={faTrashCan} />
                            </div>
                        </div>
                    )
                }
            })

  const tableColumnsTaxa = [];
  tableColumnsTaxa.push(
                 {
                     Header: 'Taxon',
                     accessor: 'Name',
                 }, {
                Header: 'Actions',
                accessor: 'actions',
                //@ts-ignore
                Cell: (props: any) => {
                    return (
                        <div className="action-box">
                            <div
                                onClick={() => {
                                    handleTaxaDelete(props.row.values.Name)            
                                }}
                                className='action-cell'
                            >
                                <FontAwesomeIcon style={{ marginLeft:18, color: '#4fc2d9', cursor: 'pointer' }} icon={faTrashCan} />
                            </div>
                        </div>
                    )
                }
            })

  const tableColumnsTranslation = [];
  tableColumnsTranslation.push(
                   {
                     Header: 'Characteristic',
                     accessor: 'Characteristic',
                 },

                 {
                     Header: 'Detection Limit',
                     accessor: 'DetectionLimit',
                 }, 
                 {
                     Header: 'Lower Quant Limit',
                     accessor: 'LowerQuantLimit',
                 }, 
                 {
                Header: 'Actions',
                accessor: 'actions',
                //@ts-ignore
                Cell: (props: any) => {
                    return (
                        <div className="action-box">
                            <div
                                onClick={() => {
                                    handleTranslationDelete(props.row.values.Characteristic)            
                                }}
                                className='action-cell'
                            >
                                <FontAwesomeIcon style={{ marginLeft:18, color: '#4fc2d9', cursor: 'pointer' }} icon={faTrashCan} />
                            </div>
                        </div>
                    )
                }
            })


  return (
    <div className="gir-container">
      <h2>Organization Data Rules</h2>
      <section className="gir-tabs-container">
        <Col className="col-12 mt-4">
          <Tabs
            onSelect={(e:any) => setActiveKey(e)}
            className="w-100 p-0 m-0 position-absolute top-0"
            activeKey={activeKey}
          >
            <Tab
              className="w-100 h-100 p-0 mt-5 mb-auto fs-6"
              eventKey="General Defaults"
              title="General Defaults">
              <Col className="w-100">
                <div className="info-box">
                  <p>Before entering activities, you must set the default timezone (used to set the correct timezone for activities).</p>
                </div>
                <Col className="col-12 d-flex mt-2">
                  <Col className="col-2">
                    <p>Default Time Zone:</p>
                  </Col>
                  <Col className="col-4">
                    <Form.Select size="sm" defaultValue={timeZone} onChange={(e: any) => handleTimeZone(e.target.value)}>
                      <option value=""></option>
                      <option value="Pacific">Pacific</option>
                      <option value="Mountain">Mountain</option>
                      <option value="Eastern">Eastern</option>
                      <option value="Central">Central</option>
                      <option value="Alaskan">Alaskan</option>
                    </Form.Select>
                    <Col
                      style={{
                        position: 'absolute',
                        left: '0',
                      }}
                      className='col-12 mt-2 d-flex justify-content-start'
                    >
                      <div className="footer-button-box">
                        <Button size="sm" id="footer-btn" className="btn btn-primary m-2 edit-btn">
                          Save
                        </Button>
                        <Button 
                        onClick={() => {                        
                          setImportAction(true)
                          setGlobalImportRules(false)
                        }}
                        size="sm" id="footer-btn" className="btn btn-primary m-2">
                          Exit
                        </Button>
                      </div>
                    </Col>
                  </Col>
                </Col>
              </Col>
            </Tab>
            <Tab
              className="w-100 h-100 p-0 mt-5 mb-auto fs-6"
              eventKey="Characteristic Defaults"
              title="Characteristic Defaults">
              <Col className="w-100">
                <div className="info-box characteristic">
                  <p>This tab serves two purposes:</p>
                  <p>(1) Characteristics listed here will be included in the characteristic dropdown when manually entering samples (as opposed to importing them)</p>
                  <p>(2) Define default values (such as Default Detection Limit, Unit of Measure, etc) that will be automatically populated when importing sampling data. When importing samples, if data is not included in your import file, True Elements will apply the default value here if it is available.</p>
                </div>
                <Col className="col-12 d-flex mt-2">
                  <Col className='col-12 mt-2 d-flex justify-content-start' style={{flexDirection: 'column', alignItems:'flex-start'}}>
                    <div className="footer-button-box">
                      <Button
                        size="sm"
                        id="footer-btn"
                        className="btn btn-primary m-2 edit-btn"
                        onClick={handleAddCharacteristic}
                      >
                        Add Characteristic
                      </Button>
                    </div>
                    <ReportTable columns={tableColumnsChar} data={importChar} />
                  </Col>

                  
                </Col>
              </Col>
            </Tab>
            <Tab
              className="w-100 h-100 p-0 mt-5 mb-auto fs-6"
              eventKey="Taxa Defaults"
              title="Taxa Defaults">
              <Col className="w-100">
                <div className="info-box">
                  <p>Taxa you list here will be included in the taxonomy drop down when manually entering biological samples</p>
                </div>
                <Col className='col-12 mt-2 d-flex justify-content-start' style={{flexDirection: 'column', alignItems:'flex-start'}}>
                  <Col className='col-2 m-2' style={{width: '80vw'}}>
                    <div style={{width: '80vw', flexDirection: 'row'}}>
                      <div className='users-tool-container'  style={{minWidth: 80, display: 'inline-flex'}}>
                          <Form.Group  className='users-search' controlId="formSearch" style={{display: 'inline-flex', height: 'fit-content', marginTop: 5}}>
                              <Form.Control onChange={onChangeTaxaSearch} type="text" placeholder="Search" />
                          </Form.Group>
                      </div>


                      <Form.Group className="mb-3" controlId="floatingMonitoringLocationType" style={{width: '30vw', marginLeft: 15, display: 'inline-flex'}}>                                    
                          <Form.Select  onChange={(e: any) => setCurrentTaxon(e.target.value)}
                           size="lg" aria-label="MonitoringLocIdent_MonitoringLocationTypeName" defaultValue={""}>
                              {Taxon  && Taxon.map((obj:any, index:any) =>
                                  (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                              )} 
                          </Form.Select>                                    
                      </Form.Group>               
                      <Button size="sm" id="footer-btn" className="btn btn-primary edit-btn" 
                          style={{ marginLeft: '35px'}}
                          onClick={(e: any) =>  handleAddTaxa(e.target.value)}>
                        Add Taxa
                      </Button>
                     </div>
                    <ReportTable columns={tableColumnsTaxa} data={importTaxa} />
                  </Col>
                  <Col className='col-2 d-flex justify-content-start'>
                    <div className="footer-button-box">

                    </div>
                    
                  </Col>
                </Col>
              </Col>
            </Tab>
            <Tab
              className="w-100 h-100 p-0 mt-5 mb-auto fs-6"
              eventKey="Import Translations"
              title="Import Translations">
              <Col className="w-100">
                <div className="info-box characteristic">
                  <p>Define translations if you want True Elements to automatically translate your reported data to another value when importing data.</p>
                </div>
                <Col className="col-12 d-flex mt-2">
                  <Col className='col-12 mt-2 d-flex justify-content-start' style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{marginBottom:20}} >
                      <Button
                        onClick={() => handleAddTranslation()}
                        size="sm"
                        id="footer-btn"
                        className="btn btn-primary m-2 edit-btn"
                      >
                        Add Translation
                      </Button>
                    </div>
                    <ReportTable columns={tableColumnsTranslation} data={importTranslation} />
                  </Col>
                </Col>
              </Col>
            </Tab>
          </Tabs>
        </Col>
      </section>
    </div>
  )
}

export default GlobalImportRules