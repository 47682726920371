// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.render-cluster-list-marker {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  width: 50px;
  height: 50px;
  position: relative; }
  .render-cluster-list-marker .marker {
    background-color: #282828;
    border-radius: 50%;
    width: 24px;
    height: 24px; }
  .render-cluster-list-marker p {
    position: absolute;
    z-index: 100;
    font-weight: bold; }

.mapboxgl-popup-content > div {
  display: flex;
  justify-content: center;
  flex-direction: column; }
`, "",{"version":3,"sources":["webpack://./src/components/RenderMarkers/render-markers.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,kBAAkB,EAAA;EATtB;IAWQ,yBAAiC;IACjC,kBAAkB;IAClB,WAAW;IACX,YAAY,EAAA;EAdpB;IAiBQ,kBAAkB;IAClB,YAAY;IACZ,iBAAiB,EAAA;;AAIzB;EACI,aAAa;EACb,uBAAuB;EACvB,sBAAsB,EAAA","sourcesContent":[".render-cluster-list-marker {\n    color: black;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 50%;\n    border: none;\n    width: 50px;\n    height: 50px;\n    position: relative;\n    .marker {\n        background-color: rgb(40, 40, 40);\n        border-radius: 50%;\n        width: 24px;\n        height: 24px;\n    }\n    p {\n        position: absolute;\n        z-index: 100;\n        font-weight: bold;\n    }\n  }\n\n.mapboxgl-popup-content > div {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
