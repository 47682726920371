import Map, {
  Marker,
  FullscreenControl
} from 'react-map-gl'
import { Col, Row, Tabs, Tab, Form } from 'react-bootstrap'
import {
  faChevronRight,
  faChevronLeft,
  faMaximize,
  faMinimize
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RefContext } from '../../../RefContext'
import {
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo
} from 'react'
import agent from '../../../api/agent'
import { PointContext } from '../../../PointContext';
import { AppContext } from '../../../AppContext';
import ReportTable from '../shared/ReportTable'
import RenderLayerMarkers from '../shared/RenderLayerMarkers'
import FilterState from '../shared/FilterState';
import ReportFooter from '../shared/ReportFooter'
import RenderLayers from '../shared/RenderLayers'
import MapSlider from '../shared/MapSlider'
import MapLegend from '../shared/MapLegend'
import settings from '../../../settings.json'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ReportHeader from '../shared/ReportHeader'
import {
  FullScreen,
  useFullScreenHandle
} from 'react-full-screen';
import { Bar } from 'react-chartjs-2';
import './style.scss'
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ViolationDataReport = ({ global }: any) => {

  const { currentPoint } = useContext<any>(PointContext)
  const id = currentPoint.zip

  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;

  const {
    selectedHUC8,
    updateContext,
    currentLocation
  } = useContext(AppContext);

  const [reportData, setReportData] = useState<any>()
  const [sdwaData, setSdwaData] = useState<any>()
  const [violationGraphData, setViolationGraphData] = useState<any>()
  const [expanded, setExpanded] = useState<any>(false)

  const loadData = (currentPoint: any) => {
      global.setLoading(true)
      // @ts-ignore
      agent.Reports.ViolationData(currentPoint.pwsid)
        .then(({ body }) => setReportData(body[0]))
        .finally(() => global.setLoading(false))    
  }

  useEffect(() => {
    if (currentPoint) {
      loadData(currentPoint);
    }
  }, [currentPoint])

  useEffect(() => {
    if (reportData) {
      setSdwaData(reportData.sdwa_data)
      setViolationGraphData(reportData.violation_graph_data[0])
    }
  }, [reportData])

  useEffect(() => global.setUserLogged(true), [])

  const mapRef = useRef<any>()

  const { setCurrentRef } = useContext(RefContext)

  useEffect(() => {
    setCurrentRef?.(mapRef)
  }, [mapRef])

  const bounds = mapRef.current
    ? mapRef.current
      .getMap()
      .getBounds()
      .toArray()
      .flat()
    : null;

  const [graphOptions, setGraphOptions] = useState<any>()
  const [graphData, setGraphData] = useState<any>()

  const reportContainer = document.getElementById("reportsContainer")

  useEffect(() => {
    if(reportContainer) {
      reportContainer.style.height = "100vh"

      return () => {  reportContainer.style.height = "unset" }
    }
  }, [])

  const transFormGraphData = (input: any) => {

    function getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    let object: any = input

    delete object.pk
    delete object.pwsid

    const keysArray: any = Object.keys(object)
    const labels = keysArray.map((key: any) => key.replaceAll("_", " ").toUpperCase())

    setGraphData({
      datasets: labels.map((key: any, index: any) => {
        return {
          label: key,
          data: { score: input[keysArray[index]] },
          key: index,
          backgroundColor: getRandomColor()
        }
      })
    })

    //return
  }

  useEffect(() => {
    if (violationGraphData) {

      const options = {
        responsive: true,
        maintainAspectRatio: true,
        beginAtZero: true,
        aspectRatio: 2,
        animation: true,
        scales: {
          y: {
            ticks: {
              stepSize: 1
            },
          },
          x: {
            display: true,
            title: {
              display: true,
              color: 'white'
            },
          },
        },
        plugins: {
          legend: {
            position: "bottom" as const,
            labels: {
              usePointStyle: true,
            },
            maxHeight: 70,
          } as const,
          title: {
            display: true,
            text: `PWSID - ${violationGraphData.pwsid}, PK - ${violationGraphData.pk}`,
            color: 'white',
            align: 'center',
            padding: 10,
            font: {
              size: 20
            }
          } as const,
          tooltip: {
            padding: 10,
            bodyFont: {
              size: 24
            },
            titleFont: {
              size: 24
            },
            boxPadding: 8,
            usePointStyle: true,
            backgroundColor: '#12234f',
          } as const,
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: 'xy',
            },
          } as const,
        } as const,
      } as const;


      transFormGraphData(violationGraphData)
      setGraphOptions(options)
    }
  }, [violationGraphData])

  useEffect(() => {
    if (currentPoint && reportData && currentPoint.longitude && currentPoint.latitude) {
      mapRef.current.flyTo({
        center: [
          currentPoint.longitude,
          currentPoint.latitude
        ],
        essential: true,
        zoom: global.viewport.zoom,
      });
    }
  }, [currentPoint])



  const columns1 = useMemo(() => [
    {
      Header: 'Counties Served',
      accessor: 'countiesserved',
    },
    {
      Header: 'Copper Action Level Exceedances',
      accessor: 'cuale',
    },
    {
      Header: 'Currvio Flag',
      accessor: 'currvioflag',
    },
    {
      Header: 'DFR URL',
      accessor: 'dfrurl',
      Cell: ({ data }: any) => {
        return <a href={`${data[0].dfrurl}`} target="_blank">EPA Link</a>
      }
    },
    {
      Header: 'Formal Enforcement Actions',
      accessor: 'feas',
    },
    {
      Header: 'Health-Based Violations',
      accessor: 'healthflag',
    },
    {
      Header: 'Informal Enforcement Actions',
      accessor: 'ifea',
    },
    {
      Header: 'Lead and Copper Violation',
      accessor: 'leadandcopperviol',
    },
    {
      Header: 'Monitoring and Reporting Violations',
      accessor: 'mrflag',
    },
    {
      Header: 'Has New Violations',
      accessor: 'newvioflg',
    },
    {
      Header: 'Owner',
      accessor: 'ownertypecode',
    },
    {
      Header: 'Lead Action Level Exceedances',
      accessor: 'pbale',
    },
    {
      Header: 'PK',
      accessor: 'pk',
    },
    {
      Header: 'Public Notice Violations',
      accessor: 'pnflag',
    },
    {
      Header: 'Population Served',
      accessor: 'populationservedcount',
    },
    {
      Header: 'Primary Source Water Type',
      accessor: 'primarysourcecode',
    },
    {
      Header: 'Activity Status',
      accessor: 'pwsactivitycode',
    },
    {
      Header: 'Activity Status',
      accessor: 'pwsactivitydesc',
    },
    {
      Header: 'System Name',
      accessor: 'pwsname',
    },
    {
      Header: 'System Type',
      accessor: 'pwstypecode',
    },
    {
      Header: 'Other Violations',
      accessor: 'otherflag',
    },
    {
      Header: 'Owner',
      accessor: 'ownerdesc',
    },
    {
      Header: 'System Type',
      accessor: 'pwstypedesc',
    },
    {
      Header: 'Quarters as an Enforcement Priority',
      accessor: 'qtrswithsnc',
    },
    {
      Header: 'Quarters with Violation',
      accessor: 'qtrswithvio',
    },
    {
      Header: 'Lead and Copper Violations',
      accessor: 'rc350viol',
    },
    {
      Header: 'Rules Violation 3yr',
      accessor: 'rulesvio3yr',
    },
    {
      Header: 'Contaminants in Violation',
      accessor: 'sdwacontaminants',
    },
    {
      Header: 'Contaminants with Violation in Latest Quarter',
      accessor: 'sdwacontaminantsincurviol',
    },
    {
      Header: 'SDWA Contaminants in Violation 3yr',
      accessor: 'sdwacontaminantsinviol3yr',
    },
    {
      Header: 'SDW Date Last FEA',
      accessor: 'sdwdatelastfea',
    },
    {
      Header: 'SDW Date Last FEA EPA',
      accessor: 'sdwdatelastfeaepa',
    },
    {
      Header: 'SDW Date Last FEAST',
      accessor: 'sdwdatelastfeast',
    },
    {
      Header: 'SDW Date Last IEA',
      accessor: 'sdwdatelastiea',
    },
    {
      Header: 'SDW Date Last IEA EPA',
      accessor: 'sdwdatelastieaepa',
    },
    {
      Header: 'SDW Date Last IEAST',
      accessor: 'sdwdatelastieast',
    },
    {
      Header: 'Enforcement Priority',
      accessor: 'seriousviolator',
    },
    {
      Header: 'Address',
      accessor: 'statecode',
    },
    {
      Header: 'Tribal Flag',
      accessor: 'tribalflag',
    },
    {
      Header: 'Violation Points under Formal Enforcement, but Not Yet Corrected',
      accessor: 'viofeanot',
    },
    {
      Header: 'Violation Flag',
      accessor: 'vioflag',
    },
    {
      Header: 'Violation Categories',
      accessor: 'violationcategories',
    },
    {
      Header: 'Violation Points Accrued',
      accessor: 'viopaccr',
    },
    {
      Header: 'Remaining Uncorrected Violation Points',
      accessor: 'vioremain',
    },
    {
      Header: 'Violation Points Returned to Compliance by Formal Enforcement',
      accessor: 'viortcfea',
    },
    {
      Header: 'Violation Points Returned to Compliance Without Formal Enforcement',
      accessor: 'viortcnofea',
    },
    {
      Header: 'Zip Codes Served',
      accessor: 'zipcodesserved',
    },
  ], [])

  return (
    <FullScreenComp handle={fullscreenhandle}>
      <Col className='vio-report-container col-12'>
        <Row className="container-row vd-map-container">
          <div id='ViolationDataReport-report' className="map-container-vd">
            <ReportHeader
              global={global}
              data={reportData}
              param={id}
              reportID={"ViolationDataReport-report"}
              fullScreenClickHandle={fullscreenhandle.enter}
            />
            <Map
              ref={mapRef}
              preserveDrawingBuffer={true}
              mapboxAccessToken={settings.maboxKey}
              initialViewState={global.viewport}
             mapStyle={global.mapStyle}
              projection={global.globeView ? 'globe' : 'mercator' as any}
              onClick={(e) => {
                global.onMapClick(e)
              }}
              onMove={(e) => {
                global.setViewport({
                  longitude: e.viewState.longitude,
                  latitude: e.viewState.latitude,
                  zoom: e.viewState.zoom,
                })
              }}
            >
              <FullscreenControl />
              <RenderLayerMarkers global={global} bounds={bounds} zipOff={true} wq={true} />
              <RenderLayers global={global} mapRef={mapRef} />
              {id && (
                <Marker
                  latitude={currentPoint.latitude}
                  longitude={currentPoint.longitude}
                >
                  <img
                    width="48px"
                    style={{ cursor: "pointer" }}
                    src={`https://te-v3-assets.s3.us-west-2.amazonaws.com/${currentPoint.score}.svg`}
                    alt="score_icon"
                  />
                </Marker>
              )}
              <div className='map-legend-container'>
                <MapLegend
                  global={global}
                  legendWidth={320}
                />
              </div>
            </Map>
            {global.terraClimateLayerSelected | global.evapotranspirationSelected && (
              <div style={{
                position: 'absolute',
                top: "45%",
                right: "5%"
              }} className="range-slider">
                <Form.Range
                  min={2000}
                  max={2019}
                  value={global.MTCLValue}
                  onChange={e => global.setMTCLValue(parseInt(e.target.value))}
                />
                <strong>Year: {global.MTCLValue}</strong>
              </div>
            )}
            {global.NOAASeaLevelRise && (
              <div style={{
                position: 'absolute',
                top: "45%",
                right: "5%"
              }} className="range-slider range-slider-noaa">
                <Form.Range
                  min={0}
                  max={10}
                  value={global.feetIndex}
                  onChange={e => global.setFeetIndex(parseInt(e.target.value))}
                />
                <strong>Sea Level: {global.feetIndex}ft</strong>
              </div>
            )}
          </div>
        </Row>
        <Row className={`vio-table-container d-flex flex-column ${expanded ? 'vd-expanded' : ''}`}>
          <Col className='vio-button-box col-10'>
            {
              expanded ?

                <button onClick={() => setExpanded(false)} className="table-button">
                  <FontAwesomeIcon className="paginate-btn" icon={faMinimize} />
                </button>

                :

                <button onClick={() => setExpanded(true)} className="table-button">
                  <FontAwesomeIcon className="paginate-btn" icon={faMaximize} />
                </button>

            }
          </Col>
          <Col className="d-flex justify-content-center flex-column col-10 vd-tab-container">
            <Tabs className='d-flex justify-content-center w-100'>
              <Tab eventKey="table" title="Table">
                <div className={`vio-table ${expanded && 'table-expanded'}`}>
                  {sdwaData && (<ReportTable columns={columns1} data={sdwaData} />)}
                </div>
              </Tab>
              <Tab eventKey="graph" style={{ height: 'unset !important' }} id="graph" title="Graph" className={`vio-graph-container ${expanded && 'table-expanded'}`}>
                {/* @ts-ignore */}
                <div style={{ width: '60%' }}>
                  {graphOptions && graphData && <Bar data={graphData} options={graphOptions} />}
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
        <Col className="vio-footer col-12 d-flex justify-content-end">
          <br />
          <Col className="col-6 d-flex justify-content-end">
            <ReportFooter />
          </Col>
        </Col>
      </Col>
      <MapSlider global={global} />     
    </FullScreenComp>
  )
}

export default ViolationDataReport


