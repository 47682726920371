// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-input-transparency {
  width: 90%; }

.transparency-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100% !important;
  padding-left: 0 !important;
  position: absolute;
  top: 13rem;
  left: 3%; }
  .transparency-container .range-sliders {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
  .transparency-container .slider-container,
  .transparency-container .slider-wrapper,
  .transparency-container .slider-value {
    display: flex;
    justify-content: center;
    align-items: center; }
  .transparency-container .slider-wrapper {
    flex-direction: column;
    text-align: center; }
  .transparency-container .slider-value {
    padding: 8px;
    font-size: 18px;
    width: 50px; }
  .transparency-container .range-container {
    flex-direction: column; }
    .transparency-container .range-container p {
      margin-bottom: 0; }

.transparency-item {
  margin-top: 12px;
  font-size: 12px;
  border-radius: 4px;
  align-items: center;
  background: #002044;
  opacity: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 0.25fr;
  padding-bottom: 4px; }
  .transparency-item .layer-title {
    margin-left: 8px;
    padding: 8px;
    text-align: center; }
  .transparency-item .close-wrapper-box {
    justify-self: end;
    margin-right: 16px; }
`, "",{"version":3,"sources":["webpack://./src/layout/LayerTransparency/layer-transparency.scss"],"names":[],"mappings":"AAAA;EACE,UAAU,EAAA;;AAGZ;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,sBAAsB;EACtB,0BAA0B;EAC1B,kBAAkB;EAClB,UAAU;EACV,QAAQ,EAAA;EARV;IAWI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB,EAAA;EAd1B;;;IAoBI,aAAa;IACb,uBAAuB;IACvB,mBAAmB,EAAA;EAtBvB;IA0BI,sBAAsB;IACtB,kBAAkB,EAAA;EA3BtB;IA+BI,YAAY;IACZ,eAAe;IACf,WAAW,EAAA;EAjCf;IAqCI,sBAAsB,EAAA;IArC1B;MAsCQ,gBAAgB,EAAA;;AAIxB;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,qCAAqC;EACrC,mBAAmB,EAAA;EATrB;IAYI,gBAAgB;IAChB,YAAY;IACZ,kBAAkB,EAAA;EAdtB;IAkBI,iBAAiB;IACjB,kBAAkB,EAAA","sourcesContent":[".slider-input-transparency {\n  width: 90%;\n}\n\n.transparency-container {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  width: 100% !important;\n  padding-left: 0 !important;\n  position: absolute;\n  top: 13rem;\n  left: 3%;\n\n  .range-sliders {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n  }\n\n  .slider-container,\n  .slider-wrapper,\n  .slider-value {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  .slider-wrapper {\n    flex-direction: column;\n    text-align: center;\n  }\n\n  .slider-value {\n    padding: 8px;\n    font-size: 18px;\n    width: 50px;\n  }\n\n  .range-container {\n    flex-direction: column;\n    p { margin-bottom: 0;}\n  }\n}\n\n.transparency-item {\n  margin-top: 12px;\n  font-size: 12px;\n  border-radius: 4px;\n  align-items: center;\n  background: #002044;\n  opacity: 100%;\n  display: grid;\n  grid-template-columns: 1fr 2fr 0.25fr;\n  padding-bottom: 4px;\n\n  .layer-title {\n    margin-left: 8px;\n    padding: 8px;\n    text-align: center;\n  }\n\n  .close-wrapper-box {\n    justify-self: end;\n    margin-right: 16px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
