import {
    useEffect,
    useState,
    useMemo
} from 'react'
import {
    Col,
    Row,
    Button,
    Form
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPenToSquare
} from '@fortawesome/pro-solid-svg-icons';
import ReportTable from '../../../reports/shared/ReportTable'
import EditAdminRow from '../EditAdminRow'
import FormInputField from '../FormInputField'

const UserManagement = (props: any) => {

    const {
        orgData, 
        usersData
    } = props

    const [activeIndex, setActiveIndex] = useState()

    const tableColumns = useMemo(
        () => [
            {
                Header: 'User IDX',
                accessor: 'id',
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    // @ts-ignore
                    return <FormInputField
                        type="text"
                        currentIndex={index}
                        activeIndex={activeIndex && activeIndex}
                        value={props.value}
                    />
                }
            },
            {
                Header: 'User ID',
                accessor: 'user_id',
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    // @ts-ignore
                    return <FormInputField
                        type="text"
                        currentIndex={index}
                        activeIndex={activeIndex && activeIndex}
                        value={props.value}
                    />
                }
            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    // @ts-ignore
                    return <FormInputField
                        type="text"
                        currentIndex={index}
                        activeIndex={activeIndex && activeIndex}
                        value={props.value}
                    />
                }
            },
/*            {
                Header: 'LastName',
                accessor: 'LastName',
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    // @ts-ignore
                    return <FormInputField
                        type="text"
                        currentIndex={index}
                        activeIndex={activeIndex && activeIndex}
                        value={props.value}
                    />
                }
            },*/
            {
                Header: 'Email',
                accessor: 'email',
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    // @ts-ignore
                    return <FormInputField
                        type="text"
                        currentIndex={index}
                        activeIndex={activeIndex && activeIndex}
                        value={props.value}
                        style={{minWidth: 'max-content'}}
                    />
                }
            },
            {
                Header: 'Last Login',
                accessor: 'LastLogin',
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    // @ts-ignore
                    return <FormInputField
                        type="text"
                        currentIndex={index}
                        activeIndex={activeIndex && activeIndex}
                        value={props.value}
                    />
                }
            },
            {
                Header: 'Phone',
                accessor: 'Phone',
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    // @ts-ignore
                    return <FormInputField
                        type="text"
                        currentIndex={index}
                        activeIndex={activeIndex && activeIndex}
                        value={props.value}
                    />
                }
            },
            {
                Header: 'Role',
                accessor: 'role',
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    // @ts-ignore
                    return <FormInputField
                        type="text"
                        currentIndex={index}
                        activeIndex={activeIndex && activeIndex}
                        value={props.value}
                    />
                }
            },
            /*{
                Header: 'Created By',
                accessor: 'CreatedBy',
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    // @ts-ignore
                    return <FormInputField
                        type="text"
                        currentIndex={index}
                        activeIndex={activeIndex && activeIndex}
                        value={props.value}
                    />
                }
            },
            {
                Header: 'Created Date',
                accessor: 'CreatedDate',
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    // @ts-ignore
                    return <FormInputField
                        type="text"
                        currentIndex={index}
                        activeIndex={activeIndex && activeIndex}
                        value={props.value}
                    />
                }
            },
            {
                Header: 'Modified By',
                accessor: 'ModifiedBy',
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    // @ts-ignore
                    return <FormInputField
                        type="text"
                        currentIndex={index}
                        activeIndex={activeIndex && activeIndex}
                        value={props.value}
                    />
                }
            },
            {
                Header: 'Modified Date',
                accessor: 'ModifiedDate',
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    // @ts-ignore
                    return <FormInputField
                        type="text"
                        currentIndex={index}
                        activeIndex={activeIndex && activeIndex}
                        value={props.value}
                    />
                }
            },*/
            {
                Header: 'Active',
                accessor: 'is_active',
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                   
                    // @ts-ignore
                    return <Form.Check
                        style={{marginLeft: '30%'}}
                        type="checkbox"
                        defaultChecked={props.value}
                    />
                }
            },
            {
                Header: 'Actions',
                accessor: 'Actions',
                Cell: (props: any) => {
                    const {
                        index
                    } = props.row
                    // @ts-ignore
                    return <EditAdminRow
                        setActiveIndex={setActiveIndex}
                        currentIndex={index}
                    />
                }
            }

        ],
        [activeIndex]
    );


    return (
        <div className="admin-container">
            <div className="header-box">
                <h2>Manage Users</h2>
                <div className="button-box">
                    <Button id="addNew-btn" className="p-3 btn btn-primary m-2">
                        Add New
                    </Button>
                </div>
            </div>
            <div className="admin-settings-table">
                <Row style={{
                    paddingLeft: '12px',
                    paddingRight: '18px',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                }}>
                    <div className="danger-box">
                        <span className="warning-icon">
                            i
                        </span>
                        <span>
                            This page Lists all application users and allow you to add new users or edit an existing role.
                        </span>
                    </div>
                </Row>
                <ReportTable columns={tableColumns} data={usersData} />
            </div>
        </div>
    )
}

export default UserManagement