import { useState } from 'react';
import { Marker } from 'react-map-gl';

const ReportMarkers = (props: any) => {
  const { list } = props
  const [current, setCurrent] = useState<any | null>(null)
  const [pageNumber, setPageNumber] = useState<number>(0)



  const renderList = () => {
    return list && list.map((item: any, index: any) => {
  
      return (
        <Marker
          key={index}
          latitude={parseFloat(item.latitude)}
          longitude={parseFloat(item.longitude)}
          onClick={(e) => {
            e.originalEvent.stopPropagation();
            setCurrent(list[index])
            setPageNumber(0)
          }}
        >
          <div
            style={{
              cursor: "pointer",
              borderRadius: '50%',
              backgroundColor: 'teal',
              width: '18px',
              height: '18px',
              border: '3px solid white'
            }}
          >
          </div>
        </Marker>
      );
    })
  }


  return (
    <>
      {list && renderList()}
    </>
  )
}

export default ReportMarkers