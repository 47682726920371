import { FillLayer } from 'mapbox-gl';
import { useEffect, useMemo, useState } from 'react';
import { HUC12GeoPoint } from '../../../types/HUC12GeoPoint';
import MapLayer from '../../../types/MapLayer';
import MapSource from '../../../types/MapSource';
import { MarkerConfig } from '../../../types/MarkerConfig';
import { WaterWasteTrueQIReportModel } from '../../../types/WaterWasteTrueQIReportModel';
import SimpleMapAndTableReport from '../SimpleMapAndTableReport/SimpleMapAndTableReport';
import settings from './../../../settings.json';
import { parse } from 'papaparse';
import agent from '../../../api/agent';
import ColumnHeaderDefinition from '../../../types/columnHeaderDefinition';
import { formatRowValue } from '../../utils';

const WhatsInMySurfaceWater: React.FC<{ global: any }> = ({ global }) => {
  // TODO: Remove the use of global
  useEffect(() => {
    global.setUserLogged(true);
  }, []);

  const selectedHUC8Value = '14010001';

  const [markersConfigs, setMarkersConfigs] = useState<MarkerConfig[]>([]);
  const [huc12LonLatCatalogArray, setHuc12LonLatCatalogDataArray] = useState<
    any[]
  >([]);
  const [reportData, setReportData] = useState([]);
  const [isDataReportLoaded, setIsDataReportLoaded] = useState(false);
  const [huc12LayerFilterExpression, setHuc12LayerFilterExpression] = useState([
    'in',
    'HUC12',
  ]);

  const markersConfigBuilder = (reportData: WaterWasteTrueQIReportModel[]) => {
    const huc12DataArrayFromReportData = reportData.map((x) => ({
      huc12: x.huc12,
      score: Math.round(parseFloat(x.crb_pounds_score)),
    }));
    const markerConfigObjects: MarkerConfig[] = [];
    for (let huc12DataItem of huc12DataArrayFromReportData) {
      const searchResult = huc12LonLatCatalogArray.find(
        (catalogItem) => catalogItem.huc12 == huc12DataItem.huc12
      );
      if (searchResult !== undefined) {
        markerConfigObjects.push({
          score: huc12DataItem.score.toString(),
          geoPoint: {
            latitude: searchResult.centroid_latitude,
            longitude: searchResult.centroid_longitude,
          },
        });
      }
    }
    setMarkersConfigs(markerConfigObjects);
  };

  useEffect(() => {
    agent.Reports.WasteWaterTrueQIScores(selectedHUC8Value).then((res: any) => {
      const relatedHUC12Codes = res.body.map((x: any) => x.huc12);
      setHuc12LayerFilterExpression((oldState) =>
        [...oldState].concat(relatedHUC12Codes)
      );
      setReportData(res.body);

      const huc12FileURL = '/huc12s_lat_lon.csv';
      fetch(huc12FileURL)
        .then((response) => response.text())
        .then((responseText) => {
          const data: HUC12GeoPoint[] | any[] = parse(responseText, {
            header: true,
          }).data;
          setHuc12LonLatCatalogDataArray(data);
        });
    });
  }, []);

  useEffect(() => {
    markersConfigBuilder(reportData);
    setIsDataReportLoaded(true);
  }, [huc12LonLatCatalogArray]);

  const huc12sLayerPaint = {
    'fill-opacity': 0.9,
    'fill-outline-color': 'blue',
  };
  const huc12sLayer = new MapLayer(
    'True Elements - HUC 12s',
    'fill',
    huc12sLayerPaint
  );
  const huc12sSource = new MapSource(
    'vector',
    settings.tileSetURLs.HUC12,
    huc12sLayer as FillLayer,
    [...huc12LayerFilterExpression]
  );

  const huc8sLayerPaint = {
    'fill-opacity': 0.6,
    'fill-outline-color': 'black',
  };
  const huc8slayer = new MapLayer(
    'True Elements - huc8s',
    'fill',
    huc8sLayerPaint
  );
  const huc8sSource = new MapSource(
    'vector',
    settings.tileSetURLs.HUC8,
    huc8slayer as FillLayer,
    ['==', 'HUC8', selectedHUC8Value],
    huc12sSource
  );

  const tableColumns = useMemo(
    () => [
      {
        Header: 'State',
        accessor: 'state',
      },
      {
        Header: 'huc8',
        accessor: 'huc8',
      },
      {
        Header: 'huc12',
        accessor: 'huc12',
      },
      {
        Header: 'Toxic Weighted Pounds Equivalent State Score',
        accessor: 'state_twpe_score',
        Cell: (props: any) => formatRowValue(props.value),
      },
      {
        Header: 'Pounds State Score',
        accessor: 'state_pounds_score',
        Cell: (props: any) => formatRowValue(props.value),
      },
      {
        Header: 'Toxic Weighted Pounds Equivalent HUC8 Score',
        accessor: 'huc8_twpe_score',
        Cell: (props: any) => formatRowValue(props.value),
      },
      {
        Header: 'Pounds HUC8 Score',
        accessor: 'huc8_pounds_score',
        Cell: (props: any) => formatRowValue(props.value),
      },
      {
        Header: '	Toxic Weighted Pounds Equivalent CRB Score',
        accessor: 'crb_pounds_score',
        Cell: (props: any) => formatRowValue(props.value),
      },
      {
        Header: 'Pounds CRB Score',
        accessor: 'crb_twpe_score',
        Cell: (props: any) => formatRowValue(props.value),
      },
    ],
    []
  );

  const memoizedData = useMemo(() => reportData, [reportData]);
  return (
    <SimpleMapAndTableReport
      title="What's in my Watershed?"
      source={huc8sSource}
      markersConfigs={markersConfigs}
      reportData={memoizedData}
      columns={tableColumns}
    />
  );
};

export default WhatsInMySurfaceWater;
