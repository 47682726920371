import {
  useMemo,
  useState,
  useEffect
} from 'react'
import ReportTableExpanded from '../../../reports/shared/ReportTableExpanded'
import {
  Button,
  Form,
  Row, Col, Popover, OverlayTrigger,
  FloatingLabel
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import {
  faPenToSquare,
  faTrashCan,
  faCalendar
} from '@fortawesome/pro-solid-svg-icons';
import AddActivities from '../AddActivities';
import { exportToCsv } from '../../../utils';
import { submitWQX, deleteWQX } from '../lib/data';
import WM_tablekeys from '../WM_tablekeys.json';


const ActivitiesTable = (props: any) => {

  const {
    activities,
    setActivities,
    addActivities,
    action, 
    setAction,
    setAddActivities,
    modalOpen,
    setModalOpen,
    modalList,
    setModalList,
    data,
    setData,
    activitiesData,
    setActivitiesData,
    mLocsData,
    orgCredentialsType
  } = props

  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate()-41115)));
  const [endDate, setEndDate] = useState(new Date());
  const [monitoringLocation, setMonitoringLocation] = useState('')

  const [filteredData, setFilteredData] = useState(activitiesData)

  const [currentRow, setCurrentRow] = useState();
  const [deleteCurrentRow, setDeleteCurrentRow] = useState(false);
  const [wqxCurrentRow, setWqxCurrentRow] = useState(false);  

  const [tableColumns, setTableColumns] = useState < any[] > ([]);



  const handleFilter = (event: any) => {
    //exportToCsv(data, 'Activities ');
  }

  const applyFilter = () => {
    let filterData = [...activitiesData]; 
    if (startDate && endDate) {
      filterData = filterData.filter((o: any) => (o["ACTIVITYDesc_ActivityEndDate"] > startDate && o["ACTIVITYDesc_ActivityEndDate"] < endDate) );
    }
    setFilteredData(filterData)
    return filterData;
  }

  const handleExport = (event: any) => {
    exportToCsv(filteredData, 'Activities ');
  }

  const updateMon = (value: any) => {
    setMonitoringLocation(value)
    if(value){

    } else {

    }
  }



    useEffect(() => {
      if(deleteCurrentRow){
          deleteRow(currentRow);
          setDeleteCurrentRow(false);
      }
    }, [deleteCurrentRow])

    useEffect(() => {
      if(wqxCurrentRow){
          wqxRow(currentRow);
          setWqxCurrentRow(false);
      }
    }, [wqxCurrentRow])

    const handleRow = (props: any) => {
        setCurrentRow(props.row.original)
    }

    const handleSubmit = (row: any) => {
        setWqxCurrentRow(true)     
    }

    const wqxRow = (row: any) => {
        if(row && row.id){
            submitWQX(row, handleSubmitReturn, true, row);
        }
    } 

    const handleSubmitReturn = (success: any, result: any, err?: any, row?:any) => {
        let initialData = [...activitiesData];
        if(row && row.id){
            let filterData = initialData.filter((o: any) => (o["id"] === row.id) ); 
            if(filterData && filterData.length > 0){
                //console.log(success, result, err, filterData);
            }           
        }
    }

    const handleDelete = (props: any, e:any) => {
        setDeleteCurrentRow(true)
    }

    const deleteRow = (row: any) => {
        if(row && row.id){
            deleteWQX(row.id, handleDeleteReturn);
            let initialData = [...activitiesData];
            let filterData = initialData.filter((o: any) => (o["id"] !== row.id) ); 
            if(filterData && filterData.length > 0){
                setActivitiesData(filterData);
            }
        }
    }

    const handleDeleteReturn = (result: any) => {
          
    }

    const findVal = (v:any) => {
        //@ts-ignore
      return Object.keys(WM_tablekeys).filter((k) => WM_tablekeys[k].includes(v))
    }

    const translateField = (fieldname: any) => {
        let valSearch = findVal(fieldname);
        if(valSearch && valSearch.length>0){
            return valSearch[0];
        } else {
            return fieldname;
        } 
    }

  useEffect(() => {
      let tableCol = [];

        if(orgCredentialsType !== 'none'){
          tableCol.push({
                Header: 'Send to EPA',
                accessor: 'sendToEPA',
                //@ts-ignore
                Cell: (props: any) => (
                    <div >
                        <OverlayTrigger trigger="click" placement="right" overlay={popoverWQX} rootClose={true}>
                            <div className="action-box">
                                <Form.Check
                                    value={props.value}
                                    onClick={() => {
                                        handleRow(props)        
                                    }}
                                />
                            </div>
                        </OverlayTrigger>
                    </div>
                ),
            })
        }

        tableCol.push(  {
                Header: 'Edit',
                accessor: 'actions',
                size:70,
                //@ts-ignore
                Cell: (props: any) => {
                    return (
                        <div className="action-box">
                            <div
                                onClick={() => {
                                    setAction('edit')
                                    setData(props.row.original)
                                }}
                                className='action-cell'>
                                <FontAwesomeIcon 
                                    onClick={() => {
                                        setAction('edit')
                                        setData(props.row.original)
                                    }} 
                                    style={{ color: '#4fc2d9', cursor: 'pointer' }} 
                                    icon={faPenToSquare} />
                            </div>
                        </div>
                    )
                }
            }, {
                Header: 'Delete',
                accessor: 'actions_',
                size:70,
                //@ts-ignore
                Cell: (props: any) => {
                    return (
                        <div className="action-box">
                            <OverlayTrigger       
                              trigger="click" placement="right" overlay={popover} rootClose={true}>
                            <div
                                onClick={() => {handleRow(props)}}
                                className='action-cell'>
                                <FontAwesomeIcon style={{ marginLeft:18, color: '#4fc2d9', cursor: 'pointer' }} 
                                icon={faTrashCan} />
                            </div>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }, { 
                Header: '',
                accessor: '  ',
                //@ts-ignore
                Cell: (props: any) => (
                    <div>

                    </div>
                ),
            },);



      tableCol.push(
            {
              Header: 'ID',
              accessor: 'ACTIVITYDesc_ActivityIdentifier',
            },
            {
              Header: 'Type',
              accessor: 'ACTIVITYDesc_ActivityTypeCode',
            },
            {
              Header: 'Media',
              accessor: 'ACTIVITYDesc_ActivityMediaName',
            },
            {
              Header: 'Sub Media',
              accessor: 'ACTIVITYDesc_ActivityMediaSubDivisionName',
            },
            {
              Header: 'Activity Start Date',
              accessor: 'ACTIVITYDesc_ActivityStartDate',
            },
            {
              Header: 'Activity End Date',
              accessor: 'ACTIVITYDesc_ActivityEndDate',
            },
            {
              Header: 'Comment',
              accessor: 'ACTIVITYDesc_ActivityCommentText',
            });

      if(modalList && modalList.length > 0){
        for (let dataItem of modalList) {
            if(dataItem.active){
                tableCol.push( {
                   Header: dataItem.field,
                   accessor: translateField(dataItem.field),
                });
            }
        }
      }
      setTableColumns(tableCol)
  }, [modalList])




    const popover = (
      <Popover id="popover-delete" className="wm-popover"  >
        <Popover.Header as="h3"  className="wm-popover-header">Delete</Popover.Header>
        <Popover.Body>
          Are you sure?
                <div>
                    <Button id="delete-delete-btn" 
                        onClick={(e) => {
                            handleDelete(props, e); 
                            document.body.click();                 
                        }}
                        className="p-3 btn m-2">
                        Delete
                    </Button>
                    <Button id="delete-cancel-btn" 
                        onClick={() => {
                            document.body.click();
                        }}
                        className="p-3 btn m-2">
                        Cancel
                    </Button>
                </div>
        </Popover.Body>
      </Popover>
    );

    const popoverWQX = (
      <Popover id="popover-wqx" className="wm-popover">
        <Popover.Header as="h3" className="wm-popover-header">WQX Submission</Popover.Header>
        <Popover.Body>
          Send to EPA now?
                <div>
                    <Button id="delete-delete-btn" 
                        onClick={(e) => {
                              handleSubmit(props);
                              document.body.click();
                        }}
                        className="p-3 btn">
                        Yes
                    </Button>
                    <Button id="delete-cancel-btn" 
                        onClick={() => {
                             document.body.click();
                        }}
                        className="p-3 m-2">
                        Cancel
                    </Button>
                </div>
        </Popover.Body>
      </Popover>
    );




  return (
    <Row className='activities-container'>
      <div className="header-box">
        <h2>Activities (Samples)</h2>
        <div className="button-box" style={{whiteSpace: 'nowrap'}}>
          <Button onClick={() => {
              setModalList(modalList)
              setModalOpen(true)  
          }} id="export-btn" className="p-3 btn btn-outline-primary m-2">
            Settings
          </Button>
          <Button id="export-btn" onClick={handleExport} className="p-3 btn btn-outline-primary m-2">
            Export CSV
          </Button>
          <Button
            id="addNew-btn"
            onClick={() => {
/*              setActivities(false)
              setAddActivities(true)*/
              setAction('create')
            }}
            className="p-3 btn btn-primary m-2"
          >
            Add New
          </Button>
        </div>
      </div>
      <Row id="filter-box" style={{ marginRight: '0' }}>
        <div className="filter-box">
          <div className="drop-box">
            <h4>Filters:</h4>
                <Form.Group className="" controlId="floatingMonitoringLocationType" style={{marginLeft:5}} >
                    <FloatingLabel controlId="floatingMonitoringLocationType" label="Monitoring Locations">
                        <Form.Select  onChange={(e: any) => updateMon(e.target.value)}
                         size="lg" aria-label="MonitoringLocIdent_MonitoringLocations" >
                         <option></option>
                            {mLocsData && mLocsData.sort((x: any, y: any) => x["MonitoringLocIdent_MonitoringLocationIdentifier"].localeCompare(y["MonitoringLocIdent_MonitoringLocationIdentifier"])).map((obj:any, index:any) =>
                                (<option key={'wm-proj-options'+index} value={obj["MonitoringLocIdent_MonitoringLocationIdentifier"]}>{obj["MonitoringLocIdent_MonitoringLocationIdentifier"] + ' : ' + obj["MonitoringLocIdent_MonitoringLocationName"] }</option>)
                            )} 
                        </Form.Select>
                    </FloatingLabel> 
                </Form.Group>
          </div>
          <div className="date-range-box">
            <div className="date-box">
              <p id="from">From</p><DatePicker selected={startDate} onChange={(date: Date) => setStartDate(date)} />
              <FontAwesomeIcon icon={faCalendar} />
            </div>
            <div className="date-box">
              <p id="to">To</p><DatePicker selected={endDate} onChange={(date: Date) => setEndDate(date)} />
              <FontAwesomeIcon icon={faCalendar} />
            </div>
            <Button onClick={handleFilter} style={{ width: '8rem' }} id="addNew-btn" className="p-3 btn btn-primary m-2">
              Apply
            </Button>
          </div>
        </div>
      </Row>
      <ReportTableExpanded columns={tableColumns} data={filteredData} />
    </Row>
  )
}

export default ActivitiesTable