const TestFeatures = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.4931404,
                    34.3127783
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-10278300",
                "MonitoringLocationName": "LOS ANGELES AQUED AT OUTLET AT SAN FERNANDO CA",
                "MonitoringLocationTypeName": "Stream",
                "ResolvedMonitoringLocationTypeName": "Stream",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-10278300/",
                "activityCount": "11",
                "resultCount": "11",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.4931404,
                "centroid_latitude": 34.3127783,
                "layerName": "custom",
                "activity": 11,
                "result": 11
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.768947,
                    34.2305606
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-11080000",
                "MonitoringLocationName": "EF SAN GABRIEL R A CAMP BONITA CA",
                "MonitoringLocationTypeName": "Stream",
                "ResolvedMonitoringLocationTypeName": "Stream",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-11080000/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -117.768947,
                "centroid_latitude": 34.2305606,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.9578424,
                    34.2441699
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-11080880",
                "MonitoringLocationName": "WF SAN GABRIEL R BL COGSWELL DAM CA",
                "MonitoringLocationTypeName": "Stream",
                "ResolvedMonitoringLocationTypeName": "Stream",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-11080880/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -117.9578424,
                "centroid_latitude": 34.2441699,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1775727,
                    34.2222263
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-11098000",
                "MonitoringLocationName": "ARROYO SECO NR PASADENA CA",
                "MonitoringLocationTypeName": "Stream",
                "ResolvedMonitoringLocationTypeName": "Stream",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-11098000/",
                "activityCount": "4",
                "resultCount": "4",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1775727,
                "centroid_latitude": 34.2222263,
                "layerName": "custom",
                "activity": 4,
                "result": 4
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.214925,
                    33.7460972
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334445118125301",
                "MonitoringLocationName": "005S013W11P001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334445118125301/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.214925,
                "centroid_latitude": 33.7460972,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.214925,
                    33.7460972
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334445118125302",
                "MonitoringLocationName": "005S013W11P002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334445118125302/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.214925,
                "centroid_latitude": 33.7460972,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2205222,
                    33.7709389
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334615118131001",
                "MonitoringLocationName": "005S013W02E001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334615118131001/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2205222,
                "centroid_latitude": 33.7709389,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2205222,
                    33.7709389
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334615118131002",
                "MonitoringLocationName": "005S013W02E002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334615118131002/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2205222,
                "centroid_latitude": 33.7709389,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2676667,
                    33.7823306
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334657118160001",
                "MonitoringLocationName": "004S013W32F001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334657118160001/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2676667,
                "centroid_latitude": 33.7823306,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2676667,
                    33.7823306
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334657118160002",
                "MonitoringLocationName": "004S013W32F002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334657118160002/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2676667,
                "centroid_latitude": 33.7823306,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2676667,
                    33.7823306
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334657118160003",
                "MonitoringLocationName": "004S013W32F003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334657118160003/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2676667,
                "centroid_latitude": 33.7823306,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2676667,
                    33.7823306
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334657118160004",
                "MonitoringLocationName": "004S013W32F004S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334657118160004/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2676667,
                "centroid_latitude": 33.7823306,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2676667,
                    33.7823306
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334657118160005",
                "MonitoringLocationName": "004S013W32F005S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334657118160005/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2676667,
                "centroid_latitude": 33.7823306,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.172136,
                    33.7936917
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334737118101905",
                "MonitoringLocationName": "004S012W30J005S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334737118101905/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.172136,
                "centroid_latitude": 33.7936917,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2232639,
                    33.7964194
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334747118132301",
                "MonitoringLocationName": "004S013W27H004S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334747118132301/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2232639,
                "centroid_latitude": 33.7964194,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2232639,
                    33.7964194
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334747118132303",
                "MonitoringLocationName": "004S013W27H006S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334747118132303/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2232639,
                "centroid_latitude": 33.7964194,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2389028,
                    33.8005694
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334802118141801",
                "MonitoringLocationName": "004S013W28A003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334802118141801/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2389028,
                "centroid_latitude": 33.8005694,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2389028,
                    33.8005694
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334802118141802",
                "MonitoringLocationName": "004S013W28A004S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334802118141802/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2389028,
                "centroid_latitude": 33.8005694,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2389028,
                    33.8005694
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334802118141803",
                "MonitoringLocationName": "004S013W28A005S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334802118141803/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2389028,
                "centroid_latitude": 33.8005694,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2389028,
                    33.8005694
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334802118141804",
                "MonitoringLocationName": "004S013W28A006S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334802118141804/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2389028,
                "centroid_latitude": 33.8005694,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2389028,
                    33.8005694
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334802118141805",
                "MonitoringLocationName": "004S013W28A007S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334802118141805/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2389028,
                "centroid_latitude": 33.8005694,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.314,
                    33.8046444
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334815118184701",
                "MonitoringLocationName": "004S014W26A002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334815118184701/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.314,
                "centroid_latitude": 33.8046444,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.314,
                    33.8046444
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334815118184702",
                "MonitoringLocationName": "004S014W26A003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334815118184702/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.314,
                "centroid_latitude": 33.8046444,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.314,
                    33.8046444
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334815118184704",
                "MonitoringLocationName": "004S014W26A005S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334815118184704/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.314,
                "centroid_latitude": 33.8046444,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.271402,
                    33.8073563
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334826118161003",
                "MonitoringLocationName": "004S013W20N003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334826118161003/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.271402,
                "centroid_latitude": 33.8073563,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1492,
                    33.810425
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334837118085701",
                "MonitoringLocationName": "004S012W21M008S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334837118085701/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1492,
                "centroid_latitude": 33.810425,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1492,
                    33.810425
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334837118085704",
                "MonitoringLocationName": "004S012W21M011S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334837118085704/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1492,
                "centroid_latitude": 33.810425,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.342686,
                    33.8291194
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334944118203301",
                "MonitoringLocationName": "004S014W15E001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334944118203301/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.342686,
                "centroid_latitude": 33.8291194,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.342686,
                    33.8291194
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-334944118203304",
                "MonitoringLocationName": "004S014W15E004S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-334944118203304/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.342686,
                "centroid_latitude": 33.8291194,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.241111,
                    33.8369444
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335013118142501",
                "MonitoringLocationName": "004S013W09H009S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335013118142501/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.241111,
                "centroid_latitude": 33.8369444,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.241111,
                    33.8369444
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335013118142502",
                "MonitoringLocationName": "004S013W09H010S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335013118142502/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.241111,
                "centroid_latitude": 33.8369444,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.241111,
                    33.8369444
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335013118142503",
                "MonitoringLocationName": "004S013W09H011S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335013118142503/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.241111,
                "centroid_latitude": 33.8369444,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.241111,
                    33.8369444
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335013118142504",
                "MonitoringLocationName": "004S013W09H012S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335013118142504/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.241111,
                "centroid_latitude": 33.8369444,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.201111,
                    33.85
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335100118120401",
                "MonitoringLocationName": "004S013W01N003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335100118120401/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.201111,
                "centroid_latitude": 33.85,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.201111,
                    33.85
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335100118120404",
                "MonitoringLocationName": "004S013W01N006S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335100118120404/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.201111,
                "centroid_latitude": 33.85,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1522583,
                    33.8535917
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335112118090401",
                "MonitoringLocationName": "004S012W05H005S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335112118090401/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1522583,
                "centroid_latitude": 33.8535917,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1522583,
                    33.8535917
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335112118090402",
                "MonitoringLocationName": "004S012W05H006S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335112118090402/",
                "activityCount": "6",
                "resultCount": "6",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1522583,
                "centroid_latitude": 33.8535917,
                "layerName": "custom",
                "activity": 6,
                "result": 6
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1522583,
                    33.8535917
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335112118090403",
                "MonitoringLocationName": "004S012W05H007S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335112118090403/",
                "activityCount": "6",
                "resultCount": "6",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1522583,
                "centroid_latitude": 33.8535917,
                "layerName": "custom",
                "activity": 6,
                "result": 6
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1522583,
                    33.8535917
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335112118090404",
                "MonitoringLocationName": "004S012W05H008S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335112118090404/",
                "activityCount": "4",
                "resultCount": "4",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1522583,
                "centroid_latitude": 33.8535917,
                "layerName": "custom",
                "activity": 4,
                "result": 4
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1522583,
                    33.8535917
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335112118090405",
                "MonitoringLocationName": "004S012W05H009S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335112118090405/",
                "activityCount": "4",
                "resultCount": "4",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1522583,
                "centroid_latitude": 33.8535917,
                "layerName": "custom",
                "activity": 4,
                "result": 4
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1522583,
                    33.8535917
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335112118090406",
                "MonitoringLocationName": "004S012W05H010S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335112118090406/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1522583,
                "centroid_latitude": 33.8535917,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.152361,
                    33.8534167
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335113118090801",
                "MonitoringLocationName": "004S012W05J001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335113118090801/",
                "activityCount": "13",
                "resultCount": "13",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.152361,
                "centroid_latitude": 33.8534167,
                "layerName": "custom",
                "activity": 13,
                "result": 13
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.266361,
                    33.8572778
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335126118155801",
                "MonitoringLocationName": "004S013W05F001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335126118155801/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.266361,
                "centroid_latitude": 33.8572778,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.266361,
                    33.8572778
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335126118155803",
                "MonitoringLocationName": "004S013W05F003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335126118155803/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.266361,
                "centroid_latitude": 33.8572778,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1342204,
                    33.868935
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335209118080101",
                "MonitoringLocationName": "003S012W33A007S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335209118080101/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1342204,
                "centroid_latitude": 33.868935,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2998556,
                    33.8765556
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335235118175903",
                "MonitoringLocationName": "003S014W25K009S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335235118175903/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2998556,
                "centroid_latitude": 33.8765556,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2998556,
                    33.8765556
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335235118175904",
                "MonitoringLocationName": "003S014W25K010S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335235118175904/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2998556,
                "centroid_latitude": 33.8765556,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3893333,
                    33.8766667
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335236118232101",
                "MonitoringLocationName": "003S014W30L002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070104",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335236118232101/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3893333,
                "centroid_latitude": 33.8766667,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3893333,
                    33.8766667
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335236118232105",
                "MonitoringLocationName": "003S014W30L006S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070104",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335236118232105/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3893333,
                "centroid_latitude": 33.8766667,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2266889,
                    33.8816583
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335302118072201",
                "MonitoringLocationName": "003S013W27G001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335302118072201/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2266889,
                "centroid_latitude": 33.8816583,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.242294,
                    33.8872385
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335314118142901",
                "MonitoringLocationName": "003S013W21R003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335314118142901/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.242294,
                "centroid_latitude": 33.8872385,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3771389,
                    33.8870833
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335314118223801",
                "MonitoringLocationName": "003S014W29D005S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335314118223801/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3771389,
                "centroid_latitude": 33.8870833,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2930528,
                    33.9089694
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335431118173101",
                "MonitoringLocationName": "003S014W13J005S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335431118173101/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2930528,
                "centroid_latitude": 33.9089694,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2930528,
                    33.9089694
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335431118173102",
                "MonitoringLocationName": "003S014W13J006S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335431118173102/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2930528,
                "centroid_latitude": 33.9089694,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.138361,
                    33.9108333
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335440118081101",
                "MonitoringLocationName": "003S012W16H001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335440118081101/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.138361,
                "centroid_latitude": 33.9108333,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1902732,
                    33.9211652
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335515118112101",
                "MonitoringLocationName": "003S013W12J001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335515118112101/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1902732,
                "centroid_latitude": 33.9211652,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1379944,
                    33.9213111
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335517118081301",
                "MonitoringLocationName": "003S012W09J001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335517118081301/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1379944,
                "centroid_latitude": 33.9213111,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1379944,
                    33.9213111
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335517118081302",
                "MonitoringLocationName": "003S012W09J002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335517118081302/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1379944,
                "centroid_latitude": 33.9213111,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1379944,
                    33.9213111
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335517118081303",
                "MonitoringLocationName": "003S012W09J003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335517118081303/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1379944,
                "centroid_latitude": 33.9213111,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1379944,
                    33.9213111
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335517118081304",
                "MonitoringLocationName": "003S012W09J004S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335517118081304/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1379944,
                "centroid_latitude": 33.9213111,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1379944,
                    33.9213111
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335517118081305",
                "MonitoringLocationName": "003S012W09J005S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335517118081305/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1379944,
                "centroid_latitude": 33.9213111,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1379944,
                    33.9213111
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335517118081306",
                "MonitoringLocationName": "003S012W09J006S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335517118081306/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1379944,
                "centroid_latitude": 33.9213111,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.116179,
                    33.9247366
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335529118065501",
                "MonitoringLocationName": "003S012W11E001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335529118065501/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.116179,
                "centroid_latitude": 33.9247366,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3445833,
                    33.9319444
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335555118204001",
                "MonitoringLocationName": "003S014W04R001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335555118204001/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3445833,
                "centroid_latitude": 33.9319444,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3445833,
                    33.9319444
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335555118204003",
                "MonitoringLocationName": "003S014W04R003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335555118204003/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3445833,
                "centroid_latitude": 33.9319444,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3445833,
                    33.9319444
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335555118204005",
                "MonitoringLocationName": "003S014W04R005S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335555118204005/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3445833,
                "centroid_latitude": 33.9319444,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1768667,
                    33.9448889
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335642118103702",
                "MonitoringLocationName": "003S012W06B005S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335642118103702/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1768667,
                "centroid_latitude": 33.9448889,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1768667,
                    33.9448889
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335642118103703",
                "MonitoringLocationName": "003S012W06B006S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335642118103703/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1768667,
                "centroid_latitude": 33.9448889,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1768667,
                    33.9448889
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335642118103704",
                "MonitoringLocationName": "003S012W06B007S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335642118103704/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1768667,
                "centroid_latitude": 33.9448889,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1768667,
                    33.9448889
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335642118103705",
                "MonitoringLocationName": "003S012W06B008S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335642118103705/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1768667,
                "centroid_latitude": 33.9448889,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.184361,
                    33.9451111
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335643118105801",
                "MonitoringLocationName": "003S012W06D004S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335643118105801/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.184361,
                "centroid_latitude": 33.9451111,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0976917,
                    33.9526889
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335712118054901",
                "MonitoringLocationName": "002S012W36M006S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335712118054901/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0976917,
                "centroid_latitude": 33.9526889,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1710778,
                    33.9553
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335719118101501",
                "MonitoringLocationName": "002S012W31H002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335719118101501/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1710778,
                "centroid_latitude": 33.9553,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0650278,
                    33.9620556
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335744118035101",
                "MonitoringLocationName": "002S011W30R003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335744118035101/",
                "activityCount": "4",
                "resultCount": "4",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0650278,
                "centroid_latitude": 33.9620556,
                "layerName": "custom",
                "activity": 4,
                "result": 4
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1351526,
                    33.9646417
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335752118080201",
                "MonitoringLocationName": "002S012W28J006S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335752118080201/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1351526,
                "centroid_latitude": 33.9646417,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.386861,
                    33.9820278
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335800118230001",
                "MonitoringLocationName": "002S014W19G001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070104",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335800118230001/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.386861,
                "centroid_latitude": 33.9820278,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3596639,
                    33.9669667
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335801118213101",
                "MonitoringLocationName": "002S014W28M003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070104",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335801118213101/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3596639,
                "centroid_latitude": 33.9669667,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3596639,
                    33.9669667
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335801118213103",
                "MonitoringLocationName": "002S014W28M005S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070104",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335801118213103/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3596639,
                "centroid_latitude": 33.9669667,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0876012,
                    33.9714522
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335817118051301",
                "MonitoringLocationName": "002S012W25G001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335817118051301/",
                "activityCount": "4",
                "resultCount": "4",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0876012,
                "centroid_latitude": 33.9714522,
                "layerName": "custom",
                "activity": 4,
                "result": 4
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0877806,
                    33.9714556
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335818118051201",
                "MonitoringLocationName": "002S012W25G003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335818118051201/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0877806,
                "centroid_latitude": 33.9714556,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0875,
                    33.9716667
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335818118051202",
                "MonitoringLocationName": "002S012W25G004S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335818118051202/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0875,
                "centroid_latitude": 33.9716667,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0875,
                    33.9716667
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335818118051203",
                "MonitoringLocationName": "002S012W25G005S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335818118051203/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0875,
                "centroid_latitude": 33.9716667,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0875,
                    33.9716667
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335818118051204",
                "MonitoringLocationName": "002S012W25G006S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335818118051204/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0875,
                "centroid_latitude": 33.9716667,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0875,
                    33.9716667
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335818118051205",
                "MonitoringLocationName": "002S012W25G007S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335818118051205/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0875,
                "centroid_latitude": 33.9716667,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1154389,
                    33.974825
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335829118065201",
                "MonitoringLocationName": "002S012W26D009S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335829118065201/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1154389,
                "centroid_latitude": 33.974825,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1154389,
                    33.974825
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335829118065202",
                "MonitoringLocationName": "002S012W26D010S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335829118065202/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1154389,
                "centroid_latitude": 33.974825,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1154389,
                    33.974825
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335829118065203",
                "MonitoringLocationName": "002S012W26D011S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335829118065203/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1154389,
                "centroid_latitude": 33.974825,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1154389,
                    33.974825
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335829118065204",
                "MonitoringLocationName": "002S012W26D012S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335829118065204/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1154389,
                "centroid_latitude": 33.974825,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1154389,
                    33.974825
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335829118065205",
                "MonitoringLocationName": "002S012W26D013S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335829118065205/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1154389,
                "centroid_latitude": 33.974825,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1154389,
                    33.974825
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335829118065206",
                "MonitoringLocationName": "002S012W26D014S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335829118065206/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1154389,
                "centroid_latitude": 33.974825,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.185861,
                    33.9942222
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335938118110501",
                "MonitoringLocationName": "002S012W18M001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335938118110501/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.185861,
                "centroid_latitude": 33.9942222,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042205",
                "MonitoringLocationName": "002S011W18L014S A INTRVL",
                "MonitoringLocationTypeName": "Well: Test hole not completed as a well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042205/",
                "activityCount": "23",
                "resultCount": "23",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 23,
                "result": 23
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042206",
                "MonitoringLocationName": "002S011W18L014S B INTRVL",
                "MonitoringLocationTypeName": "Well: Test hole not completed as a well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042206/",
                "activityCount": "5",
                "resultCount": "5",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 5,
                "result": 5
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042207",
                "MonitoringLocationName": "002S011W18L014S C INTRVL",
                "MonitoringLocationTypeName": "Well: Test hole not completed as a well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042207/",
                "activityCount": "4",
                "resultCount": "4",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 4,
                "result": 4
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042208",
                "MonitoringLocationName": "002S011W18L014S D INTRVL",
                "MonitoringLocationTypeName": "Well: Test hole not completed as a well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042208/",
                "activityCount": "4",
                "resultCount": "4",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 4,
                "result": 4
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042209",
                "MonitoringLocationName": "002S011W18L014S E INTRVL",
                "MonitoringLocationTypeName": "Well: Test hole not completed as a well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042209/",
                "activityCount": "7",
                "resultCount": "7",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 7,
                "result": 7
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042210",
                "MonitoringLocationName": "002S011W18L014S F INTRVL",
                "MonitoringLocationTypeName": "Well: Test hole not completed as a well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042210/",
                "activityCount": "14",
                "resultCount": "14",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 14,
                "result": 14
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042211",
                "MonitoringLocationName": "002S011W18L014S G INTRVL",
                "MonitoringLocationTypeName": "Well: Test hole not completed as a well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042211/",
                "activityCount": "5",
                "resultCount": "5",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 5,
                "result": 5
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042212",
                "MonitoringLocationName": "002S011W18L014S H INTRVL",
                "MonitoringLocationTypeName": "Well: Test hole not completed as a well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042212/",
                "activityCount": "5",
                "resultCount": "5",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 5,
                "result": 5
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042213",
                "MonitoringLocationName": "002S011W18L014S I INTRVL",
                "MonitoringLocationTypeName": "Well: Test hole not completed as a well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042213/",
                "activityCount": "9",
                "resultCount": "9",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 9,
                "result": 9
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042214",
                "MonitoringLocationName": "002S011W18L014S J INTRVL",
                "MonitoringLocationTypeName": "Well: Test hole not completed as a well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042214/",
                "activityCount": "8",
                "resultCount": "8",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 8,
                "result": 8
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042215",
                "MonitoringLocationName": "002S011W18L014S K INTRVL",
                "MonitoringLocationTypeName": "Well: Test hole not completed as a well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042215/",
                "activityCount": "17",
                "resultCount": "17",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 17,
                "result": 17
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042216",
                "MonitoringLocationName": "002S011W18L014S L INTRVL",
                "MonitoringLocationTypeName": "Well: Test hole not completed as a well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042216/",
                "activityCount": "6",
                "resultCount": "6",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 6,
                "result": 6
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042217",
                "MonitoringLocationName": "002S011W18L014S M INTRVL",
                "MonitoringLocationTypeName": "Well: Test hole not completed as a well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042217/",
                "activityCount": "6",
                "resultCount": "6",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 6,
                "result": 6
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042218",
                "MonitoringLocationName": "002S011W18L014S N INTRVL",
                "MonitoringLocationTypeName": "Well: Test hole not completed as a well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042218/",
                "activityCount": "7",
                "resultCount": "7",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 7,
                "result": 7
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042219",
                "MonitoringLocationName": "002S011W18L014S O INTRVL",
                "MonitoringLocationTypeName": "Well: Test hole not completed as a well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042219/",
                "activityCount": "6",
                "resultCount": "6",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 6,
                "result": 6
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042220",
                "MonitoringLocationName": "002S011W18L014S P INTRVL",
                "MonitoringLocationTypeName": "Well: Test hole not completed as a well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042220/",
                "activityCount": "15",
                "resultCount": "15",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 15,
                "result": 15
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042221",
                "MonitoringLocationName": "002S011W18L014S Q INTRVL",
                "MonitoringLocationTypeName": "Well: Test hole not completed as a well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042221/",
                "activityCount": "5",
                "resultCount": "5",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 5,
                "result": 5
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042224",
                "MonitoringLocationName": "002S011W18L016SLYS",
                "MonitoringLocationTypeName": "Well: Test hole not completed as a well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042224/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042242",
                "MonitoringLocationName": "002S011W18L027S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042242/",
                "activityCount": "12",
                "resultCount": "12",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 12,
                "result": 12
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042243",
                "MonitoringLocationName": "002S011W18L028S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042243/",
                "activityCount": "17",
                "resultCount": "17",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 17,
                "result": 17
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042244",
                "MonitoringLocationName": "002S011W18L029S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042244/",
                "activityCount": "10",
                "resultCount": "10",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 10,
                "result": 10
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042245",
                "MonitoringLocationName": "002S011W18L030S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042245/",
                "activityCount": "11",
                "resultCount": "11",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 11,
                "result": 11
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.07369,
                    35.9952227
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042246",
                "MonitoringLocationName": "POND AT PICO TEST SITE NR PICO RIVERA CA",
                "MonitoringLocationTypeName": "Facility: Wastewater land application",
                "ResolvedMonitoringLocationTypeName": "Facility",
                "HUCEightDigitCode": "18030002",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042246/",
                "activityCount": "30",
                "resultCount": "30",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.07369,
                "centroid_latitude": 35.9952227,
                "layerName": "custom",
                "activity": 30,
                "result": 30
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042247",
                "MonitoringLocationName": "SEWAGE EFF AT PICO TEST SITE NR PICO RIVERA CA",
                "MonitoringLocationTypeName": "Facility: Outfall",
                "ResolvedMonitoringLocationTypeName": "Facility",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042247/",
                "activityCount": "7",
                "resultCount": "7",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 7,
                "result": 7
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042248",
                "MonitoringLocationName": "002S011W18L031S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042248/",
                "activityCount": "18",
                "resultCount": "18",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 18,
                "result": 18
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.073679,
                    33.9952905
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335943118042249",
                "MonitoringLocationName": "002S011W18L032S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335943118042249/",
                "activityCount": "6",
                "resultCount": "6",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.073679,
                "centroid_latitude": 33.9952905,
                "layerName": "custom",
                "activity": 6,
                "result": 6
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0734012,
                    33.9961238
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-335946118042102",
                "MonitoringLocationName": "002S011W18L043S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-335946118042102/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0734012,
                "centroid_latitude": 33.9961238,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0766028,
                    34.00145
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340005118043301",
                "MonitoringLocationName": "002S011W18C004S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340005118043301/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0766028,
                "centroid_latitude": 34.00145,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0766028,
                    34.00145
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340005118043302",
                "MonitoringLocationName": "002S011W18C005S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340005118043302/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0766028,
                "centroid_latitude": 34.00145,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0766028,
                    34.00145
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340005118043303",
                "MonitoringLocationName": "002S011W18C006S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340005118043303/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0766028,
                "centroid_latitude": 34.00145,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0775056,
                    34.0014639
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340005118043304",
                "MonitoringLocationName": "002S011W18C007S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340005118043304/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0775056,
                "centroid_latitude": 34.0014639,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1020278,
                    34.0055
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340020118060101",
                "MonitoringLocationName": "002S012W11R004S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340020118060101/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1020278,
                "centroid_latitude": 34.0055,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1221056,
                    34.0078611
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340027118071901",
                "MonitoringLocationName": "002S012W10Q005S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340027118071901/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1221056,
                "centroid_latitude": 34.0078611,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1222778,
                    34.008
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340027118071902",
                "MonitoringLocationName": "002S012W10Q006S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340027118071902/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1222778,
                "centroid_latitude": 34.008,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1221056,
                    34.0078611
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340027118071903",
                "MonitoringLocationName": "002S012W10Q007S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340027118071903/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1221056,
                "centroid_latitude": 34.0078611,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1222778,
                    34.008
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340027118071905",
                "MonitoringLocationName": "002S012W10Q009S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340027118071905/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1222778,
                "centroid_latitude": 34.008,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.17,
                    34.0111111
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340040118100901",
                "MonitoringLocationName": "002S012W07J001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340040118100901/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.17,
                "centroid_latitude": 34.0111111,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.17,
                    34.0111111
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340040118100904",
                "MonitoringLocationName": "002S012W07J004S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340040118100904/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.17,
                "centroid_latitude": 34.0111111,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0661789,
                    34.0125121
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340045118035501",
                "MonitoringLocationName": "002S011W07J001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340045118035501/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0661789,
                "centroid_latitude": 34.0125121,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0953333,
                    34.0118611
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340047118054601",
                "MonitoringLocationName": "002S012W12E008S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340047118054601/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0953333,
                "centroid_latitude": 34.0118611,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0536667,
                    34.0292222
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340148118030901",
                "MonitoringLocationName": "002S011W05G007S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340148118030901/",
                "activityCount": "4",
                "resultCount": "4",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0536667,
                "centroid_latitude": 34.0292222,
                "layerName": "custom",
                "activity": 4,
                "result": 4
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.4596667,
                    34.0312778
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340152118273601",
                "MonitoringLocationName": "002S015W04C002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070104",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340152118273601/",
                "activityCount": "5",
                "resultCount": "5",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.4596667,
                "centroid_latitude": 34.0312778,
                "layerName": "custom",
                "activity": 5,
                "result": 5
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.6841389,
                    34.0321944
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340155118410201",
                "MonitoringLocationName": "001S017W32Q002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070104",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340155118410201/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.6841389,
                "centroid_latitude": 34.0321944,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.685111,
                    34.0324722
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340156118410601",
                "MonitoringLocationName": "MALIBU LAGOON WEST A MALIBU CA",
                "MonitoringLocationTypeName": "Estuary",
                "ResolvedMonitoringLocationTypeName": "Estuary",
                "HUCEightDigitCode": "18070104",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340156118410601/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.685111,
                "centroid_latitude": 34.0324722,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.68725,
                    34.0322222
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340156118411401",
                "MonitoringLocationName": "001S017W32P003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070104",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340156118411401/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.68725,
                "centroid_latitude": 34.0322222,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.6901389,
                    34.0328333
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340158118412401",
                "MonitoringLocationName": "001S017W32N001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070104",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340158118412401/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.6901389,
                "centroid_latitude": 34.0328333,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.696111,
                    34.0331389
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340159118414601",
                "MonitoringLocationName": "001S017W31R001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070104",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340159118414601/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.696111,
                "centroid_latitude": 34.0331389,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.46625,
                    34.0435278
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340200118270001",
                "MonitoringLocationName": "001S015W32A006S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070104",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340200118270001/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.46625,
                "centroid_latitude": 34.0435278,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.6837778,
                    34.0348611
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340205118410101",
                "MonitoringLocationName": "001S017W32K003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070104",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340205118410101/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.6837778,
                "centroid_latitude": 34.0348611,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.6846944,
                    34.0354722
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340207118410401",
                "MonitoringLocationName": "001S017W32L008S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070104",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340207118410401/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.6846944,
                "centroid_latitude": 34.0354722,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.6926667,
                    34.0355
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340207118413301",
                "MonitoringLocationName": "001S017W32M002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070104",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340207118413301/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.6926667,
                "centroid_latitude": 34.0355,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.6838056,
                    34.0356111
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340208118410101",
                "MonitoringLocationName": "001S017W32K002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070104",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340208118410101/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.6838056,
                "centroid_latitude": 34.0356111,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.6854722,
                    34.0355833
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340208118410701",
                "MonitoringLocationName": "001S017W32L009S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070104",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340208118410701/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.6854722,
                "centroid_latitude": 34.0355833,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.6843333,
                    34.0360278
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340209118410301",
                "MonitoringLocationName": "001S017W32K001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070104",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340209118410301/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.6843333,
                "centroid_latitude": 34.0360278,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.6819167,
                    34.0363056
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340210118405401",
                "MonitoringLocationName": "001S017W32K004S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070104",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340210118405401/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.6819167,
                "centroid_latitude": 34.0363056,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.809,
                    34.0515
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340300117480001",
                "MonitoringLocationName": "001S009W27Q003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340300117480001/",
                "activityCount": "4",
                "resultCount": "4",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -117.809,
                "centroid_latitude": 34.0515,
                "layerName": "custom",
                "activity": 4,
                "result": 4
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.7306389,
                    34.0509444
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340303117434701",
                "MonitoringLocationName": "001S008W28N001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070203",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340303117434701/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -117.7306389,
                "centroid_latitude": 34.0509444,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0021944,
                    34.0559167
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340321118000501",
                "MonitoringLocationName": "001S011W26K003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340321118000501/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0021944,
                "centroid_latitude": 34.0559167,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.7198167,
                    34.0594139
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340333117431001",
                "MonitoringLocationName": "001S008W28G002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070203",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340333117431001/",
                "activityCount": "4",
                "resultCount": "4",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -117.7198167,
                "centroid_latitude": 34.0594139,
                "layerName": "custom",
                "activity": 4,
                "result": 4
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.8878056,
                    34.0691389
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340400117530001",
                "MonitoringLocationName": "001S010W24M004S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340400117530001/",
                "activityCount": "4",
                "resultCount": "4",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -117.8878056,
                "centroid_latitude": 34.0691389,
                "layerName": "custom",
                "activity": 4,
                "result": 4
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.705886,
                    34.1176111
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340703117421901",
                "MonitoringLocationName": "001S008W03G002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070203",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340703117421901/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -117.705886,
                "centroid_latitude": 34.1176111,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.098861,
                    34.1431944
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340835118055401",
                "MonitoringLocationName": "001N012W25E001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340835118055401/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.098861,
                "centroid_latitude": 34.1431944,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.9203056,
                    34.1518889
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340900117550001",
                "MonitoringLocationName": "001N010W22P003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340900117550001/",
                "activityCount": "5",
                "resultCount": "5",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -117.9203056,
                "centroid_latitude": 34.1518889,
                "layerName": "custom",
                "activity": 5,
                "result": 5
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2863056,
                    34.1581111
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-340900118170001",
                "MonitoringLocationName": "001N013W19F001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-340900118170001/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2863056,
                "centroid_latitude": 34.1581111,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3685833,
                    34.1817778
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-341000118220001",
                "MonitoringLocationName": "001N014W08K002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-341000118220001/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3685833,
                "centroid_latitude": 34.1817778,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0298333,
                    34.1951667
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-341100118010001",
                "MonitoringLocationName": "001N011W04R001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-341100118010001/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0298333,
                "centroid_latitude": 34.1951667,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1556944,
                    34.1878056
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-341100118092001",
                "MonitoringLocationName": "001N012W08H003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-341100118092001/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1556944,
                "centroid_latitude": 34.1878056,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2296944,
                    34.1920556
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-341100118130001",
                "MonitoringLocationName": "001N013W10B001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-341100118130001/",
                "activityCount": "5",
                "resultCount": "5",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2296944,
                "centroid_latitude": 34.1920556,
                "layerName": "custom",
                "activity": 5,
                "result": 5
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.4159444,
                    34.1940556
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-341100118240001",
                "MonitoringLocationName": "001N015W02R001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-341100118240001/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.4159444,
                "centroid_latitude": 34.1940556,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2266667,
                    34.2482222
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-341400118130001",
                "MonitoringLocationName": "002N013W22GS01S",
                "MonitoringLocationTypeName": "Spring",
                "ResolvedMonitoringLocationTypeName": "Spring",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-341400118130001/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2266667,
                "centroid_latitude": 34.2482222,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.8838889,
                    34.2397222
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-341423117530201",
                "MonitoringLocationName": "WF SAN GABRIEL R AB BEAR C NR CAMP RICON CA",
                "MonitoringLocationTypeName": "Stream",
                "ResolvedMonitoringLocationTypeName": "Stream",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-341423117530201/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -117.8838889,
                "centroid_latitude": 34.2397222,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2863889,
                    34.2922222
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-341732118171101",
                "MonitoringLocationName": "BIG TUJUNGA C A CAMP FIFTEEN NR SUNLAND CA",
                "MonitoringLocationTypeName": "Stream",
                "ResolvedMonitoringLocationTypeName": "Stream",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-341732118171101/",
                "activityCount": "4",
                "resultCount": "4",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2863889,
                "centroid_latitude": 34.2922222,
                "layerName": "custom",
                "activity": 4,
                "result": 4
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1144444,
                    34.3105556
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-341838118065201",
                "MonitoringLocationName": "BIG TUJUNGA C NR HIDDEN SPRINGS CA",
                "MonitoringLocationTypeName": "Stream",
                "ResolvedMonitoringLocationTypeName": "Stream",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-341838118065201/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1144444,
                "centroid_latitude": 34.3105556,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1194722,
                    34.3291389
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-341900118070001",
                "MonitoringLocationName": "003N012W22J001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-341900118070001/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1194722,
                "centroid_latitude": 34.3291389,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.9683056,
                    34.3448889
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-342000117580001",
                "MonitoringLocationName": "003N010W18LS01S",
                "MonitoringLocationTypeName": "Spring",
                "ResolvedMonitoringLocationTypeName": "Spring",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-342000117580001/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -117.9683056,
                "centroid_latitude": 34.3448889,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3644444,
                    34.3458333
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-342045118215201",
                "MonitoringLocationName": "PACOIMA CYN C A ANT CYN NR SUNLAND CA",
                "MonitoringLocationTypeName": "Stream",
                "ResolvedMonitoringLocationTypeName": "Stream",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-342045118215201/",
                "activityCount": "4",
                "resultCount": "4",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3644444,
                "centroid_latitude": 34.3458333,
                "layerName": "custom",
                "activity": 4,
                "result": 4
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.7221944,
                    34.3758333
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-342200117430001",
                "MonitoringLocationName": "003N008W04L001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070106",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-342200117430001/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -117.7221944,
                "centroid_latitude": 34.3758333,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.093611,
                    34.4180556
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-342505118053701",
                "MonitoringLocationName": "ALISO CYN C NR ACTON CA",
                "MonitoringLocationTypeName": "Stream",
                "ResolvedMonitoringLocationTypeName": "Stream",
                "HUCEightDigitCode": "18070102",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-342505118053701/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.093611,
                "centroid_latitude": 34.4180556,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1458333,
                    34.4422222
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-342632118084501",
                "MonitoringLocationName": "ALISO CYN C A RANCH RD NR ACTON CA",
                "MonitoringLocationTypeName": "Stream",
                "ResolvedMonitoringLocationTypeName": "Stream",
                "HUCEightDigitCode": "18070102",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-342632118084501/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1458333,
                "centroid_latitude": 34.4422222,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.8491389,
                    34.4456944
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-342643117505201",
                "MonitoringLocationName": "004N009W08M001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-342643117505201/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -117.8491389,
                "centroid_latitude": 34.4456944,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.6169722,
                    34.4515
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-342700118370001",
                "MonitoringLocationName": "004N017W12C001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070102",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-342700118370001/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.6169722,
                "centroid_latitude": 34.4515,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.4531389,
                    34.5016389
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-343000118270001",
                "MonitoringLocationName": "005N015W21J001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18070102",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-343000118270001/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.4531389,
                "centroid_latitude": 34.5016389,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0462222,
                    34.5226944
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-343122118024401",
                "MonitoringLocationName": "005N011W17H002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-343122118024401/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0462222,
                "centroid_latitude": 34.5226944,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.9247222,
                    34.5394444
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-343222117552901",
                "MonitoringLocationName": "005N010W09H001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-343222117552901/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -117.9247222,
                "centroid_latitude": 34.5394444,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1707333,
                    34.5870056
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-343515118101401",
                "MonitoringLocationName": "006N012W29B001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-343515118101401/",
                "activityCount": "5",
                "resultCount": "5",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1707333,
                "centroid_latitude": 34.5870056,
                "layerName": "custom",
                "activity": 5,
                "result": 5
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1658528,
                    34.5895139
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-343522118095701",
                "MonitoringLocationName": "006N012W20R001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-343522118095701/",
                "activityCount": "4",
                "resultCount": "4",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1658528,
                "centroid_latitude": 34.5895139,
                "layerName": "custom",
                "activity": 4,
                "result": 4
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1653806,
                    34.5900028
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-343523118095501",
                "MonitoringLocationName": "006N012W20R002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-343523118095501/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1653806,
                "centroid_latitude": 34.5900028,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1545028,
                    34.5961667
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-343546118091601",
                "MonitoringLocationName": "006N012W21G001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-343546118091601/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1545028,
                "centroid_latitude": 34.5961667,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2447222,
                    34.6286111
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-343743118144101",
                "MonitoringLocationName": "006N013W10C PRECIP",
                "MonitoringLocationTypeName": "Atmosphere",
                "ResolvedMonitoringLocationTypeName": "Atmosphere",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-343743118144101/",
                "activityCount": "4",
                "resultCount": "4",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2447222,
                "centroid_latitude": 34.6286111,
                "layerName": "custom",
                "activity": 4,
                "result": 4
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2445556,
                    34.6589722
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-343932118144001",
                "MonitoringLocationName": "007N013W34B001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-343932118144001/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2445556,
                "centroid_latitude": 34.6589722,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0728056,
                    34.6645
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-343953118041901",
                "MonitoringLocationName": "007N011W29M001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-343953118041901/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0728056,
                "centroid_latitude": 34.6645,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1393972,
                    34.6679528
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344005118081801",
                "MonitoringLocationName": "007N012W27F002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344005118081801/",
                "activityCount": "6",
                "resultCount": "6",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1393972,
                "centroid_latitude": 34.6679528,
                "layerName": "custom",
                "activity": 6,
                "result": 6
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0661389,
                    34.684125
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344103118035501",
                "MonitoringLocationName": "007N011W20G003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344103118035501/",
                "activityCount": "13",
                "resultCount": "13",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0661389,
                "centroid_latitude": 34.684125,
                "layerName": "custom",
                "activity": 13,
                "result": 13
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1371389,
                    34.6888722
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344120118081301",
                "MonitoringLocationName": "007N012W22B002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344120118081301/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1371389,
                "centroid_latitude": 34.6888722,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1336944,
                    34.6901306
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344125118075801",
                "MonitoringLocationName": "007N012W15R004S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344125118075801/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1336944,
                "centroid_latitude": 34.6901306,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.0809444,
                    34.6931083
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344135118044801",
                "MonitoringLocationName": "007N011W18R004S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344135118044801/",
                "activityCount": "6",
                "resultCount": "6",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.0809444,
                "centroid_latitude": 34.6931083,
                "layerName": "custom",
                "activity": 6,
                "result": 6
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.9719278,
                    34.693475
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344138117581901",
                "MonitoringLocationName": "007N010W18R002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344138117581901/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -117.9719278,
                "centroid_latitude": 34.693475,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1295806,
                    34.7134028
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344248118074701",
                "MonitoringLocationName": "007N012W11E002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344248118074701/",
                "activityCount": "7",
                "resultCount": "7",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1295806,
                "centroid_latitude": 34.7134028,
                "layerName": "custom",
                "activity": 7,
                "result": 7
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1570722,
                    34.7183139
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344306118092601",
                "MonitoringLocationName": "007N012W09B001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344306118092601/",
                "activityCount": "8",
                "resultCount": "8",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1570722,
                "centroid_latitude": 34.7183139,
                "layerName": "custom",
                "activity": 8,
                "result": 8
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2176333,
                    34.73755
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344415118130301",
                "MonitoringLocationName": "008N013W36N001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344415118130301/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2176333,
                "centroid_latitude": 34.73755,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2813333,
                    34.7829444
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344600118160001",
                "MonitoringLocationName": "008N013W17K001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344600118160001/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2813333,
                "centroid_latitude": 34.7829444,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.6093056,
                    34.785
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344700118360001",
                "MonitoringLocationName": "008N016W18H004S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344700118360001/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.6093056,
                "centroid_latitude": 34.785,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3787222,
                    34.7915556
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344729118224301",
                "MonitoringLocationName": "008N014W09N001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344729118224301/",
                "activityCount": "6",
                "resultCount": "6",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3787222,
                "centroid_latitude": 34.7915556,
                "layerName": "custom",
                "activity": 6,
                "result": 6
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3787222,
                    34.7915556
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344729118224308",
                "MonitoringLocationName": "008N014W09N003SLYS",
                "MonitoringLocationTypeName": "Subsurface: Unsaturated zone",
                "ResolvedMonitoringLocationTypeName": "Subsurface",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344729118224308/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3787222,
                "centroid_latitude": 34.7915556,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3783333,
                    34.7916667
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344730118224201",
                "MonitoringLocationName": "POND AVUZ-2 NR FAIRMONT CA",
                "MonitoringLocationTypeName": "Lake, Reservoir, Impoundment",
                "ResolvedMonitoringLocationTypeName": "Lake, Reservoir, Impoundment",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344730118224201/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3783333,
                "centroid_latitude": 34.7916667,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3772222,
                    34.7944444
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344740118223801",
                "MonitoringLocationName": "NORTH POND AVUZ-2 NR FAIRMONT CA",
                "MonitoringLocationTypeName": "Lake, Reservoir, Impoundment",
                "ResolvedMonitoringLocationTypeName": "Lake, Reservoir, Impoundment",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344740118223801/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3772222,
                "centroid_latitude": 34.7944444,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.405,
                    34.7975
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344751118241801",
                "MonitoringLocationName": "POND AVUZ-4 FAR SIDE NR FAIRMONT CA",
                "MonitoringLocationTypeName": "Lake, Reservoir, Impoundment",
                "ResolvedMonitoringLocationTypeName": "Lake, Reservoir, Impoundment",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344751118241801/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.405,
                "centroid_latitude": 34.7975,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.40525,
                    34.7975
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344751118241901",
                "MonitoringLocationName": "008N014W07G001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344751118241901/",
                "activityCount": "7",
                "resultCount": "7",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.40525,
                "centroid_latitude": 34.7975,
                "layerName": "custom",
                "activity": 7,
                "result": 7
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3704139,
                    34.7998556
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344759118221301",
                "MonitoringLocationName": "008N014W09F004S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344759118221301/",
                "activityCount": "6",
                "resultCount": "6",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3704139,
                "centroid_latitude": 34.7998556,
                "layerName": "custom",
                "activity": 6,
                "result": 6
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3704139,
                    34.7998556
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344759118221302",
                "MonitoringLocationName": "008N014W09F005S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344759118221302/",
                "activityCount": "6",
                "resultCount": "6",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3704139,
                "centroid_latitude": 34.7998556,
                "layerName": "custom",
                "activity": 6,
                "result": 6
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3704139,
                    34.7998556
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344759118221303",
                "MonitoringLocationName": "008N014W09F006S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344759118221303/",
                "activityCount": "6",
                "resultCount": "6",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3704139,
                "centroid_latitude": 34.7998556,
                "layerName": "custom",
                "activity": 6,
                "result": 6
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3752778,
                    34.7997222
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344759118223101",
                "MonitoringLocationName": "POND AVUZ-1 INLET NR FAIRMONT CA",
                "MonitoringLocationTypeName": "Lake, Reservoir, Impoundment",
                "ResolvedMonitoringLocationTypeName": "Lake, Reservoir, Impoundment",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344759118223101/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3752778,
                "centroid_latitude": 34.7997222,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3755556,
                    34.7997222
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344759118223201",
                "MonitoringLocationName": "POND AVUZ-1 NR FAIRMONT CA",
                "MonitoringLocationTypeName": "Lake, Reservoir, Impoundment",
                "ResolvedMonitoringLocationTypeName": "Lake, Reservoir, Impoundment",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344759118223201/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3755556,
                "centroid_latitude": 34.7997222,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3704139,
                    34.8
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344800118221301",
                "MonitoringLocationName": "008N014W09F001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344800118221301/",
                "activityCount": "6",
                "resultCount": "6",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3704139,
                "centroid_latitude": 34.8,
                "layerName": "custom",
                "activity": 6,
                "result": 6
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3702778,
                    34.8
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344800118221309",
                "MonitoringLocationName": "POND AVUZ-1 FAR SIDE NR FAIRMONT CA",
                "MonitoringLocationTypeName": "Lake, Reservoir, Impoundment",
                "ResolvedMonitoringLocationTypeName": "Lake, Reservoir, Impoundment",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344800118221309/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3702778,
                "centroid_latitude": 34.8,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.4011333,
                    34.8043278
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344815118240401",
                "MonitoringLocationName": "008N014W07B001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344815118240401/",
                "activityCount": "9",
                "resultCount": "9",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.4011333,
                "centroid_latitude": 34.8043278,
                "layerName": "custom",
                "activity": 9,
                "result": 9
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3745972,
                    34.808275
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344829118222801",
                "MonitoringLocationName": "008N014W04P001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344829118222801/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3745972,
                "centroid_latitude": 34.808275,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.6227778,
                    34.8083333
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344830118372201",
                "MonitoringLocationName": "008N016W06M PRECIP",
                "MonitoringLocationTypeName": "Atmosphere",
                "ResolvedMonitoringLocationTypeName": "Atmosphere",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344830118372201/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.6227778,
                "centroid_latitude": 34.8083333,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.887844,
                    34.8097042
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344835117531303",
                "MonitoringLocationName": "008N010W01Q003S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344835117531303/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -117.887844,
                "centroid_latitude": 34.8097042,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3699778,
                    34.8100639
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344836118221101",
                "MonitoringLocationName": "008N014W04K002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344836118221101/",
                "activityCount": "6",
                "resultCount": "6",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3699778,
                "centroid_latitude": 34.8100639,
                "layerName": "custom",
                "activity": 6,
                "result": 6
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.3699778,
                    34.8100639
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344836118221104",
                "MonitoringLocationName": "008N014W04K003SLYS",
                "MonitoringLocationTypeName": "Subsurface: Unsaturated zone",
                "ResolvedMonitoringLocationTypeName": "Subsurface",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344836118221104/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.3699778,
                "centroid_latitude": 34.8100639,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.4009694,
                    34.8116278
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344841118240301",
                "MonitoringLocationName": "008N014W06J001S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344841118240301/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.4009694,
                "centroid_latitude": 34.8116278,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.9161722,
                    34.8136694
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344849117545801",
                "MonitoringLocationName": "008N010W02M002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344849117545801/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -117.9161722,
                "centroid_latitude": 34.8136694,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.8908997,
                    34.8194259
                ]
            },
            "properties": {
                "ProviderName": "NWIS",
                "OrganizationIdentifier": "USGS-CA",
                "OrganizationFormalName": "USGS California Water Science Center",
                "MonitoringLocationIdentifier": "USGS-344910117532402",
                "MonitoringLocationName": "008N010W01C002S",
                "MonitoringLocationTypeName": "Well",
                "ResolvedMonitoringLocationTypeName": "Well",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/NWIS/USGS-CA/USGS-344910117532402/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -117.8908997,
                "centroid_latitude": 34.8194259,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.6101,
                    34.5152
                ]
            },
            "properties": {
                "ProviderName": "STORET",
                "OrganizationIdentifier": "CALWR_WQX",
                "OrganizationFormalName": "California Department Of Water Resources",
                "MonitoringLocationIdentifier": "CALWR_WQX-CA002000",
                "MonitoringLocationName": "Castaic Lake Outlet Tower",
                "MonitoringLocationTypeName": "Other-Surface Water",
                "ResolvedMonitoringLocationTypeName": "Aggregate surface-water-use",
                "HUCEightDigitCode": "18070102",
                "siteUrl": "https://www.waterqualitydata.us/provider/STORET/CALWR_WQX/CALWR_WQX-CA002000/",
                "activityCount": "194",
                "resultCount": "194",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.6101,
                "centroid_latitude": 34.5152,
                "layerName": "custom",
                "activity": 194,
                "result": 194
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.1821111,
                    34.5785556
                ]
            },
            "properties": {
                "ProviderName": "STORET",
                "OrganizationIdentifier": "CALWR_WQX",
                "OrganizationFormalName": "California Department Of Water Resources",
                "MonitoringLocationIdentifier": "CALWR_WQX-KA034265",
                "MonitoringLocationName": "EAST BRANCH AQUEDUCT AT MP 342.65",
                "MonitoringLocationTypeName": "Other-Surface Water",
                "ResolvedMonitoringLocationTypeName": "Aggregate surface-water-use",
                "HUCEightDigitCode": "18090206",
                "siteUrl": "https://www.waterqualitydata.us/provider/STORET/CALWR_WQX/CALWR_WQX-KA034265/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.1821111,
                "centroid_latitude": 34.5785556,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.7642,
                    34.6449
                ]
            },
            "properties": {
                "ProviderName": "STORET",
                "OrganizationIdentifier": "CALWR_WQX",
                "OrganizationFormalName": "California Department Of Water Resources",
                "MonitoringLocationIdentifier": "CALWR_WQX-PY001000",
                "MonitoringLocationName": "PYRAMID LK A ANGELES TU INLET",
                "MonitoringLocationTypeName": "Other-Surface Water",
                "ResolvedMonitoringLocationTypeName": "Aggregate surface-water-use",
                "HUCEightDigitCode": "18070102",
                "siteUrl": "https://www.waterqualitydata.us/provider/STORET/CALWR_WQX/CALWR_WQX-PY001000/",
                "activityCount": "153",
                "resultCount": "153",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.7642,
                "centroid_latitude": 34.6449,
                "layerName": "custom",
                "activity": 153,
                "result": 153
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2730002,
                    33.993503
                ]
            },
            "properties": {
                "ProviderName": "STORET",
                "OrganizationIdentifier": "CEDEN",
                "OrganizationFormalName": "California State Water Resources Control Board",
                "MonitoringLocationIdentifier": "CEDEN-AvalonDWHT210604",
                "MonitoringLocationName": "Driveway between 54th St and 53rd St - South Park, dry well",
                "MonitoringLocationTypeName": "Other-Surface Water",
                "ResolvedMonitoringLocationTypeName": "Aggregate surface-water-use",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/STORET/CEDEN/CEDEN-AvalonDWHT210604/",
                "activityCount": "3",
                "resultCount": "3",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2730002,
                "centroid_latitude": 33.993503,
                "layerName": "custom",
                "activity": 3,
                "result": 3
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2730002,
                    33.993603
                ]
            },
            "properties": {
                "ProviderName": "STORET",
                "OrganizationIdentifier": "CEDEN",
                "OrganizationFormalName": "California State Water Resources Control Board",
                "MonitoringLocationIdentifier": "CEDEN-AvalonGWHT210604",
                "MonitoringLocationName": "Driveway between 54th St and 53rd St - South Park, groundwater well",
                "MonitoringLocationTypeName": "Other-Surface Water",
                "ResolvedMonitoringLocationTypeName": "Aggregate surface-water-use",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/STORET/CEDEN/CEDEN-AvalonGWHT210604/",
                "activityCount": "2",
                "resultCount": "2",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2730002,
                "centroid_latitude": 33.993603,
                "layerName": "custom",
                "activity": 2,
                "result": 2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2670003,
                    33.9959031
                ]
            },
            "properties": {
                "ProviderName": "STORET",
                "OrganizationIdentifier": "CEDEN",
                "OrganizationFormalName": "California State Water Resources Control Board",
                "MonitoringLocationIdentifier": "CEDEN-TCB",
                "MonitoringLocationName": "Towne_Ave_Catch_Basin",
                "MonitoringLocationTypeName": "Other-Surface Water",
                "ResolvedMonitoringLocationTypeName": "Aggregate surface-water-use",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/STORET/CEDEN/CEDEN-TCB/",
                "activityCount": "5",
                "resultCount": "5",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2670003,
                "centroid_latitude": 33.9959031,
                "layerName": "custom",
                "activity": 5,
                "result": 5
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.2670003,
                    33.9959031
                ]
            },
            "properties": {
                "ProviderName": "STORET",
                "OrganizationIdentifier": "CEDEN",
                "OrganizationFormalName": "California State Water Resources Control Board",
                "MonitoringLocationIdentifier": "CEDEN-TDW",
                "MonitoringLocationName": "AvalonN_Torrent_Drywell",
                "MonitoringLocationTypeName": "Other-Surface Water",
                "ResolvedMonitoringLocationTypeName": "Aggregate surface-water-use",
                "HUCEightDigitCode": "18070105",
                "siteUrl": "https://www.waterqualitydata.us/provider/STORET/CEDEN/CEDEN-TDW/",
                "activityCount": "4",
                "resultCount": "4",
                "StateName": "California",
                "CountyName": "Los Angeles County",
                "centroid_longitude": -118.2670003,
                "centroid_latitude": 33.9959031,
                "layerName": "custom",
                "activity": 4,
                "result": 4
            }
        }
    ]
} as any;

export default TestFeatures;
