import { Layer, Source } from 'react-map-gl';
import {
    useState,
    useEffect,
    useMemo,
    useRef,
    useCallback
} from 'react';
import { adjustHexBrightness } from '../../features/utils';

const YellowstoneHuc12 = ({
    global,
    id,
    opacity,
    outlineColor,
    tileSet,
    sourceLayer,
    data,
}: any) => {
    const [huc12s, setHuc12s] = useState<any[]>([]); 
    const [properties, setProperties] = useState<any[]>([]);
    const [paint, setPaint] = useState<any[]>([
        'transparent', 'transparent'
    ]);

    useEffect(() => {
        const { features } = data;

        let huc12List: any = [];
        let propertiesList: any = [];

        features.forEach((feature: any) => {
            const { properties } = feature;

            if (properties.huc12) {
                huc12List.push(properties.huc12);
                propertiesList.push(properties);
            }
        });

        global.setHoursIndex(0);

        let hours:any = []

        for (let i = 0; i < 520; i++) {
            hours.push(i)   
        }

        global.setHours(hours)
        setProperties(propertiesList);
        setHuc12s(huc12List);
    }, []);

    const handleLowMedian = (input: any, huc:any) => {

        let values: any = Object.values(input).filter((e:any) => e.huc12 !== huc);
        values.sort(function (a: any, b: any) {
            return a - b;
        });

        let minimum = Math.min(...values);

        let sum = values.reduce(function (accumulator: any, value: any) {
            return accumulator + value;
        }, 0);

        let average = sum / values.length;

        let medianNumberBetween = (minimum + average) / 2;

        return medianNumberBetween
    }

    // const handleMaxMedian = (input: any, huc: any) => {

    //     let values: any = Object.values(input).filter((e: any) => e.huc12 !== huc);
    //     values.sort(function (a: any, b: any) {
    //         return a - b;
    //     });

    //     let sum = values.reduce(function (accumulator: any, value: any) {
    //         return accumulator + value;
    //     }, 0);

    //     let average = sum / values.length;

    //     let maximum = Math.max(...values);

    //     let medianNumberBetween = (average + maximum) / 2;

    //     return medianNumberBetween
    // }

    const handleAverage = (input: any, huc: any) => {

        const values: any = Object.values(input).filter((e: any) => e.huc12 !== huc);

        let sum = values.reduce(function (accumulator: any, value: any) {
            return accumulator + value;
        }, 0);

        let av = sum / values.length;

        return av
    }

    const handleRanges = useCallback((huc: any, hour: any) => {
        const tile = properties.find((e: any) => e.huc12 === huc);

        if (tile) {

            const lowMed = handleLowMedian(tile, huc);
            const av = handleAverage(tile, huc);
            // const maxMed = handleMaxMedian(tile, huc);
            const value = tile[hour];

            if (value < lowMed) {
                return [huc, `${adjustHexBrightness('#087D04', global.yellowstoneLayerBrightness)}`];
            } else if (value > lowMed && value < av) {
                return [huc, `${adjustHexBrightness('#EBEE03', global.yellowstoneLayerBrightness)}`];
            } else if (value > av) {
                return [huc, `${adjustHexBrightness('#F5100D', global.yellowstoneLayerBrightness)}`];
            } 

        }
    }, [properties])

    const handleMap = useCallback((hour: any) => {
        let paintList: any = [];

        for (let i = 0; i < huc12s.length; i++) {
            const huc = huc12s[i]
            if (huc) {
                const color = handleRanges(huc, hour);
                if (color) {
                    paintList.push(...color);
                }
            }
        }
        return paintList;
    }, [huc12s]);


    useEffect(() => {
        if (global.hoursIndex && properties) {
            setPaint(handleMap(global.hoursIndex)); 
        }
    }, [global.hoursIndex, properties]);


    const VectorLineLayer = {
        id: `${id}-line-layer`,
        type: 'line',
        'source-layer': `${sourceLayer}`,
        paint: {
            'line-opacity': opacity,
            'line-color': `${outlineColor}`,
            'line-width': 3,
        },
    };

 
    const VectorTileLayer: any = {
        id: `${id}-layer`,
        type: 'fill',
        'source-layer': `${sourceLayer}`,
        paint: {
            'fill-opacity': opacity,
            'fill-outline-color': `${outlineColor}`,
            'fill-color': [
                'match',
                ['get', 'huc12'],
                ...paint,
                'transparent'
            ],
        },
    }

    return (
        <>
            <Source id={`${id}-line-layer`} type="vector" url={tileSet}>
                {/* @ts-ignore */}
                <Layer {...VectorLineLayer} />
            </Source>
            <Source id={`${id}-layer`} type="vector" url={tileSet}>
                {/* @ts-ignore */}
                <Layer {...VectorTileLayer} />
            </Source>

        </>
    );
};

export default YellowstoneHuc12;


