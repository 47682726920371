import { MapSource } from '../../types/MapSource'
import { MapLayer } from '../../types/MapLayer'
import { Layer, Source } from 'react-map-gl'
import { useState, useEffect, useContext } from 'react'
import settings from '../../settings.json'
import { FillLayer, SymbolLayer } from 'mapbox-gl';
import { v4 as sourceId } from 'uuid';
import { RefContext } from '../../RefContext';

const HucSearch = (props: any) => {

  const {
    data,
    fillColorHUC8,
    outlineColorHUC8,
    fillColorHUC12,
    outlineColorHUC12,
    beforeId,
    opacity,
    mapRef,
    huc8ID,
    huc12ID, 
    huc8only,
    huc12Zoom,
    setData,
    updateHuc12
  } = props

  const huc12SourceLayer = 'True Elements - HUC 12s';
  const huc8SourceLayer = 'True Elements - huc8s';

  const [huc8Filter, setHuc8Filter] = useState([
    'in',
    'HUC8',
    'none'
  ])
  const [features, setFeatures] = useState()
  const [huc12Filter, setHuc12Filter] = useState([
    'in',
    'HUC12',
    'none'
  ])
  const { currentRef } = useContext(RefContext)

  const buildHUC12Map = () => {
    if(data && data.length>0){
      if(!huc8only){
        let outer = data.map((huc: any) => {
          return huc.huc12;
        })
        setHuc12Filter(['in','HUC12'].concat(outer.flat()))
      }
    } else {
        setHuc12Filter(['in','HUC12', 'none'])
    }
  }

  // @ts-ignore
  const mapInstance = currentRef && currentRef?.current


  useEffect(() => {
    if (data) {
      buildHUC12Map()
    }
  }, [data])

  const huc12sLayerPaint = {
    'fill-opacity': opacity,
    'fill-color': (typeof fillColorHUC12 === 'string') ? `${fillColorHUC12}` : fillColorHUC12, 
    'fill-outline-color': `${outlineColorHUC12}`,
  };
  
  const huc12sLayer = new MapLayer(
    huc12SourceLayer,
    'fill',
    huc12sLayerPaint,
    huc12Zoom||8,
    undefined,
    huc12ID
  );

  const huc12sSource = new MapSource(
    'vector',
    settings.tileSetURLs.HUC12,
    huc12sLayer as FillLayer,
    props.switch ? huc12Filter : ['all']
  );

  const huc8sLayerPaint = {
    'fill-opacity': opacity,
    'fill-color': (typeof fillColorHUC8 === 'string') ? `${fillColorHUC8}` : fillColorHUC8,
    'fill-outline-color': `${outlineColorHUC8}`,
  };

  const huc8slayer = new MapLayer(
    huc8SourceLayer,
    'fill',
    huc8sLayerPaint,
    0,
    undefined,
    huc8ID
  );

  const huc8sSource = new MapSource(
    'vector',
    settings.tileSetURLs.HUC8,
    huc8slayer as FillLayer,
    props.switch ? huc8Filter : ['all'],
    huc12sSource
  );

  const highlightLayer: SymbolLayer = {
    id: `${huc12ID}-symbol-layer`,
    type: 'symbol',
    'source-layer': huc12SourceLayer,
    layout: {
          'text-field': [
            'format',
            ['get', 'NAME'],
            { 'font-scale': 1.2 },
            '\n',
            {},
            ['get', 'HUC12'],
            { 'font-scale': 1.2 },
        ],

          'text-size': 8, 
          'text-anchor': 'bottom',
    },

    paint: {
          'text-color' : 'white',
          'text-halo-color': 'black',
          'text-halo-width': 2,
    }
  };

  return (
    <>
    <div>
{/*     <Source
        key={sourceId()}
        id={huc8ID}
        type={huc8sSource.type as 'vector'}
        url={huc8sSource.tilesetURLs}
      >
       
        <Layer
          {...huc8sSource.layer}

          filter={huc8sSource.layerFilter}
          beforeId={settings.defaultBeforeId}
        />
        
      </Source>*/}

      <Source
          key={sourceId()}
          id={huc12ID}
          type={huc12sSource.type as 'vector'}
          url={huc12sSource.tilesetURLs}
        >
          <Layer {...huc12sSource.layer} beforeId={settings.defaultBeforeId}  filter={huc12sSource.layerFilter} />
          {data && data.length===11111 && (<Layer {... highlightLayer} filter={huc12sSource.layerFilter}/>)}
        </Source>
      </div>  
    </>
  )
}

export default HucSearch