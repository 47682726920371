import { useState, useEffect } from 'react'
import {
    Col,
    Row,
    Button,
    Form,
    ListGroup
} from 'react-bootstrap';
import RolesTable from './RolesTable'
import EditRoles from './EditRoles'
import FormErrorMessage from 'rsuite/esm/FormErrorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faMinusCircle,
    fa1,
    fa2,
    fa3,
    faPlusCircle,
    faXmarkCircle
} from '@fortawesome/pro-solid-svg-icons';

const RoleManagement = (props: any) => {

    const {
        rolesData, 
        usersData
    } = props


  const [data, setData] = useState(rolesData)
  const [action, setAction] = useState('table')


  const renderScreens = () => {

    if (action === 'table') {
      return <RolesTable
        action={action}
        setAction={setAction}
        data={rolesData}
        setData={setData}
        usersData={usersData}
      />
    }

    if(action === 'create') {
      return <EditRoles
        title={"Create"}
        action={action}
        setAction={setAction}
        data={''}
        rolesData={rolesData}
        usersData={usersData}
      />
    }

    if (action === 'edit') {
      return <EditRoles
        title={'Edit'}
        action={action}
        setAction={setAction}
        data={data}
        rolesData={rolesData}
        usersData={usersData}
      />
    }
  }

  return <>{renderScreens()}</>
}


export default RoleManagement