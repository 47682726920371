import { useEffect, useState, useRef, useCallback, useContext, useMemo, Ref } from 'react';
import { Button, Card, OverlayTrigger, Form } from 'react-bootstrap';
import { Tooltip as TooltipRB } from 'react-bootstrap';
import settings from '../../../settings.json';
import './styles.scss';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import {
  Map,
  useMap,
  MapRef,
  Popup,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  useControl,
  Layer,
  Source,
} from 'react-map-gl';
import type { LayerProps } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Feature, GeoJsonProperties, Point, FeatureCollection } from 'geojson';
import bbox from '@turf/bbox';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { HUC12InfoPoint } from '../../../types/HUC12InfoPoint';
import { RefContext } from '../../../RefContext'
import agent from '../../../api/agent';
import { parse } from 'papaparse';
import LoadingDataAnimation from '../../../components/LoadingDataAnimation';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import fullscreenIcon from '../../../assets/fullscreen-icon.svg';
import ellipsisIcon from '../../../assets/ellipsis-icon.svg';
import { DateTime } from 'luxon';
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import { ToastContainer, toast } from 'react-toastify';
import { message_hucrestriction, message_nodatalocation, message_noresult } from '../../../Constants';
import { AppContext } from '../../../AppContext';
import { CatalogsContext } from '../../../CatalogsProvider';
import { Location, useLocation } from 'react-router-dom';
import MapAddition from  '../shared/MapAddition';
import MapSlider from '../shared/MapSlider';
import MapLegend from '../shared/MapLegend';
import ReportsConfigs from '../../../ReportsConfigs';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import ReportTableExpanded from '../shared/ReportTableExpanded';

import { flyToInitialLocationHandler } from '../../utils';
import { processStatusMessage, uniqueValues, fitBoundsMapData } from '../shared/report-data';

import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend);

export default function PermitDischargeReport(props: any) {
  const { global } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);
  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;

  const selectedHUC8Value = appContext.selectedHUC8; //'14010001';
  const selectedSic = global.chemicalsSelected || 'All'; //Sic
  const selectedPermitDescription = global.filter1Selected;
  const selectedFilterLocations = global.filterLocationsSelected;
  const [userSelectedLocation, setUserSelectedLocation] = useState<any>();
  const [userSelectedLocations, setUserSelectedLocations] = useState<any>([]);
  const [usLocations, setUsLocations] = useState<any>([]);

  const [userSelectedRecord, setUserSelectedRecord] = useState<any>();
  const [currentRecord, setCurrentRecord] = useState<any>(null);

  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const [isFullScreenActive, setFullScreenActive] = useState(false);
  const fullscreenContainerHandler = () => {
    setFullScreenActive(!isFullScreenActive);
  };

  const [isDataReportLoaded, setIsDataReportLoaded] = useState(false);

  const [reportData, setReportData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [featureData, setFeatureData] = useState<FeatureCollection>();
  const [chartData, setChartData] = useState<any>(null);

  const [sicCodeScale, setSicCodeScale] = useState<any>([]);
  const [mapHoverFilter, setMapHoverFilter] = useState<any>(['in', ['get', 'dmrid'], '']);

  const { current: map } = useMap();
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [cursor, setCursor] = useState<string>('auto');
  const [mapLoaded, setMapLoaded] = useState(false);
  const mapRef = useRef<MapRef>();
  const { setCurrentRef } = useContext(RefContext)
  useEffect(() => { setCurrentRef?.(mapRef) }, [mapRef])

  const [mapLegendData, setMapLegendData] = useState<any[]>([]);
  const [circleColorStyle, setCircleColorStyle] = useState<any>([]);
  const [huc12Members, setHuc12Members] = useState<any[]>([]);
  const [huc12InfoCatalogArray, setHuc12InfoCatalogDataArray] = useState<any[]>([]);
  const [layerStyle, setLayerStyle] = useState<any>([]);
  const [hoverLayerStyle, setHoverLayerStyle] = useState<any>([]);
  const [statusMessage, setStatusMessage] = useState<any>(null);

  const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  const locationField = 'dmrid';
 

  useEffect(() => {
    global.setfilter1Selected('All');
    if(global.trackerOn) global.setTrackerOn(false)
  }, []); 

  useEffect(() => {
    global.setUserLogged(true);
  }, [selectedHUC8Value]);

  useEffect(() => {
    const huc12InfoFileURL = '/huc12_info.csv';
    fetch(huc12InfoFileURL)
      .then(response => response.text())
      .then(responseText => {
        const data: HUC12InfoPoint[] | any[] = parse(responseText, {
          header: true,
        }).data;
        setHuc12InfoCatalogDataArray(data);
      });
  }, []);

  useEffect(() => {
    setFilteredData([]);
    setReportData([]);
    setUserSelectedLocations([]);
  }, [selectedHUC8Value]);

  useEffect(() => {
    let filterData = [...filteredData];
    if (
      userSelectedLocations &&
      userSelectedLocations.length > 0 &&
      userSelectedLocations.indexOf('All') < 0
    ) {
      filterData = filterData.filter((o: any) => userSelectedLocations.indexOf(o.dmrid) > -1);
      setFilteredData(filterData);
    } else {
      filterReportData();
    }
  }, [userSelectedLocations]);

  useEffect(() => {
    filterReportData();
  }, [selectedFilterLocations, selectedSic, selectedPermitDescription]);

  useEffect(() => {
    if(statusMessage){
        global.notify(statusMessage)
    }
  }, [statusMessage]);

  useEffect(() => {
/*    if (!isDataReportLoaded) {
      return;
    }*/
    setStatusMessage('');
    if(selectedHUC8Value && global.hucRestrict.length>0 && (global.hucRestrict.indexOf(selectedHUC8Value) < 0)){
        setIsDataReportLoaded(true);
        return global.notify(message_hucrestriction) 
    }
    setIsDataReportLoaded(false);
    agent.Reports.PermitDischargeReport(selectedHUC8Value).then((res: any) => {
      flyToInitialLocationHandler(selectedHUC8Value, mapRef, catalogsContext.huc8Catalog);

      const returnStatus = processStatusMessage(res);
      setStatusMessage(returnStatus.statusMessage);
      if (returnStatus.Abort) {
        setIsDataReportLoaded(true);
        return;
      }
      let returnedData = [...res.body];
      if (!returnedData || !returnedData.length || returnedData.length < 1) {
        setIsDataReportLoaded(true);
        setStatusMessage('No result.');
        return;
      }

      const sicLabels = returnedData
        .map((item: any) => (item['Sic Codes'] ? item['Sic Codes'].split(' ') : 'null'))
        .flat()
        .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
        .filter((value: any, index: any, self: any) => value)
        .sort()
        .slice(0, -1);
      global.setChemicalsFilerOptions(['All', ...sicLabels]);

      const permitstatusLabels = uniqueValues(returnedData, 'Permit Status Description');
      const permitstatusScale: any[] = [];
      for (let dataItem of permitstatusLabels) {
        permitstatusScale.push({
          'Permit Status Description': dataItem,
          color: '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6),
        });
      }
      for (let dataItem of permitstatusScale) {
        switch (dataItem['Permit Status Description']) {
          case 'Admin Continued':
            dataItem.color = 'purple';
            break;
          case 'Terminated':
            dataItem.color = 'gray';
            break;
          case 'Not Needed':
            dataItem.color = 'black';
            break;
          case 'Effective':
            dataItem.color = 'red';
            break;
          default:
            dataItem.color = '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
            break;
        }
      }
      global.setfilter1Populated(['All', ...permitstatusLabels]);


      const facilitiesLabels = uniqueValues(returnedData, 'Facility Name');
      const facilitiesScale: any[] = [];
      for (let dataItem of facilitiesLabels) {
        facilitiesScale.push({ hucname: dataItem, huc12: dataItem, checked: true });
      }
      global.setfilterLocationsPopulated([{ hucname: 'All', huc12: 'All' }, ...facilitiesScale]);

      setMapLegendData(
        permitstatusScale.map((x: any, index: number) => ({
          typecode: permitstatusScale[index]['Permit Status Description'],
          color: permitstatusScale[index].color,
        }))
      );

      for (let dataItem of returnedData) {
        const permitstatusrecord = permitstatusScale.filter(
          (o: any) => o['Permit Status Description'] === dataItem['Permit Status Description']
        );
        if (permitstatusrecord && permitstatusrecord.length > 0) {
          dataItem.color = permitstatusrecord[0].color;
        }
        dataItem.dmrid = dataItem['Permit Number'] + dataItem.Latitude + Math.random() * 5;
        dataItem.lat = dataItem.Latitude;
        dataItem.lng = dataItem.Longitude;
        dataItem.centroid_longitude = dataItem.Longitude;
        dataItem.centroid_latitude = dataItem.Latitude;
        dataItem['radius'] = 10;
      }


      setReportData(returnedData);

/*      const filterData = returnedData
        .slice(0, 1000)
        .sort((x: any, y: any) => x['Facility Name'].localeCompare(y['Facility Name']));*/
      let filterData = [...returnedData].filter(
        (o: any) => o['Permit Status Description'] === 'Effective'
      ).sort((x: any, y: any) => x['Facility Name'].localeCompare(y['Facility Name']));
      global.setfilter1Selected('Effective');
      setFilteredData(filterData);

      setIsDataReportLoaded(true);
      //updateLayerStyle();
    });
  }, [selectedHUC8Value]);

  useEffect(() => {
    updateLayerStyle();
    updateHoverLayerStyle();
  }, [isDataReportLoaded]);


  useEffect(() => {
    const featureCollectionFromReportData = featureCollection(filteredData);
    setFeatureData(featureCollectionFromReportData);
    if (featureCollectionFromReportData.features.length > 0) {
      if (mapRef && mapRef.current) {
        const mapRef_ = mapRef;
        setTimeout(() => {
          fitBoundsMapData(featureCollectionFromReportData, mapRef_);
        }, 400);
      }
    }
  }, [filteredData]);

  const filterReportData = () => {
    let filterData = [...reportData];
    if (
      selectedFilterLocations &&
      selectedFilterLocations.length > 0 &&
      selectedFilterLocations.indexOf('All') < 0
    ) {
      filterData = filterData.filter(
        (o: any) => selectedFilterLocations.indexOf(o['Facility Name']) > -1
      );
    }
    if (
      selectedPermitDescription &&
      selectedPermitDescription.length > 0 &&
      selectedPermitDescription !== 'All'
    ) {
      filterData = filterData.filter(
        (o: any) => selectedPermitDescription.indexOf(o['Permit Status Description']) > -1
      );
    }
    if (selectedSic && selectedSic.length > 0 && selectedSic.indexOf('All') < 0) {
      filterData = filterData.filter((o: any) => selectedSic.indexOf(o['Sic Codes']) > -1);
    }
    filterData = filterData
      .slice(0, 3000)
      .sort((x: any, y: any) => x['Facility Name'].localeCompare(y['Facility Name']));
    setFilteredData(filterData);
  };

  const featureCollection = (featureData: any) => {
    const features: Array<Feature<Point, GeoJsonProperties>> = [];
    const addedDMR: any[] = [];
    let reportDate = Date.now();
    const dateSubset_ = featureData;
    for (let dataItem of dateSubset_) {
      if (dataItem) {
        const featureWithPoint: Feature<Point> = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [dataItem.Longitude, dataItem.Latitude],
          },
          properties: dataItem,
        };
        if (addedDMR.indexOf(dataItem.dmrid) < 0) {
          features.push(featureWithPoint);
          addedDMR.push(dataItem.dmrid);
        }
      }
    }
    const featureCollectionFromReportData: FeatureCollection = {
      type: 'FeatureCollection',
      features: features,
    };
    setFeatureData(featureCollectionFromReportData);
    return featureCollectionFromReportData;
  };

  const fitMapData = (reportData: any) => {
    const featureCollectionFromReportData = {
      type: 'FeatureCollection',
      features: [] as any,
    };
    for (let dataItem of reportData) {
      const feature = {
        type: 'Feature',
        properties: dataItem,
        geometry: {
          type: 'Point',
          coordinates: [dataItem.centroid_longitude, dataItem.centroid_latitude],
        },
      };
      feature.properties.HUC12 = dataItem.huc12;
      feature.properties.HUC8 = selectedHUC8Value;
      featureCollectionFromReportData.features.push(feature);
    }
    if (featureCollectionFromReportData.features.length > 0) {
      const bbox_ = bbox(featureCollectionFromReportData);
      if (mapRef && mapRef.current) {
        mapRef.current.fitBounds(
          [
            [bbox_[0], bbox_[1]],
            [bbox_[2], bbox_[3]],
          ],
          {
            padding: { top: 50, bottom: 25, left: 45, right: 45 },
          }
        );
      }
    }
  };

  const updateLayerStyle = () => {
    const layerStyle_: LayerProps = {
      id: 'pointlayer',
      type: 'circle' as const,
      paint: {
        'circle-radius': ['get', 'radius'],
        'circle-color': ['get', 'color'],
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1,
        'circle-opacity': 0.99,
      },
    };
    setLayerStyle(layerStyle_);
  };



  const updateHoverLayerStyle = () => {
    const hoverStyle: LayerProps = {
      id: 'hoverlayer',
      type: 'circle' as const,
      paint: {
        'circle-radius': 12,
        'circle-color': '#000099',
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1,
        'circle-opacity': 1,
      },
    };

    setHoverLayerStyle(hoverStyle);
  }

  const tableColumns = useMemo(
    () => [
      { Header: 'Facility Name', accessor: 'Facility Name' },
      {
        Header: 'Address',
        accessor: 'Address',
      },
      {
        Header: 'City',
        accessor: 'City',
      },
      {
        Header: 'State',
        accessor: 'State',
      },
      {
        Header: 'County',
        accessor: 'County',
      },
      {
        Header: 'Permit No.',
        accessor: 'Permit Number',
      },
      {
        Header: 'Permit Name',
        accessor: 'Permit Name',
      },
      {
        Header: 'Permit Status Code',
        accessor: 'Permit Status Code',
      },
      {
        Header: 'Permit Description',
        accessor: 'Permit Description',
      },
      {
        Header: 'Permit Components',
        accessor: 'Permit Components',
      },
      {
        Header: 'Last Inspection',
        accessor: 'Cwp Last Inspection Date',
        Cell: (props: any) => (props.value) ? DateTime.fromMillis(props.value).toLocaleString() : '',
      },
      {
        Header: 'Permit Effective Date',
        accessor: 'Permit Effective Date',
        Cell: (props: any) => (props.value) ? DateTime.fromMillis(props.value).toLocaleString() : '',
      },
      {
        Header: 'Permit Expiration Date',
        accessor: 'Permit Expiration Date',
        Cell: (props: any) => (props.value) ? DateTime.fromMillis(props.value).toLocaleString() : '',
      },
      {
        Header: 'Permit Termination Date',
        accessor: 'Permit Termination Date',
        Cell: (props: any) => (props.value) ? DateTime.fromMillis(props.value).toLocaleString() : '',
      },
      {
        Header: 'Last Formal EA Date',
        accessor: 'Last Formal Enforcement Action (Ea) Date',
        Cell: (props: any) => (props.value) ? DateTime.fromMillis(props.value).toLocaleString() : '',
      }
    ],
    []
  );

  const labelForValue = (labelvalue: any, index: number) => {
    return labelvalue;
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: 'Design Flow Number (MGD)',
          color: 'white',
        },
        max: 5,
      },
      x: {
        display: true,
        title: {
          display: false,
          text: 'Facility',
          color: 'white',
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
          color: 'white',
        },
        padding: 10,
      } as const,
      title: {
        display: true,
        text: 'Design Flow Number (MGD) by Facility',
        color: 'white',
        align: 'center',
        padding: 10,
      } as const,
    } as const,
  } as const;

  const labels = [''];
  const data = {
    labels,
    datasets: [
      {
        label: 'Facility',
        data: [1],
        backgroundColor: '#18244c',
      },
    ],
  };

  useEffect(() => {
    setChartData(data);
  }, []);

  const hoverRowHandler = (event: any) => {
    const chartlabels = [' '];
    const chartdatasets = [
      {
        label: event['Facility Name'],
        data: [event['Design Flow Number (Mgd)']],
        backgroundColor: '#0b61bf',
      },
    ];
    setUserSelectedRecord(event);
    setUserSelectedLocation(event.dmrid);
    setMapHoverFilter(['in', ['get', 'dmrid'], event.dmrid]);
    setChartData({ labels: chartlabels, datasets: chartdatasets });
    setPopupInfo(event);
  };

  const clickRowHandler = (event: any) => {
    if (userSelectedLocations.indexOf(event.dmrid) > -1) {
      setUserSelectedLocations([]);
      setPopupInfo(null);
    } else {
      setUserSelectedLocations([event.dmrid]);
      setPopupInfo(event);
      if (mapRef.current) {
        if (event && event.centroid_longitude) {
          mapRef.current?.flyTo({
            center: [parseFloat(event.centroid_longitude), parseFloat(event.centroid_latitude)],
          });
        }
      }
    }
  };

  const onMouseEnter = useCallback((event: any | null) => {
    if (event.features && event.features[0] && event.features[0].properties) {
      setCursor('pointer');
      setPopupInfo(event.features[0].properties);
      setUserSelectedLocation(event.features[0].properties[locationField]);
      setMapHoverFilter([
        'in',
        ['get', locationField],
        event.features[0].properties[locationField],
      ]);
    }
  }, []);

  const onMouseMove = useCallback((event: any | null) => {
    if (event.features && event.features[0] && event.features[0].properties) {
      if(event.features[0].properties[locationField]!==userSelectedLocation){
        setPopupInfo(event.features[0].properties);
        setUserSelectedLocation(event.features[0].properties[locationField]);
        setMapHoverFilter([
          'in',
          ['get', locationField],
          event.features[0].properties[locationField],
        ]);        
      }
    }
  }, []);

  const onMouseLeave = useCallback((event: any | null) => {
    setCursor('');
    if (userSelectedLocation) {
      setUserSelectedLocation(null);
    }
    if (event && event.features && event.features[0]) {
      setPopupInfo(null);
      setMapHoverFilter(['in', ['get', locationField], '']);
    }
  }, []);

  useEffect(() => {
    if (usLocations.length > 0) {
      if (userSelectedLocations.indexOf(usLocations[0][locationField]) > -1) {
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', locationField], '']);
      } else {
        setUserSelectedLocations(usLocations[0][locationField]);
        setPopupInfo(usLocations[0]);
        setTimeout(() => {
          setMapHoverFilter(['in', ['get', locationField], usLocations[0][locationField]]);
        }, 111);
      }
    }
  }, [usLocations]);
  
  const onHandleClick = useCallback((event: any | null) => {
    const feature = event.features && event.features[0];
    if (feature) {
      setUsLocations([feature.properties]);
    }
  }, []);

  const onMapLoad = useCallback(() => {
    if (mapRef && mapRef.current) {
      setMapLoaded(true);
      mapRef.current.resize();
    }
  }, []);

  const pointInPolygonUpdate = (pointsSelected: any) => {
        setUserSelectedLocations(pointsSelected);
        setMapHoverFilter(['in', ['get', locationField], ['literal', pointsSelected]]);
  }



  const onDelete = useCallback((e: any | null) => {
    if (mapRef && mapRef.current) {
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', locationField], '']);
    }
  }, []);



  const onHoverOut = useCallback((e: any | null) => {
    setPopupInfo(null);
    setMapHoverFilter(['in', ['get', locationField], '']);
  }, []);

  const deselectFilters = useCallback((e: any | null) => {
    setPopupInfo(null);
    setMapHoverFilter(['in', ['get', locationField], '']);
    setUserSelectedLocations([]);
  }, []);

  const renderPopup = () => {
    return (
      <Popup
        
        longitude={Number(popupInfo.lng)}
        latitude={Number(popupInfo.lat)}
        onClose={() => setPopupInfo(null)}
      >
        <div className='popup-container'>
          <h4>{popupInfo['hucname']}</h4>
          <div className='popup-content'>
            <div className='popup-content-left'>
              <p className='popup-content-right-triple'>Facility Name</p>
              <p className='popup-content-right-double'>Permit Name</p>
              <p className='popup-content-right-double'>Permit No.</p>
              <p>Permit Status</p>
            </div>
            <div className='popup-content-right'>
              <p className='popup-content-right-triple popup-content-highlight'>{popupInfo['Facility Name']}</p>
              <p className='popup-content-right-double'>{popupInfo['Permit Name']}</p>
              <p className='popup-content-right-double'>{popupInfo['Permit Number']}</p>
              <p>{popupInfo['Permit Status Description']}</p>
            </div>
          </div>
        </div>
      </Popup>
    );
  };
  
  return (
    <>
      <FullScreenComp handle={fullscreenhandle}>
        <div className='PermitDischargeReport'  id='PermitDischargeReport-report'>
          <ReportHeader global={global} data={filteredData} mapRef={mapRef} 
            reportID={"PermitDischargeReport-report"} fullScreenClickHandle={fullscreenhandle.enter} />
          <div className='container'>
            <div className='col main-col'>
              <div className='row gx-0'>
                <div className='map-best-container'>
                  <LoadingDataAnimation dataLoading={!isDataReportLoaded} />
                  <Map
                    id='PermitDischargeReport'
                    mapboxAccessToken={settings.maboxKey}
                   mapStyle={global.mapStyle}
                    onLoad={onMapLoad}
                    onClick={(e) => {
                      global.onMapClick(e)
                      onHandleClick(e)
                    }}
                    onMoveEnd={(e) => {
                      global.setViewport({
                        longitude: e.viewState.longitude,
                        latitude: e.viewState.latitude,
                        zoom: e.viewState.zoom,
                      })
                    }}
                    onMouseEnter={onMouseEnter}
                    onMouseMove={onMouseMove}
                    onMouseLeave={onMouseLeave}
                    preserveDrawingBuffer={true}
                    interactiveLayerIds={['pointlayer']}
                    cursor={cursor}
                    ref={mapRef as Ref<MapRef>}
                    projection={global.globeView ? 'globe' : 'mercator' as any}
                  >
                    {featureData && (
                      <Source id='circlesource' type='geojson' data={featureData}>
                        <Layer {...layerStyle} />
                        <Layer {...hoverLayerStyle} filter={mapHoverFilter} />
                      </Source>
                    )}

                    {popupInfo && renderPopup()}
                
                    <MapAddition global={global} 
                                mapRef={mapRef}
                                onDelete={onDelete}
                                PointInPolygonField={locationField}
                                PointInPolygonUpdate={pointInPolygonUpdate}
                                position={'low'}
                                zipOff={true}
                                statusMessage={statusMessage}/>

                    <div className='map-legend-container' onClick={deselectFilters}>
                      <MapLegend 
                        mapLegendData={mapLegendData} 
                        title={'Permit Status Description'}
                        global={global} 
                        legendWidth={320}
                      />
                    </div>
                  </Map>
                </div>
              </div>

              <OverlayTrigger
                placement='top-start'
                overlay={
                  <TooltipRB className='pdr-chart-tooltip'>
                    <div className='pdr-chart-tooltip-container'>
                      <div className='pdr-chart-container'>
                        <Bar options={options} data={chartData} />
                      </div>
                      <div className='pdr-summary-container'>
                        <Card
                          key={'index' + Math.random() * 5}
                          id={'variant.dmrid' + '_' + 'index'}
                          text='light'
                          className={'mb-2 te-primary pdr-summary-container-card'}
                        >
                          <Card.Body>
                            <Card.Title className={'pdr-summary-container-card-title'}>
                              <p>
                                {userSelectedRecord
                                  ? userSelectedRecord['Major Or Minor Discharger']
                                  : ''}
                              </p>
                              <p className='pdr-summary-field-name'>Major Or Minor Discharger</p>
                              <p>
                                {userSelectedRecord
                                  ? d3Format.format(',')(
                                    userSelectedRecord[
                                    'Pounds Of Pollutants Discharged In Last Complete (Cy)'
                                    ]
                                  )
                                  : ''}
                              </p>
                              <p className='pdr-summary-field-name'>
                                Lbs Of Pollutants Discharged In Last Complete (Cy)
                              </p>
                            </Card.Title>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </TooltipRB>
                }
              >
                <div
                  className={
                    isFullScreenActive
                      ? 'row gx-0 table-chart-row table-parent-container expand-container'
                      : 'row gx-0 table-chart-row table-parent-container'
                  }
                  onMouseLeave={onHoverOut}
                >
                  <div className='report-options'>
                    <div className='report-options-button-container'>
                      <Button onClick={fullscreenContainerHandler}>
                        <img src={fullscreenIcon} />
                      </Button>
                    </div>
                  </div>
                  <ReportTableExpanded
                    data={filteredData}
                    columns={tableColumns}
                    clickLoadStop={true}
                    clickRowHandler={clickRowHandler}
                    hoverRowHandler={hoverRowHandler}
                  />
                </div>
              </OverlayTrigger>
              <ReportFooter />
            </div>
          </div>
        </div>
        <MapSlider global={global} />
      </FullScreenComp>
    </>
  );
}

type PermitDischargeReportModel = {
  date?: number;
  huc12?: string;
};
