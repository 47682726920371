import { useControl } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

export default function DrawControl(props: any) {
  useControl(() => new MapboxDraw(props), {
    position: props.position,
  });

  return null;
}
