import ReportsRoutes from '../../ReportsRoutes';
import React, {
  PropsWithChildren
} from 'react';
import ContactAdmin from '../../layout/ContactAdmin';
import ContactForm from '../../layout/ContactForm';

// Does the logged in User's Role have permission for this path
const RoleRestricted = ({ children, path }: PropsWithChildren<any>) => {
  const matchingRestriction = roleRestrictions.find(
    ({ role }) => role === children.props.global.userRole
  );

  if (matchingRestriction) {	
	    const { routes } = matchingRestriction;
	    const matchingRoute = routes.find( (route: any) => route === path);
	    if (matchingRoute) {
			return children;
		} else {
			return UserRestricted({children, path});
		}
  } else {
  	return UserRestricted({children, path});
  }
}

// If not Role permitted, check explicit User Permissions
const UserRestricted = ({ children, path, global }: PropsWithChildren<any>) => {
  const matchingRestriction = userRestrictions.find(
    ({ route }) => route === path
  );

  if (matchingRestriction && children && children.props && children.props.global && children.props.global.userInfo) {
  	if(matchingRestriction.Users.indexOf(children.props.global.userInfo.email)>-1){
  		return children;	
  	} else {
  		return <ContactAdmin global={children.props.global} logged={true}/>;
  	}
  } else {
  	return <ContactAdmin global={children.props.global} logged={true}/>;
  }
}

// Roles and the routes that are permitted
const roleRestrictions = [
	  {
	    "role": "te-admin",
	    "routes": [ReportsRoutes.Chat]
	  }
] ;

// User permitted routes
const userRestrictions = [
	  {
	  	"route": ReportsRoutes.Chat,
	    "Users": ["varunkumar@trueelements.com"]
	  }
] ;

export default RoleRestricted;


