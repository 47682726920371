import "./input-form.scss";
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import ErrorDisplay from "./ErrorDisplay";
import Render from "./render";

interface InputModel{
    value?: string;
    setValue?: any; 
    label?: string; 
    errorMessage?: string;
    isNumberFormat?: boolean | false; 
    useUnformattedValue?: boolean | false;
    colSize?: string;
    placeholder?: string;
    type?: string;
    className?: string;
}

function FormInput (props:InputModel) { 
    const {
        value,
        setValue,
        label,
        errorMessage,
        isNumberFormat,
        useUnformattedValue,
        colSize,
        placeholder,
        type
    } = props;

    let className = 'form-control'

    if(props.className) { 
        className += ` ${props.className}`;
    }    

    const handleChange = (e:any) => {
      if(!useUnformattedValue) {
        setValue && setValue(e.target.value);
      }
    }

    return (
        <div className={"form-input-wrapper " + colSize}>
            <input 
                value={value} 
                type={(type ? type : 'text')} 
                onChange={handleChange} 
                className={className}
                placeholder={placeholder}
                />
                <Render condition={errorMessage != undefined}>
                    <ErrorDisplay error={errorMessage}/>
                </Render>
        </div>
    );
};

FormInput.propTypes = {
    className: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string,
    defaultValue: PropTypes.any,
    setValue: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string)
}

export default FormInput;