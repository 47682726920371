import { Source, Layer, Popup, FillLayer, CircleLayer, LineLayer } from 'react-map-gl';
import settings from "./../../settings.json";
import { useState, useContext, useEffect } from 'react';
import { RefContext } from "./../../RefContext";
import { PointContext } from "./../../PointContext";
import { v4 as uuidv4 } from 'uuid';

const RiverGauge = ({ id, url, opacity, global }: any) => {
  let rangeSel = "nws";  
  let categorySel = "observed";  
  let _currentUrl = `https://hazards.fema.gov/arcgis/rest/services/FIRMette/NFHLREST_FIRMette/MapServer/export?bbox={bbox-epsg-3857}&dpi=96&transparent=true&format=png32&layers=show:0,2,4,5,7,8,9,12,13,14,15,18,19,20,21&bboxSR=102100&imageSR=102100&f=image`

  const [currentUrl, setCurrentUrl] = useState<any>(_currentUrl);
  const [currentRange, setCurrentRange] = useState<any>(rangeSel);
  const [currentCategory, setCurrentCategory] = useState<any>(categorySel);  

  const { currentRef } = useContext(RefContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
  // @ts-ignore
  const mapInstance = currentRef && currentRef?.current;







 

  return (
    <div>
    <Source
      id={`${id}-layer`}
      key={`${id}-layer`+'currentRange'}
      type="raster"
      tiles={[currentUrl]}>
      <Layer      
        beforeId={settings.defaultBeforeId}
        paint={{ 'raster-opacity': opacity }}
        type="raster" id={`${id}-layer`}
        source={`${id}-ayer`}       
      />
    </Source>

    </div>
  )
}

export default RiverGauge