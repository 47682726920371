import settings from "../../../settings.json";

const RiskCategories = [{
    title: 'Overview',
    active: 'true',
  }, 
  { //*** 'title' changes are test breaking, internal identifier for RiskEvaluations.tsx,  AVOID changing
    // 'titlesection' is the display name and easily modified
    title: 'Climate Change Precipitation Risk',
    titlesection: 'Climate Change Precipitation Risk Creep',
    text: 'Sites assessed for risk based on physical address & year of construction or last update.',
    textgreen: 'Green rating means precipitation risk of a 100 year storm over the next 30 years is declining or has increased by less than 10%.',
    textyellow: 'Yellow means risk has increased by 10% to 25% ',
    textred: 'Red means risk is greater than 25% ',  
    assessmentfield: 'Rate Increase of Risk',
    attribution: 'NOAA Atlas 14 Point Precipitation Frequency Estimates',
    attribution_url: "https://www.weather.gov/owp/hdsc",
    attribution2: 'True Elements AI Engine Generator',
    attribution_url2: "https://trueelements.com/our-service/",
    sasbref: '',
    location: 'us',
    active: 'true',
  }, 
  {
    title: 'Site Water Security / Distress',
    titlesection: 'Site Water Security / Distress',
    text: 'Sites assessed for water stress by measuring the ratio of total water withdrawals to available renewable surface and groundwater supplies. ',
    textgreen: 'Green rating means the site is located within a watershed that has a ratio of 20% or less.',
    textyellow: 'Yellow means the ratio of use is between 20% & 40% ',
    textred: 'Red means the ratio of is greater than 40%  ',  
    assessmentfield: 'Baseline Water Stress Score',
    attribution: 'Aqueduct - World Resources Institute v4.0',
    attribution_url: "https://www.wri.org/water/water-security",
    icon: 'aqueducts',
    sasbref: 'SASB: SV-HL-140a.1 ',
    sasbrefid: '140a.1 ',
    maplayerset: '',
    location: 'international',
    active: 'true',
  },
  {
    title: 'Site Flood Zone Ratings',
    titlesection: 'Site Flood Zone Ratings',
    text: 'Site assessed for risk based on the FEMA defined zone that the physical site is located within.  ',
    textgreen: 'Green means the site is in an area of minimal flood hazard not within a FEMA defined flood zone.',
    textyellow: 'Yellow means the site is within a Moderate flood hazard area, the 500 year flood zone.',
    textred: 'Red means the site is within a 100 year flood zone (Zones A & Z). Areas with a 1% annual chance of flooding.',  
    assessmentfield: 'Flood Zone',
    attribution: 'FEMA Flood Hazard Zones',
    attribution_url: 'https://www.fema.gov/blog/fema-flood-maps-and-zones-explained',
    icon: 'fema',
    sasbref: 'SASB: SV-HL-450a.1',
    sasbrefid: '450a.1',   
    sasbsection: 'Climate Change Adaptation',
    location: 'us',
    greenResult: 'Minimal Flood Hazard',
    yellowResult: 'Moderate Flood Hazard',
    redResult: 'High Flood Hazard',
    updateonload: 'true',
    active: 'true',
  },
  {
    title: 'Site Surface Water Quality Threshold',
    titlesection: 'Site Surface Water Quality Threshold',
    text: 'Surface Water Quality is a calculation of the quality of the multiple factors that combine to create your water environment.     ',
    textgreen: 'Green rating means that Surface Water quality score is 90+ ',
    textyellow: 'Yellow means that the Surface Water quality score is 80 – 90 ',
    textred: 'Red means that Surface Water quality is below 80  ',  
    textadditional: 'If your site relies on local surface & groundwater than a red rated site has high operational continuity risk or risk of significant water price increases or water remediation charges.   ', 
    assessmentfield: 'Subwatershed',
    attribution: 'True Elements AI Engine Generator',
    attribution_url: 'https://trueelements.com/our-service/',
    icon: 'True Elements',
    sasbref: ' ',
    location: 'us',
    updateonload: 'true',
    active: 'true',
  },
  {
    title: 'PFAS Detection in Proximity',
    titlesection: 'PFAS Detection in Proximity',
    text: "PFAS risk is determined by assessing whether PFAS has been detected within the watershed or ones that immediately impact your location.  ",
    textgreen: 'Green rating means that no PFAS has been reported within the watershed of your site or the watershed(s) that directly feed it. ',
    textyellow: 'Yellow rating means that PFAS has not been detected within the watershed of your site but has been detected in a nearby local watershed (HUC 8 level)  ',
    textred: 'Red rating means that PFAS has been detected within the watershed (HUC 12) that your site is located in or a watershed that directly feeds your watershed. ', 
    assessmentfield: '',
    attribution: 'EPA National PFAS Datasets',
    attribution_url: "https://echo.epa.gov/trends/pfas-tools",
    attribution2: 'EPA Unregulated Contaminant Monitoring Report 1-5 (2001-2023)',
    attribution_url2: 'https://www.epa.gov/dwucmr',
    sasbref: '',
    location: 'us',
    tileset: '${settings.tileSetURLs.outflows.substr(9)}',
    active: 'true',
  },
  {
    title: 'Superfund in Close Proximity',
    titlesection: 'Superfund in Close Proximity',
    text: "Superfund risk is determined by assessing whether an EPA declared Superfund site has been detected within the watershed or the watersheds that immediately impact your location.  ",
    textgreen: 'Green rating means that no Superfund sites are within the watershed of your site or the watershed(s) that directly feed it.',
    textyellow: 'Yellow rating means that a Superfund site has not been detected within the watershed of your site but has been detected in a watershed that directly feeds your watershed (HUC 12 level).',
    textred: 'Red rating means that a Superfund site has been detected within the watershed (HUC 12) that your site is located in. ', 
    assessmentfield: 'Superfund Proximity EJ Index',
    attribution: 'EPA Superfund',
    attribution_url: "https://www.epa.gov/superfund",
    icon: 'EPA',
    sasbref: '',
    location: 'us',
    active: 'true',
  },
  {
    title: 'Drought Status',
    titlesection: 'Drought Status',
    text: "Site assessed for Drought risk based NOAA’s National Integrated Drought Information System.  ",
    textgreen: 'Green means the site’s drought rating is not officially rated as being in a drought.',
    textyellow: 'Yellow means the site is located within an area deemed Abnormally Dry (D0) or Moderate Drought (D1).',
    textred: 'Red means the site is located within an area deemed Severe Drought (D3), Extreme Drought (D4) or Exceptional Drought (D5).', 
    assessmentfield: 'Drought category',
    attribution: 'National Integrated Drought Information System (NIDIS)',
    attribution_url: "https://www.drought.gov/about#:~:text=The%20National%20Oceanic%20and%20Atmospheric%20Administration%E2%80%99s%20%28NOAA%27s%29%20National,creating%20a%20national%20drought%20early%20warning%20information%20system.",
    attribution2: 'National Oceanic Atmospheric Association (NOAA)',
    attribution_url2: "https://www.noaa.gov/topic-tags/drought",
    icon: 'NOAA NIDIS',
    sasbref: '',
    location: 'us',
    updateonload: 'true',
    active: 'true',
  },
  {
    title: 'Located within a Protected Area',
    titlesection: 'Located within a Protected Area',
    text: "Site is assessed as a risk if it is located within a protected area as determined within the World Database on Protected Areas (WDPA). ",
    textgreen: 'Green means that the site is not located within a Protected area. ',
    textyellow: ' ',
    textred: 'Red means that the site is located within a Protected area .', 
    assessmentfield: 'Protected Area',
    attribution: 'World Database on Protected Areas (WDPA)',
    attribution_url: "https://www.protectedplanet.net/en/thematic-areas/wdpa?tab=WDPA",
    icon: 'WDPA',
    sasbref: 'SASB: SV-HL-160a.1 ',
    sasbrefid: '160a.1',
    sasbsection: 'Ecological Impacts',
    location: 'international',
    active: 'true',
  },
/*  {
    title: 'Sea Level Rise',
    text: "Site is assessed as a risk if it is located within a geography that is at risk for flooding from reasons outside of precipitation changes. ",
    textgreen: 'Green rating means that flood risk from these factors over the next 30 years is declining or has increased by <10%.   ',
    textyellow: 'Yellow means risk has increased by >10% to 25%  ',
    textred: 'Red means risk is > 25%    ', 
    attribution: ' ',
    attribution_url: " ",
    sasbref: '',
  },*/
  {
    title: 'Riverine Flood Risk',
    titlesection: 'Riverine Flood Risk',
    text: "Site is assessed as a risk if it is located within a geography that is at risk for flooding from reasons outside of precipitation changes.  ",
    textgreen: 'Green rating means that flood risk from these factors over the next 30 years is declining or has increased by <10%. ',
    textyellow: 'Yellow means risk has increased by >10% to 25% ',
    textred: 'Red means risk is > 25%  ', 
    assessmentfield: 'RFR score',
    attribution: 'Aqueduct Water Risk Atlas v4.0',
    attribution_url: "https://www.wri.org/applications/aqueduct/floods/",
    icon: 'aqueducts',
    sasbref: '',
    location: 'international',
    active: 'true',
  },
  {
    title: 'Coastal Flood Risk',
    titlesection: 'Coastal Flood Risk',
    text: 'Along the coast, the flood map has delineated coastal areas where the source of flooding is from coastal hazards, such as storm surge and waves. ',
    textgreen: 'Green rating means that flood risk from these factors over the next 30 years is declining or has increased by <10%. ',
    textyellow: 'Yellow means risk has increased by >10% to 25% ',
    textred: 'Red means risk is > 25%  ', 
    assessmentfield: 'CFR score',
    attribution: 'Aqueduct Water Risk Atlas v4.0',
    attribution_url: "https://www.wri.org/research/aqueduct-40-updated-decision-relevant-global-water-risk-indicators",
    icon: 'aqueducts',
    sasbref: '',
    location: 'international',
    active: 'true',
  },
  {
    title: 'Water Management',
    titlesection: 'Water Management',
    text: 'Sites assessed for water stress by measuring the ratio of total water withdrawals to available renewable surface and groundwater supplies. ',
    textgreen: 'Green rating means the site is located within a watershed that has a ratio of 20% or less.',
    textyellow: 'Yellow means the ratio of use is between 20% & 40% ',
    textred: 'Red means the ratio of is greater than 40%  ',  
    attribution: 'Aqueduct - World Resources Institute',
    attribution_url: "https://www.wri.org/water/water-security",
    icon: 'aqueducts',
    sasbref: 'SASB: SV-HL-140a.1 ',
    sasbrefid: '140a.1',
    sasbsection: 'Water Management',
    location: 'international',
    active: 'true',
  },
  {
    title: 'Ingredient Sourcing',
    titlesection: 'Ingredient Sourcing',
    text: 'Sites assessed for water stress by measuring the ratio of total water withdrawals to available renewable surface and groundwater supplies. ',
    textgreen: 'Green rating means the site is located within a watershed that has a ratio of 20% or less.',
    textyellow: 'Yellow means the ratio of use is between 20% & 40% ',
    textred: 'Red means the ratio of is greater than 40%  ',  
    attribution: 'Aqueduct - World Resources Institute',
    attribution_url: "https://www.wri.org/water/water-security",
    icon: 'aqueducts',
    sasbref: 'SASB: FB-AG-440a.1',
    sasbrefid: '440a.1',
    sasbsection: 'Ingredient Sourcing',
    location: 'international',
    active: 'true',
  },
  {
    active: 'true',
    title: 'Wildfire',
    titlesection: 'Wildfire',
    text: 'Sites assessed includes perimeters for wildland fire incidents that meet the following criteria: Categorized in the IRWIN (Integrated Reporting of Wildland Fire Information) integration service as a valid Wildfire (WF), Prescribed Fire (RX), or Incident Complex (CX) record Is not "quarantined" in IRWIN ',
    textgreen: 'Green rating means the site is located within a low probablity of wildfire for a given year.',
    textyellow: 'Yellow means a medium risk for wildfire',
    textred: 'Red means a high to very high probablity of wildfire',  
    attribution: 'Wildland Fire Interagency Geospatial Services',
    attribution_url: "https://www.nwcg.gov/publications/pms936/nifs/public-distribution",
    attribution2: 'Wildfire Risk to Communities (NOAA)',
    attribution_url2: "https://coast.noaa.gov/digitalcoast/tools/wildfire-risk.html",
    icon: 'aqueducts',
    sasbref: '',
    location: 'us',   
  },
  {
    active: 'true',
    title: 'Watershed Health 303(d)',
    titlesection: 'TrueQI Watershed Health 303(d) ',
    text: 'Watershed Health is a calculation of the quality of the multiple factors that combine to create your water environment.     ',
    textgreen: 'Green rating means that Watershed Health quality score is 90+ ',
    textyellow: 'Yellow means that the Watershed Health quality score is 80 – 90 ',
    textred: 'Red means that Watershed Health quality is below 80  ',  
    textadditional: 'If your site relies on local surface & groundwater than a red rated site has high operational continuity risk or risk of significant water price increases or water remediation charges.   ', 
    attribution: 'Clean Water Act Section 303(d): Impaired Waters and Total Maximum Daily Loads (TMDLs)',
    attribution_url: "https://www.epa.gov/tmdl",
    attribution2: 'True Elements AI Engine Generator',
    attribution_url2: "https://trueelements.com/our-service/",
    icon: 'EPA',
    sasbref: '',
    location: 'us',   
  }
];

export default RiskCategories;
