import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import * as d3Scale from 'd3-scale';


export const setChartConfig = (county: string, selectedCategory: string, setConfig: any) => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: true,
      scales: {
        y: {
          display: true,
          title: {
            display: true,
            text: 'Days over Threshold',
            color: 'white'
          },
        },
        x: {
          display: true,
          title: {
            display: true,
            text: selectedCategory + ' Recurrence',
            color: 'white'
          },
        },
      },
      plugins: {
        legend: {
          position: "bottom" as const,
          labels: {
            usePointStyle: true,
          },
        } as const,
        title: {
          display: true,
          text: "Extreme Precipitation Events in " + county,
          color: 'white',
          align: 'center',
          padding: 10,
          font: {
            size: 20
          }
        } as const,
        tooltip: {
          padding: 10,
          bodyFont: {
            size: 24
          },
          titleFont: {
            size: 24
          },
          boxPadding: 8,
          usePointStyle: false,
          backgroundColor: '#12234f',
          callbacks: {
            label: function (context: any) {
              let label = context.dataset.label || '';
              if (label) {
                label = [context.dataset.label, d3Format.format(".2r")(context.parsed.y) + ' Days over Threshold']
              }
              return label;
            }
          }
        } as const,
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'xy',
          },
        } as const,
      } as const,
    } as const;
  setConfig(options);
}

export const setChartConfigStorm  = (setConfigStorm: any) => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: true,
      scales: {
        y: {
          display: true,
          min: 0,
          title: {
            display: true,
            text: 'Expected Precipitation (inches)',
            color: 'white'
          },
        },
        x: {
          display: true,
          title: {
            display: true,
            text: 'Year',
            color: 'white'
          },
        },
      },
      plugins: {
        legend: {
          position: "bottom" as const,
          labels: {
            usePointStyle: true,
          },
        } as const,
        title: {
          display: true,
          text: "Storm Intensity: Inches vs Year for each Storm Recurrence",
          color: 'white',
          align: 'center',
          padding: 10,
          font: {
            size: 20
          }
        } as const,
        tooltip: {
          padding: 10,
          bodyFont: {
            size: 24
          },
          titleFont: {
            size: 24
          },
          boxPadding: 8,
          usePointStyle: false,
          backgroundColor: '#12234f',
          callbacks: {
            label: function (context: any) {
              let label = context.dataset.label || '';
              if (label) {
                label = [context.dataset.label + ' Expected Precipitation', d3Format.format(".3r")(context.parsed.y) + ' inches']
              }
              return label;
            }
          }
        } as const,
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'xy',
          },
        } as const,
      } as const,
    } as const;
    setConfigStorm(options);
  }