export const LOCALSTORAGE_DEFAULTS_KEY = 'TE_defaults';
export const REPORT_KEY = 'report_key'
export const CHEMICALS_FILTER = 'CHEMICALS_FILTER';
export const INDUSTRY_SECTOR_FILTER = 'INDUSTRY_SECTOR_FILTER';
export const YEAR_FILTER = 'YEAR_FILTER';
export const LOCATIONS_FILTER = 'LOCATIONS_FILTER';
export const MEASUREMENTS_FILTER = 'MEASUREMENTS_FILTER';
export const TABLE_DATA = 'TABLE_DATA';
export const RESPONSE_DATA = 'RESPONSE_DATA';
export const message_hucrestriction = 'Please Upgrade Tier Access to View This Location';
export const message_nodatalocation = 'No data found at this location';
export const message_noresult = 'No result.';
export const message_unavailable = 'No data available at this time.';
export const message_erroroccured = 'An error occured.  Please try again later.';
export const message_welcome = 'Welcome to True Elements!';
export const message_welcome_newuser = 'Thank you for logging onto TrueQI. Your account is still being created.  A notification will be sent when your subscribed access is available.';