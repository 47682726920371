// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#EPAMajorPermitHolders-report .loading-spinner {
  justify-content: center;
  position: absolute;
  z-index: 300;
  height: 100vh !important;
  width: 100vw !important;
  align-items: center;
  background-color: #00000040;
  top: unset; }

#EPAMajorPermitHolders-report .popup-content-highlight {
  color: #49b4cd; }

#EPAMajorPermitHolders-report #mapLegendAccordion {
  display: block;
  right: 8%; }
`, "",{"version":3,"sources":["webpack://./src/features/reports/EPAMajorPermitHolders/styles.scss"],"names":[],"mappings":"AAAA;EAEI,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;EACZ,wBAAwB;EACxB,uBAAuB;EACvB,mBAAmB;EACnB,2BAA2B;EAC3B,UAAU,EAAA;;AATd;EAYI,cAAc,EAAA;;AAZlB;EAgBI,cAAc;EACd,SAAS,EAAA","sourcesContent":["#EPAMajorPermitHolders-report {\n  .loading-spinner {\n    justify-content: center;\n    position: absolute;\n    z-index: 300;\n    height: 100vh !important;\n    width: 100vw !important;\n    align-items: center;\n    background-color: #00000040;\n    top: unset;\n  }\n  .popup-content-highlight {\n    color: #49b4cd;\n  }\n\n  #mapLegendAccordion {\n    display: block;\n    right: 8%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
