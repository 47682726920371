 const LayerConfig: any[] = [
     {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "h303DTrueQI",
      id: "h303DTrueQI",
      type: "maplayer",
      opacity: "OABRMXOpacity",
      setOpacity: "setOABRMXOpacity",
      selected: "h303DTrueQI",
      setSelected: "setH303DTrueQI",
      hasBrightness: false
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Zip Codes",
      id: "zip-codes",
      type: "maplayer",
      opacity: "zipOpacity",
      setOpacity: "setZipOpacity",
      selected: "zipSelected",
      setSelected: "setZipSelected",
      brightness: "zipBrightness",
      setBrightness: "setZipBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "SkyFi Whiting IN",
      id: "SkyFiWhiting",
      type: "maplayer",
      opacity: "SkyFiWhitingOpacity",
      setOpacity: "setSkyFiWhitingOpacity",
      selected: "SkyFiWhiting",
      setSelected: "setSkyFiWhiting",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "SkyFi Austin",
      id: "SkyFiAustin",
      type: "maplayer",
      opacity: "SkyFiAustinOpacity",
      setOpacity: "setSkyFiAustinOpacity",
      selected: "SkyFiAustin",
      setSelected: "setSkyFiAustin",
    },
    {
      org: ["True Elements"],
      name: "Climate and Economic Justice Screening",
      id: "CEJST-layer",
      type: "maplayer",
      opacity: "CEJSTOpacity",
      setOpacity: "setCEJSTOpacity",
      selected: "CEJST",
      setSelected: "setCEJST",
      brightness: "CEJSTBrightness",
      setBrightness: "setCEJSTBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "USGS Monthly Public Water Usage By HUC12",
      id: "usgs_huc12",
      type: "maplayer",
      opacity: "USGSHuc12Opacity",
      setOpacity: "setUSGSHuc12Opacity",
      selected: "USGSHuc12",
      setSelected: "setUSGSHuc12",
      brightness: "USGSHuc12Brightness",
      setBrightness: "setUSGSHuc12Brightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Water Conflict",
      id: "water_conflict",
      type: "maplayer",
      opacity: "WaterConflictOpacity",
      setOpacity: "setWaterConflictOpacity",
      selected: "WaterConflict",
      setSelected: "setWaterConflict",
      brightness: "WaterConflictBrightness",
      setBrightness: "setWaterConflictBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "USGS Monthly Public Water Usage",
      id: "USGSWUC_layer",
      type: "maplayer",
      opacity: "USGSWUCOpacity",
      setOpacity: "setUSGSWUCOpacity",
      selected: "USGSWUC",
      setSelected: "setUSGSWUC",
      brightness: "USGSWUCBrightness",
      setBrightness: "setUSGSWUCBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Overture Pakistan Stream Systems",
      id: "overturePakistanStreamSystems",
      type: "maplayer",
      opacity: "OABSPKOpacity",
      setOpacity: "setOABSPKOpacity",
      selected: "OABSPK",
      setSelected: "setOABSPK",
      brightness: "OABSPKBrightness",
      setBrightness: "setOABSPKBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Overture Singapore Stream Systems",
      id: "overtureSingaporeStreamSystems",
      type: "maplayer",
      opacity: "OABSSGOpacity",
      setOpacity: "setOABSSGOpacity",
      selected: "OABSSG",
      setSelected: "setOABSSG",
      brightness: "OABSSGBrightness",
      setBrightness: "setOABSSGBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Overture Canadian Stream Systems",
      id: "overtureCanadaStreamSystems",
      type: "maplayer",
      opacity: "OABSCAOpacity",
      setOpacity: "setOABSCAOpacity",
      selected: "OABSCA",
      setSelected: "setOABSCA",
      brightness: "OABSCABrightness",
      setBrightness: "setOABSCABrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Overture Mexican Stream Systems",
      id: "overtureMexicanRiverSystems",
      type: "maplayer",
      opacity: "OABSMXOpacity",
      setOpacity: "setOABSMXOpacity",
      selected: "OABSMX",
      setSelected: "setOABSMX",
      brightness: "OABSMXBrightness",
      setBrightness: "setOABSMXBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Overture Indonesian Stream Systems",
      id: "overtureIndonesiaRiverSystems",
      type: "maplayer",
      opacity: "OABSIDOpacity",
      setOpacity: "setOABSIDOpacity",
      selected: "OABSID",
      setSelected: "setOABSID",
      brightness: "OABSIDBrightness",
      setBrightness: "setOABSIDBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Overture Philippines Stream Systems",
      id: "overturePhilippineRiverSystems",
      type: "maplayer",
      opacity: "OABSPHOpacity",
      setOpacity: "setOABSPHOpacity",
      selected: "OABSPH",
      setSelected: "setOABSPH",
      brightness: "OABSPHBrightness",
      setBrightness: "setOABSPHBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Overture Singapore River Systems",
      id: "overtureSingaporeRiverSystems",
      type: "maplayer",
      opacity: "OABRSGOpacity",
      setOpacity: "setOABRSGOpacity",
      selected: "OABRSG",
      setSelected: "setOABRSG",
    },
    {
      org: ["True Elements"],
      name: "Overture Mexican River Systems",
      id: "overtureMexicoRiverSystems",
      type: "maplayer",
      opacity: "OABRMXOpacity",
      setOpacity: "setOABRMXOpacity",
      selected: "OABRMX",
      setSelected: "setOABRMX",
      brightness: "OABRMXBrightness",
      setBrightness: "setOABRMXBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Overture Canadian River Systems",
      id: "overtureCanadaRiverSystems",
      type: "maplayer",
      opacity: "OABRCAOpacity",
      setOpacity: "setOABRCAOpacity",
      selected: "OABRCA",
      setSelected: "setOABRCA",
      brightness: "OABRCABrightness",
      setBrightness: "setOABRCABrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Overture Pakistan River Systems",
      id: "overturePakistanRiverSystems",
      type: "maplayer",
      opacity: "OABRPKOpacity",
      setOpacity: "setOABRPKOpacity",
      selected: "OABRPK",
      setSelected: "setOABRPK",
      brightness: "OABRPKBrightness",
      setBrightness: "setOABRPKBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Overture Philippines River Systems",
      id: "overtureIndonesiaRiverSystems",
      type: "maplayer",
      opacity: "OABRPHOpacity",
      setOpacity: "setOABRPHOpacity",
      selected: "OABRPH",
      setSelected: "setOABRPH",
      brightness: "OABRPHBrightness",
      setBrightness: "setOABRPHBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Overture Indonesia River Systems",
      id: "overtureIndonesiaRiverSystems",
      type: "maplayer",
      opacity: "OABRIDOpacity",
      setOpacity: "setOABRIDOpacity",
      selected: "OABRID",
      setSelected: "setOABRID",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Overture Global Water Treatment Data",
      id: "OGWTD-line-layer",
      type: "maplayer",
      opacity: "OGWTDOpacity",
      setOpacity: "setOGWTDOpacity",
      selected: "OGWTD",
      setSelected: "setOGWTD",
      brightness:"OGWTDBrightness",
      setBrightness: "setOGWTDBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Overture Administrative Boundaries US",
      id: "OABUS-line-layer",
      type: "maplayer",
      opacity: "OABUSOpacity",
      setOpacity: "setOABUSOpacity",
      selected: "OABUS",
      setSelected: "setOABUS",
      brightness: "OABUSBrightness",
      setBrightness: "setOABUSBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Overture Administrative Boundaries Mexico",
      id: "OABMX-line-layer",
      type: "maplayer",
      opacity: "OABMXOpacity",
      setOpacity: "setOABMXOpacity",
      selected: "OABMX",
      setSelected: "setOABMX",
      brightness: "OABMXBrightness",
      setBrightness: "setOABMXBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Overture Administrative Boundaries Indonesia",
      id: "OABID-line-layer",
      type: "maplayer",
      opacity: "OABIDOpacity",
      setOpacity: "setOABIDOpacity",
      selected: "OABID",
      setSelected: "setOABID",
      brightness: "OABIDBrightness",
      setBrightness: "setOABIDBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Overture Administrative Boundaries Philippines",
      id: "OABPH-line-layer",
      type: "maplayer",
      opacity: "OABPHOpacity",
      setOpacity: "setOABPHOpacity",
      selected: "OABPH",
      setSelected: "setOABPH",
      brightness: "OABPHBrightness",
      setBrightness: "setOABPHBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "National 303D Impaired Waters Layer",
      id: [
        "water_impairments_poly", 
        "water_impairments_lines", 
        "water_impairments_points"
      ],
      type: "maplayer",
      opacity: "NationalWaterImpairementsOpacity",
      setOpacity: "setNationalWaterImpairementsOpacity",
      selected: "NationalWaterImpairements",
      setSelected: "setNationalWaterImpairements",
      brightness: "NationalWaterImpairementsBrightness",
      setBrightness: "setNationalWaterImpairementsBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Global Biodiversity Information Facility (GBIF)",
      id: "GBIF",
      type: "maplayer",
      opacity: "GBIFOpacity",
      setOpacity: "setGBIFOpacity",
      selected: "GBIF",
      setSelected: "setGBIF",
      brightness: "GBIFBrightness",
      setBrightness: "setGBIFBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Indonesian HUCs",
      id: "indoBasins",
      type: "maplayer",
      opacity: "indoHucsOpacity",
      setOpacity: "setIndoHucsOpacity",
      selected: "indoHucs",
      setSelected: "setIndoHucs",
      brightness: "indoHucsBrightness",
      setBrightness: "setIndoHucsBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Indonesian Watersheds",
      id: "indoBasins-layer",
      type: "maplayer",
      opacity: "indoWatershedsOpacity",
      setOpacity: "setIndoWatershedsOpacity",
      selected: "indoWatersheds",
      setSelected: "setIndoWatersheds",
      brightness: "indoWatershedsBrightness",
      setBrightness: "setIndoWatershedsBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "UN Gemstat Stations Philippines",
      id: "gemstat-ph-layer",
      type: "maplayer",
      opacity: "gemstatPHOpacity",
      setOpacity: "setGemstatPHOpacity",
      selected: "gemstatPH",
      setSelected: "setGemstatPH",
      brightness: "gemstatPHBrightness",
      setBrightness: "setGemstatPHBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Philippines Flood Hazard Zones",
      id: "philippine-flood-hazard-layer",
      type: "maplayer",
      opacity: "phFloodHazardOpacity",
      setOpacity: "setPHFloodHazardOpacity",
      selected: "phFloodHazard",
      setSelected: "setPHFloodHazard",
      brightness: "phFloodHazardBrightness",
      setBrightness: "setPHFloodHazardBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Philippines Regions",
      id: "philippine-regions-layer",
      type: "maplayer",
      opacity: "philRegionsOpacity",
      setOpacity: "setPhilRegionsOpacity",
      selected: "philRegions",
      setSelected: "setPhilRegions",
      brightness: "philRegionsBrightness",
      setBrightness: "setPhilRegionsBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Philippines Water Courses (Rivers)",
      id: "philippine-rivers-layer",
      type: "maplayer",
      opacity: "philippinesRiversOpacity",
      setOpacity: "setPhilippinesRiversOpacity",
      selected: "philippinesRivers",
      setSelected: "setPhilippinesRivers",
      brightness: "philippinesRiversBrightness",
      setBrightness: "setPhilippinesRiversBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Philippines River Basins",
      id: "philippines-basins-layer",
      type: "maplayer",
      opacity: "philippinesRiverBasinsOpacity",
      setOpacity: "setPhilippinesRiverBasinsOpacity",
      selected: "philippinesRiverBasins",
      setSelected: "setPhilippinesRiverBasins",
      brightness: "philippinesRiverBasinsBrightness",
      setBrightness: "setPhilippinesRiverBasinsBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "HUCs Mexico",
      type: "maplayer",
      opacity: "HUCsMexicoOpacity",
      setOpacity: "setHUCsMexicoOpacity",
      selected: "HUCsMexico",
      setSelected: "setHUCsMexico",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Australia Gemstat",
      id: "gemstat-aus-layer",
      type: "maplayer",
      opacity: "ausGemstatOpacity",
      setOpacity: "setAusGemstatOpacity",
      selected: "ausGemstat",
      setSelected: "setAusGemstat",
      brightness: "ausGemstatBrightness",
      setBrightness: "setAusGemstatBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Australia Bore & Water Data",
      id: "ABWDLayer-layer",
      type: "maplayer",
      opacity: "ABWDLayerOpacity",
      setOpacity: "setABWDLayerOpacity",
      selected: "ABWDLayer",
      setSelected: "setABWDLayer",
      brightness: "ABWDLayerBrightness",
      setBrightness: "setABWDLayerBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 0.75,
      org: ["True Elements"],
      name: "NGIS Lower",
      id: "ANGIS-lower-layer",
      type: "maplayer",
      opacity: "ANGISLowerOpacity",
      setOpacity: "setANGISLowerOpacity",
      selected: "ANGISLower",
      setSelected: "setANGISLower",
      brightness: "ANGISLowerBrightness",
      setBrightness: "setANGISLowerBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 0.75,
      org: ["True Elements"],
      name: "NGIS Middle",
      id: "ANGIS-mid-layer",
      type: "maplayer",
      opacity: "ANGISMidOpacity",
      setOpacity: "setANGISMidOpacity",
      selected: "ANGISMid",
      setSelected: "setANGISMid",
      brightness: "ANGISMidBrightness",
      setBrightness: "setANGISMidBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 0.75,
      org: ["True Elements"],
      name: "NGIS Upper",
      id: "ANGIS-upper-layer",
      type: "maplayer",
      opacity: "ANGISUpperOpacity",
      setOpacity: "setANGISUpperOpacity",
      selected: "ANGISUpper",
      setSelected: "setANGISUpper",
      brightness: "ANGISUpperBrightness",
      setBrightness: "setANGISUpperBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Australia's Principal Hydrogeology",
      id: "aus-prin-hydro-layer",
      type: "maplayer",
      opacity: "ausPrinHydroOpacity",
      setOpacity: "setAusPrinHydroOpacity",
      selected: "ausPrinHydro",
      setSelected: "setAusPrinHydro",
      brightness: "ausPrinHydroBrightness",
      setBrightness: "setAusPrinHydroBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "WRF Country Risk",
      id: "wrfc-layer",
      type: "maplayer",
      opacity: "wrfcRiskOpacity",
      setOpacity: "setWRFCRiskOpacity",
      selected: "wrfcRisk",
      setSelected: "setWRFCRisk",
      brightness: "wrfcRiskBrightness",
      setBrightness: "setWRFCRiskBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 0.75,
      org: ["True Elements"],
      name: "Protected Areas World Database",
      id: "WDPA-layer",
      type: "maplayer",
      opacity: "WDPAOpacity",
      setOpacity: "setWDPAOpacity",
      selected: "WDPA",
      setSelected: "setWDPA",
      brightness: "WDPABrightness",
      setBrightness: "setWDPABrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Microsoft Terraclimate Evapotranspiration",
      type: "maplayer",
      opacity: "evapotranspirationSelectedOpacity",
      setOpacity: "setEvapotranspirationSelectedOpacity",
      selected: "evapotranspirationSelected",
      setSelected: "setEvapotranspirationSelected",
      brightness: "evapotranspirationSelectedBrightness",
      setBrightness: "setEvapotranspirationSelectedBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Microsoft Terraclimate Precipitation",
      type: "maplayer",
      opacity: "terraClimateLayerSelectedOpacity",
      setOpacity: "setTerraClimateLayerSelectedOpacity",
      selected: "terraClimateLayerSelected",
      setSelected: "setTerraClimateLayerSelected",
      brightness: "terraClimateLayerSelectedBrightness",
      setBrightness: "setTerraClimateLayerSelectedBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Aqueduct Atlas Riverine Flood Risk v4.0",
      id: "aAAV4RiverineFloodRisk",
      type: "maplayer",
      opacity: "AAV4RiverineFloodRiskOpacity",
      setOpacity: "setAAV4RiverineFloodRiskOpacity",
      selected: "AAV4RiverineFloodRisk",
      setSelected: "setAAV4RiverineFloodRisk",
      brightness: "AAV4RiverineFloodRiskBrightness",
      setBrightness: "setAAV4RiverineFloodRiskBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Aqueduct Atlas Water Stress Risk v4.0",
      id: "AAV4WaterStress",
      type: "maplayer",
      opacity: "AAV4WaterStressOpacity",
      setOpacity: "setAAV4WaterStressOpacity",
      selected: "AAV4WaterStress",
      setSelected: "setAAV4WaterStress",
      brightness: "AAV4WaterStressBrightness",
      setBrightness: "setAAV4WaterStressBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Aqueduct Atlas Coastal Flood Risk v4.0",
      id: "AAV4CoastalFloodRisk",
      type: "maplayer",
      opacity: "AAV4CoastalFloodRiskOpacity",
      setOpacity: "setAAV4CoastalFloodRiskOpacity",
      selected: "AAV4CoastalFloodRisk",
      setSelected: "setAAV4CoastalFloodRisk",
      brightness: "AAV4CoastalFloodRiskBrightness",
      setBrightness: "setAAV4CoastalFloodRiskBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Aqueduct Atlas Riverine Flood Risk v3.0",
      id: "aqueduct_atlas_riverine-layer",
      type: "maplayer",
      opacity: "aqueductAtlasRiverineOpacity",
      setOpacity: "setAqueductAtlasRiverineOpacity",
      selected: "aqueductAtlasRiverine",
      setSelected: "setAqueductAtlasRiverine",
    },
    {
      org: ["True Elements"],
      name: "Aqueduct Atlas Water Stress Risk v3.0",
      id: "aqueduct_atlas_water_stress-layer",
      type: "maplayer",
      opacity: "aqueductAtlasWaterStressOpacity",
      setOpacity: "setAqueductAtlasWaterStressOpacity",
      selected: "aqueductAtlasWaterStress",
      setSelected: "setAqueductAtlasWaterStress",
      brightness: "aqueductAtlasWaterStressBrightness",
      setBrightness: "setAqueductAtlasWaterStressBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      id: "aqueduct_atlas_coastal_flood-layer",
      org: ["True Elements"],
      name: "Aqueduct Atlas Coastal Flood Risk v3.0",
      type: "maplayer",
      opacity: "aqueductAtlasCoastalFloodOpacity",
      setOpacity: "setAqueductAtlasCoastalFloodOpacity",
      selected: "aqueductAtlasCoastalFlood",
      setSelected: "setAqueductAtlasCoastalFlood",
      brightness: "aqueductAtlasCoastalFloodBrightness",
      setBrightness: "setAqueductAtlasCoastalFloodBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      id: ["huc8-line", "huc12-line", "tile-hover-8", "tile-hover-12"],
      org: ["True Elements"],
      name: "Watersheds (HUC's)",
      type: "maplayer",
      opacity: "hucsSelectedOpacity",
      setOpacity: "setHucsSelectedOpacity",
      selected: "hucsSelected",
      setSelected: "setHucsSelected",
      brightness: "hucsSelectedBrightness",
      setBrightness: "setHucsSelectedBrightness",
      hasBrightness: true,
      image: "huc-layer.svg",
      country: "United States",
    },
    {
      initialOpacity: 1,
      id: "GFSHuc12s",
      org: ["True Elements"],
      name: "Watersheds (HUC's)",
      type: "noclearall",
      opacity: "hucsSelectedOpacity",
      setOpacity: "setHucsSelectedOpacity",
      selected: "GFSHuc12s",
      setSelected: "setGFSHuc12s",
      brightness: "hucsSelectedBrightness",
      setBrightness: "setHucsSelectedBrightness",
      hasBrightness: true,
      image: "huc-layer.svg",
      country: "United States",
    },
    {
      org: ["True Elements"],
      name: "Outflows",
      id: "outflowslayer",
      type: "maplayer",
      opacity: "outflowsSelectedOpacity",
      setOpacity: "setOutflowsSelectedOpacity",
      selected: "outflowsSelected",
      setSelected: "setOutflowsSelected",
      brightness: "outflowsSelectedBrightness",
      setBrightness: "setOutflowsSelectedBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      id: "waterWatchSites-layer",
      org: ["True Elements"],
      name: "National View USGS WaterWatch Sites",
      type: "maplayer",
      opacity: "waterWatchOpacity",
      setOpacity: "setWaterWatchOpacity",
      selected: "waterWatchSites",
      setSelected: "setWaterWatchSites",
      brightness: "waterWatchBrightness",
      setBrightness: "setWaterWatchBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Lakes, Rivers, and Streams",
      id: [
        "nhd_one-layer",
        "nhd_two-layer",
        "nhd_waterbody-layer",
        "nhd_area_tiles-layer",
      ],
      type: "maplayer",
      opacity: "nhdSelectedOpacity",
      setOpacity: "setNhdSelectedOpacity",
      selected: "nhdSelected",
      setSelected: "setNhdSelected",
      brightness: "nhdSelectedBrightness",
      setBrightness: "setNhdSelectedBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Yellowstone Contaminants",
      type: "maplayer",
      opacity: "yellowstoneLayerOpacity",
      setOpacity: "setYellowstoneLayerOpacity",
      selected: "yellowstoneLayer",
      setSelected: "setYellowstoneLayer",
      brightness: "yellowstoneLayerBrightness",
      setBrightness: "setYellowstoneLayerBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Wild Fire Boundaries",
      id: "wildfire-boundaries-data-layer",
      type: "maplayer",
      opacity: "wildFireBoundariesOpacity",
      setOpacity: "setWildFireBoundariesOpacity",
      selected: "wildFireBoundaries",
      setSelected: "setWildFireBoundaries",
      brightness: "wildFireBoundariesBrightness",
      setBrightness: "setWildFireBoundariesBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 0.75,
      org: ["True Elements"],
      name: "Impaired Waters List 303D (WI, VA)",
      type: "maplayer",
      opacity: "impairedLakesOpacity",
      setOpacity: "setImpairedLakesOpacity",
      selected: "impairedLakes",
      setSelected: "setImpairedLakes",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "UN Gemstat Stations",
      id: "gemstat-layer",
      type: "maplayer",
      opacity: "gemstatOpacity",
      setOpacity: "setGemstatOpacity",
      selected: "gemstat",
      setSelected: "setGemstat",
      brightness: "gemstatBrightness",
      setBrightness: "setGemstatBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "I-10 HUC 12's",
      id: "I10-huc-12-layer",
      type: "maplayer",
      opacity: "I10Huc12sOpacity",
      setOpacity: "setI10Huc12sOpacity",
      selected: "I10Huc12s",
      setSelected: "setI10Huc12s",
      brightness: "I10Huc12sBrightness",
      setBrightness: "setI10Huc12sBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Historical Fire Data",
      id: "all-fire-data-layer",
      type: "maplayer",
      opacity: "allFireDataOpacity",
      setOpacity: "setAllFireDataOpacity",
      selected: "allFireData",
      setSelected: "setAllFireData",
      brightness: "allFireDataBrightness",
      setBrightness: "setAllFireDataBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Groundwater Nitrate Assessment",
      id: "nitrateassessmentlayer",
      type: "maplayer",
      opacity: "gwNitrateAssessmentSelectedOpacity",
      setOpacity: "setGwNitrateAssessmentSelectedOpacity",
      selected: "gwNitrateAssessmentSelected",
      setSelected: "setGwNitrateAssessmentSelected",
      brightness: "gwNitrateAssessmentSelectedBrightness",
      setBrightness: "setGwNitrateAssessmentSelectedBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "EPA Superfund Sites (National Priorities List)",
      id: "epa-superfund-layer",
      type: "maplayer",
      opacity: "EPASuperfundSitesOpacity",
      setOpacity: "setEPASuperfundSitesOpacity",
      selected: "EPASuperfundSites",
      setSelected: "setEPASuperfundSites",
      brightness: "EPASuperfundSitesBrightness",
      setBrightness: "setEPASuperfundSitesBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "East Palestine Outflows",
      id: "epo-layer",
      type: "maplayer",
      opacity: "EPOLayerOpacity",
      setOpacity: "setEPOLayerOpacity",
      selected: "EPOLayer",
      setSelected: "setEPOLayer",
      brightness: "EPOLayerBrightness",
      setBrightness: "setEPOLayerBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "USA Water Service Boundary (EPIC) Tier 1",
      id: "water_system_boundaries1-layer",
      type: "maplayer",
      opacity: "waterSystemBoundaryTier1Opacity",
      setOpacity: "setWaterSystemBoundaryTier1Opacity",
      selected: "waterSystemBoundaryTier1",
      setSelected: "setWaterSystemBoundaryTier1",
      brightness: "waterSystemBoundaryTier1Brightness",
      setBrightness: "setWaterSystemBoundaryTier1Brightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "USA Water Service Boundary (EPIC) Tier 2a",
      id: "water_system_boundaries2-layer",
      type: "maplayer",
      opacity: "waterSystemBoundaryTier2Opacity",
      setOpacity: "setWaterSystemBoundaryTier2Opacity",
      selected: "waterSystemBoundaryTier2",
      setSelected: "setWaterSystemBoundaryTier2",
      brightness: "waterSystemBoundaryTier2Brightness",
      setBrightness: "setWaterSystemBoundaryTier2Brightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "USA Water Service Boundary (EPIC) Tier 3",
      id: "water_system_boundaries3-layer",
      type: "maplayer",
      opacity: "waterSystemBoundaryTier3Opacity",
      setOpacity: "setWaterSystemBoundaryTier3Opacity",
      selected: "waterSystemBoundaryTier3",
      setSelected: "setWaterSystemBoundaryTier3",
      brightness: "waterSystemBoundaryTier3Brightness",
      setBrightness: "setWaterSystemBoundaryTier3Brightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "US Counties",
      id: "counties-layer",
      type: "maplayer",
      opacity: "usCountiesOpacity",
      setOpacity: "setUSCountiesOpacity",
      selected: "usCounties",
      setSelected: "setUSCounties",
      brightness: "usCountiesBrightness",
      setBrightness: "setUSCountiesBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "BIA National Land Area Representation",
      id: "bia-layer",
      type: "maplayer",
      opacity: "biaLayerOpacity",
      setOpacity: "setBIALayerOpacity",
      selected: "biaLayer",
      setSelected: "setBIALayer",
      brightness: "biaLayerBrightness",
      setBrightness: "setBIALayerBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Domestic Well Locations",
      id: "domestic-wells-layer",
      type: "maplayer",
      opacity: "domesticWellsOpacity",
      setOpacity: "setDomesticWellsOpacity",
      selected: "domesticWellsSelected",
      setSelected: "setDomesticWellsSelected",
      brightness: "domesticWellsbrightness",
      setBrightness: "setDomesticWellsbrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Principal Aquifers",
      id: "aquifers-layer",
      type: "maplayer",
      opacity: "principalAquifersOpacity",
      setOpacity: "setPrincipalAquifersOpacity",
      selected: "principalAquifersSelected",
      setSelected: "setPrincipalAquifersSelected",
      brightness: "principalAquifersBrightness",
      setBrightness: "setPrincipalAquifersBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "MoBi - Plant Species Richness",
      type: "maplayer",
      opacity: "MoBiPSROpacity",
      setOpacity: "setMoBiPSROpacity",
      selected: "MoBiPSR",
      setSelected: "setMoBiPSR",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "MoBi - All Species Richness",
      type: "maplayer",
      opacity: "MoBiSRAllOpacity",
      setOpacity: "setMoBiSRAllOpacity",
      selected: "MoBiSRAll",
      setSelected: "setMoBiSRAll",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "MoBi - Pollinator Inverts Species Richness",
      type: "maplayer",
      opacity: "MoBiPIOpacity",
      setOpacity: "setMoBiPIOpacity",
      selected: "MoBiPI",
      setSelected: "setMoBiPI"
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "MoBi - Vertebrates Species Richness",
      type: "maplayer",
      opacity: "MoBiVOpacity",
      setOpacity: "setMoBiVOpacity",
      selected: "MoBiV",
      setSelected: "setMoBiV",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "MoBi - Aquatic Inverts",
      type: "maplayer",
      opacity: "MoBiSRAOpacity",
      setOpacity: "setMoBiSRAOpacity",
      selected: "MoBiSRA",
      setSelected: "setMoBiSRA",
    },
    {
      org: ["True Elements"],
      name: "Climate Vulnerability Index",
      id: "cvi-layer",
      type: "maplayer",
      opacity: "cviOpacity",
      setOpacity: "setCVIOpacity",
      selected: "cvi",
      setSelected: "setCVI",
      brightness: "cviBrightness",
      setBrightness: "setCVIBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Water Quality Portal (WQP)",
      id: "WaterQualityPortal",
      type: "maplayer",
      opacity: "waterQualityPortalOpacity",
      setOpacity: "setWaterQualityPortalOpacity",
      selected: "waterQualityPortal",
      setSelected: "setWaterQualityPortal",
      image: "layer/layer_WQP.png",
      country: "United States",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Evaporative Demand Drought Index",
      id: "EDDIDrought",
      type: "maplayer",
      opacity: "eddiOpacity",
      setOpacity: "setEddiOpacity",
      selected: "eddi",
      setSelected: "setEddi",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "NWS Hazard",
      id: "NWSHazard-layer",
      type: "maplayer",
      opacity: "nwshazardOpacity",
      setOpacity: "setNwshazardOpacity",
      selected: "nwshazard",
      setSelected: "setNwshazard",
      brightness: "nwshazardBrightness",
      setBrightness: "setNwshazardBrightness",
      hasBrightness: false
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Warnings",
      id: "Warning layer",
      type: "maplayer",
      opacity: "warningLayerOpacity",
      setOpacity: "setWarningLayerOpacity",
      selected: "warningLayer",
      setSelected: "setWarningLayer",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "QPE Precipitation",
      id: "NOAAMix",
      type: "maplayer",
      opacity: "NOAAMixOpacity",
      setOpacity: "setNOAAMixOpacity",
      selected: "NOAAMix",
      setSelected: "setNOAAMix",
      image: "layer/layer_NOAAMix.png",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "NFHL",
      id: "FemaFirm",
      type: "maplayer",
      opacity: "FemaFirmOpacity",
      setOpacity: "setFemaFirmOpacity",
      selected: "FemaFirm",
      setSelected: "setFemaFirm",
      image: "layer/layer_FemaFirm.png",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "RiverGauge",
      id: "RiverGauge",
      type: "maplayer",
      opacity: "RiverGaugeOpacity",
      setOpacity: "setRiverGaugeOpacity",
      selected: "RiverGauge",
      setSelected: "setRiverGauge",
      image: "layer/layer_RiverGauge.png",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Live Water Sensors",
      id: "RiverGaugeUSGS",
      type: "maplayer",
      opacity: "RiverGaugeUSGSOpacity",
      setOpacity: "setRiverGaugeUSGSOpacity",
      selected: "RiverGaugeUSGS",
      setSelected: "setRiverGaugeUSGS",
      image: "layer/layer_RiverGaugeUSGS.png",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "SigFloodOutlook",
      id: "SigFloodOutlook",
      type: "maplayer",
      opacity: "SigFloodOutlookOpacity",
      setOpacity: "setSigFloodOutlookOpacity",
      selected: "SigFloodOutlook",
      setSelected: "setSigFloodOutlook",
      image: "layer/layer_SigFloodOutlook.png",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "FloodIndMapping",
      id: "FloodIndMapping",
      type: "maplayer",
      opacity: "FloodIndMappingOpacity",
      setOpacity: "setFloodIndMappingOpacity",
      selected: "FloodIndMapping",
      setSelected: "setFloodIndMapping",
      image: "layer/layer_FloodIndMapping.png",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "MaxStreamflow",
      id: "MaxStreamflow",
      type: "maplayer",
      opacity: "MaxStreamflowOpacity",
      setOpacity: "setMaxStreamflowOpacity",
      selected: "MaxStreamflow",
      setSelected: "setMaxStreamflow",
      image: "layer/layer_MaxStreamflow.png",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Evaluation Layer",
      id: "EvaluationLayer",
      type: "maplayer",
      opacity: "EvaluationLayerOpacity",
      setOpacity: "setEvaluationLayerOpacity",
      selected: "EvaluationLayer",
      setSelected: "setEvaluationLayer",
      image: "layer/layer_EvaluationLayer.png",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Streamflow",
      id: "StreamAnomaly",
      type: "maplayer",
      opacity: "StreamAnomalyOpacity",
      setOpacity: "setStreamAnomalyOpacity",
      selected: "StreamAnomaly",
      setSelected: "setStreamAnomaly",
      image: "layer/layer_StreamAnomaly.png",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "FHO",
      id: "FHO",
      type: "maplayer",
      opacity: "FHOOpacity",
      setOpacity: "setFHOOpacity",
      selected: "FHO",
      setSelected: "setFHO",
      image: "layer/layer_FHO.png",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Emergency Response Imagery",
      id: "EMI",
      type: "maplayer",
      opacity: "EMIOpacity",
      setOpacity: "setEMIOpacity",
      selected: "EMI",
      setSelected: "setEMI",
      image: "layer/layer_EMI.png",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Inland-Coastal Flooding Forecast",
      id: "FloodInd",
      type: "maplayer",
      opacity: "FloodIndOpacity",
      setOpacity: "setFloodIndOpacity",
      selected: "FloodInd",
      setSelected: "setFloodInd",
      image: "layer/layer_FloodInd.png",
    },
    {
      org: ["True Elements"],
      name: "Excessive Rainfall Potential Forecast",
      id: "rainfall-forecast-layer",
      type: "maplayer",
      opacity: "rainfallForecastOpacity",
      setOpacity: "setRainfallForecastOpacity",
      selected: "rainfallForecast",
      setSelected: "setRainfallForecast",
      brightness: "rainfallForecastBrightness",
      setBrightness: "setRainfallForecastBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "FEMA Flood Hazard Zones & Boundaries",
      id: [
        "AppleFEMA-layer",
        "deloitte_fema_zones-layer",
        "deloitte_zones-layer",
        "RES-FEMA1-layer",
        "fema_zones-layer",
        "fema_zones_2-layer",
      ],
      type: "maplayer",
      opacity: "femaZonesOpacity",
      setOpacity: "setFemaZonesOpacity",
      brightness: "femaZonesBrightness",
      setBrightness: "setFemaZonesBrightness",
      hasBrightness: true,
      selected: "femaZones",
      setSelected: "setFemaZones",
    },
    {
      org: ["True Elements"],
      name: "NOAA Sea Level Rise",
      type: "maplayer",
      opacity: "NOAASeaLevelRiseOpacity",
      setOpacity: "setNOAASeaLevelRiseOpacity",
      selected: "NOAASeaLevelRise",
      setSelected: "setNOAASeaLevelRise",
      brightness: "NOAASeaLevelRiseBrightness",
      setBrightness: "setNOAASeaLevelRiseBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "US Drought Monitoring",
      id: "drought-layer",
      type: "maplayer",
      initialOpacity: .9,
      opacity: "droughtMonitoringOpacity",
      setOpacity: "setDroughtMonitoringOpacity",
      selected: "droughtMonitoringSelected",
      setSelected: "setDroughtMonitoringSelected",
      brightness: "droughtMonitoringBrightness",
      setBrightness: "setDroughtMonitoringBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Hazardous Waste Proximity",
      id: "HWP",
      type: "maplayer",
      opacity: "EJHazardousWasteOpacity",
      setOpacity: "setEJHazardousWasteOpacity",
      selected: "EJHazardousWaste",
      setSelected: "setEJHazardousWaste",
      brightness: "EJHazardousWasteBrightness",
      setBrightness: "setEJHazardousWasteBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "RMP Facility Proximity",
      id: "rmp",
      type: "maplayer",
      opacity: "EJRMPOpacity",
      setOpacity: "setEJRMPOpacity",
      selected: "EJRMP",
      setSelected: "setEJRMP",
      brightness: "EJRMPBrightness",
      setBrightness: "setEJRMPBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Superfund Proximity",
      id: "ejsp",
      type: "maplayer",
      opacity: "EJSuperfundOpacity",
      setOpacity: "setEJSuperfundOpacity",
      selected: "EJSuperfund",
      setSelected: "setEJSuperfund",
      brightness: "EJSuperfundBrightness",
      setBrightness: "setEJSuperfundBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Underground Storage Tanks",
      id: "underground-storage-tanks",
      type: "maplayer",
      opacity: "EJUndergroundStorageTanksOpacity",
      setOpacity: "setEJUndergroundStorageTanksOpacity",
      selected: "EJUndergroundStorageTanks",
      setSelected: "setEJUndergroundStorageTanks",
      brightness: "EJUndergroundStorageTanksBrightness",
      setBrightness: "setEJUndergroundStorageTanksBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Wastewater Discharge",
      id: "wwdischarge",
      type: "maplayer",
      opacity: "EJWasteWaterDischargeOpacity",
      setOpacity: "setEJWasteWaterDischargeOpacity",
      selected: "EJWasteWaterDischarge",
      setSelected: "setEJWasteWaterDischarge",
      brightness: "EJWasteWaterDischargeBrightness",
      setBrightness: "setEJWasteWaterDischargeBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Demographic Index",
      id: "Demographic-Index-layer",
      type: "maplayer",
      opacity: "EJDemoSelectedOpacity",
      setOpacity: "setEJDemoSelectedOpacity",
      selected: "EJDemoSelected",
      setSelected: "setEJDemoSelected",
      brightness: "EJDemoSelectedBrightness",
      setBrightness: "setEJDemoSelectedBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Less Than High School Education",
      id: "less-hs",
      type: "maplayer",
      opacity: "EJLessHSSelectedOpacity",
      setOpacity: "setEJLessHSSelectedOpacity",
      selected: "EJLessHSSelected",
      setSelected: "setEJLessHSSelected",
      brightness: "EJLessHSSelectedBrightness",
      setBrightness: "setEJLessHSSelectedBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Linguistically Isolated",
      id: "ling-iso",
      type: "maplayer",
      opacity: "EJLingIsoSelectedOpacity",
      setOpacity: "setEJLingIsoSelectedOpacity",
      selected: "EJLingIsoSelected",
      setSelected: "setEJLingIsoSelected",
      brightness: "EJLingIsoSelectedBrightness",
      setBrightness: "setEJLingIsoSelectedBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Low Income",
      id: "Percentile-Low-Income",
      type: "maplayer",
      opacity: "EJLowIncomeSelectedOpacity",
      setOpacity: "setEJLowIncomeSelectedOpacity",
      selected: "EJLowIncomeSelected",
      setSelected: "setEJLowIncomeSelected",
      brightness: "EJLowIncomeSelectedBrightness",
      setBrightness: "setEJLowIncomeSelectedBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "People of Color",
      id: "poc",
      type: "maplayer",
      opacity: "EJPOCSelectedOpacity",
      setOpacity: "setEJPOCSelectedOpacity",
      selected: "EJPOCSelected",
      setSelected: "setEJPOCSelected",
      brightness: "EJPOCSelectedBrightness",
      setBrightness: "setEJPOCSelectedBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Unemployment",
      id: "unemployment",
      type: "maplayer",
      opacity: "EJUnemploymentSelectedOpacity",
      setOpacity: "setEJUnemploymentSelectedOpacity",
      selected: "EJUnemploymentSelected",
      setSelected: "setEJUnemploymentSelected",
      brightness: "EJUnemploymentSelectedBrightness",
      setBrightness: "setEJUnemploymentSelectedBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Under Age 5",
      id: "fiveandunder",
      type: "maplayer",
      opacity: "EJFiveAndUnderAgeSelectedOpacity",
      setOpacity: "setEJFiveAndUnderAgeSelectedOpacity",
      selected: "EJFiveAndUnderAgeSelected",
      setSelected: "setEJFiveAndUnderAgeSelected",
      brightness: "EJFiveAndUnderAgeSelectedBrightness",
      setBrightness: "setEJFiveAndUnderAgeSelectedBrightness",
      hasBrightness: true
    },
    {
      org: ["True Elements"],
      name: "Over Age 64",
      id: "oversixtyfour",
      type: "maplayer",
      opacity: "EJSixtyFourAndOverSelectedOpacity",
      setOpacity: "setEJSixtyFourAndOverSelectedOpacity",
      selected: "EJSixtyFourAndOverSelected",
      setSelected: "setEJSixtyFourAndOverSelected",
      brightness: "EJSixtyFourAndOverSelectedBrightness",
      setBrightness: "setEJSixtyFourAndOverSelectedBrightness",
    }, {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Cleveland Water Alliance",
      id: "CWA",
      type: "maplayer",
      opacity: "CWALayerOpacity",
      setOpacity: "setCWALayerOpacity",
      selected: "CWALayer",
      setSelected: "setCWALayer",
      brightness: "CWALayerBrightness",
      setBrightness: "setCWALayerBrightness",
      hasBrightness: true
    },
     {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Oregon Water Quality Portal Demo",
      id: "OWQPDemo",
      type: "maplayer",
      opacity: "OWQPDemoOpacity",
      setOpacity: "setOWQPDemoOpacity",
      selected: "OWQPDemo",
      setSelected: "setOWQPDemo",
      brightness: "OWQPDemoBrightness",
      setBrightness: "setOWQPDemoBrightness",
      hasBrightness: true
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "FIB Sampling",
      id: "FBFIB",
      type: "maplayer",
      opacity: "FBFIBOpacity",
      setOpacity: "setFBFIBOpacity",
      selected: "FBFIB",
      setSelected: "setFBFIB",
      brightness: "FBFIBBrightness",
      setBrightness: "setFBFIBBrightness",
      hasBrightness: true,
      image: "fbfib.png",
      country: "United States",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Florida Healthy Beaches Program",
      id: "FHBP",
      type: "maplayer",
      opacity: "FHBPOpacity",
      setOpacity: "setFHBPOpacity",
      selected: "FHBP",
      setSelected: "setFHBP",
      brightness: "FHBPBrightness",
      setBrightness: "setFHBPBrightness",
      hasBrightness: true,
      image: "fhbp.png",
      country: "United States",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Neon Science Sensor Location Data",
      id: "NSG-layer",
      type: "maplayer",
      opacity: "NSGOpacity",
      setOpacity: "setNSGOpacity",
      selected: "NSG",
      setSelected: "setNSG",
      brightness: "NSGBrightness",
      setBrightness: "setNSGBrightness",
      hasBrightness: true,
      image: "NSG.png",
      country: "United States",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Manuel's Branch FIB Sampling Data and Trends",
      id: "MBFIBDT",
      type: "maplayer",
      opacity: "MBFIBDTOpacity",
      setOpacity: "setMBFIBDTOpacity",
      selected: "MBFIBDT",
      setSelected: "setMBFIBDT",
      brightness: "MBFIBDTBrightness",
      setBrightness: "setMBFIBDTBrightness",
      hasBrightness: true,
      image: "MBFIBDT.png",
      country: "United States",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Manuels Branch E.coli Trends",
      id: "MEC",
      type: "maplayer",
      opacity: "MECOpacity",
      setOpacity: "setMECOpacity",
      selected: "MEC",
      setSelected: "setMEC",
      brightness: "MECBrightness",
      setBrightness: "setMECBrightness",
      hasBrightness: true,
      image: "mec.png",
      country: "United States",
    },
    {
      initialOpacity: 1,
      org: ["True Elements"],
      name: "Global Groundwater Dependent Ecosystems",
      id: "GGDE",
      type: "maplayer",
      opacity: "GGDEOpacity",
      setOpacity: "setGGDEOpacity",
      selected: "GGDE",
      setSelected: "setGGDE",
      hasBrightness: false,
      image: "mec.png",
    },
    {
      org: ["True Elements"],
      name: "Global Hydrobasins",
      id: [
        "hba-outline", 
        "hba",
        "hbas-outline",
        "hbas", 
        "hbau-outline",
        "hbau",
        "hbeu-outline",
        "hbeu",
        "hbsi-outline",
        "hbsi",
        "hbna-outline",
        "hbna",
        "hbsa-outline",
        "hbsa",
        "hbgl-outline",
        "hbgl",
        "hban-outline",
        "hban",
        "hbc-outline",
        "hbc"
      ],
      type: "maplayer",
      opacity: "HydrobasinsOpacity",
      setOpacity: "setHydrobasinsOpacity",
      selected: "Hydrobasins",
      setSelected: "setHydrobasins",
      brightness: "HydrobasinsBrightness",
      setBrightness: "setHydrobasinsBrightness",
      hasBrightness: true,
      image: "HBAi.png",
    },
    {
      org: ["True Elements"],
      name: "WISE Water Framework Directive Database",
      id: "wise-layer",
      type: "maplayer",
      opacity: "WiseOpacity",
      setOpacity: "setWiseOpacity",
      selected: "Wise",
      setSelected: "setWise",
      brightness: "WiseBrightness",
      setBrightness: "setWiseBrightness",
      hasBrightness: true,
      image: "WISE_i.png",
    },
    {
      org: ["True Elements"],
      name: "FEMA Flood Hazard Zones for Gulf Of Mexico Coastal States",
      id: "fema_gulf",
      type: "maplayer",
      opacity: "FemaGulfOpacity",
      setOpacity: "setFemaGulfOpacity",
      selected: "FemaGulf",
      setSelected: "setFemaGulf",
      brightness: "FemaGulfBrightness",
      setBrightness: "setFemaGulfBrightness",
      hasBrightness: true,
      image: "FG.png",
    },
    {
      org: ["True Elements"],
      name: "Oregon Water Resources Department Recorder Well",
      id: "osu-layer",
      type: "maplayer",
      opacity: "OSUWellsOpacity",
      setOpacity: "setOSUWellsOpacity",
      selected: "OSUWells",
      setSelected: "setOSUWells",
      brightness: "OSUWellsBrightness",
      setBrightness: "setOSUWellsBrightness",
      hasBrightness: true,
      image: "osu_wells.png",
      initialOpacity: 1,
    },
    {
      org: ["True Elements"],
      name: "Umatilla OR Well Log Layer",
      id: "osu-layer",
      type: "maplayer",
      opacity: "OWiFrameOpacity",
      setOpacity: "setOWiFrameOpacity",
      selected: "OWiFrame",
      setSelected: "setOWiFrame",
      brightness: "OWiFrameBrightness",
      setBrightness: "setOWiFrameBrightness",
      hasBrightness: true,
      image: "osu_i.png",
      initialOpacity: 1,
    },
    {
      org: ["True Elements"],
      name: "EPA Water Service Area Boundaries ",
      id: "wsbv2",
      type: "maplayer",
      opacity: "WaterServiceAreaBoundariesV2Opacity",
      setOpacity: "setWaterServiceAreaBoundariesV2Opacity",
      selected: "WaterServiceAreaBoundariesV2",
      setSelected: "setWaterServiceAreaBoundariesV2",
      brightness: "WaterServiceAreaBoundariesV2Brightness",
      setBrightness: "setWaterServiceAreaBoundariesV2Brightness",
      hasBrightness: true,
      image: "osu_i.png",
      initialOpacity: 0.8,
    },
    {
      org: ["OSU", "ORG", "te-admin"],
      name: "Umatilla Well Points Prototype Output",
      id: "UmatillaWellPoints",
      type: "maplayer",
      opacity: "UmatillaWellPointsOpacity",
      setOpacity: "setUmatillaWellPointsOpacity",
      selected: "UmatillaWellPoints",
      setSelected: "setUmatillaWellPoints",
      brightness: "UmatillaWellPointsBrightness",
      setBrightness: "setUmatillaWellPointsBrightness",
      hasBrightness: true,
      image: "uwp.png",
      initialOpacity: 1,
    },
    {
      org: ["OSU", "ORG", "te-admin"],
      name: "Umatilla Well Combined Boundaries Prototype Output",
      id: "UmatillaWellCombinedBoudaries",
      type: "maplayer",
      opacity: "UmatillaWellCombinedBoudariesOpacity",
      setOpacity: "setUmatillaWellCombinedBoudariesOpacity",
      selected: "UmatillaWellCombinedBoudaries",
      setSelected: "setUmatillaWellCombinedBoudaries",
      brightness: "UmatillaWellCombinedBoudariesBrightness",
      setBrightness: "setUmatillaWellCombinedBoudariesBrightness",
      hasBrightness: true,
      image: "uwp.png",
      initialOpacity: 1,
    },
    {
      org: ["OSU", "ORG", "te-admin"],
      name: "Umatilla Well Combined Polygons Prototype Output",
      id: "UmatillaWellCombinedPolygons",
      type: "maplayer",
      opacity: "UmatillaWellCombinedPolygonsOpacity",
      setOpacity: "setUmatillaWellCombinedPolygonsOpacity",
      selected: "UmatillaWellCombinedPolygons",
      setSelected: "setUmatillaWellCombinedPolygons",
      brightness: "UmatillaWellCombinedPolygonsBrightness",
      setBrightness: "setUmatillaWellCombinedPolygonsBrightness",
      hasBrightness: true,
      image: "uwp.png",
      initialOpacity: 1,
    },
    {
      org: ["OSU", "ORG", "te-admin"],
      name: "Data Center Area of Interest",
      id: "SIS-Layer",
      type: "maplayer",
      opacity: "SISOpacity",
      setOpacity: "setSISOpacity",
      selected: "SIS",
      setSelected: "setSIS",
      brightness: "SISBrightness",
      setBrightness: "setSISBrightness",
      hasBrightness: true,
      image: "sis.png",
      initialOpacity: 1,
    },
  ];

export default LayerConfig;