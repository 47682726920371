import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Form, Button, Row, Col, Modal, FloatingLabel } from 'react-bootstrap';
import InputForm from '../../components/input-form';
import './login.scss';
import backgroundImg from '../../assets/login-background.svg';
import logoImg from '../../assets/true-elements-logo.svg';
import { useLocation, useNavigate } from 'react-router-dom'
import googleIcon from "../../assets/google-128.png";
import microsoftIcon from "../../assets/microsoftlogo.svg";
import { useKeycloak } from '@react-keycloak/web'
import swish from '../../assets/swish.svg';
import settings from '../../settings.json';
import emailIcon from '../../assets/email.svg'
import lock from '../../assets/lock.svg'
import loginIcon from '../../assets/loginIcon.svg'
import axios, { AxiosResponse } from 'axios'
import json from '../../settings.json'

import { login as keycloakLogin, resetPassword, getUser, parseUserRoles } from './createAndLoginUser'

import agent from '../../api/agent'

// import keycloak from '../../keycloak';
import { authenticate } from './autenticate'

interface stateType {
	from: { pathname: string }
}

interface UserInfo {
	email?: string;
	source?: string;
	roles?: any;
}

export default function Login(props: any) {

	const login_remember = localStorage.getItem('login_remember') && (localStorage.getItem('login_remember') === 'true' ? true : false);
	const [showForgetPassword, setShowForgetPassword] = useState(false);
	const handleForgetPasswordClose = () => setShowForgetPassword(false);
	const handleForgetPasswordShow = () => setShowForgetPassword(true);
	
	const { notify, global, setShowNewUserAlert } = props;
 	const navigate = useNavigate();
	const location = useLocation();
	const currentLocationState = location.state || {
		from: { pathname: '/' },
	}

	useEffect(() => {
		const reportsContainer = document.getElementById("reportsContainer");
		const toastContainer = document.querySelector(".Toastify");
		if (reportsContainer) {
			reportsContainer.style.maxWidth = "unset"
		}//@ts-ignore
		if(toastContainer){ toastContainer.style.display = 'none';}
		return () => {//@ts-ignore
			if(toastContainer){ toastContainer.style.display = 'block' }
			if (reportsContainer) {
				reportsContainer.style.maxWidth = "calc(100% - 80px)"
			}
		}
	}, [])
	
	const searchParams = new URLSearchParams(location.search);  
	// For AWS Marketplace Customers
	const customerId = searchParams.get('customer_id');
	// For GCP Customers
	const procurementId = searchParams.get('procurement_account_id'); 
	// For Azure Customers
	const x_ms_marketplace_token = searchParams.get('x_ms_marketplace_token'); 
	const encodedToken = x_ms_marketplace_token ? encodeURIComponent(x_ms_marketplace_token) : '';

	const [token, setToken] = useState<any>()

	const [checkedRemember, setCheckedRemember] = useState(login_remember||true)
	const [formEmailReset, setFormEmailReset] = useState('');

	const [clickedEmail, setClickedEmail] = useState(false)
	const [clickedPass, setClickedPass] = useState(false)

	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')

	const [statusMessage, setStatusMessage] = useState('');

	const { keycloak, initialized } = useKeycloak()
 	const login = useCallback((mode: string) => {
 		keycloak?.login({ idpHint: mode, prompt: 'login' })
 	}, [keycloak])

 
	const navigateRegister = () => {
		if (procurementId !== null && procurementId !== undefined) {
			navigate(`/Register?procurement_account_id=${procurementId}`);
		} else if (x_ms_marketplace_token !== null && x_ms_marketplace_token !== undefined) {
			navigate(`/Register?x_ms_marketplace_token=${encodedToken}`);
		} else if (customerId !== null && customerId !== undefined) {
			navigate(`/Register?customer_id=${customerId}`);
		} else {
			navigate(`/Register`);
		}
	}

	const navigateSplash = () => {
		navigate('/Splash');
	}

	const handleSubmit = async () => {
		const loginResult = await keycloakLogin({ grant_type: 'password', username: username, password: password, client_id: 'true-elements' }, notify, global, navigateSplash, setShowNewUserAlert)
		setStatusMessage(loginResult);
		//authenticate(username, password)		
	}

    useEffect(() => {
		global.setUserLogged(false);
    }, []);


	const authenticatedLogout = () => {
		global.setAuthenticatedUser(false);
	}

	const onReady = () => {
		notify('Login Successful!', 'success'); 
		global.setAuthenticatedUser(true);
		navigateSplash();	

		let roles = [] as any;
		roles = parseUserRoles(keycloak, props);
	
		keycloak.loadUserInfo().then((userInfo: UserInfo) => {
			userInfo.source = 'login';
			userInfo.roles = roles;
			props.setUserInfo(userInfo);
		
			const email = userInfo.email;
			getUser(email, global, false)				
			localStorage.setItem('currentUser', email || '');

			if(!localStorage.getItem('authorized' + email)){
                //clear 
                localStorage.setItem('UserOrgString', '');
            }

			// send GET request to myapi.com
			const resolver = `${settings.apiMethods.MarketplaceUserProcessor}?email=${email}&customer_id=${customerId}`;
			const entitlement = `${settings.apiMethods.MarketplaceEntitlementCheck}?email=${email}`;
			fetch(resolver, {
				headers: {
					[settings.apiHeader]: `${settings.apiKey}`,
					'Content-Type': 'application/json'
				}
			}).then(response => {
				console.log(response)
				fetch(entitlement, {
					headers: {
						[settings.apiHeader]: `${settings.apiKey}`,
						'Content-Type': 'application/json'
					}
				}).then(response => {
					console.log(response)// handle response
				});
				// handle response
			}).catch(error => {console.log(error)});
			
			const gcpApi = `${settings.apiMethods.GcpCustomer}?email=${email}&procurement_account_id=${procurementId}`;
			fetch(gcpApi, {
				headers: {
					[settings.apiHeader]: `${settings.apiKey}`,
					'Content-Type': 'application/json'
				}
			}).catch(error => {console.log(error)});

			const azureApi = `${settings.apiMethods.AzureCustomer}?email=${email}&x_ms_marketplace_token=${encodedToken}`;
			fetch(azureApi, {
				headers: {
					[settings.apiHeader]: `${settings.apiKey}`,
					'Content-Type': 'application/json'
				}
			}).catch(error => {console.log(error)});
	    });
	}

	useEffect(() => {
		keycloak.onReady = function() { onReady(); }
		if (initialized && keycloak.authenticated) {
			keycloak.onAuthLogout = function() { authenticatedLogout(); }
		}
	}, [keycloak])

	useEffect(() => {
/*		if (initialized) {
			if (keycloak.authenticated) {
				keycloak.loadUserInfo().then((userInfo: UserInfo) => {

				});
			}
		}*/
	}, [keycloak?.authenticated])

	const handleRemember = () => {
		setCheckedRemember(!checkedRemember);
		localStorage.setItem('login_remember', (!checkedRemember).toString());
	}

	const handleForgetPasswordReset = (event:any) => {
		resetPassword({USER_NAME: formEmailReset}, notify);
		setShowForgetPassword(false);
	}


	return (
		<div className='login'>
			<img className="swish" src={swish} />
			<div className='form form-login'>
				<div className="loginOptions">
					
					<div className="login-right">
						<img className='logo' src={logoImg} />

						<div className="input-wrapper">

							<Form.Control
								onChange={(e: any) => {
									setClickedEmail(true)
									setUsername(e.target.value)
									if (username === '') {
										setClickedEmail(false)
									}
								}}
								className='login-input login-input-icon-shift'
								size="lg"
								type="email"
								placeholder="       Email"
							/>
							<img
								src={emailIcon}
								style={{
									display: clickedEmail ? 'none' : 'block'
								}}
								className="input-icon"
							/>
						</div>
						<div className="input-wrapper">
							<Form.Control
								onChange={(e: any) => {
									setClickedPass(true)
									setPassword(e.target.value)
									if (password === '') {
										setClickedPass(false)
									}
								}}
								className='login-input login-input-icon-shift'
								size="lg"
								type="password"
								placeholder="       Password"
							/>
							<img
								src={lock}
								style={{
									display: clickedPass ? 'none' : 'block'
								}}
								className="input-icon"
							/>
						</div>
						<div className="text-box">
							<div className="check-box">
								<Form.Check
									className="login-check"
									type="checkbox"
									onChange={handleRemember}
									checked={checkedRemember}
								/>
								<p>Remember me</p>
							</div>
							<div className="anchor-box">
								<span className="login-link" onClick={handleForgetPasswordShow}>Forgot Password?</span>
							</div>

						</div> 

						<div className="login-btn-box">
							<Button
							onClick={handleSubmit}
							onKeyDown={(event) => {
								if(event.key === 'enter') {
									handleSubmit()
								}
							}}
							size="lg"
							className="login-button"
							type="submit"
							>
								Login
							</Button>
							<span onClick={navigateRegister} className={"login-link"} style={{marginTop: 15, fontSize:24}}>
								Don't have account? Register here
							</span>  
							<span className={"login-span"} style={(statusMessage.substr(0, 8) === "Success!") ? {marginTop: 10, minHeight: 20, fontSize:14, color: 'green'} :{marginTop: 10, minHeight: 20, fontSize:14, color: 'red'}}>
								{statusMessage}
							</span>  
					<div style={{marginTop: 10}}>
 						<Button variant="primary" className='social-button' onClick={() => { login("google"); }}><img className="social-button-img" src={googleIcon} style={{ marginLeft: "-20px" }}></img>Login with Google</Button>
 					</div>
 					<div>
 						<Button variant="primary" className='social-button' onClick={() => { login("microsoft"); }}><img className="social-button-img" src={microsoftIcon}></img>Login with Microsoft</Button>
					</div>

						</div>
					</div>
					<div className="login-left">
						<h3>TrueQI Digital Watershed Explorer</h3>
						<h3>TrueQI Scenario Forecaster</h3>
						<h3>TrueQI Site Assessment Reporter</h3>
						<h3>TrueQI Field Data Monitor</h3>
					</div>
					<img className="login-icon" src={loginIcon} />
				</div>
			</div>
			<Modal className="login-password-modal" show={showForgetPassword} onHide={handleForgetPasswordClose}>
	        <Modal.Header closeButton>
	          <Modal.Title>Reset Password</Modal.Title>
	        </Modal.Header>
	        <Modal.Body style={{fontSize: '1.3rem'}}>
	        	<span> An email with a password reset link will be sent to your account.</span>
		        <FloatingLabel controlId="floatingActivityIdentifier" label="Email" style={{marginTop:20}}>                
		        	<Form.Control
	                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormEmailReset(e.target.value)}
	                    className='login-input'
	                    size='lg'
	                    type='email'
	                    placeholder='Email'
	                    required
	                />
                </FloatingLabel>
	        </Modal.Body>
	        <Modal.Footer>
	          <Button variant="secondary" onClick={handleForgetPasswordClose}>
	            Close
	          </Button>
	          <Button variant="primary" disabled={(formEmailReset && formEmailReset.length>0) ? false : true} onClick={handleForgetPasswordReset}>
	            Reset Password
	          </Button>
	        </Modal.Footer>
	      </Modal>

		</div>
	);
}

function CheckBoxOption(props: any) {
	const { id, displayName } = props;
	return (
		<Form.Check>
			<Form.Check.Input type='checkbox' isValid />
			<Form.Check.Label>{displayName}</Form.Check.Label>
		</Form.Check>
	);
}












// export default function Login(props: any) {
	

// 	const location = useLocation();
// 	const currentLocationState = location.state || {
// 		from: { pathname: '/' },
// 	}
// 	const navigate = useNavigate();
// 	const searchParams = new URLSearchParams(location.search);
// 	const customerId = searchParams.get('customer_id');
// 	const { keycloak, initialized } = useKeycloak()

// 	const login = useCallback((mode: string) => {
// 		keycloak?.login({ idpHint: mode, prompt: 'login' })
// 	}, [keycloak])


// 	useEffect(() => {
// 		if (initialized) {
// 			if (keycloak.authenticated) {
// 				if (keycloak.resourceAccess && keycloak.resourceAccess['true-elements'] && keycloak.resourceAccess['true-elements'].roles) {
// 					const loginRoles = keycloak.resourceAccess['true-elements'].roles;
// 					if (loginRoles.length === 1 && loginRoles.indexOf('temp') > -1) {
// 						props.setShowNewUserAlert(true);
// 					}
// 				}

// 				const defaultObjectFromStorage = localStorage.getItem('report_key');
// 				if (defaultObjectFromStorage) {
// 					const root = JSON.parse(defaultObjectFromStorage).root;
// 					if (root) {
// 						navigate(root)
// 					} else {
// 						navigate('/')
// 					}
// 				} else {
// 					navigate('/')
// 				}

// 				keycloak.loadUserInfo().then((userInfo: UserInfo) => {
// 					userInfo.source = 'login';
// 					props.setUserInfo(userInfo);

// 					const email = userInfo.email;
// 					// send GET request to myapi.com
// 					const resolver = `${settings.apiBaseURl}${settings.apiMethods.MarketplaceUserProcessor}?email=${email}&customer_id=${customerId}`;
// 					const entitlement = `${settings.apiBaseURl}${settings.apiMethods.MarketplaceEntitlementCheck}?email=${email}`;
// 					fetch(resolver, {
// 						headers: {
// 							[settings.apiHeader]: `${settings.apiKey}`,
// 							'Content-Type': 'application/json'
// 						}
// 					}).then(response => {
// 						console.log(response)
// 						fetch(entitlement, {
// 							headers: {
// 								[settings.apiHeader]: `${settings.apiKey}`,
// 								'Content-Type': 'application/json'
// 							}
// 						}).then(response => {
// 							console.log(response)// handle response
// 						});
// 						// handle response
// 					});

// 				});
// 			}
// 		}
// 	})


// 	return (
// 		<div className='login'>
// 			<div className='form form-login'>
// 				<img className='logo' src={logoImg} />
// 				<div className="loginOptions">
// 					<div>
// 						<Button variant="primary" className='social-button' onClick={() => { login("google"); }}><img className="social-button-img" src={googleIcon} style={{ marginLeft: "-20px" }}></img>Login with Google</Button>
// 					</div>
// 					<div>
// 						<Button variant="primary" className='social-button' onClick={() => { login("microsoft"); }}><img className="social-button-img" src={microsoftIcon}></img>Login with Microsoft</Button>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	);
// }

// function CheckBoxOption(props: any) {
// 	const { id, displayName } = props;
// 	return (
// 		<Form.Check>
// 			<Form.Check.Input type='checkbox' isValid />
// 			<Form.Check.Label>{displayName}</Form.Check.Label>
// 		</Form.Check>
// 	);
// }
