// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gemstat-popup-main .mapboxgl-popup-content {
  width: 100px !important; }
  .gemstat-popup-main .mapboxgl-popup-content .gemstat-popup-body {
    overflow: auto;
    padding: 2px; }
    .gemstat-popup-main .mapboxgl-popup-content .gemstat-popup-body p {
      font-size: 14px; }
    .gemstat-popup-main .mapboxgl-popup-content .gemstat-popup-body a {
      color: #4fc2d9;
      text-decoration: underline;
      cursor: pointer; }

.gemstat-popup-main .mapboxgl-popup-content > div {
  text-align: center;
  padding: 4px; }
`, "",{"version":3,"sources":["webpack://./src/components/Layers/GemstatLayer/gemstat-layer.scss"],"names":[],"mappings":"AAAA;EAEM,uBAAuB,EAAA;EAF7B;IAIQ,cAAc;IACd,YAAY,EAAA;IALpB;MAQU,eAAe,EAAA;IARzB;MAYU,cAAc;MACd,0BAA0B;MAC1B,eAAe,EAAA;;AAdzB;EAoBM,kBAAkB;EAClB,YAAY,EAAA","sourcesContent":[".gemstat-popup-main {\n    .mapboxgl-popup-content {\n      width: 100px !important;\n      .gemstat-popup-body {\n        overflow: auto;\n        padding: 2px;\n        \n        p {\n          font-size: 14px;\n        }\n  \n        a {\n          color: #4fc2d9;\n          text-decoration: underline;\n          cursor: pointer;\n        }\n      }\n    }\n  \n    .mapboxgl-popup-content > div {\n      text-align: center;\n      padding: 4px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
