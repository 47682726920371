import PropTypes from 'prop-types';

interface RenderModel{
  children:any;
  condition: boolean | true
}

const Render = (props: RenderModel) => {
  const { children, condition } = props; 
  return (
    condition 
      ? children
      : null
  )
};

Render.propTypes = {
  condition: PropTypes.bool,
  children: PropTypes.node.isRequired
}

export default Render;