import { useState } from 'react'
import Map from 'react-map-gl';
import settings from '../../settings.json'
import './base-report.scss'


const BaseReport = () => {

  const params = window.location.pathname
  const result = params.replace(/([A-Z])/g, " $1");
  const title = result.charAt(0).toUpperCase().replace('/', '') + result.slice(1);

  const [viewport, setViewport] = useState<any>({
    longitude: settings.defaultLocationMap.lng,
    latitude: settings.defaultLocationMap.lat,
    zoom: settings.defaultLocationMap.zoom,
  });

  return (
    <div className="report-container">
      <div className="report-heading">
        <h3>{title}</h3>
      </div>
      <div className="map-container">
        <Map
          mapboxAccessToken={settings.maboxKey}
          initialViewState={viewport}
          onMove={(e) =>
            setViewport({
              longitude: e.viewState.longitude,
              latitude: e.viewState.latitude,
              zoom: e.viewState.zoom,
            })
          }
         mapStyle={settings.defaultMapStyle}
        >
        </Map>
      </div>
      <div className="graph-container">
          <h1>Coming Soon</h1>
      </div>
    </div>
  )
}

export default BaseReport