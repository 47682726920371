import { useEffect, useContext, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layer, Source, Popup, Marker } from 'react-map-gl'
import { FillLayer } from 'mapbox-gl';
import { RefContext } from '../../../RefContext';
import { LayerDataContext } from '../../../LayerDataContext';
import { PointContext } from '../../../PointContext';
import { ActivePopupContext } from '../../../ActivePopupContext';
import { adjustHexBrightness } from '../../../features/utils';
import settings from '../../../settings.json'

const ExcessiveRainfallForecast = ({
    global,
    action,
    opacity,
    tableColumns,
    sourceLink = '',
    sourceLabel = '',
    reportRoute = '',
}: any) => {

    const [features, setFeatures] = useState<any>(null);   
    const latLng = action && action.lngLat;
    const navigate = useNavigate();

    const { currentRef } = useContext(RefContext);
    const { setCurrentPoint } = useContext(PointContext);
    const { setCurrentLayerData } = useContext(LayerDataContext);
    const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);

    const goTo = (coor: any, data: any) => {
        setCurrentPoint?.({
            lat: coor.lat,
            lng: coor.lng
        });
        setCurrentLayerData?.({
            ...data, tableColumns, sourceLink, sourceLabel
        });
        setTimeout(() => navigate(reportRoute), 100);
    }

    // @ts-ignore
    const mapInstance = currentRef && currentRef?.current;

    useEffect(() => {
        if (mapInstance) {
            const handleClick = (event: any) => {
                const layerObject = mapInstance.queryRenderedFeatures(event.point)?.[0];
                if (layerObject) {
                    setFeatures(layerObject);
                }
            };
            mapInstance.on('click', handleClick);

            return () => {
                mapInstance.off('click', handleClick);
            };
        }
    }, [mapInstance]);

    useEffect(() => {
        if (features && latLng) {
            const handleClose = () => {
                setFeatures(null);
                setActivePopup({ id: null, content: null });
            };

            const newPopup = (
                <Popup
                    longitude={latLng.lng}
                    latitude={latLng.lat}
                    anchor="bottom"
                    onClose={handleClose}
                >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '0.75rem',
                        borderRadius: '4px',
                        height: '2.5rem'
                    }}>
                        <p style={{ color: '#FFFFFF', textAlign: 'center' }}>{features.properties.OUTLOOK}</p>
                        <a
                            style={{
                                color: '#4fc2d9',
                                fontWeight: 'bold',
                                textDecoration: 'underline',
                                borderRadius: '6px',
                            }}
                            onClick={() => goTo(latLng, features.properties)}>
                            Go to report
                        </a>
                    </div>
                </Popup>
            );
            setActivePopup({
                id: 'rainfall-forecast-layer',
                content: newPopup
            });
        }
    }, [features, latLng]);

    const LayerProps: FillLayer = {
        id: `rainfall-forecast-layer`,
        type: "fill",
        "source-layer": "rainfall_forecast",
        paint: {
            "fill-opacity": opacity,
            "fill-outline-color": "transparent",
            "fill-color": [
                'case',
                ['==', ['get', 'PRODUCT'], `Day ${global.rainfallDay} Excessive Rainfall Potential Forecast`],
                [
                    'match',
                    ['get', 'OUTLOOK'],
                    "Marginal (At Least 5%)", `${adjustHexBrightness("#059e17", global.rainfallForecastBrightness)}`,
                    "Slight (At Least 15%)", `${adjustHexBrightness("#f5f105", global.rainfallForecastBrightness)}`,
                    "Moderate (At Least 40%)", `${adjustHexBrightness("#a30707", global.rainfallForecastBrightness)}`,
                    "High (At Least 70%)", `${adjustHexBrightness("#ba0468", global.rainfallForecastBrightness)}`,
                    "transparent"
                ],
                'transparent' 
            ],
        }
    }

    return (
        <>
            <Source
                id={`rainfall-forecast-layer`}
                type="vector"
                url={settings.tileSetURLs.RainfallForecast}
            >
                <Layer {...LayerProps} />
            </Source>
            {activePopup && activePopup.id === 'rainfall-forecast-layer' && activePopup.content}
        </>
    )

}

export default ExcessiveRainfallForecast;
