import {
  useMemo, useState, useEffect
} from 'react'
import ReportTableExpanded from '../../../reports/shared/ReportTableExpanded'
import {
  Button,
  Form, Popover, OverlayTrigger
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPenToSquare,
  faTrashCan
} from '@fortawesome/pro-solid-svg-icons';
import { exportToCsv } from '../../../utils';
import { submitWQX, deleteWQX } from '../lib/data';
import WM_tablekeys from '../WM_tablekeys.json';

const ProjectsTable = (props: any) => {

  const {
    setModalOpen,
    modalOpen,
    modalList,
    setModalList,
    setAction,
    setData,
    data,
    settings,
    setSettings,
    setProjectsData,
    orgCredentialsType
  } = props

  const [tableColumns, setTableColumns] = useState < any[] > ([]);
  const [currentRow, setCurrentRow] = useState();
  const [deleteCurrentRow, setDeleteCurrentRow] = useState(false);
  const [wqxCurrentRow, setWqxCurrentRow] = useState(false);  

  //data projectsData

    useEffect(() => {
      if(deleteCurrentRow){
          deleteRow(currentRow);
          setDeleteCurrentRow(false);
      }
    }, [deleteCurrentRow])

    useEffect(() => {
      if(wqxCurrentRow){
          wqxRow(currentRow);
          setWqxCurrentRow(false);
      }
    }, [wqxCurrentRow])

    const handleRow = (props: any) => {
        setCurrentRow(props.row.original)
    }

    const handleSubmit = (row: any) => {
        setWqxCurrentRow(true)     
    }

    const wqxRow = (row: any) => {
        if(row && row.id){
            submitWQX(row, handleSubmitReturn, true, row);
        }
    } 

    const handleSubmitReturn = (success: any, result: any, err?: any, row?:any) => {
        let initialData = [...data];
        if(row && row.id){
            let filterData = initialData.filter((o: any) => (o["id"] === row.id) ); 
            if(filterData && filterData.length > 0){
                //console.log(success, result, err, filterData);
            }           
        }
    }

    const handleDelete = (props: any, e:any) => {
        setDeleteCurrentRow(true)
    }

    const deleteRow = (row: any) => {
        if(row && row.id){
            deleteWQX(row.id, handleDeleteReturn);
            let initialData = [...data];
            let filterData = initialData.filter((o: any) => (o["id"] !== row.id) ); 
            if(filterData && filterData.length > 0){
                setProjectsData(filterData);
            }
        }
    }

    const handleDeleteReturn = (result: any) => {
          
    }


    const handleExport = (event: any) => {
          exportToCsv(data, 'Projects ')
    }

    const findVal = (v:any) => {
        //@ts-ignore
      return Object.keys(WM_tablekeys).filter((k) => WM_tablekeys[k].includes(v))
    }

    const translateField = (fieldname: any) => {
        let valSearch = findVal(fieldname);
        if(valSearch && valSearch.length>0){
            return valSearch[0];
        } else {
            return fieldname;
        } 
    }


    useEffect(() => {
        let tableCol = [];

        if(orgCredentialsType !== 'none'){
          tableCol.push( {
                Header: 'Send to EPA',
                accessor: 'sendToEPA',
                //@ts-ignore
                Cell: (props: any) => (
                    <div >
                        <OverlayTrigger trigger="click" placement="right" overlay={popoverWQX} rootClose={true}>
                            <div className="action-box">
                                <Form.Check
                                    value={props.value}
                                    onClick={() => {
                                        handleRow(props)        
                                    }}
                                />
                            </div>
                        </OverlayTrigger>
                    </div>
                ),
            })
        }



        tableCol.push( {
                Header: 'Edit',
                accessor: 'actions',
                size:70,
                //@ts-ignore
                Cell: (props: any) => {
                    return (
                        <div className="action-box">
                            <div
                                onClick={() => {
                                    setAction('edit')
                                    setData(props.row.original)
                                }}
                                className='action-cell'>
                                <FontAwesomeIcon 
                                    onClick={() => {
                                        setAction('edit')
                                        setData(props.row.original)
                                    }} 
                                    style={{ color: '#4fc2d9', cursor: 'pointer' }} 
                                    icon={faPenToSquare} />
                            </div>
                        </div>
                    )
                }
            }, {
                Header: 'Delete',
                accessor: 'actions_',
                size: 70,
                //@ts-ignore
                Cell: (props: any) => {
                    return (
                        <div className="action-box">
                            <OverlayTrigger       
                              trigger="click" placement="right" overlay={popover} rootClose={true}>
                            <div
                                onClick={() => {handleRow(props)}}
                                className='action-cell'>
                                <FontAwesomeIcon style={{ marginLeft:18, color: '#4fc2d9', cursor: 'pointer' }} 
                                icon={faTrashCan} />
                            </div>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }, { 
                Header: '',
                accessor: '  ',
                //@ts-ignore
                Cell: (props: any) => (
                    <div>

                    </div>
                ),
            },);

        tableCol.push(
            {
              Header: 'ID',
              accessor: 'PROJDescription_ProjectIdentifier',
            },
            {
              Header: 'Name',
              accessor: 'PROJDescription_ProjectName',
            },
            {
              Header: 'Description',
              accessor: 'PROJDescription_ProjectDescriptionText',
            },
            {
              Header: 'Monitoring Location Identifier',
              accessor: 'ProjectMonitoringLoc_MonitoringLocationIdentifier',
            },
            {
              Header: 'Reference Location Start Date',
              accessor: 'ProjectMonitoringLoc_ReferenceLocationStartDate',
            },
            {
              Header: 'Active',
              accessor: 'PROJDescription_QAPPApprovedIndicator',
            });

        if(modalList && modalList.length > 0){
            for (let dataItem of modalList) {
                if(dataItem.active){
                    tableCol.push( {
                       Header: dataItem.field,
                       accessor: translateField(dataItem.field),
                    });
                }
            }
        }


            setTableColumns(tableCol)

    }, [modalList])


    const popover = (
      <Popover id="popover-delete" className="wm-popover"  >
        <Popover.Header as="h3"  className="wm-popover-header">Delete</Popover.Header>
        <Popover.Body>
          Are you sure?
                <div>
                    <Button id="delete-delete-btn" 
                        onClick={(e) => {
                            handleDelete(props, e); 
                            document.body.click();                 
                        }}
                        className="p-3 btn m-2">
                        Delete
                    </Button>
                    <Button id="delete-cancel-btn" 
                        onClick={() => {
                            document.body.click();
                        }}
                        className="p-3 btn m-2">
                        Cancel
                    </Button>
                </div>
        </Popover.Body>
      </Popover>
    );

    const popoverWQX = (
      <Popover id="popover-wqx" className="wm-popover">
        <Popover.Header as="h3" className="wm-popover-header">WQX Submission</Popover.Header>
        <Popover.Body>
          Send to EPA now?
                <div>
                    <Button id="delete-delete-btn" 
                        onClick={(e) => {
                              handleSubmit(props);
                              document.body.click();
                        }}
                        className="p-3 btn">
                        Yes
                    </Button>
                    <Button id="delete-cancel-btn" 
                        onClick={() => {
                             document.body.click();
                        }}
                        className="p-3 m-2">
                        Cancel
                    </Button>
                </div>
        </Popover.Body>
      </Popover>
    );


  return (
    <div className='monitoring-location-container'>
      <div className="header-box">
        <h2>Projects</h2>
        <div className="button-box">
          <Button onClick={() => {
            setModalList(modalList)
            setModalOpen(true) 
          }} id="export-btn" className="p-3 btn btn-outline-primary m-2">
            Settings
          </Button>
          <Button id="export-btn" onClick={handleExport} className="p-3 btn btn-outline-primary m-2">
            Export CSV
          </Button>
          <Button onClick={() => setAction('create')} id="addNew-btn" className="p-3 btn btn-primary m-2">
            Add New
          </Button>
        </div>
      </div>
      <ReportTableExpanded columns={tableColumns} data={data} />
    </div>
  )
}

export default ProjectsTable