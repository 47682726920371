import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXmark } from "@fortawesome/pro-solid-svg-icons";
import { Draggable } from 'react-beautiful-dnd';
import { Form, Col, Row } from "react-bootstrap";

const LayerItem = ({
  name,
  index,
  opacity,
  setOpacity,
  selected,
  setSelected,
  initialOpacity,
  brightness,
  setBrightness,
  hasBrightness,
}) => {

  useEffect(() => setOpacity(initialOpacity), [initialOpacity]);

  const [localOpacity, setLocalOpacity] = useState()
  const [isSliderAdjusting, setIsSliderAdjusting] = useState(false)

  const handlePercent = (input) => {
    return input * 0.01;
  };

  const handleChange = (input) => {
    const num = handlePercent(input);
    setLocalOpacity(num);
  };

  const handleSliderMouseDown = () => {
    setIsSliderAdjusting(true);
  };

  const handleSliderMouseUp = () => { 
    setIsSliderAdjusting(false)
    setOpacity(localOpacity);
  };

  const percentNum = (opacity * 100).toFixed(2).toString().split(".")[0];

  return (
    <Draggable isDragDisabled={isSliderAdjusting} key={`item-${index}`} draggableId={`draggableId-${index}`} index={index}>
      {(provided) => (
        <div
        data-drag="true"
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        >
        {selected && (
          <Col
            className="transparency-item col-12"
          >
            <Col className="layer-title">
            {name}
            </Col>
            <div className="range-sliders">
                <div className="slider-container">
                  <div className="slider-wrapper">
                    <p style={{ marginBottom: '0' }}>Opacity</p>
                      <Form.Range
                        id="opacitySlider"
                        className="slider-input-transparency"
                        min={10}
                        defaultValue={initialOpacity * 100}
                        max={100}
                        step={5}
                        onChange={(e) => handleChange(e.target.value)}
                        onMouseUp={handleSliderMouseUp}
                        onMouseDown={handleSliderMouseDown}
                      />
                  </div> 
                    <div className="slider-value">
                      {percentNum ? percentNum : 50}%
                    </div>
                </div> 
                {hasBrightness ? (
                  <div className="slider-container">
                  <div
                  className="slider-wrapper" 
                  style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      textAlign: 'center'
                    }}>
                      <p style={{ marginBottom: '0' }}>Brightness</p>
                    <Form.Range
                      id="brightnessSlider"
                      className="slider-input-transparency"
                      min={-10}
                      max={10}
                      step={1}
                      onChange={(e) => setBrightness(parseInt(e.target.value))}
                      onMouseUp={handleSliderMouseUp}
                      onMouseDown={handleSliderMouseDown}
                    />
                  </div>  
                  <div className="slider-value">
                    {brightness ? brightness : 0}
                  </div>
                </div>
                ): ''} 
            </div>
            <Col className="close-wrapper-box">
              <FontAwesomeIcon
                style={{ color: "white", cursor: "pointer" }}
                icon={faSquareXmark}
                onClick={() => setSelected(false)}
              />
            </Col>
          </Col>
        )}
        </div>
      )}
    </Draggable>
  );
};

export default LayerItem
