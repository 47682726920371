import React from 'react'
import { Marker } from 'react-map-gl';

const LabelMarker = ({ lat, lng, text, zoom, zoomDisplay, style = null }: any) => {
  return (
    <>
        <Marker
            latitude={lat}
            longitude={lng}
            style={{ 
                display: zoom >= zoomDisplay ? 'block' : 'none',
                ...style
            }}
        >
            {text}
        </Marker>
    </>
  )
}

export default LabelMarker
