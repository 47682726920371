// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ausbore-popup-main .mapboxgl-popup-content {
  width: 12rem !important; }
  .ausbore-popup-main .mapboxgl-popup-content .gemstat-popup-body {
    overflow: auto;
    padding: 2px; }
    .ausbore-popup-main .mapboxgl-popup-content .gemstat-popup-body p {
      font-size: 14px; }
    .ausbore-popup-main .mapboxgl-popup-content .gemstat-popup-body a {
      color: #4fc2d9;
      text-decoration: underline;
      cursor: pointer; }

.ausbore-popup-main .ausbore-popup-body {
  width: 10rem; }
  .ausbore-popup-main .ausbore-popup-body a {
    color: #4fc2d9;
    text-decoration: underline;
    margin-top: 4px;
    cursor: pointer; }

.ausbore-popup-main .aus-bore {
  width: 12rem !important; }

.ausbore-popup-main .mapboxgl-popup-content > div {
  text-align: center;
  padding: 4px; }

.epa-popup-main {
  width: 15rem; }
  .epa-popup-main .mapboxgl-popup-content {
    max-width: unset;
    padding-top: 1rem;
    text-align: center; }
    .epa-popup-main .mapboxgl-popup-content svg {
      top: 1% !important;
      font-weight: bolder; }

.wws-popup-main .mapboxgl-popup-content {
  text-align: center; }
  .wws-popup-main .mapboxgl-popup-content .wws-popup-body {
    padding-top: 0.5rem; }
  .wws-popup-main .mapboxgl-popup-content svg {
    top: 3% !important; }
`, "",{"version":3,"sources":["webpack://./src/components/Layers/CircleVectorLayer/cvl-styles.scss"],"names":[],"mappings":"AAAA;EAEI,uBAAuB,EAAA;EAF3B;IAIM,cAAc;IACd,YAAY,EAAA;IALlB;MAQQ,eAAe,EAAA;IARvB;MAYQ,cAAc;MACd,0BAA0B;MAC1B,eAAe,EAAA;;AAdvB;EAoBI,YAAY,EAAA;EApBhB;IAsBM,cAAc;IACd,0BAA0B;IAC1B,eAAe;IACf,eAAe,EAAA;;AAzBrB;EA8BI,uBAAuB,EAAA;;AA9B3B;EAkCI,kBAAkB;EAClB,YAAY,EAAA;;AAIhB;EACE,YAAY,EAAA;EADd;IAGI,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB,EAAA;IALtB;MAOM,kBAAkB;MAClB,mBAAmB,EAAA;;AAKzB;EAEI,kBAAkB,EAAA;EAFtB;IAIM,mBAAmB,EAAA;EAJzB;IAQM,kBAAkB,EAAA","sourcesContent":[".ausbore-popup-main {\n  .mapboxgl-popup-content {\n    width: 12rem !important;\n    .gemstat-popup-body {\n      overflow: auto;\n      padding: 2px;\n      \n      p {\n        font-size: 14px;\n      }\n\n      a {\n        color: #4fc2d9;\n        text-decoration: underline;\n        cursor: pointer;\n      }\n    }\n  }\n\n  .ausbore-popup-body {\n    width: 10rem;\n    a {\n      color: #4fc2d9;\n      text-decoration: underline;\n      margin-top: 4px;\n      cursor: pointer;\n    }\n  }\n\n  .aus-bore {\n    width: 12rem !important;\n  }\n\n  .mapboxgl-popup-content > div {\n    text-align: center;\n    padding: 4px;\n  }\n}\n\n.epa-popup-main {\n  width: 15rem;\n  .mapboxgl-popup-content {\n    max-width: unset;\n    padding-top: 1rem;\n    text-align: center;\n    svg { \n      top: 1% !important; \n      font-weight: bolder;\n    }\n  }\n}\n\n.wws-popup-main {\n  .mapboxgl-popup-content {\n    text-align: center;\n    .wws-popup-body {\n      padding-top: 0.5rem;\n    }\n\n    svg {\n      top: 3% !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
