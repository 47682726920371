import { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGreaterThan } from '@fortawesome/pro-solid-svg-icons';
import { Form, Button, Row, Col, Card, OverlayTrigger } from 'react-bootstrap';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import './Settings.scss';

export default function SettingsBreadcrumb(props: any){
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const onClickBreadrumb = (event: any) => {
        if(event.target.innerHTML === 'Settings'){
            navigate('/Settings')
        } else if (event.target.innerHTML === 'User Management'){
            navigate('/Users')
        } else if (event.target.innerHTML === 'Add User'){
            navigate('/add-user')
        }
    };

	return(
        <Col className='col-md-4 settings-breadcrumb'>
            <div className='settings-breadcrumb-main'>
                <span className='settings-breadcrumb-link' onClick={onClickBreadrumb}>Settings</span>
                &nbsp;&nbsp;
                <FontAwesomeIcon icon={faGreaterThan} size='1x' /> 
                &nbsp;&nbsp;
                <span className='settings-breadcrumb-link' onClick={onClickBreadrumb}>{props.title}</span>
                &nbsp;&nbsp;
                {props.subtitle && (
                    <div>
                        <FontAwesomeIcon icon={faGreaterThan} size='1x' /> 
                        &nbsp;&nbsp;
                        <span>{props.subtitle}</span>
                    </div>
                )}
        </div>
    </Col>
	);
}




