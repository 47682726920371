import React, { useState, useMemo, useEffect } from 'react';
import { Form, Button, Row, Col, Nav, Modal, Pagination, FormCheck } from 'react-bootstrap';
import ReportTableExpanded from '../reports/shared/ReportTableExpanded';
import { parse } from 'papaparse';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import InputForm from '../../components/input-form';
import companyIcon from '../../assets/company-icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faGreaterThan, faGear, faCancel, faRectangleXmark, faRotate, faTrashCan, faList, faTableCellsLarge } from '@fortawesome/pro-solid-svg-icons';
import SettingsBreadcrumb from '../account/SettingsBreadcrumb';
import LoadingDataAnimation from "../../components/LoadingDataAnimation";
import agent from '../../api/agent';
import './organizations.scss';

export default function Organizations(props: any){
	const { global } = props;

    const [reportData, setReportData] = useState<any[]>(props.organizationaldata);
    const [filteredData, setFilteredData] = useState<any[]>(props.organizationaldata);
    const [currentUser, setCurrentUser] = useState<object>({});
    const [searchText, setSearchText] = useState<string>('');

	const [ firstName, setFirstName ] = useState('');
	const [ lastName, setLastName ] = useState('');
	const [ emailName, setEmailName ] = useState('');
	const [ phoneNumber, setPhoneNumber ] = useState('');

    const [ navtab, setnavtab ] = useState('users');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteMode, setDeleteMode] = useState<string>('');
    const [ inactive, setInactive] = useState(false);
    const [editUser, setEditUser] = useState<number>(-1);
    const [deleteUser, setDeleteUser] = useState<number>(-1);


    const { pathname } = useLocation();
    const navigate = useNavigate();

	const user = {
        currentUser,
        setCurrentUser,
		firstName,
		setFirstName,
		lastName,
		setLastName,
		emailName,
		setEmailName,
		phoneNumber,
		setPhoneNumber
	};


    useEffect(() => {
        if(props && props.organizationaldata ){
            setReportData(props.organizationaldata);
            let fData = [...props.organizationaldata].filter((o: any) => o.is_active===true);
            setFilteredData(fData);
        }
    }, [props.organizationaldata]); 

    useEffect(() => {
        if(editUser > -1){
             let fData = [...filteredData].filter((o: any) => +o.id === +editUser);
            if(fData && fData.length > 0){
                setCurrentUser(fData[0])
                navigate('/add-organization', { replace: false, state:{mode: 'edit',  user : fData[0]}});
            } 
        }
    }, [editUser]);

    useEffect(() => {
        if(deleteUser > -1){
             let fData = [...filteredData].filter((o: any) => +o.id === +deleteUser);
            if(fData && fData.length > 0){
                setShowDeleteModal(true);
                setDeleteMode('user'); 
                setCurrentUser(fData[0])
            } 
        }
    }, [deleteUser]);

    const tableColumns = useMemo(
        () => [
          { Header: 'Organization Name', accessor: 'name' },
          { Header: 'Organization Id', accessor: 'org_id' },
          { Header: 'Description', accessor: 'description' },
          { Header: 'Email', accessor: 'email' },
          { Header: 'EPA_org_id ', accessor: 'epa_org_id' },
          { Header: 'Is Active', accessor: 'is_active',
            Cell: (props: any) => ((props.value) ? ' ' + props.value : ' '),
          },
          {
            Header: 'Actions',
            accessor: 'id',
            Cell: (props: any) => (
              <div className='ct-table-container-button'>
                <Button
                    type='button'
                    className='ct-table-button'
                    onClick={clickEditUserHandler}
                    value={props.value}
                    id={'edituser_' + props.value}>
                       
                    <FontAwesomeIcon icon={faPenToSquare} size='xs' />
                </Button>
                <Button
                    type='button'
                    className='ct-table-button'
                    onClick={clickDeleteHandler}
                    value={props.value}
                    id={'deleteuser_' + props.value}>
                    <FontAwesomeIcon icon={faTrashCan} size='xs' />
                </Button>
              </div>
            ),
          }
        ],
        []
    );
    
    const clickRowHandler = (event: any) => {

    };
    
    const clickAddUserHandler = (event: any) => {
        navigate('/add-organization')
    };

    const clickEditUserHandler = (event: any) => {
        if (event && event.target && event.target.value) {
            setEditUser(event.target.value);
        }   
    };

    const handleDeleteCancel = () => {
        setShowDeleteModal(false);
    };
    
    const handleDeleteConfirm = (event:any) => {  
        setShowDeleteModal(false); 

        let cuid = '11111111';        
        if(currentUser) {
            // @ts-ignore
            cuid = '' + currentUser.org_id;
        }
        setDeleteMode('');

        agent.UserManagement.OrgDelete(cuid).then((res: any) => {      
			console.log(cuid, res)
		})  
     
        let rData = [...reportData].filter((o: any) => +o.org_id !== +cuid);
        if(rData && rData.length > 0){
            setReportData(rData)
            setCurrentUser([])
        }   

        let fData = [...filteredData].filter((o: any) => +o.org_id !== +cuid);
        if(fData && fData.length > 0){
            setFilteredData(fData)
        } 
    };
    
    const clickDeleteHandler = (event: any) => {      
        if (event && event.target && event.target.value) {
            setDeleteUser(event.target.value);
        }
    };

    useEffect(() => {
        let fData = [...reportData]
        if(!inactive){
            fData = [...filteredData].filter((o: any) => o.is_active===true);
        } else {
            if (searchText && searchText.length > 2) {
                fData = [...reportData].filter((o: any) => o.name.indexOf(searchText) > -1);
            }   
        }
        setFilteredData(fData);
    }, [inactive]);

    const onChangeSearch = (event: any) => {
        if (event && event.target && event.target.value && event.target.value.length > 2) {
            let fData = [...reportData].filter((o: any) => o.name.indexOf(event.target.value) > -1);
            if(!inactive){
                fData = [...fData].filter((o: any) => o.is_active===true);
            } 
            setFilteredData(fData);
            setSearchText(event.target.value);
        } else {
            setFilteredData(reportData);
            setSearchText('');
        }       
    };

    const onSelectNav = (event: any) => {
        setnavtab(event)
    };

	return (
		<div className='organizations'>
            <Modal show={showDeleteModal} onHide={handleDeleteCancel}>
              <Modal.Header closeButton>
                <Modal.Title>Confirm deletion</Modal.Title>    
              </Modal.Header>
              <Modal.Footer>
                <Button variant="secondary"  onClick={handleDeleteCancel}>
                  Cancel
                </Button>
                <Button variant="primary" id={'confirm' + 'dashtimsestamp'} onClick={handleDeleteConfirm}>
                  Delete
                </Button>
              </Modal.Footer>  
            </Modal>   

            <div className='users-container' style={(navtab === 'users' ? {display: 'block'} : {display: 'none'})}>                 
                <div className='users-tool-container'>
                    <Form.Group  className='users-search' controlId="formSearch">
                        <Form.Label></Form.Label>
                        <Form.Control onChange={onChangeSearch} type="text" placeholder="Search" />
                    </Form.Group>
                    <div className='org-table-header'>
                        <FormCheck                               
                            onChange={() => setInactive(!inactive)}
                            label={"Include inactive"}
                            checked={inactive}                    
                        />
                        <Button className='users-button' onClick={clickAddUserHandler}>
                            Add Organization
                        </Button>
                    </div>
                </div>
                <div className='table-parent-container'> 
                        <ReportTableExpanded
                            data={filteredData}
                            columns={tableColumns}
                            clickRowHandler={clickRowHandler}
                            clickLoadStop={true}
                        />           
                </div>
            </div>

		</div>
	);
}


