import { useEffect, useContext, useState } from 'react';
import { Layer, Source, Marker, Popup } from 'react-map-gl';
import { FillLayer } from 'mapbox-gl';
import { RefContext } from '../../../RefContext';
import { useNavigate } from 'react-router-dom';
import { LayerDataContext } from '../../../LayerDataContext';
import { PointContext } from '../../../PointContext';
import { ActivePopupContext } from '../../../ActivePopupContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";

const GlobalMapWithReport = ({
    id,
    fillColor,
    opacity,
    outlineColor,
    tileSet,
    sourceLayer,
    action,
    beforeID = '',
    tableColumns = [],
    sourceLink = '',
    sourceLabel = '',
    reportRoute = '',
    report = true,
}: any) => {

    const [features, setFeatures] = useState<any>(null);

    const { currentRef } = useContext(RefContext);
    const { setCurrentPoint } = useContext(PointContext);
    const { setCurrentLayerData } = useContext(LayerDataContext);
    const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);

    const navigate = useNavigate();

    // @ts-ignore
    const mapInstance = currentRef && currentRef?.current;

    useEffect(() => {
        if (mapInstance) {
            const handleClick = (event: any) => {
                const layerObject = mapInstance.queryRenderedFeatures(event.point)?.[0];
                setFeatures(layerObject);
                if (layerObject) {
                    const lngLat = event.lngLat;
                    setActivePopup({
                        id: `${id}-popup`,
                        content: renderPopup(layerObject, lngLat)
                    });
                }
            };

            mapInstance.on('click', handleClick);

            return () => {
                mapInstance.off('click', handleClick);
            };
        }
    }, [mapInstance]);

    const goTo = (coor: any, data: any) => {
        setCurrentPoint?.({
            lat: coor.lat,
            lng: coor.lng
        });

        setCurrentLayerData?.({
            ...data,
            tableColumns,
            sourceLink,
            sourceLabel
        });

        setTimeout(() => navigate(reportRoute), 100);
    };

    const renderPopup = (feature: any, lngLat: any) => (
        <Popup
            longitude={lngLat.lng}
            latitude={lngLat.lat}
            onClose={() => setActivePopup({ id: null, content: null })}
        >
            <div style={{
                color: '#4fc2d9',
                fontWeight: 'bold',
                textDecoration: 'underline',
                padding: '0.2rem',
                borderRadius: '6px',
                cursor: 'pointer'
            }}
                onClick={() => goTo(lngLat, feature.properties)}
            >
                Go to report
                <FontAwesomeIcon
                    icon={faXmark}
                    onClick={() => setActivePopup({ id: null, content: null })}
                    style={{ color: 'white', position: 'absolute', top: '5%', right: '5%', cursor: 'pointer' }}
                />
            </div>
        </Popup>
    );

    const VectorTileLayer: FillLayer = {
        id: `${id}-layer`,
        type: "fill",
        "source-layer": `${sourceLayer}`,
        paint: {
            "fill-opacity": opacity,
            "fill-outline-color": `${outlineColor}`,
            'fill-color': fillColor,
        },
    };

    return (
        <>
            <Source
                id={`${id}-layer`}
                type="vector"
                url={tileSet}
            >
                <Layer
                    beforeId={beforeID}
                    {...VectorTileLayer}
                />
            </Source>
            {activePopup && activePopup.id === `${id}-popup` && activePopup.content}
        </>
    );
};

export default GlobalMapWithReport;
