import React, { useEffect, useState, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReportTable from '../shared/ReportTable';

const WaterImpairmentTable = ({ tileName, data, type }: any) => {

  const [tableColumns, setTableColumn] = useState<any[]>([]);

  const commonColumns: any[] = useMemo(() => [
    { Header: "GLOBALID", accessor: "GLOBALID" },
    { Header: "Algal Growth", accessor: "algal_growth" },
    { Header: "Assessment Unit Identifier", accessor: "assessmentunitidentifier" },
    { Header: "Assessment Unit Name", accessor: "assessmentunitname" },
    { Header: "Assessment Join Key", accessor: "assmnt_joinkey" },
    { Header: "Biotoxins", accessor: "biotoxins" },
    { Header: "Cause Unknown", accessor: "cause_unknown" },
    { Header: "Cause Unknown Impaired Biota", accessor: "cause_unknown_impaired_biota" },
    { Header: "Cultural Use", accessor: "cultural_use" },
    { Header: "Dioxins", accessor: "dioxins" },
    { Header: "Ecological Use", accessor: "ecological_use" },
    { Header: "Fish Consumption Use", accessor: "fishconsumption_use" },
    { Header: "Has 4B Plan", accessor: "has4bplan" },
    { Header: "Has Protection Plan", accessor: "hasprotectionplan" },
    { Header: "Has TMDL", accessor: "hastmdl" },
    { Header: "Hydrologic Alteration", accessor: "hydrologic_alteration" },
    { Header: "IR Category", accessor: "ircategory" },
    { Header: "Is Assessed", accessor: "isassessed" },
    { Header: "Is Impaired", accessor: "isimpaired" },
    { Header: "Is Threatened", accessor: "isthreatened" },
    { Header: "Mercury", accessor: "mercury" },
    { Header: "Metals Other Than Mercury", accessor: "metals_other_than_mercury" },
    { Header: "Nuisance Exotic Species", accessor: "nuisance_exotic_species" },
    { Header: "Nutrients", accessor: "nutrients" },
    { Header: "On 303d List", accessor: "on303dlist" },
    { Header: "Organization ID", accessor: "organizationid" },
    { Header: "Organization Name", accessor: "organizationname" },
    { Header: "Org Type", accessor: "orgtype" },
    { Header: "Original FID", accessor: "orig_fid" },
    { Header: "Other Cause", accessor: "other_cause" },
    { Header: "Other Use", accessor: "other_use" },
    { Header: "Overall Status", accessor: "overallstatus" },
    { Header: "Oxygen Depletion", accessor: "oxygen_depletion" },
    { Header: "Pathogens", accessor: "pathogens" },
    { Header: "Permit Join Key", accessor: "permid_joinkey" },
    { Header: "Pesticides", accessor: "pesticides" },
    { Header: "pH Acidity/Caustic Conditions", accessor: "ph_acidity_caustic_conditions" },
    { Header: "Polychlorinated Biphenyls (PCBs)", accessor: "polychlorinated_biphenyls_pcbs" },
    { Header: "Radiation", accessor: "radiation" },
    { Header: "Region", accessor: "region" },
    { Header: "Reporting Cycle", accessor: "reportingcycle" },
    { Header: "State", accessor: "state" },
    { Header: "Submission ID", accessor: "submissionid" },
    { Header: "TAS 303d", accessor: "tas303d" },
    { Header: "Taste, Color, and Odor", accessor: "taste_color_and_odor" },
    { Header: "Temperature", accessor: "temperature" },
    { Header: "Total Toxics", accessor: "total_toxics" },
    { Header: "Toxic Inorganics", accessor: "toxic_inorganics" },
    { Header: "Toxic Organics", accessor: "toxic_organics" },
    { Header: "Turbidity", accessor: "turbidity" },
    { Header: "Vision Priority 303d", accessor: "visionpriority303d" },
    { Header: "Waterbody Report Link", accessor: "waterbodyreportlink", Cell: (props: any) => <a href={props.value} target="_blank" style={{ color: "#4DBCD3" }}>{props.value}</a> },
  ], []);

  const polyColumns: any[] = useMemo(() => [
    ...commonColumns,
    { Header: "Drinking Water Use", accessor: "drinkingwater_use" },
    { Header: "Fish Consumption Advisory", accessor: "fish_consumption_advisory" },
    { Header: "Habitat Alterations", accessor: "habitat_alterations" },
    { Header: "Has Alternative Plan", accessor: "hasalternativeplan" },
    { Header: "Solids/Chlorides/Sulfates", accessor: "solids_chlorides_sulfates" },
  ], []);
  
  const pointsColumns: any[] = useMemo(() => [
    ...commonColumns,
    { Header: "Ammonia", accessor: "ammonia" },
    { Header: "Chlorine", accessor: "chlorine" },
    { Header: "Sediment", accessor: "sediment" },
  ], []);
        
  useEffect(() => {
    switch (type) {
      case "fill":
        setTableColumn(polyColumns);
        break;
      case "line":
        setTableColumn(commonColumns);
        break;
      case "circle":
        setTableColumn(pointsColumns);
        break;
      default:
        break;
    }
  }, [type]);  

  const handleReportTable = (tableData:any, columns: any) => {
    const chunkSize = 10;
    const tables = [];

    for (let i = 0; i < columns.length; i += chunkSize) {
      const chunk = columns.slice(i, i + chunkSize);
      const tableChunkData = tableData.map((row: any) => {
        const newRow: any = {};
        chunk.forEach((col:any) => {
          newRow[col.accessor] = row[col.accessor];
        });
        return newRow;
      });

      tables.push(<ReportTable data={tableChunkData} columns={chunk} />);
    }

    return tables;
  };

  return (
    <Row className='wi-table-wrapper'>
      <h3 className="tile-title">Name: {tileName}</h3>
      {tableColumns && handleReportTable(data, tableColumns)}
    </Row>
  )
}

export default WaterImpairmentTable;
