import settings from "../../../settings.json";

const baseCollection = ['Overview'];
//'Site Surface Water Quality Threshold'

//baseEvaluations and active in RiskCategories set default active tabs
const RiskCollection = [{ 
    title: 'All',
    label: 'All Primary Risk Assessments',
    code : 'AL',
    evaluations: baseCollection.concat(['Climate Change Precipitation Risk', 'Site Water Security / Distress', 'Site Flood Zone Ratings', 'Site Surface Water Quality Threshold', 'PFAS Detection in Proximity', 'Superfund in Close Proximity', 'Drought Status', 'Located within a Protected Area', 'Riverine Flood Risk', 'Coastal Flood Risk', 'Wildfire', 'Watershed Health 303(d)'])
  }, {
    title: 'Water Stewardship / Availability',
    label: 'Water Stewardship / Availability',
    code : 'WS',
    evaluations: baseCollection.concat(['Site Water Security / Distress', 'Drought Status', 'Located within a Protected Area', 'Water Management'])
  }, {
    title: 'Water Quantity',
    label: 'Water Quantity',
    code : 'WC',
    evaluations: baseCollection.concat(['Climate Change Precipitation Risk', 'Site Flood Zone Ratings', 'Riverine Flood Risk', 'Coastal Flood Risk'])
  }, {
    title: 'Water Quality',
    label: 'Water Quality',
    code : 'WQ',
    evaluations: baseCollection.concat(['PFAS Detection in Proximity', 'Superfund in Close Proximity', 'Wildfire', 'Ingredient Sourcing', 'Watershed Health 303(d)'])
  }
];

export default RiskCollection;
