import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layer, Source, Popup } from "react-map-gl";
import { FillLayer } from "mapbox-gl";
import { RefContext } from "../RefContext";
import { LayerDataContext } from "../LayerDataContext";
import { PointContext } from "../PointContext";
import { ActivePopupContext } from "../ActivePopupContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";

import "./bia-styles.scss";

const BIALayer = ({
  id,
  fillColor,
  opacity,
  outlineColor,
  tileSet,
  sourceLayer,
  action,
  ausHydro = false,
  ausAq = false,
  phil = false,
  filter = null,
  phfh = false,
  wrfc = false,
  report = false,
  reportRoute = "/",
  tableColumns = [],
  sourceLink = "",
  sourceLabel = "",
}: any) => {
  const [features, setFeatures] = useState<any>(null);
  const [lngLat, setLngLat] = useState<any>(null);

  const { currentRef } = useContext(RefContext);
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);
  
  const navigate = useNavigate();

  const { setCurrentPoint } = useContext(PointContext);
  const { setCurrentLayerData } = useContext(LayerDataContext);

  const goTo = (coor: any, data: any) => {
    setCurrentPoint?.({
      lat: coor.lat,
      lng: coor.lng,
    });

    setCurrentLayerData?.({
      ...data,
      tableColumns,
      sourceLink,
      sourceLabel,
    });

    setTimeout(() => navigate(reportRoute), 100);
  };

  // @ts-ignore
  const mapInstance = currentRef && currentRef?.current;

  useEffect(() => {
    if (mapInstance) {
      const handleClick = (event: any) => {
        const layerObject = mapInstance.queryRenderedFeatures(event.point)?.[0];
        setFeatures(layerObject);

        if (layerObject) {
          const lngLat = event.lngLat;
          setLngLat(lngLat);
          setActivePopup({
            id,
            content: renderPopup(layerObject.properties, lngLat),
          });
        }
      };

      mapInstance.on("click", handleClick);

      return () => {
        mapInstance.off("click", handleClick);
      };
    }
  }, [mapInstance]);

  const renderPopup = (properties: any, lngLat: any) => {
    const handleClose = () => {
      setFeatures(null);
      setLngLat(null);
      setActivePopup({ id: null, content: null });
    };

    return (
      <Popup
        longitude={lngLat.lng}
        latitude={lngLat.lat}
        onClose={handleClose}
        closeButton={true}
        closeOnClick={true}
        anchor="bottom"
      >
        <div className="layer-info">
          <FontAwesomeIcon
            icon={faXmark}
            onClick={handleClose}
            style={{ position: "absolute", right: "2%", top: "2%", cursor: "pointer" }}
          />
          {properties.name}
          {properties.attr_IncidentName}
          {ausHydro && properties.AQUIF_TY}
          {ausAq && `Name: ${properties.HGUName}`}
          <br />
          {ausAq && `Aquifer Type: ${properties.AquiferTyp}`}
          <br />
          {ausAq && `Source: ${properties.Source}`}
          {phil && properties.name}
          {phfh && (
            <>
              Province: {properties.province}
              <br />
              Rating: {properties.rating}
            </>
          )}
          {wrfc && (
            <>
              <p style={{ color: "#4fc2d9" }}>{properties.attr_IncidentName}</p>
              {report && (
                <a
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    borderRadius: "6px",
                  }}
                  onClick={() => goTo(lngLat, properties)}
                >
                  Go to report
                </a>
              )}
            </>
          )}
        </div>
      </Popup>
    );
  };

  const BIALayerConfig: FillLayer = filter
    ? {
        id: `${id}-layer`,
        type: "fill",
        "source-layer": `${sourceLayer}`,
        paint: {
          "fill-opacity": opacity,
          "fill-outline-color": `${outlineColor}`,
          "fill-color": fillColor,
        },
        filter: filter,
      }
    : {
        id: `${id}-layer`,
        type: "fill",
        "source-layer": `${sourceLayer}`,
        paint: {
          "fill-opacity": opacity,
          "fill-outline-color": `${outlineColor}`,
          "fill-color": fillColor,
        },
      };

  return (
    <>
      <Source id={`${id}-layer`} type="vector" url={tileSet}>
        <Layer {...BIALayerConfig} />
      </Source>
      {activePopup && activePopup.id === id && activePopup.content}
    </>
  );
};

export default BIALayer;
