import { useContext, useState, useEffect, useMemo } from "react";
import { RefContext } from "../../../RefContext";
import { CatalogsContext } from "../../../CatalogsProvider";
import { AppContext } from "../../../AppContext";
import { Button, OverlayTrigger, Popover, Tooltip, Dropdown, Form, FloatingLabel, Card, ListGroup } from 'react-bootstrap';
import settings from "../../../settings.json";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowRightLong, faArrowLeft, faArrowLeftLong, faGlobePointer, faTrashCan, faMapLocation, faMap } from '@fortawesome/pro-solid-svg-icons';

import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import PointsWithinPolygon from '@turf/points-within-polygon';
import bboxPolygon from '@turf/bbox-polygon';
import bbox from '@turf/bbox';
import clustersDbscan from '@turf/clusters-dbscan';
import { Feature, GeoJsonProperties, Point, FeatureCollection } from 'geojson';

import { parse, unparse } from 'papaparse';
import { HUC12InfoPoint } from '../../../types/HUC12InfoPoint';

import agent from "../../../api/agent";


import { uniqueValues } from './report-data';

export const MCPoint = ({ global, top, reverse }: any) => {
  const { currentRef } = useContext(RefContext);
  const catalogsContext = useContext(CatalogsContext);
  const appContext = useContext(AppContext);

  const [selectedStyle, setSelectedStyle] = useState('True Elements Satellite View'); 
  const [browsedSources, setBrowsedSources] = useState<any[]>([]);

  const [selectedLayer, setSelectedLayer] = useState(global.customSelected);
  const [selectedLayerLabel, setSelectedLayerLabel] = useState();
  const [availableLayers, setAvailableLayers] = useState<any[]>([]);

  const [huc8InfoCatalogArray, setHuc8InfoCatalogArray] = useState<any[]>([]);
  const [huc12InfoCatalogArray, setHuc12InfoCatalogArray] = useState<any[]>([]);

  const [currentHuc8, setCurrentHuc8] = useState(global.selectedHUC8);
  const [currentHuc12, setCurrentHuc12] = useState(global.globalHuc12);
  const [showDelete, setShowDelete] = useState(false);
  // @ts-ignore
  const { current } = currentRef;

  const list = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]
  const cstmLayers:any = useMemo(() => list && list.map((point: any) => (
     "customLayer"+point
   )), [])


  useEffect(() => {
    const huc12InfoFileURL = '/huc12_info_update.csv';
    fetch(huc12InfoFileURL)
      .then(response => response.text())
      .then(responseText => {
        const data: HUC12InfoPoint[] | any[] = parse(responseText, {
          header: true,
        }).data;
     
       setHuc12InfoCatalogArray(data);
      });
  }, []);


  useEffect(() => {
    checkLayers();
  }, [cstmLayers]);

  useEffect(() => {
    checkLayers();
  }, [global.AppleLocationData]);


  const checkLayers = () => {
    let activeLayers = [] as any;
    for (var i = 1; i < 20; i++) {
      if(global["customLayer"+i]){
        activeLayers.push({layer: "customLayer"+i, name: global.layerNames[i-1], length: (global["customData"+i] && global["customData"+i].length)? global["customData"+i].length : null})
        //console.log(global["customData"+i])
      }
    }

    if(global.AppleLocationData){
      activeLayers.push({layer: "AppleLocationData", name: "Apple Locations", length: global.AppleLocationData.length-1})
    }

    //console.log(activeLayers, global, cstmLayers)
    setAvailableLayers(activeLayers);

    //console.log(global.customLayers, global.layerNames, global.AppleLocationData, global.hiddenData, global.customLayer1, global.layerIds)
  }

  const handleStyle = (event: any) => {

  }  




  const getMapDataset = (layername:string = 'custom-layer0') => {

    const mapSourcesDefault = [
        "mapbox://mapbox.satellite",
        "composite",
        "mapbox-gl-draw-cold",
        "mapbox-gl-draw-hot",
        "huc12s-line"
    ];

    let layernm = layername;
    if(layername && layername.substr(0, 11) === 'customLayer'){
      layernm = 'custom-layer' + layername.substr(11)
    }

    let sources = current && current.getStyle() && current.getStyle().sources;
    //console.log(layernm, current.getStyle())

    if(sources){
        let filteredArr =  Object.entries(sources).filter((k:any) => mapSourcesDefault.indexOf(k[0]) < 0 && k[1].type === 'geojson')
        console.log(filteredArr, filteredArr[0], layername, layername.substr(0, 11), layername.substr(11), layername.substr(11,2), 'ooo')
        if(filteredArr && filteredArr.length>0){ 
          console.log(filteredArr[0][0], filteredArr[0][0]==="custom-layer0")


          let nextBrowse = filteredArr[0];
          //if()


          let findBrowse = filteredArr.find((k:any) => browsedSources.indexOf(k[0]) < 0 )
          console.log(findBrowse)
          findBrowse = filteredArr.find((k:any) => k[0] === layernm) //+++
          console.log(findBrowse, layernm, layername, browsedSources, filteredArr)
          if(layernm === 'AppleLocationData'){
            let filteredApp =  filteredArr.filter((k:any) => k[1].data.features.length === 13)
            console.log(filteredApp)
            if(filteredApp && filteredApp.length > 0){
              findBrowse = filteredApp[0];
            }
          }
          if(findBrowse){
            setBrowsedSources([...browsedSources,findBrowse[0]])  
            nextBrowse = findBrowse;
          } else {
            setBrowsedSources([nextBrowse[0]])
          }

          //@ts-ignore
          let rFeatureSet = nextBrowse[1].data;

          return [rFeatureSet, filteredArr[0][0]];
        }
    }
  }  

  const handleConvert = () => {
    let _FeatureSet =  getMapDataset();
    if(_FeatureSet && _FeatureSet.length>0){

      let rFeatureSet =  _FeatureSet[0]; 
      let FeatureSet_label =  _FeatureSet[1]; 

      if(rFeatureSet && rFeatureSet.features && rFeatureSet.features.length>0){

        console.log(rFeatureSet)

        if(rFeatureSet.features[0].properties && (rFeatureSet.features[0].properties['HUC12']||rFeatureSet.features[0].properties['Huc12']||rFeatureSet.features[0].properties['huc12'])){
            console.log('huc12')
        }

        let skipCluster = false;
        if(FeatureSet_label==="custom-layer0"){
            skipCluster = true;
        }


        updateHucLayer(rFeatureSet, skipCluster)
      }
    }
  }
        

      



/*          let centroidR = center(rFeatureSet);
          if(centroidR && centroidR.geometry && centroidR.geometry.coordinates){
              current?.flyTo({
                essential: true,
                center: centroidR.geometry.coordinates,
                zoom: 8,
              });
          }       */   


/*    async function getNextBatch(cit:any){
        return await Promise.all(cit.map((seg:any) => onProcess_s(seg))).then((values) => {
        
          if(values && values.length>0 && values[0]){
            //newData.concat(values)
            //setUploaderProcessedLocations(newData);   

            return values;         
          }
        });
    }
    */

    async function onProcess_s(dataItem: any){
      //console.log(dataItem.geometry.coordinate, dataItem.geometry && dataItem.geometry.coordinates && dataItem.geometry.coordinates[1])
      if(dataItem.geometry && dataItem.geometry.coordinates && dataItem.geometry.coordinates[1]){

         return agent.Utilities.LatLngToHuc12(dataItem.geometry.coordinates[1], dataItem.geometry.coordinates[0])
        .then(({ body }: any) => {
               if (body && body.length > 0 && body[0].Huc8){
                 return body[0].Huc8;
               } else {
                 return null;
               }
        })      
      } else {
        return null;
      }
    }

    const updateHucLayer = (mFeatureCollection:any, skipCluster?: boolean) => {
        console.log(mFeatureCollection)
        let huc12Centroids = newFeatureCollection(huc12InfoCatalogArray); 
        let radiusTest = 2;
        let minPoint = 0;
        let newSet = clustersDbscan(mFeatureCollection, radiusTest, {minPoints: minPoint} )

        let h12za = mFeatureCollection.features
        if(skipCluster){
          newSet = mFeatureCollection;
        } else {
          const h12Array = newSet.features.map((item:any) => item.properties.cluster)
          const h12Arrayza = newSet.features.map((item:any) => item.properties.dbscan)
          h12za = newSet.features.filter((obj1, i, arr) => 
            arr.findIndex(obj2 => (obj2.properties.cluster === obj1.properties.cluster)) === i
          )          
        }


        //custom-layer0
        console.log(minPoint, skipCluster, h12za)

        //uniqueValuesA(newSet.features, 'properties.cluster')
        let newDataset = [] as any;
        //const uniqueArray = newSet.features.map((item:any) => item.properties['cluster'])
        Promise.all(h12za.map((seg:any, index:any) =>{ return onProcess_s(seg); })).then((values) => {
          console.log('values', values)      
          if(values && values.length>0){
              let hucFilter = values.map((item:any) => huc12InfoCatalogArray.find((o: any) => o["huc12"] === item) );
              console.log(hucFilter)
              let newDataset = [...hucFilter,...global.resultsDataset]
              let newDatasetFilter = newDataset.filter((item:any) => item)
              console.log(newDataset, newDatasetFilter)
              let datasetUnique =  newDatasetFilter.filter((obj1:any, i:any, arr:any) => 
                arr.findIndex((obj2:any) => (obj2.huc12 === obj1.huc12)) === i
              )    

              global.setResultsDataset(datasetUnique)
          }
        });


        console.log(newSet, newDataset, radiusTest, h12za )
        //global.setResultsDataset(newDataset)
    }

    const newFeatureCollection = (fData: any) => {
      const features: Array<Feature<Point, GeoJsonProperties>> = [];
      for (let dataItem of fData) {
        const featureWithPoint: Feature<Point> = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [+dataItem.centroid_longitude, +dataItem.centroid_latitude],
          },
          properties: dataItem,
        };
        features.push(featureWithPoint);
      }
      const featureCollectionFromData: FeatureCollection = {
        type: 'FeatureCollection',
        features: features,
      };
      return featureCollectionFromData;
    };
                                                                                                          
  const BrowseLocation = (changeindex: number) => {
    // Data requires HUC8, Country
    console.log('b          ', browsedSources, selectedLayer)
    let _FeatureSet =  getMapDataset(selectedLayer);
    if(_FeatureSet && _FeatureSet.length>0 && _FeatureSet[0].features  && _FeatureSet[0].features.length>0){

      let rFeatureSet =  _FeatureSet[0]; 
      let FeatureSet_label =  _FeatureSet[1]; 

      //console.log('BrowseLocation', _FeatureSet, rFeatureSet, FeatureSet_label, global.selectedHUC8)

/*      let lat = 1, lng = 1;
      let pointHuc8 = "";
      const res = await agent.Utilities.LatLngToHuc8(lat, lng)
      .then(({ body }: any) => pointHuc8 = body[0].Huc8)
      .catch((error) => console.log(error))*/


      let findBrowse = rFeatureSet.features.find((k:any) => global.selectedHUC8 === k.properties.HUC8 )
      let findIndex_ = rFeatureSet.features.findIndex((k:any) => global.selectedHUC8 === k.properties.HUC8 )
     // console.log('findBrowse', findBrowse, findIndex_,  global.selectedHUC8) 

      /*if(findBrowse){
        rFeatureSet.features.forEach((dataItem:any, index:number) => {

        })        
      }*/

      let newLoc = rFeatureSet.features[0];
      let indexCheck = rFeatureSet.features[findIndex_ + changeindex];
      if(findIndex_ > -1 && indexCheck  ){         
        newLoc = indexCheck;    
        console.log('findIndex_', indexCheck ) 
      } else {
        if((findIndex_ + changeindex) === rFeatureSet.features.length-1){
          console.log('end') //stay at 0
        } else if ((findIndex_ + changeindex) === -1){ //go to end
          newLoc = rFeatureSet.features[rFeatureSet.features.length-1];
        }
      }

      if(appContext && appContext.updateContext){ //
        let stringLocation = "";
        let stringCountry = (newLoc.properties.Country) ? newLoc.properties.Country : global.currentCountry;

        if(newLoc.properties.City){stringLocation = stringLocation  + newLoc.properties.City + ', ' }
        if(newLoc.properties.State){stringLocation = stringLocation  + newLoc.properties.State + ', '}
        if(stringCountry){stringLocation = stringLocation  + stringCountry} 
        console.log((!newLoc.properties.HUC8 && newLoc.properties.Latitude && newLoc.properties.Longitude), newLoc.properties.HUC8, newLoc.properties, newLoc.properties.City, appContext, global.currentReport, stringCountry, newLoc.properties.Country, global.currentCountry) 
       

      if (!newLoc.properties.HUC8 && newLoc.properties.Latitude && newLoc.properties.Longitude) {
          agent.Utilities.LatLngToHuc8(newLoc.properties.Latitude, newLoc.properties.Longitude).then((res: any) => {
            const responseBody = res.body;
           
            const responseHUC8 = responseBody[0].Huc8 ? responseBody[0].Huc8 : '';
           if(appContext && appContext.updateContext){
              appContext.updateContext(
                responseHUC8,
                        stringLocation,
                        global.currentReport,
                        stringCountry 
              );
            }
            global.setSelectedHUC8(responseHUC8)
            current?.flyTo({
              essential: true,
              center: newLoc.geometry.coordinates,
              zoom: 8,
            });    
          });
        } else {

          appContext.updateContext(
                        newLoc.properties.HUC8,
                        stringLocation,
                        global.currentReport,
                        stringCountry  //+++ validation
                      )       
          global.setSelectedHUC8(newLoc.properties.HUC8)
          current?.flyTo({
            essential: true,
            center: newLoc.geometry.coordinates,
            zoom: 8,
          });          
        }
      }
    }
 }


  const NextLocation = () => {
    console.log('b', browsedSources, global)
    BrowseLocation(1);
  }

  const PreviousLocation = () => {
    BrowseLocation(-1);
  }

  const onChangeSearch = (event: any) => {   
    console.log(event)
       if (event) {
         setSelectedLayer(event);
         global.setCustomSelected(event);
         let findBrowse = availableLayers.find((k:any) => k.layer == event )
         if(findBrowse){
           setSelectedLayerLabel(findBrowse.name)
         }
      }       
  }

      

  const popover = (
    <Popover id="popover-settings" style={{minWidth: 400}}>
      <Popover.Header as="h3">Locations</Popover.Header>
      <Popover.Body>
            <div style={{display: 'flex', flexDirection: 'column'}}>
 


               <button className="btn-primary" style={{display: 'none', color:'white'}} onClick={handleConvert} >
                  <div style={{display: 'flex', flexDirection: 'column', marginTop: 5}}>
                    <FontAwesomeIcon icon={faGlobePointer} size='lg' className="fs-icon-app" />
                    <span className="mapboxgl-ctrl-icon" aria-hidden="true" title="Convert Points to Watersheds" >Convert Point Data</span>
                  </div> 
                </button>


                <Form.Group className="mb-2 filter-dropdown-group" controlId="floatingMonitoringLocationType" >
                        <span>Browse Active Datasets</span>
                        <Dropdown  style={{ paddingTop: 5}} className="filter-dropdown"  onSelect={onChangeSearch}
                          aria-label="MonitoringLocIdent_MonitoringLocationTypeName" defaultValue={selectedLayer}>  
                          <FloatingLabel className="filter-dropdown-floating" controlId="floatingMonitoringLocationType" label="Name" >                                                  
                             <Dropdown.Toggle className="filter-dropdown-toggle" id="dropdown-autoclose-true"  >
                              <span style={{color:'white'}} className="filter-dropdown-toggle-span">{selectedLayerLabel}</span>
                            </Dropdown.Toggle>
                          </FloatingLabel> 
                            <Dropdown.Menu key={"filter-dropdown-menu"+availableLayers.length} className="filter-dropdown-menu" >

                                {/* @ts-ignore */}

                              {availableLayers && availableLayers.map((obj:any, index:any) =>
                              // @ts-ignore
                                  (<Dropdown.Item  key={'wm-proj-options'+index} eventKey={obj.layer} value={obj.layer}> 
                                      <span className="filter-dropdown-item-span">{obj.name} : {obj.layer} {(obj.length) ? `(${obj.length})` : ''}</span></Dropdown.Item>)
                              )} 
                            </Dropdown.Menu>
                        </Dropdown>
                    
                </Form.Group>


                <div style={{display: 'flex', flexDirection: 'row', marginTop: 50, paddingBottom: 15, justifyContent: 'space-between'}}>
                  <button className="btn-primary" style={{color:'white', width: 100}} onClick={PreviousLocation}  >            
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <FontAwesomeIcon icon={faArrowLeftLong} size='lg' className="fs-icon-app" />
                        <span style={{paddingLeft: 2, display: 'inline-block', fontSize:12, paddingTop:4}}>Previous</span>
                    </div> 
                  </button>


                  <button className="btn-primary" style={{color:'white', width: 100}} onClick={NextLocation}>                    
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                        <FontAwesomeIcon icon={faArrowRightLong} size='lg' className="fs-icon-app" />
                        <span style={{paddingLeft: 2, display: 'inline-block', fontSize:12, paddingTop:4}}>Next</span>
                      </div> 
                  </button>
                </div> 



              </div>  

      </Popover.Body>
    </Popover>
  );


/*                <button onClick={handleCenter}  className={"map-point-button"}>
                  <span className="mapboxgl-ctrl-icon" aria-hidden="true" title="Select Location" />
                </button>*/

    return (
        <>           
          <div style={(reverse) ? {position: 'absolute', bottom: top, left: 10} : {position: 'absolute', top: top, left: 10}} className="mapboxgl-ctrl mapboxgl-ctrl-group">
            <div className="tools-box">
              <OverlayTrigger trigger="click" rootClose placement="right" overlay={popover}>
                <button className={"map-point-button map-button"}>
                  <span className="mapboxgl-ctrl-icon" aria-hidden="true" title="Locations" />
                </button>
              </OverlayTrigger>

            </div>
          </div>
        </>
    )
}
                        
export default MCPoint