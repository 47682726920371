import './contact-admin.scss'


const ContactAdmin = ({ global, logged }: any) => {
    if(logged && global && global.setUserLogged){
      global.setUserLogged(true)
    }

    return (
        <div className="ca-report-container">
            <div className="graph-container">
                <img decoding="async" className="dp-dfg-featured-image" 
                src="https://trueelements.com/wp-content/uploads/2023/02/One-Platform-Serves-Multiple-Needs-510x382.png" 
                alt="True Elements’ Water Intelligence Solutions Now Available to help Organizations Succeed in a Changing Climate"
                 width="510" height="382" data-ratio="0.74901960784314" title="" />
                <p>
                    Interested in learning how this product
                    can address your needs?
                    <br /><br />
                    Contact your Customer Relationship Manager to learn more.
                </p>
            </div>
        </div>
    )
}

export default ContactAdmin