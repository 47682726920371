import { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../../AppContext';
import './styles.scss';
import { Table } from 'react-bootstrap';
import agent from '../../../api/agent';
import { Line } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMaximize, faMinimize } from '@fortawesome/pro-solid-svg-icons';
import fullscreenIcon from '../../../assets/fullscreen-icon.svg';
import RightHeader from '../../../components/RightHeader'
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-plugin-zoom';

const expandTable = {
  position: 'absolute',
  zIndex: 100,
  width: '80vw',
  height: '100vh',
  top: '0',
};

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function DailyLakeMeadLevelsReportReport({ global }: any) {
  const { dates } = global;
  global.setUserLogged(true);

  const appContext: any = useContext(AppContext);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [indexes, setIndexes] = useState<any>();
  const [wholeData, setWholeData] = useState<any>();
  const [raw, setRaw] = useState<any>();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const initial = {
    labels: [] as any,
    datasets: [] as any,
  } as const;
  const [data, setData] = useState(initial);
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;

  const handleIndexes = (start: any, end: any) => {
    const dates = wholeData && wholeData.body.map((date: any) => handleDate(date.date));
    const indexOne = dates && dates.findIndex((i: any) => i === start);
    const indexTwo = dates && dates.findIndex((i: any) => i === end);
    const slicedRaw = wholeData.body.slice(indexOne, indexTwo);

    transformData(wholeData, indexOne, indexTwo);
    setRaw(slicedRaw);
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: 'Daily Reading',
          color: 'white',
          font: {
            size: 16,
          },
        },
        zoom: {
          enabled: true,
          mode: 'x',
        },
        ticks: {
          color: 'white',
        },
      },
      x: {
        display: true,
        title: {
          display: true,
          text: 'Date',
          color: 'white',
          font: {
            size: 16,
          },
        },
        ticks: {
          color: 'white',
        },
      },
    },
    plugins: {
      legend: {
        position: 'top' as const,
      } as const,
      title: {
        display: true,
        color: 'white',
        text: 'Max Yearly Water Level [ft] and Min Yearly Water Level [ft] by Year',
        font: {
          size: 18,
        },
      } as const,
    } as const,
  } as const;

  const handleDate = (unix: number) => {
    const date = new Date(unix);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const transformData = (input: any, start: any, end: any) => {
    const mData = {
      labels: input.body.slice(start, end).map((x: any) => handleDate(x.date)),
      datasets: [
        {
          label: 'Daily Water Level [ft]',
          data: input.body.slice(start, end).map((x: any) => x.level),
          backgroundColor: getRandomColor(),
        },
      ],
    } as const;
    setData(mData);
  };

  useEffect(() => {
    if (global.trackerOn) global.trackerOn(false)
  }, [])

  useEffect(() => {
    setLoading(true);
    appContext.updateContext('15010005', 'Lake Mead');
    agent.Reports.LakeMead()
      .then((resp: any) => {
        setWholeData(resp);
        setIndexes({
          start: resp.body.length - 91,
          end: resp.body.length - 1,
        });
        const data = resp.body.slice(indexes.start, indexes.end);
        setRaw(data);
        transformData(resp, indexes.start, indexes.end);
      })
      .finally(() => {
        setPageLoaded(true);
        setLoading(false);
      });
  }, [pageLoaded]);

  useEffect(() => {
    const { startDate, endDate } = dates;
    wholeData && handleIndexes(startDate, endDate);
  }, [dates, wholeData]);

  const routeData = {
    name: 'Daily Lake Mead Levels',
    parentReportName: 'Surface Water',
    root: '/',
  };

  const renderRows = (input: any) =>
    input.map((row: any) => (
      <tr className='data-column-body'>
        <td className='data-column-item'>{handleDate(row.date)}</td>
        <td className='data-column-item'>{row.level}</td>
      </tr>
    ));

  const renderMax = (input: any) => {
    const max = input.map((value: any) => value.level);
    return Math.max(...max);
  };

  const renderMin = (input: any) => {
    const min = input.map((value: any) => value.level);
    return Math.min(...min);
  };

  return (
    <FullScreenComp handle={fullscreenhandle}>
      <div className='daily-lake-mead-levels'>
        <div className='WLML-report-container' id='WLML-report'>
          <div className='WLML-report-header'>
            <ReportHeader global={global} data={raw} filterLabelDays={[dates.startDate, dates.endDate]}
              reportID={"WLML-report"} fullScreenClickHandle={fullscreenhandle.enter} />
          </div>
          <RightHeader global={global} button={false} />
          <div className='container-lake-mead'>
            <section style={{ padding: '0' }} className='left-side'>
              <div className='data-table'>
                <Table>
                  <thead className='thead'>
                    <tr className='data-table-header'>
                      <th className='data-column-header'>Date</th>
                      <th className='data-column-header'>Daily Level [ft]</th>
                    </tr>
                  </thead>
                  <tbody>{raw && renderRows(raw)}</tbody>
                </Table>
              </div>
            </section>
            {/* @ts-ignore */}
            <section style={open ? expandTable : null} className='right-side'>
              {open ? (
                <button onClick={() => setOpen(false)} className='table-button'>
                  <FontAwesomeIcon className='paginate-btn' icon={faMinimize} />
                </button>
              ) : (
                <button onClick={() => setOpen(true)} className='table-button'>
                  <FontAwesomeIcon className='paginate-btn' icon={faMaximize} />
                </button>
              )}
              <div className='line-graph'>
                {data !== initial && <Line options={options} data={data} />}
                {global.setLoading(loading)}
                <div className='period' style={{ display: 'flex' }}>
                  {raw && (
                    <>
                      Period:<span>{raw[0] && handleDate(raw[0].date)}</span>
                      <span> - </span>
                      <span>{raw[raw.length - 1] && handleDate(raw[raw.length - 1].date)}</span>
                    </>
                  )}
                </div>
                <div>
                  {raw && <h3>Max Level: {renderMax(raw)} ft</h3>}
                  {raw && <h3>Min Level: {renderMin(raw)} ft</h3>}
                </div>
              </div>
            </section>
          </div>
          <ReportFooter />
        </div>
      </div>
    </FullScreenComp>
  );
}

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
