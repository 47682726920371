import { useState, Ref, useRef, useContext, useEffect, useCallback } from 'react';
import {
  Map,
  MapRef,
  Source,
  Layer,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  LayerProps,
  Popup,
} from 'react-map-gl';
import RightHeader from '../../../components/RightHeader'
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import LoadingSpinner from '../../../components/LoadingSpinner';

import fullscreenIcon from '../../../assets/fullscreen-icon.svg';
import { ToastContainer, toast } from 'react-toastify';
import { Location, useLocation } from 'react-router-dom';
import { message_hucrestriction, message_nodatalocation, message_noresult } from '../../../Constants';
import settings from '../../../settings.json';
import { CatalogsContext } from '../../../CatalogsProvider';
import { AppContext } from '../../../AppContext';

import agent from '../../../api/agent';
import { PointContext } from '../../../PointContext'
import { RefContext } from '../../../RefContext';
import {
  flyToInitialLocationHandler,
  flyToLngLat,
  getRandomColor,
  groupBy,
  hasAValue,
  resolveCurrentactiveFilters,
} from '../../utils';
import Globals from '../../../types/GlobalsType';
import TimeReferenceUnit from '../../../types/TimeReferenceUnits';
import { Feature, FeatureCollection, GeoJsonProperties, Point } from 'geojson';
import moment from 'moment';
import { DateTime } from 'luxon';
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import { LOCATIONS_FILTER, MEASUREMENTS_FILTER } from '../../../Constants';
import { Chart } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import LiveWaterSensorsResponseBodyModel from '../../../types/LiveWaterSensorsResponseBodyModel';
import StationDataMeasures from '../../../types/StationDataMeasures';
import MapPointColorDefinition from '../../../types/MapPointColorDefinition';
import StationData from '../../../types/StationData';
import MapLegend from '../shared/MapLegend';

import MapAddition from  '../shared/MapAddition';

import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import './source.scss';

Chart.register(zoomPlugin);

const LiveWaterSensors = (props: any) => {
  const { global } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);

  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const [isFullScreenChartActive, setFullScreenChartActive] = useState(false);
  const { setCurrentPoint, currentPoint } = useContext(PointContext);

  const fullscreenChartContainerClickHandler = () => {
    setFullScreenChartActive(!isFullScreenChartActive);
  };

  const mapRef = useRef<MapRef>();
  const { setCurrentRef } = useContext(RefContext);
  useEffect(() => { setCurrentRef?.(mapRef) }, [mapRef]);

  const [currentMapRef, setCurrentMapRef] = useState<any>(null);

  const location: Location = useLocation();
  //@ts-ignore
  const routeData: any = location.state?.data;

  const [loading, setLoading] = useState(true);
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [cursor, setCursor] = useState<string>('');
  const [clickedFeatureName, setClickedFeatureName] = useState<string>('');
  const [featureClicksCounter, setFeatureClicksCounter] = useState<number>(0);

  const [statusMessage, setStatusMessage] = useState<any>(null);

  const [reportData, setReportData] = useState<any>();
  const [chartData, setChartData] = useState<any>({
    labels: [],
    datasets: [],
    borderColor: '#ff6384',
  });

  const daysToLimit = 367;

  const selectedDateRange = global.dateRangeSelected || '';
  const daysFilterValue = (global as Globals).filterDaysSelected || 3;
  const daysFilterReferenceUnit = (global as Globals).timeReferenceUnit || 3;
  const selectedLocationsFilterValues = (global as Globals).filterLocationsSelected;
  const selectedMeasurementFilterValue =
    (global as Globals).selectedMeasurementFilterValue || 'StreamFlow';

  const [featureData, setFeatureData] = useState<FeatureCollection>();
  const [stationsMapPointColorsCatalog, setStationsMapPointColorsCatalog] = useState<
    Array<{
      key: string;
      color: string;
    }>
  >();
  const [currentSelectedStationName, setCurrentSelectedStationName] = useState<string>();
  const [filterLabelDays, setFilterLabelDays] = useState<any>([]);
  const mapFilterDefinitionToHook: any = {
    LOCATIONS_FILTER: (global as Globals).filterLocationsSelected,
    MEASUREMENTS_FILTER: (global as Globals).selectedMeasurementFilterValue,
  };

  const mapInitialViewState = {
    longitude: settings.defaultLocationMap.lng,
    latitude: settings.defaultLocationMap.lat,
    zoom: 3,
  };


  const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  useEffect(() => {
    global.setUserLogged(true);
    if (global.trackerOn) global.setTrackerOn(false);

    const selectedHUC8Value = appContext.selectedHUC8;
    if (selectedHUC8Value && global.hucRestrict.length > 0 && (global.hucRestrict.indexOf(selectedHUC8Value) < 0)) {
      return global.notify(message_hucrestriction)
    }

    (global as Globals).setfilterLocationsSelected([]);
    (global as Globals).setSelectedMeasurementFilterValue('Streamflow');
    global.setDateRangeSelected([new Date(new Date().setDate(new Date().getDate() - 5)), new Date()]);

    let daysToQuery = 5;
    let startDate = '';
    const one_day = 1000 * 60 * 60 * 24;
    if (selectedDateRange && selectedDateRange.length > 1) {
/*      const daysDiff = Math.floor((selectedDateRange[1] - selectedDateRange[0]) / one_day) + 1;
      daysToQuery = Math.min(daysDiff, daysToLimit);
      startDate = DateTime.fromJSDate(new Date(selectedDateRange[1])).toFormat('yyyyLLdd');
      */
    }
    daysToQuery = Math.max(1, daysToQuery);

    setLoading(true);
    agent.Reports.LiveWaterSensors(appContext.selectedHUC8, daysToQuery, startDate).then(
      (res: any) => {
        const responseBody: LiveWaterSensorsResponseBodyModel = res.body;
        updateComponentStates(responseBody);
      }
    );
    setLoading(false);
    setCurrentPoint?.({ ...routeData, ...currentPoint })

    return () => {
      cleanUpFilters();
    };
  }, []);

  useEffect(() => {
    if (mapRef.current === currentMapRef) {
    } else {
      setCurrentMapRef(mapRef.current);
    }

    setTimeout(() => {
      flyToInitialLocationHandler(appContext.selectedHUC8, mapRef, catalogsContext.huc8Catalog);
    }, 1000);
  }, [mapRef.current]);

  useEffect(() => {

    const selectedHUC8Value = appContext.selectedHUC8;
    if (selectedHUC8Value && global.hucRestrict.length > 0 && (global.hucRestrict.indexOf(selectedHUC8Value) < 0)) {
      return global.notify(message_hucrestriction)
    }
    setLoading(true);

    let daysToQuery = 5;
    let startDate = '';
    const one_day = 1000 * 60 * 60 * 24;
    if (selectedDateRange && selectedDateRange.length > 1) {
      const daysDiff = Math.floor((selectedDateRange[1] - selectedDateRange[0]) / one_day) + 1;
      daysToQuery = Math.min(daysDiff, daysToLimit);
      startDate = DateTime.fromJSDate(new Date(selectedDateRange[1])).toFormat('yyyyLLdd');
    }
    daysToQuery = Math.max(1, daysToQuery);

    agent.Reports.LiveWaterSensors(appContext.selectedHUC8, daysToQuery, startDate).then(
      (res: any) => {
        const responseBody: LiveWaterSensorsResponseBodyModel = res.body;
        updateComponentStates(responseBody);
        setLoading(false);
        flyToInitialLocationHandler(appContext.selectedHUC8, mapRef, catalogsContext.huc8Catalog);
      }
    );
  }, [selectedDateRange, appContext.selectedHUC8]);

  useEffect(() => {
    setLoading(true);
    if (reportData) {
      updateComponentStates(reportData);
    }
    setLoading(false);
  }, [selectedLocationsFilterValues, selectedMeasurementFilterValue]);

  useEffect(() => { }, [selectedMeasurementFilterValue]);

  const cleanUpFilters = () => {
    const globalProps = global as Globals;
    globalProps.setfilterDaysSelected(3);
    globalProps.setTimeReferenceUnit(3);
    globalProps.setfilterLocationsSelected([]);
    globalProps.setSelectedMeasurementFilterOptions([]);
    globalProps.setSelectedMeasurementFilterValue('Streamflow');
  };

  useEffect(() => {
    if (mapRef.current === currentMapRef) {
    } else {
      setCurrentMapRef(mapRef.current);
    }

    setTimeout(() => {
      if (mapRef && mapRef.current) {
        mapRef.current.on('click', 'pointlayer', clickedFeatureHandler);
      }
    }, 1000);

    return () => {
      mapRef.current?.off('click', 'pointlayer', clickedFeatureHandler);
    };
  }, [mapRef.current]);

  useEffect(() => {
    resolveClickedFeatureBehavior(clickedFeatureName);
  }, [clickedFeatureName, featureClicksCounter]);

  const generateColor = (arrayOfKeys: Array<string>): MapPointColorDefinition[] => {
    return arrayOfKeys.map(x => {
      return { key: x, color: getRandomColor() };
    });
  };

  const updateLocationsFilterOptions = (stationsData: StationData[]) => {
    const locations = stationsData.map(x => {
      return { name: x.Name };
    });
    (global as Globals).setfilterLocationsPopulated(locations);
  };

  const updateMeaurementsFilterOptions = (stationsData: StationDataMeasures[]) => {
    if (stationsData && stationsData.length > 0) {
      const responseObjectProperties = Object.keys(stationsData[0]);
      const objectPropertiesToExclude = ['Name', 'Date'];
      const measurementOptionsToDisplay = responseObjectProperties.filter(
        el => !objectPropertiesToExclude.includes(el)
      );
      (global as Globals).setSelectedMeasurementFilterOptions(measurementOptionsToDisplay);
    }
  };

  function resolveDataWithFilters(data: StationDataMeasures[] = reportData?.data) {
    const currentactiveFilters = resolveCurrentactiveFilters(global);
    let dataUnderWork = data;
    for (let filter of currentactiveFilters) {
      const groupedData = groupDataByFilterType(dataUnderWork, filter);
      const currentGroupsOfData = Object.keys(groupedData);

      const selection = mapFilterDefinitionToHook[filter];

      if (typeof selection == 'string') {
        if (currentGroupsOfData.includes(selection)) {
          dataUnderWork = [...groupedData[selection]];
        }
      } else {
        let result: any[] = [];

        if (selection.length > 0) {
          for (let group of selection) {
            if (currentGroupsOfData.includes(group)) {
              const concatenatedResult = result.concat(groupedData[group]);
              result = [...concatenatedResult];
            }
          }
        } else {
          result = reportData?.data || [];
        }
        dataUnderWork = result;
      }
    }
    return dataUnderWork;
  }

  function groupDataByFilterType(data: any[], filter: string) {
    switch (filter) {
      case LOCATIONS_FILTER:
        return groupBy<StationDataMeasures, any>(data, x => x.Name);
        break;
      case MEASUREMENTS_FILTER:
        return groupBy<StationDataMeasures, any>(
          data,
          x => x[selectedMeasurementFilterValue as keyof StationDataMeasures]
        );
        break;

      default:
        return {};
        break;
    }
  }

  const updateComponentStates = (response: LiveWaterSensorsResponseBodyModel) => {
    const returnedData = response.data;

    const dateExtent = d3Array.extent<any, any>(returnedData, d => d["Date"]);

    if (selectedDateRange && selectedDateRange.length > 1) {
      let filterDays = [DateTime.fromJSDate(new Date(selectedDateRange[0])).toLocaleString(), DateTime.fromJSDate(new Date(selectedDateRange[1])).toLocaleString()];
      setFilterLabelDays(filterDays);
    } else if (dateExtent && dateExtent[0] && dateExtent[1]) {
      let filterDays = [DateTime.fromMillis(dateExtent[0]).toLocaleString(), DateTime.fromMillis(dateExtent[1]).toLocaleString()];
      setFilterLabelDays(filterDays);
    }

    setReportData(response);
    updateLocationsFilterOptions(response.stations);

    updateMeaurementsFilterOptions(response.data);
    const stationsMapPointColors = generateColor(response.stations.map((x: any) => x.Name));
    setStationsMapPointColorsCatalog(stationsMapPointColors);
    createFeatureCollection(response.stations);

    if (
      hasAValue((global as Globals).filterLocationsSelected) ||
      hasAValue(selectedMeasurementFilterValue)
    ) {
      const filteredData = resolveDataWithFilters(response.data);
      updateChartData(filteredData, stationsMapPointColors);
    } else {
      updateChartData(response.data, stationsMapPointColors);
    }
  };

  const getColorForStation = (
    stationName: string,
    colorsCatalog: MapPointColorDefinition[] | undefined
  ) => {
    if (colorsCatalog) {
      return colorsCatalog.find(x => x.key == stationName)?.color;
    }
    return '#FFFF';
  };

  const updateChartData = (
    reportData: StationDataMeasures[],
    colorsCatalog: MapPointColorDefinition[]
  ) => {
    const dataGroupedByDate = groupBy<StationDataMeasures, any>(reportData, x => x.Date);
    const _unixDates = Object.keys(dataGroupedByDate);
    const unixDates = _unixDates.sort();
    const humanReadeableDates = unixDates.map(x => moment(Number(x)).format('MMM DD YYYY'));
    const extractedDataSets = [];

    const dataGroupedByStationName = groupBy<StationDataMeasures, any>(reportData, x => x.Name);

    for (let stationName in dataGroupedByStationName) {
      const streamFlowsValues = dataGroupedByStationName[stationName].map(
        x => x[selectedMeasurementFilterValue as keyof StationDataMeasures]
      );

      let dataObject = {
        label: stationName.substr(0, 20),
        data: streamFlowsValues,
        backgroundColor: getColorForStation(stationName, colorsCatalog),
      };

      extractedDataSets.push(dataObject);
    }

    const chartConfig = {
      labels: humanReadeableDates,
      datasets: extractedDataSets,
      borderColor: '#ff6384',
      backgroundColor: '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6),
    };
    setChartData(chartConfig);
  };

  const calculateDays = (days: number, timeReferenceUnit: TimeReferenceUnit): number => {
    switch (timeReferenceUnit) {
      case 1:
        return days * 365;
        break;

      case 2:
        return days * 30;
        break;

      default:
        return days;
        break;
    }
  };

  const createFeatureCollection = (stationsInfo: StationData[]) => {
    const features: Array<Feature<Point, GeoJsonProperties>> = [];
    for (let station of stationsInfo) {
      const featureWithPoint: Feature<Point> = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [Number(station.Longitude), Number(station.Latitude)],
        },
        properties: {
          Name: station.Name,
          Longitude: station.Longitude,
          Latitude: station.Latitude,
        },
      };
      features.push(featureWithPoint);
    }

    const featureCollectionFromReportData: FeatureCollection = {
      type: 'FeatureCollection',
      features: features,
    };
    setFeatureData(featureCollectionFromReportData);
  };

  const flattenColorsCatalogArray = (
    colorsCatalog: MapPointColorDefinition[] | undefined
  ): string[] => {
    if (colorsCatalog) {
      return colorsCatalog
        .map(x => {
          return [x.key, x.color];
        })
        .flat();
    }

    return [];
  };

  const layerStyle: LayerProps = {
    id: 'pointlayer',
    type: 'circle' as const,
    paint: {
      'circle-radius': 8,
      'circle-color': [
        'match',
        ['get', 'Name'],
        ...flattenColorsCatalogArray(stationsMapPointColorsCatalog),
        'gray',
      ],
      'circle-stroke-color': 'white',
      'circle-stroke-width': 1,
      'circle-opacity': 1,
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: 'Max. of Reading',
          color: 'white',
          font: {
            size: 16,
          },
        },
        ticks: {
          color: 'white',
        },
      },
      x: {
        display: true,
        title: {
          display: true,
          text: 'Date',
          color: 'white',
          font: {
            size: 16,
          },
        },
        ticks: {
          color: 'white',
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
        },
        maxHeight: 47,
      } as const,
      title: {
        display: true,
        text: selectedMeasurementFilterValue,
        color: 'white',
        align: 'center',
        padding: 10,
        font: {
          size: 20,
        },
      } as const,
      tooltip: {
        padding: 10,
        bodyFont: {
          size: 24,
        },
        titleFont: {
          size: 24,
        },
        boxPadding: 8,
        usePointStyle: true,
        backgroundColor: '#12234f',
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || '';
            if (label) {
              label = [
                context.dataset.label,
                d3Format.format(',')(context.parsed.y) + ' ' + selectedMeasurementFilterValue,
              ];
            }
            return label;
          },
        },
      } as const,
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        } as const,
      } as const,
    } as const,
  } as const;

  const onMouseEnter = useCallback((event: any | null) => {
    if (event.features && event.features[0]) {
      setCursor('pointer');
      setPopupInfo(event.features[0].properties);
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    setCursor('');
    setPopupInfo(null);
  }, []);
  const chartRef = useRef<any>();

  const onBarChartClickHandler = (event: any) => {
    const currentBar = getElementAtEvent(chartRef.current, event)[0];
    if (currentBar) {
      const currentBarMetaData = chartRef.current.getDatasetMeta(currentBar.datasetIndex);
      setFeatureClicksCounter(prev => prev + 1);
      resolveClickedFeatureBehavior(currentBarMetaData.label, true);
    }
  };

  const resolveClickedFeatureBehavior = (stationName: string, isBarClick = false) => {
    const isAlreadySelected = currentSelectedStationName == stationName;

    if (
      (isAlreadySelected && reportData?.stations.length && featureClicksCounter == 1) ||
      (isAlreadySelected && isBarClick)
    ) {
      createFeatureCollection(reportData.stations);
      flyToInitialLocationHandler(appContext.selectedHUC8, mapRef, catalogsContext.huc8Catalog);
      setCurrentSelectedStationName('');
      updateChartData(reportData.data, stationsMapPointColorsCatalog || []);
      setFeatureClicksCounter(0);
    } else if (featureClicksCounter == 1 || isBarClick) {
      setCurrentSelectedStationName(stationName);
      const currentStationSelected =
        (reportData as LiveWaterSensorsResponseBodyModel)?.stations?.find(
          (x: any) => x.Name == stationName
        ) || '';
      if (currentStationSelected) {
        createFeatureCollection([currentStationSelected]);
        const filteredDataByLocation = reportData?.data?.filter(
          (x: StationDataMeasures) => x.Name == stationName
        );

        updateChartData(filteredDataByLocation, stationsMapPointColorsCatalog || []);

        flyToLngLat(
          mapRef,
          Number(currentStationSelected.Longitude),
          Number(currentStationSelected.Latitude)
        );
      }
      setFeatureClicksCounter(0);
    }
  };

  const clickedFeatureHandler = (e: any) => {
    if (e.features?.length > 0) {
      //@ts-ignore
      const clickedFeatureStationName = e.features[0].properties['Name'];
      setFeatureClicksCounter(prev => prev + 1);
      setClickedFeatureName(clickedFeatureStationName);
    }
  };

  const renderPopup = () => {
    return (
      <Popup
        style={{ color: 'white' }}

        longitude={Number(popupInfo.Longitude)}
        latitude={Number(popupInfo.Latitude)}
        onClose={() => setPopupInfo(null)}
        className='popup-container--dark'
      >
        <div className='popup-container'>
          <Row>
            <Col> Station Name:</Col>
            <Col className='popup-content-highlight'>{popupInfo['Name']}</Col>
          </Row>
        </div>
      </Popup>
    );
  };

  return (
    <FullScreenComp handle={fullscreenhandle}>
      <div className='toxics-release-inventory' id='LiveWaterSensors-report'>
        <ReportHeader global={global} data={reportData}
          reportID={"LiveWaterSensors-report"} filterLabelDays={filterLabelDays} fullScreenClickHandle={fullscreenhandle.enter} />
        <div className='container'>
          <Row>
            <Col>
              <div style={{ position: 'relative' }} className='map-container'>
                <LoadingSpinner active={loading} />
                <RightHeader global={global} button={false} />
                <Map
                  ref={mapRef as Ref<MapRef>}
                  preserveDrawingBuffer={true}
                  mapboxAccessToken={settings.maboxKey}
                  initialViewState={mapInitialViewState}
                  mapStyle={global.mapStyle}
                  cursor={cursor}
                  interactiveLayerIds={['pointlayer']}
                  projection={global.globeView ? 'globe' : 'mercator' as any}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  onClick={(e) => {
                    global.onMapClick(e)
                  }}
                  onMove={(e) => {
                    global.setViewport({
                      longitude: e.viewState.longitude,
                      latitude: e.viewState.latitude,
                      zoom: e.viewState.zoom,
                    })
                  }}
                >

                    <MapAddition global={global} 
                                mapRef={mapRef}

                                position={'low'}
                                zipOff={true}
                                MapSliderAdd={true}
                                statusMessage={statusMessage}/>

                  {featureData && (
                    <Source id='circlesource' type='geojson' data={featureData}>
                      <Layer {...layerStyle} />
                    </Source>
                  )}
                  {popupInfo && renderPopup()}

                  <div className='map-legend-container'>
                    <MapLegend
                      global={global}
                      legendWidth={320}
                    />
                  </div>
                </Map>
              </div>
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '23rem' }}>
              {/* <div className='table-container' style={{ minHeight: '30rem' }}> */}
              <div
                className={
                  isFullScreenChartActive
                    ? 'container--expand-container lws-table-container'
                    : 'lws-table-container'
                }
              >
                <Row>
                  <div className='report-options'>
                    <Button onClick={fullscreenChartContainerClickHandler}>
                      <img src={fullscreenIcon} />
                    </Button>
                  </div>
                </Row>
                <Row className='chart-container' style={{ height: '22rem' }}>
                  <Bar
                    options={options}
                    data={chartData}
                    ref={chartRef}
                    onClick={onBarChartClickHandler}
                  />
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <ReportFooter />
      </div>

    </FullScreenComp>
  );
};

export default LiveWaterSensors;
