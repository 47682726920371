import { useState, useEffect } from 'react'
import {
    Form,
    Col,
    Row,
    Button,
    Table,
    Tabs,
    Tab,
    FloatingLabel
} from 'react-bootstrap';
import { getDomainValue, postWQX } from './lib/data';

const CharacteristicModal = ({
    characteristicModal,
    setCharacteristicModal,
    importChar,
    setImportChar,
    data,
    setData       
}: any) => {

    const dropdownLimit = 2000;
    const [fullCharacteristic, setFullCharacteristic] = useState < any[] > ([]);
    const [currentCharacteristic, setCurrentCharacteristic] = useState < any[] > ([]);

    const [Characteristic, setCharacteristic] = useState < any[] > ([]);
    const [AnalyticalMethod, setAnalyticalMethod] = useState < any[] > ([]);
    const [SampleFraction, setSampleFraction] = useState < any[] > ([]);
    const [ResultStatus, setResultStatus ] = useState < any[] > ([]);
    const [ResultValueType, setResultValueType] = useState < any[] > ([]);
    const [AnalyticalMethodContext, setAnalyticalMethodContext] = useState < any[] > ([]);
    const [MeasurementUnit, setMeasurementUnit] = useState < any[] > ([]);

    const limitCharacteristic = (value:any) => {
        setFullCharacteristic(value);
        setCharacteristic(value.slice(0, dropdownLimit));
    }

    useEffect(() => {
        getDomainValue('Characteristic', limitCharacteristic);
        getDomainValue('AnalyticalMethod', setAnalyticalMethod);
        getDomainValue('SampleFraction', setSampleFraction);
        getDomainValue('ResultStatus', setResultStatus);
        getDomainValue('ResultValueType', setResultValueType);
        getDomainValue('MeasurementUnit', setMeasurementUnit);

    }, []);

    const updateMon = (property: any, value:any) => {
      let changeMon = data;
      changeMon[property]=value;
      setData(changeMon)
    }

    const saveImportChar = () => {
        setCharacteristicModal(!characteristicModal); 
        let newData = [...importChar];
        newData.push(data);
        setImportChar(newData);
    }

    const onChangeCharSearch = (event: any) => {     
        if (event && event.target && event.target.value && event.target.value.length > 1) {
            let fData = [...fullCharacteristic].filter((o: any) => o["Name"].toLowerCase().indexOf(event.target.value.toLowerCase()) > -1);
            setCharacteristic(fData.slice(0,dropdownLimit));
        } else {
            setCharacteristic(fullCharacteristic.slice(0,dropdownLimit));
        }       
    }


    return (
        <div className="modal-container">
            <div className="modal-wrapper add-char-wrapper">
                <div className="modal">
                    <div className="modal-header">
                        <p>Add/Edit Characteristic Defaults</p>
                    </div>
                    <div className="modal-body">
                        <Col className="col-12">
                            <Row>
                                <Col className="col-12 d-flex justify-content-between">
                                    <Col className="col-4">
                                        <p>Characteristic Name:</p>
                                    </Col>
                                    <Col className="col-8">
                                       <div className='users-tool-container'  style={{minWidth: 80, display: 'inline-flex'}}>
                                          <Form.Group  className='users-search' controlId="formSearch" style={{display: 'inline-flex', height: 'fit-content', marginTop: 5}}>
                                              <Form.Control onChange={onChangeCharSearch} type="text" placeholder="Search" />
                                          </Form.Group>
                                      </div>
                                        <Form.Group className="mb-3" controlId="floatingMonitoringLocationType">                                    
                                            <Form.Select  onChange={(e: any) => updateMon('Characteristic', e.target.value)}
                                             size="lg" aria-label="MonitoringLocIdent_MonitoringLocationTypeName" defaultValue={""}>
                                                {Characteristic   && Characteristic.map((obj:any, index:any) =>
                                                    (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                                )} 
                                            </Form.Select>                                    
                                        </Form.Group>
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12 d-flex justify-content-between">
                                    <Col className="col-4">
                                        <p>Default Unit:</p>
                                    </Col>
                                    <Col className="col-8">
                                        <Form.Group className="mb-3" controlId="floatingMeasurementUnit">                                    
                                            <Form.Select  onChange={(e: any) => updateMon('MeasurementUnit', e.target.value)}
                                             size="lg" aria-label="MeasurementUnit" defaultValue={data.MeasurementUnit}>
                                                {MeasurementUnit   && MeasurementUnit.map((obj:any, index:any) =>
                                                    (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                                )} 
                                            </Form.Select>                                    
                                        </Form.Group>
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12 d-flex justify-content-between">
                                    <Col className="col-4">
                                        <Form.Label>Detection Limit:</Form.Label>
                                    </Col>
                                    <Col className="col-8">
                                        <Form.Control size="sm" type="text" 
                                            defaultValue={data.DetectionLimit}
                                            onChange={(e: any) => updateMon('DetectionLimit', e.target.value)}/>
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12 d-flex justify-content-between">
                                    <Col className="col-4">
                                        <Form.Label>Lower Quant Limit:</Form.Label>
                                    </Col>
                                    <Col className="col-8">                                       
                                        <Form.Control size="sm" type="text"  defaultValue={data.LowerQuantLimit} 
                                            onChange={(e: any) => updateMon('LowerQuantLimit', e.target.value)}/>
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12 d-flex justify-content-between">
                                    <Col className="col-4">
                                        <Form.Label>Upper Quant Limit:</Form.Label>
                                    </Col>
                                    <Col className="col-8">
                                        <Form.Control size="sm" type="text" defaultValue={data.UpperQuantLimit} 
                                                onChange={(e: any) => updateMon('UpperQuantLimit', e.target.value)}/>
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12 d-flex justify-content-between">
                                    <Col className="col-4">
                                        <p>Analysis Method:</p>
                                    </Col>
                                    <Col className="col-8">
                                        <Form.Group className="mb-3" controlId="floatingMonitoringLocationType">                                    
                                            <Form.Select  onChange={(e: any) => updateMon('AnalysisMethod', e.target.value)}
                                             size="lg" aria-label="Analysi Method" defaultValue={data.AnalysisMethod}>
                                                {AnalyticalMethod   && AnalyticalMethod.map((obj:any, index:any) =>
                                                    (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                                )} 
                                            </Form.Select>                                    
                                        </Form.Group>
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12 d-flex justify-content-between">
                                    <Col className="col-4">
                                        <p>Sample Fraction:</p>
                                    </Col>
                                    <Col className="col-8">
                                        <Form.Group className="mb-3" controlId="floatingSampleFraction.">                                    
                                            <Form.Select  onChange={(e: any) => updateMon('SampleFraction', e.target.value)}
                                             size="lg" aria-label="SampleFraction" defaultValue={data.SampleFraction}>
                                                {SampleFraction  && SampleFraction.map((obj:any, index:any) =>
                                                    (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                                )} 
                                            </Form.Select>                                    
                                        </Form.Group>
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12 d-flex justify-content-between">
                                    <Col className="col-4">
                                        <p>Result Status:</p>
                                    </Col>
                                    <Col className="col-8">
                                        <Form.Group className="mb-3" controlId="floatingResultStatus">                                    
                                            <Form.Select  onChange={(e: any) => updateMon('ResultStatus', e.target.value)}
                                             size="lg" aria-label="ResultStatus" defaultValue={data.ResultStatus}>
                                                {ResultStatus && ResultStatus.map((obj:any, index:any) =>
                                                    (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                                )} 
                                            </Form.Select>                                    
                                        </Form.Group>
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12 d-flex justify-content-between">
                                    <Col className="col-4">
                                        <p>Result Value Type:</p>
                                    </Col>
                                    <Col className="col-8">
                                        <Form.Group className="mb-3" controlId="floatingMResultValueType">                                    
                                            <Form.Select  onChange={(e: any) => updateMon('ResultValueType', e.target.value)}
                                             size="lg" aria-label="ResultValueType" defaultValue={data.ResultValueType}>
                                                {ResultValueType && ResultValueType.map((obj:any, index:any) =>
                                                    (<option key={'wm-proj-options'+index} value={obj.Name}>{obj.Name}</option>)
                                                )} 
                                            </Form.Select>                                    
                                        </Form.Group>
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                    <div className="modal-footer add-char-modal-footer">
                        <div className="modal-button-container">
                            <Button onClick={() => saveImportChar()}>
                                Save
                            </Button>
                            <Button onClick={() => setCharacteristicModal(!characteristicModal)}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CharacteristicModal