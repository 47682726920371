import { useState, useEffect } from 'react';
import { Marker, Popup } from 'react-map-gl';
import useSupercluster from 'use-supercluster';
import RenderPopup from '../../RenderPopup';

const HUCMarkers = (props: any) => {
    const { data, bounds, viewport, color, reportUrl, global, huc8only, popupfieldsreflect } = props
    const [current, setCurrent] = useState<any | null>(null)
    const [list, setList] = useState<any[]>([])

    useEffect(() => {
        if(huc8only){
            setList(data)
        } else {
            const huc12s = data.map((huc: any) => {
                return huc.huc12s.map((huc12: any) => huc12)
            })
            setList(huc12s.flat())
        }
    }, [])

    // @ts-ignore
    const points: any = list !== [] && list.map((point: any, index: number) => ({
        type: "Feature",
        properties: {
            cluster: false,
            index,
            score: Math.round(point.national_twpe_score),
            id: `cluster-${index}`,
        },
        geometry: {
            type: "Point",
            coordinates: [point.lng, point.lat],
        }
    }))

    const renderAverage = (input: any) => {
        const sum: any = input.reduce((accumulator: any, point: any) => {
            return parseInt(accumulator) + parseInt(point.properties.score)
        }, 0)
        return Math.round(sum / input.length)
    }

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom: viewport.zoom,
        options: { radius: 100, maxZoom: 15 }
    })

    const renderList = () => {
        return clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates
            const { cluster: isCluster, point_count: pointCount, index, score } = cluster.properties;

            if (isCluster) {

                const pointData = supercluster?.getLeaves(parseInt(cluster.id!.toString()))

                return (
                    <Marker    
                        key={cluster.id}
                        latitude={latitude}
                        longitude={longitude}
                    >
                        <div
                            className="cluster-list-marker"
                            style={{ border: `3px solid ${color}`}}
                        >
                            <img
                                
                                width="36px"
                                style={{ cursor: "pointer" }}
                                src={`https://te-v3-assets.s3.us-west-2.amazonaws.com/${renderAverage(pointData)}.svg`}
                                alt="score_icon"
                            />
                        </div>
                    </Marker>
                )
            }

            return (
                <Marker
                    key={cluster.properties.id}
                    latitude={latitude}
                    longitude={longitude}
                    onClick={(e) => {
                        e.originalEvent.stopPropagation();
                        setCurrent(list[index])
                    }}
                >
                    <img
                        width="30px"
                        style={{ cursor: "pointer", border: `3px solid ${color}`, borderRadius: '50%' }}
                        src={`https://te-v3-assets.s3.us-west-2.amazonaws.com/${score}.svg`}
                        alt="score_icon"
                    />
                </Marker>
            );
        })
    }

    return (
        <>
            {clusters && renderList()}
            {current && <RenderPopup reportUrl={reportUrl} current={current} setCurrent={setCurrent} global={global} popupfieldsreflect={popupfieldsreflect}/>}
        </>
    )
}

export default HUCMarkers