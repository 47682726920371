const IndustrySectorsMarkersColors = [
  '2122',
  '#7f0800',
  '3121',
  '#00cc0c',
  '336',
  '#0c00cc',
  '2121',
  '#ffea32',
  '322',
  '#d10d00',
  '4246',
  '#4C5C78',
  '335',
  '#754D82',
  '323',
  '#CCC09F',
  '4247',
  '#F7A12F',
  '315',
  '#29CCCC',
  '337',
  '#894A92',
  '339',
  '#F232FF',
  '562',
  '#ff1100',
  '314',
  '#BD3CE5',
  '999',
  '#da680f',
  '511',
  '#AE9690 ',
  '2211',
  '#ba581f',
  '3122',
  '#3cff00',
  '313',
  '#559807',
  '311',
  '#1a9850',
  '334',
  '#66bd63',
  '331',
  '#c7b585',
  '325',
  '#9ba832',
  '321',
  '#edde07',
  '324',
  '#d61125',
  '332',
  '#6e151e',
  '326',
  '#365587',
  '327',
  '#9ebae8',
  '#ccc',
];

export default IndustrySectorsMarkersColors;
