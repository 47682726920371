import { Marker, Popup } from 'react-map-gl';
import { MarkerConfig } from '../types/MarkerConfig';
import { v4 as uuidv4 } from 'uuid';
import MapPinCircle from '../features/reports/shared/MapPinCircle';

const MapPinsBuilder: React.FC<{
  markersLocationConfigArray: MarkerConfig[];
  onMouseEnter?: (e: any) => void;
  onMouseExit?: (e: any) => void;
}> = ({
  markersLocationConfigArray: markerConfigsArray,
  onMouseEnter = () => {},
  onMouseExit = () => {},
}) => {
  return (
    <>
      {markerConfigsArray.map((location) => (
        <Marker
          key={uuidv4()}
          latitude={parseFloat(location.geoPoint.latitude)}
          longitude={parseFloat(location.geoPoint.longitude)}
        >
          <MapPinCircle
            onMouseEnter={() =>
              onMouseEnter({
                latitude: location.geoPoint.latitude,
                longitude: location.geoPoint.longitude,
                text: `${location.huc} - ${location.name}`,
              })
            }
            onMouseExit={onMouseExit}
          />
        </Marker>
      ))}
    </>
  );
};

export default MapPinsBuilder;
