import { useState, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Source, Layer, Popup, CircleLayer } from 'react-map-gl';
import { RefContext } from "../../../RefContext";
import { ActivePopupContext } from '../../../ActivePopupContext';
import { PointContext } from "../../../PointContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import './circle-data-layer.scss';

const CircleDataLayer = ({
  id,
  action,
  circleColor,
  tileSet,
  sourceLayer,
  opacity,
  objString = 'Point',
  reportRoute = '/',
  featurePoint,
  includedLayerData = true,
  reportAnchor = true,
  circleStrokeColor = "white",
  excludedIDs = [''],
  radius,
  popupTitle = true,
  pointLabel = null,
  nsg = false
}: any) => {

  const navigate = useNavigate();
  const [features, setFeatures] = useState<any>(null);
  const { currentRef } = useContext(RefContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);

  // @ts-ignore
  const mapInstance = currentRef && currentRef?.current;
  const lngLat = action && action.lngLat;

  const noDataList =  [
    'ABBY', 'BARR', 'BART', 'BLAN', 'BONA', 'CLBJ', 'CPER', 'DCFS', 'DEJU', 
    'DELA', 'DSNY', 'GRSM', 'GUAN', 'HARV', 'HEAL', 'JERC', 'JORN', 'KONA', 
    'KONZ', 'LAJA', 'LENO', 'MLBS', 'MOAB', 'NIWO', 'NOGP', 'OAES', 'ONAQ', 
    'ORNL', 'OSBS', 'PUUM', 'RMNP', 'SCBI', 'SERC', 'SJER', 'SOAP', 'SRER', 
    'STEI', 'STER', 'TALL', 'TEAK', 'TOOL', 'TREE', 'UKFS', 'UNDE', 'WOOD', 
    'WREF', 'YELL'
  ]

  const ensureCoordinates = (obj: any, defaultCoordinates: any) => {
    const coordinateRegex = /^(lat|lng|long|lon|latitude|longitude)$/i;
    let hasLat = false;
    let hasLng = false;
    let tempLat: any, tempLng: any;

    Object.keys(obj).forEach(key => {
      if (coordinateRegex.test(key)) {
        const lowerCaseKey = key.toLowerCase();
        if (lowerCaseKey === 'lat' || lowerCaseKey === 'latitude') {
          tempLat = obj[key];
          hasLat = true;
          delete obj[key];
        }
        if (lowerCaseKey === 'lng' || lowerCaseKey === 'long' || lowerCaseKey === 'lon' || lowerCaseKey === 'longitude') {
          tempLng = obj[key];
          hasLng = true;
          delete obj[key];
        }
      }
    });

    const parseToNumber = (input: any) => {
      const parsedValue = parseFloat(input);
      return isNaN(parsedValue) ? null : parsedValue;
    };

    obj.lat = hasLat ? parseToNumber(tempLat) : parseToNumber(defaultCoordinates.lat);
    obj.lng = hasLng ? parseToNumber(tempLng) : parseToNumber(defaultCoordinates.lng);

    return obj;
  };

  const memoizedPopup = useMemo(() => {
    if (!features || !lngLat) return null;

    let reportData = true

    let pointData = ensureCoordinates(features, lngLat);
    if (includedLayerData) {
      pointData = { ...pointData, sourceLayer, tileSet };
    }
    setCurrentPoint(pointData); 

    if(features.field_site_id && noDataList.includes(features.field_site_id)) reportData = false

    const handleClose = () => {
      setFeatures(null);
      setActivePopup({ id: null, content: null });
    };

    return (
      <Popup
        longitude={lngLat.lng}
        latitude={lngLat.lat}
        onClose={handleClose}
        className={'circledata-popup'}
      >
        <FontAwesomeIcon
          className="close-btn"
          icon={faXmark}
          onClick={handleClose}
        />
        {popupTitle && <strong>{objString}: {features[pointLabel ? pointLabel : featurePoint]}</strong>}
        {reportAnchor && (!reportData ? <a className="goTo noData" >No data at this location</a> : <a className="goTo" onClick={() => goTo(features)}>Go to report</a>)}
      </Popup>
    );
  }, [features, lngLat, popupTitle, reportAnchor, pointLabel, featurePoint, objString]);

  useEffect(() => {
    if (mapInstance) {
      setTimeout(() => {
        mapInstance.on("click", (event: any) => {

  
          const layerObject = mapInstance.queryRenderedFeatures(event.point, { layers: [`${id}-layer`] });
          if (layerObject.length > 0) {
            setFeatures(layerObject[0].properties);
          }
        });
      }, 300);
    }
  }, [mapInstance]);

  useEffect(() => {
    if (memoizedPopup && activePopup.id !== id) {
      setActivePopup({
        id,
        content: memoizedPopup
      });
    }
  }, [memoizedPopup, activePopup, setActivePopup, id]);

  useEffect(() => {
    const popupContent: any = document.querySelector(".mapboxgl-popup-content");
    return () => {
      if (popupContent) {
        popupContent.style.height = 'unset';
      }
    };
  }, []);

  const goTo = (input: any) => {
    let pointData = ensureCoordinates(input, lngLat);
    if (includedLayerData) {
      pointData = { ...pointData, sourceLayer, tileSet };
    }
    setCurrentPoint(pointData);
    setTimeout(() => navigate(reportRoute), 300);
  };

  const CircleDataLayerConfig: CircleLayer = {
    id: `${id}-layer`,
    type: "circle",
    "source-layer": sourceLayer,
    paint: {
      "circle-stroke-color": 'rgba(0,0,0,0)',
      "circle-stroke-width": 8,
      "circle-radius": radius,
      "circle-color": circleColor,
      "circle-opacity": opacity,
      "circle-stroke-opacity": opacity,
    },
    filter: ["!in", "ID", ...excludedIDs]
  };

  const NSGLayerConfig: CircleLayer = {
    id: `${id}-layer`,
    type: "circle",
    "source-layer": sourceLayer,
    paint: {
      "circle-stroke-color": "#FFFFFF", 
      "circle-stroke-width": 2,
      "circle-radius": radius,
      "circle-color": [
        "match",
        ["get", "field_site_id"],
        noDataList,
        "#cbcb14", 
        circleColor 
      ],
      "circle-opacity": opacity, 
      "circle-stroke-opacity": opacity 
    }
  };

  return (
    <>
      <Source id={`${id}-layer`} type="vector" url={tileSet}>
        {nsg ? <Layer {...NSGLayerConfig} /> : <Layer {...CircleDataLayerConfig} />}
      </Source>
      {activePopup && activePopup.id === id && activePopup.content}
    </>
  );
};

export default CircleDataLayer;
