import { useState, Ref, useRef, useContext, useEffect, useCallback } from 'react';
import { Location, useLocation } from 'react-router-dom';
import ReportsConfigs from '../../../ReportsConfigs';
import ReportName from '../shared/ReportName';
import './ReportFooter.scss';


const ReportFooter = (props: any) => {
    const location: Location = useLocation();
    //@ts-ignore
    const routeData = location.state?.data;
    const configsArray = ReportsConfigs.filter(config => config.route == location.pathname);

    let sourceText = '', sourceLink = '';
    if (configsArray && configsArray[0] && configsArray[0].sourceText && configsArray[0].sourceLink) {
        sourceText = configsArray[0].sourceText;
        sourceLink = configsArray[0].sourceLink;
    }

    return (
        <div className='report-footer'>
            <div className='report-footer-source-text'>
                <h3>
                    Source:{' '} {configsArray[0].sourcePreText}
                    <a target='_blank' className='report-footer-source-link' href={sourceLink}>
                        {' '}
                        {sourceText}
                    </a>
                    {configsArray[0].sourceTextSecondary && (
                            <a target='_blank' className='report-footer-source-link' href={configsArray[0].sourceLinkSecondary}>
                                {' & '}
                                {configsArray[0].sourceTextSecondary}
                            </a>
                    )}
                </h3>
            </div>
        </div>
    );
};

export default ReportFooter;